import { IconProps } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps } from 'react';

import Setting from '../../../pages/setting';
import Grid from '../Grid';
import NavList from '../NavList';

const SettingGridNavListLayout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Grid gapX={2} gapY={3}>
      <Grid.Unit size={[1, 1 / 4]}>
        <NavList>
          {Setting.routes.map(({ id, name, pathname, icon }) => (
            <NavItem key={id} href={pathname} leadingIcon={icon}>
              {name}
            </NavItem>
          ))}
        </NavList>
      </Grid.Unit>
      <Grid.Unit size={[1, 3 / 4]}>{children}</Grid.Unit>
    </Grid>
  );
};

const NavItem = ({
  href = '/',
  leadingIcon: LeadingIcon,
  children,
  ...props
}: React.PropsWithChildren<
  {
    leadingIcon?: React.FunctionComponent<React.PropsWithChildren<IconProps>>;
  } & ComponentProps<typeof NavList.Item>
>) => {
  const { pathname, push } = useRouter();
  const isCurrent = pathname === href;
  return (
    <NavList.Item
      href={href}
      onClick={(e) => {
        e.preventDefault();
        push(href);
      }}
      aria-current={isCurrent ? 'page' : false}
      {...props}
    >
      {LeadingIcon ? (
        <NavList.LeadingVisual>
          <LeadingIcon />
        </NavList.LeadingVisual>
      ) : null}
      {children}
    </NavList.Item>
  );
};

export default SettingGridNavListLayout;
