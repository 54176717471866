/**
 * @generated SignedSource<<da725d5e02ff7e536793691f8c348dd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BookPublisherSearchOverlayTokenField_bookPublishersQuery$variables = {};
export type BookPublisherSearchOverlayTokenField_bookPublishersQuery$data = {
  readonly bookPublishers: ReadonlyArray<string>;
};
export type BookPublisherSearchOverlayTokenField_bookPublishersQuery = {
  response: BookPublisherSearchOverlayTokenField_bookPublishersQuery$data;
  variables: BookPublisherSearchOverlayTokenField_bookPublishersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bookPublishers",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BookPublisherSearchOverlayTokenField_bookPublishersQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BookPublisherSearchOverlayTokenField_bookPublishersQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ab705b819b0a7def088163face24032c",
    "id": null,
    "metadata": {},
    "name": "BookPublisherSearchOverlayTokenField_bookPublishersQuery",
    "operationKind": "query",
    "text": "query BookPublisherSearchOverlayTokenField_bookPublishersQuery {\n  bookPublishers\n}\n"
  }
};
})();

(node as any).hash = "19c7d99a8c309b52e068e8b735c71c53";

export default node;
