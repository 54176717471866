import { CheckIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import { DeliveryOrderCreateDialog_deliveryOrderCreateMutation } from '../../../relay/__generated__/DeliveryOrderCreateDialog_deliveryOrderCreateMutation.graphql';
import AddressInputField from '../../core/AddressInputField';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import TelField from '../../core/TelField';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';
import View from '../../core/View';
import StuffSelectField from '../../stuff/StuffSelectField';

type Props = {} & DialogProps &
  Pick<
    MutationFormikProps<DeliveryOrderCreateDialog_deliveryOrderCreateMutation>,
    'connections' | 'onSubmit' | 'config'
  >;

const DeliveryOrderCreateDialog = ({ config, onSubmit, connections, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>배송 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<DeliveryOrderCreateDialog_deliveryOrderCreateMutation>
        mutation={graphql`
          mutation DeliveryOrderCreateDialog_deliveryOrderCreateMutation($input: DeliveryOrderCreateInput!) {
            deliveryOrderCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{
          address: { address: '', zipCode: '', addressDetail: '' },
          description: '',
          recipientName: '',
          recipientNumber: '',
          stuff: { id: '' },
          title: '',
          type: 'order',
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit, dirty }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <View>
                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>배송 상품</Text>
                </View>
                <TextField name={'title'} label={'제목'} required placeholder={'제목 입력'} autoComplete={'off'} />
                <TextareaField name={'description'} label={'설명'} placeholder={'설명 입력'} autoComplete={'off'} />
                <Suspense>
                  <EnumPairSelectField
                    typename={'DeliveryOrderTypeEnum'}
                    name={'type'}
                    label={'타입'}
                    required
                    placeholder={'타입 선택'}
                  />
                </Suspense>
                <Suspense>
                  <StuffSelectField name={'stuff.id'} label={'물품'} required placeholder={'물품 선택'} />
                </Suspense>
              </FormLayout>
              <View sx={{ marginTop: 6, marginBottom: 4 }}>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>수령자 정보</Text>
              </View>
              <FormLayout>
                <TextField
                  name={'recipientName'}
                  label={'수령자'}
                  required
                  placeholder={'수령자 입력'}
                  autoComplete={'off'}
                />
                <TelField
                  name={'recipientNumber'}
                  label={'전화번호'}
                  required
                  placeholder={'01000000000'}
                  autoComplete={'off'}
                />
                <AddressInputField name={'address'} label={'주소'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button disabled={!dirty} variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default DeliveryOrderCreateDialog;
