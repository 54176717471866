/**
 * @generated SignedSource<<f05882391cdac00d4e2e07c28f1dcde1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeliveryOrderActionEnum = "delivery_order_cancel" | "delivery_order_complete" | "delivery_order_update" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderMutationActionsButtonStack_deliveryOrder$data = {
  readonly actions: ReadonlyArray<DeliveryOrderActionEnum>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderCancelDialog_deliveryOrder" | "DeliveryOrderUpdateDialog_deliveryOrder">;
  readonly " $fragmentType": "DeliveryOrderMutationActionsButtonStack_deliveryOrder";
};
export type DeliveryOrderMutationActionsButtonStack_deliveryOrder$key = {
  readonly " $data"?: DeliveryOrderMutationActionsButtonStack_deliveryOrder$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderMutationActionsButtonStack_deliveryOrder">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryOrderMutationActionsButtonStack_deliveryOrder",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeliveryOrderUpdateDialog_deliveryOrder"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeliveryOrderCancelDialog_deliveryOrder"
    }
  ],
  "type": "DeliveryOrder",
  "abstractKey": null
};

(node as any).hash = "0905cd67a81b14d0788c4c4c35b9a510";

export default node;
