/**
 * @generated SignedSource<<03a809013d835dfac2749fd8de228dc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorDifferenceItemList_difference$data = {
  readonly generatedCount?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly publishedProblemsCount?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly " $fragmentType": "ProblemGeneratorDifferenceItemList_difference";
};
export type ProblemGeneratorDifferenceItemList_difference$key = {
  readonly " $data"?: ProblemGeneratorDifferenceItemList_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorDifferenceItemList_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "DiffItem_diff"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProblemGeneratorDifferenceItemList_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "generatedCount",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "publishedProblemsCount",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ProblemGeneratorDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "9f6e150566b1b285393c1bc15076b074";

export default node;
