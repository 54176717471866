/**
 * @generated SignedSource<<6354c6e17859d6ddd636c7480e6380d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentActionEnum = "comment_delete" | "comment_update" | "%future added value";
export type CommentCreateInput = {
  description: string;
  nodeId: string;
};
export type CommentCreateMutationFormik_commentCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CommentCreateInput;
};
export type CommentCreateMutationFormik_commentCreateMutation$data = {
  readonly commentCreate: {
    readonly actions: ReadonlyArray<CommentActionEnum>;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CommentMutationActionItem_comment">;
  };
};
export type CommentCreateMutationFormik_commentCreateMutation = {
  response: CommentCreateMutationFormik_commentCreateMutation$data;
  variables: CommentCreateMutationFormik_commentCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentCreateMutationFormik_commentCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "commentCreate",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CommentMutationActionItem_comment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommentCreateMutationFormik_commentCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "commentCreate",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "appendNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "commentCreate",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "CommentEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a17815b38195a389de69aacf0d35b52d",
    "id": null,
    "metadata": {},
    "name": "CommentCreateMutationFormik_commentCreateMutation",
    "operationKind": "mutation",
    "text": "mutation CommentCreateMutationFormik_commentCreateMutation(\n  $input: CommentCreateInput!\n) {\n  commentCreate(input: $input) {\n    id\n    actions\n    ...CommentMutationActionItem_comment\n  }\n}\n\nfragment CommentItem_comment on Comment {\n  id\n  created\n  createdBy {\n    id\n    ...UserAvatarText_user\n  }\n  modified\n  modifiedBy {\n    id\n  }\n  description\n}\n\nfragment CommentMutationActionItem_comment on Comment {\n  id\n  actions\n  description\n  createdBy {\n    ...UserAvatarText_user\n    id\n  }\n  ...CommentItem_comment\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "ba6298d4754e777025962dee33720841";

export default node;
