import { CheckIcon } from '@primer/octicons-react';
import { graphql } from 'react-relay';

import { MATOrganizationLMSSendDialog_lmsSendMutation } from '../../../relay/__generated__/MATOrganizationLMSSendDialog_lmsSendMutation.graphql';
import { isNullable } from '../../../utils/is';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import TelField from '../../core/TelField';
import Text from '../../core/Text';
import TextareaField from '../../core/TextareaField';

type Props = {} & DialogProps &
  Pick<MutationFormikProps<MATOrganizationLMSSendDialog_lmsSendMutation>, 'config' | 'onSubmit' | 'initialValues'>;

const MATOrganizationLMSSendDialog = ({ initialValues, config, onSubmit, ...props }: Props) => {
  return (
    <Dialog wide {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>문자 보내기</Text>
      </Dialog.Header>
      <MutationFormik<MATOrganizationLMSSendDialog_lmsSendMutation>
        mutation={graphql`
          mutation MATOrganizationLMSSendDialog_lmsSendMutation($input: MATLmsSendInput!) {
            lmsSend(input: $input)
          }
        `}
        initialValues={{ phoneNumber: '', ...initialValues }}
        config={config}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }, { nonFieldErrorRef, scrollContainerRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <TelField
                  label={'받는 사람'}
                  name={'phoneNumber'}
                  required
                  placeholder={'휴대폰 번호 입력'}
                  type={'phoneNumber'}
                  validate={(value) => {
                    if (isNullable(value) || value === '') {
                      return '휴대폰 번호를 입력해 주세요';
                    }
                  }}
                />
                <TextareaField
                  label={'내용'}
                  name={'content'}
                  required
                  placeholder={'내용 입력'}
                  caption={'선생님 이름, 문의 받을 번호, 계정 정보를 수정해 주세요'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button leadingIcon={CheckIcon} variant={'primary'} onClick={() => handleSubmit()}>
                보내기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default MATOrganizationLMSSendDialog;
