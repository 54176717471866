/**
 * @generated SignedSource<<2d2e6312ec6f2765b942e784591daf02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeliveryOrderTypeEnum = "gift" | "order" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderConnectionDataTable_deliveryOrderConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly delivered: any | null;
      readonly deliveredBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly deliveryAddress: {
        readonly address: string;
        readonly addressDetail: string;
        readonly recipientName: string;
        readonly recipientNumber: string;
      };
      readonly deliveryWaybill: {
        readonly waybillNumber: string;
      } | null;
      readonly id: string;
      readonly sequence: number;
      readonly stuff: {
        readonly id: string;
        readonly title: string;
      };
      readonly type: DeliveryOrderTypeEnum;
      readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderStatusLabel_deliveryOrder">;
    };
  }>;
  readonly " $fragmentType": "DeliveryOrderConnectionDataTable_deliveryOrderConnection";
};
export type DeliveryOrderConnectionDataTable_deliveryOrderConnection$key = {
  readonly " $data"?: DeliveryOrderConnectionDataTable_deliveryOrderConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderConnectionDataTable_deliveryOrderConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryOrderConnectionDataTable_deliveryOrderConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DeliveryOrderEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DeliveryOrder",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DeliveryOrderStatusLabel_deliveryOrder"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Stuff",
              "kind": "LinkedField",
              "name": "stuff",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DeliveryWaybill",
              "kind": "LinkedField",
              "name": "deliveryWaybill",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "waybillNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DeliveryAddress",
              "kind": "LinkedField",
              "name": "deliveryAddress",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recipientName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "recipientNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "address",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "addressDetail",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "delivered",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "deliveredBy",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeliveryOrderConnection",
  "abstractKey": null
};
})();

(node as any).hash = "0dd348b19ed018736d5e8ea38ce8464c";

export default node;
