/**
 * @generated SignedSource<<725dcf4c3bb0d8a25d8e411fd046c29f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type QuizAnswerTypeEnum = "O" | "X" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuizDescriptionList_quiz$data = {
  readonly answer: QuizAnswerTypeEnum;
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly description: string | null;
  readonly id: string;
  readonly unitD: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UnitDItem_unitD">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"QuizStatusLabel_quiz">;
  readonly " $fragmentType": "QuizDescriptionList_quiz";
};
export type QuizDescriptionList_quiz$key = {
  readonly " $data"?: QuizDescriptionList_quiz$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizDescriptionList_quiz">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizDescriptionList_quiz",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnitD",
      "kind": "LinkedField",
      "name": "unitD",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UnitDItem_unitD"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuizStatusLabel_quiz"
    }
  ],
  "type": "Quiz",
  "abstractKey": null
};
})();

(node as any).hash = "3d0b5c6395a49c80bf4343e2c9430254";

export default node;
