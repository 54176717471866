/**
 * @generated SignedSource<<09c8d9f46823c0a7ddf9c98de3f4e4a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task$data = {
  readonly answerChangeHistory: {
    readonly currentAnswer: number;
    readonly previousAnswer: number;
  } | null;
  readonly sequence: number;
  readonly " $fragmentType": "TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task";
};
export type TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task$key = {
  readonly " $data"?: TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskAnswerChangeHistory",
      "kind": "LinkedField",
      "name": "answerChangeHistory",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentAnswer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "previousAnswer",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};

(node as any).hash = "26637bcc060d43353ab3246c8ca1e957";

export default node;
