/**
 * @generated SignedSource<<61f00a5d629b16ebca44290f414c0f5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskCopyInput = {
  id: string;
};
export type TaskActionsMutationButtonStack_taskCopyMutation$variables = {
  input: TaskCopyInput;
};
export type TaskActionsMutationButtonStack_taskCopyMutation$data = {
  readonly taskCopy: {
    readonly id: string;
  };
};
export type TaskActionsMutationButtonStack_taskCopyMutation = {
  response: TaskActionsMutationButtonStack_taskCopyMutation$data;
  variables: TaskActionsMutationButtonStack_taskCopyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Task",
    "kind": "LinkedField",
    "name": "taskCopy",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskActionsMutationButtonStack_taskCopyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskActionsMutationButtonStack_taskCopyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "491bd99eeea1452ac4c9ff4cecd6b85f",
    "id": null,
    "metadata": {},
    "name": "TaskActionsMutationButtonStack_taskCopyMutation",
    "operationKind": "mutation",
    "text": "mutation TaskActionsMutationButtonStack_taskCopyMutation(\n  $input: TaskCopyInput!\n) {\n  taskCopy(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b80e0bdb4331b5d27fe2edc31a88aa9a";

export default node;
