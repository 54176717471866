/**
 * @generated SignedSource<<1d1def664647574bfd1ab0cdf6ca8b00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkbookConnectionDataTable_workbookConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly grade: ScrapSourceExamGradeEnum;
      readonly id: string;
      readonly modified: any;
      readonly modifiedBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly month: ScrapSourceExamMonthEnum;
      readonly sequence: number;
      readonly sourceName: string;
      readonly title: string;
      readonly year: ScrapSourceExamYearEnum;
      readonly " $fragmentSpreads": FragmentRefs<"WorkbookStatusLabel_workbook">;
    };
  }>;
  readonly " $fragmentType": "WorkbookConnectionDataTable_workbookConnection";
};
export type WorkbookConnectionDataTable_workbookConnection$key = {
  readonly " $data"?: WorkbookConnectionDataTable_workbookConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkbookConnectionDataTable_workbookConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkbookConnectionDataTable_workbookConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkbookEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Workbook",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "WorkbookStatusLabel_workbook"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "year",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grade",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "month",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sourceName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modified",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "modifiedBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "WorkbookConnection",
  "abstractKey": null
};
})();

(node as any).hash = "72a123bf647ad7b54964e8782d9f2554";

export default node;
