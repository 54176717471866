/**
 * @generated SignedSource<<77438cc31ddd06dd90773890dcb7581b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type OrganizationStudentsXlsxCreateInput = {
  file: Upload;
  organization: string;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type MATOrganizationStudentsXlsxCreateDialog_organizationStudentsXlsxCreateMutation$variables = {
  input: OrganizationStudentsXlsxCreateInput;
};
export type MATOrganizationStudentsXlsxCreateDialog_organizationStudentsXlsxCreateMutation$data = {
  readonly organizationStudentsXlsxCreate: boolean;
};
export type MATOrganizationStudentsXlsxCreateDialog_organizationStudentsXlsxCreateMutation = {
  response: MATOrganizationStudentsXlsxCreateDialog_organizationStudentsXlsxCreateMutation$data;
  variables: MATOrganizationStudentsXlsxCreateDialog_organizationStudentsXlsxCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "organizationStudentsXlsxCreate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATOrganizationStudentsXlsxCreateDialog_organizationStudentsXlsxCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATOrganizationStudentsXlsxCreateDialog_organizationStudentsXlsxCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "db915d02139b8c218e1d614972cb7041",
    "id": null,
    "metadata": {},
    "name": "MATOrganizationStudentsXlsxCreateDialog_organizationStudentsXlsxCreateMutation",
    "operationKind": "mutation",
    "text": "mutation MATOrganizationStudentsXlsxCreateDialog_organizationStudentsXlsxCreateMutation(\n  $input: OrganizationStudentsXlsxCreateInput!\n) {\n  organizationStudentsXlsxCreate(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "dfe9e7637861fe408f304c40c7ef421c";

export default node;
