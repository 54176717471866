/**
 * @generated SignedSource<<906dac5a89af6adab2045abef0f5c9b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskSolutionVideoUpdateDialog_taskSolutionVideo$data = {
  readonly id: string;
  readonly isPremium: boolean;
  readonly isRecommended: boolean;
  readonly modified: any;
  readonly taskVideo: {
    readonly id: string;
  };
  readonly thumbnailHorizontal: {
    readonly id: string;
    readonly key: string;
  } | null;
  readonly thumbnailVertical: {
    readonly id: string;
    readonly key: string;
  } | null;
  readonly " $fragmentType": "TaskSolutionVideoUpdateDialog_taskSolutionVideo";
};
export type TaskSolutionVideoUpdateDialog_taskSolutionVideo$key = {
  readonly " $data"?: TaskSolutionVideoUpdateDialog_taskSolutionVideo$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskSolutionVideoUpdateDialog_taskSolutionVideo">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskSolutionVideoUpdateDialog_taskSolutionVideo",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "modified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskVideo",
      "kind": "LinkedField",
      "name": "taskVideo",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPremium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isRecommended",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoThumbnail",
      "kind": "LinkedField",
      "name": "thumbnailHorizontal",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoThumbnail",
      "kind": "LinkedField",
      "name": "thumbnailVertical",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "TaskSolutionVideo",
  "abstractKey": null
};
})();

(node as any).hash = "95650022696c006f6a703bcc62d335e1";

export default node;
