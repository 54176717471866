/**
 * @generated SignedSource<<2530cabe3b361b348aaa0c0a3bf46ba0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EbookSearchOverlayTokenField_ebooksQuery$variables = {};
export type EbookSearchOverlayTokenField_ebooksQuery$data = {
  readonly ebooks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
      };
    }>;
  };
};
export type EbookSearchOverlayTokenField_ebooksQuery = {
  response: EbookSearchOverlayTokenField_ebooksQuery$data;
  variables: EbookSearchOverlayTokenField_ebooksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EbookConnection",
    "kind": "LinkedField",
    "name": "ebooks",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EbookEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Ebook",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EbookSearchOverlayTokenField_ebooksQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EbookSearchOverlayTokenField_ebooksQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4dfda09a7ec732f17fef9b1a9aaaa415",
    "id": null,
    "metadata": {},
    "name": "EbookSearchOverlayTokenField_ebooksQuery",
    "operationKind": "query",
    "text": "query EbookSearchOverlayTokenField_ebooksQuery {\n  ebooks {\n    edges {\n      node {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0e2015b0fff21c6a1a07aeea6fd374f3";

export default node;
