import { graphql } from 'react-relay';

import { BookBookScrapPaginator_book$key } from '../../../relay/__generated__/BookBookScrapPaginator_book.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const BookBookScrapPaginator_book = graphql`
  fragment BookBookScrapPaginator_book on Book
  @argumentDefinitions(first: { type: Int }, after: { type: String })
  @refetchable(queryName: "BookBookScrapPaginatorRefreshQuery") {
    bookScraps(first: $first, after: $after) @connection(key: "BookBookScrapPaginator_book_bookScraps") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          actions
          problemBookPage
          solutionBookPage
          problemNumber
        }
      }
      ...BookScrapConnectionDataTable_bookScrapConnection
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<BookBookScrapPaginator_book$key>, 'fragment'>;

const BookBookScrapPaginator = ({ ...props }: Props) => {
  return <FragmentPaginator fragment={BookBookScrapPaginator_book} {...props} />;
};

export default BookBookScrapPaginator;
