import { useCallback, useState } from 'react';
import { useLazyLoadQuery as baseUseLazyLoadQuery } from 'react-relay';
import { OperationType } from 'relay-runtime';

const useLazyLoadQuery = <TQuery extends OperationType>(...arg: Parameters<typeof baseUseLazyLoadQuery<TQuery>>) => {
  const [gqlQuery, variables, baseOptions] = arg;
  const [options, setOptions] = useState<Parameters<typeof baseUseLazyLoadQuery>[2]>(baseOptions);
  const refresh = useCallback(() => {
    setOptions((prev) => {
      return {
        ...prev,
        fetchKey: ((prev?.fetchKey ?? 0) as number) + 1,
        fetchPolicy: 'store-and-network',
      };
    });
  }, [setOptions]);
  const data = baseUseLazyLoadQuery<TQuery>(gqlQuery, variables, options);

  return [data, refresh] as const;
};

export default useLazyLoadQuery;
