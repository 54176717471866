/**
 * @generated SignedSource<<b1e976bbc70f1ffd99b54dd86b710ee7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskLabelingCountEnum = "one" | "two" | "%future added value";
export type TaskProblemAnswerTypeEnum = "objective" | "subjective" | "%future added value";
export type TaskProblemCreationTypeEnum = "first" | "original" | "second" | "third" | "%future added value";
export type TaskProblemUpdateInput = {
  answer?: string | null;
  answerChoiceCount?: number | null;
  answerType?: TaskProblemAnswerTypeEnum | null;
  creationType?: TaskProblemCreationTypeEnum | null;
  imageRequests: ReadonlyArray<ImageRequestInput>;
  labelingCount?: TaskLabelingCountEnum | null;
  problem?: EditorInput | null;
  solution?: EditorInput | null;
  task: string;
  taskProblem: string;
  unitDs: ReadonlyArray<string>;
};
export type ImageRequestInput = {
  description: string;
  file: Upload;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type EditorInput = {
  text: string;
  textHtml?: string | null;
};
export type TaskProblemUpdateDialog_taskProblemUpdateMutation$variables = {
  input: TaskProblemUpdateInput;
};
export type TaskProblemUpdateDialog_taskProblemUpdateMutation$data = {
  readonly taskProblemUpdate: {
    readonly answer: string | null;
    readonly answerChoiceCount: number | null;
    readonly answerType: TaskProblemAnswerTypeEnum | null;
    readonly creationType: TaskProblemCreationTypeEnum | null;
    readonly id: string;
    readonly problem: string | null;
    readonly solution: string | null;
    readonly task: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"TaskProblemUpdateDialog_task">;
    };
  };
};
export type TaskProblemUpdateDialog_taskProblemUpdateMutation = {
  response: TaskProblemUpdateDialog_taskProblemUpdateMutation$data;
  variables: TaskProblemUpdateDialog_taskProblemUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problem",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "solution",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answer",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "answerChoiceCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "creationType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectUrl",
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  (v9/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskProblemUpdateDialog_taskProblemUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TaskProblem",
        "kind": "LinkedField",
        "name": "taskProblemUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TaskProblemUpdateDialog_task"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskProblemUpdateDialog_taskProblemUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TaskProblem",
        "kind": "LinkedField",
        "name": "taskProblemUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sequence",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskProblem",
                "kind": "LinkedField",
                "name": "problem",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "problemType",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Scrap",
                "kind": "LinkedField",
                "name": "scrap",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "problems",
                    "plural": true,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "solutions",
                    "plural": true,
                    "selections": (v10/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskImageRequestConnection",
                "kind": "LinkedField",
                "name": "imageRequests",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskImageRequestEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaskImageRequest",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "key",
                                "storageKey": null
                              },
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "size",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "order",
                    "value": {
                      "order": "DESC"
                    }
                  }
                ],
                "concreteType": "UnitD",
                "kind": "LinkedField",
                "name": "unitDs",
                "plural": true,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "labelingCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Unit",
                "kind": "LinkedField",
                "name": "unit",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fa50f233a8036345d61fac0a78153af6",
    "id": null,
    "metadata": {},
    "name": "TaskProblemUpdateDialog_taskProblemUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation TaskProblemUpdateDialog_taskProblemUpdateMutation(\n  $input: TaskProblemUpdateInput!\n) {\n  taskProblemUpdate(input: $input) {\n    id\n    problem\n    solution\n    answerType\n    answer\n    answerChoiceCount\n    creationType\n    task {\n      id\n      ...TaskProblemUpdateDialog_task\n    }\n  }\n}\n\nfragment TaskProblemUpdateDialog_task on Task {\n  id\n  title\n  sequence\n  problem {\n    id\n    problem\n    solution\n    problemType\n    creationType\n    answer\n    answerChoiceCount\n    answerType\n  }\n  scrap {\n    id\n    problems {\n      id\n      objectUrl\n    }\n    solutions {\n      id\n      objectUrl\n    }\n  }\n  imageRequests {\n    edges {\n      node {\n        id\n        image {\n          key\n          objectUrl\n          size\n          id\n        }\n        description\n      }\n    }\n  }\n  unitDs(order: {order: DESC}) {\n    id\n  }\n  labelingCount\n  unit {\n    id\n    schoolType\n  }\n}\n"
  }
};
})();

(node as any).hash = "fdafd111d2b74296d536d21371ebe51e";

export default node;
