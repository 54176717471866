/**
 * @generated SignedSource<<4d07b8ee6f015bc31a39ca0c39d5b94d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GeneratedProblemActionEnum = "generated_problem_activate" | "generated_problem_deactivate" | "%future added value";
export type GeneratedProblemStatusEnum = "active" | "inactive" | "%future added value";
export type GeneratedProblemActivateInput = {
  id: string;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation$variables = {
  input: GeneratedProblemActivateInput;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation$data = {
  readonly generatedProblemActivate: {
    readonly actions: ReadonlyArray<GeneratedProblemActionEnum>;
    readonly id: string;
    readonly status: GeneratedProblemStatusEnum;
  };
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation = {
  response: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation$data;
  variables: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GeneratedProblem",
    "kind": "LinkedField",
    "name": "generatedProblemActivate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "actions",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5cb2b3a7910215d24830276088d7aebf",
    "id": null,
    "metadata": {},
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation",
    "operationKind": "mutation",
    "text": "mutation ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation(\n  $input: GeneratedProblemActivateInput!\n) {\n  generatedProblemActivate(input: $input) {\n    id\n    status\n    actions\n  }\n}\n"
  }
};
})();

(node as any).hash = "73967dea8d3bee693c172fee5501ab94";

export default node;
