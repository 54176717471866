import { Space } from '@teamturing/react-kit';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { BillSendMessageDialog_bill$key } from '../../../relay/__generated__/BillSendMessageDialog_bill.graphql';
import { BillSendMessageDialog_billSendMessageMutation } from '../../../relay/__generated__/BillSendMessageDialog_billSendMessageMutation.graphql';
import { formatMobilePhoneNumber } from '../../../utils/phoneNumber';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextInput from '../../core/TextInput';
import View from '../../core/View';

const BillSendMessageDialog_bill = graphql`
  fragment BillSendMessageDialog_bill on Bill {
    id
    purchaserNumber
  }
`;

type Props = { bill: BillSendMessageDialog_bill$key } & DialogProps &
  Pick<MutationFormikProps<BillSendMessageDialog_billSendMessageMutation>, 'config'>;

const BillSendMessageDialog = ({ bill, config, ...props }: Props) => {
  const { id, purchaserNumber } = useFragment(BillSendMessageDialog_bill, bill);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>문자 보내기</Text>
      </Dialog.Header>
      <MutationFormik<BillSendMessageDialog_billSendMessageMutation>
        mutation={graphql`
          mutation BillSendMessageDialog_billSendMessageMutation($input: BillSendSmsInput!) {
            billSendMessage(input: $input) {
              id
            }
          }
        `}
        initialValues={{ bill: id }}
        config={config}
      >
        {({ submitForm }) => (
          <>
            <Dialog.Body>
              <FormLayout>
                <View>
                  <Text fontSize={1}>휴대폰 번호 *</Text>
                  <Space mt={1}>
                    <TextInput block disabled size={'large'} value={formatMobilePhoneNumber(purchaserNumber)} />
                  </Space>
                </View>
                <NonFieldError />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button onClick={() => submitForm()} variant={'primary'}>
                보내기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default BillSendMessageDialog;
export type { Props as BillSendMessageDialogProps };
