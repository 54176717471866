import { graphql, useFragment } from 'react-relay';

import {
  DeliveryOrderConnectionDataTable_deliveryOrderConnection$data,
  DeliveryOrderConnectionDataTable_deliveryOrderConnection$key,
} from '../../../relay/__generated__/DeliveryOrderConnectionDataTable_deliveryOrderConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import DeliveryOrderStatusLabel from '../DeliveryOrderStatusLabel';

const DeliveryOrderConnectionDataTable_deliveryOrderConnection = graphql`
  fragment DeliveryOrderConnectionDataTable_deliveryOrderConnection on DeliveryOrderConnection {
    edges {
      node {
        id
        sequence
        ...DeliveryOrderStatusLabel_deliveryOrder
        stuff {
          id
          title
        }
        created
        createdBy {
          id
          ...UserAvatarText_user
        }
        type
        deliveryWaybill {
          waybillNumber
        }
        deliveryAddress {
          recipientName
          recipientNumber
          address
          addressDetail
        }
        delivered
        deliveredBy {
          id
          ...UserAvatarText_user
        }
      }
    }
  }
`;

type Props = {
  deliveryOrderConnection: DeliveryOrderConnectionDataTable_deliveryOrderConnection$key;
} & Omit<
  DataTableProps<DeliveryOrderConnectionDataTable_deliveryOrderConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const DeliveryOrderConnectionDataTable = ({ deliveryOrderConnection, ...props }: Props) => {
  const { edges } = useFragment(DeliveryOrderConnectionDataTable_deliveryOrderConnection, deliveryOrderConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (deliveryOrder) => <DeliveryOrderStatusLabel deliveryOrder={deliveryOrder} />,
          width: 100,
          align: 'center',
        },
        {
          field: 'sequence',
          title: 'ID',
          renderValue: ({ sequence }) => <Text fontSize={1}>{sequence}</Text>,
          width: 80,
        },
        {
          field: 'type',
          title: '타입',
          renderValue: ({ type }) => <EnumPair typename={'DeliveryOrderTypeEnum'}>{type}</EnumPair>,
          width: 80,
        },
        {
          field: 'stuff',
          title: '물품명',
          renderValue: ({ stuff }) => <Text fontSize={1}>{stuff.title}</Text>,
          width: 200,
        },
        {
          field: 'recipientName',
          title: '수령자',
          renderValue: ({ deliveryAddress }) => <Text fontSize={1}>{deliveryAddress.recipientName}</Text>,
          width: 120,
        },
        {
          field: 'recipientNumber',
          title: '전화번호',
          renderValue: ({ deliveryAddress }) => <Text fontSize={1}>{deliveryAddress.recipientNumber}</Text>,
          width: 120,
        },
        {
          field: 'address',
          title: '주소',
          renderValue: ({ deliveryAddress }) => {
            const address = `${deliveryAddress.address} ${deliveryAddress.addressDetail}`;
            return <Text fontSize={1}>{address}</Text>;
          },
          width: 200,
        },
        {
          field: 'deliveryWaybill.waybillNumber',
          title: '운송장번호',
          renderValue: ({ deliveryWaybill }) => <Text fontSize={1}>{deliveryWaybill?.waybillNumber || '-'}</Text>,
          width: 150,
        },
        {
          field: 'created',
          title: '요청일',
          renderValue: ({ created }) => <DateText fontSize={1}>{created}</DateText>,
          width: 160,
        },
        {
          field: 'createdBy',
          title: '요청자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 92,
        },
        {
          field: 'delivered',
          title: '배송일',
          renderValue: ({ delivered }) => (delivered ? <DateText>{delivered}</DateText> : ' - '),
          width: 160,
        },
        {
          field: 'deliveredBy',
          title: '배송자',
          renderValue: ({ deliveredBy }) => (deliveredBy ? <UserAvatarText user={deliveredBy} /> : ' - '),
          width: 92,
        },
      ]}
      {...props}
    />
  );
};

export default DeliveryOrderConnectionDataTable;
