/**
 * @generated SignedSource<<23a14909be656f9f2131a4b11a24ca7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskCreateInput = {
  description?: string | null;
  title: string;
  unit: string;
};
export type TaskCreateDialog_taskCreateMutation$variables = {
  input: TaskCreateInput;
};
export type TaskCreateDialog_taskCreateMutation$data = {
  readonly taskCreate: {
    readonly id: string;
  };
};
export type TaskCreateDialog_taskCreateMutation = {
  response: TaskCreateDialog_taskCreateMutation$data;
  variables: TaskCreateDialog_taskCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Task",
    "kind": "LinkedField",
    "name": "taskCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskCreateDialog_taskCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskCreateDialog_taskCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "62f209c2580d4a2fa980d0dd814f221d",
    "id": null,
    "metadata": {},
    "name": "TaskCreateDialog_taskCreateMutation",
    "operationKind": "mutation",
    "text": "mutation TaskCreateDialog_taskCreateMutation(\n  $input: TaskCreateInput!\n) {\n  taskCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d22c102ff11430d350af7d8ebb2de522";

export default node;
