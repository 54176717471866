import useTabHandler, { UseTabHandlerOptions } from '../../../hooks/useTabHandler';

type Props = {
  children: (args: ReturnType<typeof useTabHandler>) => React.ReactNode;
} & UseTabHandlerOptions;

const TabHandler = ({ children, ...props }: Props) => {
  const { selectedIndex, handleSelect } = useTabHandler(props);

  return children({ selectedIndex, handleSelect }) as JSX.Element;
};

export default TabHandler;
