import { graphql } from 'react-relay';

import { WorkbookWorkbookHistoryPaginator_workbook$key } from '../../../relay/__generated__/WorkbookWorkbookHistoryPaginator_workbook.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const WorkbookWorkbookHistoryPaginator_workbook = graphql`
  fragment WorkbookWorkbookHistoryPaginator_workbook on Workbook
  @argumentDefinitions(first: { type: Int, defaultValue: 10 }, after: { type: String })
  @refetchable(queryName: "WorkbookWorkbookHistoryPaginatorRefreshQuery") {
    histories(first: $first, after: $after) @connection(key: "WorkbookWorkbookHistoryPaginator_workbook_histories") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          type
          ...WorkbookHistoryTimelineItem_workbookHistory
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<WorkbookWorkbookHistoryPaginator_workbook$key>, 'fragment'>;

const WorkbookWorkbookHistoryPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={WorkbookWorkbookHistoryPaginator_workbook} {...props} />
);

export default WorkbookWorkbookHistoryPaginator;
