/**
 * @generated SignedSource<<2e05edbaeaf0a642031ae6d01ff99b8c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CodepushVersionStatusEnum = "production" | "staging" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CodepushVersionStatusLabel_codepushVersion$data = {
  readonly status: CodepushVersionStatusEnum;
  readonly " $fragmentType": "CodepushVersionStatusLabel_codepushVersion";
};
export type CodepushVersionStatusLabel_codepushVersion$key = {
  readonly " $data"?: CodepushVersionStatusLabel_codepushVersion$data;
  readonly " $fragmentSpreads": FragmentRefs<"CodepushVersionStatusLabel_codepushVersion">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodepushVersionStatusLabel_codepushVersion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "CodepushVersion",
  "abstractKey": null
};

(node as any).hash = "2c4f5e4cc5a447b99c39b697e6d63e8f";

export default node;
