import { graphql } from 'react-relay';

import { UnitBPaginator_query$key } from '../../../relay/__generated__/UnitBPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const UnitBPaginator_query = graphql`
  fragment UnitBPaginator_query on Query
  @argumentDefinitions(
    filters: { type: UnitBFilter }
    order: { type: UnitBOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "UnitBPaginatorRefreshQuery") {
    unitBs(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "UnitBPaginator_query_unitBs") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<UnitBPaginator_query$key>, 'fragment'>;

const UnitBPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={UnitBPaginator_query} {...props} />;

export default UnitBPaginator;
