import { CheckIcon, KebabHorizontalIcon, PencilIcon, TrashIcon, VersionsIcon } from '@primer/octicons-react';
import { formatKatexToHtmlString } from '@teamturing/katex-utils';
import { useRouter } from 'next/router';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { QuizActionsMutationButtonStack_quiz$key } from '../../../relay/__generated__/QuizActionsMutationButtonStack_quiz.graphql';
import { QuizActionsMutationButtonStack_quizDeleteMutation } from '../../../relay/__generated__/QuizActionsMutationButtonStack_quizDeleteMutation.graphql';
import ActionList from '../../core/ActionList';
import ActionMenu from '../../core/ActionMenu';
import DialogButton from '../../core/DialogButton';
import IconButton from '../../core/IconButton';
import MutationConfirmButton from '../../core/MutationConfirmButton';
import Stack from '../../core/Stack';
import QuizCreateDialog from '../QuizCreateDialog';
import QuizUpdateDialog from '../QuizUpdateDialog';

const QuizActionsMutationButtonStack_quiz = graphql`
  fragment QuizActionsMutationButtonStack_quiz on Quiz {
    id
    actions
    ...QuizUpdateDialog_quiz
    description
    unitD {
      id
    }
    answer
    problem
    solution
    tip
  }
`;

type Props = { quiz: QuizActionsMutationButtonStack_quiz$key };

const QuizActionsMutationButtonStack = ({ quiz: quizReference }: Props) => {
  const quiz = useFragment(QuizActionsMutationButtonStack_quiz, quizReference);
  const { id, description, answer, unitD, problem, solution, tip, actions } = quiz;
  const router = useRouter();
  const { toast } = useToast();

  return (
    <Stack gapX={2}>
      <Stack.Item>
        <DialogButton
          leadingIcon={VersionsIcon}
          size={'large'}
          renderDialog={({ isOpen, closeDialog }) => (
            <QuizCreateDialog
              isOpen={isOpen}
              onDismiss={closeDialog}
              wide
              config={{
                onCompleted: ({ quizCreate: { id } }) => {
                  toast('OX 퀴즈 복제가 완료됐어요', 'success');
                  closeDialog();
                  router.push(`/quiz/${id}`);
                },
                onError: () => {
                  toast('다시 복제해 주세요', 'error');
                },
              }}
              initialValues={{
                description,
                answer,
                unitD,
                problem: { text: problem, textHtml: formatKatexToHtmlString(problem) },
                solution: { text: solution, textHtml: formatKatexToHtmlString(solution) },
                tip: { text: tip, textHtml: formatKatexToHtmlString(tip) },
              }}
            />
          )}
        >
          복제하기
        </DialogButton>
      </Stack.Item>
      {actions.includes('quiz_review') ? (
        <Stack.Item>
          <MutationConfirmButton
            mutation={graphql`
              mutation QuizActionsMutationButtonStack_quizReviewMutation($input: QuizReviewInput!) {
                quizReview(input: $input) {
                  id
                  actions
                  ...QuizStatusLabel_quiz
                  ...QuizQuizHistoryPaginator_quiz
                }
              }
            `}
            input={{ id }}
            size={'large'}
            leadingIcon={CheckIcon}
            variant={'outline'}
            message={'OX 퀴즈 정보 확인 후 출시해 주세요. 출시할까요?'}
          >
            출시하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('quiz_update') ? (
        <Stack.Item>
          <DialogButton
            variant={'primary'}
            leadingIcon={PencilIcon}
            size={'large'}
            renderDialog={({ isOpen, closeDialog }) => (
              <QuizUpdateDialog
                quiz={quiz}
                isOpen={isOpen}
                onDismiss={closeDialog}
                wide
                config={{
                  onCompleted: () => {
                    toast('OX 퀴즈 수정이 완료됐어요', 'success');
                    closeDialog();
                  },
                  onError: () => {
                    toast('다시 수정해 주세요', 'error');
                  },
                }}
              />
            )}
          >
            수정하기
          </DialogButton>
        </Stack.Item>
      ) : null}
      {actions.includes('quiz_delete') ? (
        <Stack.Item>
          <ActionMenu>
            <ActionMenu.Anchor>
              <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
            </ActionMenu.Anchor>
            <ActionMenu.Overlay>
              <ActionList>
                <ActionList.MutationItem<QuizActionsMutationButtonStack_quizDeleteMutation>
                  mutation={graphql`
                    mutation QuizActionsMutationButtonStack_quizDeleteMutation($input: QuizDeleteInput!) {
                      quizDelete(input: $input) {
                        id @deleteRecord
                      }
                    }
                  `}
                  input={{ id }}
                  variant={'danger'}
                  config={{
                    onCompleted: () => {
                      router.replace('/quiz').then(() => toast('OX 퀴즈 삭제가 완료됐어요', 'success'));
                    },
                  }}
                  message={'정말 삭제할까요?'}
                >
                  <ActionList.LeadingVisual>
                    <TrashIcon />
                  </ActionList.LeadingVisual>
                  삭제하기
                </ActionList.MutationItem>
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export default QuizActionsMutationButtonStack;
