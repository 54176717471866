/**
 * @generated SignedSource<<04570ec218a688ab8475fe757b2a3f77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type QuizSetStatusEnum = "labeling" | "published" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuizSetStatusLabel_quizSet$data = {
  readonly status: QuizSetStatusEnum;
  readonly " $fragmentType": "QuizSetStatusLabel_quizSet";
};
export type QuizSetStatusLabel_quizSet$key = {
  readonly " $data"?: QuizSetStatusLabel_quizSet$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizSetStatusLabel_quizSet">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizSetStatusLabel_quizSet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "QuizSet",
  "abstractKey": null
};

(node as any).hash = "4080c1b131778840afcf4a6b1813f8cd";

export default node;
