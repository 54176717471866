import { graphql } from 'react-relay';

import { ScrapSourceScrapPaginator_scrapSource$key } from '../../../relay/__generated__/ScrapSourceScrapPaginator_scrapSource.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const ScrapSourceScrapPaginator_scrapSource = graphql`
  fragment ScrapSourceScrapPaginator_scrapSource on ScrapSource
  @argumentDefinitions(first: { type: Int }, after: { type: String })
  @refetchable(queryName: "ScrapSourceScrapPaginatorRefreshQuery") {
    scraps(first: $first, after: $after) @connection(key: "ScrapSourceScrapPaginator_scrapSource_scraps") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          actions
          ...ScrapItem_scrap
        }
      }
      ...ScrapSourceScrapConnectionDataTable_scrapConnection
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<ScrapSourceScrapPaginator_scrapSource$key>, 'fragment'>;

const ScrapSourceScrapPaginator = ({ ...props }: Props) => {
  return <FragmentPaginator fragment={ScrapSourceScrapPaginator_scrapSource} {...props} />;
};

export default ScrapSourceScrapPaginator;
