import { graphql, useFragment } from 'react-relay';

import { TaskHintFeedbackDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/TaskHintFeedbackDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const TaskHintFeedbackDifferenceCounterLabel_difference = graphql`
  fragment TaskHintFeedbackDifferenceCounterLabel_difference on Difference {
    ... on TaskHintFeedbackDifference {
      hint {
        __typename
      }
      specialAnswer {
        __typename
      }
      special {
        __typename
      }
      general {
        __typename
      }
      incorrect {
        __typename
      }
      correct {
        __typename
      }
    }
  }
`;

type Props = { difference: TaskHintFeedbackDifferenceCounterLabel_difference$key } & CounterLabelProps;

const TaskHintFeedbackDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { hint, specialAnswer, special, general, incorrect, correct } = useFragment(
    TaskHintFeedbackDifferenceCounterLabel_difference,
    difference,
  );

  const differenceCount = [hint, specialAnswer, special, general, incorrect, correct].filter(
    (diff) => !isNullable(diff),
  ).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default TaskHintFeedbackDifferenceCounterLabel;
