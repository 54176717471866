import { useTheme } from '@primer/react';
import { PropsWithChildren } from 'react';

import { isArray } from '../../../utils/is';
import View, { ViewProps } from '../View';

import Item from './Item';

type Props = {
  gapX?: ViewProps['marginLeft'];
  gapY?: ViewProps['marginTop'];
  wrap?: boolean;
  alignItems?: ViewProps['alignItems'];
};

const Stack = ({ gapX = 0, gapY = 0, wrap, alignItems = 'center', children }: PropsWithChildren<Props>) => {
  const { theme } = useTheme();
  const space = theme?.['space'].map((s: string) => parseInt(s));

  return (
    <View
      sx={{
        'display': 'flex',
        'flexDirection': 'row',
        'alignItems': alignItems,
        '& > *': {
          paddingX: isArray(gapX) ? gapX.map((gx) => `${space[gx as number] / 2}px`) : `${space[gapX as number] / 2}px`,
          marginTop: isArray(gapY) ? gapY.map((gy) => gy) : (gapY as number),
        },
        'marginX': isArray(gapX) ? gapX.map((g) => `${-space[g as number] / 2}px`) : `${-space[gapX as number] / 2}px`,
        'marginTop': isArray(gapY) ? gapY.map((gy) => -(gy as number)) : -(gapY as number),
        'flexWrap': wrap ? 'wrap' : 'nowrap',
      }}
    >
      {children}
    </View>
  );
};

export default Object.assign(Stack, { Item });
export type { Props as StackProps };
