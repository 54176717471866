import { isValid } from 'date-fns';

const isFunction = (value: any): value is Function => typeof value === 'function';
const isArray = <T>(value: any): value is Array<T> => Array.isArray(value);
const isUrl = (s: string) => {
  const regexForUrl =
    /(?:(?:https?|ftp):\/\/|\b(?:[a-z\d]+\.))(?:(?:[^\s()<>]+|\((?:[^\s()<>]+|(?:\([^\s()<>]+\)))?\))+(?:\((?:[^\s()<>]+|(?:\(?:[^\s()<>]+\)))?\)|[^\s`!()[\]{};:'".,<>?«»“”‘’]))?/;

  return !!regexForUrl.test(s);
};
const isNullable = (value: any): value is null | undefined => value === null || value === undefined;
const isValidJSON = (value: string) => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};
const isValidDate = isValid;

export { isFunction, isArray, isUrl, isNullable, isValidJSON, isValidDate };
