/**
 * @generated SignedSource<<0cada222cab96f112dc97cbc2ee12fb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationInvoiceCreateDialog_matOrganization$data = {
  readonly id: string;
  readonly " $fragmentType": "MATOrganizationInvoiceCreateDialog_matOrganization";
};
export type MATOrganizationInvoiceCreateDialog_matOrganization$key = {
  readonly " $data"?: MATOrganizationInvoiceCreateDialog_matOrganization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATOrganizationInvoiceCreateDialog_matOrganization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATOrganizationInvoiceCreateDialog_matOrganization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MATOrganization",
  "abstractKey": null
};

(node as any).hash = "eb4a4b410dacc40d9e6e1c80eac2424c";

export default node;
