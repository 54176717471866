/**
 * @generated SignedSource<<c6d3bcbbea270e5a961b82ab1e885f7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkbookTasksAddInput = {
  id: string;
  taskIds: ReadonlyArray<string>;
};
export type WorkbookTasksAddDialog_workbookTasksAddMutation$variables = {
  input: WorkbookTasksAddInput;
};
export type WorkbookTasksAddDialog_workbookTasksAddMutation$data = {
  readonly workbookTasksAdd: {
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"WorkbookStatusLabel_workbook" | "WorkbookTasksAddDialog_workbook">;
  };
};
export type WorkbookTasksAddDialog_workbookTasksAddMutation = {
  response: WorkbookTasksAddDialog_workbookTasksAddMutation$data;
  variables: WorkbookTasksAddDialog_workbookTasksAddMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookTasksAddDialog_workbookTasksAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookTasksAdd",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookStatusLabel_workbook"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookTasksAddDialog_workbook"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookTasksAddDialog_workbookTasksAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookTasksAdd",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grade",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskConnection",
            "kind": "LinkedField",
            "name": "tasks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sequence",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnitD",
                        "kind": "LinkedField",
                        "name": "unitD",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitATitle",
                            "storageKey": null
                          },
                          {
                            "alias": "unitDTitle",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "difficulty",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af03a06f14b264f672f49bd9f8199b0c",
    "id": null,
    "metadata": {},
    "name": "WorkbookTasksAddDialog_workbookTasksAddMutation",
    "operationKind": "mutation",
    "text": "mutation WorkbookTasksAddDialog_workbookTasksAddMutation(\n  $input: WorkbookTasksAddInput!\n) {\n  workbookTasksAdd(input: $input) {\n    id\n    modifiedBy {\n      ...UserAvatarText_user\n      id\n    }\n    modified\n    ...WorkbookStatusLabel_workbook\n    ...WorkbookTasksAddDialog_workbook\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n\nfragment WorkbookStatusLabel_workbook on Workbook {\n  status\n}\n\nfragment WorkbookTaskConnectionDataTable_taskConnection on TaskConnection {\n  edges {\n    node {\n      id\n      sequence\n      unitD {\n        unitATitle\n        unitDTitle: title\n        id\n      }\n      difficulty\n    }\n  }\n}\n\nfragment WorkbookTasksAddDialog_workbook on Workbook {\n  id\n  grade\n  tasks {\n    totalCount\n    edges {\n      node {\n        id\n      }\n    }\n    ...WorkbookTaskConnectionDataTable_taskConnection\n  }\n}\n"
  }
};
})();

(node as any).hash = "1a31c15e6fb7a8f4d07ae536b8ff7657";

export default node;
