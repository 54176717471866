/**
 * @generated SignedSource<<e97874bfb70108b0e9c15f112b832e1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolExamSourceHistoryTypeEnum = "publish" | "scrap" | "upload" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly type: SchoolExamSourceHistoryTypeEnum;
  readonly " $fragmentType": "SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory";
};
export type SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory$key = {
  readonly " $data"?: SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "SchoolExamSourceHistory",
  "abstractKey": null
};

(node as any).hash = "34a4f1c61aa1423f4f8243be51d6c3b9";

export default node;
