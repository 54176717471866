import {
  format as dateFnsFormat,
  formatDistanceToNow,
  formatDistanceToNowStrict as dateFnsFormatDistanceToNowStrict,
  formatISO as dateFnsFormatISO,
  endOfDay,
  startOfDay,
  isValid,
} from 'date-fns';
import { ko } from 'date-fns/locale';
import { isInteger } from 'lodash-es';

const formatAgo = (datetime: string) => formatDistanceToNow(new Date(datetime), { addSuffix: true, locale: ko });
const formatDistanceToNowStrict = (
  datetime: string,
  options?: {
    addSuffix?: boolean;
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
    roundingMethod?: 'floor' | 'ceil' | 'round';
  },
) => dateFnsFormatDistanceToNowStrict(new Date(datetime), { ...options, locale: ko });
const formatDate = (datetime: string, format: string) => dateFnsFormat(new Date(datetime), format, { locale: ko });
const formatISO = (date: number | Date) => dateFnsFormatISO(date);

const isValidDateString = (date: string) => isValid(new Date(date));

const isValid6Or8DigitDateString = (date: string) =>
  isInteger(Number(date)) && (date.length === 6 || date.length === 8);

const getDateFrom6Or8DigitString = (date: string) => {
  if (!isValid6Or8DigitDateString(date)) return new Date(0);

  const _date = date.length === 6 ? `20${date}` : date;

  const year = _date.slice(0, 4);
  const month = _date.slice(4, 6);
  const day = _date.slice(6, 8);

  return new Date(Number(year), Number(month) - 1, Number(day));
};

export {
  formatAgo,
  formatDistanceToNowStrict,
  formatDate,
  formatISO,
  endOfDay,
  startOfDay,
  isValidDateString,
  isValid6Or8DigitDateString,
  getDateFrom6Or8DigitString,
};
