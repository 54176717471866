import { RepoPushIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import Checkbox from '../../components/core/Checkbox';
import CheckboxGroupField from '../../components/core/CheckboxGroupField';
import { RowWrapper } from '../../components/core/DataTable';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import SchoolPaginatorSearchOverlayTokenField from '../../components/school/SchoolPaginatorSearchOverlayTokenField';
import SchoolExamSourceAssignDialog from '../../components/schoolExamSource/SchoolExamSourceAssignDialog';
import SchoolExamSourceConnectionDataTable from '../../components/schoolExamSource/SchoolExamSourceConnectionDataTable';
import SchoolExamSourceImageCsvExportDialog from '../../components/schoolExamSource/SchoolExamSourceImageCsvExportDialog';
import SchoolExamSourceImageExportDialog from '../../components/schoolExamSource/SchoolExamSourceImageExportDialog';
import SchoolExamSourceWindowPaginator from '../../components/schoolExamSource/SchoolExamSourceWindowPaginator';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { schoolExamSource_meQuery } from '../../relay/__generated__/schoolExamSource_meQuery.graphql';
import {
  SchoolRegionEnum,
  ExamTypeEnum,
  ExamYearEnum,
  SchoolExamSourceStatusEnum,
  schoolExamSource_schoolExamSourcesQuery,
  SchoolYearEnum,
} from '../../relay/__generated__/schoolExamSource_schoolExamSourcesQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { parseOrdering } from '../../utils/order';
import { NextPage } from '../_app';

import SchoolExamSourceId from './[schoolExamSourceId]';

const schoolExamSourcesForSchoolExamSource = graphql`
  query schoolExamSource_schoolExamSourcesQuery(
    $filters: SchoolExamSourceFilter
    $order: SchoolExamSourceOrder
    $page: Int
    $pageSize: Int
  ) {
    ...SchoolExamSourceWindowPaginator_query
      @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

const meForSchoolExamSource = graphql`
  query schoolExamSource_meQuery {
    me {
      id
      permissions {
        id
        title
      }
    }
  }
`;

type Props = {};

const SchoolExamSource: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const [{ me }] = useLazyLoadQuery<schoolExamSource_meQuery>(meForSchoolExamSource, {});

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_In: { type: 'string', multiple: true },
    completedById_In: { type: 'string', multiple: true },
    completedBy_Exists_In: { type: 'boolean', multiple: true },
    assignedToId_In: { type: 'string', multiple: true },
    assignedTo_Exists_In: { type: 'boolean', multiple: true },
    region_In: { type: 'string', multiple: true },
    schoolId_In: { type: 'string', multiple: true },
    schoolId_Not_In: { type: 'string', multiple: true },
    schoolYear_In: { type: 'string', multiple: true },
    examYear_In: { type: 'string', multiple: true },
    examType_In: { type: 'string', multiple: true },
    innerPdf_Exists_In: { type: 'boolean', multiple: true },
    order: { type: 'string' },
    page: { type: 'number' },
  });

  const {
    search,
    completedById_In,
    completedBy_Exists_In,
    assignedToId_In,
    assignedTo_Exists_In,
    schoolId_In,
    schoolId_Not_In,
    region_In,
    status_In,
    schoolYear_In,
    examYear_In,
    examType_In,
    innerPdf_Exists_In,
    order,
    page,
  } = initialValues;

  return (
    <View>
      <Head siteTitle={'내신 기출'} />
      <ErrorBoundary>
        <QueryFormik<schoolExamSource_schoolExamSourcesQuery>
          query={schoolExamSourcesForSchoolExamSource}
          staticVariables={{ pageSize: 50 }}
          initialValues={{
            filters: {
              search,
              schoolId_In,
              schoolId_Not_In,
              completedById_In,
              completedBy_Exists_In,
              assignedToId_In,
              assignedTo_Exists_In,
              region_In: region_In as SchoolRegionEnum[],
              status_In: status_In as SchoolExamSourceStatusEnum[],
              schoolYear_In: schoolYear_In as SchoolYearEnum[],
              examYear_In: examYear_In as ExamYearEnum[],
              examType_In: examType_In as ExamTypeEnum[],
              innerPdf_Exists_In,
            },
            order: order || '-school_Title',
            page,
          }}
          initialStatus={[]}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values, formikHelpers) => {
            formikHelpers.setStatus([]);
            setParsedUrlQuery({ ...values.filters, order }, { scroll: false });
          }}
          enableReinitialize
        >
          {(
            { values: { filters, order }, setFieldValue, resetForm, submitForm, status, setStatus },
            queryReference,
          ) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>내신 기출</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Stack gapX={2}>
                      {!!me.permissions.find(({ title }) => title === 'school_exam_source_export_image') ? (
                        <>
                          <Stack.Item>
                            <DialogButton
                              size={'large'}
                              renderDialog={({ isOpen, closeDialog }) => (
                                <SchoolExamSourceImageExportDialog isOpen={isOpen} onDismiss={closeDialog} full />
                              )}
                            >
                              이미지 추출하기
                            </DialogButton>
                          </Stack.Item>
                          <Stack.Item>
                            <DialogButton
                              size={'large'}
                              renderDialog={({ isOpen, closeDialog }) => (
                                <SchoolExamSourceImageCsvExportDialog
                                  isOpen={isOpen}
                                  onDismiss={closeDialog}
                                  full
                                  variables={{ filters }}
                                />
                              )}
                            >
                              이미지 리스트 csv 추출하기
                            </DialogButton>
                          </Stack.Item>
                        </>
                      ) : null}
                      {!!me.permissions.find(({ title }) => title === 'school_exam_source_assign') ? (
                        <Stack.Item>
                          <DialogButton
                            size={'large'}
                            variant={'primary'}
                            disabled={status.length === 0}
                            renderDialog={({ isOpen, closeDialog }) => (
                              <SchoolExamSourceAssignDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                schoolExamSources={status}
                                config={{
                                  onCompleted: () => {
                                    toast('배정 성공했어요', 'success');
                                    closeDialog();
                                    submitForm();
                                  },
                                  onError: () => {
                                    toast('배정 실패했어요', 'error');
                                  },
                                }}
                              />
                            )}
                          >
                            배정하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                    </Stack>
                  </Grid.Unit>
                </Grid>
                <Grid gapY={2}>
                  <Grid.Unit size={[1, 1, 'min']}>
                    <QueryFormik.FilterSearchTextField
                      typename={'SchoolExamSourceFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 'max']}>
                    <View sx={{ display: 'flex', justifyContent: ['start', 'start', 'end'] }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'SchoolExamSourceOrder'}
                            orders={['school_Title', 'examYear', 'completed']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={1} title={'상태'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'SchoolExamSourceStatusEnum'}
                        label={'상태'}
                        name={'filters.status_In'}
                        placeholder={'상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'완료자 유무'}>
                      <CheckboxGroupField
                        label={'완료자 유무'}
                        name={'filters.completedBy_Exists_In'}
                        options={[
                          { id: 'true', text: '있음' },
                          { id: 'false', text: '없음' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.completedBy_Exists_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.completedBy_Exists_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'완료자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'완료자'}
                        name={'filters.completedById_In'}
                        placeholder={'완료자 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'배정'}>
                      <CheckboxGroupField
                        label={'배정'}
                        name={'filters.assignedTo_Exists_In'}
                        options={[
                          { id: 'false', text: '배정 필요' },
                          { id: 'true', text: '배정 완료' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.assignedTo_Exists_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.assignedTo_Exists_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'작업자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'작업자'}
                        name={'filters.assignedToId_In'}
                        placeholder={'작업자 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'지역'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'SchoolRegionEnum'}
                        label={'지역'}
                        name={'filters.region_In'}
                        placeholder={'지역 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'학교'}>
                      <SchoolPaginatorSearchOverlayTokenField
                        label={'학교'}
                        name={'filters.schoolId_In'}
                        placeholder={'학교 선택'}
                        variables={{
                          filters: {
                            region_In: filters?.region_In,
                            schoolYear_In: filters?.schoolYear_In,
                          },
                          ...(order && Object.keys(parseOrdering(order)).includes('school_Title')
                            ? {
                                order: {
                                  title: parseOrdering(order)['school_Title'],
                                },
                              }
                            : {}),
                        }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'특정 학교 제외'}>
                      <SchoolPaginatorSearchOverlayTokenField
                        label={'특정 학교 제외'}
                        name={'filters.schoolId_Not_In'}
                        placeholder={'제외 학교 선택'}
                        variables={{
                          filters: {
                            region_In: filters?.region_In,
                            schoolYear_In: filters?.schoolYear_In,
                          },
                          ...(order && Object.keys(parseOrdering(order)).includes('school_Title')
                            ? {
                                order: {
                                  title: parseOrdering(order)['school_Title'],
                                },
                              }
                            : {}),
                        }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'연도'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'ExamYearEnum'}
                        label={'연도'}
                        name={'filters.examYear_In'}
                        placeholder={'연도 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'학년'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'SchoolYearEnum'}
                        label={'학년'}
                        name={'filters.schoolYear_In'}
                        placeholder={'학년 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'시험'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'ExamTypeEnum'}
                        label={'시험'}
                        name={'filters.examType_In'}
                        placeholder={'시험 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'내부 업로드'}>
                      <CheckboxGroupField
                        name={'filters.innerPdf_Exists_In'}
                        label={'내부 업로드'}
                        options={[
                          { id: 'true', text: 'O' },
                          { id: 'false', text: 'X' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.innerPdf_Exists_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.innerPdf_Exists_In',
                            selected.map((selectedValue) => (selectedValue === 'true' ? true : false)),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<schoolExamSource_schoolExamSourcesQuery>>
                        {(queryReference) => (
                          <SchoolExamSourceWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ schoolExamSources }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    {`총 ${numberWithCommas(schoolExamSources?.totalCount || 0)}개${
                                      status.length > 0 ? ` / ${status.length}개` : ''
                                    }`}
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <SchoolExamSourceConnectionDataTable
                                    schoolExamSourceConnection={schoolExamSources}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                    renderHead={(columns) => (
                                      <View
                                        as={'thead'}
                                        sx={{
                                          borderBottomWidth: 1,
                                          borderBottomStyle: 'solid',
                                          borderBottomColor: 'border.default',
                                          backgroundColor: 'canvas.subtle',
                                        }}
                                      >
                                        <View as={'tr'}>
                                          <View as={'th'} sx={{ padding: 2 }}>
                                            <Checkbox
                                              checked={status.length === schoolExamSources.edges.length}
                                              onChange={(e) => {
                                                setStatus(
                                                  e.target.checked
                                                    ? schoolExamSources.edges.map(({ node }) => node.id)
                                                    : [],
                                                );
                                              }}
                                            />
                                          </View>
                                          {columns.map(({ field, title, width }) => (
                                            <View
                                              key={field}
                                              as={'th'}
                                              sx={{
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                borderColor: 'border.default',
                                                minWidth: width,
                                                textAlign: 'start',
                                                padding: 2,
                                                fontWeight: 'bold',
                                                color: 'fg.muted',
                                              }}
                                            >
                                              {title}
                                            </View>
                                          ))}
                                        </View>
                                      </View>
                                    )}
                                    renderRowWrapper={(children, row) => {
                                      const isSelectedTask = !!(status as string[]).find((id) => id === row.id);
                                      return (
                                        <RowWrapper
                                          key={row.id}
                                          sx={{
                                            'cursor': 'pointer',
                                            ':hover': {
                                              backgroundColor: 'canvas.inset',
                                              transition: 'background-color 250ms',
                                            },
                                          }}
                                          onClick={() => {
                                            router.push(`/schoolExamSource/${row.id}`);
                                          }}
                                        >
                                          <View
                                            as={'td'}
                                            sx={{
                                              cursor: 'default',
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              padding: 2,
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Checkbox
                                              checked={isSelectedTask}
                                              onChange={() => {
                                                setStatus(
                                                  isSelectedTask
                                                    ? (status as string[]).filter((id) => id !== row.id)
                                                    : [...status, row.id],
                                                );
                                              }}
                                            />
                                          </View>
                                          {children}
                                        </RowWrapper>
                                      );
                                    }}
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </SchoolExamSourceWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

SchoolExamSource.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
SchoolExamSource.authenticated = true;
SchoolExamSource.routes = [
  {
    id: 'schoolExamSource',
    pathname: '/schoolExamSource',
    name: '내신 기출',
    icon: RepoPushIcon,
    permissions: ['school_exam_source_read'],
  },
  ...SchoolExamSourceId.routes,
];

export default SchoolExamSource;
