/**
 * @generated SignedSource<<0fa80c12df3ec112238857d2d1f3e194>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorActivateDialog_problemGenerator$data = {
  readonly id: string;
  readonly " $fragmentType": "ProblemGeneratorActivateDialog_problemGenerator";
};
export type ProblemGeneratorActivateDialog_problemGenerator$key = {
  readonly " $data"?: ProblemGeneratorActivateDialog_problemGenerator$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorActivateDialog_problemGenerator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProblemGeneratorActivateDialog_problemGenerator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ProblemGenerator",
  "abstractKey": null
};

(node as any).hash = "3a5890d753840d74a8362cc1e42bdcb5";

export default node;
