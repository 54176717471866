import { CheckIcon } from '@primer/octicons-react';
import { omit } from 'lodash-es';
import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { MATOrganizationUpdateDialog_matOrganization$key } from '../../../relay/__generated__/MATOrganizationUpdateDialog_matOrganization.graphql';
import { MATOrganizationUpdateDialog_organizationUpdateMutation } from '../../../relay/__generated__/MATOrganizationUpdateDialog_organizationUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const MATOrganizationUpdateDialog_matOrganization = graphql`
  fragment MATOrganizationUpdateDialog_matOrganization on MATOrganization {
    id
    title
    prefix
    type
  }
`;

type Props = {
  matOrganization: MATOrganizationUpdateDialog_matOrganization$key;
} & DialogProps &
  Pick<
    MutationFormikProps<MATOrganizationUpdateDialog_organizationUpdateMutation, { prefix: string }>,
    'config' | 'onSubmit' | 'connections'
  >;

const MATOrganizationUpdateDialog = ({ matOrganization, config, onSubmit, connections, ...props }: Props) => {
  const { id, title, prefix, type } = useFragment(MATOrganizationUpdateDialog_matOrganization, matOrganization);

  return (
    <Dialog wide {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>조직 정보 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<MATOrganizationUpdateDialog_organizationUpdateMutation, { prefix: string }>
        mutation={graphql`
          mutation MATOrganizationUpdateDialog_organizationUpdateMutation($input: MATOrganizationUpdateInput!) {
            organizationUpdate(input: $input) {
              id
              ...MATOrganizationUpdateDialog_matOrganization
            }
          }
        `}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
        initialValues={{
          id,
          title,
          type,
          prefix,
        }}
        valuesTransformer={(values) => omit(values, 'prefix')}
      >
        {({ handleSubmit }, { nonFieldErrorRef, scrollContainerRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <TextField label={'조직 이름'} name={'title'} required placeholder={'조직 이름 입력'} />
                <TextField
                  name={'prefix'}
                  label={'계정 이름'}
                  placeholder={'영문 입력'}
                  caption={'e.g. turing을 입력하면 turing001로 계정이 생성돼요'}
                  required
                  disabled
                />
                <Suspense>
                  <EnumPairRadioGroupField
                    typename={'MATOrganizationTypeEnum'}
                    label={'조직 타입'}
                    name={'type'}
                    required
                    renderOptionWrapper={(children, { id }) => (
                      <Grid.Unit key={id} size={1 / 2}>
                        {children}
                      </Grid.Unit>
                    )}
                    renderContainer={(children) => <Grid gapX={3}>{children}</Grid>}
                  />
                </Suspense>
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default MATOrganizationUpdateDialog;
