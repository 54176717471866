import { PropsWithChildren } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  MATInvoiceStateLabel_matInvoice$key,
  MATInvoiceStateLabel_matInvoice$data,
} from '../../../relay/__generated__/MATInvoiceStateLabel_matInvoice.graphql';
import Label, { LabelProps } from '../../core/Label';

const MATInvoiceStateLabel_matInvoice = graphql`
  fragment MATInvoiceStateLabel_matInvoice on MATInvoice {
    id
    state
    organization {
      id
      currentInvoice {
        id
      }
    }
  }
`;

type Props = {
  matInvoice: MATInvoiceStateLabel_matInvoice$key;
} & Omit<LabelProps, 'variant'>;

const MATInvoiceStateLabel = ({ matInvoice: matInvoiceRef, ...props }: Props) => {
  const matInvoice = useFragment(MATInvoiceStateLabel_matInvoice, matInvoiceRef);

  const getLabelProps = ({
    id,
    organization,
    state,
  }: MATInvoiceStateLabel_matInvoice$data): PropsWithChildren<LabelProps> => {
    const isActiveInvoice = organization.currentInvoice?.id === id;

    if (state === 'expired') {
      return {
        children: '만료',
        variant: 'secondary',
      };
    } else if (state === 'pending') {
      return {
        children: '예정',
        variant: 'accent',
      };
    } else if (state === 'ongoing' && isActiveInvoice) {
      return {
        children: '적용 중',
        variant: 'success',
      };
    } else if (state === 'ongoing' && !isActiveInvoice) {
      return {
        children: '적용 X',
        variant: 'default',
      };
    }

    return {};
  };

  return <Label {...getLabelProps(matInvoice)} {...props} />;
};

export default MATInvoiceStateLabel;
