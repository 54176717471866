import { graphql, useFragment } from 'react-relay';

import { QuizDifferenceItemList_difference$key } from '../../../relay/__generated__/QuizDifferenceItemList_difference.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import Text from '../../core/Text';
import View from '../../core/View';
import DiffItem from '../../difference/DiffItem';

const QuizDifferenceItemList_difference = graphql`
  fragment QuizDifferenceItemList_difference on Difference {
    ... on QuizDifference {
      description {
        ...DiffItem_diff
      }
      answer {
        ...DiffItem_diff
      }
      problem {
        ...DiffItem_diff
      }
      solution {
        ...DiffItem_diff
      }
      tip {
        ...DiffItem_diff
      }
      status {
        ...DiffItem_diff
      }
      unitDDescription {
        ...DiffItem_diff
      }
    }
  }
`;

type Props = { difference: QuizDifferenceItemList_difference$key };

const QuizDifferenceItemList = ({ difference }: Props) => {
  const { description, answer, problem, solution, tip, status, unitDDescription } = useFragment(
    QuizDifferenceItemList_difference,
    difference,
  );
  const diffItems = [
    {
      title: '설명',
      diff: description,
    },
    { title: '정답', diff: answer },
    {
      title: '문제',
      diff: problem,
      renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex>,
    },
    {
      title: '해설',
      diff: solution,
      renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex>,
    },
    {
      title: '개념 TIP',
      diff: tip,
      renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex>,
    },
    { title: '상태', diff: status },
    { title: 'Unit D', diff: unitDDescription },
  ].filter(({ diff }) => !isNullable(diff));

  return (
    <ItemList
      items={diffItems}
      renderItem={({ title, diff, renderItem }) => (
        <>
          <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
          <View sx={{ marginTop: 2 }}>
            <DiffItem diff={diff!} renderItem={renderItem} />
          </View>
        </>
      )}
      renderItemWrapper={(children, { title }, i) => (
        <View key={title} sx={{ marginTop: i > 0 ? 3 : 0 }}>
          {children}
        </View>
      )}
      emptyState={
        <View sx={{ paddingY: 6 }}>
          <EmptyState
            title={'기록되지 않은 히스토리이에요'}
            description={'이 기능이 만들어지기 전에 일어난 일이거나 오류로 누락된 히스토리입니다.'}
          />
        </View>
      }
    />
  );
};

export default QuizDifferenceItemList;
