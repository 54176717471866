/**
 * @generated SignedSource<<27443a67fdecb4dc2432922485938cf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductShowTypeEnum = "non_registered" | "private" | "repurchase" | "store" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductConnectionDataTable_productConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly discountPrice: number;
      readonly id: string;
      readonly modified: any | null;
      readonly sequence: number;
      readonly showTypes: ReadonlyArray<ProductShowTypeEnum>;
      readonly storeStuff: {
        readonly id: string;
        readonly stuff: {
          readonly id: string;
          readonly title: string;
        } | null;
        readonly title: string;
      } | null;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"ProductStatusLabel_product">;
    };
  }>;
  readonly " $fragmentType": "ProductConnectionDataTable_productConnection";
};
export type ProductConnectionDataTable_productConnection$key = {
  readonly " $data"?: ProductConnectionDataTable_productConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductConnectionDataTable_productConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductConnectionDataTable_productConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProductStatusLabel_product"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "StoreStuff",
              "kind": "LinkedField",
              "name": "storeStuff",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Stuff",
                  "kind": "LinkedField",
                  "name": "stuff",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showTypes",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "discountPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modified",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductConnection",
  "abstractKey": null
};
})();

(node as any).hash = "cda285fe1474b33f4e2e5108a5dac98d";

export default node;
