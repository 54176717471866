/**
 * @generated SignedSource<<ebfaeae2ed48a0a655c00cc9a50b63f6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery$variables = {
  after?: string | null;
  first?: number | null;
  id: string;
};
export type ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator">;
  };
};
export type ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery = {
  response: ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery$data;
  variables: ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "before",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "after",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "ProblemGeneratorHistoryConnection",
                "kind": "LinkedField",
                "name": "histories",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProblemGeneratorHistoryEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProblemGeneratorHistory",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "picture",
                                "storageKey": null
                              },
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isDeleted",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "created",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "difference",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isDifference"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Diff",
                                    "kind": "LinkedField",
                                    "name": "generatedCount",
                                    "plural": false,
                                    "selections": (v5/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Diff",
                                    "kind": "LinkedField",
                                    "name": "publishedProblemsCount",
                                    "plural": false,
                                    "selections": (v5/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "type": "ProblemGeneratorDifference",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator_histories",
                "kind": "LinkedHandle",
                "name": "histories"
              }
            ],
            "type": "ProblemGenerator",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ebe4a34c412e741e9acab33786ba212",
    "id": null,
    "metadata": {},
    "name": "ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery(\n  $after: String\n  $first: Int = 10\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator_2HEEH6\n    id\n  }\n}\n\nfragment DiffItem_diff on Diff {\n  before\n  after\n}\n\nfragment ProblemGeneratorDifferenceCounterLabel_difference on Difference {\n  __isDifference: __typename\n  ... on ProblemGeneratorDifference {\n    generatedCount {\n      __typename\n    }\n    publishedProblemsCount {\n      __typename\n    }\n  }\n}\n\nfragment ProblemGeneratorDifferenceItemList_difference on Difference {\n  __isDifference: __typename\n  ... on ProblemGeneratorDifference {\n    generatedCount {\n      ...DiffItem_diff\n    }\n    publishedProblemsCount {\n      ...DiffItem_diff\n    }\n  }\n}\n\nfragment ProblemGeneratorHistoryDialog_problemGeneratorHistory on ProblemGeneratorHistory {\n  createdBy {\n    name\n    picture\n    id\n  }\n  created\n  difference {\n    __typename\n    ...ProblemGeneratorDifferenceCounterLabel_difference\n    ...ProblemGeneratorDifferenceItemList_difference\n  }\n}\n\nfragment ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory on ProblemGeneratorHistory {\n  created\n  createdBy {\n    id\n    ...UserAvatarText_user\n  }\n  type\n}\n\nfragment ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator_2HEEH6 on ProblemGenerator {\n  histories(first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        type\n        ...ProblemGeneratorHistoryDialog_problemGeneratorHistory\n        ...ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory\n        __typename\n      }\n    }\n  }\n  id\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "227f710a054fa7796b50808595a24446";

export default node;
