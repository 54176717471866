import { graphql, useFragment } from 'react-relay';

import {
  ProblemGeneratorStatusEnum,
  ProblemGeneratorStatusLabel_problemGenerator$key,
} from '../../../relay/__generated__/ProblemGeneratorStatusLabel_problemGenerator.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const ProblemGeneratorStatusLabel_problemGenerator = graphql`
  fragment ProblemGeneratorStatusLabel_problemGenerator on ProblemGenerator {
    status
  }
`;

type Props = {
  problemGenerator: ProblemGeneratorStatusLabel_problemGenerator$key;
} & Omit<LabelProps, 'variant'>;

const ProblemGeneratorStatusLabel = ({ problemGenerator, ...props }: Props) => {
  const { status } = useFragment(ProblemGeneratorStatusLabel_problemGenerator, problemGenerator);

  const labelPropsByStatus: Record<ProblemGeneratorStatusEnum, LabelProps> = {
    'invalid': { variant: 'danger' },
    'valid': { variant: 'success' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'ProblemGeneratorStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default ProblemGeneratorStatusLabel;
