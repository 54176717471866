import { CheckIcon, DashIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import { AppDialogCreateDialog_appDialogCreateMutation } from '../../../relay/__generated__/AppDialogCreateDialog_appDialogCreateMutation.graphql';
import { formatISO } from '../../../utils/date';
import Button from '../../core/Button';
import { CheckboxGroupFieldProps } from '../../core/CheckboxGroupField';
import DatetimeField from '../../core/DatetimeField';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairCheckboxGroupField from '../../core/EnumPairCheckboxGroupField';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import NumberRangeField from '../../core/NumberRangeField';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import UploadField from '../../core/UploadField';
import View from '../../core/View';
import AppDialogImageAndTextTypePreview from '../AppDialogImageAndTextTypePreview';
import AppDialogImageTypePreview from '../AppDialogImageTypePreview';
import AppDialogTextTypePreview from '../AppDialogTextTypePreview';

type Props = {
  initialValues?: Partial<MutationFormikProps<AppDialogCreateDialog_appDialogCreateMutation>['initialValues']>;
} & DialogProps &
  Pick<MutationFormikProps<AppDialogCreateDialog_appDialogCreateMutation>, 'config' | 'connections' | 'onSubmit'>;

const horizontalFieldsLayoutProp: Pick<CheckboxGroupFieldProps, 'renderContainer' | 'renderOptionWrapper'> = {
  renderContainer: (children) => (
    <Stack gapX={3} gapY={2} wrap>
      {children}
    </Stack>
  ),
  renderOptionWrapper: (option, { id }) => <Stack.Item key={id}>{option}</Stack.Item>,
};

const AppDialogCreateDialog = ({ isOpen, config, connections, onSubmit, initialValues, ...props }: Props) => {
  return (
    <Dialog isOpen={isOpen} full {...props}>
      <>
        <Dialog.Header>
          <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>만능 다이얼로그 생성하기</Text>
        </Dialog.Header>
        <MutationFormik<AppDialogCreateDialog_appDialogCreateMutation>
          mutation={graphql`
            mutation AppDialogCreateDialog_appDialogCreateMutation($input: AppDialogCreateInput!) {
              appDialogCreate(input: $input) {
                id
              }
            }
          `}
          initialValues={{
            title: '',
            appDialogTitle: '',
            appDialogBody: '',
            appDialogButton: '',
            startAt: formatISO(Date.now()),
            endAt: formatISO(new Date().setFullYear(2050, 0, 1)),
            appDeepLink: '',
            appDialogType: 'image',
            exposeType: 'once',
            exposeWeekdays: [],
            userAppVersion: {},
            userOs: ['android', 'ios'],
            userPeriod: {},
            order: 0,
            userTypes: ['general', 'non_refundable', 'refundable'],
            userYears: [
              'elementary_school_4',
              'elementary_school_5',
              'elementary_school_6',
              'middle_school_1',
              'middle_school_2',
              'middle_school_3',
              'high_school_1',
              'high_school_2',
              'high_school_3',
              'high_school_N',
            ],
            ...initialValues,
          }}
          config={config}
          {...{ connections, onSubmit }}
        >
          {({ values, handleSubmit }, { scrollContainerRef, nonFieldErrorRef }) => (
            <>
              <Grid wrap={false} sx={{ height: '100%', overflowY: 'hidden' }}>
                <Grid.Unit size={3 / 4}>
                  <View
                    ref={scrollContainerRef}
                    sx={{
                      padding: 3,
                      height: '100%',
                      overflowY: 'auto',
                    }}
                  >
                    <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
                    <FormLayout>
                      <TextField label={'제목'} name={'title'} placeholder={'제목 입력'} required />
                      <FormLayout>
                        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>타겟 사용자</Text>
                        <View sx={{ marginTop: 4 }}>
                          <Grid sx={{ alignItems: 'flex-end' }} gapX={2}>
                            <Grid.Unit size={'max'}>
                              <TextField name={'userAppVersion.min'} label={'앱 버전'} placeholder={'0.0.0'} />
                            </Grid.Unit>
                            <Grid.Unit size={'min'}>
                              <View sx={{ marginBottom: 1 }}>
                                <StyledOcticon icon={DashIcon} color={'neutral.emphasis'} />
                              </View>
                            </Grid.Unit>
                            <Grid.Unit size={'max'}>
                              <TextField
                                name={'userAppVersion.max'}
                                label={'앱 버전'}
                                labelConfig={{ visuallyHidden: true }}
                                placeholder={'0.0.0'}
                              />
                            </Grid.Unit>
                          </Grid>
                        </View>

                        <Suspense fallback={<Spinner />}>
                          <EnumPairCheckboxGroupField
                            {...horizontalFieldsLayoutProp}
                            typename={'AppDialogUserOsEnum'}
                            label={'OS'}
                            name={'userOs'}
                            required
                          />
                        </Suspense>

                        <Suspense fallback={<Spinner />}>
                          <EnumPairCheckboxGroupField
                            {...horizontalFieldsLayoutProp}
                            typename={'AppDialogUserTypeEnum'}
                            label={'타입'}
                            name={'userTypes'}
                            required
                          />
                        </Suspense>

                        <Suspense fallback={<Spinner />}>
                          <EnumPairCheckboxGroupField
                            {...horizontalFieldsLayoutProp}
                            typename={'AppDialogUserYearEnum'}
                            label={'학년'}
                            name={'userYears'}
                            required
                          />
                        </Suspense>

                        <NumberRangeField
                          label={'고객 수명(일)'}
                          name={'userPeriod'}
                          placeholder={['0', '0']}
                          caption={'사용자의 가입 후 기간을 필터링 할 수 있어요'}
                        />
                      </FormLayout>

                      <FormLayout>
                        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>만능 다이얼로그</Text>

                        <View sx={{ marginTop: 4 }}>
                          <DatetimeField
                            label={'시작일'}
                            required
                            name={'startAt'}
                            datePickerProps={{ placeholder: '시작일 선택' }}
                          />
                        </View>
                        <View sx={{ marginTop: 4 }}>
                          <DatetimeField
                            label={'종료일'}
                            required
                            name={'endAt'}
                            datePickerProps={{ placeholder: '종료일 선택' }}
                          />
                        </View>
                        <NumberField label={'우선순위'} required name={'order'} placeholder={'0'} />
                        <View>
                          <Suspense fallback={<Spinner />}>
                            <EnumPairRadioGroupField
                              {...horizontalFieldsLayoutProp}
                              typename={'AppDialogExposeTypeEnum'}
                              label={'노출 타입'}
                              required
                              name={'exposeType'}
                            />
                          </Suspense>
                          <View sx={{ marginTop: 2 }}>
                            <Suspense>
                              {values.exposeType === 'hour_repeat' ? (
                                <EnumPairRadioGroupField
                                  {...horizontalFieldsLayoutProp}
                                  typename={'AppDialogExposeHourEnum'}
                                  label={'시간 반복 설정'}
                                  labelConfig={{ visuallyHidden: true }}
                                  name={'exposeHour'}
                                />
                              ) : values.exposeType === 'weekday_repeat' ? (
                                <EnumPairCheckboxGroupField
                                  {...horizontalFieldsLayoutProp}
                                  typename={'AppDialogExposeWeekdayEnum'}
                                  label={'요일 반복 설정'}
                                  labelConfig={{ visuallyHidden: true }}
                                  name={'exposeWeekdays'}
                                />
                              ) : values.exposeType === 'manual_tag' ? (
                                <TextField
                                  name={'exposeTag'}
                                  label={'manualTag'}
                                  labelConfig={{ visuallyHidden: true }}
                                  caption={'중복된 태그는 삼가해 주세요'}
                                  placeholder={'manualTag 입력'}
                                />
                              ) : null}
                            </Suspense>
                          </View>
                        </View>
                        <Suspense fallback={<Spinner />}>
                          <EnumPairRadioGroupField
                            {...horizontalFieldsLayoutProp}
                            typename={'AppDialogTypeEnum'}
                            label={'타입'}
                            required
                            name={'appDialogType'}
                          />
                        </Suspense>

                        {values.appDialogType === 'image' && (
                          <TextField
                            name={'appDeepLink'}
                            label={'액션 딥링크'}
                            placeholder={'https:// or mathking://'}
                          />
                        )}
                        {values.appDialogType !== 'image' && (
                          <TextField name={'appDialogTitle'} label={'제목'} placeholder={'제목 입력'} required />
                        )}
                        {values.appDialogType !== 'image' && (
                          <TextField name={'appDialogBody'} label={'본문'} placeholder={'본문 입력'} required />
                        )}

                        {values.appDialogType === 'image' && (
                          <Grid gapX={3}>
                            <Grid.Unit size={1 / 2}>
                              <UploadField
                                label={'모바일용 이미지'}
                                name={'appDialogMobileImage'}
                                required
                                caption={'* 파일은 jpg, png를 권장합니다.\n* 비율은 300x500로 올려주세요.'}
                                accept={'image/*'}
                              />
                            </Grid.Unit>
                            <Grid.Unit size={1 / 2}>
                              <UploadField
                                label={'태블릿용 이미지'}
                                name={'appDialogTabletImage'}
                                required
                                caption={'* 파일은 jpg, png를 권장합니다.\n* 비율은 1080x500로 올려주세요.'}
                                accept={'image/*'}
                              />
                            </Grid.Unit>
                          </Grid>
                        )}

                        {values.appDialogType !== 'image' && (
                          <Grid gapX={2}>
                            <Grid.Unit size={1 / 2}>
                              <TextField
                                label={'CTA 버튼명'}
                                name={'appDialogButton'}
                                required
                                placeholder={'CTA 버튼명 입력'}
                              />
                            </Grid.Unit>
                            <Grid.Unit size={1 / 2}>
                              <TextField
                                label={'액션 딥링크'}
                                name={'appDeepLink'}
                                placeholder={'https:// or mathking://'}
                              />
                            </Grid.Unit>
                          </Grid>
                        )}

                        {values.appDialogType === 'image_and_text' && (
                          <UploadField
                            label={'이미지'}
                            name={'appDialogImage'}
                            required
                            caption={'* 파일은 jpg, png를 권장합니다.\n* 비율은 270x170로 올려주세요.'}
                            accept={'image/*'}
                          />
                        )}
                      </FormLayout>
                    </FormLayout>
                  </View>
                </Grid.Unit>
                <Grid.Unit size={1 / 4}>
                  <View
                    sx={{
                      padding: 3,
                      height: '100%',
                      overflowY: 'auto',
                      backgroundColor: 'canvas.inset',
                    }}
                  >
                    {values.appDialogType === 'image' ? (
                      <AppDialogImageTypePreview
                        appDialogMobileImage={values.appDialogMobileImage?.objectUrl}
                        appDialogTabletImage={values.appDialogTabletImage?.objectUrl}
                      />
                    ) : values.appDialogType === 'text' ? (
                      <AppDialogTextTypePreview
                        appDialogTitle={values.appDialogTitle || ''}
                        appDialogBody={values.appDialogBody || ''}
                        appDialogButton={values.appDialogButton || ''}
                      />
                    ) : values.appDialogType === 'image_and_text' ? (
                      <AppDialogImageAndTextTypePreview
                        appDialogTitle={values.appDialogTitle || ''}
                        appDialogBody={values.appDialogBody || ''}
                        appDialogButton={values.appDialogButton || ''}
                        appDialogImage={values.appDialogImage?.objectUrl}
                      />
                    ) : null}
                  </View>
                </Grid.Unit>
              </Grid>
              <Dialog.Footer>
                <Button variant={'primary'} onClick={() => handleSubmit()} leadingIcon={CheckIcon}>
                  생성하기
                </Button>
              </Dialog.Footer>
            </>
          )}
        </MutationFormik>
      </>
    </Dialog>
  );
};

export default AppDialogCreateDialog;
