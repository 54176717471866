/**
 * @generated SignedSource<<50eb2888af2548e462e48581cfae70ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationTeachersXlsxCreateDialog_matOrganization$data = {
  readonly id: string;
  readonly teachersBulkCreateTemplateUrl: string;
  readonly " $fragmentType": "MATOrganizationTeachersXlsxCreateDialog_matOrganization";
};
export type MATOrganizationTeachersXlsxCreateDialog_matOrganization$key = {
  readonly " $data"?: MATOrganizationTeachersXlsxCreateDialog_matOrganization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATOrganizationTeachersXlsxCreateDialog_matOrganization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATOrganizationTeachersXlsxCreateDialog_matOrganization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teachersBulkCreateTemplateUrl",
      "storageKey": null
    }
  ],
  "type": "MATOrganization",
  "abstractKey": null
};

(node as any).hash = "7e4af392d23c9834f3bd14a861cd911b";

export default node;
