import { formatKatexToHtmlString } from '@teamturing/katex-utils';
import React, { useRef } from 'react';
import { graphql, useFragment } from 'react-relay';

import { QuizUpdateDialog_quiz$key } from '../../../relay/__generated__/QuizUpdateDialog_quiz.graphql';
import { QuizUpdateDialog_quizUpdateMutation } from '../../../relay/__generated__/QuizUpdateDialog_quizUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import KatexEditorField from '../../core/KatexEditorField';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextareaField from '../../core/TextareaField';
import UnitDSearchChoiceTextField from '../../unitD/UnitDSearchChoiceTextField';

const QuizUpdateDialog_quiz = graphql`
  fragment QuizUpdateDialog_quiz on Quiz {
    id
    description
    answer
    unitD {
      id
    }

    problem
    solution
    tip
  }
`;

type Props = {
  quiz: QuizUpdateDialog_quiz$key;
} & DialogProps &
  Pick<MutationFormikProps<QuizUpdateDialog_quizUpdateMutation>, 'config' | 'connections' | 'onSubmit'>;

const QuizUpdateDialog = ({ quiz, config, connections, onSubmit, ...props }: Props) => {
  const { id, description, unitD, answer, problem, solution, tip } = useFragment(QuizUpdateDialog_quiz, quiz);
  const dialogScrollContainerRef = useRef<HTMLDivElement>(null);
  const nonFieldErrorRef = useRef<HTMLDivElement>(null);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>수정하기</Text>
      </Dialog.Header>
      <MutationFormik<QuizUpdateDialog_quizUpdateMutation>
        mutation={graphql`
          mutation QuizUpdateDialog_quizUpdateMutation($connections: [ID!]!, $input: QuizUpdateInput!) {
            quizUpdate(input: $input) @appendNode(connections: $connections, edgeTypeName: "QuizEdge") {
              id
              ...QuizUpdateDialog_quiz
              ...QuizQuizHistoryPaginator_quiz
            }
          }
        `}
        connections={connections}
        initialValues={{
          id,
          description,
          answer,
          unitD,
          problem: { text: problem, textHtml: formatKatexToHtmlString(problem) },
          solution: { text: solution, textHtml: formatKatexToHtmlString(solution) },
          tip: { text: tip, textHtml: formatKatexToHtmlString(tip) },
        }}
        config={config}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body ref={dialogScrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextareaField label={'설명'} name={'description'} placeholder={'설명을 입력해주세요.'} />
                <EnumPairRadioGroupField typename={'QuizAnswerTypeEnum'} label={'정답'} name={'answer'} required />
                <UnitDSearchChoiceTextField
                  label={'Unit D'}
                  name={'unitD.id'}
                  placeholder={'Unit D를 선택해주세요.'}
                  variables={{
                    filters: { unitSchoolType_In: ['high_school', 'middle_school'] },
                    order: { order: 'ASC' },
                  }}
                  required
                />
                <KatexEditorField label={'문제'} name={'problem'} required />
                <KatexEditorField label={'해설'} name={'solution'} required />
                <KatexEditorField label={'개념 TIP'} name={'tip'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default QuizUpdateDialog;
