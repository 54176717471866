import { EyeClosedIcon } from '@primer/octicons-react';
import { capitalize } from 'lodash-es';
import { graphql, useFragment } from 'react-relay';

import { UserAvatarText_user$key } from '../../../relay/__generated__/UserAvatarText_user.graphql';
import Avatar, { AvatarProps } from '../../core/Avatar';
import StyledOcticon from '../../core/StyledOcticon';
import Text, { TextProps } from '../../core/Text';
import View from '../../core/View';

type Props = {
  user: UserAvatarText_user$key;
} & TextProps &
  Pick<AvatarProps, 'size'>;

const UserAvatarText_user = graphql`
  fragment UserAvatarText_user on User {
    name
    picture
    isDeleted
  }
`;

const UserAvatarText = ({ user, size = 20, ...props }: Props) => {
  const { name: baseName, picture, isDeleted } = useFragment(UserAvatarText_user, user);
  const name = capitalize(baseName);
  return (
    <Text {...props}>
      <View sx={{ display: 'inline-block', width: size, height: size, position: 'relative' }}>
        <Avatar title={name} size={size} src={picture || ''} />
        {isDeleted ? (
          <View
            sx={{
              width: size / 2 + 3,
              height: size / 2 + 3,
              position: 'absolute',
              bottom: -size / 8,
              right: -size / 8,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'canvas.default',
              borderRadius: 9999,
            }}
          >
            <StyledOcticon icon={EyeClosedIcon} color={'severe.fg'} size={size / 2} />
          </View>
        ) : null}
      </View>
      {` ${name}`}
    </Text>
  );
};

export default UserAvatarText;
export type { Props as UserAvatarTextProps };
