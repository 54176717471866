/**
 * @generated SignedSource<<bf9b761a5361e2758190cdd14d4f30a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ScrapSourceBookNamesFilter = {
  search?: string | null;
};
export type ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery$variables = {
  filters?: ScrapSourceBookNamesFilter | null;
};
export type ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery$data = {
  readonly scrapSourceBookNames: ReadonlyArray<string>;
};
export type ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery = {
  response: ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery$data;
  variables: ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      }
    ],
    "kind": "ScalarField",
    "name": "scrapSourceBookNames",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fae7b7de84a27dd13f426379484977b2",
    "id": null,
    "metadata": {},
    "name": "ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery",
    "operationKind": "query",
    "text": "query ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery(\n  $filters: ScrapSourceBookNamesFilter\n) {\n  scrapSourceBookNames(filters: $filters)\n}\n"
  }
};
})();

(node as any).hash = "cc99145a325229b530e3b2e9e9531bac";

export default node;
