/**
 * @generated SignedSource<<8f98f30b1e78eb65116954ed6e20134e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorDifferenceCounterLabel_difference$data = {
  readonly generatedCount?: {
    readonly __typename: "Diff";
  } | null;
  readonly publishedProblemsCount?: {
    readonly __typename: "Diff";
  } | null;
  readonly " $fragmentType": "ProblemGeneratorDifferenceCounterLabel_difference";
};
export type ProblemGeneratorDifferenceCounterLabel_difference$key = {
  readonly " $data"?: ProblemGeneratorDifferenceCounterLabel_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorDifferenceCounterLabel_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProblemGeneratorDifferenceCounterLabel_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "generatedCount",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "publishedProblemsCount",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "ProblemGeneratorDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "915c4993440b39a9fa78dd04d2d4223e";

export default node;
