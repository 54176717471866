import NextImage, { ImageProps as NextImageProps } from 'next/image';

import { isNullable } from '../../../utils/is';

type Props = {
  html?: boolean;
} & NextImageProps;

const Image = ({ src, width, height, html, ...props }: Props) => {
  const isRemoteImage = typeof src === 'string';

  return html ? (
    <img
      src={isRemoteImage ? src : 'src' in src ? src.src : src.default.src}
      width={width ? width : '100%'}
      {...props}
    />
  ) : (
    <NextImage
      unoptimized
      {...(isRemoteImage && !(isNullable(width) || isNullable(height))
        ? { src: `${src}?w=${width}&h=${height}` }
        : { src })}
      width={width}
      height={height}
      {...props}
    />
  );
};

export default Image;
export type { Props as ImageProps };
