import { CalendarIcon } from '@primer/octicons-react';
import { format, isAfter, isBefore } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import Calendar, { CalendarProps, OnChangeDateCallback } from 'react-calendar';

import {
  formatDate,
  formatISO,
  getDateFrom6Or8DigitString,
  isValid6Or8DigitDateString,
  isValidDateString,
  startOfDay,
} from '../../../utils/date';
import { isNullable } from '../../../utils/is';
import AnchoredOverlay from '../AnchoredOverlay';
import TextInput, { TextInputProps } from '../TextInput';

type Props = { onChange?: (date: Date) => void; value?: string } & Omit<
  TextInputProps,
  'onChange' | 'value' | 'block' | 'autoComplete' | 'trailingAction' | 'min' | 'max'
> &
  Pick<CalendarProps, 'minDate' | 'maxDate'>;

const DatePicker = ({
  value,
  onChange,
  disabled,
  onKeyDown,
  onBlur,
  onFocus,
  minDate,
  maxDate,
  size = 'medium',
  ...props
}: Props) => {
  const INVALID_DATE = '';
  const textInputRef = useRef<HTMLInputElement>(null);
  const setTextInputValue = (date: string) => {
    if (!textInputRef.current) return;

    textInputRef.current.value = date && isValidDateString(date) ? formatDate(date, 'yyyy-MM-dd') : INVALID_DATE;
  };
  useEffect(() => {
    setTextInputValue(value || '');
  }, [value]);

  const handleChangeWithDateString = () => {
    const dateTextValue = textInputRef.current?.value || INVALID_DATE;

    let date: Date | null = null;

    if (isValid6Or8DigitDateString(dateTextValue)) {
      date = getDateFrom6Or8DigitString(dateTextValue);
      // onChange?.(getDateFrom6Or8DigitString(dateTextValue));
    } else if (isValidDateString(dateTextValue) && new Date(dateTextValue).getFullYear() < 10000) {
      date = startOfDay(new Date(dateTextValue));
      // onChange?.(startOfDay(new Date(dateTextValue)));
    }

    if (isNullable(date)) {
      setTextInputValue(value || '');
      return;
    }

    if (minDate && isBefore(date, minDate)) {
      date = minDate;
    }
    if (maxDate && isAfter(date, maxDate)) {
      date = maxDate;
    }

    onChange?.(date);
    setTextInputValue(format(date, 'yyyy-MM-dd'));
  };
  const handleKeyDown: TextInputProps['onKeyDown'] = (e) => {
    onKeyDown?.(e);
    if (e.code === 'Enter') {
      handleChangeWithDateString();
    }
  };

  const isFocusRef = useRef(false);
  const handleFocus: TextInputProps['onFocus'] = (e) => {
    onFocus?.(e);
    isFocusRef.current = true;
  };
  const handleBlur: TextInputProps['onBlur'] = (e) => {
    onBlur?.(e);
    isFocusRef.current = false;
    handleChangeWithDateString();
  };
  const handleChange: TextInputProps['onChange'] = (e) => {
    if (!isFocusRef.current && e.target.value !== value) {
      handleChangeWithDateString();
    }
  };

  const [isAnchoredOverlayOpen, setIsAnchoredOverlayOpen] = useState<boolean>(false);
  const handleAnchoredOverlayOpen = () => {
    setIsAnchoredOverlayOpen(true);
  };
  const handleAnchoredOverlayClose = () => {
    setIsAnchoredOverlayOpen(false);
  };
  const handleCalendarChange: OnChangeDateCallback = (date) => {
    onChange?.(date);
    handleAnchoredOverlayClose();
  };

  return (
    <TextInput
      {...props}
      ref={textInputRef}
      disabled={disabled}
      size={size}
      block
      autoComplete="off"
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
      trailingAction={
        <AnchoredOverlay
          width={'medium'}
          height={'auto'}
          open={isAnchoredOverlayOpen}
          {...(disabled ? {} : { onOpen: handleAnchoredOverlayOpen })}
          onClose={handleAnchoredOverlayClose}
          align={'end'}
          renderAnchor={(anchorProps) => (
            <TextInput.Action
              {...anchorProps}
              icon={CalendarIcon}
              aria-label={'open calendar overlay'}
              disabled={disabled}
              sx={{ color: 'fg.muted' }}
            />
          )}
        >
          <Calendar
            value={value ? new Date(value) : undefined}
            onChange={handleCalendarChange}
            locale={'ko'}
            calendarType={'US'}
            formatDay={(_, date) => formatDate(formatISO(date), 'd')}
            minDate={minDate}
            maxDate={maxDate}
          />
        </AnchoredOverlay>
      }
    />
  );
};

export default DatePicker;
export type { Props as DatePickerProps };
