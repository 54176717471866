import useEnumPairsQuery from '../../../hooks/useEnumPairsQuery';
import { isNullable } from '../../../utils/is';

type Props = {
  typename: string;
  children: string;
  formatter?: (value: string) => string;
};

const EnumPair = ({ typename, children: key, formatter = (value) => value }: Props) => {
  const pairs = useEnumPairsQuery(typename);
  const value = pairs.find(({ key: pairKey }) => pairKey === key)?.value;

  if (isNullable(value)) throw new Error(`'${key}' is not defined in '${typename}'`);

  return formatter(value) as unknown as JSX.Element;
};

export default EnumPair;
export type { Props as EnumPairsProps };
