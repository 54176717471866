/**
 * @generated SignedSource<<27d4588a3e86aa3d9c19cc33e28f32b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MATSchoolYearMajorTypeEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1_common" | "high_school_2_common" | "high_school_3_giha" | "high_school_3_hwaktong" | "high_school_3_mijukbun" | "high_school_n_giha" | "high_school_n_hwaktong" | "high_school_n_mijukbun" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InternalUserDescriptionList_internalUser$data = {
  readonly id: string;
  readonly loginId: string;
  readonly phoneNumber: string | null;
  readonly studentProfile: {
    readonly friendTag: string;
    readonly profileImageUrl: string;
    readonly schoolYearMajorType: MATSchoolYearMajorTypeEnum;
    readonly statusMessage: string | null;
  };
  readonly userName: string;
  readonly userSequence: number;
  readonly " $fragmentSpreads": FragmentRefs<"InternalLoginTypeUpdateDialog_internalUser" | "InternalUserNameUpdateDialog_internalUser" | "InternalUserPhoneNumberUpdateDialog_internalUser" | "InternalUserSchoolYearMajorTypeUpdateDialog_internalUser" | "InternalUserStatusMessageUpdateDialog_internalUser">;
  readonly " $fragmentType": "InternalUserDescriptionList_internalUser";
};
export type InternalUserDescriptionList_internalUser$key = {
  readonly " $data"?: InternalUserDescriptionList_internalUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternalUserDescriptionList_internalUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternalUserDescriptionList_internalUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userSequence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loginId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MATInternalStudentProfile",
      "kind": "LinkedField",
      "name": "studentProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schoolYearMajorType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profileImageUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "friendTag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "statusMessage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InternalLoginTypeUpdateDialog_internalUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InternalUserNameUpdateDialog_internalUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InternalUserPhoneNumberUpdateDialog_internalUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InternalUserStatusMessageUpdateDialog_internalUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InternalUserSchoolYearMajorTypeUpdateDialog_internalUser"
    }
  ],
  "type": "MATInternalUser",
  "abstractKey": null
};

(node as any).hash = "d3c76cf1844d6a25a6e2726824ef2e5a";

export default node;
