import { TextInput as PrimerTextInput, TextInputProps as PrimerTextInputProps } from '@primer/react';
import { ComponentProps, forwardRef, Ref } from 'react';

import { useDialogContext } from '../../../contexts/DialogContext';
import { isNullable } from '../../../utils/is';
import DialogHandler from '../DialogHandler';

type Props = {} & PrimerTextInputProps;

const TextInput = ({ onKeyDown, maxLength, onChange, ...props }: Props, ref: Ref<HTMLInputElement>) => {
  const dialogContext = useDialogContext();
  const handleKeyDown: PrimerTextInputProps['onKeyDown'] = (event) => {
    if (!!dialogContext && event.key === 'Tab') event.stopPropagation();
    onKeyDown?.(event);
  };

  const handleChange: PrimerTextInputProps['onChange'] = (event) => {
    if (!isNullable(maxLength)) {
      event.target.value = event.target.value.slice(0, maxLength);
    }
    onChange?.(event);
  };

  return (
    <PrimerTextInput ref={ref} onKeyDown={handleKeyDown} maxLength={maxLength} onChange={handleChange} {...props} />
  );
};

const DialogAction = ({
  renderDialog,
  onClick,
  ...props
}: {
  renderDialog: ({ isOpen, closeDialog }: { isOpen: boolean; closeDialog: () => void }) => React.ReactNode;
} & ComponentProps<typeof PrimerTextInput.Action>) => (
  <DialogHandler renderDialog={renderDialog} onClick={onClick}>
    <PrimerTextInput.Action {...props} />
  </DialogHandler>
);

export default Object.assign(forwardRef(TextInput), { Action: PrimerTextInput.Action, DialogAction });
export type { Props as TextInputProps };
