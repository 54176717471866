/**
 * @generated SignedSource<<a475daef6eadbbd75ce740f1dd62218a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type SchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitDFilter = {
  AND?: UnitDFilter | null;
  DISTINCT?: boolean | null;
  NOT?: UnitDFilter | null;
  OR?: UnitDFilter | null;
  courseId_Exact?: string | null;
  courseId_In?: ReadonlyArray<string> | null;
  courseTitle_Contains?: string | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  search?: string | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  unitAId_Exact?: string | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_Exact?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_Exact?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitId_Exact?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: SchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<SchoolTypeEnum> | null;
  unitTitle_Contains?: string | null;
};
export type UnitDOrder = {
  description?: Ordering | null;
  id?: Ordering | null;
  order?: Ordering | null;
  schoolType?: Ordering | null;
  title?: Ordering | null;
};
export type UnitDPaginatorRefreshQuery$variables = {
  after?: string | null;
  filters?: UnitDFilter | null;
  first?: number | null;
  order?: UnitDOrder | null;
};
export type UnitDPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UnitDPaginator_query">;
};
export type UnitDPaginatorRefreshQuery = {
  response: UnitDPaginatorRefreshQuery$data;
  variables: UnitDPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitDPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UnitDPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnitDPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnitDConnection",
        "kind": "LinkedField",
        "name": "unitDs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitDEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitD",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitATitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitBTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "unitCTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "UnitDPaginator_query_unitDs",
        "kind": "LinkedHandle",
        "name": "unitDs"
      }
    ]
  },
  "params": {
    "cacheID": "1ab27516415dd3d11a3049e35226321f",
    "id": null,
    "metadata": {},
    "name": "UnitDPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query UnitDPaginatorRefreshQuery(\n  $after: String\n  $filters: UnitDFilter\n  $first: Int\n  $order: UnitDOrder\n) {\n  ...UnitDPaginator_query_2rfEMg\n}\n\nfragment UnitDItem_unitD on UnitD {\n  id\n  unitATitle\n  unitBTitle\n  unitCTitle\n  description\n}\n\nfragment UnitDPaginator_query_2rfEMg on Query {\n  unitDs(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        description\n        ...UnitDItem_unitD\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "782b6379bf13229ce607366ee0526124";

export default node;
