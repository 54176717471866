import { sanitize } from 'dompurify';

import useMarked from '../../../hooks/useMarked';
import View from '../View';

import { markdownStyle } from './markdownStyle';

type Props = {
  children: string;
};

const Markdown = ({ children }: Props) => {
  const { parse } = useMarked({});

  return <View sx={{ ...markdownStyle }} dangerouslySetInnerHTML={{ __html: sanitize(parse(children) as string) }} />;
};

export default Markdown;

export type { Props as MarkdownProps };
