import { graphql, useFragment } from 'react-relay';

import { TaskSolutionVideoGrid_taskSolutionVideo$key } from '../../../relay/__generated__/TaskSolutionVideoGrid_taskSolutionVideo.graphql';
import Grid, { GridProps } from '../../core/Grid';
import Image from '../../core/Image';
import Link from '../../core/Link';
import SquareView from '../../core/SquareView';
import Text from '../../core/Text';
import Video from '../../core/Video';
import View from '../../core/View';

const TaskSolutionVideoGrid_taskSolutionVideo = graphql`
  fragment TaskSolutionVideoGrid_taskSolutionVideo on TaskSolutionVideo {
    hlsUrl
    thumbnailHorizontal {
      id
      url
    }
    thumbnailVertical {
      id
      url
    }
  }
`;

type Props = {
  taskSolutionVideo: TaskSolutionVideoGrid_taskSolutionVideo$key;
} & GridProps;

const TaskSolutionVideoGrid = ({ taskSolutionVideo, ...props }: Props) => {
  const { hlsUrl, thumbnailHorizontal, thumbnailVertical } = useFragment(
    TaskSolutionVideoGrid_taskSolutionVideo,
    taskSolutionVideo,
  );

  return (
    <Grid gapX={2} gapY={2} {...props}>
      <Grid.Unit size={[1 / 2, 1 / 4]}>
        <SquareView
          sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'border.default',
            borderRadius: 2,
            backgroundColor: 'neutral.emphasisPlus',
          }}
        >
          <View
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Video src={hlsUrl} width={'100%'} height={'100%'} />
          </View>
        </SquareView>
      </Grid.Unit>
      {thumbnailHorizontal ? (
        <Grid.Unit size={[1 / 2, 1 / 4]}>
          <SquareView
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'border.default',
              borderRadius: 2,
              backgroundColor: 'neutral.emphasisPlus',
            }}
          >
            <View
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Link target={'_blank'} href={thumbnailHorizontal.url}>
                <Image
                  src={thumbnailHorizontal.url}
                  alt={thumbnailHorizontal.url}
                  fill
                  style={{ objectFit: 'contain' }}
                />
                <View
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    padding: 2,
                  }}
                >
                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.onEmphasis' }}>가로 썸네일</Text>
                </View>
              </Link>
            </View>
          </SquareView>
        </Grid.Unit>
      ) : null}
      {thumbnailVertical ? (
        <Grid.Unit size={[1 / 2, 1 / 4]}>
          <SquareView
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'border.default',
              borderRadius: 2,
              backgroundColor: 'neutral.emphasisPlus',
            }}
          >
            <View
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Link target={'_blank'} href={thumbnailVertical.url}>
                <Image src={thumbnailVertical.url} alt={thumbnailVertical.url} fill style={{ objectFit: 'contain' }} />
                <View
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    left: 0,
                    padding: 2,
                  }}
                >
                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.onEmphasis' }}>세로 썸네일</Text>
                </View>
              </Link>
            </View>
          </SquareView>
        </Grid.Unit>
      ) : null}
    </Grid>
  );
};

export default TaskSolutionVideoGrid;
