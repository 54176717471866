import Image from '../../core/Image';
import View from '../../core/View';

type Props = {
  appDialogMobileImage?: string | null;
  appDialogTabletImage?: string | null;
};
const AppDialogImageTypePreview = ({ appDialogMobileImage, appDialogTabletImage }: Props) => {
  return (
    <View>
      {appDialogMobileImage && (
        <View
          sx={{
            position: 'relative',
            width: '100%',
            borderRadius: 2,
            aspectRatio: '300 / 500',
            overflow: 'hidden',
            backgroundColor: 'white',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'border.default',
          }}
        >
          <Image src={appDialogMobileImage} alt={'모바일 전용 이미지'} fill style={{ objectFit: 'cover' }} />
        </View>
      )}
      {appDialogTabletImage && (
        <View
          sx={{
            position: 'relative',
            width: '100%',
            borderRadius: 2,
            aspectRatio: '1080 / 500',
            overflow: 'hidden',
            marginTop: 4,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'border.default',
          }}
        >
          <Image src={appDialogTabletImage} alt={'태블릿 전용 이미지'} fill style={{ objectFit: 'cover' }} />
        </View>
      )}
    </View>
  );
};

export default AppDialogImageTypePreview;
