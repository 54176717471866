import { editor } from 'monaco-editor';
import { forwardRef, Ref } from 'react';

import Editor, { EditorProps, Monaco } from '../Editor';

import { getCompletionItems } from './utils/autoComplete';

type Props = {} & Omit<EditorProps, 'language'>;

let registered = false;

const SQLEditor = ({ beforeMount, ...props }: Props, ref: Ref<editor.IStandaloneCodeEditor>) => {
  const LANGUAGE_NAME = 'sql';

  const handleBeforeMount = (monaco: Monaco) => {
    if (registered) {
      return;
    }

    // Register a new language
    monaco.languages.register({ id: LANGUAGE_NAME });

    monaco.languages.onLanguage(LANGUAGE_NAME, () => {
      monaco.languages.registerCompletionItemProvider(LANGUAGE_NAME, {
        provideCompletionItems: () => {
          return {
            suggestions: getCompletionItems(monaco),
          };
        },
      } as any);
    });

    beforeMount?.(monaco);

    registered = true;
  };

  return <Editor ref={ref} beforeMount={handleBeforeMount} language={LANGUAGE_NAME} {...props} />;
};

export default forwardRef(SQLEditor);
export type { Props as SQLEditorProps };
