import { graphql, useFragment } from 'react-relay';

import { BookStatusLabel_book$key, BookStatusEnum } from '../../../relay/__generated__/BookStatusLabel_book.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const BookStatusLabel_book = graphql`
  fragment BookStatusLabel_book on Book {
    status
  }
`;

type Props = {
  book: BookStatusLabel_book$key;
} & Omit<LabelProps, 'variant'>;

const BookStatusLabel = ({ book, ...props }: Props) => {
  const { status } = useFragment(BookStatusLabel_book, book);

  const labelPropsByStatus: Record<BookStatusEnum, LabelProps> = {
    'ongoing': { variant: 'default' },
    'completed': { variant: 'success' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'BookStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default BookStatusLabel;
