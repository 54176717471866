import { KebabHorizontalIcon, PencilIcon, TrashIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { graphql } from 'react-relay';

import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import DateText from '../../components/core/DateText';
import DialogButton from '../../components/core/DialogButton';
import EnumPair from '../../components/core/EnumPair';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import StuffUpdateDialog from '../../components/stuff/StuffUpdateDialog';
import UserAvatarText from '../../components/user/UserAvatarText';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { StuffId_stuffDeleteMutation } from '../../relay/__generated__/StuffId_stuffDeleteMutation.graphql';
import { StuffId_stuffQuery } from '../../relay/__generated__/StuffId_stuffQuery.graphql';
import { NextPage } from '../_app';

const stuffForStuffId = graphql`
  query StuffId_stuffQuery($id: ID!) {
    stuff(id: $id) {
      id
      title
      actions
      description
      type
      createdBy {
        id
        ...UserAvatarText_user
      }
      created
      modifiedBy {
        id
        ...UserAvatarText_user
      }
      modified
      ...StuffUpdateDialog_stuff
    }
  }
`;

type Props = {};

const StuffId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ stuff }] = useLazyLoadQuery<StuffId_stuffQuery>(stuffForStuffId, { id: router.query.stuffId as string });
  if (!stuff) return null;
  const { id, title, actions, description, type, createdBy, created, modifiedBy, modified } = stuff;

  return (
    <View>
      <Head siteTitle={`물품 - ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{title}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              {actions.includes('stuff_update') ? (
                <Stack.Item>
                  <DialogButton
                    renderDialog={({ closeDialog, isOpen }) => (
                      <StuffUpdateDialog
                        stuff={stuff}
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        config={{
                          onCompleted: () => {
                            toast('물품 수정 완료!', 'success');
                            closeDialog();
                          },
                        }}
                      />
                    )}
                    variant={'outline'}
                    leadingIcon={PencilIcon}
                  >
                    수정하기
                  </DialogButton>
                </Stack.Item>
              ) : null}
              {actions.includes('stuff_delete') ? (
                <Stack.Item>
                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.MutationItem<StuffId_stuffDeleteMutation>
                          mutation={graphql`
                            mutation StuffId_stuffDeleteMutation($input: StuffDeleteInput!) {
                              stuffDelete(input: $input) {
                                id @deleteRecord
                              }
                            }
                          `}
                          input={{ id }}
                          variant={'danger'}
                          config={{
                            onCompleted: () => {
                              router.replace('/stuff').then(() => toast('물품 삭제 완료!', 'success'));
                            },
                          }}
                          message={'정말 삭제할까요?'}
                        >
                          <ActionList.LeadingVisual>
                            <TrashIcon />
                          </ActionList.LeadingVisual>
                          삭제하기
                        </ActionList.MutationItem>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Stack.Item>
              ) : null}
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <ItemList
              items={[
                {
                  id: 'description',
                  title: '설명',
                  description: (
                    <Text sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{description || '-'}</Text>
                  ),
                },
                { id: 'type', title: '타입', description: <EnumPair typename={'StuffTypeEnum'}>{type}</EnumPair> },
              ]}
              renderItem={({ title, description }) => (
                <View>
                  <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                  <View
                    sx={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: 'border.default',
                      borderRadius: 2,
                      marginTop: 2,
                      padding: 3,
                    }}
                  >
                    <Text sx={{ fontSize: 1 }}>{description}</Text>
                  </View>
                </View>
              )}
              renderItemWrapper={(children, { id }, i) => (
                <View key={id} sx={{ marginTop: i > 0 ? 3 : 0 }}>
                  {children}
                </View>
              )}
            />
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <ItemList
              items={[
                { id: 'createdBy', title: '제작자', description: <UserAvatarText user={createdBy} /> },
                { id: 'created', title: '제작일', description: <DateText>{created}</DateText> },
                {
                  id: 'modifiedBy',
                  title: '수정자',
                  description: modifiedBy ? <UserAvatarText user={modifiedBy} /> : '-',
                },
                { id: 'modified', title: '수정일', description: <DateText>{modified}</DateText> },
              ]}
              renderItem={({ title, description }) => (
                <Grid gapX={1} gapY={1}>
                  <Grid.Unit size={[1, 1, 'max']}>
                    <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 'min']}>
                    <Text sx={{ fontSize: 1 }}>{description}</Text>
                  </Grid.Unit>
                </Grid>
              )}
              renderItemWrapper={(children, { id }, i) => (
                <View key={id} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                  {children}
                </View>
              )}
            />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

StuffId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
StuffId.authenticated = true;
StuffId.routes = [
  {
    id: 'stuffId',
    pathname: '/stuff/[stuffId]',
    name: '물품 상세',
    permissions: ['stuff_read'],
  },
];

export default StuffId;
