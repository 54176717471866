/**
 * @generated SignedSource<<bd20a0122f5f71a2513e5bdda172fac0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RaffleStuffPreviewItem_raffleStuff$data = {
  readonly backgroundColor: string;
  readonly colorThumbnail: {
    readonly objectUrl: string;
  };
  readonly " $fragmentType": "RaffleStuffPreviewItem_raffleStuff";
};
export type RaffleStuffPreviewItem_raffleStuff$key = {
  readonly " $data"?: RaffleStuffPreviewItem_raffleStuff$data;
  readonly " $fragmentSpreads": FragmentRefs<"RaffleStuffPreviewItem_raffleStuff">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RaffleStuffPreviewItem_raffleStuff",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UploadResult",
      "kind": "LinkedField",
      "name": "colorThumbnail",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "objectUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    }
  ],
  "type": "RaffleStuff",
  "abstractKey": null
};

(node as any).hash = "82060145cda94e6e3467cc04981b5aa4";

export default node;
