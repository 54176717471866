/**
 * @generated SignedSource<<815abb97e56286e056033686400e5e03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuffTypeEnum = "coupon" | "delivery" | "gifticon" | "premium_non_refundable" | "premium_refundable" | "%future added value";
export type StuffCreateInput = {
  description?: string | null;
  title: string;
  type: StuffTypeEnum;
};
export type StuffCreateDialog_stuffCreateMutation$variables = {
  input: StuffCreateInput;
};
export type StuffCreateDialog_stuffCreateMutation$data = {
  readonly stuffCreate: {
    readonly id: string;
  };
};
export type StuffCreateDialog_stuffCreateMutation = {
  response: StuffCreateDialog_stuffCreateMutation$data;
  variables: StuffCreateDialog_stuffCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Stuff",
    "kind": "LinkedField",
    "name": "stuffCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StuffCreateDialog_stuffCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StuffCreateDialog_stuffCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ec9726fab1d3295f7266a9192031395",
    "id": null,
    "metadata": {},
    "name": "StuffCreateDialog_stuffCreateMutation",
    "operationKind": "mutation",
    "text": "mutation StuffCreateDialog_stuffCreateMutation(\n  $input: StuffCreateInput!\n) {\n  stuffCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "68fd8d94e60299854e7bec09e41d3dde";

export default node;
