import { graphql } from 'react-relay';

import withSuspense from '../../../hocs/withSuspense';
import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  UnitSchoolGradeSelectField_unitQuery,
  UnitSchoolGradeSelectField_unitQuery$variables,
} from '../../../relay/__generated__/UnitSchoolGradeSelectField_unitQuery.graphql';
import EnumPair from '../../core/EnumPair';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const unitForUnitSchoolGradeSelectField = graphql`
  query UnitSchoolGradeSelectField_unitQuery($id: ID!) {
    unit(id: $id) {
      id
      schoolGrades
    }
  }
`;

type Props = { variables: UnitSchoolGradeSelectField_unitQuery$variables } & Omit<SelectFieldProps, 'options'>;

const UnitSchoolGradeSelectField = ({ variables, ...props }: Props) => {
  const [{ unit }] = useLazyLoadQuery<UnitSchoolGradeSelectField_unitQuery>(unitForUnitSchoolGradeSelectField, {
    ...variables,
  });

  return (
    <SelectField
      options={unit.schoolGrades.map((schoolGrade) => ({
        id: schoolGrade,
        text: (<EnumPair typename={'UnitSchoolGradeEnum'}>{schoolGrade}</EnumPair>) as unknown as string,
      }))}
      {...props}
    />
  );
};

export default withSuspense(UnitSchoolGradeSelectField);
