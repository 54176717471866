import React from 'react';

type CreateCtx<T> = readonly [() => T, React.Provider<T | undefined>, React.Consumer<T>];

// create context with no upfront defaultValue
// without having to do undefined check all the time
function createCtx<T>(name = ''): CreateCtx<T> {
  const context = React.createContext<T | undefined>(undefined);

  function useCtx(): T {
    const c = React.useContext(context);
    if (!c) {
      throw new Error(`${[name]} useCtx must be inside a Provider with a value`);
    }
    return c;
  }

  // make TypeScript infer a tuple, not an array of union types
  return [useCtx, context.Provider, context.Consumer as any] as const;
}

export default createCtx;
