import createCtx from '../../utils/context';

type MeContextValue = {
  id: string;
  name: string;
  email: string;
};

const [useContext, Provider] = createCtx<MeContextValue>();

export { useContext as useMeContext, Provider as MeContextProvider };
