import { GraphQLError } from 'graphql';

const parseGraphQLError = (e: GraphQLError) => {
  const { source } = e;
  if (source) {
    return source.errors.map(({ extensions }) => extensions);
  }

  return null;
};

export { parseGraphQLError };
