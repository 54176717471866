import { CheckIcon } from '@primer/octicons-react';
import { Grid } from '@teamturing/react-kit';
import { Suspense } from 'react';
import { graphql } from 'react-relay';

import { MATInvoiceUpdateDialog_invoiceUpdateMutation } from '../../../relay/__generated__/MATInvoiceUpdateDialog_invoiceUpdateMutation.graphql';
import Button from '../../core/Button';
import DateField from '../../core/DateField';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

type Props = {} & DialogProps &
  Pick<
    MutationFormikProps<MATInvoiceUpdateDialog_invoiceUpdateMutation>,
    'config' | 'onSubmit' | 'connections' | 'initialValues'
  >;

const MATInvoiceUpdateDialog = ({ initialValues, config, onSubmit, connections, ...props }: Props) => {
  return (
    <Dialog wide {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>이용 정보 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<MATInvoiceUpdateDialog_invoiceUpdateMutation>
        mutation={graphql`
          mutation MATInvoiceUpdateDialog_invoiceUpdateMutation($input: MATInvoiceUpdateInput!) {
            invoiceUpdate(input: $input) {
              id
              title
              premiumType
              scholarshipAmount
              startDate
              endDate
              maxStudentCount
              amount
            }
          }
        `}
        initialValues={initialValues}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit }, { nonFieldErrorRef, scrollContainerRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <TextField label={'제목'} name={'title'} required placeholder={'e.g. 조직 이름 혹은 체험용 입력'} />
                <Suspense fallback={<Spinner />}>
                  <EnumPairRadioGroupField
                    label={'타입'}
                    name={'premiumType'}
                    required
                    typename={'MATOrganizationPremiumTypeEnum'}
                    renderOptionWrapper={(children, { id }) => (
                      <Grid.Unit key={id} size={1 / 2}>
                        {children}
                      </Grid.Unit>
                    )}
                    renderContainer={(children) => <Grid gapX={3}>{children}</Grid>}
                  />
                </Suspense>
                <NumberField
                  label={'일 환급 금액'}
                  name={'scholarshipAmount'}
                  required
                  trailingVisual={'원'}
                  placeholder={'환급 금액 입력'}
                />
                <Grid gapX={3} gapY={2}>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <DateField label={'시작일'} name={'startDate'} required placeholder={'시작일 선택'} />
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <DateField label={'종료일'} name={'endDate'} required placeholder={'종료일 선택'} />
                  </Grid.Unit>
                </Grid>
                <NumberField
                  label={'최대 인원 수'}
                  name={'maxStudentCount'}
                  required
                  trailingVisual={'명'}
                  placeholder={'최대 인원 수 입력'}
                />
                <NumberField
                  label={'결제 금액'}
                  name={'amount'}
                  required
                  trailingVisual={'원'}
                  placeholder={'결제 금액 입력'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default MATInvoiceUpdateDialog;
