import MonacoEditor, { EditorProps as MonacoEditorProps, loader, Monaco } from '@monaco-editor/react';
import { useTheme } from '@primer/react';
import { editor } from 'monaco-editor';
import { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react';

import Card, { CardProps } from '../Card';
import Spinner from '../Spinner';
import View from '../View';

type Props = {
  id?: string;
  disabled?: boolean;
  onFocus?: (editor: editor.IStandaloneCodeEditor) => void;
  onBlur?: (editor: editor.IStandaloneCodeEditor) => void;
  type?: EditorRenderType;
} & Omit<MonacoEditorProps, 'options'> &
  Pick<NonNullable<MonacoEditorProps['options']>, 'readOnly' | 'lineNumbers'> &
  Pick<CardProps, 'sx'>;

type EditorRenderType = 'default' | 'scroll';

/**
 * monaco language service provider (custom token)
 * const willMountHandler = () => {}
 * https://microsoft.github.io/monaco-editor/playground.html#extending-language-services-custom-languages
 */
loader.config({ paths: { vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.34.1/min/vs' } });

// editor.EndOfLineSequence.LF
const DEFAULT_EOL = 0;

const Editor = (
  {
    id = '',
    disabled = false,
    type = 'default',
    onMount,
    onFocus,
    onBlur,
    readOnly,
    sx,
    height = 200,
    lineNumbers,
    ...props
  }: Props,
  ref: Ref<editor.IStandaloneCodeEditor | null>,
) => {
  const { theme, resolvedColorMode } = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const [editor, setEditor] = useState<editor.IStandaloneCodeEditor>();
  const [isWheelEventHandled, setIsWheelEventHandled] = useState(false);

  const editorTheme: MonacoEditorProps['theme'] =
    resolvedColorMode === 'day' || resolvedColorMode === 'light' ? 'light' : 'vs-dark';

  const handleMount: MonacoEditorProps['onMount'] = (editor, monaco) => {
    editor.getModel()?.setEOL(DEFAULT_EOL);
    editor.onDidFocusEditorText(() => {
      if (editor.getModel()?.getEndOfLineSequence() !== DEFAULT_EOL) {
        editor.getModel()?.setEOL(DEFAULT_EOL);
      }
      onFocus?.(editor);
    });
    editor.onDidBlurEditorText(() => onBlur?.(editor));

    const updateEditorLayout = () => {
      editor?.layout({ height: editor.getContentHeight(), width: containerRef.current?.clientWidth || 1000 });
    };

    editor.onDidContentSizeChange((e) => {
      if (type === 'default') {
        updateEditorLayout();
      }
      if (type === 'scroll') {
        const isContentOverflow = (containerRef.current?.clientHeight || 0) < e.contentHeight;
        setIsWheelEventHandled(isContentOverflow);
      }
    });
    updateEditorLayout();

    onMount?.(editor, monaco);
    setEditor(editor);
    const textareaInsideEditor = editor.getDomNode()?.querySelector('textarea');
    if (textareaInsideEditor) {
      textareaInsideEditor.id = id || '';
    }
  };

  useImperativeHandle(ref, () => (editor ? editor : null), [editor]);

  return (
    <Card
      ref={containerRef}
      sx={{
        'minHeight': height,
        'position': 'relative',
        ':focus-within': {
          borderColor: 'accent.fg',
        },
        ...sx,
        ...(type === 'default' ? { height: 'fit-content' } : type === 'scroll' ? { height } : null),
        ...(disabled ? { cursor: 'not-allowed' } : null),
      }}
      onClick={disabled ? undefined : () => editor?.focus()}
    >
      <MonacoEditor
        options={{
          fixedOverflowWidgets: true,
          fontSize: theme?.fontSizes[1] || '14px',
          minimap: { enabled: false },
          wrappingStrategy: 'advanced',
          wordWrap: 'on',
          scrollBeyondLastLine: false,
          automaticLayout: true,
          cursorSmoothCaretAnimation: true,
          contextmenu: false,
          glyphMargin: false,
          cursorBlinking: 'smooth',
          lineNumbersMinChars: 2,
          lightbulb: { enabled: false },
          scrollbar: {
            handleMouseWheel: isWheelEventHandled,
          },
          lineNumbers,
          readOnly,
          ...(disabled ? { tabIndex: -1 } : {}),
        }}
        loading={<Spinner />}
        onMount={handleMount}
        theme={editorTheme}
        {...props}
      />
      {disabled ? (
        <View
          sx={{
            height: '100%',
            width: '100%',
            backgroundColor: 'canvas.inset',
            opacity: 0.6,
            position: 'absolute',
            top: 0,
          }}
        />
      ) : null}
    </Card>
  );
};

export default forwardRef(Editor);
export type { Props as EditorProps, Monaco };
