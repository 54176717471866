/**
 * @generated SignedSource<<feff19058c5d5b9a8ea7cfc7dfe5875f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormulaDifferenceItemList_formulaDifference$data = {
  readonly text: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly title: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly " $fragmentType": "FormulaDifferenceItemList_formulaDifference";
};
export type FormulaDifferenceItemList_formulaDifference$key = {
  readonly " $data"?: FormulaDifferenceItemList_formulaDifference$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormulaDifferenceItemList_formulaDifference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "DiffItem_diff"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormulaDifferenceItemList_formulaDifference",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Diff",
      "kind": "LinkedField",
      "name": "text",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Diff",
      "kind": "LinkedField",
      "name": "title",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "FormulaDifference",
  "abstractKey": null
};
})();

(node as any).hash = "26e3940c5e355b88cc426ae81ffeee06";

export default node;
