/**
 * @generated SignedSource<<ece817da9b1e2ab057827e51214dada2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskLabelingCountEnum = "one" | "two" | "%future added value";
export type TaskProblemAnswerTypeEnum = "objective" | "subjective" | "%future added value";
export type TaskProblemCreationTypeEnum = "first" | "original" | "second" | "third" | "%future added value";
export type TaskProblemTypeEnum = "copy" | "exam" | "private" | "problem_generator" | "pure" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskDescriptionList_task$data = {
  readonly aiDifficulty: number | null;
  readonly aiFile: {
    readonly id: string;
    readonly objectUrl: string;
  } | null;
  readonly assigned: any | null;
  readonly assignedTo: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  } | null;
  readonly difficulty: number | null;
  readonly finalLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly unitDs: ReadonlyArray<{
      readonly description: string | null;
      readonly id: string;
      readonly unitATitle: string;
      readonly unitBTitle: string;
      readonly unitCTitle: string;
    }>;
  } | null;
  readonly firstLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly unitDs: ReadonlyArray<{
      readonly description: string | null;
      readonly id: string;
      readonly unitATitle: string;
      readonly unitBTitle: string;
      readonly unitCTitle: string;
    }>;
  } | null;
  readonly generatedProblem: {
    readonly id: string;
    readonly problemGenerator: {
      readonly id: string;
    };
  } | null;
  readonly id: string;
  readonly labelingCount: TaskLabelingCountEnum | null;
  readonly originalTask: {
    readonly id: string;
  } | null;
  readonly problem: {
    readonly answer: string | null;
    readonly answerChoiceCount: number | null;
    readonly answerType: TaskProblemAnswerTypeEnum | null;
    readonly creationType: TaskProblemCreationTypeEnum | null;
    readonly id: string;
    readonly problemType: TaskProblemTypeEnum;
  } | null;
  readonly problemGenerator: {
    readonly id: string;
  } | null;
  readonly publishedUnitDs: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly unitATitle: string;
    readonly unitBTitle: string;
    readonly unitCTitle: string;
  }>;
  readonly scrap: {
    readonly id: string;
    readonly scrapSource: {
      readonly id: string;
      readonly title: string;
    };
  } | null;
  readonly secondLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly unitDs: ReadonlyArray<{
      readonly description: string | null;
      readonly id: string;
      readonly unitATitle: string;
      readonly unitBTitle: string;
      readonly unitCTitle: string;
    }>;
  } | null;
  readonly title: string;
  readonly typingAutomationScript: {
    readonly id: string;
    readonly title: string;
  } | null;
  readonly unitDs: ReadonlyArray<{
    readonly description: string | null;
    readonly id: string;
    readonly unitATitle: string;
    readonly unitBTitle: string;
    readonly unitCTitle: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"TaskStatusLabel_task">;
  readonly " $fragmentType": "TaskDescriptionList_task";
};
export type TaskDescriptionList_task$key = {
  readonly " $data"?: TaskDescriptionList_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskDescriptionList_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  (v0/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ProblemGenerator",
  "kind": "LinkedField",
  "name": "problemGenerator",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
},
v6 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "order": "DESC"
    }
  }
],
v7 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unitATitle",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unitBTitle",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unitCTitle",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "description",
    "storageKey": null
  }
],
v8 = {
  "alias": null,
  "args": (v6/*: any*/),
  "concreteType": "UnitD",
  "kind": "LinkedField",
  "name": "unitDs",
  "plural": true,
  "selections": (v7/*: any*/),
  "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
},
v9 = [
  (v0/*: any*/),
  (v5/*: any*/),
  (v8/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskDescriptionList_task",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProblem",
      "kind": "LinkedField",
      "name": "problem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problemType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creationType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerChoiceCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Scrap",
      "kind": "LinkedField",
      "name": "scrap",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ScrapSource",
          "kind": "LinkedField",
          "name": "scrapSource",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaskStatusLabel_task"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "assignedTo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assigned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "aiFile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "objectUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "GeneratedProblem",
      "kind": "LinkedField",
      "name": "generatedProblem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "originalTask",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "labelingCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskLabeling",
      "kind": "LinkedField",
      "name": "firstLabeling",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskLabeling",
      "kind": "LinkedField",
      "name": "secondLabeling",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskLabeling",
      "kind": "LinkedField",
      "name": "finalLabeling",
      "plural": false,
      "selections": (v9/*: any*/),
      "storageKey": null
    },
    (v8/*: any*/),
    {
      "alias": null,
      "args": (v6/*: any*/),
      "concreteType": "UnitD",
      "kind": "LinkedField",
      "name": "publishedUnitDs",
      "plural": true,
      "selections": (v7/*: any*/),
      "storageKey": "publishedUnitDs(order:{\"order\":\"DESC\"})"
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aiDifficulty",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TypingAutomationScript",
      "kind": "LinkedField",
      "name": "typingAutomationScript",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};
})();

(node as any).hash = "abd034401be04fe6784cdb4dc60492f0";

export default node;
