/**
 * @generated SignedSource<<5f9f04b508921caf2bdddc7f6a96c8d3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NotificationTypeEnum = "bill_completed" | "%future added value";
export type NotificationFilter = {
  AND?: NotificationFilter | null;
  DISTINCT?: boolean | null;
  NOT?: NotificationFilter | null;
  OR?: NotificationFilter | null;
  checked_Isnull?: boolean | null;
  created_Gte?: any | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  nodeId_Exact?: string | null;
  nodeId_In?: ReadonlyArray<string> | null;
  read_Isnull?: boolean | null;
  type_In?: ReadonlyArray<NotificationTypeEnum> | null;
};
export type NotificationHeaderItem_unchecked_notificationsQuery$variables = {
  filters?: NotificationFilter | null;
};
export type NotificationHeaderItem_unchecked_notificationsQuery$data = {
  readonly notifications: {
    readonly totalCount: number | null;
  };
};
export type NotificationHeaderItem_unchecked_notificationsQuery = {
  response: NotificationHeaderItem_unchecked_notificationsQuery$data;
  variables: NotificationHeaderItem_unchecked_notificationsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      }
    ],
    "concreteType": "NotificationConnection",
    "kind": "LinkedField",
    "name": "notifications",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationHeaderItem_unchecked_notificationsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationHeaderItem_unchecked_notificationsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "afb14d8b3989be84ce4938540d9bbe98",
    "id": null,
    "metadata": {},
    "name": "NotificationHeaderItem_unchecked_notificationsQuery",
    "operationKind": "query",
    "text": "query NotificationHeaderItem_unchecked_notificationsQuery(\n  $filters: NotificationFilter\n) {\n  notifications(filters: $filters) {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "20daafba69497afb0c6f511a149dc88e";

export default node;
