import { editor } from 'monaco-editor';
import React from 'react';

import Button, { ButtonProps } from '../Button';
import Stack, { StackProps } from '../Stack';

type Props = {
  editor: editor.IStandaloneCodeEditor;
} & StackProps &
  Omit<ButtonProps, 'children' | 'onClick'>;

const KatexEditorShortcutButtonsStack = ({ editor, sx, gapX, gapY, wrap, ...props }: Props) => {
  const shortcutButtons = [
    '　',
    '㉠',
    '㉡',
    '㉢',
    '㉣',
    '㉤',
    '㉥',
    '①',
    '②',
    '③',
    '④',
    '⑤',
    '⑥',
    'ⅰ',
    'ⅱ',
    'ⅲ',
    'ⅳ',
    'ⅴ',
    'ⅵ',
    '‘’',
  ].map((char) => (
    <Stack.Item key={char}>
      <Button
        variant={'invisible'}
        onClick={() => {
          if (!editor) return;
          const cursorSelections = editor.getSelections();

          if (cursorSelections) {
            editor.executeEdits(
              null,
              cursorSelections.map((selection) => ({
                range: selection,
                text: char,
                forceMoveMarkers: true,
              })),
            );
          }
          editor.focus();
        }}
        sx={{ paddingX: 2, color: 'fg.default', ...sx }}
        {...props}
      >
        {char === '　' ? '공백' : char}
      </Button>
    </Stack.Item>
  ));

  return (
    <Stack gapX={gapX} gapY={gapY} wrap={wrap}>
      {shortcutButtons}
    </Stack>
  );
};

export default KatexEditorShortcutButtonsStack;
