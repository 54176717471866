/**
 * @generated SignedSource<<f61e1ea6b62b9c2956f99cc379b66e5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BillActionEnum = "bill_delete" | "bill_payment_link_copy" | "bill_send_sms" | "bill_xlsx_import" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BillConnectionDataTable_billConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly actions: ReadonlyArray<BillActionEnum>;
      readonly additionalDiscount: boolean;
      readonly created: any;
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly expired: any;
      readonly finalPrice: number;
      readonly id: string;
      readonly paymentLink: string;
      readonly product: {
        readonly additionalDiscountAmount: number;
        readonly id: string;
        readonly title: string;
      };
      readonly purchaserNumber: any;
      readonly studentName: string;
      readonly studentNumber: any;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"BillSendMessageDialog_bill" | "BillStatusLabel_bill">;
    };
  }>;
  readonly " $fragmentType": "BillConnectionDataTable_billConnection";
};
export type BillConnectionDataTable_billConnection$key = {
  readonly " $data"?: BillConnectionDataTable_billConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillConnectionDataTable_billConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillConnectionDataTable_billConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BillEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Bill",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BillStatusLabel_bill"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "product",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "additionalDiscountAmount",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "additionalDiscount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "finalPrice",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "studentName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "studentNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "purchaserNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "expired",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paymentLink",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BillSendMessageDialog_bill"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BillConnection",
  "abstractKey": null
};
})();

(node as any).hash = "2ff9f367b2a9855f6685bee8e33fe804";

export default node;
