import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  RaffleStuffSelectField_raffleStuffsQuery,
  RaffleStuffSelectField_raffleStuffsQuery$variables,
} from '../../../relay/__generated__/RaffleStuffSelectField_raffleStuffsQuery.graphql';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const raffleStuffsForRaffleStuffSelectField = graphql`
  query RaffleStuffSelectField_raffleStuffsQuery($filters: RaffleStuffFilter) {
    raffleStuffs(filters: $filters) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = { variables?: RaffleStuffSelectField_raffleStuffsQuery$variables } & Omit<SelectFieldProps, 'options'>;

const RaffleStuffSelectField = ({ variables, ...props }: Props) => {
  const [{ raffleStuffs }] = useLazyLoadQuery<RaffleStuffSelectField_raffleStuffsQuery>(
    raffleStuffsForRaffleStuffSelectField,
    variables || {},
    {
      fetchPolicy: 'store-and-network',
    },
  );

  return <SelectField options={raffleStuffs.edges.map(({ node }) => ({ id: node.id, text: node.title }))} {...props} />;
};

export default RaffleStuffSelectField;
