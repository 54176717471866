/**
 * @generated SignedSource<<108e26ed9fce6724a8075b511ded1a20>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceInnerPdfUploadDialog_schoolExamSource$data = {
  readonly id: string;
  readonly " $fragmentType": "SchoolExamSourceInnerPdfUploadDialog_schoolExamSource";
};
export type SchoolExamSourceInnerPdfUploadDialog_schoolExamSource$key = {
  readonly " $data"?: SchoolExamSourceInnerPdfUploadDialog_schoolExamSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceInnerPdfUploadDialog_schoolExamSource">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceInnerPdfUploadDialog_schoolExamSource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "SchoolExamSource",
  "abstractKey": null
};

(node as any).hash = "abe6cfa6c1396ee5dfd039fa81dc5fae";

export default node;
