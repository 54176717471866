/**
 * @generated SignedSource<<552140448afbd5b15a97294228a06bb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProductDeleteInput = {
  id: string;
};
export type ProductId_productDeleteMutation$variables = {
  input: ProductDeleteInput;
};
export type ProductId_productDeleteMutation$data = {
  readonly productDelete: {
    readonly id: string;
  };
};
export type ProductId_productDeleteMutation = {
  response: ProductId_productDeleteMutation$data;
  variables: ProductId_productDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductId_productDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "productDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductId_productDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "productDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa2ce06cee93d4bf2c985a0b8744e486",
    "id": null,
    "metadata": {},
    "name": "ProductId_productDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ProductId_productDeleteMutation(\n  $input: ProductDeleteInput!\n) {\n  productDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "55e12b94fa5a549fb731873c775b6cd3";

export default node;
