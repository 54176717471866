import { clipboard } from '@teamturing/utils';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { TaskDemoDialog_task$key } from '../../../relay/__generated__/TaskDemoDialog_task.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import Editor from '../../core/Editor';
import Grid from '../../core/Grid';
import View from '../../core/View';

const TaskDemoDialog_task = graphql`
  fragment TaskDemoDialog_task on Task {
    uuid
  }
`;

type Props = {
  task: TaskDemoDialog_task$key;
} & DialogProps;

const TaskDemoDialog = ({ task, ...props }: Props) => {
  const { uuid } = useFragment(TaskDemoDialog_task, task);

  const iframSrc = `${process.env.NEXT_PUBLIC_IAMMATHKING_END_POINT}learn?sp=${uuid}`;
  const iframeCode = `<iframe width="390" height="800" src="${iframSrc}" style="border: none"></iframe>`;

  const { toast } = useToast();

  return (
    <Dialog wide fillHeight {...props}>
      <Dialog.Header>퍼가기</Dialog.Header>
      <Dialog.Body sx={{ padding: 0 }}>
        <Grid sx={{ height: '100%' }}>
          <Grid.Unit size={1 / 2}>
            <iframe src={iframSrc} width={'100%'} height={'100%'} style={{ border: 'none' }} />
          </Grid.Unit>
          <Grid.Unit size={1 / 2}>
            <View minHeight={'100%'} backgroundColor={'neutral.subtle'} padding={3}>
              <Editor language={'html'} readOnly lineNumbers={'off'} value={iframeCode} />
            </View>
          </Grid.Unit>
        </Grid>
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          size={'large'}
          variant={'primary'}
          onClick={() => {
            clipboard
              .writeText(iframeCode)
              .then(() => toast('코드가 복사됐어요!', 'success'))
              .catch(() => toast('코드 복사에 실패했어요!', 'error'));
          }}
        >
          코드 복사하기
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
};

export default TaskDemoDialog;
export type { Props as TaskDemoDialogProps };
