import { CheckIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { WorkbookUpdateDialog_workbook$key } from '../../../relay/__generated__/WorkbookUpdateDialog_workbook.graphql';
import { WorkbookUpdateDialog_workbookUpdateMutation } from '../../../relay/__generated__/WorkbookUpdateDialog_workbookUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const WorkbookUpdateDialog_workbook = graphql`
  fragment WorkbookUpdateDialog_workbook on Workbook {
    id
    title
    grade
    month
    year
    sourceName
  }
`;

type Props = { workbook: WorkbookUpdateDialog_workbook$key } & DialogProps &
  Pick<MutationFormikProps<WorkbookUpdateDialog_workbookUpdateMutation>, 'config' | 'onSubmit' | 'connections'>;

const WorkbookUpdateDialog = ({ workbook, config, onSubmit, connections, ...props }: Props) => {
  const { id, title, grade, month, year, sourceName } = useFragment(WorkbookUpdateDialog_workbook, workbook);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>기출 정보 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<WorkbookUpdateDialog_workbookUpdateMutation>
        mutation={graphql`
          mutation WorkbookUpdateDialog_workbookUpdateMutation($input: WorkbookUpdateInput!) {
            workbookUpdate(input: $input) {
              id
              ...WorkbookStatusLabel_workbook
              ...WorkbookUpdateDialog_workbook
              modified
              modifiedBy {
                ...UserAvatarText_user
              }
            }
          }
        `}
        initialValues={{
          id,
          title,
          grade,
          month,
          year,
          sourceName,
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField
                  name={'title'}
                  label={'기출명'}
                  placeholder={'기출명 입력'}
                  required
                  caption={'권장 20자 이내 (최대 32자)'}
                  maxLength={32}
                />
                <Grid gapX={3}>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <Suspense fallback={<Spinner />}>
                      <EnumPairSelectField
                        typename={'ScrapSourceExamGradeEnum'}
                        name={'grade'}
                        label={'학년'}
                        required
                        placeholder={'학년 선택'}
                      />
                    </Suspense>
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <Suspense fallback={<Spinner />}>
                      <EnumPairSelectField
                        typename={'ScrapSourceExamMonthEnum'}
                        name={'month'}
                        label={'월'}
                        required
                        placeholder={'월 선택'}
                      />
                    </Suspense>
                  </Grid.Unit>
                </Grid>
                <Suspense fallback={<Spinner />}>
                  <EnumPairSelectField
                    typename={'ScrapSourceExamYearEnum'}
                    name={'year'}
                    label={'연도'}
                    required
                    placeholder={'연도 선택'}
                  />
                </Suspense>
                <TextField name={'sourceName'} label={'출제기관'} required placeholder={'출제기관명 입력'} />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()} leadingIcon={CheckIcon}>
                저장하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default WorkbookUpdateDialog;
