import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  StuffSelectField_stuffsQuery,
  StuffSelectField_stuffsQuery$variables,
} from '../../../relay/__generated__/StuffSelectField_stuffsQuery.graphql';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const stuffsForStuffSelectField = graphql`
  query StuffSelectField_stuffsQuery($filters: StuffFilter) {
    stuffs(filters: $filters) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = { variables?: StuffSelectField_stuffsQuery$variables } & Omit<SelectFieldProps, 'options'>;

const StuffSelectField = ({ variables, ...props }: Props) => {
  const [{ stuffs }] = useLazyLoadQuery<StuffSelectField_stuffsQuery>(stuffsForStuffSelectField, variables || {}, {
    fetchPolicy: 'store-and-network',
  });

  return <SelectField options={stuffs.edges.map(({ node }) => ({ id: node.id, text: node.title }))} {...props} />;
};

export default StuffSelectField;
