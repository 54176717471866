/**
 * @generated SignedSource<<f6bd0f7a0529e184794b81143a39dfb5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type AppPushCreateDialog_appPushPreviewQuery$variables = {
  csvFile?: Upload | null;
  pushBody: string;
  pushTitle: string;
  query: string;
};
export type AppPushCreateDialog_appPushPreviewQuery$data = {
  readonly appPushPreview: {
    readonly rows: ReadonlyArray<{
      readonly body: string;
      readonly id: string;
      readonly title: string;
      readonly token: string;
    }>;
    readonly targetCount: number;
  };
};
export type AppPushCreateDialog_appPushPreviewQuery = {
  response: AppPushCreateDialog_appPushPreviewQuery$data;
  variables: AppPushCreateDialog_appPushPreviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "csvFile"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pushBody"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pushTitle"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "csvFile",
        "variableName": "csvFile"
      },
      {
        "kind": "Variable",
        "name": "pushBody",
        "variableName": "pushBody"
      },
      {
        "kind": "Variable",
        "name": "pushTitle",
        "variableName": "pushTitle"
      },
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }
    ],
    "concreteType": "AppPushPreview",
    "kind": "LinkedField",
    "name": "appPushPreview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "targetCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AppPushSqlRow",
        "kind": "LinkedField",
        "name": "rows",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppPushCreateDialog_appPushPreviewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppPushCreateDialog_appPushPreviewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "77f49dba2d509761461ab1cbb9ad57f2",
    "id": null,
    "metadata": {},
    "name": "AppPushCreateDialog_appPushPreviewQuery",
    "operationKind": "query",
    "text": "query AppPushCreateDialog_appPushPreviewQuery(\n  $csvFile: Upload = null\n  $pushBody: String!\n  $pushTitle: String!\n  $query: String!\n) {\n  appPushPreview(pushBody: $pushBody, pushTitle: $pushTitle, csvFile: $csvFile, query: $query) {\n    targetCount\n    rows {\n      id\n      title\n      token\n      body\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "edcfe438e90b7cb9c80df8890be4c08c";

export default node;
