/**
 * @generated SignedSource<<e7439a52d429a7c2d75f414db3095f68>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolExamProblemSubjectEnum = "english" | "korean" | "math" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SchoolExamProblemConnectionDataTable_schoolExamProblemConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly id: string;
      readonly modifiedBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly problemSubject: SchoolExamProblemSubjectEnum;
      readonly sequence: number;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"SchoolExamProblemStatusLabel_schoolExamProblem">;
    };
  }>;
  readonly " $fragmentType": "SchoolExamProblemConnectionDataTable_schoolExamProblemConnection";
};
export type SchoolExamProblemConnectionDataTable_schoolExamProblemConnection$key = {
  readonly " $data"?: SchoolExamProblemConnectionDataTable_schoolExamProblemConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamProblemConnectionDataTable_schoolExamProblemConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamProblemConnectionDataTable_schoolExamProblemConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SchoolExamProblemEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SchoolExamProblem",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SchoolExamProblemStatusLabel_schoolExamProblem"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "problemSubject",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "modifiedBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SchoolExamProblemConnection",
  "abstractKey": null
};
})();

(node as any).hash = "2fd14559910a13f26c9b1f689b723957";

export default node;
