/**
 * @generated SignedSource<<72aaf3b1df911488cc901f268ac6d274>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskProblemTypeEnum = "copy" | "exam" | "private" | "problem_generator" | "pure" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskProblemTypingDialog_task$data = {
  readonly id: string;
  readonly problem: {
    readonly id: string;
    readonly problem: string | null;
    readonly problemType: TaskProblemTypeEnum;
    readonly solution: string | null;
  } | null;
  readonly scrap: {
    readonly id: string;
    readonly problems: ReadonlyArray<{
      readonly id: string;
      readonly objectUrl: string;
    }>;
    readonly solutions: ReadonlyArray<{
      readonly id: string;
      readonly objectUrl: string;
    }>;
  } | null;
  readonly sequence: number;
  readonly title: string;
  readonly " $fragmentType": "TaskProblemTypingDialog_task";
};
export type TaskProblemTypingDialog_task$key = {
  readonly " $data"?: TaskProblemTypingDialog_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskProblemTypingDialog_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskProblemTypingDialog_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Scrap",
      "kind": "LinkedField",
      "name": "scrap",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "problems",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "solutions",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProblem",
      "kind": "LinkedField",
      "name": "problem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problem",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "solution",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problemType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};
})();

(node as any).hash = "4d10fa91205e37b0dc1c336cacba8a31";

export default node;
