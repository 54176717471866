import { CheckIcon, TrashIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { graphql } from 'react-relay';

import CommentCreateMutationFormik from '../../components/comment/CommentCreateMutationFormik';
import CommentMutationActionItem from '../../components/comment/CommentMutationActionItem';
import CommentPaginator from '../../components/comment/CommentPaginator';
import Button from '../../components/core/Button';
import Card from '../../components/core/Card';
import DataTable from '../../components/core/DataTable';
import DateText from '../../components/core/DateText';
import DialogButton from '../../components/core/DialogButton';
import DialogHandler from '../../components/core/DialogHandler';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import Image from '../../components/core/Image';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Lightbox from '../../components/core/Lightbox';
import MutationConfirmButton from '../../components/core/MutationConfirmButton';
import MutationConfirmIconButton from '../../components/core/MutationConfirmIconButton';
import SquareView from '../../components/core/SquareView';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import Timeline from '../../components/core/Timeline';
import View from '../../components/core/View';
import SchoolExamSourceDescriptionList from '../../components/schoolExamSource/SchoolExamSourceDescriptionList';
import SchoolExamSourceHistoryDialog from '../../components/schoolExamSource/SchoolExamSourceHistoryDialog';
import SchoolExamSourceHistoryTimelineItem from '../../components/schoolExamSource/SchoolExamSourceHistoryTimelineItem';
import SchoolExamSourceInnerPdfUploadDialog from '../../components/schoolExamSource/SchoolExamSourceInnerPdfUploadDialog';
import SchoolExamSourceSchoolExamScrapCreateDialog from '../../components/schoolExamSource/SchoolExamSourceSchoolExamScrapCreateDialog';
import SchoolExamSourceSchoolExamSourceHistoryPaginator from '../../components/schoolExamSource/SchoolExamSourceSchoolExamSourceHistoryPaginator';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import {
  Ordering,
  SchoolExamSourceId_commentsQuery,
} from '../../relay/__generated__/SchoolExamSourceId_commentsQuery.graphql';
import { SchoolExamSourceId_schoolExamProblemDeleteMutation } from '../../relay/__generated__/SchoolExamSourceId_schoolExamProblemDeleteMutation.graphql';
import { SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation } from '../../relay/__generated__/SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation.graphql';
import { SchoolExamSourceId_schoolExamSourcePublishMutation } from '../../relay/__generated__/SchoolExamSourceId_schoolExamSourcePublishMutation.graphql';
import { SchoolExamSourceId_schoolExamSourceQuery } from '../../relay/__generated__/SchoolExamSourceId_schoolExamSourceQuery.graphql';
import { SchoolExamSourceId_schoolExamSourceResetMutation } from '../../relay/__generated__/SchoolExamSourceId_schoolExamSourceResetMutation.graphql';
import { isNullable } from '../../utils/is';
import { numberWithCommas } from '../../utils/number';
import { scrollToBottom } from '../../utils/scroll';
import { NextPage } from '../_app';

const schoolExamSourceForSchoolExamSourceId = graphql`
  query SchoolExamSourceId_schoolExamSourceQuery(
    $id: ID!
    $problemFilters: SchoolExamProblemFilter
    $problemOrder: SchoolExamProblemOrder
    $scrapOrder: SchoolExamScrapOrder
  ) {
    schoolExamSource(id: $id) {
      id
      sequence
      actions
      school {
        id
        title
      }
      title
      ...SchoolExamSourceDescriptionList_schoolExamSource
      innerPdf {
        id
        key
        objectUrl
      }
      problems(filters: $problemFilters, order: $problemOrder) {
        totalCount
        edges {
          node {
            id
            sequence
            created
            pdf {
              id
              objectUrl
            }
          }
        }
      }
      scraps(order: $scrapOrder) {
        totalCount
        edges {
          node {
            id
            order
            text
            scrap {
              id
              objectUrl
            }
          }
        }
      }
      ...SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource
      ...SchoolExamSourceInnerPdfUploadDialog_schoolExamSource
      ...SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource
        @arguments(filters: $problemFilters, order: $problemOrder, scrapOrder: $scrapOrder)
    }
  }
`;

const commentsForSchoolExamSourceId = graphql`
  query SchoolExamSourceId_commentsQuery($filters: CommentFilter, $order: CommentOrder) {
    ...CommentPaginator_query @arguments(filters: $filters, order: $order)
  }
`;

type Props = {};

const SchoolExamSourceId: NextPage<Props> = () => {
  const router = useRouter();
  const { schoolExamSourceId } = router.query;
  const { toast } = useToast();

  const scrapOrder = { order: 'ASC' as Ordering };

  const [{ schoolExamSource }, refetch] = useLazyLoadQuery<SchoolExamSourceId_schoolExamSourceQuery>(
    schoolExamSourceForSchoolExamSourceId,
    {
      id: schoolExamSourceId as string,
      problemFilters: { status_Exact: 'approved' },
      problemOrder: { created: 'DESC' },
      scrapOrder,
    },
  );

  const [comments] = useLazyLoadQuery<SchoolExamSourceId_commentsQuery>(commentsForSchoolExamSourceId, {
    filters: { nodeId_Exact: schoolExamSourceId as string },
    order: { created: 'ASC' as Ordering },
  });

  const commentContainerRef = useRef<HTMLDivElement>(null);

  if (!schoolExamSource) return null;
  const { id, sequence, actions, school, title, innerPdf, problems, scraps } = schoolExamSource;
  const isPdfExist = !isNullable(innerPdf) || problems.edges.some(({ node }) => !isNullable(node.pdf));

  return (
    <View>
      <Head siteTitle={`내신 기출 | ${school.title} ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{`${school.title} ${title} - ${sequence}`}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <MutationConfirmButton<SchoolExamSourceId_schoolExamSourcePublishMutation>
              mutation={graphql`
                mutation SchoolExamSourceId_schoolExamSourcePublishMutation($input: SchoolExamSourcePublishInput!) {
                  schoolExamSourcePublish(input: $input) {
                    id
                    actions
                    ...SchoolExamSourceStatusLabel_schoolExamSource
                    ...SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource
                  }
                }
              `}
              input={{ id }}
              variant={'primary'}
              leadingIcon={CheckIcon}
              disabled={!actions.includes('school_exam_source_publish')}
              size={'large'}
              config={{
                onCompleted: () => {
                  toast('출시가 완료됐어요!', 'success');
                },
                onError: () => {
                  toast('다시 출시해주세요', 'error');
                },
              }}
              message={'스크랩 검수 후 출시해 주세요. 출시할까요?'}
            >
              출시하기
            </MutationConfirmButton>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <View>
              <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>시험지 정보</Text>
              <View sx={{ marginTop: 3 }}>
                <Card>
                  <View sx={{ padding: 4 }}>
                    <SchoolExamSourceDescriptionList schoolExamSource={schoolExamSource} />
                  </View>
                </Card>
              </View>
            </View>
            <View sx={{ marginTop: 5 }}>
              <Grid sx={{ alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>스크랩</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Stack gapX={2}>
                    {actions.includes('school_exam_source_reset') ? (
                      <Stack.Item>
                        <MutationConfirmButton<SchoolExamSourceId_schoolExamSourceResetMutation>
                          variant={'danger'}
                          size={'large'}
                          mutation={graphql`
                            mutation SchoolExamSourceId_schoolExamSourceResetMutation(
                              $input: SchoolExamSourceResetInput!
                            ) {
                              schoolExamSourceReset(input: $input) {
                                id
                              }
                            }
                          `}
                          input={{ id }}
                          message={'삭제한 문제는 되돌릴 수 없어요. 모두 삭제할까요?'}
                          config={{
                            onCompleted: () => {
                              toast('삭제가 완료됐어요', 'success');
                              refetch();
                            },
                            onError: () => {
                              toast('다시 시도해주세요', 'error');
                            },
                          }}
                        >
                          모두 삭제하기
                        </MutationConfirmButton>
                      </Stack.Item>
                    ) : null}
                    <Stack.Item>
                      <DialogButton
                        variant={'outline'}
                        size={'large'}
                        disabled={!isPdfExist}
                        renderDialog={({ isOpen, closeDialog }) => (
                          <SchoolExamSourceSchoolExamScrapCreateDialog
                            schoolExamSource={schoolExamSource}
                            isOpen={isOpen}
                            onDismiss={closeDialog}
                            config={{
                              onCompleted: () => {
                                refetch();
                              },
                            }}
                          />
                        )}
                      >
                        스크랩하기
                      </DialogButton>
                    </Stack.Item>
                  </Stack>
                </Grid.Unit>
              </Grid>
              <View sx={{ marginTop: 3 }}>
                <Text sx={{ fontSize: 1, color: 'fg.muted', fontWeight: 'bold' }}>총 {scraps.totalCount || 0}건</Text>
                <View sx={{ marginTop: 3 }}>
                  <Grid gapX={[2, 2, 3]} gapY={[2, 2, 3]}>
                    <ItemList
                      items={scraps.edges.map(({ node }) => node).filter((node) => !!node)}
                      renderItem={({ scrap, order }) => (
                        <SquareView
                          sx={{
                            borderWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'border.default',
                            borderRadius: 2,
                            backgroundColor: 'neutral.emphasisPlus',
                            overflow: 'hidden',
                            cursor: 'pointer',
                          }}
                        >
                          <View
                            sx={{
                              width: '100%',
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              position: 'relative',
                            }}
                          >
                            <Image src={scrap.objectUrl} alt={scrap.objectUrl} fill style={{ objectFit: 'contain' }} />
                            <View
                              sx={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                left: 0,
                                padding: [1, 1, 2],
                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                              }}
                            >
                              <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.onEmphasis' }}>{order}번</Text>
                            </View>
                          </View>
                        </SquareView>
                      )}
                      renderItemWrapper={(children, { id }, i) => {
                        const schoolExamScrapDeleteForSchoolExamSourceId = graphql`
                          mutation SchoolExamSourceId_schoolExamScrapDeleteMutation(
                            $input: SchoolExamScrapDeleteInput!
                          ) {
                            schoolExamScrapDelete(input: $input) {
                              id @deleteRecord
                            }
                          }
                        `;

                        return (
                          <Grid.Unit key={`${id}-${i}`} size={1 / 4}>
                            <View sx={{ position: 'relative' }}>
                              <DialogHandler
                                renderDialog={({ isOpen, closeDialog }) => (
                                  <Lightbox
                                    isOpen={isOpen}
                                    onDismiss={() => closeDialog()}
                                    items={scraps.edges.map(({ node }) => node).filter((node) => !!node)}
                                    getImage={({ scrap }) => scrap.objectUrl}
                                    getImageTitle={({ order }) => `${order}번`}
                                    initialIndex={i}
                                    toolbarButtons={[
                                      <Lightbox.MutationConfirmToolbarIconButton<SchoolExamSourceId_schoolExamProblemDeleteMutation>
                                        key={'Delete schoolExamScrap'}
                                        mutation={schoolExamScrapDeleteForSchoolExamSourceId}
                                        aria-labelledby={'Delete schoolExamScrap'}
                                        icon={TrashIcon}
                                        input={{ id }}
                                        config={{
                                          onCompleted: () => {
                                            toast('삭제가 완료됐어요', 'success');
                                            refetch();
                                          },
                                          onError: () => {
                                            toast('다시 시도해주세요', 'error');
                                          },
                                        }}
                                        message={'삭제한 문제는 되돌릴 수 없어요. 삭제할까요?'}
                                      />,
                                    ]}
                                  />
                                )}
                              >
                                {children}
                              </DialogHandler>
                              <MutationConfirmIconButton<SchoolExamSourceId_schoolExamProblemDeleteMutation>
                                mutation={schoolExamScrapDeleteForSchoolExamSourceId}
                                aria-labelledby={'Delete schoolExamScrap'}
                                icon={TrashIcon}
                                size={'small'}
                                input={{ id }}
                                config={{
                                  onCompleted: () => {
                                    toast('삭제가 완료됐어요', 'success');
                                    refetch();
                                  },
                                  onError: () => {
                                    toast('다시 시도해주세요', 'error');
                                  },
                                }}
                                message={'삭제한 문제는 되돌릴 수 없어요. 삭제할까요?'}
                                sx={{ position: 'absolute', bottom: 2, left: 2 }}
                              />
                            </View>
                          </Grid.Unit>
                        );
                      }}
                      emptyState={
                        <Grid.Unit size={'max'}>
                          <Card>
                            <View sx={{ padding: 5 }}>
                              <EmptyState title={'스크랩한 시험지가 없어요'} />
                            </View>
                          </Card>
                        </Grid.Unit>
                      }
                    />
                  </Grid>
                </View>
              </View>
            </View>
            <View sx={{ marginTop: 5 }}>
              <Grid sx={{ alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <View sx={{ lineHeight: 1 }}>
                    <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>내부 업로드</Text>
                  </View>
                  <Text sx={{ fontSize: 0, color: 'fg.subtle' }}>다시 업로드하는 경우, 덮어쓰기가 돼요</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <DialogButton
                    variant={'outline'}
                    size={'large'}
                    disabled={!actions.includes('school_exam_source_inner_pdf_upload')}
                    renderDialog={({ isOpen, closeDialog }) => (
                      <SchoolExamSourceInnerPdfUploadDialog
                        schoolExamSource={schoolExamSource}
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        config={{
                          onCompleted: () => {
                            toast('업로드가 완료됐어요!', 'success');
                            closeDialog();
                          },
                          onError: () => {
                            toast('다시 저장해주세요', 'error');
                          },
                        }}
                      />
                    )}
                  >
                    {innerPdf ? '재업로드하기' : '업로드하기'}
                  </DialogButton>
                </Grid.Unit>
              </Grid>
              <View sx={{ marginTop: 3, overflowX: 'auto' }}>
                <DataTable
                  rows={innerPdf ? [innerPdf] : []}
                  columns={[
                    {
                      field: 'title',
                      title: '제목',
                      renderValue: () => '내부 업로드',
                      width: 688,
                    },
                    {
                      field: 'schoolExamSourceInnerPdfDeleteMutationIconButton',
                      title: '',
                      renderValue: () => (
                        <View
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <MutationConfirmIconButton<SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation>
                            mutation={graphql`
                              mutation SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation(
                                $input: SchoolExamSourceInnerPdfDeleteInput!
                              ) {
                                schoolExamSourceInnerPdfDelete(input: $input) {
                                  id
                                  innerPdf {
                                    id @deleteRecord
                                  }
                                }
                              }
                            `}
                            aria-labelledby={'Delete schoolExamProblemPdf'}
                            icon={TrashIcon}
                            size={'small'}
                            input={{ schoolExamSource: id }}
                            message={'삭제한 PDF는 되돌릴 수 없어요. 삭제할까요?'}
                          />
                        </View>
                      ),
                      width: 48,
                      align: 'center',
                    },
                  ]}
                  emptyState={
                    <View sx={{ padding: 3 }}>
                      <EmptyState title={'업로드한 시험지가 없어요'} />
                    </View>
                  }
                  onRowClick={({ objectUrl }) => window.open(objectUrl, '_blank')}
                />
              </View>
            </View>
            {(problems?.totalCount || 0) > 0 ? (
              <View sx={{ marginTop: 5 }}>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>사용자 업로드</Text>
                <View sx={{ marginTop: 3 }}>
                  <Text sx={{ fontSize: 1, color: 'fg.muted', fontWeight: 'bold' }}>총 {problems.totalCount}건</Text>
                  <View sx={{ marginTop: 3, overflowX: 'auto' }}>
                    <DataTable
                      rows={problems.edges.map(({ node }) => node).filter((node) => !!node)}
                      columns={[
                        {
                          field: 'title',
                          title: '제목',
                          renderValue: ({ created, sequence }) => (
                            <>
                              사용자 업로드 {sequence} (<DateText format="P">{created}</DateText>)
                            </>
                          ),
                          width: 688,
                        },
                        {
                          field: 'schoolExamProblemPdfDeleteMutationIconButton',
                          title: '',
                          renderValue: ({ id }) => (
                            <View
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <MutationConfirmIconButton<SchoolExamSourceId_schoolExamProblemDeleteMutation>
                                mutation={graphql`
                                  mutation SchoolExamSourceId_schoolExamProblemDeleteMutation(
                                    $input: SchoolExamProblemDeleteInput!
                                  ) {
                                    schoolExamProblemDelete(input: $input) {
                                      id @deleteRecord
                                    }
                                  }
                                `}
                                aria-labelledby={'Delete schoolExamProblemPdf'}
                                icon={TrashIcon}
                                size={'small'}
                                input={{ id }}
                                config={{
                                  onCompleted: () => {
                                    refetch();
                                  },
                                }}
                                message={'삭제한 PDF는 되돌릴 수 없어요. 삭제할까요?'}
                              />
                            </View>
                          ),
                          width: 48,
                          align: 'center',
                        },
                      ]}
                      onRowClick={({ id, pdf }) =>
                        pdf?.objectUrl ? window.open(pdf.objectUrl, '_blank') : router.push(`/schoolExamProblem/${id}`)
                      }
                    />
                  </View>
                </View>
              </View>
            ) : null}
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]} sx={{ '&>*:not(:first-child)': { marginTop: 5 } }}>
            <View>
              <SchoolExamSourceDescriptionList
                schoolExamSource={schoolExamSource}
                type={'activity'}
                titleUnitSize={'max'}
                descriptionUnitSize={'min'}
                renderTitle={(title) => (
                  <Text fontWeight={'bold'} fontSize={1}>
                    {title}
                  </Text>
                )}
              />
            </View>
            <View>
              <ErrorBoundary>
                <CommentPaginator fragmentReference={comments}>
                  {({ comments }, { loadMore, hasNext, isLoadingNext, refetch }) => {
                    return (
                      <>
                        <View>
                          <Stack gapX={1}>
                            <Stack.Item>
                              <Text fontWeight={'bold'} fontSize={1}>
                                댓글
                              </Text>
                            </Stack.Item>
                            <Stack.Item>
                              <Text
                                sx={{ fontSize: 1, fontWeight: 'bold' }}
                                color={comments.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                              >
                                {numberWithCommas(comments.totalCount || 0)}
                              </Text>
                            </Stack.Item>
                          </Stack>
                          <Card ref={commentContainerRef} sx={{ maxHeight: 240, overflowY: 'auto', marginTop: 2 }}>
                            <View sx={{ padding: 3 }}>
                              <ItemList
                                items={comments.edges.map(({ node }) => node).filter((node) => !!node)}
                                renderItem={(node) => <CommentMutationActionItem comment={node} />}
                                renderItemWrapper={(children, { id }, index) => (
                                  <View key={id} sx={{ marginTop: index === 0 ? 0 : 4 }}>
                                    {children}
                                  </View>
                                )}
                                emptyState={<EmptyState title={'댓글이 없어요'} />}
                              />
                              {hasNext ? (
                                <Button
                                  onClick={() => loadMore(3)}
                                  disabled={isLoadingNext}
                                  sx={{ width: '100%', marginTop: 1 }}
                                >
                                  더보기
                                </Button>
                              ) : null}
                            </View>
                          </Card>
                        </View>
                        <View sx={{ marginTop: 2 }}>
                          <CommentCreateMutationFormik
                            nodeId={schoolExamSourceId as string}
                            config={{
                              onCompleted: () => {
                                refetch(
                                  {},
                                  {
                                    fetchPolicy: 'store-and-network',
                                    onComplete: () => {
                                      setTimeout(() => {
                                        if (commentContainerRef.current)
                                          scrollToBottom(commentContainerRef.current, {});
                                      }, 0);
                                    },
                                  },
                                );
                              },
                            }}
                          />
                        </View>
                      </>
                    );
                  }}
                </CommentPaginator>
              </ErrorBoundary>
            </View>
            <View>
              <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>히스토리</Text>
              <ErrorBoundary>
                <SchoolExamSourceSchoolExamSourceHistoryPaginator fragmentReference={schoolExamSource}>
                  {({ histories }, { isLoadingNext, hasNext, loadMore }) => (
                    <Timeline>
                      <ItemList
                        items={histories.edges}
                        renderItem={({ node }) => (
                          <SchoolExamSourceHistoryTimelineItem key={node.id} schoolExamSourceHistory={node} />
                        )}
                        renderItemWrapper={(children, { node }) => {
                          const { id, type } = node;
                          return (
                            <React.Fragment key={id}>
                              {type === 'upload' || type === 'publish' ? (
                                <DialogHandler
                                  renderDialog={({ isOpen, closeDialog }) => (
                                    <ErrorBoundary>
                                      <SchoolExamSourceHistoryDialog
                                        schoolExamSourceHistory={node}
                                        isOpen={isOpen}
                                        onDismiss={() => closeDialog()}
                                        wide
                                      />
                                    </ErrorBoundary>
                                  )}
                                >
                                  <View
                                    sx={{
                                      'borderRadius': 2,
                                      'cursor': 'pointer',
                                      'transition': 'background-color 250ms',
                                      ':hover': { backgroundColor: 'canvas.subtle' },
                                    }}
                                  >
                                    <View sx={{ paddingX: 2 }}>{children}</View>
                                  </View>
                                </DialogHandler>
                              ) : (
                                <View sx={{ paddingX: 2 }}>{children}</View>
                              )}
                            </React.Fragment>
                          );
                        }}
                      />
                      {hasNext ? (
                        <Button sx={{ width: '100%' }} disabled={isLoadingNext} onClick={() => loadMore(10)}>
                          더보기
                        </Button>
                      ) : (
                        <Timeline.Break />
                      )}
                    </Timeline>
                  )}
                </SchoolExamSourceSchoolExamSourceHistoryPaginator>
              </ErrorBoundary>
            </View>
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

SchoolExamSourceId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
SchoolExamSourceId.authenticated = true;
SchoolExamSourceId.routes = [
  {
    id: 'schoolExamSourceId',
    name: '내신 기출 상세',
    pathname: '/schoolExamSource/[schoolExamSourceId]',
    permissions: ['school_exam_source_read'],
  },
];

export default SchoolExamSourceId;
