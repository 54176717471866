import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { RaffleUpdateDialog_raffle$key } from '../../../relay/__generated__/RaffleUpdateDialog_raffle.graphql';
import { RaffleUpdateDialog_raffleUpdateMutation } from '../../../relay/__generated__/RaffleUpdateDialog_raffleUpdateMutation.graphql';
import { endOfDay, formatISO } from '../../../utils/date';
import Button from '../../core/Button';
import CheckboxField from '../../core/CheckboxField';
import DateField from '../../core/DateField';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';
import RaffleStuffSelectField from '../../raffleStuff/RaffleStuffSelectField';

const RaffleUpdateDialog_raffle = graphql`
  fragment RaffleUpdateDialog_raffle on Raffle {
    id
    raffleStuff {
      id
    }
    title
    description
    isPremium
    requiredPoint
    winnerCount
    startAt
    endAt
    showEndAt
    order
  }
`;

type Props = {
  raffle: RaffleUpdateDialog_raffle$key;
} & DialogProps &
  Pick<MutationFormikProps<RaffleUpdateDialog_raffleUpdateMutation>, 'onSubmit' | 'config' | 'connections'>;

const RaffleUpdateDialog = ({ raffle, onSubmit, config, connections, ...props }: Props) => {
  const {
    id,
    raffleStuff,
    title,
    description,
    isPremium,
    requiredPoint,
    winnerCount,
    startAt,
    endAt,
    showEndAt,
    order,
  } = useFragment(RaffleUpdateDialog_raffle, raffle);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>뽑기왕 매쓰킹 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<RaffleUpdateDialog_raffleUpdateMutation>
        mutation={graphql`
          mutation RaffleUpdateDialog_raffleUpdateMutation($input: RaffleUpdateInput!) {
            raffleUpdate(input: $input) {
              id
              ...RaffleUpdateDialog_raffle
            }
          }
        `}
        initialValues={{
          id,
          raffleStuff,
          title,
          description,
          isPremium,
          requiredPoint,
          winnerCount,
          startAt,
          endAt,
          showEndAt,
          order,
        }}
        onSubmit={onSubmit}
        config={config}
        connections={connections}
      >
        {({ handleSubmit, setFieldValue }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <Suspense>
                  <RaffleStuffSelectField
                    name={'raffleStuff.id'}
                    label={'뽑기왕 경품'}
                    placeholder={'경품 선택'}
                    required
                  />
                </Suspense>
                <TextField
                  name={'title'}
                  label={'제목'}
                  placeholder={'제목 입력'}
                  caption={'수학대왕 앱 뽑기왕 매쓰킹에서 각 카드의 제목으로 보여집니다.'}
                  required
                />
                <TextareaField name={'description'} label={'설명'} placeholder={'설명 입력'} />
                <CheckboxField name={'isPremium'} label={'프리미엄 사용자용?'} required />
                <NumberField
                  name={'requiredPoint'}
                  label={'사용 포인트'}
                  placeholder={'사용 포인트 입력'}
                  min={0}
                  required
                />
                <NumberField name={'winnerCount'} label={'당첨자 수'} placeholder={'당첨자 수 입력'} min={0} required />
                <NumberField name={'order'} label={'우선순위'} placeholder={'우선순위 입력'} min={0} required />
                <DateField name={'startAt'} label={'시작일'} placeholder={'시작일 선택'} required />
                <DateField
                  name={'endAt'}
                  label={'종료일'}
                  placeholder={'종료일 선택'}
                  onChange={(value) => setFieldValue('endAt', formatISO(endOfDay(value)))}
                  required
                />
                <DateField
                  name={'showEndAt'}
                  label={'노출일'}
                  placeholder={'노출일 선택'}
                  onChange={(value) => setFieldValue('showEndAt', formatISO(endOfDay(value)))}
                  required
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default RaffleUpdateDialog;
