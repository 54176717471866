import { ReactNode } from 'react';

import { isNullable } from '../../../utils/is';
import Grid, { GridProps } from '../Grid';
import { GridUnitProps } from '../Grid/Unit';
import ItemList, { ItemListProps } from '../ItemList';
import Text from '../Text';
import View from '../View';

type DescriptionListItemDescriptionType<T extends Record<string, any>> = {
  title: string | ReactNode;
  renderValue: (item: T) => ReactNode;
};

type Props<T extends Record<string, any>, TD extends Record<string, DescriptionListItemDescriptionType<T>> = {}> = {
  item: T;
  itemDescriptions: TD;
  picks: (keyof TD)[];
  renderTitle?: (title: ReactNode) => ReactNode;
  renderDescription?: (description: ReactNode) => ReactNode;
  titleUnitSize?: GridUnitProps['size'];
  descriptionUnitSize?: GridUnitProps['size'];
} & Pick<GridProps, 'gapX' | 'gapY'> &
  Omit<ItemListProps<DescriptionListItemDescriptionType<T>>, 'items' | 'renderItem'>;

const DescriptionList = <
  T extends Record<string, any>,
  TD extends Record<string, DescriptionListItemDescriptionType<T>> = {},
>({
  item,
  itemDescriptions,
  renderTitle = (title) => <Text sx={{ fontSize: 1, color: 'fg.muted' }}>{title}</Text>,
  renderDescription = (description) => <Text sx={{ fontSize: 1 }}>{description}</Text>,
  picks,
  gapX = 3,
  gapY = 1,
  titleUnitSize = [1, 1, 1 / 8],
  descriptionUnitSize = [1, 1, 7 / 8],
  ...props
}: Props<T, TD>) => (
  <ItemList
    items={picks.map((pick) => itemDescriptions[pick]!)}
    renderItem={({ title, renderValue }) => {
      const description = renderValue(item);
      return (
        <Grid gapX={gapX} gapY={gapY}>
          <Grid.Unit size={titleUnitSize}>{renderTitle(title)}</Grid.Unit>
          <Grid.Unit size={descriptionUnitSize}>
            {renderDescription(!isNullable(description) ? description : '-')}
          </Grid.Unit>
        </Grid>
      );
    }}
    renderItemWrapper={(children, _, i) => (
      <View key={i} sx={{ marginTop: i > 0 ? 2 : 0 }}>
        {children}
      </View>
    )}
    {...props}
  />
);

export default DescriptionList;
export type { Props as DescriptionListProps };
