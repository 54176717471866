/**
 * @generated SignedSource<<ecfc2246cb743743091f297ddb943324>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type TaskLabelingCountEnum = "one" | "two" | "%future added value";
export type TaskProblemAnswerTypeEnum = "objective" | "subjective" | "%future added value";
export type TaskProblemCreationTypeEnum = "first" | "original" | "second" | "third" | "%future added value";
export type TaskProblemTypeEnum = "copy" | "exam" | "private" | "problem_generator" | "pure" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskProblemUpdateDialog_task$data = {
  readonly id: string;
  readonly imageRequests: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string | null;
        readonly id: string;
        readonly image: {
          readonly key: string;
          readonly objectUrl: string;
          readonly size: number;
        };
      };
    }>;
  };
  readonly labelingCount: TaskLabelingCountEnum | null;
  readonly problem: {
    readonly answer: string | null;
    readonly answerChoiceCount: number | null;
    readonly answerType: TaskProblemAnswerTypeEnum | null;
    readonly creationType: TaskProblemCreationTypeEnum | null;
    readonly id: string;
    readonly problem: string | null;
    readonly problemType: TaskProblemTypeEnum;
    readonly solution: string | null;
  } | null;
  readonly scrap: {
    readonly id: string;
    readonly problems: ReadonlyArray<{
      readonly id: string;
      readonly objectUrl: string;
    }>;
    readonly solutions: ReadonlyArray<{
      readonly id: string;
      readonly objectUrl: string;
    }>;
  } | null;
  readonly sequence: number;
  readonly title: string;
  readonly unit: {
    readonly id: string;
    readonly schoolType: SchoolTypeEnum;
  } | null;
  readonly unitDs: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentType": "TaskProblemUpdateDialog_task";
};
export type TaskProblemUpdateDialog_task$key = {
  readonly " $data"?: TaskProblemUpdateDialog_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskProblemUpdateDialog_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectUrl",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskProblemUpdateDialog_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProblem",
      "kind": "LinkedField",
      "name": "problem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problem",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "solution",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problemType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creationType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerChoiceCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answerType",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Scrap",
      "kind": "LinkedField",
      "name": "scrap",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "problems",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "File",
          "kind": "LinkedField",
          "name": "solutions",
          "plural": true,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskImageRequestConnection",
      "kind": "LinkedField",
      "name": "imageRequests",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskImageRequestEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskImageRequest",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "image",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "key",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "size",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "order",
          "value": {
            "order": "DESC"
          }
        }
      ],
      "concreteType": "UnitD",
      "kind": "LinkedField",
      "name": "unitDs",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "labelingCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Unit",
      "kind": "LinkedField",
      "name": "unit",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schoolType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};
})();

(node as any).hash = "3c9c8a7d5362887b66276e51fbf0ffe4";

export default node;
