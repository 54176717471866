import { isArray, isNullable } from './is';

const normalizeObject = (values: string | Record<string, any> | undefined | null) => {
  if (!values) return {};
  if (typeof values === 'string') return values;
  return Object.fromEntries(
    Object.entries(values || {}).filter(([, v]) => !(v === '' || isNullable(v) || (isArray(v) && v.length === 0))),
  );
};

export { normalizeObject };
