import { graphql } from 'react-relay';

import { UnitAPaginator_query$key } from '../../../relay/__generated__/UnitAPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const UnitAPaginator_query = graphql`
  fragment UnitAPaginator_query on Query
  @argumentDefinitions(
    filters: { type: UnitAFilter }
    order: { type: UnitAOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "UnitAPaginatorRefreshQuery") {
    unitAs(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "UnitAPaginator_query_unitAs") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<UnitAPaginator_query$key>, 'fragment'>;

const UnitAPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={UnitAPaginator_query} {...props} />;

export default UnitAPaginator;
