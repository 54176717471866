import { KebabHorizontalIcon, PencilIcon, TrashIcon, XIcon } from '@primer/octicons-react';
import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import { CommentMutationActionItem_comment$key } from '../../../relay/__generated__/CommentMutationActionItem_comment.graphql';
import { CommentMutationActionItem_commentDeleteMutation } from '../../../relay/__generated__/CommentMutationActionItem_commentDeleteMutation.graphql';
import { CommentMutationActionItem_commentUpdateMutation } from '../../../relay/__generated__/CommentMutationActionItem_commentUpdateMutation.graphql';
import ActionList from '../../core/ActionList';
import ActionMenu from '../../core/ActionMenu';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import MutationFormik from '../../core/MutationFormik';
import TextField from '../../core/TextField';
import UserAvatarText from '../../user/UserAvatarText';
import CommentItem from '../CommentItem';

const CommentMutationActionItem_comment = graphql`
  fragment CommentMutationActionItem_comment on Comment {
    id
    actions
    description
    createdBy {
      ...UserAvatarText_user
    }
    ...CommentItem_comment
  }
`;

type Props = { comment: CommentMutationActionItem_comment$key };

const CommentMutationActionItem = ({ comment: commentReference }: Props) => {
  const comment = useFragment(CommentMutationActionItem_comment, commentReference);
  const { id, description, createdBy, actions } = comment;

  const [isEditing, setIsEditing] = useState(false);
  const cancelEditingComment = () => {
    setIsEditing(false);
  };

  const showActionMenu = actions?.some((action) => action === 'comment_update' || action === 'comment_delete');

  return isEditing ? (
    <>
      <Grid sx={{ marginBottom: 2 }}>
        <Grid.Unit size={'max'}>
          <UserAvatarText user={createdBy} fontSize={1} fontWeight={'bold'} />
        </Grid.Unit>
        <Grid.Unit size={'min'}>
          <IconButton
            icon={XIcon}
            size={'small'}
            aria-label={'close update view'}
            variant={'plain'}
            onClick={() => cancelEditingComment()}
          />
        </Grid.Unit>
      </Grid>
      <MutationFormik<CommentMutationActionItem_commentUpdateMutation>
        mutation={graphql`
          mutation CommentMutationActionItem_commentUpdateMutation($input: CommentUpdateInput!, $connections: [ID!]!) {
            commentUpdate(input: $input) @appendNode(edgeTypeName: "CommentEdge", connections: $connections) {
              id
              ...CommentItem_comment
            }
          }
        `}
        initialValues={{
          id,
          description,
        }}
        config={{
          onCompleted: () => {
            cancelEditingComment();
          },
        }}
      >
        {({ submitForm, isSubmitting, dirty }) => (
          <TextField
            label={'내용'}
            labelConfig={{ visuallyHidden: true }}
            placeholder={'댓글 입력'}
            name={'description'}
            autoComplete={'off'}
            onKeyUp={(e) => {
              if (e.key === 'Enter') submitForm();
            }}
            tabIndex={0}
            trailingAction={
              <IconButton
                icon={PencilIcon}
                disabled={isSubmitting || !dirty}
                aria-label={'update comment'}
                variant={'plain'}
                onClick={() => {
                  submitForm();
                }}
              />
            }
          />
        )}
      </MutationFormik>
    </>
  ) : (
    <Grid wrap={false}>
      <Grid.Unit size={'max'}>
        <CommentItem comment={comment} />
      </Grid.Unit>
      {showActionMenu ? (
        <Grid.Unit size={'min'}>
          <ActionMenu>
            <ActionMenu.Anchor>
              <IconButton
                icon={KebabHorizontalIcon}
                size={'small'}
                variant={'plain'}
                aria-label="Open column options"
              />
            </ActionMenu.Anchor>
            <ActionMenu.Overlay>
              <ActionList>
                {actions.includes('comment_update') ? (
                  <ActionList.Item onSelect={() => setIsEditing(true)}>
                    <ActionList.LeadingVisual>
                      <PencilIcon />
                    </ActionList.LeadingVisual>
                    수정하기
                  </ActionList.Item>
                ) : null}
                {actions.includes('comment_delete') ? (
                  <ActionList.MutationItem<CommentMutationActionItem_commentDeleteMutation>
                    mutation={graphql`
                      mutation CommentMutationActionItem_commentDeleteMutation($input: CommentDeleteInput!) {
                        commentDelete(input: $input) {
                          id @deleteRecord
                        }
                      }
                    `}
                    input={{ id }}
                    variant={'danger'}
                    message={'정말 댓글을 삭제할까요?'}
                  >
                    <ActionList.LeadingVisual>
                      <TrashIcon />
                    </ActionList.LeadingVisual>
                    삭제하기
                  </ActionList.MutationItem>
                ) : null}
              </ActionList>
            </ActionMenu.Overlay>
          </ActionMenu>
        </Grid.Unit>
      ) : null}
    </Grid>
  );
};

export default CommentMutationActionItem;
