/**
 * @generated SignedSource<<1aa3faaebe98334e628f76cae2e13c43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATOrganizationPremiumTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
export type MATInvoiceUpdateInput = {
  amount?: number | null;
  description?: string | null;
  endDate?: any | null;
  id: string;
  maxStudentCount?: number | null;
  premiumType?: MATOrganizationPremiumTypeEnum | null;
  scholarshipAmount?: number | null;
  startDate?: any | null;
  title?: string | null;
};
export type MATInvoiceUpdateDialog_invoiceUpdateMutation$variables = {
  input: MATInvoiceUpdateInput;
};
export type MATInvoiceUpdateDialog_invoiceUpdateMutation$data = {
  readonly invoiceUpdate: {
    readonly amount: number;
    readonly endDate: any;
    readonly id: string;
    readonly maxStudentCount: number;
    readonly premiumType: MATOrganizationPremiumTypeEnum;
    readonly scholarshipAmount: number | null;
    readonly startDate: any;
    readonly title: string;
  };
};
export type MATInvoiceUpdateDialog_invoiceUpdateMutation = {
  response: MATInvoiceUpdateDialog_invoiceUpdateMutation$data;
  variables: MATInvoiceUpdateDialog_invoiceUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MATInvoice",
    "kind": "LinkedField",
    "name": "invoiceUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "premiumType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "scholarshipAmount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maxStudentCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATInvoiceUpdateDialog_invoiceUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATInvoiceUpdateDialog_invoiceUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8231498d06e570cadc2a5372214383d0",
    "id": null,
    "metadata": {},
    "name": "MATInvoiceUpdateDialog_invoiceUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation MATInvoiceUpdateDialog_invoiceUpdateMutation(\n  $input: MATInvoiceUpdateInput!\n) {\n  invoiceUpdate(input: $input) {\n    id\n    title\n    premiumType\n    scholarshipAmount\n    startDate\n    endDate\n    maxStudentCount\n    amount\n  }\n}\n"
  }
};
})();

(node as any).hash = "1751cfdd47857c30d8d9713f3ea1ffb6";

export default node;
