/**
 * @generated SignedSource<<4b6b6d9f9645296dc9e8a08644e5ae93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BookActionEnum = "book_delete" | "book_rescrap" | "book_scrap_create" | "book_submit" | "book_update" | "scrap_source_book_create" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BookActionsMutationButtonStack_book$data = {
  readonly actions: ReadonlyArray<BookActionEnum>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"BookBookScrapCreateDialog_book">;
  readonly " $fragmentType": "BookActionsMutationButtonStack_book";
};
export type BookActionsMutationButtonStack_book$key = {
  readonly " $data"?: BookActionsMutationButtonStack_book$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookActionsMutationButtonStack_book">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookActionsMutationButtonStack_book",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BookBookScrapCreateDialog_book"
    }
  ],
  "type": "Book",
  "abstractKey": null
};

(node as any).hash = "bb47d994099880cd5a24352fd7033cbc";

export default node;
