import { graphql, useFragment } from 'react-relay';

import {
  ProblemGeneratorDescriptionList_problemGenerator$data,
  ProblemGeneratorDescriptionList_problemGenerator$key,
} from '../../../relay/__generated__/ProblemGeneratorDescriptionList_problemGenerator.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import UserAvatarText from '../../user/UserAvatarText';
import ProblemGeneratorStatusLabel from '../ProblemGeneratorStatusLabel';

const ProblemGeneratorDescriptionList_problemGenerator = graphql`
  fragment ProblemGeneratorDescriptionList_problemGenerator on ProblemGenerator {
    id

    assigned
    assignedTo {
      id
      ...UserAvatarText_user
    }
    ...ProblemGeneratorStatusLabel_problemGenerator
  }
`;

type Props = {
  problemGenerator: ProblemGeneratorDescriptionList_problemGenerator$key;
  type?: 'activity';
} & Omit<
  DescriptionListProps<ProblemGeneratorDescriptionList_problemGenerator$data>,
  'item' | 'itemDescriptions' | 'picks'
>;
const ProblemGeneratorDescriptionList = ({
  problemGenerator: problemGeneratorReference,
  type = 'activity',
  ...props
}: Props) => {
  const problemGenerator = useFragment(ProblemGeneratorDescriptionList_problemGenerator, problemGeneratorReference);

  return (
    <DescriptionList
      item={problemGenerator}
      itemDescriptions={{
        status: {
          title: '상태',
          renderValue: (problemGenerator) => <ProblemGeneratorStatusLabel problemGenerator={problemGenerator} />,
        },
        assignedTo: {
          title: '작업자',
          renderValue: ({ assignedTo }) => (assignedTo ? <UserAvatarText user={assignedTo} /> : undefined),
        },
        assigned: {
          title: '배정일',
          renderValue: ({ assigned }) => (assigned ? <DateText fontSize={1}>{assigned}</DateText> : undefined),
        },
      }}
      picks={type === 'activity' ? ['status', 'assignedTo', 'assigned'] : []}
      {...props}
    />
  );
};

export default ProblemGeneratorDescriptionList;
