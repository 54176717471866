import { CheckIcon, ChevronRightIcon, XIcon } from '@primer/octicons-react';
import { omit } from 'lodash-es';
import React, { Suspense, useEffect } from 'react';
import { fetchQuery, graphql, useFragment, useQueryLoader, useRelayEnvironment } from 'react-relay';

import {
  TaskFinalLabelingDialog_task$key,
  UnitSchoolGradeEnum,
  UnitSchoolYearEnum,
} from '../../../relay/__generated__/TaskFinalLabelingDialog_task.graphql';
import { TaskFinalLabelingDialog_taskFinalLabelingMutation } from '../../../relay/__generated__/TaskFinalLabelingDialog_taskFinalLabelingMutation.graphql';
import {
  TaskFinalLabelingDialog_unitDefaultRatingQuery,
  TaskFinalLabelingDialog_unitDefaultRatingQuery$variables,
} from '../../../relay/__generated__/TaskFinalLabelingDialog_unitDefaultRatingQuery.graphql';
import { TaskFinalLabelingDialog_unitDsQuery } from '../../../relay/__generated__/TaskFinalLabelingDialog_unitDsQuery.graphql';
import { isNullable } from '../../../utils/is';
import Button from '../../core/Button';
import Card from '../../core/Card';
import DescriptionList from '../../core/DescriptionList';
import Dialog, { DialogProps } from '../../core/Dialog';
import DialogHandler from '../../core/DialogHandler';
import EmptyState from '../../core/EmptyState';
import EnumPair from '../../core/EnumPair';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import TabHandler from '../../core/TabHandler';
import Text from '../../core/Text';
import View from '../../core/View';
import UnitSchoolGradeSelectField from '../../unit/UnitSchoolGradeSelectField';
import UnitSchoolYearSelectField from '../../unit/UnitSchoolYearSelectField';
import UnitSelectField from '../../unit/UnitSelectField';
import UnitDItem from '../../unitD/UnitDItem';
import UnitDPaginator from '../../unitD/UnitDPaginator';
import UnitDSelectDialogTokenField from '../../unitD/UnitDSelectDialogTokenField';

import PreviousLabelingDialog from './PreviousLabelingDialog';

const TaskFinalLabelingDialog_task = graphql`
  fragment TaskFinalLabelingDialog_task on Task {
    id
    title
    sequence
    scrap {
      id
      problems {
        id
        objectUrl
      }
      solutions {
        id
        objectUrl
      }
    }
    unit {
      id
      schoolType
      schoolYears
      schoolGrades
    }
    problem {
      id
      problem
      solution
      problemType
      answer
      answerChoiceCount
      answerType
    }
    finalLabeling {
      id
      difficulty
      unitDs(order: { order: DESC }) {
        id
      }
    }
    ...PreviousLabelingDialog_task
  }
`;

const unitDefaultRatingForTaskFinalLabelingDialog = graphql`
  query TaskFinalLabelingDialog_unitDefaultRatingQuery(
    $schoolGrade: UnitSchoolGradeEnum!
    $schoolYear: UnitSchoolYearEnum!
    $skip: Boolean!
  ) {
    unitDefaultRating(schoolGrade: $schoolGrade, schoolYear: $schoolYear) @skip(if: $skip)
  }
`;

const unitDsForTaskFinalLabelingDialog = graphql`
  query TaskFinalLabelingDialog_unitDsQuery($filters: UnitDFilter, $order: UnitDOrder, $first: Int, $after: String) {
    ...UnitDPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

type Props = { task: TaskFinalLabelingDialog_task$key } & DialogProps &
  Pick<MutationFormikProps<TaskFinalLabelingDialog_taskFinalLabelingMutation>, 'config' | 'onSubmit' | 'connections'>;

const TaskFinalLabelingDialog = ({ task: taskReference, config, onSubmit, connections, ...props }: Props) => {
  const task = useFragment(TaskFinalLabelingDialog_task, taskReference);
  const { id, sequence, title, problem, scrap, finalLabeling, unit } = task;

  const problemTabItems = [
    { id: 'all', label: '창작 + 스크랩' },
    { id: 'creation', label: '창작' },
    { id: 'scrap', label: '스크랩' },
  ];

  const [unitDsQueryReference, unitDsLoadQuery, unitDsDisposeQuery] =
    useQueryLoader<TaskFinalLabelingDialog_unitDsQuery>(unitDsForTaskFinalLabelingDialog);

  useEffect(() => {
    if (finalLabeling && finalLabeling.unitDs.length > 0)
      unitDsLoadQuery({ filters: { id_In: finalLabeling.unitDs.map(({ id }) => id) }, order: { order: 'DESC' } });
  }, []);

  const environment = useRelayEnvironment();
  const fetchUnitDefaultRating = ({ ...variables }: TaskFinalLabelingDialog_unitDefaultRatingQuery$variables) =>
    fetchQuery<TaskFinalLabelingDialog_unitDefaultRatingQuery>(
      environment,
      unitDefaultRatingForTaskFinalLabelingDialog,
      { ...variables },
      { fetchPolicy: 'store-or-network' },
    );

  return (
    <Dialog {...props}>
      <MutationFormik<
        TaskFinalLabelingDialog_taskFinalLabelingMutation,
        { unit?: string; schoolYear?: UnitSchoolYearEnum; schoolGrade?: UnitSchoolGradeEnum }
      >
        mutation={graphql`
          mutation TaskFinalLabelingDialog_taskFinalLabelingMutation($input: TaskFinalLabelingInput!) {
            taskFinalLabeling(input: $input) {
              id
              difficulty
              unitDs(order: { order: DESC }) {
                id
              }

              task {
                id
                ...TaskFinalLabelingDialog_task
              }
            }
          }
        `}
        initialValues={{
          task: id,
          unitDs: finalLabeling?.unitDs.map(({ id }) => id) || [],
          difficulty: finalLabeling?.difficulty || 0,
          ...(unit ? { unit: unit.id, schoolYear: undefined, schoolGrade: undefined } : {}),
        }}
        valuesTransformer={(values) => omit(values, ['unit', 'schoolYear', 'schoolGrade'])}
        config={{
          ...config,
          updater: (store, data) => {
            config?.updater?.(store, data);
            const task = store.get(id);
            const labeling = store.getRootField('taskFinalLabeling');
            task?.setLinkedRecord(labeling, 'finalLabeling');
          },
        }}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ values, setFieldValue, handleSubmit, dirty }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Header>
              <Grid sx={{ paddingRight: 4 }}>
                <Grid.Unit size={'max'}>
                  <Stack gapX={1}>
                    <Stack.Item>
                      <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>최종 라벨링하기</Text>
                    </Stack.Item>
                    {problem ? (
                      <Stack.Item>
                        <Suspense>
                          <View>
                            <Label variant={'accent'}>
                              <EnumPair typename={'TaskProblemTypeEnum'}>{problem.problemType}</EnumPair>
                              {` · ${title}`}
                            </Label>
                          </View>
                        </Suspense>
                      </Stack.Item>
                    ) : null}
                    <Stack.Item>
                      <Label variant={'primary'}>{sequence}</Label>
                    </Stack.Item>
                  </Stack>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Button variant={'primary'} onClick={() => handleSubmit()} leadingIcon={CheckIcon} disabled={!dirty}>
                    저장하기
                  </Button>
                </Grid.Unit>
              </Grid>
            </Dialog.Header>
            <Grid wrap={false} sx={{ height: '100%', overflowY: 'hidden' }}>
              <Grid.Unit size={3 / 4}>
                <TabHandler initialSelectIndex={0}>
                  {({ selectedIndex, handleSelect }) => (
                    <View
                      ref={scrollContainerRef}
                      sx={{
                        'padding': 3,
                        '&>*:not(:last-child)': { marginBottom: 5 },
                        'overflowY': 'auto',
                        'height': '100%',
                      }}
                    >
                      <NonFieldError ref={nonFieldErrorRef} />
                      <Stack gapX={1}>
                        <ItemList
                          items={problemTabItems}
                          renderItem={({ label }, index) => (
                            <Button
                              onClick={() => handleSelect(index)}
                              variant={selectedIndex === index ? 'outline' : 'invisible'}
                            >
                              {label}
                            </Button>
                          )}
                          renderItemWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
                        />
                      </Stack>
                      <Grid gapX={2} gapY={3}>
                        {problemTabItems[selectedIndex].id === 'all' ||
                        problemTabItems[selectedIndex].id === 'creation' ? (
                          <>
                            <Grid.Unit size={1 / 2}>
                              <Text fontSize={1}>창작한 문제</Text>
                              <Card
                                sx={{
                                  height: problemTabItems[selectedIndex].id === 'all' ? '35vh' : '70vh',
                                  overflowY: 'auto',
                                  marginTop: 1,
                                }}
                              >
                                <View sx={{ padding: 2 }}>
                                  <Katex>{problem?.problem || ''}</Katex>
                                </View>
                              </Card>
                            </Grid.Unit>
                            <Grid.Unit size={1 / 2}>
                              <Text fontSize={1}>창작한 해설</Text>
                              <Card
                                sx={{
                                  height: problemTabItems[selectedIndex].id === 'all' ? '35vh' : '70vh',
                                  overflowY: 'auto',
                                  marginTop: 1,
                                }}
                              >
                                <View sx={{ padding: 2 }}>
                                  <Katex>{problem?.solution || ''}</Katex>
                                </View>
                              </Card>
                            </Grid.Unit>
                          </>
                        ) : null}
                        {problemTabItems[selectedIndex].id === 'all' ||
                        problemTabItems[selectedIndex].id === 'scrap' ? (
                          <>
                            <Grid.Unit size={1 / 2}>
                              <Text fontSize={1}>스크랩한 문제</Text>
                              <Card
                                sx={{
                                  height: problemTabItems[selectedIndex].id === 'all' ? '35vh' : '70vh',
                                  overflowY: 'auto',
                                  marginTop: 1,
                                }}
                              >
                                <ItemList
                                  items={scrap?.problems || []}
                                  renderItem={({ objectUrl }) => (
                                    <Image src={objectUrl} alt={objectUrl} html style={{ display: 'block' }} />
                                  )}
                                  renderItemWrapper={(children, { id }) => <View key={id}>{children}</View>}
                                  emptyState={
                                    <View
                                      sx={{
                                        display: 'flex',
                                        height: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <EmptyState title={'스크랩한 문제가 없습니다'} />
                                    </View>
                                  }
                                />
                              </Card>
                            </Grid.Unit>
                            <Grid.Unit size={1 / 2}>
                              <Text fontSize={1} sx={{ marginTop: 3 }}>
                                스크랩한 해설
                              </Text>
                              <Card
                                sx={{
                                  height: problemTabItems[selectedIndex].id === 'all' ? '35vh' : '70vh',
                                  overflowY: 'auto',
                                  marginTop: 1,
                                }}
                              >
                                <ItemList
                                  items={scrap?.solutions || []}
                                  renderItem={({ objectUrl }) => (
                                    <Image src={objectUrl} alt={objectUrl} html style={{ display: 'block' }} />
                                  )}
                                  renderItemWrapper={(children, { id }) => <View key={id}>{children}</View>}
                                  emptyState={
                                    <View
                                      sx={{
                                        display: 'flex',
                                        height: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <EmptyState title={'스크랩한 해설이 없습니다'} />
                                    </View>
                                  }
                                />
                              </Card>
                            </Grid.Unit>
                          </>
                        ) : null}
                      </Grid>
                    </View>
                  )}
                </TabHandler>
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <View sx={{ padding: 3, backgroundColor: 'canvas.inset', height: '100%', overflowY: 'auto' }}>
                  <FormLayout>
                    <View>
                      <Text fontSize={2} fontWeight={'bold'}>
                        문제 정보
                      </Text>
                    </View>
                    <Card
                      sx={{
                        padding: 3,
                        backgroundColor: 'canvas.inset',
                      }}
                    >
                      <DescriptionList
                        item={task}
                        itemDescriptions={{
                          answerType: {
                            title: '문제 유형',
                            renderValue: ({ problem }) =>
                              problem?.answerType ? (
                                <Suspense>
                                  <EnumPair typename={'TaskProblemAnswerTypeEnum'}>{problem?.answerType}</EnumPair>
                                </Suspense>
                              ) : undefined,
                          },
                          answer: {
                            title: '정답',
                            renderValue: ({ problem }) => problem?.answer,
                          },
                          answerChoiceCount: {
                            title: '선지 개수',
                            renderValue: ({ problem }) =>
                              problem?.answerChoiceCount !== 0 ? problem?.answerChoiceCount : undefined,
                          },
                        }}
                        picks={['answerType', 'answer', 'answerChoiceCount']}
                        titleUnitSize={1 / 2}
                        descriptionUnitSize={1 / 2}
                      />
                    </Card>
                    <UnitSelectField
                      label={'학교급 및 교육과정'}
                      name={'unit'}
                      onChange={(value) => {
                        setFieldValue('unit', value);
                        setFieldValue('schoolYear', undefined);
                        setFieldValue('schoolGrade', undefined);
                        setFieldValue('difficulty', 0);
                        setFieldValue('unitDs', []);
                        unitDsDisposeQuery();
                      }}
                    />
                    <View>
                      <Text fontSize={2} fontWeight={'bold'}>
                        Rating
                      </Text>
                    </View>
                    <UnitSchoolYearSelectField
                      label={'학년'}
                      placeholder={'학년 선택'}
                      name={'schoolYear'}
                      variables={{ id: values.unit! }}
                      onChange={async (value) => {
                        setFieldValue('schoolYear', value);
                        const data = await fetchUnitDefaultRating({
                          schoolYear: value as UnitSchoolYearEnum,
                          schoolGrade: values.schoolGrade!,
                          skip: isNullable(values.schoolGrade),
                        }).toPromise();

                        if (data?.unitDefaultRating) {
                          setFieldValue('difficulty', data.unitDefaultRating);
                        }
                      }}
                    />
                    <UnitSchoolGradeSelectField
                      label={'등급'}
                      placeholder={'등급 선택'}
                      name={'schoolGrade'}
                      variables={{ id: values.unit! }}
                      onChange={async (value) => {
                        setFieldValue('schoolGrade', value);
                        const data = await fetchUnitDefaultRating({
                          schoolYear: values.schoolYear!,
                          schoolGrade: value as UnitSchoolGradeEnum,
                          skip: isNullable(values.schoolYear),
                        }).toPromise();

                        if (data?.unitDefaultRating) {
                          setFieldValue('difficulty', data.unitDefaultRating);
                        }
                      }}
                    />
                    <NumberField label={'Rating'} name={'difficulty'} required min={0} max={1000} />
                    <View>
                      <Text fontSize={2} fontWeight={'bold'}>
                        단원
                      </Text>
                    </View>
                    <DialogHandler
                      renderDialog={({ isOpen, closeDialog }) => (
                        <PreviousLabelingDialog
                          isOpen={isOpen}
                          onDismiss={closeDialog}
                          task={task}
                          wide
                          onClickGetButton={(rating, unitDs) => {
                            setFieldValue('difficulty', rating);
                            setFieldValue('unitDs', unitDs);
                            unitDsLoadQuery({ filters: { id_In: unitDs }, order: { order: 'DESC' } });
                            closeDialog();
                          }}
                        />
                      )}
                    >
                      <Card sx={{ padding: 3, backgroundColor: 'transparent', cursor: 'pointer' }}>
                        <Grid sx={{ alignItems: 'center' }}>
                          <Grid.Unit size={'max'}>
                            <Text fontSize={1} color={'fg.subtle'}>
                              이전 라벨링 가져오기
                            </Text>
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            <StyledOcticon icon={ChevronRightIcon} size={16} color={'neutral.emphasis'} />
                          </Grid.Unit>
                        </Grid>
                      </Card>
                    </DialogHandler>
                    <Suspense
                      fallback={
                        <View padding={2}>
                          <Spinner size={'small'} />
                        </View>
                      }
                    >
                      <UnitDSelectDialogTokenField
                        required
                        label={'Unit D'}
                        name={'unitDs'}
                        placeholder={'Unit A > B > C > D 선택'}
                        caption={'최대 3개 선택 가능'}
                        variables={
                          values.unit
                            ? {
                                filters: {
                                  unitId_In: [values.unit],
                                },
                              }
                            : undefined
                        }
                        onChange={(id) => {
                          const unitDs = values.unitDs.includes(id)
                            ? values.unitDs.filter((unitD) => unitD !== id)
                            : [...values.unitDs, id];
                          setFieldValue('unitDs', unitDs);
                          unitDsLoadQuery({ filters: { id_In: unitDs }, order: { order: 'DESC' } });
                        }}
                      />
                    </Suspense>
                    <View>
                      <Suspense
                        fallback={
                          <View padding={2}>
                            <Spinner size={'small'} />
                          </View>
                        }
                      >
                        {unitDsQueryReference ? (
                          <PreloadedQueryRenderer
                            query={unitDsForTaskFinalLabelingDialog}
                            queryReference={unitDsQueryReference}
                          >
                            {(queryReference) => (
                              <UnitDPaginator fragmentReference={queryReference}>
                                {({ unitDs }) => (
                                  <ItemList
                                    items={unitDs.edges}
                                    renderItem={({ node }) => <UnitDItem unitD={node} />}
                                    renderItemWrapper={(children, { node }, index) => (
                                      <View
                                        key={node.id}
                                        sx={{
                                          marginTop: index === 0 ? 0 : 2,
                                          borderRadius: 2,
                                          backgroundColor: 'neutral.muted',
                                          padding: 2,
                                        }}
                                      >
                                        <Grid wrap={false}>
                                          <Grid.Unit size={'max'}>
                                            {index === 0 ? (
                                              <Label sx={{ marginBottom: 1 }} variant={'default'}>
                                                Last Unit D
                                              </Label>
                                            ) : null}
                                            {children}
                                          </Grid.Unit>
                                          <Grid.Unit size={'min'}>
                                            <IconButton
                                              icon={XIcon}
                                              variant={'plain'}
                                              aria-label={'remove unitD'}
                                              onClick={() => {
                                                const unitDs = values.unitDs.filter((unitD) => unitD !== node.id);
                                                setFieldValue('unitDs', unitDs);
                                                unitDsLoadQuery({
                                                  filters: { id_In: unitDs },
                                                  order: { order: 'DESC' },
                                                });
                                              }}
                                            />
                                          </Grid.Unit>
                                        </Grid>
                                      </View>
                                    )}
                                  />
                                )}
                              </UnitDPaginator>
                            )}
                          </PreloadedQueryRenderer>
                        ) : null}
                      </Suspense>
                    </View>
                  </FormLayout>
                </View>
              </Grid.Unit>
            </Grid>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default TaskFinalLabelingDialog;
