/**
 * @generated SignedSource<<936fd1be2ec2497df533f9b97922cf5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PreviousLabelingDialog_task$data = {
  readonly difficulty: number | null;
  readonly firstLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly unitDs: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UnitDItem_unitD">;
    }>;
  } | null;
  readonly id: string;
  readonly secondLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly unitDs: ReadonlyArray<{
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UnitDItem_unitD">;
    }>;
  } | null;
  readonly unitDs: ReadonlyArray<{
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UnitDItem_unitD">;
  }>;
  readonly " $fragmentType": "PreviousLabelingDialog_task";
};
export type PreviousLabelingDialog_task$key = {
  readonly " $data"?: PreviousLabelingDialog_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"PreviousLabelingDialog_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order",
      "value": {
        "order": "DESC"
      }
    }
  ],
  "concreteType": "UnitD",
  "kind": "LinkedField",
  "name": "unitDs",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UnitDItem_unitD"
    }
  ],
  "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PreviousLabelingDialog_task",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskLabeling",
      "kind": "LinkedField",
      "name": "firstLabeling",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskLabeling",
      "kind": "LinkedField",
      "name": "secondLabeling",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};
})();

(node as any).hash = "477fc1a1c9d3f0306ec11155d6980e08";

export default node;
