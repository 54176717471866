/**
 * @generated SignedSource<<6f9917bc52abcce87dbe7699cbebac74>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormulaSetWindowPaginator_query$data = {
  readonly formulaSets: {
    readonly pages: {
      readonly " $fragmentSpreads": FragmentRefs<"Pagination_pages">;
    };
    readonly totalCount: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"FormulaSetConnectionDataTable_formulaSetConnection">;
  };
  readonly " $fragmentType": "FormulaSetWindowPaginator_query";
};
export type FormulaSetWindowPaginator_query$key = {
  readonly " $data"?: FormulaSetWindowPaginator_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormulaSetWindowPaginator_query">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order"
    },
    {
      "defaultValue": 1,
      "kind": "LocalArgument",
      "name": "page"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "pageSize"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./FormulaSetWindowPaginatorRefreshQuery.graphql')
    }
  },
  "name": "FormulaSetWindowPaginator_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        },
        {
          "kind": "Variable",
          "name": "page",
          "variableName": "page"
        },
        {
          "kind": "Variable",
          "name": "pageSize",
          "variableName": "pageSize"
        }
      ],
      "concreteType": "FormulaSetConnection",
      "kind": "LinkedField",
      "name": "formulaSets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Pages",
          "kind": "LinkedField",
          "name": "pages",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Pagination_pages"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FormulaSetConnectionDataTable_formulaSetConnection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "1f9404d9dc9a5861819e84764f68fed1";

export default node;
