/**
 * @generated SignedSource<<ea3b3879f5cf25412a8f674b92b49b46>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShortcutUpdateReloadDialog_user$data = {
  readonly id: string;
  readonly shortcuts: ReadonlyArray<{
    readonly label: string;
    readonly text: string;
  }>;
  readonly " $fragmentType": "ShortcutUpdateReloadDialog_user";
};
export type ShortcutUpdateReloadDialog_user$key = {
  readonly " $data"?: ShortcutUpdateReloadDialog_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShortcutUpdateReloadDialog_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShortcutUpdateReloadDialog_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Shortcut",
      "kind": "LinkedField",
      "name": "shortcuts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5db11b41c4c1c25a6ee7d62dc99c59dd";

export default node;
