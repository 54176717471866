/**
 * @generated SignedSource<<57547899b3a54ccd7b72e0affc868e48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductDiscountUpdateInput = {
  backgroundColor: string;
  id: string;
  mobileImage: Upload;
  webImage: Upload;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type ProductDiscountUpdateDialog_productDiscountUpdateMutation$variables = {
  input: ProductDiscountUpdateInput;
};
export type ProductDiscountUpdateDialog_productDiscountUpdateMutation$data = {
  readonly productDiscountUpdate: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProductDiscountUpdateDialog_productDiscount">;
  };
};
export type ProductDiscountUpdateDialog_productDiscountUpdateMutation = {
  response: ProductDiscountUpdateDialog_productDiscountUpdateMutation$data;
  variables: ProductDiscountUpdateDialog_productDiscountUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductDiscountUpdateDialog_productDiscountUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductDiscount",
        "kind": "LinkedField",
        "name": "productDiscountUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductDiscountUpdateDialog_productDiscount"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductDiscountUpdateDialog_productDiscountUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductDiscount",
        "kind": "LinkedField",
        "name": "productDiscountUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "webImage",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "mobileImage",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backgroundColor",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "85f9d8e36c95e235442c6947404aa4a7",
    "id": null,
    "metadata": {},
    "name": "ProductDiscountUpdateDialog_productDiscountUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ProductDiscountUpdateDialog_productDiscountUpdateMutation(\n  $input: ProductDiscountUpdateInput!\n) {\n  productDiscountUpdate(input: $input) {\n    id\n    ...ProductDiscountUpdateDialog_productDiscount\n  }\n}\n\nfragment ProductDiscountUpdateDialog_productDiscount on ProductDiscount {\n  id\n  product {\n    id\n    title\n  }\n  webImage {\n    key\n    objectUrl\n    size\n    id\n  }\n  mobileImage {\n    key\n    objectUrl\n    size\n    id\n  }\n  backgroundColor\n}\n"
  }
};
})();

(node as any).hash = "77ec9c7811cc30508ef4f67f4ed7bc4a";

export default node;
