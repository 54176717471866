import useFilterSearchPairsQuery from '../../../hooks/useFilterSearchPairsQuery';
import TextField, { TextFieldProps } from '../TextField';

type Props = { typename: string } & Omit<TextFieldProps, 'placeholder'>;

const FilterSearchTextField = ({ typename, sx, ...props }: Props) => {
  const filterSearchPairs = useFilterSearchPairsQuery(typename);
  return (
    <TextField
      placeholder={`${filterSearchPairs.map(({ value }) => value).join(', ')}`}
      sx={{ width: 300, ...sx }}
      {...props}
    />
  );
};

export default FilterSearchTextField;
