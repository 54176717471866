import { CheckIcon } from '@primer/octicons-react';
import { Space } from '@teamturing/react-kit';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { TaskHintFeedbackPublishDialog_task$key } from '../../../relay/__generated__/TaskHintFeedbackPublishDialog_task.graphql';
import { TaskHintFeedbackPublishDialog_taskHintFeedbackPublishMutation } from '../../../relay/__generated__/TaskHintFeedbackPublishDialog_taskHintFeedbackPublishMutation.graphql';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import FixedMiniView from '../../core/FixedMiniView';
import MutationButton, { MutationButtonProps } from '../../core/MutationButton';
import ScrollMiniKatex from '../../core/ScrollMiniKatex';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';

const TaskHintFeedbackPublishDialog_task = graphql`
  fragment TaskHintFeedbackPublishDialog_task on Task {
    id
    hint {
      id
      text
    }
    submitFeedback {
      id
      specialAnswer
      special
      general
    }
  }
`;

type Props = { task: TaskHintFeedbackPublishDialog_task$key } & DialogProps &
  Pick<MutationButtonProps<TaskHintFeedbackPublishDialog_taskHintFeedbackPublishMutation>, 'config' | 'onSubmit'>;

const TaskHintFeedbackPublishDialog = ({ task, config, onSubmit, ...props }: Props) => {
  const { id, hint, submitFeedback } = useFragment(TaskHintFeedbackPublishDialog_task, task);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>힌트 · 피드백 검수하기</Text>
      </Dialog.Header>
      <Dialog.Body>
        <View sx={{ '&>*:not(:first-child)': { marginTop: 6 } }}>
          <View sx={{ '&>*:not(:first-child)': { marginTop: 4 } }}>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>힌트</Text>
            <View>
              <Text fontSize={1}>힌트</Text>
              <Card sx={{ marginTop: 1, width: 'fit-content' }}>
                {hint?.text ? (
                  <ScrollMiniKatex>{hint?.text}</ScrollMiniKatex>
                ) : (
                  <FixedMiniView>
                    <Space py={4}>
                      <EmptyState title={'제작한 힌트가 없어요'} />
                    </Space>
                  </FixedMiniView>
                )}
              </Card>
            </View>
          </View>
          <View sx={{ '&>*:not(:first-child)': { marginTop: 4 } }}>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>다시 풀기 피드백</Text>
            <View>
              <Text fontSize={1}>특정 오답</Text>
              <Card sx={{ paddingX: 3, paddingY: 2, marginTop: 1 }}>
                <Text fontSize={1}>{submitFeedback?.specialAnswer || '-'}</Text>
              </Card>
            </View>
            <Stack gapX={3} alignItems={'start'}>
              <Stack.Item>
                <View>
                  <Text fontSize={1}>특정 오답</Text>
                  <Card sx={{ marginTop: 1 }}>
                    {submitFeedback?.special ? (
                      <ScrollMiniKatex>{submitFeedback?.special}</ScrollMiniKatex>
                    ) : (
                      <FixedMiniView>
                        <Space py={4}>
                          <EmptyState title={'제작한 피드백이 없어요'} />
                        </Space>
                      </FixedMiniView>
                    )}
                  </Card>
                </View>
              </Stack.Item>
              <Stack.Item>
                <View>
                  <Text fontSize={1}>일반 오답</Text>
                  <Card sx={{ marginTop: 1 }}>
                    {submitFeedback?.general ? (
                      <ScrollMiniKatex>{submitFeedback?.general}</ScrollMiniKatex>
                    ) : (
                      <FixedMiniView>
                        <Space py={4}>
                          <EmptyState title={'제작한 피드백이 없어요'} />
                        </Space>
                      </FixedMiniView>
                    )}
                  </Card>
                </View>
              </Stack.Item>
            </Stack>
          </View>
        </View>
      </Dialog.Body>
      <Dialog.Footer>
        <MutationButton<TaskHintFeedbackPublishDialog_taskHintFeedbackPublishMutation>
          mutation={graphql`
            mutation TaskHintFeedbackPublishDialog_taskHintFeedbackPublishMutation(
              $input: TaskHintFeedbackPublishInput!
            ) {
              taskHintFeedbackPublish(input: $input) {
                id
                ...TaskActionsMutationButtonStack_task
                ...TaskTaskHistoryPaginator_task
                hint {
                  id
                  publishedText
                  ...TaskHintStatusLabel_taskHint
                }

                submitFeedback {
                  id
                  publishedGeneral
                  publishedSpecial
                  publishedSpecialAnswer
                  ...TaskSubmitFeedbackStatusLabel_taskSubmitFeedback
                }
              }
            }
          `}
          input={{ task: id }}
          leadingIcon={CheckIcon}
          variant={'primary'}
          config={config}
          onSubmit={onSubmit}
        >
          출시하기
        </MutationButton>
      </Dialog.Footer>
    </Dialog>
  );
};

export default TaskHintFeedbackPublishDialog;
