import { graphql } from 'react-relay';

import { TaskTypingAutomationHistoryPaginator_task$key } from '../../../relay/__generated__/TaskTypingAutomationHistoryPaginator_task.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const TaskTypingAutomationHistoryPaginator_task = graphql`
  fragment TaskTypingAutomationHistoryPaginator_task on Task
  @argumentDefinitions(
    first: { type: Int, defaultValue: 10 }
    after: { type: String }
    order: { type: TypingAutomationHistoryOrder }
  )
  @refetchable(queryName: "TaskTypingAutomationHistoryPaginatorRefreshQuery") {
    typingAutomationHistories(first: $first, after: $after, order: $order)
      @connection(key: "TaskTypingAutomationHistoryPaginator_task_typingAutomationHistories") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          ...TypingAutomationHistoryTimelineItem_typingAutomationHistory
          ...TypingAutomationHistoryDialog_typingAutomationHistory
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<TaskTypingAutomationHistoryPaginator_task$key>, 'fragment'>;

const TaskTypingAutomationHistoryPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={TaskTypingAutomationHistoryPaginator_task} {...props} />
);

export default TaskTypingAutomationHistoryPaginator;
