/**
 * @generated SignedSource<<b3d37d95cfadacbc758f789e702cf5c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizDifferenceItemList_difference$data = {
  readonly answer?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly description?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly problem?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly solution?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly status?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly tip?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly unitDDescription?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly " $fragmentType": "QuizDifferenceItemList_difference";
};
export type QuizDifferenceItemList_difference$key = {
  readonly " $data"?: QuizDifferenceItemList_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizDifferenceItemList_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "DiffItem_diff"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizDifferenceItemList_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "answer",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "problem",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "solution",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "tip",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "unitDDescription",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "QuizDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "d0a22ac2c886d8eb9ba1900da3ecda59";

export default node;
