import { ChevronDownIcon, SearchIcon } from '@primer/octicons-react';
import { useTheme } from '@primer/react';
import { FieldConfig, useField } from 'formik';
import { debounce } from 'lodash-es';
import {
  ChangeEventHandler,
  ComponentProps,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent,
  KeyboardEvent as ReactKeyboardEvent,
  RefObject,
} from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { FocusKeys } from '../../../types/primer';
import { isNullable } from '../../../utils/is';
import { scrollIntoView } from '../../../utils/scroll';
import { formatKoreanByConsonant } from '../../../utils/string';
import AnchoredOverlay from '../../core/AnchoredOverlay';
import FormControl from '../../core/FormControl';
import TextInput, { TextInputProps } from '../../core/TextInput';
import View, { ViewProps } from '../../core/View';

type Props<T extends { id?: string | number | undefined | null }> = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  value?: string;
  onChange?: (item: T) => void;
  onSearch?: (search: string) => void;
  renderItemList: (
    {
      controllingElementRef,
      containerRef,
      handleSelect,
    }: {
      controllingElementRef: RefObject<HTMLInputElement>;
      containerRef: RefObject<HTMLElement>;
      handleSelect: (e: ReactMouseEvent<HTMLDivElement> | ReactKeyboardEvent<HTMLDivElement>, selected: T) => void;
    },
    itemProps: ViewProps,
  ) => React.ReactNode;
  onOverlayOpen?: () => void;
  onOverlayClose?: () => void;
  caption?: string;
} & Pick<TextInputProps, 'placeholder' | 'disabled' | 'name' | 'required' | 'trailingAction' | 'size'> &
  Pick<FieldConfig, 'validate'>;

const SearchOverlayField = <T extends { id?: string | number | undefined | null }>({
  label,
  labelConfig,
  value: propValue,
  onChange: propOnChange,
  placeholder,
  disabled: propDisabled,
  name = '',
  required,
  trailingAction,
  size = 'medium',
  renderItemList,
  onSearch,
  onOverlayOpen,
  onOverlayClose,
  caption,
  validate,
}: Props<T>) => {
  const inputId = `search_overlay_field_for_${label}`;

  const [{ value: baseValue }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);

      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();
  const value = !isNullable(propValue) ? propValue : baseValue;
  const handleChange = (selected: T) => {
    propOnChange ? propOnChange(selected) : setValue(selected.id);
    setError(undefined);
    handleAnchoredOverlayClose();
  };
  const handleClick = (e: ReactMouseEvent<HTMLDivElement>, selected: T) => handleChange(selected);
  const handleKeyDown = (e: ReactKeyboardEvent<HTMLDivElement>, selected: T) =>
    ['Enter'].includes(e.code) ? handleChange(selected) : null;
  const handleSelect = (e: ReactMouseEvent<HTMLDivElement> | ReactKeyboardEvent<HTMLDivElement>, selected: T) =>
    'code' in e ? handleKeyDown(e, selected) : handleClick(e, selected);

  const { theme } = useTheme();
  const containerRef = useRef<HTMLElement>(null);
  const activeDescendantRef = useRef<HTMLElement>();
  const controllingElementRef = useRef<HTMLInputElement>(null);
  const anchorRef = useRef<HTMLInputElement>(null);
  const anchorWidth = anchorRef.current?.getBoundingClientRect().width;

  const handleTextInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onSearch?.(e.target.value);
  };

  const [isAnchoredOverlayOpen, setIsAnchoredOverlayOpen] = useState<boolean>(false);
  const handleAnchoredOverlayOpen = () => {
    onOverlayOpen?.();

    setIsAnchoredOverlayOpen(true);
  };
  const handleAnchoredOverlayClose = () => {
    onOverlayClose?.();

    setIsAnchoredOverlayOpen(false);
  };

  const disabled = propDisabled || isSubmitting;

  const validationStatus = error ? 'error' : undefined;

  return (
    <FormControl required={required} disabled={disabled}>
      <FormControl.Label {...labelConfig}>{label}</FormControl.Label>
      <AnchoredOverlay
        width={'auto'}
        height={'auto'}
        overlayProps={{
          maxHeight: 'small',
          sx: { minWidth: anchorWidth, display: 'flex' },
        }}
        align={'center'}
        anchorRef={anchorRef}
        onOpen={handleAnchoredOverlayOpen}
        onClose={handleAnchoredOverlayClose}
        focusZoneSettings={{
          containerRef,
          activeDescendantFocus: controllingElementRef,
          focusOutBehavior: 'stop',
          bindKeys: FocusKeys.ArrowVertical,
          onActiveDescendantChanged: (current, previous) => {
            activeDescendantRef.current = current;
            if (current && containerRef.current) {
              current.style.backgroundColor = theme?.colors.neutral.muted;
              scrollIntoView(current, containerRef.current, { behavior: 'auto' });
            }
            if (previous && current !== previous) {
              previous.style.backgroundColor = '';
            }
          },
          focusableElementFilter: (elem) => elem instanceof HTMLDivElement,
        }}
        renderAnchor={(anchorProps) => (
          <TextInput
            {...anchorProps}
            key={value}
            id={inputId}
            value={value}
            size={size}
            block
            placeholder={placeholder}
            disabled={disabled}
            autoComplete="off"
            readOnly
            trailingVisual={ChevronDownIcon}
            trailingAction={trailingAction}
            validationStatus={validationStatus}
          />
        )}
        open={isAnchoredOverlayOpen}
      >
        <View sx={{ padding: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
          <TextInput
            ref={controllingElementRef}
            leadingVisual={SearchIcon}
            size={'medium'}
            block
            placeholder={'검색'}
            disabled={disabled}
            autoComplete="off"
            onChange={debounce(handleTextInputChange, 500)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && activeDescendantRef.current) {
                const activeDescendantEvent = new KeyboardEvent(e.type, e.nativeEvent);
                activeDescendantRef.current?.dispatchEvent(activeDescendantEvent);
              }
            }}
          />
          <View sx={{ overflow: 'auto' }}>
            {renderItemList(
              {
                controllingElementRef,
                containerRef,
                handleSelect,
              },
              {
                tabIndex: -1,
                sx: { paddingX: 2, paddingY: 1, cursor: 'pointer', transition: 'background-color 250ms' },
              },
            )}
          </View>
        </View>
      </AnchoredOverlay>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default SearchOverlayField;
export type { Props as SearchOverlayFieldProps };
