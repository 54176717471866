import { AlertFillIcon, CheckIcon, InfoIcon, XIcon } from '@primer/octicons-react';
import { ReactNode } from 'react';
import { cssTransition, Id as BaseToastId, toast as baseToast, ToastOptions as BaseToastOptions } from 'react-toastify';

import IconButton from '../../components/core/IconButton';
import Text from '../../components/core/Text';
import View from '../../components/core/View';

type ToastType = 'default' | 'success' | 'error' | 'warning';

const toastStyle: Record<ToastType, { color: string; icon: ReactNode }> = {
  default: { color: 'accent.emphasis', icon: <InfoIcon /> },
  success: { color: 'success.emphasis', icon: <CheckIcon /> },
  error: { color: 'danger.emphasis', icon: <AlertFillIcon /> },
  warning: { color: 'attention.emphasis', icon: <AlertFillIcon /> },
};

const useToast = () => {
  const toast = (text: string, type: ToastType, option?: BaseToastOptions) =>
    baseToast(
      ({ closeToast }) => (
        <View
          sx={{
            width: 'fit-content',
            display: 'flex',
            alignItems: 'stretch',
            backgroundColor: 'canvas.default',
            color: 'fg.default',
            borderRadius: '6px',
            borderWidth: '1px',
            borderColor: 'border.default',
            borderStyle: 'solid',
            overflow: 'hidden',
          }}
        >
          <View
            sx={{
              paddingX: 3,
              backgroundColor: toastStyle[type].color,
              color: 'fg.onEmphasis',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {toastStyle[type].icon}
          </View>
          <View sx={{ minWidth: '200px', maxWidth: '300px', padding: 3 }}>
            <Text fontSize={1} fontWeight={300} lineHeight={1}>
              {text}
            </Text>
          </View>
          <View sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 2 }}>
            <IconButton icon={XIcon} onClick={closeToast} aria-label={'close toast'} variant={'plain'} />
          </View>
        </View>
      ),
      { ...option },
    );
  const dismissToast = (toastId: BaseToastId) => baseToast.dismiss(toastId);
  const dismissAllToast = () => baseToast.dismiss();

  return { toast, dismissToast, dismissAllToast };
};

export const ToastTransition = cssTransition({ enter: 'toast_in', exit: 'toast_out' });
export default useToast;
