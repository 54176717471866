/**
 * @generated SignedSource<<12cf95d0c9108f8b0d0b2740999518bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPushDescriptionList_appPush$data = {
  readonly created: any;
  readonly destination: string | null;
  readonly id: string;
  readonly pushBody: string;
  readonly pushTitle: string;
  readonly startAt: any;
  readonly " $fragmentSpreads": FragmentRefs<"AppPushStatusLabel_appPush">;
  readonly " $fragmentType": "AppPushDescriptionList_appPush";
};
export type AppPushDescriptionList_appPush$key = {
  readonly " $data"?: AppPushDescriptionList_appPush$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppPushDescriptionList_appPush">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPushDescriptionList_appPush",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pushTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pushBody",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "destination",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppPushStatusLabel_appPush"
    }
  ],
  "type": "AppPush",
  "abstractKey": null
};

(node as any).hash = "1c27b32257a3c3166e6c835ebec6ea0b";

export default node;
