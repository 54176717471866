import { graphql, useFragment } from 'react-relay';

import { TaskSolutionVideoDifferenceItemList_difference$key } from '../../../relay/__generated__/TaskSolutionVideoDifferenceItemList_difference.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import Link from '../../core/Link';
import SquareView from '../../core/SquareView';
import Text from '../../core/Text';
import View from '../../core/View';
import DiffItem from '../../difference/DiffItem';

const TaskSolutionVideoDifferenceItemList_difference = graphql`
  fragment TaskSolutionVideoDifferenceItemList_difference on Difference {
    ... on TaskSolutionVideoDifference {
      taskVideoTitle {
        ...DiffItem_diff
      }
      isPremium {
        ...DiffItem_diff
      }
      thumbnailHorizontalUrl {
        ...DiffItem_diff
      }
      thumbnailVerticalUrl {
        ...DiffItem_diff
      }
      isRecommended {
        ...DiffItem_diff
      }
    }
  }
`;

type Props = {
  difference: TaskSolutionVideoDifferenceItemList_difference$key;
};

const TaskSolutionVideoDifferenceItemList = ({ difference }: Props) => {
  const { taskVideoTitle, isPremium, isRecommended, thumbnailHorizontalUrl, thumbnailVerticalUrl } = useFragment(
    TaskSolutionVideoDifferenceItemList_difference,
    difference,
  );

  const diffItems = [
    { title: '비디오', diff: taskVideoTitle },
    { title: '유료 콘텐츠인가요?', diff: isPremium },
    { title: '매력이 높은 영상인가요?', diff: isRecommended },
    {
      title: '세로형 썸네일',
      diff: thumbnailVerticalUrl,
      renderItem: (beforeOrAfter: string) => (
        <SquareView
          sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'border.default',
            borderRadius: 2,
            backgroundColor: 'neutral.emphasisPlus',
          }}
        >
          <View
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Link target={'_blank'} href={beforeOrAfter}>
              <Image src={beforeOrAfter} alt={'thumbnailVertical'} fill style={{ objectFit: 'contain' }} />
            </Link>
          </View>
        </SquareView>
      ),
    },
    {
      title: '가로형 썸네일',
      diff: thumbnailHorizontalUrl,
      renderItem: (beforeOrAfter: string) => (
        <SquareView
          sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'border.default',
            borderRadius: 2,
            backgroundColor: 'neutral.emphasisPlus',
          }}
        >
          <View
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Link target={'_blank'} href={beforeOrAfter}>
              <Image src={beforeOrAfter} alt={beforeOrAfter} fill style={{ objectFit: 'contain' }} />
            </Link>
          </View>
        </SquareView>
      ),
    },
  ].filter(({ diff }) => !isNullable(diff));

  return (
    <ItemList
      items={diffItems}
      renderItem={({ title, diff, renderItem }) => (
        <>
          <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
          <View sx={{ marginTop: 2 }}>
            <DiffItem diff={diff!} renderItem={renderItem} />
          </View>
        </>
      )}
      renderItemWrapper={(children, { title }, i) => (
        <View key={title} sx={{ marginTop: i > 0 ? 3 : 0 }}>
          {children}
        </View>
      )}
      emptyState={
        <View sx={{ paddingY: 6 }}>
          <EmptyState
            title={'기록되지 않은 히스토리이에요'}
            description={'이 기능이 만들어지기 전에 일어난 일이거나 오류로 누락된 히스토리입니다.'}
          />
        </View>
      }
    />
  );
};

export default TaskSolutionVideoDifferenceItemList;
