/**
 * @generated SignedSource<<2ea8ed7d4ac0da25946c3fdd0625c23e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CodepushSeverityEnum = "tier1" | "tier2" | "tier3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CodepushVersionDescriptionList_codepushVersion$data = {
  readonly created: any;
  readonly id: string;
  readonly severity: CodepushSeverityEnum;
  readonly targetBinaryVersion: string;
  readonly title: string;
  readonly version: number;
  readonly " $fragmentSpreads": FragmentRefs<"CodepushVersionStatusLabel_codepushVersion">;
  readonly " $fragmentType": "CodepushVersionDescriptionList_codepushVersion";
};
export type CodepushVersionDescriptionList_codepushVersion$key = {
  readonly " $data"?: CodepushVersionDescriptionList_codepushVersion$data;
  readonly " $fragmentSpreads": FragmentRefs<"CodepushVersionDescriptionList_codepushVersion">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CodepushVersionDescriptionList_codepushVersion",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "targetBinaryVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "severity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CodepushVersionStatusLabel_codepushVersion"
    }
  ],
  "type": "CodepushVersion",
  "abstractKey": null
};

(node as any).hash = "86dd5e6d2c7d3980b8ae7e1aae754c10";

export default node;
