import { DependencyList, useEffect } from 'react';

const useGlobalEventListener = <K extends keyof GlobalEventHandlersEventMap>(
  type: K,
  event: (e: DocumentEventMap[K]) => void,
  deps: DependencyList = [],
) => {
  useEffect(() => {
    document.addEventListener(type, event);
    return () => {
      document.removeEventListener(type, event);
    };
  }, deps);
};

export default useGlobalEventListener;
