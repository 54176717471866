/**
 * @generated SignedSource<<dbc77fb5ceb1c8a5e92009e521164f99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type WorkbookSourceNamesFilter = {
  search?: string | null;
};
export type WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery$variables = {
  filters?: WorkbookSourceNamesFilter | null;
};
export type WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery$data = {
  readonly workbookSourceNames: ReadonlyArray<string>;
};
export type WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery = {
  response: WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery$data;
  variables: WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      }
    ],
    "kind": "ScalarField",
    "name": "workbookSourceNames",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "98c589e523faf7e3517c586259e17c7b",
    "id": null,
    "metadata": {},
    "name": "WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery",
    "operationKind": "query",
    "text": "query WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery(\n  $filters: WorkbookSourceNamesFilter\n) {\n  workbookSourceNames(filters: $filters)\n}\n"
  }
};
})();

(node as any).hash = "21effb90eda80014770da280c8c4b327";

export default node;
