import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { TaskSolutionVideoCreateDialog_taskSolutionVideoCreateMutation } from '../../../relay/__generated__/TaskSolutionVideoCreateDialog_taskSolutionVideoCreateMutation.graphql';
import Button from '../../core/Button';
import CheckboxField from '../../core/CheckboxField';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TaskVideoSelectField from '../TaskVideoSelectField';
import TaskVideoThumbnailRadioGroupField from '../TaskVideoThumbnailRadioGroupField';

const taskSolutionVideoCreateForTaskVideoSolutionCreateDialog = graphql`
  mutation TaskSolutionVideoCreateDialog_taskSolutionVideoCreateMutation($input: TaskSolutionVideoCreateInput!) {
    taskSolutionVideoCreate(input: $input) {
      id
      task {
        id
        actions
        ...TaskTaskHistoryPaginator_task
        ...TaskDescriptionList_task
        solutionVideo {
          id
          modified
          isPremium
          isRecommended
          taskVideo {
            id
          }
          ...TaskSolutionVideoUpdateDialog_taskSolutionVideo
          ...TaskSolutionVideoGrid_taskSolutionVideo
        }
      }
    }
  }
`;

type Props = { taskId: string } & DialogProps &
  Pick<
    MutationFormikProps<TaskSolutionVideoCreateDialog_taskSolutionVideoCreateMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const TaskSolutionVideoCreateDialog = ({ taskId, config, onSubmit, connections, ...props }: Props) => {
  const { toast } = useToast();
  const handleCompletedCreate = () => toast('대표 해설 영상 지정 완료!', 'success');
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>해설 영상 설정</Text>
      </Dialog.Header>
      <MutationFormik<TaskSolutionVideoCreateDialog_taskSolutionVideoCreateMutation>
        mutation={taskSolutionVideoCreateForTaskVideoSolutionCreateDialog}
        initialValues={{ task: { id: taskId }, isPremium: true, video: { id: '' }, isRecommended: false }}
        connections={connections}
        config={{
          ...config,
          onCompleted: (...arg) => {
            config?.onCompleted?.(...arg);
            handleCompletedCreate();
          },
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <Suspense fallback={<Spinner />}>
                  <TaskVideoSelectField taskId={taskId} label={'비디오'} name={'video.id'} required />
                </Suspense>
                <Grid gapX={2} gapY={2}>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <CheckboxField label={'유료 콘텐츠인가요?'} name={'isPremium'} />
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <CheckboxField label={'매력이 높은 영상인가요?'} name={'isRecommended'} />
                  </Grid.Unit>
                </Grid>
                {values.video.id ? (
                  <Suspense fallback={<Spinner />}>
                    <TaskVideoThumbnailRadioGroupField
                      label={'세로형 썸네일'}
                      name={'thumbnailVerticalKey'}
                      taskVideoId={values.video.id}
                      filters={{ type: 'vertical' }}
                      renderContainer={(children) => (
                        <Grid gapX={3} gapY={2}>
                          {children}
                        </Grid>
                      )}
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={1 / 4}>
                          {children}
                        </Grid.Unit>
                      )}
                    />
                    <TaskVideoThumbnailRadioGroupField
                      label={'가로형 썸네일'}
                      name={'thumbnailHorizontalKey'}
                      taskVideoId={values.video.id}
                      filters={{ type: 'horizontal' }}
                      renderContainer={(children) => (
                        <Grid gapX={3} gapY={2}>
                          {children}
                        </Grid>
                      )}
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={1 / 3}>
                          {children}
                        </Grid.Unit>
                      )}
                    />
                  </Suspense>
                ) : null}
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                설정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default TaskSolutionVideoCreateDialog;
