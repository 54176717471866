import { graphql, useFragment } from 'react-relay';

import { SchoolItem_school$key } from '../../../relay/__generated__/SchoolItem_school.graphql';
import Text from '../../core/Text';
import View from '../../core/View';

const SchoolItem_school = graphql`
  fragment SchoolItem_school on School {
    title
    location
  }
`;

type Props = {
  school: SchoolItem_school$key;
};

const SchoolItem = ({ school }: Props) => {
  const { title, location } = useFragment(SchoolItem_school, school);

  return (
    <View>
      <View>
        <Text sx={{ fontSize: 1, lineHeight: 0 }}>{title}</Text>
      </View>
      <Text sx={{ fontSize: 0, color: 'fg.muted' }}>{location}</Text>
    </View>
  );
};

export default SchoolItem;
