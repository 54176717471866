/**
 * @generated SignedSource<<b7a91cb1fba9ed7a487dd451ced8e078>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderHistoryDialog_deliveryOrderHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly name: string;
    readonly picture: string | null;
  };
  readonly difference: {
    readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderDifferenceCounterLabel_difference" | "DeliveryOrderDifferenceItemList_difference">;
  } | null;
  readonly " $fragmentType": "DeliveryOrderHistoryDialog_deliveryOrderHistory";
};
export type DeliveryOrderHistoryDialog_deliveryOrderHistory$key = {
  readonly " $data"?: DeliveryOrderHistoryDialog_deliveryOrderHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderHistoryDialog_deliveryOrderHistory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryOrderHistoryDialog_deliveryOrderHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picture",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "difference",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeliveryOrderDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeliveryOrderDifferenceItemList_difference"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeliveryOrderHistory",
  "abstractKey": null
};

(node as any).hash = "57cfaa8c43fc9a5baeceff1ce7ff8791";

export default node;
