/**
 * @generated SignedSource<<a3daf263cb4b9b75dfd85d14c0a6574f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FormulaSetStatusEnum = "pending" | "published" | "review" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FormulaSetStatusLabel_formulaSet$data = {
  readonly status: FormulaSetStatusEnum;
  readonly " $fragmentType": "FormulaSetStatusLabel_formulaSet";
};
export type FormulaSetStatusLabel_formulaSet$key = {
  readonly " $data"?: FormulaSetStatusLabel_formulaSet$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormulaSetStatusLabel_formulaSet">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormulaSetStatusLabel_formulaSet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "FormulaSet",
  "abstractKey": null
};

(node as any).hash = "bc86ddc36af44d7dbfad8960715ffaf8";

export default node;
