import { CheckIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import { SchoolExamSourceInnerPdfUploadDialog_schoolExamSource$key } from '../../../relay/__generated__/SchoolExamSourceInnerPdfUploadDialog_schoolExamSource.graphql';
import { SchoolExamSourceInnerPdfUploadDialog_schoolExamSourceInnerPdfUploadMutation } from '../../../relay/__generated__/SchoolExamSourceInnerPdfUploadDialog_schoolExamSourceInnerPdfUploadMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import Text from '../../core/Text';
import UploadField from '../../core/UploadField';

const SchoolExamSourceInnerPdfUploadDialog_schoolExamSource = graphql`
  fragment SchoolExamSourceInnerPdfUploadDialog_schoolExamSource on SchoolExamSource {
    id
  }
`;

type Props = {
  schoolExamSource: SchoolExamSourceInnerPdfUploadDialog_schoolExamSource$key;
} & DialogProps &
  Pick<
    MutationFormikProps<SchoolExamSourceInnerPdfUploadDialog_schoolExamSourceInnerPdfUploadMutation>,
    'connections' | 'config' | 'onSubmit'
  >;

const SchoolExamSourceInnerPdfUploadDialog = ({ schoolExamSource, connections, config, onSubmit, ...props }: Props) => {
  const { id } = useFragment(SchoolExamSourceInnerPdfUploadDialog_schoolExamSource, schoolExamSource);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>업로드하기</Text>
      </Dialog.Header>

      <MutationFormik<SchoolExamSourceInnerPdfUploadDialog_schoolExamSourceInnerPdfUploadMutation>
        mutation={graphql`
          mutation SchoolExamSourceInnerPdfUploadDialog_schoolExamSourceInnerPdfUploadMutation(
            $input: SchoolExamSourceInnerPdfUploadInput!
          ) {
            schoolExamSourceInnerPdfUpload(input: $input) {
              id
              innerPdf {
                id
                key
                objectUrl
              }
              ...SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource
            }
          }
        `}
        initialValues={{
          innerPdf: {
            key: '',
            size: 0,
          },
          schoolExamSource: id,
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <>
            <Dialog.Body>
              <FormLayout>
                <UploadField name={'innerPdf'} label={'시험지 PDF'} accept={'application/pdf'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()} disabled={!dirty}>
                업로드하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default SchoolExamSourceInnerPdfUploadDialog;
