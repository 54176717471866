/**
 * @generated SignedSource<<ef35fb1b6010ecd537a5ef836e9da468>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type DeliveryOrderStatusEnum = "canceled" | "completed" | "waybill_required" | "%future added value";
export type DeliveryOrderTypeEnum = "gift" | "order" | "%future added value";
export type DeliveryOrderFilter = {
  AND?: DeliveryOrderFilter | null;
  DISTINCT?: boolean | null;
  NOT?: DeliveryOrderFilter | null;
  OR?: DeliveryOrderFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  created_Gte?: any | null;
  created_Lte?: any | null;
  deliveredById_Exact?: string | null;
  deliveredById_In?: ReadonlyArray<string> | null;
  delivered_Gte?: any | null;
  delivered_Lte?: any | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  modified_Gte?: any | null;
  modified_Lte?: any | null;
  search?: string | null;
  status_Exact?: DeliveryOrderStatusEnum | null;
  status_In?: ReadonlyArray<DeliveryOrderStatusEnum> | null;
  stuffId_Exact?: string | null;
  stuffId_In?: ReadonlyArray<string> | null;
  type_Exact?: DeliveryOrderTypeEnum | null;
  type_In?: ReadonlyArray<DeliveryOrderTypeEnum> | null;
};
export type deliveryOrder_deliveryOrderXlsxExportQuery$variables = {
  filters?: DeliveryOrderFilter | null;
};
export type deliveryOrder_deliveryOrderXlsxExportQuery$data = {
  readonly deliveryOrderXlsxExport: string;
};
export type deliveryOrder_deliveryOrderXlsxExportQuery = {
  response: deliveryOrder_deliveryOrderXlsxExportQuery$data;
  variables: deliveryOrder_deliveryOrderXlsxExportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      }
    ],
    "kind": "ScalarField",
    "name": "deliveryOrderXlsxExport",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "deliveryOrder_deliveryOrderXlsxExportQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "deliveryOrder_deliveryOrderXlsxExportQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "892acc744da92adf6d67898488c21a8b",
    "id": null,
    "metadata": {},
    "name": "deliveryOrder_deliveryOrderXlsxExportQuery",
    "operationKind": "query",
    "text": "query deliveryOrder_deliveryOrderXlsxExportQuery(\n  $filters: DeliveryOrderFilter\n) {\n  deliveryOrderXlsxExport(filters: $filters)\n}\n"
  }
};
})();

(node as any).hash = "f7594077f781f805571784ea73fe7528";

export default node;
