import { useTheme } from '@primer/react';
import React from 'react';

import { isArray } from '../../../utils/is';
import View, { ViewProps } from '../View';

import Unit from './Unit';

type Props = {
  gapX?: ViewProps['padding'];
  gapY?: ViewProps['margin'];
  reverse?: boolean | boolean[];
  wrap?: boolean;
} & Pick<ViewProps, 'sx'>;

const Grid = ({ children, gapX = 0, gapY = 0, reverse = false, wrap = true, sx }: React.PropsWithChildren<Props>) => {
  const { theme } = useTheme();
  const space = theme?.['space'].map((s: string) => parseInt(s));

  return (
    <View
      sx={{
        ...sx,
        'display': 'flex',
        '& > *': {
          paddingX: isArray(gapX) ? gapX.map((gx) => `${space[gx as number] / 2}px`) : `${space[gapX as number] / 2}px`,
          marginTop: isArray(gapY) ? gapY.map((gy) => gy) : (gapY as number),
        },
        'marginX': isArray(gapX) ? gapX.map((g) => `${-space[g as number] / 2}px`) : `${-space[gapX as number] / 2}px`,
        'marginTop': isArray(gapY) ? gapY.map((gy) => -(gy as number)) : -(gapY as number),
        'flexDirection': isArray(reverse)
          ? reverse.map((r) => (r ? 'row-reverse' : 'row'))
          : reverse
          ? 'row-reverse'
          : 'row',
        'flexWrap': !wrap
          ? 'nowrap'
          : isArray(reverse)
          ? reverse.map((r) => (r ? 'wrap-reverse' : 'wrap'))
          : reverse
          ? 'wrap-reverse'
          : 'wrap',
      }}
    >
      {children}
    </View>
  );
};

export default Object.assign(Grid, { Unit: Unit });
export type { Props as GridProps };
