/**
 * @generated SignedSource<<61e10cb638e67a12aaafded56fe98a48>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MATOrganizationTypeEnum = "academy" | "school" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationDescriptionList_matOrganization$data = {
  readonly created: any;
  readonly createdBy: string | null;
  readonly prefix: string;
  readonly title: string;
  readonly type: MATOrganizationTypeEnum;
  readonly " $fragmentType": "MATOrganizationDescriptionList_matOrganization";
};
export type MATOrganizationDescriptionList_matOrganization$key = {
  readonly " $data"?: MATOrganizationDescriptionList_matOrganization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATOrganizationDescriptionList_matOrganization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATOrganizationDescriptionList_matOrganization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "MATOrganization",
  "abstractKey": null
};

(node as any).hash = "0c641eba4d6d89d433c073e9c427ed71";

export default node;
