import { Monaco } from '../../Editor';

type ProvideCodeActionsParameters = Parameters<
  Parameters<Monaco['languages']['registerCodeActionProvider']>[1]['provideCodeActions']
>;

const provideCodeActions = (...args: ProvideCodeActionsParameters) => {
  const [model, range] = args;
  const rangedValue = model.getValueInRange(range);
  return {
    actions: [
      {
        title: `팬텀박스 수식 만들기 (phantomBox)`,
        kind: 'refactor',
        edit: {
          edits: [
            {
              resource: model.uri,
              versionId: model.getVersionId(),
              textEdit: {
                range: range,
                text: `\\boxed{\\phantom{${rangedValue}}}`,
                insertAsSnippet: true,
              },
            },
          ],
        },
        isPreferred: true,
      },
      {
        title: `볼드 수식 만들기 (bm)`,
        kind: 'refactor',
        edit: {
          edits: [
            {
              resource: model.uri,
              versionId: model.getVersionId(),
              textEdit: {
                range: range,
                text: `\\bm{${rangedValue}}`,
                insertAsSnippet: true,
              },
            },
          ],
        },
      },
      {
        title: `작은 따옴표 만들기`,
        kind: 'refactor',
        edit: {
          edits: [
            {
              resource: model.uri,
              versionId: model.getVersionId(),
              textEdit: {
                range: range,
                text: `‘${rangedValue}’`,
                insertAsSnippet: true,
              },
            },
          ],
        },
      },
    ],
    dispose: () => {},
  };
};

export default provideCodeActions;
