/**
 * @generated SignedSource<<2e6586bc8f3dbae4caa4432990947d95>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RaffleStuffActionEnum = "raffle_stuff_delete" | "raffle_stuff_update" | "%future added value";
export type StuffTypeEnum = "coupon" | "delivery" | "gifticon" | "premium_non_refundable" | "premium_refundable" | "%future added value";
export type RaffleStuffId_raffleStuffQuery$variables = {
  id: string;
};
export type RaffleStuffId_raffleStuffQuery$data = {
  readonly raffleStuff: {
    readonly actions: ReadonlyArray<RaffleStuffActionEnum>;
    readonly created: any;
    readonly createdBy: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    };
    readonly description: string | null;
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly stuff: {
      readonly id: string;
      readonly title: string;
      readonly type: StuffTypeEnum;
    };
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"RaffleStuffPreviewItem_raffleStuff" | "RaffleStuffUpdateDialog_raffleStuff">;
  };
};
export type RaffleStuffId_raffleStuffQuery = {
  response: RaffleStuffId_raffleStuffQuery$data;
  variables: RaffleStuffId_raffleStuffQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Stuff",
  "kind": "LinkedField",
  "name": "stuff",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    (v3/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "picture",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeleted",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RaffleStuffId_raffleStuffQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RaffleStuff",
        "kind": "LinkedField",
        "name": "raffleStuff",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RaffleStuffPreviewItem_raffleStuff"
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RaffleStuffUpdateDialog_raffleStuff"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RaffleStuffId_raffleStuffQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RaffleStuff",
        "kind": "LinkedField",
        "name": "raffleStuff",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UploadResult",
            "kind": "LinkedField",
            "name": "colorThumbnail",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "objectUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backgroundColor",
            "storageKey": null
          },
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "360ffdc7c192f5f2e35ff434a515e90b",
    "id": null,
    "metadata": {},
    "name": "RaffleStuffId_raffleStuffQuery",
    "operationKind": "query",
    "text": "query RaffleStuffId_raffleStuffQuery(\n  $id: ID!\n) {\n  raffleStuff(id: $id) {\n    id\n    title\n    actions\n    description\n    ...RaffleStuffPreviewItem_raffleStuff\n    stuff {\n      id\n      type\n      title\n    }\n    createdBy {\n      id\n      ...UserAvatarText_user\n    }\n    created\n    modifiedBy {\n      id\n      ...UserAvatarText_user\n    }\n    modified\n    ...RaffleStuffUpdateDialog_raffleStuff\n  }\n}\n\nfragment RaffleStuffPreviewItem_raffleStuff on RaffleStuff {\n  colorThumbnail {\n    objectUrl\n  }\n  backgroundColor\n}\n\nfragment RaffleStuffUpdateDialog_raffleStuff on RaffleStuff {\n  id\n  stuff {\n    id\n  }\n  title\n  description\n  backgroundColor\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "cceb031198673076efc608312dd8afd4";

export default node;
