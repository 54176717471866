/**
 * @generated SignedSource<<0ec2230ffecdc33d465085fb139c3899>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type QuizSetStatusEnum = "labeling" | "published" | "%future added value";
export type QuizSetFilter = {
  AND?: QuizSetFilter | null;
  DISTINCT?: boolean | null;
  NOT?: QuizSetFilter | null;
  OR?: QuizSetFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  status_Exact?: QuizSetStatusEnum | null;
  status_In?: ReadonlyArray<QuizSetStatusEnum> | null;
  unitAId_Exact?: string | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_Exact?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_Exact?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitId_Exact?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitTitle_Contains?: string | null;
};
export type QuizSetOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
};
export type quizSet_quizSetsQuery$variables = {
  filters?: QuizSetFilter | null;
  order?: QuizSetOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type quizSet_quizSetsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"QuizSetWindowPaginator_query">;
};
export type quizSet_quizSetsQuery = {
  response: quizSet_quizSetsQuery$data;
  variables: quizSet_quizSetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "quizSet_quizSetsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "QuizSetWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "quizSet_quizSetsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuizSetConnection",
        "kind": "LinkedField",
        "name": "quizSets",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "QuizSetEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QuizSet",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnitB",
                    "kind": "LinkedField",
                    "name": "unitB",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnitC",
                    "kind": "LinkedField",
                    "name": "unitC",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "modifiedBy",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "picture",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeleted",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "modified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d33be7ae98decfad974960a5e0e6e66a",
    "id": null,
    "metadata": {},
    "name": "quizSet_quizSetsQuery",
    "operationKind": "query",
    "text": "query quizSet_quizSetsQuery(\n  $filters: QuizSetFilter\n  $order: QuizSetOrder\n  $page: Int\n  $pageSize: Int\n) {\n  ...QuizSetWindowPaginator_query_36liK3\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment QuizSetConnectionDataTable_quizSetConnection on QuizSetConnection {\n  edges {\n    node {\n      id\n      unitB {\n        id\n        title\n      }\n      unitC {\n        id\n        title\n      }\n      modifiedBy {\n        ...UserAvatarText_user\n        id\n      }\n      modified\n      ...QuizSetStatusLabel_quizSet\n    }\n  }\n}\n\nfragment QuizSetStatusLabel_quizSet on QuizSet {\n  status\n}\n\nfragment QuizSetWindowPaginator_query_36liK3 on Query {\n  quizSets(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...QuizSetConnectionDataTable_quizSetConnection\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "f73e231107527894bc2c26dc0de172f8";

export default node;
