import { graphql, useFragment } from 'react-relay';

import {
  StuffConnectionDataTable_stuffConnection$data,
  StuffConnectionDataTable_stuffConnection$key,
} from '../../../relay/__generated__/StuffConnectionDataTable_stuffConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import UserAvatarText from '../../user/UserAvatarText';

const StuffConnectionDataTable_stuffConnection = graphql`
  fragment StuffConnectionDataTable_stuffConnection on StuffConnection {
    edges {
      node {
        id
        title
        type
        createdBy {
          id
          ...UserAvatarText_user
        }
        created
        modified
      }
    }
  }
`;

type Props = {
  stuffConnection: StuffConnectionDataTable_stuffConnection$key;
} & Omit<DataTableProps<StuffConnectionDataTable_stuffConnection$data['edges'][number]['node']>, 'rows' | 'columns'>;

const StuffConnectionDataTable = ({ stuffConnection, ...props }: Props) => {
  const { edges } = useFragment(StuffConnectionDataTable_stuffConnection, stuffConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'type',
          title: '타입',
          renderValue: ({ type }) => <EnumPair typename={'StuffTypeEnum'}>{type}</EnumPair>,
          width: 80,
        },
        { field: 'title', title: '제목', renderValue: ({ title }) => title, width: 360 },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 120,
        },
        { field: 'created', title: '제작일', renderValue: ({ created }) => <DateText>{created}</DateText>, width: 160 },
        {
          field: 'modified',
          title: '수정일',
          renderValue: ({ modified }) => <DateText>{modified}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default StuffConnectionDataTable;
