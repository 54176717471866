/**
 * @generated SignedSource<<5a5ba7f3c27cbb9d0362de3ea8bc7ef8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type MATSchoolYearTypeEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3" | "high_school_N" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type MATInternalUserFilter = {
  AND?: MATInternalUserFilter | null;
  NOT?: MATInternalUserFilter | null;
  OR?: MATInternalUserFilter | null;
  distinct?: boolean | null;
  friendTag_Exact?: string | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  phoneNumber_Exact?: string | null;
  premium_Exists_In?: ReadonlyArray<boolean> | null;
  schoolType_In?: ReadonlyArray<MATSchoolTypeEnum> | null;
  schoolYearType_Exact?: MATSchoolYearTypeEnum | null;
  search?: string | null;
  sequence_Exact?: number | null;
};
export type MATInternalUserOrder = {
  dateJoined?: Ordering | null;
  id?: Ordering | null;
  studentprofile_ChargeExpirationDate?: Ordering | null;
  username?: Ordering | null;
};
export type InternalUserWindowPaginatorRefreshQuery$variables = {
  filters?: MATInternalUserFilter | null;
  order?: MATInternalUserOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type InternalUserWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InternalUserWindowPaginator_query">;
};
export type InternalUserWindowPaginatorRefreshQuery = {
  response: InternalUserWindowPaginatorRefreshQuery$data;
  variables: InternalUserWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": {
      "dateJoined": "DESC"
    },
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternalUserWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "InternalUserWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternalUserWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATInternalUserConnection",
        "kind": "LinkedField",
        "name": "internalUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MATInternalUserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MATInternalUser",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "loginId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userSequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "userName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phoneNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MATInternalStudentProfile",
                    "kind": "LinkedField",
                    "name": "studentProfile",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "schoolYearType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profileImageUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "friendTag",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "statusMessage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "07c345c55f32a482e4eecfdd1d7f7661",
    "id": null,
    "metadata": {},
    "name": "InternalUserWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query InternalUserWindowPaginatorRefreshQuery(\n  $filters: MATInternalUserFilter\n  $order: MATInternalUserOrder = {dateJoined: DESC}\n  $page: Int = 1\n  $pageSize: Int = 10\n) {\n  ...InternalUserWindowPaginator_query_36liK3\n}\n\nfragment InternalUserConnectionDataTable_internalUserConnection on MATInternalUserConnection {\n  edges {\n    node {\n      id\n      loginId\n      userSequence\n      userName\n      phoneNumber\n      studentProfile {\n        schoolYearType\n        profileImageUrl\n        friendTag\n        statusMessage\n      }\n    }\n  }\n}\n\nfragment InternalUserWindowPaginator_query_36liK3 on Query {\n  internalUsers(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...InternalUserConnectionDataTable_internalUserConnection\n  }\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n"
  }
};
})();

(node as any).hash = "95e0842a89141ed06156ff23113a3873";

export default node;
