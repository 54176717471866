import { CheckIcon } from '@teamturing/icons';
import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  TaskSolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery,
  TaskSolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery$variables,
} from '../../../relay/__generated__/TaskSolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery.graphql';
import { TaskSolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation } from '../../../relay/__generated__/TaskSolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation.graphql';
import { TaskSolvedProblemUserAnswerUpdateDialog_task$key } from '../../../relay/__generated__/TaskSolvedProblemUserAnswerUpdateDialog_task.graphql';
import { isNullable } from '../../../utils/is';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import DialogButton from '../../core/DialogButton';
import ErrorBoundary from '../../core/ErrorBoundary';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';

const TaskSolvedProblemUserAnswerUpdateDialog_task = graphql`
  fragment TaskSolvedProblemUserAnswerUpdateDialog_task on Task {
    sequence
    problem {
      id
      answer
    }
  }
`;

type Props = {
  task: TaskSolvedProblemUserAnswerUpdateDialog_task$key;
} & DialogProps &
  Pick<
    MutationFormikProps<TaskSolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const TaskSolvedProblemUserAnswerUpdateDialog = ({ task, config, onSubmit, connections, ...props }: Props) => {
  const { sequence, problem } = useFragment(TaskSolvedProblemUserAnswerUpdateDialog_task, task);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>정답 처리하기</Text>
      </Dialog.Header>
      <MutationFormik<TaskSolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation>
        mutation={graphql`
          mutation TaskSolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation(
            $input: MATSolvedProblemUserAnswerUpdateInput!
          ) {
            solvedProblemUserAnswerUpdate(input: $input) {
              previousAnswerCount
              currentAnswerCount
            }
          }
        `}
        initialValues={{
          sequence,
          previousAnswer: undefined,
          currentAnswer: Number(problem?.answer),
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit, values }, { nonFieldErrorRef, scrollContainerRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <NumberField label={'기존 유저 정답'} placeholder={'기존 유저 정답 입력'} name={'previousAnswer'} />
                <NumberField label={'변경될 유저 정답'} placeholder={'변경될 유저 정답 입력'} name={'currentAnswer'} />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <DialogButton
                variant={'primary'}
                disabled={isNullable(values.previousAnswer) || isNullable(values.currentAnswer)}
                renderDialog={({ isOpen, closeDialog }) => (
                  <Dialog isOpen={isOpen} onDismiss={closeDialog}>
                    <Dialog.Header>
                      <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>확인하기</Text>
                    </Dialog.Header>
                    <Dialog.Body>
                      <ErrorBoundary>
                        <Suspense
                          fallback={
                            <View sx={{ paddingY: 3 }}>
                              <Spinner />
                            </View>
                          }
                        >
                          <Text fontSize={1} sx={{ whiteSpace: 'pre-wrap' }}>
                            <SolvedProblemUserAnswerHelpText
                              input={{
                                sequence: values.sequence,
                                previousAnswer: values.previousAnswer,
                                currentAnswer: values.currentAnswer,
                              }}
                            />
                          </Text>
                        </Suspense>
                      </ErrorBoundary>
                    </Dialog.Body>
                    <Dialog.Footer>
                      <Button
                        variant={'primary'}
                        leadingIcon={CheckIcon}
                        onClick={() => {
                          closeDialog();
                          handleSubmit();
                        }}
                      >
                        진행하기
                      </Button>
                    </Dialog.Footer>
                  </Dialog>
                )}
              >
                확인하기
              </DialogButton>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

const SolvedProblemUserAnswerHelpText = ({
  ...variables
}: TaskSolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery$variables) => {
  const [{ solvedProblemUserAnswer }] =
    useLazyLoadQuery<TaskSolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery>(
      graphql`
        query TaskSolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery(
          $input: MATSolvedProblemUserAnswerInput!
        ) {
          solvedProblemUserAnswer(input: $input) {
            helpText
          }
        }
      `,
      variables,
      { fetchPolicy: 'store-or-network' },
    );

  return <>{solvedProblemUserAnswer.helpText}</>;
};

export default TaskSolvedProblemUserAnswerUpdateDialog;
