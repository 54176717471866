import { graphql, useFragment } from 'react-relay';

import {
  ProductEventStatusEnum,
  ProductEventStatusLabel_productEvent$key,
} from '../../../relay/__generated__/ProductEventStatusLabel_productEvent.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const ProductEventStatusLabel_productEvent = graphql`
  fragment ProductEventStatusLabel_productEvent on ProductEvent {
    status
  }
`;

type Props = {
  productEvent: ProductEventStatusLabel_productEvent$key;
} & Omit<LabelProps, 'variant'>;

const ProductEventStatusLabel = ({ productEvent, ...props }: Props) => {
  const { status } = useFragment(ProductEventStatusLabel_productEvent, productEvent);

  const labelPropsByStatus: Record<ProductEventStatusEnum, LabelProps> = {
    'inactive': { variant: 'default' },
    'pending': { variant: 'accent' },
    'ongoing': { variant: 'success' },
    'finished': { variant: 'secondary' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'ProductEventStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default ProductEventStatusLabel;
