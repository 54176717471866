/**
 * @generated SignedSource<<1d5fd70dbe98ade5c40d46ebe8ba42ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TypingAutomationHistoryDialog_typingAutomationHistory$data = {
  readonly created: any;
  readonly problem: string;
  readonly script: {
    readonly description: string | null;
    readonly id: string;
    readonly title: string;
  };
  readonly solution: string;
  readonly task: {
    readonly id: string;
    readonly scrap: {
      readonly id: string;
      readonly problems: ReadonlyArray<{
        readonly id: string;
        readonly objectUrl: string;
      }>;
      readonly solutions: ReadonlyArray<{
        readonly id: string;
        readonly objectUrl: string;
      }>;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"TaskTypingAutomationHistoryCreateDialog_task">;
  };
  readonly " $fragmentType": "TypingAutomationHistoryDialog_typingAutomationHistory";
};
export type TypingAutomationHistoryDialog_typingAutomationHistory$key = {
  readonly " $data"?: TypingAutomationHistoryDialog_typingAutomationHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"TypingAutomationHistoryDialog_typingAutomationHistory">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TypingAutomationHistoryDialog_typingAutomationHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TypingAutomationScript",
      "kind": "LinkedField",
      "name": "script",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "solution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "task",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Scrap",
          "kind": "LinkedField",
          "name": "scrap",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "solutions",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "problems",
              "plural": true,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskTypingAutomationHistoryCreateDialog_task"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TypingAutomationHistory",
  "abstractKey": null
};
})();

(node as any).hash = "bd1b2b5c8769a0de64e2e442f56a93a4";

export default node;
