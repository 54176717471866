import { CircleSlashIcon, KeyIcon, PencilIcon, SearchIcon } from '@primer/octicons-react';
import { Space } from '@teamturing/react-kit';
import { FormikProps } from 'formik';
import { GraphQLError } from 'graphql/index';
import React, { Suspense, useEffect, useRef } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import Card from '../../components/core/Card';
import DialogButton from '../../components/core/DialogButton';
import DialogIconButton from '../../components/core/DialogIconButton';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationConfirmIconButton from '../../components/core/MutationConfirmIconButton';
import PreloadedQueryRenderer from '../../components/core/PreloadedQueryRenderer';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import TabList from '../../components/core/TabList';
import Text from '../../components/core/Text';
import Tooltip from '../../components/core/Tooltip';
import View from '../../components/core/View';
import GroupCategoryDialog from '../../components/group/GroupCategoryDialog';
import GroupInviteDialog from '../../components/group/GroupInviteDialog';
import UserAvatarText from '../../components/user/UserAvatarText';
import UserConnectionDataTable from '../../components/user/UserConnectionDataTable';
import UserCreateDialog from '../../components/user/UserCreateDialog';
import UserPaginator from '../../components/user/UserPaginator';
import UserPermissionsUpdateDialog from '../../components/user/UserPermissionsUpdateDialog';
import UserUpdateDialog from '../../components/user/UserUpdateDialog';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { permission_groupQuery } from '../../relay/__generated__/permission_groupQuery.graphql';
import { permission_meQuery } from '../../relay/__generated__/permission_meQuery.graphql';
import { permission_userDeleteMutation } from '../../relay/__generated__/permission_userDeleteMutation.graphql';
import { permission_usersQuery } from '../../relay/__generated__/permission_usersQuery.graphql';
import { parseGraphQLError } from '../../utils/error';
import { NextPage } from '../_app';

type Props = {};

const meForPermission = graphql`
  query permission_meQuery {
    me {
      id
      leadingGroups {
        id
        title
      }
    }
  }
`;

const usersForPermission = graphql`
  query permission_usersQuery($filters: UserFilter, $order: UserOrder) {
    users(filters: $filters, order: $order) {
      totalCount
      edges {
        node {
          id
          ...UserPermissionsUpdateDialog_user
          ...UserUpdateDialog_user
        }
      }
    }
    ...UserPaginator_query @arguments(filters: $filters, order: $order)
  }
`;

const groupForPermission = graphql`
  query permission_groupQuery($id: ID!) {
    group(id: $id) {
      id
      title
      leaders {
        id
        ...UserAvatarText_user
        group {
          title
        }
      }
      ...UserCreateDialog_group
      ...GroupInviteDialog_group
      ...GroupCategoryDialog_group
    }
  }
`;

const Permission: NextPage<Props> = () => {
  const [{ me }] = useLazyLoadQuery<permission_meQuery>(meForPermission, {});
  const { toast } = useToast();

  const [groupQueryReference, loadGroupQuery] = useQueryLoader<permission_groupQuery>(groupForPermission);
  const tabItems = me.leadingGroups.map(({ id, title }) => ({ id, text: title }));
  const initialTabId = tabItems[0]?.id;

  useEffect(() => {
    if (initialTabId) loadGroupQuery({ id: initialTabId });
  }, []);

  const queryFormikRef = useRef<FormikProps<any>>(null);

  return (
    <View>
      <Head siteTitle={'그룹 · 권한 관리'} />
      <View>
        <Text as={'h1'}>그룹 · 권한 관리</Text>
        {initialTabId ? (
          <QueryFormik<permission_usersQuery>
            innerRef={queryFormikRef}
            query={usersForPermission}
            initialValues={{ filters: { groupId_Exact: initialTabId } }}
            options={{ fetchPolicy: 'store-and-network' }}
          >
            {({ values, setValues, submitForm }, userReference) => {
              return (
                <>
                  <TabList
                    items={tabItems}
                    renderItem={({ text }) => (
                      <Text fontSize={1} whiteSpace={'nowrap'}>
                        {text}
                      </Text>
                    )}
                    selectedIndex={tabItems.findIndex(({ id }) => id === values.filters?.groupId_Exact)}
                    onSelect={({ id }) => {
                      setValues({ ...values, filters: { ...values.filters, groupId_Exact: id } });
                      loadGroupQuery({ id }, { fetchPolicy: 'store-and-network' });
                      setTimeout(() => submitForm());
                    }}
                  />
                  <HorizontalDivider mb={3} />
                  {groupQueryReference ? (
                    <Suspense
                      fallback={
                        <Space py={10}>
                          <Spinner />
                        </Space>
                      }
                    >
                      <PreloadedQueryRenderer<permission_groupQuery>
                        query={groupForPermission}
                        queryReference={groupQueryReference}
                      >
                        {({ group }) => (
                          <>
                            <Space mb={3}>
                              <Grid sx={{ alignItems: 'center' }} gapX={2} gapY={1}>
                                <Grid.Unit size={'max'}>
                                  <Text as={'h2'}>{group.title}</Text>
                                </Grid.Unit>
                                <Grid.Unit size={'min'}>
                                  <Stack gapX={2}>
                                    <Stack.Item>
                                      <DialogButton
                                        size={'large'}
                                        renderDialog={({ isOpen, closeDialog }) => (
                                          <GroupInviteDialog
                                            isOpen={isOpen}
                                            onDismiss={closeDialog}
                                            group={group}
                                            config={{
                                              onCompleted: () => {
                                                toast('팀원이 추가됐어요', 'success');
                                                closeDialog();
                                                queryFormikRef.current?.submitForm();
                                              },
                                              onError: () => {
                                                toast('팀원을 추가하지 못했어요', 'error');
                                              },
                                            }}
                                          />
                                        )}
                                      >
                                        팀원 추가하기
                                      </DialogButton>
                                    </Stack.Item>
                                    <Stack.Item>
                                      <DialogButton
                                        size={'large'}
                                        renderDialog={({ isOpen, closeDialog }) => (
                                          <UserCreateDialog
                                            isOpen={isOpen}
                                            onDismiss={closeDialog}
                                            config={{
                                              onCompleted: () => {
                                                toast('계정이 생성됐어요', 'success');
                                                closeDialog();
                                                queryFormikRef.current?.submitForm();
                                              },
                                              onError: (error) => {
                                                toast(
                                                  parseGraphQLError(error as GraphQLError)?.[0].message ||
                                                    '계정 생성에 실패했어요',
                                                  'error',
                                                );
                                              },
                                            }}
                                            group={group}
                                          />
                                        )}
                                      >
                                        계정 생성하기
                                      </DialogButton>
                                    </Stack.Item>
                                    <Stack.Item>
                                      <DialogButton
                                        size={'large'}
                                        renderDialog={({ isOpen, closeDialog }) => (
                                          <GroupCategoryDialog
                                            isOpen={isOpen}
                                            onDismiss={closeDialog}
                                            group={group}
                                            wide
                                          />
                                        )}
                                      >
                                        그룹 권한 보기
                                      </DialogButton>
                                    </Stack.Item>
                                  </Stack>
                                </Grid.Unit>
                              </Grid>
                            </Space>
                            <Space mb={3}>
                              <Space mb={2}>
                                <Text fontSize={1} fontWeight={'bold'} color={'fg.muted'}>
                                  관리자
                                </Text>
                              </Space>
                              <Card>
                                <Space p={4}>
                                  {group.leaders.length > 0 ? (
                                    <Stack wrap={true} gapX={3} gapY={2}>
                                      <ItemList
                                        items={group.leaders}
                                        renderItem={(leader) => <UserAvatarText fontSize={1} user={leader} />}
                                        renderItemWrapper={(children, { id }) => (
                                          <Stack.Item key={id}>{children}</Stack.Item>
                                        )}
                                      />
                                    </Stack>
                                  ) : (
                                    <Text fontSize={1}>-</Text>
                                  )}
                                </Space>
                              </Card>
                            </Space>
                          </>
                        )}
                      </PreloadedQueryRenderer>
                    </Suspense>
                  ) : null}
                  <Space mb={3}>
                    <QueryFormik.FilterSearchTextField
                      typename={'UserFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setValues({
                          ...values,
                          filters: { ...values.filters, search: e.target.value },
                        });
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Space>
                  <ErrorBoundary key={userReference?.fetchKey}>
                    <View>
                      <Suspense>
                        <QueryFormik.PreloadedQueryRenderer<permission_usersQuery>>
                          {({ users }) => (
                            <Text
                              fontSize={1}
                              color={'fg.muted'}
                              fontWeight={'bold'}
                            >{`총 ${users.totalCount}명`}</Text>
                          )}
                        </QueryFormik.PreloadedQueryRenderer>
                      </Suspense>
                    </View>
                    <Suspense
                      fallback={
                        <View sx={{ padding: 5 }}>
                          <Spinner />
                        </View>
                      }
                    >
                      <QueryFormik.PreloadedQueryRenderer<permission_usersQuery>>
                        {(queryReference) => (
                          <UserPaginator fragmentReference={queryReference}>
                            {({ users }) => (
                              <>
                                <View sx={{ marginTop: 3 }}>
                                  <UserConnectionDataTable
                                    userConnection={users}
                                    renderHead={(columns) => {
                                      const headCommonStyle = {
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'border.default',
                                        textAlign: 'start',
                                        padding: 2,
                                        fontWeight: 'bold',
                                        color: 'fg.muted',
                                      };
                                      return (
                                        <View
                                          as={'thead'}
                                          sx={{
                                            borderBottomWidth: 1,
                                            borderBottomStyle: 'solid',
                                            borderBottomColor: 'border.default',
                                            backgroundColor: 'canvas.subtle',
                                          }}
                                        >
                                          <View as={'tr'}>
                                            {columns.map(({ field, title, width }) => (
                                              <View
                                                key={field}
                                                as={'th'}
                                                sx={{
                                                  minWidth: width,
                                                  ...headCommonStyle,
                                                }}
                                              >
                                                {title}
                                              </View>
                                            ))}
                                            <View as={'th'} sx={{ ...headCommonStyle, width: 90, minWidth: 90 }}>
                                              수정
                                            </View>
                                            <View as={'th'} sx={{ ...headCommonStyle, width: 65, minWidth: 65 }}>
                                              비활성화
                                            </View>
                                          </View>
                                        </View>
                                      );
                                    }}
                                    renderRow={(row, columns, index) => {
                                      const user = queryReference.users.edges.find(
                                        ({ node }) => node.id === row.id,
                                      )?.node;
                                      const cellCommonStyle = {
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'border.default',
                                      };
                                      return (
                                        <>
                                          {columns.map(({ field, renderValue, width, align = 'start' }) => (
                                            <View
                                              key={field}
                                              as={'td'}
                                              sx={{
                                                ...cellCommonStyle,
                                                minWidth: width,
                                                textAlign: align,
                                                padding: 2,
                                              }}
                                            >
                                              {renderValue(row, index)}
                                            </View>
                                          ))}
                                          <View as={'td'} sx={{ ...cellCommonStyle, paddingX: '12px' }}>
                                            {user ? (
                                              <Stack wrap={false} gapX={1}>
                                                <Stack.Item>
                                                  <Tooltip text={'정보 수정'}>
                                                    <DialogIconButton
                                                      renderDialog={({ isOpen, closeDialog }) => {
                                                        return (
                                                          <UserUpdateDialog
                                                            user={user}
                                                            isOpen={isOpen}
                                                            onDismiss={closeDialog}
                                                            config={{
                                                              onCompleted: () => {
                                                                toast('유저 정보가 수정됐어요', 'success');
                                                                closeDialog();
                                                              },
                                                              onError: (error) => {
                                                                toast(
                                                                  parseGraphQLError(error as GraphQLError)?.[0]
                                                                    .message || '정보 수정에 실패했어요',
                                                                  'error',
                                                                );
                                                              },
                                                            }}
                                                          />
                                                        );
                                                      }}
                                                      icon={PencilIcon}
                                                      aria-label={'유저 정보 수정하기'}
                                                    />
                                                  </Tooltip>
                                                </Stack.Item>
                                                <Stack.Item>
                                                  <Tooltip text={'권한 수정'}>
                                                    <DialogIconButton
                                                      renderDialog={({ isOpen, closeDialog }) => (
                                                        <Suspense>
                                                          <UserPermissionsUpdateDialog
                                                            user={user}
                                                            isOpen={isOpen}
                                                            onDismiss={closeDialog}
                                                            config={{
                                                              onCompleted: () => {
                                                                toast('권한이 수정됐어요', 'success');
                                                                closeDialog();
                                                              },
                                                              onError: (error) => {
                                                                toast(
                                                                  parseGraphQLError(error as GraphQLError)?.[0]
                                                                    .message || '권한 수정에 실패했어요',
                                                                  'error',
                                                                );
                                                              },
                                                            }}
                                                            wide
                                                          />
                                                        </Suspense>
                                                      )}
                                                      icon={KeyIcon}
                                                      aria-label={'유저 권한 수정하기'}
                                                    />
                                                  </Tooltip>
                                                </Stack.Item>
                                              </Stack>
                                            ) : null}
                                          </View>
                                          <View
                                            as={'td'}
                                            sx={{
                                              ...cellCommonStyle,
                                              paddingX: '12px',
                                              textAlign: 'center',
                                            }}
                                          >
                                            {user ? (
                                              <MutationConfirmIconButton<permission_userDeleteMutation>
                                                mutation={graphql`
                                                  mutation permission_userDeleteMutation($input: UserDeleteInput!) {
                                                    userDelete(input: $input) {
                                                      id
                                                    }
                                                  }
                                                `}
                                                input={{ id: user.id }}
                                                icon={CircleSlashIcon}
                                                aria-label={'유저 비활성화 하기'}
                                                message={'비활성화한 유저는 되돌릴 수 없어요. 정말 비활성화 할까요?'}
                                                config={{
                                                  onCompleted: () => {
                                                    toast('유저가 비활성화됐어요.', 'success');
                                                    submitForm();
                                                  },
                                                  onError: (error) => {
                                                    toast(
                                                      parseGraphQLError(error as GraphQLError)?.[0].message ||
                                                        '다시 비활성화해주세요',
                                                      'error',
                                                    );
                                                  },
                                                }}
                                              />
                                            ) : null}
                                          </View>
                                        </>
                                      );
                                    }}
                                    emptyState={
                                      <Space py={6}>
                                        <EmptyState title={'그룹에 추가된 팀원이 없어요'} />
                                      </Space>
                                    }
                                  />
                                </View>
                              </>
                            )}
                          </UserPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </>
              );
            }}
          </QueryFormik>
        ) : (
          <>
            <Space py={10}>
              <EmptyState title={'관리할 수 있는 그룹이 없어요'} />
            </Space>
          </>
        )}
      </View>
    </View>
  );
};

Permission.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Permission.authenticated = true;
Permission.routes = [
  {
    id: 'permission',
    pathname: '/permission',
    name: '그룹 · 권한 관리',
    icon: KeyIcon,
    permissions: ['permission_setting_read'],
  },
];

export default Permission;
