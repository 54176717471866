/**
 * @generated SignedSource<<d3c422565a6fd5e2dda1bb8421e19272>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskSequenceItem_task$data = {
  readonly sequence: number;
  readonly " $fragmentType": "TaskSequenceItem_task";
};
export type TaskSequenceItem_task$key = {
  readonly " $data"?: TaskSequenceItem_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskSequenceItem_task">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskSequenceItem_task",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};

(node as any).hash = "feca98dfd7e0c8a5fa03dd3e28300c5f";

export default node;
