import { GraphQLTaggedNode, useMutation, UseMutationConfig } from 'react-relay';

import ConfirmToolbarIconButton, { ConfirmToolbarIconButtonProps } from './ConfirmToolbarIconButton';

type Props<
  TMutation extends {
    readonly response: {};
    readonly variables: { readonly input?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
> = {
  mutation: GraphQLTaggedNode;
  input: TMutation['variables']['input'];
  config?: Omit<UseMutationConfig<TMutation>, 'variables'>;
} & ConfirmToolbarIconButtonProps;

const MutationConfirmToolbarIconButton = <
  TMutation extends {
    readonly response: {};
    readonly variables: { readonly input?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
>({
  mutation,
  input,
  config,
  onClick,
  disabled,
  ...props
}: Props<TMutation>) => {
  const [mutate, isLoading] = useMutation(mutation);

  const handleClick: ConfirmToolbarIconButtonProps['onClick'] = (e) => {
    onClick?.(e);
    mutate({ ...(config ? config : {}), variables: { input: input } });
  };

  return <ConfirmToolbarIconButton onClick={handleClick} disabled={isLoading || disabled} {...props} />;
};

export default MutationConfirmToolbarIconButton;
export type { Props as MutationConfirmToolbarIconButtonProps };
