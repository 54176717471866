import { graphql, useFragment } from 'react-relay';

import { NoticeItem_notice$key } from '../../../relay/__generated__/NoticeItem_notice.graphql';
import Avatar from '../../core/Avatar';
import DateText from '../../core/DateText';
import Markdown from '../../core/Markdown';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';

const NoticeItem_notice = graphql`
  fragment NoticeItem_notice on Notice {
    id
    created
    createdBy {
      id
      picture
      name
      group {
        id
        title
      }
    }
    modified
    modifiedBy {
      id
    }
    title
    description
  }
`;

type Props = {
  notice: NoticeItem_notice$key;
};

const NoticeItem = ({ notice }: Props) => {
  const { created, createdBy, modified, modifiedBy, title, description } = useFragment(NoticeItem_notice, notice);

  return (
    <>
      <Stack gapX={2}>
        <Stack.Item>
          <Avatar src={createdBy.picture || ''} alt={createdBy.name} size={32} />
        </Stack.Item>
        <Stack.Item>
          <View>
            <Stack gapX={1}>
              <Stack.Item>
                <Text fontSize={1} fontWeight={'bold'}>
                  {title}
                </Text>
              </Stack.Item>
              <Stack.Item>
                <DateText ago sx={{ color: 'fg.subtle', fontSize: 0 }}>
                  {modifiedBy ? modified : created}
                </DateText>
                {modifiedBy ? <Text sx={{ color: 'fg.subtle', fontSize: 0 }}>(수정됨)</Text> : null}
              </Stack.Item>
            </Stack>
            <View sx={{ lineHeight: 1 }}>
              <Text fontSize={0} color={'fg.subtle'}>
                {createdBy.name + (createdBy.group ? ` · ${createdBy.group.title}` : '')}
              </Text>
            </View>
          </View>
        </Stack.Item>
      </Stack>
      <View sx={{ marginTop: 2 }}>
        <Markdown>{description || ''}</Markdown>
      </View>
    </>
  );
};

export default NoticeItem;
