import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  ProductEventDescriptionList_productEvent$data,
  ProductEventDescriptionList_productEvent$key,
} from '../../../relay/__generated__/ProductEventDescriptionList_productEvent.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import ProductEventStatusLabel from '../ProductEventStatusLabel';

const ProductEventDescriptionList_productEvent = graphql`
  fragment ProductEventDescriptionList_productEvent on ProductEvent {
    id
    description
    startAt
    endAt
    userYears

    createdBy {
      id
      ...UserAvatarText_user
    }
    created
    ...ProductEventStatusLabel_productEvent
  }
`;

type Props = {
  productEvent: ProductEventDescriptionList_productEvent$key;
  type?: 'default' | 'activity';
} & Omit<DescriptionListProps<ProductEventDescriptionList_productEvent$data>, 'item' | 'itemDescriptions' | 'picks'>;
const ProductEventDescriptionList = ({ productEvent: productEventReference, type = 'default', ...props }: Props) => {
  const productEvent = useFragment(ProductEventDescriptionList_productEvent, productEventReference);

  return (
    <DescriptionList
      item={productEvent}
      itemDescriptions={{
        description: {
          title: '설명',
          renderValue: ({ description }) =>
            description ? (
              <Text fontSize={1} whiteSpace={'pre-wrap'}>
                {description}
              </Text>
            ) : undefined,
        },
        startAt: {
          title: '시작일',
          renderValue: ({ startAt }) => <DateText fontSize={1}>{startAt}</DateText>,
        },
        endAt: {
          title: '종료일',
          renderValue: ({ endAt }) => <DateText fontSize={1}>{endAt}</DateText>,
        },
        userTypes: {
          title: '유저 타입',
          renderValue: ({ userYears }) =>
            userYears.length !== 0
              ? userYears.map((userYear, index) => (
                  <React.Fragment key={userYear}>
                    {index !== 0 ? ', ' : ''}
                    <EnumPair key={userYear} typename={'ProductEventUserYearEnum'}>
                      {userYear}
                    </EnumPair>
                  </React.Fragment>
                ))
              : undefined,
        },
        createdBy: {
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
        },
        created: {
          title: '제작일',
          renderValue: ({ created }) => <DateText fontSize={1}>{created}</DateText>,
        },
        status: {
          title: '상태',
          renderValue: (productEvent) => <ProductEventStatusLabel productEvent={productEvent} />,
        },
      }}
      picks={
        type === 'default'
          ? ['description', 'startAt', 'endAt', 'userTypes']
          : type === 'activity'
          ? ['createdBy', 'created', 'status']
          : []
      }
      {...props}
    />
  );
};

export default ProductEventDescriptionList;
