/**
 * @generated SignedSource<<ad390dd70106bb589c3e1967e52ce227>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WorkbookHistoryTypeEnum = "created" | "deactivated" | "published" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkbookHistoryTimelineItem_workbookHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly id: string;
  readonly type: WorkbookHistoryTypeEnum;
  readonly " $fragmentType": "WorkbookHistoryTimelineItem_workbookHistory";
};
export type WorkbookHistoryTimelineItem_workbookHistory$key = {
  readonly " $data"?: WorkbookHistoryTimelineItem_workbookHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkbookHistoryTimelineItem_workbookHistory">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkbookHistoryTimelineItem_workbookHistory",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "WorkbookHistory",
  "abstractKey": null
};
})();

(node as any).hash = "e74d09a7a42c0b9692ea9c30b97c6a53";

export default node;
