/**
 * @generated SignedSource<<1bd1f744528fd2d6f80e6e147601081e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProblemGeneratorHistoryTypeEnum = "activate" | "create" | "deactivate" | "publish" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly type: ProblemGeneratorHistoryTypeEnum;
  readonly " $fragmentType": "ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory";
};
export type ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory$key = {
  readonly " $data"?: ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "ProblemGeneratorHistory",
  "abstractKey": null
};

(node as any).hash = "9b61133547ade7b6a636c515c1351ea6";

export default node;
