import { CheckIcon, DownloadIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import { BillXlsxImportDialog_billXlsxImportMutation } from '../../../relay/__generated__/BillXlsxImportDialog_billXlsxImportMutation.graphql';
import { StoreStuffTypeEnum } from '../../../relay/__generated__/StoreStuffPaginatorSearchOverlayTokenField_storeStuffsQuery.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import ErrorBoundary from '../../core/ErrorBoundary';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import LinkButton from '../../core/LinkButton';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import UploadField from '../../core/UploadField';
import View from '../../core/View';
import ProductSelectField from '../../product/ProductSelectField';

type Props = {} & DialogProps &
  Pick<MutationFormikProps<BillXlsxImportDialog_billXlsxImportMutation>, 'config' | 'onSubmit' | 'connections'>;

const BillXlsxImportDialog = ({ config, onSubmit, connections, ...props }: Props) => {
  const stuffTypes: StoreStuffTypeEnum[] = ['premium_refundable', 'premium_non_refundable'];
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>개인 결제 링크 벌크 생성</Text>
        <View>
          <Text sx={{ fontSize: 1, fontWeight: 'normal', color: 'fg.subtle' }}>
            xlsx 파일을 양식에 맞게 업로드 해주세요
          </Text>
        </View>
      </Dialog.Header>
      <MutationFormik<BillXlsxImportDialog_billXlsxImportMutation>
        mutation={graphql`
          mutation BillXlsxImportDialog_billXlsxImportMutation($input: BillXlsxImportInput!) {
            billXlsxImport(input: $input) {
              id
            }
          }
        `}
        initialValues={{ file: { key: '', size: 0 }, product: '' }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
        initialStatus={{ stuffType: stuffTypes[0] }}
      >
        {({ dirty, handleSubmit, status, setStatus, values, setValues }) => (
          <>
            <Dialog.Body>
              <FormLayout>
                <Text fontSize={3} fontWeight={'bold'}>
                  링크 정보
                </Text>
                <LinkButton
                  href={'https://cdn.teamturing.com/cms/bill/billXlsxImportFormat.xlsx'}
                  size={'small'}
                  variant={'invisible'}
                  leadingIcon={DownloadIcon}
                >
                  양식 다운로드
                </LinkButton>
                <NonFieldError sx={{ marginBottom: 3 }} />
                <UploadField
                  label={'파일'}
                  name={'file'}
                  required
                  accept={'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
                />
              </FormLayout>
              <View mt={6}>
                <FormLayout>
                  <Text fontSize={3} fontWeight={'bold'}>
                    상품 정보
                  </Text>
                  <EnumPairRadioGroupField
                    typename={'StoreStuffTypeEnum'}
                    label={'타입'}
                    name={'status.stuffType'}
                    value={status.stuffType}
                    onChange={(selected) => {
                      setStatus({ ...status, stuffType: selected });
                      setValues({ ...values, product: '' });
                    }}
                    renderContainer={(children) => <Grid>{children}</Grid>}
                    renderOptionWrapper={(children, { id }) => (
                      <Grid.Unit key={id} size={1 / 2}>
                        {children}
                      </Grid.Unit>
                    )}
                  />
                  <ErrorBoundary fallback={<></>}>
                    <Suspense fallback={<Spinner size={'small'} />}>
                      <ProductSelectField
                        label={'판매 상품'}
                        name={'product'}
                        placeholder={'판매 상품 선택'}
                        variables={{
                          filters: {
                            storeStuffType_In: [status.stuffType],
                            showTypes_Contains_Any: ['private'],
                            status_In: ['active'],
                          },
                        }}
                        required
                        size={'large'}
                        onChange={(value) => {
                          setValues({ ...values, product: value });
                        }}
                      />
                    </Suspense>
                  </ErrorBoundary>
                </FormLayout>
              </View>
            </Dialog.Body>
            <Dialog.Footer>
              <Button disabled={!dirty} onClick={() => handleSubmit()} variant={'primary'} leadingIcon={CheckIcon}>
                업로드 하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default BillXlsxImportDialog;
