import { forwardRef, Ref, useRef } from 'react';

import usePostcodePopup from '../../../hooks/usePostcodePopup';
import { AddressData } from '../../../types/postcode';
import Button from '../Button';
import Grid from '../Grid';
import TextInput, { TextInputProps } from '../TextInput';
import View from '../View';

type Props = {
  onSelectZipCode?: (data: AddressData) => void;
} & TextInputProps;

const ZipCodeInput = ({ onSelectZipCode, disabled, block, ...props }: Props, ref: Ref<HTMLInputElement>) => {
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const { open } = usePostcodePopup({
    onComplete: (data) => {
      onSelectZipCode?.(data);
      const textInput = inputContainerRef.current?.querySelector('input');
      if (textInput) textInput.value = data.zonecode;
    },
  });

  return (
    <Grid sx={{ alignItems: 'center' }} gapX={2}>
      <Grid.Unit size={block ? 'max' : 'min'}>
        <View ref={inputContainerRef}>
          <TextInput ref={ref} autoComplete={'off'} block={block} disabled={disabled} {...props} />
        </View>
      </Grid.Unit>
      <Grid.Unit size={'min'}>
        <View>
          <Button variant={'outline'} onClick={open} disabled={disabled}>
            우편번호 검색
          </Button>
        </View>
      </Grid.Unit>
    </Grid>
  );
};

export default forwardRef(ZipCodeInput);
export type { Props as ZipCodeInputProps };
