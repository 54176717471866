import { AlertFillIcon, CheckCircleFillIcon } from '@primer/octicons-react';
import { FormValidationStatus } from '@primer/react/lib/utils/types/FormValidationStatus';
import styled, { keyframes } from 'styled-components';

import Text from '../Text';
import View, { ViewProps } from '../View';

type Props = {
  variant: FormValidationStatus;
} & ViewProps;

const ValidationTextView = ({ variant, children, ...props }: React.PropsWithChildren<Props>) => {
  const Icon = variant === 'error' ? AlertFillIcon : variant === 'warning' ? AlertFillIcon : CheckCircleFillIcon;
  return (
    <StyledView {...props}>
      <Text
        fontSize={12}
        fontWeight={'bold'}
        color={variant === 'error' ? 'danger.fg' : variant === 'warning' ? 'attention.fg' : 'success.fg'}
      >
        <Icon size={12} verticalAlign={'middle'} />
        <Text sx={{ marginLeft: 1 }}>{children}</Text>
      </Text>
    </StyledView>
  );
};

const errorViewKeyframe = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledView = styled(View)`
  animation: 170ms ${errorViewKeyframe} cubic-bezier(0.44, 0.74, 0.36, 1);
`;

export default ValidationTextView;
