import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  UnitDSelectField_unitDsQuery,
  UnitDSelectField_unitDsQuery$variables,
} from '../../../relay/__generated__/UnitDSelectField_unitDsQuery.graphql';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const unitDsForUnitDSelectField = graphql`
  query UnitDSelectField_unitDsQuery($filters: UnitDFilter, $order: UnitDOrder) {
    unitDs(filters: $filters, order: $order) {
      edges {
        node {
          id
          description
        }
      }
    }
  }
`;

type Props = {
  variables?: UnitDSelectField_unitDsQuery$variables;
} & Omit<SelectFieldProps, 'options'>;

const UnitDSelectField = ({ variables, ...props }: Props) => {
  const [{ unitDs }] = useLazyLoadQuery<UnitDSelectField_unitDsQuery>(unitDsForUnitDSelectField, {
    ...variables,
  });

  return (
    <SelectField
      options={unitDs.edges.map(({ node: { id, description } }) => ({ id, text: description || '' }))}
      {...props}
    />
  );
};

export default UnitDSelectField;
export type { Props as UnitDSelectFieldProps };
