import { CheckIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { WorkbookGradesUpdateDialog_workbook$key } from '../../../relay/__generated__/WorkbookGradesUpdateDialog_workbook.graphql';
import {
  WorkbookGradesUpdateDialog_workbookGradeMutation,
  WorkbookGrade,
} from '../../../relay/__generated__/WorkbookGradesUpdateDialog_workbookGradeMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormControl from '../../core/FormControl';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberInput from '../../core/NumberInput';
import Text from '../../core/Text';
import View from '../../core/View';

const WorkbookGradesUpdateDialog_workbook = graphql`
  fragment WorkbookGradesUpdateDialog_workbook on Workbook {
    id
    grades {
      grade
      percentile
      rawScore
      standardScore
    }
  }
`;

type Props = { workbook: WorkbookGradesUpdateDialog_workbook$key } & DialogProps &
  Pick<MutationFormikProps<WorkbookGradesUpdateDialog_workbookGradeMutation>, 'config' | 'onSubmit' | 'connections'>;

const WorkbookGradesUpdateDialog = ({ workbook, config, onSubmit, connections, ...props }: Props) => {
  const { grades, id } = useFragment(WorkbookGradesUpdateDialog_workbook, workbook);
  const { toast } = useToast();

  const allGradeNames = ['최고점', '1등급', '2등급', '3등급', '4등급', '5등급', '6등급', '7등급', '8등급'];

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>등급컷 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<WorkbookGradesUpdateDialog_workbookGradeMutation>
        mutation={graphql`
          mutation WorkbookGradesUpdateDialog_workbookGradeMutation($input: WorkbookGradeInput!) {
            workbookGrade(input: $input) {
              id
              ...WorkbookStatusLabel_workbook
              ...WorkbookUpdateDialog_workbook
              modified
              modifiedBy {
                ...UserAvatarText_user
              }
            }
          }
        `}
        initialValues={{
          id,
          grades: grades.map((grade) => ({
            rawScore: grade.rawScore ?? 0,
            standardScore: grade.standardScore ?? 0,
            percentile: grade.percentile ?? 0,
          })),
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, setFieldValue, errors }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                {allGradeNames.map((gradeName, i) => (
                  <Grid key={gradeName} gapX={3}>
                    <Grid.Unit size={'min'}>
                      <View minWidth={'60px'}>
                        <Text fontSize={1} fontWeight={'bold'}>{`${gradeName} *`}</Text>
                      </View>
                    </Grid.Unit>
                    {[
                      {
                        key: 'rawScore',
                        text: '원점수',
                        min: 0,
                        max: 100,
                      },
                      {
                        key: 'standardScore',
                        text: '표준 점수',
                        min: 0,
                        max: 200,
                      },
                      {
                        key: 'percentile',
                        text: '백분위',
                        min: 0,
                        max: 100,
                      },
                    ].map(({ key, text, min, max }) => (
                      <Grid.Unit key={key} size={'max'}>
                        <FormControl>
                          <FormControl.Label>{text}</FormControl.Label>
                          <NumberInput
                            onPaste={(e) => {
                              // Paste Shortcut
                              e.preventDefault();
                              e.stopPropagation();
                              const data = e.clipboardData.getData('Text');
                              if (!data) return handleFailure();
                              let splitted = data.split(/[\s\t\n\r]+/);
                              console.log(splitted);
                              if (splitted.length < 9 * 4) return handleFailure();
                              const isIncludeHeader = splitted[0] === '등급';
                              let colCount = -1;
                              if (isIncludeHeader) {
                                colCount = splitted.findIndex((s) => s === '최고점');
                                if (colCount === -1) return handleFailure();
                                // not handle header order yet
                                // const headers = splitted.slice(0, colCount);
                                splitted.splice(0, colCount);
                              } else {
                                if (splitted.length % 9 !== 0 || ![4, 5].includes(splitted.length / 9))
                                  return handleFailure();
                                colCount = splitted.length / 9;
                              }
                              if (colCount === 5)
                                splitted = splitted
                                  .map((v, i) => (i % 5 === 1 ? 'removed' : v))
                                  .filter((v) => v !== 'removed');
                              if (colCount === -1) return handleFailure();
                              setFieldWithArray(splitted);

                              function handleFailure() {
                                toast('등급컷 붙여넣기에 실패했어요', 'warning');
                              }

                              function setFieldWithArray(data: (number | string)[]) {
                                if (data.length !== 9 * 4) return handleFailure();
                                const value: WorkbookGrade[] = allGradeNames.map((_, i) => ({
                                  rawScore: Number(data[i * 4 + 1]),
                                  standardScore: Number(data[i * 4 + 2]),
                                  percentile: Number(data[i * 4 + 3]),
                                }));
                                setFieldValue('grades', value);
                              }
                            }}
                            width={'100%'}
                            min={min}
                            max={max}
                            placeholder={`${text} 입력`}
                            skipDebounce
                            value={values.grades[i][key as keyof WorkbookGrade]}
                            onChange={(e) =>
                              setFieldValue(
                                'grades',
                                values.grades.map((grade, j) =>
                                  i !== j ? grade : { ...grade, [key]: e.target.valueAsNumber },
                                ),
                              )
                            }
                          />
                          {errors.grades && (
                            <FormControl.Validation variant={'error'}>{'Error'}</FormControl.Validation>
                          )}
                        </FormControl>
                      </Grid.Unit>
                    ))}
                  </Grid>
                ))}
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                variant={'primary'}
                onClick={() => {
                  handleSubmit();
                }}
                leadingIcon={CheckIcon}
              >
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default WorkbookGradesUpdateDialog;
