import { graphql, useFragment } from 'react-relay';

import {
  ScrapSourceConnectionDataTable_scrapSourceConnection$data,
  ScrapSourceConnectionDataTable_scrapSourceConnection$key,
} from '../../../relay/__generated__/ScrapSourceConnectionDataTable_scrapSourceConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import ScrapSourceStatusLabel from '../ScrapSourceStatusLabel';

const ScrapSourceConnectionDataTable_scrapSourceConnection = graphql`
  fragment ScrapSourceConnectionDataTable_scrapSourceConnection on ScrapSourceConnection {
    edges {
      node {
        id
        ...ScrapSourceStatusLabel_scrapSource
        sequence
        type
        title
        created
        submittedBy {
          id
          ...UserAvatarText_user
        }
        createdBy {
          id
          ...UserAvatarText_user
        }
      }
    }
  }
`;

type Props = { scrapSourceConnection: ScrapSourceConnectionDataTable_scrapSourceConnection$key } & Omit<
  DataTableProps<ScrapSourceConnectionDataTable_scrapSourceConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const ScrapSourceConnectionDataTable = ({ scrapSourceConnection, ...props }: Props) => {
  const { edges } = useFragment(ScrapSourceConnectionDataTable_scrapSourceConnection, scrapSourceConnection);
  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (scrapSource) => <ScrapSourceStatusLabel scrapSource={scrapSource} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'sequence',
          title: 'ID',
          renderValue: ({ sequence }) => <Text>{sequence}</Text>,
          width: 80,
        },
        {
          field: 'type',
          title: '기출/사설',
          renderValue: ({ type }) => <EnumPair typename={'ScrapSourceTypeEnum'}>{type}</EnumPair>,
          width: 80,
        },
        {
          field: 'title',
          title: '상세',
          renderValue: ({ title }) => <Text>{title}</Text>,
          width: 604,
        },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 92,
        },
        {
          field: 'submittedBy',
          title: '제출자',
          renderValue: ({ submittedBy }) => (submittedBy ? <UserAvatarText user={submittedBy} /> : '-'),
          width: 92,
        },
        {
          field: 'created',
          title: '제작일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default ScrapSourceConnectionDataTable;
