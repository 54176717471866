/**
 * @generated SignedSource<<3df97e2a30e1c17ba9076d9ed3980da6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationStudentsXlsxCreateDialog_matOrganization$data = {
  readonly id: string;
  readonly studentsBulkCreateTemplateUrl: string;
  readonly " $fragmentType": "MATOrganizationStudentsXlsxCreateDialog_matOrganization";
};
export type MATOrganizationStudentsXlsxCreateDialog_matOrganization$key = {
  readonly " $data"?: MATOrganizationStudentsXlsxCreateDialog_matOrganization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATOrganizationStudentsXlsxCreateDialog_matOrganization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATOrganizationStudentsXlsxCreateDialog_matOrganization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studentsBulkCreateTemplateUrl",
      "storageKey": null
    }
  ],
  "type": "MATOrganization",
  "abstractKey": null
};

(node as any).hash = "c7a405dfa1f431431f651b4df6a53390";

export default node;
