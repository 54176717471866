import { graphql, useFragment } from 'react-relay';

import {
  AppPushConnectionDataTable_appPushConnection$data,
  AppPushConnectionDataTable_appPushConnection$key,
} from '../../../relay/__generated__/AppPushConnectionDataTable_appPushConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import Text from '../../core/Text';
import AppPushStatusLabel from '../AppPushStatusLabel';

const AppPushConnectionDataTable_appPushConnection = graphql`
  fragment AppPushConnectionDataTable_appPushConnection on AppPushConnection {
    edges {
      node {
        id
        ...AppPushStatusLabel_appPush
        sequence
        title
        destination
        targetCount
        startAt
      }
    }
  }
`;

type Props = {
  appPushConnection: AppPushConnectionDataTable_appPushConnection$key;
} & Omit<
  DataTableProps<AppPushConnectionDataTable_appPushConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const AppPushConnectionDataTable = ({ appPushConnection, ...props }: Props) => {
  const { edges } = useFragment(AppPushConnectionDataTable_appPushConnection, appPushConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (appPush) => <AppPushStatusLabel appPush={appPush} />,
          width: 80,
          align: 'center',
        },
        { field: 'sequence', title: 'ID', renderValue: ({ sequence }) => <Text>{sequence}</Text>, width: 80 },
        {
          field: 'title',
          title: '제목',
          renderValue: ({ title }) => <Text>{title}</Text>,
          width: 335,
        },
        {
          field: 'destination',
          title: '딥링크',
          renderValue: ({ destination }) => <Text>{destination}</Text>,
          width: 335,
        },
        {
          field: 'targetCount',
          title: '타겟 사용자',
          renderValue: ({ targetCount }) => <Text>{`${targetCount || '-'}명`}</Text>,
          width: 85,
        },
        { field: 'startAt', title: '시작일', renderValue: ({ startAt }) => <DateText>{startAt}</DateText>, width: 180 },
      ]}
      {...props}
    />
  );
};

export default AppPushConnectionDataTable;
