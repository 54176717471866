/**
 * @generated SignedSource<<2e4799fa214ce0cfd9b556a392ffc06b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentUpdateInput = {
  description: string;
  id: string;
};
export type CommentMutationActionItem_commentUpdateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CommentUpdateInput;
};
export type CommentMutationActionItem_commentUpdateMutation$data = {
  readonly commentUpdate: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CommentItem_comment">;
  };
};
export type CommentMutationActionItem_commentUpdateMutation = {
  response: CommentMutationActionItem_commentUpdateMutation$data;
  variables: CommentMutationActionItem_commentUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommentMutationActionItem_commentUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "commentUpdate",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CommentItem_comment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CommentMutationActionItem_commentUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Comment",
        "kind": "LinkedField",
        "name": "commentUpdate",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "appendNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "commentUpdate",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "CommentEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "ab8ca9a724c89565fd02338d66e8db1b",
    "id": null,
    "metadata": {},
    "name": "CommentMutationActionItem_commentUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CommentMutationActionItem_commentUpdateMutation(\n  $input: CommentUpdateInput!\n) {\n  commentUpdate(input: $input) {\n    id\n    ...CommentItem_comment\n  }\n}\n\nfragment CommentItem_comment on Comment {\n  id\n  created\n  createdBy {\n    id\n    ...UserAvatarText_user\n  }\n  modified\n  modifiedBy {\n    id\n  }\n  description\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "22d9c78e4e6f180df0c6f152f80524bf";

export default node;
