/**
 * @generated SignedSource<<e11355b74edcf1599c6d41205814d1f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TeacherSelectField_teachersQuery$variables = {
  first?: number | null;
};
export type TeacherSelectField_teachersQuery$data = {
  readonly teachers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type TeacherSelectField_teachersQuery = {
  response: TeacherSelectField_teachersQuery$data;
  variables: TeacherSelectField_teachersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "first",
        "variableName": "first"
      }
    ],
    "concreteType": "TeacherConnection",
    "kind": "LinkedField",
    "name": "teachers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TeacherEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Teacher",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TeacherSelectField_teachersQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TeacherSelectField_teachersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c4029128d1340c7b1a974da38f4077a6",
    "id": null,
    "metadata": {},
    "name": "TeacherSelectField_teachersQuery",
    "operationKind": "query",
    "text": "query TeacherSelectField_teachersQuery(\n  $first: Int\n) {\n  teachers(first: $first) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "660f9d6cfb497e062a8d40023fd4a8d9";

export default node;
