/**
 * @generated SignedSource<<bf6509d5533c1ed1e6dbaf3e772705b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductShowTypeEnum = "non_registered" | "private" | "repurchase" | "store" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductUpdateDialog_product$data = {
  readonly additionalDiscountAmount: number;
  readonly discountPrice: number;
  readonly id: string;
  readonly price: number;
  readonly showTypes: ReadonlyArray<ProductShowTypeEnum>;
  readonly storeStuff: {
    readonly id: string;
  } | null;
  readonly title: string;
  readonly " $fragmentType": "ProductUpdateDialog_product";
};
export type ProductUpdateDialog_product$key = {
  readonly " $data"?: ProductUpdateDialog_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductUpdateDialog_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductUpdateDialog_product",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreStuff",
      "kind": "LinkedField",
      "name": "storeStuff",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalDiscountAmount",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "27289bad53fd2786b2b782d5bc8d59b8";

export default node;
