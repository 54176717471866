import { graphql } from 'react-relay';

import { TypingAutomationScriptPaginator_query$key } from '../../../relay/__generated__/TypingAutomationScriptPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const TypingAutomationScriptPaginator_query = graphql`
  fragment TypingAutomationScriptPaginator_query on Query
  @argumentDefinitions(
    filters: { type: TypingAutomationScriptFilter }
    order: { type: TypingAutomationScriptOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "TypingAutomationScriptPaginatorRefreshQuery") {
    typingAutomationScripts(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "TypingAutomationScriptPaginator_query_typingAutomationScripts") {
      __id
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<TypingAutomationScriptPaginator_query$key>, 'fragment'>;

const TypingAutomationScriptPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={TypingAutomationScriptPaginator_query} {...props} />
);

export default TypingAutomationScriptPaginator;
