import { CheckIcon, ImageIcon, StopIcon, TagIcon, TrashIcon, XIcon } from '@primer/octicons-react';
import {
  decideAnswerTypeObjectiveFromKatex,
  countAnswerChoice,
  extractAnswerFromKatex,
  formatKatexToHtmlString,
} from '@teamturing/katex-utils';
import { omit } from 'lodash-es';
import { editor } from 'monaco-editor';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';

import {
  SchoolTypeEnum,
  TaskProblemUpdateDialog_task$key,
} from '../../../relay/__generated__/TaskProblemUpdateDialog_task.graphql';
import {
  TaskProblemCreationTypeEnum,
  TaskProblemUpdateDialog_taskProblemUpdateMutation,
} from '../../../relay/__generated__/TaskProblemUpdateDialog_taskProblemUpdateMutation.graphql';
import { TaskProblemUpdateDialog_unitDsQuery } from '../../../relay/__generated__/TaskProblemUpdateDialog_unitDsQuery.graphql';
import { isNullable } from '../../../utils/is';
import { numberWithCommas } from '../../../utils/number';
import Button from '../../core/Button';
import Card from '../../core/Card';
import DescriptionList from '../../core/DescriptionList';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import EnumPair from '../../core/EnumPair';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import { KatexEditorShortcutButtonsStack } from '../../core/KatexEditor';
import KatexEditorField, { KatexEditorFieldProps } from '../../core/KatexEditorField';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import SquareView from '../../core/SquareView';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import TabHandler from '../../core/TabHandler';
import Text from '../../core/Text';
import TextareaField from '../../core/TextareaField';
import View from '../../core/View';
import UnitDItem from '../../unitD/UnitDItem';
import UnitDPaginator from '../../unitD/UnitDPaginator';
import UnitDSelectDialogTokenField from '../../unitD/UnitDSelectDialogTokenField';

const TaskProblemUpdateDialog_task = graphql`
  fragment TaskProblemUpdateDialog_task on Task {
    id
    title
    sequence
    problem {
      id
      problem
      solution
      problemType
      creationType
      answer
      answerChoiceCount
      answerType
    }

    scrap {
      id
      problems {
        id
        objectUrl
      }
      solutions {
        id
        objectUrl
      }
    }

    imageRequests {
      edges {
        node {
          id
          image {
            key
            objectUrl
            size
          }
          description
        }
      }
    }
    unitDs(order: { order: DESC }) {
      id
    }
    labelingCount
    unit {
      id
      schoolType
    }
  }
`;

type Props = {
  task: TaskProblemUpdateDialog_task$key;
} & DialogProps &
  Pick<MutationFormikProps<TaskProblemUpdateDialog_taskProblemUpdateMutation>, 'config' | 'connections' | 'onSubmit'>;

const unitDsForTaskProblemUpdateDialog = graphql`
  query TaskProblemUpdateDialog_unitDsQuery($filters: UnitDFilter, $order: UnitDOrder, $first: Int, $after: String) {
    ...UnitDPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

const TaskProblemUpdateDialog = ({ isOpen, task, config, connections, onSubmit, ...props }: Props) => {
  const { id, title, sequence, problem, imageRequests, labelingCount, unitDs, unit, scrap } = useFragment(
    TaskProblemUpdateDialog_task,
    task,
  );

  const sideTabItems = [
    { id: 'tag', icon: TagIcon },
    { id: 'image', icon: ImageIcon },
  ];
  const editorInputTabItems = [
    { id: 'all', label: '문제 + 해설' },
    { id: 'problem', label: '문제' },
    { id: 'solution', label: '해설' },
  ];

  const problemEditorRef = useRef<editor.IStandaloneCodeEditor>(null);
  const solutionEditorRef = useRef<editor.IStandaloneCodeEditor>(null);
  const [activeEditor, setActiveEditor] = useState<editor.IStandaloneCodeEditor | null>(null);

  const [unitDsQueryReference, unitDsLoadQuery, unitDsDisposeQuery] =
    useQueryLoader<TaskProblemUpdateDialog_unitDsQuery>(unitDsForTaskProblemUpdateDialog);

  const getAnswerChoiceCountByAnswerType = (value: string) =>
    decideAnswerTypeObjectiveFromKatex(value) ? countAnswerChoice(value) || 5 : null;
  const getAnswerType = (value: string) => (decideAnswerTypeObjectiveFromKatex(value) ? 'objective' : 'subjective');

  useEffect(() => {
    if (unitDs.length > 0)
      unitDsLoadQuery({ filters: { id_In: unitDs.map(({ id }) => id) }, order: { order: 'DESC' } });
  }, []);

  return (
    <Dialog isOpen={isOpen} {...props}>
      <TabHandler initialSelectIndex={0}>
        {({ selectedIndex: selectedSideTabIndex, handleSelect: handleSelectSideTab }) => (
          <MutationFormik<TaskProblemUpdateDialog_taskProblemUpdateMutation, { unitSchoolType?: SchoolTypeEnum }>
            mutation={graphql`
              mutation TaskProblemUpdateDialog_taskProblemUpdateMutation($input: TaskProblemUpdateInput!) {
                taskProblemUpdate(input: $input) {
                  id
                  problem
                  solution
                  answerType
                  answer
                  answerChoiceCount
                  creationType

                  task {
                    id
                    ...TaskProblemUpdateDialog_task
                  }
                }
              }
            `}
            initialValues={{
              answer: extractAnswerFromKatex(problem?.solution || '') || '',
              answerChoiceCount: getAnswerChoiceCountByAnswerType(problem?.problem || ''),
              answerType: getAnswerType(problem?.problem || ''),
              creationType: problem?.creationType || ('' as TaskProblemCreationTypeEnum),
              imageRequests: imageRequests.edges.map(({ node }) => ({
                file: { key: node.image.key, size: node.image.size, objectUrl: node.image.objectUrl },
                description: node.description || '',
              })),
              labelingCount: labelingCount || 'one',
              problem: {
                text: problem?.problem || '',
                textHtml: formatKatexToHtmlString(problem?.problem || ''),
              },
              solution: {
                text: problem?.solution || '',
                textHtml: formatKatexToHtmlString(problem?.solution || ''),
              },
              task: id,
              taskProblem: problem?.id || '',
              unitDs: unitDs.map(({ id }) => id),

              unitSchoolType: unit?.schoolType || undefined,
            }}
            valuesTransformer={(values) => omit(values, 'unitSchoolType')}
            config={{
              ...config,
              onError: (...arg) => {
                config?.onError?.(...arg);
                handleSelectSideTab(0);
              },
            }}
            onSubmit={onSubmit}
            connections={connections}
          >
            {({ dirty, handleSubmit, setFieldValue, values }, { scrollContainerRef, nonFieldErrorRef }) => {
              const handleUploadImage: KatexEditorFieldProps['onUploadImage'] = (blob, metadata) => {
                setFieldValue('imageRequests', [
                  { file: { key: metadata.key, size: blob.size, objectUrl: metadata.object_url }, description: '' },
                  ...values.imageRequests,
                ]);
                handleSelectSideTab(1);
              };

              const handleChangeProblemKatexEditor: KatexEditorFieldProps['onChange'] = (value = '') => {
                setFieldValue('problem', { text: value, textHtml: formatKatexToHtmlString(value) });
                setFieldValue('answerType', getAnswerType(value || ''));
                setFieldValue('answerChoiceCount', getAnswerChoiceCountByAnswerType(value || ''));
              };
              const handleChangeSolutionKatexEditor: KatexEditorFieldProps['onChange'] = (value = '') => {
                setFieldValue('solution', { text: value, textHtml: formatKatexToHtmlString(value) });
                setFieldValue('answer', extractAnswerFromKatex(value || ''));
              };

              const handleClickTrashButton = (index: number) => {
                const imageSrc = values.imageRequests[index].file.objectUrl;
                const problem = (values.problem?.text || '').replace(/<img.*?src="(.*?)".*?\/>[\n\r]?/g, (s0, s1) => {
                  if (s1 === imageSrc) return '';
                  return s0;
                });
                const solution = (values.solution?.text || '').replace(/<img.*?src="(.*?)".*?\/>[\n\r]?/g, (s0, s1) => {
                  if (s1 === imageSrc) return '';
                  return s0;
                });
                problemEditorRef.current?.setValue(problem);
                solutionEditorRef.current?.setValue(solution);

                setFieldValue(
                  'imageRequests',
                  values.imageRequests.filter((_, i) => i !== index),
                );
              };

              return (
                <>
                  <Dialog.Header>
                    <Grid sx={{ paddingRight: 4, alignItems: 'center' }}>
                      <Grid.Unit size={'max'}>
                        <Stack gapX={1}>
                          <Stack.Item>
                            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>창작하기</Text>
                          </Stack.Item>
                          {problem ? (
                            <Stack.Item>
                              <Suspense>
                                <Label variant={'accent'}>
                                  <EnumPair typename={'TaskProblemTypeEnum'}>{problem.problemType}</EnumPair>
                                  {` · ${title}`}
                                </Label>
                              </Suspense>
                            </Stack.Item>
                          ) : null}
                          <Stack.Item>
                            <Label variant={'primary'}>{sequence}</Label>
                          </Stack.Item>
                        </Stack>
                      </Grid.Unit>
                      <Grid.Unit size={'min'}>
                        <Stack gapX={1}>
                          <ItemList
                            items={sideTabItems}
                            renderItem={({ icon }, index) => (
                              <IconButton
                                onClick={() => handleSelectSideTab(index)}
                                variant={index === selectedSideTabIndex ? 'default' : 'plain'}
                                icon={icon}
                                aria-label={`open ${id} tab`}
                              />
                            )}
                            renderItemWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
                          />
                          <Stack.Item>
                            <Button
                              variant={'primary'}
                              onClick={() => handleSubmit()}
                              leadingIcon={CheckIcon}
                              disabled={!dirty}
                            >
                              저장하기
                            </Button>
                          </Stack.Item>
                        </Stack>
                      </Grid.Unit>
                    </Grid>
                  </Dialog.Header>
                  <Grid wrap={false} sx={{ height: '100%', overflowY: 'hidden' }}>
                    <Grid.Unit size={3 / 4}>
                      <TabHandler initialSelectIndex={0}>
                        {({ selectedIndex: selectedEditorInputTab, handleSelect: handleSelectEditorInputTab }) => (
                          <View
                            ref={scrollContainerRef}
                            sx={{
                              'padding': 3,
                              'height': '100%',
                              'overflowY': 'auto',
                              '&>*:not(:last-child)': { marginBottom: 5 },
                            }}
                          >
                            <NonFieldError ref={nonFieldErrorRef} />
                            <Grid gapY={2}>
                              <Grid.Unit size={'max'}>
                                <Stack gapX={1}>
                                  <ItemList
                                    items={editorInputTabItems}
                                    renderItem={({ label }, index) => (
                                      <Button
                                        onClick={() => handleSelectEditorInputTab(index)}
                                        variant={selectedEditorInputTab === index ? 'outline' : 'invisible'}
                                      >
                                        {label}
                                      </Button>
                                    )}
                                    renderItemWrapper={(children, { id }) => (
                                      <Stack.Item key={id}>{children}</Stack.Item>
                                    )}
                                  />
                                </Stack>
                              </Grid.Unit>
                              <Grid.Unit size={'min'}>
                                <KatexEditorShortcutButtonsStack editor={activeEditor!} gapX={1} />
                              </Grid.Unit>
                            </Grid>
                            <View sx={{ '&>*:not(:first-child)': { marginTop: 3 } }}>
                              {editorInputTabItems[selectedEditorInputTab].id === 'all' ||
                              editorInputTabItems[selectedEditorInputTab].id === 'problem' ? (
                                <Grid gapX={2}>
                                  <Grid.Unit size={2 / 3}>
                                    <KatexEditorField
                                      ref={problemEditorRef}
                                      name={'problem'}
                                      label={'문제'}
                                      type={'scroll'}
                                      height={
                                        editorInputTabItems[selectedEditorInputTab].id === 'all' ? '33vh' : '70vh'
                                      }
                                      required
                                      onFocus={setActiveEditor}
                                      onUploadImage={handleUploadImage}
                                      onChange={handleChangeProblemKatexEditor}
                                      emptyStateText={'아직 타이핑한 문제가 없어요'}
                                    />
                                  </Grid.Unit>
                                  <Grid.Unit size={1 / 3}>
                                    <Text fontSize={1}>스크랩한 문제</Text>
                                    <Card
                                      sx={{
                                        marginTop: 1,
                                        overflow: 'auto',
                                        height:
                                          editorInputTabItems[selectedEditorInputTab].id === 'all' ? '33vh' : '70vh',
                                      }}
                                    >
                                      <ItemList
                                        items={scrap?.problems || []}
                                        renderItem={({ objectUrl }) => (
                                          <Image
                                            key={objectUrl}
                                            src={objectUrl}
                                            alt={objectUrl}
                                            html
                                            style={{ display: 'block' }}
                                          />
                                        )}
                                      />
                                    </Card>
                                  </Grid.Unit>
                                </Grid>
                              ) : null}
                              {editorInputTabItems[selectedEditorInputTab].id === 'all' ||
                              editorInputTabItems[selectedEditorInputTab].id === 'solution' ? (
                                <Grid gapX={2}>
                                  <Grid.Unit size={2 / 3}>
                                    <KatexEditorField
                                      ref={solutionEditorRef}
                                      name={'solution'}
                                      label={'해설'}
                                      type={'scroll'}
                                      height={
                                        editorInputTabItems[selectedEditorInputTab].id === 'all' ? '33vh' : '70vh'
                                      }
                                      required
                                      onFocus={setActiveEditor}
                                      onUploadImage={handleUploadImage}
                                      onChange={handleChangeSolutionKatexEditor}
                                      emptyStateText={'아직 타이핑한 해설이 없어요'}
                                    />
                                  </Grid.Unit>
                                  <Grid.Unit size={1 / 3}>
                                    <Text fontSize={1}>스크랩한 해설</Text>
                                    <Card
                                      sx={{
                                        marginTop: 1,
                                        overflow: 'auto',
                                        height:
                                          editorInputTabItems[selectedEditorInputTab].id === 'all' ? '33vh' : '70vh',
                                      }}
                                    >
                                      <ItemList
                                        items={scrap?.solutions || []}
                                        renderItem={({ objectUrl }) => (
                                          <Image
                                            key={objectUrl}
                                            src={objectUrl}
                                            alt={objectUrl}
                                            html
                                            style={{ display: 'block' }}
                                          />
                                        )}
                                      />
                                    </Card>
                                  </Grid.Unit>
                                </Grid>
                              ) : null}
                            </View>
                          </View>
                        )}
                      </TabHandler>
                    </Grid.Unit>
                    <Grid.Unit size={1 / 4}>
                      <View sx={{ padding: 3, backgroundColor: 'canvas.inset', overflowY: 'auto', height: '100%' }}>
                        {sideTabItems[selectedSideTabIndex].id === 'tag' ? (
                          <FormLayout>
                            <View>
                              <Text fontSize={2} fontWeight={'bold'}>
                                문제 정보
                              </Text>
                            </View>
                            <Card sx={{ padding: 3, backgroundColor: 'canvas.inset' }}>
                              <DescriptionList
                                item={values}
                                itemDescriptions={{
                                  answerType: {
                                    title: '문제 유형',
                                    renderValue: ({ answerType }) =>
                                      answerType ? (
                                        <Suspense>
                                          <EnumPair typename={'TaskProblemAnswerTypeEnum'}>{answerType}</EnumPair>
                                        </Suspense>
                                      ) : undefined,
                                  },
                                  answer: {
                                    title: '정답',
                                    renderValue: ({ answer }) =>
                                      isNullable(answer) || answer === '' ? (
                                        <View
                                          sx={{
                                            color: 'danger.fg',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Stack gapX={1}>
                                            <Stack.Item>
                                              <StyledOcticon icon={StopIcon} size={12} />
                                            </Stack.Item>
                                            <Stack.Item>
                                              <Text fontSize={1}>입력 필요</Text>
                                            </Stack.Item>
                                          </Stack>
                                        </View>
                                      ) : (
                                        answer
                                      ),
                                  },
                                  answerChoiceCount: {
                                    title: '선지 개수',
                                    renderValue: ({ answerChoiceCount }) =>
                                      answerChoiceCount !== 0 ? answerChoiceCount : undefined,
                                  },
                                }}
                                picks={['answerType', 'answer', 'answerChoiceCount']}
                                titleUnitSize={1 / 2}
                                descriptionUnitSize={1 / 2}
                              />
                            </Card>
                            <Suspense
                              fallback={
                                <View padding={2}>
                                  <Spinner size={'small'} />
                                </View>
                              }
                            >
                              <EnumPairSelectField
                                typename={'TaskProblemCreationTypeEnum'}
                                label={'변형 정도'}
                                name={'creationType'}
                                placeholder={'변형 정도 선택'}
                                required
                              />
                            </Suspense>
                            <Suspense
                              fallback={
                                <View padding={2}>
                                  <Spinner size={'small'} />
                                </View>
                              }
                            >
                              <EnumPairRadioGroupField
                                typename={'TaskLabelingCountEnum'}
                                label={'라벨링 횟수'}
                                name={'labelingCount'}
                                required
                                renderOptionWrapper={(children, { id }) => (
                                  <Grid.Unit key={id} size={'min'}>
                                    {children}
                                  </Grid.Unit>
                                )}
                                renderContainer={(children) => <Grid gapX={2}>{children}</Grid>}
                              />
                            </Suspense>
                            <Suspense
                              fallback={
                                <View padding={2}>
                                  <Spinner size={'small'} />
                                </View>
                              }
                            >
                              <EnumPairSelectField
                                typename={'SchoolTypeEnum'}
                                label={'학교급'}
                                name={'unitSchoolType'}
                                onChange={(value) => {
                                  setFieldValue('unitSchoolType', value as SchoolTypeEnum);
                                  setFieldValue('unitDs', []);
                                  unitDsDisposeQuery();
                                }}
                              />
                            </Suspense>
                            <Suspense
                              fallback={
                                <View padding={2}>
                                  <Spinner size={'small'} />
                                </View>
                              }
                            >
                              <UnitDSelectDialogTokenField
                                label={'Unit D'}
                                name={'unitDs'}
                                placeholder={'Unit A > B > C > D 선택'}
                                variables={
                                  unit ? { filters: { unitSchoolType_Exact: values.unitSchoolType } } : undefined
                                }
                                caption={'최대 3개 선택 가능'}
                                onChange={(id) => {
                                  const unitDs = values.unitDs.includes(id)
                                    ? values.unitDs.filter((unitD) => unitD !== id)
                                    : [...values.unitDs, id];
                                  setFieldValue('unitDs', unitDs);
                                  unitDsLoadQuery({ filters: { id_In: unitDs }, order: { order: 'DESC' } });
                                }}
                              />
                            </Suspense>
                            <View>
                              <Suspense
                                fallback={
                                  <View padding={2}>
                                    <Spinner size={'small'} />
                                  </View>
                                }
                              >
                                {unitDsQueryReference ? (
                                  <PreloadedQueryRenderer
                                    query={unitDsForTaskProblemUpdateDialog}
                                    queryReference={unitDsQueryReference}
                                  >
                                    {(queryReference) => (
                                      <UnitDPaginator fragmentReference={queryReference}>
                                        {({ unitDs }) => (
                                          <ItemList
                                            items={unitDs.edges}
                                            renderItem={({ node }) => <UnitDItem unitD={node} />}
                                            renderItemWrapper={(children, { node }, index) => (
                                              <View
                                                key={node.id}
                                                sx={{
                                                  marginTop: index === 0 ? 0 : 2,
                                                  borderRadius: 2,
                                                  backgroundColor: 'neutral.muted',
                                                  padding: 2,
                                                }}
                                              >
                                                <Grid wrap={false}>
                                                  <Grid.Unit size={'max'}>
                                                    {index === 0 ? (
                                                      <Label sx={{ marginBottom: 1 }} variant={'default'}>
                                                        Last Unit D
                                                      </Label>
                                                    ) : null}
                                                    {children}
                                                  </Grid.Unit>
                                                  <Grid.Unit size={'min'}>
                                                    <IconButton
                                                      icon={XIcon}
                                                      variant={'plain'}
                                                      aria-label={'remove unitD'}
                                                      onClick={() => {
                                                        const unitDs = values.unitDs.filter(
                                                          (unitD) => unitD !== node.id,
                                                        );
                                                        setFieldValue('unitDs', unitDs);
                                                        unitDsLoadQuery({
                                                          filters: { id_In: unitDs },
                                                          order: { order: 'DESC' },
                                                        });
                                                      }}
                                                    />
                                                  </Grid.Unit>
                                                </Grid>
                                              </View>
                                            )}
                                          />
                                        )}
                                      </UnitDPaginator>
                                    )}
                                  </PreloadedQueryRenderer>
                                ) : null}
                              </Suspense>
                            </View>
                          </FormLayout>
                        ) : sideTabItems[selectedSideTabIndex].id === 'image' ? (
                          <FormLayout>
                            <Stack gapX={1}>
                              <Stack.Item>
                                <Text fontSize={2} fontWeight={'bold'}>
                                  요청 이미지
                                </Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Text
                                  sx={{ fontSize: 1, fontWeight: 'bold' }}
                                  color={values.imageRequests.length === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                                >
                                  {numberWithCommas(values.imageRequests.length || 0)}
                                </Text>
                              </Stack.Item>
                            </Stack>
                            <ItemList
                              items={values.imageRequests}
                              renderItem={({ file }, index) => (
                                <View sx={{ '&>*:not(:first-child)': { marginTop: 1 } }}>
                                  <Card>
                                    <SquareView
                                      sx={{
                                        '&:hover': {
                                          '*': {
                                            display: 'block',
                                          },
                                        },
                                      }}
                                    >
                                      <View
                                        sx={{
                                          position: 'absolute',
                                          display: 'none',
                                          width: '100%',
                                          height: '100%',
                                          backgroundColor: 'fg.default',
                                          opacity: 0.2,
                                          zIndex: 1,
                                        }}
                                      />
                                      <IconButton
                                        sx={{ position: 'absolute', display: 'none', left: 2, bottom: 2, zIndex: 1 }}
                                        size={'large'}
                                        icon={TrashIcon}
                                        aria-label={`delete image${index}`}
                                        onClick={() => handleClickTrashButton(index)}
                                      />
                                      <Image
                                        src={file.objectUrl || ''}
                                        alt={`uploaded image${index}`}
                                        fill
                                        style={{ objectFit: 'contain' }}
                                      />
                                    </SquareView>
                                  </Card>
                                  <TextareaField
                                    id={file.key}
                                    label={'요청사항'}
                                    name={`imageRequests.${index}.description`}
                                    placeholder={'요청사항 입력'}
                                  />
                                </View>
                              )}
                              renderItemWrapper={(children, { file }, index) => (
                                <View key={file.key} sx={{ marginTop: index === 0 ? 0 : 4 }}>
                                  {children}
                                </View>
                              )}
                              emptyState={
                                <Card sx={{ paddingY: 5, backgroundColor: 'canvas.inset' }}>
                                  <EmptyState title={'이미지 첨부를 눌러\n이미지를 넣어주세요'} />
                                </Card>
                              }
                            />
                          </FormLayout>
                        ) : null}
                      </View>
                    </Grid.Unit>
                  </Grid>
                </>
              );
            }}
          </MutationFormik>
        )}
      </TabHandler>
    </Dialog>
  );
};

export default TaskProblemUpdateDialog;
