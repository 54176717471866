/**
 * @generated SignedSource<<125207bdf2c68cecbfd44db47da5008b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NotificationHeaderItem_notificationArrivedSubscription$variables = {
  connections: ReadonlyArray<string>;
};
export type NotificationHeaderItem_notificationArrivedSubscription$data = {
  readonly notificationArrived: {
    readonly description: string | null;
    readonly id: string;
    readonly nodeId: string;
    readonly title: string;
    readonly typename: string;
    readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_notification">;
  };
};
export type NotificationHeaderItem_notificationArrivedSubscription = {
  response: NotificationHeaderItem_notificationArrivedSubscription$data;
  variables: NotificationHeaderItem_notificationArrivedSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nodeId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationHeaderItem_notificationArrivedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Notification",
        "kind": "LinkedField",
        "name": "notificationArrived",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotificationItem_notification"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationHeaderItem_notificationArrivedSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Notification",
        "kind": "LinkedField",
        "name": "notificationArrived",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notificationType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "checked",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "filters": null,
        "handle": "prependNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "notificationArrived",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "NotificationEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "2b2c9ac7cb388fcaf970bb6ebac0e99a",
    "id": null,
    "metadata": {},
    "name": "NotificationHeaderItem_notificationArrivedSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationHeaderItem_notificationArrivedSubscription {\n  notificationArrived {\n    id\n    title\n    description\n    nodeId\n    typename\n    ...NotificationItem_notification\n  }\n}\n\nfragment NotificationItem_notification on Notification {\n  id\n  title\n  notificationType\n  description\n  checked\n  created\n}\n"
  }
};
})();

(node as any).hash = "0d2ae84c4a6ab380386a162acda94302";

export default node;
