/**
 * @generated SignedSource<<3ab5c184538d6c770f226499910742d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SchoolExamSourceInnerPdfDeleteInput = {
  schoolExamSource: string;
};
export type SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation$variables = {
  input: SchoolExamSourceInnerPdfDeleteInput;
};
export type SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation$data = {
  readonly schoolExamSourceInnerPdfDelete: {
    readonly id: string;
    readonly innerPdf: {
      readonly id: string;
    } | null;
  };
};
export type SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation = {
  response: SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation$data;
  variables: SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamSource",
        "kind": "LinkedField",
        "name": "schoolExamSourceInnerPdfDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "innerPdf",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamSource",
        "kind": "LinkedField",
        "name": "schoolExamSourceInnerPdfDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "innerPdf",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteRecord",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "270fc0d5d69604cca207210e75673437",
    "id": null,
    "metadata": {},
    "name": "SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation SchoolExamSourceId_schoolExamSourceInnerPdfDeleteMutation(\n  $input: SchoolExamSourceInnerPdfDeleteInput!\n) {\n  schoolExamSourceInnerPdfDelete(input: $input) {\n    id\n    innerPdf {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "afbd6d4cdbe4a224e89eff5cb0056dbf";

export default node;
