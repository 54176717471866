import { debounce } from 'lodash-es';
import { ChangeEvent, forwardRef, Ref } from 'react';

import { isNullable } from '../../../utils/is';
import TextInput, { TextInputProps } from '../TextInput';

const MAX_INT = 2147483647;
const MIN_INT = -MAX_INT;

type Props = {
  skipDebounce?: boolean;
} & Omit<TextInputProps, 'type'>;

const NumberInput = (
  { min: propMin, max: propMax, onChange: propOnChange, step: propStep, skipDebounce, ...props }: Props,
  ref: Ref<HTMLInputElement>,
) => {
  const min = isNaN(Number(propMin)) ? MIN_INT : Math.max(Number(propMin), MIN_INT);
  const max = isNaN(Number(propMax)) ? MAX_INT : Math.min(Number(propMax), MAX_INT);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '' && (e.target.value as unknown as number) < min) e.target.value = `${min}`;
    if (e.target.value !== '' && max < (e.target.value as unknown as number)) e.target.value = `${max}`;
    if (e.target.value !== '' && propStep) {
      const step = Number(propStep);
      e.target.value = `${Math.floor(Number(e.target.value) / step) * step}`;
    }

    propOnChange?.(e);
  };

  return (
    <TextInput
      ref={ref}
      {...props}
      min={min}
      max={max}
      step={propStep}
      onChange={(!isNullable(propMin) || !isNullable(propMax)) && !skipDebounce ? debounce(onChange, 300) : onChange}
      type={'number'}
    />
  );
};

export default Object.assign(forwardRef(NumberInput), {
  Action: TextInput.Action,
  DialogAction: TextInput.DialogAction,
});
export type { Props as NumberInputProps };
