import { graphql, useFragment } from 'react-relay';

import { SchoolExamSourcePublishDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/SchoolExamSourcePublishDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const SchoolExamSourcePublishDifferenceCounterLabel_difference = graphql`
  fragment SchoolExamSourcePublishDifferenceCounterLabel_difference on Difference {
    ... on SchoolExamSourcePublishDifference {
      scrapImages {
        __typename
      }
    }
  }
`;

type Props = { difference: SchoolExamSourcePublishDifferenceCounterLabel_difference$key } & CounterLabelProps;

const SchoolExamSourcePublishDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { scrapImages } = useFragment(SchoolExamSourcePublishDifferenceCounterLabel_difference, difference);

  const differenceCount = [scrapImages].filter((diff) => !isNullable(diff)).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default SchoolExamSourcePublishDifferenceCounterLabel;
