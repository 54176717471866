/**
 * @generated SignedSource<<4f90fd68cbd312448381109504e0cd0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserTeamEnum = "contents" | "designers" | "developers" | "freelancer" | "ga" | "growth" | "%future added value";
export type UserFilter = {
  AND?: UserFilter | null;
  DISTINCT?: boolean | null;
  NOT?: UserFilter | null;
  OR?: UserFilter | null;
  groupId_Exact?: string | null;
  groupId_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  name_Exact?: string | null;
  name_In?: ReadonlyArray<string> | null;
  search?: string | null;
  team_In?: ReadonlyArray<UserTeamEnum> | null;
};
export type UserAvatarRadioGroupField_usersQuery$variables = {
  filters?: UserFilter | null;
};
export type UserAvatarRadioGroupField_usersQuery$data = {
  readonly users: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
    }>;
  };
};
export type UserAvatarRadioGroupField_usersQuery = {
  response: UserAvatarRadioGroupField_usersQuery$data;
  variables: UserAvatarRadioGroupField_usersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAvatarRadioGroupField_usersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserAvatarText_user"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserAvatarRadioGroupField_usersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "picture",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isDeleted",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "97983eba395e368c7d2db793f8c48db4",
    "id": null,
    "metadata": {},
    "name": "UserAvatarRadioGroupField_usersQuery",
    "operationKind": "query",
    "text": "query UserAvatarRadioGroupField_usersQuery(\n  $filters: UserFilter\n) {\n  users(filters: $filters) {\n    edges {\n      node {\n        id\n        name\n        ...UserAvatarText_user\n      }\n    }\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "f0445a7f2d55ea90ceb9a67d5534ec90";

export default node;
