import { ArrowSwitchIcon, CheckIcon } from '@primer/octicons-react';
import { formatKatexToHtmlString } from '@teamturing/katex-utils';
import { editor } from 'monaco-editor';
import React, { ChangeEvent, Suspense, useRef } from 'react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { TaskImageCreationUpdateDialog_task$key } from '../../../relay/__generated__/TaskImageCreationUpdateDialog_task.graphql';
import { TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation } from '../../../relay/__generated__/TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation.graphql';
import { UploadMetaDataSchema } from '../../../types/upload';
import { getUploadMetaData, uploadGeneral } from '../../../utils/file';
import { isNullable } from '../../../utils/is';
import { numberWithCommas } from '../../../utils/number';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import KatexEditorField from '../../core/KatexEditorField';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import SquareView from '../../core/SquareView';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import UploadField from '../../core/UploadField';
import UploadIconButtonField from '../../core/UploadIconButtonField';
import View from '../../core/View';

const TaskImageCreationUpdateDialog_task = graphql`
  fragment TaskImageCreationUpdateDialog_task on Task {
    id
    title
    sequence
    problem {
      id
      problem
      solution
      problemType
    }
    aiFile {
      key
      objectUrl
      size
    }
    imageRequests {
      edges {
        node {
          id
          image {
            key
            objectUrl
            size
          }
          description
        }
      }
    }
  }
`;

type Props = { task: TaskImageCreationUpdateDialog_task$key } & DialogProps &
  Pick<
    MutationFormikProps<TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation>,
    'config' | 'connections' | 'onSubmit'
  >;

const TaskImageCreationUpdateDialog = ({ task, config, connections, onSubmit, ...props }: Props) => {
  const { id, sequence, title, problem, imageRequests, aiFile } = useFragment(TaskImageCreationUpdateDialog_task, task);
  const { toast } = useToast();

  const problemEditorRef = useRef<editor.IStandaloneCodeEditor>(null);
  const solutionEditorRef = useRef<editor.IStandaloneCodeEditor>(null);

  const handleChangeUploadField = async (
    e: ChangeEvent<HTMLInputElement>,
    onGeneralUpload: (blob: Blob, metaData: UploadMetaDataSchema) => void,
  ) => {
    const targetFile = e.target.files?.[0];

    if (isNullable(targetFile)) return;
    const { name: fileName, size: fileSize } = targetFile;
    const metaData = await getUploadMetaData({ fileName, fileSize });

    if (!metaData) {
      return;
    }

    const isMultiPartUploadRequired = 'upload_id' in metaData && 'part_metadata' in metaData;

    if (isMultiPartUploadRequired) {
      toast('사진 크기가 너무 큽니다. 작은 사진을 첨부해주세요', 'error');
    } else {
      const response = await uploadGeneral(targetFile, metaData);
      if (response.ok) {
        onGeneralUpload(targetFile, metaData);
      }
    }
  };

  return (
    <Dialog {...props}>
      <MutationFormik<TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation>
        mutation={graphql`
          mutation TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation(
            $input: TaskImageCreationUpdateInput!
          ) {
            taskImageCreationUpdate(input: $input) {
              id
              ...TaskImageCreationUpdateDialog_task
            }
          }
        `}
        initialValues={{
          aiFile: { key: aiFile?.key || '', size: aiFile?.size || 0, objectUrl: aiFile?.objectUrl },
          imageRequests: imageRequests.edges.map(({ node }) => ({
            file: { key: node.image.key, size: node.image.size, objectUrl: node.image.objectUrl },
            description: node.description || '',
          })),
          problem: {
            text: problem?.problem || '',
            textHtml: formatKatexToHtmlString(problem?.problem || ''),
          },
          solution: {
            text: problem?.solution || '',
            textHtml: formatKatexToHtmlString(problem?.solution || ''),
          },
          task: id,
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ values, setFieldValue, dirty, handleSubmit }, { scrollContainerRef, nonFieldErrorRef }) => {
          const handleGeneralUploadImage = (index: number) => (blob: Blob, metaData: UploadMetaDataSchema) => {
            const previousImageSrc = values.imageRequests?.[index].file.objectUrl;
            if (!previousImageSrc) return;

            const nextImageSrc = metaData.object_url;

            const problem = values.problem.text.replaceAll(previousImageSrc, nextImageSrc);
            const solution = values.solution.text.replaceAll(previousImageSrc, nextImageSrc);

            problemEditorRef.current?.setValue(problem);
            solutionEditorRef.current?.setValue(solution);
            setFieldValue(`imageRequests.${index}.file`, {
              key: metaData.key,
              size: blob.size,
              objectUrl: metaData.object_url,
            });
          };

          return (
            <>
              <Dialog.Header>
                <Grid sx={{ paddingRight: 4 }}>
                  <Grid.Unit size={'max'}>
                    <Stack gapX={1}>
                      <Stack.Item>
                        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>이미지 제작하기</Text>
                      </Stack.Item>
                      {problem ? (
                        <Stack.Item>
                          <Suspense>
                            <View>
                              <Label variant={'accent'}>
                                <EnumPair typename={'TaskProblemTypeEnum'}>{problem.problemType}</EnumPair>
                                {` · ${title}`}
                              </Label>
                            </View>
                          </Suspense>
                        </Stack.Item>
                      ) : null}
                      <Stack.Item>
                        <Label variant={'primary'}>{sequence}</Label>
                      </Stack.Item>
                    </Stack>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Button
                      variant={'primary'}
                      onClick={() => handleSubmit()}
                      leadingIcon={CheckIcon}
                      disabled={!dirty}
                    >
                      저장하기
                    </Button>
                  </Grid.Unit>
                </Grid>
              </Dialog.Header>
              <Grid wrap={false} sx={{ height: '100%', overflowY: 'hidden' }}>
                <Grid.Unit size={3 / 4}>
                  <View
                    ref={scrollContainerRef}
                    sx={{
                      'padding': 3,
                      'height': '100%',
                      'overflowY': 'auto',
                      '&>*:not(:first-child)': { marginTop: 3 },
                    }}
                  >
                    <NonFieldError ref={nonFieldErrorRef} />
                    <KatexEditorField
                      ref={problemEditorRef}
                      name={'problem'}
                      label={'문제 타이핑'}
                      height={'36vh'}
                      type={'scroll'}
                      emptyStateText={'아직 타이핑한 문제가 없어요'}
                      readOnly
                      disabledUploadImage
                      required
                    />
                    <KatexEditorField
                      ref={solutionEditorRef}
                      name={'solution'}
                      label={'해설 타이핑'}
                      height={'36vh'}
                      type={'scroll'}
                      emptyStateText={'아직 타이핑한 해설이 없어요'}
                      readOnly
                      disabledUploadImage
                      required
                    />
                  </View>
                </Grid.Unit>
                <Grid.Unit size={1 / 4}>
                  <View
                    sx={{
                      'padding': 3,
                      'backgroundColor': 'canvas.inset',
                      'height': '100%',
                      'overflowY': 'auto',
                      '&>*:not(:first-child)': { marginTop: 4 },
                    }}
                  >
                    <Stack gapX={1}>
                      <Stack.Item>
                        <Text fontSize={2} fontWeight={'bold'}>
                          요청한 이미지
                        </Text>
                      </Stack.Item>
                      <Stack.Item>
                        <Text
                          sx={{ fontSize: 1, fontWeight: 'bold' }}
                          color={imageRequests.edges.length === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                        >
                          {numberWithCommas(values.imageRequests.length || 0)}
                        </Text>
                      </Stack.Item>
                    </Stack>
                    <UploadField
                      label={'이미지 ai 파일'}
                      name={'aiFile'}
                      placeholder={'ai 파일을 선택하세요'}
                      accept={'application/postscript'}
                      required
                    />
                    <ItemList
                      items={values.imageRequests || []}
                      renderItem={({ file, description }, index) => (
                        <View sx={{ '&>*:not(:first-child)': { marginTop: 1 } }}>
                          <Card>
                            <SquareView
                              sx={{
                                '&:hover': {
                                  '*': {
                                    display: 'block',
                                  },
                                },
                              }}
                            >
                              <View
                                sx={{
                                  position: 'absolute',
                                  display: 'none',
                                  width: '100%',
                                  height: '100%',
                                  backgroundColor: 'fg.default',
                                  opacity: 0.2,
                                  zIndex: 1,
                                }}
                              />
                              <UploadIconButtonField
                                sx={{ position: 'absolute', display: 'none', left: 2, bottom: 2, zIndex: 1 }}
                                name={`imageRequest.${index}.file`}
                                label={`image${index}`}
                                labelConfig={{ visuallyHidden: true }}
                                size={'large'}
                                aria-label={`Change image ${index}`}
                                icon={ArrowSwitchIcon}
                                accept={'image/*'}
                                onChange={(e) => handleChangeUploadField(e, handleGeneralUploadImage(index))}
                              />
                              <Image
                                src={file.objectUrl || ''}
                                alt={`requested image ${index}`}
                                fill
                                style={{ objectFit: 'contain' }}
                              />
                            </SquareView>
                          </Card>
                          <Card sx={{ padding: 3 }}>
                            <Text fontSize={1} color={'fg.subtle'} sx={{ whiteSpace: 'pre-wrap' }}>
                              {description || '-'}
                            </Text>
                          </Card>
                        </View>
                      )}
                      renderItemWrapper={(children, { file }) => <View key={file.key}>{children}</View>}
                      emptyState={
                        <Card sx={{ paddingY: 5, backgroundColor: 'canvas.inset' }}>
                          <EmptyState title={'요청한 이미지가 없습니다.'} />
                        </Card>
                      }
                    />
                  </View>
                </Grid.Unit>
              </Grid>
            </>
          );
        }}
      </MutationFormik>
    </Dialog>
  );
};

export default TaskImageCreationUpdateDialog;
