import { graphql, useFragment } from 'react-relay';

import { SchoolExamSourcePublishDifferenceItemList_difference$key } from '../../../relay/__generated__/SchoolExamSourcePublishDifferenceItemList_difference.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import SquareView from '../../core/SquareView';
import Text from '../../core/Text';
import View from '../../core/View';
import DiffItem from '../../difference/DiffItem';

const SchoolExamSourcePublishDifferenceItemList_difference = graphql`
  fragment SchoolExamSourcePublishDifferenceItemList_difference on Difference {
    ... on SchoolExamSourcePublishDifference {
      scrapImages {
        ...DiffItem_diff
      }
    }
  }
`;

type Props = { difference: SchoolExamSourcePublishDifferenceItemList_difference$key };

const SchoolExamSourcePublishDifferenceItemList = ({ difference }: Props) => {
  const { scrapImages } = useFragment(SchoolExamSourcePublishDifferenceItemList_difference, difference);

  const diffItems = [
    ...(scrapImages
      ? scrapImages.map((scrap, index) => ({
          title: `스크랩 - ${index + 1}번`,
          diff: scrap,
          renderItem: (beforeOfAfter: string) => (
            <SquareView sx={{ backgroundColor: 'neutral.muted' }}>
              <View sx={{ height: '100%', padding: 2, position: 'relative' }}>
                <View sx={{ position: 'relative', height: '100%' }}>
                  <Image src={beforeOfAfter} alt={`${index + 1}번 문제`} fill style={{ objectFit: 'contain' }} />
                </View>
              </View>
            </SquareView>
          ),
        }))
      : []),
  ].filter(({ diff }) => !isNullable(diff));
  return (
    <ItemList
      items={diffItems}
      renderItem={({ title, diff, renderItem }) => (
        <>
          <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
          <View sx={{ marginTop: 2 }}>
            <DiffItem diff={diff!} renderItem={renderItem} />
          </View>
        </>
      )}
      renderItemWrapper={(children, { title }, i) => (
        <View key={title} sx={{ marginTop: i > 0 ? 3 : 0 }}>
          {children}
        </View>
      )}
      emptyState={
        <View sx={{ paddingY: 6 }}>
          <EmptyState
            title={'기록되지 않은 히스토리이에요'}
            description={'이 기능이 만들어지기 전에 일어난 일이거나 오류로 누락된 히스토리입니다.'}
          />
        </View>
      }
    />
  );
};

export default SchoolExamSourcePublishDifferenceItemList;
