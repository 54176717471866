import { capitalize } from 'lodash-es';
import React, { Suspense, useEffect } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';

import { UserPermissionsUpdateDialog_groupQuery } from '../../../relay/__generated__/UserPermissionsUpdateDialog_groupQuery.graphql';
import { UserPermissionsUpdateDialog_user$key } from '../../../relay/__generated__/UserPermissionsUpdateDialog_user.graphql';
import {
  PermissionEnum,
  UserPermissionsUpdateDialog_userPermissionsUpdateMutation,
} from '../../../relay/__generated__/UserPermissionsUpdateDialog_userPermissionsUpdateMutation.graphql';
import CategoryTableItem from '../../category/CategoryTableItem';
import Avatar from '../../core/Avatar';
import Button from '../../core/Button';
import Checkbox, { CheckboxProps } from '../../core/Checkbox';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPair from '../../core/EnumPair';
import ErrorBoundary from '../../core/ErrorBoundary';
import ItemList from '../../core/ItemList';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';

const groupForUserPermissionsUpdateDialog = graphql`
  query UserPermissionsUpdateDialog_groupQuery($id: ID!) {
    group(id: $id) {
      id
      categories {
        id
        title
        ...CategoryTableItem_category
      }
    }
  }
`;

const UserPermissionsUpdateDialog_user = graphql`
  fragment UserPermissionsUpdateDialog_user on User {
    id
    name
    picture
    permissions {
      id
      title
    }
    group {
      id
    }
  }
`;

type Props = { user: UserPermissionsUpdateDialog_user$key } & DialogProps &
  Pick<MutationFormikProps<UserPermissionsUpdateDialog_userPermissionsUpdateMutation>, 'config' | 'onSubmit'>;

const UserPermissionsUpdateDialog = ({ isOpen, user, config, onSubmit, ...props }: Props) => {
  const { id, name, picture, permissions, group } = useFragment(UserPermissionsUpdateDialog_user, user);

  const [queryReference, loadQuery] = useQueryLoader<UserPermissionsUpdateDialog_groupQuery>(
    groupForUserPermissionsUpdateDialog,
  );

  useEffect(() => {
    const isFirstOpenDialog = isOpen && !queryReference;
    if (isFirstOpenDialog && group) {
      loadQuery({ id: group.id }, { fetchPolicy: 'store-and-network' });
    }
  }, [isOpen]);

  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Stack gapX={2}>
          <Stack.Item>
            <Avatar src={picture || ''} size={32} />
          </Stack.Item>
          <Stack.Item>
            <Text fontSize={3} fontWeight={'bold'}>
              {`${capitalize(name)}님의 권한 수정하기`}
            </Text>
          </Stack.Item>
        </Stack>
      </Dialog.Header>
      <ErrorBoundary>
        <Suspense
          fallback={
            <View sx={{ padding: 5 }}>
              <Spinner />
            </View>
          }
        >
          {queryReference ? (
            <PreloadedQueryRenderer<UserPermissionsUpdateDialog_groupQuery>
              query={groupForUserPermissionsUpdateDialog}
              queryReference={queryReference}
            >
              {({ group: { categories } }) => (
                <MutationFormik<UserPermissionsUpdateDialog_userPermissionsUpdateMutation>
                  mutation={graphql`
                    mutation UserPermissionsUpdateDialog_userPermissionsUpdateMutation(
                      $input: UserPermissionsUpdateInput!
                    ) {
                      userPermissionsUpdate(input: $input) {
                        id
                        ...UserPermissionsUpdateDialog_user
                        excludedPermissions {
                          id
                          title
                        }
                      }
                    }
                  `}
                  initialValues={{
                    id,
                    permissions: permissions.map(({ id }) => id),
                  }}
                  config={config}
                  onSubmit={onSubmit}
                >
                  {({ values, setFieldValue, submitForm, isSubmitting }) => {
                    const handleChange: CheckboxProps['onChange'] = (e) => {
                      setFieldValue(
                        'permissions',
                        values.permissions.includes(e.target.value as PermissionEnum)
                          ? values.permissions.filter((v) => v !== e.target.value)
                          : [...values.permissions, e.target.value],
                      );
                    };
                    return (
                      <>
                        <Dialog.Body>
                          <ItemList
                            items={categories}
                            renderItem={(category) => (
                              <>
                                <View sx={{ marginBottom: 3 }}>
                                  <Text sx={{ fontSize: 1, color: 'fg.muted', fontWeight: 'bold' }}>
                                    <EnumPair typename={'CategoryEnum'}>{category.title}</EnumPair>
                                  </Text>
                                </View>
                                <CategoryTableItem
                                  category={category}
                                  renderPermissions={(permissions) => (
                                    <View sx={{ padding: 2 }}>
                                      <Stack wrap={true} gapX={5} gapY={3}>
                                        <ItemList
                                          items={permissions}
                                          renderItem={({ id, isActive, label }) => {
                                            const disabled = !isActive || isSubmitting;
                                            return (
                                              <Stack gapX={2}>
                                                <Stack.Item>
                                                  <Checkbox
                                                    id={id}
                                                    value={id}
                                                    disabled={disabled}
                                                    checked={values.permissions.includes(id)}
                                                    onChange={handleChange}
                                                    sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                                                  />
                                                </Stack.Item>
                                                <Stack.Item>
                                                  <View
                                                    as={'label'}
                                                    htmlFor={id}
                                                    sx={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
                                                  >
                                                    <Text fontSize={1} color={disabled ? 'fg.subtle' : 'fg.default'}>
                                                      {label}
                                                    </Text>
                                                  </View>
                                                </Stack.Item>
                                              </Stack>
                                            );
                                          }}
                                          renderItemWrapper={(children, { id }) => (
                                            <Stack.Item key={id}>{children}</Stack.Item>
                                          )}
                                        />
                                      </Stack>
                                    </View>
                                  )}
                                />
                              </>
                            )}
                            renderItemWrapper={(children, { id }, index) => (
                              <View key={id} sx={{ marginTop: index === 0 ? 0 : 4 }}>
                                {children}
                              </View>
                            )}
                          />
                        </Dialog.Body>
                        <Dialog.Footer>
                          <Button size={'large'} variant={'primary'} onClick={() => submitForm()}>
                            저장하기
                          </Button>
                        </Dialog.Footer>
                      </>
                    );
                  }}
                </MutationFormik>
              )}
            </PreloadedQueryRenderer>
          ) : null}
        </Suspense>
      </ErrorBoundary>
    </Dialog>
  );
};

export default UserPermissionsUpdateDialog;
