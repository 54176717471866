/**
 * @generated SignedSource<<3da74b9ef259db3969e00c2e669ec857>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderDifferenceCounterLabel_difference$data = {
  readonly address?: {
    readonly __typename: "Diff";
  } | null;
  readonly addressDetail?: {
    readonly __typename: "Diff";
  } | null;
  readonly description?: {
    readonly __typename: "Diff";
  } | null;
  readonly recipientName?: {
    readonly __typename: "Diff";
  } | null;
  readonly recipientNumber?: {
    readonly __typename: "Diff";
  } | null;
  readonly stuffTitle?: {
    readonly __typename: "Diff";
  } | null;
  readonly title?: {
    readonly __typename: "Diff";
  } | null;
  readonly zipCode?: {
    readonly __typename: "Diff";
  } | null;
  readonly " $fragmentType": "DeliveryOrderDifferenceCounterLabel_difference";
};
export type DeliveryOrderDifferenceCounterLabel_difference$key = {
  readonly " $data"?: DeliveryOrderDifferenceCounterLabel_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderDifferenceCounterLabel_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryOrderDifferenceCounterLabel_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "address",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "addressDetail",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "zipCode",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "recipientName",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "recipientNumber",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "stuffTitle",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "title",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "DeliveryOrderDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "c758d0ed572723519e603b4069f29636";

export default node;
