import { ChevronRightIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  AppDialogDescriptionList_appDialog$data,
  AppDialogDescriptionList_appDialog$key,
} from '../../../relay/__generated__/AppDialogDescriptionList_appDialog.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import LinkButton from '../../core/LinkButton';
import Text from '../../core/Text';

const AppDialogDescriptionList_appDialog = graphql`
  fragment AppDialogDescriptionList_appDialog on AppDialog {
    userAppVersion {
      min
      max
    }
    userOs
    userTypes
    userYears
    userPeriod {
      min
      max
    }
    startAt
    endAt
    exposeType
    exposeType
    exposeTag
    exposeHour
    exposeWeekdays
    appDialogType
    appDeepLink
    appDialogMobileImage {
      objectUrl
    }
    appDialogTabletImage {
      objectUrl
    }
    appDialogImage {
      objectUrl
    }
    order
  }
`;

type Props = {
  type: 'target' | 'dialog-image' | 'dialog-text' | 'dialog-image-and-text';
  appDialog: AppDialogDescriptionList_appDialog$key;
} & Omit<DescriptionListProps<AppDialogDescriptionList_appDialog$data>, 'item' | 'itemDescriptions' | 'picks'>;
const AppDialogDescriptionList = ({ appDialog: appDialogReference, type, ...props }: Props) => {
  const appDialog = useFragment(AppDialogDescriptionList_appDialog, appDialogReference);

  const renderImageItem = (imageUrl = '') => (
    <Grid wrap={false}>
      <Grid.Unit size={'max'}>{imageUrl.replace(/^https:.*\/cms\//, '')}</Grid.Unit>
      <Grid.Unit size={'min'}>
        <LinkButton
          href={imageUrl}
          trailingIcon={ChevronRightIcon}
          size={'small'}
          variant={'invisible'}
          target={'_blank'}
        >
          이미지 열기
        </LinkButton>
      </Grid.Unit>
    </Grid>
  );

  return (
    <DescriptionList
      item={appDialog}
      itemDescriptions={{
        userAppVersion: {
          title: '앱 버전',
          renderValue: ({ userAppVersion }) =>
            !userAppVersion.min && !userAppVersion.max
              ? '전체 버전'
              : `${userAppVersion.min || ''}~${userAppVersion.max}`,
        },
        userOs: {
          title: 'OS',
          renderValue: ({ userOs }) =>
            userOs.map((type, index) => (
              <React.Fragment key={type}>
                {index > 0 && <Text>, </Text>}
                <EnumPair typename={'AppDialogUserOsEnum'}>{type}</EnumPair>
              </React.Fragment>
            )),
        },
        userTypes: {
          title: '타입',
          renderValue: ({ userTypes }) =>
            userTypes.map((type, index) => (
              <React.Fragment key={type}>
                {index > 0 && <Text>, </Text>}
                <EnumPair typename={'AppDialogUserTypeEnum'}>{type}</EnumPair>
              </React.Fragment>
            )),
        },
        userYears: {
          title: '학년',
          renderValue: ({ userYears }) =>
            userYears.map((type, index) => (
              <React.Fragment key={type}>
                {index > 0 && <Text>, </Text>}
                <EnumPair typename={'AppDialogUserYearEnum'}>{type}</EnumPair>
              </React.Fragment>
            )),
        },
        userPeriod: {
          title: '고객 수명',
          renderValue: ({ userPeriod }) =>
            !userPeriod.min && !userPeriod.max
              ? '전체 기간'
              : `${userPeriod.min ? `${userPeriod.min}일` : ''}${
                  (userPeriod.min ? '~' : '') + (userPeriod.max ? `${userPeriod.max}일` : '')
                }`,
        },
        startAt: {
          title: '시작일',
          renderValue: ({ startAt }) => <DateText>{startAt}</DateText>,
        },
        endAt: {
          title: '종료일',
          renderValue: ({ endAt }) => <DateText>{endAt}</DateText>,
        },
        order: {
          title: '우선순위',
          renderValue: ({ order }) => order,
        },
        exposeType: {
          title: '노출 타입',
          renderValue: ({ exposeType, exposeHour, exposeWeekdays, exposeTag }) => (
            <>
              {!['hour_repeat', 'weekday_repeat'].includes(exposeType) && (
                <EnumPair typename={'AppDialogExposeTypeEnum'}>{exposeType}</EnumPair>
              )}
              {exposeType === 'hour_repeat' && (
                <EnumPair typename={'AppDialogExposeHourEnum'} formatter={(value) => `${value} 반복`}>
                  {exposeHour!}
                </EnumPair>
              )}
              {exposeType === 'weekday_repeat' &&
                exposeWeekdays!.map((weekday, index) => (
                  <React.Fragment key={weekday}>
                    {index > 0 && <Text>, </Text>}
                    <EnumPair typename={'AppDialogExposeWeekdayEnum'}>{weekday}</EnumPair>
                    {index === exposeWeekdays!.length - 1 && ' 반복'}
                  </React.Fragment>
                ))}
              {exposeType === 'manual_tag' && ` [${exposeTag}]`}
            </>
          ),
        },
        appDialogType: {
          title: '타입',
          renderValue: ({ appDialogType }) => <EnumPair typename={'AppDialogTypeEnum'}>{appDialogType}</EnumPair>,
        },
        appDeepLink: {
          title: '액션 딥링크',
          renderValue: ({ appDeepLink }) => appDeepLink || '-',
        },
        appDialogMobileImage: {
          title: '모바일용 이미지',
          renderValue: ({ appDialogMobileImage }) => renderImageItem(appDialogMobileImage?.objectUrl),
        },
        appDialogTabletImage: {
          title: '태블릿용 이미지',
          renderValue: ({ appDialogTabletImage }) => renderImageItem(appDialogTabletImage?.objectUrl),
        },
        appDialogImage: {
          title: '이미지',
          renderValue: ({ appDialogImage }) => renderImageItem(appDialogImage?.objectUrl),
        },
      }}
      picks={
        type === 'target'
          ? ['userAppVersion', 'userOs', 'userTypes', 'userYears', 'userPeriod']
          : type === 'dialog-image'
          ? [
              'startAt',
              'endAt',
              'order',
              'exposeType',
              'appDialogType',
              'appDeepLink',
              'appDialogMobileImage',
              'appDialogTabletImage',
            ]
          : type === 'dialog-text'
          ? ['startAt', 'endAt', 'order', 'exposeType', 'appDialogType', 'appDeepLink']
          : type === 'dialog-image-and-text'
          ? ['startAt', 'endAt', 'order', 'exposeType', 'appDialogType', 'appDeepLink', 'appDialogImage']
          : []
      }
      {...props}
    />
  );
};
export default AppDialogDescriptionList;
