import { graphql, useFragment } from 'react-relay';

import { InternalUserPhoneNumberUpdateDialog_internalUser$key } from '../../../relay/__generated__/InternalUserPhoneNumberUpdateDialog_internalUser.graphql';
import { InternalUserPhoneNumberUpdateDialog_internalUserPhoneNumberUpdateMutation } from '../../../relay/__generated__/InternalUserPhoneNumberUpdateDialog_internalUserPhoneNumberUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const InternalUserPhoneNumberUpdateDialog_internalUser = graphql`
  fragment InternalUserPhoneNumberUpdateDialog_internalUser on MATInternalUser {
    id
    phoneNumber
  }
`;

type Props = {
  internalUser: InternalUserPhoneNumberUpdateDialog_internalUser$key;
} & DialogProps &
  Pick<
    MutationFormikProps<InternalUserPhoneNumberUpdateDialog_internalUserPhoneNumberUpdateMutation>,
    'config' | 'onSubmit'
  >;

const InternalUserPhoneNumberUpdateDialog = ({ internalUser, onSubmit, config, ...props }: Props) => {
  const data = useFragment(InternalUserPhoneNumberUpdateDialog_internalUser, internalUser);
  const { id, phoneNumber } = data;
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>유저 전화번호 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<InternalUserPhoneNumberUpdateDialog_internalUserPhoneNumberUpdateMutation>
        mutation={graphql`
          mutation InternalUserPhoneNumberUpdateDialog_internalUserPhoneNumberUpdateMutation(
            $input: MATInternalPhoneNumberUpdateInput!
          ) {
            internalPhoneNumberUpdate(input: $input) {
              id
              phoneNumber
            }
          }
        `}
        initialValues={{
          id: id,
          phoneNumber: phoneNumber || '',
        }}
        onSubmit={onSubmit}
        config={config}
      >
        {({ submitForm, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField label={'전화번호'} name={'phoneNumber'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button onClick={submitForm} disabled={!dirty}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default InternalUserPhoneNumberUpdateDialog;
