import { graphql, useFragment } from 'react-relay';

import { TaskImageCreationDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/TaskImageCreationDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const TaskImageCreationDifferenceCounterLabel_difference = graphql`
  fragment TaskImageCreationDifferenceCounterLabel_difference on Difference {
    ... on TaskImageCreationDifference {
      problem {
        __typename
      }
      solution {
        __typename
      }
    }
  }
`;

type Props = { difference: TaskImageCreationDifferenceCounterLabel_difference$key } & CounterLabelProps;

const TaskImageCreationDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { problem, solution } = useFragment(TaskImageCreationDifferenceCounterLabel_difference, difference);

  const differenceCount = [problem, solution].filter((diff) => !isNullable(diff)).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default TaskImageCreationDifferenceCounterLabel;
