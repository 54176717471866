/**
 * @generated SignedSource<<0bcf129368fca147d285ed2e1898dbac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamProblemRejectDialog_schoolExamProblem$data = {
  readonly id: string;
  readonly " $fragmentType": "SchoolExamProblemRejectDialog_schoolExamProblem";
};
export type SchoolExamProblemRejectDialog_schoolExamProblem$key = {
  readonly " $data"?: SchoolExamProblemRejectDialog_schoolExamProblem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamProblemRejectDialog_schoolExamProblem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamProblemRejectDialog_schoolExamProblem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "SchoolExamProblem",
  "abstractKey": null
};

(node as any).hash = "1b4fba05f5b9c9b750a3cb9a51463eb9";

export default node;
