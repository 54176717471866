import { graphql, useFragment } from 'react-relay';

import { QuizDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/QuizDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const QuizDifferenceCounterLabel_difference = graphql`
  fragment QuizDifferenceCounterLabel_difference on Difference {
    ... on QuizDifference {
      description {
        before
        after
      }
      answer {
        before
        after
      }
      problem {
        before
        after
      }
      solution {
        before
        after
      }
      status {
        before
        after
      }
      tip {
        before
        after
      }
      unitDDescription {
        before
        after
      }
    }
  }
`;

type Props = {
  difference: QuizDifferenceCounterLabel_difference$key;
} & CounterLabelProps;

const QuizDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { description, answer, problem, solution, status, tip, unitDDescription } = useFragment(
    QuizDifferenceCounterLabel_difference,
    difference,
  );
  const differenceCount = [description, answer, problem, solution, status, tip, unitDDescription].filter(
    (diff) => !isNullable(diff),
  ).length;

  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default QuizDifferenceCounterLabel;
