/**
 * @generated SignedSource<<174f40efe84033f56c26748cca622c62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ExamTypeEnum = "first_final" | "first_mid" | "second_final" | "second_mid" | "%future added value";
export type ExamYearEnum = "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "%future added value";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type SchoolExamSourceStatusEnum = "published" | "scrap" | "upload_required" | "%future added value";
export type SchoolRegionEnum = "busan" | "chungbuk" | "chungnam" | "daegu" | "daejeon" | "gangwon" | "gwangju" | "gyeongbuk" | "gyeonggi" | "gyeongnam" | "incheon" | "jeju" | "jeongbuk" | "jeonnam" | "sejong" | "seoul" | "ulsan" | "%future added value";
export type SchoolYearEnum = "high_1" | "high_2" | "high_3" | "middle_1" | "middle_2" | "middle_3" | "%future added value";
export type SchoolExamSourceFilter = {
  AND?: SchoolExamSourceFilter | null;
  DISTINCT?: boolean | null;
  NOT?: SchoolExamSourceFilter | null;
  OR?: SchoolExamSourceFilter | null;
  assignedToId_Exact?: string | null;
  assignedToId_In?: ReadonlyArray<string> | null;
  assignedTo_Exists?: boolean | null;
  assignedTo_Exists_In?: ReadonlyArray<boolean> | null;
  completedById_In?: ReadonlyArray<string> | null;
  completedBy_Exists_In?: ReadonlyArray<boolean> | null;
  examType_In?: ReadonlyArray<ExamTypeEnum> | null;
  examYear_In?: ReadonlyArray<ExamYearEnum> | null;
  innerPdf_Exists_In?: ReadonlyArray<boolean> | null;
  pdf_Exists_In?: ReadonlyArray<boolean> | null;
  region_In?: ReadonlyArray<SchoolRegionEnum> | null;
  schoolId_In?: ReadonlyArray<string> | null;
  schoolId_Not_In?: ReadonlyArray<string> | null;
  schoolYear_In?: ReadonlyArray<SchoolYearEnum> | null;
  search?: string | null;
  status_In?: ReadonlyArray<SchoolExamSourceStatusEnum> | null;
};
export type SchoolExamScrapOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  order?: Ordering | null;
};
export type SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery$variables = {
  filters?: SchoolExamSourceFilter | null;
  first?: number | null;
  scrapCount?: number | null;
  scrapOrder?: SchoolExamScrapOrder | null;
};
export type SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery$data = {
  readonly schoolExamSources: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly school: {
          readonly id: string;
          readonly title: string;
        };
        readonly title: string;
      };
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourcePaginator_query">;
};
export type SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery = {
  response: SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery$data;
  variables: SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scrapCount"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "scrapOrder"
},
v4 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v5 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "SchoolExamSourceConnection",
        "kind": "LinkedField",
        "name": "schoolExamSources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolExamSourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchoolExamSource",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "School",
                    "kind": "LinkedField",
                    "name": "school",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "args": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "Variable",
            "name": "scrapCount",
            "variableName": "scrapCount"
          },
          {
            "kind": "Variable",
            "name": "scrapOrder",
            "variableName": "scrapOrder"
          }
        ],
        "kind": "FragmentSpread",
        "name": "SchoolExamSourcePaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "SchoolExamSourceConnection",
        "kind": "LinkedField",
        "name": "schoolExamSources",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SchoolExamSourceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SchoolExamSource",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "School",
                    "kind": "LinkedField",
                    "name": "school",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "region",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "first",
                        "variableName": "scrapCount"
                      },
                      {
                        "kind": "Variable",
                        "name": "order",
                        "variableName": "scrapOrder"
                      }
                    ],
                    "concreteType": "SchoolExamScrapConnection",
                    "kind": "LinkedField",
                    "name": "scraps",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SchoolExamScrapEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchoolExamScrap",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "scrap",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "objectUrl",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "SchoolExamSourcePaginator_query_schoolExamSources",
        "kind": "LinkedHandle",
        "name": "schoolExamSources"
      }
    ]
  },
  "params": {
    "cacheID": "e6cec3bf70a6529f5593c60c0170d46e",
    "id": null,
    "metadata": {},
    "name": "SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery",
    "operationKind": "query",
    "text": "query SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery(\n  $filters: SchoolExamSourceFilter\n  $first: Int\n  $scrapOrder: SchoolExamScrapOrder\n  $scrapCount: Int\n) {\n  schoolExamSources(filters: $filters, first: $first) {\n    edges {\n      node {\n        id\n        school {\n          id\n          title\n        }\n        title\n      }\n    }\n  }\n  ...SchoolExamSourcePaginator_query_3siC0o\n}\n\nfragment SchoolExamSourceExportedPreview_schoolExamSource_17JTyD on SchoolExamSource {\n  id\n  school {\n    id\n    title\n  }\n  title\n  scraps(order: $scrapOrder, first: $scrapCount) {\n    edges {\n      node {\n        id\n        scrap {\n          id\n          objectUrl\n        }\n      }\n    }\n  }\n}\n\nfragment SchoolExamSourcePaginator_query_3siC0o on Query {\n  schoolExamSources(filters: $filters, first: $first) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        sequence\n        school {\n          id\n          title\n          region\n        }\n        title\n        scraps(order: $scrapOrder, first: $scrapCount) {\n          edges {\n            node {\n              id\n              scrap {\n                id\n                objectUrl\n              }\n            }\n          }\n        }\n        ...SchoolExamSourceExportedPreview_schoolExamSource_17JTyD\n        __typename\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e4b0a70ae8771aaa51cdeb2d99d275f";

export default node;
