const dynamicFaviconId = 'dynamic-favicon';
const remove = () => {
  const dynamicFavicon = document.getElementById(dynamicFaviconId);
  if (dynamicFavicon) {
    (dynamicFavicon as HTMLLinkElement).href = 'data;';
    document.head.removeChild(dynamicFavicon);
  }
};
const apply = (src?: string) => {
  remove();
  const link = document.createElement('link');
  link.id = dynamicFaviconId;
  link.rel = 'shortcut icon';
  link.href = src || '/favicon-noti.svg';
  document.head.appendChild(link);
};

export const dynamicFavicon = {
  remove,
  apply,
};
