import { ReactNode } from 'react';

import useDialogHandler from '../../../hooks/useDialogHandler';

type Props = {
  children: ({
    isOpen,
    openOverlay,
    closeOverlay,
  }: {
    isOpen: boolean;
    openOverlay: () => void;
    closeOverlay: () => void;
  }) => ReactNode;
};

const OverlayHandler = ({ children }: Props) => {
  const { isOpen, openDialog, closeDialog } = useDialogHandler();

  return <>{children({ isOpen, openOverlay: openDialog, closeOverlay: closeDialog })}</>;
};

export default OverlayHandler;
