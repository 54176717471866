/**
 * @generated SignedSource<<3f4a4d463be1742a0398dc57c8ff5727>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskVideoDifferenceCounterLabel_difference$data = {
  readonly description?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly teacherName?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly title?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly type?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly " $fragmentType": "TaskVideoDifferenceCounterLabel_difference";
};
export type TaskVideoDifferenceCounterLabel_difference$key = {
  readonly " $data"?: TaskVideoDifferenceCounterLabel_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskVideoDifferenceCounterLabel_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "before",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "after",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskVideoDifferenceCounterLabel_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "teacherName",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "title",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "type",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "TaskVideoDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "4538361db6d8774432a6480e21d7a4c5";

export default node;
