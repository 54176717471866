import { CheckIcon } from '@primer/octicons-react';
import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery,
  TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery$variables,
} from '../../../relay/__generated__/TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery.graphql';
import { TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation } from '../../../relay/__generated__/TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation.graphql';
import { TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task$key } from '../../../relay/__generated__/TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';

const TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task = graphql`
  fragment TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task on Task {
    sequence
    answerChangeHistory {
      currentAnswer
      previousAnswer
    }
  }
`;

type Props = {
  task: TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task$key;
} & DialogProps &
  Pick<
    MutationFormikProps<TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog = ({
  task,
  config,
  onSubmit,
  connections,
  ...props
}: Props) => {
  const { sequence, answerChangeHistory } = useFragment(
    TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task,
    task,
  );
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>정답 처리하기</Text>
      </Dialog.Header>
      <MutationFormik<TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation>
        mutation={graphql`
          mutation TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_matSolvedProblemUserAnswerUpdateMutation(
            $input: MATSolvedProblemUserAnswerUpdateInput!
          ) {
            solvedProblemUserAnswerUpdate(input: $input) {
              previousAnswerCount
              currentAnswerCount
            }
          }
        `}
        initialValues={{
          sequence,
          previousAnswer: answerChangeHistory?.previousAnswer,
          currentAnswer: answerChangeHistory?.currentAnswer,
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit, values }, { nonFieldErrorRef, scrollContainerRef }) => (
          <Suspense
            fallback={
              <View sx={{ paddingY: 3 }}>
                <Spinner />
              </View>
            }
          >
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <Text fontSize={1} sx={{ whiteSpace: 'pre-wrap' }}>
                  <SolvedProblemUserAnswerHelpText
                    input={{
                      sequence: values.sequence,
                      previousAnswer: values.previousAnswer,
                      currentAnswer: values.currentAnswer,
                    }}
                  />
                </Text>
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                진행하기
              </Button>
            </Dialog.Footer>
          </Suspense>
        )}
      </MutationFormik>
    </Dialog>
  );
};

const SolvedProblemUserAnswerHelpText = ({
  ...variables
}: TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery$variables) => {
  const [{ solvedProblemUserAnswer }] =
    useLazyLoadQuery<TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery>(
      graphql`
        query TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery(
          $input: MATSolvedProblemUserAnswerInput!
        ) {
          solvedProblemUserAnswer(input: $input) {
            helpText
          }
        }
      `,
      variables,
    );

  return <>{solvedProblemUserAnswer.helpText}</>;
};

export default TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog;
