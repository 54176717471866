import { NavList as PrimerNavList, NavListProps as PrimerNavListProps } from '@primer/react';
import React from 'react';

type Props = {} & PrimerNavListProps;

/**
 * TODO: 'ref as any' should be fixed.
 */
const NavList = ({ ...props }: Props, ref: React.Ref<HTMLElement>) => <PrimerNavList ref={ref as any} {...props} />;

export default Object.assign(React.forwardRef(NavList), {
  Item: PrimerNavList.Item,
  LeadingVisual: PrimerNavList.LeadingVisual,
  TrailingVisual: PrimerNavList.TrailingVisual,
  SubNav: PrimerNavList.SubNav,
  Group: PrimerNavList.Group,
  Divider: PrimerNavList.Divider,
});
export type { Props as NavListProps };
