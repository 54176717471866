import { createClient } from 'graphql-ws';
import { flowRight } from 'lodash-es';
import { getSession } from 'next-auth/react';
import { Environment, RequestParameters, Variables, Network, Store, RecordSource, Observable } from 'relay-runtime';

import { logger } from './middleware';

const networkFetch = async (request: RequestParameters, variables: Variables) => {
  const session = await getSession();
  const accessToken = (session as any)?.accessToken ? ((session as any)?.accessToken as string) : '';

  const response = await fetch(process.env.NEXT_PUBLIC_TCMS_GRAPHQL_API_END_POINT!, {
    method: 'POST',
    headers: {
      'Authorization': accessToken,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: request.text,
      variables,
    }),
  });

  const json = await response.json();

  return json;
};

const createSubscriptionsClient = () =>
  createClient({
    url: process.env.NEXT_PUBLIC_TCMS_GRAPHQL_API_END_POINT_WSS!,
    connectionParams: async () => {
      const session = await getSession();
      const accessToken = (session as any)?.accessToken ?? '';

      if (!session) {
        return {};
      }
      return {
        Authorization: accessToken,
      };
    },
  });

function subscriptionFunction(operation: RequestParameters, variables: Variables): Observable<any> {
  return Observable.create((sink) => {
    if (!operation.text) {
      return sink.error(new Error('Operation text cannot be empty'));
    }
    const client = createSubscriptionsClient();

    return client.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      sink,
    );
  });
}

const createNetwork = () => {
  const network = Network.create(flowRight([logger])(networkFetch), subscriptionFunction);
  return network;
};

const createStore = () => {
  return new Store(new RecordSource(), { gcReleaseBufferSize: 30 });
};

const createEnvironment = () => {
  return new Environment({
    network: createNetwork(),
    store: createStore(),
  });
};

const environment = createEnvironment();

export default environment;
