/**
 * @generated SignedSource<<5ef2560433e6ffc695801f2a73fea08b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceAssignInput = {
  assignedTo: string;
  schoolExamSources: ReadonlyArray<string>;
};
export type SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation$variables = {
  input: SchoolExamSourceAssignInput;
};
export type SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation$data = {
  readonly schoolExamSourceAssign: ReadonlyArray<{
    readonly assigned: any | null;
    readonly assignedTo: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly id: string;
  }>;
};
export type SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation = {
  response: SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation$data;
  variables: SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assigned",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamSource",
        "kind": "LinkedField",
        "name": "schoolExamSourceAssign",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "assignedTo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamSource",
        "kind": "LinkedField",
        "name": "schoolExamSourceAssign",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "assignedTo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7564b4fa3d5382062222302ca81dfa8c",
    "id": null,
    "metadata": {},
    "name": "SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation",
    "operationKind": "mutation",
    "text": "mutation SchoolExamSourceAssignDialog_schoolExamSourceAssignMutation(\n  $input: SchoolExamSourceAssignInput!\n) {\n  schoolExamSourceAssign(input: $input) {\n    id\n    assigned\n    assignedTo {\n      id\n      ...UserAvatarText_user\n    }\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "f7142f8a428a07789f7d985f70fac5c2";

export default node;
