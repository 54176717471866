import { CopyIcon } from '@primer/octicons-react';
import { clipboard } from '@teamturing/utils';

import Stack from '../Stack';
import StyledOcticon from '../StyledOcticon';
import Text, { TextProps } from '../Text';
import View from '../View';

type Props = {
  children: string;
  onSuccess?: (value: string) => void;
  onError?: (error: any, value: string) => void;
} & Omit<TextProps, 'children'>;

const CopyableText = ({ children, onSuccess, onError, ...props }: Props) => {
  const handleClick = () =>
    clipboard
      .writeText(children)
      .then(() => onSuccess?.(children))
      .catch((reason) => onError?.(reason, children));
  return (
    <View sx={{ cursor: 'pointer', width: 'fit-content' }} onClick={handleClick}>
      <Stack gapX={1}>
        <Stack.Item>
          <Text {...props}>{children}</Text>
        </Stack.Item>
        <Stack.Item>
          <StyledOcticon icon={CopyIcon} size={14} color={'border.default'} />
        </Stack.Item>
      </Stack>
    </View>
  );
};

export default CopyableText;
