import { graphql, useFragment } from 'react-relay';

import {
  InternalUserConnectionDataTable_internalUserConnection$data,
  InternalUserConnectionDataTable_internalUserConnection$key,
} from '../../../relay/__generated__/InternalUserConnectionDataTable_internalUserConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import EnumPair from '../../core/EnumPair';
import Image from '../../core/Image';
import Text from '../../core/Text';

const InternalUserConnectionDataTable_internalUserConnection = graphql`
  fragment InternalUserConnectionDataTable_internalUserConnection on MATInternalUserConnection {
    edges {
      node {
        id
        loginId
        userSequence
        userName
        phoneNumber
        studentProfile {
          schoolYearType
          profileImageUrl
          friendTag
          statusMessage
        }
      }
    }
  }
`;

type Props = {
  internalUserConnection: InternalUserConnectionDataTable_internalUserConnection$key;
} & Omit<
  DataTableProps<InternalUserConnectionDataTable_internalUserConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const InternalUserConnectionDataTable = ({ internalUserConnection, ...props }: Props) => {
  const { edges } = useFragment(InternalUserConnectionDataTable_internalUserConnection, internalUserConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'profileImageUrl',
          title: '',
          renderValue: ({ studentProfile }) => (
            <Image
              alt={'/favicon-16x16.png'}
              src={studentProfile.profileImageUrl}
              width={32}
              height={32}
              style={{ display: 'block', borderRadius: 10 }}
            />
          ),
          width: 32,
        },
        {
          field: 'userSequence',
          title: '유저 ID',
          renderValue: ({ userSequence }) => <Text fontSize={1}>{userSequence}</Text>,
          width: 50,
        },
        {
          field: 'userName',
          title: '이름',
          renderValue: ({ userName }) => <Text fontSize={1}>{userName}</Text>,
          width: 120,
        },
        {
          field: 'phoneNumber',
          title: '전화번호',
          renderValue: ({ phoneNumber }) => <Text fontSize={1}>{phoneNumber}</Text>,
          width: 120,
        },
        {
          field: 'friendTag',
          title: '친구태그',
          renderValue: ({ studentProfile }) => <Text fontSize={1}>{studentProfile.friendTag}</Text>,
          width: 120,
        },
        {
          field: 'schoolYearType',
          title: '학년',
          renderValue: ({ studentProfile }) => (
            <Text fontSize={1}>
              <EnumPair typename={'MATSchoolYearTypeEnum'}>{studentProfile.schoolYearType}</EnumPair>
            </Text>
          ),
          width: 50,
        },
      ]}
      {...props}
    />
  );
};

export default InternalUserConnectionDataTable;
