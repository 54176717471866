import { CheckIcon } from '@primer/octicons-react';
import React, { Suspense, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import { TaskAssignDialog_taskAssignMutation } from '../../../relay/__generated__/TaskAssignDialog_taskAssignMutation.graphql';
import { TaskAssignDialog_taskAssigneesQuery } from '../../../relay/__generated__/TaskAssignDialog_taskAssigneesQuery.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPair from '../../core/EnumPair';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import UserAvatarRadioGroupField from '../../user/UserAvatarRadioGroupField';

const taskAssigneesForTaskAssignDialog = graphql`
  query TaskAssignDialog_taskAssigneesQuery($task: ID!) {
    taskAssignees(task: $task) {
      status
      user {
        id
      }
    }
  }
`;

type Props = {} & DialogProps &
  Pick<MutationFormikProps<TaskAssignDialog_taskAssignMutation>, 'onSubmit' | 'config' | 'connections'> &
  Pick<MutationFormikProps<TaskAssignDialog_taskAssignMutation>['initialValues'], 'tasks'>;

const TaskAssignDialog = ({ isOpen, onSubmit, config, connections, tasks, ...props }: Props) => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<TaskAssignDialog_taskAssigneesQuery>(
    taskAssigneesForTaskAssignDialog,
  );

  useEffect(() => {
    if (isOpen && tasks && tasks.length === 1) loadQuery({ task: tasks[0] }, { fetchPolicy: 'store-and-network' });
    return () => disposeQuery();
  }, [isOpen]);

  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Stack gapX={1}>
          <Stack.Item>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>배정하기</Text>
          </Stack.Item>
          <Stack.Item>
            <Label variant={'accent'}>{tasks?.length || 0}문제</Label>
          </Stack.Item>
        </Stack>
      </Dialog.Header>
      <MutationFormik<TaskAssignDialog_taskAssignMutation>
        mutation={graphql`
          mutation TaskAssignDialog_taskAssignMutation($input: TaskAssignInput!) {
            taskAssign(input: $input) {
              id
              assigned
              assignedTo {
                id
                ...UserAvatarText_user
              }
              showProblem
              showLabeling
              showVideo
              showSolutionVideo
              ...TaskActionsMutationButtonStack_task
              ...TaskStatusLabel_task
            }
          }
        `}
        initialValues={{
          assignedTo: '',
          tasks,
        }}
        onSubmit={onSubmit}
        config={config}
        connections={connections}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <Suspense
                  fallback={
                    <View padding={2}>
                      <Spinner size={'small'} />
                    </View>
                  }
                >
                  {queryReference ? (
                    <PreloadedQueryRenderer<TaskAssignDialog_taskAssigneesQuery>
                      query={taskAssigneesForTaskAssignDialog}
                      queryReference={queryReference}
                    >
                      {({ taskAssignees }) => {
                        return (
                          <UserAvatarRadioGroupField
                            label={'작업자'}
                            name={'assignedTo'}
                            required
                            variables={{ filters: { team_In: ['contents'] } }}
                            renderOptionWrapper={(children, { id }) => (
                              <Stack key={id} gapX={2}>
                                <Stack.Item>
                                  <View sx={{ whiteSpace: 'nowrap' }}>{children}</View>
                                </Stack.Item>
                                <Stack.Item>
                                  <Grid gapX={1} gapY={1} wrap={true}>
                                    {taskAssignees.map((assignee) => {
                                      if (assignee.user.id === id)
                                        return (
                                          <Grid.Unit key={assignee.status} size={'min'}>
                                            <Label variant={'default'}>
                                              <EnumPair typename={'TaskStatusEnum'}>{assignee.status}</EnumPair>
                                            </Label>
                                          </Grid.Unit>
                                        );
                                      return null;
                                    })}
                                  </Grid>
                                </Stack.Item>
                              </Stack>
                            )}
                          />
                        );
                      }}
                    </PreloadedQueryRenderer>
                  ) : (
                    <UserAvatarRadioGroupField
                      label={'작업자'}
                      name={'assignedTo'}
                      required
                      variables={{ filters: { team_In: ['contents'] } }}
                    />
                  )}
                </Suspense>
                <Suspense
                  fallback={
                    <View padding={2}>
                      <Spinner size={'small'} />
                    </View>
                  }
                >
                  <EnumPairSelectField
                    label={'상태 변경'}
                    name={'status'}
                    placeholder={'상태 변경 없음'}
                    typename={'TaskStatusEnum'}
                    caption={
                      '현재 상태에서 변경이 필요한 경우, 이전 상태로만 변경할 수 있어요\ne.g. 출시 완료 → 검수 (O) / 타이핑 → 문제창작 (X)'
                    }
                  />
                </Suspense>
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} size={'large'} onClick={() => handleSubmit()}>
                배정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default TaskAssignDialog;
export type { Props as TaskAssignDialogProps };
