import { CheckIcon, LinkExternalIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ProductDiscountUpdateDialog_productDiscount$key } from '../../../relay/__generated__/ProductDiscountUpdateDialog_productDiscount.graphql';
import { ProductDiscountUpdateDialog_productDiscountUpdateMutation } from '../../../relay/__generated__/ProductDiscountUpdateDialog_productDiscountUpdateMutation.graphql';
import Button from '../../core/Button';
import ColorPickField from '../../core/ColorPickField';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import Link from '../../core/Link';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';
import UploadField from '../../core/UploadField';

const ProductDiscountUpdateDialog_productDiscount = graphql`
  fragment ProductDiscountUpdateDialog_productDiscount on ProductDiscount {
    id
    product {
      id
      title
    }
    webImage {
      key
      objectUrl
      size
    }
    mobileImage {
      key
      objectUrl
      size
    }
    backgroundColor
  }
`;

type Props = {
  productDiscount: ProductDiscountUpdateDialog_productDiscount$key;
} & DialogProps &
  Pick<
    MutationFormikProps<ProductDiscountUpdateDialog_productDiscountUpdateMutation>,
    'config' | 'connections' | 'onSubmit'
  >;

const DISCOUNT_IMAGE_GUIDE_LINK =
  'https://www.figma.com/file/y4t45QVC6kj4DLcogUECHn/%EC%88%98%ED%95%99%EB%8C%80%EC%99%95-APP?type=design&node-id=28564%3A79925&t=GfRSj0aFeGvKE84M-1';

const ProductDiscountUpdateDialog = ({ productDiscount, config, connections, onSubmit, ...props }: Props) => {
  const { id, product, webImage, mobileImage, backgroundColor } = useFragment(
    ProductDiscountUpdateDialog_productDiscount,
    productDiscount,
  );

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Grid sx={{ paddingRight: 4, alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{product.title}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Link href={DISCOUNT_IMAGE_GUIDE_LINK} target={'_blank'}>
              <Stack gapX={1}>
                <Stack.Item>
                  <StyledOcticon icon={LinkExternalIcon} size={16} />
                </Stack.Item>
                <Stack.Item>
                  <Text fontSize={0}>이벤트 이미지 제작 가이드</Text>
                </Stack.Item>
              </Stack>
            </Link>
          </Grid.Unit>
        </Grid>
      </Dialog.Header>
      <MutationFormik<ProductDiscountUpdateDialog_productDiscountUpdateMutation>
        mutation={graphql`
          mutation ProductDiscountUpdateDialog_productDiscountUpdateMutation($input: ProductDiscountUpdateInput!) {
            productDiscountUpdate(input: $input) {
              id
              ...ProductDiscountUpdateDialog_productDiscount
            }
          }
        `}
        initialValues={{
          id,
          mobileImage: {
            key: mobileImage?.key || '',
            size: mobileImage?.size || 0,
            objectUrl: mobileImage?.objectUrl || '',
          },
          webImage: {
            key: webImage?.key || '',
            size: webImage?.size || 0,
            objectUrl: webImage?.objectUrl || '',
          },
          backgroundColor: backgroundColor || '',
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ submitForm, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <Grid gapX={2}>
                  <Grid.Unit size={1 / 2}>
                    <UploadField
                      label={'모바일용 이미지 1'}
                      name={'mobileImage'}
                      accept={'image/png'}
                      required
                      placeholder={'이미지를 선택하세요'}
                      caption={'* 파일은 png를 업로드 해주세요.\n* 사이즈는 640x853으로 올려주세요.'}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={1 / 2}>
                    <UploadField
                      label={'웹용 이미지 1'}
                      name={'webImage'}
                      accept={'image/png'}
                      required
                      placeholder={'이미지를 선택하세요'}
                      caption={'* 파일은 png를 업로드 해주세요.\n* 사이즈는 1280x720으로 올려주세요.'}
                    />
                  </Grid.Unit>
                </Grid>
                <ColorPickField
                  label={'배경색'}
                  name={'backgroundColor'}
                  placeholder={'배경색 선택'}
                  required
                  caption={'* 이미지 배경에 사용할 컬러를 선택해 주세요.'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                size={'large'}
                leadingIcon={CheckIcon}
                disabled={!dirty}
                variant={'primary'}
                onClick={() => submitForm()}
              >
                업로드하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default ProductDiscountUpdateDialog;
