/**
 * @generated SignedSource<<8ca1795b949332116649e09eef768973>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormulaSetConnectionDataTable_formulaSetConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly modified: any;
      readonly modifiedBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly reviewedBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly sequence: number;
      readonly unitD: {
        readonly description: string | null;
        readonly unitATitle: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"FormulaSetStatusLabel_formulaSet">;
    };
  }>;
  readonly " $fragmentType": "FormulaSetConnectionDataTable_formulaSetConnection";
};
export type FormulaSetConnectionDataTable_formulaSetConnection$key = {
  readonly " $data"?: FormulaSetConnectionDataTable_formulaSetConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormulaSetConnectionDataTable_formulaSetConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormulaSetConnectionDataTable_formulaSetConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FormulaSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FormulaSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FormulaSetStatusLabel_formulaSet"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UnitD",
              "kind": "LinkedField",
              "name": "unitD",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "unitATitle",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "modifiedBy",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "reviewedBy",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modified",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FormulaSetConnection",
  "abstractKey": null
};
})();

(node as any).hash = "dd32b505a8722ef29a7d6adf979bfeb8";

export default node;
