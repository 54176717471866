/**
 * @generated SignedSource<<f067ee6ee434783daad5a9047746c3cf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ExamTypeEnum = "first_final" | "first_mid" | "second_final" | "second_mid" | "%future added value";
export type ExamYearEnum = "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "%future added value";
export type SchoolYearEnum = "high_1" | "high_2" | "high_3" | "middle_1" | "middle_2" | "middle_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceDescriptionList_schoolExamSource$data = {
  readonly assigned: any | null;
  readonly assignedTo: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  } | null;
  readonly examType: ExamTypeEnum;
  readonly examYear: ExamYearEnum;
  readonly school: {
    readonly id: string;
    readonly title: string;
  };
  readonly schoolYear: SchoolYearEnum;
  readonly " $fragmentType": "SchoolExamSourceDescriptionList_schoolExamSource";
};
export type SchoolExamSourceDescriptionList_schoolExamSource$key = {
  readonly " $data"?: SchoolExamSourceDescriptionList_schoolExamSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceDescriptionList_schoolExamSource">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceDescriptionList_schoolExamSource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "School",
      "kind": "LinkedField",
      "name": "school",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schoolYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "assignedTo",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assigned",
      "storageKey": null
    }
  ],
  "type": "SchoolExamSource",
  "abstractKey": null
};
})();

(node as any).hash = "e985385d76e52fb3c7a20f03ff765c91";

export default node;
