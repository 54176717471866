import { isMobilePhoneNumber } from '@teamturing/validators';
import { FieldConfig, useField } from 'formik';
import { ComponentProps } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatMobilePhoneNumber, parseFormattedPhoneNumber } from '../../../utils/phoneNumber';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import TextInput, { TextInputProps } from '../TextInput';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
  type?: 'default' | 'phoneNumber';
  onChange?: (value: string) => void;
} & Omit<TextInputProps, 'type' | 'onChange'> &
  Pick<FieldConfig, 'validate'>;

const TelField = ({
  label,
  labelConfig,
  caption,
  disabled: propDisabled,
  required,
  name = '',
  value: propValue,
  onChange: propOnChange,
  type = 'default',
  validate,
  ...props
}: Props) => {
  const inputId = `tel_field_for_${label}`;

  const [{ value: baseValue, ...restFieldProps }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 입력해 주세요`;
      }
      if (type === 'phoneNumber' && !isMobilePhoneNumber(value)) {
        return '01로 시작하는 숫자 11자를 입력해 주세요';
      }
    },
  });

  const formatter = type === 'phoneNumber' ? formatMobilePhoneNumber : (value: string) => value;
  const parser = type === 'phoneNumber' ? parseFormattedPhoneNumber : (value: string) => value;

  const { isSubmitting } = useFormikContext();

  const value = !isNullable(propValue) ? propValue : baseValue;

  const handleChange: TextInputProps['onChange'] = (event) => {
    setError(undefined);

    const parsedValue = parser(event.target.value ?? '');

    if (propOnChange) propOnChange(parsedValue);
    else setValue(parsedValue);
  };

  const disabled = propDisabled || isSubmitting;

  return (
    <FormControl disabled={disabled} required={required}>
      <FormControl.Label {...labelConfig} htmlFor={inputId}>
        {label}
      </FormControl.Label>
      <TextInput
        {...restFieldProps}
        id={inputId}
        block
        type={'tel'}
        value={formatter(value)}
        onChange={handleChange}
        disabled={disabled}
        validationStatus={error ? 'error' : undefined}
        {...props}
      />
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default TelField;
