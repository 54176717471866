import { graphql, useFragment } from 'react-relay';

import {
  ProductStatusEnum,
  ProductStatusLabel_product$key,
} from '../../../relay/__generated__/ProductStatusLabel_product.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const ProductStatusLabel_product = graphql`
  fragment ProductStatusLabel_product on Product {
    status
  }
`;

type Props = {
  product: ProductStatusLabel_product$key;
} & Omit<LabelProps, 'variant'>;

const ProductStatusLabel = ({ product, ...props }: Props) => {
  const { status } = useFragment(ProductStatusLabel_product, product);

  const labelPropsByStatus: Record<ProductStatusEnum, LabelProps> = {
    'active': { variant: 'success' },
    'ongoing': { variant: 'accent' },
    'inactive': { variant: 'secondary' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'ProductStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default ProductStatusLabel;
