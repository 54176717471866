import { graphql } from 'react-relay';

import { UnitPaginator_query$key } from '../../../relay/__generated__/UnitPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const UnitPaginator_query = graphql`
  fragment UnitPaginator_query on Query
  @argumentDefinitions(
    filters: { type: UnitFilter }
    order: { type: UnitOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "UnitPaginatorRefreshQuery") {
    units(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "UnitPaginator_query_units") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<UnitPaginator_query$key>, 'fragment'>;

const UnitPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={UnitPaginator_query} {...props} />;

export default UnitPaginator;
