import { marked } from 'marked';

type Options = { options?: marked.MarkedOptions };

const useMarked = ({ options }: Options) => {
  if (options) {
    marked.setOptions(options);
  }

  marked.use({
    extensions: [
      {
        name: 'mention',
        level: 'inline',
        start(src) {
          return src.match(/\s*@/)?.index;
        },
        tokenizer(src) {
          const rule = /^@\w+/;
          const match = rule.exec(src);
          if (match) {
            const token = {
              type: 'mention',
              raw: match[0],
              text: match[0],
            };
            return token;
          }
        },
        renderer(token) {
          return `<mark>${token.text}</mark>`;
        },
      },
    ],
  });

  return { parse: (src: string) => (options?.async ? marked.parse(src, { async: true }) : marked.parse(src, {})) };
};

export default useMarked;
