import { getDateDistanceFromNow } from '@teamturing/date';

import { formatDate } from '../../../utils/date';
import Text, { TextProps } from '../Text';
import Tooltip, { TooltipProps } from '../Tooltip';

type Props = {
  ago?: boolean;
  format?: string;
  children: string;
  tooltipProps?: Pick<TooltipProps, 'direction'>;
} & TextProps;

const DateText = ({ ago = false, format = 'P a hh:mm', children: propChildren, tooltipProps, ...props }: Props) => {
  const children = ago
    ? getDateDistanceFromNow(propChildren, { includeYearPrefix: true })
    : formatDate(propChildren, format);
  return ago ? (
    <Tooltip {...tooltipProps} text={formatDate(propChildren, format)}>
      <Text {...props}>{children}</Text>
    </Tooltip>
  ) : (
    <Text {...props}>{children}</Text>
  );
};

export default DateText;
