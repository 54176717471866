/**
 * @generated SignedSource<<9015b31622ed317b86f4b7317537afaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskProblemAnswerTypeEnum = "objective" | "subjective" | "%future added value";
export type TaskProblemCreationTypeEnum = "first" | "original" | "second" | "third" | "%future added value";
export type TaskLabelingProblemUpdateInput = {
  aiFile?: Upload | null;
  answer: string;
  answerChoiceCount?: number | null;
  answerType: TaskProblemAnswerTypeEnum;
  creationType?: TaskProblemCreationTypeEnum | null;
  imageRequests?: ReadonlyArray<ImageRequestInput> | null;
  problem: EditorInput;
  solution: EditorInput;
  task: string;
  taskProblem: string;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type ImageRequestInput = {
  description: string;
  file: Upload;
};
export type EditorInput = {
  text: string;
  textHtml?: string | null;
};
export type TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation$variables = {
  input: TaskLabelingProblemUpdateInput;
};
export type TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation$data = {
  readonly taskLabelingProblemUpdate: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"TaskLabelingProblemUpdateDialog_task">;
  };
};
export type TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation = {
  response: TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation$data;
  variables: TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "objectUrl",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "size",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "taskLabelingProblemUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaskLabelingProblemUpdateDialog_task"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "taskLabelingProblemUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sequence",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskProblem",
            "kind": "LinkedField",
            "name": "problem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "problem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "solution",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "problemType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "creationType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "answer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "answerChoiceCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "answerType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Scrap",
            "kind": "LinkedField",
            "name": "scrap",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "problems",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "solutions",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskImageRequestConnection",
            "kind": "LinkedField",
            "name": "imageRequests",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskImageRequestEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskImageRequest",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v3/*: any*/),
                          (v6/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "aiFile",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v5/*: any*/),
              (v3/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8855b953b5e9dc787f666a6fae5cf523",
    "id": null,
    "metadata": {},
    "name": "TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation(\n  $input: TaskLabelingProblemUpdateInput!\n) {\n  taskLabelingProblemUpdate(input: $input) {\n    id\n    ...TaskLabelingProblemUpdateDialog_task\n  }\n}\n\nfragment TaskLabelingProblemUpdateDialog_task on Task {\n  id\n  title\n  sequence\n  status\n  problem {\n    id\n    problem\n    solution\n    problemType\n    creationType\n    answer\n    answerChoiceCount\n    answerType\n  }\n  scrap {\n    id\n    problems {\n      id\n      objectUrl\n    }\n    solutions {\n      id\n      objectUrl\n    }\n  }\n  imageRequests {\n    edges {\n      node {\n        id\n        image {\n          key\n          objectUrl\n          size\n          id\n        }\n        description\n      }\n    }\n  }\n  aiFile {\n    id\n    key\n    objectUrl\n    size\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae5b3f25dd0819e1e19cd337481c0f1b";

export default node;
