/**
 * @generated SignedSource<<170789d1c2be2a087247e58c34bf5235>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type NotificationTypeEnum = "bill_completed" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type NotificationItem_notification$data = {
  readonly checked: any | null;
  readonly created: any;
  readonly description: string | null;
  readonly id: string;
  readonly notificationType: NotificationTypeEnum;
  readonly title: string;
  readonly " $fragmentType": "NotificationItem_notification";
};
export type NotificationItem_notification$key = {
  readonly " $data"?: NotificationItem_notification$data;
  readonly " $fragmentSpreads": FragmentRefs<"NotificationItem_notification">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationItem_notification",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notificationType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "checked",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    }
  ],
  "type": "Notification",
  "abstractKey": null
};

(node as any).hash = "25d57cd6c043cea27bb0973d6ee98118";

export default node;
