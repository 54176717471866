/**
 * @generated SignedSource<<91b42f279465481f5277f5ef0f0f19b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type QuizAnswerTypeEnum = "O" | "X" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuizConnectionDataTable_quizConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly answer: QuizAnswerTypeEnum;
      readonly created: any;
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly id: string;
      readonly modified: any;
      readonly sequence: number;
      readonly unitD: {
        readonly description: string | null;
      };
      readonly " $fragmentSpreads": FragmentRefs<"QuizStatusLabel_quiz">;
    };
  }>;
  readonly " $fragmentType": "QuizConnectionDataTable_quizConnection";
};
export type QuizConnectionDataTable_quizConnection$key = {
  readonly " $data"?: QuizConnectionDataTable_quizConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizConnectionDataTable_quizConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizConnectionDataTable_quizConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuizEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Quiz",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuizStatusLabel_quiz"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UnitD",
              "kind": "LinkedField",
              "name": "unitD",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answer",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modified",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuizConnection",
  "abstractKey": null
};
})();

(node as any).hash = "bf2209f062dd8c0ec974463f764c6a35";

export default node;
