import { differenceInDays, startOfDay } from 'date-fns';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  UserConnectionDataTable_UserConnection$data,
  UserConnectionDataTable_UserConnection$key,
} from '../../../relay/__generated__/UserConnectionDataTable_UserConnection.graphql';
import CopyableText from '../../core/CopyableText';
import DataTable, { DataTableProps } from '../../core/DataTable';
import Label from '../../core/Label';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import Tooltip from '../../core/Tooltip';
import UserAvatarText from '../../user/UserAvatarText';

const UserConnectionDataTable_UserConnection = graphql`
  fragment UserConnectionDataTable_UserConnection on UserConnection {
    edges {
      node {
        id
        ...UserAvatarText_user
        group {
          id
          title
        }
        #        excludedPermissions {
        #          id
        #          title
        #        }
        email
        slackId
        joinedAt
      }
    }
  }
`;

type Props = {
  userConnection: UserConnectionDataTable_UserConnection$key;
} & Omit<DataTableProps<UserConnectionDataTable_UserConnection$data['edges'][number]['node']>, 'rows' | 'columns'>;

const UserConnectionDataTable = ({ userConnection, ...props }: Props) => {
  const { edges } = useFragment(UserConnectionDataTable_UserConnection, userConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'user',
          title: '팀원',
          renderValue: (user) => (
            <Stack gapX={2}>
              <Stack.Item>
                <UserAvatarText user={user} />
              </Stack.Item>
              {user.group ? (
                <Stack.Item>
                  <Label>{user.group.title}</Label>
                </Stack.Item>
              ) : null}
            </Stack>
          ),
          width: 200,
        },
        {
          field: 'email',
          title: '이메일',
          renderValue: ({ email }) =>
            email ? <CopyableText fontSize={1}>{email}</CopyableText> : <Text fontSize={1}>-</Text>,
          width: 200,
        },
        {
          field: 'slackId',
          title: '슬랙 아이디',
          renderValue: ({ slackId }) =>
            slackId ? <CopyableText fontSize={1}>{slackId}</CopyableText> : <Text fontSize={1}>{'-'}</Text>,
          width: 150,
        },
        {
          field: 'jointAt',
          title: '입사일',
          renderValue: ({ joinedAt }) =>
            joinedAt ? (
              <Tooltip text={`${differenceInDays(Date.now(), startOfDay(new Date(joinedAt))) + 1}일 째`}>
                <Text fontSize={1}>{joinedAt}</Text>
              </Tooltip>
            ) : (
              <Text fontSize={1}>-</Text>
            ),
          width: 90,
        },
        // {
        //   field: 'excludedPermission',
        //   title: '제한된 권한',
        //   renderValue: ({ excludedPermissions }) =>
        //     isNullable(excludedPermissions) || excludedPermissions.length === 0
        //       ? '-'
        //       : excludedPermissions.map(({ title }, index) => (
        //           <React.Fragment key={title}>
        //             {index !== 0 ? ', ' : ''}
        //             <EnumPair typename={'PermissionEnum'}>{title}</EnumPair>
        //           </React.Fragment>
        //         )),
        //   width: 848,
        // },
      ]}
      {...props}
    />
  );
};

export default UserConnectionDataTable;
