/**
 * @generated SignedSource<<405ae09bc3f80e11c63049fe4f57f921>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RaffleActionEnum = "raffle_delete" | "raffle_update" | "%future added value";
export type RaffleId_raffleQuery$variables = {
  id: string;
};
export type RaffleId_raffleQuery$data = {
  readonly raffle: {
    readonly actions: ReadonlyArray<RaffleActionEnum>;
    readonly created: any;
    readonly createdBy: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    };
    readonly description: string | null;
    readonly endAt: any;
    readonly id: string;
    readonly isPremium: boolean;
    readonly modified: any;
    readonly modifiedBy: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly order: number;
    readonly raffleStuff: {
      readonly id: string;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"RaffleStuffPreviewItem_raffleStuff">;
    };
    readonly requiredPoint: number;
    readonly showEndAt: any;
    readonly startAt: any;
    readonly title: string;
    readonly winnerCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"RaffleStatusLabel_raffle" | "RaffleUpdateDialog_raffle">;
  };
};
export type RaffleId_raffleQuery = {
  response: RaffleId_raffleQuery$data;
  variables: RaffleId_raffleQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isPremium",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "order",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiredPoint",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "winnerCount",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showEndAt",
  "storageKey": null
},
v13 = [
  (v2/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
},
v16 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "picture",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeleted",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RaffleId_raffleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Raffle",
        "kind": "LinkedField",
        "name": "raffle",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RaffleStuff",
            "kind": "LinkedField",
            "name": "raffleStuff",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "RaffleStuffPreviewItem_raffleStuff"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RaffleStatusLabel_raffle"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          },
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          },
          (v15/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RaffleUpdateDialog_raffle"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RaffleId_raffleQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Raffle",
        "kind": "LinkedField",
        "name": "raffle",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RaffleStuff",
            "kind": "LinkedField",
            "name": "raffleStuff",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UploadResult",
                "kind": "LinkedField",
                "name": "colorThumbnail",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "objectUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundColor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "172d02989e094adc00145673c978e993",
    "id": null,
    "metadata": {},
    "name": "RaffleId_raffleQuery",
    "operationKind": "query",
    "text": "query RaffleId_raffleQuery(\n  $id: ID!\n) {\n  raffle(id: $id) {\n    id\n    title\n    actions\n    description\n    raffleStuff {\n      id\n      title\n      ...RaffleStuffPreviewItem_raffleStuff\n    }\n    isPremium\n    order\n    requiredPoint\n    winnerCount\n    startAt\n    endAt\n    showEndAt\n    ...RaffleStatusLabel_raffle\n    createdBy {\n      id\n      ...UserAvatarText_user\n    }\n    created\n    modifiedBy {\n      id\n      ...UserAvatarText_user\n    }\n    modified\n    ...RaffleUpdateDialog_raffle\n  }\n}\n\nfragment RaffleStatusLabel_raffle on Raffle {\n  status\n}\n\nfragment RaffleStuffPreviewItem_raffleStuff on RaffleStuff {\n  colorThumbnail {\n    objectUrl\n  }\n  backgroundColor\n}\n\nfragment RaffleUpdateDialog_raffle on Raffle {\n  id\n  raffleStuff {\n    id\n  }\n  title\n  description\n  isPremium\n  requiredPoint\n  winnerCount\n  startAt\n  endAt\n  showEndAt\n  order\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "e1442e7d2933ea65877eaf2b579c8775";

export default node;
