/**
 * @generated SignedSource<<92741d14dde5eab61a9a149da7d90b63>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TypingAutomationPreviewInput = {
  description: string;
  task: string;
};
export type TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery$variables = {
  input: TypingAutomationPreviewInput;
};
export type TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery$data = {
  readonly typingAutomationPreview: {
    readonly problem: string;
    readonly solution: string;
  } | null;
};
export type TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery = {
  response: TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery$data;
  variables: TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TypingAutomationPreview",
    "kind": "LinkedField",
    "name": "typingAutomationPreview",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problem",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "solution",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e924c28576fad5bb5ee8e53c0701ce0e",
    "id": null,
    "metadata": {},
    "name": "TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery",
    "operationKind": "query",
    "text": "query TaskTypingAutomationHistoryCreateDialog_typingAutomationPreviewQuery(\n  $input: TypingAutomationPreviewInput!\n) {\n  typingAutomationPreview(input: $input) {\n    problem\n    solution\n  }\n}\n"
  }
};
})();

(node as any).hash = "f835df16cb2e33b0a47f94172d4de358";

export default node;
