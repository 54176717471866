/**
 * @generated SignedSource<<c7f19b22d61d64dfec84d69c38732414>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TaskStatusEnum = "deactivated" | "error_report" | "first_labeling" | "image_creation" | "problem_creation" | "problem_typing" | "published" | "review" | "second_labeling" | "%future added value";
export type TaskAssignDialog_taskAssigneesQuery$variables = {
  task: string;
};
export type TaskAssignDialog_taskAssigneesQuery$data = {
  readonly taskAssignees: ReadonlyArray<{
    readonly status: TaskStatusEnum;
    readonly user: {
      readonly id: string;
    };
  }>;
};
export type TaskAssignDialog_taskAssigneesQuery = {
  response: TaskAssignDialog_taskAssigneesQuery$data;
  variables: TaskAssignDialog_taskAssigneesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "task"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "task",
        "variableName": "task"
      }
    ],
    "concreteType": "TaskAssignee",
    "kind": "LinkedField",
    "name": "taskAssignees",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskAssignDialog_taskAssigneesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskAssignDialog_taskAssigneesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3e821b81cd8a5f75111cb9d77c941d9c",
    "id": null,
    "metadata": {},
    "name": "TaskAssignDialog_taskAssigneesQuery",
    "operationKind": "query",
    "text": "query TaskAssignDialog_taskAssigneesQuery(\n  $task: ID!\n) {\n  taskAssignees(task: $task) {\n    status\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "18c51294dcb7ffa5e5ed1dc5cf39fff0";

export default node;
