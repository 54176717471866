import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  WorkbookTaskConnectionDataTable_taskConnection$data,
  WorkbookTaskConnectionDataTable_taskConnection$key,
} from '../../../relay/__generated__/WorkbookTaskConnectionDataTable_taskConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import Text from '../../core/Text';

const WorkbookTaskConnectionDataTable_taskConnection = graphql`
  fragment WorkbookTaskConnectionDataTable_taskConnection on TaskConnection {
    edges {
      node {
        id
        sequence
        unitD {
          unitATitle
          unitDTitle: title
        }
        difficulty
      }
    }
  }
`;

type Props = {
  taskConnection: WorkbookTaskConnectionDataTable_taskConnection$key;
} & Omit<
  DataTableProps<WorkbookTaskConnectionDataTable_taskConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const WorkbookTaskConnectionDataTable = ({ taskConnection, ...props }: Props) => {
  const { edges } = useFragment(WorkbookTaskConnectionDataTable_taskConnection, taskConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'order',
          renderValue: (_, index) => <Text fontSize={1}>{index + 1}</Text>,
          title: '',
          width: 48,
        },
        {
          field: 'sequence',
          renderValue: ({ sequence }) => <Text fontSize={1}>{sequence}</Text>,
          title: 'ID',
          width: 80,
        },
        {
          field: 'unitD.unitATitle',
          renderValue: ({ unitD }) => <Text fontSize={1}>{unitD?.unitATitle || '-'}</Text>,
          title: 'Unit A',
          width: 80,
        },
        {
          field: 'unitD.unitDTitle',
          renderValue: ({ unitD }) => <Text fontSize={1}>{unitD?.unitDTitle || '-'}</Text>,
          title: 'Unit D',
          width: 392,
        },
        {
          field: 'difficulty',
          renderValue: ({ difficulty }) => <Text fontSize={1}>{difficulty}</Text>,
          title: 'Rating',
          width: 80,
        },
      ]}
      {...props}
    />
  );
};

export default WorkbookTaskConnectionDataTable;
