import Hls, { HlsConfig } from 'hls.js';
import React from 'react';
import { useEffect, RefObject, createRef } from 'react';

type Props = {
  hlsConfig?: HlsConfig;
  playerRef?: RefObject<HTMLVideoElement>;
} & React.VideoHTMLAttributes<HTMLVideoElement>;

const Video = ({ src, autoPlay, hlsConfig, playerRef = createRef<HTMLVideoElement>(), ...props }: Props) => {
  useEffect(() => {
    let hls: Hls;

    function _initPlayer() {
      if (hls != null) {
        hls.destroy();
      }

      const newHls = new Hls({
        enableWorker: false,
        ...hlsConfig,
      });

      if (playerRef.current != null) {
        newHls.attachMedia(playerRef.current);
      }

      newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
        newHls.loadSource(src || '');

        newHls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (autoPlay) {
            playerRef?.current?.play().catch(() => console.log('자동 재생이 불가능합니다.'));
          }
        });
      });

      newHls.on(Hls.Events.ERROR, function (_, data) {
        if (data.fatal) {
          switch (data.type) {
            case Hls.ErrorTypes.NETWORK_ERROR:
              newHls.startLoad();
              break;
            case Hls.ErrorTypes.MEDIA_ERROR:
              newHls.recoverMediaError();
              break;
            default:
              _initPlayer();
              break;
          }
        }
      });

      hls = newHls;
    }

    if (Hls.isSupported()) {
      _initPlayer();
    }

    return () => {
      if (hls != null) {
        hls.destroy();
      }
    };
  }, [autoPlay, hlsConfig, playerRef, src]);

  return (
    <video ref={playerRef} controls width={'100%'} autoPlay={autoPlay} {...props}>
      <source src={src} />
    </video>
  );
};

export default React.memo(Video);
