import { graphql } from 'react-relay';

import { useFilterSearchPairsQuery_filterSearchPairsQuery } from '../../relay/__generated__/useFilterSearchPairsQuery_filterSearchPairsQuery.graphql';
import useLazyLoadQuery from '../useLazyLoadQuery';

const filterSearchPairsForUseFilterSearchPairsQuery = graphql`
  query useFilterSearchPairsQuery_filterSearchPairsQuery($typename: String!) {
    filterSearchPairs(typename: $typename) {
      key
      value
    }
  }
`;

const useFilterSearchPairsQuery = (typename: string) => {
  const [{ filterSearchPairs }] = useLazyLoadQuery<useFilterSearchPairsQuery_filterSearchPairsQuery>(
    filterSearchPairsForUseFilterSearchPairsQuery,
    {
      typename,
    },
  );
  return filterSearchPairs;
};

export default useFilterSearchPairsQuery;
