/**
 * @generated SignedSource<<7a986e8a6ad970f84f6bb3bc43b1a130>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookTypeEnum = "normal" | "school" | "%future added value";
export type BookUpdateInput = {
  coverPdf?: Upload | null;
  description?: string | null;
  edition?: string | null;
  id: string;
  problemPdf?: Upload | null;
  publisher?: string | null;
  solutionPdf?: Upload | null;
  title?: string | null;
  type?: BookTypeEnum | null;
  unitAs?: ReadonlyArray<string> | null;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type BookUpdateDialog_bookUpdateMutation$variables = {
  input: BookUpdateInput;
};
export type BookUpdateDialog_bookUpdateMutation$data = {
  readonly bookUpdate: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BookUpdateDialog_book">;
  };
};
export type BookUpdateDialog_bookUpdateMutation = {
  response: BookUpdateDialog_bookUpdateMutation$data;
  variables: BookUpdateDialog_bookUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookUpdateDialog_bookUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookUpdateDialog_book"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookUpdateDialog_bookUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitA",
            "kind": "LinkedField",
            "name": "unitAs",
            "plural": true,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publisher",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "edition",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "coverPdf",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "problemPdf",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "solutionPdf",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "190747d534ad7ba458618e7f913ca71b",
    "id": null,
    "metadata": {},
    "name": "BookUpdateDialog_bookUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation BookUpdateDialog_bookUpdateMutation(\n  $input: BookUpdateInput!\n) {\n  bookUpdate(input: $input) {\n    id\n    ...BookUpdateDialog_book\n  }\n}\n\nfragment BookUpdateDialog_book on Book {\n  id\n  type\n  title\n  unitAs {\n    id\n  }\n  publisher\n  edition\n  coverPdf {\n    key\n    objectUrl\n    id\n  }\n  problemPdf {\n    key\n    objectUrl\n    id\n  }\n  solutionPdf {\n    key\n    objectUrl\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "626f7638a31a9fb2329189af5fa89e66";

export default node;
