import { DotFillIcon, GrabberIcon, TrashIcon } from '@primer/octicons-react';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { graphql, useFragment, useMutation, UseMutationConfig } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { WorkbookTasksReorderDialog_workbook$key } from '../../../relay/__generated__/WorkbookTasksReorderDialog_workbook.graphql';
import { WorkbookTasksReorderDialog_workbookTasksDeleteMutation } from '../../../relay/__generated__/WorkbookTasksReorderDialog_workbookTasksDeleteMutation.graphql';
import { WorkbookTasksReorderDialog_workbookTasksReorderMutation } from '../../../relay/__generated__/WorkbookTasksReorderDialog_workbookTasksReorderMutation.graphql';
import { isNullable } from '../../../utils/is';
import Card from '../../core/Card';
import DescriptionList from '../../core/DescriptionList';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import MutationConfirmIconButton from '../../core/MutationConfirmIconButton';
import ScrollContainer from '../../core/ScrollContainer';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';
import View from '../../core/View';
import TaskSequenceItem from '../../task/TaskSequenceItem';

const WorkbookTasksReorderDialog_workbook = graphql`
  fragment WorkbookTasksReorderDialog_workbook on Workbook {
    id
    actions
    tasks {
      totalCount
      edges {
        node {
          id
          sequence
          difficulty
          ...TaskSequenceItem_task
          problem {
            problem
            solution
          }
          unitD {
            id
            description
            unitATitle
            unitBTitle
            unitCTitle
          }
        }
      }
    }
  }
`;

type Props = {
  workbook: WorkbookTasksReorderDialog_workbook$key;
  config?: Omit<UseMutationConfig<WorkbookTasksReorderDialog_workbookTasksReorderMutation>, 'variables'>;
} & DialogProps;

const WorkbookTasksReorderDialog = ({ workbook, config, ...props }: Props) => {
  const { id, tasks, actions } = useFragment(WorkbookTasksReorderDialog_workbook, workbook);
  const { toast } = useToast();

  const canReorder = actions.includes('workbook_tasks_reorder');

  const canDelete = actions.includes('workbook_tasks_delete');

  const [mutate, isLoadingMutation] = useMutation<WorkbookTasksReorderDialog_workbookTasksReorderMutation>(graphql`
    mutation WorkbookTasksReorderDialog_workbookTasksReorderMutation($input: WorkbookTasksReorderInput!) {
      workbookTasksReorder(input: $input) {
        id
        modifiedBy {
          ...UserAvatarText_user
        }
        modified
        ...WorkbookStatusLabel_workbook
        ...WorkbookTasksReorderDialog_workbook
      }
    }
  `);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>문제 상세 보기</Text>
      </Dialog.Header>
      <Dialog.Body sx={{ padding: 0, overflowY: 'hidden' }}>
        <Grid sx={{ height: '100%' }}>
          <Grid.Unit
            size={1 / 5}
            sx={{
              height: '100%',
              backgroundColor: 'canvas.inset',
              paddingX: 3,
              paddingY: 5,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack gapX={1}>
              <Stack.Item>
                <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>문제</Text>
              </Stack.Item>
              <Stack.Item>
                <Text sx={{ fontSize: 2, color: 'fg.subtle', fontWeight: 'bold' }}>{tasks.totalCount}</Text>
              </Stack.Item>
            </Stack>
            <View sx={{ marginTop: 3, overflowY: 'hidden', flex: 1, position: 'relative' }}>
              <ScrollContainer height={'100%'}>
                <DragDropContext
                  onDragEnd={({ draggableId, destination }) => {
                    if (isNullable(destination)) return;

                    const taskIds = tasks.edges.map(({ node }) => node.id);

                    const srcIndex = taskIds.findIndex((id) => id === draggableId);
                    const destIndex = destination.index;
                    if (srcIndex === destIndex) return;

                    const reorderedTask = taskIds.splice(srcIndex, 1);
                    taskIds.splice(destIndex, 0, reorderedTask[0]);
                    mutate({
                      ...config,
                      variables: { input: { id, taskIds } },
                    });
                  }}
                >
                  <Droppable droppableId={'task-reorder-list'} isDropDisabled={isLoadingMutation || !canReorder}>
                    {(provided) => (
                      <View ref={provided.innerRef} {...provided.droppableProps}>
                        <ItemList
                          items={tasks.edges}
                          renderItem={({ node }) => (
                            <Grid sx={{ alignItems: 'center' }}>
                              <Grid.Unit size={[1, 1, 1, 'max']}>
                                <TaskSequenceItem task={node} />
                              </Grid.Unit>
                              <Grid.Unit
                                size={[1, 1, 1, 'min']}
                                sx={{ display: 'block', textAlign: ['left', 'left', 'left', 'right'], lineHeight: 1 }}
                              >
                                <Text
                                  fontSize={0}
                                  color={isLoadingMutation ? 'neutral.muted' : 'fg.subtle'}
                                  whiteSpace={'pre-wrap'}
                                >
                                  {node.unitD?.description ? `${node.unitD.description}\n` : ''}
                                  {`Rating ${node.difficulty}`}
                                </Text>
                              </Grid.Unit>
                            </Grid>
                          )}
                          renderItemWrapper={(children, { node }, index) => (
                            <Draggable
                              key={node.id}
                              draggableId={node.id}
                              index={index}
                              isDragDisabled={isLoadingMutation || !canReorder}
                            >
                              {(provided, snapshot) => (
                                <View
                                  ref={provided.innerRef}
                                  {...provided.dragHandleProps}
                                  {...provided.draggableProps}
                                  sx={{
                                    paddingY: 2,
                                    paddingX: 2,
                                    borderRadius: 2,
                                    ...(canReorder && !isLoadingMutation
                                      ? { ':hover': { backgroundColor: 'accent.subtle' } }
                                      : null),
                                    ...(isLoadingMutation ? { color: 'neutral.muted' } : null),

                                    // dialog의 marginTop 보정
                                    ...(snapshot.isDragging || snapshot.isDropAnimating
                                      ? { marginTop: '-32px', backgroundColor: 'accent.subtle', opacity: 0.5 }
                                      : null),
                                  }}
                                >
                                  <Grid gapX={2} sx={{ alignItems: 'center' }} wrap={false}>
                                    <Grid.Unit size={'min'}>
                                      {canReorder ? (
                                        <StyledOcticon
                                          icon={GrabberIcon}
                                          color={isLoadingMutation ? 'neutral.muted' : 'fg.muted'}
                                        />
                                      ) : (
                                        <DotFillIcon size={12} verticalAlign={'middle'} />
                                      )}
                                    </Grid.Unit>
                                    <Grid.Unit size={'max'}>{children}</Grid.Unit>
                                    {canDelete ? (
                                      <Grid.Unit size={'min'}>
                                        <MutationConfirmIconButton<WorkbookTasksReorderDialog_workbookTasksDeleteMutation>
                                          mutation={graphql`
                                            mutation WorkbookTasksReorderDialog_workbookTasksDeleteMutation(
                                              $input: WorkbookTasksDeleteInput!
                                            ) {
                                              workbookTasksDelete(input: $input) {
                                                id
                                                ...WorkbookTasksReorderDialog_workbook
                                              }
                                            }
                                          `}
                                          input={{ id, taskIds: [node.id] }}
                                          aria-label={'삭제'}
                                          icon={TrashIcon}
                                          variant={'plain'}
                                          size={'small'}
                                          disabled={
                                            snapshot.isDragging || snapshot.isDropAnimating || isLoadingMutation
                                          }
                                          message={'삭제한 문제는 되돌릴 수 없어요. 정말 삭제할까요?'}
                                          config={{
                                            onCompleted: () => {
                                              toast('문제가 삭제됐어요', 'success');
                                            },
                                          }}
                                        />
                                      </Grid.Unit>
                                    ) : null}
                                  </Grid>
                                </View>
                              )}
                            </Draggable>
                          )}
                          emptyState={
                            <Card sx={{ paddingY: 5, paddingX: 3 }}>
                              <EmptyState title={'검색 결과가 없어요'} />
                            </Card>
                          }
                        />
                        {provided.placeholder}
                      </View>
                    )}
                  </Droppable>
                </DragDropContext>
              </ScrollContainer>
            </View>
          </Grid.Unit>
          <Grid.Unit size={4 / 5} sx={{ padding: 5, overflowY: 'auto', height: '100%' }}>
            <ItemList
              items={tasks?.edges.map(({ node }) => node) || []}
              renderItem={(task) => {
                const { sequence, problem } = task;
                return (
                  <>
                    <Text fontSize={2} fontWeight={'bold'}>
                      {sequence}
                    </Text>
                    <View sx={{ marginTop: 4 }}>
                      <Grid gapX={2} gapY={3}>
                        <Grid.Unit size={1 / 2}>
                          <Text fontSize={1} color={'fg.subtle'}>
                            문제
                          </Text>
                          <Card sx={{ overflow: 'auto', height: '30vh', marginTop: 1 }}>
                            <Katex>{problem?.problem || ''}</Katex>
                          </Card>
                        </Grid.Unit>
                        <Grid.Unit size={1 / 2}>
                          <Text fontSize={1} color={'fg.subtle'}>
                            해설
                          </Text>
                          <Card sx={{ overflow: 'auto', height: '30vh', marginTop: 1 }}>
                            <Katex>{problem?.solution || ''}</Katex>
                          </Card>
                        </Grid.Unit>
                        <Grid.Unit size={1}>
                          <Card sx={{ padding: 3 }}>
                            <DescriptionList
                              item={task}
                              itemDescriptions={{
                                unitD: {
                                  title: 'Last Unit D',
                                  renderValue: ({ unitD }) =>
                                    unitD ? (
                                      <View key={id}>
                                        {[unitD.unitATitle, unitD.unitBTitle, unitD.unitCTitle, unitD.description].join(
                                          ' > ',
                                        )}
                                      </View>
                                    ) : undefined,
                                },
                                rating: {
                                  title: 'Rating',
                                  renderValue: ({ difficulty }) => <Text fontSize={1}>{difficulty}</Text>,
                                },
                              }}
                              picks={['unitD', 'rating']}
                              titleUnitSize={1 / 7}
                              descriptionUnitSize={6 / 7}
                            />
                          </Card>
                        </Grid.Unit>
                      </Grid>
                    </View>
                  </>
                );
              }}
              renderItemWrapper={(children, { id }, index) => (
                <View key={id} sx={{ marginTop: index !== 0 ? 5 : 0 }}>
                  {children}
                </View>
              )}
            />
          </Grid.Unit>
        </Grid>
      </Dialog.Body>
    </Dialog>
  );
};

export default WorkbookTasksReorderDialog;
