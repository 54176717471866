import { CheckIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import { TaskCreateDialog_taskCreateMutation } from '../../../relay/__generated__/TaskCreateDialog_taskCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import UnitSelectField from '../../unit/UnitSelectField';

type Props = {} & DialogProps &
  Pick<MutationFormikProps<TaskCreateDialog_taskCreateMutation>, 'connections' | 'config' | 'onSubmit'>;

const TaskCreateDialog = ({ connections, config, onSubmit, ...props }: Props) => (
  <Dialog {...props}>
    <Dialog.Header>
      <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>생성하기</Text>
    </Dialog.Header>
    <MutationFormik<TaskCreateDialog_taskCreateMutation>
      mutation={graphql`
        mutation TaskCreateDialog_taskCreateMutation($input: TaskCreateInput!) {
          taskCreate(input: $input) {
            id
          }
        }
      `}
      initialValues={{
        unit: '',
        title: '',
      }}
      connections={connections}
      config={config}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <>
          <Dialog.Body>
            <NonFieldError sx={{ marginBottom: 3 }} />
            <FormLayout>
              <Suspense>
                <UnitSelectField
                  label={'교육과정'}
                  name={'unit'}
                  variables={{ order: { order: 'ASC' } }}
                  required
                  placeholder={'교육과정 선택'}
                />
              </Suspense>
              <TextField name={'title'} label={'제목'} required placeholder={'제목 입력'} />
            </FormLayout>
          </Dialog.Body>
          <Dialog.Footer>
            <Button variant={'primary'} onClick={() => handleSubmit()} leadingIcon={CheckIcon}>
              생성하기
            </Button>
          </Dialog.Footer>
        </>
      )}
    </MutationFormik>
  </Dialog>
);

export default TaskCreateDialog;
