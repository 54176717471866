/**
 * @generated SignedSource<<e3f9edaa88c00aa45a1dfd127432a058>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RaffleCreateInput = {
  description?: string | null;
  endAt: any;
  isPremium: boolean;
  order: number;
  raffleStuff: NodeInput;
  requiredPoint: number;
  showEndAt: any;
  startAt: any;
  title: string;
  winnerCount: number;
};
export type NodeInput = {
  id: string;
};
export type RaffleCreateDialog_raffleCreateMutation$variables = {
  input: RaffleCreateInput;
};
export type RaffleCreateDialog_raffleCreateMutation$data = {
  readonly raffleCreate: {
    readonly id: string;
  };
};
export type RaffleCreateDialog_raffleCreateMutation = {
  response: RaffleCreateDialog_raffleCreateMutation$data;
  variables: RaffleCreateDialog_raffleCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Raffle",
    "kind": "LinkedField",
    "name": "raffleCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RaffleCreateDialog_raffleCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RaffleCreateDialog_raffleCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5da72fbc7cbfed9f98f045d969edbb67",
    "id": null,
    "metadata": {},
    "name": "RaffleCreateDialog_raffleCreateMutation",
    "operationKind": "mutation",
    "text": "mutation RaffleCreateDialog_raffleCreateMutation(\n  $input: RaffleCreateInput!\n) {\n  raffleCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ae6e08148ebfce09531b416fe06ba8f4";

export default node;
