import { GraphQLTaggedNode } from 'react-relay';
import { GraphQLSubscriptionConfig, OperationType, requestSubscription } from 'relay-runtime';

import useSubscription from '../../../hooks/useSubscription';

type Props<TSubscriptionPayload extends OperationType> = {
  subscription: GraphQLTaggedNode;
  children: (data: TSubscriptionPayload['response'] | null | undefined) => React.ReactNode;
  config?: Partial<Omit<GraphQLSubscriptionConfig<TSubscriptionPayload>, 'subscription'>>;
  requestSubscriptionFn?: typeof requestSubscription;
};

const SubscriptionRenderer = <TSubscriptionPayload extends OperationType>({
  subscription,
  config,
  children,
  requestSubscriptionFn,
}: Props<TSubscriptionPayload>) => {
  const { data } = useSubscription<TSubscriptionPayload>(
    { ...config, subscription, variables: config?.variables ?? {} },
    requestSubscriptionFn,
  );
  return children(data) as JSX.Element;
};

export default SubscriptionRenderer;
export type { Props as SubscriptionRendererProps };
