import { graphql } from 'react-relay';

import { UnitDPaginator_query$key } from '../../../relay/__generated__/UnitDPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const UnitDPaginator_query = graphql`
  fragment UnitDPaginator_query on Query
  @argumentDefinitions(
    filters: { type: UnitDFilter }
    order: { type: UnitDOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "UnitDPaginatorRefreshQuery") {
    unitDs(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "UnitDPaginator_query_unitDs") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          description
          ...UnitDItem_unitD
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<UnitDPaginator_query$key>, 'fragment'>;

const UnitDPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={UnitDPaginator_query} {...props} />;

export default UnitDPaginator;
