import { graphql, useFragment } from 'react-relay';

import { TaskSolutionVideoDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/TaskSolutionVideoDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const TaskSolutionVideoDifferenceCounterLabel_difference = graphql`
  fragment TaskSolutionVideoDifferenceCounterLabel_difference on Difference {
    ... on TaskSolutionVideoDifference {
      isPremium {
        before
        after
      }
      isRecommended {
        before
        after
      }
      taskVideoTitle {
        before
        after
      }
      thumbnailHorizontalUrl {
        before
        after
      }
      thumbnailVerticalUrl {
        before
        after
      }
    }
  }
`;

type Props = {
  difference: TaskSolutionVideoDifferenceCounterLabel_difference$key;
} & CounterLabelProps;

const TaskSolutionVideoDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { isPremium, isRecommended, taskVideoTitle, thumbnailHorizontalUrl, thumbnailVerticalUrl } = useFragment(
    TaskSolutionVideoDifferenceCounterLabel_difference,
    difference,
  );
  const differenceCount = [
    isPremium,
    isRecommended,
    taskVideoTitle,
    thumbnailHorizontalUrl,
    thumbnailVerticalUrl,
  ].filter((diff) => !isNullable(diff)).length;

  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default TaskSolutionVideoDifferenceCounterLabel;
