import useEnumPairsQuery from '../../../hooks/useEnumPairsQuery';
import RadioGroupField, { RadioGroupFieldProps } from '../RadioGroupField';

type Props = {
  typename: string;
} & Omit<RadioGroupFieldProps, 'options'>;

const EnumPairRadioGroupField = ({ typename, ...props }: Props) => {
  const enumPairs = useEnumPairsQuery(typename);
  return <RadioGroupField options={enumPairs.map(({ key, value }) => ({ id: key, text: value }))} {...props} />;
};

export default EnumPairRadioGroupField;
export type { Props as EnumPairRadioGroupFieldProps };
