import { graphql, useFragment } from 'react-relay';

import { BillStatusEnum, BillStatusLabel_bill$key } from '../../../relay/__generated__/BillStatusLabel_bill.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const BillStatusLabel_bill = graphql`
  fragment BillStatusLabel_bill on Bill {
    status
  }
`;

type Props = {
  bill: BillStatusLabel_bill$key;
} & Omit<LabelProps, 'variant'>;

const BillStatusLabel = ({ bill, ...props }: Props) => {
  const { status } = useFragment(BillStatusLabel_bill, bill);

  const labelPropsByStatus: Record<BillStatusEnum, LabelProps> = {
    'pending': { variant: 'secondary' },
    'completed': { variant: 'success' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'BillStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default BillStatusLabel;
