import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { graphql } from 'react-relay';

import CommentCreateMutationFormik from '../../components/comment/CommentCreateMutationFormik';
import CommentMutationActionItem from '../../components/comment/CommentMutationActionItem';
import CommentPaginator from '../../components/comment/CommentPaginator';
import Button from '../../components/core/Button';
import Card from '../../components/core/Card';
import DateText from '../../components/core/DateText';
import DialogHandler from '../../components/core/DialogHandler';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import Timeline from '../../components/core/Timeline';
import View from '../../components/core/View';
import DeliveryAddressDescriptionList from '../../components/deliveryAddress/DeliveryAddressDescriptionList';
import DeliveryOrderDeliveryOrderHistoryPaginator from '../../components/deliveryOrder/DeliveryOrderDeliveryOrderHistoryPaginator';
import DeliveryOrderHistoryDialog from '../../components/deliveryOrder/DeliveryOrderHistoryDialog';
import DeliveryOrderHistoryTimelineItem from '../../components/deliveryOrder/DeliveryOrderHistoryTimelineItem';
import DeliveryOrderMutationActionsButtonStack from '../../components/deliveryOrder/DeliveryOrderMutationActionsButtonStack';
import DeliveryOrderStatusLabel from '../../components/deliveryOrder/DeliveryOrderStatusLabel';
import DeliveryWaybillDescriptionList from '../../components/deliveryWaybill/DeliveryWaybillDescriptionList';
import UserAvatarText from '../../components/user/UserAvatarText';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import {
  Ordering,
  DeliveryOrderId_commentsQuery,
} from '../../relay/__generated__/DeliveryOrderId_commentsQuery.graphql';
import { DeliveryOrderId_deliveryOrderQuery } from '../../relay/__generated__/DeliveryOrderId_deliveryOrderQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { scrollToBottom } from '../../utils/scroll';
import { NextPage } from '../_app';

const deliveryOrderForDeliveryOrderId = graphql`
  query DeliveryOrderId_deliveryOrderQuery($id: ID!) {
    deliveryOrder(id: $id) {
      id
      title
      description
      stuff {
        id
        title
      }
      created
      createdBy {
        ...UserAvatarText_user
      }
      deliveryAddress {
        ...DeliveryAddressDescriptionList_deliveryAddress
      }
      deliveryWaybill {
        ...DeliveryWaybillDescriptionList_deliveryWaybill
      }
      ...DeliveryOrderStatusLabel_deliveryOrder
      ...DeliveryOrderMutationActionsButtonStack_deliveryOrder
      ...DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder
    }
  }
`;

const commentsForDeliveryOrderId = graphql`
  query DeliveryOrderId_commentsQuery($filters: CommentFilter, $order: CommentOrder) {
    ...CommentPaginator_query @arguments(filters: $filters, order: $order)
  }
`;

const DeliveryOrderId: NextPage = () => {
  const router = useRouter();
  const deliveryOrderId = router.query.deliveryOrderId as string;
  const [{ deliveryOrder }] = useLazyLoadQuery<DeliveryOrderId_deliveryOrderQuery>(deliveryOrderForDeliveryOrderId, {
    id: deliveryOrderId,
  });
  const { title, description, stuff, deliveryAddress, createdBy, created, deliveryWaybill } = deliveryOrder;

  const [comments] = useLazyLoadQuery<DeliveryOrderId_commentsQuery>(commentsForDeliveryOrderId, {
    filters: { nodeId_Exact: deliveryOrderId },
    order: { created: 'ASC' as Ordering },
  });
  const commentContainerRef = useRef<HTMLDivElement>(null);

  return (
    <View>
      <Head siteTitle={`배송 요청`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{title}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <DeliveryOrderMutationActionsButtonStack deliveryOrder={deliveryOrder} />
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <ItemList
              items={[
                { id: 'description', title: '설명', description: description || '-' },
                { id: 'stuff', title: '물품', description: stuff.title },
                {
                  id: 'deliveryAddress',
                  title: '수령자 정보',
                  description: (
                    <DeliveryAddressDescriptionList
                      deliveryAddress={deliveryAddress}
                      titleUnitSize={[1 / 3, 1 / 3, 1 / 3, 1 / 6]}
                      descriptionUnitSize={[2 / 3, 2 / 3, 2 / 3, 5 / 6]}
                    />
                  ),
                },
                {
                  id: 'deliveryWaybill',
                  title: '운송 정보',
                  description: deliveryWaybill ? (
                    <Grid gapX={1} gapY={2}>
                      <Grid.Unit size={[1, 1, 1, 1 / 2]}>
                        <DeliveryWaybillDescriptionList
                          deliveryWaybill={deliveryWaybill}
                          type={'default'}
                          titleUnitSize={1 / 3}
                          descriptionUnitSize={2 / 3}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={[1, 1, 1, 1 / 2]}>
                        <DeliveryWaybillDescriptionList
                          deliveryWaybill={deliveryWaybill}
                          type={'fee'}
                          titleUnitSize={1 / 3}
                          descriptionUnitSize={2 / 3}
                        />
                      </Grid.Unit>
                    </Grid>
                  ) : (
                    <EmptyState title={'가져온 운송 정보가 없어요'} description={'운송 정보를 입력해주세요'} />
                  ),
                },
              ]}
              renderItem={({ title, description }) => (
                <>
                  <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                  <View
                    sx={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: 'border.default',
                      borderRadius: 2,
                      marginTop: 2,
                      padding: 3,
                    }}
                  >
                    <Text sx={{ fontSize: 1 }}>{description}</Text>
                  </View>
                </>
              )}
              renderItemWrapper={(children, { id }, index) => (
                <View key={id} sx={{ marginTop: index === 0 ? 0 : 5 }}>
                  {children}
                </View>
              )}
            />
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <ItemList
              items={[
                {
                  id: 'status',
                  title: '상태',
                  description: <DeliveryOrderStatusLabel deliveryOrder={deliveryOrder} />,
                },
                { id: 'createdBy', title: '요청자', description: <UserAvatarText user={createdBy} /> },
                { id: 'created', title: '요청일', description: <DateText>{created}</DateText> },
              ]}
              renderItem={({ title, description }) => (
                <Grid gapX={1} gapY={1}>
                  <Grid.Unit size={[1, 1, 'max']}>
                    <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 'min']}>
                    <Text sx={{ fontSize: 1 }}>{description}</Text>
                  </Grid.Unit>
                </Grid>
              )}
              renderItemWrapper={(children, { id }, i) => (
                <View key={id} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                  {children}
                </View>
              )}
            />
            <View sx={{ marginTop: 6 }}>
              <ErrorBoundary>
                <CommentPaginator fragmentReference={comments}>
                  {({ comments }, { loadMore, hasNext, isLoadingNext, refetch }) => {
                    return (
                      <>
                        <View>
                          <Stack gapX={1}>
                            <Stack.Item>
                              <Text fontWeight={'bold'} fontSize={1}>
                                댓글
                              </Text>
                            </Stack.Item>
                            <Stack.Item>
                              <Text
                                sx={{ fontSize: 1, fontWeight: 'bold' }}
                                color={comments?.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                              >
                                {numberWithCommas(comments?.totalCount || 0)}
                              </Text>
                            </Stack.Item>
                          </Stack>
                          <Card ref={commentContainerRef} sx={{ maxHeight: 240, overflowY: 'auto', marginTop: 2 }}>
                            <View sx={{ padding: 3 }}>
                              <ItemList
                                items={comments?.edges.map(({ node }) => node).filter((node) => !!node)}
                                renderItem={(node) => <CommentMutationActionItem comment={node} />}
                                renderItemWrapper={(children, { id }, index) => (
                                  <View key={id} sx={{ marginTop: index === 0 ? 0 : 4 }}>
                                    {children}
                                  </View>
                                )}
                                emptyState={<EmptyState title={'댓글이 없어요'} />}
                              />
                              {hasNext ? (
                                <Button
                                  onClick={() => loadMore(3)}
                                  disabled={isLoadingNext}
                                  sx={{ width: '100%', marginTop: 1 }}
                                >
                                  더보기
                                </Button>
                              ) : null}
                            </View>
                          </Card>
                        </View>
                        <View sx={{ marginTop: 2 }}>
                          <CommentCreateMutationFormik
                            nodeId={deliveryOrderId}
                            config={{
                              onCompleted: () => {
                                refetch(
                                  {},
                                  {
                                    fetchPolicy: 'store-and-network',
                                    onComplete: () => {
                                      setTimeout(() => {
                                        if (commentContainerRef.current)
                                          scrollToBottom(commentContainerRef.current, {});
                                      }, 0);
                                    },
                                  },
                                );
                              },
                            }}
                          />
                        </View>
                      </>
                    );
                  }}
                </CommentPaginator>
              </ErrorBoundary>
            </View>
            <View sx={{ marginTop: 6 }}>
              <Text fontWeight={'bold'} fontSize={1}>
                히스토리
              </Text>
              <ErrorBoundary>
                <DeliveryOrderDeliveryOrderHistoryPaginator fragmentReference={deliveryOrder}>
                  {({ histories }, { isLoadingNext, hasNext, loadMore }) => (
                    <Timeline>
                      <ItemList
                        items={histories.edges}
                        renderItem={({ node }) => <DeliveryOrderHistoryTimelineItem deliveryOrderHistory={node} />}
                        renderItemWrapper={(children, { node }) => (
                          <React.Fragment key={node.id}>
                            {node.type === 'delivery_order_update' ? (
                              <DialogHandler
                                key={node.id}
                                renderDialog={({ isOpen, closeDialog }) => (
                                  <DeliveryOrderHistoryDialog
                                    isOpen={isOpen}
                                    onDismiss={closeDialog}
                                    wide
                                    deliveryOrderHistory={node}
                                  />
                                )}
                              >
                                <View
                                  sx={{
                                    'paddingX': 2,
                                    'borderRadius': 2,
                                    'cursor': 'pointer',
                                    'transition': 'background-color 250ms',
                                    ':hover': { backgroundColor: 'canvas.subtle' },
                                  }}
                                >
                                  {children}
                                </View>
                              </DialogHandler>
                            ) : (
                              <View sx={{ paddingX: 2 }}>{children}</View>
                            )}
                          </React.Fragment>
                        )}
                      />
                      {hasNext ? (
                        <Button sx={{ width: '100%' }} disabled={isLoadingNext} onClick={() => loadMore(10)}>
                          더보기
                        </Button>
                      ) : (
                        <Timeline.Break />
                      )}
                    </Timeline>
                  )}
                </DeliveryOrderDeliveryOrderHistoryPaginator>
              </ErrorBoundary>
            </View>
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

DeliveryOrderId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
DeliveryOrderId.authenticated = true;
DeliveryOrderId.routes = [
  {
    id: 'deliveryOrderId',
    pathname: '/deliveryOrder/[deliveryOrderId]',
    name: '배송내역 상세',
    permissions: ['delivery_order_read'],
  },
];

export default DeliveryOrderId;
