/**
 * @generated SignedSource<<4a2a18a6f9db9ab841fd9a22a114d725>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATOrganizationPremiumTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
export type MATInvoiceCreateInput = {
  amount: number;
  description?: string | null;
  endDate: any;
  maxStudentCount: number;
  organization: string;
  premiumType: MATOrganizationPremiumTypeEnum;
  scholarshipAmount?: number | null;
  startDate: any;
  title: string;
};
export type MATOrganizationInvoiceCreateDialog_invoiceCreateMutation$variables = {
  input: MATInvoiceCreateInput;
};
export type MATOrganizationInvoiceCreateDialog_invoiceCreateMutation$data = {
  readonly invoiceCreate: {
    readonly id: string;
    readonly organization: {
      readonly currentInvoice: {
        readonly id: string;
        readonly premiumType: MATOrganizationPremiumTypeEnum;
      } | null;
      readonly id: string;
    };
  };
};
export type MATOrganizationInvoiceCreateDialog_invoiceCreateMutation = {
  response: MATOrganizationInvoiceCreateDialog_invoiceCreateMutation$data;
  variables: MATOrganizationInvoiceCreateDialog_invoiceCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MATInvoice",
    "kind": "LinkedField",
    "name": "invoiceCreate",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "MATOrganization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MATInvoice",
            "kind": "LinkedField",
            "name": "currentInvoice",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "premiumType",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATOrganizationInvoiceCreateDialog_invoiceCreateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATOrganizationInvoiceCreateDialog_invoiceCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "69dc17e6b7ac693af374655b8ff3e9fe",
    "id": null,
    "metadata": {},
    "name": "MATOrganizationInvoiceCreateDialog_invoiceCreateMutation",
    "operationKind": "mutation",
    "text": "mutation MATOrganizationInvoiceCreateDialog_invoiceCreateMutation(\n  $input: MATInvoiceCreateInput!\n) {\n  invoiceCreate(input: $input) {\n    id\n    organization {\n      id\n      currentInvoice {\n        id\n        premiumType\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e633ab519e2041b10f052099a70b0e3e";

export default node;
