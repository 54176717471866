/**
 * @generated SignedSource<<b4c64ca2571e98e5c2c726eb3360afc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type ProblemGeneratorSchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type ProblemGeneratorStatusEnum = "invalid" | "valid" | "%future added value";
export type ProblemGeneratorFilter = {
  AND?: ProblemGeneratorFilter | null;
  DISTINCT?: boolean | null;
  NOT?: ProblemGeneratorFilter | null;
  OR?: ProblemGeneratorFilter | null;
  activeProblemsCount_Gte?: number | null;
  activeProblemsCount_Lte?: number | null;
  assignedToId_Exact?: string | null;
  assignedToId_In?: ReadonlyArray<string> | null;
  assignedTo_Exists?: boolean | null;
  assignedTo_Exists_In?: ReadonlyArray<boolean> | null;
  publishedProblemsCount_Gte?: number | null;
  publishedProblemsCount_Lte?: number | null;
  schoolType_In?: ReadonlyArray<ProblemGeneratorSchoolTypeEnum> | null;
  search?: string | null;
  status_In?: ReadonlyArray<ProblemGeneratorStatusEnum> | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitDId_In?: ReadonlyArray<string> | null;
};
export type ProblemGeneratorOrder = {
  changed?: Ordering | null;
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  originalTask_UnitD_Order?: Ordering | null;
};
export type problemGenerator_problemGeneratorsQuery$variables = {
  filters?: ProblemGeneratorFilter | null;
  order?: ProblemGeneratorOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type problemGenerator_problemGeneratorsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorWindowPaginator_query">;
};
export type problemGenerator_problemGeneratorsQuery = {
  response: problemGenerator_problemGeneratorsQuery$data;
  variables: problemGenerator_problemGeneratorsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "problemGenerator_problemGeneratorsQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ProblemGeneratorWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "problemGenerator_problemGeneratorsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProblemGeneratorConnection",
        "kind": "LinkedField",
        "name": "problemGenerators",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProblemGeneratorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProblemGenerator",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publishedProblemsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activeProblemsCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "originalTask",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sequence",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnitA",
                        "kind": "LinkedField",
                        "name": "unitA",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnitD",
                        "kind": "LinkedField",
                        "name": "unitD",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "changed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "assignedTo",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "picture",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDeleted",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e09f539d873f3fa0fa320aee408a55f0",
    "id": null,
    "metadata": {},
    "name": "problemGenerator_problemGeneratorsQuery",
    "operationKind": "query",
    "text": "query problemGenerator_problemGeneratorsQuery(\n  $filters: ProblemGeneratorFilter\n  $order: ProblemGeneratorOrder\n  $page: Int\n  $pageSize: Int\n) {\n  ...ProblemGeneratorWindowPaginator_query_36liK3\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n\nfragment ProblemGeneratorConnectionDataTable_problemGeneratorConnection on ProblemGeneratorConnection {\n  edges {\n    node {\n      id\n      ...ProblemGeneratorStatusLabel_problemGenerator\n      publishedProblemsCount\n      activeProblemsCount\n      originalTask {\n        id\n        sequence\n        unitA {\n          id\n          title\n        }\n        unitD {\n          id\n          description\n        }\n      }\n      changed\n      assignedTo {\n        id\n        ...UserAvatarText_user\n      }\n    }\n  }\n}\n\nfragment ProblemGeneratorStatusLabel_problemGenerator on ProblemGenerator {\n  status\n}\n\nfragment ProblemGeneratorWindowPaginator_query_36liK3 on Query {\n  problemGenerators(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n        status\n      }\n    }\n    ...ProblemGeneratorConnectionDataTable_problemGeneratorConnection\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "579203a252c897aa99e9459dbf4bbe58";

export default node;
