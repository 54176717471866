/**
 * @generated SignedSource<<e61c7b8dc5ccc6997ba205fe85862460>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppDialogStatusEnum = "finished" | "ongoing" | "pending" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AppDialogStatusLabel_appDialog$data = {
  readonly status: AppDialogStatusEnum;
  readonly " $fragmentType": "AppDialogStatusLabel_appDialog";
};
export type AppDialogStatusLabel_appDialog$key = {
  readonly " $data"?: AppDialogStatusLabel_appDialog$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppDialogStatusLabel_appDialog">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppDialogStatusLabel_appDialog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "AppDialog",
  "abstractKey": null
};

(node as any).hash = "6431e7324e4a30916305db2115feb011";

export default node;
