/**
 * @generated SignedSource<<8109fa1f2604d9b9a0e121e60db7bcca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizSetStatusEnum = "labeling" | "published" | "%future added value";
export type QuizSetId_quizSetQuery$variables = {
  id: string;
};
export type QuizSetId_quizSetQuery$data = {
  readonly quizSet: {
    readonly id: string;
    readonly quizzes: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
    };
    readonly status: QuizSetStatusEnum;
    readonly unitB: {
      readonly id: string;
      readonly title: string;
    };
    readonly unitC: {
      readonly id: string;
      readonly title: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"QuizSetStatusLabel_quizSet">;
  };
};
export type QuizSetId_quizSetQuery = {
  response: QuizSetId_quizSetQuery$data;
  variables: QuizSetId_quizSetQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UnitB",
  "kind": "LinkedField",
  "name": "unitB",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UnitC",
  "kind": "LinkedField",
  "name": "unitC",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 10
    }
  ],
  "concreteType": "QuizConnection",
  "kind": "LinkedField",
  "name": "quizzes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuizEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Quiz",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "quizzes(first:10)"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizSetId_quizSetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuizSet",
        "kind": "LinkedField",
        "name": "quizSet",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "QuizSetStatusLabel_quizSet"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuizSetId_quizSetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuizSet",
        "kind": "LinkedField",
        "name": "quizSet",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e2043e06f0c8756790b27a2850a59f1",
    "id": null,
    "metadata": {},
    "name": "QuizSetId_quizSetQuery",
    "operationKind": "query",
    "text": "query QuizSetId_quizSetQuery(\n  $id: ID!\n) {\n  quizSet(id: $id) {\n    id\n    unitB {\n      id\n      title\n    }\n    unitC {\n      id\n      title\n    }\n    quizzes(first: 10) {\n      edges {\n        node {\n          id\n        }\n      }\n    }\n    status\n    ...QuizSetStatusLabel_quizSet\n  }\n}\n\nfragment QuizSetStatusLabel_quizSet on QuizSet {\n  status\n}\n"
  }
};
})();

(node as any).hash = "617ae887c1f8ea5e72b5da4baeb634ee";

export default node;
