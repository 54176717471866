import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { CodepushVersionUpdateDialog_codepushVersion$key } from '../../../relay/__generated__/CodepushVersionUpdateDialog_codepushVersion.graphql';
import { CodepushVersionUpdateDialog_codepushVersionUpdateMutation } from '../../../relay/__generated__/CodepushVersionUpdateDialog_codepushVersionUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MarkdownEditorField from '../../core/MarkdownEditorField';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';

const CodepushVersionUpdateDialog_codepushVersion = graphql`
  fragment CodepushVersionUpdateDialog_codepushVersion on CodepushVersion {
    id
    title
    severity
  }
`;

type Props = { codepushVersion: CodepushVersionUpdateDialog_codepushVersion$key } & DialogProps &
  Pick<MutationFormikProps<CodepushVersionUpdateDialog_codepushVersionUpdateMutation>, 'config' | 'onSubmit'>;

const CodepushVersionUpdateDialog = ({ codepushVersion, onSubmit, config, ...props }: Props) => {
  const { id, severity, title } = useFragment(CodepushVersionUpdateDialog_codepushVersion, codepushVersion);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>코드푸시 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<CodepushVersionUpdateDialog_codepushVersionUpdateMutation>
        mutation={graphql`
          mutation CodepushVersionUpdateDialog_codepushVersionUpdateMutation($input: CodepushVersionUpdateInput!) {
            codepushVersionUpdate(input: $input) {
              id
              ...CodepushVersionUpdateDialog_codepushVersion
            }
          }
        `}
        initialValues={{ id, severity, title }}
        onSubmit={onSubmit}
        config={config}
      >
        {({ submitForm, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <EnumPairRadioGroupField
                  typename={'CodepushSeverityEnum'}
                  label={'티어'}
                  name={'severity'}
                  required
                  renderOptionWrapper={(children, { id }) => (
                    <Grid.Unit key={id} size={'min'}>
                      {children}
                    </Grid.Unit>
                  )}
                  renderContainer={(children) => <Grid gapX={3}>{children}</Grid>}
                />
                <MarkdownEditorField
                  label={'업데이트 항목 요약'}
                  placeholder={'업데이트 항목 요약 입력'}
                  name={'title'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button size={'large'} variant={'primary'} onClick={() => submitForm()} disabled={!dirty}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default CodepushVersionUpdateDialog;
