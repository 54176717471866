/**
 * @generated SignedSource<<aab63ec55441d550eeafb27a7b9072a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FormulaSetHistoryTypeEnum = "formula_set_create" | "formula_set_review" | "formula_set_update" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FormulaSetHistoryTimelineItem_formulaSetHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly id: string;
  readonly type: FormulaSetHistoryTypeEnum;
  readonly " $fragmentType": "FormulaSetHistoryTimelineItem_formulaSetHistory";
};
export type FormulaSetHistoryTimelineItem_formulaSetHistory$key = {
  readonly " $data"?: FormulaSetHistoryTimelineItem_formulaSetHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormulaSetHistoryTimelineItem_formulaSetHistory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormulaSetHistoryTimelineItem_formulaSetHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "FormulaSetHistory",
  "abstractKey": null
};

(node as any).hash = "415c8681ba19cb6b67a384f958ab7fa5";

export default node;
