/**
 * @generated SignedSource<<e54547b9e45228c9a49af7cbff820f06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceExportedPreview_schoolExamSource$data = {
  readonly id: string;
  readonly school: {
    readonly id: string;
    readonly title: string;
  };
  readonly scraps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly scrap: {
          readonly id: string;
          readonly objectUrl: string;
        };
      };
    }>;
  };
  readonly title: string;
  readonly " $fragmentType": "SchoolExamSourceExportedPreview_schoolExamSource";
};
export type SchoolExamSourceExportedPreview_schoolExamSource$key = {
  readonly " $data"?: SchoolExamSourceExportedPreview_schoolExamSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceExportedPreview_schoolExamSource">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scrapCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scrapOrder"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceExportedPreview_schoolExamSource",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "School",
      "kind": "LinkedField",
      "name": "school",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "scrapCount"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "scrapOrder"
        }
      ],
      "concreteType": "SchoolExamScrapConnection",
      "kind": "LinkedField",
      "name": "scraps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SchoolExamScrapEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SchoolExamScrap",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "scrap",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "objectUrl",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SchoolExamSource",
  "abstractKey": null
};
})();

(node as any).hash = "94abb42a1854a404646bd4073e8d4bd7";

export default node;
