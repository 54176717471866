import { graphql, useFragment } from 'react-relay';

import { InternalUserNameUpdateDialog_internalUser$key } from '../../../relay/__generated__/InternalUserNameUpdateDialog_internalUser.graphql';
import { InternalUserNameUpdateDialog_internalUserNameUpdateMutation } from '../../../relay/__generated__/InternalUserNameUpdateDialog_internalUserNameUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const InternalUserNameUpdateDialog_internalUser = graphql`
  fragment InternalUserNameUpdateDialog_internalUser on MATInternalUser {
    id
    userName
  }
`;

type Props = {
  internalUser: InternalUserNameUpdateDialog_internalUser$key;
} & DialogProps &
  Pick<MutationFormikProps<InternalUserNameUpdateDialog_internalUserNameUpdateMutation>, 'config' | 'onSubmit'>;

const InternalUserNameUpdateDialog = ({ internalUser: internalUserReference, onSubmit, config, ...props }: Props) => {
  const data = useFragment(InternalUserNameUpdateDialog_internalUser, internalUserReference);
  const { id, userName } = data;
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>유저 닉네임 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<InternalUserNameUpdateDialog_internalUserNameUpdateMutation>
        mutation={graphql`
          mutation InternalUserNameUpdateDialog_internalUserNameUpdateMutation(
            $input: MATInternalUserNameUpdateInput!
          ) {
            internalUserNameUpdate(input: $input) {
              id
              userName
            }
          }
        `}
        initialValues={{
          id: id,
          userName: userName,
        }}
        onSubmit={onSubmit}
        config={config}
      >
        {({ submitForm, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField label={'유저 닉네임'} name={'userName'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button onClick={submitForm} disabled={!dirty}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default InternalUserNameUpdateDialog;
