import { graphql, useFragment } from 'react-relay';

import { TaskProblemTypingDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/TaskProblemTypingDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const TaskProblemTypingDifferenceCounterLabel_difference = graphql`
  fragment TaskProblemTypingDifferenceCounterLabel_difference on Difference {
    ... on TaskProblemTypingDifference {
      problem {
        __typename
      }
      solution {
        __typename
      }
    }
  }
`;

type Props = { difference: TaskProblemTypingDifferenceCounterLabel_difference$key } & CounterLabelProps;

const TaskProblemTypingDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { problem, solution } = useFragment(TaskProblemTypingDifferenceCounterLabel_difference, difference);

  const differenceCount = [problem, solution].filter((diff) => !isNullable(diff)).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default TaskProblemTypingDifferenceCounterLabel;
