/**
 * @generated SignedSource<<69a499f897306b981ce9b2f03bd0e076>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserConnectionDataTable_UserConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly email: string;
      readonly group: {
        readonly id: string;
        readonly title: string;
      } | null;
      readonly id: string;
      readonly joinedAt: any | null;
      readonly slackId: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    };
  }>;
  readonly " $fragmentType": "UserConnectionDataTable_UserConnection";
};
export type UserConnectionDataTable_UserConnection$key = {
  readonly " $data"?: UserConnectionDataTable_UserConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserConnectionDataTable_UserConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserConnectionDataTable_UserConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserAvatarText_user"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Group",
              "kind": "LinkedField",
              "name": "group",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slackId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "joinedAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserConnection",
  "abstractKey": null
};
})();

(node as any).hash = "64418975d233cc64fff06dc6a92a8728";

export default node;
