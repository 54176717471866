/**
 * @generated SignedSource<<5d73439fd6f3b7fe853515c95713801e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AppDialogExposeHourEnum = "day" | "hour" | "month" | "%future added value";
export type AppDialogExposeTypeEnum = "always" | "hour_repeat" | "manual_tag" | "once" | "weekday_repeat" | "%future added value";
export type AppDialogExposeWeekdayEnum = "fri" | "mon" | "sat" | "sun" | "thu" | "tue" | "wed" | "%future added value";
export type AppDialogTypeEnum = "image" | "image_and_text" | "text" | "%future added value";
export type AppDialogUserOsEnum = "android" | "ios" | "%future added value";
export type AppDialogUserTypeEnum = "general" | "non_refundable" | "refundable" | "%future added value";
export type AppDialogUserYearEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3" | "high_school_N" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AppDialogDescriptionList_appDialog$data = {
  readonly appDeepLink: string | null;
  readonly appDialogImage: {
    readonly objectUrl: string;
  } | null;
  readonly appDialogMobileImage: {
    readonly objectUrl: string;
  } | null;
  readonly appDialogTabletImage: {
    readonly objectUrl: string;
  } | null;
  readonly appDialogType: AppDialogTypeEnum;
  readonly endAt: any;
  readonly exposeHour: AppDialogExposeHourEnum | null;
  readonly exposeTag: string | null;
  readonly exposeType: AppDialogExposeTypeEnum;
  readonly exposeWeekdays: ReadonlyArray<AppDialogExposeWeekdayEnum> | null;
  readonly order: number;
  readonly startAt: any;
  readonly userAppVersion: {
    readonly max: string | null;
    readonly min: string | null;
  };
  readonly userOs: ReadonlyArray<AppDialogUserOsEnum>;
  readonly userPeriod: {
    readonly max: number | null;
    readonly min: number | null;
  };
  readonly userTypes: ReadonlyArray<AppDialogUserTypeEnum>;
  readonly userYears: ReadonlyArray<AppDialogUserYearEnum>;
  readonly " $fragmentType": "AppDialogDescriptionList_appDialog";
};
export type AppDialogDescriptionList_appDialog$key = {
  readonly " $data"?: AppDialogDescriptionList_appDialog$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppDialogDescriptionList_appDialog">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "min",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "max",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppDialogDescriptionList_appDialog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StringRange",
      "kind": "LinkedField",
      "name": "userAppVersion",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userOs",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userYears",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IntRange",
      "kind": "LinkedField",
      "name": "userPeriod",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exposeType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exposeTag",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exposeHour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "exposeWeekdays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appDialogType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "appDeepLink",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "appDialogMobileImage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "appDialogTabletImage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "appDialogImage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    }
  ],
  "type": "AppDialog",
  "abstractKey": null
};
})();

(node as any).hash = "6ede5f27848160d482afd602c068d235";

export default node;
