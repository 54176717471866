/**
 * @generated SignedSource<<5190f36d92933dfd60408c0360ca548f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScrapSourceTypeEnum = "book" | "exam" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScrapCreateDialog_scrapSource$data = {
  readonly id: string;
  readonly latestScrap: {
    readonly problemBookPage: number;
    readonly problemNumber: string;
    readonly problemPage: number;
    readonly solutionBookPage: number;
    readonly solutionPage: number;
  } | null;
  readonly problemPdf: {
    readonly id: string;
    readonly objectUrl: string;
  };
  readonly solutionPdf: {
    readonly id: string;
    readonly objectUrl: string;
  };
  readonly title: string;
  readonly type: ScrapSourceTypeEnum;
  readonly " $fragmentType": "ScrapCreateDialog_scrapSource";
};
export type ScrapCreateDialog_scrapSource$key = {
  readonly " $data"?: ScrapCreateDialog_scrapSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapCreateDialog_scrapSource">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScrapCreateDialog_scrapSource",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "solutionPdf",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "problemPdf",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Scrap",
      "kind": "LinkedField",
      "name": "latestScrap",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problemBookPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "solutionBookPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problemNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problemPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "solutionPage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScrapSource",
  "abstractKey": null
};
})();

(node as any).hash = "014046e062346a1c649b98aa01612010";

export default node;
