import { graphql, useFragment } from 'react-relay';

import { AppDialogImageTypePreviewItem_appDialog$key } from '../../../relay/__generated__/AppDialogImageTypePreviewItem_appDialog.graphql';
import AppDialogImageTypePreview from '../AppDialogImageTypePreview';

const AppDialogImageTypePreviewItem_appDialog = graphql`
  fragment AppDialogImageTypePreviewItem_appDialog on AppDialog {
    appDialogMobileImage {
      id
      objectUrl
      size
    }
    appDialogTabletImage {
      id
      objectUrl
      size
    }
  }
`;

type Props = {
  appDialog: AppDialogImageTypePreviewItem_appDialog$key;
};
const AppDialogImageTypePreviewItem = ({ appDialog }: Props) => {
  const { appDialogMobileImage, appDialogTabletImage } = useFragment(
    AppDialogImageTypePreviewItem_appDialog,
    appDialog,
  );

  return (
    <AppDialogImageTypePreview
      appDialogMobileImage={appDialogMobileImage?.objectUrl}
      appDialogTabletImage={appDialogTabletImage?.objectUrl}
    />
  );
};

export default AppDialogImageTypePreviewItem;
