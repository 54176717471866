/**
 * @generated SignedSource<<906e6082eac966cba313d1d7993269a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserUpdateInput = {
  id: string;
  joinedAt?: any | null;
  name?: string | null;
  password?: string | null;
  slackId?: string | null;
};
export type UserUpdateDialog_userUpdateMutation$variables = {
  input: UserUpdateInput;
};
export type UserUpdateDialog_userUpdateMutation$data = {
  readonly userUpdate: {
    readonly " $fragmentSpreads": FragmentRefs<"UserUpdateDialog_user">;
  };
};
export type UserUpdateDialog_userUpdateMutation = {
  response: UserUpdateDialog_userUpdateMutation$data;
  variables: UserUpdateDialog_userUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserUpdateDialog_userUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserUpdateDialog_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserUpdateDialog_userUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slackId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "joinedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "picture",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDeleted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "60bcfedbd6835410d9ea599435ece2ac",
    "id": null,
    "metadata": {},
    "name": "UserUpdateDialog_userUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation UserUpdateDialog_userUpdateMutation(\n  $input: UserUpdateInput!\n) {\n  userUpdate(input: $input) {\n    ...UserUpdateDialog_user\n    id\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n\nfragment UserUpdateDialog_user on User {\n  id\n  slackId\n  joinedAt\n  ...UserAvatarText_user\n}\n"
  }
};
})();

(node as any).hash = "3537815b71f8d3fde6634c4cca6d5856";

export default node;
