/**
 * @generated SignedSource<<e80bfda7d83040ab6b76b2c3e88cbc56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskSecondLabelingDifferenceCounterLabel_difference$data = {
  readonly answer?: {
    readonly __typename: "Diff";
  } | null;
  readonly answerChoiceCount?: {
    readonly __typename: "Diff";
  } | null;
  readonly answerType?: {
    readonly __typename: "Diff";
  } | null;
  readonly difficulty?: {
    readonly __typename: "Diff";
  } | null;
  readonly problem?: {
    readonly __typename: "Diff";
  } | null;
  readonly solution?: {
    readonly __typename: "Diff";
  } | null;
  readonly unitDs?: ReadonlyArray<{
    readonly __typename: "Diff";
  }> | null;
  readonly " $fragmentType": "TaskSecondLabelingDifferenceCounterLabel_difference";
};
export type TaskSecondLabelingDifferenceCounterLabel_difference$key = {
  readonly " $data"?: TaskSecondLabelingDifferenceCounterLabel_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskSecondLabelingDifferenceCounterLabel_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskSecondLabelingDifferenceCounterLabel_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "difficulty",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "unitDs",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "problem",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "solution",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "answer",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "answerChoiceCount",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "answerType",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "TaskSecondLabelingDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "e6b2ce7af4598bccbad2fbea74810e84";

export default node;
