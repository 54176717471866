/**
 * @generated SignedSource<<32acad5ee0888deb852a6a9b73b55c8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskImageCreationUpdateInput = {
  aiFile: Upload;
  imageRequests: ReadonlyArray<ImageRequestInput>;
  problem: EditorInput;
  solution: EditorInput;
  task: string;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type ImageRequestInput = {
  description: string;
  file: Upload;
};
export type EditorInput = {
  text: string;
  textHtml?: string | null;
};
export type TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation$variables = {
  input: TaskImageCreationUpdateInput;
};
export type TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation$data = {
  readonly taskImageCreationUpdate: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"TaskImageCreationUpdateDialog_task">;
  };
};
export type TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation = {
  response: TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation$data;
  variables: TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "taskImageCreationUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaskImageCreationUpdateDialog_task"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "taskImageCreationUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sequence",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskProblem",
            "kind": "LinkedField",
            "name": "problem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "problem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "solution",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "problemType",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "aiFile",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskImageRequestConnection",
            "kind": "LinkedField",
            "name": "imageRequests",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskImageRequestEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TaskImageRequest",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c36235778685cf8052dbc8563c5d382",
    "id": null,
    "metadata": {},
    "name": "TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation TaskImageCreationUpdateDialog_taskImageCreationUpdateMutation(\n  $input: TaskImageCreationUpdateInput!\n) {\n  taskImageCreationUpdate(input: $input) {\n    id\n    ...TaskImageCreationUpdateDialog_task\n  }\n}\n\nfragment TaskImageCreationUpdateDialog_task on Task {\n  id\n  title\n  sequence\n  problem {\n    id\n    problem\n    solution\n    problemType\n  }\n  aiFile {\n    key\n    objectUrl\n    size\n    id\n  }\n  imageRequests {\n    edges {\n      node {\n        id\n        image {\n          key\n          objectUrl\n          size\n          id\n        }\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "62740363dfd14471aa6e1f418416cc9e";

export default node;
