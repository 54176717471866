/**
 * @generated SignedSource<<b0f6a7aa9484063f9ec1b213f80a3e9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProblemGeneratorActionEnum = "generated_problems_create" | "problem_generator_activate" | "problem_generator_assign" | "problem_generator_deactivate" | "problem_generator_publish" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator$data = {
  readonly actions: ReadonlyArray<ProblemGeneratorActionEnum>;
  readonly id: string;
  readonly originalTask: {
    readonly hint: {
      readonly id: string;
      readonly publishedText: string | null;
    } | null;
    readonly id: string;
    readonly problem: {
      readonly answer: string | null;
      readonly id: string;
      readonly problem: string | null;
      readonly solution: string | null;
    } | null;
    readonly sequence: number;
    readonly submitFeedback: {
      readonly id: string;
      readonly publishedGeneral: string | null;
      readonly publishedSpecial: string | null;
      readonly publishedSpecialAnswer: string | null;
    } | null;
  };
  readonly " $fragmentType": "ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator";
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator$key = {
  readonly " $data"?: ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Task",
      "kind": "LinkedField",
      "name": "originalTask",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sequence",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskProblem",
          "kind": "LinkedField",
          "name": "problem",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "problem",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "solution",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answer",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskHint",
          "kind": "LinkedField",
          "name": "hint",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publishedText",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskSubmitFeedback",
          "kind": "LinkedField",
          "name": "submitFeedback",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publishedSpecialAnswer",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publishedSpecial",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publishedGeneral",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProblemGenerator",
  "abstractKey": null
};
})();

(node as any).hash = "18a45a362d5e010c323ed2f34301dda5";

export default node;
