import { graphql } from 'react-relay';

import { UnitCPaginator_query$key } from '../../../relay/__generated__/UnitCPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const UnitCPaginator_query = graphql`
  fragment UnitCPaginator_query on Query
  @argumentDefinitions(
    filters: { type: UnitCFilter }
    order: { type: UnitCOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "UnitCPaginatorRefreshQuery") {
    unitCs(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "UnitCPaginator_query_unitCs") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<UnitCPaginator_query$key>, 'fragment'>;

const UnitCPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={UnitCPaginator_query} {...props} />;

export default UnitCPaginator;
