import { AlertFillIcon } from '@primer/octicons-react';
import { StyledOcticon } from '@primer/react';

import Text from '../Text';
import View from '../View';

type Props = {
  icon?: React.ElementType;
  title?: string;
  description?: string;
};

const ErrorState = ({
  icon: IconComponent = AlertFillIcon,
  title = '오류가 발생했어요',
  description,
  ...props
}: Props) => {
  return (
    <View {...props} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
      <StyledOcticon icon={IconComponent} size={24} sx={{ color: 'fg.muted' }} />
      <View sx={{ marginTop: 3 }}>
        <Text fontSize={3} fontWeight={'bold'} whiteSpace={'pre-wrap'}>
          {title}
        </Text>
      </View>
      <View sx={{ marginTop: 1, color: 'fg.muted' }}>
        <Text fontSize={1} whiteSpace={'pre-wrap'}>
          {description}
        </Text>
      </View>
    </View>
  );
};

export default ErrorState;
export type { Props as ErrorStateProps };
