import { graphql, useFragment } from 'react-relay';

import { TaskPublishDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/TaskPublishDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const TaskPublishDifferenceCounterLabel_difference = graphql`
  fragment TaskPublishDifferenceCounterLabel_difference on Difference {
    ... on TaskPublishDifference {
      difficulty {
        __typename
      }
      unitDs {
        __typename
      }
      problem {
        __typename
      }
      solution {
        __typename
      }
      answer {
        __typename
      }
      answerChoiceCount {
        __typename
      }
      answerType {
        __typename
      }
    }
  }
`;

type Props = { difference: TaskPublishDifferenceCounterLabel_difference$key } & CounterLabelProps;

const TaskPublishDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { difficulty, unitDs, answer, answerChoiceCount, answerType, problem, solution } = useFragment(
    TaskPublishDifferenceCounterLabel_difference,
    difference,
  );

  const differenceCount = [difficulty, unitDs, answer, answerChoiceCount, answerType, problem, solution].filter(
    (diff) => !isNullable(diff),
  ).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default TaskPublishDifferenceCounterLabel;
