import { UrlObject } from 'url';

import { debounce } from 'lodash-es';
import { Router, useRouter } from 'next/router';
import { useCallback } from 'react';

import { isArray, isNullable } from '../../utils/is';
import { normalizeObject } from '../../utils/object';

type Options = {
  [key in string]: {
    type: 'number' | 'string' | 'boolean';
    multiple?: boolean;
  };
};

type OptionsValueType<T extends Options[string]['type']> = T extends 'number'
  ? number
  : T extends 'string'
  ? string
  : boolean;

type OptionsType<T extends Options> = {
  [key in keyof T]: T[key] extends {
    multiple: true;
  }
    ? OptionsValueType<T[key]['type']>[]
    : OptionsValueType<T[key]['type']>;
};

const useInitialValuesFromParsedUrlQuery = <T extends Options>(options: T) => {
  const router = useRouter();
  const getQueryValueByType = (queryValue: string, type: 'number' | 'string' | 'boolean') => {
    return type === 'number'
      ? Number(queryValue)
      : type === 'boolean'
      ? queryValue === 'true'
        ? true
        : false
      : !isNullable(queryValue)
      ? queryValue
      : '';
  };

  const initialValues: OptionsType<T> = Object.fromEntries(
    Object.entries(options)
      .map(([key, { type, multiple }]) => {
        const queryValue = router.query[key];
        const value = isNullable(queryValue)
          ? undefined
          : multiple
          ? isArray(queryValue)
            ? queryValue.map((value) => getQueryValueByType(value, type))
            : [getQueryValueByType(queryValue, type)]
          : getQueryValueByType(queryValue.toString(), type);

        return [key, value];
      })
      .filter(([, value]) => !isNullable(value)),
  );

  const setParsedUrlQuery = useCallback(
    (query: UrlObject['query'], options?: Parameters<Router['push']>[2] & { replace?: boolean }) => {
      const routerMethod = options?.replace ? router.replace : router.push;
      routerMethod({ pathname: router.pathname, query: normalizeObject(query) }, undefined, options);
    },
    [],
  );
  return { initialValues, setParsedUrlQuery: debounce(setParsedUrlQuery, 500) };
};

export default useInitialValuesFromParsedUrlQuery;
