import { ArrowSwitchIcon, CheckIcon, ImageIcon, StopIcon, TagIcon } from '@primer/octicons-react';
import {
  decideAnswerTypeObjectiveFromKatex,
  countAnswerChoice,
  extractAnswerFromKatex,
  formatKatexToHtmlString,
} from '@teamturing/katex-utils';
import { editor } from 'monaco-editor';
import React, { ChangeEvent, Suspense, useRef, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import {
  TaskLabelingProblemUpdateDialog_task$key,
  TaskProblemCreationTypeEnum,
} from '../../../relay/__generated__/TaskLabelingProblemUpdateDialog_task.graphql';
import { TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation } from '../../../relay/__generated__/TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation.graphql';
import { UploadMetaDataSchema } from '../../../types/upload';
import { getUploadMetaData, uploadGeneral } from '../../../utils/file';
import { isNullable } from '../../../utils/is';
import { numberWithCommas } from '../../../utils/number';
import Button from '../../core/Button';
import Card from '../../core/Card';
import DescriptionList from '../../core/DescriptionList';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import EnumPair from '../../core/EnumPair';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import { KatexEditorShortcutButtonsStack } from '../../core/KatexEditor';
import KatexEditorField, { KatexEditorFieldProps } from '../../core/KatexEditorField';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import SquareView from '../../core/SquareView';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import TabHandler from '../../core/TabHandler';
import Text from '../../core/Text';
import UploadField from '../../core/UploadField';
import UploadIconButtonField from '../../core/UploadIconButtonField';
import View from '../../core/View';

const TaskLabelingProblemUpdateDialog_task = graphql`
  fragment TaskLabelingProblemUpdateDialog_task on Task {
    id
    title
    sequence
    status
    problem {
      id
      problem
      solution
      problemType
      creationType
      answer
      answerChoiceCount
      answerType
    }

    scrap {
      id
      problems {
        id
        objectUrl
      }
      solutions {
        id
        objectUrl
      }
    }

    imageRequests {
      edges {
        node {
          id
          image {
            key
            objectUrl
            size
          }
          description
        }
      }
    }
    aiFile {
      id
      key
      objectUrl
      size
    }
  }
`;

type Props = {
  task: TaskLabelingProblemUpdateDialog_task$key;
} & DialogProps &
  Pick<
    MutationFormikProps<TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation>,
    'config' | 'connections' | 'onSubmit'
  >;

const TaskLabelingProblemUpdateDialog = ({ task, config, connections, onSubmit, ...props }: Props) => {
  const { id, sequence, status, title, problem, scrap, imageRequests, aiFile } = useFragment(
    TaskLabelingProblemUpdateDialog_task,
    task,
  );
  const { toast } = useToast();

  const sideTabItems = [
    { id: 'tag', icon: TagIcon },
    { id: 'image', icon: ImageIcon },
  ];
  const editorInputTabItems = [
    { id: 'all', label: '문제 + 해설' },
    { id: 'problem', label: '문제' },
    { id: 'solution', label: '해설' },
  ];

  const handleChangeUploadField = async (
    e: ChangeEvent<HTMLInputElement>,
    onGeneralUpload: (blob: Blob, metaData: UploadMetaDataSchema) => void,
  ) => {
    const targetFile = e.target.files?.[0];

    if (isNullable(targetFile)) return;
    const { name: fileName, size: fileSize } = targetFile;
    const metaData = await getUploadMetaData({ fileName, fileSize });

    if (!metaData) {
      return;
    }

    const isMultiPartUploadRequired = 'upload_id' in metaData && 'part_metadata' in metaData;

    if (isMultiPartUploadRequired) {
      toast('사진 크기가 너무 큽니다. 작은 사진을 첨부해주세요', 'error');
    } else {
      const response = await uploadGeneral(targetFile, metaData);
      if (response.ok) {
        onGeneralUpload(targetFile, metaData);
      }
    }
  };

  const problemEditorRef = useRef<editor.IStandaloneCodeEditor>(null);
  const solutionEditorRef = useRef<editor.IStandaloneCodeEditor>(null);
  const [activeEditor, setActiveEditor] = useState<editor.IStandaloneCodeEditor | null>(null);

  const isFinalLabeling = status === 'review';

  const getAnswerChoiceCountByAnswerType = (value: string) =>
    decideAnswerTypeObjectiveFromKatex(value) ? countAnswerChoice(value) || 5 : null;
  const getAnswerType = (value: string) => (decideAnswerTypeObjectiveFromKatex(value) ? 'objective' : 'subjective');

  return (
    <Dialog {...props}>
      <TabHandler initialSelectIndex={0}>
        {({ selectedIndex: selectedSideTabIndex, handleSelect: handleSelectSideTab }) => (
          <MutationFormik<TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation>
            mutation={graphql`
              mutation TaskLabelingProblemUpdateDialog_taskLabelingProblemUpdateMutation(
                $input: TaskLabelingProblemUpdateInput!
              ) {
                taskLabelingProblemUpdate(input: $input) {
                  id
                  ...TaskLabelingProblemUpdateDialog_task
                }
              }
            `}
            initialValues={{
              answer: extractAnswerFromKatex(problem?.solution || '') || '',
              answerChoiceCount: getAnswerChoiceCountByAnswerType(problem?.problem || ''),
              answerType: getAnswerType(problem?.problem || ''),
              problem: {
                text: problem?.problem || '',
                textHtml: formatKatexToHtmlString(problem?.problem || ''),
              },
              solution: {
                text: problem?.solution || '',
                textHtml: formatKatexToHtmlString(problem?.solution || ''),
              },
              task: id,
              taskProblem: problem?.id || '',
              creationType: problem?.creationType || ('' as TaskProblemCreationTypeEnum),
              aiFile: aiFile ? { key: aiFile.key, size: aiFile.size || 0, objectUrl: aiFile?.objectUrl } : null,
              imageRequests: imageRequests.edges.map(({ node }) => ({
                file: { key: node.image.key, size: node.image.size, objectUrl: node.image.objectUrl },
                description: node.description || '',
              })),
            }}
            config={{
              ...config,
              onError: (...arg) => {
                config?.onError?.(...arg);
                handleSelectSideTab(1);
              },
            }}
            onSubmit={onSubmit}
            connections={connections}
          >
            {({ dirty, handleSubmit, setFieldValue, values }, { scrollContainerRef, nonFieldErrorRef }) => {
              const handleChangeProblemKatexEditor: KatexEditorFieldProps['onChange'] = (value = '') => {
                setFieldValue('problem', { text: value, textHtml: formatKatexToHtmlString(value) });
                setFieldValue('answerType', getAnswerType(value || ''));
                setFieldValue('answerChoiceCount', getAnswerChoiceCountByAnswerType(value || ''));
              };
              const handleChangeSolutionKatexEditor: KatexEditorFieldProps['onChange'] = (value = '') => {
                setFieldValue('solution', { text: value, textHtml: formatKatexToHtmlString(value) });
                setFieldValue('answer', extractAnswerFromKatex(value || ''));
              };

              const handleGeneralUploadImage = (index: number) => (blob: Blob, metaData: UploadMetaDataSchema) => {
                const previousImageSrc = values.imageRequests?.[index].file.objectUrl;
                if (!previousImageSrc) return;

                const nextImageSrc = metaData.object_url;

                const problem = values.problem.text.replaceAll(previousImageSrc, nextImageSrc);
                const solution = values.solution.text.replaceAll(previousImageSrc, nextImageSrc);

                problemEditorRef.current?.setValue(problem);
                solutionEditorRef.current?.setValue(solution);
                setFieldValue(`imageRequests.${index}.file`, {
                  key: metaData.key,
                  size: blob.size,
                  objectUrl: metaData.object_url,
                });
              };

              return (
                <>
                  <Dialog.Header>
                    <Grid sx={{ paddingRight: 4, alignItems: 'center' }}>
                      <Grid.Unit size={'max'}>
                        <Stack gapX={1}>
                          <Stack.Item>
                            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>수정하기</Text>
                          </Stack.Item>
                          {problem ? (
                            <Stack.Item>
                              <Suspense>
                                <Label variant={'accent'}>
                                  <EnumPair typename={'TaskProblemTypeEnum'}>{problem.problemType}</EnumPair>
                                  {` · ${title}`}
                                </Label>
                              </Suspense>
                            </Stack.Item>
                          ) : null}
                          <Stack.Item>
                            <Label variant={'primary'}>{sequence}</Label>
                          </Stack.Item>
                        </Stack>
                      </Grid.Unit>
                      <Grid.Unit size={'min'}>
                        <Stack gapX={1}>
                          <ItemList
                            items={sideTabItems}
                            renderItem={({ icon }, index) => (
                              <IconButton
                                onClick={() => handleSelectSideTab(index)}
                                variant={selectedSideTabIndex === index ? 'default' : 'plain'}
                                icon={icon}
                                aria-label={`open ${id} tab`}
                              />
                            )}
                            renderItemWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
                          />
                          <Stack.Item>
                            <Button
                              variant={'primary'}
                              onClick={() => handleSubmit()}
                              leadingIcon={CheckIcon}
                              disabled={!dirty}
                            >
                              저장하기
                            </Button>
                          </Stack.Item>
                        </Stack>
                      </Grid.Unit>
                    </Grid>
                  </Dialog.Header>
                  <Grid wrap={false} sx={{ height: '100%', overflowY: 'hidden' }}>
                    <Grid.Unit size={3 / 4}>
                      <TabHandler initialSelectIndex={0}>
                        {({ selectedIndex: selectedEditorInputTab, handleSelect: handleSelectEditorInputTab }) => (
                          <View
                            ref={scrollContainerRef}
                            sx={{
                              'padding': 3,
                              'height': '100%',
                              'overflowY': 'auto',
                              '&>*:not(:last-child)': { marginBottom: 5 },
                            }}
                          >
                            <NonFieldError ref={nonFieldErrorRef} />
                            <Grid gapY={2}>
                              <Grid.Unit size={'max'}>
                                <Stack gapX={1}>
                                  <ItemList
                                    items={editorInputTabItems}
                                    renderItem={({ label }, index) => (
                                      <Button
                                        onClick={() => handleSelectEditorInputTab(index)}
                                        variant={selectedEditorInputTab === index ? 'outline' : 'invisible'}
                                      >
                                        {label}
                                      </Button>
                                    )}
                                    renderItemWrapper={(children, { id }) => (
                                      <Stack.Item key={id}>{children}</Stack.Item>
                                    )}
                                  />
                                </Stack>
                              </Grid.Unit>
                              <Grid.Unit size={'min'}>
                                <KatexEditorShortcutButtonsStack editor={activeEditor!} gapX={1} />
                              </Grid.Unit>
                            </Grid>
                            <View sx={{ '&>*:not(:first-child)': { marginTop: 3 } }}>
                              {editorInputTabItems[selectedEditorInputTab].id === 'all' ||
                              editorInputTabItems[selectedEditorInputTab].id === 'problem' ? (
                                <Grid gapX={2}>
                                  <Grid.Unit size={2 / 3}>
                                    <KatexEditorField
                                      ref={problemEditorRef}
                                      name={'problem'}
                                      label={'문제'}
                                      type={'scroll'}
                                      height={
                                        editorInputTabItems[selectedEditorInputTab].id === 'all' ? '33vh' : '70vh'
                                      }
                                      required
                                      onFocus={setActiveEditor}
                                      onChange={handleChangeProblemKatexEditor}
                                      emptyStateText={'아직 타이핑한 문제가 없어요'}
                                    />
                                  </Grid.Unit>
                                  <Grid.Unit size={1 / 3} sx={{ '&>*:not(:first-child)': { marginTop: 1 } }}>
                                    <Text fontSize={1}>스크랩한 문제</Text>
                                    <Card
                                      sx={{
                                        overflow: 'auto',
                                        height:
                                          editorInputTabItems[selectedEditorInputTab].id === 'all' ? '33vh' : '70vh',
                                      }}
                                    >
                                      <ItemList
                                        items={scrap?.problems || []}
                                        renderItem={({ objectUrl }) => (
                                          <Image
                                            key={objectUrl}
                                            src={objectUrl}
                                            alt={objectUrl}
                                            html
                                            style={{ display: 'block' }}
                                          />
                                        )}
                                      />
                                    </Card>
                                  </Grid.Unit>
                                </Grid>
                              ) : null}
                              {editorInputTabItems[selectedEditorInputTab].id === 'all' ||
                              editorInputTabItems[selectedEditorInputTab].id === 'solution' ? (
                                <Grid gapX={2}>
                                  <Grid.Unit size={2 / 3}>
                                    <KatexEditorField
                                      ref={solutionEditorRef}
                                      name={'solution'}
                                      label={'해설'}
                                      type={'scroll'}
                                      height={
                                        editorInputTabItems[selectedEditorInputTab].id === 'all' ? '33vh' : '70vh'
                                      }
                                      required
                                      onFocus={setActiveEditor}
                                      onChange={handleChangeSolutionKatexEditor}
                                      emptyStateText={'아직 타이핑한 해설이 없어요'}
                                    />
                                  </Grid.Unit>
                                  <Grid.Unit size={1 / 3} sx={{ '&>*:not(:first-child)': { marginTop: 1 } }}>
                                    <Text fontSize={1}>스크랩한 해설</Text>
                                    <Card
                                      sx={{
                                        overflow: 'auto',
                                        height:
                                          editorInputTabItems[selectedEditorInputTab].id === 'all' ? '33vh' : '70vh',
                                      }}
                                    >
                                      <ItemList
                                        items={scrap?.solutions || []}
                                        renderItem={({ objectUrl }) => (
                                          <Image
                                            key={objectUrl}
                                            src={objectUrl}
                                            alt={objectUrl}
                                            html
                                            style={{ display: 'block' }}
                                          />
                                        )}
                                      />
                                    </Card>
                                  </Grid.Unit>
                                </Grid>
                              ) : null}
                            </View>
                          </View>
                        )}
                      </TabHandler>
                    </Grid.Unit>
                    <Grid.Unit size={1 / 4}>
                      <View
                        sx={{
                          'padding': 3,
                          'backgroundColor': 'canvas.inset',
                          'overflowY': 'auto',
                          'height': '100%',
                          '&>*:not(:first-child)': { marginTop: 4 },
                        }}
                      >
                        {sideTabItems[selectedSideTabIndex].id === 'tag' ? (
                          <>
                            <View>
                              <Text fontSize={2} fontWeight={'bold'}>
                                문제 정보
                              </Text>
                            </View>
                            <Card sx={{ padding: 3, backgroundColor: 'canvas.inset' }}>
                              <DescriptionList
                                item={values}
                                itemDescriptions={{
                                  answerType: {
                                    title: '문제 유형',
                                    renderValue: ({ answerType }) =>
                                      answerType ? (
                                        <Suspense>
                                          <EnumPair typename={'TaskProblemAnswerTypeEnum'}>{answerType}</EnumPair>
                                        </Suspense>
                                      ) : undefined,
                                  },
                                  answer: {
                                    title: '정답',
                                    renderValue: ({ answer }) =>
                                      isNullable(answer) || answer === '' ? (
                                        <View
                                          sx={{
                                            color: 'danger.fg',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          <Stack gapX={1}>
                                            <Stack.Item>
                                              <StyledOcticon icon={StopIcon} size={12} />
                                            </Stack.Item>
                                            <Stack.Item>
                                              <Text fontSize={1}>입력 필요</Text>
                                            </Stack.Item>
                                          </Stack>
                                        </View>
                                      ) : (
                                        answer
                                      ),
                                  },
                                  answerChoiceCount: {
                                    title: '선지 개수',
                                    renderValue: ({ answerChoiceCount }) =>
                                      answerChoiceCount !== 0 ? answerChoiceCount : undefined,
                                  },
                                }}
                                picks={['answerType', 'answer', 'answerChoiceCount']}
                                titleUnitSize={1 / 2}
                                descriptionUnitSize={1 / 2}
                              />
                            </Card>
                            {isFinalLabeling ? (
                              <Suspense>
                                <EnumPairSelectField
                                  typename={'TaskProblemCreationTypeEnum'}
                                  label={'변형 정도'}
                                  name={'creationType'}
                                  placeholder={'변형 정도 선택'}
                                  required
                                />
                              </Suspense>
                            ) : null}
                          </>
                        ) : sideTabItems[selectedSideTabIndex].id === 'image' ? (
                          <>
                            <Stack gapX={1}>
                              <Stack.Item>
                                <Text fontSize={2} fontWeight={'bold'}>
                                  요청한 이미지
                                </Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Text
                                  sx={{ fontSize: 1, fontWeight: 'bold' }}
                                  color={imageRequests.edges.length === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                                >
                                  {numberWithCommas(imageRequests.edges.length || 0)}
                                </Text>
                              </Stack.Item>
                            </Stack>
                            <UploadField
                              label={'이미지 ai 파일'}
                              name={'aiFile'}
                              placeholder={'ai 파일을 선택하세요'}
                              accept={'application/postscript'}
                              required
                            />
                            <ItemList
                              items={values.imageRequests || []}
                              renderItem={({ file, description }, index) => (
                                <View sx={{ '&>*:not(:first-child)': { marginTop: 1 } }}>
                                  <Card>
                                    <SquareView
                                      sx={{
                                        '&:hover': {
                                          '*': {
                                            display: 'block',
                                          },
                                        },
                                      }}
                                    >
                                      <View
                                        sx={{
                                          position: 'absolute',
                                          display: 'none',
                                          width: '100%',
                                          height: '100%',
                                          backgroundColor: 'fg.default',
                                          opacity: 0.2,
                                          zIndex: 1,
                                        }}
                                      />
                                      <UploadIconButtonField
                                        sx={{ position: 'absolute', display: 'none', left: 2, bottom: 2, zIndex: 1 }}
                                        name={`imageRequest.${index}.file`}
                                        label={`image${index}`}
                                        labelConfig={{ visuallyHidden: true }}
                                        size={'large'}
                                        aria-label={`Change image ${index}`}
                                        icon={ArrowSwitchIcon}
                                        accept={'image/*'}
                                        onChange={(e) => handleChangeUploadField(e, handleGeneralUploadImage(index))}
                                      />
                                      <Image
                                        src={file.objectUrl || ''}
                                        alt={`requested image ${index}`}
                                        fill
                                        style={{ objectFit: 'contain' }}
                                      />
                                    </SquareView>
                                  </Card>
                                  <Card sx={{ padding: 3 }}>
                                    <Text fontSize={1} color={'fg.subtle'} sx={{ whiteSpace: 'pre-wrap' }}>
                                      {description || ''}
                                    </Text>
                                  </Card>
                                </View>
                              )}
                              renderItemWrapper={(children, { file }) => <View key={file.key}>{children}</View>}
                              emptyState={
                                <Card sx={{ paddingY: 5, backgroundColor: 'canvas.inset' }}>
                                  <EmptyState title={'요청한 이미지가 없습니다.'} />
                                </Card>
                              }
                            />
                          </>
                        ) : null}
                      </View>
                    </Grid.Unit>
                  </Grid>
                </>
              );
            }}
          </MutationFormik>
        )}
      </TabHandler>
    </Dialog>
  );
};

export default TaskLabelingProblemUpdateDialog;
