import { graphql, useFragment } from 'react-relay';

import {
  UnitDConnectionDataTable_unitDConnection$data,
  UnitDConnectionDataTable_unitDConnection$key,
} from '../../../relay/__generated__/UnitDConnectionDataTable_unitDConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';

const UnitDConnectionDataTable_unitDConnection = graphql`
  fragment UnitDConnectionDataTable_unitDConnection on UnitDConnection {
    edges {
      node {
        id
        unitBTitle
        unitCTitle
        title: description
      }
    }
  }
`;

type Props = {
  unitDConnection: UnitDConnectionDataTable_unitDConnection$key;
} & Omit<DataTableProps<UnitDConnectionDataTable_unitDConnection$data['edges'][number]['node']>, 'rows' | 'columns'>;

const UnitDConnectionDataTable = ({ unitDConnection, ...props }: Props) => {
  const { edges } = useFragment(UnitDConnectionDataTable_unitDConnection, unitDConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        { field: 'unitB', title: 'Unit B', renderValue: ({ unitBTitle }) => unitBTitle, width: 120 },
        { field: 'unitC', title: 'Unit C', renderValue: ({ unitCTitle }) => unitCTitle, width: 160 },
        { field: 'unitD', title: 'Unit D', renderValue: ({ title }) => title, width: 240 },
      ]}
      {...props}
    />
  );
};

export default UnitDConnectionDataTable;
