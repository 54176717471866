/**
 * @generated SignedSource<<da9cf924f425b8230fad56db1bec1029>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RaffleStuffCreateInput = {
  backgroundColor: string;
  blackThumbnail: Upload;
  colorThumbnail: Upload;
  description?: string | null;
  stuff: NodeInput;
  title: string;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type NodeInput = {
  id: string;
};
export type RaffleStuffCreateDialog_raffleStuffCreateMutation$variables = {
  input: RaffleStuffCreateInput;
};
export type RaffleStuffCreateDialog_raffleStuffCreateMutation$data = {
  readonly raffleStuffCreate: {
    readonly id: string;
  };
};
export type RaffleStuffCreateDialog_raffleStuffCreateMutation = {
  response: RaffleStuffCreateDialog_raffleStuffCreateMutation$data;
  variables: RaffleStuffCreateDialog_raffleStuffCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RaffleStuff",
    "kind": "LinkedField",
    "name": "raffleStuffCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RaffleStuffCreateDialog_raffleStuffCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RaffleStuffCreateDialog_raffleStuffCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "53ce671aaeb62bae68fc45ae2622977c",
    "id": null,
    "metadata": {},
    "name": "RaffleStuffCreateDialog_raffleStuffCreateMutation",
    "operationKind": "mutation",
    "text": "mutation RaffleStuffCreateDialog_raffleStuffCreateMutation(\n  $input: RaffleStuffCreateInput!\n) {\n  raffleStuffCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "af6912af4c155c2223971e1fd38d0718";

export default node;
