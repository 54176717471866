import { graphql } from 'react-relay';

import { TaskPaginator_query$key } from '../../../relay/__generated__/TaskPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const TaskPaginator_query = graphql`
  fragment TaskPaginator_query on Query
  @argumentDefinitions(
    filters: { type: TaskFilter }
    order: { type: TaskOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "TaskPaginatorRefreshQuery") {
    tasks(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "TaskPaginator_query_tasks") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          ...TaskSequenceItem_task
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<TaskPaginator_query$key>, 'fragment'>;

const TaskPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={TaskPaginator_query} {...props} />;

export default TaskPaginator;
