/**
 * @generated SignedSource<<767049026b77bf0f18611ae886a3a304>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolExamSourceStatusEnum = "published" | "scrap" | "upload_required" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceStatusLabel_schoolExamSource$data = {
  readonly status: SchoolExamSourceStatusEnum;
  readonly " $fragmentType": "SchoolExamSourceStatusLabel_schoolExamSource";
};
export type SchoolExamSourceStatusLabel_schoolExamSource$key = {
  readonly " $data"?: SchoolExamSourceStatusLabel_schoolExamSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceStatusLabel_schoolExamSource">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceStatusLabel_schoolExamSource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "SchoolExamSource",
  "abstractKey": null
};

(node as any).hash = "ddb5d0ee4746a07cbc3c864dd9f63cda";

export default node;
