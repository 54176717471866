import { DotFillIcon, TrashIcon } from '@primer/octicons-react';
import React, { Suspense, useEffect } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';

import { ScrapSourceScrapDeleteDialog_ScrapSource$key } from '../../../relay/__generated__/ScrapSourceScrapDeleteDialog_ScrapSource.graphql';
import { ScrapSourceScrapDeleteDialog_scrapDeleteMutation } from '../../../relay/__generated__/ScrapSourceScrapDeleteDialog_scrapDeleteMutation.graphql';
import { ScrapSourceScrapDeleteDialog_scrapQuery } from '../../../relay/__generated__/ScrapSourceScrapDeleteDialog_scrapQuery.graphql';
import { numberWithCommas } from '../../../utils/number';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import Label from '../../core/Label';
import MutationConfirmIconButton, { MutationConfirmIconButtonProps } from '../../core/MutationConfirmIconButton';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';
import View from '../../core/View';
import ScrapItem from '../../scrap/ScrapItem';
import ScrapSourceScrapPaginator from '../ScrapSourceScrapPaginator';

const ScrapSourceScrapDeleteDialog_ScrapSource = graphql`
  fragment ScrapSourceScrapDeleteDialog_ScrapSource on ScrapSource
  @argumentDefinitions(first: { type: Int }, after: { type: String }) {
    id
    title
    type
    scraps {
      edges {
        node {
          id
        }
      }
    }
    ...ScrapSourceScrapPaginator_scrapSource @arguments(first: $first, after: $after)
  }
`;

const scrapForScrapSourceScrapDeleteDialog = graphql`
  query ScrapSourceScrapDeleteDialog_scrapQuery($id: ID!) {
    scrap(id: $id) {
      id
      problemBookPage
      solutionBookPage
      problemNumber
      problems {
        id
        objectUrl
      }
      solutions {
        id
        objectUrl
      }
    }
  }
`;

type Props = {
  scrapSource: ScrapSourceScrapDeleteDialog_ScrapSource$key;
  initialIndex: number;
} & DialogProps &
  Pick<MutationConfirmIconButtonProps<ScrapSourceScrapDeleteDialog_scrapDeleteMutation>, 'config'>;

const ScrapSourceScrapDeleteDialog = ({ isOpen, onDismiss, scrapSource, initialIndex, config, ...props }: Props) => {
  const data = useFragment(ScrapSourceScrapDeleteDialog_ScrapSource, scrapSource);
  const [queryReference, loadQuery] = useQueryLoader<ScrapSourceScrapDeleteDialog_scrapQuery>(
    scrapForScrapSourceScrapDeleteDialog,
  );
  const { title, type } = data;

  useEffect(() => {
    if (isOpen && data.scraps?.edges?.[initialIndex]?.node.id) {
      loadQuery({ id: data.scraps.edges[initialIndex].node.id });
    }
  }, [isOpen, initialIndex]);

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss} {...props}>
      <Dialog.Header>
        <Stack gapX={1}>
          <Stack.Item>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>스크랩상세</Text>
          </Stack.Item>
          <Stack.Item>
            <Label variant={'accent'}>
              <EnumPair typename={'ScrapSourceTypeEnum'}>{type}</EnumPair>
              {` · ${title}`}
            </Label>
          </Stack.Item>
        </Stack>
      </Dialog.Header>
      <Grid wrap={false} sx={{ overflowY: 'hidden', height: '100%' }}>
        <Grid.Unit size={3 / 4}>
          {queryReference ? (
            <Suspense
              fallback={
                <View sx={{ padding: 6 }}>
                  <Spinner />
                </View>
              }
            >
              <PreloadedQueryRenderer query={scrapForScrapSourceScrapDeleteDialog} queryReference={queryReference}>
                {({ scrap }) => {
                  if (!scrap) return null;
                  const { problemBookPage, solutionBookPage, problemNumber, problems, solutions } = scrap;
                  return (
                    <View sx={{ padding: 3, height: '100%', overflowY: 'auto' }}>
                      <Grid gapX={4} sx={{ height: '100%' }}>
                        <Grid.Unit size={1 / 2}>
                          <Stack gapX={1}>
                            <Stack.Item>
                              <Text fontSize={1}>{`${problemBookPage}P의 ${problemNumber} 문제`}</Text>
                            </Stack.Item>
                            <Stack.Item>
                              <Text fontSize={1} fontWeight={'bold'} color={'accent.emphasis'}>
                                {problems.length}
                              </Text>
                            </Stack.Item>
                          </Stack>
                          <Card sx={{ marginTop: 2, overflowY: 'auto', height: '80vh' }}>
                            <ItemList
                              items={problems}
                              renderItem={({ objectUrl }) => (
                                <Image src={objectUrl} alt={objectUrl} html style={{ display: 'block' }} />
                              )}
                              renderItemWrapper={(children, { id }) => <View key={id}>{children}</View>}
                            />
                          </Card>
                        </Grid.Unit>
                        <Grid.Unit size={1 / 2}>
                          <Stack gapX={1}>
                            <Stack.Item>
                              <Text fontSize={1}>{`${solutionBookPage}P의 ${problemNumber} 해설`}</Text>
                            </Stack.Item>
                            <Stack.Item>
                              <Text fontSize={1} fontWeight={'bold'} color={'accent.emphasis'}>
                                {solutions.length}
                              </Text>
                            </Stack.Item>
                          </Stack>
                          <Card sx={{ marginTop: 2, overflowY: 'auto', height: '80vh' }}>
                            <ItemList
                              items={solutions}
                              renderItem={({ objectUrl }) => (
                                <Image src={objectUrl} alt={objectUrl} html style={{ display: 'block' }} />
                              )}
                              renderItemWrapper={(children, { id }) => <View key={id}>{children}</View>}
                            />
                          </Card>
                        </Grid.Unit>
                      </Grid>
                    </View>
                  );
                }}
              </PreloadedQueryRenderer>
            </Suspense>
          ) : null}
        </Grid.Unit>
        <Grid.Unit
          size={1 / 4}
          sx={{ padding: 5, backgroundColor: 'canvas.inset', height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <ScrapSourceScrapPaginator fragmentReference={data}>
            {({ scraps }, { loadMore, hasNext, isLoadingNext }) => (
              <>
                <Stack gapX={1}>
                  <Stack.Item>
                    <Text fontWeight={'bold'} fontSize={1}>
                      스크랩한 문제
                    </Text>
                  </Stack.Item>
                  <Stack.Item>
                    <Text
                      fontWeight={'bold'}
                      fontSize={1}
                      color={scraps.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                    >
                      {numberWithCommas(scraps.totalCount || 0)}
                    </Text>
                  </Stack.Item>
                </Stack>
                <View
                  sx={{
                    flex: 1,
                    marginTop: 3,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                  }}
                >
                  <ItemList
                    items={scraps.edges.map(({ node }) => node).filter((node) => !!node)}
                    renderItem={(scrap) => <ScrapItem scrap={scrap} />}
                    renderItemWrapper={(children, scrap, index) => (
                      <View
                        key={scrap.id}
                        onClick={() => loadQuery({ id: scrap.id })}
                        sx={{
                          'cursor': 'pointer',
                          'borderRadius': 2,
                          ':hover': { backgroundColor: 'neutral.muted' },
                          ':active': {
                            backgroundColor: 'accent.subtle',
                            fontWeight: 'bold',
                            color: 'accent.emphasis',
                          },
                          ...(queryReference?.variables.id === scrap.id
                            ? {
                                backgroundColor: 'accent.subtle',
                                fontWeight: 'bold',
                                color: 'accent.emphasis',
                              }
                            : {}),
                        }}
                        tabIndex={0}
                      >
                        <Grid wrap={false} gapX={1} sx={{ alignItems: 'center', paddingX: 2 }}>
                          <Grid.Unit size={'min'} sx={{ display: 'flex', alignItems: 'center' }}>
                            <StyledOcticon size={12} icon={DotFillIcon} color={'border.default'} />
                          </Grid.Unit>
                          <Grid.Unit size={'max'} sx={{ paddingY: 2 }}>
                            {children}
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            {queryReference?.variables.id === scrap.id && scrap.actions.includes('scrap_delete') ? (
                              <MutationConfirmIconButton<ScrapSourceScrapDeleteDialog_scrapDeleteMutation>
                                tabIndex={-1}
                                mutation={graphql`
                                  mutation ScrapSourceScrapDeleteDialog_scrapDeleteMutation($input: ScrapDeleteInput!) {
                                    scrapDelete(input: $input) {
                                      id @deleteRecord
                                    }
                                  }
                                `}
                                input={{ id: scrap.id }}
                                message={'삭제한 문제는 되돌릴 수 없어요. 삭제할까요?'}
                                variant={'invisible'}
                                size={'small'}
                                config={{
                                  ...config,
                                  onCompleted: (...args) => {
                                    // ㅠㅠ
                                    if (scraps.edges[index + 1]?.node?.id)
                                      loadQuery({ id: scraps.edges[index + 1].node.id });
                                    else onDismiss?.();
                                    config?.onCompleted?.(...args);
                                  },
                                }}
                                icon={TrashIcon}
                                aria-label={'delete scrap'}
                              />
                            ) : null}
                          </Grid.Unit>
                        </Grid>
                      </View>
                    )}
                  />
                  {hasNext ? (
                    <Button onClick={() => loadMore(100)} disabled={isLoadingNext} sx={{ width: '100%', marginTop: 1 }}>
                      더보기
                    </Button>
                  ) : null}
                </View>
              </>
            )}
          </ScrapSourceScrapPaginator>
        </Grid.Unit>
      </Grid>
    </Dialog>
  );
};

export default ScrapSourceScrapDeleteDialog;
