import { MouseEventHandler } from 'react';
import { GraphQLTaggedNode, useMutation, UseMutationConfig } from 'react-relay';

import Button, { ButtonProps } from '../Button';

type Props<
  TMutation extends {
    readonly response: {};
    readonly variables: { readonly input?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
> = {
  mutation: GraphQLTaggedNode;
  input: TMutation['variables']['input'];
  config?: Omit<UseMutationConfig<TMutation>, 'variables'>;
} & ButtonProps;

const MutationButton = <
  TMutation extends {
    readonly response: {};
    readonly variables: { readonly input?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
>({
  mutation,
  input,
  config,
  onClick,
  disabled,
  ...props
}: Props<TMutation>) => {
  const [mutate, isLoading] = useMutation(mutation);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    onClick?.(e);
    mutate({ ...(config ? config : {}), variables: { input: input } });
  };

  return <Button onClick={handleClick} disabled={isLoading || disabled} {...props} />;
};

export default MutationButton;
export type { Props as MutationButtonProps };
