import { formatKatexToHtmlString } from '@teamturing/katex-utils';
import { FieldConfig, useField } from 'formik';
import { editor } from 'monaco-editor';
import { ComponentProps, forwardRef, Ref } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import KatexEditorInput, { KatexEditorInputProps } from '../KatexEditorInput';
import View from '../View';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  required?: boolean;
  value?: { text: string; textHtml?: string };
  caption?: string;
} & Omit<KatexEditorInputProps, 'value'> &
  Pick<FieldConfig, 'validate'>;

const KatexEditorField = (
  {
    name = '',
    label,
    required = false,
    labelConfig,
    value: propValue,
    onChange: propOnChange,
    disabled: propDisabled,
    caption,
    validate,
    ...props
  }: Props,
  ref: Ref<editor.IStandaloneCodeEditor>,
) => {
  const INPUT_ID = `katex_editor_field_for_${label}`;
  const [{ value: baseValue = { text: '' } }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value.text === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 입력해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();

  const value = !isNullable(propValue) ? propValue : baseValue;
  const disabled = propDisabled || isSubmitting;

  const onChange: KatexEditorInputProps['onChange'] = (value = '') =>
    setValue({ text: value, textHtml: formatKatexToHtmlString(value) });

  const handleChange: KatexEditorInputProps['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else onChange(...arg);
  };

  return (
    <FormControl required={required} disabled={disabled}>
      <FormControl.Label {...labelConfig} htmlFor={INPUT_ID} sx={{ marginBottom: 1, ...labelConfig?.sx }}>
        {label}
      </FormControl.Label>
      <View sx={{ width: '100%' }}>
        <KatexEditorInput
          ref={ref}
          id={INPUT_ID}
          name={name}
          value={value?.text}
          onChange={handleChange}
          disabled={disabled}
          validationStatus={error ? 'error' : undefined}
          {...props}
        />
      </View>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default forwardRef(KatexEditorField);
export type { Props as KatexEditorFieldProps };
