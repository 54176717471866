import {
  ChipGroupViewType,
  EnigmaSectionType,
  IconViewType,
  ImageViewType,
  ResponsiveLayoutContainerType,
  TextViewType,
  ViewComponentType,
  ViewContainerType,
} from '@teamturing/react-kit/dist/enigma/types';

let id = 0;

export class EnigmaBuilder {
  private id: string;
  private views: ViewContainerType[] = [];
  private responsiveLayout: ResponsiveLayoutContainerType = {
    mobile: { layoutComponentType: 'SingleColumnLayout', layout: { main: [] } },
  };

  constructor({
    id,
    views,
    responsiveLayout,
  }: {
    id: string;
    views?: ViewContainerType[];
    responsiveLayout?: ResponsiveLayoutContainerType;
  }) {
    this.id = id;
    this.views = views || this.views;
    this.responsiveLayout = responsiveLayout || this.responsiveLayout;
  }

  private getViewContainerId(viewComponentType: ViewComponentType) {
    return `${viewComponentType}-${id++}`;
  }
  private appendViewToResponsiveLayout(viewContainerId: string) {
    this.responsiveLayout = {
      ...this.responsiveLayout,
      mobile: {
        layoutComponentType: 'SingleColumnLayout',
        layout: { main: [...this.responsiveLayout.mobile.layout.main, { viewContainerId: viewContainerId }] },
      },
    };
  }

  appendTextView(textView: TextViewType) {
    const viewContainerId = this.getViewContainerId('TextView');
    this.views = [...this.views, { id: viewContainerId, viewComponentType: 'TextView', view: textView }];
    this.appendViewToResponsiveLayout(viewContainerId);
  }
  appendIconView(iconView: IconViewType) {
    const viewContainerId = this.getViewContainerId('IconView');
    this.views = [...this.views, { id: viewContainerId, viewComponentType: 'IconView', view: iconView }];
    this.appendViewToResponsiveLayout(viewContainerId);
  }
  appendImageView(imageView: ImageViewType) {
    const viewContainerId = this.getViewContainerId('ImageView');
    this.views = [...this.views, { id: viewContainerId, viewComponentType: 'ImageView', view: imageView }];
    this.appendViewToResponsiveLayout(viewContainerId);
  }
  appendChipGroupView(chipGroupView: ChipGroupViewType) {
    const viewContainerId = this.getViewContainerId('ChipGroupView');
    this.views = [...this.views, { id: viewContainerId, viewComponentType: 'ChipGroupView', view: chipGroupView }];
    this.appendViewToResponsiveLayout(viewContainerId);
  }

  set({ views, responsiveLayout }: EnigmaSectionType) {
    this.views = views;
    this.responsiveLayout = responsiveLayout;
  }

  get() {
    return {
      id: this.id,
      views: this.views,
      responsiveLayout: this.responsiveLayout,
    };
  }
}
