import React from 'react';

import DialogHandler from '../DialogHandler';
import IconButton, { IconButtonProps } from '../IconButton';

type Props = {
  renderDialog: ({ isOpen, closeDialog }: { isOpen: boolean; closeDialog: () => void }) => React.ReactNode;
} & IconButtonProps;

const DialogIconButton = ({ renderDialog, onClick, ...props }: Props) => (
  <DialogHandler renderDialog={renderDialog} onClick={onClick}>
    <IconButton {...props} />
  </DialogHandler>
);

export default DialogIconButton;
