type Options = {
  behavior?: ScrollBehavior;
};

const scrollIntoView = (
  childrenRef: HTMLElement,
  scrollContainerRef: HTMLElement,
  { behavior = 'smooth' }: Options,
) => {
  const startSide = 'top';
  const endSide = 'bottom';
  const scrollSide = 'scrollTop';
  const { [startSide]: childrenStart, [endSide]: childrenEnd } = childrenRef.getBoundingClientRect();
  const { [startSide]: scrollContainerStart, [endSide]: scrollContainerEnd } =
    scrollContainerRef.getBoundingClientRect();

  const isChildrenStartAboveScrollContainer = childrenStart < scrollContainerStart;
  const isChildrenBottomBelowScrollContainer = childrenEnd > scrollContainerEnd;

  if (isChildrenStartAboveScrollContainer) {
    const scrollHeight = childrenStart - scrollContainerStart + scrollContainerRef[scrollSide];
    scrollContainerRef.scrollTo({ behavior, [startSide]: scrollHeight });
  } else if (isChildrenBottomBelowScrollContainer) {
    const scrollHeight = childrenEnd - scrollContainerEnd + scrollContainerRef[scrollSide];
    scrollContainerRef.scrollTo({ behavior, [startSide]: scrollHeight });
  }
};

const scrollToBottom = (scrollContainerRef: HTMLElement, { behavior = 'smooth' }: Options) => {
  const scrollHeight = scrollContainerRef.scrollHeight;
  scrollContainerRef.scrollTo({ top: scrollHeight, behavior });
};

const scrollToTop = (scrollContainerRef: HTMLElement, { behavior = 'smooth' }: Options) => {
  scrollContainerRef.scrollTo({ top: 0, behavior });
};

export { scrollIntoView, scrollToBottom, scrollToTop };
