import { graphql, useFragment } from 'react-relay';

import {
  RaffleConnectionDataTable_raffleConnection$data,
  RaffleConnectionDataTable_raffleConnection$key,
} from '../../../relay/__generated__/RaffleConnectionDataTable_raffleConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import RaffleStuffPreviewItem from '../../raffleStuff/RaffleStuffPreviewItem';
import UserAvatarText from '../../user/UserAvatarText';
import RaffleStatusLabel from '../RaffleStatusLabel';

const RaffleConnectionDataTable_raffleConnection = graphql`
  fragment RaffleConnectionDataTable_raffleConnection on RaffleConnection {
    edges {
      node {
        id
        ...RaffleStatusLabel_raffle
        title
        raffleStuff {
          id
          title
          ...RaffleStuffPreviewItem_raffleStuff
        }
        winnerCount
        startAt
        endAt
        showEndAt
        order
        createdBy {
          id
          ...UserAvatarText_user
        }
      }
    }
  }
`;

type Props = {
  raffleConnection: RaffleConnectionDataTable_raffleConnection$key;
} & Omit<DataTableProps<RaffleConnectionDataTable_raffleConnection$data['edges'][number]['node']>, 'rows' | 'columns'>;

const RaffleConnectionDataTable = ({ raffleConnection, ...props }: Props) => {
  const { edges } = useFragment(RaffleConnectionDataTable_raffleConnection, raffleConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (raffle) => <RaffleStatusLabel raffle={raffle} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'title',
          title: '제목',
          renderValue: ({ title }) => title,
          width: 300,
        },
        {
          field: 'raffleStuff',
          title: '경품',
          renderValue: ({ raffleStuff }) => (
            <Stack gapX={2}>
              <Stack.Item>
                <View sx={{ width: 36, height: 36 }}>
                  <RaffleStuffPreviewItem raffleStuff={raffleStuff} />
                </View>
              </Stack.Item>
              <Stack.Item>
                <Text>{raffleStuff.title}</Text>
              </Stack.Item>
            </Stack>
          ),
          width: 300,
        },
        {
          field: 'winnerCount',
          title: '당첨자 수',
          renderValue: ({ winnerCount }) => `${winnerCount}명`,
          width: 80,
        },
        {
          field: 'startAt',
          title: '시작일',
          renderValue: ({ startAt }) => <DateText format={'P EEEE'}>{startAt}</DateText>,
          width: 160,
        },
        {
          field: 'endAt',
          title: '종료일',
          renderValue: ({ endAt }) => <DateText format={'P EEEE'}>{endAt}</DateText>,
          width: 160,
        },
        {
          field: 'showEndAt',
          title: '노출일',
          renderValue: ({ showEndAt }) => <DateText format={'P EEEE'}>{showEndAt}</DateText>,
          width: 160,
        },
        {
          field: 'order',
          title: '우선순위',
          renderValue: ({ order }) => order,
          width: 80,
        },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 120,
        },
      ]}
      {...props}
    />
  );
};

export default RaffleConnectionDataTable;
