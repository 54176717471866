/**
 * @generated SignedSource<<b2436d8d2f3f0272b43a380a3c1b7a43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProblemGeneratorStatusEnum = "invalid" | "valid" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorStatusLabel_problemGenerator$data = {
  readonly status: ProblemGeneratorStatusEnum;
  readonly " $fragmentType": "ProblemGeneratorStatusLabel_problemGenerator";
};
export type ProblemGeneratorStatusLabel_problemGenerator$key = {
  readonly " $data"?: ProblemGeneratorStatusLabel_problemGenerator$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorStatusLabel_problemGenerator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProblemGeneratorStatusLabel_problemGenerator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "ProblemGenerator",
  "abstractKey": null
};

(node as any).hash = "86568e0006f2b69f8bfaa9ca6b567013";

export default node;
