import { RequestParameters, Variables } from 'relay-runtime';

const logger =
  (next: (request: RequestParameters, variables: Variables) => Promise<any>) =>
  async (request: RequestParameters, variables: Variables) => {
    const startTime = new Date().getTime();
    const { operationKind, name: operationName } = request;

    const response = await next(request, variables);
    const { data, errors } = response;
    const endTime = new Date().getTime();
    const timeSpended = endTime - startTime;
    if (data) {
      console.groupCollapsed(
        `%c${operationKind} %c${operationName} %c(in ${timeSpended}ms)`,
        `color: ${operationKind === 'query' ? '#2DA44E' : '#FF3737'}`,
        'color: inherit',
        'color: grey; font-weight: 100',
      );
      console.log('variables', variables);
      console.log('result', data);
      console.groupEnd();
    } else {
      console.groupCollapsed(`%cerror %c${operationName}`, `color: inherit}`, 'color: #FF3737');
      console.log('variables', variables);
      console.log('errors', errors);
      console.groupEnd();
    }

    return response;
  };

export default logger;
