import { CheckIcon } from '@primer/octicons-react';
import { formatKatexToHtmlString } from '@teamturing/katex-utils';
import { GraphQLError } from 'graphql/index';
import { useRouter } from 'next/router';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { TaskActionsMutationButtonStack_task$key } from '../../../relay/__generated__/TaskActionsMutationButtonStack_task.graphql';
import { TaskActionsMutationButtonStack_taskCopyMutation } from '../../../relay/__generated__/TaskActionsMutationButtonStack_taskCopyMutation.graphql';
import { TaskActionsMutationButtonStack_taskFirstLabelingCompleteMutation } from '../../../relay/__generated__/TaskActionsMutationButtonStack_taskFirstLabelingCompleteMutation.graphql';
import { TaskActionsMutationButtonStack_taskImageCreationCompleteMutation } from '../../../relay/__generated__/TaskActionsMutationButtonStack_taskImageCreationCompleteMutation.graphql';
import { TaskActionsMutationButtonStack_taskProblemCreationCompleteMutation } from '../../../relay/__generated__/TaskActionsMutationButtonStack_taskProblemCreationCompleteMutation.graphql';
import { TaskActionsMutationButtonStack_taskProblemTypingCompleteMutation } from '../../../relay/__generated__/TaskActionsMutationButtonStack_taskProblemTypingCompleteMutation.graphql';
import { TaskActionsMutationButtonStack_taskPublishMutation } from '../../../relay/__generated__/TaskActionsMutationButtonStack_taskPublishMutation.graphql';
import { TaskActionsMutationButtonStack_taskSecondLabelingCompleteMutation } from '../../../relay/__generated__/TaskActionsMutationButtonStack_taskSecondLabelingCompleteMutation.graphql';
import { parseGraphQLError } from '../../../utils/error';
import DialogButton from '../../core/DialogButton';
import MutationConfirmButton from '../../core/MutationConfirmButton';
import Stack from '../../core/Stack';
import TaskAssignDialog from '../TaskAssignDialog';
import TaskHintFeedbackPublishDialog from '../TaskHintFeedbackPublishDialog';

const TaskActionsMutationButtonStack_task = graphql`
  fragment TaskActionsMutationButtonStack_task on Task {
    id
    actions
    ...TaskHintFeedbackPublishDialog_task
    problem {
      id
      problem
      solution
    }
  }
`;

type Props = {
  task: TaskActionsMutationButtonStack_task$key;
};

const TaskActionsMutationButtonStack = ({ task: taskReference }: Props) => {
  const task = useFragment(TaskActionsMutationButtonStack_task, taskReference);
  const router = useRouter();
  const { id, actions, problem } = task;

  const { toast } = useToast();

  return (
    <Stack gapX={2}>
      {actions.includes('task_problem_typing_complete') ? (
        <Stack.Item>
          <MutationConfirmButton<TaskActionsMutationButtonStack_taskProblemTypingCompleteMutation>
            mutation={graphql`
              mutation TaskActionsMutationButtonStack_taskProblemTypingCompleteMutation(
                $input: TaskProblemTypingCompleteInput!
              ) {
                taskProblemTypingComplete(input: $input) {
                  id
                  showProblem
                  showLabeling
                  showVideo
                  showSolutionVideo
                  completedBy {
                    id
                    ...UserAvatarText_user
                  }
                  ...TaskActionsMutationButtonStack_task
                  ...TaskDescriptionList_task
                  ...TaskStatusLabel_task
                  ...TaskTaskHistoryPaginator_task
                }
              }
            `}
            input={{
              task: id,
            }}
            variant={'primary'}
            size={'large'}
            message={'타이핑한 문제와 해설을 확인 후 제출해 주세요. 제출할까요?'}
            config={{
              onCompleted: () => {
                toast('제출이 완료됐어요!', 'success');
              },
              onError: () => {
                toast('타이핑을 먼저 완료해주세요', 'error');
              },
            }}
          >
            제출하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_problem_creation_complete') ? (
        <Stack.Item>
          <MutationConfirmButton<TaskActionsMutationButtonStack_taskProblemCreationCompleteMutation>
            mutation={graphql`
              mutation TaskActionsMutationButtonStack_taskProblemCreationCompleteMutation(
                $input: TaskProblemCreationCompleteInput!
              ) {
                taskProblemCreationComplete(input: $input) {
                  id
                  showProblem
                  showLabeling
                  showVideo
                  showSolutionVideo
                  completedBy {
                    id
                    ...UserAvatarText_user
                  }
                  ...TaskActionsMutationButtonStack_task
                  ...TaskDescriptionList_task
                  ...TaskStatusLabel_task
                  ...TaskTaskHistoryPaginator_task
                }
              }
            `}
            input={{ task: id }}
            variant={'primary'}
            size={'large'}
            message={'창작한 문제와 해설을 확인 후 제출해 주세요. 제출할까요?'}
            config={{
              onCompleted: () => {
                toast('제출이 완료됐어요!', 'success');
              },
              onError: () => {
                toast('창작을 먼저 완료해주세요', 'error');
              },
            }}
          >
            제출하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_image_creation_complete') ? (
        <Stack.Item>
          <MutationConfirmButton<TaskActionsMutationButtonStack_taskImageCreationCompleteMutation>
            mutation={graphql`
              mutation TaskActionsMutationButtonStack_taskImageCreationCompleteMutation(
                $input: TaskImageCreationCompleteInput!
              ) {
                taskImageCreationComplete(input: $input) {
                  id
                  showProblem
                  showLabeling
                  showVideo
                  showSolutionVideo
                  completedBy {
                    id
                    ...UserAvatarText_user
                  }
                  ...TaskActionsMutationButtonStack_task
                  ...TaskDescriptionList_task
                  ...TaskStatusLabel_task
                  ...TaskTaskHistoryPaginator_task
                }
              }
            `}
            input={{ task: id }}
            variant={'primary'}
            size={'large'}
            message={'제작한 이미지로 반영됐는지 확인 후 제출해 주세요. 제출할까요?'}
            config={{
              onCompleted: () => {
                toast('제출이 완료됐어요!', 'success');
              },
              onError: () => {
                toast('이미지 제작을 먼저 완료해주세요', 'error');
              },
            }}
          >
            제출하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_first_labeling_complete') ? (
        <Stack.Item>
          <MutationConfirmButton<TaskActionsMutationButtonStack_taskFirstLabelingCompleteMutation>
            mutation={graphql`
              mutation TaskActionsMutationButtonStack_taskFirstLabelingCompleteMutation(
                $input: TaskFirstLabelingCompleteInput!
              ) {
                taskFirstLabelingComplete(input: $input) {
                  id
                  showProblem
                  showLabeling
                  showVideo
                  showSolutionVideo
                  completedBy {
                    id
                    ...UserAvatarText_user
                  }
                  ...TaskActionsMutationButtonStack_task
                  ...TaskDescriptionList_task
                  ...TaskStatusLabel_task
                  ...TaskTaskHistoryPaginator_task
                }
              }
            `}
            input={{ task: id }}
            variant={'primary'}
            size={'large'}
            message={'1차 라벨링 정보 확인 후 제출해 주세요. 제출할까요?'}
            config={{
              onCompleted: () => {
                toast('제출이 완료됐어요!', 'success');
              },
              onError: () => {
                toast('1차 라벨링을 먼저 완료해주세요', 'error');
              },
            }}
          >
            제출하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_second_labeling_complete') ? (
        <Stack.Item>
          <MutationConfirmButton<TaskActionsMutationButtonStack_taskSecondLabelingCompleteMutation>
            mutation={graphql`
              mutation TaskActionsMutationButtonStack_taskSecondLabelingCompleteMutation(
                $input: TaskSecondLabelingCompleteInput!
              ) {
                taskSecondLabelingComplete(input: $input) {
                  id
                  showProblem
                  showLabeling
                  showVideo
                  showSolutionVideo
                  completedBy {
                    id
                    ...UserAvatarText_user
                  }
                  ...TaskActionsMutationButtonStack_task
                  ...TaskDescriptionList_task
                  ...TaskStatusLabel_task
                  ...TaskTaskHistoryPaginator_task
                }
              }
            `}
            input={{ task: id }}
            variant={'primary'}
            size={'large'}
            message={'2차 라벨링 정보 확인 후 제출해 주세요. 제출할까요?'}
            config={{
              onCompleted: () => {
                toast('제출이 완료됐어요!', 'success');
              },
              onError: () => {
                toast('2차 라벨링을 먼저 완료해주세요', 'error');
              },
            }}
          >
            제출하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_hint_feedback_publish') ? (
        <Stack.Item>
          <DialogButton
            variant={'outline'}
            renderDialog={({ isOpen, closeDialog }) => (
              <TaskHintFeedbackPublishDialog
                isOpen={isOpen}
                onDismiss={closeDialog}
                task={task}
                config={{
                  onCompleted: () => {
                    toast('출시가 완료됐어요!', 'success');
                    closeDialog();
                  },
                  onError: (error) => {
                    toast(parseGraphQLError(error as GraphQLError)?.[0].message ?? '다시 출시해주세요', 'error');
                  },
                }}
                wide
              />
            )}
            size={'large'}
          >
            힌트 · 피드백 검수하기
          </DialogButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_copy') ? (
        <Stack.Item>
          <MutationConfirmButton<TaskActionsMutationButtonStack_taskCopyMutation>
            variant={'outline'}
            mutation={graphql`
              mutation TaskActionsMutationButtonStack_taskCopyMutation($input: TaskCopyInput!) {
                taskCopy(input: $input) {
                  id
                }
              }
            `}
            input={{ id }}
            size={'large'}
            config={{
              onCompleted: ({ taskCopy }) => {
                toast('복제 성공했어요', 'success');
                router.push(`/task/${taskCopy.id}`);
              },
              onError: () => {
                toast('복제 실패했어요', 'error');
              },
            }}
            message={'정말 복제할까요?'}
          >
            복제하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_assign') ? (
        <Stack.Item>
          <DialogButton
            variant={'outline'}
            renderDialog={({ isOpen, closeDialog }) => (
              <TaskAssignDialog
                isOpen={isOpen}
                onDismiss={closeDialog}
                tasks={[id]}
                config={{
                  onCompleted: () => {
                    toast('배정 성공했어요', 'success');
                    closeDialog();
                  },
                  onError: () => {
                    toast('배정 실패했어요', 'error');
                  },
                }}
              />
            )}
            size={'large'}
          >
            배정하기
          </DialogButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_publish') ? (
        <Stack.Item>
          <MutationConfirmButton<TaskActionsMutationButtonStack_taskPublishMutation>
            mutation={graphql`
              mutation TaskActionsMutationButtonStack_taskPublishMutation($input: TaskPublishInput!) {
                taskPublish(input: $input) {
                  id
                  showProblem
                  showLabeling
                  showVideo
                  showSolutionVideo
                  completedBy {
                    id
                    ...UserAvatarText_user
                  }
                  ...TaskActionsMutationButtonStack_task
                  ...TaskDescriptionList_task
                  ...TaskStatusLabel_task
                  ...TaskTaskHistoryPaginator_task
                  ...TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_task
                }
              }
            `}
            input={{
              task: id,
              problemHtml: formatKatexToHtmlString(problem?.problem || ''),
              solutionHtml: formatKatexToHtmlString(problem?.solution || ''),
            }}
            variant={'primary'}
            size={'large'}
            message={'문제 및 최종 라벨링 정보 확인 후 출시해 주세요. 출시할까요?'}
            config={{
              onCompleted: () => {
                toast('출시가 완료됐어요!', 'success');
              },
              onError: () => {
                toast('다시 출시해주세요', 'error');
              },
            }}
            leadingIcon={CheckIcon}
          >
            출시하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_activate') ? (
        <Stack.Item>
          <MutationConfirmButton
            mutation={graphql`
              mutation TaskActionsMutationButtonStack_taskActivateMutation($input: TaskActivateInput!) {
                taskActivate(input: $input) {
                  id
                  ...TaskStatusLabel_task
                  ...TaskTaskHistoryPaginator_task
                  ...TaskActionsMutationButtonStack_task
                }
              }
            `}
            input={{ task: id }}
            size={'large'}
            message={'문제 정보 확인 후 활성화해주세요. 활성화 할까요?'}
            config={{
              onCompleted: () => {
                toast('활성화를 성공했어요', 'success');
              },
              onError: () => {
                toast('활성화를 실패했어요', 'error');
              },
            }}
          >
            활성화하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('task_deactivate') ? (
        <Stack.Item>
          <MutationConfirmButton
            variant={'danger'}
            mutation={graphql`
              mutation TaskActionsMutationButtonStack_taskDeactivateMutation($input: TaskDeactivateInput!) {
                taskDeactivate(input: $input) {
                  id
                  ...TaskStatusLabel_task
                  ...TaskTaskHistoryPaginator_task
                  ...TaskActionsMutationButtonStack_task
                }
              }
            `}
            input={{ task: id }}
            size={'large'}
            message={'정말 문제를 비활성화 할까요?'}
            config={{
              onCompleted: () => {
                toast('비활성화를 성공했어요', 'success');
              },
              onError: () => {
                toast('비활성화를 실패했어요', 'error');
              },
            }}
          >
            비활성화하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export default TaskActionsMutationButtonStack;
