import { graphql, useFragment } from 'react-relay';

import {
  SchoolExamSourceDescriptionList_schoolExamSource$data,
  SchoolExamSourceDescriptionList_schoolExamSource$key,
} from '../../../relay/__generated__/SchoolExamSourceDescriptionList_schoolExamSource.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import UserAvatarText from '../../user/UserAvatarText';

const SchoolExamSourceDescriptionList_schoolExamSource = graphql`
  fragment SchoolExamSourceDescriptionList_schoolExamSource on SchoolExamSource {
    school {
      id
      title
    }
    schoolYear
    examYear
    examType

    assignedTo {
      id
      ...UserAvatarText_user
    }
    assigned
  }
`;

type Props = {
  schoolExamSource: SchoolExamSourceDescriptionList_schoolExamSource$key;
  type?: 'default' | 'activity';
} & Omit<
  DescriptionListProps<SchoolExamSourceDescriptionList_schoolExamSource$data>,
  'item' | 'itemDescriptions' | 'picks'
>;
const SchoolExamSourceDescriptionList = ({
  schoolExamSource: schoolExamSourceReference,
  type = 'default',
  ...props
}: Props) => {
  const schoolExamSource = useFragment(SchoolExamSourceDescriptionList_schoolExamSource, schoolExamSourceReference);

  return (
    <DescriptionList
      item={schoolExamSource}
      itemDescriptions={{
        'school.title': {
          title: '학교',
          renderValue: ({ school }) => school.title,
        },
        'examYear': {
          title: '연도',
          renderValue: ({ examYear }) => <EnumPair typename={'ExamYearEnum'}>{examYear}</EnumPair>,
        },
        'schoolYear': {
          title: '학년',
          renderValue: ({ schoolYear }) => <EnumPair typename={'SchoolYearEnum'}>{schoolYear}</EnumPair>,
        },
        'examType': {
          title: '시험',
          renderValue: ({ examType }) => <EnumPair typename={'ExamTypeEnum'}>{examType}</EnumPair>,
        },
        'assignedTo': {
          title: '작업자',
          renderValue: ({ assignedTo }) => (assignedTo ? <UserAvatarText user={assignedTo} /> : undefined),
        },
        'assigned': {
          title: '배정일',
          renderValue: ({ assigned }) => (assigned ? <DateText>{assigned}</DateText> : undefined),
        },
      }}
      picks={
        type === 'default'
          ? ['school.title', 'examYear', 'schoolYear', 'examType']
          : type === 'activity'
          ? ['assignedTo', 'assigned']
          : []
      }
      {...props}
    />
  );
};

export default SchoolExamSourceDescriptionList;
