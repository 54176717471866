import { RepoIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { GraphQLError } from 'graphql/index';
import { useRouter } from 'next/router';
import React, { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import UnitAPaginatorSearchOverlayTokenField from '../../components/unitA/UnitAPaginatorSearchOverlayTokenField';
import UnitBPaginatorSearchOverlayTokenField from '../../components/unitB/UnitBPaginatorSearchOverlayTokenField';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import WorkbookConnectionDataTable from '../../components/workbook/WorkbookConnectionDataTable';
import WorkbookCreateDialog from '../../components/workbook/WorkbookCreateDialog';
import WorkbookSourceNamesSearchOverlayTokenField from '../../components/workbook/WorkbookSourceNamesSearchOverlayTokenField';
import WorkbookWindowPaginator from '../../components/workbook/WorkbookWindowPaginator';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import { WorkbookStatusEnum } from '../../relay/__generated__/WorkbookStatusLabel_workbook.graphql';
import {
  ScrapSourceExamYearEnum,
  ScrapSourceExamGradeEnum,
  ScrapSourceExamMonthEnum,
} from '../../relay/__generated__/task_tasksQuery.graphql';
import { workbook_workbooksQuery } from '../../relay/__generated__/workbook_workbooksQuery.graphql';
import { parseGraphQLError } from '../../utils/error';
import { numberWithCommas } from '../../utils/number';
import { normalizeObject } from '../../utils/object';
import { NextPage } from '../_app';

import WorkbookId from './[workbookId]';

const workbooksForWorkbook = graphql`
  query workbook_workbooksQuery($filters: WorkbookFilter, $order: WorkbookOrder, $page: Int, $pageSize: Int) {
    ...WorkbookWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const Workbook: NextPage<Props> = () => {
  const router = useRouter();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },

    status_In: { type: 'string', multiple: true },

    taskCount_Gte: { type: 'number' },
    taskCount_Lte: { type: 'number' },
    modifiedById_In: { type: 'string', multiple: true },

    taskUnitAId_In: { type: 'string', multiple: true },
    taskUnitBId_In: { type: 'string', multiple: true },

    year_In: { type: 'string', multiple: true },
    grade_In: { type: 'string', multiple: true },
    month_In: { type: 'string', multiple: true },
    sourceName_In: { type: 'string', multiple: true },

    page: { type: 'number' },
    order: { type: 'string' },
  });
  const {
    search,
    page,
    order,
    status_In,
    taskCount_Gte,
    taskCount_Lte,
    modifiedById_In,
    taskUnitAId_In,
    taskUnitBId_In,
    year_In,
    grade_In,
    month_In,
    sourceName_In,
  } = initialValues;

  const { toast } = useToast();

  return (
    <View>
      <Head siteTitle={'기출'} />
      <ErrorBoundary>
        <QueryFormik<workbook_workbooksQuery>
          query={workbooksForWorkbook}
          staticVariables={{ pageSize: 50 }}
          initialValues={{
            filters: {
              search,
              status_In: status_In as WorkbookStatusEnum[],
              taskCount_Gte,
              taskCount_Lte,
              modifiedById_In,
              taskUnitAId_In,
              taskUnitBId_In,
              year_In: year_In as ScrapSourceExamYearEnum[],
              grade_In: grade_In as ScrapSourceExamGradeEnum[],
              month_In: month_In as ScrapSourceExamMonthEnum[],
              sourceName_In,
            },
            order: order || '-modified',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, handleSubmit, setFieldValue, submitForm, resetForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>기출</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <DialogButton
                      size={'large'}
                      variant={'primary'}
                      renderDialog={({ isOpen, closeDialog }) => (
                        <WorkbookCreateDialog
                          wide
                          fillHeight
                          isOpen={isOpen}
                          onDismiss={closeDialog}
                          config={{
                            onCompleted: () => {
                              toast('생성이 완료됐어요', 'success');
                              handleSubmit();
                              closeDialog();
                            },
                            onError: (error) => {
                              toast(
                                parseGraphQLError(error as GraphQLError)?.[0].message || '생성에 실패했어요',
                                'error',
                              );
                            },
                          }}
                        />
                      )}
                    >
                      생성하기
                    </DialogButton>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'WorkbookFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'WorkbookOrder'}
                            orders={['modified', 'title']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'상태'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'WorkbookStatusEnum'}
                        label={'상태'}
                        name={'filters.status_In'}
                        placeholder={'상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'작업자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'작업자'}
                        name={'filters.modifiedById_In'}
                        placeholder={'작업자 선택'}
                        variables={{ filters: { team_In: ['contents'] } }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'기출 상세'}>
                      <Grid>
                        <Grid.Unit size={[1, 1, 1 / 4]}>
                          <EnumPairSearchOverlayTokenField
                            label={'연도'}
                            labelConfig={{ visuallyHidden: true }}
                            typename={'ScrapSourceExamYearEnum'}
                            name={'filters.year_In'}
                            placeholder={'연도 선택'}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 4]}>
                          <EnumPairSearchOverlayTokenField
                            label={'학년'}
                            labelConfig={{ visuallyHidden: true }}
                            typename={'ScrapSourceExamGradeEnum'}
                            name={'filters.grade_In'}
                            placeholder={'학년 선택'}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 4]}>
                          <EnumPairSearchOverlayTokenField
                            label={'월'}
                            labelConfig={{ visuallyHidden: true }}
                            typename={'ScrapSourceExamMonthEnum'}
                            name={'filters.month_In'}
                            placeholder={'월 선택'}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 4]}>
                          <WorkbookSourceNamesSearchOverlayTokenField
                            label={'출제기관'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.sourceName_In'}
                            placeholder={'출제기관 선택'}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'문제 Unit'}>
                      <Grid>
                        <Grid.Unit size={[1, 1, 1 / 2]}>
                          <UnitAPaginatorSearchOverlayTokenField
                            label={'Unit A'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.taskUnitAId_In'}
                            placeholder={'Unit A 선택'}
                            variables={{
                              order: { order: 'ASC' },
                            }}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={[1, 1, 1 / 2]}>
                          <UnitBPaginatorSearchOverlayTokenField
                            label={'Unit B'}
                            labelConfig={{ visuallyHidden: true }}
                            name={'filters.taskUnitBId_In'}
                            placeholder={'Unit B 선택'}
                            variables={{
                              order: { order: 'ASC' },
                              filters: {
                                unitAId_In: filters?.taskUnitAId_In,
                              },
                            }}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<workbook_workbooksQuery>>
                        {(queryReference) => (
                          <WorkbookWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ workbooks }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(workbooks.totalCount || 0)}개
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <WorkbookConnectionDataTable
                                    workbookConnection={workbooks}
                                    onRowClick={({ id }) => {
                                      const pathname = `/workbook/${id}`;
                                      router.push(
                                        { pathname, query: normalizeObject({ ...filters, order }) },
                                        pathname,
                                      );
                                    }}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </WorkbookWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

Workbook.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Workbook.authenticated = true;
Workbook.routes = [
  {
    id: 'workbook',
    name: '기출',
    pathname: '/workbook',
    icon: RepoIcon,
    permissions: ['workbook_read'],
  },
  ...WorkbookId.routes,
];

export default Workbook;
