import { graphql, useFragment } from 'react-relay';

import { QuizHistoryDialog_quizHistory$key } from '../../../relay/__generated__/QuizHistoryDialog_quizHistory.graphql';
import Avatar from '../../core/Avatar';
import DateText from '../../core/DateText';
import Dialog, { DialogProps } from '../../core/Dialog';
import Grid from '../../core/Grid';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import QuizDifferenceCounterLabel from '../QuizDifferenceCounterLabel';
import QuizDifferenceItemList from '../QuizDifferenceItemList';

const QuizHistoryDialog_quizHistory = graphql`
  fragment QuizHistoryDialog_quizHistory on QuizHistory {
    createdBy {
      name
      picture
    }
    created
    difference {
      ...QuizDifferenceItemList_difference
      ...QuizDifferenceCounterLabel_difference
    }
  }
`;

type Props = {
  quizHistory: QuizHistoryDialog_quizHistory$key;
} & DialogProps;

const QuizHistoryDialog = ({ quizHistory, ...props }: Props) => {
  const { createdBy, created, difference } = useFragment(QuizHistoryDialog_quizHistory, quizHistory);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Grid gapX={3} sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'min'}>
            <Avatar src={createdBy.picture || ''} alt={createdBy.name} size={40} />
          </Grid.Unit>
          <Grid.Unit size={'max'}>
            <Stack gapX={1}>
              <Stack.Item>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{`${createdBy.name}님의 수정 내역`}</Text>
              </Stack.Item>
              <Stack.Item>
                {difference ? <QuizDifferenceCounterLabel difference={difference} scheme={'primary'} /> : null}
              </Stack.Item>
            </Stack>
            <View>
              <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
            </View>
          </Grid.Unit>
        </Grid>
      </Dialog.Header>
      <Dialog.Body>{difference ? <QuizDifferenceItemList difference={difference} /> : null}</Dialog.Body>
    </Dialog>
  );
};

export default QuizHistoryDialog;
