import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  DeliveryAddressDescriptionList_deliveryAddress$data,
  DeliveryAddressDescriptionList_deliveryAddress$key,
} from '../../../relay/__generated__/DeliveryAddressDescriptionList_deliveryAddress.graphql';
import CopyableText from '../../core/CopyableText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';

const DeliveryAddressDescriptionList_deliveryAddress = graphql`
  fragment DeliveryAddressDescriptionList_deliveryAddress on DeliveryAddress {
    recipientNumber
    recipientName
    address
    addressDetail
    zipCode
  }
`;

type Props = { deliveryAddress: DeliveryAddressDescriptionList_deliveryAddress$key; type?: 'default' } & Omit<
  DescriptionListProps<DeliveryAddressDescriptionList_deliveryAddress$data>,
  'item' | 'itemDescriptions' | 'picks'
>;

const DeliveryAddressDescriptionList = ({
  deliveryAddress: deliveryAddressReference,
  type = 'default',
  ...props
}: Props) => {
  const deliveryAddress = useFragment(DeliveryAddressDescriptionList_deliveryAddress, deliveryAddressReference);

  return (
    <DescriptionList
      item={deliveryAddress}
      itemDescriptions={{
        recipientNumber: {
          title: '수령자 전화번호',
          renderValue: ({ recipientNumber }) => <CopyableText>{recipientNumber}</CopyableText>,
        },
        recipientName: {
          title: '수령자',
          renderValue: ({ recipientName }) => <CopyableText>{recipientName}</CopyableText>,
        },
        address: {
          title: '주소',
          renderValue: ({ address, addressDetail }) => <CopyableText>{`${address} ${addressDetail}`}</CopyableText>,
        },
        zipCode: {
          title: '우편번호',
          renderValue: ({ zipCode }) => <CopyableText>{zipCode}</CopyableText>,
        },
      }}
      picks={type === 'default' ? ['recipientName', 'recipientNumber', 'address', 'zipCode'] : []}
      {...props}
    />
  );
};

export default DeliveryAddressDescriptionList;
