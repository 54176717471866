import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  WorkbookDescriptionList_workbook$data,
  WorkbookDescriptionList_workbook$key,
} from '../../../relay/__generated__/WorkbookDescriptionList_workbook.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import WorkbookStatusLabel from '../WorkbookStatusLabel';

const WorkbookDescriptionList_workbook = graphql`
  fragment WorkbookDescriptionList_workbook on Workbook {
    id
    ...WorkbookStatusLabel_workbook
    modified
    modifiedBy {
      ...UserAvatarText_user
    }

    title
    tasks {
      totalCount
    }

    year
    grade
    month
    sourceName
  }
`;

type Props = {
  workbook: WorkbookDescriptionList_workbook$key;
  type?: 'default' | 'activity' | 'publish';
} & Omit<DescriptionListProps<WorkbookDescriptionList_workbook$data>, 'item' | 'itemDescriptions' | 'picks'>;
const WorkbookDescriptionList = ({ workbook: workbookReference, type = 'default', ...props }: Props) => {
  const workbook = useFragment(WorkbookDescriptionList_workbook, workbookReference);

  return (
    <DescriptionList
      item={workbook}
      itemDescriptions={{
        status: {
          title: '상태',
          renderValue: (workbook) => <WorkbookStatusLabel workbook={workbook} />,
        },
        modifiedBy: {
          title: '작업자',
          renderValue: ({ modifiedBy }) => (modifiedBy ? <UserAvatarText user={modifiedBy} /> : undefined),
        },
        modified: {
          title: '작업일',
          renderValue: ({ modified }) => <DateText fontSize={1}>{modified}</DateText>,
        },
        title: {
          title: '문제집명',
          renderValue: ({ title }) => <Text fontSize={1}>{title}</Text>,
        },
        taskCount: {
          title: '문제',
          renderValue: ({ tasks }) =>
            tasks?.totalCount ? <Text fontSize={1}>{tasks?.totalCount}문제</Text> : undefined,
        },
        year: {
          title: '연도',
          renderValue: ({ year }) => (
            <Text fontSize={1}>
              <EnumPair typename={'ScrapSourceExamYearEnum'}>{year}</EnumPair>
            </Text>
          ),
        },
        grade: {
          title: '학년',
          renderValue: ({ grade }) => (
            <Text fontSize={1}>
              <EnumPair typename={'ScrapSourceExamGradeEnum'}>{grade}</EnumPair>
            </Text>
          ),
        },
        month: {
          title: '월',
          renderValue: ({ month }) => (
            <Text fontSize={1}>
              <EnumPair typename={'ScrapSourceExamMonthEnum'}>{month}</EnumPair>
            </Text>
          ),
        },
        sourceName: {
          title: '출제기관',
          renderValue: ({ sourceName }) => <Text fontSize={1}>{sourceName}</Text>,
        },
      }}
      picks={
        type === 'default'
          ? ['title', 'year', 'grade', 'month', 'sourceName']
          : type === 'activity'
          ? ['status', 'modifiedBy', 'modified']
          : type === 'publish'
          ? ['title', 'taskCount', 'grade', 'month', 'sourceName']
          : []
      }
      {...props}
    />
  );
};

export default WorkbookDescriptionList;
