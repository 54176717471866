import { CheckIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { NoticeUpdateDialog_notice$key } from '../../../relay/__generated__/NoticeUpdateDialog_notice.graphql';
import { NoticeUpdateDialog_noticeUpdateMutation } from '../../../relay/__generated__/NoticeUpdateDialog_noticeUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MarkdownEditorField from '../../core/MarkdownEditorField';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const NoticeUpdateDialog_notice = graphql`
  fragment NoticeUpdateDialog_notice on Notice {
    id
    title
    description
  }
`;

type Props = { notice: NoticeUpdateDialog_notice$key } & DialogProps &
  Pick<MutationFormikProps<NoticeUpdateDialog_noticeUpdateMutation>, 'config' | 'onSubmit' | 'connections'>;

const NoticeUpdateDialog = ({ config, onSubmit, connections, notice, ...props }: Props) => {
  const { title, description, id } = useFragment(NoticeUpdateDialog_notice, notice);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>공지사항 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<NoticeUpdateDialog_noticeUpdateMutation>
        mutation={graphql`
          mutation NoticeUpdateDialog_noticeUpdateMutation($input: NoticeUpdateInput!) {
            noticeUpdate(input: $input) {
              id
              ...NoticeItem_notice
            }
          }
        `}
        initialValues={{
          id,
          title,
          description,
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField name={'title'} label={'제목'} placeholder={'제목 입력'} required />
                <MarkdownEditorField name={'description'} label={'본문'} placeholder={'본문 입력'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button leadingIcon={CheckIcon} variant={'primary'} disabled={!dirty} onClick={() => handleSubmit()}>
                작성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default NoticeUpdateDialog;
