import { graphql } from 'react-relay';

import { SchoolExamSourcePaginator_query$key } from '../../../relay/__generated__/SchoolExamSourcePaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const SchoolExamSourcePaginator_query = graphql`
  fragment SchoolExamSourcePaginator_query on Query
  @argumentDefinitions(
    filters: { type: SchoolExamSourceFilter }
    order: { type: SchoolExamSourceOrder }
    first: { type: Int }
    after: { type: String }
    scrapCount: { type: Int }
    scrapOrder: { type: SchoolExamScrapOrder }
  )
  @refetchable(queryName: "SchoolExamSourcePaginatorRefreshQuery") {
    schoolExamSources(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "SchoolExamSourcePaginator_query_schoolExamSources") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          sequence
          school {
            id
            title
            region
          }
          title
          scraps(order: $scrapOrder, first: $scrapCount) {
            edges {
              node {
                id
                scrap {
                  id
                  objectUrl
                }
              }
            }
          }
          ...SchoolExamSourceExportedPreview_schoolExamSource
            @arguments(scrapOrder: $scrapOrder, scrapCount: $scrapCount)
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<SchoolExamSourcePaginator_query$key>, 'fragment'>;

const SchoolExamSourcePaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={SchoolExamSourcePaginator_query} {...props} />
);

export default SchoolExamSourcePaginator;
