/**
 * @generated SignedSource<<42d39526a0aba57071c381e270e9e8bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly id: string;
      readonly isDefault: boolean;
      readonly title: string;
    };
  }>;
  readonly " $fragmentType": "TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection";
};
export type TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection$key = {
  readonly " $data"?: TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TypingAutomationScriptEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TypingAutomationScript",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isDefault",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TypingAutomationScriptConnection",
  "abstractKey": null
};
})();

(node as any).hash = "53b5b2cd1c19c801c46c4db78b306024";

export default node;
