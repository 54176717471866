import { graphql, useFragment } from 'react-relay';

import {
  ProductEventConnectionDataTable_productEventConnection$data,
  ProductEventConnectionDataTable_productEventConnection$key,
} from '../../../relay/__generated__/ProductEventConnectionDataTable_productEventConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import UserAvatarText from '../../user/UserAvatarText';
import ProductEventStatusLabel from '../ProductEventStatusLabel';

const ProductEventConnectionDataTable_productEventConnection = graphql`
  fragment ProductEventConnectionDataTable_productEventConnection on ProductEventConnection {
    edges {
      node {
        id
        ...ProductEventStatusLabel_productEvent
        sequence
        title
        startAt
        endAt
        createdBy {
          id
          ...UserAvatarText_user
        }
      }
    }
  }
`;

type Props = {
  productEventConnection: ProductEventConnectionDataTable_productEventConnection$key;
} & Omit<
  DataTableProps<ProductEventConnectionDataTable_productEventConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const ProductEventConnectionDataTable = ({ productEventConnection, ...props }: Props) => {
  const { edges } = useFragment(ProductEventConnectionDataTable_productEventConnection, productEventConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (productEvent) => <ProductEventStatusLabel productEvent={productEvent} />,
          width: 80,
          align: 'center',
        },
        { field: 'sequence', title: 'ID', renderValue: ({ sequence }) => sequence, width: 80 },
        { field: 'title', title: '이벤트명', renderValue: ({ title }) => title, width: 524 },
        { field: 'startAt', title: '시작일', renderValue: ({ startAt }) => <DateText>{startAt}</DateText>, width: 160 },
        { field: 'endAt', title: '종료일', renderValue: ({ endAt }) => <DateText>{endAt}</DateText>, width: 160 },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 92,
        },
      ]}
      {...props}
    />
  );
};

export default ProductEventConnectionDataTable;
