import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ScrapItem_scrap$key } from '../../../relay/__generated__/ScrapItem_scrap.graphql';
import Text from '../../core/Text';

const ScrapItem_scrap = graphql`
  fragment ScrapItem_scrap on Scrap {
    id
    problemBookPage
    solutionBookPage
    problemNumber
  }
`;

type Props = { scrap: ScrapItem_scrap$key };

const ScrapItem = ({ scrap }: Props) => {
  const { problemBookPage, problemNumber, solutionBookPage } = useFragment(ScrapItem_scrap, scrap);
  return <Text sx={{ fontSize: 1 }}>{`${problemBookPage}P의 ${problemNumber} (해설 ${solutionBookPage}P)`}</Text>;
};

export default ScrapItem;
