/**
 * @generated SignedSource<<463f69b26c4bfab15e37da00067cf23a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppDialogDeleteInput = {
  id: string;
};
export type AppDialogId_appDialogDeleteMutation$variables = {
  input: AppDialogDeleteInput;
};
export type AppDialogId_appDialogDeleteMutation$data = {
  readonly appDialogDelete: {
    readonly id: string;
  };
};
export type AppDialogId_appDialogDeleteMutation = {
  response: AppDialogId_appDialogDeleteMutation$data;
  variables: AppDialogId_appDialogDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppDialogId_appDialogDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppDialog",
        "kind": "LinkedField",
        "name": "appDialogDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppDialogId_appDialogDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppDialog",
        "kind": "LinkedField",
        "name": "appDialogDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5872e86c2c7c8ec1956e0dab77cf2b1a",
    "id": null,
    "metadata": {},
    "name": "AppDialogId_appDialogDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation AppDialogId_appDialogDeleteMutation(\n  $input: AppDialogDeleteInput!\n) {\n  appDialogDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c09df2f981d2c128058cbca5342fb0f";

export default node;
