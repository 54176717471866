import { graphql } from 'react-relay';

import { ErrorReportPaginator_query$key } from '../../../relay/__generated__/ErrorReportPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const ErrorReportPaginator_query = graphql`
  fragment ErrorReportPaginator_query on Query
  @argumentDefinitions(
    filters: { type: ErrorReportFilter }
    order: { type: ErrorReportOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "ErrorReportPaginatorQuery") {
    errorReports(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "ErrorReportsPaginator_query_errorReports") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          ...ErrorReportItem_errorReport
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<ErrorReportPaginator_query$key>, 'fragment'>;

const ErrorReportPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={ErrorReportPaginator_query} {...props} />
);

export default ErrorReportPaginator;
export type { Props as ErrorReportPaginatorProps };
