/**
 * @generated SignedSource<<966f144ec4e00e309fd1f17b37a5926e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiffItem_diff$data = {
  readonly after: string | null;
  readonly before: string | null;
  readonly " $fragmentType": "DiffItem_diff";
};
export type DiffItem_diff$key = {
  readonly " $data"?: DiffItem_diff$data;
  readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DiffItem_diff",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "before",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "after",
      "storageKey": null
    }
  ],
  "type": "Diff",
  "abstractKey": null
};

(node as any).hash = "effb4f1e82a718aa65d796474126187b";

export default node;
