import * as Sentry from '@sentry/nextjs';
import { GraphQLError, GraphQLErrorExtensions } from 'graphql';
import React, { Component, ErrorInfo, ReactNode } from 'react';

import { parseGraphQLError } from '../../../utils/error';
import ErrorState from '../ErrorState';

interface Props {
  children?: ReactNode | ((params: { fetchKey: number }) => ReactNode);
  fallback?:
    | ReactNode
    | ((params: {
        error: Error | undefined | null;
        parsedGraphQLError: GraphQLErrorExtensions[] | undefined | null;
        retry: () => void;
      }) => ReactNode);
}

interface State {
  hasError: boolean;
  fetchKey: number;
  error: Error | undefined | null;
  parsedGraphQLError: GraphQLErrorExtensions[] | undefined | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    fetchKey: 0,
    error: null,
    parsedGraphQLError: null,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(error: Error | GraphQLError): State {
    // Update state so the next render will show the fallback UI.
    return { error, parsedGraphQLError: parseGraphQLError(error as GraphQLError), hasError: true, fetchKey: 0 };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const isProduction = process.env.NODE_ENV === 'production' && process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

    console.error('Uncaught error:', error, errorInfo);
    if (isProduction) {
      Sentry.captureException(error);
    }
  }

  private retry = () => this.setState((prev) => ({ hasError: false, fetchKey: prev.fetchKey + 1 }));

  public render() {
    const { children, fallback } = this.props;
    const { fetchKey, hasError } = this.state;

    if (hasError) {
      return typeof fallback === 'function'
        ? fallback({ error: this.state.error, parsedGraphQLError: this.state.parsedGraphQLError, retry: this.retry })
        : fallback ?? <ErrorState description={'잠시 후에 다시 시도해 주세요'} />;
    }

    return typeof children === 'function' ? children({ fetchKey: fetchKey }) : children;
  }
}

export default ErrorBoundary;
