/**
 * @generated SignedSource<<551fa2880e0b44e3c057f191372fd5e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ScrapSourceScrapDeleteDialog_scrapQuery$variables = {
  id: string;
};
export type ScrapSourceScrapDeleteDialog_scrapQuery$data = {
  readonly scrap: {
    readonly id: string;
    readonly problemBookPage: number;
    readonly problemNumber: string;
    readonly problems: ReadonlyArray<{
      readonly id: string;
      readonly objectUrl: string;
    }>;
    readonly solutionBookPage: number;
    readonly solutions: ReadonlyArray<{
      readonly id: string;
      readonly objectUrl: string;
    }>;
  };
};
export type ScrapSourceScrapDeleteDialog_scrapQuery = {
  response: ScrapSourceScrapDeleteDialog_scrapQuery$data;
  variables: ScrapSourceScrapDeleteDialog_scrapQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Scrap",
    "kind": "LinkedField",
    "name": "scrap",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problemBookPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "solutionBookPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problemNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "problems",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "File",
        "kind": "LinkedField",
        "name": "solutions",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScrapSourceScrapDeleteDialog_scrapQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScrapSourceScrapDeleteDialog_scrapQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "ec0dc4d1589fcfa5c9568ae99a394f26",
    "id": null,
    "metadata": {},
    "name": "ScrapSourceScrapDeleteDialog_scrapQuery",
    "operationKind": "query",
    "text": "query ScrapSourceScrapDeleteDialog_scrapQuery(\n  $id: ID!\n) {\n  scrap(id: $id) {\n    id\n    problemBookPage\n    solutionBookPage\n    problemNumber\n    problems {\n      id\n      objectUrl\n    }\n    solutions {\n      id\n      objectUrl\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9454facb630ef7f4fdf714de352f5ea9";

export default node;
