/**
 * @generated SignedSource<<e2ff882673bc5bed1f7be232ea65b255>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useOrderPairsQuery_orderPairsQuery$variables = {
  typename: string;
};
export type useOrderPairsQuery_orderPairsQuery$data = {
  readonly orderPairs: ReadonlyArray<{
    readonly key: string;
    readonly value: string;
  }>;
};
export type useOrderPairsQuery_orderPairsQuery = {
  response: useOrderPairsQuery_orderPairsQuery$data;
  variables: useOrderPairsQuery_orderPairsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "typename"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "typename",
        "variableName": "typename"
      }
    ],
    "concreteType": "Pair",
    "kind": "LinkedField",
    "name": "orderPairs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useOrderPairsQuery_orderPairsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useOrderPairsQuery_orderPairsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1a198424e3bb939feca83cb16235f468",
    "id": null,
    "metadata": {},
    "name": "useOrderPairsQuery_orderPairsQuery",
    "operationKind": "query",
    "text": "query useOrderPairsQuery_orderPairsQuery(\n  $typename: String!\n) {\n  orderPairs(typename: $typename) {\n    key\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8be01454065385595c41696f9d2d152";

export default node;
