import { ReactNode } from 'react';
import { Disposable, GraphQLTaggedNode, useRefetchableFragment } from 'react-relay';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import { Options } from 'react-relay/relay-hooks/useRefetchableFragmentNode';

type Props<TKey extends KeyType> = {
  fragment: GraphQLTaggedNode;
  fragmentReference: TKey;
  children: (
    data: Required<TKey>[' $data'],
    {
      loadPage,
      renderPagination,
    }: { loadPage: (page: number, options?: Options) => Disposable; renderPagination?: () => React.ReactNode },
  ) => ReactNode;
  onLoadPage?: (page?: number) => void;
};

const FragmentWindowPaginator = <TKey extends KeyType>({
  fragment,
  fragmentReference,
  onLoadPage,
  children,
}: Props<TKey>) => {
  const [data, refetch] = useRefetchableFragment(fragment, fragmentReference);

  const loadPage = (page: number, options?: Options) => {
    onLoadPage?.(page);
    return refetch({ page }, options);
  };

  return children(data, { loadPage }) as JSX.Element;
};

export default FragmentWindowPaginator;
export type { Props as FragmentWindowPaginatorProps };
