/**
 * @generated SignedSource<<255ed1f1bbdd9f9f9c4db4cd3dc1fc7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WorkbookActionEnum = "workbook_delete" | "workbook_publish" | "workbook_tasks_add" | "workbook_tasks_delete" | "workbook_tasks_reorder" | "workbook_update" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkbookTasksReorderDialog_workbook$data = {
  readonly actions: ReadonlyArray<WorkbookActionEnum>;
  readonly id: string;
  readonly tasks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly difficulty: number | null;
        readonly id: string;
        readonly problem: {
          readonly problem: string | null;
          readonly solution: string | null;
        } | null;
        readonly sequence: number;
        readonly unitD: {
          readonly description: string | null;
          readonly id: string;
          readonly unitATitle: string;
          readonly unitBTitle: string;
          readonly unitCTitle: string;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"TaskSequenceItem_task">;
      };
    }>;
    readonly totalCount: number | null;
  };
  readonly " $fragmentType": "WorkbookTasksReorderDialog_workbook";
};
export type WorkbookTasksReorderDialog_workbook$key = {
  readonly " $data"?: WorkbookTasksReorderDialog_workbook$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkbookTasksReorderDialog_workbook">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkbookTasksReorderDialog_workbook",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskConnection",
      "kind": "LinkedField",
      "name": "tasks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Task",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sequence",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "difficulty",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TaskSequenceItem_task"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TaskProblem",
                  "kind": "LinkedField",
                  "name": "problem",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "problem",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "solution",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UnitD",
                  "kind": "LinkedField",
                  "name": "unitD",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitATitle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitBTitle",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "unitCTitle",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Workbook",
  "abstractKey": null
};
})();

(node as any).hash = "f08d858d4554f245622990195fcf8386";

export default node;
