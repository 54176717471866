/**
 * @generated SignedSource<<28521703ecb77f045e6c2132b2cfc2e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BillStatusEnum = "completed" | "pending" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BillStatusLabel_bill$data = {
  readonly status: BillStatusEnum;
  readonly " $fragmentType": "BillStatusLabel_bill";
};
export type BillStatusLabel_bill$key = {
  readonly " $data"?: BillStatusLabel_bill$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillStatusLabel_bill">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillStatusLabel_bill",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Bill",
  "abstractKey": null
};

(node as any).hash = "62e76dcb08e9d23f13a532386066e1fc";

export default node;
