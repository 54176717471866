import { CheckIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql } from 'react-relay';

import { DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation } from '../../../relay/__generated__/DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import UploadField from '../../core/UploadField';
import View from '../../core/View';

type Props = {} & DialogProps &
  Pick<
    MutationFormikProps<DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const DeliveryOrderXlsxImportDialog = ({ config, onSubmit, connections, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>xlsx 업로드</Text>
        <View>
          <Text sx={{ fontSize: 1, fontWeight: 'normal', color: 'fg.subtle' }}>
            다운로드 받은 운송장 xlsx 파일을 수정없이 업로드 해주세요
          </Text>
        </View>
      </Dialog.Header>
      <MutationFormik<DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation>
        mutation={graphql`
          mutation DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation(
            $input: DeliveryOrderXlsxImportInput!
          ) {
            deliveryOrderXlsxImport(input: $input) {
              id
              delivered
              deliveredBy {
                id
                ...UserAvatarText_user
              }
              ...DeliveryOrderStatusLabel_deliveryOrder
            }
          }
        `}
        initialValues={{ file: { key: '', size: 0 } }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ dirty, handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <UploadField
                label={'파일'}
                name={'file'}
                accept={'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
              />
            </Dialog.Body>
            <Dialog.Footer>
              <Button disabled={!dirty} onClick={() => handleSubmit()} variant={'primary'} leadingIcon={CheckIcon}>
                업로드 하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default DeliveryOrderXlsxImportDialog;
