import useEnumPairsQuery from '../../../hooks/useEnumPairsQuery';
import CheckboxGroupField, { CheckboxGroupFieldProps } from '../CheckboxGroupField';

type Props = {
  typename: string;
} & Omit<CheckboxGroupFieldProps, 'options'>;

const EnumPairCheckboxGroupField = ({ typename, ...props }: Props) => {
  const enumPairs = useEnumPairsQuery(typename);

  return <CheckboxGroupField options={enumPairs.map(({ key, value }) => ({ id: key, text: value }))} {...props} />;
};

export default EnumPairCheckboxGroupField;
export type { Props as EnumPairCheckboxGroupFieldProps };
