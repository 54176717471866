import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import { RaffleStuffCreateDialog_raffleStuffCreateMutation } from '../../../relay/__generated__/RaffleStuffCreateDialog_raffleStuffCreateMutation.graphql';
import Button from '../../core/Button';
import ColorPickField from '../../core/ColorPickField';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';
import UploadField from '../../core/UploadField';
import StuffSelectField from '../../stuff/StuffSelectField';

type Props = {} & DialogProps &
  Pick<MutationFormikProps<RaffleStuffCreateDialog_raffleStuffCreateMutation>, 'onSubmit' | 'config' | 'connections'>;

const RaffleStuffCreateDialog = ({ config, onSubmit, connections, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>새로운 뽑기왕 경품 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<RaffleStuffCreateDialog_raffleStuffCreateMutation>
        mutation={graphql`
          mutation RaffleStuffCreateDialog_raffleStuffCreateMutation($input: RaffleStuffCreateInput!) {
            raffleStuffCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{
          stuff: {
            id: '',
          },
          title: '',
          description: null,
          colorThumbnail: {
            key: '',
            size: 0,
          },
          blackThumbnail: {
            key: '',
            size: 0,
          },
          backgroundColor: '',
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <Suspense>
                  <StuffSelectField name={'stuff.id'} label={'물품'} placeholder={'물품 선택'} required />
                </Suspense>
                <TextField
                  name={'title'}
                  label={'제목'}
                  placeholder={'제목 입력'}
                  caption={
                    '수학대왕 앱 뽑기왕 매쓰킹에서 각 카드를 눌렀을 때 나오는 다이얼로그에서 제목으로 보여집니다.'
                  }
                  required
                />
                <TextareaField name={'description'} label={'설명'} placeholder={'설명 입력'} />
                <UploadField
                  name={'colorThumbnail'}
                  label={'컬러 이미지'}
                  placeholder={'컬러 이미지를 선택하세요'}
                  accept={'image/*'}
                  required
                />
                <UploadField
                  name={'blackThumbnail'}
                  label={'흑백 이미지'}
                  placeholder={'흑백 이미지를 선택하세요'}
                  accept={'image/*'}
                  required
                />
                <ColorPickField name={'backgroundColor'} label={'배경색'} placeholder={'배경색 선택'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default RaffleStuffCreateDialog;
