import { FileBadgeIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import QuizSetConnectionDataTable from '../../components/quizSet/QuizSetConnectionDataTable';
import QuizSetWindowPaginator from '../../components/quizSet/QuizSetWindowPaginator';
import UnitAPaginatorSearchOverlayTokenField from '../../components/unitA/UnitAPaginatorSearchOverlayTokenField';
import UnitBPaginatorSearchOverlayTokenField from '../../components/unitB/UnitBPaginatorSearchOverlayTokenField';
import UnitCPaginatorSearchOverlayTokenField from '../../components/unitC/UnitCPaginatorSearchOverlayTokenField';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import { QuizSetStatusEnum, quizSet_quizSetsQuery } from '../../relay/__generated__/quizSet_quizSetsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import QuizSetId from './[quizSetId]';

type Props = {};

const quizSetsForQuizSet = graphql`
  query quizSet_quizSetsQuery($filters: QuizSetFilter, $order: QuizSetOrder, $page: Int, $pageSize: Int) {
    ...QuizSetWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

const QuizSet: NextPage<Props> = () => {
  const router = useRouter();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_In: { type: 'string', multiple: true },
    unitAId_In: { type: 'string', multiple: true },
    unitBId_In: { type: 'string', multiple: true },
    unitCId_In: { type: 'string', multiple: true },
    modifiedById_In: { type: 'string', multiple: true },
    page: { type: 'number' },
    order: { type: 'string' },
  });

  const { search, status_In, unitAId_In, unitBId_In, unitCId_In, modifiedById_In, page, order } = initialValues;

  return (
    <View>
      <Head siteTitle={'OX 퀴즈 맛보기'} />
      <QueryFormik<quizSet_quizSetsQuery>
        query={quizSetsForQuizSet}
        staticVariables={{ pageSize: 20 }}
        initialValues={{
          filters: {
            search,
            status_In: status_In as QuizSetStatusEnum[],
            unitAId_In,
            unitBId_In,
            unitCId_In,
            modifiedById_In,
          },
          order: order || '-modified',
          page,
        }}
        options={{ fetchPolicy: 'store-and-network' }}
        onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
        enableReinitialize
      >
        {({ values: { filters, order }, setFieldValue, submitForm, resetForm }, queryReference) => {
          const handleReset = () => {
            resetForm({ values: { order } });
            setParsedUrlQuery({ order }, { scroll: false });
          };

          const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
            newValue,
          ) => {
            setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
          };

          return (
            <View>
              <Text as={'h1'}>OX 퀴즈 맛보기</Text>
              <Grid>
                <Grid.Unit size={'min'}>
                  <QueryFormik.FilterSearchTextField
                    typename={'QuizSetFilter'}
                    label={'Search'}
                    labelConfig={{ visuallyHidden: true }}
                    name={'filters.search'}
                    size={'large'}
                    autoComplete={'off'}
                    leadingVisual={SearchIcon}
                    debounce
                    onChange={(e) => {
                      setFieldValue('filters.search', e.target.value);
                      setTimeout(() => submitForm(), 0);
                    }}
                  />
                </Grid.Unit>
                <Grid.Unit size={'max'}>
                  <View sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Stack gapX={2}>
                      <Stack.Item>
                        <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                          초기화
                        </Button>
                      </Stack.Item>
                      <Stack.Item>
                        <QueryFormik.OrderActionMenuButton
                          typename={'QuizSetOrder'}
                          orders={['modified']}
                          onChange={handleChangeOrderActionMenu}
                        />
                      </Stack.Item>
                    </Stack>
                  </View>
                </Grid.Unit>
              </Grid>
              <View sx={{ marginTop: 5 }}>
                <QueryFormik.FilterGrid
                  onChange={(filters) => {
                    setParsedUrlQuery({ ...filters, order }, { scroll: false });
                  }}
                >
                  <QueryFormik.FilterGridUnit size={1} title={'상태'}>
                    <EnumPairSearchOverlayTokenField
                      typename={'QuizSetStatusEnum'}
                      label={'상태'}
                      name={'filters.status_In'}
                      placeholder={'상태 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={1} title={'Unit'}>
                    <UnitAPaginatorSearchOverlayTokenField
                      label={'Unit A'}
                      name={'filters.unitAId_In'}
                      placeholder={'Unit A 선택'}
                      variables={{
                        order: { order: 'ASC' },
                        filters: { unitSchoolType_In: ['high_school', 'middle_school'] },
                      }}
                    />
                    <UnitBPaginatorSearchOverlayTokenField
                      label={'Unit B'}
                      name={'filters.unitBId_In'}
                      placeholder={'Unit B 선택'}
                      variables={{
                        order: { order: 'ASC' },
                        filters: {
                          unitSchoolType_In: ['high_school', 'middle_school'],
                          unitAId_In: filters?.unitAId_In,
                        },
                      }}
                    />
                    <UnitCPaginatorSearchOverlayTokenField
                      label={'Unit C'}
                      name={'filters.unitCId_In'}
                      placeholder={'Unit C 선택'}
                      variables={{
                        order: { order: 'ASC' },
                        filters: {
                          unitSchoolType_In: ['high_school', 'middle_school'],
                          unitAId_In: filters?.unitAId_In,
                          unitBId_In: filters?.unitBId_In,
                        },
                      }}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={1} title={'지정자'}>
                    <UserPaginatorSearchOverlayTokenField
                      label={'지정자'}
                      name={'filters.modifiedById_In'}
                      placeholder={'지정자 선택'}
                    />
                  </QueryFormik.FilterGridUnit>
                </QueryFormik.FilterGrid>
              </View>
              <View sx={{ marginTop: 5 }}>
                <ErrorBoundary key={queryReference?.fetchKey}>
                  <Suspense fallback={<Spinner />}>
                    <QueryFormik.PreloadedQueryRenderer<quizSet_quizSetsQuery>>
                      {(queryReference) => (
                        <QuizSetWindowPaginator
                          fragmentReference={queryReference}
                          onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                        >
                          {({ quizSets }, { renderPagination }) => (
                            <>
                              <View>
                                <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                  총 {numberWithCommas(quizSets.totalCount || 0)}개
                                </Text>
                              </View>
                              <View sx={{ marginTop: 3 }}>
                                <QuizSetConnectionDataTable
                                  quizSetConnection={quizSets}
                                  onRowClick={({ id }) => router.push({ pathname: `/quizSet/${id}` })}
                                  emptyState={
                                    <View sx={{ paddingY: 3 }}>
                                      <EmptyState
                                        title={'필터에 맞는 결과가 없어요'}
                                        description={'다른 필터로 다시 시도해보세요.'}
                                      />
                                    </View>
                                  }
                                />
                              </View>
                              <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                {renderPagination?.()}
                              </View>
                            </>
                          )}
                        </QuizSetWindowPaginator>
                      )}
                    </QueryFormik.PreloadedQueryRenderer>
                  </Suspense>
                </ErrorBoundary>
              </View>
            </View>
          );
        }}
      </QueryFormik>
    </View>
  );
};

QuizSet.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
QuizSet.authenticated = true;
QuizSet.routes = [
  { id: 'quizSet', icon: FileBadgeIcon, pathname: '/quizSet', name: 'OX 퀴즈 맛보기', permissions: ['quiz_set_read'] },
  ...QuizSetId.routes,
];

export default QuizSet;
