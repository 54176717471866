import { graphql, useFragment } from 'react-relay';

import {
  PremiumConnectionDataTable_PremiumApplyHistoryConnection$data,
  PremiumConnectionDataTable_PremiumApplyHistoryConnection$key,
} from '../../../relay/__generated__/PremiumConnectionDataTable_PremiumApplyHistoryConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';

const PremiumConnectionDataTable_PremiumApplyHistoryConnection = graphql`
  fragment PremiumConnectionDataTable_PremiumApplyHistoryConnection on MATPremiumApplyHistoryConnection {
    edges {
      node {
        id
        startAt
        endAt
        item {
          name
          premiumType
        }
        ...PremiumUpdateDialog_premium
        ...PremiumDeleteConfirmButton_premium
      }
    }
  }
`;

type Props = {
  PremiumApplyHistoryConnection: PremiumConnectionDataTable_PremiumApplyHistoryConnection$key;
} & Omit<
  DataTableProps<PremiumConnectionDataTable_PremiumApplyHistoryConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const PremiumConnectionDataTable = ({ PremiumApplyHistoryConnection, ...props }: Props) => {
  const { edges } = useFragment(
    PremiumConnectionDataTable_PremiumApplyHistoryConnection,
    PremiumApplyHistoryConnection,
  );

  return (
    <DataTable
      rows={edges.map((edge) => edge.node)}
      columns={[
        {
          field: 'item.name',
          title: '이용권명',
          renderValue: ({ item }) => <Text fontSize={1}> {item.name} </Text>,
          width: 80,
          align: 'center',
        },
        {
          field: 'item.premiumType',
          title: '이용권 타입',
          renderValue: ({ item }) => (
            <Text fontSize={1}>
              <EnumPair typename={'MATPremiumTypeEnum'}>{item.premiumType}</EnumPair>{' '}
            </Text>
          ),
          width: 80,
          align: 'center',
        },
        {
          field: 'startAt',
          title: '시작일',
          renderValue: ({ startAt }) => <Text fontSize={1}> {startAt} </Text>,
          width: 80,
          align: 'center',
        },
        {
          field: 'endAt',
          title: '종료일',
          renderValue: ({ endAt }) => <Text fontSize={1}> {endAt} </Text>,
          width: 80,
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

export default PremiumConnectionDataTable;
