import { BookIcon, SearchIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import BookConnectionDataTable from '../../components/book/BookConnectionDataTable';
import BookCreateDialog from '../../components/book/BookCreateDialog';
import BookPublisherSearchOverlayTokenField from '../../components/book/BookPublisherSearchOverlayTokenField';
import BookWindowPaginator from '../../components/book/BookWindowPaginator';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairCheckboxGroupField from '../../components/core/EnumPairCheckboxGroupField';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import UnitAPaginatorSearchOverlayTokenField from '../../components/unitA/UnitAPaginatorSearchOverlayTokenField';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import { book_booksQuery, BookStatusEnum, BookTypeEnum } from '../../relay/__generated__/book_booksQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { normalizeObject } from '../../utils/object';
import { NextPage } from '../_app';

import BookId from './[bookId]';

const booksForBook = graphql`
  query book_booksQuery($filters: BookFilter, $order: BookOrder, $page: Int, $pageSize: Int) {
    ...BookWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const Book: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },
    status_In: { type: 'string', multiple: true },
    type_In: { type: 'string', multiple: true },
    unitAId_In: { type: 'string', multiple: true },
    publisher_In: { type: 'string', multiple: true },
    submittedBy_In: { type: 'string', multiple: true },
  });
  const { search, order, page, status_In, type_In, unitAId_In, publisher_In, submittedBy_In } = initialValues;

  return (
    <View>
      <Head siteTitle={'시중 교재 스크랩'} />
      <QueryFormik<book_booksQuery>
        query={booksForBook}
        staticVariables={{ pageSize: 20 }}
        initialValues={{
          filters: {
            search,
            status_In: status_In as BookStatusEnum[],
            type_In: type_In as BookTypeEnum[],
            unitAId_In,
            publisher_In,
            submittedBy_In,
          },
          order: order || 'created',
          page,
        }}
        options={{ fetchPolicy: 'store-and-network' }}
        onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
        enableReinitialize
      >
        {({ values: { filters }, setFieldValue, submitForm }, queryReference) => {
          const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
            newValue,
          ) => {
            setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
          };

          return (
            <View>
              <Grid sx={{ alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <Text as={'h1'}>시중 교재 스크랩</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <DialogButton
                    size={'large'}
                    variant={'primary'}
                    renderDialog={({ isOpen, closeDialog }) => (
                      <BookCreateDialog
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        wide
                        config={{
                          onCompleted: () => {
                            toast('PDF 업로드에 성공했어요', 'success');
                            submitForm();
                            closeDialog();
                          },
                          onError: () => {
                            toast('PDF 업로드에 실패했어요', 'error');
                          },
                        }}
                      />
                    )}
                  >
                    PDF 업로드
                  </DialogButton>
                </Grid.Unit>
              </Grid>
              <Grid>
                <Grid.Unit size={'min'}>
                  <QueryFormik.FilterSearchTextField
                    typename={'BookFilter'}
                    label={'Search'}
                    labelConfig={{ visuallyHidden: true }}
                    name={'filters.search'}
                    autoComplete={'off'}
                    leadingVisual={SearchIcon}
                    size={'large'}
                    debounce
                    onChange={(e) => {
                      setFieldValue('filters.search', e.target.value);
                      setTimeout(() => submitForm(), 0);
                    }}
                  />
                </Grid.Unit>
                <Grid.Unit size={'max'}>
                  <View sx={{ display: 'flex', justifyContent: 'end' }}>
                    <QueryFormik.OrderActionMenuButton
                      typename={'BookOrder'}
                      orders={['created', 'title']}
                      onChange={handleChangeOrderActionMenu}
                    />
                  </View>
                </Grid.Unit>
              </Grid>
              <View sx={{ marginTop: 5 }}>
                <QueryFormik.FilterGrid
                  onChange={(filters) => {
                    setParsedUrlQuery({ ...filters, order }, { scroll: false });
                  }}
                >
                  <QueryFormik.FilterGridUnit size={1 / 2} title={'상태'}>
                    <EnumPairSearchOverlayTokenField
                      typename={'BookStatusEnum'}
                      label={'상태'}
                      placeholder={'상태 선택'}
                      name={'filters.status_In'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={1 / 2} title={'제출자'}>
                    <UserPaginatorSearchOverlayTokenField
                      label={'제출자'}
                      placeholder={'제출자 선택'}
                      name={'filters.submittedBy_In'}
                    />
                  </QueryFormik.FilterGridUnit>
                  <QueryFormik.FilterGridUnit size={1} title={'교재 정보'}>
                    <Grid>
                      <Grid.Unit size={1 / 3}>
                        <EnumPairCheckboxGroupField
                          typename={'BookTypeEnum'}
                          label={'교재 타입'}
                          labelConfig={{ visuallyHidden: true }}
                          name={'filters.type_In'}
                          renderContainer={(children) => (
                            <View sx={{ paddingX: 2, paddingY: 1 }}>
                              <Grid gapX={2} sx={{ alignItems: 'center' }}>
                                {children}
                              </Grid>
                            </View>
                          )}
                          renderOptionWrapper={(children, { id }) => (
                            <Grid.Unit key={id} size={'min'}>
                              {children}
                            </Grid.Unit>
                          )}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={1 / 3}>
                        <UnitAPaginatorSearchOverlayTokenField
                          label={'Unit A'}
                          labelConfig={{ visuallyHidden: true }}
                          name={'filters.unitAId_In'}
                          placeholder={'Unit A 선택'}
                          variables={{ order: { order: 'ASC' } }}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={1 / 3}>
                        <BookPublisherSearchOverlayTokenField
                          label={'출판사'}
                          labelConfig={{ visuallyHidden: true }}
                          name={'filters.publisher_In'}
                          placeholder={'출판사 검색'}
                        />
                      </Grid.Unit>
                    </Grid>
                  </QueryFormik.FilterGridUnit>
                </QueryFormik.FilterGrid>
              </View>
              <View sx={{ marginTop: 5 }}>
                <ErrorBoundary key={queryReference?.fetchKey}>
                  <Suspense fallback={<Spinner />}>
                    <QueryFormik.PreloadedQueryRenderer<book_booksQuery>>
                      {(queryReference) => (
                        <BookWindowPaginator
                          fragmentReference={queryReference}
                          onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                        >
                          {({ books }, { renderPagination }) => (
                            <>
                              <View>
                                <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                  총 {numberWithCommas(books.totalCount || 0)}개
                                </Text>
                              </View>
                              <View sx={{ marginTop: 3 }}>
                                <BookConnectionDataTable
                                  bookConnection={books}
                                  onRowClick={({ id }) => {
                                    const pathname = `/book/${id}`;
                                    router.push({ pathname, query: normalizeObject({ ...filters, order }) }, pathname);
                                  }}
                                  emptyState={
                                    <View sx={{ paddingY: 3 }}>
                                      <EmptyState title={'교재가 없어요'} />
                                    </View>
                                  }
                                />
                              </View>
                              <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                {renderPagination?.()}
                              </View>
                            </>
                          )}
                        </BookWindowPaginator>
                      )}
                    </QueryFormik.PreloadedQueryRenderer>
                  </Suspense>
                </ErrorBoundary>
              </View>
            </View>
          );
        }}
      </QueryFormik>
    </View>
  );
};

Book.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Book.authenticated = true;
Book.routes = [
  {
    id: 'book',
    icon: BookIcon,
    pathname: '/book',
    name: '시중 교재 스크랩',
    permissions: ['book_read'],
  },
  ...BookId.routes,
];

export default Book;
