import { LoadMoreFn, RefetchFnDynamic, usePaginationFragment } from 'react-relay';
import { KeyType } from 'react-relay/relay-hooks/helpers';
import { GraphQLTaggedNode, OperationType } from 'relay-runtime';

type Props<TKey extends KeyType> = {
  fragment: GraphQLTaggedNode;
  fragmentReference: TKey;
  children: (
    data: Required<TKey>[' $data'],
    {
      loadMore,
      hasNext,
      isLoadingNext,
      refetch,
    }: {
      loadMore: LoadMoreFn<OperationType>;
      hasNext: boolean;
      isLoadingNext: boolean;
      refetch: RefetchFnDynamic<OperationType, TKey>;
    },
  ) => React.ReactNode;
};

const FragmentPaginator = <TKey extends KeyType>({ fragment, fragmentReference, children }: Props<TKey>) => {
  const {
    data,
    loadNext: loadMore,
    hasNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment(fragment, fragmentReference);

  return children(data, { loadMore, hasNext, isLoadingNext, refetch }) as JSX.Element;
};

export default FragmentPaginator;
export type { Props as FragmentPaginatorProps };
