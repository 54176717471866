import createCtx from '../../utils/context';

type HeaderSidebarLayoutContextValue = {
  isSidebarCollapsed: boolean;
  setIsSidebarCollapsed: (value: boolean) => void;
  headerHeight: number;
};

const [useContext, Provider] = createCtx<HeaderSidebarLayoutContextValue>();

export { useContext as useHeaderSidebarLayoutContext, Provider as HeaderSidebarLayoutContextProvider };
