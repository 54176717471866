import { Timeline as PrimerTimeline, TimelineProps as PrimerTimelineProps } from '@primer/react';

type Props = {} & PrimerTimelineProps;

const Timeline = ({ ...props }: Props) => <PrimerTimeline {...props} />;

export default Object.assign(Timeline, {
  Item: PrimerTimeline.Item,
  Badge: PrimerTimeline.Badge,
  Body: PrimerTimeline.Body,
  Break: PrimerTimeline.Break,
});
export type { Props as TimelineProps };
