/**
 * @generated SignedSource<<fbcc0c2b6561ec925f1dd2454b68dac4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TypingAutomationHistoryCreateInput = {
  description: string;
  isDefault: boolean;
  problem: string;
  solution: string;
  task: string;
  title?: string | null;
};
export type TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: TypingAutomationHistoryCreateInput;
};
export type TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation$data = {
  readonly typingAutomationHistoryCreate: {
    readonly id: string;
  };
};
export type TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation = {
  response: TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation$data;
  variables: TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": (v2/*: any*/),
  "concreteType": "TypingAutomationHistory",
  "kind": "LinkedField",
  "name": "typingAutomationHistoryCreate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation",
    "selections": [
      (v3/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": null,
        "handle": "appendNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "typingAutomationHistoryCreate",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "TypingAutomationHistoryEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "572df7e9740529ce0a378dead06ee421",
    "id": null,
    "metadata": {},
    "name": "TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation",
    "operationKind": "mutation",
    "text": "mutation TaskTypingAutomationHistoryCreateDialog_typingAutomationHistortCreateMutation(\n  $input: TypingAutomationHistoryCreateInput!\n) {\n  typingAutomationHistoryCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "aef7faf19c7757c408400fd571313381";

export default node;
