import { graphql } from 'react-relay';

import { QuizQuizHistoryPaginator_quiz$key } from '../../../relay/__generated__/QuizQuizHistoryPaginator_quiz.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const QuizQuizHistoryPaginator_quiz = graphql`
  fragment QuizQuizHistoryPaginator_quiz on Quiz
  @argumentDefinitions(
    order: { type: QuizHistoryOrder, defaultValue: { created: DESC } }
    first: { type: Int, defaultValue: 10 }
    after: { type: String }
  )
  @refetchable(queryName: "QuizQuizHistoryPaginatorRefreshQuery") {
    histories(order: $order, first: $first, after: $after) @connection(key: "QuizQuizHistoryPaginator_quiz_histories") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          type
          ...QuizHistoryDialog_quizHistory
          ...QuizHistoryTimelineItem_quizHistory
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<QuizQuizHistoryPaginator_quiz$key>, 'fragment'>;

const QuizQuizHistoryPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={QuizQuizHistoryPaginator_quiz} {...props} />
);

export default QuizQuizHistoryPaginator;
