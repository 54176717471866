/**
 * @generated SignedSource<<ff9224788fe303328dcddf2a8231e08d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProductEventUserYearEnum = "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3" | "high_school_N" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
export type ProductEventCreateInput = {
  description?: string | null;
  discounts: ReadonlyArray<ProductDiscountInput>;
  endAt: any;
  startAt: any;
  title: string;
  userYears: ReadonlyArray<ProductEventUserYearEnum>;
};
export type ProductDiscountInput = {
  amount: number;
  productId: string;
};
export type ProductEventCreateDialog_productEventCreateMutation$variables = {
  input: ProductEventCreateInput;
};
export type ProductEventCreateDialog_productEventCreateMutation$data = {
  readonly productEventCreate: {
    readonly id: string;
  };
};
export type ProductEventCreateDialog_productEventCreateMutation = {
  response: ProductEventCreateDialog_productEventCreateMutation$data;
  variables: ProductEventCreateDialog_productEventCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ProductEvent",
    "kind": "LinkedField",
    "name": "productEventCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductEventCreateDialog_productEventCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductEventCreateDialog_productEventCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f7ae9e37b372587aacc396d395ab3527",
    "id": null,
    "metadata": {},
    "name": "ProductEventCreateDialog_productEventCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ProductEventCreateDialog_productEventCreateMutation(\n  $input: ProductEventCreateInput!\n) {\n  productEventCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6dce115e6bd44617bdcbbe5e35ad4f57";

export default node;
