/**
 * @generated SignedSource<<8b71c2bc11eed7432e72533cdcaf8b21>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserUpdateDialog_user$data = {
  readonly id: string;
  readonly joinedAt: any | null;
  readonly slackId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  readonly " $fragmentType": "UserUpdateDialog_user";
};
export type UserUpdateDialog_user$key = {
  readonly " $data"?: UserUpdateDialog_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserUpdateDialog_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserUpdateDialog_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slackId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "joinedAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserAvatarText_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "39b9fba2c56d1f6e278088f41e715ae4";

export default node;
