import { PropsWithChildren, useEffect, useRef } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import Grid from '../Grid';

type Props<
  TQuery extends {
    readonly response: {};
    readonly variables: { readonly filters?: { readonly search?: string | null } | null };
  },
> = {
  onChange: (filters: TQuery['variables']['filters']) => void;
};

const FilterGrid = <
  TQuery extends {
    readonly response: {};
    readonly variables: { readonly filters?: { readonly search?: string | null } | null };
  },
>({
  children,
  onChange,
}: PropsWithChildren<Props<TQuery>>) => {
  const {
    initialValues: { filters: initialFilters },
    values: { filters },
    dirty,
  } = useFormikContext<TQuery['variables']>();

  const searchRef = useRef<string | undefined | null>(initialFilters?.search);

  useEffect(() => {
    if (searchRef.current !== filters?.search) {
      searchRef.current = filters?.search;
      return;
    }

    if (dirty) onChange?.(filters);
  }, [filters]);

  return (
    <Grid
      sx={{
        'borderTopWidth': 1,
        'borderTopStyle': 'solid',
        'borderTopColor': 'border.default',
        'width': '100%',
        'fontSize': 1,
        '& > div': {
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: 'border.default',
        },
      }}
    >
      {children}
    </Grid>
  );
};

export default FilterGrid;
