/**
 * @generated SignedSource<<3e58a9a0ec41c38f6e36805a69511c2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductShowTypeEnum = "non_registered" | "private" | "repurchase" | "store" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDescriptionList_product$data = {
  readonly additionalDiscountAmount: number;
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly discountPrice: number;
  readonly id: string;
  readonly price: number;
  readonly productPrice: {
    readonly discountPercentage: number;
    readonly monthlyPrice: number;
  };
  readonly showTypes: ReadonlyArray<ProductShowTypeEnum>;
  readonly storeStuff: {
    readonly endAt: any | null;
    readonly id: string;
    readonly stuff: {
      readonly id: string;
      readonly title: string;
    } | null;
    readonly title: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"ProductStatusLabel_product">;
  readonly " $fragmentType": "ProductDescriptionList_product";
};
export type ProductDescriptionList_product$key = {
  readonly " $data"?: ProductDescriptionList_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDescriptionList_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDescriptionList_product",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreStuff",
      "kind": "LinkedField",
      "name": "storeStuff",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Stuff",
          "kind": "LinkedField",
          "name": "stuff",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductPrice",
      "kind": "LinkedField",
      "name": "productPrice",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discountPercentage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "monthlyPrice",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalDiscountAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductStatusLabel_product"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "0c8d943a28a9adaa2dbe4cf9432fb4bc";

export default node;
