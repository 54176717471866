/**
 * @generated SignedSource<<b5ebd5e3acc09293343aea57d5a74f35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorActionEnum = "generated_problems_create" | "problem_generator_activate" | "problem_generator_assign" | "problem_generator_deactivate" | "problem_generator_publish" | "%future added value";
export type ProblemGeneratorAssignInput = {
  assignedTo: string;
  problemGenerators: ReadonlyArray<string>;
};
export type ProblemGeneratorAssignDialog_problemGeneratorAssignMutation$variables = {
  input: ProblemGeneratorAssignInput;
};
export type ProblemGeneratorAssignDialog_problemGeneratorAssignMutation$data = {
  readonly problemGeneratorAssign: ReadonlyArray<{
    readonly actions: ReadonlyArray<ProblemGeneratorActionEnum>;
    readonly assigned: any | null;
    readonly assignedTo: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly id: string;
  }>;
};
export type ProblemGeneratorAssignDialog_problemGeneratorAssignMutation = {
  response: ProblemGeneratorAssignDialog_problemGeneratorAssignMutation$data;
  variables: ProblemGeneratorAssignDialog_problemGeneratorAssignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assigned",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProblemGeneratorAssignDialog_problemGeneratorAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProblemGenerator",
        "kind": "LinkedField",
        "name": "problemGeneratorAssign",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "assignedTo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProblemGeneratorAssignDialog_problemGeneratorAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProblemGenerator",
        "kind": "LinkedField",
        "name": "problemGeneratorAssign",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "assignedTo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "82435562f72061772a60a69d16c65353",
    "id": null,
    "metadata": {},
    "name": "ProblemGeneratorAssignDialog_problemGeneratorAssignMutation",
    "operationKind": "mutation",
    "text": "mutation ProblemGeneratorAssignDialog_problemGeneratorAssignMutation(\n  $input: ProblemGeneratorAssignInput!\n) {\n  problemGeneratorAssign(input: $input) {\n    id\n    actions\n    assigned\n    assignedTo {\n      id\n      ...UserAvatarText_user\n    }\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "2723143170f929fada1eb859c38df786";

export default node;
