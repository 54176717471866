/**
 * @generated SignedSource<<796c38e253c910cd0669328dcbe37c6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizHistoryDialog_quizHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly name: string;
    readonly picture: string | null;
  };
  readonly difference: {
    readonly " $fragmentSpreads": FragmentRefs<"QuizDifferenceCounterLabel_difference" | "QuizDifferenceItemList_difference">;
  } | null;
  readonly " $fragmentType": "QuizHistoryDialog_quizHistory";
};
export type QuizHistoryDialog_quizHistory$key = {
  readonly " $data"?: QuizHistoryDialog_quizHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizHistoryDialog_quizHistory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizHistoryDialog_quizHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picture",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "difference",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuizDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "QuizDifferenceCounterLabel_difference"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuizHistory",
  "abstractKey": null
};

(node as any).hash = "a77d2072c785ca38b4c0569b65b32f36";

export default node;
