import { cloneDeep } from 'lodash-es';
import { useRef } from 'react';

type DataType = string | number;
type RowType = DataType[];

const useCsv = (initialValue?: { columnTitle?: DataType[] | null; data?: RowType[] }) => {
  const csvColumnTitleRef = useRef<DataType[] | null>(initialValue?.columnTitle || null);
  const csvDataRef = useRef<RowType[]>(initialValue?.data || []);

  const columnTitle: DataType[] | null = csvColumnTitleRef.current ? [...csvColumnTitleRef.current] : null;
  const data: RowType[] = cloneDeep(csvDataRef.current);

  const setColumnTitle = (titles: DataType[]) => (csvColumnTitleRef.current = [...titles]);

  const setData = (data: RowType[]) => (csvDataRef.current = data);
  const pushRowData = (row: RowType) => (csvDataRef.current = [...csvDataRef.current, row]);

  const getBlobFromCsv = () => {
    const csvContent = csvColumnTitleRef.current
      ? [csvColumnTitleRef.current, ...csvDataRef.current]
      : csvDataRef.current;
    return new Blob([csvContent.map((row) => row?.join(',')).join('\n')], { type: 'text/csv;charset=utf-8,' });
  };

  const downloadCsv = (fileName?: string) => {
    const csvUrl = URL.createObjectURL(getBlobFromCsv());
    const link = document.createElement('a');
    link.download = `${fileName || 'file'}.csv`;
    link.href = csvUrl;
    link.click();
  };

  const reset = () => {
    csvColumnTitleRef.current = initialValue?.columnTitle || null;
    csvDataRef.current = initialValue?.data || [];
  };

  return { data, columnTitle, setData, pushRowData, setColumnTitle, downloadCsv, reset };
};

export default useCsv;
