/**
 * @generated SignedSource<<a9e5c2b0f72c6ba848adc526e40382ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BookTypeEnum = "normal" | "school" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BookConnectionDataTable_bookConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly id: string;
      readonly publisher: string;
      readonly sequence: number;
      readonly submittedBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly title: string;
      readonly type: BookTypeEnum;
      readonly unitAs: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
      }>;
      readonly " $fragmentSpreads": FragmentRefs<"BookStatusLabel_book">;
    };
  }>;
  readonly " $fragmentType": "BookConnectionDataTable_bookConnection";
};
export type BookConnectionDataTable_bookConnection$key = {
  readonly " $data"?: BookConnectionDataTable_bookConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookConnectionDataTable_bookConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookConnectionDataTable_bookConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BookEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Book",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BookStatusLabel_book"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "order",
                  "value": {
                    "order": "ASC"
                  }
                }
              ],
              "concreteType": "UnitA",
              "kind": "LinkedField",
              "name": "unitAs",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": "unitAs(order:{\"order\":\"ASC\"})"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publisher",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "submittedBy",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BookConnection",
  "abstractKey": null
};
})();

(node as any).hash = "c18f6068a34d5aa1f8b3ac3d2997dad6";

export default node;
