import { graphql, useFragment } from 'react-relay';

import {
  TaskHintStatusEnum,
  TaskHintStatusLabel_taskHint$key,
} from '../../../relay/__generated__/TaskHintStatusLabel_taskHint.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const TaskHintStatusLabel_taskHint = graphql`
  fragment TaskHintStatusLabel_taskHint on TaskHint {
    status
  }
`;

type Props = {
  taskHint: TaskHintStatusLabel_taskHint$key;
} & Omit<LabelProps, 'variant'>;

const TaskHintStatusLabel = ({ taskHint, ...props }: Props) => {
  const { status } = useFragment(TaskHintStatusLabel_taskHint, taskHint);

  const labelPropsByStatus: Record<TaskHintStatusEnum, LabelProps> = {
    '%future added value': {},
    'published': { variant: 'success' },
    'saved': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'TaskHintStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default TaskHintStatusLabel;
