/**
 * @generated SignedSource<<5e99535b9c4467fb0728dd8ba2949ed3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ExamTypeEnum = "first_final" | "first_mid" | "second_final" | "second_mid" | "%future added value";
export type ExamYearEnum = "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "%future added value";
export type SchoolYearEnum = "high_1" | "high_2" | "high_3" | "middle_1" | "middle_2" | "middle_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource$data = {
  readonly examType: ExamTypeEnum;
  readonly examYear: ExamYearEnum;
  readonly id: string;
  readonly innerPdf: {
    readonly id: string;
    readonly objectUrl: string;
  } | null;
  readonly problems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created: any;
        readonly id: string;
        readonly pdf: {
          readonly id: string;
          readonly objectUrl: string;
        } | null;
        readonly sequence: number;
      };
    }>;
  };
  readonly school: {
    readonly id: string;
    readonly title: string;
  };
  readonly schoolYear: SchoolYearEnum;
  readonly scraps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly order: number;
      };
    }>;
  };
  readonly " $fragmentType": "SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource";
};
export type SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource$key = {
  readonly " $data"?: SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scrapOrder"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "School",
      "kind": "LinkedField",
      "name": "school",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "schoolYear",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "innerPdf",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "scrapOrder"
        }
      ],
      "concreteType": "SchoolExamScrapConnection",
      "kind": "LinkedField",
      "name": "scraps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SchoolExamScrapEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SchoolExamScrap",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "order",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        }
      ],
      "concreteType": "SchoolExamProblemConnection",
      "kind": "LinkedField",
      "name": "problems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SchoolExamProblemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SchoolExamProblem",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sequence",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "created",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "pdf",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SchoolExamSource",
  "abstractKey": null
};
})();

(node as any).hash = "cab4c246cb852009e0ea6f62fe3d9948";

export default node;
