/**
 * @generated SignedSource<<9f94f64dc376aa523ab5c42ba0c05bb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ThumbnailTypeEnum = "horizontal" | "vertical" | "%future added value";
export type TaskVideoThumbnailFilters = {
  taskVideo: string;
  type: ThumbnailTypeEnum;
};
export type TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery$variables = {
  filters: TaskVideoThumbnailFilters;
};
export type TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery$data = {
  readonly taskVideoThumbnails: ReadonlyArray<{
    readonly key: string;
    readonly url: string;
  }>;
};
export type TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery = {
  response: TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery$data;
  variables: TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VideoThumbnail",
        "kind": "LinkedField",
        "name": "taskVideoThumbnails",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VideoThumbnail",
        "kind": "LinkedField",
        "name": "taskVideoThumbnails",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "96c06a4c51782d91460b4613ee2e25c8",
    "id": null,
    "metadata": {},
    "name": "TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery",
    "operationKind": "query",
    "text": "query TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery(\n  $filters: TaskVideoThumbnailFilters!\n) {\n  taskVideoThumbnails(filters: $filters) {\n    key\n    url\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "a751a80613b56968775ec2ff47b3c93d";

export default node;
