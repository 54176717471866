import { graphql, useFragment } from 'react-relay';

import { ErrorReportItem_errorReport$key } from '../../../relay/__generated__/ErrorReportItem_errorReport.graphql';
import DateText from '../../core/DateText';
import Text from '../../core/Text';
import View from '../../core/View';

const ErrorReportItem_errorReport = graphql`
  fragment ErrorReportItem_errorReport on ErrorReport {
    id
    reason
    created
  }
`;

type Props = {
  errorReport: ErrorReportItem_errorReport$key;
};

const ErrorReportItem = ({ errorReport }: Props) => {
  const { reason, created } = useFragment(ErrorReportItem_errorReport, errorReport);
  return (
    <>
      <Text fontSize={1} whiteSpace={'pre-wrap'}>
        {reason}
      </Text>
      <View sx={{ marginTop: 1 }}>
        <DateText fontSize={0} color={'fg.subtle'}>
          {created}
        </DateText>
      </View>
    </>
  );
};

export default ErrorReportItem;
