import { graphql, useFragment } from 'react-relay';

import { InternalUserSchoolYearMajorTypeUpdateDialog_internalUser$key } from '../../../relay/__generated__/InternalUserSchoolYearMajorTypeUpdateDialog_internalUser.graphql';
import { InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation } from '../../../relay/__generated__/InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';

const InternalUserSchoolYearMajorTypeUpdateDialog_internalUser = graphql`
  fragment InternalUserSchoolYearMajorTypeUpdateDialog_internalUser on MATInternalUser {
    id
    studentProfile {
      schoolYearMajorType
    }
  }
`;

type Props = {
  internalUser: InternalUserSchoolYearMajorTypeUpdateDialog_internalUser$key;
} & DialogProps &
  Pick<
    MutationFormikProps<InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation>,
    'config' | 'onSubmit'
  >;

const InternalUserSchoolYearMajorTypeUpdateDialog = ({ internalUser, onSubmit, config, ...props }: Props) => {
  const data = useFragment(InternalUserSchoolYearMajorTypeUpdateDialog_internalUser, internalUser);
  const { id, studentProfile } = data;
  const { schoolYearMajorType } = studentProfile;
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>유저 학년 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation>
        mutation={graphql`
          mutation InternalUserSchoolYearMajorTypeUpdateDialog_internalUserSchoolYearMajorTypeUpdateMutation(
            $input: MATInternalSchoolYearMajorTypeUpdateInput!
          ) {
            internalStudentTypeUpdate(input: $input) {
              id
              studentProfile {
                schoolYearMajorType
              }
            }
          }
        `}
        initialValues={{
          id: id,
          schoolYearMajorType: schoolYearMajorType,
        }}
        onSubmit={onSubmit}
        config={config}
      >
        {({ submitForm, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <EnumPairSelectField
                  typename={'MATSchoolYearMajorTypeEnum'}
                  name={'schoolYearMajorType'}
                  label={'학년'}
                  required
                  placeholder={'학년 선택'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button onClick={submitForm} disabled={!dirty}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default InternalUserSchoolYearMajorTypeUpdateDialog;
