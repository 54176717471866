import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

import AnimatedLogo from '../../AnimatedLogo';
import View from '../../View';

type Props = {};

const AuthenticatedRoute = ({ children }: PropsWithChildren<Props>) => {
  const router = useRouter();
  const { status } = useSession({
    required: true,
    onUnauthenticated: () => router.replace({ pathname: '/login', query: { next: router.asPath } }),
  });

  if (status === 'loading') {
    return (
      <View sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <AnimatedLogo size={80} />
      </View>
    );
  }

  return children as JSX.Element;
};

export default AuthenticatedRoute;
export type { Props as AuthenticatedRouteProps };
