import { SearchIcon } from '@primer/octicons-react';
import { useTheme } from '@primer/react';
import { debounce } from 'lodash-es';
import {
  ChangeEventHandler,
  useRef,
  MouseEvent as ReactMouseEvent,
  KeyboardEvent as ReactKeyboardEvent,
  RefObject,
} from 'react';

import { FocusKeys } from '../../../types/primer';
import { scrollIntoView } from '../../../utils/scroll';
import OverlayTokenField, { OverlayTokenFieldProps } from '../OverlayTokenField';
import TextInput from '../TextInput';
import { ViewProps } from '../View';

type Props = {
  onSearch?: (search: string) => void;
  renderItemList: (
    {
      controllingElementRef,
      containerRef,
      handleSelect,
    }: {
      controllingElementRef: RefObject<HTMLInputElement>;
      containerRef: RefObject<HTMLElement>;
      handleSelect: (e: ReactMouseEvent<HTMLDivElement> | ReactKeyboardEvent<HTMLDivElement>, selected: string) => void;
    },
    itemProps: ViewProps,
  ) => React.ReactNode;
} & Omit<OverlayTokenFieldProps, 'renderHeader' | 'children'>;

const SearchOverlayTokenField = ({ onSearch, renderItemList, disabled, ...props }: Props) => {
  const { theme } = useTheme();
  const containerRef = useRef<HTMLElement>(null);
  const activeDescendantRef = useRef<HTMLElement>();
  const controllingElementRef = useRef<HTMLInputElement>(null);

  const handleTextInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onSearch?.(e.target.value);
  };

  return (
    <OverlayTokenField
      renderHeader={() => (
        <TextInput
          ref={controllingElementRef}
          leadingVisual={SearchIcon}
          size={'medium'}
          block
          placeholder={'검색'}
          disabled={disabled}
          autoComplete="off"
          onChange={debounce(handleTextInputChange, 500)}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.code === 'Enter' && activeDescendantRef.current) {
              const activeDescendantEvent = new KeyboardEvent(e.type, e.nativeEvent);
              activeDescendantRef.current?.dispatchEvent(activeDescendantEvent);
            }
          }}
        />
      )}
      disabled={disabled}
      focusZoneSettings={{
        containerRef,
        activeDescendantFocus: controllingElementRef,
        focusOutBehavior: 'stop',
        bindKeys: FocusKeys.ArrowVertical,
        onActiveDescendantChanged: (current, previous) => {
          activeDescendantRef.current = current;
          if (current && containerRef.current) {
            current.style.backgroundColor = theme?.colors.neutral.muted;
            scrollIntoView(current, containerRef.current, { behavior: 'auto' });
          }
          if (previous && current !== previous) {
            previous.style.backgroundColor = '';
          }
        },
        focusableElementFilter: (elem) => {
          return elem instanceof HTMLDivElement;
        },
      }}
      {...props}
    >
      {({ handleSelect }) =>
        renderItemList(
          {
            controllingElementRef,
            containerRef,
            handleSelect,
          },
          {
            tabIndex: -1,
            sx: { paddingX: 2, paddingY: 1, cursor: 'pointer', transition: 'background-color 250ms' },
          },
        )
      }
    </OverlayTokenField>
  );
};

export default SearchOverlayTokenField;
export type { Props as SearchOverlayTokenFieldProps };
