import { Space } from '@teamturing/react-kit';
import { commaizeNumber } from '@teamturing/utils';
import { startOfToday } from 'date-fns';
import React, { Suspense } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import { BillCreateDialog_billCreateMutation } from '../../../relay/__generated__/BillCreateDialog_billCreateMutation.graphql';
import { BillCreateDialog_billFinalPriceQuery } from '../../../relay/__generated__/BillCreateDialog_billFinalPriceQuery.graphql';
import { StoreStuffTypeEnum } from '../../../relay/__generated__/StoreStuffPaginatorSearchOverlayTokenField_storeStuffsQuery.graphql';
import { formatISO } from '../../../utils/date';
import Button from '../../core/Button';
import CheckboxField from '../../core/CheckboxField';
import DatetimeField from '../../core/DatetimeField';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import ErrorBoundary from '../../core/ErrorBoundary';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import RadioGroupField from '../../core/RadioGroupField';
import Spinner from '../../core/Spinner';
import TelField from '../../core/TelField';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextInput, { TextInputProps } from '../../core/TextInput';
import View from '../../core/View';
import ProductSelectField from '../../product/ProductSelectField';

const billFinalPriceForBillCreateDialog = graphql`
  query BillCreateDialog_billFinalPriceQuery($product: ID!, $additionalDiscount: Boolean!) {
    billFinalPrice(product: $product, additionalDiscount: $additionalDiscount)
  }
`;

type Props = {} & DialogProps &
  Pick<MutationFormikProps<BillCreateDialog_billCreateMutation>, 'config' | 'onSubmit' | 'connections'>;

const BillCreateDialog = ({ config, onSubmit, connections, onDismiss, ...props }: Props) => {
  const stuffTypes: StoreStuffTypeEnum[] = ['premium_refundable', 'premium_non_refundable'];

  const [finalPriceQueryReference, loadFinalPriceQuery, disposeFinalPriceQuery] =
    useQueryLoader<BillCreateDialog_billFinalPriceQuery>(billFinalPriceForBillCreateDialog);

  const handleDismiss = () => {
    onDismiss?.();
    disposeFinalPriceQuery();
  };

  return (
    <Dialog onDismiss={handleDismiss} {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>개인 결제 링크 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<BillCreateDialog_billCreateMutation>
        mutation={graphql`
          mutation BillCreateDialog_billCreateMutation($input: BillCreateInput!) {
            billCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{
          title: '',
          expired: formatISO(startOfToday()),
          product: '',
          additionalDiscount: true,
          studentName: '',
          studentNumber: '',
          purchaserNumber: '',
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
        initialStatus={{ stuffType: stuffTypes[0], isSameStudentAndPurchaser: false }}
      >
        {({ handleSubmit, status, setStatus, values, setValues }, { scrollContainerRef, nonFieldErrorRef }) => {
          return (
            <>
              <Dialog.Body ref={scrollContainerRef}>
                <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
                <FormLayout>
                  <Text fontSize={3} fontWeight={'bold'}>
                    링크 정보
                  </Text>
                  <TextField
                    label={'링크 제목'}
                    name={'title'}
                    placeholder={'예) 00학생을 위한 특별 상품'}
                    required
                    size={'large'}
                  />
                  <DatetimeField
                    label={'만료 일시'}
                    name={'expired'}
                    required
                    size={'large'}
                    datePickerProps={{ minDate: new Date() }}
                  />
                </FormLayout>
                <View mt={6}>
                  <FormLayout>
                    <Text fontSize={3} fontWeight={'bold'}>
                      상품 정보
                    </Text>
                    <EnumPairRadioGroupField
                      typename={'StoreStuffTypeEnum'}
                      label={'타입'}
                      name={'status.stuffType'}
                      value={status.stuffType}
                      onChange={(selected) => {
                        setStatus({ ...status, stuffType: selected });
                        setValues({ ...values, product: '' });
                        disposeFinalPriceQuery();
                      }}
                      renderContainer={(children) => <Grid>{children}</Grid>}
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={1 / 2}>
                          {children}
                        </Grid.Unit>
                      )}
                    />
                    <ErrorBoundary fallback={<></>}>
                      <Suspense fallback={<Spinner size={'small'} />}>
                        <ProductSelectField
                          label={'판매 상품'}
                          name={'product'}
                          placeholder={'판매 상품 선택'}
                          variables={{
                            filters: {
                              storeStuffType_In: [status.stuffType],
                              showTypes_Contains_Any: ['private'],
                              status_In: ['active'],
                            },
                            showAdditionalDiscountAmount: true,
                          }}
                          required
                          size={'large'}
                          onChange={(value) => {
                            setValues({ ...values, product: value });
                            loadFinalPriceQuery({ product: value, additionalDiscount: values.additionalDiscount });
                          }}
                        />
                      </Suspense>
                    </ErrorBoundary>
                    <RadioGroupField
                      label={'콜 추가 할인'}
                      required
                      options={[
                        { id: 'true', text: '있음' },
                        { id: 'false', text: '없음' },
                      ]}
                      name={'additionalDiscount'}
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={1 / 2}>
                          {children}
                        </Grid.Unit>
                      )}
                      renderContainer={(children) => <Grid gapX={2}>{children}</Grid>}
                      value={values.additionalDiscount === true ? 'true' : 'false'}
                      onChange={(selected) => {
                        const value = selected === 'true' ? true : false;
                        setValues({ ...values, additionalDiscount: value });
                        if (values.product) loadFinalPriceQuery({ product: values.product, additionalDiscount: value });
                      }}
                    />
                    <View>
                      <Text fontSize={1}>최종 판매가</Text>
                      <Space mt={1}>
                        {finalPriceQueryReference ? (
                          <Suspense fallback={<TextInputForBillFinalPrice />}>
                            <PreloadedQueryRenderer
                              query={billFinalPriceForBillCreateDialog}
                              queryReference={finalPriceQueryReference}
                            >
                              {({ billFinalPrice }) => (
                                <TextInputForBillFinalPrice value={commaizeNumber(billFinalPrice)} />
                              )}
                            </PreloadedQueryRenderer>
                          </Suspense>
                        ) : (
                          <TextInputForBillFinalPrice />
                        )}
                      </Space>
                    </View>
                  </FormLayout>
                </View>
                <View mt={6}>
                  <FormLayout>
                    <Text fontSize={3} fontWeight={'bold'}>
                      구매자 정보
                    </Text>
                    <TextField
                      label={'학생 이름'}
                      name={'studentName'}
                      placeholder={'학생 이름 입력'}
                      required
                      size={'large'}
                    />
                    <TelField
                      label={'학생 휴대폰 번호'}
                      name={'studentNumber'}
                      placeholder={'휴대폰 번호 입력'}
                      validate={(value) => {
                        if (!value) return '01로 시작하는 숫자 11자를 입력해 주세요';
                      }}
                      type={'phoneNumber'}
                      required
                      {...(status.isSameStudentAndPurchaser
                        ? {
                            onChange: (value) => {
                              setValues({ ...values, studentNumber: value, purchaserNumber: value });
                            },
                          }
                        : {})}
                      size={'large'}
                    />
                    <TelField
                      label={'구매자 휴대폰 번호'}
                      name={'purchaserNumber'}
                      placeholder={'휴대폰 번호 입력'}
                      type={'phoneNumber'}
                      caption={'결제 링크를 받을 구매자의 휴대폰 번호를 입력해 주세요'}
                      validate={(value) => {
                        if (!value) return '01로 시작하는 숫자 11자를 입력해 주세요';
                      }}
                      required
                      size={'large'}
                    />
                  </FormLayout>
                  <View sx={{ marginTop: 2 }}>
                    <CheckboxField
                      label={'학생 휴대폰 번호와 동일'}
                      name={'status.isSameStudentAndPurchaser'}
                      checked={status.isSameStudentAndPurchaser}
                      onChange={(event) => {
                        setStatus({ ...status, isSameStudentAndPurchaser: event.target.checked });
                        if (event.target.checked) {
                          setValues({ ...values, purchaserNumber: values.studentNumber });
                        } else {
                          setValues({ ...values, purchaserNumber: '' });
                        }
                      }}
                    />
                  </View>
                </View>
              </Dialog.Body>
              <Dialog.Footer>
                <Button variant={'primary'} onClick={() => handleSubmit()}>
                  {'생성하기'}
                </Button>
              </Dialog.Footer>
            </>
          );
        }}
      </MutationFormik>
    </Dialog>
  );
};

const TextInputForBillFinalPrice = ({ value = '-' }: Pick<TextInputProps, 'value'>) => (
  <TextInput block readOnly size={'large'} value={value} />
);

export default BillCreateDialog;
export type { Props as BillCreateDialogProps };
