import { graphql, useFragment } from 'react-relay';

import { FormulaSetDifferenceItemList_formulaSetDifference$key } from '../../../relay/__generated__/FormulaSetDifferenceItemList_formulaSetDifference.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import ItemList from '../../core/ItemList';
import Text from '../../core/Text';
import View from '../../core/View';
import DiffItem from '../../difference/DiffItem';
import FormulaDifferenceItemList from '../FormulaDifferenceItemList';

const FormulaSetDifferenceItemList_formulaSetDifference = graphql`
  fragment FormulaSetDifferenceItemList_formulaSetDifference on Difference {
    ... on FormulaSetDifference {
      formulas {
        ...FormulaDifferenceItemList_formulaDifference
      }
      description {
        ...DiffItem_diff
      }
    }
  }
`;

type Props = {
  formulaSetDifference: FormulaSetDifferenceItemList_formulaSetDifference$key;
};

const FormulaSetDifferenceItemList = ({ formulaSetDifference }: Props) => {
  const { formulas, description } = useFragment(
    FormulaSetDifferenceItemList_formulaSetDifference,
    formulaSetDifference,
  );

  const diffItems = [{ title: '설명', diff: description, renderItem: (beforeOrAfter: string) => beforeOrAfter }].filter(
    ({ diff }) => !isNullable(diff),
  );

  const isDifferenceEmpty = isNullable(formulas) && diffItems.length === 0;

  return isDifferenceEmpty ? (
    <View sx={{ paddingY: 6 }}>
      <EmptyState
        title={'기록되지 않은 히스토리이에요'}
        description={'이 기능이 만들어지기 전에 일어난 일이거나 오류로 누락된 히스토리입니다.'}
      />
    </View>
  ) : (
    <>
      <ItemList
        items={diffItems}
        renderItem={({ title, diff, renderItem }) => (
          <>
            <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
            <View sx={{ marginTop: 2 }}>
              <DiffItem diff={diff!} renderItem={renderItem} />
            </View>
          </>
        )}
        renderItemWrapper={(children, { title }, i) => (
          <View key={title} sx={{ marginTop: i > 0 ? 4 : 0 }}>
            {children}
          </View>
        )}
      />
      {formulas ? (
        <View sx={{ '&:not(:first-child)': { marginTop: 4 } }}>
          <ItemList
            items={formulas}
            renderItem={(formula, index) => (
              <>
                <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>#{index + 1}</Text>
                <View sx={{ marginTop: 2 }}>
                  <FormulaDifferenceItemList formulaDifference={formula} />
                </View>
              </>
            )}
            renderItemWrapper={(children, _, index) => (
              <View key={`formula#${index}`} sx={{ marginTop: index > 0 ? 4 : 0 }}>
                {children}
              </View>
            )}
          />
        </View>
      ) : null}
    </>
  );
};

export default FormulaSetDifferenceItemList;
