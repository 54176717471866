import { useField } from 'formik';
import { ChangeEventHandler, ComponentProps, InputHTMLAttributes, useState } from 'react';
import styled from 'styled-components';

import useFormikContext from '../../../hooks/useFormikContext';
import { getUploadMetaData, uploadGeneral, uploadMultipart } from '../../../utils/file';
import FormControl from '../FormControl';
import IconButton, { IconButtonA11yProps, IconButtonProps } from '../IconButton';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  nodeId?: string;
  caption?: string;
  required?: boolean;
} & Pick<IconButtonProps, 'disabled' | 'icon' | 'size' | 'variant' | 'sx' | 'onClick'> &
  Pick<InputHTMLAttributes<HTMLInputElement>, 'accept' | 'onChange' | 'name'> &
  IconButtonA11yProps;

const UploadIconButtonField = ({
  label,
  labelConfig,
  nodeId,
  required,
  caption,
  disabled: propDisabled,
  accept,
  onChange: propOnChange,
  onClick,
  name = '',
  ...props
}: Props) => {
  const inputId = `upload_icon_button_field_for_${label}`;

  const [, { error }, { setValue }] = useField({ name });
  const { isSubmitting } = useFormikContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    setIsLoading(true);

    const targetFile = e.target.files?.[0];
    if (!targetFile) {
      setIsLoading(false);
      return;
    }

    const { name: fileName, size: fileSize } = targetFile;
    const metaData = await getUploadMetaData({ nodeId, fileName, fileSize });

    if (!metaData) {
      setIsLoading(false);
      return;
    }

    const isMultiPartUploadRequired = 'upload_id' in metaData && 'part_metadata' in metaData;

    if (isMultiPartUploadRequired) {
      const response = await uploadMultipart(targetFile, metaData);
      const isOk = response.reduce((acc, cur) => acc && cur.ok, true);

      if (isOk) {
        const value = {
          key: metaData.key,
          multipartUploadResult: {
            uploadId: metaData.upload_id,
            partPairs: metaData.part_metadata.map(({ part_number: PartNumber }, i) => ({
              PartNumber,
              ETag: response[i].headers.get('ETag'),
            })),
          },
          size: fileSize,
        };

        setValue(value);
      }
    } else {
      const response = await uploadGeneral(targetFile, metaData);
      if (response.ok) {
        const value = { key: metaData.key, size: fileSize };
        setValue(value);
      }
    }

    e.target.value = '';

    setIsLoading(false);
  };
  const handleChange = propOnChange ? propOnChange : onChange;

  const handleClickIconButton: IconButtonProps['onClick'] = (...arg) => {
    onClick?.(...arg);
    document.getElementById(inputId)?.click();
  };

  const disabled = propDisabled || isLoading || isSubmitting;
  return (
    <FormControl required={required} disabled={disabled}>
      <FormControl.Label {...labelConfig}>{label}</FormControl.Label>
      <IconButton disabled={disabled} onClick={handleClickIconButton} {...props} />
      <StyledInput id={inputId} type={'file'} accept={accept} onChange={handleChange} disabled={disabled} />
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

const StyledInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

export default UploadIconButtonField;
export type { Props as UploadIconButtonField };
