import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  UnitARadioGroupField_unitAsQuery,
  UnitARadioGroupField_unitAsQuery$variables,
} from '../../../relay/__generated__/UnitARadioGroupField_unitAsQuery.graphql';
import RadioGroupField, { RadioGroupFieldProps } from '../../core/RadioGroupField';

const unitAsForUnitARadioGroupField = graphql`
  query UnitARadioGroupField_unitAsQuery($filters: UnitAFilter, $order: UnitAOrder) {
    unitAs(filters: $filters, order: $order) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {
  variables?: UnitARadioGroupField_unitAsQuery$variables;
} & Omit<RadioGroupFieldProps, 'options'>;

const UnitARadioGroupField = ({ variables, ...props }: Props) => {
  const [{ unitAs }] = useLazyLoadQuery<UnitARadioGroupField_unitAsQuery>(unitAsForUnitARadioGroupField, {
    ...variables,
  });
  return <RadioGroupField options={unitAs.edges.map(({ node }) => ({ id: node.id, text: node.title }))} {...props} />;
};

export default UnitARadioGroupField;
