/**
 * @generated SignedSource<<2f35e1467e9d43242512a5ad888b79a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizDifferenceCounterLabel_difference$data = {
  readonly answer?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly description?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly problem?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly solution?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly status?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly tip?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly unitDDescription?: {
    readonly after: string | null;
    readonly before: string | null;
  } | null;
  readonly " $fragmentType": "QuizDifferenceCounterLabel_difference";
};
export type QuizDifferenceCounterLabel_difference$key = {
  readonly " $data"?: QuizDifferenceCounterLabel_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizDifferenceCounterLabel_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "before",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "after",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizDifferenceCounterLabel_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "answer",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "problem",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "solution",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "status",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "tip",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "unitDDescription",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "QuizDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "b4485817596c1f9d2f0742a6c73fa398";

export default node;
