/**
 * @generated SignedSource<<08f0bd04d065265c1bbd7fb0ad0139df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductActionEnum = "product_activate" | "product_deactivate" | "product_delete" | "product_update" | "%future added value";
export type ProductId_productQuery$variables = {
  id: string;
};
export type ProductId_productQuery$data = {
  readonly product: {
    readonly actions: ReadonlyArray<ProductActionEnum>;
    readonly id: string;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProductDescriptionList_product" | "ProductUpdateDialog_product">;
  };
};
export type ProductId_productQuery = {
  response: ProductId_productQuery$data;
  variables: ProductId_productQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductId_productQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductDescriptionList_product"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductUpdateDialog_product"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductId_productQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreStuff",
            "kind": "LinkedField",
            "name": "storeStuff",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Stuff",
                "kind": "LinkedField",
                "name": "stuff",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "showTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductPrice",
            "kind": "LinkedField",
            "name": "productPrice",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "discountPercentage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "monthlyPrice",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionalDiscountAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "700861388a1a207837547af5129d66f9",
    "id": null,
    "metadata": {},
    "name": "ProductId_productQuery",
    "operationKind": "query",
    "text": "query ProductId_productQuery(\n  $id: ID!\n) {\n  product(id: $id) {\n    id\n    actions\n    title\n    ...ProductDescriptionList_product\n    ...ProductUpdateDialog_product\n  }\n}\n\nfragment ProductDescriptionList_product on Product {\n  id\n  storeStuff {\n    id\n    title\n    endAt\n    stuff {\n      id\n      title\n    }\n  }\n  showTypes\n  price\n  discountPrice\n  productPrice {\n    discountPercentage\n    monthlyPrice\n  }\n  additionalDiscountAmount\n  createdBy {\n    id\n    ...UserAvatarText_user\n  }\n  created\n  ...ProductStatusLabel_product\n}\n\nfragment ProductStatusLabel_product on Product {\n  status\n}\n\nfragment ProductUpdateDialog_product on Product {\n  id\n  title\n  storeStuff {\n    id\n  }\n  showTypes\n  price\n  discountPrice\n  additionalDiscountAmount\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "6ce19bcf74ac88f37d74ee05999a398d";

export default node;
