import { CheckIcon, PlusIcon, XIcon } from '@primer/octicons-react';
import { formatKatexToHtmlString } from '@teamturing/katex-utils';
import React, { useRef } from 'react';
import { graphql, useFragment } from 'react-relay';

import { FormulaSetUpdateDialog_formulaSet$key } from '../../../relay/__generated__/FormulaSetUpdateDialog_formulaSet.graphql';
import { FormulaSetUpdateDialog_formulaSetUpdateMutation } from '../../../relay/__generated__/FormulaSetUpdateDialog_formulaSetUpdateMutation.graphql';
import { isNullable } from '../../../utils/is';
import { scrollIntoView } from '../../../utils/scroll';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormControl from '../../core/FormControl';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import ItemList from '../../core/ItemList';
import KatexEditorInput from '../../core/KatexEditorInput';
import KatexTextInput from '../../core/KatexTextInput';
import Label from '../../core/Label';
import LabelGroup from '../../core/LabelGroup';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import TextareaField from '../../core/TextareaField';
import View from '../../core/View';
import FormulaSetStatusLabel from '../FormulaSetStatusLabel';

const FormulaSetUpdateDialog_formulaSet = graphql`
  fragment FormulaSetUpdateDialog_formulaSet on FormulaSet {
    id
    description
    unitD {
      unitATitle
      title
    }
    formulas {
      totalCount
      edges {
        node {
          title
          text
        }
      }
    }
    ...FormulaSetStatusLabel_formulaSet
  }
`;

type Props = {
  formulaSet: FormulaSetUpdateDialog_formulaSet$key;
} & DialogProps &
  Pick<MutationFormikProps<FormulaSetUpdateDialog_formulaSetUpdateMutation>, 'config' | 'onSubmit'>;
const FormulaSetUpdateDialog = ({ formulaSet, onDismiss, config, onSubmit, ...props }: Props) => {
  const data = useFragment(FormulaSetUpdateDialog_formulaSet, formulaSet);
  const {
    id,
    description,
    unitD: { title: unitDTitle, unitATitle },
    formulas,
  } = data;

  const getId = ({ name, index }: { name: string; index?: number }) => {
    const baseIdArray = ['formula', name];
    return (isNullable(index) ? baseIdArray : [...baseIdArray, index]).join('-');
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollToFormula = (index: number) => {
    const lastAddedFormula = document.getElementById(getId({ name: 'wrapper', index }));
    if (lastAddedFormula && scrollContainerRef.current) {
      scrollIntoView(lastAddedFormula, scrollContainerRef.current, { behavior: 'auto' });
    }
  };
  const focusFormulaTitle = (index: number) => {
    const titleInput = document.getElementById(getId({ name: 'title', index }));
    if (titleInput) titleInput.focus();
  };

  const defaultFormulaValue = { title: { text: '', textHtml: '' }, editor: { text: '', textHtml: '' } };

  const initialFormulas =
    formulas.totalCount === 0
      ? [defaultFormulaValue]
      : formulas.edges.map(({ node: { title, text } }) => ({
          title: {
            text: title,
            textHtml: formatKatexToHtmlString(title),
          },
          editor: {
            text,
            textHtml: formatKatexToHtmlString(text),
          },
        }));

  return (
    <Dialog onDismiss={onDismiss} {...props}>
      <Dialog.Header>
        <Stack gapX={2}>
          <Stack.Item>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>개념 추가 및 수정하기</Text>
          </Stack.Item>
          <Stack.Item>
            <LabelGroup>
              <Label variant={'accent'}>{`${unitATitle} / ${unitDTitle}`}</Label>
              <FormulaSetStatusLabel formulaSet={data} />
            </LabelGroup>
          </Stack.Item>
        </Stack>
      </Dialog.Header>
      <MutationFormik<FormulaSetUpdateDialog_formulaSetUpdateMutation>
        mutation={graphql`
          mutation FormulaSetUpdateDialog_formulaSetUpdateMutation($input: FormulaSetUpdateInput!) {
            formulaSetUpdate(input: $input) {
              id
              ...FormulaSetUpdateDialog_formulaSet
              ...FormulaSetMutationActionsButtonStack_formulaSet
              ...FormulaSetFormulaSetHistoryPaginator_formulaSet
            }
          }
        `}
        onSubmit={onSubmit}
        initialValues={{ id, description, formulas: initialFormulas }}
        config={config}
      >
        {({ values, handleSubmit, setFieldValue, isSubmitting }) => {
          const insert = (index: number) => {
            const formulas = values.formulas || [];
            setFieldValue('formulas', [...formulas.slice(0, index), defaultFormulaValue, ...formulas.slice(index)]);
          };

          const remove = (index: number) => {
            const formulas = values.formulas || [];
            setFieldValue('formulas', [...formulas.slice(0, index), ...formulas.slice(index + 1)]);
          };

          return (
            <>
              <Dialog.Body ref={scrollContainerRef}>
                <NonFieldError sx={{ marginBottom: 3 }} />
                <View sx={{ '&>*:not(:first-child)': { marginTop: 5 } }}>
                  <TextareaField label={'설명'} name={'description'} placeholder={'설명 입력'} />
                  <ItemList
                    items={values.formulas || []}
                    renderItem={({ title, editor }, index) => (
                      <>
                        <Grid sx={{ alignItems: 'center' }}>
                          <Grid.Unit size={'max'}>
                            <Text fontSize={2} fontWeight={'bold'}>
                              #{index + 1}
                            </Text>
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            <IconButton
                              icon={XIcon}
                              onClick={() => remove(index)}
                              disabled={(values.formulas || []).length < 2}
                              aria-label={`remove #${index + 1} formula`}
                            />
                          </Grid.Unit>
                        </Grid>
                        <FormLayout>
                          <FormControl required>
                            <FormControl.Label>제목</FormControl.Label>
                            <View sx={{ width: '100%' }}>
                              <KatexTextInput
                                id={getId({ name: 'title', index })}
                                name={`formula.${index}.title`}
                                placeholder={'제목 입력'}
                                value={title.text}
                                disabled={isSubmitting}
                                onBlur={(event) => {
                                  const text = event.target.value;
                                  setFieldValue(`formulas.${index}.title`, {
                                    text,
                                    textHtml: formatKatexToHtmlString(text),
                                  });
                                }}
                              />
                            </View>
                          </FormControl>
                          <FormControl required>
                            <FormControl.Label>내용</FormControl.Label>
                            <View sx={{ width: '100%' }}>
                              <KatexEditorInput
                                id={getId({ name: 'editor', index })}
                                name={`formulas.${index}.editor`}
                                value={editor.text}
                                disabled={isSubmitting}
                                onBlur={(editor) => {
                                  const text = editor.getValue();
                                  setFieldValue(`formulas.${index}.editor`, {
                                    text,
                                    textHtml: formatKatexToHtmlString(text),
                                  });
                                }}
                              />
                            </View>
                          </FormControl>
                        </FormLayout>
                        <Button
                          sx={{ marginX: 'auto' }}
                          variant={'outline'}
                          leadingIcon={PlusIcon}
                          onClick={() => {
                            insert(index + 1);
                            setTimeout(() => {
                              scrollToFormula(index + 1);
                              focusFormulaTitle(index + 1);
                            }, 0);
                          }}
                        >
                          추가하기
                        </Button>
                      </>
                    )}
                    renderItemWrapper={(children, _, index) => (
                      <View
                        id={getId({ name: 'wrapper', index })}
                        key={`formula${index}/${values.formulas?.length}`}
                        sx={{ '&>*:not(:first-child)': { marginTop: 5 } }}
                      >
                        {children}
                      </View>
                    )}
                  />
                </View>
              </Dialog.Body>
              <Dialog.Footer>
                <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                  추가 및 수정하기
                </Button>
              </Dialog.Footer>
            </>
          );
        }}
      </MutationFormik>
    </Dialog>
  );
};

export default FormulaSetUpdateDialog;
