import { ChevronLeftIcon, ChevronRightIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import Card from '../../components/core/Card';
import DialogHandler from '../../components/core/DialogHandler';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import ItemList from '../../components/core/ItemList';
import Katex from '../../components/core/Katex';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import Timeline from '../../components/core/Timeline';
import View from '../../components/core/View';
import ErrorReportItem from '../../components/errorReport/ErrorReportItem';
import ErrorReportPaginator from '../../components/errorReport/ErrorReportPaginator';
import QuizActionsMutationButtonStack from '../../components/quiz/QuizActionsMutationButtonStack';
import QuizDescriptionList from '../../components/quiz/QuizDescriptionList';
import QuizHistoryDialog from '../../components/quiz/QuizHistoryDialog';
import QuizHistoryTimelineItem from '../../components/quiz/QuizHistoryTimelineItem';
import QuizQuizHistoryPaginator from '../../components/quiz/QuizQuizHistoryPaginator';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import { QuizId_errorReportsQuery } from '../../relay/__generated__/QuizId_errorReportsQuery.graphql';
import { QuizFilter, QuizId_quizQuery } from '../../relay/__generated__/QuizId_quizQuery.graphql';
import { isNullable } from '../../utils/is';
import { numberWithCommas } from '../../utils/number';
import { parseOrdering } from '../../utils/order';
import { NextPage } from '../_app';

const quizForQuizId = graphql`
  query QuizId_quizQuery($id: ID!, $listFilters: QuizFilter, $listOrder: QuizOrder) {
    quiz(id: $id) {
      id
      sequence
      next(filters: $listFilters, order: $listOrder)
      previous(filters: $listFilters, order: $listOrder)

      problem
      solution
      tip

      ...QuizActionsMutationButtonStack_quiz
      ...QuizDescriptionList_quiz
      ...QuizQuizHistoryPaginator_quiz
    }
  }
`;

const errorReportsForQuizId = graphql`
  query QuizId_errorReportsQuery($filters: ErrorReportFilter, $order: ErrorReportOrder, $first: Int) {
    ...ErrorReportPaginator_query @arguments(filters: $filters, order: $order, first: $first)
  }
`;

type Props = {};

const QuizId: NextPage<Props> = () => {
  const router = useRouter();
  const { quizId } = router.query;

  const [errorReports] = useLazyLoadQuery<QuizId_errorReportsQuery>(errorReportsForQuizId, {
    filters: { nodeId_Exact: quizId as string },
    order: { created: 'DESC' },
    first: 3,
  });

  const { initialValues } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_In: { type: 'string', multiple: true },
    createdById_In: { type: 'string', multiple: true },
    answer_In: { type: 'string', multiple: true },
    unitAId_In: { type: 'string', multiple: true },
    unitBId_In: { type: 'string', multiple: true },
    unitCId_In: { type: 'string', multiple: true },
    unitDId_In: { type: 'string', multiple: true },
    order: { type: 'string' },
  });
  const { order, ...filters } = initialValues;

  const [{ quiz }] = useLazyLoadQuery<QuizId_quizQuery>(quizForQuizId, {
    id: quizId as string,
    listFilters: filters as QuizFilter,
    listOrder: order ? parseOrdering(order) : undefined,
  });
  if (!quiz) return null;
  const { sequence, previous: previousQuizId, next: nextQuizId, problem, solution, tip } = quiz;

  const hasPrevious = !isNullable(previousQuizId);
  const handlePreviousButtonClick = () => {
    const previousQuizPathname = `/quiz/${previousQuizId}`;
    router.replace({ pathname: previousQuizPathname, query: router.query }, previousQuizPathname);
  };

  const hasNext = !isNullable(nextQuizId);
  const handleNextButtonClick = () => {
    const nextQuizPathname = `/quiz/${nextQuizId}`;
    router.replace({ pathname: nextQuizPathname, query: router.query }, nextQuizPathname);
  };

  return (
    <View>
      <Head siteTitle={`OX 퀴즈 - ${sequence}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }} gapX={4} gapY={3}>
          <Grid.Unit size={[1, 1, 'max']}>
            <Stack gapX={3}>
              <Stack.Item>
                <IconButton
                  icon={ChevronLeftIcon}
                  aria-label={'Previous Quiz Id'}
                  disabled={!hasPrevious}
                  onClick={() => handlePreviousButtonClick()}
                />
              </Stack.Item>
              <Stack.Item>
                <Text as={'h1'}>{sequence}</Text>
              </Stack.Item>
              <Stack.Item>
                <IconButton
                  icon={ChevronRightIcon}
                  aria-label={'Next Quiz Id'}
                  disabled={!hasNext}
                  onClick={() => handleNextButtonClick()}
                />
              </Stack.Item>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 'min']}>
            <QuizActionsMutationButtonStack quiz={quiz} />
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <Text as={'h3'}>OX 퀴즈 정보</Text>
            <Card sx={{ padding: 4 }}>
              <QuizDescriptionList titleUnitSize={1 / 4} descriptionUnitSize={3 / 4} quiz={quiz} type={'default'} />
            </Card>
            <ItemList
              items={[
                { id: 'problem', title: '문제', text: problem },
                { id: 'solution', title: '해설', text: solution },
                { id: 'tip', title: 'TIP', text: tip },
              ]}
              renderItem={({ title, text }) => (
                <>
                  <Text fontSize={2} fontWeight={'bold'}>
                    {title}
                  </Text>
                  <Card sx={{ marginTop: 2, padding: 2 }}>
                    <Katex>{text}</Katex>
                  </Card>
                </>
              )}
              renderItemWrapper={(children, { id }) => (
                <View key={id} sx={{ marginTop: 5 }}>
                  {children}
                </View>
              )}
            />
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <QuizDescriptionList
              quiz={quiz}
              type={'activity'}
              titleUnitSize={'max'}
              descriptionUnitSize={'min'}
              renderTitle={(value) => <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{value}</Text>}
            />
            <View sx={{ marginTop: 6 }}>
              <ErrorBoundary>
                <ErrorReportPaginator fragmentReference={errorReports}>
                  {({ errorReports }, { loadMore, hasNext, isLoadingNext }) => (
                    <View>
                      <Stack gapX={1}>
                        <Text fontWeight={'bold'} fontSize={1}>
                          오류 신고 목록
                        </Text>
                        <Text
                          sx={{ fontSize: 1, fontWeight: 'bold' }}
                          color={errorReports.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                        >
                          {numberWithCommas(errorReports.totalCount || 0)}
                        </Text>
                      </Stack>
                      <View sx={{ marginTop: 2 }}>
                        <ItemList
                          items={errorReports.edges.map(({ node }) => node)}
                          renderItem={(node) => <ErrorReportItem errorReport={node} />}
                          renderItemWrapper={(children, { id }, index) => (
                            <Card key={id} sx={{ marginTop: index === 0 ? 0 : 2, padding: 3 }}>
                              {children}
                            </Card>
                          )}
                          emptyState={
                            <Card sx={{ padding: 3 }}>
                              <EmptyState title={'신고된 오류가 없습니다'} />
                            </Card>
                          }
                        />
                        {hasNext ? (
                          <Button
                            onClick={() => loadMore(20)}
                            disabled={isLoadingNext}
                            sx={{ width: '100%', marginTop: 1 }}
                          >
                            더보기
                          </Button>
                        ) : null}
                      </View>
                    </View>
                  )}
                </ErrorReportPaginator>
              </ErrorBoundary>
            </View>
            <View sx={{ marginTop: 6 }}>
              <Text sx={{ fontWeight: 'bold', fontSize: 1 }}>히스토리</Text>
              <ErrorBoundary>
                <QuizQuizHistoryPaginator fragmentReference={quiz}>
                  {({ histories }, { isLoadingNext, hasNext, loadMore }) => (
                    <Timeline>
                      <ItemList
                        items={histories.edges}
                        renderItem={({ node }) => <QuizHistoryTimelineItem quizHistory={node} />}
                        renderItemWrapper={(baseChildren, { node }) => {
                          const children = <View sx={{ paddingX: 2 }}>{baseChildren}</View>;
                          return (
                            <React.Fragment key={node.id}>
                              {node.type === 'quiz_update' ? (
                                <DialogHandler
                                  key={node.id}
                                  renderDialog={({ isOpen, closeDialog }) => (
                                    <QuizHistoryDialog
                                      quizHistory={node}
                                      isOpen={isOpen}
                                      onDismiss={() => closeDialog()}
                                      wide
                                    />
                                  )}
                                >
                                  <View
                                    sx={{
                                      'borderRadius': 2,
                                      'cursor': 'pointer',
                                      'transition': 'background-color 250ms',
                                      ':hover': { backgroundColor: 'canvas.subtle' },
                                    }}
                                  >
                                    {children}
                                  </View>
                                </DialogHandler>
                              ) : (
                                children
                              )}
                            </React.Fragment>
                          );
                        }}
                      />
                      {hasNext ? (
                        <Button sx={{ width: '100%' }} disabled={isLoadingNext} onClick={() => loadMore(10)}>
                          더보기
                        </Button>
                      ) : (
                        <Timeline.Break />
                      )}
                    </Timeline>
                  )}
                </QuizQuizHistoryPaginator>
              </ErrorBoundary>
            </View>
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

QuizId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
QuizId.authenticated = true;
QuizId.routes = [{ id: 'quizId', pathname: '/quiz/[quizId]', name: 'OX 퀴즈 상세', permissions: ['quiz_read'] }];

export default QuizId;
