/**
 * @generated SignedSource<<b8751a15732c47ccf3184eb5b377350e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScrapSourceScrapsDeleteInput = {
  scrapSource: string;
  scraps: ReadonlyArray<string>;
};
export type ScrapSourceId_scrapSourceScrapsDeleteMutation$variables = {
  input: ScrapSourceScrapsDeleteInput;
};
export type ScrapSourceId_scrapSourceScrapsDeleteMutation$data = {
  readonly scrapSourceScrapsDelete: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceScrapPaginator_scrapSource">;
  };
};
export type ScrapSourceId_scrapSourceScrapsDeleteMutation = {
  response: ScrapSourceId_scrapSourceScrapsDeleteMutation$data;
  variables: ScrapSourceId_scrapSourceScrapsDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScrapSourceId_scrapSourceScrapsDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScrapSource",
        "kind": "LinkedField",
        "name": "scrapSourceScrapsDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScrapSourceScrapPaginator_scrapSource"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScrapSourceId_scrapSourceScrapsDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScrapSource",
        "kind": "LinkedField",
        "name": "scrapSourceScrapsDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ScrapConnection",
            "kind": "LinkedField",
            "name": "scraps",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScrapEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scrap",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "actions",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "problemBookPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "solutionBookPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "problemNumber",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "connection",
            "key": "ScrapSourceScrapPaginator_scrapSource_scraps",
            "kind": "LinkedHandle",
            "name": "scraps"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5315b4c619a777291abc238cee31217b",
    "id": null,
    "metadata": {},
    "name": "ScrapSourceId_scrapSourceScrapsDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ScrapSourceId_scrapSourceScrapsDeleteMutation(\n  $input: ScrapSourceScrapsDeleteInput!\n) {\n  scrapSourceScrapsDelete(input: $input) {\n    id\n    ...ScrapSourceScrapPaginator_scrapSource\n  }\n}\n\nfragment ScrapItem_scrap on Scrap {\n  id\n  problemBookPage\n  solutionBookPage\n  problemNumber\n}\n\nfragment ScrapSourceScrapConnectionDataTable_scrapConnection on ScrapConnection {\n  edges {\n    node {\n      id\n      actions\n      problemNumber\n      problemBookPage\n      solutionBookPage\n    }\n  }\n}\n\nfragment ScrapSourceScrapPaginator_scrapSource on ScrapSource {\n  scraps {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        actions\n        ...ScrapItem_scrap\n        __typename\n      }\n    }\n    ...ScrapSourceScrapConnectionDataTable_scrapConnection\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "3e5b8b26ca2803cab58ca7071cd1843d";

export default node;
