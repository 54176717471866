import 'katex/dist/katex.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/styles.css';
import '../styles/animation/toast.css';
import 'react-18-image-lightbox/style.css';
import 'react-calendar/dist/Calendar.css';
import 'cropperjs/dist/cropper.min.css';
import '../styles/calendar.css';

import { BaseStyles, SSRProvider, ThemeProvider, useTheme } from '@primer/react';
import type { NextPage as BaseNextPage } from 'next';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';
import { RelayEnvironmentProvider } from 'react-relay';
import { ToastContainer } from 'react-toastify';

import ErrorBoundary from '../components/core/ErrorBoundary';
import ErrorState from '../components/core/ErrorState';
import { AuthenticatedRoute, UnauthenticatedRoute, PermissionRoute, RouteType } from '../components/core/Route';
import View from '../components/core/View';
import { PRIMER_OVERLAY_ZINDEX } from '../constants/zIndexes';
import { ColorModeContextProvider } from '../contexts/ColorModeContext';
import { ToastTransition } from '../hooks/useToast';
import RelayEnvironment from '../relay/environment';

type PageWithLayout = { getLayout?: (page: ReactElement) => ReactNode };
type PageWithAuthenticated = { authenticated: boolean };
type PageWithRoutes = { routes: RouteType[] };

export type NextPage<P = {}, IP = P> = BaseNextPage<P, IP> & PageWithLayout & PageWithAuthenticated & PageWithRoutes;

type AppPropsWithLayout = AppProps & {
  Component: NextPage;
};

const MyApp = ({ Component, pageProps, router }: AppPropsWithLayout) => {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <SSRProvider>
      <ColorModeContextProvider>
        {({ colorMode }) => (
          <ThemeProvider colorMode={colorMode} preventSSRMismatch>
            <CssThemeWrapper>
              <BaseStyles>
                <SessionProvider session={pageProps.session}>
                  <ErrorBoundary
                    fallback={
                      <View
                        sx={{
                          width: '100vw',
                          height: '100vh',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <ErrorState description={'잠시 후에 다시 시도해 주세요'} />
                      </View>
                    }
                  >
                    <RelayEnvironmentProvider environment={RelayEnvironment}>
                      {Component.authenticated ? (
                        <AuthenticatedRoute>
                          <PermissionRoute router={router}>{getLayout(<Component {...pageProps} />)}</PermissionRoute>
                        </AuthenticatedRoute>
                      ) : (
                        <UnauthenticatedRoute>{getLayout(<Component {...pageProps} />)}</UnauthenticatedRoute>
                      )}
                      <div id="dialog_root" />
                      <div id="__primerPortalRoot__" style={{ position: 'absolute', zIndex: PRIMER_OVERLAY_ZINDEX }} />
                      <ToastContainer
                        hideProgressBar={true}
                        autoClose={2500}
                        position={'top-center'}
                        transition={ToastTransition}
                        closeButton={false}
                        style={{ width: 'auto', top: '160px', left: '50%', transform: 'translateX(-50%)' }}
                        toastStyle={{ backgroundColor: 'transparent', padding: 0, minHeight: 'auto' }}
                        bodyStyle={{ padding: 0 }}
                        pauseOnFocusLoss={false}
                      />
                      <GlobalTheme />
                    </RelayEnvironmentProvider>
                  </ErrorBoundary>
                </SessionProvider>
              </BaseStyles>
            </CssThemeWrapper>
          </ThemeProvider>
        )}
      </ColorModeContextProvider>
    </SSRProvider>
  );
};

const CssThemeWrapper = ({ children }: PropsWithChildren) => {
  const { resolvedColorScheme } = useTheme();

  return (
    <div data-color-mode={resolvedColorScheme} data-dark-theme={'dark'} data-light-theme={'light'}>
      {children}
    </div>
  );
};

const GlobalTheme = () => {
  const { theme } = useTheme();
  return (
    <style jsx global>{`
      html,
      body {
        background-color: ${theme?.colors.canvas.default};
      }
      :any-link {
        color: inherit;
        font-weight: inherit;
        text-decoration: none;
      }
      fieldset {
        padding: 0;
        margin: 0;
      }
      input[type='checkbox'],
      input[type='radio'] {
        width: 20px;
        height: 20px;
        margin: 3px 0;
      }
      div:has(> input[type='checkbox'], > input[type='radio']) + div {
        justify-content: center;
      }
      div:has(> input[type='checkbox'], > input[type='radio']) {
        display: flex;
        align-items: center;
      }

      .sketch-picker {
        background: ${theme?.colors.canvas.overlay} !important;
      }
      .sketch-picker label {
        color: ${theme?.colors.fg.default} !important;
      }
      .cropper-modal {
        opacity: 0.3;
      }
      .cropper-line {
        padding: 10px;
      }
    `}</style>
  );
};

export default MyApp;
