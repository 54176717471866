import { graphql, useFragment } from 'react-relay';

import { InternalLoginTypeUpdateDialog_internalLoginTypeUpdateMutation } from '../../../relay/__generated__/InternalLoginTypeUpdateDialog_internalLoginTypeUpdateMutation.graphql';
import { InternalLoginTypeUpdateDialog_internalUser$key } from '../../../relay/__generated__/InternalLoginTypeUpdateDialog_internalUser.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const InternalLoginTypeUpdateDialog_internalUser = graphql`
  fragment InternalLoginTypeUpdateDialog_internalUser on MATInternalUser {
    id
    loginId
  }
`;

type Props = {
  internalUser: InternalLoginTypeUpdateDialog_internalUser$key;
} & DialogProps &
  Pick<MutationFormikProps<InternalLoginTypeUpdateDialog_internalLoginTypeUpdateMutation>, 'config' | 'onSubmit'>;

const InternalLoginTypeUpdateDialog = ({ internalUser: internalUserReference, onSubmit, config, ...props }: Props) => {
  const data = useFragment(InternalLoginTypeUpdateDialog_internalUser, internalUserReference);
  const { id, loginId } = data;
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>유저 ID/PW 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<InternalLoginTypeUpdateDialog_internalLoginTypeUpdateMutation>
        mutation={graphql`
          mutation InternalLoginTypeUpdateDialog_internalLoginTypeUpdateMutation(
            $input: MATInternalLoginTypeUpdateInput!
          ) {
            internalLoginTypeUpdate(input: $input) {
              id
              loginId
            }
          }
        `}
        initialValues={{
          id: id,
          loginId: loginId,
          password: '',
        }}
        onSubmit={onSubmit}
        config={config}
      >
        {({ submitForm, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField label={'로그인 ID'} name={'loginId'} required />
                <TextField label={'비밀번호'} name={'password'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button onClick={submitForm} disabled={!dirty}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default InternalLoginTypeUpdateDialog;
