import { LogoColorIcon } from '@teamturing/icons';

import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';

type Props = { title?: string; body?: string };

const AppPushPreview = ({ title, body }: Props) => {
  return (
    <View sx={{ borderRadius: '20px', backgroundColor: 'canvas.inset', padding: 3 }}>
      <Stack gapX={2}>
        <Stack.Item>
          <View
            sx={{
              width: '38px',
              height: '38px',
              borderRadius: 2,
              backgroundColor: 'canvas.default',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LogoColorIcon width={34} height={34} />
          </View>
        </Stack.Item>
        <Stack.Item>
          <View sx={{ lineHeight: 1 }}>
            <Text fontSize={1} fontWeight={'bold'} whiteSpace={'pre-wrap'}>
              {title || '제목'}
            </Text>
          </View>
          <View sx={{ lineHeight: 1 }}>
            <Text fontSize={0} color={'fg.muted'} whiteSpace={'pre-wrap'}>
              {body || '본문'}
            </Text>
          </View>
        </Stack.Item>
      </Stack>
    </View>
  );
};

export default AppPushPreview;
