/**
 * @generated SignedSource<<31d3baef63b1c749226ccd610592e96e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationCheckInput = {
  id: string;
};
export type NotificationHeaderItem_notificationCheckMutation$variables = {
  input: NotificationCheckInput;
};
export type NotificationHeaderItem_notificationCheckMutation$data = {
  readonly notificationCheck: {
    readonly checked: any | null;
    readonly id: string;
  };
};
export type NotificationHeaderItem_notificationCheckMutation = {
  response: NotificationHeaderItem_notificationCheckMutation$data;
  variables: NotificationHeaderItem_notificationCheckMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Notification",
    "kind": "LinkedField",
    "name": "notificationCheck",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "checked",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationHeaderItem_notificationCheckMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationHeaderItem_notificationCheckMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "869a33e59cdf89404321efb5d54b3f83",
    "id": null,
    "metadata": {},
    "name": "NotificationHeaderItem_notificationCheckMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationHeaderItem_notificationCheckMutation(\n  $input: NotificationCheckInput!\n) {\n  notificationCheck(input: $input) {\n    id\n    checked\n  }\n}\n"
  }
};
})();

(node as any).hash = "164eef8defb6cfe74112a2030cb235d3";

export default node;
