import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import { TaskVideoSelectField_taskVideosQuery } from '../../../relay/__generated__/TaskVideoSelectField_taskVideosQuery.graphql';
import { formatDate } from '../../../utils/date';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const taskVideosForTaskVideoSelectField = graphql`
  query TaskVideoSelectField_taskVideosQuery($filters: TaskVideoFilter, $first: Int) {
    taskVideos(filters: $filters, first: $first) @connection(key: "TaskVideoSelectFieldQuery_taskVideos") {
      edges {
        node {
          id
          title
          video {
            id
            transcodingStatus
          }
          type
          teacher {
            id
            name
          }
          created
        }
      }
    }
  }
`;

type Props = {
  taskId: string;
} & Omit<SelectFieldProps, 'options'>;

const TaskVideoSelectField = ({ taskId, name = '', ...props }: Props) => {
  const [{ taskVideos }] = useLazyLoadQuery<TaskVideoSelectField_taskVideosQuery>(
    taskVideosForTaskVideoSelectField,
    {
      filters: { taskId_Exact: taskId },
    },
    { fetchPolicy: 'store-and-network' },
  );
  const { edges = [] } = taskVideos || {};
  const options =
    edges?.map(({ node }) => {
      const { id, title: nodeTitle, video, type, created, teacher } = node;
      const typeString = type === 'horizontal' ? '가로형' : type === 'vertical' ? '세로형' : '';
      const createdString = formatDate(created, 'P a hh:mm');
      const title = `${nodeTitle} (${typeString} · ${teacher.name} · ${createdString})`;

      const titleMeta =
        video.transcodingStatus === 'failed'
          ? '트랜스코딩 실패'
          : video.transcodingStatus === 'ongoing'
          ? '트랜스코딩 진행 중'
          : video.transcodingStatus === 'pending'
          ? '트랜스코딩 준비 중'
          : video.transcodingStatus === 'success'
          ? ''
          : '상태 알 수 없음';

      return {
        id: id,
        text: `${(titleMeta ? [title, titleMeta] : [title]).join(' - ')}`,
        disabled: video.transcodingStatus !== 'success',
      };
    }) || [];

  return <SelectField name={name} options={options} {...props} />;
};

export default TaskVideoSelectField;
