import { useRef, useState } from 'react';
import { useSubscription as baseUseSubscription } from 'react-relay';
import { GraphQLSubscriptionConfig, OperationType, requestSubscription } from 'relay-runtime';

const useSubscription = <TSubscription extends OperationType>(
  config: GraphQLSubscriptionConfig<TSubscription>,
  requestSubscriptionFn?: typeof requestSubscription,
) => {
  const [data, setData] = useState<TSubscription['response'] | null | undefined>();
  const _config = useRef<GraphQLSubscriptionConfig<TSubscription>>({
    ...config,
    onNext: (data) => {
      setData(data);
      config?.onNext?.(data);
    },
  });
  baseUseSubscription(_config.current, requestSubscriptionFn);

  return { data };
};

export default useSubscription;
