/**
 * @generated SignedSource<<30c77519cb61d6d40013fce30a8ec46d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskHintFeedbackPublishDialog_task$data = {
  readonly hint: {
    readonly id: string;
    readonly text: string | null;
  } | null;
  readonly id: string;
  readonly submitFeedback: {
    readonly general: string | null;
    readonly id: string;
    readonly special: string | null;
    readonly specialAnswer: string | null;
  } | null;
  readonly " $fragmentType": "TaskHintFeedbackPublishDialog_task";
};
export type TaskHintFeedbackPublishDialog_task$key = {
  readonly " $data"?: TaskHintFeedbackPublishDialog_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskHintFeedbackPublishDialog_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskHintFeedbackPublishDialog_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskHint",
      "kind": "LinkedField",
      "name": "hint",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskSubmitFeedback",
      "kind": "LinkedField",
      "name": "submitFeedback",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "specialAnswer",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "special",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "general",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};
})();

(node as any).hash = "cfaa1224566b8f719a1c0702f47072ec";

export default node;
