/**
 * @generated SignedSource<<19d291ff9c2d1721fa46a3ff9daf6be4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormulaSetUpdateDialog_formulaSet$data = {
  readonly description: string | null;
  readonly formulas: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly text: string;
        readonly title: string;
      };
    }>;
    readonly totalCount: number | null;
  };
  readonly id: string;
  readonly unitD: {
    readonly title: string;
    readonly unitATitle: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"FormulaSetStatusLabel_formulaSet">;
  readonly " $fragmentType": "FormulaSetUpdateDialog_formulaSet";
};
export type FormulaSetUpdateDialog_formulaSet$key = {
  readonly " $data"?: FormulaSetUpdateDialog_formulaSet$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormulaSetUpdateDialog_formulaSet">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormulaSetUpdateDialog_formulaSet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnitD",
      "kind": "LinkedField",
      "name": "unitD",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitATitle",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FormulaConnection",
      "kind": "LinkedField",
      "name": "formulas",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FormulaEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Formula",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "text",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormulaSetStatusLabel_formulaSet"
    }
  ],
  "type": "FormulaSet",
  "abstractKey": null
};
})();

(node as any).hash = "313f7588a6f9719514e6bb96b4bcd811";

export default node;
