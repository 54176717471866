import { Checkbox as PrimerCheckbox, CheckboxProps as PrimerCheckboxProps } from '@primer/react';
import { useField } from 'formik';
import { ComponentProps, forwardRef, Ref } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import FormControl from '../FormControl';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
} & PrimerCheckboxProps;

const CheckboxField = (
  { label, labelConfig = {}, caption, disabled, required, name = '', onChange: propOnChange, ...props }: Props,
  ref: Ref<HTMLInputElement>,
) => {
  const [{ value, onChange, ...restProps }, { error }, { setError }] = useField({
    name,
  });

  const { isSubmitting } = useFormikContext();

  const handleChange: PrimerCheckboxProps['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else onChange(...arg);
  };

  return (
    <FormControl disabled={disabled || isSubmitting} required={required} sx={{ alignItems: 'center' }}>
      <PrimerCheckbox ref={ref} checked={value} value={value} onChange={handleChange} {...restProps} {...props} />
      <FormControl.Label {...labelConfig} sx={{ fontWeight: 'normal' }}>
        {label}
      </FormControl.Label>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default forwardRef(CheckboxField);
export type { Props as CheckboxFieldProps };
