import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  ScrapSourceDescriptionList_scrapSource$data,
  ScrapSourceDescriptionList_scrapSource$key,
} from '../../../relay/__generated__/ScrapSourceDescriptionList_scrapSource.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import ScrapSourceStatusLabel from '../ScrapSourceStatusLabel';

const ScrapSourceDescriptionList_scrapSource = graphql`
  fragment ScrapSourceDescriptionList_scrapSource on ScrapSource {
    id
    type

    nonExamInfo {
      bookName
      unitAs {
        id
        title
      }
      bookPublisher
    }
    examInfo {
      year
      month
      grade
      source
      category
    }

    ...ScrapSourceStatusLabel_scrapSource
    created
    createdBy {
      id
      ...UserAvatarText_user
    }
    modified
    submittedBy {
      id
      ...UserAvatarText_user
    }
  }
`;

type Props = {
  scrapSource: ScrapSourceDescriptionList_scrapSource$key;
  type?: 'examInfo' | 'nonExamInfo' | 'activity';
} & Omit<DescriptionListProps<ScrapSourceDescriptionList_scrapSource$data>, 'item' | 'itemDescriptions' | 'picks'>;
const ScrapSourceDescriptionList = ({ scrapSource: scrapSourceReference, type = 'examInfo', ...props }: Props) => {
  const scrapSource = useFragment(ScrapSourceDescriptionList_scrapSource, scrapSourceReference);

  return (
    <DescriptionList
      item={scrapSource}
      itemDescriptions={{
        'examInfo.examYear': {
          title: '연도',
          renderValue: ({ examInfo }) =>
            examInfo ? <EnumPair typename={'ScrapSourceExamYearEnum'}>{examInfo.year}</EnumPair> : undefined,
        },
        'examInfo.examMonth': {
          title: '월',
          renderValue: ({ examInfo }) =>
            examInfo ? <EnumPair typename={'ScrapSourceExamMonthEnum'}>{examInfo.month}</EnumPair> : undefined,
        },
        'examInfo.examGrade': {
          title: '학년',
          renderValue: ({ examInfo }) =>
            examInfo ? <EnumPair typename={'ScrapSourceExamGradeEnum'}>{examInfo.grade}</EnumPair> : undefined,
        },
        'examInfo.examSource': {
          title: '출제기관',
          renderValue: ({ examInfo }) =>
            examInfo ? <EnumPair typename={'ScrapSourceExamSourceEnum'}>{examInfo.source}</EnumPair> : undefined,
        },
        'examInfo.examCategory': {
          title: '구분',
          renderValue: ({ examInfo }) =>
            examInfo ? <EnumPair typename={'ScrapSourceExamCategoryEnum'}>{examInfo.category}</EnumPair> : undefined,
        },

        'nonExamInfo.bookName': {
          title: '출처',
          renderValue: ({ nonExamInfo }) =>
            nonExamInfo ? <Text fontSize={1}>{nonExamInfo.bookName}</Text> : undefined,
        },
        'nonExamInfo.unitAs': {
          title: 'UnitA',
          renderValue: ({ nonExamInfo }) =>
            nonExamInfo ? (
              <Text fontSize={1}>{nonExamInfo.unitAs.map(({ title }) => title).join(', ')}</Text>
            ) : undefined,
        },
        'nonExamInfo.bookPublisher': {
          title: '출판사',
          renderValue: ({ nonExamInfo }) =>
            nonExamInfo ? <Text fontSize={1}>{nonExamInfo.bookPublisher}</Text> : undefined,
        },

        'status': {
          title: '상태',
          renderValue: (scrapSource) => <ScrapSourceStatusLabel scrapSource={scrapSource} />,
        },
        'createdBy': {
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
        },
        'submittedBy': {
          title: '제출자',
          renderValue: ({ submittedBy }) => (submittedBy ? <UserAvatarText user={submittedBy} /> : undefined),
        },
        'created': {
          title: '제작일',
          renderValue: ({ created }) => <DateText fontSize={1}>{created}</DateText>,
        },
        'modified': {
          title: '수정일',
          renderValue: ({ modified }) => <DateText fontSize={1}>{modified}</DateText>,
        },
      }}
      picks={
        type === 'examInfo'
          ? [
              'examInfo.examYear',
              'examInfo.examMonth',
              'examInfo.examGrade',
              'examInfo.examSource',
              'examInfo.examCategory',
            ]
          : type === 'nonExamInfo'
          ? ['nonExamInfo.bookName', 'nonExamInfo.unitAs', 'nonExamInfo.bookPublisher']
          : type === 'activity'
          ? ['status', 'createdBy', 'submittedBy', 'created', 'modified']
          : []
      }
      {...props}
    />
  );
};

export default ScrapSourceDescriptionList;
