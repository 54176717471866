/**
 * @generated SignedSource<<56ee4f54eee90f92e5f0b75bb580cd58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormulaSetDifferenceItemList_formulaSetDifference$data = {
  readonly description?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly formulas?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"FormulaDifferenceItemList_formulaDifference">;
  }> | null;
  readonly " $fragmentType": "FormulaSetDifferenceItemList_formulaSetDifference";
};
export type FormulaSetDifferenceItemList_formulaSetDifference$key = {
  readonly " $data"?: FormulaSetDifferenceItemList_formulaSetDifference$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormulaSetDifferenceItemList_formulaSetDifference">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormulaSetDifferenceItemList_formulaSetDifference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FormulaDifference",
          "kind": "LinkedField",
          "name": "formulas",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FormulaDifferenceItemList_formulaDifference"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "description",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DiffItem_diff"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "FormulaSetDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};

(node as any).hash = "348f86afd53ccd5cbc76d69211bfe561";

export default node;
