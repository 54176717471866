import { graphql, useFragment } from 'react-relay';

import { TaskVideoDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/TaskVideoDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const TaskVideoDifferenceCounterLabel_difference = graphql`
  fragment TaskVideoDifferenceCounterLabel_difference on Difference {
    ... on TaskVideoDifference {
      description {
        before
        after
      }
      teacherName {
        before
        after
      }
      title {
        before
        after
      }
      type {
        before
        after
      }
    }
  }
`;

type Props = {
  difference: TaskVideoDifferenceCounterLabel_difference$key;
} & CounterLabelProps;

const TaskVideoDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { description, teacherName, title, type } = useFragment(TaskVideoDifferenceCounterLabel_difference, difference);
  const differenceCount = [description, teacherName, title, type].filter((diff) => !isNullable(diff)).length;

  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default TaskVideoDifferenceCounterLabel;
