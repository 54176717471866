import { useFragment, graphql } from 'react-relay';

import { UnitDItem_unitD$key } from '../../../relay/__generated__/UnitDItem_unitD.graphql';
import Text from '../../core/Text';
import View from '../../core/View';

const UnitDItem_unitD = graphql`
  fragment UnitDItem_unitD on UnitD {
    id
    unitATitle
    unitBTitle
    unitCTitle
    description
  }
`;

type Props = { unitD: UnitDItem_unitD$key };

const UnitDItem = ({ unitD }: Props) => {
  const { description, unitATitle, unitBTitle, unitCTitle } = useFragment(UnitDItem_unitD, unitD);
  return (
    <>
      <View>
        <Text fontSize={1}>{description}</Text>
      </View>
      <View>
        <Text fontSize={0} color={'fg.subtle'}>
          {[unitATitle, unitBTitle, unitCTitle].join(' > ')}
        </Text>
      </View>
    </>
  );
};

export default UnitDItem;
