import { graphql, useFragment } from 'react-relay';

import { QuizQuizSetItem_quiz$key } from '../../../relay/__generated__/QuizQuizSetItem_quiz.graphql';
import Card from '../../core/Card';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';

const QuizQuizSetItem_quiz = graphql`
  fragment QuizQuizSetItem_quiz on Quiz {
    sequence
    unitD {
      title
    }
    answer
    problem
    solution
    tip
  }
`;

type Props = {
  quiz: QuizQuizSetItem_quiz$key;
};

const QuizQuizSetItem = ({ quiz }: Props) => {
  const { sequence, unitD, answer, problem, solution, tip } = useFragment(QuizQuizSetItem_quiz, quiz);
  return (
    <View>
      <Grid>
        <Grid.Unit size={'max'}>
          <Stack gapX={2}>
            <Stack.Item>
              <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{sequence}</Text>
            </Stack.Item>
            <Stack.Item>
              <Text sx={{ fontSize: 3, fontWeight: 'bold', color: 'fg.subtle' }}>{unitD.title}</Text>
            </Stack.Item>
          </Stack>
        </Grid.Unit>
        <Grid.Unit size={'min'}>
          <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>
            정답 <Text sx={{ color: answer === 'O' ? 'success.fg' : 'danger.fg' }}>{answer}</Text>
          </Text>
        </Grid.Unit>
      </Grid>
      <View sx={{ marginTop: 3 }}>
        <Grid gapX={5}>
          <ItemList
            items={[
              { title: '문제', children: problem },
              { title: '해설', children: solution },
              { title: '개념 TIP', children: tip },
            ]}
            renderItem={({ title, children }) => (
              <>
                <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                <Card
                  sx={{
                    maxWidth: '100%',
                    marginTop: 2,
                    minHeight: 200,
                    flex: 1,
                  }}
                >
                  <Katex>{children}</Katex>
                </Card>
              </>
            )}
            renderItemWrapper={(children, { title }) => (
              <Grid.Unit
                key={title}
                size={[1, 1, 1 / 3]}
                sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
              >
                {children}
              </Grid.Unit>
            )}
          />
        </Grid>
      </View>
    </View>
  );
};

export default QuizQuizSetItem;
