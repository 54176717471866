/**
 * @generated SignedSource<<35a89f233ab16a68daba789062168006>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Pagination_pages$data = {
  readonly around: ReadonlyArray<{
    readonly isCurrent: boolean;
    readonly page: number;
  }>;
  readonly first: {
    readonly page: number;
  } | null;
  readonly last: {
    readonly page: number;
  } | null;
  readonly next: {
    readonly page: number;
  } | null;
  readonly previous: {
    readonly page: number;
  } | null;
  readonly " $fragmentType": "Pagination_pages";
};
export type Pagination_pages$key = {
  readonly " $data"?: Pagination_pages$data;
  readonly " $fragmentSpreads": FragmentRefs<"Pagination_pages">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Pagination_pages",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "first",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "around",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurrent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "last",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "next",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Page",
      "kind": "LinkedField",
      "name": "previous",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Pages",
  "abstractKey": null
};
})();

(node as any).hash = "31fc003f8b6d4992e6dc88754f8612d7";

export default node;
