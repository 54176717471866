import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  UserAvatarRadioGroupField_usersQuery,
  UserAvatarRadioGroupField_usersQuery$variables,
} from '../../../relay/__generated__/UserAvatarRadioGroupField_usersQuery.graphql';
import RadioGroupField, { RadioGroupFieldProps } from '../../core/RadioGroupField';
import UserAvatarText from '../UserAvatarText';

const usersForUserAvatarRadioGroupField = graphql`
  query UserAvatarRadioGroupField_usersQuery($filters: UserFilter) {
    users(filters: $filters) {
      edges {
        node {
          id
          name
          ...UserAvatarText_user
        }
      }
    }
  }
`;

type Props = {
  variables?: UserAvatarRadioGroupField_usersQuery$variables;
} & Omit<RadioGroupFieldProps, 'options' | 'renderOption'>;

const UserAvatarRadioGroupField = ({ variables, ...props }: Props) => {
  const [{ users }] = useLazyLoadQuery<UserAvatarRadioGroupField_usersQuery>(usersForUserAvatarRadioGroupField, {
    ...variables,
  });

  return (
    <RadioGroupField
      options={users.edges.map(({ node: { id, name } }) => ({ id, text: name }))}
      renderOption={({ id }) => {
        const user = users.edges.find(({ node }) => node.id === id)?.node;
        return user ? <UserAvatarText user={user} /> : null;
      }}
      {...props}
    />
  );
};

export default UserAvatarRadioGroupField;
