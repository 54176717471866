/**
 * @generated SignedSource<<ddbf6afe6d41c27e6d5bb25829b78aa9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FormulaSetActionEnum = "formula_set_review" | "formula_set_update" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FormulaSetMutationActionsButtonStack_formulaSet$data = {
  readonly actions: ReadonlyArray<FormulaSetActionEnum>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"FormulaSetUpdateDialog_formulaSet">;
  readonly " $fragmentType": "FormulaSetMutationActionsButtonStack_formulaSet";
};
export type FormulaSetMutationActionsButtonStack_formulaSet$key = {
  readonly " $data"?: FormulaSetMutationActionsButtonStack_formulaSet$data;
  readonly " $fragmentSpreads": FragmentRefs<"FormulaSetMutationActionsButtonStack_formulaSet">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FormulaSetMutationActionsButtonStack_formulaSet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FormulaSetUpdateDialog_formulaSet"
    }
  ],
  "type": "FormulaSet",
  "abstractKey": null
};

(node as any).hash = "ed30875217ed28a24667651907740116";

export default node;
