import { graphql, useFragment } from 'react-relay';

import {
  RaffleStuffConnectionDataTable_raffleStuffConnection$data,
  RaffleStuffConnectionDataTable_raffleStuffConnection$key,
} from '../../../relay/__generated__/RaffleStuffConnectionDataTable_raffleStuffConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';
import RaffleStuffPreviewItem from '../RaffleStuffPreviewItem';

const RaffleStuffConnectionDataTable_raffleStuffConnection = graphql`
  fragment RaffleStuffConnectionDataTable_raffleStuffConnection on RaffleStuffConnection {
    edges {
      node {
        id
        title
        stuff {
          id
          title
        }
        ...RaffleStuffPreviewItem_raffleStuff
        createdBy {
          id
          ...UserAvatarText_user
        }
        created
        modified
      }
    }
  }
`;

type Props = {
  raffleStuffConnection: RaffleStuffConnectionDataTable_raffleStuffConnection$key;
} & Omit<
  DataTableProps<RaffleStuffConnectionDataTable_raffleStuffConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const RaffleStuffConnectionDataTable = ({ raffleStuffConnection, ...props }: Props) => {
  const { edges } = useFragment(RaffleStuffConnectionDataTable_raffleStuffConnection, raffleStuffConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'title',
          title: '제목',
          renderValue: (raffleStuff) => {
            const { title } = raffleStuff;
            return (
              <Stack gapX={2}>
                <Stack.Item>
                  <View sx={{ width: 36, height: 36 }}>
                    <RaffleStuffPreviewItem raffleStuff={raffleStuff} />
                  </View>
                </Stack.Item>
                <Stack.Item>
                  <Text>{title}</Text>
                </Stack.Item>
              </Stack>
            );
          },
          width: 360,
        },
        {
          field: 'stuff',
          title: '물품',
          renderValue: ({ stuff }) => stuff.title,
          width: 360,
        },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 120,
        },
        { field: 'created', title: '제작일', renderValue: ({ created }) => <DateText>{created}</DateText>, width: 160 },
        {
          field: 'modified',
          title: '수정일',
          renderValue: ({ modified }) => <DateText>{modified}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default RaffleStuffConnectionDataTable;
