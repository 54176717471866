import React, { Suspense, useEffect, useState } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import useCaptureHtml from '../../../hooks/useCaptureHtml';
import useCsv from '../../../hooks/useCsv';
import useEnumPairsQuery from '../../../hooks/useEnumPairsQuery';
import useToast from '../../../hooks/useToast';
import {
  SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery,
  SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery$variables,
} from '../../../relay/__generated__/SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery.graphql';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import ErrorBoundary from '../../core/ErrorBoundary';
import ErrorState from '../../core/ErrorState';
import NumberInput from '../../core/NumberInput';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import ValidationTextView from '../../core/ValidationTextView';
import View from '../../core/View';
import SchoolExamSourceExportedPreview from '../SchoolExamSourceExportedPreview';
import SchoolExamSourcePaginator from '../SchoolExamSourcePaginator';

const schoolExamSourcesForSchoolExamSourceImageCsvExportDialog = graphql`
  query SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery(
    $filters: SchoolExamSourceFilter
    $first: Int
    $scrapOrder: SchoolExamScrapOrder
    $scrapCount: Int
  ) {
    schoolExamSources(filters: $filters, first: $first) {
      edges {
        node {
          id
          school {
            id
            title
          }
          title
        }
      }
    }
    ...SchoolExamSourcePaginator_query
      @arguments(filters: $filters, first: $first, scrapOrder: $scrapOrder, scrapCount: $scrapCount)
  }
`;

type Props = { variables?: SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery$variables } & DialogProps;

const CONTAINER_ID = '캡쳐해야하는요소';
const DEFAULT_SCRAP_COUNT = 6;

const SchoolExamSourceImageCsvExportDialog = ({ isOpen, variables, ...props }: Props) => {
  const { toast } = useToast();
  const schoolRegionPairs = useEnumPairsQuery('SchoolRegionEnum');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { uploadImageFromHtml } = useCaptureHtml({
    scale: 1.5,
    removeContainer: true,
  });
  const {
    reset: resetCsv,
    pushRowData,
    downloadCsv,
  } = useCsv({
    columnTitle: ['sequence', 'school_region', 'school_title', 'exam_title', 'image_url'],
  });

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery>(
      schoolExamSourcesForSchoolExamSourceImageCsvExportDialog,
    );

  const [index, setIndex] = useState(0);

  let uploadImage: (() => void) | null = null;

  const reset = () => {
    setIndex(0);
    resetCsv();
    disposeQuery();
  };
  useEffect(() => {
    if (isOpen) {
      loadQuery({ ...variables, scrapCount: DEFAULT_SCRAP_COUNT, scrapOrder: { order: 'ASC' } });
    } else {
      reset();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      uploadImage?.();
    }
  }, [index]);

  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>내신 기출 이미지 CSV로 추출</Text>
      </Dialog.Header>
      <Dialog.Body>
        <Stack gapX={1}>
          <Stack.Item>
            <Text fontSize={1}>문제 수 :</Text>
          </Stack.Item>
          <Stack.Item>
            <NumberInput
              defaultValue={DEFAULT_SCRAP_COUNT}
              min={1}
              disabled={isLoading}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  loadQuery({ ...variables, scrapCount: Number(e.currentTarget.value), scrapOrder: { order: 'ASC' } });
                }
              }}
              onBlur={(e) => {
                loadQuery({ ...variables, scrapCount: Number(e.currentTarget.value), scrapOrder: { order: 'ASC' } });
              }}
              sx={{ width: 100 }}
            />
          </Stack.Item>
          <Stack.Item>
            <Button onClick={() => uploadImage?.()} disabled={isLoading}>
              추출하기
            </Button>
          </Stack.Item>
          {isLoading ? (
            <>
              <Stack.Item>
                <Text fontSize={1}>{`현재 추출 된 내신 기출: ${index}개`}</Text>
              </Stack.Item>
              <Stack.Item>
                <Spinner size={'small'} />
              </Stack.Item>
            </>
          ) : null}
        </Stack>
        <ValidationTextView variant={'warning'}>
          {'내신 기출 리스트 페이지에서 필터링된 모든 내신 기출을 이미지로 변환하여 CSV 파일로 추출합니다.'}
        </ValidationTextView>{' '}
        <ValidationTextView variant={'warning'}>
          {'추출이 진행되는 동안 창을 닫지 않도록 주의해주세요.'}
        </ValidationTextView>
        {queryReference ? (
          <ErrorBoundary
            key={queryReference.fetchKey}
            fallback={({ parsedGraphQLError }) => (
              <View sx={{ padding: 5 }}>
                <ErrorState description={parsedGraphQLError?.[0].message} />
              </View>
            )}
          >
            <Suspense
              fallback={
                <View sx={{ padding: 5 }}>
                  <Spinner />
                </View>
              }
            >
              <PreloadedQueryRenderer<SchoolExamSourceImageCsvExportDialog_schoolExamSourcesQuery>
                query={schoolExamSourcesForSchoolExamSourceImageCsvExportDialog}
                queryReference={queryReference}
              >
                {(fragmentReference) => {
                  return (
                    <SchoolExamSourcePaginator fragmentReference={fragmentReference}>
                      {({ schoolExamSources }, { hasNext, loadMore }) => {
                        const schoolExamSource = schoolExamSources.edges[index]?.node;

                        if (!schoolExamSource)
                          return (
                            <View sx={{ padding: 5 }}>
                              <EmptyState title={'검색된 내신 기출이 없어요'} />
                            </View>
                          );

                        uploadImage = () => {
                          const container = document.getElementById(CONTAINER_ID);
                          if (!container) return;
                          setIsLoading(true);

                          uploadImageFromHtml(container, 'webflow/school_exam_source/' + schoolExamSource.sequence)
                            .then(({ metaData }) => {
                              pushRowData([
                                schoolExamSource.sequence + '',
                                schoolRegionPairs.find(({ key }) => key === schoolExamSource.school.region)?.value ||
                                  schoolExamSource.school.region,
                                schoolExamSource.school.title,
                                schoolExamSource.title,
                                metaData.object_url,
                              ]);

                              const isLast =
                                schoolExamSources.pageInfo.endCursor === schoolExamSources.edges[index].cursor;

                              if (!isLast) setIndex((index) => index + 1);
                              else if (isLast && hasNext)
                                loadMore(200, { onComplete: () => setIndex((index) => index + 1) });
                              else {
                                downloadCsv('school_exam_source');
                                toast('내신 기출 이미지 csv 추출이 완료됐어요', 'success');
                                setIsLoading(false);
                                reset();
                              }
                            })
                            .catch(() => {
                              toast('이미지 추출에 실패했습니다', 'error');

                              setIsLoading(false);
                              reset();
                            });
                        };

                        return (
                          <Card
                            sx={{
                              borderRadius: 0,
                              width: 'fit-content',
                              maxWidth: '100%',
                              overflowX: 'auto',
                              marginTop: 3,
                            }}
                          >
                            <View id={CONTAINER_ID} sx={{ width: 'fit-content' }}>
                              <SchoolExamSourceExportedPreview schoolExamSource={schoolExamSource} />
                            </View>
                          </Card>
                        );
                      }}
                    </SchoolExamSourcePaginator>
                  );
                }}
              </PreloadedQueryRenderer>
            </Suspense>
          </ErrorBoundary>
        ) : (
          <View sx={{ padding: 5 }}>
            <EmptyState title={'추출할 내신기출이 없어요'} description={'새로 시도하거나 필터를 변경해주세요'} />
          </View>
        )}
      </Dialog.Body>
    </Dialog>
  );
};

export default SchoolExamSourceImageCsvExportDialog;
