import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  CodepushVersionStatusEnum,
  CodepushVersionStatusLabel_codepushVersion$key,
} from '../../../relay/__generated__/CodepushVersionStatusLabel_codepushVersion.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const CodepushVersionStatusLabel_codepushVersion = graphql`
  fragment CodepushVersionStatusLabel_codepushVersion on CodepushVersion {
    status
  }
`;

type Props = {
  codepushVersion: CodepushVersionStatusLabel_codepushVersion$key;
} & Omit<LabelProps, 'variant'>;

const CodepushVersionStatusLabel = ({ codepushVersion, ...props }: Props) => {
  const { status } = useFragment(CodepushVersionStatusLabel_codepushVersion, codepushVersion);

  const labelPropsByStatus: Record<CodepushVersionStatusEnum, LabelProps> = {
    'production': { variant: 'success' },
    'staging': { variant: 'secondary' },
    '%future added value': {},
  };

  return (
    <Suspense>
      <Label {...labelPropsByStatus[status]} {...props}>
        <EnumPair typename={'CodepushVersionStatusEnum'}>{status}</EnumPair>
      </Label>
    </Suspense>
  );
};

export default CodepushVersionStatusLabel;
