import { graphql, useFragment } from 'react-relay';

import {
  AppPushStatusEnum,
  AppPushStatusLabel_appPush$key,
} from '../../../relay/__generated__/AppPushStatusLabel_appPush.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const AppPushStatusLabel_appPush = graphql`
  fragment AppPushStatusLabel_appPush on AppPush {
    status
  }
`;

type Props = {
  appPush: AppPushStatusLabel_appPush$key;
} & Omit<LabelProps, 'variant'>;

const AppPushStatusLabel = ({ appPush, ...props }: Props) => {
  const { status } = useFragment(AppPushStatusLabel_appPush, appPush);

  const labelPropsByStatus: Record<AppPushStatusEnum, LabelProps> = {
    'completed': { variant: 'secondary' },
    'scheduled': { variant: 'accent' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'AppPushStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default AppPushStatusLabel;
