import { graphql, useFragment } from 'react-relay';

import {
  StoreStuffConnectionDataTable_storeStuffConnection$data,
  StoreStuffConnectionDataTable_storeStuffConnection$key,
} from '../../../relay/__generated__/StoreStuffConnectionDataTable_storeStuffConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';

const StoreStuffConnectionDataTable_storeStuffConnection = graphql`
  fragment StoreStuffConnectionDataTable_storeStuffConnection on StoreStuffConnection {
    edges {
      node {
        id
        sequence
        title
        type
        period
        endAt
        createdBy {
          id
          ...UserAvatarText_user
        }
        modified
      }
    }
  }
`;

type Props = {
  storeStuffConnection: StoreStuffConnectionDataTable_storeStuffConnection$key;
} & Omit<
  DataTableProps<StoreStuffConnectionDataTable_storeStuffConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const StoreStuffConnectionDataTable = ({ storeStuffConnection, ...props }: Props) => {
  const { edges } = useFragment(StoreStuffConnectionDataTable_storeStuffConnection, storeStuffConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'sequence',
          title: 'ID',
          renderValue: ({ sequence }) => <Text fontSize={1}>{sequence}</Text>,
          width: 80,
        },
        {
          field: 'title',
          title: '상품명',
          renderValue: ({ title }) => <Text fontSize={1}>{title}</Text>,
          width: 524,
        },
        {
          field: 'type',
          title: '타입',
          renderValue: ({ type }) => <EnumPair typename={'StoreStuffTypeEnum'}>{type}</EnumPair>,
          width: 120,
        },
        {
          field: 'period',
          title: '기간',
          renderValue: ({ period }) => (period ? <Text fontSize={1}>{period}일</Text> : '-'),
          width: 92,
        },
        {
          field: 'endAt',
          title: '만료일',
          renderValue: ({ endAt }) =>
            endAt ? (
              <DateText fontSize={1} format={'P'}>
                {endAt}
              </DateText>
            ) : (
              '-'
            ),
          width: 92,
        },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 92,
        },
        {
          field: 'modified',
          title: '수정일',
          renderValue: ({ modified }) => <DateText fontSize={1}>{modified}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default StoreStuffConnectionDataTable;
