/**
 * @generated SignedSource<<34e2c84d7e98909327cdc6e2bec169fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScrapSourceStatusEnum = "completed" | "ongoing" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScrapSourceStatusLabel_scrapSource$data = {
  readonly status: ScrapSourceStatusEnum;
  readonly " $fragmentType": "ScrapSourceStatusLabel_scrapSource";
};
export type ScrapSourceStatusLabel_scrapSource$key = {
  readonly " $data"?: ScrapSourceStatusLabel_scrapSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceStatusLabel_scrapSource">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScrapSourceStatusLabel_scrapSource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "ScrapSource",
  "abstractKey": null
};

(node as any).hash = "7dbab9773405f06e955a594266caf7db";

export default node;
