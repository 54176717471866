import { ChevronDownIcon, SyncIcon } from '@primer/octicons-react';
import { TextInputWithTokens, TextInputWithTokensProps } from '@primer/react';
import { useField, FieldConfig } from 'formik';
import {
  ComponentProps,
  useRef,
  useState,
  MouseEvent as ReactMouseEvent,
  KeyboardEvent as ReactKeyboardEvent,
} from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import AnchoredOverlay, { AnchoredOverlayProps } from '../AnchoredOverlay';
import Button from '../Button';
import FormControl from '../FormControl';
import Grid from '../Grid';
import HorizontalDivider from '../HorizontalDivider';
import Text from '../Text';
import Truncate from '../Truncate';
import View from '../View';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  value?: string[];
  onChange?: (id: string) => void;
  renderTokenText?: (id: string) => string;
  renderHeader?: () => React.ReactNode;
  onOverlayOpen?: () => void;
  onOverlayClose?: () => void;
  children: ({
    handleSelect,
  }: {
    handleSelect: (e: ReactMouseEvent<HTMLDivElement> | ReactKeyboardEvent<HTMLDivElement>, selected: string) => void;
  }) => React.ReactNode;
  caption?: string;
} & Pick<
  TextInputWithTokensProps,
  | 'placeholder'
  | 'disabled'
  | 'name'
  | 'required'
  | 'trailingAction'
  | 'visibleTokenCount'
  | 'preventTokenWrapping'
  | 'size'
> &
  Pick<AnchoredOverlayProps, 'focusZoneSettings'> &
  Pick<FieldConfig, 'validate'>;

const OverlayTokenField = ({
  label,
  labelConfig,
  value: propValue,
  onChange: propOnChange,
  placeholder,
  disabled: propDisabled,
  name = '',
  required,
  trailingAction,
  visibleTokenCount = 2,
  preventTokenWrapping = false,
  size = 'medium',
  renderHeader,
  renderTokenText = (id) => id,
  onOverlayOpen,
  onOverlayClose,
  children,
  focusZoneSettings,
  caption,
  validate,
}: Props) => {
  const inputId = `overlay_token_field_for_${label}`;

  const [{ value: baseValue = [] }, { error }, { setValue, setError }] = useField<string[]>({
    name,
    multiple: true,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (value ?? []).length === 0) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });

  const { isSubmitting } = useFormikContext();
  const value = !isNullable(propValue) ? propValue : baseValue;
  const handleChange = (selected: string) => {
    setError(undefined);

    propOnChange
      ? propOnChange(selected)
      : setValue(value.includes(selected) ? value.filter((v) => v !== selected) : [...value, selected]);
  };
  const handleClick = (e: ReactMouseEvent<HTMLDivElement>, selected: string) => handleChange(selected);
  const handleKeyDown = (e: ReactKeyboardEvent<HTMLDivElement>, selected: string) =>
    ['Enter'].includes(e.code) ? handleChange(selected) : null;
  const handleSelect = (e: ReactMouseEvent<HTMLDivElement> | ReactKeyboardEvent<HTMLDivElement>, selected: string) =>
    'code' in e ? handleKeyDown(e, selected) : handleClick(e, selected);
  const handleTokenRemove: TextInputWithTokensProps['onTokenRemove'] = (tokenId) => {
    handleChange(tokenId as string);
  };
  const handleInitialize = () => {
    setValue([]);
  };

  const anchorRef = useRef<HTMLInputElement>(null);
  const anchorWidth = anchorRef.current?.getBoundingClientRect().width;

  const [isAnchoredOverlayOpen, setIsAnchoredOverlayOpen] = useState<boolean>(false);
  const handleAnchoredOverlayOpen = () => {
    onOverlayOpen?.();

    setIsAnchoredOverlayOpen(true);
  };
  const handleAnchoredOverlayClose = () => {
    onOverlayClose?.();

    setIsAnchoredOverlayOpen(false);
  };

  const disabled = propDisabled || isSubmitting;

  return (
    <FormControl required={required} disabled={disabled}>
      <FormControl.Label {...labelConfig}>{label}</FormControl.Label>
      <AnchoredOverlay
        width={'auto'}
        height={'auto'}
        overlayProps={{
          maxHeight: 'small',
          sx: { width: anchorWidth, maxWidth: anchorWidth, display: 'flex' },
        }}
        align={'center'}
        anchorRef={anchorRef}
        open={isAnchoredOverlayOpen}
        {...(disabled ? {} : { onOpen: handleAnchoredOverlayOpen })}
        onClose={handleAnchoredOverlayClose}
        focusZoneSettings={focusZoneSettings}
        renderAnchor={(anchorProps) => (
          <View sx={{ width: '100%' }} {...anchorProps}>
            <TextInputWithTokens
              tokens={value.map((v) => ({
                id: v,
                text: (
                  <Truncate title={renderTokenText(v)} maxWidth={300}>
                    {renderTokenText(v)}
                  </Truncate>
                ),
              }))}
              onTokenRemove={disabled ? () => {} : handleTokenRemove}
              id={inputId}
              size={size}
              block
              placeholder={value.length > 0 ? '' : placeholder}
              disabled={disabled}
              autoComplete={'off'}
              visibleTokenCount={visibleTokenCount}
              preventTokenWrapping={preventTokenWrapping}
              trailingVisual={ChevronDownIcon}
              trailingAction={trailingAction}
              sx={{ overflow: 'clip' }}
              {...(size === 'small' || size === 'medium' ? { maxHeight: 32 } : {})}
              {...(error ? { validationStatus: 'error' } : {})}
            />
          </View>
        )}
      >
        <View sx={{ padding: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
          <View>
            {renderHeader?.()}
            <View sx={{ padding: 2 }}>
              <Grid sx={{ alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <Text sx={{ fontSize: 0, color: 'fg.muted' }}>{value.length}개 선택</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleInitialize} size={'small'}>
                    초기화
                  </Button>
                </Grid.Unit>
              </Grid>
            </View>
            <HorizontalDivider />
          </View>
          <View sx={{ overflow: 'auto' }}>{children({ handleSelect })}</View>
        </View>
      </AnchoredOverlay>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default OverlayTokenField;
export type { Props as OverlayTokenFieldProps };
