import { ComponentType, ReactNode, Ref, Suspense, forwardRef } from 'react';

function withSuspense<P extends object>(Component: ComponentType<P>, FallbackComponent?: ComponentType<P> | ReactNode) {
  return forwardRef((props: P, ref: Ref<HTMLElement>) => (
    <Suspense
      {...(FallbackComponent
        ? {
            fallback:
              typeof FallbackComponent === 'function' ? <FallbackComponent {...props} ref={ref} /> : FallbackComponent,
          }
        : {})}
    >
      <Component {...props} ref={ref} />
    </Suspense>
  ));
}

export default withSuspense;
