import { graphql, useFragment } from 'react-relay';

import {
  SchoolExamProblemConnectionDataTable_schoolExamProblemConnection$data,
  SchoolExamProblemConnectionDataTable_schoolExamProblemConnection$key,
} from '../../../relay/__generated__/SchoolExamProblemConnectionDataTable_schoolExamProblemConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import UserAvatarText from '../../user/UserAvatarText';
import SchoolExamProblemStatusLabel from '../SchoolExamProblemStatusLabel';

const SchoolExamProblemConnectionDataTable_schoolExamProblemConnection = graphql`
  fragment SchoolExamProblemConnectionDataTable_schoolExamProblemConnection on SchoolExamProblemConnection {
    edges {
      node {
        id
        sequence
        ...SchoolExamProblemStatusLabel_schoolExamProblem
        title
        problemSubject
        modifiedBy {
          id
          ...UserAvatarText_user
        }
        created
      }
    }
  }
`;

type Props = {
  schoolExamProblemConnection: SchoolExamProblemConnectionDataTable_schoolExamProblemConnection$key;
} & Omit<
  DataTableProps<SchoolExamProblemConnectionDataTable_schoolExamProblemConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const SchoolExamProblemConnectionDataTable = ({ schoolExamProblemConnection, ...props }: Props) => {
  const { edges } = useFragment(
    SchoolExamProblemConnectionDataTable_schoolExamProblemConnection,
    schoolExamProblemConnection,
  );

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'SchoolExamProblemStatusLabel_schoolExamProblem',
          title: '상태',
          renderValue: (schoolExamProblem) => <SchoolExamProblemStatusLabel schoolExamProblem={schoolExamProblem} />,
          width: 80,
        },
        {
          field: 'sequence',
          title: 'ID',
          renderValue: ({ sequence }) => sequence,
          width: 80,
        },
        {
          field: 'title',
          title: '제목',
          renderValue: ({ title }) => title,
          width: 564,
        },
        {
          field: 'problemSubject',
          title: '과목',
          renderValue: ({ problemSubject }) => (
            <EnumPair typename="SchoolExamProblemSubjectEnum">{problemSubject}</EnumPair>
          ),
          width: 120,
        },
        {
          field: 'modifiedBy',
          title: '검수자',
          renderValue: ({ modifiedBy }) => (modifiedBy ? <UserAvatarText user={modifiedBy} /> : '-'),
          width: 92,
        },
        {
          field: 'created',
          title: '제작일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default SchoolExamProblemConnectionDataTable;
