/**
 * @generated SignedSource<<a7f39e8e1073cfd15f6b5927b36cbad8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GroupInviteInput = {
  groupId: string;
  userIds: ReadonlyArray<string>;
};
export type GroupInviteDialog_groupInviteMutation$variables = {
  input: GroupInviteInput;
};
export type GroupInviteDialog_groupInviteMutation$data = {
  readonly groupInvite: {
    readonly id: string;
    readonly users: ReadonlyArray<{
      readonly group: {
        readonly id: string;
      } | null;
      readonly id: string;
    }>;
  };
};
export type GroupInviteDialog_groupInviteMutation = {
  response: GroupInviteDialog_groupInviteMutation$data;
  variables: GroupInviteDialog_groupInviteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Group",
    "kind": "LinkedField",
    "name": "groupInvite",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "users",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Group",
            "kind": "LinkedField",
            "name": "group",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupInviteDialog_groupInviteMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GroupInviteDialog_groupInviteMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "25634d218dd2a0143f53d58b831c5ffb",
    "id": null,
    "metadata": {},
    "name": "GroupInviteDialog_groupInviteMutation",
    "operationKind": "mutation",
    "text": "mutation GroupInviteDialog_groupInviteMutation(\n  $input: GroupInviteInput!\n) {\n  groupInvite(input: $input) {\n    id\n    users {\n      id\n      group {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f8460233bdde8add300e894a103e9f01";

export default node;
