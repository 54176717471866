import { CheckIcon } from '@primer/octicons-react';
import { GraphQLError } from 'graphql';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { ScrapSourceActionsMutationButtonStack_ScrapSource$key } from '../../../relay/__generated__/ScrapSourceActionsMutationButtonStack_ScrapSource.graphql';
import { ScrapSourceActionsMutationButtonStack_scrapSourceSubmitCancelMutation } from '../../../relay/__generated__/ScrapSourceActionsMutationButtonStack_scrapSourceSubmitCancelMutation.graphql';
import { ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation } from '../../../relay/__generated__/ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation.graphql';
import { parseGraphQLError } from '../../../utils/error';
import DialogButton from '../../core/DialogButton';
import MutationConfirmButton from '../../core/MutationConfirmButton';
import Stack from '../../core/Stack';
import ScrapCreateDialog from '../../scrap/ScrapCreateDialog';

const ScrapSourceActionsMutationButtonStack_ScrapSource = graphql`
  fragment ScrapSourceActionsMutationButtonStack_ScrapSource on ScrapSource {
    id
    actions
    ...ScrapCreateDialog_scrapSource
  }
`;

type Props = {
  scrapSource: ScrapSourceActionsMutationButtonStack_ScrapSource$key;
};
const ScrapSourceActionsMutationButtonStack = ({ scrapSource }: Props) => {
  const { toast } = useToast();
  const data = useFragment(ScrapSourceActionsMutationButtonStack_ScrapSource, scrapSource);

  const { id, actions } = data;

  return (
    <Stack gapX={2}>
      {actions.includes('scrap_source_scrap_create') ? (
        <Stack.Item>
          <DialogButton
            variant={'outline'}
            renderDialog={({ isOpen, closeDialog }) => (
              <ScrapCreateDialog scrapSource={data} isOpen={isOpen} onDismiss={closeDialog} full />
            )}
            size={'large'}
          >
            스크랩하기
          </DialogButton>
        </Stack.Item>
      ) : null}
      {actions.includes('scrap_source_submit') ? (
        <Stack.Item>
          <MutationConfirmButton<ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation>
            mutation={graphql`
              mutation ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation(
                $input: ScrapSourceSubmitInput!
              ) {
                scrapSourceSubmit(input: $input) {
                  id
                  ...ScrapSourceStatusLabel_scrapSource
                  ...ScrapSourceActionsMutationButtonStack_ScrapSource
                  ...ScrapSourceScrapPaginator_scrapSource
                }
              }
            `}
            input={{ scrapSource: { id } }}
            message={'제출한 스크랩은 수정할 수 없어요. 제출할까요?'}
            leadingIcon={CheckIcon}
            variant={'primary'}
            config={{
              onCompleted: () => {
                toast('제출이 완료됐어요!', 'success');
              },
              onError: () => {
                toast('스크랩을 먼저 완료해주세요', 'error');
              },
            }}
            size={'large'}
          >
            제출하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('scrap_source_submit_cancel') ? (
        <Stack.Item>
          <MutationConfirmButton<ScrapSourceActionsMutationButtonStack_scrapSourceSubmitCancelMutation>
            mutation={graphql`
              mutation ScrapSourceActionsMutationButtonStack_scrapSourceSubmitCancelMutation(
                $input: ScrapSourceSubmitCancelInput!
              ) {
                scrapSourceSubmitCancel(input: $input) {
                  id
                  ...ScrapSourceStatusLabel_scrapSource
                  ...ScrapSourceActionsMutationButtonStack_ScrapSource
                  ...ScrapSourceScrapPaginator_scrapSource
                }
              }
            `}
            input={{ scrapSource: { id } }}
            variant={'outline'}
            message={'상태를 진행 중으로 변경할까요?'}
            config={{
              onCompleted: () => {
                toast('상태 변경이 완료됐어요!', 'success');
              },
              onError: (error) => {
                toast(parseGraphQLError(error as GraphQLError)?.[0].message || '오류가 발생했어요', 'error');
              },
            }}
            size={'large'}
          >
            추가 작업하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export default ScrapSourceActionsMutationButtonStack;
