/**
 * @generated SignedSource<<e7a51de7772498c44f47db47ea292add>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScrapSourceBookCreateInput = {
  bookId: string;
};
export type BookActionsMutationButtonStack_scrapSourceBookCreateMutation$variables = {
  input: ScrapSourceBookCreateInput;
};
export type BookActionsMutationButtonStack_scrapSourceBookCreateMutation$data = {
  readonly scrapSourceBookCreate: {
    readonly id: string;
  };
};
export type BookActionsMutationButtonStack_scrapSourceBookCreateMutation = {
  response: BookActionsMutationButtonStack_scrapSourceBookCreateMutation$data;
  variables: BookActionsMutationButtonStack_scrapSourceBookCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ScrapSource",
    "kind": "LinkedField",
    "name": "scrapSourceBookCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookActionsMutationButtonStack_scrapSourceBookCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookActionsMutationButtonStack_scrapSourceBookCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d9b810c3fabc39d2a6d5c5410b691335",
    "id": null,
    "metadata": {},
    "name": "BookActionsMutationButtonStack_scrapSourceBookCreateMutation",
    "operationKind": "mutation",
    "text": "mutation BookActionsMutationButtonStack_scrapSourceBookCreateMutation(\n  $input: ScrapSourceBookCreateInput!\n) {\n  scrapSourceBookCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c2361b91344307547895224c3dfeb980";

export default node;
