import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  UnitASelectField_unitAsQuery,
  UnitASelectField_unitAsQuery$variables,
} from '../../../relay/__generated__/UnitASelectField_unitAsQuery.graphql';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const unitAsForUnitASelectField = graphql`
  query UnitASelectField_unitAsQuery($filters: UnitAFilter, $order: UnitAOrder) {
    unitAs(filters: $filters, order: $order) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = { variables?: UnitASelectField_unitAsQuery$variables } & Omit<SelectFieldProps, 'options'>;

const UnitASelectField = ({ variables, ...props }: Props) => {
  const [{ unitAs }] = useLazyLoadQuery<UnitASelectField_unitAsQuery>(unitAsForUnitASelectField, { ...variables });

  return <SelectField options={unitAs.edges.map(({ node: { id, title } }) => ({ id, text: title }))} {...props} />;
};

export default UnitASelectField;
