import React, { useRef } from 'react';
import { graphql } from 'react-relay';

import { QuizCreateDialog_quizCreateMutation } from '../../../relay/__generated__/QuizCreateDialog_quizCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import KatexEditorField from '../../core/KatexEditorField';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextareaField from '../../core/TextareaField';
import UnitDSearchChoiceTextField from '../../unitD/UnitDSearchChoiceTextField';

type Props = {
  initialValues?: MutationFormikProps<QuizCreateDialog_quizCreateMutation>['initialValues'];
} & DialogProps &
  Pick<MutationFormikProps<QuizCreateDialog_quizCreateMutation>, 'config' | 'connections' | 'onSubmit'>;

const QuizCreateDialog = ({ config, connections, onSubmit, initialValues, ...props }: Props) => {
  const dialogScrollContainerRef = useRef<HTMLDivElement>(null);
  const nonFieldErrorRef = useRef<HTMLDivElement>(null);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>생성하기</Text>
      </Dialog.Header>
      <MutationFormik<QuizCreateDialog_quizCreateMutation>
        mutation={graphql`
          mutation QuizCreateDialog_quizCreateMutation($connections: [ID!]!, $input: QuizCreateInput!) {
            quizCreate(input: $input) @appendNode(connections: $connections, edgeTypeName: "QuizEdge") {
              id
            }
          }
        `}
        connections={connections}
        initialValues={{
          description: '',
          answer: 'O',
          unitD: { id: '' },
          problem: { text: '', textHtml: '' },
          solution: { text: '', textHtml: '' },
          tip: { text: '', textHtml: '' },
          ...initialValues,
        }}
        config={config}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body ref={dialogScrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextareaField label={'설명'} name={'description'} placeholder={'설명을 입력해주세요.'} />
                <EnumPairRadioGroupField typename={'QuizAnswerTypeEnum'} label={'정답'} name={'answer'} required />
                <UnitDSearchChoiceTextField
                  label={'Unit D'}
                  name={'unitD.id'}
                  placeholder={'Unit D를 선택해주세요.'}
                  variables={{
                    filters: { unitSchoolType_In: ['high_school', 'middle_school'] },
                    order: { order: 'ASC' },
                  }}
                  required
                />
                <KatexEditorField label={'문제'} name={'problem'} required />
                <KatexEditorField label={'해설'} name={'solution'} required />
                <KatexEditorField label={'개념 TIP'} name={'tip'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default QuizCreateDialog;
