/**
 * @generated SignedSource<<d5b42433c7cc96e88b22c2e9f9c2ec86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolExamProblemActionEnum = "school_exam_problem_approve" | "school_exam_problem_reject" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SchoolExamProblemActionsMutationButtonStack_schoolExamProblem$data = {
  readonly actions: ReadonlyArray<SchoolExamProblemActionEnum>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamProblemRejectDialog_schoolExamProblem">;
  readonly " $fragmentType": "SchoolExamProblemActionsMutationButtonStack_schoolExamProblem";
};
export type SchoolExamProblemActionsMutationButtonStack_schoolExamProblem$key = {
  readonly " $data"?: SchoolExamProblemActionsMutationButtonStack_schoolExamProblem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamProblemActionsMutationButtonStack_schoolExamProblem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamProblemActionsMutationButtonStack_schoolExamProblem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SchoolExamProblemRejectDialog_schoolExamProblem"
    }
  ],
  "type": "SchoolExamProblem",
  "abstractKey": null
};

(node as any).hash = "54d12c45dbee2f35d4a9e561718be7f1";

export default node;
