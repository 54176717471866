import styled, { keyframes } from 'styled-components';

type Props = {
  size?: number;
};

const AnimatedLogo = ({ size = 24 }: Props) => (
  <AnimatedSvg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 144.000000 144.000000"
    preserveAspectRatio="xMidYMid meet"
    fill={'transparent'}
    strokeDasharray={10439.419921875}
    strokeDashoffset={10439.419921875}
  >
    <g transform="translate(0.000000,144.000000) scale(0.100000,-0.100000)">
      <path
        d="M670 1367 c-19 -7 -91 -45 -160 -84 -339 -191 -361 -207 -379 -279 -14 -56 -14 -516 0 -567 6 -22 25 -54 42 -73 35 -37 470 -289 518 -299 16 -4 42 -4 58 0 48 10 483 262 518 299 17 19 36 51 42 73 14 51 14 511 0 567 -6 26 -24 57 -42 74 -36 35 -482 292 -506 292 -9 0 -25 2 -36 5 -11 3 -36 -1 -55 -8z m271 -219 c109 -62 198 -115 198 -118 0 -3 -33 -24 -74 -47 l-74 -43 -208 120 c-114 66 -207 123 -206 126 4 14 119 73 142 74 13 0 113 -51 222 -112z m-287 -143 c249 -143 233 -124 147 -174 -39 -23 -78 -41 -86 -39 -18 4 -414 232 -414 238 -1 11 145 88 158 84 7 -2 95 -51 195 -109z m-149 -226 l150 -87 3 -246 2 -245 -192 110 c-106 61 -201 118 -210 126 -16 15 -18 40 -18 255 l0 239 58 -32 c31 -18 125 -72 207 -120z m678 -340 c-10 -10 -105 -67 -211 -128 l-192 -110 2 246 3 245 205 118 205 119 3 -237 c2 -215 1 -238 -15 -253z"
        stroke="#835EEB"
        strokeWidth={20}
      />
    </g>
  </AnimatedSvg>
);

const draw = keyframes`
  0% {
    stroke-dasharray: 10439.419921875;
    stroke-dashoffset: 10439.419921875;
  }
  100% {
    stroke-dasharray: 10439.419921875;
    stroke-dashoffset: 0;
  }
`;

const fill = keyframes`
  0% {
    fill: transparent;
  }
  30% {
    fill: transparent;
  }
  40% {
    fill: #835EEB;
  }
  100% {
    fill: #835EEB;
  }
`;

const AnimatedSvg = styled.svg`
  animation: ${draw} 3s linear alternate infinite, ${fill} 3s linear alternate infinite;
`;

export default AnimatedLogo;
