import { Radio as PrimerRadio, RadioGroup as PrimerRadioGroup } from '@primer/react';
import { FieldConfig, useField } from 'formik';
import React, { ComponentProps } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import ItemList from '../ItemList';
import View from '../View';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof PrimerRadioGroup.Label>;
  caption?: string;
  options: { id: NonNullable<string | number | undefined>; text: string | undefined }[];
  renderOption?: (option: Props['options'][number]) => React.ReactNode;
  renderOptionWrapper?: (children: React.ReactNode, option: Props['options'][number]) => React.ReactNode;
  renderContainer?: (children: React.ReactNode) => React.ReactNode;
  value?: Props['options'][number]['id'];
} & React.ComponentProps<typeof PrimerRadioGroup> &
  Pick<FieldConfig, 'validate'>;

const RadioGroupField = ({
  id,
  label,
  labelConfig,
  caption,
  options,
  name,
  disabled,
  required,
  value: propValue,
  onChange: propOnChange,
  renderContainer = (children) => children,
  renderOption = ({ text }) => text,
  renderOptionWrapper = (children, { id }) => <View key={id}>{children}</View>,
  validate,
  ...props
}: Props) => {
  const [{ value: baseValue }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext<{}>();

  const onChange: React.ComponentProps<typeof PrimerRadioGroup>['onChange'] = (selected) => {
    setValue(selected);
  };

  const value = propValue ? propValue : baseValue;
  const handleChange: React.ComponentProps<typeof PrimerRadioGroup>['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else onChange(...arg);
  };

  return (
    <PrimerRadioGroup
      id={id}
      name={[name, id].join('-')}
      disabled={disabled || isSubmitting}
      onChange={handleChange}
      required={required}
      {...props}
    >
      <PrimerRadioGroup.Label {...labelConfig} sx={{ fontSize: 1, fontWeight: 'bold' }}>
        {label}
      </PrimerRadioGroup.Label>
      {renderContainer(
        <ItemList
          items={options}
          renderItemWrapper={renderOptionWrapper}
          renderItem={(option) => {
            const { id } = option;
            return (
              <FormControl key={id} sx={{ alignItems: 'center' }}>
                <PrimerRadio value={String(id)} checked={value === id} />
                <FormControl.Label sx={{ fontWeight: 'normal' }}>{renderOption(option)}</FormControl.Label>
              </FormControl>
            );
          }}
        />,
      )}
      {error ? (
        <PrimerRadioGroup.Validation variant={'error'}>{error}</PrimerRadioGroup.Validation>
      ) : caption ? (
        <PrimerRadioGroup.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</PrimerRadioGroup.Caption>
      ) : null}
    </PrimerRadioGroup>
  );
};

export default RadioGroupField;
export type { Props as RadioGroupFieldProps };
