/**
 * @generated SignedSource<<f9396462d24343620f3b0e3b7ebb097d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeliveryOrderStatusEnum = "canceled" | "completed" | "waybill_required" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderStatusLabel_deliveryOrder$data = {
  readonly status: DeliveryOrderStatusEnum;
  readonly " $fragmentType": "DeliveryOrderStatusLabel_deliveryOrder";
};
export type DeliveryOrderStatusLabel_deliveryOrder$key = {
  readonly " $data"?: DeliveryOrderStatusLabel_deliveryOrder$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderStatusLabel_deliveryOrder">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryOrderStatusLabel_deliveryOrder",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "DeliveryOrder",
  "abstractKey": null
};

(node as any).hash = "e056b8d2caa81c2499eac9fef451b48a";

export default node;
