import { PersonIcon } from '@primer/octicons-react';
import { Radio, RadioGroup } from '@primer/react';
import { GraphQLError } from 'graphql/index';
import { camelCase } from 'lodash-es';
import React, { useState } from 'react';
import { graphql } from 'react-relay';

import CommentItem from '../../components/comment/CommentItem';
import CommentPaginator from '../../components/comment/CommentPaginator';
import Avatar from '../../components/core/Avatar';
import Card from '../../components/core/Card';
import Checkbox from '../../components/core/Checkbox';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import Flash from '../../components/core/Flash';
import FormControl from '../../components/core/FormControl';
import Head from '../../components/core/Head';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout, SettingGridNavListLayout } from '../../components/core/Layout';
import Link from '../../components/core/Link';
import ScrollContainer from '../../components/core/ScrollContainer';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import UserUpdatePasswordDialog from '../../components/user/UserUpdatePasswordDialog';
import { ColorModeWithAuto, useColorModeContext } from '../../contexts/ColorModeContext';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { setting_commentsQuery } from '../../relay/__generated__/setting_commentsQuery.graphql';
import { setting_meQuery } from '../../relay/__generated__/setting_meQuery.graphql';
import { parseGraphQLError } from '../../utils/error';
import { isNullable } from '../../utils/is';
import { notification } from '../../utils/notification';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import Shortcut from './shortcut';

const meForSetting = graphql`
  query setting_meQuery {
    me {
      id
      email
      name
      picture
      ...UserUpdatePasswordDialog_user
    }
  }
`;

const commentsForSetting = graphql`
  query setting_commentsQuery($filters: CommentFilter, $order: CommentOrder, $first: Int) {
    ...CommentPaginator_query @arguments(filters: $filters, order: $order, first: $first)
  }
`;

const colorModeItems: { text: string; id: ColorModeWithAuto }[] = [
  { id: 'auto', text: 'system setting' },
  {
    id: 'light',
    text: 'light',
  },
  {
    id: 'dark',
    text: 'dark',
  },
];

const Setting: NextPage = () => {
  const [{ me }] = useLazyLoadQuery<setting_meQuery>(meForSetting, {});
  const [commentsReference] = useLazyLoadQuery<setting_commentsQuery>(
    commentsForSetting,
    { filters: { createdById_Exact: me.id }, order: { created: 'DESC' }, first: 5 },
    {},
  );
  const { toast } = useToast();

  const { colorMode, setColorMode } = useColorModeContext();

  const [notiPermission, setNotiPermission] = useState(notification.getPermission());
  const canUpdateNotiPermission = notiPermission === 'default';

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'설정'} />
      <View>
        <Text as={'h1'} fontWeight={'bold'}>
          프로필 설정
        </Text>
        <View sx={{ marginTop: 5 }}>
          <Avatar src={me.picture || ''} size={64} />
          <View sx={{ marginTop: 4 }}>
            <Text fontSize={4} fontWeight={'bold'} color={'fg.default'}>
              {me.name}
            </Text>
          </View>
          <View mt={1}>
            <Text fontSize={1} fontWeight={'medium'} color={'fg.default'}>
              {me.email}
            </Text>
          </View>
          <View mt={2}>
            <DialogButton
              size={'small'}
              renderDialog={({ isOpen, closeDialog }) => (
                <UserUpdatePasswordDialog
                  user={me}
                  isOpen={isOpen}
                  onDismiss={closeDialog}
                  config={{
                    onCompleted: () => {
                      toast('비밀번호가 변경됐어요', 'success');
                      closeDialog();
                    },
                    onError: (error) => {
                      toast(
                        parseGraphQLError(error as GraphQLError)?.[0].message || '비밀번호 변경에 실패했어요',
                        'error',
                      );
                    },
                  }}
                />
              )}
            >
              비밀번호 변경
            </DialogButton>
          </View>
        </View>

        <View sx={{ marginTop: 8 }}>
          <Text fontWeight={'bold'} fontSize={1}>
            색상 테마
          </Text>
          <RadioGroup
            name={'colorMode'}
            onChange={(value) => setColorMode(value as ColorModeWithAuto)}
            aria-labelledby={'color-mode'}
          >
            <Stack gapX={4} gapY={2}>
              <ItemList
                items={colorModeItems}
                renderItem={({ id, text }) => (
                  <FormControl sx={{ alignItems: 'center' }}>
                    <Radio value={id} checked={colorMode === id} />
                    <FormControl.Label sx={{ fontWeight: 'normal' }}>{text}</FormControl.Label>
                  </FormControl>
                )}
                renderItemWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
              />
            </Stack>
          </RadioGroup>
        </View>
        <View sx={{ marginTop: 5 }}>
          <View>
            <Text fontWeight={'bold'} fontSize={1}>
              브라우저 알림
            </Text>
          </View>
          {isNullable(notiPermission) ? (
            <Flash variant={'warning'} sx={{ marginTop: 2 }}>
              <Text fontSize={1}>현재 사용 중인 브라우저는 알림을 지원하지 않습니다.</Text>
            </Flash>
          ) : (
            <>
              <Stack gapX={1}>
                <Stack.Item>
                  <Checkbox
                    onClick={async () => {
                      const permission = await notification.request();
                      if (permission) setNotiPermission(permission);
                    }}
                    checked={notiPermission === 'granted'}
                    disabled={!canUpdateNotiPermission}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Text fontSize={1} color={canUpdateNotiPermission ? 'fg.default' : 'fg.muted'}>
                    창을 켜두고 있을 때 시스템 알림 받기
                  </Text>
                </Stack.Item>
              </Stack>
              {!canUpdateNotiPermission ? (
                <Flash sx={{ marginTop: 2 }} variant={'warning'}>
                  <Text fontSize={1}>사용하는 브라우저의 설정 페이지에서 알림을 수정해주세요</Text>
                  <View>
                    <Text fontSize={0} color={'fg.muted'}>
                      {`크롬: 설정 > 개인정보 및 보안 > 사이트 설정 > 알림 > ${window.location.origin} 추가/삭제`}
                    </Text>
                  </View>
                  <View>
                    <Text fontSize={0} color={'fg.muted'}>
                      {`사파리: 설정 > 웹 사이트 > 알림 > ${window.location.origin} 추가/삭제`}
                    </Text>
                  </View>
                </Flash>
              ) : null}
            </>
          )}
        </View>
        <View sx={{ marginTop: 5, maxWidth: 300 }}>
          <CommentPaginator fragmentReference={commentsReference}>
            {({ comments }, { hasNext, loadMore, isLoadingNext }) => (
              <>
                <Stack gapX={1}>
                  <Stack.Item>
                    <Text fontWeight={'bold'} fontSize={1}>
                      내가 쓴 댓글
                    </Text>
                  </Stack.Item>
                  <Stack.Item>
                    <Text
                      sx={{ fontSize: 1, fontWeight: 'bold' }}
                      color={comments.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                    >
                      {numberWithCommas(comments.totalCount || 0)}
                    </Text>
                  </Stack.Item>
                </Stack>
                <Card sx={{ marginTop: 2, height: '300px', padding: 1 }}>
                  <ScrollContainer
                    onScrollReachEnd={() => {
                      if (hasNext && !isLoadingNext) loadMore(20);
                    }}
                  >
                    <ItemList
                      items={comments.edges.map(({ node }) => node)}
                      renderItem={(node) => <CommentItem comment={node} />}
                      renderItemWrapper={(children, { id, typename, nodeId }, index) => (
                        <View
                          key={id}
                          sx={{
                            'marginTop': index === 0 ? 0 : 1,
                            'borderRadius': 2,
                            'transition': 'background-color 250ms',
                            ':hover': { backgroundColor: 'canvas.subtle' },
                            'padding': 2,
                          }}
                        >
                          <Link href={`/${camelCase(typename)}/${nodeId}`}>{children}</Link>
                        </View>
                      )}
                      emptyState={<EmptyState title={'내가 쓴 댓글이 없어요'} />}
                    />
                    {isLoadingNext ? <Spinner size={'small'} /> : null}
                  </ScrollContainer>
                </Card>
              </>
            )}
          </CommentPaginator>
        </View>
      </View>
    </View>
  );
};

Setting.getLayout = (page) => (
  <HeaderSidebarNavPageLayout>
    <SettingGridNavListLayout>{page}</SettingGridNavListLayout>
  </HeaderSidebarNavPageLayout>
);
Setting.authenticated = true;
Setting.routes = [
  {
    id: 'setting',
    name: '프로필 설정',
    pathname: '/setting',
    icon: PersonIcon,
  },
  ...Shortcut.routes,
];

export default Setting;
