import { CheckIcon } from '@primer/octicons-react';
import { Suspense } from 'react';
import { graphql } from 'react-relay';

import { useMeContext } from '../../../contexts/MeContext';
import { MATOrganizationCreateDialog_organizationCreateMutation } from '../../../relay/__generated__/MATOrganizationCreateDialog_organizationCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

type Props = {} & DialogProps &
  Pick<
    MutationFormikProps<MATOrganizationCreateDialog_organizationCreateMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const MATOrganizationCreateDialog = ({ config, onSubmit, connections, ...props }: Props) => {
  const { id } = useMeContext();
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>조직 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<MATOrganizationCreateDialog_organizationCreateMutation>
        mutation={graphql`
          mutation MATOrganizationCreateDialog_organizationCreateMutation($input: MATOrganizationCreateInput!) {
            organizationCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{
          createdBy: id,
          title: '',
          prefix: '',
          type: 'school',
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <TextField label={'조직 이름'} name={'title'} placeholder={'조직 이름 입력'} required />
                <TextField
                  label={'계정 이름'}
                  name={'prefix'}
                  placeholder={'영문 입력'}
                  caption={'e.g. turing을 입력하면 turing001로 계정이 생성돼요'}
                  required
                />
                <Suspense>
                  <EnumPairRadioGroupField
                    typename={'MATOrganizationTypeEnum'}
                    label={'조직 타입'}
                    name={'type'}
                    required
                    renderOptionWrapper={(children, { id }) => (
                      <Grid.Unit key={id} size={1 / 2}>
                        {children}
                      </Grid.Unit>
                    )}
                    renderContainer={(children) => <Grid gapX={3}>{children}</Grid>}
                  />
                </Suspense>
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default MATOrganizationCreateDialog;
