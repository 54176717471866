/**
 * @generated SignedSource<<8d2f4402f0fbaf3210ce58918501ecf8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderXlsxImportInput = {
  file: Upload;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation$variables = {
  input: DeliveryOrderXlsxImportInput;
};
export type DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation$data = {
  readonly deliveryOrderXlsxImport: ReadonlyArray<{
    readonly delivered: any | null;
    readonly deliveredBy: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderStatusLabel_deliveryOrder">;
  }>;
};
export type DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation = {
  response: DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation$data;
  variables: DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "delivered",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliveryOrder",
        "kind": "LinkedField",
        "name": "deliveryOrderXlsxImport",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "deliveredBy",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeliveryOrderStatusLabel_deliveryOrder"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeliveryOrder",
        "kind": "LinkedField",
        "name": "deliveryOrderXlsxImport",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "deliveredBy",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c56f4eba01a7eae2077ecdfc44571823",
    "id": null,
    "metadata": {},
    "name": "DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation",
    "operationKind": "mutation",
    "text": "mutation DeliveryOrderXlsxImportDialog_deliveryOrderXlsxImportMutation(\n  $input: DeliveryOrderXlsxImportInput!\n) {\n  deliveryOrderXlsxImport(input: $input) {\n    id\n    delivered\n    deliveredBy {\n      id\n      ...UserAvatarText_user\n    }\n    ...DeliveryOrderStatusLabel_deliveryOrder\n  }\n}\n\nfragment DeliveryOrderStatusLabel_deliveryOrder on DeliveryOrder {\n  status\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "42911a10cb06a4482663b2365a25fa2b";

export default node;
