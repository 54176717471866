import { CheckIcon, DotFillIcon, SearchIcon } from '@primer/octicons-react';
import { FormikProps } from 'formik';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { graphql, useFragment, useMutation, UseMutationConfig, useQueryLoader } from 'react-relay';

import { WorkbookTasksAddDialog_taskQuery } from '../../../relay/__generated__/WorkbookTasksAddDialog_taskQuery.graphql';
import { WorkbookTasksAddDialog_tasksQuery } from '../../../relay/__generated__/WorkbookTasksAddDialog_tasksQuery.graphql';
import { WorkbookTasksAddDialog_workbook$key } from '../../../relay/__generated__/WorkbookTasksAddDialog_workbook.graphql';
import { WorkbookTasksAddDialog_workbookTasksAddMutation } from '../../../relay/__generated__/WorkbookTasksAddDialog_workbookTasksAddMutation.graphql';
import { numberWithCommas } from '../../../utils/number';
import Button from '../../core/Button';
import Card from '../../core/Card';
import DescriptionList from '../../core/DescriptionList';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import EnumPair from '../../core/EnumPair';
import ErrorBoundary from '../../core/ErrorBoundary';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import Label from '../../core/Label';
import NumberRangeField from '../../core/NumberRangeField';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import QueryFormik from '../../core/QueryFormik';
import ScrollContainer from '../../core/ScrollContainer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import TaskPaginator from '../../task/TaskPaginator';
import TaskSequenceItem from '../../task/TaskSequenceItem';
import UnitDSelectDialogTokenField from '../../unitD/UnitDSelectDialogTokenField';
import WorkbookTaskConnectionDataTable from '../WorkbookTaskConnectionDataTable';

const WorkbookTasksAddDialog_workbook = graphql`
  fragment WorkbookTasksAddDialog_workbook on Workbook {
    id
    grade
    tasks {
      totalCount
      edges {
        node {
          id
        }
      }
      ...WorkbookTaskConnectionDataTable_taskConnection
    }
  }
`;

const tasksForWorkbookTasksAddDialog = graphql`
  query WorkbookTasksAddDialog_tasksQuery($filters: TaskFilter, $order: TaskOrder, $first: Int, $after: String) {
    ...TaskPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

const taskForWorkbookTasksAddDialog = graphql`
  query WorkbookTasksAddDialog_taskQuery($id: ID!) {
    task(id: $id) {
      id
      sequence
      problem {
        problemType
        problem
        solution
      }
      publishedUnitDs(order: { order: DESC }) {
        id
        unitATitle
        unitBTitle
        unitCTitle
        description
      }
      difficulty
      workbooks {
        totalCount
        edges {
          node {
            id
            title
            grade
          }
        }
      }
    }
  }
`;

type Props = {
  workbook: WorkbookTasksAddDialog_workbook$key;
  config?: Omit<UseMutationConfig<WorkbookTasksAddDialog_workbookTasksAddMutation>, 'variables'>;
} & DialogProps;

const WorkbookTasksAddDialog = ({ isOpen, workbook, config, ...props }: Props) => {
  const { id, tasks } = useFragment(WorkbookTasksAddDialog_workbook, workbook);
  const idNotInFilterValue = tasks.edges.map(({ node }) => node.id);

  const initialIdNotInFilter = useRef<string[]>([]);
  const [currentAddedTaskIds, setCurrentAddedTaskIds] = useState<string[]>([]);
  useEffect(() => {
    if (isOpen) {
      setCurrentAddedTaskIds([]);
      initialIdNotInFilter.current = idNotInFilterValue;
    }
  }, [isOpen]);

  const queryFormikRef = useRef<FormikProps<any>>(null);
  useEffect(() => {
    queryFormikRef.current?.setFieldValue('filters.id_Not_In', idNotInFilterValue);
  }, [JSON.stringify(idNotInFilterValue)]);

  const [mutate, isLoadingMutation] = useMutation<WorkbookTasksAddDialog_workbookTasksAddMutation>(graphql`
    mutation WorkbookTasksAddDialog_workbookTasksAddMutation($input: WorkbookTasksAddInput!) {
      workbookTasksAdd(input: $input) {
        id
        modifiedBy {
          ...UserAvatarText_user
        }
        modified
        ...WorkbookStatusLabel_workbook
        ...WorkbookTasksAddDialog_workbook
      }
    }
  `);

  const [taskQueryReference, loadTaskQuery] =
    useQueryLoader<WorkbookTasksAddDialog_taskQuery>(taskForWorkbookTasksAddDialog);

  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>문제 추가하기</Text>
      </Dialog.Header>
      <Dialog.Body sx={{ padding: 0 }}>
        <Grid sx={{ height: '100%' }}>
          <Grid.Unit
            size={1 / 5}
            sx={{
              height: '100%',
              backgroundColor: 'canvas.inset',
              paddingX: 3,
              paddingY: 5,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <QueryFormik<WorkbookTasksAddDialog_tasksQuery>
              innerRef={queryFormikRef}
              query={tasksForWorkbookTasksAddDialog}
              staticVariables={{ first: 40 }}
              initialValues={{
                filters: {
                  status_Exact: 'published',
                  search: '',
                  lastUnitDId_In: [],
                  difficulty_Gte: undefined,
                  difficulty_Lte: undefined,
                  id_Not_In: initialIdNotInFilter.current,
                  problemType_In: ['exam'],
                },
              }}
              onSubmit={() => setCurrentAddedTaskIds([])}
            >
              {({ values: { filters }, setFieldValue, submitForm: _submitForm }, queryReference) => {
                const submitForm = () => {
                  setTimeout(() => _submitForm(), 0);
                };
                return (
                  <>
                    <QueryFormik.FilterSearchTextField
                      label={'ID'}
                      name={'search'}
                      typename={'TaskFilter'}
                      trailingVisual={SearchIcon}
                      sx={{ width: '100%' }}
                      debounce
                      onChange={(event) => {
                        setFieldValue('filters.search', event.target.value);
                        submitForm();
                      }}
                    />
                    <View sx={{ marginTop: 2 }}>
                      <UnitDSelectDialogTokenField
                        label={'Unit D'}
                        name={'filters.lastUnitDId_In'}
                        placeholder={'Unit A > B > C > D 선택'}
                        onChange={(id) => {
                          setFieldValue(
                            'filters.lastUnitDId_In',
                            filters?.lastUnitDId_In?.includes(id)
                              ? filters.lastUnitDId_In.filter((i) => i !== id)
                              : [...(filters?.lastUnitDId_In || []), id],
                          );
                          submitForm();
                        }}
                        variables={{ filters: { unitSchoolType_Exact: 'high_school' } }}
                      />
                    </View>
                    <View sx={{ marginTop: 2 }}>
                      <NumberRangeField
                        label={'Rating'}
                        placeholder={['0', '0']}
                        debounce
                        onChange={({ min, max }) => {
                          setFieldValue('filters.difficulty_Gte', min);
                          setFieldValue('filters.difficulty_Lte', max);
                          submitForm();
                        }}
                      />
                    </View>
                    <ErrorBoundary key={queryReference?.fetchKey}>
                      <QueryFormik.PreloadedQueryRenderer<WorkbookTasksAddDialog_tasksQuery>>
                        {(queryReference) => (
                          <TaskPaginator fragmentReference={queryReference}>
                            {({ tasks }, { hasNext, isLoadingNext, loadMore }) => (
                              <>
                                <View sx={{ marginTop: 6 }}>
                                  <Stack gapX={1}>
                                    <Stack.Item>
                                      <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>검색 결과</Text>
                                    </Stack.Item>
                                    <Stack.Item>
                                      <Text sx={{ fontSize: 2, color: 'fg.subtle' }}>
                                        {(tasks.totalCount || 0) - currentAddedTaskIds.length}
                                      </Text>
                                    </Stack.Item>
                                  </Stack>
                                </View>
                                <View sx={{ marginTop: 3, overflowY: 'hidden', flex: 1 }}>
                                  <ScrollContainer
                                    height={'100%'}
                                    onScrollReachEnd={() => {
                                      if (hasNext && !isLoadingNext) loadMore(20);
                                    }}
                                  >
                                    <ItemList
                                      items={tasks.edges.filter(({ node }) => !currentAddedTaskIds.includes(node.id))}
                                      renderItem={({ node }) => <TaskSequenceItem task={node} />}
                                      renderItemWrapper={(children, { node }) => (
                                        <View
                                          key={node.id}
                                          sx={{
                                            'paddingY': 2,
                                            'paddingX': 2,
                                            'borderRadius': 2,
                                            'cursor': 'pointer',
                                            ...(taskQueryReference?.variables.id === node.id
                                              ? {
                                                  backgroundColor: 'accent.subtle',
                                                  color: 'accent.fg',
                                                  fontWeight: 'bold',
                                                }
                                              : {}),
                                            ':hover': { backgroundColor: 'accent.subtle' },
                                          }}
                                          onClick={() => {
                                            loadTaskQuery({ id: node.id });
                                          }}
                                        >
                                          <Grid gapX={1} sx={{ alignItems: 'center' }} wrap={false}>
                                            <Grid.Unit size={'min'}>
                                              <DotFillIcon size={12} verticalAlign={'middle'} />
                                            </Grid.Unit>
                                            <Grid.Unit size={'max'}>{children}</Grid.Unit>
                                          </Grid>
                                        </View>
                                      )}
                                      emptyState={
                                        <Card sx={{ paddingY: 5, paddingX: 3 }}>
                                          <EmptyState title={'검색 결과가 없어요'} />
                                        </Card>
                                      }
                                    />
                                    <View>{isLoadingNext ? <Spinner size={'small'} /> : null}</View>
                                  </ScrollContainer>
                                </View>
                              </>
                            )}
                          </TaskPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </ErrorBoundary>
                  </>
                );
              }}
            </QueryFormik>
          </Grid.Unit>
          <Grid.Unit size={4 / 5} sx={{ padding: 5, overflowY: 'auto', height: '100%' }}>
            <ErrorBoundary key={taskQueryReference?.fetchKey}>
              <Suspense
                fallback={
                  <View sx={{ padding: 5 }}>
                    <Spinner />
                  </View>
                }
              >
                {taskQueryReference ? (
                  <PreloadedQueryRenderer query={taskForWorkbookTasksAddDialog} queryReference={taskQueryReference}>
                    {({ task }) => {
                      const isAddedTask = !!tasks?.edges.find(({ node }) => node.id === task.id);
                      return (
                        <>
                          <Text fontSize={2} fontWeight={'bold'}>
                            {task.sequence}
                          </Text>
                          <View sx={{ marginTop: 4 }}>
                            <Grid gapX={2} gapY={4}>
                              <Grid.Unit size={1 / 2}>
                                <Text fontSize={1} color={'fg.subtle'}>
                                  문제
                                </Text>
                                <Card sx={{ overflow: 'auto', height: '30vh', marginTop: 1 }}>
                                  <Katex>{task.problem?.problem || ''}</Katex>
                                </Card>
                              </Grid.Unit>
                              <Grid.Unit size={1 / 2}>
                                <Text fontSize={1} color={'fg.subtle'}>
                                  해설
                                </Text>
                                <Card sx={{ overflow: 'auto', height: '30vh', marginTop: 1 }}>
                                  <Katex>{task.problem?.solution || ''}</Katex>
                                </Card>
                              </Grid.Unit>
                              <Grid.Unit size={1}>
                                <Card sx={{ padding: 3 }}>
                                  <DescriptionList
                                    item={task}
                                    itemDescriptions={{
                                      unitD: {
                                        title: 'Unit D',
                                        renderValue: ({ publishedUnitDs }) =>
                                          publishedUnitDs.length > 0
                                            ? publishedUnitDs.map(
                                                ({ id, unitATitle, unitBTitle, unitCTitle, description }, index) => (
                                                  <View key={id}>
                                                    {[unitATitle, unitBTitle, unitCTitle, description].join(' > ')}
                                                    {index === 0 ? (
                                                      <Label sx={{ marginLeft: 1 }}>Last Unit D</Label>
                                                    ) : null}
                                                  </View>
                                                ),
                                              )
                                            : undefined,
                                      },
                                      rating: {
                                        title: 'Rating',
                                        renderValue: ({ difficulty }) => <Text fontSize={1}>{difficulty}</Text>,
                                      },
                                      problemType: {
                                        title: '문제 유형',
                                        renderValue: ({ problem }) =>
                                          problem?.problemType ? (
                                            <Text fontSize={1}>
                                              <EnumPair typename={'TaskProblemTypeEnum'}>
                                                {problem.problemType}
                                              </EnumPair>
                                            </Text>
                                          ) : undefined,
                                      },
                                    }}
                                    picks={['problemType', 'unitD', 'rating']}
                                    titleUnitSize={1 / 7}
                                    descriptionUnitSize={6 / 7}
                                  />
                                </Card>
                              </Grid.Unit>
                            </Grid>
                          </View>
                          <View sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
                            <Button
                              variant={'primary'}
                              size={'large'}
                              disabled={isLoadingMutation || isAddedTask}
                              leadingIcon={CheckIcon}
                              onClick={() => {
                                mutate({
                                  ...config,
                                  onCompleted: (...args) => {
                                    setCurrentAddedTaskIds((ids) => [...ids, task.id]);
                                    loadTaskQuery({ id: task.id }, { fetchPolicy: 'store-and-network' });
                                    config?.onCompleted?.(...args);
                                  },
                                  variables: { input: { id, taskIds: [task.id] } },
                                });
                              }}
                            >
                              추가하기
                            </Button>
                          </View>
                        </>
                      );
                    }}
                  </PreloadedQueryRenderer>
                ) : (
                  <View sx={{ padding: 8 }}>
                    <EmptyState
                      title={'선택된 문제가 없습니다'}
                      description={'리스트 또는 표에서 문제를 선택해주세요'}
                    />
                  </View>
                )}
              </Suspense>
            </ErrorBoundary>
            <View sx={{ marginTop: 5 }}>
              <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>문제</Text>
            </View>
            <View sx={{ marginTop: 3 }}>
              <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                {`총 ${numberWithCommas(tasks.totalCount || 0)} 문제`}
              </Text>
            </View>
            <View sx={{ marginTop: 2, overflowX: 'auto' }}>
              <WorkbookTaskConnectionDataTable
                onRowClick={(row) => loadTaskQuery({ id: row.id })}
                taskConnection={tasks}
                emptyState={
                  <View sx={{ paddingY: 3 }}>
                    <EmptyState title={'추가된 문제가 없어요'} />
                  </View>
                }
              />
            </View>
          </Grid.Unit>
        </Grid>
      </Dialog.Body>
    </Dialog>
  );
};

export default WorkbookTasksAddDialog;
