/**
 * @generated SignedSource<<2c8960dc3f4b2ec53cccca28a01539a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useFilterSearchPairsQuery_filterSearchPairsQuery$variables = {
  typename: string;
};
export type useFilterSearchPairsQuery_filterSearchPairsQuery$data = {
  readonly filterSearchPairs: ReadonlyArray<{
    readonly key: string;
    readonly value: string;
  }>;
};
export type useFilterSearchPairsQuery_filterSearchPairsQuery = {
  response: useFilterSearchPairsQuery_filterSearchPairsQuery$data;
  variables: useFilterSearchPairsQuery_filterSearchPairsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "typename"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "typename",
        "variableName": "typename"
      }
    ],
    "concreteType": "Pair",
    "kind": "LinkedField",
    "name": "filterSearchPairs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useFilterSearchPairsQuery_filterSearchPairsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useFilterSearchPairsQuery_filterSearchPairsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6ef84c43c250fdffd8df5f9fea091994",
    "id": null,
    "metadata": {},
    "name": "useFilterSearchPairsQuery_filterSearchPairsQuery",
    "operationKind": "query",
    "text": "query useFilterSearchPairsQuery_filterSearchPairsQuery(\n  $typename: String!\n) {\n  filterSearchPairs(typename: $typename) {\n    key\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "9b0c97d32a111b98cf6e6996911ef909";

export default node;
