import { graphql } from 'react-relay';

import { MATOrganizationWindowPaginator_query$key } from '../../../relay/__generated__/MATOrganizationWindowPaginator_query.graphql';
import FragmentWindowPaginator, { FragmentWindowPaginatorProps } from '../../core/FragmentWindowPaginator';
import Pagination from '../../page/Pagination';

const MATOrganizationWindowPaginator_query = graphql`
  fragment MATOrganizationWindowPaginator_query on Query
  @argumentDefinitions(
    filters: { type: MATOrganizationFilter }
    order: { type: MATOrganizationOrder }
    page: { type: Int, defaultValue: 1 }
    pageSize: { type: Int, defaultValue: 20 }
  )
  @refetchable(queryName: "MATOrganizationWindowPaginatorRefreshQuery") {
    organizations(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      totalCount
      pages {
        ...Pagination_pages
      }
      edges {
        node {
          id
        }
      }
      ...MATOrganizationConnectionDataTable_matOrganizationConnection
    }
  }
`;

type Props = {} & Omit<FragmentWindowPaginatorProps<MATOrganizationWindowPaginator_query$key>, 'fragment'>;

const MATOrganizationWindowPaginator = ({ ...props }: Props) => (
  <FragmentWindowPaginator fragment={MATOrganizationWindowPaginator_query} {...props}>
    {(data, { loadPage }) =>
      props.children(data, {
        loadPage,
        renderPagination: () => <Pagination pages={data.organizations.pages} loadPage={loadPage} />,
      })
    }
  </FragmentWindowPaginator>
);

export default MATOrganizationWindowPaginator;
