/**
 * @generated SignedSource<<1456b3c4f15f99591bc852b03f2ebd07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type QuizStatusEnum = "error_report" | "published" | "review" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuizStatusLabel_quiz$data = {
  readonly status: QuizStatusEnum;
  readonly " $fragmentType": "QuizStatusLabel_quiz";
};
export type QuizStatusLabel_quiz$key = {
  readonly " $data"?: QuizStatusLabel_quiz$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizStatusLabel_quiz">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizStatusLabel_quiz",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Quiz",
  "abstractKey": null
};

(node as any).hash = "1d7fed0a0f1901fcd0392e538c86458c";

export default node;
