import { CheckIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import { TypingAutomationHistoryDialog_typingAutomationHistory$key } from '../../../relay/__generated__/TypingAutomationHistoryDialog_typingAutomationHistory.graphql';
import Card from '../../core/Card';
import DateText from '../../core/DateText';
import Dialog, { DialogProps } from '../../core/Dialog';
import DialogButton from '../../core/DialogButton';
import Editor from '../../core/Editor';
import Grid from '../../core/Grid';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import KatexEditor from '../../core/KatexEditor';
import Text from '../../core/Text';
import View from '../../core/View';
import TaskTypingAutomationHistoryCreateDialog from '../../task/TaskTypingAutomationHistoryCreateDialog';

const TypingAutomationHistoryDialog_typingAutomationHistory = graphql`
  fragment TypingAutomationHistoryDialog_typingAutomationHistory on TypingAutomationHistory {
    script {
      id
      title
      description
    }
    created
    problem
    solution
    task {
      id
      scrap {
        id
        solutions {
          id
          objectUrl
        }
        problems {
          id
          objectUrl
        }
      }
      ...TaskTypingAutomationHistoryCreateDialog_task
    }
  }
`;

type Props = {
  typingAutomationHistory: TypingAutomationHistoryDialog_typingAutomationHistory$key;
} & DialogProps;

const TypingAutomationHistoryDialog = ({ typingAutomationHistory, ...props }: Props) => {
  const { script, created, task, problem, solution } = useFragment(
    TypingAutomationHistoryDialog_typingAutomationHistory,
    typingAutomationHistory,
  );

  return (
    <Dialog full {...props}>
      <Dialog.Header>
        <Grid sx={{ paddingRight: 4 }}>
          <Grid.Unit size={'max'}>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{script.title}</Text>
            <View mt={1}>
              <DateText color={'fg.subtle'}>{created}</DateText>
            </View>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <DialogButton
              renderDialog={({ isOpen, closeDialog }) => (
                <TaskTypingAutomationHistoryCreateDialog
                  task={task}
                  isOpen={isOpen}
                  onDismiss={() => {
                    closeDialog();
                    props.onDismiss?.();
                  }}
                  initialValues={{ description: script.description || '' }}
                />
              )}
              leadingIcon={CheckIcon}
              variant={'primary'}
            >
              새로 만들기
            </DialogButton>
          </Grid.Unit>
        </Grid>
      </Dialog.Header>
      <View height={'100%'} padding={5} overflowY={'hidden'}>
        <Grid wrap={false} sx={{ height: 'inherit' }} gapX={4}>
          <Grid.Unit size={3 / 4}>
            <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 2 }}>
              <View height={'50%'}>
                <Grid gapX={2} sx={{ height: '100%' }}>
                  <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                    <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                      <Text fontSize={1}>스크랩한 문제</Text>
                      <Card flex={1} sx={{ overflow: 'auto' }}>
                        <ItemList
                          items={task.scrap?.problems || []}
                          renderItem={({ objectUrl }) => (
                            <Image key={objectUrl} src={objectUrl} alt={objectUrl} html style={{ display: 'block' }} />
                          )}
                        />
                      </Card>
                    </View>
                  </Grid.Unit>
                  <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                    <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                      <Text fontSize={1}>문제</Text>
                      <Card flex={1} sx={{ overflow: 'auto' }}>
                        <Katex>{problem}</Katex>
                      </Card>
                    </View>
                  </Grid.Unit>
                  <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                    <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                      <Text fontSize={1} sx={{ visibility: 'hidden' }}>
                        문제 텍스트
                      </Text>
                      <Card flex={1}>
                        <KatexEditor height={'100%'} type={'scroll'} readOnly value={problem} />
                      </Card>
                    </View>
                  </Grid.Unit>
                </Grid>
              </View>
              <View height={'50%'}>
                <Grid gapX={2} sx={{ height: '100%' }}>
                  <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                    <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                      <Text fontSize={1}>스크랩한 해설</Text>
                      <Card flex={1} sx={{ overflow: 'auto' }}>
                        <ItemList
                          items={task.scrap?.solutions || []}
                          renderItem={({ objectUrl }) => (
                            <Image key={objectUrl} src={objectUrl} alt={objectUrl} html style={{ display: 'block' }} />
                          )}
                        />
                      </Card>
                    </View>
                  </Grid.Unit>
                  <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                    <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                      <Text fontSize={1}>해설</Text>
                      <Card flex={1} sx={{ overflow: 'auto' }}>
                        <Katex>{solution}</Katex>
                      </Card>
                    </View>
                  </Grid.Unit>
                  <Grid.Unit size={1 / 3} sx={{ height: 'inherit' }}>
                    <View height={'100%'} display={'flex'} flexDirection={'column'} sx={{ rowGap: 1 }}>
                      <Text fontSize={1} sx={{ visibility: 'hidden' }}>
                        해설 텍스트
                      </Text>
                      <Card flex={1} sx={{ overflow: 'auto' }}>
                        <KatexEditor height={'100%'} readOnly value={solution} />
                      </Card>
                    </View>
                  </Grid.Unit>
                </Grid>
              </View>
            </View>
          </Grid.Unit>
          <Grid.Unit size={1 / 4}>
            <View height={'100%'} display={'flex'} flexDirection={'column'} overflow={'hidden'}>
              <Text sx={{ fontSize: 2, flex: 0 }}>프롬프트</Text>
              <View mt={3} flex={1} sx={{ overflow: 'auto' }}>
                <Editor height={'100%'} type={'scroll'} value={script.description || ''} readOnly />
              </View>
            </View>
          </Grid.Unit>
        </Grid>
      </View>
    </Dialog>
  );
};

export default TypingAutomationHistoryDialog;
