import { graphql } from 'react-relay';

import { QuizPaginator_query$key } from '../../../relay/__generated__/QuizPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const QuizPaginator_query = graphql`
  fragment QuizPaginator_query on Query
  @argumentDefinitions(
    filters: { type: QuizFilter }
    order: { type: QuizOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "QuizPaginatorRefreshQuery") {
    quizzes(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "QuizPaginator_query_quizzes") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          ...QuizQuizSetItem_quiz
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<QuizPaginator_query$key>, 'fragment'>;

const QuizPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={QuizPaginator_query} {...props} />;

export default QuizPaginator;
