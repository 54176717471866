type NotificationOptionsWithTitle = NotificationOptions & { title?: string };

const checkAvailableNotification = () => {
  if ('Notification' in window) {
    return true;
  } else {
    console.log('브라우저 알림이 지원되지 않습니다');
    return false;
  }
};

export const show = async (
  options: NotificationOptionsWithTitle,
  additionalOptions?: { onClick?: () => void; onShow?: () => void; onlyBlur?: boolean },
) => {
  if (!checkAvailableNotification()) return;

  if (additionalOptions?.onlyBlur && window.document.hasFocus()) return;

  if (Notification.permission === 'denied') {
    return;
  } else if (Notification.permission === 'granted') {
    return createNotification();
  } else {
    const permission = await request();

    if (permission === 'granted') {
      return createNotification();
    }
  }

  function createNotification() {
    const { title, ..._options } = options;

    const noti = new Notification(title ?? 'TCMS', {
      icon: 'https://cdn.teamturing.com/cms/1705651574_logo.png',
      ..._options,
    });
    noti.onclick = (ev) => {
      ev.preventDefault();
      noti.close();
      additionalOptions?.onClick?.();
    };
    noti.onshow = () => {
      additionalOptions?.onShow?.();
    };
    return noti;
  }
};

const getPermission = () => {
  if (!checkAvailableNotification()) return;

  return Notification.permission;
};

const request = async () => {
  if (!checkAvailableNotification()) return;

  let permission: NotificationPermission;
  try {
    permission = await Notification.requestPermission().then();
  } catch (e) {
    /*
     * Notification.requestPermission가 promise를 반환하지 않는 브라우저를 대응합니다
     */
    permission = await new Promise((resolve) => Notification.requestPermission((permission) => resolve(permission)));
  }

  return permission;
};

export const notification = {
  show,
  getPermission,
  request,
};
