import { graphql, useFragment } from 'react-relay';

import {
  BookScrapConnectionDataTable_bookScrapConnection$data,
  BookScrapConnectionDataTable_bookScrapConnection$key,
} from '../../../relay/__generated__/BookScrapConnectionDataTable_bookScrapConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import Text from '../../core/Text';

const BookScrapConnectionDataTable_bookScrapConnection = graphql`
  fragment BookScrapConnectionDataTable_bookScrapConnection on BookScrapConnection {
    edges {
      node {
        id
        actions
        problemNumber
        problemBookPage
        solutionBookPage
        unitD {
          id
          title
        }
      }
    }
  }
`;

type Props = {
  bookScrapConnection: BookScrapConnectionDataTable_bookScrapConnection$key;
} & Omit<
  DataTableProps<BookScrapConnectionDataTable_bookScrapConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const BookScrapConnectionDataTable = ({ bookScrapConnection, ...props }: Props) => {
  const { edges } = useFragment(BookScrapConnectionDataTable_bookScrapConnection, bookScrapConnection);
  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'index',
          title: '',
          renderValue: (_, index) => <Text fontSize={1}>{index + 1}</Text>,
          width: 30,
        },
        {
          field: 'problem',
          title: '문제',
          renderValue: ({ problemBookPage, problemNumber }) => (
            <Text fontSize={1}>{`${problemBookPage}P의 ${problemNumber}`}</Text>
          ),
          width: 120,
        },
        {
          field: 'solution',
          title: '해설',
          renderValue: ({ solutionBookPage }) => <Text fontSize={1}>{solutionBookPage}P</Text>,
          width: 120,
        },
        {
          field: 'unitD.title',
          title: 'Unit D',
          renderValue: ({ unitD }) => <Text fontSize={1}>{unitD?.title || '?'}</Text>,
          width: 120,
        },
      ]}
      {...props}
    />
  );
};

export default BookScrapConnectionDataTable;
