import { CheckIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import { DeliveryOrderCancelDialog_deliveryOrder$key } from '../../../relay/__generated__/DeliveryOrderCancelDialog_deliveryOrder.graphql';
import { DeliveryOrderCancelDialog_deliveryOrderCancelMutation } from '../../../relay/__generated__/DeliveryOrderCancelDialog_deliveryOrderCancelMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import Text from '../../core/Text';
import TextareaField from '../../core/TextareaField';
import View from '../../core/View';

const DeliveryOrderCancelDialog_deliveryOrder = graphql`
  fragment DeliveryOrderCancelDialog_deliveryOrder on DeliveryOrder {
    id
  }
`;

type Props = { deliveryOrder: DeliveryOrderCancelDialog_deliveryOrder$key } & DialogProps &
  Pick<
    MutationFormikProps<DeliveryOrderCancelDialog_deliveryOrderCancelMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const DeliveryOrderCancelDialog = ({ deliveryOrder, config, onSubmit, connections, ...props }: Props) => {
  const { id } = useFragment(DeliveryOrderCancelDialog_deliveryOrder, deliveryOrder);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>배송 취소하기</Text>
        <View>
          <Text sx={{ fontSize: 1, fontWeight: 'normal', color: 'fg.subtle' }}>
            {"사유를 입력한 뒤, '배송 취소하기' 버튼을 선택해 주세요."}
          </Text>
        </View>
      </Dialog.Header>
      <MutationFormik<DeliveryOrderCancelDialog_deliveryOrderCancelMutation>
        mutation={graphql`
          mutation DeliveryOrderCancelDialog_deliveryOrderCancelMutation($input: DeliveryOrderCancelInput!) {
            deliveryOrderCancel(input: $input) {
              id
              cancelReason
              ...DeliveryOrderStatusLabel_deliveryOrder
              ...DeliveryOrderMutationActionsButtonStack_deliveryOrder
              ...DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder
            }
          }
        `}
        initialValues={{ id, cancelReason: '' }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit, dirty }) => (
          <>
            <Dialog.Body>
              <FormLayout>
                <TextareaField label={'취소 이유'} name={'cancelReason'} placeholder={'사유 입력'} />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button disabled={!dirty} onClick={() => handleSubmit()} variant={'primary'} leadingIcon={CheckIcon}>
                배송 취소하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default DeliveryOrderCancelDialog;
