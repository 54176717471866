/**
 * @generated SignedSource<<592ae7f09895a8098082ce3dd7ad50e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskProblemTypingDifferenceCounterLabel_difference$data = {
  readonly problem?: {
    readonly __typename: "Diff";
  } | null;
  readonly solution?: {
    readonly __typename: "Diff";
  } | null;
  readonly " $fragmentType": "TaskProblemTypingDifferenceCounterLabel_difference";
};
export type TaskProblemTypingDifferenceCounterLabel_difference$key = {
  readonly " $data"?: TaskProblemTypingDifferenceCounterLabel_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskProblemTypingDifferenceCounterLabel_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskProblemTypingDifferenceCounterLabel_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "problem",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "solution",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "TaskProblemTypingDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "d5c0ab474bcb535c90af74dc8444488b";

export default node;
