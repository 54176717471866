import { LinkExternalIcon } from '@primer/octicons-react';
import { formatKatexToHtmlString } from '@teamturing/katex-utils';
import { Space } from '@teamturing/react-kit';
import { GraphQLError } from 'graphql/index';
import { editor } from 'monaco-editor';
import React, { Suspense, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { TaskHintFeedbackSaveDialog_task$key } from '../../../relay/__generated__/TaskHintFeedbackSaveDialog_task.graphql';
import { TaskHintFeedbackSaveDialog_taskHintAiSuggestionCreateMutation } from '../../../relay/__generated__/TaskHintFeedbackSaveDialog_taskHintAiSuggestionCreateMutation.graphql';
import { TaskHintFeedbackSaveDialog_taskHintFeedbackSaveMutation } from '../../../relay/__generated__/TaskHintFeedbackSaveDialog_taskHintFeedbackSaveMutation.graphql';
import { parseGraphQLError } from '../../../utils/error';
import { isNullable } from '../../../utils/is';
import Button from '../../core/Button';
import Card from '../../core/Card';
import CheckboxField from '../../core/CheckboxField';
import DateText from '../../core/DateText';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import EnumPair from '../../core/EnumPair';
import FixedMiniView from '../../core/FixedMiniView';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import KatexEditor, { KatexEditorShortcutButtonsStack } from '../../core/KatexEditor';
import KatexEditorField from '../../core/KatexEditorField';
import Label from '../../core/Label';
import Link from '../../core/Link';
import MutationButton from '../../core/MutationButton';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import ScrollMiniKatex from '../../core/ScrollMiniKatex';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import TabHandler from '../../core/TabHandler';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import Tooltip from '../../core/Tooltip';
import View from '../../core/View';
import UnitDItem from '../../unitD/UnitDItem';
import TaskDescriptionList from '../TaskDescriptionList';
import TaskHintAiSuggestionPaginator from '../TaskHintAiSuggestionPaginator';
import TaskProblemSolveInfoDescriptionList from '../TaskProblemSolveInfoDescriptionList';

const TaskHintFeedbackSaveDialog_task = graphql`
  fragment TaskHintFeedbackSaveDialog_task on Task @argumentDefinitions(filters: { type: UnitDFilter }) {
    id
    sequence
    title
    problem {
      id
      problemType
      problem
      solution
    }
    unit {
      schoolType
    }
    hint {
      id
      text
      isMeaningless
      isSimpleModified
      aiCreationModified

      usedAiSuggestion {
        id
      }
    }
    ...TaskHintAiSuggestionPaginator_query @arguments(first: 1, order: { created: DESC })

    submitFeedback {
      id
      specialAnswer
      special
      general
    }
    ...TaskDescriptionList_task
    publishedUnitDs(filters: $filters, order: { order: DESC }) {
      id
      ...UnitDItem_unitD
    }
    problemSolveInfo {
      ...TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo
      specialAnswers {
        rank
        answer
        percentage
      }
    }
  }
`;

type Props = { task: TaskHintFeedbackSaveDialog_task$key } & DialogProps &
  Pick<MutationFormikProps<TaskHintFeedbackSaveDialog_taskHintFeedbackSaveMutation>, 'config' | 'onSubmit'>;

const HINT_FEEDBACK_GUIDE_LINK =
  'https://www.notion.so/bc5c62d8674541218206c843bd18fd40#b2063110c8e04ce2a84fb84cd15b0f68';

const TaskHintFeedbackSaveDialog = ({ task: taskReference, config, onSubmit, ...props }: Props) => {
  const task = useFragment(TaskHintFeedbackSaveDialog_task, taskReference);
  const { toast } = useToast();

  const { id, sequence, hint, submitFeedback, problem, title, publishedUnitDs, problemSolveInfo, unit } = task;

  const editorInputTabItems = [
    { id: 'all', label: '전체' },
    { id: 'hint', label: '힌트' },
    { id: 'special', label: '다시 풀기 피드백' },
  ];

  const renderingTabItems = [
    { id: 'typing', label: '타이핑' },
    { id: 'rendering', label: '렌더링' },
  ];

  const [activeEditor, setActiveEditor] = useState<editor.IStandaloneCodeEditor | null>(null);

  return (
    <Dialog {...props}>
      <MutationFormik<TaskHintFeedbackSaveDialog_taskHintFeedbackSaveMutation>
        mutation={graphql`
          mutation TaskHintFeedbackSaveDialog_taskHintFeedbackSaveMutation($input: TaskHintFeedbackSaveInput!) {
            taskHintFeedbackSave(input: $input) {
              id
              ...TaskHintFeedbackSaveDialog_task
              hint {
                id
                status
              }
              submitFeedback {
                id
                status
              }

              ...TaskActionsMutationButtonStack_task
            }
          }
        `}
        initialValues={{
          general: submitFeedback?.general
            ? { text: submitFeedback?.general, textHtml: formatKatexToHtmlString(submitFeedback?.general) }
            : null,
          hint: hint?.text ? { text: hint.text, textHtml: formatKatexToHtmlString(hint.text) } : null,
          special: submitFeedback?.special
            ? { text: submitFeedback?.special, textHtml: formatKatexToHtmlString(submitFeedback?.special) }
            : null,
          specialAnswer: submitFeedback?.specialAnswer || null,
          task: id,
          isMeaningless: hint?.isMeaningless ?? false,
          isSimpleModified: hint?.isSimpleModified ?? false,
          aiCreationModified: hint?.aiCreationModified ?? false,
          hintAiSuggestion: hint?.usedAiSuggestion?.id,
        }}
        config={config}
        onSubmit={onSubmit}
        initialStatus={{ showAiRawHint: false }}
      >
        {(
          { handleSubmit, dirty, setFieldValue, values, status, setStatus },
          { scrollContainerRef, nonFieldErrorRef },
        ) => (
          <>
            <Dialog.Header>
              <Grid gapX={4} sx={{ paddingRight: 4, alignItems: 'center' }}>
                <Grid.Unit size={'max'}>
                  <Stack gapX={1}>
                    <Stack.Item>
                      <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>힌트 · 피드백 제작하기</Text>
                    </Stack.Item>
                    {problem ? (
                      <Stack.Item>
                        <Suspense>
                          <Label variant={'accent'}>
                            <EnumPair typename={'TaskProblemTypeEnum'}>{problem.problemType}</EnumPair>
                            {` · ${title}`}
                          </Label>
                        </Suspense>
                      </Stack.Item>
                    ) : null}
                    <Stack.Item>
                      <Label variant={'primary'}>{sequence}</Label>
                    </Stack.Item>
                  </Stack>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Link href={HINT_FEEDBACK_GUIDE_LINK} target={'_blank'}>
                    <Stack gapX={1}>
                      <Stack.Item>
                        <StyledOcticon icon={LinkExternalIcon} size={16} />
                      </Stack.Item>
                      <Stack.Item>
                        <Text fontSize={0}>힌트 · 피드백 작성 가이드</Text>
                      </Stack.Item>
                    </Stack>
                  </Link>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Button variant={'primary'} onClick={() => handleSubmit()} disabled={!dirty}>
                    저장하기
                  </Button>
                </Grid.Unit>
              </Grid>
            </Dialog.Header>
            <Grid wrap={false} sx={{ height: '100%', overflowY: 'hidden' }}>
              <Grid.Unit
                ref={scrollContainerRef}
                size={3 / 4}
                sx={{
                  'padding': 3,
                  'height': '100%',
                  'overflowY': 'auto',
                  '&>*:not(:last-child)': { marginBottom: 5 },
                }}
              >
                <TabHandler initialSelectIndex={0}>
                  {({ selectedIndex, handleSelect }) => (
                    <>
                      <NonFieldError ref={nonFieldErrorRef} />
                      <Grid gapY={2}>
                        <Grid.Unit size={'max'}>
                          <Stack gapX={1}>
                            <ItemList
                              items={editorInputTabItems}
                              renderItem={({ label }, index) => (
                                <Button
                                  onClick={() => handleSelect(index)}
                                  variant={selectedIndex === index ? 'outline' : 'invisible'}
                                >
                                  {label}
                                </Button>
                              )}
                              renderItemWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
                            />
                          </Stack>
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <KatexEditorShortcutButtonsStack editor={activeEditor!} gapX={1} />
                        </Grid.Unit>
                      </Grid>
                      <Grid gapX={2}>
                        <Grid.Unit size={1 / 2}>
                          <TabHandler initialSelectIndex={1}>
                            {({ selectedIndex, handleSelect }) => (
                              <>
                                <Grid sx={{ alignItems: 'center' }}>
                                  <Grid.Unit size={'max'}>
                                    <Text fontSize={1}>문제</Text>
                                  </Grid.Unit>
                                  <Grid.Unit size={'min'}>
                                    <Stack gapX={1}>
                                      <ItemList
                                        items={renderingTabItems}
                                        renderItem={({ label }, index) => (
                                          <Button
                                            onClick={() => handleSelect(index)}
                                            size={'small'}
                                            variant={selectedIndex === index ? 'outline' : 'invisible'}
                                          >
                                            {label}
                                          </Button>
                                        )}
                                        renderItemWrapper={(children, { id }) => (
                                          <Stack.Item key={id}>{children}</Stack.Item>
                                        )}
                                      />
                                    </Stack>
                                  </Grid.Unit>
                                </Grid>
                                {renderingTabItems[selectedIndex].id === 'typing' ? (
                                  <KatexEditor
                                    value={problem?.problem || ''}
                                    readOnly
                                    height={'30vh'}
                                    type={'scroll'}
                                    sx={{ marginTop: 1 }}
                                  />
                                ) : renderingTabItems[selectedIndex].id === 'rendering' ? (
                                  <Card sx={{ height: '30vh', marginTop: 1, overflow: 'auto' }}>
                                    <Katex>{problem?.problem || ''}</Katex>
                                  </Card>
                                ) : null}
                              </>
                            )}
                          </TabHandler>
                        </Grid.Unit>
                        <Grid.Unit size={1 / 2}>
                          <TabHandler initialSelectIndex={1}>
                            {({ selectedIndex, handleSelect }) => (
                              <>
                                <Grid sx={{ alignItems: 'center' }}>
                                  <Grid.Unit size={'max'}>
                                    <Text fontSize={1}>해설</Text>
                                  </Grid.Unit>
                                  <Grid.Unit size={'min'}>
                                    <Stack gapX={1}>
                                      <ItemList
                                        items={renderingTabItems}
                                        renderItem={({ label }, index) => (
                                          <Button
                                            onClick={() => handleSelect(index)}
                                            size={'small'}
                                            variant={selectedIndex === index ? 'outline' : 'invisible'}
                                          >
                                            {label}
                                          </Button>
                                        )}
                                        renderItemWrapper={(children, { id }) => (
                                          <Stack.Item key={id}>{children}</Stack.Item>
                                        )}
                                      />
                                    </Stack>
                                  </Grid.Unit>
                                </Grid>
                                {renderingTabItems[selectedIndex].id === 'typing' ? (
                                  <KatexEditor
                                    value={problem?.solution || ''}
                                    readOnly
                                    height={'30vh'}
                                    type={'scroll'}
                                    sx={{ marginTop: 1 }}
                                  />
                                ) : renderingTabItems[selectedIndex].id === 'rendering' ? (
                                  <Card sx={{ height: '30vh', marginTop: 1, overflow: 'auto' }}>
                                    <Katex>{problem?.solution || ''}</Katex>
                                  </Card>
                                ) : null}
                              </>
                            )}
                          </TabHandler>
                        </Grid.Unit>
                      </Grid>
                      {editorInputTabItems[selectedIndex].id === 'all' ||
                      editorInputTabItems[selectedIndex].id === 'hint' ? (
                        <View>
                          {unit?.schoolType === 'elementary_school' ? (
                            <KatexEditorField
                              onFocus={setActiveEditor}
                              label={'힌트'}
                              name={'hint'}
                              height={'30vh'}
                              caption={
                                '오른쪽 영역보다 길게 제작할 경우, 서비스에서 잘려 보이므로 해당 영역 내로 제작해 주세요.'
                              }
                              type={'scroll'}
                              mini
                              emptyStateText={'입력된 힌트가 없어요'}
                              disabledUploadImage
                            />
                          ) : (
                            <View>
                              <Space mb={2}>
                                <Text fontSize={1}>AI 힌트 추천</Text>
                              </Space>
                              <TaskHintAiSuggestionPaginator fragmentReference={task}>
                                {({ hintAiSuggestions }, { refetch }) => {
                                  const aiHint = hintAiSuggestions?.edges[0]?.node;
                                  return (
                                    <Grid wrap={false} gapX={2} sx={{ justifyContent: 'space-between' }}>
                                      <Grid.Unit size={'max'}>
                                        <Card>
                                          {aiHint ? (
                                            <Grid sx={{ height: '20vh' }}>
                                              {status.showAiRawHint ? (
                                                <Grid.Unit size={1 / 2} sx={{ height: '100%', overflow: 'auto' }}>
                                                  <Katex>{aiHint.rawText ?? ''}</Katex>
                                                </Grid.Unit>
                                              ) : null}
                                              <Grid.Unit
                                                size={status.showAiRawHint ? 1 / 2 : 1}
                                                sx={{ height: '100%', overflow: 'auto' }}
                                              >
                                                <Katex>{aiHint.text}</Katex>
                                              </Grid.Unit>
                                            </Grid>
                                          ) : (
                                            <Space py={4}>
                                              <EmptyState title={'AI 힌트를 제작해주세요'} />
                                            </Space>
                                          )}
                                        </Card>
                                        <Space mt={1}>
                                          <Grid gapX={1} sx={{ alignItems: 'center' }}>
                                            <Grid.Unit size={'max'} sx={{ alignSelf: 'stretch' }}>
                                              {aiHint?.created ? (
                                                <DateText fontSize={0} color={'fg.subtle'} format={'P a hh:mm:ss'}>
                                                  {aiHint.created}
                                                </DateText>
                                              ) : null}
                                            </Grid.Unit>
                                            <Grid.Unit size={'min'}>
                                              <Button
                                                variant={'plain'}
                                                disabled={!aiHint?.rawText}
                                                onClick={() => {
                                                  setStatus({ ...status, showAiRawHint: !status.showAiRawHint });
                                                }}
                                                size={'small'}
                                              >
                                                영어 원문 {status.showAiRawHint ? '숨기기' : '보기'}
                                              </Button>
                                            </Grid.Unit>
                                            <Grid.Unit size={'min'}>
                                              <Button
                                                variant={'outline'}
                                                disabled={!aiHint?.text}
                                                onClick={() => {
                                                  if (aiHint) {
                                                    setFieldValue('hintAiSuggestion', aiHint.id);
                                                    setFieldValue('hint', {
                                                      text: aiHint.text,
                                                      textHtml: formatKatexToHtmlString(aiHint.text),
                                                    });
                                                  }
                                                }}
                                              >
                                                적용하기
                                              </Button>
                                            </Grid.Unit>
                                            <Grid.Unit size={'min'}>
                                              <Tooltip text={'60~90초 정도 소요됩니다'}>
                                                <MutationButton<TaskHintFeedbackSaveDialog_taskHintAiSuggestionCreateMutation>
                                                  mutation={graphql`
                                                    mutation TaskHintFeedbackSaveDialog_taskHintAiSuggestionCreateMutation(
                                                      $input: TaskHintAiSuggestionCreateInput!
                                                    ) {
                                                      taskHintAiSuggestionCreate(input: $input) {
                                                        id
                                                        created
                                                        rawText
                                                        text
                                                      }
                                                    }
                                                  `}
                                                  input={{ task: id }}
                                                  config={{
                                                    onCompleted: () => {
                                                      toast('AI 힌트가 생성됐어요', 'success');
                                                      refetch({}, { fetchPolicy: 'store-and-network' });
                                                    },
                                                    onError: (error) => {
                                                      toast(
                                                        parseGraphQLError(error as GraphQLError)?.[0].message ||
                                                          'AI 힌트 생성에 실패했어요',
                                                        'error',
                                                      );
                                                    },
                                                  }}
                                                >
                                                  생성하기
                                                </MutationButton>
                                              </Tooltip>
                                            </Grid.Unit>
                                          </Grid>
                                        </Space>
                                      </Grid.Unit>
                                      <Grid.Unit size={'min'}>
                                        <Card sx={{ width: 'fit-content' }}>
                                          {values.hint?.text ? (
                                            <ScrollMiniKatex>{values.hint?.text}</ScrollMiniKatex>
                                          ) : (
                                            <FixedMiniView>
                                              <Space py={4}>
                                                <EmptyState title={'입력된 힌트가 없어요'} />
                                              </Space>
                                            </FixedMiniView>
                                          )}
                                        </Card>
                                      </Grid.Unit>
                                    </Grid>
                                  );
                                }}
                              </TaskHintAiSuggestionPaginator>
                              <Stack gapX={3}>
                                <Stack.Item>
                                  <Text fontSize={1}>[AI 생성 힌트 수정 기준]</Text>
                                </Stack.Item>
                                <Stack.Item>
                                  <CheckboxField label={'의미 없음'} name={'isMeaningless'} />
                                </Stack.Item>
                                <Stack.Item>
                                  <CheckboxField label={'단순 오류'} name={'isSimpleModified'} />
                                </Stack.Item>
                                <Stack.Item>
                                  <CheckboxField label={'수학적 오류'} name={'aiCreationModified'} />
                                </Stack.Item>
                              </Stack>
                            </View>
                          )}
                        </View>
                      ) : null}
                      {editorInputTabItems[selectedIndex].id === 'all' ||
                      editorInputTabItems[selectedIndex].id === 'special' ? (
                        <View sx={{ '&>*:not(:first-child)': { marginTop: 3 } }}>
                          <TextField
                            name={'specialAnswer'}
                            label={'특정 오답'}
                            placeholder={'특정 오답 입력'}
                            autoComplete={'off'}
                          />
                          <KatexEditorField
                            onFocus={setActiveEditor}
                            label={'특정 오답 피드백'}
                            name={'special'}
                            height={'30vh'}
                            caption={
                              '오른쪽 영역보다 길게 제작할 경우, 서비스에서 잘려 보이므로 해당 영역 내로 제작해 주세요.'
                            }
                            type={'scroll'}
                            mini
                            emptyStateText={'입력된 피드백이 없어요'}
                            disabledUploadImage
                          />
                          <KatexEditorField
                            onFocus={setActiveEditor}
                            label={'일반 오답 피드백'}
                            name={'general'}
                            height={'30vh'}
                            caption={
                              '오른쪽 영역보다 길게 제작할 경우, 서비스에서 잘려 보이므로 해당 영역 내로 제작해 주세요.'
                            }
                            type={'scroll'}
                            mini
                            emptyStateText={'입력된 피드백이 없어요'}
                            disabledUploadImage
                          />
                        </View>
                      ) : null}
                    </>
                  )}
                </TabHandler>
              </Grid.Unit>
              <Grid.Unit
                size={1 / 4}
                sx={{
                  'padding': 3,
                  'height': '100%',
                  'overflowY': 'auto',
                  'backgroundColor': 'canvas.inset',
                  '&>*:not(:first-child)': { marginTop: 4 },
                }}
              >
                <View>
                  <Text fontSize={2} fontWeight={'bold'}>
                    문제 정보
                  </Text>
                </View>
                <Card sx={{ padding: 3, backgroundColor: 'canvas.inset' }}>
                  <TaskDescriptionList task={task} type={'answer'} titleUnitSize={1 / 2} descriptionUnitSize={1 / 2} />
                </Card>
                <View>
                  <Text sx={{ fontSize: 1 }}>Unit D</Text>
                  <View sx={{ marginTop: 2 }}>
                    <ItemList
                      items={publishedUnitDs}
                      renderItem={(unitD) => <UnitDItem unitD={unitD} />}
                      renderItemWrapper={(children, { id }, index) => (
                        <View
                          key={id}
                          sx={{
                            marginTop: index === 0 ? 0 : 2,
                            borderRadius: 2,
                            backgroundColor: 'neutral.muted',
                            padding: 2,
                          }}
                        >
                          {index === 0 ? (
                            <Label sx={{ marginBottom: 1 }} variant={'default'}>
                              Last Unit D
                            </Label>
                          ) : null}
                          {children}
                        </View>
                      )}
                    />
                  </View>
                </View>
                {problemSolveInfo ? (
                  <>
                    <View>
                      <Text fontSize={1}>풀이 데이터</Text>
                      <Card sx={{ marginTop: 2, padding: 3, backgroundColor: 'canvas.inset' }}>
                        <TaskProblemSolveInfoDescriptionList
                          taskProblemSolveInfo={problemSolveInfo}
                          titleUnitSize={1 / 2}
                          descriptionUnitSize={1 / 2}
                        />
                      </Card>
                    </View>
                    <View>
                      <Text sx={{ fontSize: 1 }}>특정 오답</Text>
                      <Card sx={{ marginTop: 2, padding: 3, backgroundColor: 'canvas.inset' }}>
                        <ItemList
                          items={problemSolveInfo.specialAnswers}
                          renderItem={({ rank, answer, percentage }) => (
                            <Grid wrap={false}>
                              <Grid.Unit size={1 / 2}>
                                <Text sx={{ fontSize: 1, color: 'fg.muted' }}>{`${rank}순위`}</Text>
                              </Grid.Unit>
                              <Grid.Unit size={1 / 2}>
                                <Grid wrap={false}>
                                  <Grid.Unit size={'max'}>
                                    <Text sx={{ fontSize: 1 }}>{answer || '-'}</Text>
                                  </Grid.Unit>
                                  <Grid.Unit size={'min'}>
                                    <Text sx={{ fontSize: 1 }}>{`${!isNullable(percentage) ? percentage : 'N'}%`}</Text>
                                  </Grid.Unit>
                                </Grid>
                              </Grid.Unit>
                            </Grid>
                          )}
                          renderItemWrapper={(children, { rank }, i) => (
                            <View key={rank} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                              {children}
                            </View>
                          )}
                        />
                      </Card>
                    </View>
                  </>
                ) : null}
              </Grid.Unit>
            </Grid>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default TaskHintFeedbackSaveDialog;
