/**
 * @generated SignedSource<<7f92c74d7d5ecd35b76443040e1312e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkbookGradesUpdateDialog_workbook$data = {
  readonly grades: ReadonlyArray<{
    readonly grade: string;
    readonly percentile: number | null;
    readonly rawScore: number | null;
    readonly standardScore: number | null;
  }>;
  readonly id: string;
  readonly " $fragmentType": "WorkbookGradesUpdateDialog_workbook";
};
export type WorkbookGradesUpdateDialog_workbook$key = {
  readonly " $data"?: WorkbookGradesUpdateDialog_workbook$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkbookGradesUpdateDialog_workbook">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkbookGradesUpdateDialog_workbook",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkbookGradeType",
      "kind": "LinkedField",
      "name": "grades",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentile",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rawScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "standardScore",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Workbook",
  "abstractKey": null
};

(node as any).hash = "c2fd3c014b6ad0c713c18d77c3fce2b8";

export default node;
