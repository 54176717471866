/**
 * @generated SignedSource<<90930cec4e6768beca2f11905f65efaa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternalLoginTypeUpdateDialog_internalUser$data = {
  readonly id: string;
  readonly loginId: string;
  readonly " $fragmentType": "InternalLoginTypeUpdateDialog_internalUser";
};
export type InternalLoginTypeUpdateDialog_internalUser$key = {
  readonly " $data"?: InternalLoginTypeUpdateDialog_internalUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternalLoginTypeUpdateDialog_internalUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternalLoginTypeUpdateDialog_internalUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "loginId",
      "storageKey": null
    }
  ],
  "type": "MATInternalUser",
  "abstractKey": null
};

(node as any).hash = "3dcd8981f82c2d30c5067b18d9fd2f7f";

export default node;
