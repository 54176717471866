import { CheckIcon, DownloadIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import { MATOrganizationTeachersXlsxCreateDialog_matOrganization$key } from '../../../relay/__generated__/MATOrganizationTeachersXlsxCreateDialog_matOrganization.graphql';
import { MATOrganizationTeachersXlsxCreateDialog_organizationStudentsXlsxCreateMutation } from '../../../relay/__generated__/MATOrganizationTeachersXlsxCreateDialog_organizationStudentsXlsxCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import LinkButton from '../../core/LinkButton';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import UploadFieldv2 from '../../core/UploadField';
import View from '../../core/View';

const MATOrganizationTeachersXlsxCreateDialog_matOrganization = graphql`
  fragment MATOrganizationTeachersXlsxCreateDialog_matOrganization on MATOrganization {
    id
    teachersBulkCreateTemplateUrl
  }
`;

type Props = {
  matOrganization: MATOrganizationTeachersXlsxCreateDialog_matOrganization$key;
} & DialogProps &
  Pick<
    MutationFormikProps<MATOrganizationTeachersXlsxCreateDialog_organizationStudentsXlsxCreateMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const MATOrganizationTeachersXlsxCreateDialog = ({
  matOrganization,
  config,
  connections,
  onSubmit,
  ...props
}: Props) => {
  const { id, teachersBulkCreateTemplateUrl } = useFragment(
    MATOrganizationTeachersXlsxCreateDialog_matOrganization,
    matOrganization,
  );

  return (
    <Dialog wide {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>선생님 계정 벌크 생성하기</Text>
        <View mt={1}>
          <Text sx={{ fontSize: 1, fontWeight: 'medium', color: 'fg.subtle' }}>
            xlsx 파일을 양식에 맞게 업로드해 주세요
          </Text>
        </View>
      </Dialog.Header>
      <MutationFormik<MATOrganizationTeachersXlsxCreateDialog_organizationStudentsXlsxCreateMutation>
        mutation={graphql`
          mutation MATOrganizationTeachersXlsxCreateDialog_organizationStudentsXlsxCreateMutation(
            $input: OrganizationTeachersXlsxCreateInput!
          ) {
            organizationTeachersXlsxCreate(input: $input)
          }
        `}
        initialValues={{ organization: id }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit }, { nonFieldErrorRef, scrollContainerRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <View>
                  <Text as={'label'} color={'fg.default'} fontSize={1}>
                    양식
                  </Text>
                  <LinkButton
                    href={teachersBulkCreateTemplateUrl}
                    download
                    leadingIcon={DownloadIcon}
                    size={'medium'}
                    variant={'outline'}
                    sx={{ mt: 1 }}
                  >
                    양식 다운로드
                  </LinkButton>
                </View>
                <UploadFieldv2 label={'파일'} name={'file'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default MATOrganizationTeachersXlsxCreateDialog;
