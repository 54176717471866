import { CheckIcon, DownloadIcon, LinkIcon, XIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  TaskDescriptionList_task$data,
  TaskDescriptionList_task$key,
} from '../../../relay/__generated__/TaskDescriptionList_task.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import Label from '../../core/Label';
import Link from '../../core/Link';
import LinkButton from '../../core/LinkButton';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';
import TaskStatusLabel from '../TaskStatusLabel';

const TaskDescriptionList_task = graphql`
  fragment TaskDescriptionList_task on Task {
    id
    title
    problem {
      id
      problemType
      creationType
      answer
      answerType
      answerChoiceCount
    }
    scrap {
      id
      scrapSource {
        id
        title
      }
    }
    ...TaskStatusLabel_task
    assignedTo {
      id
      ...UserAvatarText_user
    }
    assigned

    aiFile {
      id
      objectUrl
    }

    problemGenerator {
      id
    }
    generatedProblem {
      id
      problemGenerator {
        id
      }
    }

    originalTask {
      id
    }

    labelingCount

    firstLabeling {
      id
      difficulty
      unitDs(order: { order: DESC }) {
        id
        unitATitle
        unitBTitle
        unitCTitle
        description
      }
    }
    secondLabeling {
      id
      difficulty
      unitDs(order: { order: DESC }) {
        id
        unitATitle
        unitBTitle
        unitCTitle
        description
      }
    }
    finalLabeling {
      id
      difficulty
      unitDs(order: { order: DESC }) {
        id
        unitATitle
        unitBTitle
        unitCTitle
        description
      }
    }

    unitDs(order: { order: DESC }) {
      id
      unitATitle
      unitBTitle
      unitCTitle
      description
    }
    publishedUnitDs(order: { order: DESC }) {
      id
      unitATitle
      unitBTitle
      unitCTitle
      description
    }

    difficulty
    aiDifficulty

    typingAutomationScript {
      id
      title
    }
  }
`;

type Props = {
  task: TaskDescriptionList_task$key;
  type?:
    | 'default'
    | 'activity'
    | 'labeling'
    | 'unitDifficulty'
    | 'unitAiDifficulty'
    | 'answer'
    | 'generatorOriginalTask';
} & Omit<DescriptionListProps<TaskDescriptionList_task$data>, 'item' | 'itemDescriptions' | 'picks'>;

const TaskDescriptionList = ({ task: taskReference, type = 'default', ...props }: Props) => {
  const task = useFragment(TaskDescriptionList_task, taskReference);

  return (
    <DescriptionList
      item={task}
      itemDescriptions={{
        'problem.problemType': {
          title: '창작 방식',
          renderValue: ({ problem }) =>
            problem?.problemType ? (
              <EnumPair typename={'TaskProblemTypeEnum'}>{problem?.problemType}</EnumPair>
            ) : undefined,
        },
        'problemGenerator': {
          title: '자동 생성기',
          renderValue: ({ problemGenerator, generatedProblem }) => (
            <Stack gapX={1}>
              {problemGenerator ? (
                <>
                  <Stack.Item>
                    <StyledOcticon icon={CheckIcon} color={'success.fg'} size={16} />
                  </Stack.Item>
                  <Stack.Item>
                    <LinkButton
                      href={`/problemGenerator/${problemGenerator.id}`}
                      size={'small'}
                      variant={'invisible'}
                      leadingIcon={LinkIcon}
                    >
                      자동 생성기로 이동
                    </LinkButton>
                  </Stack.Item>
                </>
              ) : generatedProblem ? (
                <>
                  <Stack.Item>
                    <StyledOcticon icon={XIcon} color={'closed.fg'} size={16} />
                  </Stack.Item>
                  <Stack.Item>
                    <LinkButton
                      href={`/problemGenerator/${generatedProblem.problemGenerator.id}`}
                      size={'small'}
                      variant={'invisible'}
                      leadingIcon={LinkIcon}
                    >
                      자동 생성기로 이동
                    </LinkButton>
                  </Stack.Item>
                </>
              ) : (
                <Stack.Item>
                  <StyledOcticon icon={XIcon} color={'closed.fg'} size={16} />
                </Stack.Item>
              )}
            </Stack>
          ),
        },
        'originalTask': {
          title: '원본 문제',
          renderValue: ({ originalTask }) =>
            originalTask ? (
              <LinkButton href={`/task/${originalTask.id}`} size={'small'} variant={'invisible'} leadingIcon={LinkIcon}>
                원본 문제로 이동
              </LinkButton>
            ) : undefined,
        },
        'scrap': { title: '출처', renderValue: ({ scrap }) => scrap?.scrapSource.title },
        'title': { title: '제목', renderValue: ({ title }) => title },
        'labelingCount': {
          title: '라벨링 단계',
          renderValue: ({ labelingCount }) =>
            labelingCount ? (
              <Text>
                <EnumPair typename={'TaskLabelingCountEnum'}>{labelingCount}</EnumPair> 라벨링
              </Text>
            ) : undefined,
        },
        'problem.answerType': {
          title: '문제 유형',
          renderValue: ({ problem }) =>
            problem?.answerType ? (
              <EnumPair typename={'TaskProblemAnswerTypeEnum'}>{problem.answerType}</EnumPair>
            ) : undefined,
        },
        'problem.creationType': {
          title: '변형 정도',
          renderValue: ({ problem }) =>
            problem?.creationType ? (
              <EnumPair typename={'TaskProblemCreationTypeEnum'}>{problem?.creationType}</EnumPair>
            ) : undefined,
        },
        'problem.answerChoiceCount': {
          title: '선지 개수',
          renderValue: ({ problem }) => (problem?.answerChoiceCount !== 0 ? problem?.answerChoiceCount : undefined),
        },
        'aiFile': {
          title: '이미지 ai 파일',
          renderValue: ({ aiFile }) =>
            aiFile ? (
              <LinkButton href={aiFile.objectUrl} size={'small'} variant={'invisible'} leadingIcon={DownloadIcon}>
                파일 다운로드
              </LinkButton>
            ) : undefined,
        },
        'problem.answer': { title: '정답', renderValue: ({ problem }) => problem?.answer || undefined },
        'status': {
          title: '상태',
          renderValue: (task) => <TaskStatusLabel task={task} />,
        },
        'assignedTo': {
          title: '작업자',
          renderValue: ({ assignedTo }) => (assignedTo ? <UserAvatarText user={assignedTo} /> : undefined),
        },
        'assigned': {
          title: '배정일',
          renderValue: ({ assigned }) => (assigned ? <DateText>{assigned}</DateText> : undefined),
        },
        'unitDs': {
          title: '창작 라벨링',
          renderValue: ({ unitDs }) =>
            unitDs.length > 0
              ? unitDs.map(({ id, unitATitle, unitBTitle, unitCTitle, description }, index) => (
                  <View key={id}>
                    {[unitATitle, unitBTitle, unitCTitle, description].join(' > ')}
                    {index === 0 ? <Label sx={{ marginLeft: 1 }}>Last</Label> : null}
                  </View>
                ))
              : undefined,
        },
        'firstLabeling': {
          title: '1차 라벨링',
          renderValue: ({ firstLabeling }) => (
            <>
              <View sx={{ marginBottom: 2 }}>
                <Text>Rating {firstLabeling?.difficulty || '-'}</Text>
              </View>
              {firstLabeling && firstLabeling.unitDs.length > 0
                ? firstLabeling.unitDs.map(({ id, unitATitle, unitBTitle, unitCTitle, description }, index) => (
                    <View key={id}>
                      {[unitATitle, unitBTitle, unitCTitle, description].join(' > ')}
                      {index === 0 ? <Label sx={{ marginLeft: 1 }}>Last</Label> : null}
                    </View>
                  ))
                : '-'}
            </>
          ),
        },
        'secondLabeling': {
          title: '2차 라벨링',
          renderValue: ({ secondLabeling }) => (
            <>
              <View sx={{ marginBottom: 2 }}>
                <Text>Rating {secondLabeling?.difficulty || '-'}</Text>
              </View>
              {secondLabeling && secondLabeling.unitDs.length > 0
                ? secondLabeling.unitDs.map(({ id, unitATitle, unitBTitle, unitCTitle, description }, index) => (
                    <View key={id}>
                      {[unitATitle, unitBTitle, unitCTitle, description].join(' > ')}
                      {index === 0 ? <Label sx={{ marginLeft: 1 }}>Last</Label> : null}
                    </View>
                  ))
                : '-'}
            </>
          ),
        },
        'finalLabeling': {
          title: '최종 라벨링',
          renderValue: ({ finalLabeling }) => (
            <>
              <View sx={{ marginBottom: 2 }}>
                <Text>Rating {finalLabeling?.difficulty || '-'}</Text>
              </View>
              {finalLabeling && finalLabeling.unitDs.length > 0
                ? finalLabeling.unitDs.map(({ id, unitATitle, unitBTitle, unitCTitle, description }, index) => (
                    <View key={id}>
                      {[unitATitle, unitBTitle, unitCTitle, description].join(' > ')}
                      {index === 0 ? <Label sx={{ marginLeft: 1 }}>Last</Label> : null}
                    </View>
                  ))
                : '-'}
            </>
          ),
        },
        'difficulty': {
          title: 'Rating',
          renderValue: ({ difficulty }) => difficulty || '-',
        },
        'aiDifficulty': {
          title: 'AI Rating',
          renderValue: ({ aiDifficulty }) => aiDifficulty || '-',
        },
        'publishedUnitDs': {
          title: 'Unit D',
          renderValue: ({ publishedUnitDs }) =>
            publishedUnitDs.length > 0
              ? publishedUnitDs.map(({ id, unitATitle, unitBTitle, unitCTitle, description }, index) => (
                  <View key={id}>
                    {[unitATitle, unitBTitle, unitCTitle, description].join(' > ')}
                    {index === 0 ? <Label sx={{ marginLeft: 1 }}>Last</Label> : null}
                  </View>
                ))
              : undefined,
        },
        'typingAutomationScript': {
          title: '자동 타이핑 여부',
          renderValue: ({ typingAutomationScript }) =>
            typingAutomationScript && typingAutomationScript.id ? 'O' : 'X',
        },
        'typingAutomationScript.id': {
          title: '프롬프트',
          renderValue: ({ typingAutomationScript }) =>
            typingAutomationScript ? (
              <Link href={`/typingAutomationScript/[typingAutomationScriptId]`}>
                <Text color={'accent.fg'}>{typingAutomationScript.title}</Text>
              </Link>
            ) : (
              '-'
            ),
        },
      }}
      picks={
        type === 'default'
          ? [
              'problem.problemType',
              'problemGenerator',
              'originalTask',
              'title',
              'problem.answerType',
              'problem.answer',
              'problem.answerChoiceCount',
              'problem.creationType',
              'labelingCount',
              'finalLabeling',
              'aiFile',
              'typingAutomationScript',
              'typingAutomationScript.id',
            ]
          : type === 'activity'
          ? ['status', 'assignedTo', 'assigned']
          : type === 'labeling'
          ? ['secondLabeling', 'firstLabeling', 'unitDs']
          : type === 'unitAiDifficulty'
          ? ['publishedUnitDs', 'aiDifficulty']
          : type === 'answer'
          ? ['problem.answerType', 'problem.answer', 'problem.answerChoiceCount']
          : type === 'generatorOriginalTask'
          ? [
              'problem.problemType',
              'title',
              'problem.answerType',
              'problem.answer',
              'problem.answerChoiceCount',
              'problem.creationType',
              'labelingCount',
              'difficulty',
              'publishedUnitDs',
              'aiFile',
            ]
          : []
      }
      {...props}
    />
  );
};

export default TaskDescriptionList;
