/**
 * @generated SignedSource<<539ce5deb1bd96298b3fe3408d2fbd16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery$variables = {};
export type StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery$data = {
  readonly storeStuffPeriods: ReadonlyArray<number>;
};
export type StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery = {
  response: StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery$data;
  variables: StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "storeStuffPeriods",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "db0b89f90e1211cc7ad0f965baf42f61",
    "id": null,
    "metadata": {},
    "name": "StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery",
    "operationKind": "query",
    "text": "query StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery {\n  storeStuffPeriods\n}\n"
  }
};
})();

(node as any).hash = "abc32a7584af9ed84d082beb8bbd92c2";

export default node;
