import { Avatar as PrimerAvatar, AvatarProps as PrimerAvatarProps } from '@primer/react';
import React from 'react';

type Props = {} & PrimerAvatarProps;

const Avatar = ({ ...props }: Props, ref: React.Ref<HTMLImageElement>) => (
  /**
   * * https://stackoverflow.com/questions/40570117/http403-forbidden-error-when-trying-to-load-img-src-with-google-profile-pic
   */
  <PrimerAvatar ref={ref} referrerPolicy={'no-referrer'} {...props} />
);

export default React.forwardRef(Avatar);
export type { Props as AvatarProps };
