import { useTheme } from '@primer/react';

import { PostcodeOptions } from '../../types/postcode';

const POPUP_WIDTH = 500;
const POPUP_HEIGHT = 500;

const usePostcodePopup = (options?: PostcodeOptions, popupIndex = 1) => {
  const { theme } = useTheme();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const zipCodePopup = new daum.Postcode({
    width: POPUP_WIDTH,
    height: POPUP_HEIGHT,
    theme: {
      bgColor: theme?.colors.canvas.subtle,
      searchBgColor: theme?.colors.canvas.default,
      contentBgColor: theme?.colors.canvas.default,
      pageBgColor: theme?.colors.canvas.default,
      textColor: theme?.colors.fg.default,
      queryTextColor: theme?.colors.fg.default,
      outlineColor: theme?.colors.border.default,
    },
    oncomplete: options?.onComplete,
  });

  const open = () =>
    zipCodePopup.open({
      top: window.screen.height / 2 - POPUP_HEIGHT / 2,
      left: window.screen.width / 2 - POPUP_WIDTH / 2,
      popupTitle: '우편번호 검색',
      popupKey: `zipcodePopup${popupIndex}`,
    });
  return { open };
};

export default usePostcodePopup;
