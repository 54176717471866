import {
  AnchoredOverlay as PrimerAnchoredOverlay,
  AnchoredOverlayProps as PrimerAnchoredOverlayProps,
} from '@primer/react';
import { PropsWithChildren } from 'react';

type Props = {} & PrimerAnchoredOverlayProps;

const AnchoredOverlay = ({ ...props }: PropsWithChildren<Props>) => <PrimerAnchoredOverlay {...props} />;

export default AnchoredOverlay;
export type { Props as AnchoredOverlayProps };
