import { IconButton as PrimerIconButton, IconButtonProps as PrimerIconButtonProps } from '@primer/react';
import React from 'react';

import useFormikContext from '../../../hooks/useFormikContext';

// type NoStringIndex<T> = { [K in keyof T as string extends K ? never : K]: T[K] };
// type NoNumberIndex<T> = { [K in keyof T as number extends K ? never : K]: T[K] };
// type NoSymbolIndex<T> = { [K in keyof T as symbol extends K ? never : K]: T[K] };

// type Props = {} & NoStringIndex<NoNumberIndex<NoSymbolIndex<PrimerIconButtonProps>>>;
type Props = {} & Omit<PrimerIconButtonProps, 'aria-label' | 'aria-labelledby'> & IconButtonA11yProps;

type IconButtonA11yProps =
  | {
      'aria-label': string;
      'aria-labelledby'?: undefined;
    }
  | {
      'aria-label'?: undefined;
      'aria-labelledby': string;
    };

const IconButton = ({ disabled, ...props }: Props, ref: React.Ref<HTMLButtonElement>) => {
  const formikContext = useFormikContext();

  return <PrimerIconButton ref={ref} disabled={disabled || formikContext?.isSubmitting} {...props} />;
};

export default React.forwardRef(IconButton);
export type { Props as IconButtonProps, IconButtonA11yProps };
