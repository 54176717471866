import { useField } from 'formik';
import { Suspense, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import usePaginatorSearchOverlayQueryLoader from '../../../hooks/usePaginatorSearchOverlayQueryLoader';
import {
  TypingAutomationScriptPaginatorSearchOverlayField_typingAutomationScriptsQuery,
  TypingAutomationScriptPaginatorSearchOverlayField_typingAutomationScriptsQuery$data,
  TypingAutomationScriptPaginatorSearchOverlayField_typingAutomationScriptsQuery$variables,
} from '../../../relay/__generated__/TypingAutomationScriptPaginatorSearchOverlayField_typingAutomationScriptsQuery.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import ItemList from '../../core/ItemList';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import ScrollContainer from '../../core/ScrollContainer';
import SearchOverlayField, { SearchOverlayFieldProps } from '../../core/SearchOverlayField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';
import TypingAutomationScriptPaginator from '../TypingAutomationScriptPaginator';

const typingAutomationScriptsForTypingAutomationScriptPaginatorSearchOverlayField = graphql`
  query TypingAutomationScriptPaginatorSearchOverlayField_typingAutomationScriptsQuery(
    $filters: TypingAutomationScriptFilter
    $order: TypingAutomationScriptOrder
    $first: Int
    $after: String
  ) {
    typingAutomationScripts(filters: $filters, order: $order, first: $first, after: $after) {
      edges {
        node {
          id
          title
        }
      }
    }
    ...TypingAutomationScriptPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

type Props = {
  variables?: TypingAutomationScriptPaginatorSearchOverlayField_typingAutomationScriptsQuery$variables;
} & Omit<
  SearchOverlayFieldProps<
    TypingAutomationScriptPaginatorSearchOverlayField_typingAutomationScriptsQuery$data['typingAutomationScripts']['edges'][number]['node']
  >,
  'renderItemList'
>;

const TypingAutomationScriptPaginatorSearchOverlayField = ({
  name = '',
  value: propValue,
  variables,
  ...props
}: Props) => {
  const [{ value: baseValue }] = useField<string>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [valueQueryReference, loadValueQuery, disposeValueQuery] =
    useQueryLoader<TypingAutomationScriptPaginatorSearchOverlayField_typingAutomationScriptsQuery>(
      typingAutomationScriptsForTypingAutomationScriptPaginatorSearchOverlayField,
    );
  useEffect(() => {
    loadValueQuery({ filters: { id_Exact: value as string } });
    return () => disposeValueQuery();
  }, [value, loadValueQuery, disposeValueQuery]);

  const [searchOverlayFieldProps, queryReference] =
    usePaginatorSearchOverlayQueryLoader<TypingAutomationScriptPaginatorSearchOverlayField_typingAutomationScriptsQuery>(
      typingAutomationScriptsForTypingAutomationScriptPaginatorSearchOverlayField,
      variables,
    );

  return valueQueryReference ? (
    <PreloadedQueryRenderer
      query={typingAutomationScriptsForTypingAutomationScriptPaginatorSearchOverlayField}
      queryReference={valueQueryReference}
    >
      {({ typingAutomationScripts }) => (
        <SearchOverlayField
          value={typingAutomationScripts?.edges.map(({ node }) => node.title).join(', ') || ''}
          name={name}
          renderItemList={({ containerRef, handleSelect }, itemProps) =>
            queryReference ? (
              <Suspense fallback={<Spinner sx={{ paddingY: 3 }} />}>
                <PreloadedQueryRenderer
                  query={typingAutomationScriptsForTypingAutomationScriptPaginatorSearchOverlayField}
                  queryReference={queryReference}
                >
                  {(queryData) => (
                    <TypingAutomationScriptPaginator fragmentReference={queryData}>
                      {(data, { hasNext, isLoadingNext, loadMore }) => (
                        <ScrollContainer
                          ref={containerRef as React.RefObject<HTMLDivElement>}
                          onScrollReachEnd={() => {
                            if (hasNext && !isLoadingNext) loadMore(20);
                          }}
                        >
                          <ItemList
                            items={data.typingAutomationScripts.edges}
                            renderItem={({ node: { title } }) => <Text sx={{ fontSize: 1 }}>{title}</Text>}
                            renderItemWrapper={(children, { node }) => (
                              <View
                                {...itemProps}
                                key={node.id}
                                onClick={(e) => handleSelect(e, node)}
                                onKeyDown={(e) => handleSelect(e, node)}
                              >
                                {children}
                              </View>
                            )}
                            emptyState={
                              <View sx={{ paddingY: 3 }}>
                                <EmptyState
                                  title={'검색 결과가 없어요'}
                                  description={'다른 검색어로 다시 시도해주세요.'}
                                />
                              </View>
                            }
                          />
                          <View>{isLoadingNext ? <Spinner size={'small'} /> : null}</View>
                        </ScrollContainer>
                      )}
                    </TypingAutomationScriptPaginator>
                  )}
                </PreloadedQueryRenderer>
              </Suspense>
            ) : null
          }
          {...searchOverlayFieldProps}
          {...props}
        />
      )}
    </PreloadedQueryRenderer>
  ) : (
    <Spinner size={'small'} />
  );
};

export default TypingAutomationScriptPaginatorSearchOverlayField;
