import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

import { unauthenticatedRoutes } from '../../../../constants/routes';
import AnimatedLogo from '../../AnimatedLogo';
import View from '../../View';

type Props = {};

const UnauthenticatedRoute = ({ children }: PropsWithChildren<Props>) => {
  const router = useRouter();
  const { status } = useSession();

  const loading = (
    <View sx={{ width: '100vw', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <AnimatedLogo size={80} />
    </View>
  );

  if (status === 'loading') {
    return loading;
  }

  if (status === 'authenticated' && unauthenticatedRoutes.some(({ pathname }) => pathname === router.pathname)) {
    router.replace('/dashboard');
    return loading;
  }

  return children as JSX.Element;
};

export default UnauthenticatedRoute;
export type { Props as AuthenticatedRouteProps };
