import { graphql } from 'react-relay';

import { TaskHintAiSuggestionPaginator_query$key } from '../../../relay/__generated__/TaskHintAiSuggestionPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const TaskHintAiSuggestionPaginator_query = graphql`
  fragment TaskHintAiSuggestionPaginator_query on Task
  @argumentDefinitions(
    first: { type: Int }
    after: { type: String }
    last: { type: Int }
    before: { type: String }
    order: { type: TaskHintAiSuggestionOrder }
  )
  @refetchable(queryName: "TaskHintAiSuggestionPaginatorRefreshQuery") {
    hintAiSuggestions(first: $first, after: $after, last: $last, before: $before, order: $order)
      @connection(key: "TaskHintAiSuggestionPaginator_query_hintAiSuggestions") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          text
          created
          rawText
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<TaskHintAiSuggestionPaginator_query$key>, 'fragment'>;

const TaskHintAiSuggestionPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={TaskHintAiSuggestionPaginator_query} {...props} />
);

export default TaskHintAiSuggestionPaginator;
