import { PageLayout as PrimerPageLayout, PageLayoutProps as PrimerPageLayoutProps } from '@primer/react';
import { PropsWithChildren } from 'react';

type Props = {} & PropsWithChildren<PrimerPageLayoutProps>;

const PageLayout = ({ ...props }: Props) => <PrimerPageLayout {...props} />;

export default Object.assign(PageLayout, {
  Header: PrimerPageLayout.Header,
  Content: PrimerPageLayout.Content,
  Pane: PrimerPageLayout.Pane,
  Footer: PrimerPageLayout.Footer,
});
export type { Props as PageLayoutProps };
