import { CheckIcon, CopyIcon, KebabHorizontalIcon, PencilIcon, TrashIcon, XIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { graphql } from 'react-relay';

import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import DateText from '../../components/core/DateText';
import DialogButton from '../../components/core/DialogButton';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Stack from '../../components/core/Stack';
import StyledOcticon from '../../components/core/StyledOcticon';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import RaffleCreateDialog from '../../components/raffle/RaffleCreateDialog';
import RaffleStatusLabel from '../../components/raffle/RaffleStatusLabel';
import RaffleUpdateDialog from '../../components/raffle/RaffleUpdateDialog';
import RaffleStuffPreviewItem from '../../components/raffleStuff/RaffleStuffPreviewItem';
import UserAvatarText from '../../components/user/UserAvatarText';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { RaffleId_raffleDeleteMutation } from '../../relay/__generated__/RaffleId_raffleDeleteMutation.graphql';
import { RaffleId_raffleQuery } from '../../relay/__generated__/RaffleId_raffleQuery.graphql';
import { NextPage } from '../_app';

const raffleForRaffleId = graphql`
  query RaffleId_raffleQuery($id: ID!) {
    raffle(id: $id) {
      id
      title
      actions
      description
      raffleStuff {
        id
        title
        ...RaffleStuffPreviewItem_raffleStuff
      }
      isPremium
      order
      requiredPoint
      winnerCount
      startAt
      endAt
      showEndAt
      ...RaffleStatusLabel_raffle
      createdBy {
        id
        ...UserAvatarText_user
      }
      created
      modifiedBy {
        id
        ...UserAvatarText_user
      }
      modified
      ...RaffleUpdateDialog_raffle
    }
  }
`;

type Props = {};

const RaffleId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ raffle }] = useLazyLoadQuery<RaffleId_raffleQuery>(raffleForRaffleId, {
    id: router.query.raffleId as string,
  });
  if (!raffle) return null;
  const {
    id,
    title,
    actions,
    description,
    raffleStuff,
    isPremium,
    order,
    requiredPoint,
    winnerCount,
    startAt,
    endAt,
    showEndAt,
    created,
    createdBy,
    modified,
    modifiedBy,
  } = raffle;

  return (
    <View>
      <Head siteTitle={`뽑기왕 매쓰킹 - ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{title}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              <Stack.Item>
                <DialogButton
                  renderDialog={({ isOpen, closeDialog }) => (
                    <RaffleCreateDialog
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      initialValues={{
                        raffleStuff: { id: raffleStuff.id },
                        title,
                        description,
                        isPremium,
                        requiredPoint,
                        winnerCount,
                        order,
                        startAt,
                        showEndAt,
                        endAt,
                      }}
                      wide
                      config={{
                        onCompleted: () => {
                          toast('뽑기왕 복제 완료!', 'success');
                          closeDialog();
                          router.push('/raffle');
                        },
                      }}
                    />
                  )}
                  variant={'outline'}
                  leadingIcon={CopyIcon}
                >
                  복제하기
                </DialogButton>
              </Stack.Item>
              {actions.includes('raffle_update') ? (
                <Stack.Item>
                  <DialogButton
                    renderDialog={({ isOpen, closeDialog }) => (
                      <RaffleUpdateDialog
                        raffle={raffle}
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        wide
                        config={{
                          onCompleted: () => {
                            toast('뽑기왕 수정 완료!', 'success');
                            closeDialog();
                          },
                        }}
                      />
                    )}
                    variant={'outline'}
                    leadingIcon={PencilIcon}
                  >
                    수정하기
                  </DialogButton>
                </Stack.Item>
              ) : null}
              {actions.includes('raffle_delete') ? (
                <Stack.Item>
                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.MutationItem<RaffleId_raffleDeleteMutation>
                          mutation={graphql`
                            mutation RaffleId_raffleDeleteMutation($input: RaffleDeleteInput!) {
                              raffleDelete(input: $input) {
                                id @deleteRecord
                              }
                            }
                          `}
                          input={{ id }}
                          variant={'danger'}
                          config={{
                            onCompleted: () => {
                              router.replace('/raffle').then(() => toast('뽑기왕 매쓰킹 삭제 완료!', 'success'));
                            },
                          }}
                          message={'정말 삭제할까요?'}
                        >
                          <ActionList.LeadingVisual>
                            <TrashIcon />
                          </ActionList.LeadingVisual>
                          삭제하기
                        </ActionList.MutationItem>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Stack.Item>
              ) : null}
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <ItemList
              items={[
                {
                  id: 'description',
                  title: '설명',
                  description: (
                    <Text sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{description || '-'}</Text>
                  ),
                },
                {
                  id: 'raffleStuff',
                  title: '뽑기왕 경품',
                  description: (
                    <Stack gapX={2}>
                      <Stack.Item>
                        <View sx={{ width: '60px' }}>
                          <RaffleStuffPreviewItem raffleStuff={raffleStuff} />
                        </View>
                      </Stack.Item>
                      <Stack.Item>
                        <Text>{title}</Text>
                      </Stack.Item>
                    </Stack>
                  ),
                },
                {
                  id: 'isPremium',
                  title: '프리미엄 사용자용',
                  description: isPremium ? (
                    <StyledOcticon icon={CheckIcon} sx={{ color: 'success.fg' }} />
                  ) : (
                    <StyledOcticon icon={XIcon} sx={{ color: 'danger.fg' }} />
                  ),
                },
                { id: 'order', title: '우선순위', description: order },
                { id: 'requiredPoint', title: '사용 포인트', description: `${requiredPoint}P` },
                { id: 'winnerCount', title: '당첨자 수', description: `${winnerCount}명` },
                { id: 'startAt', title: '시작일', description: <DateText format={'P EEEE'}>{startAt}</DateText> },
                { id: 'endAt', title: '종료일', description: <DateText format={'P EEEE'}>{endAt}</DateText> },
                { id: 'showEndAt', title: '노출일', description: <DateText format={'P EEEE'}>{showEndAt}</DateText> },
              ]}
              renderItem={({ title, description }) => (
                <View>
                  <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                  <View
                    sx={{
                      borderWidth: 1,
                      borderStyle: 'solid',
                      borderColor: 'border.default',
                      borderRadius: 2,
                      marginTop: 2,
                      padding: 3,
                    }}
                  >
                    <Text sx={{ fontSize: 1 }}>{description}</Text>
                  </View>
                </View>
              )}
              renderItemWrapper={(children, { id }, i) => (
                <View key={id} sx={{ marginTop: i > 0 ? 3 : 0 }}>
                  {children}
                </View>
              )}
            />
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <ItemList
              items={[
                { id: 'status', title: '상태', description: <RaffleStatusLabel raffle={raffle} /> },
                { id: 'createdBy', title: '제작자', description: <UserAvatarText user={createdBy} /> },
                { id: 'created', title: '제작일', description: <DateText>{created}</DateText> },
                {
                  id: 'modifiedBy',
                  title: '수정자',
                  description: modifiedBy ? <UserAvatarText user={modifiedBy} /> : '-',
                },
                { id: 'modified', title: '수정일', description: <DateText>{modified}</DateText> },
              ]}
              renderItem={({ title, description }) => (
                <Grid gapX={1} gapY={1}>
                  <Grid.Unit size={[1, 1, 'max']}>
                    <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 'min']}>
                    <Text sx={{ fontSize: 1 }}>{description}</Text>
                  </Grid.Unit>
                </Grid>
              )}
              renderItemWrapper={(children, { id }, i) => (
                <View key={id} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                  {children}
                </View>
              )}
            />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

RaffleId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
RaffleId.authenticated = true;
RaffleId.routes = [
  { id: 'raffleId', name: '뽑기왕 매쓰킹 상세', pathname: '/raffle/[raffleId]', permissions: ['raffle_read'] },
];

export default RaffleId;
