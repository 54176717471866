/**
 * @generated SignedSource<<5e25c8b4ca2ebdff8efde3dcfdccb99b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeliveryOrderTypeEnum = "gift" | "order" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderUpdateDialog_deliveryOrder$data = {
  readonly deliveryAddress: {
    readonly address: string;
    readonly addressDetail: string;
    readonly recipientName: string;
    readonly recipientNumber: string;
    readonly zipCode: string;
  };
  readonly description: string | null;
  readonly id: string;
  readonly stuff: {
    readonly id: string;
  };
  readonly title: string;
  readonly type: DeliveryOrderTypeEnum;
  readonly " $fragmentType": "DeliveryOrderUpdateDialog_deliveryOrder";
};
export type DeliveryOrderUpdateDialog_deliveryOrder$key = {
  readonly " $data"?: DeliveryOrderUpdateDialog_deliveryOrder$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderUpdateDialog_deliveryOrder">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryOrderUpdateDialog_deliveryOrder",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Stuff",
      "kind": "LinkedField",
      "name": "stuff",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DeliveryAddress",
      "kind": "LinkedField",
      "name": "deliveryAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipientName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "recipientNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "address",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressDetail",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "zipCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeliveryOrder",
  "abstractKey": null
};
})();

(node as any).hash = "57ef955100a4dedd8557bb1d1c221083";

export default node;
