/**
 * @generated SignedSource<<e0f5ab0e63e295fa69be2190fc432ce3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StoreStuffTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StoreStuffConnectionDataTable_storeStuffConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly endAt: any | null;
      readonly id: string;
      readonly modified: any | null;
      readonly period: number | null;
      readonly sequence: number;
      readonly title: string;
      readonly type: StoreStuffTypeEnum;
    };
  }>;
  readonly " $fragmentType": "StoreStuffConnectionDataTable_storeStuffConnection";
};
export type StoreStuffConnectionDataTable_storeStuffConnection$key = {
  readonly " $data"?: StoreStuffConnectionDataTable_storeStuffConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"StoreStuffConnectionDataTable_storeStuffConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoreStuffConnectionDataTable_storeStuffConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StoreStuffEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "StoreStuff",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "period",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modified",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StoreStuffConnection",
  "abstractKey": null
};
})();

(node as any).hash = "2dd30f19a5b25be046590734acca7f03";

export default node;
