/**
 * @generated SignedSource<<1c84eb431f6a87a8cba192cb08498c3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrganizationId_organizationQuery$variables = {
  id: string;
};
export type OrganizationId_organizationQuery$data = {
  readonly organization: {
    readonly id: string;
    readonly invoices: {
      readonly " $fragmentSpreads": FragmentRefs<"MATInvoiceConnectionDataTable_matInvoiceConnection">;
    };
    readonly lmsContent: string;
    readonly sequence: number;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"MATOrganizationDescriptionList_matOrganization" | "MATOrganizationInvoiceCreateDialog_matOrganization" | "MATOrganizationStudentsCreateDialog_matOrganization" | "MATOrganizationStudentsXlsxCreateDialog_matOrganization" | "MATOrganizationTeachersCreateDialog_matOrganization" | "MATOrganizationTeachersXlsxCreateDialog_matOrganization" | "MATOrganizationUpdateDialog_matOrganization">;
  };
};
export type OrganizationId_organizationQuery = {
  response: OrganizationId_organizationQuery$data;
  variables: OrganizationId_organizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sequence",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lmsContent",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationId_organizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATOrganization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MATInvoiceConnection",
            "kind": "LinkedField",
            "name": "invoices",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MATInvoiceConnectionDataTable_matInvoiceConnection"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MATOrganizationUpdateDialog_matOrganization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MATOrganizationDescriptionList_matOrganization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MATOrganizationInvoiceCreateDialog_matOrganization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MATOrganizationTeachersXlsxCreateDialog_matOrganization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MATOrganizationTeachersCreateDialog_matOrganization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MATOrganizationStudentsXlsxCreateDialog_matOrganization"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MATOrganizationStudentsCreateDialog_matOrganization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationId_organizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATOrganization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "MATInvoiceConnection",
            "kind": "LinkedField",
            "name": "invoices",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MATInvoiceEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MATInvoice",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "premiumType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "scholarshipAmount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endDate",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxStudentCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "state",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MATOrganization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MATInvoice",
                            "kind": "LinkedField",
                            "name": "currentInvoice",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "prefix",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdBy",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "teachersBulkCreateTemplateUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "studentsBulkCreateTemplateUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "74c4ae2b68087440196f352a5f004165",
    "id": null,
    "metadata": {},
    "name": "OrganizationId_organizationQuery",
    "operationKind": "query",
    "text": "query OrganizationId_organizationQuery(\n  $id: ID!\n) {\n  organization(id: $id) {\n    id\n    sequence\n    title\n    lmsContent\n    invoices {\n      ...MATInvoiceConnectionDataTable_matInvoiceConnection\n    }\n    ...MATOrganizationUpdateDialog_matOrganization\n    ...MATOrganizationDescriptionList_matOrganization\n    ...MATOrganizationInvoiceCreateDialog_matOrganization\n    ...MATOrganizationTeachersXlsxCreateDialog_matOrganization\n    ...MATOrganizationTeachersCreateDialog_matOrganization\n    ...MATOrganizationStudentsXlsxCreateDialog_matOrganization\n    ...MATOrganizationStudentsCreateDialog_matOrganization\n  }\n}\n\nfragment MATInvoiceConnectionDataTable_matInvoiceConnection on MATInvoiceConnection {\n  edges {\n    node {\n      id\n      title\n      premiumType\n      scholarshipAmount\n      startDate\n      endDate\n      maxStudentCount\n      amount\n      ...MATInvoiceStateLabel_matInvoice\n    }\n  }\n}\n\nfragment MATInvoiceStateLabel_matInvoice on MATInvoice {\n  id\n  state\n  organization {\n    id\n    currentInvoice {\n      id\n    }\n  }\n}\n\nfragment MATOrganizationDescriptionList_matOrganization on MATOrganization {\n  createdBy\n  created\n  title\n  prefix\n  type\n}\n\nfragment MATOrganizationInvoiceCreateDialog_matOrganization on MATOrganization {\n  id\n}\n\nfragment MATOrganizationStudentsCreateDialog_matOrganization on MATOrganization {\n  id\n}\n\nfragment MATOrganizationStudentsXlsxCreateDialog_matOrganization on MATOrganization {\n  id\n  studentsBulkCreateTemplateUrl\n}\n\nfragment MATOrganizationTeachersCreateDialog_matOrganization on MATOrganization {\n  id\n}\n\nfragment MATOrganizationTeachersXlsxCreateDialog_matOrganization on MATOrganization {\n  id\n  teachersBulkCreateTemplateUrl\n}\n\nfragment MATOrganizationUpdateDialog_matOrganization on MATOrganization {\n  id\n  title\n  prefix\n  type\n}\n"
  }
};
})();

(node as any).hash = "b31d471ab2c9f30e1038f0aa6e45fc96";

export default node;
