import useOrderPairsQuery from '../../../hooks/useOrderPairsQuery';
import { isNullable } from '../../../utils/is';

type Props = {
  typename: string;
  children: string;
};

const OrderPair = ({ typename, children: key }: Props) => {
  const pairs = useOrderPairsQuery(typename);
  const value = pairs.find(({ key: pairKey }) => pairKey === key)?.value;

  if (isNullable(value)) throw new Error(`'${key}' is not defined in '${typename}'`);

  return value as unknown as JSX.Element;
};

export default OrderPair;
export type { Props as OrderPairProps };
