import { CheckIcon, PlusIcon, XIcon } from '@primer/octicons-react';
import { FieldArray } from 'formik';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import useEnumPairsQuery from '../../../hooks/useEnumPairsQuery';
import {
  ProductDiscountInput,
  ProductEventCreateDialog_productEventCreateMutation,
  ProductEventUserYearEnum,
} from '../../../relay/__generated__/ProductEventCreateDialog_productEventCreateMutation.graphql';
import Button from '../../core/Button';
import DatetimeField from '../../core/DatetimeField';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairCheckboxGroupField from '../../core/EnumPairCheckboxGroupField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import ItemList from '../../core/ItemList';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';
import View from '../../core/View';
import ProductSelectField from '../../product/ProductSelectField';

type Props = {
  initialValues?: Partial<MutationFormikProps<ProductEventCreateDialog_productEventCreateMutation>['initialValues']>;
} & DialogProps &
  Pick<MutationFormikProps<ProductEventCreateDialog_productEventCreateMutation>, 'connections' | 'config' | 'onSubmit'>;

const ProductEventCreateDialog = ({ initialValues, connections, config, onSubmit, ...props }: Props) => {
  const userYearEnum = useEnumPairsQuery('ProductEventUserYearEnum');
  const defaultDiscountValue: ProductDiscountInput = { productId: '', amount: 0 };

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>생성하기</Text>
      </Dialog.Header>
      <MutationFormik<ProductEventCreateDialog_productEventCreateMutation>
        mutation={graphql`
          mutation ProductEventCreateDialog_productEventCreateMutation($input: ProductEventCreateInput!) {
            productEventCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{
          title: '',
          description: null,
          startAt: null,
          endAt: null,
          discounts: [defaultDiscountValue],
          userYears: userYearEnum.map(({ key }) => key) as ProductEventUserYearEnum[],
          ...initialValues,
        }}
        connections={connections}
        config={config}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }, { nonFieldErrorRef, scrollContainerRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>이벤트 정보</Text>
                <TextField
                  label={'이벤트명'}
                  name={'title'}
                  caption={'띄어쓰기 포함 최대 16자 입력'}
                  placeholder={'이벤트명 입력'}
                  maxLength={16}
                  required
                />
                <TextareaField label={'설명'} name={'description'} placeholder={'설명 입력'} />
                <DatetimeField
                  label={'시작일'}
                  name={'startAt'}
                  datePickerProps={{ placeholder: '시작일 선택' }}
                  required
                />
                <DatetimeField
                  label={'종료일'}
                  name={'endAt'}
                  datePickerProps={{ placeholder: '종료일 선택' }}
                  required
                />
                <EnumPairCheckboxGroupField
                  typename={'ProductEventUserYearEnum'}
                  label={'유저 타입'}
                  name={'userYears'}
                  required
                  renderContainer={(children) => (
                    <Stack gapX={3} gapY={2} wrap>
                      {children}
                    </Stack>
                  )}
                  renderOptionWrapper={(option, { id }) => <Stack.Item key={id}>{option}</Stack.Item>}
                />
              </FormLayout>
              <View sx={{ marginTop: 5 }}>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>적용 상품</Text>
                <View sx={{ marginTop: 4 }}>
                  <FormLayout>
                    <FieldArray name={'discounts'}>
                      {({ remove, insert }) => (
                        <View>
                          <ItemList
                            items={values.discounts}
                            renderItem={(_, i) => (
                              <Suspense>
                                <Grid sx={{ alignItems: 'flex-end' }} gapX={2}>
                                  <Grid.Unit size={'max'}>
                                    <Grid gapX={2}>
                                      <Grid.Unit size={1 / 2}>
                                        <ProductSelectField
                                          label={'판매 상품'}
                                          name={`discounts.${i}.productId`}
                                          placeholder={'판매 상품 선택'}
                                          variables={{
                                            filters: {
                                              showTypes_Contains_Any: ['store', 'repurchase'],
                                              status_In: ['active'],
                                            },
                                          }}
                                          required
                                        />
                                      </Grid.Unit>
                                      <Grid.Unit size={1 / 2}>
                                        <NumberField
                                          label={'할인 금액'}
                                          name={`discounts.${i}.amount`}
                                          placeholder={'할인 금액'}
                                          required
                                        />
                                      </Grid.Unit>
                                    </Grid>
                                  </Grid.Unit>
                                  <Grid.Unit size={'min'}>
                                    <IconButton
                                      icon={XIcon}
                                      onClick={() => remove(i)}
                                      disabled={values.discounts.length < 2}
                                      aria-label={`remove #${i} productDis`}
                                    />
                                  </Grid.Unit>
                                </Grid>
                              </Suspense>
                            )}
                            renderItemWrapper={(children, _, i) => (
                              <View key={i} sx={{ marginTop: i > 0 ? 3 : 0 }}>
                                {children}
                              </View>
                            )}
                          />
                          <View sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 3 }}>
                            <Button
                              variant={'outline'}
                              onClick={() => insert(values.discounts.length, defaultDiscountValue)}
                              leadingIcon={PlusIcon}
                            >
                              추가하기
                            </Button>
                          </View>
                        </View>
                      )}
                    </FieldArray>
                  </FormLayout>
                </View>
              </View>
            </Dialog.Body>
            <Dialog.Footer>
              <Button leadingIcon={CheckIcon} variant={'primary'} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default ProductEventCreateDialog;
