import { KebabHorizontalIcon, PencilIcon, TrashIcon, VersionsIcon } from '@primer/octicons-react';
import { GraphQLError } from 'graphql';
import { useRouter } from 'next/router';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Card from '../../components/core/Card';
import DataTable, { RowWrapper } from '../../components/core/DataTable';
import DialogButton from '../../components/core/DialogButton';
import DialogHandler from '../../components/core/DialogHandler';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationConfirmButton from '../../components/core/MutationConfirmButton';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import ProductDiscountUpdateDialog from '../../components/productDiscount/ProductDiscountUpdateDialog';
import ProductEventCreateDialog from '../../components/productEvent/ProductEventCreateDialog';
import ProductEventDescriptionList from '../../components/productEvent/ProductEventDescriptionList';
import ProductEventUpdateDialog from '../../components/productEvent/ProductEventUpdateDialog';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { ProductEventId_productEventActivateMutation } from '../../relay/__generated__/ProductEventId_productEventActivateMutation.graphql';
import { ProductEventId_productEventDeleteMutation } from '../../relay/__generated__/ProductEventId_productEventDeleteMutation.graphql';
import { ProductEventId_productEventQuery } from '../../relay/__generated__/ProductEventId_productEventQuery.graphql';
import { formatISO } from '../../utils/date';
import { parseGraphQLError } from '../../utils/error';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

const productEventForProductEventId = graphql`
  query ProductEventId_productEventQuery($id: ID!) {
    productEvent(id: $id) {
      id
      title
      actions

      description
      startAt
      endAt
      userYears

      discounts {
        id
        product {
          id
          title
        }
        amount
        ...ProductDiscountUpdateDialog_productDiscount
      }
      ...ProductEventDescriptionList_productEvent
      ...ProductEventUpdateDialog_productEvent
    }
  }
`;

type Props = {};

const ProductEventId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ productEvent }, refresh] = useLazyLoadQuery<ProductEventId_productEventQuery>(
    productEventForProductEventId,
    {
      id: router.query.productEventId as string,
    },
  );
  if (!productEvent) return null;
  const { id, title, actions, description, startAt, endAt, discounts, userYears } = productEvent;

  return (
    <View>
      <Head siteTitle={`스토어 할인 이벤트 - ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{title}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              <Stack.Item>
                <Suspense fallback={<Spinner size={'small'} />}>
                  <DialogButton
                    renderDialog={({ isOpen, closeDialog }) => (
                      <ProductEventCreateDialog
                        wide
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        initialValues={{
                          title,
                          description,
                          startAt: formatISO(new Date(startAt)),
                          endAt: formatISO(new Date(endAt)),
                          discounts: discounts.map(({ product, amount }) => ({ productId: product.id, amount })),
                          userYears,
                        }}
                        config={{
                          onCompleted: () => {
                            toast('스토어 할인 이벤트 복제 완료!', 'success');
                            closeDialog();
                          },
                        }}
                      />
                    )}
                    leadingIcon={VersionsIcon}
                    variant={'outline'}
                    size={'large'}
                  >
                    복제하기
                  </DialogButton>
                </Suspense>
              </Stack.Item>
              {actions.includes('product_event_update') ? (
                <Stack.Item>
                  <DialogButton
                    renderDialog={({ isOpen, closeDialog }) => (
                      <ProductEventUpdateDialog
                        wide
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        productEvent={productEvent}
                        config={{
                          onCompleted: () => {
                            toast('수정이 완료됐어요', 'success');
                            closeDialog();
                          },
                          onError: () => {
                            toast('다시 수정해 주세요', 'error');
                          },
                        }}
                      />
                    )}
                    variant={'outline'}
                    leadingIcon={PencilIcon}
                    size={'large'}
                  >
                    수정하기
                  </DialogButton>
                </Stack.Item>
              ) : null}
              {actions.includes('product_event_activate') ? (
                <Stack.Item>
                  <MutationConfirmButton<ProductEventId_productEventActivateMutation>
                    mutation={graphql`
                      mutation ProductEventId_productEventActivateMutation($input: ProductEventActivateInput!) {
                        productEventActivate(input: $input) {
                          id
                          actions
                          ...ProductEventStatusLabel_productEvent
                        }
                      }
                    `}
                    input={{ id }}
                    variant={'primary'}
                    size={'large'}
                    config={{
                      onCompleted: () => {
                        toast('이벤트가 활성화됐어요.', 'success');
                      },
                      onError: (error) => {
                        toast(parseGraphQLError(error as GraphQLError)?.[0].message || '다시 활성화해주세요', 'error');
                      },
                    }}
                    message={'이벤트 정보와 등록된 이미지 확인 후 활성화해주세요. 활성화할까요?'}
                  >
                    활성화하기
                  </MutationConfirmButton>
                </Stack.Item>
              ) : null}
              {actions.includes('product_event_delete') ? (
                <Stack.Item>
                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.MutationItem<ProductEventId_productEventDeleteMutation>
                          mutation={graphql`
                            mutation ProductEventId_productEventDeleteMutation($input: ProductEventDeleteInput!) {
                              productEventDelete(input: $input) {
                                id @deleteRecord
                              }
                            }
                          `}
                          input={{ id }}
                          variant={'danger'}
                          config={{
                            onCompleted: () => {
                              router
                                .replace('/productEvent')
                                .then(() => toast('스토어 할인 이벤트 삭제 완료!', 'success'));
                            },
                          }}
                          message={'정말 삭제할까요?'}
                        >
                          <ActionList.LeadingVisual>
                            <TrashIcon />
                          </ActionList.LeadingVisual>
                          삭제하기
                        </ActionList.MutationItem>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Stack.Item>
              ) : null}
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <Text fontSize={3} fontWeight={'bold'}>
              이벤트 정보
            </Text>
            <Card sx={{ padding: 4, marginTop: 2 }}>
              <ProductEventDescriptionList
                productEvent={productEvent}
                type={'default'}
                titleUnitSize={1 / 4}
                descriptionUnitSize={3 / 4}
              />
            </Card>
            <View sx={{ marginTop: 5 }}>
              <Text fontSize={3} fontWeight={'bold'}>
                적용 상품
              </Text>
            </View>
            <Text sx={{ fontSize: 0, color: 'fg.subtle' }}>이미지를 모두 등록해야 이벤트를 활성화할 수 있어요</Text>
            <View sx={{ marginTop: 2 }}>
              <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                총 {numberWithCommas(discounts?.length || 0)}개
              </Text>
            </View>
            <View sx={{ marginTop: 2, overflowX: 'auto' }}>
              <DataTable
                columns={[
                  {
                    field: 'product.title',
                    title: '상품명',
                    width: 512,
                    renderValue: ({ product }) => <Text fontSize={1}>{product.title}</Text>,
                  },
                  {
                    field: 'amount',
                    title: '할인 금액',
                    width: 216,
                    renderValue: ({ amount }) => <Text fontSize={1}>{`${numberWithCommas(amount)}원 할인`}</Text>,
                  },
                ]}
                rows={discounts}
                renderRowWrapper={(children, productDiscount) => {
                  const { id } = productDiscount;
                  return (
                    <RowWrapper
                      key={id}
                      sx={{
                        'cursor': 'pointer',
                        ':hover': {
                          backgroundColor: 'canvas.inset',
                          transition: 'background-color 250ms',
                        },
                      }}
                    >
                      <DialogHandler
                        renderDialog={({ isOpen, closeDialog }) => (
                          <ProductDiscountUpdateDialog
                            productDiscount={productDiscount}
                            isOpen={isOpen}
                            onDismiss={closeDialog}
                            full
                            config={{
                              onCompleted: () => {
                                toast('이미지 업로드가 완료됐어요!', 'success');
                                closeDialog();
                                refresh();
                              },
                              onError: () => {
                                toast('다시 업로드해 주세요', 'error');
                              },
                            }}
                          />
                        )}
                      >
                        {children}
                      </DialogHandler>
                    </RowWrapper>
                  );
                }}
              />
            </View>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <ProductEventDescriptionList
              productEvent={productEvent}
              type={'activity'}
              titleUnitSize={'max'}
              descriptionUnitSize={'min'}
              renderTitle={(title) => <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>}
            />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

ProductEventId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
ProductEventId.authenticated = true;
ProductEventId.routes = [
  {
    id: 'productEventId',
    name: '스토어 할인 이벤트 상세',
    pathname: '/productEvent/[productEventId]',
    permissions: ['product_event_read'],
  },
];

export default ProductEventId;
