import { graphql, useFragment } from 'react-relay';

import { TaskPublishDifferenceItemList_difference$key } from '../../../relay/__generated__/TaskPublishDifferenceItemList_difference.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import EnumPair from '../../core/EnumPair';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import Text from '../../core/Text';
import View from '../../core/View';
import DiffItem from '../../difference/DiffItem';

const TaskPublishDifferenceItemList_difference = graphql`
  fragment TaskPublishDifferenceItemList_difference on Difference {
    ... on TaskPublishDifference {
      difficulty {
        ...DiffItem_diff
      }
      unitDs {
        ...DiffItem_diff
      }
      problem {
        ...DiffItem_diff
      }
      solution {
        ...DiffItem_diff
      }
      answer {
        ...DiffItem_diff
      }
      answerChoiceCount {
        ...DiffItem_diff
      }
      answerType {
        ...DiffItem_diff
      }
    }
  }
`;

type Props = { difference: TaskPublishDifferenceItemList_difference$key };

const TaskPublishDifferenceItemList = ({ difference }: Props) => {
  const { difficulty, unitDs, answer, answerType, answerChoiceCount, problem, solution } = useFragment(
    TaskPublishDifferenceItemList_difference,
    difference,
  );

  const diffItems = [
    { title: 'rating', diff: difficulty },
    ...(unitDs
      ? unitDs.map((unitD, index) => ({
          title: `unit D - ${index + 1}`,
          diff: unitD,
        }))
      : []),
    {
      title: '문제 유형',
      diff: answerType,
      renderItem: (beforeOrAfter: string) => (
        <EnumPair typename={'TaskProblemAnswerTypeEnum'}>{beforeOrAfter}</EnumPair>
      ),
    },
    { title: '정답', diff: answer },
    { title: '선지 개수', diff: answerChoiceCount },
    { title: '문제', diff: problem, renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex> },
    { title: '해설', diff: solution, renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex> },
  ].filter(({ diff }) => !isNullable(diff));
  return (
    <ItemList
      items={diffItems}
      renderItem={({ title, diff, renderItem }) => (
        <>
          <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
          <View sx={{ marginTop: 2 }}>
            <DiffItem diff={diff!} renderItem={renderItem} />
          </View>
        </>
      )}
      renderItemWrapper={(children, { title }, i) => (
        <View key={title} sx={{ marginTop: i > 0 ? 3 : 0 }}>
          {children}
        </View>
      )}
      emptyState={
        <View sx={{ paddingY: 6 }}>
          <EmptyState
            title={'기록되지 않은 히스토리이에요'}
            description={'이 기능이 만들어지기 전에 일어난 일이거나 오류로 누락된 히스토리입니다.'}
          />
        </View>
      }
    />
  );
};

export default TaskPublishDifferenceItemList;
