import { graphql, useFragment } from 'react-relay';

import { TaskFirstLabelingDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/TaskFirstLabelingDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const TaskFirstLabelingDifferenceCounterLabel_difference = graphql`
  fragment TaskFirstLabelingDifferenceCounterLabel_difference on Difference {
    ... on TaskFirstLabelingDifference {
      difficulty {
        __typename
      }
      unitDs {
        __typename
      }
      problem {
        __typename
      }
      solution {
        __typename
      }
      answer {
        __typename
      }
      answerChoiceCount {
        __typename
      }
      answerType {
        __typename
      }
    }
  }
`;

type Props = { difference: TaskFirstLabelingDifferenceCounterLabel_difference$key } & CounterLabelProps;

const TaskFirstLabelingDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { difficulty, unitDs, answer, answerChoiceCount, answerType, problem, solution } = useFragment(
    TaskFirstLabelingDifferenceCounterLabel_difference,
    difference,
  );

  const differenceCount = [difficulty, unitDs, answer, answerChoiceCount, answerType, problem, solution].filter(
    (diff) => !isNullable(diff),
  ).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default TaskFirstLabelingDifferenceCounterLabel;
