import { graphql, useFragment } from 'react-relay';

import {
  QuizSetConnectionDataTable_quizSetConnection$data,
  QuizSetConnectionDataTable_quizSetConnection$key,
} from '../../../relay/__generated__/QuizSetConnectionDataTable_quizSetConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import UserAvatarText from '../../user/UserAvatarText';
import QuizSetStatusLabel from '../QuizSetStatusLabel';

const QuizSetConnectionDataTable_quizSetConnection = graphql`
  fragment QuizSetConnectionDataTable_quizSetConnection on QuizSetConnection {
    edges {
      node {
        id
        unitB {
          id
          title
        }
        unitC {
          id
          title
        }
        modifiedBy {
          ...UserAvatarText_user
        }
        modified
        ...QuizSetStatusLabel_quizSet
      }
    }
  }
`;

type Props = {
  quizSetConnection: QuizSetConnectionDataTable_quizSetConnection$key;
} & Omit<
  DataTableProps<QuizSetConnectionDataTable_quizSetConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const QuizSetConnectionDataTable = ({ quizSetConnection, ...props }: Props) => {
  const { edges } = useFragment(QuizSetConnectionDataTable_quizSetConnection, quizSetConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (quizSet) => <QuizSetStatusLabel quizSet={quizSet} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'unitB',
          title: 'Unit B',
          renderValue: ({ unitB }) => unitB.title,
          width: 300,
        },
        {
          field: 'unitC',
          title: '첫 Unit C',
          renderValue: ({ unitC }) => unitC.title,
          width: 464,
        },
        {
          field: 'modifiedBy',
          title: '지정자',
          renderValue: ({ modifiedBy }) => (modifiedBy ? <UserAvatarText user={modifiedBy} /> : '-'),
          width: 92,
        },
        {
          field: 'modified',
          title: '지정일',
          renderValue: ({ modified }) => <DateText>{modified}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default QuizSetConnectionDataTable;
