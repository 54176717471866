/**
 * @generated SignedSource<<e70ea4dc69aba330a7952d3fedc9b256>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolExamProblemStatusEnum = "approved" | "rejected" | "review" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SchoolExamProblemStatusLabel_schoolExamProblem$data = {
  readonly status: SchoolExamProblemStatusEnum;
  readonly " $fragmentType": "SchoolExamProblemStatusLabel_schoolExamProblem";
};
export type SchoolExamProblemStatusLabel_schoolExamProblem$key = {
  readonly " $data"?: SchoolExamProblemStatusLabel_schoolExamProblem$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamProblemStatusLabel_schoolExamProblem">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamProblemStatusLabel_schoolExamProblem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "SchoolExamProblem",
  "abstractKey": null
};

(node as any).hash = "58f16c25272be20719c7bc975ca45b12";

export default node;
