/**
 * @generated SignedSource<<d3a8ba5f2a7e66544fa3e84aedfb9962>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery$variables = {
  id: string;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery$data = {
  readonly generatedProblem: {
    readonly answer: string | null;
    readonly hint: string | null;
    readonly id: string;
    readonly problem: string;
    readonly solution: string;
    readonly submitGeneral: string | null;
    readonly submitSpecial: string | null;
    readonly submitSpecialAnswer: string | null;
  };
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery = {
  response: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery$data;
  variables: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "GeneratedProblem",
    "kind": "LinkedField",
    "name": "generatedProblem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problem",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "solution",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "answer",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hint",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submitSpecialAnswer",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submitSpecial",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "submitGeneral",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3ecdef2f0ff5f869462d264a75e5c374",
    "id": null,
    "metadata": {},
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery",
    "operationKind": "query",
    "text": "query ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery(\n  $id: ID!\n) {\n  generatedProblem(id: $id) {\n    id\n    problem\n    solution\n    answer\n    hint\n    submitSpecialAnswer\n    submitSpecial\n    submitGeneral\n  }\n}\n"
  }
};
})();

(node as any).hash = "209fcf485ee3b14df8fa88cba4ddba2f";

export default node;
