/**
 * @generated SignedSource<<a0a31c87b20175f42691d6d0e1aafc8a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type KatexEditor_meQuery$variables = {};
export type KatexEditor_meQuery$data = {
  readonly me: {
    readonly shortcuts: ReadonlyArray<{
      readonly label: string;
      readonly text: string;
    }>;
  };
};
export type KatexEditor_meQuery = {
  response: KatexEditor_meQuery$data;
  variables: KatexEditor_meQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "KatexEditor_meQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shortcut",
            "kind": "LinkedField",
            "name": "shortcuts",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "KatexEditor_meQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shortcut",
            "kind": "LinkedField",
            "name": "shortcuts",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b111d6774a261469ebd7704ee80d080f",
    "id": null,
    "metadata": {},
    "name": "KatexEditor_meQuery",
    "operationKind": "query",
    "text": "query KatexEditor_meQuery {\n  me {\n    shortcuts {\n      text\n      label\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "db782ef6464123133a8dca96941b0878";

export default node;
