import { format } from 'date-fns';
import { graphql, useFragment } from 'react-relay';

import { PremiumUpdateDialog_premium$key } from '../../../relay/__generated__/PremiumUpdateDialog_premium.graphql';
import { PremiumUpdateDialog_premiumUpdateMutation } from '../../../relay/__generated__/PremiumUpdateDialog_premiumUpdateMutation.graphql';
import Button from '../../core/Button';
import DateField from '../../core/DateField';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';

const PremiumUpdateDialog_premium = graphql`
  fragment PremiumUpdateDialog_premium on MATPremiumApplyHistory {
    id
    startAt
    endAt
  }
`;

type Props = {
  premium: PremiumUpdateDialog_premium$key;
} & DialogProps &
  Pick<MutationFormikProps<PremiumUpdateDialog_premiumUpdateMutation>, 'config' | 'onSubmit'>;

const PremiumUpdateDialog = ({ premium: premiumReference, onSubmit, config, ...props }: Props) => {
  const premium = useFragment(PremiumUpdateDialog_premium, premiumReference);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>이용권 기간 변경하기</Text>
      </Dialog.Header>
      <MutationFormik<PremiumUpdateDialog_premiumUpdateMutation>
        mutation={graphql`
          mutation PremiumUpdateDialog_premiumUpdateMutation($input: MATInternalPremiumApplyHistoryUpdateInput!) {
            internalPremiumApplyHistoryUpdate(input: $input) {
              id
              startAt
              endAt
              created
              item {
                id
                name
                premiumType
              }
              user {
                id
              }
            }
          }
        `}
        initialValues={{
          id: premium.id,
          startAt: premium.startAt,
          endAt: premium.endAt,
        }}
        onSubmit={onSubmit}
        config={config}
      >
        {({ submitForm, setFieldValue, dirty }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <DateField
                  label={'시작일'}
                  name={'startAt'}
                  onChange={(value) => setFieldValue('endAt', format(value, 'yyyy-MM-dd'))}
                  required
                />
                <DateField
                  label={'종료일'}
                  name={'endAt'}
                  onChange={(value) => setFieldValue('endAt', format(value, 'yyyy-MM-dd'))}
                  required
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button onClick={submitForm} disabled={!dirty}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default PremiumUpdateDialog;
