import TextInput, { TextInputProps } from '../TextInput';

type Props = { value?: string } & Omit<TextInputProps, 'type' | 'block' | 'value'>;

const TimePicker = ({ ...props }: Props) => {
  return (
    <>
      <TextInput
        type={'time'}
        step={2}
        block
        {...props}
        sx={{
          '& > input': {
            paddingRight: 1,
          },
          'input[type="time"]::-webkit-calendar-picker-indicator': {
            'width': 20,
            'height': 20,
            ':hover': {
              backgroundColor: 'canvas.inset',
              borderRadius: 2,
            },
          },
          ...(props?.sx ?? {}),
        }}
      />
    </>
  );
};

export default TimePicker;
export type { Props as TimePickerProps };
