/**
 * @generated SignedSource<<4f8e4dd096b007cffe5d0a335b9b26a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type FeatureEnum = "ai_probability_test" | "ai_top_percent_test" | "app_dialog" | "app_push" | "bill" | "book" | "codepush_version" | "comment" | "delivery_order" | "error_report" | "formula" | "group" | "math_king_user" | "notice" | "organization" | "permission_setting" | "problem_charge_event" | "problem_generator" | "product" | "product_event" | "quiz" | "quiz_set" | "raffle" | "raffle_stuff" | "school" | "school_exam_problem" | "school_exam_scrap" | "school_exam_source" | "scrap" | "scrap_source" | "store_stuff" | "stuff" | "task" | "task_hint_feedback" | "task_solution_video" | "task_video" | "typing_script" | "unit" | "user" | "virtual_solved_problem" | "virtual_user" | "workbook" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CategoryTableItem_category$data = {
  readonly features: ReadonlyArray<{
    readonly id: string;
    readonly permissions: ReadonlyArray<{
      readonly id: string;
      readonly isActive: boolean;
      readonly label: string;
    }>;
    readonly title: FeatureEnum;
  }>;
  readonly id: string;
  readonly " $fragmentType": "CategoryTableItem_category";
};
export type CategoryTableItem_category$key = {
  readonly " $data"?: CategoryTableItem_category$data;
  readonly " $fragmentSpreads": FragmentRefs<"CategoryTableItem_category">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CategoryTableItem_category",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Feature",
      "kind": "LinkedField",
      "name": "features",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Permission",
          "kind": "LinkedField",
          "name": "permissions",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isActive",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Category",
  "abstractKey": null
};
})();

(node as any).hash = "096e18c43996964e0a6897cb5fbc9002";

export default node;
