import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  ProductConnectionDataTable_productConnection$data,
  ProductConnectionDataTable_productConnection$key,
} from '../../../relay/__generated__/ProductConnectionDataTable_productConnection.graphql';
import { numberWithCommas } from '../../../utils/number';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import ProductStatusLabel from '../ProductStatusLabel';

const ProductConnectionDataTable_productConnection = graphql`
  fragment ProductConnectionDataTable_productConnection on ProductConnection {
    edges {
      node {
        id
        ...ProductStatusLabel_product
        sequence
        title
        storeStuff {
          id
          title
          stuff {
            id
            title
          }
        }
        showTypes
        discountPrice
        createdBy {
          id
          ...UserAvatarText_user
        }
        modified
      }
    }
  }
`;

type Props = {
  productConnection: ProductConnectionDataTable_productConnection$key;
} & Omit<
  DataTableProps<ProductConnectionDataTable_productConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const ProductConnectionDataTable = ({ productConnection, ...props }: Props) => {
  const { edges } = useFragment(ProductConnectionDataTable_productConnection, productConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (product) => <ProductStatusLabel product={product} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'sequence',
          title: 'ID',
          renderValue: ({ sequence }) => <Text fontSize={1}>{sequence}</Text>,
          width: 80,
        },
        {
          field: 'title',
          title: '판매 상품명',
          renderValue: ({ title }) => <Text fontSize={1}>{title}</Text>,
          width: 236,
        },
        {
          field: 'storeStuff',
          title: '상품',
          renderValue: ({ storeStuff }) =>
            storeStuff ? (
              <Text fontSize={1}>{`${storeStuff.title}${storeStuff.stuff ? ` / ${storeStuff.stuff.title}` : ''}`}</Text>
            ) : (
              '-'
            ),
          width: 236,
        },
        {
          field: 'discountPrice',
          title: '판매가',
          renderValue: ({ discountPrice }) => <Text fontSize={1}>{numberWithCommas(discountPrice)}원</Text>,
          width: 120,
        },
        {
          field: 'showTypes',
          title: '타입',
          renderValue: ({ showTypes }) =>
            showTypes.length === 0
              ? ' - '
              : showTypes.map((type, index) => (
                  <Text key={type} fontSize={1}>
                    {index === 0 ? '' : ', '}
                    <EnumPair typename={'ProductShowTypeEnum'}>{type}</EnumPair>
                  </Text>
                )),
          width: 193,
        },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 92,
        },
        {
          field: 'modified',
          title: '수정일',
          renderValue: ({ modified }) => <DateText fontSize={1}>{modified}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default ProductConnectionDataTable;
