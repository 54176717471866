/**
 * @generated SignedSource<<74cdd874626c52ca6c0d73dcae526987>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type QuizAnswerTypeEnum = "O" | "X" | "%future added value";
export type QuizCreateInput = {
  answer: QuizAnswerTypeEnum;
  description?: string | null;
  problem: EditorInput;
  solution: EditorInput;
  tip: EditorInput;
  unitD: NodeInput;
};
export type EditorInput = {
  text: string;
  textHtml?: string | null;
};
export type NodeInput = {
  id: string;
};
export type QuizCreateDialog_quizCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: QuizCreateInput;
};
export type QuizCreateDialog_quizCreateMutation$data = {
  readonly quizCreate: {
    readonly id: string;
  };
};
export type QuizCreateDialog_quizCreateMutation = {
  response: QuizCreateDialog_quizCreateMutation$data;
  variables: QuizCreateDialog_quizCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "connections"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": (v1/*: any*/),
  "concreteType": "Quiz",
  "kind": "LinkedField",
  "name": "quizCreate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizCreateDialog_quizCreateMutation",
    "selections": [
      (v2/*: any*/)
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuizCreateDialog_quizCreateMutation",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": null,
        "handle": "appendNode",
        "key": "",
        "kind": "LinkedHandle",
        "name": "quizCreate",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          },
          {
            "kind": "Literal",
            "name": "edgeTypeName",
            "value": "QuizEdge"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "944c97f0f1ef5ce05be8222261da8c8d",
    "id": null,
    "metadata": {},
    "name": "QuizCreateDialog_quizCreateMutation",
    "operationKind": "mutation",
    "text": "mutation QuizCreateDialog_quizCreateMutation(\n  $input: QuizCreateInput!\n) {\n  quizCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1b144abc16ec2359b59af2586646d98f";

export default node;
