/**
 * @generated SignedSource<<25199fe41be6d46b7712799710fc7ed9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo$data = {
  readonly correctRate: number;
  readonly totalCount: number;
  readonly " $fragmentType": "TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo";
};
export type TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo$key = {
  readonly " $data"?: TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "correctRate",
      "storageKey": null
    }
  ],
  "type": "TaskProblemSolveInfo",
  "abstractKey": null
};

(node as any).hash = "22d1de7422bea5f905b2f8d6f13de441";

export default node;
