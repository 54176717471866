import { FormControl as PrimerFormControl } from '@primer/react';
import React from 'react';
import { ComponentProps } from 'react';

type PrimerFormControlProps = ComponentProps<typeof PrimerFormControl>;
type Props = {} & PrimerFormControlProps;

const FormControl = ({ ...props }: Props, ref: React.Ref<HTMLDivElement>) => <PrimerFormControl ref={ref} {...props} />;

export default Object.assign(React.forwardRef(FormControl), {
  Label: PrimerFormControl.Label,
  Caption: PrimerFormControl.Caption,
  Validation: PrimerFormControl.Validation,
  LeadingVisual: PrimerFormControl.LeadingVisual,
});
export type { Props as FormControlProps };
