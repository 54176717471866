import { graphql, useFragment } from 'react-relay';

import {
  StoreStuffDescriptionList_storeStuff$data,
  StoreStuffDescriptionList_storeStuff$key,
} from '../../../relay/__generated__/StoreStuffDescriptionList_storeStuff.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';

const StoreStuffDescriptionList_storeStuff = graphql`
  fragment StoreStuffDescriptionList_storeStuff on StoreStuff {
    id
    type
    period
    endAt
    stuff {
      id
      title
    }
    created
    createdBy {
      ...UserAvatarText_user
    }
  }
`;

type Props = {
  storeStuff: StoreStuffDescriptionList_storeStuff$key;
  type?: 'default' | 'activity';
} & Omit<DescriptionListProps<StoreStuffDescriptionList_storeStuff$data>, 'item' | 'itemDescriptions' | 'picks'>;
const StoreStuffDescriptionList = ({ storeStuff: storeStuffReference, type = 'default', ...props }: Props) => {
  const storeStuff = useFragment(StoreStuffDescriptionList_storeStuff, storeStuffReference);

  return (
    <DescriptionList
      item={storeStuff}
      itemDescriptions={{
        activeTime: {
          title: '부여일',
          renderValue: ({ endAt, period }) =>
            endAt ? (
              <DateText fontSize={1} format={'P'}>
                {endAt}
              </DateText>
            ) : period ? (
              <Text fontSize={1}>{period}일</Text>
            ) : undefined,
        },
        type: {
          title: '타입',
          renderValue: ({ type }) => (
            <Text fontSize={1}>
              <EnumPair typename={'StoreStuffTypeEnum'}>{type}</EnumPair>
            </Text>
          ),
        },
        stuff: {
          title: '물품',
          renderValue: ({ stuff }) => (stuff ? <Text fontSize={1}>{stuff.title}</Text> : undefined),
        },
        created: {
          title: '제작일',
          renderValue: ({ created }) => <DateText fontSize={1}>{created}</DateText>,
        },
        createdBy: {
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
        },
      }}
      picks={type === 'default' ? ['type', 'activeTime', 'stuff'] : type === 'activity' ? ['createdBy', 'created'] : []}
      {...props}
    />
  );
};

export default StoreStuffDescriptionList;
