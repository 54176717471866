import { graphql, useFragment } from 'react-relay';

import { AppDialogImageAndTextTypePreviewItem_appDialog$key } from '../../../relay/__generated__/AppDialogImageAndTextTypePreviewItem_appDialog.graphql';
import AppDialogImageAndTextTypePreview from '../AppDialogImageAndTextTypePreview';

const AppDialogImageAndTextTypePreviewItem_appDialog = graphql`
  fragment AppDialogImageAndTextTypePreviewItem_appDialog on AppDialog {
    appDialogImage {
      id
      objectUrl
      size
    }
    appDialogTitle
    appDialogBody
    appDialogButton
  }
`;

type Props = {
  appDialog: AppDialogImageAndTextTypePreviewItem_appDialog$key;
};
const AppDialogImageAndTextTypePreviewItem = ({ appDialog }: Props) => {
  const { appDialogBody, appDialogButton, appDialogImage, appDialogTitle } = useFragment(
    AppDialogImageAndTextTypePreviewItem_appDialog,
    appDialog,
  );
  return (
    <AppDialogImageAndTextTypePreview
      appDialogImage={appDialogImage?.objectUrl}
      appDialogTitle={appDialogTitle || ''}
      appDialogBody={appDialogBody || ''}
      appDialogButton={appDialogButton || ''}
    />
  );
};
export default AppDialogImageAndTextTypePreviewItem;
