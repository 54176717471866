/**
 * @generated SignedSource<<ac12c3a99976e5c02692db6fd95c9b7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceHistoryDialog_SchoolExamSourceHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly name: string;
    readonly picture: string | null;
  };
  readonly difference: {
    readonly __typename: string;
    readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourcePublishDifferenceCounterLabel_difference" | "SchoolExamSourcePublishDifferenceItemList_difference" | "SchoolExamSourceUploadDifferenceCounterLabel_difference" | "SchoolExamSourceUploadDifferenceItemList_difference">;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "SchoolExamSourceHistoryDialog_SchoolExamSourceHistory";
};
export type SchoolExamSourceHistoryDialog_SchoolExamSourceHistory$key = {
  readonly " $data"?: SchoolExamSourceHistoryDialog_SchoolExamSourceHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceHistoryDialog_SchoolExamSourceHistory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceHistoryDialog_SchoolExamSourceHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picture",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "difference",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SchoolExamSourcePublishDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SchoolExamSourcePublishDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SchoolExamSourceUploadDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SchoolExamSourceUploadDifferenceItemList_difference"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SchoolExamSourceHistory",
  "abstractKey": null
};

(node as any).hash = "12636350ad1670772d3358ff824935c9";

export default node;
