import { CheckIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { SchoolExamProblemRejectDialog_schoolExamProblem$key } from '../../../relay/__generated__/SchoolExamProblemRejectDialog_schoolExamProblem.graphql';
import { SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation } from '../../../relay/__generated__/SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';

const SchoolExamProblemRejectDialog_schoolExamProblem = graphql`
  fragment SchoolExamProblemRejectDialog_schoolExamProblem on SchoolExamProblem {
    id
  }
`;

type Props = {
  schoolExamProblem: SchoolExamProblemRejectDialog_schoolExamProblem$key;
} & DialogProps &
  Pick<
    MutationFormikProps<SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const SchoolExamProblemRejectDialog = ({ schoolExamProblem, config, onSubmit, connections, ...props }: Props) => {
  const { id } = useFragment(SchoolExamProblemRejectDialog_schoolExamProblem, schoolExamProblem);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>반려하기</Text>
        <Text sx={{ fontSize: 0, color: 'fg.muted' }}>1개 선택 후, ‘반려하기’ 버튼을 선택해 주세요</Text>
      </Dialog.Header>
      <MutationFormik<SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation>
        mutation={graphql`
          mutation SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation(
            $input: SchoolExamProblemRejectInput!
          ) {
            schoolExamProblemReject(input: $input) {
              id
              ...SchoolExamProblemStatusLabel_schoolExamProblem
              ...SchoolExamProblemActionsMutationButtonStack_schoolExamProblem
            }
          }
        `}
        initialValues={{
          id,
          reason: 'others',
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <Suspense fallback={<Spinner />}>
                <EnumPairRadioGroupField
                  typename={'SchoolExamProblemReasonEnum'}
                  label={'반려 이유'}
                  name={'reason'}
                  required
                />
              </Suspense>
            </Dialog.Body>
            <Dialog.Footer>
              <Button onClick={() => handleSubmit()} variant={'primary'} leadingIcon={CheckIcon}>
                반려하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default SchoolExamProblemRejectDialog;
