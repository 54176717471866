/**
 * @generated SignedSource<<fe6b48d1edd04af77b6ce8980fe929e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductActionEnum = "product_activate" | "product_deactivate" | "product_delete" | "product_update" | "%future added value";
export type ProductDeactivateInput = {
  id: string;
};
export type ProductId_productDeactivateMutation$variables = {
  input: ProductDeactivateInput;
};
export type ProductId_productDeactivateMutation$data = {
  readonly productDeactivate: {
    readonly actions: ReadonlyArray<ProductActionEnum>;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProductStatusLabel_product">;
  };
};
export type ProductId_productDeactivateMutation = {
  response: ProductId_productDeactivateMutation$data;
  variables: ProductId_productDeactivateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductId_productDeactivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "productDeactivate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductStatusLabel_product"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductId_productDeactivateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "productDeactivate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b98fff7d30a37512be90f36b7fb37d33",
    "id": null,
    "metadata": {},
    "name": "ProductId_productDeactivateMutation",
    "operationKind": "mutation",
    "text": "mutation ProductId_productDeactivateMutation(\n  $input: ProductDeactivateInput!\n) {\n  productDeactivate(input: $input) {\n    id\n    actions\n    ...ProductStatusLabel_product\n  }\n}\n\nfragment ProductStatusLabel_product on Product {\n  status\n}\n"
  }
};
})();

(node as any).hash = "4dffbe8cab3b4e185113334498814022";

export default node;
