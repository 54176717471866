import { CheckIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql } from 'react-relay';

import { NoticeCreateDialog_noticeCreateMutation } from '../../../relay/__generated__/NoticeCreateDialog_noticeCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MarkdownEditorField from '../../core/MarkdownEditorField';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

type Props = {} & DialogProps &
  Pick<MutationFormikProps<NoticeCreateDialog_noticeCreateMutation>, 'config' | 'onSubmit' | 'connections'>;

const NoticeCreateDialog = ({ config, onSubmit, connections, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>공지사항 작성하기</Text>
      </Dialog.Header>
      <MutationFormik<NoticeCreateDialog_noticeCreateMutation>
        mutation={graphql`
          mutation NoticeCreateDialog_noticeCreateMutation($input: NoticeCreateInput!) {
            noticeCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{
          title: '',
          description: '',
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField name={'title'} label={'제목'} placeholder={'제목 입력'} required />
                <MarkdownEditorField
                  name={'description'}
                  label={'본문'}
                  placeholder={'본문 입력'}
                  required
                  withTogglePreviewTokenButton
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button leadingIcon={CheckIcon} variant={'primary'} onClick={() => handleSubmit()}>
                작성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default NoticeCreateDialog;
