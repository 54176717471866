import { CheckIcon, PencilIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { Suspense, useRef } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import Checkbox from '../../components/core/Checkbox';
import CheckboxGroupField from '../../components/core/CheckboxGroupField';
import ConfirmButton from '../../components/core/ConfirmButton';
import Dialog, { DialogProps } from '../../components/core/Dialog';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPair from '../../components/core/EnumPair';
import { FormikProps } from '../../components/core/Formik';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import ItemList from '../../components/core/ItemList';
import Katex from '../../components/core/Katex';
import Label from '../../components/core/Label';
import LabelGroup from '../../components/core/LabelGroup';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationFormik from '../../components/core/MutationFormik';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import QuizPaginator from '../../components/quiz/QuizPaginator';
import QuizQuizSetItem from '../../components/quiz/QuizQuizSetItem';
import QuizSetStatusLabel from '../../components/quizSet/QuizSetStatusLabel';
import { useHeaderSidebarLayoutContext } from '../../contexts/HeaderSidebarLayoutContext';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useResumableFormik from '../../hooks/useResumableFormik';
import useToast from '../../hooks/useToast';
import { QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery } from '../../relay/__generated__/QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery.graphql';
import {
  QuizSetId_quizSetLabelingMutation,
  QuizSetLabelingInput,
} from '../../relay/__generated__/QuizSetId_quizSetLabelingMutation.graphql';
import { QuizSetId_quizSetQuery } from '../../relay/__generated__/QuizSetId_quizSetQuery.graphql';
import { QuizSetId_quizzesQuery } from '../../relay/__generated__/QuizSetId_quizzesQuery.graphql';
import { NodeInput } from '../../types/gql';
import { NextPage } from '../_app';

const quizSetForQuizSetId = graphql`
  query QuizSetId_quizSetQuery($id: ID!) {
    quizSet(id: $id) {
      id
      unitB {
        id
        title
      }
      unitC {
        id
        title
      }
      quizzes(first: 10) {
        edges {
          node {
            id
          }
        }
      }
      status
      ...QuizSetStatusLabel_quizSet
    }
  }
`;

const quizzesForQuizSetId = graphql`
  query QuizSetId_quizzesQuery($filters: QuizFilter, $order: QuizOrder, $first: Int, $after: String) {
    ...QuizPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

const QUIZZES_COUNT = 10;

const QuizSetId: NextPage = () => {
  const router = useRouter();
  const [{ quizSet }] = useLazyLoadQuery<QuizSetId_quizSetQuery>(quizSetForQuizSetId, {
    id: router.query.quizSetId as string,
  });
  const { toast } = useToast();
  const handleSubmitQuizSet = () => toast('맛보기 지정 완료!', 'success');
  const handleUpdateQuizSet = () => toast('맛보기 수정 완료!', 'success');

  const { headerHeight } = useHeaderSidebarLayoutContext();

  const mutationFormikStaticValues = {
    quizSet: { id: quizSet.id },
  };
  const mutationFormikDefaultValues: { quizzes: NodeInput[] } = {
    quizzes: quizSet.quizzes.edges.map(({ node }) => ({ id: node.id })),
  };
  const mutationFormikInitialValues = {
    ...mutationFormikDefaultValues,
    ...mutationFormikStaticValues,
  };

  const mutationFormikRef = useRef<FormikProps<any>>(null);
  const { stopRestore } = useResumableFormik(mutationFormikRef);

  return (
    <View>
      <Head siteTitle={`${quizSet.unitC.title} 맛보기 지정`} />
      <MutationFormik<QuizSetId_quizSetLabelingMutation>
        innerRef={mutationFormikRef}
        mutation={graphql`
          mutation QuizSetId_quizSetLabelingMutation($input: QuizSetLabelingInput!) {
            quizSetLabeling(input: $input) {
              id
            }
          }
        `}
        initialValues={mutationFormikInitialValues}
      >
        {({
          values: mutationFormikValues,
          handleSubmit: handleMutationFormikSubmit,
          setFieldValue: setMutationFormikFieldValue,
          dirty: mutationFormikDirty,
          resetForm,
        }) => (
          <QueryFormik<QuizSetId_quizzesQuery>
            query={quizzesForQuizSetId}
            staticVariables={{ first: 10 }}
            initialValues={{ filters: { unitCId_In: [quizSet.unitC.id], status_Exact: 'published' } }}
            options={{ fetchPolicy: 'store-and-network' }}
          >
            {({ handleSubmit: handleQueryFormikSubmit, setFieldValue: setQueryFormikFieldValue }) => (
              <View>
                <View
                  sx={{
                    position: 'sticky',
                    top: `${headerHeight}px`,
                    zIndex: 1,
                    backgroundColor: 'canvas.default',
                  }}
                >
                  <Grid sx={{ alignItems: 'center' }} gapX={2}>
                    <Grid.Unit size={'max'}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Text as={'h1'}>맛보기 지정</Text>
                        </Stack.Item>
                        <Stack.Item>
                          <LabelGroup>
                            <Label variant={'accent'}>{`${quizSet.unitB.title} / ${quizSet.unitC.title}`}</Label>
                            <QuizSetStatusLabel quizSet={quizSet} />
                          </LabelGroup>
                        </Stack.Item>
                      </Stack>
                    </Grid.Unit>
                    <Grid.Unit size={'min'}>
                      <DialogButton
                        variant={'primary'}
                        leadingIcon={PencilIcon}
                        disabled={mutationFormikValues.quizzes.length !== QUIZZES_COUNT || !mutationFormikDirty}
                        renderDialog={({ isOpen, closeDialog }) => (
                          <Suspense>
                            <QuizSetLabelingInputConfirmDialog
                              quizSetLabelingInput={mutationFormikValues}
                              isOpen={isOpen}
                              onDismiss={closeDialog}
                              onConfirmClick={() => {
                                stopRestore();
                                handleMutationFormikSubmit();
                                resetForm({ values: mutationFormikValues });
                                closeDialog();
                                if (quizSet.status === 'published') handleUpdateQuizSet();
                                else handleSubmitQuizSet();
                              }}
                            />
                          </Suspense>
                        )}
                      >
                        지정하기
                      </DialogButton>
                    </Grid.Unit>
                  </Grid>
                  <HorizontalDivider />
                  <View sx={{ marginTop: 3 }}>
                    <Grid sx={{ alignItems: 'center' }}>
                      <Grid.Unit size={'max'}>
                        <CheckboxGroupField
                          label={'정답'}
                          labelConfig={{ visuallyHidden: true }}
                          name={'filters.answer_In'}
                          options={[
                            { id: 'O', text: '정답 O만 보기' },
                            { id: 'X', text: '정답 X만 보기' },
                          ]}
                          onChange={(selected) => {
                            setQueryFormikFieldValue('filters.answer_In', selected.length > 0 ? selected : undefined);
                            setTimeout(() => handleQueryFormikSubmit(), 0);
                          }}
                          renderContainer={(children) => <Stack gapX={3}>{children}</Stack>}
                          renderOptionWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={'min'}>
                        <Stack gapX={2}>
                          <Stack.Item>
                            <Button
                              variant={'invisible'}
                              onClick={() => resetForm({ values: { ...mutationFormikInitialValues, quizzes: [] } })}
                            >
                              전체 해제
                            </Button>
                          </Stack.Item>
                          <Stack.Item>
                            <Text sx={{ fontSize: 1 }}>
                              {`${mutationFormikValues.quizzes.length} `}
                              <Text sx={{ color: 'fg.muted' }}>{`/ ${QUIZZES_COUNT}`}</Text>
                            </Text>
                          </Stack.Item>
                        </Stack>
                      </Grid.Unit>
                    </Grid>
                  </View>
                  <HorizontalDivider mt={3} />
                </View>
                <View sx={{ marginTop: 6 }}>
                  <Suspense fallback={<Spinner />}>
                    <QueryFormik.PreloadedQueryRenderer<QuizSetId_quizzesQuery>>
                      {(queryReference) => (
                        <QuizPaginator fragmentReference={queryReference}>
                          {(data, { hasNext, isLoadingNext, loadMore }) => (
                            <>
                              <ItemList
                                items={data.quizzes.edges}
                                renderItem={({ node }) => <QuizQuizSetItem quiz={node} />}
                                renderItemWrapper={(children, { node: { id } }, i) => (
                                  <View key={id} sx={{ marginTop: i > 0 ? 9 : 0 }}>
                                    <Grid gapX={2} wrap={false}>
                                      <Grid.Unit size={'min'}>
                                        <Checkbox
                                          checked={mutationFormikValues.quizzes.some(
                                            (selectedQuiz) => selectedQuiz.id === id,
                                          )}
                                          onChange={() => {
                                            const selectedQuizzes = mutationFormikValues.quizzes;
                                            if (selectedQuizzes.some((selectedQuiz) => selectedQuiz.id === id)) {
                                              setMutationFormikFieldValue(
                                                'quizzes',
                                                selectedQuizzes.filter((selectedQuiz) => selectedQuiz.id !== id),
                                              );
                                            } else {
                                              setMutationFormikFieldValue('quizzes', [
                                                ...mutationFormikValues.quizzes,
                                                { id },
                                              ]);
                                            }
                                          }}
                                        />
                                      </Grid.Unit>
                                      <Grid.Unit size={'max'}>{children}</Grid.Unit>
                                    </Grid>
                                  </View>
                                )}
                                emptyState={
                                  <EmptyState
                                    title={'현재 Unit에 포함되어있는 출시된 OX 퀴즈가 없어요.'}
                                    description={'OX 퀴즈를 출시하고 다시 시도해주세요.'}
                                  />
                                }
                              />
                              {hasNext ? (
                                <Button
                                  sx={{ width: '100%', marginTop: 2 }}
                                  disabled={isLoadingNext}
                                  onClick={() => loadMore(10)}
                                >
                                  더보기
                                </Button>
                              ) : null}
                            </>
                          )}
                        </QuizPaginator>
                      )}
                    </QueryFormik.PreloadedQueryRenderer>
                  </Suspense>
                </View>
              </View>
            )}
          </QueryFormik>
        )}
      </MutationFormik>
    </View>
  );
};

type Props = {
  quizSetLabelingInput: QuizSetLabelingInput;
  onConfirmClick: () => void;
} & DialogProps;

const QuizSetLabelingInputConfirmDialog = ({ quizSetLabelingInput, onConfirmClick, ...props }: Props) => {
  const [{ quizzes }] = useLazyLoadQuery<QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery>(
    graphql`
      query QuizSetId_QuizSetLabelingInputConfirmDialog_quizzesQuery($filters: QuizFilter, $first: Int) {
        quizzes(filters: $filters, first: $first) {
          edges {
            node {
              id
              unitD {
                id
                title
              }
              answer
              problem
            }
          }
        }
      }
    `,
    { filters: { id_In: quizSetLabelingInput.quizzes.map(({ id }) => id) }, first: 10 },
  );

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>지정하기</Text>
        <View>
          <Text sx={{ fontSize: 0, color: 'fg.muted' }}>지정이 완료됐다면 아래 ‘출시하기’ 버튼을 클릭해 주세요</Text>
        </View>
      </Dialog.Header>
      <Dialog.Body>
        <ItemList
          items={quizzes.edges}
          renderItem={({ node: { id, unitD, answer, problem } }, i) => (
            <View key={id}>
              <Grid gapX={1}>
                <Grid.Unit size={'min'}>
                  <Text sx={{ fontSize: 2, color: 'fg.muted', fontWeight: 'bold' }}>{i + 1}</Text>
                </Grid.Unit>
                <Grid.Unit size={'max'}>
                  <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>{unitD.title}</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Text sx={{ fontSize: 2, fontWeight: 'bold' }}>
                    정답{' '}
                    <Text sx={{ color: answer === 'O' ? 'success.fg' : 'danger.fg' }}>
                      <EnumPair typename={'QuizAnswerTypeEnum'}>{answer}</EnumPair>
                    </Text>
                  </Text>
                </Grid.Unit>
              </Grid>
              <View
                sx={{
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderColor: 'border.default',
                  borderRadius: 2,
                  overflowX: 'hidden',
                  overflowY: 'hidden',
                  maxWidth: '100%',
                  marginTop: 2,
                  minHeight: 200,
                }}
              >
                <Katex>{problem}</Katex>
              </View>
            </View>
          )}
          renderItemWrapper={(children, { node: { id } }, i) => (
            <View key={id} sx={{ marginTop: i > 0 ? 6 : 0 }}>
              {children}
            </View>
          )}
        />
      </Dialog.Body>
      <Dialog.Footer>
        <ConfirmButton
          variant={'primary'}
          onClick={() => onConfirmClick()}
          leadingIcon={CheckIcon}
          message={'OX 퀴즈 맛보기 지정은 수학대왕에 바로 반영되지 않아요. 수정 후 제품개발팀에 문의해주세요.'}
        >
          출시하기
        </ConfirmButton>
      </Dialog.Footer>
    </Dialog>
  );
};

QuizSetId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
QuizSetId.authenticated = true;
QuizSetId.routes = [
  { id: 'quizSetId', pathname: '/quizSet/[quizSetId]', name: 'OX 퀴즈 맛보기 상세', permissions: ['quiz_set_read'] },
];

export default QuizSetId;
