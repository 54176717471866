import { KebabHorizontalIcon, PencilIcon, TrashIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { graphql } from 'react-relay';

import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Card from '../../components/core/Card';
import DialogButton from '../../components/core/DialogButton';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationButton from '../../components/core/MutationButton';
import MutationConfirmButton from '../../components/core/MutationConfirmButton';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import ProductDescriptionList from '../../components/product/ProductDescriptionList';
import ProductUpdateDialog from '../../components/product/ProductUpdateDialog';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { ProductId_productDeleteMutation } from '../../relay/__generated__/ProductId_productDeleteMutation.graphql';
import { ProductId_productQuery } from '../../relay/__generated__/ProductId_productQuery.graphql';
import { NextPage } from '../_app';

const productForProductId = graphql`
  query ProductId_productQuery($id: ID!) {
    product(id: $id) {
      id
      actions
      title
      ...ProductDescriptionList_product
      ...ProductUpdateDialog_product
    }
  }
`;

type Props = {};

const ProductId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ product }] = useLazyLoadQuery<ProductId_productQuery>(
    productForProductId,
    {
      id: router.query.productId as string,
    },
    { fetchPolicy: 'store-and-network' },
  );
  if (!product) return null;
  const { id, actions, title } = product;

  return (
    <View>
      <Head siteTitle={`판매 상품 상세 - ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h2'}>{title}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              {actions.includes('product_update') ? (
                <Stack.Item>
                  <DialogButton
                    renderDialog={({ isOpen, closeDialog }) => (
                      <ProductUpdateDialog
                        product={product}
                        wide
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        config={{
                          onCompleted: () => {
                            toast('수정이 완료됐어요', 'success');
                            closeDialog();
                          },
                          onError: () => {
                            toast('다시 수정해 주세요', 'error');
                          },
                        }}
                      />
                    )}
                    variant={'outline'}
                    leadingIcon={PencilIcon}
                    size={'large'}
                  >
                    수정하기
                  </DialogButton>
                </Stack.Item>
              ) : null}
              {actions.includes('product_activate') ? (
                <Stack.Item>
                  <MutationConfirmButton
                    mutation={graphql`
                      mutation ProductId_productActivateMutation($input: ProductActivateInput!) {
                        productActivate(input: $input) {
                          id
                          actions
                          ...ProductStatusLabel_product
                        }
                      }
                    `}
                    input={{ id }}
                    size={'large'}
                    variant={'primary'}
                    message={'상품 정보 확인 후 활성화해주세요. 활성화할까요?'}
                    config={{
                      onCompleted: () => {
                        toast('활성화가 완료됐어요', 'success');
                      },
                      onError: () => {
                        toast('다시 활성화해주세요', 'error');
                      },
                    }}
                  >
                    활성화하기
                  </MutationConfirmButton>
                </Stack.Item>
              ) : null}
              {actions.includes('product_deactivate') ? (
                <Stack.Item>
                  <MutationButton
                    mutation={graphql`
                      mutation ProductId_productDeactivateMutation($input: ProductDeactivateInput!) {
                        productDeactivate(input: $input) {
                          id
                          actions
                          ...ProductStatusLabel_product
                        }
                      }
                    `}
                    input={{ id }}
                    size={'large'}
                    variant={'danger'}
                  >
                    비활성화하기
                  </MutationButton>
                </Stack.Item>
              ) : null}
              {actions.includes('product_delete') ? (
                <Stack.Item>
                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.MutationItem<ProductId_productDeleteMutation>
                          mutation={graphql`
                            mutation ProductId_productDeleteMutation($input: ProductDeleteInput!) {
                              productDelete(input: $input) {
                                id @deleteRecord
                              }
                            }
                          `}
                          input={{ id }}
                          variant={'danger'}
                          config={{
                            onCompleted: () => {
                              router.replace('/product').then(() => toast('판매 상품 삭제 완료!', 'success'));
                            },
                          }}
                          message={'정말 삭제할까요?'}
                        >
                          <ActionList.LeadingVisual>
                            <TrashIcon />
                          </ActionList.LeadingVisual>
                          삭제하기
                        </ActionList.MutationItem>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Stack.Item>
              ) : null}
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <Text as={'h3'}>판매 상품 정보</Text>
            <Card sx={{ padding: 3 }}>
              <ProductDescriptionList
                product={product}
                type={'default'}
                titleUnitSize={[1, 1, 1 / 5]}
                descriptionUnitSize={[1, 1, 4 / 5]}
              />
            </Card>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <ProductDescriptionList
              product={product}
              type={'activity'}
              titleUnitSize={'max'}
              descriptionUnitSize={'min'}
              renderTitle={(title) => <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>}
            />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

ProductId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
ProductId.authenticated = true;
ProductId.routes = [
  {
    id: 'productId',
    name: '판매 상품 상세',
    pathname: '/product/[productId]',
    permissions: ['product_read'],
  },
];

export default ProductId;
