/**
 * @generated SignedSource<<8d1bc7a786692d72ad010ec5b1b21d6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuffDeleteInput = {
  id: string;
};
export type StuffId_stuffDeleteMutation$variables = {
  input: StuffDeleteInput;
};
export type StuffId_stuffDeleteMutation$data = {
  readonly stuffDelete: {
    readonly id: string;
  };
};
export type StuffId_stuffDeleteMutation = {
  response: StuffId_stuffDeleteMutation$data;
  variables: StuffId_stuffDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StuffId_stuffDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stuff",
        "kind": "LinkedField",
        "name": "stuffDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StuffId_stuffDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stuff",
        "kind": "LinkedField",
        "name": "stuffDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7296cfc3e8b96e88b6bf4007d1b799ed",
    "id": null,
    "metadata": {},
    "name": "StuffId_stuffDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation StuffId_stuffDeleteMutation(\n  $input: StuffDeleteInput!\n) {\n  stuffDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "424af9edddeaba660b35affd3045782f";

export default node;
