import { FieldConfig, useField } from 'formik';
import { debounce } from 'lodash-es';
import { ComponentProps, useEffect, useRef } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import Textarea, { TextareaProps } from '../Textarea';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
  debounce?: boolean;
} & TextareaProps &
  Pick<FieldConfig, 'validate'>;

const TextareaField = ({
  label,
  labelConfig,
  caption,
  disabled: propDisabled,
  required,
  name = '',
  onChange: propOnChange,
  value: propValue,
  debounce: propDebounce = false,
  validate,
  ...props
}: Props) => {
  const inputId = `textarea_field_for_${label}`;

  const [{ value: baseValue, onChange, ...restFieldProps }, { error }, { setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 입력해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();

  const handleChange: TextareaProps['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else onChange(...arg);
  };
  const value = isNullable(propValue) ? baseValue : propValue;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.value = value || '';
    }
  }, [value]);

  const disabled = propDisabled || isSubmitting;

  return (
    <FormControl disabled={disabled} required={required}>
      <FormControl.Label {...labelConfig} htmlFor={inputId}>
        {label}
      </FormControl.Label>
      <Textarea
        id={inputId}
        ref={textAreaRef}
        block
        onChange={propDebounce ? debounce(handleChange, 500) : handleChange}
        disabled={disabled}
        validationStatus={error ? 'error' : undefined}
        {...restFieldProps}
        {...props}
      />
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default TextareaField;
