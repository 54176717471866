/**
 * @generated SignedSource<<ee2591a31fd8356907325b663ca8273e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TranscodingStatusEnum = "failed" | "ongoing" | "pending" | "success" | "%future added value";
export type VideoTypeEnum = "horizontal" | "vertical" | "%future added value";
export type TaskVideoFilter = {
  AND?: TaskVideoFilter | null;
  DISTINCT?: boolean | null;
  NOT?: TaskVideoFilter | null;
  OR?: TaskVideoFilter | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  taskId_Exact?: string | null;
};
export type TaskVideoSelectField_taskVideosQuery$variables = {
  filters?: TaskVideoFilter | null;
  first?: number | null;
};
export type TaskVideoSelectField_taskVideosQuery$data = {
  readonly taskVideos: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly created: any;
        readonly id: string;
        readonly teacher: {
          readonly id: string;
          readonly name: string;
        };
        readonly title: string;
        readonly type: VideoTypeEnum;
        readonly video: {
          readonly id: string;
          readonly transcodingStatus: TranscodingStatusEnum;
        };
      };
    }>;
  };
};
export type TaskVideoSelectField_taskVideosQuery = {
  response: TaskVideoSelectField_taskVideosQuery$data;
  variables: TaskVideoSelectField_taskVideosQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "filters",
  "variableName": "filters"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TaskVideoEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskVideo",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Video",
            "kind": "LinkedField",
            "name": "video",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transcodingStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Teacher",
            "kind": "LinkedField",
            "name": "teacher",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v4 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskVideoSelectField_taskVideosQuery",
    "selections": [
      {
        "alias": "taskVideos",
        "args": [
          (v1/*: any*/)
        ],
        "concreteType": "TaskVideoConnection",
        "kind": "LinkedField",
        "name": "__TaskVideoSelectFieldQuery_taskVideos_connection",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskVideoSelectField_taskVideosQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "TaskVideoConnection",
        "kind": "LinkedField",
        "name": "taskVideos",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "filters"
        ],
        "handle": "connection",
        "key": "TaskVideoSelectFieldQuery_taskVideos",
        "kind": "LinkedHandle",
        "name": "taskVideos"
      }
    ]
  },
  "params": {
    "cacheID": "c0993ffe41fa2b1210de56ce043e99bc",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": "first",
          "cursor": null,
          "direction": "forward",
          "path": [
            "taskVideos"
          ]
        }
      ]
    },
    "name": "TaskVideoSelectField_taskVideosQuery",
    "operationKind": "query",
    "text": "query TaskVideoSelectField_taskVideosQuery(\n  $filters: TaskVideoFilter\n  $first: Int\n) {\n  taskVideos(filters: $filters, first: $first) {\n    edges {\n      node {\n        id\n        title\n        video {\n          id\n          transcodingStatus\n        }\n        type\n        teacher {\n          id\n          name\n        }\n        created\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "99c5895e5d28f7c3130d76fa9ddb856d";

export default node;
