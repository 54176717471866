import { graphql, useFragment } from 'react-relay';

import { ProblemGeneratorDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/ProblemGeneratorDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const ProblemGeneratorDifferenceCounterLabel_difference = graphql`
  fragment ProblemGeneratorDifferenceCounterLabel_difference on Difference {
    ... on ProblemGeneratorDifference {
      #        problemGenerator 모델 수정 전 히스토리를 위해 남겨둡니다
      generatedCount {
        __typename
      }
      publishedProblemsCount {
        __typename
      }
    }
  }
`;

type Props = { difference: ProblemGeneratorDifferenceCounterLabel_difference$key } & CounterLabelProps;

const ProblemGeneratorDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { generatedCount, publishedProblemsCount } = useFragment(
    ProblemGeneratorDifferenceCounterLabel_difference,
    difference,
  );

  const differenceCount = [generatedCount, publishedProblemsCount].filter((diff) => !isNullable(diff)).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default ProblemGeneratorDifferenceCounterLabel;
