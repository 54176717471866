import React, { ReactElement } from 'react';
import { PropsWithChildren } from 'react';

import { isArray } from '../../../utils/is';
import Grid from '../Grid';
import { GridUnitProps } from '../Grid/Unit';
import Text from '../Text';
import View from '../View';

type Props = {
  title: string;
} & GridUnitProps;

const FilterGridUnit = ({ children: propChildren, title, ...props }: PropsWithChildren<Props>) => {
  const commonProps = { labelConfig: { visuallyHidden: true } };
  const children = isArray(propChildren)
    ? propChildren.map((child) => {
        const childElement = child as ReactElement;
        return React.cloneElement(childElement, {
          key: childElement.props.label,
          ...commonProps,
        });
      })
    : React.cloneElement(propChildren as ReactElement, { ...commonProps });

  return (
    <Grid.Unit {...props}>
      <View sx={{ display: 'flex', flexDirection: 'row', alignItems: 'stretch', height: '100%' }}>
        <View sx={{ width: '100px', minWidth: '100px', backgroundColor: 'canvas.subtle', padding: 2 }}>
          <Text sx={{ fontWeight: 'bold', color: 'fg.muted' }}>{title}</Text>
        </View>
        <View sx={{ display: 'flex', alignItems: 'center', flex: 1, overflowX: 'auto' }}>
          <View
            sx={{
              'flex': 1,
              '& > *': { flex: 1 },
              '& div:has(> label) > div > span': {
                border: 'none',
                borderRadius: 0,
                boxShadow: 'none',
              },
              '& div > span:has(> input)': {
                border: 'none',
                borderRadius: 0,
                boxShadow: 'none',
              },
              '& div:has(> label) > span': {
                border: 'none',
                borderRadius: 0,
                boxShadow: 'none',
              },
              '& > div + div': {
                borderTopWidth: 1,
                borderTopStyle: 'solid',
                borderTopColor: 'border.default',
              },
            }}
          >
            {children}
          </View>
        </View>
      </View>
    </Grid.Unit>
  );
};

export default FilterGridUnit;
