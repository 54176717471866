import { Icon, StopIcon } from '@primer/octicons-react';
import { difference } from 'lodash-es';
import { Router } from 'next/router';
import { PropsWithChildren } from 'react';
import { graphql } from 'react-relay';

import { routes } from '../../../../constants/routes';
import { MeContextProvider } from '../../../../contexts/MeContext';
import useLazyLoadQuery from '../../../../hooks/useLazyLoadQuery';
import {
  PermissionEnum,
  PermissionRoute_meQuery,
} from '../../../../relay/__generated__/PermissionRoute_meQuery.graphql';
import { isNullable } from '../../../../utils/is';
import ErrorState from '../../ErrorState';
import LinkButton from '../../LinkButton';
import View from '../../View';

const meForPermissionRoute = graphql`
  query PermissionRoute_meQuery {
    me {
      id
      name
      email
      permissions {
        id
        title
      }
    }
  }
`;

type Props = {
  router: Router;
};

type RouteType = {
  id: string;
  pathname: string;
  name: string;
  icon?: Icon;
  permissions?: PermissionEnum[];
};

const PermissionRoute = ({ router, children }: PropsWithChildren<Props>) => {
  const [{ me }] = useLazyLoadQuery<PermissionRoute_meQuery>(meForPermissionRoute, {});
  const { permissions } = me;
  const userPermissions = permissions.map(({ title }) => title);
  const currentRoutePermissions = routes.find(({ pathname }) => pathname === router.pathname)?.permissions;

  const isPermissionGranted = difference(currentRoutePermissions, userPermissions).length === 0;

  /*
   * for 면접
   * 배정된 업무 → 문제 상세인 경우에는 task_read 권한 없이도 접근 가능, 새로고침이나 직접 링크를 통해 접속할 땐 권한 필요
   * TODO fix
   */
  const isAssignedTaskDetail = router.pathname === '/task/[taskId]' && !isNullable(router.query.assignedToId_Exact);

  return !isPermissionGranted && !isAssignedTaskDetail ? (
    <View
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ErrorState icon={StopIcon} title={'페이지에 대한 권한이 없어요'} description={'관리자에게 문의해주세요'} />
      <View sx={{ marginTop: 3 }}>
        <LinkButton replace href={'/dashboard'} variant={'primary'} sx={{ marginX: 'auto' }}>
          대시보드로 이동
        </LinkButton>
      </View>
    </View>
  ) : (
    <MeContextProvider value={{ id: me.id, email: me.email, name: me.name }}>
      {children as JSX.Element}
    </MeContextProvider>
  );
};

export default PermissionRoute;
export type { RouteType };
