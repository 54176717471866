import { SxProp } from '@primer/react';
import { color, space, typography } from '@teamturing/token-studio';

const markdownSxWithTeamturingToken: SxProp['sx'] = {
  ...typography.xs,
  'fontWeight': 400,
  'color': color['text/neutral/subtle'],
  'h1': { color: color['text/neutral'], ...typography['xl/bold'], my: `${space[2]}px` },
  'h2': { color: color['text/neutral'], ...typography['l/bold'], my: `${space[2]}px` },
  'h3': { color: color['text/neutral'], ...typography['m/bold'], my: `${space[2]}px` },
  'h4': { color: color['text/neutral'], ...typography['m/bold'], my: `${space[2]}px` },
  'h5': { color: color['text/neutral'], ...typography['m/bold'], my: `${space[2]}px` },
  'h6': { color: color['text/neutral'], ...typography['m/bold'], my: `${space[2]}px` },

  'p': { m: 0, whiteSpace: 'pre-wrap' },
  'p + p': { mt: `${space[2]}px` },

  'a': { color: color['link'] },
  'ol, ul': {
    pl: '30px',
  },

  'hr': { height: '1px', border: 'none', backgroundColor: color['border/neutral'] },

  'mark': {
    backgroundColor: color['bg/accent/yellow/subtlest'],
  },

  '*:not(pre:has(code)) > code': {
    ...typography['xxs/regular'],
    backgroundColor: color['bg/neutral'],
    borderRadius: '3px',
    px: `${space[1]}px`,
    py: `${space[0.5]}px`,
  },
  'pre:has(code:only-child)': {
    ...typography['xxs/regular'],
    backgroundColor: color['bg/neutral'],
    borderRadius: '3px',
    px: `${space[1]}px`,
    py: `${space[0.5]}px`,
  },

  'blockquote': {
    marginLeft: 0,
    paddingLeft: `${space[4]}px`,
    borderLeftColor: color['bg/neutral/bold'],
    borderLeftWidth: '3px',
    borderLeftStyle: 'solid',
  },

  'img': {
    maxWidth: 610,
    width: '100%',
    my: `${space[6]}px`,
  },
  'li img': {
    verticalAlign: 'top',
    my: `${space[2]}px`,
  },
};

const markdownStyle: SxProp['sx'] = {
  'a': { color: 'accent.fg' },
  'p': { whiteSpace: 'pre-line' },
  'p, li': { fontSize: 1 },
  'mark': { color: 'fg.onEmphasis', backgroundColor: 'neutral.emphasis', borderRadius: 1, paddingX: 1 },
  '*:not(pre:has(code)) > code': {
    backgroundColor: 'neutral.muted',
    fontSize: 0,
    padding: 1,
    borderRadius: 2,
  },
  'pre:has(code:only-child)': {
    backgroundColor: 'neutral.muted',
    fontSize: 0,
    padding: 2,
  },
  'blockquote': {
    marginLeft: 0,
    paddingLeft: 6,
    borderLeftColor: 'neutral.emphasis',
    borderLeftWidth: 1,
    borderLeftStyle: 'solid',
  },
  'input[type="checkbox"]': {
    width: 'initial',
    height: 'initial',
    verticalAlign: 'middle',
    margin: 0,
    marginBottom: '3px',
  },
};

export { markdownStyle, markdownSxWithTeamturingToken };
