/**
 * @generated SignedSource<<35bd9b11f90f6cd11680964f1f5435f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SchoolExamScrapCreateInput = {
  isEnhance: boolean;
  order: number;
  schoolExamSource: string;
  scrap: Upload;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation$variables = {
  input: SchoolExamScrapCreateInput;
};
export type SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation$data = {
  readonly schoolExamScrapCreate: {
    readonly id: string;
    readonly order: number;
  };
};
export type SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation = {
  response: SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation$data;
  variables: SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SchoolExamScrap",
    "kind": "LinkedField",
    "name": "schoolExamScrapCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "order",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5cc157b575427f33c35dabfed490c226",
    "id": null,
    "metadata": {},
    "name": "SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation",
    "operationKind": "mutation",
    "text": "mutation SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation(\n  $input: SchoolExamScrapCreateInput!\n) {\n  schoolExamScrapCreate(input: $input) {\n    id\n    order\n  }\n}\n"
  }
};
})();

(node as any).hash = "06c3ebbea3d18e14b6ad973d20b0f5d3";

export default node;
