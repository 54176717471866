import { SearchIcon } from '@primer/octicons-react';
import { clipboard } from '@teamturing/utils';
import { Suspense, useRef, useState } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import useCaptureHtml from '../../../hooks/useCaptureHtml';
import useToast from '../../../hooks/useToast';
import { SchoolExamSourceImageExportDialog_schoolExamSourcesQuery } from '../../../relay/__generated__/SchoolExamSourceImageExportDialog_schoolExamSourcesQuery.graphql';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import ErrorBoundary from '../../core/ErrorBoundary';
import ErrorState from '../../core/ErrorState';
import NumberInput from '../../core/NumberInput';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import TextInput from '../../core/TextInput';
import View from '../../core/View';
import SchoolExamSourceExportedPreview from '../SchoolExamSourceExportedPreview';

const schoolExamSourcesForSchoolExamSourceImageExportDialog = graphql`
  query SchoolExamSourceImageExportDialog_schoolExamSourcesQuery(
    $filters: SchoolExamSourceFilter
    $scrapCount: Int
    $scrapOrder: SchoolExamScrapOrder
  ) {
    schoolExamSources(filters: $filters) {
      edges {
        node {
          id
          sequence
          ...SchoolExamSourceExportedPreview_schoolExamSource
            @arguments(scrapOrder: $scrapOrder, scrapCount: $scrapCount)
        }
      }
    }
  }
`;

type Props = {} & DialogProps;

const CONTAINER_ID = '캡쳐해야하는요소';

const SchoolExamSourceImageExportDialog = ({ ...props }: Props) => {
  const { toast } = useToast();

  const [queryReference, loadQuery] = useQueryLoader<SchoolExamSourceImageExportDialog_schoolExamSourcesQuery>(
    schoolExamSourcesForSchoolExamSourceImageExportDialog,
  );

  const searchRef = useRef<HTMLInputElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [scrapCount, setScrapCount] = useState<number>(6);

  const { downloadImageFromHtml, uploadImageFromHtml } = useCaptureHtml({
    scale: 1.5,
    removeContainer: true,
  });

  const handleClickDownload = (fileName: string) => {
    const container = document.getElementById(CONTAINER_ID);
    if (!container) return;
    setIsLoading(true);

    downloadImageFromHtml(container, fileName)
      .catch(() => {
        toast('이미지 추출에 실패했습니다', 'error');
      })
      .finally(() => setIsLoading(false));
  };

  const handleClickCopyUrl = (fileName: string) => {
    const container = document.getElementById(CONTAINER_ID);
    if (!container) return;
    setIsLoading(true);

    uploadImageFromHtml(container, fileName)
      .then(({ metaData }) => {
        clipboard.writeText(metaData.object_url);
        toast('이미지 주소가 클립보드에 복사됐어요', 'success');
      })
      .catch(() => {
        toast('이미지 추출에 실패했습니다', 'error');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog {...props}>
      <Dialog.Header>내신 기출 이미지 추출하기</Dialog.Header>
      <Dialog.Body>
        <>
          <Stack gapX={3}>
            <Stack.Item>
              <TextInput
                ref={searchRef}
                leadingVisual={SearchIcon}
                placeholder={'ID 검색'}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    loadQuery({
                      filters: { search: e.currentTarget.value },
                      scrapCount,
                      scrapOrder: { order: 'ASC' },
                    });
                  }
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Stack gapX={1}>
                <Stack.Item>
                  <Text fontSize={1}>문제 수 :</Text>
                </Stack.Item>
                <Stack.Item>
                  <NumberInput
                    defaultValue={scrapCount}
                    min={1}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const newValue = Number(e.currentTarget.value);
                        setScrapCount(newValue);
                        if (searchRef.current?.value) {
                          loadQuery({ filters: { search: searchRef.current?.value }, scrapCount: newValue });
                        }
                      }
                    }}
                    sx={{ width: 100 }}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
            {queryReference ? (
              <ErrorBoundary key={queryReference.fetchKey} fallback={() => null}>
                <Suspense>
                  <PreloadedQueryRenderer<SchoolExamSourceImageExportDialog_schoolExamSourcesQuery>
                    query={schoolExamSourcesForSchoolExamSourceImageExportDialog}
                    queryReference={queryReference}
                  >
                    {({ schoolExamSources }) => {
                      const schoolExamSource = schoolExamSources.edges[0]?.node;

                      const fileName = schoolExamSource.sequence + '';

                      return (
                        <>
                          <Stack.Item>
                            <Button onClick={() => handleClickDownload(fileName)} disabled={isLoading}>
                              추출하기
                            </Button>
                          </Stack.Item>
                          <Stack.Item>
                            <Button
                              onClick={() => handleClickCopyUrl(`webflow/school_exam_source/${fileName}`)}
                              disabled={isLoading}
                            >
                              이미지 url 복사
                            </Button>
                          </Stack.Item>
                        </>
                      );
                    }}
                  </PreloadedQueryRenderer>
                </Suspense>
              </ErrorBoundary>
            ) : null}
            {isLoading ? (
              <Stack.Item>
                <Spinner size={'small'} />
              </Stack.Item>
            ) : null}
          </Stack>
          {queryReference ? (
            <ErrorBoundary
              key={queryReference.fetchKey}
              fallback={({ parsedGraphQLError }) => (
                <View sx={{ padding: 5 }}>
                  <ErrorState description={parsedGraphQLError?.[0].message} />
                </View>
              )}
            >
              <Suspense
                fallback={
                  <View sx={{ padding: 5 }}>
                    <Spinner />
                  </View>
                }
              >
                <PreloadedQueryRenderer<SchoolExamSourceImageExportDialog_schoolExamSourcesQuery>
                  query={schoolExamSourcesForSchoolExamSourceImageExportDialog}
                  queryReference={queryReference}
                >
                  {({ schoolExamSources }) => {
                    const schoolExamSource = schoolExamSources.edges[0]?.node;

                    if (!schoolExamSource)
                      return (
                        <View sx={{ padding: 5 }}>
                          <EmptyState title={'검색된 내신 기출이 없어요'} />
                        </View>
                      );

                    return (
                      <Card
                        sx={{
                          borderRadius: 0,
                          width: 'fit-content',
                          maxWidth: '100%',
                          overflowX: 'auto',
                          marginTop: 3,
                        }}
                      >
                        <View id={CONTAINER_ID} sx={{ width: 'fit-content' }}>
                          <SchoolExamSourceExportedPreview schoolExamSource={schoolExamSource} />
                        </View>
                      </Card>
                    );
                  }}
                </PreloadedQueryRenderer>
              </Suspense>
            </ErrorBoundary>
          ) : (
            <View sx={{ padding: 5 }}>
              <EmptyState title={'내신 기출을 검색해 주세요'} />
            </View>
          )}
        </>
      </Dialog.Body>
    </Dialog>
  );
};

export default SchoolExamSourceImageExportDialog;
