import { SystemBorderProps } from '@primer/react/lib/constants';

import View, { ViewProps } from '../View';

type Props = {
  borderWidth?: ViewProps['width'];
  borderColor?: SystemBorderProps['borderColor'];
} & Pick<ViewProps, 'm' | 'mt' | 'mr' | 'mb' | 'ml' | 'my' | 'mx' | 'm' | 'display'>;

const HorizontalDivider = ({ borderWidth = 1, borderColor = 'border.default', ...props }: Props) => (
  <View width={'100%'} height={borderWidth} backgroundColor={borderColor} {...props} />
);

export default HorizontalDivider;
