/**
 * @generated SignedSource<<d26087f21521ddf1dd4c72d76e15b526>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type TaskProblemTypeEnum = "copy" | "exam" | "private" | "problem_generator" | "pure" | "%future added value";
export type WorkbookTasksAddDialog_taskQuery$variables = {
  id: string;
};
export type WorkbookTasksAddDialog_taskQuery$data = {
  readonly task: {
    readonly difficulty: number | null;
    readonly id: string;
    readonly problem: {
      readonly problem: string | null;
      readonly problemType: TaskProblemTypeEnum;
      readonly solution: string | null;
    } | null;
    readonly publishedUnitDs: ReadonlyArray<{
      readonly description: string | null;
      readonly id: string;
      readonly unitATitle: string;
      readonly unitBTitle: string;
      readonly unitCTitle: string;
    }>;
    readonly sequence: number;
    readonly workbooks: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly grade: ScrapSourceExamGradeEnum;
          readonly id: string;
          readonly title: string;
        };
      }>;
      readonly totalCount: number | null;
    };
  };
};
export type WorkbookTasksAddDialog_taskQuery = {
  response: WorkbookTasksAddDialog_taskQuery$data;
  variables: WorkbookTasksAddDialog_taskQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sequence",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problem",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "solution",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "order",
      "value": {
        "order": "DESC"
      }
    }
  ],
  "concreteType": "UnitD",
  "kind": "LinkedField",
  "name": "publishedUnitDs",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitATitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitBTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitCTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": "publishedUnitDs(order:{\"order\":\"DESC\"})"
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "WorkbookConnection",
  "kind": "LinkedField",
  "name": "workbooks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkbookEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Workbook",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grade",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookTasksAddDialog_taskQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskProblem",
            "kind": "LinkedField",
            "name": "problem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookTasksAddDialog_taskQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "task",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskProblem",
            "kind": "LinkedField",
            "name": "problem",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "149e28afc9b25708cceb076c9224b3d2",
    "id": null,
    "metadata": {},
    "name": "WorkbookTasksAddDialog_taskQuery",
    "operationKind": "query",
    "text": "query WorkbookTasksAddDialog_taskQuery(\n  $id: ID!\n) {\n  task(id: $id) {\n    id\n    sequence\n    problem {\n      problemType\n      problem\n      solution\n      id\n    }\n    publishedUnitDs(order: {order: DESC}) {\n      id\n      unitATitle\n      unitBTitle\n      unitCTitle\n      description\n    }\n    difficulty\n    workbooks {\n      totalCount\n      edges {\n        node {\n          id\n          title\n          grade\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c83be2d0ade754a0d5207a358b0c39c1";

export default node;
