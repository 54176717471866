import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  UnitCSelectField_unitCsQuery,
  UnitCSelectField_unitCsQuery$variables,
} from '../../../relay/__generated__/UnitCSelectField_unitCsQuery.graphql';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const unitCsForUnitCSelectField = graphql`
  query UnitCSelectField_unitCsQuery($filters: UnitCFilter, $order: UnitCOrder) {
    unitCs(filters: $filters, order: $order) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {
  variables?: UnitCSelectField_unitCsQuery$variables;
} & Omit<SelectFieldProps, 'options'>;

const UnitCSelectField = ({ variables, ...props }: Props) => {
  const [{ unitCs }] = useLazyLoadQuery<UnitCSelectField_unitCsQuery>(unitCsForUnitCSelectField, {
    ...variables,
  });

  return <SelectField options={unitCs.edges.map(({ node: { id, title } }) => ({ id, text: title }))} {...props} />;
};

export default UnitCSelectField;
