/**
 * @generated SignedSource<<a1ed8bd7a46ab5cda3470efc1094e9ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookBookScrapCreateDialog_book$data = {
  readonly id: string;
  readonly latestBookScraps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly problemBookPage: number;
        readonly problemNumber: string;
        readonly problemPage: number;
        readonly solutionBookPage: number;
        readonly solutionPage: number;
      };
    }>;
    readonly totalCount: number | null;
  };
  readonly problemPdf: {
    readonly id: string;
    readonly objectUrl: string;
  };
  readonly solutionPdf: {
    readonly id: string;
    readonly objectUrl: string;
  };
  readonly title: string;
  readonly " $fragmentType": "BookBookScrapCreateDialog_book";
};
export type BookBookScrapCreateDialog_book$key = {
  readonly " $data"?: BookBookScrapCreateDialog_book$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookBookScrapCreateDialog_book">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookBookScrapCreateDialog_book",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "solutionPdf",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "problemPdf",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": "latestBookScraps",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "order",
          "value": {
            "created": "DESC"
          }
        }
      ],
      "concreteType": "BookScrapConnection",
      "kind": "LinkedField",
      "name": "bookScraps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "BookScrapEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BookScrap",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "problemPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "solutionPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "problemBookPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "solutionBookPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "problemNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "bookScraps(first:1,order:{\"created\":\"DESC\"})"
    }
  ],
  "type": "Book",
  "abstractKey": null
};
})();

(node as any).hash = "de1d9815cba0dfcf8104fbb75c03e732";

export default node;
