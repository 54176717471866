import Image from '../../core/Image';
import Text from '../../core/Text';
import View from '../../core/View';

type Props = {
  appDialogTitle: string;
  appDialogBody: string;
  appDialogButton: string;
  appDialogImage?: string | null;
};
const AppDialogImageAndTextTypePreview = ({
  appDialogBody,
  appDialogTitle,
  appDialogButton,
  appDialogImage,
}: Props) => {
  return (
    <View sx={{ height: '100%' }}>
      <View
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          padding: 4,
        }}
      >
        <Text sx={{ color: '#33373B', fontWeight: 'bold' }}>{appDialogTitle}</Text>
        <View style={{ marginTop: 2 }}>
          <Text sx={{ color: '#7A828D', fontSize: 12 }}>{appDialogBody}</Text>
        </View>
        {appDialogImage && (
          <View sx={{ aspectRatio: '270/170', width: '100%', position: 'relative', marginTop: 3 }}>
            <Image src={appDialogImage} alt={'만능 다이얼로그 미리보기 이미지'} fill />
          </View>
        )}
        <View
          sx={{
            backgroundColor: '#835EEB',
            borderRadius: 999,
            padding: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 4,
            color: 'white',
          }}
        >
          <Text>{appDialogButton}</Text>
        </View>
      </View>
    </View>
  );
};

export default AppDialogImageAndTextTypePreview;
