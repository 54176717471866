import { CopyIcon, KebabHorizontalIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React from 'react';
import { graphql } from 'react-relay';

import AppPushCreateDialog from '../../components/appPush/AppPushCreateDialog';
import AppPushDescriptionList from '../../components/appPush/AppPushDescriptionList';
import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Card from '../../components/core/Card';
import DialogButton from '../../components/core/DialogButton';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import SQLEditor from '../../components/core/SQLEditor';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { AppPushId_appPushDeleteMutation } from '../../relay/__generated__/AppPushId_appPushDeleteMutation.graphql';
import { AppPushId_appPushQuery } from '../../relay/__generated__/AppPushId_appPushQuery.graphql';
import { NextPage } from '../_app';

const appPushForAppPushId = graphql`
  query AppPushId_appPushQuery($id: ID!) {
    appPush(id: $id) {
      id
      title
      actions
      description
      pushTitle
      pushBody
      deepLinkParams
      deepLinkUrl
      query
      ...AppPushDescriptionList_appPush
    }
  }
`;

type Props = {};

const AppPushId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ appPush }] = useLazyLoadQuery<AppPushId_appPushQuery>(appPushForAppPushId, {
    id: router.query.appPushId as string,
  });
  if (!appPush) return null;

  const { id, title, actions, description, query, pushTitle, pushBody, deepLinkParams, deepLinkUrl } = appPush;

  return (
    <View>
      <Head siteTitle={`푸시 알림 | ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{title}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              {actions.includes('app_push_create') ? (
                <Stack.Item>
                  <DialogButton
                    renderDialog={({ isOpen, closeDialog }) => (
                      <AppPushCreateDialog
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        full
                        config={{
                          onCompleted: ({ appPushCreate }) => {
                            toast('푸시 알림이 생성되었어요!', 'success');
                            closeDialog();
                            router.push(`/appPush/${appPushCreate.id}`);
                          },
                          onError: () => {
                            toast('다시 생성해 주세요', 'error');
                          },
                        }}
                        initialValues={{
                          pushBody,
                          pushTitle,
                          query,
                          startAt: null,
                          deepLinkParams,
                          deepLinkUrl,
                          title,
                          description,
                        }}
                      />
                    )}
                    size={'large'}
                    leadingIcon={CopyIcon}
                  >
                    복제하기
                  </DialogButton>
                </Stack.Item>
              ) : null}
              {actions.includes('app_push_delete') ? (
                <Stack.Item>
                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.MutationItem<AppPushId_appPushDeleteMutation>
                          mutation={graphql`
                            mutation AppPushId_appPushDeleteMutation($input: AppPushDeleteInput!) {
                              appPushDelete(input: $input) {
                                id @deleteRecord
                              }
                            }
                          `}
                          variant={'danger'}
                          input={{ id }}
                          config={{
                            onCompleted: () => {
                              router.replace('/appPush').then(() => toast('푸시 알림 삭제 완료!', 'success'));
                            },
                          }}
                          message={'정말 삭제할까요?'}
                        >
                          삭제하기
                        </ActionList.MutationItem>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Stack.Item>
              ) : null}
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid gapX={5} gapY={3} reverse={[true, true, false]}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <View>
              <Text fontSize={1} fontWeight={'bold'}>
                설명
              </Text>
              <Card sx={{ padding: 3, marginTop: 2 }}>
                <Text fontSize={1} whiteSpace={'pre-wrap'}>
                  {description || '-'}
                </Text>
              </Card>
            </View>
            <View sx={{ marginTop: 5 }}>
              <Text fontSize={1} fontWeight={'bold'}>
                푸시 알림
              </Text>
              <Card sx={{ padding: 4, marginTop: 2 }}>
                <AppPushDescriptionList
                  appPush={appPush}
                  titleUnitSize={[1, 1, 1 / 6]}
                  descriptionUnitSize={[1, 1, 5 / 6]}
                />
              </Card>
            </View>
            <View sx={{ marginTop: 5 }}>
              <Text fontSize={1}>SQL</Text>
              <SQLEditor readOnly value={query} height={200} sx={{ marginTop: 1 }} />
            </View>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <AppPushDescriptionList
              appPush={appPush}
              type={'activity'}
              titleUnitSize={'max'}
              descriptionUnitSize={'min'}
              renderTitle={(title) => (
                <Text fontWeight={'bold'} fontSize={1}>
                  {title}
                </Text>
              )}
            />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

AppPushId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
AppPushId.authenticated = true;
AppPushId.routes = [
  { id: 'appPushId', pathname: '/appPush/[appPushId]', name: '푸시 알림 상세', permissions: ['app_push_read'] },
];

export default AppPushId;
