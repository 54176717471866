/**
 * @generated SignedSource<<94fba34144f1c97652564d5ab8f8b132>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type QuizHistoryTypeEnum = "quiz_create" | "quiz_error_report" | "quiz_labeling" | "quiz_review" | "quiz_update" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuizHistoryTimelineItem_quizHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly id: string;
  readonly type: QuizHistoryTypeEnum;
  readonly " $fragmentType": "QuizHistoryTimelineItem_quizHistory";
};
export type QuizHistoryTimelineItem_quizHistory$key = {
  readonly " $data"?: QuizHistoryTimelineItem_quizHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizHistoryTimelineItem_quizHistory">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizHistoryTimelineItem_quizHistory",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "QuizHistory",
  "abstractKey": null
};
})();

(node as any).hash = "6a97b969bac6f79fd24545533288798c";

export default node;
