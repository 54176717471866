/**
 * @generated SignedSource<<abbe40cccf204329053e536826d9b98f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type permission_meQuery$variables = {};
export type permission_meQuery$data = {
  readonly me: {
    readonly id: string;
    readonly leadingGroups: ReadonlyArray<{
      readonly id: string;
      readonly title: string;
    }>;
  };
};
export type permission_meQuery = {
  response: permission_meQuery$data;
  variables: permission_meQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "leadingGroups",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "permission_meQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "permission_meQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "728a03bcdb2f11cb872601477ced7ef2",
    "id": null,
    "metadata": {},
    "name": "permission_meQuery",
    "operationKind": "query",
    "text": "query permission_meQuery {\n  me {\n    id\n    leadingGroups {\n      id\n      title\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1ce6a2227c987cf6399d51e5273b497";

export default node;
