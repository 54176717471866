import { graphql, useFragment } from 'react-relay';

import { QuizStatusEnum, QuizStatusLabel_quiz$key } from '../../../relay/__generated__/QuizStatusLabel_quiz.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const QuizStatusLabel_quiz = graphql`
  fragment QuizStatusLabel_quiz on Quiz {
    status
  }
`;

type Props = {
  quiz: QuizStatusLabel_quiz$key;
} & Omit<LabelProps, 'variant'>;

const QuizStatusLabel = ({ quiz, ...props }: Props) => {
  const { status } = useFragment(QuizStatusLabel_quiz, quiz);

  const labelPropsByStatus: Record<QuizStatusEnum, LabelProps> = {
    'error_report': { variant: 'danger' },
    'review': { variant: 'severe' },
    'published': { variant: 'success' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'QuizStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default QuizStatusLabel;
