/**
 * @generated SignedSource<<a27c787b34f0f7980d7b60d31cdae47e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RaffleUpdateDialog_raffle$data = {
  readonly description: string | null;
  readonly endAt: any;
  readonly id: string;
  readonly isPremium: boolean;
  readonly order: number;
  readonly raffleStuff: {
    readonly id: string;
  };
  readonly requiredPoint: number;
  readonly showEndAt: any;
  readonly startAt: any;
  readonly title: string;
  readonly winnerCount: number;
  readonly " $fragmentType": "RaffleUpdateDialog_raffle";
};
export type RaffleUpdateDialog_raffle$key = {
  readonly " $data"?: RaffleUpdateDialog_raffle$data;
  readonly " $fragmentSpreads": FragmentRefs<"RaffleUpdateDialog_raffle">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RaffleUpdateDialog_raffle",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "RaffleStuff",
      "kind": "LinkedField",
      "name": "raffleStuff",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isPremium",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredPoint",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "winnerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "showEndAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "order",
      "storageKey": null
    }
  ],
  "type": "Raffle",
  "abstractKey": null
};
})();

(node as any).hash = "c58c2913cd86a1bf8709270d84f69352";

export default node;
