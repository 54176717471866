import {
  formatKatexToHtmlString,
  resizeObserverCallbackForChoiceLayout,
  renderChoiceLayout,
} from '@teamturing/katex-utils';
import { debounce } from 'lodash-es';
import { memo, useEffect, useRef } from 'react';

import View, { ViewProps } from '../View';

type Props = {
  children: string;
} & Pick<ViewProps, 'sx'>;

const Katex = ({ children: propChildren, sx }: Props) => {
  const children = formatKatexToHtmlString(propChildren);
  const katexContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let resizeObserver = window.__resizeObserverForChoiceLayout;
    if (!resizeObserver) {
      resizeObserver = new ResizeObserver(debounce(resizeObserverCallbackForChoiceLayout, 100));
      window.__resizeObserverForChoiceLayout = resizeObserver;
    }
    if (katexContainerRef.current) resizeObserver.observe(katexContainerRef.current);
    return () => {
      if (katexContainerRef.current && resizeObserver) resizeObserver.unobserve(katexContainerRef.current);
    };
  }, []);

  useEffect(() => {
    if (katexContainerRef.current) renderChoiceLayout(katexContainerRef.current);
  }, [children]);

  return (
    <View
      ref={katexContainerRef}
      sx={{ fontFamily: 'Noto Serif KR', whiteSpace: 'pre-wrap', height: '100%', ...sx }}
      dangerouslySetInnerHTML={{ __html: children }}
    />
  );
};

export default memo(Katex);
export type { Props as KatexProps };
