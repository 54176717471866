/**
 * @generated SignedSource<<9b451403544767e106c183e56318084d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskSubmitFeedbackStatusEnum = "published" | "saved" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskSubmitFeedbackStatusLabel_taskSubmitFeedback$data = {
  readonly status: TaskSubmitFeedbackStatusEnum;
  readonly " $fragmentType": "TaskSubmitFeedbackStatusLabel_taskSubmitFeedback";
};
export type TaskSubmitFeedbackStatusLabel_taskSubmitFeedback$key = {
  readonly " $data"?: TaskSubmitFeedbackStatusLabel_taskSubmitFeedback$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskSubmitFeedbackStatusLabel_taskSubmitFeedback">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskSubmitFeedbackStatusLabel_taskSubmitFeedback",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "TaskSubmitFeedback",
  "abstractKey": null
};

(node as any).hash = "d170d6aa4f23a07fa6ea5da9c17b409e";

export default node;
