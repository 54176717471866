/**
 * @generated SignedSource<<df54b588af47c8279ecd0ff1a973d7a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BookStatusEnum = "completed" | "ongoing" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BookStatusLabel_book$data = {
  readonly status: BookStatusEnum;
  readonly " $fragmentType": "BookStatusLabel_book";
};
export type BookStatusLabel_book$key = {
  readonly " $data"?: BookStatusLabel_book$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookStatusLabel_book">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookStatusLabel_book",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Book",
  "abstractKey": null
};

(node as any).hash = "38382c7bb318af01a35090d4d3ee80c3";

export default node;
