/**
 * @generated SignedSource<<0cde16dd2bffb41a43a5a702e8b2a981>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskHintFeedbackDifferenceItemList_difference$data = {
  readonly correct?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly general?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly hint?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly incorrect?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly special?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly specialAnswer?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly " $fragmentType": "TaskHintFeedbackDifferenceItemList_difference";
};
export type TaskHintFeedbackDifferenceItemList_difference$key = {
  readonly " $data"?: TaskHintFeedbackDifferenceItemList_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskHintFeedbackDifferenceItemList_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "DiffItem_diff"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskHintFeedbackDifferenceItemList_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "hint",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "specialAnswer",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "special",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "general",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "incorrect",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "correct",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "TaskHintFeedbackDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "a6b8f6b6a13b8557e9d246411e83ab52";

export default node;
