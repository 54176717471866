import { SpeakerIcon } from '@teamturing/icons';

import Text from '../../core/Text';
import View from '../../core/View';

type Props = {
  appDialogTitle: string;
  appDialogBody: string;
  appDialogButton: string;
};
const AppDialogTextTypePreview = ({ appDialogBody, appDialogTitle, appDialogButton }: Props) => {
  return (
    <View sx={{ height: '100%' }}>
      <View
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: 4,
        }}
      >
        <SpeakerIcon width={64} height={64} color={'#D1D5DB'} />
        <Text sx={{ color: '#33373B', marginTop: 4, fontWeight: 'bold' }}>{appDialogTitle}</Text>
        <View style={{ marginTop: 4 }}>
          <Text sx={{ color: '#7A828D', fontSize: 12 }}>{appDialogBody}</Text>
        </View>
        <View
          sx={{
            backgroundColor: '#835EEB',
            borderRadius: 999,
            padding: 2,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 4,
            color: 'white',
          }}
        >
          <Text>{appDialogButton}</Text>
        </View>
      </View>
    </View>
  );
};

export default AppDialogTextTypePreview;
