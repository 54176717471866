import { CheckIcon, PencilIcon, XIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { DeliveryOrderMutationActionsButtonStack_deliveryOrder$key } from '../../../relay/__generated__/DeliveryOrderMutationActionsButtonStack_deliveryOrder.graphql';
import { DeliveryOrderMutationActionsButtonStack_deliveryOrderCompleteMutation } from '../../../relay/__generated__/DeliveryOrderMutationActionsButtonStack_deliveryOrderCompleteMutation.graphql';
import DialogButton from '../../core/DialogButton';
import MutationConfirmButton from '../../core/MutationConfirmButton';
import Stack from '../../core/Stack';
import DeliveryOrderCancelDialog from '../DeliveryOrderCancelDialog';
import DeliveryOrderUpdateDialog from '../DeliveryOrderUpdateDialog';

const DeliveryOrderMutationActionsButtonStack_deliveryOrder = graphql`
  fragment DeliveryOrderMutationActionsButtonStack_deliveryOrder on DeliveryOrder {
    id
    actions
    ...DeliveryOrderUpdateDialog_deliveryOrder
    ...DeliveryOrderCancelDialog_deliveryOrder
  }
`;

type Props = {
  deliveryOrder: DeliveryOrderMutationActionsButtonStack_deliveryOrder$key;
};

const DeliveryOrderMutationActionsButtonStack = ({ deliveryOrder }: Props) => {
  const data = useFragment(DeliveryOrderMutationActionsButtonStack_deliveryOrder, deliveryOrder);
  const { id, actions } = data;
  const { toast } = useToast();
  return (
    <Stack gapX={2}>
      {actions.includes('delivery_order_cancel') ? (
        <Stack.Item>
          <DialogButton
            variant={'danger'}
            leadingIcon={XIcon}
            renderDialog={({ isOpen, closeDialog }) => (
              <DeliveryOrderCancelDialog
                deliveryOrder={data}
                isOpen={isOpen}
                onDismiss={closeDialog}
                config={{
                  onCompleted: () => {
                    toast('배송 취소 완료!', 'success');
                    closeDialog();
                  },
                }}
              />
            )}
            size={'large'}
          >
            배송 취소하기
          </DialogButton>
        </Stack.Item>
      ) : null}
      {actions.includes('delivery_order_complete') ? (
        <Stack.Item>
          <MutationConfirmButton<DeliveryOrderMutationActionsButtonStack_deliveryOrderCompleteMutation>
            input={{ id }}
            mutation={graphql`
              mutation DeliveryOrderMutationActionsButtonStack_deliveryOrderCompleteMutation(
                $input: DeliveryOrderCompleteInput!
              ) {
                deliveryOrderComplete(input: $input) {
                  id
                  ...DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder
                  ...DeliveryOrderMutationActionsButtonStack_deliveryOrder
                  ...DeliveryOrderStatusLabel_deliveryOrder
                }
              }
            `}
            leadingIcon={CheckIcon}
            variant={'outline'}
            message={'배송 완료했는지 다시 한 번 확인해 주세요.\n정말 배송 완료 할까요?'}
            config={{
              onCompleted: () => {
                toast('배송 완료!', 'success');
              },
            }}
          >
            배송 완료하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
      {actions.includes('delivery_order_update') ? (
        <Stack.Item>
          <DialogButton
            leadingIcon={PencilIcon}
            variant={'primary'}
            renderDialog={({ isOpen, closeDialog }) => (
              <DeliveryOrderUpdateDialog
                deliveryOrder={data}
                isOpen={isOpen}
                onDismiss={closeDialog}
                config={{
                  onCompleted: () => {
                    toast('배송 요청 수정 완료!', 'success');
                    closeDialog();
                  },
                }}
              />
            )}
            size={'large'}
          >
            수정하기
          </DialogButton>
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export default DeliveryOrderMutationActionsButtonStack;
