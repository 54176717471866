/**
 * @generated SignedSource<<0891d2c39ae7f31dcaf2521242bee164>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskHistoryTypeEnum = "task_activate" | "task_create" | "task_deactivate" | "task_error_report" | "task_first_labeling" | "task_hint_feedback_publish" | "task_image_creation" | "task_problem_creation" | "task_problem_typing" | "task_publish" | "task_second_labeling" | "task_solution_video_create" | "task_solution_video_update" | "task_update" | "task_video_create" | "task_video_update" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskHistoryTimelineItem_taskHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly type: TaskHistoryTypeEnum;
  readonly " $fragmentType": "TaskHistoryTimelineItem_taskHistory";
};
export type TaskHistoryTimelineItem_taskHistory$key = {
  readonly " $data"?: TaskHistoryTimelineItem_taskHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskHistoryTimelineItem_taskHistory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskHistoryTimelineItem_taskHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "TaskHistory",
  "abstractKey": null
};

(node as any).hash = "79723378eef44043266520edb799176c";

export default node;
