import { graphql } from 'react-relay';

import { FormulaSetFormulaSetHistoryPaginator_formulaSet$key } from '../../../relay/__generated__/FormulaSetFormulaSetHistoryPaginator_formulaSet.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const FormulaSetFormulaSetHistoryPaginator_formulaSet = graphql`
  fragment FormulaSetFormulaSetHistoryPaginator_formulaSet on FormulaSet
  @argumentDefinitions(
    order: { type: FormulaSetHistoryOrder, defaultValue: { created: DESC } }
    first: { type: Int, defaultValue: 10 }
    after: { type: String }
  )
  @refetchable(queryName: "FormulaSetFormulaSetHistoryPaginatorRefreshQuery") {
    histories(order: $order, first: $first, after: $after)
      @connection(key: "FormulaSetFormulaSetHistoryPaginator_formulaSet_histories") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          type
          ...FormulaSetHistoryDialog_formulaSetHistory
          ...FormulaSetHistoryTimelineItem_formulaSetHistory
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<FormulaSetFormulaSetHistoryPaginator_formulaSet$key>, 'fragment'>;

const FormulaSetFormulaSetHistoryPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={FormulaSetFormulaSetHistoryPaginator_formulaSet} {...props} />
);

export default FormulaSetFormulaSetHistoryPaginator;
