/**
 * @generated SignedSource<<8d1388326e5d3b2979c1ecc768cac332>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATOrganizationTeachersCreateInput = {
  count: number;
  organization: string;
};
export type MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation$variables = {
  input: MATOrganizationTeachersCreateInput;
};
export type MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation$data = {
  readonly organizationTeachersCreate: boolean;
};
export type MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation = {
  response: MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation$data;
  variables: MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "organizationTeachersCreate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6c87a1f454e5cc8b71ec41b8d613a33f",
    "id": null,
    "metadata": {},
    "name": "MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation",
    "operationKind": "mutation",
    "text": "mutation MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation(\n  $input: MATOrganizationTeachersCreateInput!\n) {\n  organizationTeachersCreate(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "468098107a720627bbb3a0958b883adc";

export default node;
