/**
 * @generated SignedSource<<1b319fbaaaf826a6f8e623c156de4606>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NoticeUpdateDialog_notice$data = {
  readonly description: string | null;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "NoticeUpdateDialog_notice";
};
export type NoticeUpdateDialog_notice$key = {
  readonly " $data"?: NoticeUpdateDialog_notice$data;
  readonly " $fragmentSpreads": FragmentRefs<"NoticeUpdateDialog_notice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoticeUpdateDialog_notice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "Notice",
  "abstractKey": null
};

(node as any).hash = "040d4a9655af9afb9c60491c28262f74";

export default node;
