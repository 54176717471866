import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  BookConnectionDataTable_bookConnection$data,
  BookConnectionDataTable_bookConnection$key,
} from '../../../relay/__generated__/BookConnectionDataTable_bookConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import UserAvatarText from '../../user/UserAvatarText';
import BookStatusLabel from '../BookStatusLabel';

const BookConnectionDataTable_bookConnection = graphql`
  fragment BookConnectionDataTable_bookConnection on BookConnection {
    edges {
      node {
        id
        ...BookStatusLabel_book
        sequence
        type
        title
        unitAs(order: { order: ASC }) {
          id
          title
        }
        publisher
        createdBy {
          id
          ...UserAvatarText_user
        }
        submittedBy {
          id
          ...UserAvatarText_user
        }
        created
      }
    }
  }
`;

type Props = {
  bookConnection: BookConnectionDataTable_bookConnection$key;
} & Omit<DataTableProps<BookConnectionDataTable_bookConnection$data['edges'][number]['node']>, 'rows' | 'columns'>;

const BookConnectionDataTable = ({ bookConnection, ...props }: Props) => {
  const { edges } = useFragment(BookConnectionDataTable_bookConnection, bookConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (book) => <BookStatusLabel book={book} size={'small'} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'sequence',
          title: 'ID',
          renderValue: ({ sequence }) => sequence,
          width: 80,
        },
        {
          field: 'type',
          title: '교재/교과서',
          renderValue: ({ type }) => (
            <Suspense fallback={'...'}>
              <EnumPair typename={'BookTypeEnum'}>{type}</EnumPair>
            </Suspense>
          ),
          width: 92,
        },
        {
          field: 'title',
          title: '교재 이름',
          renderValue: ({ title }) => title,
          width: 288,
        },
        {
          field: 'unitAs',
          title: 'Unit A',
          renderValue: ({ unitAs }) => unitAs.map(({ title }) => title).join(', '),
          width: 120,
        },
        { field: 'publisher', title: '출판사', renderValue: ({ publisher }) => publisher, width: 92 },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 92,
        },
        {
          field: 'submittedBy',
          title: '제출자',
          renderValue: ({ submittedBy }) => (submittedBy ? <UserAvatarText user={submittedBy} /> : '-'),
          width: 92,
        },
        {
          field: 'created',
          title: '생성일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default BookConnectionDataTable;
