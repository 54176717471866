import { SearchIcon } from '@primer/octicons-react';
import { useTheme } from '@primer/react';
import { debounce } from 'lodash-es';
import { Suspense, useState } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import useCaptureHtml from '../../../hooks/useCaptureHtml';
import useToast from '../../../hooks/useToast';
import { TaskImageExportDialog_tasksQuery } from '../../../relay/__generated__/TaskImageExportDialog_tasksQuery.graphql';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import ErrorBoundary from '../../core/ErrorBoundary';
import ErrorState from '../../core/ErrorState';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import TabHandler from '../../core/TabHandler';
import TextInput from '../../core/TextInput';
import Tooltip from '../../core/Tooltip';
import View from '../../core/View';

const tasksForTaskImageExportDialog = graphql`
  query TaskImageExportDialog_tasksQuery($filters: TaskFilter) {
    tasks(filters: $filters) {
      edges {
        node {
          id
          problem {
            id
            problem
            solution
          }
          hint {
            id
            publishedText
          }
        }
      }
    }
  }
`;

type Props = {} & DialogProps;

const CONTAINER_ID = '캡쳐해야하는요소';

const TaskImageExportDialog = ({ ...props }: Props) => {
  const { theme } = useTheme();
  const { toast } = useToast();

  const [queryReference, loadQuery] = useQueryLoader<TaskImageExportDialog_tasksQuery>(tasksForTaskImageExportDialog);

  const exportViewItems = [
    { id: 'problem', label: '문제' },
    { id: 'solution', label: '해설' },
    { id: 'hint', label: '힌트' },
  ];
  const [width, setWidth] = useState<number>(500);
  const [scale, setScale] = useState<number>(3);
  const [color, setColor] = useState<string>('#24292f'); // light color schema fg.default

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { downloadImageFromHtml } = useCaptureHtml({
    backgroundColor: theme?.colorSchemes.light.colors.canvas.default,
  });

  const handleClickExport = (fileName?: string) => {
    const container = document.getElementById(CONTAINER_ID);
    if (!container) return;
    setIsLoading(true);

    downloadImageFromHtml(container, fileName || 'exportImage', { scale })
      .catch(() => {
        toast('이미지 추출에 실패했습니다', 'error');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Dialog {...props}>
      <Dialog.Header>이미지 추출하기</Dialog.Header>
      <Dialog.Body>
        <TabHandler initialSelectIndex={0}>
          {({ selectedIndex, handleSelect }) => (
            <>
              <Stack gapX={3}>
                <Stack.Item>
                  <TextInput
                    leadingVisual={SearchIcon}
                    placeholder={'ID 검색'}
                    onChange={debounce((e) => {
                      const value = Number(e.target.value);
                      if (!isNaN(value)) {
                        loadQuery({ filters: { sequence_Exact: value } });
                      }
                    }, 500)}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Stack gapX={2}>
                    <ItemList
                      items={exportViewItems}
                      renderItem={({ label }, index) => (
                        <Button
                          onClick={() => handleSelect(index)}
                          variant={selectedIndex === index ? 'outline' : 'invisible'}
                        >
                          {label}
                        </Button>
                      )}
                      renderItemWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
                    />
                  </Stack>
                </Stack.Item>
              </Stack>
              <View sx={{ paddingY: 1 }} />
              <Stack gapX={3}>
                <Stack.Item>
                  <TextInput
                    type={'number'}
                    leadingVisual={'width'}
                    value={width}
                    onChange={(e) => setWidth(Number(e.target.value))}
                    sx={{ width: 150 }}
                  />
                </Stack.Item>
                <Stack.Item>
                  <Tooltip direction={'s'} text={'추출된 이미지 너비를 약 width * scale로 조정할 수 있습니다'}>
                    <TextInput
                      leadingVisual={'scale'}
                      type={'number'}
                      value={scale}
                      onChange={(e) => setScale(Number(e.target.value))}
                      sx={{ width: 100 }}
                    />
                  </Tooltip>
                </Stack.Item>
                <Stack.Item>
                  <Tooltip
                    direction={'s'}
                    text={'이미지를 제외한 텍스트와 수식의 색(hex)을 변경합니다.\nenter를 입력해야 적용됩니다.'}
                  >
                    <TextInput
                      type={'text'}
                      defaultValue={color}
                      leadingVisual={'color'}
                      onKeyDown={(e) => {
                        const value = e.currentTarget.value;
                        if (e.key === 'Enter' && value.match(/^#([0-9a-f]){6}$/i)) {
                          setColor(value);
                        }
                      }}
                      sx={{ width: 150 }}
                    />
                  </Tooltip>
                </Stack.Item>
                <Stack.Item>
                  <Button
                    onClick={() =>
                      handleClickExport(
                        `${queryReference?.variables.filters?.sequence_Exact ?? ''}_${
                          exportViewItems[selectedIndex].label
                        }`,
                      )
                    }
                    disabled={isLoading}
                  >
                    추출하기
                  </Button>
                </Stack.Item>
                {isLoading ? (
                  <Stack.Item>
                    <Spinner size={'small'} />
                  </Stack.Item>
                ) : null}
              </Stack>
              {queryReference ? (
                <ErrorBoundary
                  key={queryReference.fetchKey}
                  fallback={({ parsedGraphQLError }) => (
                    <View sx={{ padding: 5 }}>
                      <ErrorState description={parsedGraphQLError?.[0].message} />
                    </View>
                  )}
                >
                  <Suspense
                    fallback={
                      <View sx={{ padding: 5 }}>
                        <Spinner />
                      </View>
                    }
                  >
                    <PreloadedQueryRenderer<TaskImageExportDialog_tasksQuery>
                      query={tasksForTaskImageExportDialog}
                      queryReference={queryReference}
                    >
                      {({ tasks }) => {
                        const { problem, hint } = tasks.edges[0]?.node || {};

                        if (!problem)
                          return (
                            <View sx={{ padding: 5 }}>
                              <EmptyState title={'검색된 문제가 없어요'} />
                            </View>
                          );

                        const exportedText =
                          exportViewItems[selectedIndex].id === 'problem'
                            ? problem.problem
                            : exportViewItems[selectedIndex].id === 'solution'
                            ? problem.solution
                            : exportViewItems[selectedIndex].id === 'hint'
                            ? hint?.publishedText
                            : null;

                        return (
                          <Card
                            sx={{
                              borderRadius: 0,
                              width: 'fit-content',
                              maxWidth: '100%',
                              overflowX: 'auto',
                              marginTop: 4,
                            }}
                          >
                            {exportedText ? (
                              <View
                                id={CONTAINER_ID}
                                sx={{ width: width || '100%', lineHeight: 0 }}
                                data-color-mode={'light'}
                                data-light-theme={'light'}
                              >
                                <Katex
                                  sx={{
                                    '& ._cms_content-frame, ._cms_condition-box-frame': {
                                      color: `${color}!important`,
                                      borderColor: `${color}!important`,
                                    },
                                  }}
                                >
                                  {exportedText}
                                </Katex>
                              </View>
                            ) : (
                              <View sx={{ padding: 5 }}>
                                <EmptyState title={'입력된 내용이 없습니다'} />
                              </View>
                            )}
                          </Card>
                        );
                      }}
                    </PreloadedQueryRenderer>
                  </Suspense>
                </ErrorBoundary>
              ) : (
                <View sx={{ padding: 5 }}>
                  <EmptyState title={'문제를 검색해 주세요'} />
                </View>
              )}
            </>
          )}
        </TabHandler>
      </Dialog.Body>
    </Dialog>
  );
};

export default TaskImageExportDialog;
