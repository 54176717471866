import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { GroupCategoryDialog_group$key } from '../../../relay/__generated__/GroupCategoryDialog_group.graphql';
import CategoryTableItem from '../../category/CategoryTableItem';
import Checkbox from '../../core/Checkbox';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPair from '../../core/EnumPair';
import ItemList from '../../core/ItemList';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';

const GroupCategoryDialog_group = graphql`
  fragment GroupCategoryDialog_group on Group {
    id
    categories {
      id
      title
      ...CategoryTableItem_category
    }
  }
`;

type Props = { group: GroupCategoryDialog_group$key } & DialogProps;

const GroupCategoryDialog = ({ group, ...props }: Props) => {
  const { categories } = useFragment(GroupCategoryDialog_group, group);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text fontSize={3} fontWeight={'bold'}>
          그룹 권한 보기
        </Text>
      </Dialog.Header>
      <Dialog.Body>
        <ItemList
          items={categories}
          renderItem={(category) => (
            <>
              <View sx={{ marginBottom: 3 }}>
                <Text sx={{ fontSize: 1, color: 'fg.muted', fontWeight: 'bold' }}>
                  <Suspense>
                    <EnumPair typename={'CategoryEnum'}>{category.title}</EnumPair>
                  </Suspense>
                </Text>
              </View>
              <CategoryTableItem
                category={category}
                renderPermissions={(permissions) => (
                  <View sx={{ padding: 2 }}>
                    <Stack wrap={true} gapX={5} gapY={3}>
                      <ItemList
                        items={permissions}
                        renderItem={({ isActive, label }) => (
                          <Stack gapX={2}>
                            <Stack.Item>
                              <Checkbox disabled checked={isActive} sx={{ cursor: 'not-allowed' }} />
                            </Stack.Item>
                            <Stack.Item>
                              <Text sx={{ cursor: 'not-allowed', fontSize: 1, color: 'fg.subtle' }}>{label}</Text>
                            </Stack.Item>
                          </Stack>
                        )}
                        renderItemWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
                      />
                    </Stack>
                  </View>
                )}
              />
            </>
          )}
          renderItemWrapper={(children, { id }, index) => (
            <View key={id} sx={{ marginTop: index === 0 ? 0 : 4 }}>
              {children}
            </View>
          )}
        />
      </Dialog.Body>
    </Dialog>
  );
};

export default GroupCategoryDialog;
