import {
  CheckIcon,
  EyeClosedIcon,
  EyeIcon,
  FileAddedIcon,
  NoteIcon,
  PencilIcon,
  ReportIcon,
  RocketIcon,
  UploadIcon,
  VideoIcon,
} from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { TaskHistoryTimelineItem_taskHistory$key } from '../../../relay/__generated__/TaskHistoryTimelineItem_taskHistory.graphql';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Label from '../../core/Label';
import Timeline from '../../core/Timeline';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';

type Props = {
  taskHistory: TaskHistoryTimelineItem_taskHistory$key;
};

const TaskHistoryTimelineItem_taskHistory = graphql`
  fragment TaskHistoryTimelineItem_taskHistory on TaskHistory {
    created
    createdBy {
      id
      ...UserAvatarText_user
    }
    type
  }
`;

const TaskHistoryTimelineItem = ({ taskHistory }: Props) => {
  const { created, createdBy, type } = useFragment(TaskHistoryTimelineItem_taskHistory, taskHistory);

  return (
    <Timeline.Item>
      <Timeline.Badge>
        {type === 'task_create' ? (
          <FileAddedIcon />
        ) : type === 'task_video_create' ? (
          <UploadIcon />
        ) : type === 'task_solution_video_create' ? (
          <VideoIcon />
        ) : type === 'task_solution_video_update' ? (
          <PencilIcon />
        ) : type === 'task_publish' ? (
          <RocketIcon />
        ) : type === 'task_error_report' ? (
          <ReportIcon />
        ) : type === 'task_activate' ? (
          <EyeIcon />
        ) : type === 'task_deactivate' ? (
          <EyeClosedIcon />
        ) : type === 'task_hint_feedback_publish' ? (
          <NoteIcon />
        ) : (
          <CheckIcon />
        )}
      </Timeline.Badge>
      <Timeline.Body>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Grid.Unit size={'max'}>
              <View sx={{ display: 'flex', flexDirection: 'column' }}>
                <UserAvatarText user={createdBy} sx={{ fontWeight: 'bold', color: 'fg.muted' }} />
                <View sx={{ marginTop: 2 }}>
                  <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
                </View>
              </View>
            </Grid.Unit>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Label
              variant={
                type === 'task_publish'
                  ? 'success'
                  : type === 'task_hint_feedback_publish'
                  ? 'success'
                  : type === 'task_error_report'
                  ? 'danger'
                  : type === 'task_activate'
                  ? 'accent'
                  : type === 'task_deactivate'
                  ? 'attention'
                  : 'default'
              }
            >
              <EnumPair typename={'TaskHistoryTypeEnum'}>{type}</EnumPair>
            </Label>
          </Grid.Unit>
        </Grid>
      </Timeline.Body>
    </Timeline.Item>
  );
};

export default TaskHistoryTimelineItem;
