import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { RaffleStuffUpdateDialog_raffleStuff$key } from '../../../relay/__generated__/RaffleStuffUpdateDialog_raffleStuff.graphql';
import { RaffleStuffUpdateDialog_raffleStuffUpdateMutation } from '../../../relay/__generated__/RaffleStuffUpdateDialog_raffleStuffUpdateMutation.graphql';
import Button from '../../core/Button';
import ColorPickField from '../../core/ColorPickField';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';
import UploadField from '../../core/UploadField';
import StuffSelectField from '../../stuff/StuffSelectField';

const RaffleStuffUpdateDialog_raffleStuff = graphql`
  fragment RaffleStuffUpdateDialog_raffleStuff on RaffleStuff {
    id
    stuff {
      id
    }
    title
    description
    backgroundColor
  }
`;

type Props = { raffleStuff: RaffleStuffUpdateDialog_raffleStuff$key } & DialogProps &
  Pick<MutationFormikProps<RaffleStuffUpdateDialog_raffleStuffUpdateMutation>, 'onSubmit' | 'config' | 'connections'>;

const RaffleStuffUpdateDialog = ({ raffleStuff, config, onSubmit, connections, ...props }: Props) => {
  const { id, stuff, title, description, backgroundColor } = useFragment(
    RaffleStuffUpdateDialog_raffleStuff,
    raffleStuff,
  );

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>뽑기왕 경품 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<RaffleStuffUpdateDialog_raffleStuffUpdateMutation>
        mutation={graphql`
          mutation RaffleStuffUpdateDialog_raffleStuffUpdateMutation($input: RaffleStuffUpdateInput!) {
            raffleStuffUpdate(input: $input) {
              id
              ...RaffleStuffUpdateDialog_raffleStuff
              colorThumbnail {
                key
                objectUrl
              }
              blackThumbnail {
                key
                objectUrl
              }
              modifiedBy {
                id
                name
                picture
              }
              modified
            }
          }
        `}
        initialValues={{
          id,
          stuff,
          title,
          description,
          backgroundColor,
          colorThumbnail: null,
          blackThumbnail: null,
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <Suspense>
                  <StuffSelectField name={'stuff.id'} label={'물품'} placeholder={'물품 선택'} required />
                </Suspense>
                <TextField
                  name={'title'}
                  label={'제목'}
                  placeholder={'제목 입력'}
                  caption={
                    '수학대왕 앱 뽑기왕 매쓰킹에서 각 카드를 눌렀을 때 나오는 다이얼로그에서 제목으로 보여집니다.'
                  }
                  required
                />
                <TextareaField name={'description'} label={'설명'} placeholder={'설명 입력'} />
                <UploadField
                  name={'colorThumbnail'}
                  label={'컬러 이미지'}
                  placeholder={'컬러 이미지를 선택하세요'}
                  accept={'image/*'}
                  required
                />
                <UploadField
                  name={'blackThumbnail'}
                  label={'흑백 이미지'}
                  placeholder={'흑백 이미지를 선택하세요'}
                  accept={'image/*'}
                  required
                />
                <ColorPickField name={'backgroundColor'} label={'배경색'} placeholder={'배경색 선택'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default RaffleStuffUpdateDialog;
