import { CheckIcon, XIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { SchoolExamProblemActionsMutationButtonStack_schoolExamProblem$key } from '../../../relay/__generated__/SchoolExamProblemActionsMutationButtonStack_schoolExamProblem.graphql';
import { SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation } from '../../../relay/__generated__/SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation.graphql';
import DialogButton from '../../core/DialogButton';
import MutationButton from '../../core/MutationButton';
import Stack, { StackProps } from '../../core/Stack';
import SchoolExamProblemRejectDialog from '../SchoolExamProblemRejectDialog';

const SchoolExamProblemActionsMutationButtonStack_schoolExamProblem = graphql`
  fragment SchoolExamProblemActionsMutationButtonStack_schoolExamProblem on SchoolExamProblem {
    id
    actions
    ...SchoolExamProblemRejectDialog_schoolExamProblem
  }
`;

type Props = {
  schoolExamProblem: SchoolExamProblemActionsMutationButtonStack_schoolExamProblem$key;
} & StackProps;

const SchoolExamProblemActionsMutationButtonStack = ({ schoolExamProblem, gapX: gap = 2, ...props }: Props) => {
  const schoolExamProblemFragment = useFragment(
    SchoolExamProblemActionsMutationButtonStack_schoolExamProblem,
    schoolExamProblem,
  );
  const { id, actions } = schoolExamProblemFragment;

  const { toast } = useToast();

  return (
    <Stack gapX={gap} {...props}>
      {actions.includes('school_exam_problem_approve') ? (
        <Stack.Item>
          <MutationButton<SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation>
            mutation={graphql`
              mutation SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation(
                $input: SchoolExamProblemApproveInput!
              ) {
                schoolExamProblemApprove(input: $input) {
                  id
                  ...SchoolExamProblemStatusLabel_schoolExamProblem
                  ...SchoolExamProblemActionsMutationButtonStack_schoolExamProblem
                }
              }
            `}
            input={{ id }}
            variant={'outline'}
            leadingIcon={CheckIcon}
            config={{
              onCompleted: () => {
                toast('시험지 승인 완료!', 'success');
              },
              onError: () => {
                toast('오류가 발생했습니다.', 'error');
              },
            }}
          >
            승인하기
          </MutationButton>
        </Stack.Item>
      ) : null}
      {actions.includes('school_exam_problem_reject') ? (
        <Stack.Item>
          <DialogButton
            leadingIcon={XIcon}
            variant={'danger'}
            renderDialog={({ isOpen, closeDialog }) => (
              <SchoolExamProblemRejectDialog
                schoolExamProblem={schoolExamProblemFragment}
                isOpen={isOpen}
                onDismiss={closeDialog}
                config={{
                  onCompleted: () => {
                    toast('시험지 반려 완료!', 'success');
                    closeDialog();
                  },
                }}
              />
            )}
          >
            반려하기
          </DialogButton>
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export default SchoolExamProblemActionsMutationButtonStack;
