/**
 * @generated SignedSource<<1ad0d8c9587e6eb903dea45c062b3b7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkbookTasksDeleteInput = {
  id: string;
  taskIds: ReadonlyArray<string>;
};
export type WorkbookTasksReorderDialog_workbookTasksDeleteMutation$variables = {
  input: WorkbookTasksDeleteInput;
};
export type WorkbookTasksReorderDialog_workbookTasksDeleteMutation$data = {
  readonly workbookTasksDelete: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"WorkbookTasksReorderDialog_workbook">;
  };
};
export type WorkbookTasksReorderDialog_workbookTasksDeleteMutation = {
  response: WorkbookTasksReorderDialog_workbookTasksDeleteMutation$data;
  variables: WorkbookTasksReorderDialog_workbookTasksDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookTasksReorderDialog_workbookTasksDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookTasksDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookTasksReorderDialog_workbook"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookTasksReorderDialog_workbookTasksDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookTasksDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskConnection",
            "kind": "LinkedField",
            "name": "tasks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sequence",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "difficulty",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaskProblem",
                        "kind": "LinkedField",
                        "name": "problem",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "problem",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "solution",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnitD",
                        "kind": "LinkedField",
                        "name": "unitD",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitATitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitBTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitCTitle",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9cdfef8146963b83a1bcc699dcd0dc4",
    "id": null,
    "metadata": {},
    "name": "WorkbookTasksReorderDialog_workbookTasksDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation WorkbookTasksReorderDialog_workbookTasksDeleteMutation(\n  $input: WorkbookTasksDeleteInput!\n) {\n  workbookTasksDelete(input: $input) {\n    id\n    ...WorkbookTasksReorderDialog_workbook\n  }\n}\n\nfragment TaskSequenceItem_task on Task {\n  sequence\n}\n\nfragment WorkbookTasksReorderDialog_workbook on Workbook {\n  id\n  actions\n  tasks {\n    totalCount\n    edges {\n      node {\n        id\n        sequence\n        difficulty\n        ...TaskSequenceItem_task\n        problem {\n          problem\n          solution\n          id\n        }\n        unitD {\n          id\n          description\n          unitATitle\n          unitBTitle\n          unitCTitle\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c49a09fb399707d557df5eeae1970265";

export default node;
