/**
 * @generated SignedSource<<e36897c0661b8e2622b63e79c0fd8dee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type setting_meQuery$variables = {};
export type setting_meQuery$data = {
  readonly me: {
    readonly email: string;
    readonly id: string;
    readonly name: string;
    readonly picture: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"UserUpdatePasswordDialog_user">;
  };
};
export type setting_meQuery = {
  response: setting_meQuery$data;
  variables: setting_meQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "picture",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "setting_meQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserUpdatePasswordDialog_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "setting_meQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5b642b97df00bda4d5db510c20bfb7d3",
    "id": null,
    "metadata": {},
    "name": "setting_meQuery",
    "operationKind": "query",
    "text": "query setting_meQuery {\n  me {\n    id\n    email\n    name\n    picture\n    ...UserUpdatePasswordDialog_user\n  }\n}\n\nfragment UserUpdatePasswordDialog_user on User {\n  id\n}\n"
  }
};
})();

(node as any).hash = "26cbf10ad3591a7cebecde6c5e8e53a6";

export default node;
