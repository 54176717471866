/**
 * @generated SignedSource<<22cb3652f929be63eb0bcd941c0fcde1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductShowTypeEnum = "non_registered" | "private" | "repurchase" | "store" | "%future added value";
export type ProductUpdateInput = {
  additionalDiscountAmount?: number | null;
  description?: string | null;
  discountPrice?: number | null;
  id: string;
  price?: number | null;
  showTypes?: ReadonlyArray<ProductShowTypeEnum> | null;
  storeStuff?: string | null;
  title?: string | null;
};
export type ProductUpdateDialog_productUpdateMutation$variables = {
  input: ProductUpdateInput;
};
export type ProductUpdateDialog_productUpdateMutation$data = {
  readonly productUpdate: {
    readonly id: string;
    readonly productPrice: {
      readonly discountPercentage: number;
      readonly monthlyPrice: number;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ProductUpdateDialog_product">;
  };
};
export type ProductUpdateDialog_productUpdateMutation = {
  response: ProductUpdateDialog_productUpdateMutation$data;
  variables: ProductUpdateDialog_productUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ProductPrice",
  "kind": "LinkedField",
  "name": "productPrice",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthlyPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountPercentage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductUpdateDialog_productUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "productUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductUpdateDialog_product"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductUpdateDialog_productUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "productUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "StoreStuff",
            "kind": "LinkedField",
            "name": "storeStuff",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "showTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountPrice",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionalDiscountAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "28496fc0e4a726185bb1c6ba1b3923f4",
    "id": null,
    "metadata": {},
    "name": "ProductUpdateDialog_productUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ProductUpdateDialog_productUpdateMutation(\n  $input: ProductUpdateInput!\n) {\n  productUpdate(input: $input) {\n    id\n    productPrice {\n      monthlyPrice\n      discountPercentage\n    }\n    ...ProductUpdateDialog_product\n  }\n}\n\nfragment ProductUpdateDialog_product on Product {\n  id\n  title\n  storeStuff {\n    id\n  }\n  showTypes\n  price\n  discountPrice\n  additionalDiscountAmount\n}\n"
  }
};
})();

(node as any).hash = "806049bd1051282db1fbb076d0a2bb3d";

export default node;
