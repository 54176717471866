import { graphql, useFragment } from 'react-relay';

import {
  QuizConnectionDataTable_quizConnection$data,
  QuizConnectionDataTable_quizConnection$key,
} from '../../../relay/__generated__/QuizConnectionDataTable_quizConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';
import QuizStatusLabel from '../QuizStatusLabel';

const QuizConnectionDataTable_quizConnection = graphql`
  fragment QuizConnectionDataTable_quizConnection on QuizConnection {
    edges {
      node {
        id
        ...QuizStatusLabel_quiz
        sequence
        unitD {
          description
        }
        answer
        createdBy {
          id
          ...UserAvatarText_user
        }
        created
        modified
      }
    }
  }
`;

type Props = {
  quizConnection: QuizConnectionDataTable_quizConnection$key;
} & Omit<DataTableProps<QuizConnectionDataTable_quizConnection$data['edges'][number]['node']>, 'rows' | 'columns'>;

const QuizConnectionDataTable = ({ quizConnection, ...props }: Props) => {
  const { edges } = useFragment(QuizConnectionDataTable_quizConnection, quizConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (quiz) => <QuizStatusLabel quiz={quiz} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'sequence',
          title: 'ID',
          renderValue: ({ sequence }) => (
            <View sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{sequence}</View>
          ),
          width: 80,
        },
        { field: 'unitD', title: 'Unit D', renderValue: ({ unitD: { description } }) => description, width: 454 },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 120,
        },
        { field: 'answer', title: '정답', renderValue: ({ answer }) => answer, width: 70, align: 'center' },
        {
          field: 'created',
          title: '제작일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
          width: 160,
        },
        {
          field: 'modified',
          title: '수정일',
          renderValue: ({ modified }) => <DateText>{modified}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default QuizConnectionDataTable;
