/**
 * @generated SignedSource<<2340d6ecfc12a60a591a3cb3fa306e7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookSubmitInput = {
  id: string;
};
export type BookActionsMutationButtonStack_bookSubmitMutation$variables = {
  input: BookSubmitInput;
};
export type BookActionsMutationButtonStack_bookSubmitMutation$data = {
  readonly bookSubmit: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"BookActionsMutationButtonStack_book" | "BookBookScrapCreateDialog_book" | "BookBookScrapPaginator_book" | "BookDescriptionList_book" | "BookStatusLabel_book">;
  };
};
export type BookActionsMutationButtonStack_bookSubmitMutation = {
  response: BookActionsMutationButtonStack_bookSubmitMutation$data;
  variables: BookActionsMutationButtonStack_bookSubmitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "picture",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeleted",
    "storageKey": null
  }
],
v6 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemBookPage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "solutionBookPage",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookActionsMutationButtonStack_bookSubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookSubmit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookStatusLabel_book"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookDescriptionList_book"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookBookScrapCreateDialog_book"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookActionsMutationButtonStack_book"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "BookBookScrapPaginator_book"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookActionsMutationButtonStack_bookSubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookSubmit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitA",
            "kind": "LinkedField",
            "name": "unitAs",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publisher",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "edition",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "submittedBy",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "solutionPdf",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "problemPdf",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": "latestBookScraps",
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Literal",
                "name": "order",
                "value": {
                  "created": "DESC"
                }
              }
            ],
            "concreteType": "BookScrapConnection",
            "kind": "LinkedField",
            "name": "bookScraps",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BookScrapEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BookScrap",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "problemPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "solutionPage",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "bookScraps(first:1,order:{\"created\":\"DESC\"})"
          },
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BookScrapConnection",
            "kind": "LinkedField",
            "name": "bookScraps",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BookScrapEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "BookScrap",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v11/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnitD",
                        "kind": "LinkedField",
                        "name": "unitD",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "connection",
            "key": "BookBookScrapPaginator_book_bookScraps",
            "kind": "LinkedHandle",
            "name": "bookScraps"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ff7727003822241917a3acda82ddbd83",
    "id": null,
    "metadata": {},
    "name": "BookActionsMutationButtonStack_bookSubmitMutation",
    "operationKind": "mutation",
    "text": "mutation BookActionsMutationButtonStack_bookSubmitMutation(\n  $input: BookSubmitInput!\n) {\n  bookSubmit(input: $input) {\n    id\n    ...BookStatusLabel_book\n    ...BookDescriptionList_book\n    ...BookBookScrapCreateDialog_book\n    ...BookActionsMutationButtonStack_book\n    ...BookBookScrapPaginator_book\n  }\n}\n\nfragment BookActionsMutationButtonStack_book on Book {\n  id\n  actions\n  ...BookBookScrapCreateDialog_book\n}\n\nfragment BookBookScrapCreateDialog_book on Book {\n  id\n  title\n  solutionPdf {\n    id\n    objectUrl\n  }\n  problemPdf {\n    id\n    objectUrl\n  }\n  latestBookScraps: bookScraps(order: {created: DESC}, first: 1) {\n    totalCount\n    edges {\n      node {\n        id\n        problemPage\n        solutionPage\n        problemBookPage\n        solutionBookPage\n        problemNumber\n      }\n    }\n  }\n}\n\nfragment BookBookScrapPaginator_book on Book {\n  bookScraps {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        actions\n        problemBookPage\n        solutionBookPage\n        problemNumber\n        __typename\n      }\n    }\n    ...BookScrapConnectionDataTable_bookScrapConnection\n  }\n  id\n}\n\nfragment BookDescriptionList_book on Book {\n  type\n  title\n  unitAs {\n    id\n    title\n  }\n  publisher\n  edition\n  ...BookStatusLabel_book\n  createdBy {\n    id\n    ...UserAvatarText_user\n  }\n  submittedBy {\n    id\n    ...UserAvatarText_user\n  }\n  created\n}\n\nfragment BookScrapConnectionDataTable_bookScrapConnection on BookScrapConnection {\n  edges {\n    node {\n      id\n      actions\n      problemNumber\n      problemBookPage\n      solutionBookPage\n      unitD {\n        id\n        title\n      }\n    }\n  }\n}\n\nfragment BookStatusLabel_book on Book {\n  status\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "20359cd4727a9f6755f76047a221ff64";

export default node;
