/**
 * @generated SignedSource<<e03e7d86b916b38b81b8a817f31cc360>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BookScrapActionEnum = "book_scrap_delete" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BookScrapConnectionDataTable_bookScrapConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly actions: ReadonlyArray<BookScrapActionEnum>;
      readonly id: string;
      readonly problemBookPage: number;
      readonly problemNumber: string;
      readonly solutionBookPage: number;
      readonly unitD: {
        readonly id: string;
        readonly title: string;
      } | null;
    };
  }>;
  readonly " $fragmentType": "BookScrapConnectionDataTable_bookScrapConnection";
};
export type BookScrapConnectionDataTable_bookScrapConnection$key = {
  readonly " $data"?: BookScrapConnectionDataTable_bookScrapConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookScrapConnectionDataTable_bookScrapConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookScrapConnectionDataTable_bookScrapConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BookScrapEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookScrap",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "actions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "problemNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "problemBookPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "solutionBookPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UnitD",
              "kind": "LinkedField",
              "name": "unitD",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BookScrapConnection",
  "abstractKey": null
};
})();

(node as any).hash = "127833526201177329c34ab1ca6c86db";

export default node;
