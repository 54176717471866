import { CheckboxGroup as PrimerCheckboxGroup, Checkbox as PrimerCheckbox } from '@primer/react';
import { FieldConfig, useField } from 'formik';
import React, { ComponentProps, InputHTMLAttributes } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import ItemList from '../ItemList';
import View from '../View';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof PrimerCheckboxGroup.Label>;
  caption?: string;
  options: {
    id: NonNullable<string | number | undefined>;
    text: string | undefined;
    disabled?: boolean | undefined;
  }[];
  name: InputHTMLAttributes<HTMLInputElement>['name'];
  value?: Array<Props['options'][number]['id']>;
  onChange?: (selected: Array<Props['options'][number]['id']>, value: string) => void;
  renderOption?: (option: Props['options'][number]) => React.ReactNode;
  renderOptionWrapper?: (children: React.ReactNode, option: Props['options'][number]) => React.ReactNode;
  renderContainer?: (children: React.ReactNode) => React.ReactNode;
} & Omit<ComponentProps<typeof PrimerCheckboxGroup>, 'onChange'> &
  Pick<FieldConfig, 'validate'>;

const CheckboxGroupField = ({
  label,
  labelConfig,
  caption,
  disabled,
  required,
  options,
  name = '',
  onChange: propOnChange,
  value: propValue,
  renderContainer = (children) => children,
  renderOption = ({ text }) => text,
  renderOptionWrapper = (children, { id }) => <View key={id}>{children}</View>,
  validate,
  ...props
}: Props) => {
  const [{ value: baseValue = [] }, { error }, { setValue, setError }] = useField<Props['value']>({
    name,
    multiple: true,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (value ?? []).length === 0) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();

  const value = !isNullable(propValue) ? propValue : baseValue;

  const onChange: ComponentProps<typeof PrimerCheckboxGroup>['onChange'] = (_selected, e) => {
    if (!e) return;
    setValue(value.includes(e.target.value) ? value.filter((v) => v !== e.target.value) : [...value, e.target.value]);
  };
  const handleChange: ComponentProps<typeof PrimerCheckboxGroup>['onChange'] = (selected, e) => {
    setError(undefined);

    if (propOnChange) {
      if (!e) return;
      propOnChange(
        value.includes(e.target.value) ? value.filter((v) => v !== e.target.value) : [...value, e.target.value],
        e.target.value,
      );
    } else {
      onChange(selected, e);
    }
  };

  return (
    <PrimerCheckboxGroup disabled={disabled || isSubmitting} required={required} onChange={handleChange} {...props}>
      <PrimerCheckboxGroup.Label {...labelConfig} sx={{ fontSize: 1, fontWeight: 'bold' }}>
        {label}
      </PrimerCheckboxGroup.Label>
      {renderContainer(
        <ItemList
          items={options}
          renderItemWrapper={renderOptionWrapper}
          renderItem={(option) => {
            const { id } = option;
            return (
              <FormControl key={id} disabled={disabled} sx={{ alignItems: 'center' }}>
                <PrimerCheckbox value={String(id)} checked={value.includes(id)} />
                <FormControl.Label sx={{ fontWeight: 'normal' }}>{renderOption(option)}</FormControl.Label>
              </FormControl>
            );
          }}
        />,
      )}
      {error ? (
        <PrimerCheckboxGroup.Validation variant={'error'}>{error}</PrimerCheckboxGroup.Validation>
      ) : caption ? (
        <PrimerCheckboxGroup.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</PrimerCheckboxGroup.Caption>
      ) : null}
    </PrimerCheckboxGroup>
  );
};

export default CheckboxGroupField;
export type { Props as CheckboxGroupFieldProps };
