import { graphql, useFragment } from 'react-relay';

import {
  AppPushDescriptionList_appPush$data,
  AppPushDescriptionList_appPush$key,
} from '../../../relay/__generated__/AppPushDescriptionList_appPush.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import Text from '../../core/Text';
import AppPushStatusLabel from '../AppPushStatusLabel';

const AppPushDescriptionList_appPush = graphql`
  fragment AppPushDescriptionList_appPush on AppPush {
    id
    startAt
    pushTitle
    pushBody
    destination

    created
    ...AppPushStatusLabel_appPush
  }
`;

type Props = {
  appPush: AppPushDescriptionList_appPush$key;
  type?: 'default' | 'activity';
} & Omit<DescriptionListProps<AppPushDescriptionList_appPush$data>, 'item' | 'itemDescriptions' | 'picks'>;
const AppPushDescriptionList = ({ appPush: appPushReference, type = 'default', ...props }: Props) => {
  const appPush = useFragment(AppPushDescriptionList_appPush, appPushReference);

  return (
    <DescriptionList
      item={appPush}
      itemDescriptions={{
        startAt: {
          title: '시작일',
          renderValue: ({ startAt }) => <DateText fontSize={1}>{startAt}</DateText>,
        },
        pushTitle: {
          title: '제목',
          renderValue: ({ pushTitle }) => (
            <Text fontSize={1} whiteSpace={'pre-wrap'}>
              {pushTitle}
            </Text>
          ),
        },
        pushBody: {
          title: '본문',
          renderValue: ({ pushBody }) => (
            <Text fontSize={1} whiteSpace={'pre-wrap'}>
              {pushBody}
            </Text>
          ),
        },
        destination: {
          title: '딥링크',
          renderValue: ({ destination }) => (destination ? <Text fontSize={1}>{destination}</Text> : null),
        },
        created: {
          title: '제작일',
          renderValue: ({ created }) => <DateText fontSize={1}>{created}</DateText>,
        },
        status: {
          title: '상태',
          renderValue: (appPush) => <AppPushStatusLabel appPush={appPush} />,
        },
      }}
      picks={
        type === 'default'
          ? ['startAt', 'pushTitle', 'pushBody', 'destination']
          : type === 'activity'
          ? ['created', 'status']
          : []
      }
      {...props}
    />
  );
};

export default AppPushDescriptionList;
