/**
 * @generated SignedSource<<b4a480e13d325c382c1ccf52bb533742>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StuffTypeEnum = "coupon" | "delivery" | "gifticon" | "premium_non_refundable" | "premium_refundable" | "%future added value";
export type StuffUpdateInput = {
  description?: string | null;
  id: string;
  title?: string | null;
  type?: StuffTypeEnum | null;
};
export type StuffUpdateDialog_stuffUpdateMutation$variables = {
  input: StuffUpdateInput;
};
export type StuffUpdateDialog_stuffUpdateMutation$data = {
  readonly stuffUpdate: {
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly id: string;
      readonly name: string;
      readonly picture: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"StuffUpdateDialog_stuff">;
  };
};
export type StuffUpdateDialog_stuffUpdateMutation = {
  response: StuffUpdateDialog_stuffUpdateMutation$data;
  variables: StuffUpdateDialog_stuffUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "modifiedBy",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "picture",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StuffUpdateDialog_stuffUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stuff",
        "kind": "LinkedField",
        "name": "stuffUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StuffUpdateDialog_stuff"
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StuffUpdateDialog_stuffUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stuff",
        "kind": "LinkedField",
        "name": "stuffUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "62829152b03b76b4ecc78bfe0fd20013",
    "id": null,
    "metadata": {},
    "name": "StuffUpdateDialog_stuffUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation StuffUpdateDialog_stuffUpdateMutation(\n  $input: StuffUpdateInput!\n) {\n  stuffUpdate(input: $input) {\n    id\n    ...StuffUpdateDialog_stuff\n    modifiedBy {\n      id\n      name\n      picture\n    }\n    modified\n  }\n}\n\nfragment StuffUpdateDialog_stuff on Stuff {\n  id\n  title\n  description\n  type\n}\n"
  }
};
})();

(node as any).hash = "3ceda29a8cc94c74e03d202677b382ce";

export default node;
