/**
 * @generated SignedSource<<f7d4c487dd780f239c3e063934c808f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkbookTasksAddDialog_workbook$data = {
  readonly grade: ScrapSourceExamGradeEnum;
  readonly id: string;
  readonly tasks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
    readonly totalCount: number | null;
    readonly " $fragmentSpreads": FragmentRefs<"WorkbookTaskConnectionDataTable_taskConnection">;
  };
  readonly " $fragmentType": "WorkbookTasksAddDialog_workbook";
};
export type WorkbookTasksAddDialog_workbook$key = {
  readonly " $data"?: WorkbookTasksAddDialog_workbook$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkbookTasksAddDialog_workbook">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkbookTasksAddDialog_workbook",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grade",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskConnection",
      "kind": "LinkedField",
      "name": "tasks",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Task",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "WorkbookTaskConnectionDataTable_taskConnection"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Workbook",
  "abstractKey": null
};
})();

(node as any).hash = "62d4e7c8b81e3038815b03f7e53bd08c";

export default node;
