import { graphql, useFragment } from 'react-relay';

import {
  ProblemGeneratorConnectionDataTable_problemGeneratorConnection$data,
  ProblemGeneratorConnectionDataTable_problemGeneratorConnection$key,
} from '../../../relay/__generated__/ProblemGeneratorConnectionDataTable_problemGeneratorConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import ProblemGeneratorStatusLabel from '../ProblemGeneratorStatusLabel';

const ProblemGeneratorConnectionDataTable_problemGeneratorConnection = graphql`
  fragment ProblemGeneratorConnectionDataTable_problemGeneratorConnection on ProblemGeneratorConnection {
    edges {
      node {
        id
        ...ProblemGeneratorStatusLabel_problemGenerator

        publishedProblemsCount
        activeProblemsCount

        originalTask {
          id
          sequence
          unitA {
            id
            title
          }
          unitD {
            id
            description
          }
        }
        changed

        assignedTo {
          id
          ...UserAvatarText_user
        }
      }
    }
  }
`;

type Props = {
  problemGeneratorConnection: ProblemGeneratorConnectionDataTable_problemGeneratorConnection$key;
} & Omit<
  DataTableProps<ProblemGeneratorConnectionDataTable_problemGeneratorConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const ProblemGeneratorConnectionDataTable = ({ problemGeneratorConnection, ...props }: Props) => {
  const { edges } = useFragment(
    ProblemGeneratorConnectionDataTable_problemGeneratorConnection,
    problemGeneratorConnection,
  );

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (node) => <ProblemGeneratorStatusLabel problemGenerator={node} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'originalTask.sequence',
          title: '원본 문제 ID',
          renderValue: ({ originalTask }) => <Text fontSize={1}>{originalTask.sequence}</Text>,
          width: 92,
        },
        {
          field: 'activeProblemsCount',
          title: '생성된 문제',
          renderValue: ({ activeProblemsCount }) => <Text fontSize={1}>{activeProblemsCount || 0}문제</Text>,
          width: 90,
        },
        {
          field: 'publishedProblemsCount',
          title: '출시된 문제',
          renderValue: ({ publishedProblemsCount }) => <Text fontSize={1}>{publishedProblemsCount || 0}문제</Text>,
          width: 90,
        },
        {
          field: 'originalTask.unitA',
          title: 'Unit A',
          renderValue: ({ originalTask }) => <Text fontSize={1}>{originalTask.unitA?.title || '-'}</Text>,
          width: 80,
        },
        {
          field: 'originalTask.unitD',
          title: 'Unit D',
          renderValue: ({ originalTask }) => <Text fontSize={1}>{originalTask.unitD?.description || '-'}</Text>,
          width: 180,
        },
        {
          field: 'changed',
          title: '변경일',
          renderValue: ({ changed }) => <DateText fontSize={1}>{changed}</DateText>,
          width: 180,
        },
        {
          field: 'assignedTo',
          title: '작업자',
          renderValue: ({ assignedTo }) => (assignedTo ? <UserAvatarText user={assignedTo} /> : '-'),
          width: 92,
        },
      ]}
      {...props}
    />
  );
};

export default ProblemGeneratorConnectionDataTable;
