import { useRouter } from 'next/router';
import { graphql } from 'react-relay';

import CodepushVersionDescriptionList from '../../components/codepushVersion/CodepushVersionDescriptionList';
import CodepushVersionUpdateDialog from '../../components/codepushVersion/CodepushVersionUpdateDialog';
import Card from '../../components/core/Card';
import DialogButton from '../../components/core/DialogButton';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationConfirmButton from '../../components/core/MutationConfirmButton';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { CodepushVersionId_codepushVersionProductionUpdateMutation } from '../../relay/__generated__/CodepushVersionId_codepushVersionProductionUpdateMutation.graphql';
import { CodepushVersionId_codepushVersionQuery } from '../../relay/__generated__/CodepushVersionId_codepushVersionQuery.graphql';
import { CodepushVersionId_codepushVersionStagingUpdateMutation } from '../../relay/__generated__/CodepushVersionId_codepushVersionStagingUpdateMutation.graphql';
import { NextPage } from '../_app';

const codepushVersionForCodepushVersionId = graphql`
  query CodepushVersionId_codepushVersionQuery($id: ID!) {
    codepushVersion(id: $id) {
      id
      version
      targetBinaryVersion
      actions
      ...CodepushVersionStatusLabel_codepushVersion
      ...CodepushVersionDescriptionList_codepushVersion
      ...CodepushVersionUpdateDialog_codepushVersion
    }
  }
`;

type Props = {};

const CodepushVersionId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ codepushVersion }] = useLazyLoadQuery<CodepushVersionId_codepushVersionQuery>(
    codepushVersionForCodepushVersionId,
    { id: router.query.codepushVersionId as string },
  );
  if (!codepushVersion) return null;
  const { id, actions, version, targetBinaryVersion } = codepushVersion;

  return (
    <View>
      <Head siteTitle={`코드 푸시 - ${targetBinaryVersion}.${version}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{`${targetBinaryVersion}.${version}`}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              {actions.includes('codepush_version_update_production') ? (
                <Stack.Item>
                  <MutationConfirmButton<CodepushVersionId_codepushVersionProductionUpdateMutation>
                    mutation={graphql`
                      mutation CodepushVersionId_codepushVersionProductionUpdateMutation(
                        $input: CodepushVersionProductionUpdateInput!
                      ) {
                        codepushVersionProductionUpdate(input: $input) {
                          id
                          actions
                          ...CodepushVersionStatusLabel_codepushVersion
                        }
                      }
                    `}
                    input={{ id }}
                    size={'large'}
                    message={'정말 출시할까요?'}
                  >
                    출시하기
                  </MutationConfirmButton>
                </Stack.Item>
              ) : actions.includes('codepush_version_update_staging') ? (
                <Stack.Item>
                  <MutationConfirmButton<CodepushVersionId_codepushVersionStagingUpdateMutation>
                    mutation={graphql`
                      mutation CodepushVersionId_codepushVersionStagingUpdateMutation(
                        $input: CodepushVersionStagingUpdateInput!
                      ) {
                        codepushVersionStagingUpdate(input: $input) {
                          id
                          actions
                          ...CodepushVersionStatusLabel_codepushVersion
                        }
                      }
                    `}
                    input={{ id }}
                    size={'large'}
                    variant={'danger'}
                    message={'정말 출시를 중단할까요?'}
                  >
                    출시 중단하기
                  </MutationConfirmButton>
                </Stack.Item>
              ) : null}
              <Stack.Item>
                <DialogButton
                  renderDialog={({ isOpen, closeDialog }) => (
                    <CodepushVersionUpdateDialog
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      codepushVersion={codepushVersion}
                      wide
                      config={{
                        onCompleted: () => {
                          toast('코드푸시가 수정됐어요', 'success');
                          closeDialog();
                        },
                        onError: () => {
                          toast('코드푸시를 수정하지 못했어요', 'error');
                        },
                      }}
                    />
                  )}
                  variant={'primary'}
                  size={'large'}
                >
                  수정하기
                </DialogButton>
              </Stack.Item>
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <View>
              <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>상세 정보</Text>
              <Card sx={{ marginTop: 2, padding: 3 }}>
                <CodepushVersionDescriptionList
                  codepushVersion={codepushVersion}
                  titleUnitSize={1 / 4}
                  descriptionUnitSize={3 / 4}
                />
              </Card>
            </View>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <CodepushVersionDescriptionList
              codepushVersion={codepushVersion}
              type={'activity'}
              titleUnitSize={'max'}
              descriptionUnitSize={'min'}
              renderTitle={(value) => <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{value}</Text>}
            />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

CodepushVersionId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
CodepushVersionId.authenticated = true;
CodepushVersionId.routes = [
  {
    id: 'codepushVersionId',
    pathname: '/codepushVersion/[codepushVersionId]',
    name: '코드푸시 상세',
    permissions: ['codepush_version_read'],
  },
];

export default CodepushVersionId;
