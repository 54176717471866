import { graphql } from 'react-relay';

import { StoreStuffPaginator_query$key } from '../../../relay/__generated__/StoreStuffPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const StoreStuffPaginator_query = graphql`
  fragment StoreStuffPaginator_query on Query
  @argumentDefinitions(
    filters: { type: StoreStuffFilter }
    order: { type: StoreStuffOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "StoreStuffPaginatorRefreshQuery") {
    storeStuffs(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "StoreStuffPaginator_query_storeStuffs") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          stuff {
            id
            title
          }
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<StoreStuffPaginator_query$key>, 'fragment'>;

const StoreStuffPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={StoreStuffPaginator_query} {...props} />
);

export default StoreStuffPaginator;
