import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import { StuffCreateDialog_stuffCreateMutation } from '../../../relay/__generated__/StuffCreateDialog_stuffCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';

type Props = {} & DialogProps &
  Pick<MutationFormikProps<StuffCreateDialog_stuffCreateMutation>, 'config' | 'onSubmit' | 'connections'>;

const StuffCreateDialog = ({ config, onSubmit, connections, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>새로운 물품 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<StuffCreateDialog_stuffCreateMutation>
        mutation={graphql`
          mutation StuffCreateDialog_stuffCreateMutation($input: StuffCreateInput!) {
            stuffCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{
          title: '',
          description: '',
          type: 'delivery',
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField name={'title'} label={'제목'} placeholder={'제목 입력'} required />
                <TextareaField name={'description'} label={'설명'} placeholder={'설명 입력'} />
                <Suspense>
                  <EnumPairRadioGroupField typename={'StuffTypeEnum'} name={'type'} label={'타입'} required />
                </Suspense>
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default StuffCreateDialog;
