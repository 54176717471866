import { useRouter } from 'next/router';
import { graphql } from 'react-relay';

import DialogHandler from '../../components/core/DialogHandler';
import EnumPair from '../../components/core/EnumPair';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import Image from '../../components/core/Image';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Lightbox from '../../components/core/Lightbox';
import SquareView from '../../components/core/SquareView';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import SchoolExamProblemActionsMutationButtonStack from '../../components/schoolExamProblem/SchoolExamProblemActionsMutationButtonStack';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import { SchoolExamProblemId_schoolExamProblemQuery } from '../../relay/__generated__/SchoolExamProblemId_schoolExamProblemQuery.graphql';
import { NextPage } from '../_app';

const schoolExamProblemForSchoolExamProblemId = graphql`
  query SchoolExamProblemId_schoolExamProblemQuery($id: ID!) {
    schoolExamProblem(id: $id) {
      id
      sequence
      ...SchoolExamProblemActionsMutationButtonStack_schoolExamProblem
      userName
      userSchoolName
      problemSchoolYear
      problemSubject
      problemExamYear
      problemSchoolName
      title
      images {
        edges {
          node {
            id
            url
          }
        }
      }
    }
  }
`;

type Props = {};

const SchoolExamProblemId: NextPage<Props> = () => {
  const router = useRouter();

  const [{ schoolExamProblem }] = useLazyLoadQuery<SchoolExamProblemId_schoolExamProblemQuery>(
    schoolExamProblemForSchoolExamProblemId,
    {
      id: router.query.schoolExamProblemId as string,
    },
  );
  if (!schoolExamProblem) return null;
  const {
    sequence,
    userName,
    userSchoolName,
    problemSchoolYear,
    problemSubject,
    problemExamYear,
    problemSchoolName,
    title,
    images,
  } = schoolExamProblem;

  return (
    <View>
      <Head siteTitle={`시험지 검수 - ${sequence}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{sequence}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <SchoolExamProblemActionsMutationButtonStack schoolExamProblem={schoolExamProblem} />
          </Grid.Unit>
        </Grid>
        <HorizontalDivider />
        <View sx={{ marginTop: 5 }}>
          <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>유저 정보</Text>
          <View
            sx={{
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: 'border.default',
              borderRadius: 2,
              padding: 3,
              marginTop: 3,
            }}
          >
            <ItemList
              items={[
                { id: 'userName', title: '닉네임', description: userName },
                { id: 'userSchoolName', title: '학교', description: userSchoolName },
                {
                  id: 'problemSchoolYear',
                  title: '학년',
                  description: <EnumPair typename="SchoolExamProblemSchoolYearEnum">{problemSchoolYear}</EnumPair>,
                },
              ]}
              renderItem={({ title, description }) => (
                <Grid gapX={3}>
                  <Grid.Unit size={[1, 1, 1 / 12]}>
                    <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 11 / 12]}>
                    <Text sx={{ fontSize: 1 }}>{description}</Text>
                  </Grid.Unit>
                </Grid>
              )}
              renderItemWrapper={(children, { id }, i) => (
                <View key={id} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                  {children}
                </View>
              )}
            />
          </View>
          <View sx={{ marginTop: 5 }}>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>시험지 정보</Text>
            <View
              sx={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'border.default',
                borderRadius: 2,
                padding: 3,
                marginTop: 3,
              }}
            >
              <ItemList
                items={[
                  {
                    id: 'problemSubject',
                    title: '과목',
                    description: <EnumPair typename="SchoolExamProblemSubjectEnum">{problemSubject}</EnumPair>,
                  },
                  { id: 'problemExamYear', title: '연도', description: problemExamYear },
                  { id: 'problemSchoolName', title: '학교', description: problemSchoolName },
                  { id: 'title', title: '학기 및 시험', description: title },
                ]}
                renderItem={({ title, description }) => (
                  <Grid gapX={3}>
                    <Grid.Unit size={[1, 1, 1 / 12]}>
                      <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                    </Grid.Unit>
                    <Grid.Unit size={[1, 1, 11 / 12]}>
                      <Text sx={{ fontSize: 1 }}>{description}</Text>
                    </Grid.Unit>
                  </Grid>
                )}
                renderItemWrapper={(children, { id }, i) => (
                  <View key={id} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                    {children}
                  </View>
                )}
              />
            </View>
          </View>
          <View sx={{ marginTop: 5 }}>
            <Text sx={{ fontWeight: 'bold' }}>이미지</Text>
            <View sx={{ marginTop: 3 }}>
              <Grid gapX={3} gapY={3}>
                <ItemList
                  items={images.edges}
                  renderItem={({ node: { url } }, i) => (
                    <SquareView
                      sx={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'border.default',
                        borderRadius: 2,
                        backgroundColor: 'neutral.emphasisPlus',
                      }}
                    >
                      <View
                        sx={{
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Image src={url} alt={url} fill style={{ objectFit: 'contain' }} />
                        <View
                          sx={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            left: 0,
                            padding: 2,
                          }}
                        >
                          <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.onEmphasis' }}>{i + 1} 페이지</Text>
                        </View>
                      </View>
                    </SquareView>
                  )}
                  renderItemWrapper={(children, { node: { id } }, i) => (
                    <Grid.Unit key={id} size={[1 / 2, 1 / 2, 1 / 3]}>
                      <DialogHandler
                        renderDialog={({ isOpen, closeDialog }) => (
                          <Lightbox
                            isOpen={isOpen}
                            onDismiss={() => closeDialog()}
                            items={images.edges.map(({ node }) => node)}
                            getImage={({ url }) => url}
                            initialIndex={i}
                          />
                        )}
                      >
                        {children}
                      </DialogHandler>
                    </Grid.Unit>
                  )}
                />
              </Grid>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

SchoolExamProblemId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
SchoolExamProblemId.authenticated = true;
SchoolExamProblemId.routes = [
  {
    id: 'schoolExamProblemId',
    name: '시험지 검수 상세',
    pathname: '/schoolExamProblem/[schoolExamProblemId]',
    permissions: ['school_exam_problem_read'],
  },
];

export default SchoolExamProblemId;
