import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import { AppDeepLinkSelectField_appDeepLinksQuery } from '../../../relay/__generated__/AppDeepLinkSelectField_appDeepLinksQuery.graphql';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const appDeepLinksForAppDeepLinkSelectField = graphql`
  query AppDeepLinkSelectField_appDeepLinksQuery {
    appDeepLinks {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`;

type Props = {} & Omit<SelectFieldProps, 'options'>;

const AppDeepLinkSelectField = ({ ...props }: Props) => {
  const [{ appDeepLinks }] = useLazyLoadQuery<AppDeepLinkSelectField_appDeepLinksQuery>(
    appDeepLinksForAppDeepLinkSelectField,
    {},
  );

  return (
    <SelectField
      options={appDeepLinks.edges.map(({ node }) => ({
        id: node.title,
        text: `${node.description || ''} (${node.title})`,
      }))}
      {...props}
    />
  );
};

export default AppDeepLinkSelectField;
