import React from 'react';
import { fetchQuery, graphql, useRelayEnvironment } from 'react-relay';

import {
  UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery,
  UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery$data,
  UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery$variables,
} from '../../../relay/__generated__/UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery.graphql';
import Formik, { FormikConfig } from '../../core/Formik';

const unitDefaultRatingForUnitDefaultRatingFetchQueryFormik = graphql`
  query UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery(
    $schoolGrade: UnitSchoolGradeEnum!
    $schoolYear: UnitSchoolYearEnum!
  ) {
    unitDefaultRating(schoolGrade: $schoolGrade, schoolYear: $schoolYear)
  }
`;

type Props = {
  onCompleteFetch: (data: UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery$data) => void;
  onSubmit?: FormikConfig<UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery$variables>['onSubmit'];
} & Omit<FormikConfig<UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery$variables>, 'onSubmit'>;

const UnitDefaultRatingFetchQueryFormik = ({ onCompleteFetch, onSubmit, ...props }: Props) => {
  const environment = useRelayEnvironment();

  return (
    <Formik<UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery$variables>
      onSubmit={(values, formikHelpers) => {
        onSubmit?.(values, formikHelpers);
        fetchQuery<UnitDefaultRatingFetchQueryFormik_unitDefaultRatingQuery>(
          environment,
          unitDefaultRatingForUnitDefaultRatingFetchQueryFormik,
          { ...values },
        )
          .toPromise()
          .then((data) => {
            if (data) onCompleteFetch(data);
          })
          .finally(() => formikHelpers.setSubmitting(false));
      }}
      {...props}
    />
  );
};

export default UnitDefaultRatingFetchQueryFormik;
