import { FormValidationStatus } from '@primer/react/lib/utils/types/FormValidationStatus';
import { FieldConfig, useField } from 'formik';
import { ComponentProps } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import SQLEditor, { SQLEditorProps } from '../SQLEditor';
import View from '../View';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  required?: boolean;
  caption?: string;
  name?: string;
  validationStatus?: FormValidationStatus;
} & Omit<SQLEditorProps, 'sx'> &
  Pick<FieldConfig, 'validate'>;

const SQLEditorField = ({
  onChange: propOnChange,
  value: propValue,
  name = '',
  label,
  labelConfig,
  caption,
  required,
  disabled: propDisabled,
  validationStatus,
  validate,
  ...props
}: Props) => {
  const INPUT_ID = `SQL_editor_field_for_${label}`;

  const [{ value: baseValue }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 입력해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();

  const value = !isNullable(propValue) ? propValue : baseValue;

  const disabled = propDisabled || isSubmitting;

  const onChange: SQLEditorProps['onChange'] = (value) => {
    setValue(value);
  };
  const handleChange: SQLEditorProps['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else onChange(...arg);
  };

  return (
    <FormControl required={required} disabled={disabled}>
      <FormControl.Label {...labelConfig} htmlFor={INPUT_ID}>
        {label}
      </FormControl.Label>
      <View sx={{ width: '100%' }}>
        <SQLEditor
          id={INPUT_ID}
          disabled={disabled}
          value={value}
          onChange={handleChange}
          sx={{
            ...(error || validationStatus
              ? {
                  borderColor:
                    error || validationStatus === 'error'
                      ? 'danger.fg'
                      : validationStatus === 'warning'
                      ? 'attention.fg'
                      : 'success.fg',
                }
              : {}),
          }}
          {...props}
        />
      </View>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default SQLEditorField;
