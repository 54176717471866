import { CheckIcon, PencilIcon, RocketIcon, UnverifiedIcon, VerifiedIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory$key } from '../../../relay/__generated__/ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory.graphql';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Label from '../../core/Label';
import Timeline from '../../core/Timeline';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';

type Props = {
  problemGeneratorHistory: ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory$key;
};

const ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory = graphql`
  fragment ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory on ProblemGeneratorHistory {
    created
    createdBy {
      id
      ...UserAvatarText_user
    }
    type
  }
`;

const ProblemGeneratorHistoryTimelineItem = ({ problemGeneratorHistory }: Props) => {
  const { created, createdBy, type } = useFragment(
    ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory,
    problemGeneratorHistory,
  );

  return (
    <Timeline.Item>
      <Timeline.Badge>
        {type === 'deactivate' ? (
          <UnverifiedIcon />
        ) : type === 'activate' ? (
          <VerifiedIcon />
        ) : type === 'publish' ? (
          <RocketIcon />
        ) : type === 'create' ? (
          <PencilIcon />
        ) : (
          <CheckIcon />
        )}
      </Timeline.Badge>
      <Timeline.Body>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Grid.Unit size={'max'}>
              <View sx={{ display: 'flex', flexDirection: 'column' }}>
                <UserAvatarText user={createdBy} sx={{ fontWeight: 'bold', color: 'fg.muted' }} />
                <View sx={{ marginTop: 2 }}>
                  <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
                </View>
              </View>
            </Grid.Unit>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Label
              variant={
                type === 'deactivate'
                  ? 'danger'
                  : type === 'activate'
                  ? 'secondary'
                  : type === 'publish'
                  ? 'success'
                  : type === 'create'
                  ? 'secondary'
                  : 'default'
              }
            >
              <EnumPair typename={'ProblemGeneratorHistoryTypeEnum'}>{type}</EnumPair>
            </Label>
          </Grid.Unit>
        </Grid>
      </Timeline.Body>
    </Timeline.Item>
  );
};

export default ProblemGeneratorHistoryTimelineItem;
