/**
 * @generated SignedSource<<9f8d32b5450dcd1871ad0671244af242>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StuffTypeEnum = "coupon" | "delivery" | "gifticon" | "premium_non_refundable" | "premium_refundable" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StuffConnectionDataTable_stuffConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly id: string;
      readonly modified: any;
      readonly title: string;
      readonly type: StuffTypeEnum;
    };
  }>;
  readonly " $fragmentType": "StuffConnectionDataTable_stuffConnection";
};
export type StuffConnectionDataTable_stuffConnection$key = {
  readonly " $data"?: StuffConnectionDataTable_stuffConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"StuffConnectionDataTable_stuffConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StuffConnectionDataTable_stuffConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StuffEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Stuff",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modified",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StuffConnection",
  "abstractKey": null
};
})();

(node as any).hash = "9bf68d9a899b7bac019e0f7369a914a4";

export default node;
