/**
 * @generated SignedSource<<4b728ecf80b37e7f034d614fb1cc0777>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskStatusEnum = "deactivated" | "error_report" | "first_labeling" | "image_creation" | "problem_creation" | "problem_typing" | "published" | "review" | "second_labeling" | "%future added value";
export type TaskAssignInput = {
  assignedTo: string;
  status?: TaskStatusEnum | null;
  tasks: ReadonlyArray<string>;
};
export type TaskAssignDialog_taskAssignMutation$variables = {
  input: TaskAssignInput;
};
export type TaskAssignDialog_taskAssignMutation$data = {
  readonly taskAssign: ReadonlyArray<{
    readonly assigned: any | null;
    readonly assignedTo: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly id: string;
    readonly showLabeling: boolean;
    readonly showProblem: boolean;
    readonly showSolutionVideo: boolean;
    readonly showVideo: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"TaskActionsMutationButtonStack_task" | "TaskStatusLabel_task">;
  }>;
};
export type TaskAssignDialog_taskAssignMutation = {
  response: TaskAssignDialog_taskAssignMutation$data;
  variables: TaskAssignDialog_taskAssignMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assigned",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showProblem",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showLabeling",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showVideo",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "showSolutionVideo",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskAssignDialog_taskAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "taskAssign",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "assignedTo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaskActionsMutationButtonStack_task"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaskStatusLabel_task"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskAssignDialog_taskAssignMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Task",
        "kind": "LinkedField",
        "name": "taskAssign",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "assignedTo",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskHint",
            "kind": "LinkedField",
            "name": "hint",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "text",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskSubmitFeedback",
            "kind": "LinkedField",
            "name": "submitFeedback",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "specialAnswer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "special",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "general",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskProblem",
            "kind": "LinkedField",
            "name": "problem",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "problem",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "solution",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "54f7a97f64350b20a03f7a1894046d12",
    "id": null,
    "metadata": {},
    "name": "TaskAssignDialog_taskAssignMutation",
    "operationKind": "mutation",
    "text": "mutation TaskAssignDialog_taskAssignMutation(\n  $input: TaskAssignInput!\n) {\n  taskAssign(input: $input) {\n    id\n    assigned\n    assignedTo {\n      id\n      ...UserAvatarText_user\n    }\n    showProblem\n    showLabeling\n    showVideo\n    showSolutionVideo\n    ...TaskActionsMutationButtonStack_task\n    ...TaskStatusLabel_task\n  }\n}\n\nfragment TaskActionsMutationButtonStack_task on Task {\n  id\n  actions\n  ...TaskHintFeedbackPublishDialog_task\n  problem {\n    id\n    problem\n    solution\n  }\n}\n\nfragment TaskHintFeedbackPublishDialog_task on Task {\n  id\n  hint {\n    id\n    text\n  }\n  submitFeedback {\n    id\n    specialAnswer\n    special\n    general\n  }\n}\n\nfragment TaskStatusLabel_task on Task {\n  status\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "a4e09cc70d401d446923fee21f68ea66";

export default node;
