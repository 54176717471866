import { Suspense } from 'react';
import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery,
  TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery$variables,
} from '../../../relay/__generated__/TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery.graphql';
import Grid from '../../core/Grid';
import Image from '../../core/Image';
import RadioGroupField, { RadioGroupFieldProps } from '../../core/RadioGroupField';
import Spinner from '../../core/Spinner';

const taskVideoThumbnailsForTaskVideoThumbnailRadioGroupField = graphql`
  query TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery($filters: TaskVideoThumbnailFilters!) {
    taskVideoThumbnails(filters: $filters) {
      key
      url
    }
  }
`;

type Props = {
  taskVideoId: TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery$variables['filters']['taskVideo'];
  filters: Omit<TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery$variables['filters'], 'taskVideo'>;
} & Omit<RadioGroupFieldProps, 'options'>;

const TaskVideoThumbnailRadioGroupField = ({ taskVideoId, filters, ...props }: Props) => {
  const [{ taskVideoThumbnails }] = useLazyLoadQuery<TaskVideoThumbnailRadioGroupField_taskVideoThumbnailsQuery>(
    taskVideoThumbnailsForTaskVideoThumbnailRadioGroupField,
    { filters: { taskVideo: taskVideoId, ...filters } },
  );

  return (
    <Suspense fallback={<Spinner />}>
      <RadioGroupField
        options={taskVideoThumbnails.map(({ key, url }) => ({ id: key, text: url }))}
        renderContainer={(children) => (
          <Grid gapX={2} gapY={2}>
            {children}
          </Grid>
        )}
        renderOption={({ text }) => (
          <Image
            src={text!}
            alt={text!}
            {...(filters.type === 'horizontal' ? { width: 288, height: 162 } : { width: 162, height: 288 })}
            style={{ width: '100%', height: 'auto' }}
          />
        )}
        renderOptionWrapper={(children, { id }) => (
          <Grid.Unit key={id} size={[1, 1, 1 / 2]}>
            {children}
          </Grid.Unit>
        )}
        {...props}
      />
    </Suspense>
  );
};

export default TaskVideoThumbnailRadioGroupField;
