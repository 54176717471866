/**
 * @generated SignedSource<<d2fa09914bbc8bfc0ac323137c1c32ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskSolutionVideoGrid_taskSolutionVideo$data = {
  readonly hlsUrl: string;
  readonly thumbnailHorizontal: {
    readonly id: string;
    readonly url: string;
  } | null;
  readonly thumbnailVertical: {
    readonly id: string;
    readonly url: string;
  } | null;
  readonly " $fragmentType": "TaskSolutionVideoGrid_taskSolutionVideo";
};
export type TaskSolutionVideoGrid_taskSolutionVideo$key = {
  readonly " $data"?: TaskSolutionVideoGrid_taskSolutionVideo$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskSolutionVideoGrid_taskSolutionVideo">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskSolutionVideoGrid_taskSolutionVideo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hlsUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoThumbnail",
      "kind": "LinkedField",
      "name": "thumbnailHorizontal",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoThumbnail",
      "kind": "LinkedField",
      "name": "thumbnailVertical",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "TaskSolutionVideo",
  "abstractKey": null
};
})();

(node as any).hash = "f697d8063f9de1ca153f014d8c8a04b3";

export default node;
