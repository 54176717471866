import { useField } from 'formik';
import { Suspense, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import usePaginatorSearchOverlayQueryLoader from '../../../hooks/usePaginatorSearchOverlayQueryLoader';
import {
  StoreStuffPaginatorSearchOverlayTokenField_storeStuffsQuery,
  StoreStuffPaginatorSearchOverlayTokenField_storeStuffsQuery$variables,
} from '../../../relay/__generated__/StoreStuffPaginatorSearchOverlayTokenField_storeStuffsQuery.graphql';
import { isNullable } from '../../../utils/is';
import Checkbox from '../../core/Checkbox';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import ScrollContainer from '../../core/ScrollContainer';
import SearchOverlayTokenField, { SearchOverlayTokenFieldProps } from '../../core/SearchOverlayTokenField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';
import StoreStuffPaginator from '../StoreStuffPaginator';

const storeStuffsForStoreStuffPaginatorSearchOverlayTokenField = graphql`
  query StoreStuffPaginatorSearchOverlayTokenField_storeStuffsQuery(
    $filters: StoreStuffFilter
    $order: StoreStuffOrder
    $first: Int
    $after: String
  ) {
    storeStuffs(filters: $filters, order: $order, first: $first, after: $after) {
      edges {
        node {
          id
          title
          stuff {
            id
            title
          }
        }
      }
    }
    ...StoreStuffPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

type Props = {
  variables?: StoreStuffPaginatorSearchOverlayTokenField_storeStuffsQuery$variables;
} & Omit<SearchOverlayTokenFieldProps, 'renderItemList'>;

const StoreStuffPaginatorSearchOverlayTokenField = ({ name = '', value: propValue, variables, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string[] | undefined>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [valueQueryReference, loadValueQuery, disposeValueQuery] =
    useQueryLoader<StoreStuffPaginatorSearchOverlayTokenField_storeStuffsQuery>(
      storeStuffsForStoreStuffPaginatorSearchOverlayTokenField,
    );
  useEffect(() => {
    loadValueQuery({ filters: { id_In: value || [] } });
    return () => disposeValueQuery();
  }, [value, loadValueQuery, disposeValueQuery]);

  const [searchOverlayFieldProps, queryReference] =
    usePaginatorSearchOverlayQueryLoader<StoreStuffPaginatorSearchOverlayTokenField_storeStuffsQuery>(
      storeStuffsForStoreStuffPaginatorSearchOverlayTokenField,
      variables,
    );

  return valueQueryReference ? (
    <PreloadedQueryRenderer
      query={storeStuffsForStoreStuffPaginatorSearchOverlayTokenField}
      queryReference={valueQueryReference}
    >
      {({ storeStuffs }) => (
        <SearchOverlayTokenField
          name={name}
          value={value}
          renderTokenText={(id) => {
            const storeStuff = storeStuffs?.edges.find(({ node }) => node.id === id)?.node;
            return storeStuff ? `${storeStuff.title}${storeStuff.stuff ? ` / ${storeStuff.stuff.title}` : ''}` : '';
          }}
          renderItemList={({ containerRef, handleSelect }, itemProps) =>
            queryReference ? (
              <Suspense fallback={<Spinner sx={{ paddingY: 3 }} />}>
                <PreloadedQueryRenderer
                  query={storeStuffsForStoreStuffPaginatorSearchOverlayTokenField}
                  queryReference={queryReference}
                >
                  {(queryData) => (
                    <StoreStuffPaginator fragmentReference={queryData}>
                      {(data, { hasNext, isLoadingNext, loadMore }) => (
                        <ScrollContainer
                          ref={containerRef as React.RefObject<HTMLDivElement>}
                          onScrollReachEnd={() => {
                            if (hasNext && !isLoadingNext) loadMore(20);
                          }}
                        >
                          <ItemList
                            items={data.storeStuffs.edges}
                            renderItem={({ node: { title, stuff } }) => (
                              <Text sx={{ fontSize: 1 }}>{`${title}${stuff ? ` / ${stuff.title}` : ''}`}</Text>
                            )}
                            renderItemWrapper={(children, { node: { id } }) => (
                              <View
                                {...itemProps}
                                key={id}
                                onClick={(e) => handleSelect(e, id)}
                                onKeyDown={(e) => handleSelect(e, id)}
                              >
                                <Grid gapX={2} wrap={false}>
                                  <Grid.Unit size={'min'}>
                                    <Checkbox checked={value?.some((selectedId) => selectedId === id)} />
                                  </Grid.Unit>
                                  <Grid.Unit size={'max'}>{children}</Grid.Unit>
                                </Grid>
                              </View>
                            )}
                            emptyState={
                              <View sx={{ paddingY: 3 }}>
                                <EmptyState
                                  title={'검색 결과가 없어요'}
                                  description={'다른 검색어로 다시 시도해주세요.'}
                                />
                              </View>
                            }
                          />
                          <View>{isLoadingNext ? <Spinner size={'small'} /> : null}</View>
                        </ScrollContainer>
                      )}
                    </StoreStuffPaginator>
                  )}
                </PreloadedQueryRenderer>
              </Suspense>
            ) : null
          }
          {...searchOverlayFieldProps}
          {...props}
        />
      )}
    </PreloadedQueryRenderer>
  ) : (
    <Spinner size={'small'} />
  );
};

export default StoreStuffPaginatorSearchOverlayTokenField;
