import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { StuffUpdateDialog_stuff$key } from '../../../relay/__generated__/StuffUpdateDialog_stuff.graphql';
import { StuffUpdateDialog_stuffUpdateMutation } from '../../../relay/__generated__/StuffUpdateDialog_stuffUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';

const StuffUpdateDialog_stuff = graphql`
  fragment StuffUpdateDialog_stuff on Stuff {
    id
    title
    description
    type
  }
`;

type Props = { stuff: StuffUpdateDialog_stuff$key } & DialogProps &
  Pick<MutationFormikProps<StuffUpdateDialog_stuffUpdateMutation>, 'config' | 'onSubmit' | 'connections'>;

const StuffUpdateDialog = ({ stuff, config, onSubmit, connections, ...props }: Props) => {
  const { id, title, description, type } = useFragment(StuffUpdateDialog_stuff, stuff);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>물품 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<StuffUpdateDialog_stuffUpdateMutation>
        mutation={graphql`
          mutation StuffUpdateDialog_stuffUpdateMutation($input: StuffUpdateInput!) {
            stuffUpdate(input: $input) {
              id
              ...StuffUpdateDialog_stuff
              modifiedBy {
                id
                name
                picture
              }
              modified
            }
          }
        `}
        initialValues={{
          id,
          title,
          description,
          type,
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField name={'title'} label={'제목'} placeholder={'제목 입력'} required />
                <TextareaField name={'description'} label={'설명'} placeholder={'설명 입력'} />
                <Suspense>
                  <EnumPairRadioGroupField typename={'StuffTypeEnum'} name={'type'} label={'타입'} required />
                </Suspense>
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default StuffUpdateDialog;
