/**
 * @generated SignedSource<<dc4d4899a82fb8fc38333f5a86536895>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkbookTasksReorderInput = {
  id: string;
  taskIds: ReadonlyArray<string>;
};
export type WorkbookTasksReorderDialog_workbookTasksReorderMutation$variables = {
  input: WorkbookTasksReorderInput;
};
export type WorkbookTasksReorderDialog_workbookTasksReorderMutation$data = {
  readonly workbookTasksReorder: {
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"WorkbookStatusLabel_workbook" | "WorkbookTasksReorderDialog_workbook">;
  };
};
export type WorkbookTasksReorderDialog_workbookTasksReorderMutation = {
  response: WorkbookTasksReorderDialog_workbookTasksReorderMutation$data;
  variables: WorkbookTasksReorderDialog_workbookTasksReorderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookTasksReorderDialog_workbookTasksReorderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookTasksReorder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookStatusLabel_workbook"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookTasksReorderDialog_workbook"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookTasksReorderDialog_workbookTasksReorderMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookTasksReorder",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskConnection",
            "kind": "LinkedField",
            "name": "tasks",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Task",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "sequence",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "difficulty",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TaskProblem",
                        "kind": "LinkedField",
                        "name": "problem",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "problem",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "solution",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UnitD",
                        "kind": "LinkedField",
                        "name": "unitD",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitATitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitBTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unitCTitle",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e90f0fc256f602c4b715058d1889941",
    "id": null,
    "metadata": {},
    "name": "WorkbookTasksReorderDialog_workbookTasksReorderMutation",
    "operationKind": "mutation",
    "text": "mutation WorkbookTasksReorderDialog_workbookTasksReorderMutation(\n  $input: WorkbookTasksReorderInput!\n) {\n  workbookTasksReorder(input: $input) {\n    id\n    modifiedBy {\n      ...UserAvatarText_user\n      id\n    }\n    modified\n    ...WorkbookStatusLabel_workbook\n    ...WorkbookTasksReorderDialog_workbook\n  }\n}\n\nfragment TaskSequenceItem_task on Task {\n  sequence\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n\nfragment WorkbookStatusLabel_workbook on Workbook {\n  status\n}\n\nfragment WorkbookTasksReorderDialog_workbook on Workbook {\n  id\n  actions\n  tasks {\n    totalCount\n    edges {\n      node {\n        id\n        sequence\n        difficulty\n        ...TaskSequenceItem_task\n        problem {\n          problem\n          solution\n          id\n        }\n        unitD {\n          id\n          description\n          unitATitle\n          unitBTitle\n          unitCTitle\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "948e30634df6d98765b49ea4f95567b4";

export default node;
