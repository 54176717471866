import { PencilIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import {
  InternalUserDescriptionList_internalUser$data,
  InternalUserDescriptionList_internalUser$key,
} from '../../../relay/__generated__/InternalUserDescriptionList_internalUser.graphql';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import DialogButton from '../../core/DialogButton';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Text from '../../core/Text';
import InternalUserNameUpdateDialog from '../InternalUserNameUpdateDialog';
import InternalUserPhoneNumberUpdateDialog from '../InternalUserPhoneNumberUpdateDialog';
import InternalUserSchoolYearMajorTypeUpdateDialog from '../InternalUserSchoolYearMajorTypeUpdateDialog';
import InternalUserStatusMessageUpdateDialog from '../InternalUserStatusMessageUpdateDialog';

const InternalUserDescriptionList_internalUser = graphql`
  fragment InternalUserDescriptionList_internalUser on MATInternalUser {
    id
    userSequence
    userName
    loginId
    phoneNumber
    studentProfile {
      schoolYearMajorType
      profileImageUrl
      friendTag
      statusMessage
    }
    ...InternalLoginTypeUpdateDialog_internalUser
    ...InternalUserNameUpdateDialog_internalUser
    ...InternalUserPhoneNumberUpdateDialog_internalUser
    ...InternalUserStatusMessageUpdateDialog_internalUser
    ...InternalUserSchoolYearMajorTypeUpdateDialog_internalUser
  }
`;

type Props = {
  internalUser: InternalUserDescriptionList_internalUser$key;
  type?: 'default' | 'user-info';
} & Omit<DescriptionListProps<InternalUserDescriptionList_internalUser$data>, 'item' | 'itemDescriptions' | 'picks'>;

const InternalUserDescriptionList = ({ internalUser: internalUserReference, type = 'default', ...props }: Props) => {
  const internalUser = useFragment(InternalUserDescriptionList_internalUser, internalUserReference);
  return (
    <DescriptionList
      item={internalUser}
      itemDescriptions={{
        'userSequence': {
          title: '유저 ID',
          renderValue: ({ userSequence }) => <Text fontSize={1}>{userSequence}</Text>,
        },
        'studentProfile.friendTag': {
          title: '친구 태그',
          renderValue: ({ studentProfile }) => <Text fontSize={1}>{studentProfile.friendTag}</Text>,
        },
        'userName': {
          title: '이름',
          renderValue: ({ userName }) => (
            <Grid>
              <Grid.Unit size={'max'}>
                <Text fontSize={1}>{userName}</Text>
              </Grid.Unit>
              <Grid.Unit size={'min'}>
                <DialogButton
                  leadingIcon={PencilIcon}
                  variant={'invisible'}
                  size={'small'}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <InternalUserNameUpdateDialog
                      internalUser={internalUser}
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      config={{
                        onCompleted: () => {
                          closeDialog();
                        },
                      }}
                    />
                  )}
                >
                  수정
                </DialogButton>
              </Grid.Unit>
            </Grid>
          ),
        },
        'phoneNumber': {
          title: '전화번호',
          renderValue: ({ phoneNumber }) => (
            <Grid>
              <Grid.Unit size={'max'}>
                <Text fontSize={1}>{phoneNumber}</Text>
              </Grid.Unit>
              <Grid.Unit size={'min'}>
                <DialogButton
                  leadingIcon={PencilIcon}
                  variant={'invisible'}
                  size={'small'}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <InternalUserPhoneNumberUpdateDialog
                      internalUser={internalUser}
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      config={{
                        onCompleted: () => {
                          closeDialog();
                        },
                      }}
                    />
                  )}
                >
                  수정
                </DialogButton>
              </Grid.Unit>
            </Grid>
          ),
        },
        'studentProfile.schoolYearMajorType': {
          title: '학년',
          renderValue: ({ studentProfile }) => (
            <Grid>
              <Grid.Unit size={'max'}>
                <EnumPair typename={'MATSchoolYearMajorTypeEnum'}>{studentProfile.schoolYearMajorType}</EnumPair>
              </Grid.Unit>
              <Grid.Unit size={'min'}>
                <DialogButton
                  leadingIcon={PencilIcon}
                  variant={'invisible'}
                  size={'small'}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <InternalUserSchoolYearMajorTypeUpdateDialog
                      internalUser={internalUser}
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      config={{
                        onCompleted: () => {
                          closeDialog();
                        },
                      }}
                    />
                  )}
                >
                  수정
                </DialogButton>
              </Grid.Unit>
            </Grid>
          ),
        },
        'studentProfile.statusMessage': {
          title: '상태 메시지',
          renderValue: ({ studentProfile }) => (
            <Grid>
              <Grid.Unit size={'max'}>
                <Text fontSize={1}>{studentProfile.statusMessage}</Text>
              </Grid.Unit>
              <Grid.Unit size={'min'}>
                <DialogButton
                  leadingIcon={PencilIcon}
                  variant={'invisible'}
                  size={'small'}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <InternalUserStatusMessageUpdateDialog
                      internalUser={internalUser}
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      config={{
                        onCompleted: () => {
                          closeDialog();
                        },
                      }}
                    />
                  )}
                >
                  수정
                </DialogButton>
              </Grid.Unit>
            </Grid>
          ),
        },
      }}
      picks={
        type === 'user-info'
          ? [
              'userSequence',
              'studentProfile.friendTag',
              'userName',
              'phoneNumber',
              'studentProfile.schoolYearMajorType',
              'studentProfile.statusMessage',
            ]
          : type === 'default'
          ? [
              'userSequence',
              'studentProfile.friendTag',
              'userName',
              'phoneNumber',
              'studentProfile.schoolYearMajorType',
              'studentProfile.statusMessage',
            ]
          : []
      }
      {...props}
    />
  );
};

export default InternalUserDescriptionList;
