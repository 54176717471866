import { CheckIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { DeliveryOrderUpdateDialog_deliveryOrder$key } from '../../../relay/__generated__/DeliveryOrderUpdateDialog_deliveryOrder.graphql';
import { DeliveryOrderUpdateDialog_deliveryOrderUpdateMutation } from '../../../relay/__generated__/DeliveryOrderUpdateDialog_deliveryOrderUpdateMutation.graphql';
import AddressInputField from '../../core/AddressInputField';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import TelField from '../../core/TelField';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';
import View from '../../core/View';
import StuffSelectField from '../../stuff/StuffSelectField';

const DeliveryOrderUpdateDialog_deliveryOrder = graphql`
  fragment DeliveryOrderUpdateDialog_deliveryOrder on DeliveryOrder {
    id
    type
    description
    title
    stuff {
      id
    }
    deliveryAddress {
      recipientName
      recipientNumber
      address
      addressDetail
      zipCode
    }
  }
`;

type Props = { deliveryOrder: DeliveryOrderUpdateDialog_deliveryOrder$key } & DialogProps &
  Pick<
    MutationFormikProps<DeliveryOrderUpdateDialog_deliveryOrderUpdateMutation>,
    'connections' | 'onSubmit' | 'config'
  >;

const DeliveryOrderUpdateDialog = ({ deliveryOrder, config, onSubmit, connections, ...props }: Props) => {
  const {
    id,
    type,
    description,
    title,
    stuff,
    deliveryAddress: { recipientName, recipientNumber, address, addressDetail, zipCode },
  } = useFragment(DeliveryOrderUpdateDialog_deliveryOrder, deliveryOrder);
  return (
    <Dialog wide {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>배송 수정하기</Text>
      </Dialog.Header>
      <MutationFormik<DeliveryOrderUpdateDialog_deliveryOrderUpdateMutation>
        mutation={graphql`
          mutation DeliveryOrderUpdateDialog_deliveryOrderUpdateMutation($input: DeliveryOrderUpdateInput!) {
            deliveryOrderUpdate(input: $input) {
              id
              ...DeliveryOrderUpdateDialog_deliveryOrder
              ...DeliveryOrderStatusLabel_deliveryOrder
              ...DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder
            }
          }
        `}
        initialValues={{
          id,
          address: { address, zipCode, addressDetail },
          description,
          recipientName,
          recipientNumber,
          stuff: stuff,
          title: title,
          type: type,
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit, dirty }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <View>
                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>배송 상품</Text>
                </View>
                <TextField name={'title'} label={'제목'} required placeholder={'제목 입력'} autoComplete={'off'} />
                <TextareaField name={'description'} label={'설명'} placeholder={'설명 입력'} autoComplete={'off'} />
                <Suspense>
                  <EnumPairSelectField
                    typename={'DeliveryOrderTypeEnum'}
                    name={'type'}
                    label={'타입'}
                    required
                    placeholder={'타입 선택'}
                  />
                </Suspense>
                <Suspense>
                  <StuffSelectField name={'stuff.id'} label={'물품'} required placeholder={'물품 선택'} />
                </Suspense>
              </FormLayout>
              <View sx={{ marginTop: 6, marginBottom: 4 }}>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>수령자 정보</Text>
              </View>
              <FormLayout>
                <TextField
                  name={'recipientName'}
                  label={'수령자'}
                  required
                  placeholder={'수령자 입력'}
                  autoComplete={'off'}
                />
                <TelField
                  name={'recipientNumber'}
                  label={'전화번호'}
                  required
                  placeholder={'01000000000'}
                  autoComplete={'off'}
                />
                <AddressInputField name={'address'} label={'주소'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button disabled={!dirty} variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default DeliveryOrderUpdateDialog;
