/**
 * @generated SignedSource<<e5f31ae91184670cf014eb23b7550108>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskProblemTypeEnum = "copy" | "exam" | "private" | "problem_generator" | "pure" | "%future added value";
export type TaskStatusEnum = "deactivated" | "error_report" | "first_labeling" | "image_creation" | "problem_creation" | "problem_typing" | "published" | "review" | "second_labeling" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskConnectionDataTable_taskConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly assignedTo: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly completed: any | null;
      readonly completedBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly difficulty: number | null;
      readonly hint: {
        readonly id: string;
        readonly publishedText: string | null;
      } | null;
      readonly id: string;
      readonly problem: {
        readonly id: string;
        readonly problemType: TaskProblemTypeEnum;
      } | null;
      readonly problemGenerator: {
        readonly id: string;
      } | null;
      readonly sequence: number;
      readonly solutionVideo: {
        readonly id: string;
      } | null;
      readonly status: TaskStatusEnum;
      readonly submitFeedback: {
        readonly id: string;
        readonly publishedGeneral: string | null;
        readonly publishedSpecial: string | null;
      } | null;
      readonly title: string;
      readonly typingAutomationScript: {
        readonly id: string;
      } | null;
      readonly unitA: {
        readonly id: string;
        readonly title: string;
      } | null;
      readonly unitB: {
        readonly id: string;
        readonly title: string;
      } | null;
      readonly unitC: {
        readonly id: string;
        readonly title: string;
      } | null;
      readonly unitD: {
        readonly description: string | null;
        readonly id: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"TaskStatusLabel_task">;
    };
  }>;
  readonly " $fragmentType": "TaskConnectionDataTable_taskConnection";
};
export type TaskConnectionDataTable_taskConnection$key = {
  readonly " $data"?: TaskConnectionDataTable_taskConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskConnectionDataTable_taskConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
],
v4 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskConnectionDataTable_taskConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TaskStatusLabel_task"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskProblem",
              "kind": "LinkedField",
              "name": "problem",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "problemType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UnitA",
              "kind": "LinkedField",
              "name": "unitA",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UnitB",
              "kind": "LinkedField",
              "name": "unitB",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UnitC",
              "kind": "LinkedField",
              "name": "unitC",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UnitD",
              "kind": "LinkedField",
              "name": "unitD",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "assignedTo",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "completedBy",
              "plural": false,
              "selections": (v3/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "difficulty",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ProblemGenerator",
              "kind": "LinkedField",
              "name": "problemGenerator",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskSolutionVideo",
              "kind": "LinkedField",
              "name": "solutionVideo",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskHint",
              "kind": "LinkedField",
              "name": "hint",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publishedText",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskSubmitFeedback",
              "kind": "LinkedField",
              "name": "submitFeedback",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publishedSpecial",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publishedGeneral",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TypingAutomationScript",
              "kind": "LinkedField",
              "name": "typingAutomationScript",
              "plural": false,
              "selections": (v4/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TaskConnection",
  "abstractKey": null
};
})();

(node as any).hash = "62f3b1e99de4f9286dc0e999b8844692";

export default node;
