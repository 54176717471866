/**
 * @generated SignedSource<<d3d780b2f6c01b0019128da7e3dbdf10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProblemGeneratorActionEnum = "generated_problems_create" | "problem_generator_activate" | "problem_generator_assign" | "problem_generator_deactivate" | "problem_generator_publish" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorActionsMutationButtonStack_problemGenerator$data = {
  readonly actions: ReadonlyArray<ProblemGeneratorActionEnum>;
  readonly generatedProblems: {
    readonly totalCount: number | null;
  };
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorActivateDialog_problemGenerator" | "ProblemGeneratorDeactivateDialog_problemGenerator">;
  readonly " $fragmentType": "ProblemGeneratorActionsMutationButtonStack_problemGenerator";
};
export type ProblemGeneratorActionsMutationButtonStack_problemGenerator$key = {
  readonly " $data"?: ProblemGeneratorActionsMutationButtonStack_problemGenerator$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorActionsMutationButtonStack_problemGenerator">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "generatedProblemFilters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "generatedProblemOrder"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProblemGeneratorActionsMutationButtonStack_problemGenerator",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProblemGeneratorActivateDialog_problemGenerator"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProblemGeneratorDeactivateDialog_problemGenerator"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "generatedProblemFilters"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "generatedProblemOrder"
        }
      ],
      "concreteType": "GeneratedProblemConnection",
      "kind": "LinkedField",
      "name": "generatedProblems",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProblemGenerator",
  "abstractKey": null
};

(node as any).hash = "e1b04c0aa5f3152f4cff7f5681f3de30";

export default node;
