import { graphql, useFragment } from 'react-relay';

import { AppDialogTextTypePreviewItem_appDialog$key } from '../../../relay/__generated__/AppDialogTextTypePreviewItem_appDialog.graphql';
import AppDialogTextTypePreview from '../AppDialogTextTypePreview';

const AppDialogTextTypePreviewItem_appDialog = graphql`
  fragment AppDialogTextTypePreviewItem_appDialog on AppDialog {
    appDialogTitle
    appDialogBody
    appDialogButton
  }
`;

type Props = {
  appDialog: AppDialogTextTypePreviewItem_appDialog$key;
};
const AppDialogTextTypePreviewItem = ({ appDialog }: Props) => {
  const { appDialogBody, appDialogButton, appDialogTitle } = useFragment(
    AppDialogTextTypePreviewItem_appDialog,
    appDialog,
  );
  return (
    <AppDialogTextTypePreview
      appDialogTitle={appDialogTitle || ''}
      appDialogBody={appDialogBody || ''}
      appDialogButton={appDialogButton || ''}
    />
  );
};
export default AppDialogTextTypePreviewItem;
