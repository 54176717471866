import { graphql } from 'react-relay';

import { TypingAutomationScriptWindowPaginator_query$key } from '../../../relay/__generated__/TypingAutomationScriptWindowPaginator_query.graphql';
import FragmentWindowPaginator, { FragmentWindowPaginatorProps } from '../../core/FragmentWindowPaginator';
import Pagination from '../../page/Pagination';

const TypingAutomationScriptWindowPaginator_query = graphql`
  fragment TypingAutomationScriptWindowPaginator_query on Query
  @argumentDefinitions(
    filters: { type: TypingAutomationScriptFilter }
    order: { type: TypingAutomationScriptOrder }
    page: { type: Int, defaultValue: 1 }
    pageSize: { type: Int, defaultValue: 20 }
  )
  @refetchable(queryName: "TypingAutomationScriptWindowPaginatorRefreshQuery") {
    typingAutomationScripts(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      totalCount
      pages {
        ...Pagination_pages
      }
      edges {
        node {
          id
        }
      }
      ...TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection
    }
  }
`;

type Props = {} & Omit<FragmentWindowPaginatorProps<TypingAutomationScriptWindowPaginator_query$key>, 'fragment'>;

const TypingAutomationScriptWindowPaginator = ({ ...props }: Props) => (
  <FragmentWindowPaginator fragment={TypingAutomationScriptWindowPaginator_query} {...props}>
    {(data, { loadPage }) =>
      props.children(data, {
        loadPage,
        renderPagination: () => <Pagination pages={data.typingAutomationScripts.pages} loadPage={loadPage} />,
      })
    }
  </FragmentWindowPaginator>
);

export default TypingAutomationScriptWindowPaginator;
