import { GraphQLTaggedNode, usePreloadedQuery, PreloadedQuery as PreloadedQueryRenderer } from 'react-relay';
import { OperationType } from 'relay-runtime';

type Props<TQuery extends OperationType> = {
  query: GraphQLTaggedNode;
  queryReference: PreloadedQueryRenderer<TQuery>;
  children: (data: TQuery['response']) => React.ReactNode;
};

const PreloadedQueryRenderer = <TQuery extends OperationType>({ query, queryReference, children }: Props<TQuery>) => {
  const data = usePreloadedQuery(query, queryReference);

  return children(data) as JSX.Element;
};

export default PreloadedQueryRenderer;
export type { Props as PreloadedQueryRendererProps };
