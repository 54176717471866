import { CheckCircleIcon, UploadIcon, XIcon } from '@primer/octicons-react';
import { TextInputProps as PrimerTextInputProps } from '@primer/react';
import { isEmptyString } from '@teamturing/validators';
import { FieldConfig, useField } from 'formik';
import { ChangeEventHandler, ComponentProps, DragEventHandler, useState } from 'react';
import styled from 'styled-components';

import useFormikContext from '../../../hooks/useFormikContext';
import { uploadFile } from '../../../utils/file';
import { isArray, isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../../core/FormControl';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import Link from '../../core/Link';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';

type UploadValue = {
  fieldName?: string;
  key: string;
  multipartUploadResult?: {
    partPairs: { ETag: string; PartNumber: number }[];
    uploadId: string;
  };
  objectUrl?: string;
  size: number;
};

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  nodeId?: string;
  caption?: string;
  multipart?: boolean;
  onCompletedUpload?: (file: UploadValue[] | UploadValue) => void;
  uploadOptions?: { targetDirectory?: string };
} & Pick<PrimerTextInputProps, 'disabled' | 'required' | 'name' | 'onChange' | 'placeholder' | 'accept' | 'multiple'> &
  Pick<FieldConfig, 'validate'>;

const UploadFieldv2 = ({
  label,
  labelConfig,
  nodeId,
  caption,
  disabled: propDisabled,
  required,
  name = '',
  onChange: propOnChange,
  placeholder = '파일을 선택하세요',
  accept,
  validate,
  multipart = true,
  onCompletedUpload,
  multiple,
  uploadOptions,
}: Props) => {
  const inputId = `upload_field_for_${label}_${name}`;

  const [{ value, ...restProps }, { error }, { setValue, setError }] = useField<(UploadValue[] | UploadValue) | null>({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value.key === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 업로드해 주세요`;
      }
    },
  });

  const { isSubmitting } = useFormikContext();

  const [file, setFile] = useState<(File[] | File) | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const deleteFile = () => {
    setError(undefined);
    setFile(null);
    setValue(null);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (!e.target.files?.length) return;

    setError(undefined);
    setIsLoading(true);

    const options = {
      multipart,
      nodeId,
      fieldName: name,
      ...(uploadOptions?.targetDirectory
        ? {
            formatFileName: (fileName: string) => `${uploadOptions?.targetDirectory}/${fileName}`,
          }
        : {}),
    };

    if (multiple) {
      const fileList = Array.from(e.target.files);
      const uploadResult = await Promise.allSettled(
        fileList.map((file) => {
          return uploadFile(file, options);
        }),
      );

      const successFileList = [...fileList];
      const values = uploadResult.reduceRight((pre, cur, index) => {
        if (cur.status === 'fulfilled') return [cur.value, ...pre];
        else {
          successFileList.splice(index, 1);
          return pre;
        }
      }, [] as UploadValue[]);

      if (successFileList.length < uploadResult.length)
        setError(`${uploadResult.length}개 중 ${uploadResult.length - successFileList.length}개 업로드 실패`);

      onCompletedUpload?.(values);
      if (values.length > 0) {
        setValue(values);
        setFile(successFileList);
      }
    } else {
      const targetFile = e.target.files[0];

      try {
        const value = await uploadFile(targetFile, options);
        onCompletedUpload?.(value);
        setValue(value);
        setFile(targetFile);
      } catch (e) {
        if (e === 'block_multipart_upload') {
          setError('파일 사이즈가 100MB보다 큽니다');
        } else {
          setError('파일 업로드에 실패했어요');
        }

        setFile(null);
      }
    }

    setIsLoading(false);
    e.target.value = '';
  };
  const handleChange = propOnChange ? propOnChange : onChange;

  const handleDragOver: DragEventHandler<HTMLLabelElement> = (e) => e.preventDefault();
  const handleDragEnter: DragEventHandler<HTMLLabelElement> = (e) => {
    if (isLoading) return;
    if (e.currentTarget.contains(e.relatedTarget as Node)) return;
    e.currentTarget?.classList.add('active');
  };
  const handleDragLeave: DragEventHandler<HTMLLabelElement> = (e) => {
    if (e.currentTarget.contains(e.relatedTarget as Node)) return;
    e.currentTarget?.classList.remove('active');
  };
  const handleDrop: DragEventHandler<HTMLLabelElement> = (e) => {
    e.preventDefault();

    const files = e.dataTransfer?.files ?? [];
    if (files.length < 1) return;

    const fileInput = document.getElementById(inputId) as HTMLInputElement;

    const isAcceptableFile = new RegExp(
      (accept || '*').replace(/\*/g, '.*').replace(/,/g, '|').replace(/\s/g, ''),
    ).test(files[0].type);

    if (!isAcceptableFile) {
      setError('확장자가 맞지 않는 파일입니다.');
      return;
    }

    if (isLoading) return;
    e.currentTarget?.classList.remove('active');

    if (fileInput && files) {
      fileInput.files = files;
      fileInput.dispatchEvent(new Event('change', { bubbles: true }));
    }
  };

  const disabled = propDisabled || isSubmitting || isLoading;

  return (
    <View>
      <FormControl disabled={disabled} required={required}>
        <FormControl.Label {...labelConfig} htmlFor={inputId}>
          {label}
        </FormControl.Label>
        <View
          as={'label'}
          htmlFor={inputId}
          sx={{
            'width': '100%',
            'borderWidth': 1,
            'borderColor': error ? 'danger.emphasis' : 'border.default',
            'borderStyle': 'dashed',
            'borderRadius': 2,
            'minHeight': '32px',
            'marginTop': 1,
            'padding': 4,
            'display': 'flex',
            'flexDirection': 'column',
            'alignItems': 'center',
            'cursor': 'pointer',
            '&.active': {
              backgroundColor: 'neutral.subtle',
            },
            ...(disabled ? { color: 'fg.muted', backgroundColor: 'canvas.inset', cursor: 'not-allowed' } : undefined),
          }}
          {...(disabled
            ? {}
            : {
                onDragOver: handleDragOver,
                onDragLeave: handleDragLeave,
                onDragEnter: handleDragEnter,
                onDrop: handleDrop,
              })}
          tabIndex={0}
        >
          {isLoading ? (
            <>
              <Spinner size={'medium'} />
              <Text fontSize={1} sx={{ marginTop: 2 }}>
                파일 준비 중...
              </Text>
            </>
          ) : file ? (
            <>
              <CheckCircleIcon size={'medium'} fill={'green'} />
              <View>
                <Text fontSize={1} sx={{ marginTop: 2, wordBreak: 'break-all' }} whiteSpace={'pre-wrap'}>
                  {'name' in file
                    ? `${file.name} 준비 완료`
                    : `${file[0].name}${file.length > 1 ? ` 외 ${file.length - 1}개` : ''} 준비 완료`}
                </Text>
              </View>
            </>
          ) : (
            <>
              <UploadIcon size={'medium'} />
              <Text fontSize={1} sx={{ marginTop: 2 }}>
                {placeholder}
              </Text>
            </>
          )}
          <StyledInput
            {...restProps}
            id={inputId}
            type={'file'}
            accept={accept}
            onChange={handleChange}
            disabled={disabled}
            multiple={multiple}
          />
        </View>
        {value ? (
          isArray(value) ? (
            value
              .filter((v) => !isEmptyString(v.key))
              .map((v, index) => {
                return (
                  <UploadedFileItem
                    key={v.key}
                    file={v}
                    disabled={disabled}
                    onClickX={() => {
                      setError(undefined);

                      if (value.length === 1) deleteFile();
                      else {
                        const newFile = [...(file as File[])];
                        newFile.splice(index, 1);
                        setFile(newFile);

                        const newValue = [...value];
                        newValue.splice(index, 1);
                        setValue(newValue);
                      }
                    }}
                  />
                );
              })
          ) : !isEmptyString(value.key) ? (
            <UploadedFileItem
              file={value}
              disabled={disabled}
              onClickX={() => {
                setError(undefined);
                deleteFile();
              }}
            />
          ) : null
        ) : null}
        {error ? (
          <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
        ) : caption ? (
          <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
        ) : null}
      </FormControl>
    </View>
  );
};

const UploadedFileItem = ({
  file,
  disabled,
  onClickX,
}: {
  file: UploadValue;
  disabled?: boolean;
  onClickX: () => void;
}) => {
  const canDownload = 'objectUrl' in file && file.objectUrl;
  const content = (
    <Grid gapX={2} wrap={false} sx={{ alignItems: 'center' }}>
      <Grid.Unit size={'max'}>
        <Text fontSize={1} sx={{ lineBreak: 'anywhere' }}>
          {file.key}
        </Text>
      </Grid.Unit>
      <Grid.Unit size={'min'}>
        <IconButton
          icon={XIcon}
          variant={'plain'}
          aria-label={'reset upload field'}
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault();
            onClickX?.();
          }}
        />
      </Grid.Unit>
    </Grid>
  );
  return (
    <View
      sx={{
        paddingX: 3,
        borderRadius: 2,
        marginTop: 2,
        width: '100%',
        ...(canDownload
          ? { cursor: 'pointer', backgroundColor: 'neutral.muted', paddingY: 3 }
          : { borderColor: 'neutral.muted', borderWidth: 1, borderStyle: 'solid', paddingY: 2 }),
      }}
    >
      {canDownload ? (
        <Link href={file.objectUrl as string} target={'_blank'}>
          {content}
        </Link>
      ) : (
        content
      )}
    </View>
  );
};

const StyledInput = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

export default UploadFieldv2;
export type { Props as UploadFieldv2Props };
