import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  ProductDescriptionList_product$data,
  ProductDescriptionList_product$key,
} from '../../../relay/__generated__/ProductDescriptionList_product.graphql';
import { formatDate, formatISO } from '../../../utils/date';
import { numberWithCommas, numberWithPercent } from '../../../utils/number';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import ProductStatusLabel from '../ProductStatusLabel';

const ProductDescriptionList_product = graphql`
  fragment ProductDescriptionList_product on Product {
    id
    storeStuff {
      id
      title
      endAt
      stuff {
        id
        title
      }
    }
    showTypes
    price
    discountPrice
    productPrice {
      discountPercentage
      monthlyPrice
    }
    additionalDiscountAmount

    createdBy {
      id
      ...UserAvatarText_user
    }
    created
    ...ProductStatusLabel_product
  }
`;

type Props = {
  product: ProductDescriptionList_product$key;
  type?: 'default' | 'activity';
} & Omit<DescriptionListProps<ProductDescriptionList_product$data>, 'item' | 'itemDescriptions' | 'picks'>;
const ProductDescriptionList = ({ product: productReference, type = 'default', ...props }: Props) => {
  const product = useFragment(ProductDescriptionList_product, productReference);

  return (
    <DescriptionList
      item={product}
      itemDescriptions={{
        'storeStuffTitle': {
          title: '상품명',
          renderValue: ({ storeStuff }) =>
            storeStuff ? (
              <Text fontSize={1}>{`${storeStuff.title}${storeStuff.stuff ? ` / ${storeStuff.stuff.title}` : ''}`}</Text>
            ) : undefined,
        },
        'price': {
          title: '정가',
          renderValue: ({ price }) => <Text fontSize={1}>{numberWithCommas(price)}원</Text>,
        },
        'discountPrice': {
          title: '판매가',
          renderValue: ({ discountPrice }) => <Text fontSize={1}>{numberWithCommas(discountPrice)}원</Text>,
        },
        'additionalDiscountAmount': {
          title: '콜 추가 할인',
          renderValue: ({ additionalDiscountAmount }) =>
            additionalDiscountAmount ? (
              <Text fontSize={1}>{numberWithCommas(additionalDiscountAmount)}원</Text>
            ) : undefined,
        },
        'productPrice.discountPercentage': {
          title: '할인율',
          renderValue: ({ productPrice }) => (
            <Text fontSize={1}>{numberWithPercent(productPrice.discountPercentage)}</Text>
          ),
        },
        'productPrice.monthlyPrice': {
          title: '월가',
          renderValue: ({ productPrice, storeStuff }) => {
            const baseMonthlyPrice = `월 ${numberWithCommas(productPrice.monthlyPrice)}원`;
            return (
              <Text fontSize={1}>
                {storeStuff?.endAt
                  ? [baseMonthlyPrice, `(${formatDate(formatISO(Date.now()), 'yy. M. d')} 현재 기준)`].join(' ')
                  : baseMonthlyPrice}
              </Text>
            );
          },
        },
        'createdBy': {
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
        },
        'created': {
          title: '제작일',
          renderValue: ({ created }) => <DateText fontSize={1}>{created}</DateText>,
        },
        'status': {
          title: '상태',
          renderValue: (product) => <ProductStatusLabel product={product} />,
        },
        'showTypes': {
          title: '타입',
          renderValue: ({ showTypes }) =>
            showTypes.length === 0
              ? ' - '
              : showTypes.map((type, index) => (
                  <Text key={type} fontSize={1}>
                    {index === 0 ? '' : ', '}
                    <EnumPair typename={'ProductShowTypeEnum'}>{type}</EnumPair>
                  </Text>
                )),
        },
      }}
      picks={
        type === 'default'
          ? [
              'storeStuffTitle',
              'price',
              'discountPrice',
              'productPrice.discountPercentage',
              'productPrice.monthlyPrice',
              'showTypes',
              'additionalDiscountAmount',
            ]
          : type === 'activity'
          ? ['createdBy', 'created', 'status']
          : []
      }
      {...props}
    />
  );
};

export default ProductDescriptionList;
