import { graphql, useFragment } from 'react-relay';

import { ProblemGeneratorDifferenceItemList_difference$key } from '../../../relay/__generated__/ProblemGeneratorDifferenceItemList_difference.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import ItemList from '../../core/ItemList';
import Text from '../../core/Text';
import View from '../../core/View';
import DiffItem from '../../difference/DiffItem';

const ProblemGeneratorDifferenceItemList_difference = graphql`
  fragment ProblemGeneratorDifferenceItemList_difference on Difference {
    ... on ProblemGeneratorDifference {
      #        problemGenerator 모델 수정 전 히스토리를 위해 남겨둡니다
      generatedCount {
        ...DiffItem_diff
      }
      publishedProblemsCount {
        ...DiffItem_diff
      }
    }
  }
`;

type Props = {
  difference: ProblemGeneratorDifferenceItemList_difference$key;
};

const ProblemGeneratorDifferenceItemList = ({ difference }: Props) => {
  const { generatedCount, publishedProblemsCount } = useFragment(
    ProblemGeneratorDifferenceItemList_difference,
    difference,
  );

  const diffItems = [
    {
      title: '출시된 문제',
      diff: generatedCount,
    },
    {
      title: '출시된 문제',
      diff: publishedProblemsCount,
    },
  ].filter(({ diff }) => !isNullable(diff));

  return (
    <ItemList
      items={diffItems}
      renderItem={({ title, diff }) => (
        <>
          <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
          <View sx={{ marginTop: 2 }}>
            <DiffItem diff={diff!} />
          </View>
        </>
      )}
      renderItemWrapper={(children, { title }, i) => (
        <View key={title} sx={{ marginTop: i > 0 ? 3 : 0 }}>
          {children}
        </View>
      )}
      emptyState={
        <View sx={{ paddingY: 6 }}>
          <EmptyState
            title={'기록되지 않은 히스토리이에요'}
            description={'이 기능이 만들어지기 전에 일어난 일이거나 오류로 누락된 히스토리입니다.'}
          />
        </View>
      }
    />
  );
};

export default ProblemGeneratorDifferenceItemList;
