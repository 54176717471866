import { graphql, useFragment } from 'react-relay';

import { DeliveryOrderDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/DeliveryOrderDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const DeliveryOrderDifferenceCounterLabel_difference = graphql`
  fragment DeliveryOrderDifferenceCounterLabel_difference on Difference {
    ... on DeliveryOrderDifference {
      description {
        __typename
      }
      address {
        __typename
      }
      addressDetail {
        __typename
      }
      zipCode {
        __typename
      }
      recipientName {
        __typename
      }
      recipientNumber {
        __typename
      }
      stuffTitle {
        __typename
      }
      title {
        __typename
      }
    }
  }
`;

type Props = { difference: DeliveryOrderDifferenceCounterLabel_difference$key } & CounterLabelProps;

const DeliveryOrderDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { description, address, addressDetail, zipCode, recipientName, recipientNumber, stuffTitle, title } =
    useFragment(DeliveryOrderDifferenceCounterLabel_difference, difference);

  const differenceCount = [
    description,
    address,
    addressDetail,
    zipCode,
    recipientName,
    recipientNumber,
    stuffTitle,
    title,
  ].filter((diff) => !isNullable(diff)).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default DeliveryOrderDifferenceCounterLabel;
