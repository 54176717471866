/**
 * @generated SignedSource<<a9ca5cef7143665f41f661e5cfb3896a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamProblemSchoolYearEnum = "first" | "second" | "third" | "%future added value";
export type SchoolExamProblemSubjectEnum = "english" | "korean" | "math" | "%future added value";
export type SchoolExamProblemId_schoolExamProblemQuery$variables = {
  id: string;
};
export type SchoolExamProblemId_schoolExamProblemQuery$data = {
  readonly schoolExamProblem: {
    readonly id: string;
    readonly images: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly url: string;
        };
      }>;
    };
    readonly problemExamYear: number;
    readonly problemSchoolName: string;
    readonly problemSchoolYear: SchoolExamProblemSchoolYearEnum;
    readonly problemSubject: SchoolExamProblemSubjectEnum;
    readonly sequence: number;
    readonly title: string;
    readonly userName: string;
    readonly userSchoolName: string;
    readonly " $fragmentSpreads": FragmentRefs<"SchoolExamProblemActionsMutationButtonStack_schoolExamProblem">;
  };
};
export type SchoolExamProblemId_schoolExamProblemQuery = {
  response: SchoolExamProblemId_schoolExamProblemQuery$data;
  variables: SchoolExamProblemId_schoolExamProblemQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sequence",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userSchoolName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemSchoolYear",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemSubject",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemExamYear",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemSchoolName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "SchoolExamProblemImageConnection",
  "kind": "LinkedField",
  "name": "images",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SchoolExamProblemImageEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SchoolExamProblemImage",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolExamProblemId_schoolExamProblemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamProblem",
        "kind": "LinkedField",
        "name": "schoolExamProblem",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SchoolExamProblemActionsMutationButtonStack_schoolExamProblem"
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchoolExamProblemId_schoolExamProblemQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamProblem",
        "kind": "LinkedField",
        "name": "schoolExamProblem",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actions",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e2a95565bde3878a9cec25345da06ed",
    "id": null,
    "metadata": {},
    "name": "SchoolExamProblemId_schoolExamProblemQuery",
    "operationKind": "query",
    "text": "query SchoolExamProblemId_schoolExamProblemQuery(\n  $id: ID!\n) {\n  schoolExamProblem(id: $id) {\n    id\n    sequence\n    ...SchoolExamProblemActionsMutationButtonStack_schoolExamProblem\n    userName\n    userSchoolName\n    problemSchoolYear\n    problemSubject\n    problemExamYear\n    problemSchoolName\n    title\n    images {\n      edges {\n        node {\n          id\n          url\n        }\n      }\n    }\n  }\n}\n\nfragment SchoolExamProblemActionsMutationButtonStack_schoolExamProblem on SchoolExamProblem {\n  id\n  actions\n  ...SchoolExamProblemRejectDialog_schoolExamProblem\n}\n\nfragment SchoolExamProblemRejectDialog_schoolExamProblem on SchoolExamProblem {\n  id\n}\n"
  }
};
})();

(node as any).hash = "469af03b2d32c466c80dfed2d4da5993";

export default node;
