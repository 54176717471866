/**
 * @generated SignedSource<<1143895d8f6b05afe09e4ba1dfdf50f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SchoolRegionEnum = "busan" | "chungbuk" | "chungnam" | "daegu" | "daejeon" | "gangwon" | "gwangju" | "gyeongbuk" | "gyeonggi" | "gyeongnam" | "incheon" | "jeju" | "jeongbuk" | "jeonnam" | "sejong" | "seoul" | "ulsan" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceConnectionDataTable_schoolExamSourceConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly assignedTo: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly completedBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly id: string;
      readonly innerPdf: {
        readonly id: string;
      } | null;
      readonly school: {
        readonly id: string;
        readonly region: SchoolRegionEnum;
        readonly title: string;
      };
      readonly sequence: number;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceStatusLabel_schoolExamSource">;
    };
  }>;
  readonly " $fragmentType": "SchoolExamSourceConnectionDataTable_schoolExamSourceConnection";
};
export type SchoolExamSourceConnectionDataTable_schoolExamSourceConnection$key = {
  readonly " $data"?: SchoolExamSourceConnectionDataTable_schoolExamSourceConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceConnectionDataTable_schoolExamSourceConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceConnectionDataTable_schoolExamSourceConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SchoolExamSourceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SchoolExamSource",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SchoolExamSourceStatusLabel_schoolExamSource"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "School",
              "kind": "LinkedField",
              "name": "school",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "region",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "innerPdf",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "assignedTo",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "completedBy",
              "plural": false,
              "selections": (v2/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SchoolExamSourceConnection",
  "abstractKey": null
};
})();

(node as any).hash = "13ebc0264cd7325b8a04db23d86641cd";

export default node;
