import { graphql, useFragment } from 'react-relay';

import { UserCreateDialog_group$key } from '../../../relay/__generated__/UserCreateDialog_group.graphql';
import { UserCreateDialog_userCreateMutation } from '../../../relay/__generated__/UserCreateDialog_userCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const UserCreateDialog_group = graphql`
  fragment UserCreateDialog_group on Group {
    id
  }
`;

type Props = { group: UserCreateDialog_group$key } & DialogProps &
  Pick<MutationFormikProps<UserCreateDialog_userCreateMutation>, 'config'>;

const UserCreateDialog = ({ group, config, ...props }: Props) => {
  const { id } = useFragment(UserCreateDialog_group, group);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text fontSize={2} fontWeight={'bold'}>
          계정 생성하기
        </Text>
      </Dialog.Header>
      <MutationFormik<UserCreateDialog_userCreateMutation>
        mutation={graphql`
          mutation UserCreateDialog_userCreateMutation($input: UserCreateInput!) {
            userCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{ name: '', group: id }}
        config={config}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <FormLayout>
                <TextField
                  label={'팀원명'}
                  placeholder={'팀원명 입력'}
                  name={'name'}
                  required
                  autoComplete={'off'}
                  size={'large'}
                  caption={'영어 소문자와 숫자의 조합으로 입력해주세요'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default UserCreateDialog;
