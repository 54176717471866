import { ReactNode, useState } from 'react';
import BaseLightbox, { ILightBoxProps as BaseLightboxProps } from 'react-18-image-lightbox';

import ConfirmToolbarIconButton from './ConfirmToolbarIconButton';
import MutationConfirmToolbarIconButton from './MutationConfirmToolbarIconButton';
import ToolbarIconButton from './ToolbarIconButton';

type Props<T> = {
  items: T[];
  getImage: (item: T) => string;
  getImageTitle?: (item: T) => ReactNode;
  initialIndex: number;
  isOpen?: boolean;
  onDismiss?: () => void;
} & Omit<BaseLightboxProps, 'mainSrc' | 'nextSrc' | 'prevSrc' | 'onCloseRequest' | 'imageTitle'>;

const Lightbox = <T,>({
  items,
  getImage,
  getImageTitle,
  initialIndex,
  isOpen,
  onDismiss,
  onMoveNextRequest,
  onMovePrevRequest,
  onImageLoad,
  onAfterOpen,
  ...props
}: Props<T>) => {
  const images = items.map((item) => getImage(item));
  const [currentIndex, setCurrentIndex] = useState<number>(initialIndex);

  return isOpen ? (
    <BaseLightbox
      onAfterOpen={() => {
        setCurrentIndex(initialIndex);
        onAfterOpen?.();
      }}
      mainSrc={images[currentIndex]}
      nextSrc={images[(currentIndex + 1) % images.length]}
      prevSrc={images[(currentIndex + images.length - 1) % images.length]}
      onMovePrevRequest={() => {
        onMovePrevRequest?.();
        setCurrentIndex((currentIndex + images.length - 1) % images.length);
      }}
      onMoveNextRequest={() => {
        onMoveNextRequest?.();
        setCurrentIndex((currentIndex + 1) % images.length);
      }}
      onImageLoad={() => {
        onImageLoad?.();

        // https://github.com/frontend-collective/react-image-lightbox/issues/589#issuecomment-1159723673
        window.dispatchEvent(new Event('resize'));
      }}
      onCloseRequest={() => {
        onDismiss?.();
      }}
      animationDuration={0}
      keyRepeatLimit={0}
      imageTitle={getImageTitle?.(items[currentIndex])}
      {...props}
    />
  ) : null;
};

export default Object.assign(Lightbox, {
  ToolbarIconButton,
  ConfirmToolbarIconButton,
  MutationConfirmToolbarIconButton,
});
export type { Props as LightboxProps };
