import { useField } from 'formik';
import { Suspense, useState } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import { BookPublisherSearchOverlayTokenField_bookPublishersQuery } from '../../../relay/__generated__/BookPublisherSearchOverlayTokenField_bookPublishersQuery.graphql';
import { isNullable } from '../../../utils/is';
import Checkbox from '../../core/Checkbox';
import EmptyState from '../../core/EmptyState';
import ErrorBoundary from '../../core/ErrorBoundary';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import SearchOverlayTokenField, { SearchOverlayTokenFieldProps } from '../../core/SearchOverlayTokenField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';

const bookPublishersForBookPublisherSearchOverlayTokenField = graphql`
  query BookPublisherSearchOverlayTokenField_bookPublishersQuery {
    bookPublishers
  }
`;

type Props = {} & Omit<SearchOverlayTokenFieldProps, 'renderItemList'>;

const BookPublisherSearchOverlayTokenField = ({ name = '', value: propValue, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string[] | undefined>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<BookPublisherSearchOverlayTokenField_bookPublishersQuery>(
      bookPublishersForBookPublisherSearchOverlayTokenField,
    );

  const [search, setSearch] = useState<string>('');

  return (
    <SearchOverlayTokenField
      name={name}
      value={value}
      renderTokenText={(title) => title}
      renderItemList={({ handleSelect }, itemProps) =>
        queryReference ? (
          <ErrorBoundary key={queryReference.fetchKey}>
            <Suspense fallback={<Spinner sx={{ paddingY: 3 }} />}>
              <PreloadedQueryRenderer
                query={bookPublishersForBookPublisherSearchOverlayTokenField}
                queryReference={queryReference}
              >
                {({ bookPublishers }) => (
                  <ItemList
                    items={bookPublishers.filter((bookPublisher) => bookPublisher.includes(search))}
                    renderItem={(bookPublisher) => <Text sx={{ fontSize: 1 }}>{bookPublisher}</Text>}
                    renderItemWrapper={(children, bookPublisher, i) => (
                      <View
                        {...itemProps}
                        key={[bookPublisher, i].join('-')}
                        onClick={(e) => handleSelect(e, bookPublisher)}
                        onKeyDown={(e) => handleSelect(e, bookPublisher)}
                      >
                        <Grid gapX={2} wrap={false}>
                          <Grid.Unit size={'min'}>
                            <Checkbox
                              checked={value?.some((selectedBookPublisher) => selectedBookPublisher === bookPublisher)}
                            />
                          </Grid.Unit>
                          <Grid.Unit size={'max'}>{children}</Grid.Unit>
                        </Grid>
                      </View>
                    )}
                    emptyState={
                      <View sx={{ paddingY: 3 }}>
                        <EmptyState title={'검색 결과가 없어요'} description={'다른 검색어로 다시 시도해주세요.'} />
                      </View>
                    }
                  />
                )}
              </PreloadedQueryRenderer>
            </Suspense>
          </ErrorBoundary>
        ) : null
      }
      onOverlayOpen={() => loadQuery({}, { fetchPolicy: 'store-and-network' })}
      onOverlayClose={() => {
        setSearch('');
        disposeQuery();
      }}
      onSearch={(search) => setSearch(search)}
      {...props}
    />
  );
};

export default BookPublisherSearchOverlayTokenField;
