/**
 * @generated SignedSource<<40790d40e23cd68e62db9103d93b0941>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATInternalUserStatusMessageUpdateInput = {
  id: string;
  statusMessage: string;
};
export type InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation$variables = {
  input: MATInternalUserStatusMessageUpdateInput;
};
export type InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation$data = {
  readonly internalUserStatusMessageUpdate: {
    readonly id: string;
    readonly studentProfile: {
      readonly statusMessage: string | null;
    };
  };
};
export type InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation = {
  response: InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation$data;
  variables: InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MATInternalUser",
    "kind": "LinkedField",
    "name": "internalUserStatusMessageUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MATInternalStudentProfile",
        "kind": "LinkedField",
        "name": "studentProfile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "statusMessage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1b14d8e6199f62f979ae823d68ec3910",
    "id": null,
    "metadata": {},
    "name": "InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation InternalUserStatusMessageUpdateDialog_internalUserStatusMessageUpdateMutation(\n  $input: MATInternalUserStatusMessageUpdateInput!\n) {\n  internalUserStatusMessageUpdate(input: $input) {\n    id\n    studentProfile {\n      statusMessage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "649c9a8cf541649f7690ce6057ed5c65";

export default node;
