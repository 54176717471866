import BaseHead from 'next/head';

type HeadProps = {
  siteTitle: string;
  title?: string;
};

const Head = ({ title: propTitle = 'TCMS', siteTitle }: HeadProps) => {
  const title = `${propTitle} | ${siteTitle}`;

  return (
    <BaseHead>
      <title>{title}</title>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteTitle} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
    </BaseHead>
  );
};

export default Head;
export type { HeadProps };
