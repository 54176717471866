import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { GroupInviteDialog_group$key } from '../../../relay/__generated__/GroupInviteDialog_group.graphql';
import { GroupInviteDialog_groupInviteMutation } from '../../../relay/__generated__/GroupInviteDialog_groupInviteMutation.graphql';
import ConfirmButton from '../../core/ConfirmButton';
import Dialog, { DialogProps } from '../../core/Dialog';
import ErrorBoundary from '../../core/ErrorBoundary';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import Text from '../../core/Text';
import UserPaginatorSearchOverlayTokenField from '../../user/UserPaginatorSearchOverlayTokenField';

const GroupInviteDialog_group = graphql`
  fragment GroupInviteDialog_group on Group {
    id
  }
`;

type Props = { group: GroupInviteDialog_group$key } & DialogProps &
  Pick<MutationFormikProps<GroupInviteDialog_groupInviteMutation>, 'config' | 'onSubmit'>;

const GroupInviteDialog = ({ group, config, onSubmit, ...props }: Props) => {
  const { id } = useFragment(GroupInviteDialog_group, group);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text fontSize={3} fontWeight={'bold'}>
          팀원 추가하기
        </Text>
      </Dialog.Header>
      <ErrorBoundary>
        <MutationFormik<GroupInviteDialog_groupInviteMutation>
          mutation={graphql`
            mutation GroupInviteDialog_groupInviteMutation($input: GroupInviteInput!) {
              groupInvite(input: $input) {
                id
                users {
                  id
                  group {
                    id
                  }
                }
              }
            }
          `}
          initialValues={{ groupId: id, userIds: [] }}
          config={config}
          onSubmit={onSubmit}
        >
          {({ submitForm, dirty }) => (
            <>
              <Dialog.Body>
                <UserPaginatorSearchOverlayTokenField
                  label={'팀원'}
                  name={'userIds'}
                  placeholder={'팀원 선택'}
                  caption={'원하는 팀원이 보이지 않는 경우, TCMS에 가입된 팀원인지 확인해 주세요'}
                  required
                />
              </Dialog.Body>
              <Dialog.Footer>
                <ConfirmButton
                  size={'large'}
                  variant={'primary'}
                  disabled={!dirty}
                  onClick={() => submitForm()}
                  message={'다른 그룹에 속해있던 팀원은 해당 그룹으로 이동됩니다. 정말 추가할까요?'}
                >
                  추가하기
                </ConfirmButton>
              </Dialog.Footer>
            </>
          )}
        </MutationFormik>
      </ErrorBoundary>
    </Dialog>
  );
};

export default GroupInviteDialog;
