import { RouteType } from '../components/core/Route';
import AppDialog from '../pages/appDialog';
import AppPush from '../pages/appPush';
import AssignedTask from '../pages/assignedTask';
import Bill from '../pages/bill';
import Book from '../pages/book';
import CodepushVersion from '../pages/codepushVersion';
import Dashboard from '../pages/dashboard';
import DeliveryOrder from '../pages/deliveryOrder';
import EnigmaBuilder from '../pages/enigmaBuilder';
import FormulaSet from '../pages/formulaSet';
import Login from '../pages/login';
import MathKingUser from '../pages/mathKingUser';
import Organization from '../pages/organization';
import Permission from '../pages/permission';
import ProblemGenerator from '../pages/problemGenerator';
import Product from '../pages/product';
import ProductEvent from '../pages/productEvent';
import Quiz from '../pages/quiz';
import QuizSet from '../pages/quizSet';
import Raffle from '../pages/raffle';
import RaffleStuff from '../pages/raffleStuff';
import SchoolExamProblem from '../pages/schoolExamProblem';
import SchoolExamSource from '../pages/schoolExamSource';
import ScrapSource from '../pages/scrapSource';
import StoreStuff from '../pages/storeStuff';
import Stuff from '../pages/stuff';
import Task from '../pages/task';
import TypingAutomationScript from '../pages/typingAutomationScript';
import Workbook from '../pages/workbook';

type RouteGroupType = {
  id: string;
  name?: string;
  routes: RouteType[];
};

export const unauthenticatedRoutes: RouteType[] = [...Login.routes];

export const authenticatedRouteGroups: RouteGroupType[] = [
  { id: 'main', routes: [...Dashboard.routes, ...Permission.routes] },
  {
    id: 'mathKingUser',
    name: '수학대왕 CS',
    routes: [...MathKingUser.routes],
  },
  {
    id: 'mathContents',
    name: '학습 콘텐츠',
    routes: [
      ...ScrapSource.routes,
      ...TypingAutomationScript.routes,
      ...Task.routes,
      ...AssignedTask.routes,
      ...ProblemGenerator.routes,
      ...Workbook.routes,
      ...Quiz.routes,
      ...QuizSet.routes,
      ...SchoolExamProblem.routes,
      ...SchoolExamSource.routes,
      ...FormulaSet.routes,
    ],
  },
  {
    id: 'event',
    name: '이벤트',
    routes: [...Stuff.routes, ...RaffleStuff.routes, ...Raffle.routes, ...AppDialog.routes, ...AppPush.routes],
  },
  {
    id: 'product',
    name: '상품',
    routes: [...StoreStuff.routes, ...Product.routes, ...ProductEvent.routes, ...Bill.routes],
  },
  { id: 'deliveryOrder', name: '결제 · 배송', routes: [...DeliveryOrder.routes] },
  { id: 'development', name: '개발', routes: [...CodepushVersion.routes, ...EnigmaBuilder.routes] },
  { id: 'classroom', name: '클래스', routes: [...Organization.routes, ...Book.routes] },
];

export const authenticatedRoutes: RouteType[] = authenticatedRouteGroups.reduce<RouteType[]>(
  (result, { routes }) => [...result, ...routes],
  [],
);

export const routes: RouteType[] = [...unauthenticatedRoutes, ...authenticatedRoutes];
