/**
 * @generated SignedSource<<4afcd8b02069085416961751424f3017>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATLmsSendInput = {
  content: string;
  phoneNumber: any;
};
export type MATOrganizationLMSSendDialog_lmsSendMutation$variables = {
  input: MATLmsSendInput;
};
export type MATOrganizationLMSSendDialog_lmsSendMutation$data = {
  readonly lmsSend: boolean;
};
export type MATOrganizationLMSSendDialog_lmsSendMutation = {
  response: MATOrganizationLMSSendDialog_lmsSendMutation$data;
  variables: MATOrganizationLMSSendDialog_lmsSendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "lmsSend",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATOrganizationLMSSendDialog_lmsSendMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATOrganizationLMSSendDialog_lmsSendMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f0d638788e6d91f28f0b51e17ce979ca",
    "id": null,
    "metadata": {},
    "name": "MATOrganizationLMSSendDialog_lmsSendMutation",
    "operationKind": "mutation",
    "text": "mutation MATOrganizationLMSSendDialog_lmsSendMutation(\n  $input: MATLmsSendInput!\n) {\n  lmsSend(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "e3bd7be42a4d47fc94cdfeb7f7160cb7";

export default node;
