import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import { TeacherSelectField_teachersQuery } from '../../../relay/__generated__/TeacherSelectField_teachersQuery.graphql';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const teachersForTeacherSelectField = graphql`
  query TeacherSelectField_teachersQuery($first: Int) {
    teachers(first: $first) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

type Props = {} & Omit<SelectFieldProps, 'options'>;

const TeacherSelectField = ({ name = '', value, ...props }: Props) => {
  const [{ teachers }] = useLazyLoadQuery<TeacherSelectField_teachersQuery>(teachersForTeacherSelectField, {});
  const { edges = [] } = teachers || {};

  return (
    <SelectField
      name={name}
      options={edges?.map(({ node }) => ({ id: node.id, text: node.name })) || []}
      value={value}
      {...props}
    />
  );
};

export default TeacherSelectField;
