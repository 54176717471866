import { CheckIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import { ProblemGeneratorAssignDialog_problemGeneratorAssignMutation } from '../../../relay/__generated__/ProblemGeneratorAssignDialog_problemGeneratorAssignMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import UserAvatarRadioGroupField from '../../user/UserAvatarRadioGroupField';

type Props = {} & DialogProps &
  Pick<
    MutationFormikProps<ProblemGeneratorAssignDialog_problemGeneratorAssignMutation>,
    'onSubmit' | 'config' | 'connections'
  > &
  Pick<
    MutationFormikProps<ProblemGeneratorAssignDialog_problemGeneratorAssignMutation>['initialValues'],
    'problemGenerators'
  >;

const ProblemGeneratorAssignDialog = ({
  isOpen,
  onSubmit,
  config,
  connections,
  problemGenerators,
  ...props
}: Props) => {
  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>배정하기</Text>
      </Dialog.Header>
      <MutationFormik<ProblemGeneratorAssignDialog_problemGeneratorAssignMutation>
        mutation={graphql`
          mutation ProblemGeneratorAssignDialog_problemGeneratorAssignMutation($input: ProblemGeneratorAssignInput!) {
            problemGeneratorAssign(input: $input) {
              id
              actions
              assigned
              assignedTo {
                id
                ...UserAvatarText_user
              }
            }
          }
        `}
        initialValues={{
          assignedTo: '',
          problemGenerators,
        }}
        onSubmit={onSubmit}
        config={config}
        connections={connections}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <Suspense>
                <UserAvatarRadioGroupField
                  label={'작업자'}
                  name={'assignedTo'}
                  required
                  variables={{ filters: { team_In: ['contents'] } }}
                />
              </Suspense>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} size={'large'} onClick={() => handleSubmit()}>
                배정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default ProblemGeneratorAssignDialog;
export type { Props as ProblemGeneratorAssignDialogProps };
