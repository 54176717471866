/**
 * @generated SignedSource<<3c98274fa4e77931847124e974904460>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskStatusEnum = "deactivated" | "error_report" | "first_labeling" | "image_creation" | "problem_creation" | "problem_typing" | "published" | "review" | "second_labeling" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskStatusLabel_task$data = {
  readonly status: TaskStatusEnum;
  readonly " $fragmentType": "TaskStatusLabel_task";
};
export type TaskStatusLabel_task$key = {
  readonly " $data"?: TaskStatusLabel_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskStatusLabel_task">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskStatusLabel_task",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};

(node as any).hash = "65223ed8794007199f6306b03aa15bd3";

export default node;
