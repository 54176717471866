import { LinkExternalIcon } from '@primer/octicons-react';
import { useField } from 'formik';
import { Suspense, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import usePaginatorSearchOverlayQueryLoader from '../../../hooks/usePaginatorSearchOverlayQueryLoader';
import {
  BookPaginatorSearchOverlayField_booksQuery,
  BookPaginatorSearchOverlayField_booksQuery$data,
  BookPaginatorSearchOverlayField_booksQuery$variables,
} from '../../../relay/__generated__/BookPaginatorSearchOverlayField_booksQuery.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import ItemList from '../../core/ItemList';
import Link from '../../core/Link';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import ScrollContainer from '../../core/ScrollContainer';
import SearchOverlayField, { SearchOverlayFieldProps } from '../../core/SearchOverlayField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';
import BookPaginator from '../BookPaginator';

const booksForBookPaginatorSearchOverlayField = graphql`
  query BookPaginatorSearchOverlayField_booksQuery(
    $filters: BookFilter
    $order: BookOrder
    $first: Int
    $after: String
  ) {
    books(filters: $filters, order: $order, first: $first, after: $after) {
      edges {
        node {
          id
          title
        }
      }
    }
    ...BookPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

type Props = {
  variables?: BookPaginatorSearchOverlayField_booksQuery$variables;
} & Omit<
  SearchOverlayFieldProps<BookPaginatorSearchOverlayField_booksQuery$data['books']['edges'][number]['node']>,
  'renderItemList'
>;

const BookPaginatorSearchOverlayField = ({ name = '', value: propValue, variables, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [valueQueryReference, loadValueQuery, disposeValueQuery] =
    useQueryLoader<BookPaginatorSearchOverlayField_booksQuery>(booksForBookPaginatorSearchOverlayField);
  useEffect(() => {
    loadValueQuery({ filters: { id_Exact: value as string } });
    return () => disposeValueQuery();
  }, [value, loadValueQuery, disposeValueQuery]);

  const [searchOverlayFieldProps, queryReference] =
    usePaginatorSearchOverlayQueryLoader<BookPaginatorSearchOverlayField_booksQuery>(
      booksForBookPaginatorSearchOverlayField,
      variables,
    );

  return valueQueryReference ? (
    <PreloadedQueryRenderer query={booksForBookPaginatorSearchOverlayField} queryReference={valueQueryReference}>
      {({ books }) => (
        <SearchOverlayField
          value={books?.edges.map(({ node }) => node.title).join(', ') || ''}
          name={name}
          renderItemList={({ containerRef, handleSelect }, itemProps) =>
            queryReference ? (
              <Suspense fallback={<Spinner sx={{ paddingY: 3 }} />}>
                <PreloadedQueryRenderer query={booksForBookPaginatorSearchOverlayField} queryReference={queryReference}>
                  {(queryData) => (
                    <BookPaginator fragmentReference={queryData}>
                      {(data, { hasNext, isLoadingNext, loadMore }) => (
                        <ScrollContainer
                          ref={containerRef as React.RefObject<HTMLDivElement>}
                          onScrollReachEnd={() => {
                            if (hasNext && !isLoadingNext) loadMore(20);
                          }}
                        >
                          <ItemList
                            items={data.books.edges}
                            renderItem={({ node: { title, publisher } }) => (
                              <>
                                <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>{' '}
                                <Text sx={{ fontSize: 1 }}>[{publisher}]</Text>
                              </>
                            )}
                            renderItemWrapper={(children, { node }) => (
                              <View
                                {...itemProps}
                                key={node.id}
                                onClick={(e) => handleSelect(e, node)}
                                onKeyDown={(e) => handleSelect(e, node)}
                              >
                                <Grid gapX={2} sx={{ alignItems: 'center' }}>
                                  <Grid.Unit size={'max'}>
                                    {children}
                                    <View>
                                      <Text sx={{ fontSize: 0, color: 'fg.subtle' }}>
                                        {node.unitAs.map(({ title }) => title).join(', ')}
                                      </Text>
                                    </View>
                                  </Grid.Unit>
                                  <Grid.Unit size={'min'}>
                                    <Link
                                      href={{ pathname: '/book/[bookId]', query: { bookId: node.id } }}
                                      target={'_blank'}
                                    >
                                      <IconButton
                                        icon={LinkExternalIcon}
                                        size={'small'}
                                        variant={'plain'}
                                        aria-labelledby={'보러가기'}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      />
                                    </Link>
                                  </Grid.Unit>
                                </Grid>
                              </View>
                            )}
                            emptyState={
                              <View sx={{ paddingY: 3 }}>
                                <EmptyState
                                  title={'검색 결과가 없어요'}
                                  description={'다른 검색어로 다시 시도해주세요.'}
                                />
                              </View>
                            }
                          />
                          <View>{isLoadingNext ? <Spinner size={'small'} /> : null}</View>
                        </ScrollContainer>
                      )}
                    </BookPaginator>
                  )}
                </PreloadedQueryRenderer>
              </Suspense>
            ) : null
          }
          {...searchOverlayFieldProps}
          {...props}
        />
      )}
    </PreloadedQueryRenderer>
  ) : (
    <Spinner size={'small'} />
  );
};

export default BookPaginatorSearchOverlayField;
