import { useField } from 'formik';
import { Suspense, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import usePaginatorSearchOverlayQueryLoader from '../../../hooks/usePaginatorSearchOverlayQueryLoader';
import {
  SchoolPaginatorSearchOverlayTokenField_schoolsQuery,
  SchoolPaginatorSearchOverlayTokenField_schoolsQuery$variables,
} from '../../../relay/__generated__/SchoolPaginatorSearchOverlayTokenField_schoolsQuery.graphql';
import { isNullable } from '../../../utils/is';
import Checkbox from '../../core/Checkbox';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import ScrollContainer from '../../core/ScrollContainer';
import SearchOverlayTokenField, { SearchOverlayTokenFieldProps } from '../../core/SearchOverlayTokenField';
import Spinner from '../../core/Spinner';
import TextHighlight from '../../core/TextHighlight';
import View from '../../core/View';
import SchoolItem from '../SchoolItem';
import SchoolPaginator from '../SchoolPaginator';

const schoolsForSchoolPaginatorSearchOverlayTokenField = graphql`
  query SchoolPaginatorSearchOverlayTokenField_schoolsQuery(
    $filters: SchoolFilter
    $order: SchoolOrder
    $first: Int
    $after: String
  ) {
    schools(filters: $filters, order: $order, first: $first, after: $after) {
      edges {
        node {
          id
          title
        }
      }
    }
    ...SchoolPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

type Props = {
  variables?: SchoolPaginatorSearchOverlayTokenField_schoolsQuery$variables;
} & Omit<SearchOverlayTokenFieldProps, 'renderItemList'>;

const SchoolPaginatorSearchOverlayTokenField = ({ name = '', value: propValue, variables, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string[] | undefined>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [valueQueryReference, loadValueQuery, disposeValueQuery] =
    useQueryLoader<SchoolPaginatorSearchOverlayTokenField_schoolsQuery>(
      schoolsForSchoolPaginatorSearchOverlayTokenField,
    );
  useEffect(() => {
    loadValueQuery({ filters: { id_In: value || [] } });
    return () => disposeValueQuery();
  }, [baseValue, loadValueQuery, disposeValueQuery]);

  const [searchOverlayFieldProps, queryReference] =
    usePaginatorSearchOverlayQueryLoader<SchoolPaginatorSearchOverlayTokenField_schoolsQuery>(
      schoolsForSchoolPaginatorSearchOverlayTokenField,
      variables,
    );

  return valueQueryReference ? (
    <PreloadedQueryRenderer
      query={schoolsForSchoolPaginatorSearchOverlayTokenField}
      queryReference={valueQueryReference}
    >
      {({ schools }) => (
        <SearchOverlayTokenField
          name={name}
          value={value}
          renderTokenText={(id) => schools?.edges.find(({ node }) => node.id === id)?.node.title || ''}
          renderItemList={({ containerRef, handleSelect }, itemProps) =>
            queryReference ? (
              <Suspense fallback={<Spinner sx={{ paddingY: 3 }} />}>
                <PreloadedQueryRenderer
                  query={schoolsForSchoolPaginatorSearchOverlayTokenField}
                  queryReference={queryReference}
                >
                  {(queryData) => (
                    <SchoolPaginator fragmentReference={queryData}>
                      {(data, { hasNext, isLoadingNext, loadMore }) => (
                        <ScrollContainer
                          ref={containerRef as React.RefObject<HTMLDivElement>}
                          onScrollReachEnd={() => {
                            if (hasNext && !isLoadingNext) loadMore(20);
                          }}
                        >
                          <ItemList
                            items={data.schools.edges}
                            renderItem={({ node }) => (
                              <TextHighlight text={queryReference.variables.filters?.search}>
                                <SchoolItem school={node} />
                              </TextHighlight>
                            )}
                            renderItemWrapper={(children, { node: { id } }) => (
                              <View
                                {...itemProps}
                                key={id}
                                onClick={(e) => handleSelect(e, id)}
                                onKeyDown={(e) => handleSelect(e, id)}
                              >
                                <Grid gapX={2} wrap={false}>
                                  <Grid.Unit size={'min'}>
                                    <Checkbox checked={value?.some((selectedId) => selectedId === id)} />
                                  </Grid.Unit>
                                  <Grid.Unit size={'max'}>{children}</Grid.Unit>
                                </Grid>
                              </View>
                            )}
                            emptyState={
                              <View sx={{ paddingY: 3 }}>
                                <EmptyState
                                  title={'검색 결과가 없어요'}
                                  description={'다른 검색어로 다시 시도해주세요.'}
                                />
                              </View>
                            }
                          />
                          <View>{isLoadingNext ? <Spinner size={'small'} /> : null}</View>
                        </ScrollContainer>
                      )}
                    </SchoolPaginator>
                  )}
                </PreloadedQueryRenderer>
              </Suspense>
            ) : null
          }
          {...searchOverlayFieldProps}
          {...props}
        />
      )}
    </PreloadedQueryRenderer>
  ) : (
    <Spinner size={'small'} />
  );
};

export default SchoolPaginatorSearchOverlayTokenField;
