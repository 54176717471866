/**
 * @generated SignedSource<<acb3716e54770fafabb3d72defad2369>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RaffleStuffConnectionDataTable_raffleStuffConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly id: string;
      readonly modified: any;
      readonly stuff: {
        readonly id: string;
        readonly title: string;
      };
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"RaffleStuffPreviewItem_raffleStuff">;
    };
  }>;
  readonly " $fragmentType": "RaffleStuffConnectionDataTable_raffleStuffConnection";
};
export type RaffleStuffConnectionDataTable_raffleStuffConnection$key = {
  readonly " $data"?: RaffleStuffConnectionDataTable_raffleStuffConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"RaffleStuffConnectionDataTable_raffleStuffConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RaffleStuffConnectionDataTable_raffleStuffConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RaffleStuffEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RaffleStuff",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Stuff",
              "kind": "LinkedField",
              "name": "stuff",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RaffleStuffPreviewItem_raffleStuff"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modified",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RaffleStuffConnection",
  "abstractKey": null
};
})();

(node as any).hash = "eadf16b40d26e17329b10d016ff93611";

export default node;
