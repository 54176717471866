/**
 * @generated SignedSource<<f7fc3069857ca43c0135f6ede4d0fdd7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScrapSourceExamCategoryEnum = "common" | "giha" | "hwak_tong" | "mijukbun" | "none" | "type_ga" | "type_na" | "%future added value";
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
export type WorkbookCreateInput = {
  categories: ReadonlyArray<ScrapSourceExamCategoryEnum>;
  grade: ScrapSourceExamGradeEnum;
  month: ScrapSourceExamMonthEnum;
  sourceName: string;
  title: string;
  year: ScrapSourceExamYearEnum;
};
export type WorkbookCreateDialog_workbookCreateMutation$variables = {
  input: WorkbookCreateInput;
};
export type WorkbookCreateDialog_workbookCreateMutation$data = {
  readonly workbookCreate: {
    readonly id: string;
  };
};
export type WorkbookCreateDialog_workbookCreateMutation = {
  response: WorkbookCreateDialog_workbookCreateMutation$data;
  variables: WorkbookCreateDialog_workbookCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Workbook",
    "kind": "LinkedField",
    "name": "workbookCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookCreateDialog_workbookCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookCreateDialog_workbookCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "63981425c57cebde60d9a05c4a66e6ff",
    "id": null,
    "metadata": {},
    "name": "WorkbookCreateDialog_workbookCreateMutation",
    "operationKind": "mutation",
    "text": "mutation WorkbookCreateDialog_workbookCreateMutation(\n  $input: WorkbookCreateInput!\n) {\n  workbookCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3cfeee335a14c76fdb4e6b8fed4f489f";

export default node;
