/**
 * @generated SignedSource<<c622dd68671c203fd6022159739a265b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationStudentsCreateDialog_matOrganization$data = {
  readonly id: string;
  readonly " $fragmentType": "MATOrganizationStudentsCreateDialog_matOrganization";
};
export type MATOrganizationStudentsCreateDialog_matOrganization$key = {
  readonly " $data"?: MATOrganizationStudentsCreateDialog_matOrganization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATOrganizationStudentsCreateDialog_matOrganization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATOrganizationStudentsCreateDialog_matOrganization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "MATOrganization",
  "abstractKey": null
};

(node as any).hash = "131c6fc71bcc53735dbc66062a380f72";

export default node;
