import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  DeliveryWaybillDescriptionList_deliveryWaybill$data,
  DeliveryWaybillDescriptionList_deliveryWaybill$key,
} from '../../../relay/__generated__/DeliveryWaybillDescriptionList_deliveryWaybill.graphql';
import { numberWithCommas } from '../../../utils/number';
import CopyableText from '../../core/CopyableText';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';

const DeliveryWaybillDescriptionList_deliveryWaybill = graphql`
  fragment DeliveryWaybillDescriptionList_deliveryWaybill on DeliveryWaybill {
    pickUpAt
    waybillNumber
    sender
    senderNumber
    pickUpStore
    paymentType
    boxType
    count
    innerCount
    basicFee
    extraFee
    totalFee
  }
`;

type Props = {
  deliveryWaybill: DeliveryWaybillDescriptionList_deliveryWaybill$key;
  type?: 'default' | 'fee';
} & Omit<
  DescriptionListProps<DeliveryWaybillDescriptionList_deliveryWaybill$data>,
  'item' | 'itemDescriptions' | 'picks'
>;

const DeliveryWaybillDescriptionList = ({
  deliveryWaybill: deliveryWaybillReference,
  type = 'default',
  ...props
}: Props) => {
  const deliveryWaybill = useFragment(DeliveryWaybillDescriptionList_deliveryWaybill, deliveryWaybillReference);

  return (
    <DescriptionList
      item={deliveryWaybill}
      itemDescriptions={{
        pickUpAt: {
          title: '집화예정일자',
          renderValue: ({ pickUpAt }) => (pickUpAt ? <DateText format={'P'}>{pickUpAt}</DateText> : undefined),
        },
        waybillNumber: {
          title: '운송장번호',
          renderValue: ({ waybillNumber }) => <CopyableText>{waybillNumber}</CopyableText>,
        },
        sender: {
          title: '보내는분',
          renderValue: ({ sender }) => <CopyableText>{sender}</CopyableText>,
        },
        senderNumber: {
          title: '보내는분 전화번호',
          renderValue: ({ senderNumber }) => <CopyableText>{senderNumber}</CopyableText>,
        },
        pickUpStore: {
          title: '집화예정장소',
          renderValue: ({ pickUpStore }) => (pickUpStore ? <CopyableText>{pickUpStore}</CopyableText> : undefined),
        },
        paymentType: {
          title: '운임구분',
          renderValue: ({ paymentType }) => (
            <EnumPair typename={'DeliveryWaybillPaymentTypeEnum'}>{paymentType}</EnumPair>
          ),
        },
        boxType: {
          title: '박스타입',
          renderValue: ({ boxType }) => <EnumPair typename={'DeliveryWaybillBoxTypeEnum'}>{boxType}</EnumPair>,
        },
        count: {
          title: '수량',
          renderValue: ({ count }) => numberWithCommas(count),
        },
        innerCount: {
          title: '내품 수량',
          renderValue: ({ innerCount }) => numberWithCommas(innerCount),
        },
        basicFee: {
          title: '기본운임',
          renderValue: ({ basicFee }) => numberWithCommas(basicFee),
        },
        extraFee: {
          title: '기타운임',
          renderValue: ({ extraFee }) => numberWithCommas(extraFee),
        },
        totalFee: {
          title: '운임합계',
          renderValue: ({ totalFee }) => numberWithCommas(totalFee),
        },
      }}
      picks={
        type === 'default'
          ? ['pickUpAt', 'waybillNumber', 'pickUpStore', 'sender', 'senderNumber']
          : type === 'fee'
          ? ['paymentType', 'boxType', 'count', 'innerCount', 'basicFee', 'extraFee', 'totalFee']
          : []
      }
      {...props}
    />
  );
};

export default DeliveryWaybillDescriptionList;
