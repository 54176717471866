/**
 * @generated SignedSource<<7d9961b4bb5de56ee94ea0c01ed0e348>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
export type WorkbookUpdateInput = {
  grade?: ScrapSourceExamGradeEnum | null;
  id: string;
  month?: ScrapSourceExamMonthEnum | null;
  sourceName?: string | null;
  title?: string | null;
  year?: ScrapSourceExamYearEnum | null;
};
export type WorkbookUpdateDialog_workbookUpdateMutation$variables = {
  input: WorkbookUpdateInput;
};
export type WorkbookUpdateDialog_workbookUpdateMutation$data = {
  readonly workbookUpdate: {
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"WorkbookStatusLabel_workbook" | "WorkbookUpdateDialog_workbook">;
  };
};
export type WorkbookUpdateDialog_workbookUpdateMutation = {
  response: WorkbookUpdateDialog_workbookUpdateMutation$data;
  variables: WorkbookUpdateDialog_workbookUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookUpdateDialog_workbookUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookStatusLabel_workbook"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookUpdateDialog_workbook"
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookUpdateDialog_workbookUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grade",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "month",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sourceName",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9f64e9b8580bffd1be655894843cdbfc",
    "id": null,
    "metadata": {},
    "name": "WorkbookUpdateDialog_workbookUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation WorkbookUpdateDialog_workbookUpdateMutation(\n  $input: WorkbookUpdateInput!\n) {\n  workbookUpdate(input: $input) {\n    id\n    ...WorkbookStatusLabel_workbook\n    ...WorkbookUpdateDialog_workbook\n    modified\n    modifiedBy {\n      ...UserAvatarText_user\n      id\n    }\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n\nfragment WorkbookStatusLabel_workbook on Workbook {\n  status\n}\n\nfragment WorkbookUpdateDialog_workbook on Workbook {\n  id\n  title\n  grade\n  month\n  year\n  sourceName\n}\n"
  }
};
})();

(node as any).hash = "8f0281fe8dbfa9b512f055fbe6f0c3e3";

export default node;
