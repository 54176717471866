/**
 * @generated SignedSource<<c44410c9fef928317a24b70159564723>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StoreStuffActionEnum = "store_stuff_delete" | "store_stuff_update" | "%future added value";
export type StoreStuffId_storeStuffQuery$variables = {
  id: string;
};
export type StoreStuffId_storeStuffQuery$data = {
  readonly storeStuff: {
    readonly actions: ReadonlyArray<StoreStuffActionEnum>;
    readonly id: string;
    readonly stuff: {
      readonly id: string;
      readonly title: string;
    } | null;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"StoreStuffDescriptionList_storeStuff" | "StoreStuffUpdateDialog_storeStuff">;
  };
};
export type StoreStuffId_storeStuffQuery = {
  response: StoreStuffId_storeStuffQuery$data;
  variables: StoreStuffId_storeStuffQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Stuff",
  "kind": "LinkedField",
  "name": "stuff",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StoreStuffId_storeStuffQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StoreStuff",
        "kind": "LinkedField",
        "name": "storeStuff",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StoreStuffDescriptionList_storeStuff"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StoreStuffUpdateDialog_storeStuff"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StoreStuffId_storeStuffQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "StoreStuff",
        "kind": "LinkedField",
        "name": "storeStuff",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "period",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b420829f2a1b0ba565f66a0f7b17083f",
    "id": null,
    "metadata": {},
    "name": "StoreStuffId_storeStuffQuery",
    "operationKind": "query",
    "text": "query StoreStuffId_storeStuffQuery(\n  $id: ID!\n) {\n  storeStuff(id: $id) {\n    id\n    title\n    actions\n    stuff {\n      id\n      title\n    }\n    ...StoreStuffDescriptionList_storeStuff\n    ...StoreStuffUpdateDialog_storeStuff\n  }\n}\n\nfragment StoreStuffDescriptionList_storeStuff on StoreStuff {\n  id\n  type\n  period\n  endAt\n  stuff {\n    id\n    title\n  }\n  created\n  createdBy {\n    ...UserAvatarText_user\n    id\n  }\n}\n\nfragment StoreStuffUpdateDialog_storeStuff on StoreStuff {\n  id\n  title\n  type\n  period\n  endAt\n  stuff {\n    id\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "6d32bdc3c77d9b83831f7ddfacc6a82b";

export default node;
