/**
 * @generated SignedSource<<56d7c38e179c67482522cef8d318594c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type SchoolTypeEnum = "elementary_school" | "high_school" | "middle_school" | "%future added value";
export type UnitBFilter = {
  AND?: UnitBFilter | null;
  DISTINCT?: boolean | null;
  NOT?: UnitBFilter | null;
  OR?: UnitBFilter | null;
  courseId_Exact?: string | null;
  courseId_In?: ReadonlyArray<string> | null;
  courseTitle_Contains?: string | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  search?: string | null;
  title_Contains?: string | null;
  title_Exact?: string | null;
  title_In?: ReadonlyArray<string> | null;
  unitAId_Exact?: string | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitId_Exact?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitSchoolType_Exact?: SchoolTypeEnum | null;
  unitSchoolType_In?: ReadonlyArray<SchoolTypeEnum> | null;
  unitTitle_Contains?: string | null;
};
export type UnitBOrder = {
  id?: Ordering | null;
  order?: Ordering | null;
  schoolType?: Ordering | null;
  title?: Ordering | null;
};
export type UnitBSelectField_unitBsQuery$variables = {
  filters?: UnitBFilter | null;
  order?: UnitBOrder | null;
};
export type UnitBSelectField_unitBsQuery$data = {
  readonly unitBs: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly title: string;
      };
    }>;
  };
};
export type UnitBSelectField_unitBsQuery = {
  response: UnitBSelectField_unitBsQuery$data;
  variables: UnitBSelectField_unitBsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      }
    ],
    "concreteType": "UnitBConnection",
    "kind": "LinkedField",
    "name": "unitBs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnitBEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnitB",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UnitBSelectField_unitBsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UnitBSelectField_unitBsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eeecd58d042ca3dd44ad5e8b7be0ba49",
    "id": null,
    "metadata": {},
    "name": "UnitBSelectField_unitBsQuery",
    "operationKind": "query",
    "text": "query UnitBSelectField_unitBsQuery(\n  $filters: UnitBFilter\n  $order: UnitBOrder\n) {\n  unitBs(filters: $filters, order: $order) {\n    edges {\n      node {\n        id\n        title\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b12924b2878a8cbb057bed0c47373da";

export default node;
