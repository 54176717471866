/**
 * @generated SignedSource<<ea7a426f2d4e6685053c7db0ae65a8e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductEventUserYearEnum = "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3" | "high_school_N" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductEventUpdateDialog_productEvent$data = {
  readonly description: string | null;
  readonly discounts: ReadonlyArray<{
    readonly amount: number;
    readonly id: string;
    readonly product: {
      readonly id: string;
    };
  }>;
  readonly endAt: any;
  readonly id: string;
  readonly startAt: any;
  readonly title: string;
  readonly userYears: ReadonlyArray<ProductEventUserYearEnum>;
  readonly " $fragmentType": "ProductEventUpdateDialog_productEvent";
};
export type ProductEventUpdateDialog_productEvent$key = {
  readonly " $data"?: ProductEventUpdateDialog_productEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductEventUpdateDialog_productEvent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductEventUpdateDialog_productEvent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductDiscount",
      "kind": "LinkedField",
      "name": "discounts",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Product",
          "kind": "LinkedField",
          "name": "product",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userYears",
      "storageKey": null
    }
  ],
  "type": "ProductEvent",
  "abstractKey": null
};
})();

(node as any).hash = "844591cd0e2f6e8e673d7f19debc6b1a";

export default node;
