/**
 * @generated SignedSource<<7e43211c81449511118adc302f0fcb09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type QuizAnswerTypeEnum = "O" | "X" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type QuizUpdateDialog_quiz$data = {
  readonly answer: QuizAnswerTypeEnum;
  readonly description: string | null;
  readonly id: string;
  readonly problem: string;
  readonly solution: string;
  readonly tip: string;
  readonly unitD: {
    readonly id: string;
  };
  readonly " $fragmentType": "QuizUpdateDialog_quiz";
};
export type QuizUpdateDialog_quiz$key = {
  readonly " $data"?: QuizUpdateDialog_quiz$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizUpdateDialog_quiz">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizUpdateDialog_quiz",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnitD",
      "kind": "LinkedField",
      "name": "unitD",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "problem",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "solution",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tip",
      "storageKey": null
    }
  ],
  "type": "Quiz",
  "abstractKey": null
};
})();

(node as any).hash = "ba197455f7e9b2c121ba6880660b513b";

export default node;
