import { StopIcon } from '@primer/octicons-react';
import { useField } from 'formik';
import { forwardRef, Ref } from 'react';

import Flash, { FlashProps } from '../Flash';
import View from '../View';

type Props = {} & Omit<FlashProps, 'variant'>;

const NonFieldError = ({ sx, ...props }: Props, ref: Ref<HTMLDivElement>) => {
  const [, { error }] = useField({ name: 'nonFieldError' });

  return error ? (
    <View ref={ref} sx={{ ...sx }}>
      <Flash variant={'danger'} {...props}>
        <StopIcon />
        {error}
      </Flash>
    </View>
  ) : null;
};

export default forwardRef(NonFieldError);
