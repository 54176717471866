import { useEffect } from 'react';
import { GraphQLTaggedNode, PreloadedQuery, useQueryLoader, UseQueryLoaderLoadQueryOptions } from 'react-relay';

import { QueryFormikContextProvider } from '../../../contexts/QueryFormikContext';
import { isFunction } from '../../../utils/is';
import { normalizeObject } from '../../../utils/object';
import { parseOrdering } from '../../../utils/order';
import Formik, { FormikConfig, FormikProps } from '../Formik';

import FilterGrid from './FilterGrid';
import FilterGridUnit from './FilterGridUnit';
import FilterSearchTextField from './FilterSearchTextField';
import OrderActionMenuButton from './OrderActionMenuButton';
import PreloadedQueryRenderer from './PreloadedQueryRenderer';

type FormikValueType<
  T extends {
    variables: {
      filters?: {} | null;
    };
  },
> = Partial<
  Omit<T['variables'], 'order' | 'filters'> & {
    order?: string;
    // AND OR NOT 연산자는 formik value type으로 사용하지 않도록 임시조치합니다
    // 서버 스펙 업그레이드로 모든 filter에 필드들이 추가되었는데 기존 formik 방식과 달라서 논의가 필요합니다
    filters?: Omit<NonNullable<T['variables']['filters']>, 'AND' | 'OR' | 'NOT'> | null;
  }
>;

type Props<
  TQuery extends {
    readonly response: {};
    readonly variables: {
      readonly filters?: {} | null;
      readonly order?: any | null;
      readonly page?: number | null;
    };
    readonly rawResponse?: {} | undefined;
  },
> = {
  query: GraphQLTaggedNode;
  options?: UseQueryLoaderLoadQueryOptions;
  children?:
    | ((
        props: FormikProps<FormikValueType<TQuery>>,
        queryReference: PreloadedQuery<TQuery, Record<string, unknown>> | null | undefined,
      ) => React.ReactNode)
    | React.ReactNode;
  onSubmit?: FormikConfig<FormikValueType<TQuery>>['onSubmit'];
  staticVariables?: FormikValueType<TQuery>;
  enableInitialQueryLoad?: boolean;
} & Omit<FormikConfig<FormikValueType<TQuery>>, 'onSubmit' | 'children'>;

const QueryFormik = <
  TQuery extends {
    readonly response: {};
    readonly variables: {
      readonly filters?: {} | null;
      readonly order?: any | null;
      readonly page?: number | null;
    };
  },
>({
  query,
  initialValues,
  staticVariables,
  enableInitialQueryLoad = true,
  onSubmit,
  children,
  options,
  ...props
}: Props<TQuery>) => {
  const [queryReference, baseLoadQuery] = useQueryLoader<TQuery>(query);

  const loadQuery = (variables: TQuery['variables'], options?: UseQueryLoaderLoadQueryOptions) => {
    const normalizedFilters = normalizeObject(variables.filters);
    const normalizedVariables = normalizeObject({ ...variables, filters: normalizedFilters }) as TQuery['variables'];
    baseLoadQuery({ ...normalizedVariables, ...staticVariables }, options);
  };

  const handleSubmit: FormikConfig<FormikValueType<TQuery>>['onSubmit'] = (values, formikHelpers) => {
    onSubmit?.(values, formikHelpers);
    loadQuery({ ...values, order: values.order ? parseOrdering(values.order) : undefined }, options);

    formikHelpers.setSubmitting(false);
  };

  useEffect(() => {
    if (enableInitialQueryLoad) {
      loadQuery(
        { ...initialValues, order: initialValues.order ? parseOrdering(initialValues.order) : undefined },
        options,
      );
    }
  }, [JSON.stringify(initialValues)]);

  return (
    <QueryFormikContextProvider value={{ query, queryReference }}>
      <Formik<FormikValueType<TQuery>> initialValues={initialValues} onSubmit={handleSubmit} {...props}>
        {isFunction(children) ? (formikProps) => children(formikProps, queryReference) : children}
      </Formik>
    </QueryFormikContextProvider>
  );
};

export default Object.assign(QueryFormik, {
  OrderActionMenuButton,
  PreloadedQueryRenderer,
  FilterGrid,
  FilterGridUnit,
  FilterSearchTextField,
});
