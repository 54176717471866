/**
 * @generated SignedSource<<9e3890928214c45641c4dcfdf9296ce0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppPushStatusEnum = "completed" | "scheduled" | "%future added value";
export type AppPushFilter = {
  AND?: AppPushFilter | null;
  DISTINCT?: boolean | null;
  NOT?: AppPushFilter | null;
  OR?: AppPushFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  endAt_Gte?: any | null;
  endAt_Lte?: any | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  startAt_Gte?: any | null;
  startAt_Lte?: any | null;
  status_Exact?: AppPushStatusEnum | null;
  status_In?: ReadonlyArray<AppPushStatusEnum> | null;
};
export type appPush_appPushXlsxExportQuery$variables = {
  filters?: AppPushFilter | null;
};
export type appPush_appPushXlsxExportQuery$data = {
  readonly appPushXlsxExport: string;
};
export type appPush_appPushXlsxExportQuery = {
  response: appPush_appPushXlsxExportQuery$data;
  variables: appPush_appPushXlsxExportQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      }
    ],
    "kind": "ScalarField",
    "name": "appPushXlsxExport",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "appPush_appPushXlsxExportQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "appPush_appPushXlsxExportQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9556e5221ec45605ec5f9c86e0280f06",
    "id": null,
    "metadata": {},
    "name": "appPush_appPushXlsxExportQuery",
    "operationKind": "query",
    "text": "query appPush_appPushXlsxExportQuery(\n  $filters: AppPushFilter\n) {\n  appPushXlsxExport(filters: $filters)\n}\n"
  }
};
})();

(node as any).hash = "510bf5bb0ec554d867111e2c2af9a46a";

export default node;
