/**
 * @generated SignedSource<<9c77e581b75220d66bce1a2486332e16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskImageCreationDifferenceCounterLabel_difference$data = {
  readonly problem?: {
    readonly __typename: "Diff";
  } | null;
  readonly solution?: {
    readonly __typename: "Diff";
  } | null;
  readonly " $fragmentType": "TaskImageCreationDifferenceCounterLabel_difference";
};
export type TaskImageCreationDifferenceCounterLabel_difference$key = {
  readonly " $data"?: TaskImageCreationDifferenceCounterLabel_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskImageCreationDifferenceCounterLabel_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskImageCreationDifferenceCounterLabel_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "problem",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "solution",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "TaskImageCreationDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "02200d065811137d159a1557c852fec5";

export default node;
