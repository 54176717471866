import { ArrowRightIcon } from '@primer/octicons-react';
import { BetterSystemStyleObject } from '@primer/react/lib/sx';
import { graphql, useFragment } from 'react-relay';

import { DiffItem_diff$key } from '../../../relay/__generated__/DiffItem_diff.graphql';
import Grid from '../../core/Grid';
import View from '../../core/View';

const DiffItem_diff = graphql`
  fragment DiffItem_diff on Diff {
    before
    after
  }
`;

type Props = {
  diff: DiffItem_diff$key;
  renderItem?: (beforeOrAfter: string) => React.ReactNode;
};

const DiffItem = ({ diff, renderItem = (beforeOrAfter) => beforeOrAfter }: Props) => {
  const { before, after } = useFragment(DiffItem_diff, diff);

  const commonSx: BetterSystemStyleObject = {
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    width: '100%',
    height: '100%',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 2,
    padding: 2,
    fontSize: 1,
  };

  return (
    <Grid>
      <Grid.Unit size={6 / 13}>
        <View
          sx={{
            ...commonSx,
            borderColor: 'border.default',
            overflow: 'scroll',
          }}
        >
          {before ? renderItem(before) : null}
        </View>
      </Grid.Unit>
      <Grid.Unit size={1 / 13}>
        <View sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <ArrowRightIcon />
        </View>
      </Grid.Unit>
      <Grid.Unit size={6 / 13}>
        <View
          sx={{
            ...commonSx,
            borderColor: 'success.emphasis',
            overflow: 'scroll',
          }}
        >
          {after ? renderItem(after) : after}
        </View>
      </Grid.Unit>
    </Grid>
  );
};

export default DiffItem;
export type { Props as DiffItemProps };
