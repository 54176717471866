import { Spinner as PrimerSpinner, SpinnerProps as PrimerSpinnerProps } from '@primer/react';

import View from '../View';

type Props = {} & PrimerSpinnerProps;

const Spinner = ({ ...props }: Props) => (
  <View sx={{ overflow: 'hidden', lineHeight: 1 }}>
    <PrimerSpinner sx={{ width: '100%', ...props.sx }} {...props} />
  </View>
);

export default Spinner;
export type { Props as SpinnerProps };
