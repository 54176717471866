import { graphql, useFragment } from 'react-relay';

import {
  QuizSetStatusEnum,
  QuizSetStatusLabel_quizSet$key,
} from '../../../relay/__generated__/QuizSetStatusLabel_quizSet.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const QuizSetStatusLabel_quizSet = graphql`
  fragment QuizSetStatusLabel_quizSet on QuizSet {
    status
  }
`;

type Props = {
  quizSet: QuizSetStatusLabel_quizSet$key;
} & Omit<LabelProps, 'variant'>;

const QuizSetStatusLabel = ({ quizSet, ...props }: Props) => {
  const { status } = useFragment(QuizSetStatusLabel_quizSet, quizSet);

  const labelPropsByStatus: Record<QuizSetStatusEnum, LabelProps> = {
    'labeling': { variant: 'severe' },
    'published': { variant: 'success' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'QuizSetStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default QuizSetStatusLabel;
