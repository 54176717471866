import { SearchIcon } from '@primer/octicons-react';
import { useField } from 'formik';
import React, { Suspense, useEffect } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import { UnitDSearchChoiceTextField_unitDsQuery } from '../../../relay/__generated__/UnitDSearchChoiceTextField_unitDsQuery.graphql';
import { isNullable } from '../../../utils/is';
import Button from '../../core/Button';
import Dialog from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import NonFieldError from '../../core/NonFieldError';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import QueryFormik from '../../core/QueryFormik';
import QuerySearchChoiceTextField, { QuerySearchChoiceFieldProps } from '../../core/QuerySearchChoiceTextField';
import Text from '../../core/Text';
import TextInput from '../../core/TextInput';
import View from '../../core/View';
import UnitASelectField from '../../unitA/UnitASelectField';
import UnitBSelectField from '../../unitB/UnitBSelectField';
import UnitCSelectField from '../../unitC/UnitCSelectField';
import UnitDSelectField from '../UnitDSelectField';

const unitDsForUnitDSearchChoiceTextField = graphql`
  query UnitDSearchChoiceTextField_unitDsQuery($filters: UnitDFilter, $order: UnitDOrder, $first: Int) {
    unitDs(filters: $filters, order: $order, first: $first) {
      edges {
        node {
          id
          description
          unitATitle
          unitBTitle
          unitCTitle
        }
      }
    }
  }
`;

type Props = { value?: string } & Omit<
  QuerySearchChoiceFieldProps<UnitDSearchChoiceTextField_unitDsQuery>,
  'query' | 'getItems' | 'renderItem' | 'value'
>;

const UnitDSearchChoiceTextField = ({
  name = '',
  value: propValue,
  onChange: propOnChange,
  disabled,
  variables,
  ...props
}: Props) => {
  const [{ value: baseValue }, , { setValue }] = useField<string>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<UnitDSearchChoiceTextField_unitDsQuery>(
    unitDsForUnitDSearchChoiceTextField,
  );

  useEffect(() => {
    loadQuery({ filters: { id_In: [value || ''] } });
    return () => disposeQuery();
  }, [value, loadQuery, disposeQuery]);

  const advancedSearchInitialValues = {
    unitAId_In: undefined,
    unitBId_In: undefined,
    unitCId_In: undefined,
    id_In: undefined,
  };

  return queryReference ? (
    <PreloadedQueryRenderer query={unitDsForUnitDSearchChoiceTextField} queryReference={queryReference}>
      {(data) => (
        <QuerySearchChoiceTextField<UnitDSearchChoiceTextField_unitDsQuery>
          query={unitDsForUnitDSearchChoiceTextField}
          placeholder={'Unit D를 선택해주세요.'}
          getItems={({ unitDs }) => unitDs.edges}
          renderItem={({ node }) => (
            <View id={node.id} sx={{ paddingX: 2, paddingY: 1 }}>
              <Text fontSize={1}>{node.description}</Text>
              <View>
                <Text fontSize={0} sx={{ color: 'fg.muted' }}>
                  {[node.unitATitle, node.unitBTitle, node.unitCTitle].join(' / ')}
                </Text>
              </View>
            </View>
          )}
          value={data.unitDs.edges.map(({ node }) => node.description).join(', ') || ''}
          name={name}
          trailingAction={
            <QueryFormik<UnitDSearchChoiceTextField_unitDsQuery>
              query={unitDsForUnitDSearchChoiceTextField}
              initialValues={{ filters: advancedSearchInitialValues }}
            >
              {({ values: { filters }, setValues }) => (
                <TextInput.DialogAction
                  aria-label={'단계별 검색하기'}
                  icon={SearchIcon}
                  disabled={disabled}
                  renderDialog={({ isOpen, closeDialog }) => {
                    const handleDismiss = () => {
                      setValues({ filters: advancedSearchInitialValues });
                      closeDialog();
                    };
                    return (
                      <Dialog isOpen={isOpen} onDismiss={handleDismiss}>
                        <Dialog.Header>단계별 검색하기</Dialog.Header>
                        <Dialog.Body>
                          <NonFieldError sx={{ marginBottom: 3 }} />
                          <View onClick={(e) => e.stopPropagation()}>
                            <FormLayout>
                              <Suspense>
                                <UnitASelectField
                                  label={'Unit A'}
                                  name={'filters.unitAId_In[0]'}
                                  onChange={(value) =>
                                    setValues({
                                      filters: {
                                        unitAId_In: [value],
                                        unitBId_In: undefined,
                                        unitCId_In: undefined,
                                        id_In: undefined,
                                      },
                                    })
                                  }
                                  variables={{
                                    filters: { ...variables?.filters },
                                    order: { order: 'ASC' },
                                  }}
                                />
                              </Suspense>
                              {filters?.unitAId_In ? (
                                <Suspense>
                                  <UnitBSelectField
                                    label={'Unit B'}
                                    name={'filters.unitBId_In[0]'}
                                    variables={{
                                      filters: { unitAId_In: filters.unitAId_In },
                                      order: { order: 'ASC' },
                                    }}
                                    onChange={(value) =>
                                      setValues({
                                        filters: {
                                          ...filters,
                                          unitBId_In: [value],
                                          unitCId_In: undefined,
                                          id_In: undefined,
                                        },
                                      })
                                    }
                                  />
                                </Suspense>
                              ) : null}
                              {filters?.unitAId_In && filters?.unitBId_In ? (
                                <Suspense>
                                  <UnitCSelectField
                                    label={'Unit C'}
                                    name={'filters.unitCId_In[0]'}
                                    variables={{
                                      filters: { unitAId_In: filters.unitAId_In, unitBId_In: filters.unitBId_In },
                                      order: { order: 'ASC' },
                                    }}
                                    onChange={(value) =>
                                      setValues({
                                        filters: { ...filters, unitCId_In: [value], id_In: undefined },
                                      })
                                    }
                                  />
                                </Suspense>
                              ) : null}
                              {filters?.unitAId_In && filters?.unitBId_In && filters?.unitCId_In ? (
                                <Suspense>
                                  <UnitDSelectField
                                    label={'Unit D'}
                                    name={'filters.id_In[0]'}
                                    variables={{
                                      filters: {
                                        unitAId_In: filters.unitAId_In,
                                        unitBId_In: filters.unitBId_In,
                                        unitCId_In: filters.unitCId_In,
                                      },
                                      order: { order: 'ASC' },
                                    }}
                                  />
                                </Suspense>
                              ) : null}
                            </FormLayout>
                          </View>
                        </Dialog.Body>
                        <Dialog.Footer>
                          <Button
                            variant={'primary'}
                            sx={{ width: '100%' }}
                            disabled={!filters?.id_In?.[0]}
                            onClick={() => {
                              const value = filters?.id_In?.[0] || '';
                              propOnChange ? propOnChange(value) : setValue(value);
                              handleDismiss();
                            }}
                          >
                            {filters?.id_In?.[0] ? '이 Unit D 적용하기' : 'Unit D까지 선택하세요'}
                          </Button>
                        </Dialog.Footer>
                      </Dialog>
                    );
                  }}
                />
              )}
            </QueryFormik>
          }
          onChange={propOnChange}
          disabled={disabled}
          variables={variables}
          {...props}
        />
      )}
    </PreloadedQueryRenderer>
  ) : null;
};

export default UnitDSearchChoiceTextField;
