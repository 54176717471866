import { BrowserIcon } from '@primer/octicons-react';
import { ThemeProvider, EnigmaUI } from '@teamturing/react-kit';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import Editor from '../../components/core/Editor';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import ErrorState from '../../components/core/ErrorState';
import Grid from '../../components/core/Grid';
import View from '../../components/core/View';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import { EnigmaBuilder as EB } from '../../utils/enigma';
import { isValidJSON } from '../../utils/is';
import { NextPage } from '../_app';

type Props = {};

const EnigmaBuilder: NextPage<Props> = () => {
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    value: { type: 'string', multiple: false },
  });

  const enigmaBuilder = useRef<EB>(new EB({ id: 'test-section' })).current;
  const [enigmaSection, setEnigmaSection] = useState(enigmaBuilder.get());
  const [isValidEnigmaSection, setIsValidEnigmaSection] = useState<boolean>(true);

  const encodeString = (value: string) => {
    return Buffer.from(value).toString('base64');
  };
  const decodeString = (value: string) => {
    return Buffer.from(value, 'base64').toString('utf8');
  };

  useEffect(() => {
    if (initialValues.value) {
      const enigmaSection = JSON.parse(decodeString(initialValues.value));
      enigmaBuilder.set(enigmaSection);
      setEnigmaSection(enigmaBuilder.get());
    }
  }, []);

  return (
    <Grid>
      <Grid.Unit
        size={1 / 2}
        sx={{
          borderRightWidth: 1,
          borderRightStyle: 'solid',
          borderRightColor: 'border.default',
          height: '100vh',
          overflow: 'hidden',

          position: 'relative',
        }}
      >
        <ErrorBoundary
          key={initialValues.value}
          fallback={
            <Blanket>
              <View backgroundColor={'canvas.default'} borderRadius={2} p={5}>
                <ErrorState description={'EnigmaUI 문법에 맞도록 작성해주세요'} />
              </View>
            </Blanket>
          }
        >
          {!isValidEnigmaSection ? (
            <Blanket>
              <View backgroundColor={'canvas.default'} borderRadius={2} p={5}>
                <ErrorState description={'형식에 맞는 JSON을 입력해주세요'} />
              </View>
            </Blanket>
          ) : null}
          <View sx={{ p: 3, height: '100%', overflow: 'auto' }}>
            <ThemeProvider>
              <EnigmaUI section={enigmaSection} />
            </ThemeProvider>
          </View>
        </ErrorBoundary>
      </Grid.Unit>
      <Grid.Unit size={1 / 2} sx={{ height: '100vh' }}>
        <Editor
          height={'100%'}
          language={'json'}
          type={'scroll'}
          value={JSON.stringify(enigmaSection, null, 2)}
          onChange={(value) => {
            if (!isValidJSON(value || '')) {
              setIsValidEnigmaSection(false);
            } else {
              const engimaUISection = JSON.parse(value || '');
              setEnigmaSection(engimaUISection);
              enigmaBuilder.set(engimaUISection);

              setParsedUrlQuery({ value: encodeString(value || '') });
              setIsValidEnigmaSection(true);
            }
          }}
        />
      </Grid.Unit>
    </Grid>
  );
};

const Blanket = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(4px);
`;

EnigmaBuilder.getLayout = (page) => <>{page}</>;
EnigmaBuilder.authenticated = true;
EnigmaBuilder.routes = [
  {
    id: 'enigmaBuilder',
    pathname: '/enigmaBuilder',
    name: 'Enigma UI Builder (Beta)',
    icon: BrowserIcon,
    permissions: ['codepush_version_read'],
  },
];

export default EnigmaBuilder;
