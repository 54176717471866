import { XIcon } from '@primer/octicons-react';
import { SxProp, themeGet } from '@primer/react';
import {
  forwardRef,
  PropsWithChildren,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import styled, { css, keyframes } from 'styled-components';

import { PRIMER_DIALOG_ZINDEX } from '../../../constants/zIndexes';
import { DialogContextProvider, DialogContextValue } from '../../../contexts/DialogContext';
import IconButton from '../../core/IconButton';
import View from '../../core/View';

function visible(el: HTMLInputElement) {
  return !el.hidden && (!el.type || el.type !== 'hidden') && (el.offsetWidth > 0 || el.offsetHeight > 0);
}

function focusable(el: Element) {
  const inputEl = el as HTMLInputElement;
  return inputEl.tabIndex >= 0 && !inputEl.disabled && visible(inputEl);
}

type Props = {
  isOpen?: boolean;
  onDismiss?: () => void;
  full?: boolean;
  wide?: boolean;
  fillHeight?: boolean;
  animation?: boolean;
} & SxProp;

const Dialog = (
  {
    children,
    isOpen,
    onDismiss,
    full = false,
    wide = false,
    fillHeight = false,
    animation = true,
    sx,
  }: PropsWithChildren<Props>,
  ref: Ref<HTMLDivElement>,
) => {
  const dialogRoot = document.getElementById('dialog_root');
  if (dialogRoot === null) return null;

  const dialogValue: DialogContextValue = { isConfirm: false, confirmText: '' };

  const setIsConfirm = (value: boolean) => (dialogValue.isConfirm = value);
  const setConfirmText = (text: string) => (dialogValue.confirmText = text);

  const handleDismiss = useCallback(
    () => (dialogValue.isConfirm ? (window.confirm(dialogValue.confirmText) ? onDismiss?.() : null) : onDismiss?.()),
    [dialogValue, onDismiss],
  );

  const overlayRef = useRef<HTMLSpanElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => dialogRef.current);

  const handleOutsideClick = useCallback(
    (e: MouseEvent) => {
      if (
        dialogRef.current &&
        overlayRef.current &&
        e.target instanceof Node &&
        !dialogRef.current.contains(e.target) &&
        overlayRef.current.contains(e.target)
      ) {
        handleDismiss?.();
      }
    },
    [handleDismiss, dialogRef, overlayRef],
  );
  const getFocusableItem = useCallback(
    (e: React.KeyboardEvent, movement: number) => {
      if (dialogRef.current) {
        const items = Array.from(dialogRef.current.querySelectorAll('*')).filter(focusable);

        if (items.length === 0) return;
        e.preventDefault();
        const focusedElement = document.activeElement;
        if (!focusedElement) {
          return;
        }

        const index = items.indexOf(focusedElement);
        const offsetIndex = index + movement;
        const fallbackIndex = movement === 1 ? 0 : items.length - 1;
        const focusableItem = items[offsetIndex] || items[fallbackIndex];
        return focusableItem as HTMLElement;
      }
    },
    [dialogRef],
  );
  const handleTab = useCallback(
    (e: React.KeyboardEvent) => {
      const movement = e.shiftKey ? -1 : 1;
      const focusableItem = getFocusableItem(e, movement);
      if (!focusableItem) {
        return;
      }

      focusableItem.focus();
    },
    [getFocusableItem],
  );
  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'Tab':
          handleTab(event);
          break;
        case 'Escape':
          handleDismiss?.();
          event.stopPropagation();
          break;
      }
    },
    [handleDismiss],
  );

  const [isAnimate, setIsAnimate] = useState<boolean | undefined>(false);
  const [isVisible, setIsVisible] = useState<boolean | undefined>(isOpen);

  useEffect(() => {
    if (isVisible && !isOpen && animation) {
      setIsAnimate(true);
      setTimeout(() => setIsAnimate(false), 500);
    }
    setIsVisible(isOpen || false);
  }, [isVisible, isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick);
      };
    }
  }, [isOpen, handleOutsideClick]);

  useEffect(() => {
    if (isVisible || isOpen) {
      if (closeButtonRef && closeButtonRef.current) {
        closeButtonRef.current.focus();
      }
    }
  }, [isVisible, isOpen, closeButtonRef]);

  return ReactDOM.createPortal(
    <DialogContextProvider value={{ setConfirmText, setIsConfirm, ...dialogValue }}>
      {isAnimate || isVisible ? (
        <View
          sx={{
            position: 'fixed',
            display: 'table',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: PRIMER_DIALOG_ZINDEX,
          }}
        >
          <Overlay ref={overlayRef} isOpen={isOpen} animation={animation} />
          <AnimatedView
            ref={dialogRef}
            isOpen={isOpen}
            full={full}
            animation={animation}
            aria-modal={'true'}
            role={'dialog'}
            tabIndex={-1}
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              boxShadow: 'shadow.extraLarge',
              backgroundColor: 'canvas.default',
              outline: 'none',
              overflow: 'hidden',

              ...(full
                ? {
                    bottom: 0,
                    width: '100vw',
                    maxHeight: ['100vh', '100vh', 'calc(100vh - 32px)'],
                    height: ['100vh', '100vh', 'calc(100vh - 32px)'],
                    marginTop: [0, 0, 32],
                  }
                : wide
                ? {
                    top: 0,
                    width: ['100vw', '100vw', 880],
                    maxHeight: ['100vh', '100vh', '80vh'],
                    height: ['100vh', '100vh', 'fit-content'],
                    margin: [0, 0, '10vh auto'],
                    borderRadius: [0, 0, 2],
                  }
                : {
                    top: 0,
                    width: ['100vw', '100vw', 440],
                    maxHeight: ['100vh', '100vh', '80vh'],
                    height: ['100vh', '100vh', 'fit-content'],
                    margin: [0, 0, '10vh auto'],
                    borderRadius: [0, 0, 2],
                  }),
              ...(!full && fillHeight
                ? {
                    height: ['100vh', '100vh', '80vh'],
                  }
                : {}),
              ...sx,
            }}
            onKeyDown={handleKeyDown}
          >
            <IconButton
              aria-label={'Close Dialog'}
              ref={closeButtonRef}
              icon={XIcon}
              variant={'plain'}
              onClick={handleDismiss}
              sx={{ position: 'absolute', top: 1, right: 1 }}
            />
            {children}
          </AnimatedView>
        </View>
      ) : null}
    </DialogContextProvider>,
    dialogRoot,
  );
};

const Header = ({ children }: PropsWithChildren, ref: Ref<HTMLDivElement>) => (
  <View
    ref={ref}
    sx={{
      flex: 0,
      paddingX: 3,
      paddingY: 2,
      backgroundColor: 'canvas.default',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: 'border.default',
    }}
  >
    {children}
  </View>
);

const Body = ({ children, sx }: PropsWithChildren<SxProp>, ref: Ref<HTMLDivElement>) => (
  <View ref={ref} sx={{ padding: 3, ...sx, flex: 1, overflow: 'auto' }}>
    {children}
  </View>
);

const Footer = ({ children }: PropsWithChildren, ref: Ref<HTMLDivElement>) => (
  <View
    ref={ref}
    sx={{
      flex: 0,
      paddingX: 3,
      paddingY: 2,
      backgroundColor: 'canvas.default',
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: 'border.default',
      display: 'flex',
      justifyContent: 'end',
    }}
  >
    {children}
  </View>
);

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
const overlayAnimation = (open?: boolean) => css`
  animation: ${open ? fadeIn : fadeOut} 500ms cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
`;
const Overlay = styled.span<Pick<Props, 'isOpen' | 'animation'>>`
  &:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    cursor: default;
    content: ' ';
    background: ${themeGet('colors.primer.canvas.backdrop')};
    ${({ isOpen, animation }) => animation && overlayAnimation(isOpen)}
  }
`;

const scaleDown = keyframes`
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;
const scaleUp = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
`;
const slideUp = keyframes`
  0% {
    transform: translateY(2000px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;
const slideDown = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateY(2000px);
    opacity: 0;
  }
`;
const dialogAnimation = ({ open, full }: { open?: boolean; full?: boolean }) => css`
  animation: ${open && full ? slideUp : !open && full ? slideDown : open && !full ? scaleDown : scaleUp} 500ms
    cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
`;

const AnimatedView = styled(View)<Pick<Props, 'isOpen' | 'full' | 'animation'>>`
  ${({ isOpen, full, animation }) => animation && dialogAnimation({ open: isOpen, full })}
`;

export default Object.assign(forwardRef(Dialog), {
  Header: forwardRef(Header),
  Body: forwardRef(Body),
  Footer: forwardRef(Footer),
});
export type { Props as DialogProps };
