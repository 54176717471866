import { graphql, useFragment } from 'react-relay';

import { FormulaSetHistoryDialog_formulaSetHistory$key } from '../../../relay/__generated__/FormulaSetHistoryDialog_formulaSetHistory.graphql';
import Avatar from '../../core/Avatar';
import DateText from '../../core/DateText';
import Dialog, { DialogProps } from '../../core/Dialog';
import Grid from '../../core/Grid';
import Text from '../../core/Text';
import View from '../../core/View';
import FormulaSetDifferenceItemList from '../FormulaSetDifferenceItemList';

const FormulaSetHistoryDialog_formulaSetHistory = graphql`
  fragment FormulaSetHistoryDialog_formulaSetHistory on FormulaSetHistory {
    createdBy {
      name
      picture
    }
    created
    difference {
      ...FormulaSetDifferenceItemList_formulaSetDifference
    }
  }
`;

type Props = { formulaSetHistory: FormulaSetHistoryDialog_formulaSetHistory$key } & DialogProps;

const FormulaSetHistoryDialog = ({ formulaSetHistory, ...props }: Props) => {
  const { created, createdBy, difference } = useFragment(FormulaSetHistoryDialog_formulaSetHistory, formulaSetHistory);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Grid gapX={3} sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'min'}>
            <Avatar src={createdBy.picture || ''} alt={createdBy.name} size={40} />
          </Grid.Unit>
          <Grid.Unit size={'max'}>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{`${createdBy.name}님의 수정 내역`}</Text>
            <View>
              <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
            </View>
          </Grid.Unit>
        </Grid>
      </Dialog.Header>
      <Dialog.Body>
        {difference ? <FormulaSetDifferenceItemList formulaSetDifference={difference} /> : null}
      </Dialog.Body>
    </Dialog>
  );
};

export default FormulaSetHistoryDialog;
