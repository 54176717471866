import { useRouter } from 'next/router';
import { RefObject, useEffect } from 'react';

import { FormikProps } from '../../components/core/Formik';

const useResumableFormik = (formikRef: RefObject<FormikProps<any>>) => {
  const router = useRouter();
  const pageId = router.asPath;

  const handleRouteChangeStart = () => {
    const stringifyFormikValues = JSON.stringify(formikRef.current?.values);

    sessionStorage.setItem(pageId, stringifyFormikValues);

    formikRef.current?.resetForm();
  };

  // 페이지 이동이 일어났을 때, formik의 values를 sessionStorage에 현재 router.asPath를 키로 저장.
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    return () => router.events.off('routeChangeStart', handleRouteChangeStart);
  }, [pageId]);

  // 페이지로 들어왔을 때, sessionStorage에 router.asPath를 키로 가진 values가 있다면 formik의 values를 불러옴.
  useEffect(() => {
    const resumableFields = sessionStorage.getItem(pageId);
    if (resumableFields) {
      const parsedFormikValues = JSON.parse(resumableFields);
      formikRef.current?.setValues(parsedFormikValues);
    }
  }, [pageId]);

  // 더 이상 현재 formik의 values에 대해서 저장하는 기능을 사용하고 싶지 않을 떄 사용.
  const stopRestore = () => {
    sessionStorage.removeItem(pageId);
    router.events.off('routeChangeStart', handleRouteChangeStart);
  };

  return { stopRestore };
};

export default useResumableFormik;
