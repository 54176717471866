import useEnumPairsQuery from '../../../hooks/useEnumPairsQuery';
import SelectField, { SelectFieldProps } from '../SelectField';

type Props = {
  typename: string;
} & Omit<SelectFieldProps, 'options'>;

const EnumPairSelectField = ({ typename, ...props }: Props) => {
  const enumPairs = useEnumPairsQuery(typename);
  return <SelectField options={enumPairs.map(({ key, value }) => ({ id: key, text: value }))} {...props} />;
};

export default EnumPairSelectField;
export type { Props as EnumPairSelectFieldProps };
