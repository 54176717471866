import { signIn } from 'next-auth/react';
import { useRouter } from 'next/router';

import logo from '../../../public/logo.png';
import Button from '../../components/core/Button';
import FormLayout from '../../components/core/FormLayout';
import Formik from '../../components/core/Formik';
import Head from '../../components/core/Head';
import Image from '../../components/core/Image';
import NonFieldError from '../../components/core/NonFieldError';
import PasswordField from '../../components/core/PasswordField';
import Text from '../../components/core/Text';
import TextField from '../../components/core/TextField';
import View from '../../components/core/View';
import { NextPage } from '../_app';
import { CredentialSignInInput } from '../api/auth/[...nextauth]';

type Props = {};

const Credential: NextPage<Props> = () => {
  const router = useRouter();

  const callbackUrl = (router.query.next || '/dashboard') as string;

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'로그인'} />
      <View display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'} height={'100vh'} px={[3, 0]}>
        <View display={'flex'} flexDirection={'column'} alignItems={'center'} mt={8}>
          <Image src={logo} alt={logo.src} width={48} height={48} />
          <Text fontSize={3} mt={2} textAlign={'center'}>
            TCMS - Turing Common Management System
          </Text>
        </View>
        <View width={['100%', 330]} mt={4}>
          <Formik<CredentialSignInInput>
            initialValues={{
              email: '',
              password: '',
            }}
            onSubmit={(values, formikHelpers) => {
              signIn('credentials', { callbackUrl, redirect: false, ...values }).then((res) => {
                if (!res?.ok) {
                  formikHelpers?.setSubmitting(false);
                  formikHelpers?.setFieldError('nonFieldError', '아이디 또는 비밀번호가 일치하지 않습니다');
                }
              });
            }}
          >
            {({ values, isSubmitting, submitForm }) => {
              const isFilledAllField = values.email && values.password;

              const availableSubmit = isFilledAllField && !isSubmitting;

              return (
                <>
                  <NonFieldError sx={{ marginBottom: 3, fontSize: 1 }} />
                  <FormLayout>
                    <TextField
                      label={'아이디'}
                      name={'email'}
                      placeholder={'아이디 또는 이메일을 입력하세요'}
                      autoComplete={'off'}
                      required
                    />
                    <PasswordField
                      label={'비밀번호'}
                      name={'password'}
                      placeholder={'비밀번호를 입력하세요'}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter' && availableSubmit) submitForm();
                      }}
                      required
                    />
                    <Button sx={{ width: '100%' }} variant={'primary'} onClick={submitForm} disabled={!availableSubmit}>
                      Sign in
                    </Button>
                  </FormLayout>
                </>
              );
            }}
          </Formik>
        </View>
        <View mt={2} fontSize={1}>
          <Text>© TURING</Text>
        </View>
      </View>
    </View>
  );
};

Credential.authenticated = false;
Credential.routes = [{ id: 'credential', pathname: '/login/credential', name: 'Credential' }];

export default Credential;
