import { UploadMetaDataSchema, MetaDataSchema, MultiPartUploadMetaDataSchema } from '../types/upload';

const getUploadMetaData = async ({
  nodeId,
  fileName,
  fileSize,
}: {
  fileName: string;
  fileSize: number;
  nodeId?: string;
}) => {
  const response = await fetch(`${process.env.NEXT_PUBLIC_TCMS_REST_API_END_POINT}v1/upload`, {
    method: 'POST',
    body: JSON.stringify({
      node_id: nodeId,
      file_name: fileName,
      file_size: fileSize,
    }),
  });

  if (response.ok) {
    const { meta_data: metaData }: { meta_data: MetaDataSchema } = await response.json();
    return metaData;
  }

  return null;
};

const uploadGeneral = async (file: Blob, { pre_signed_url }: Pick<UploadMetaDataSchema, 'pre_signed_url'>) => {
  const response = await fetch(pre_signed_url, {
    method: 'PUT',
    body: file,
  });

  return response;
};

const uploadMultipart = async (
  file: Blob,
  { part_metadata: partMetaData }: Pick<MultiPartUploadMetaDataSchema, 'part_metadata'>,
) => {
  let fileOffset = 0;
  const fileChucks = partMetaData.reduce<(MultiPartUploadMetaDataSchema['part_metadata'][number] & { file: Blob })[]>(
    (acc, cur) => {
      const result = [...acc, { ...cur, file: file.slice(fileOffset, fileOffset + cur.part_size) }];
      fileOffset = fileOffset + cur.part_size;
      return result;
    },
    [],
  );

  const response = await Promise.all(
    fileChucks.map(({ file, pre_signed_url }) => uploadGeneral(file, { pre_signed_url })),
  );

  return response;
};

const uploadFile = async (
  targetFile: File | Blob,
  options?: { multipart?: boolean; nodeId?: string; fieldName?: string; formatFileName?: (fileName: string) => string },
) => {
  const fileInfo = { name: '', size: 0 };
  if ('name' in targetFile) {
    fileInfo.name = targetFile.name;
    fileInfo.size = targetFile.size;
  } else {
    const [, extension] = targetFile.type.split('/');
    fileInfo.name = `${options?.fieldName ?? 'blob'}.${extension}`;
    fileInfo.size = targetFile.size;
  }

  const _formatFileName = options?.formatFileName ?? ((fileName) => fileName);
  const metaData = await getUploadMetaData({
    nodeId: options?.nodeId,
    fileName: _formatFileName(fileInfo.name),
    fileSize: fileInfo.size,
  });

  if (!metaData) {
    return Promise.reject('no_metadata');
  }

  const commonValue = {
    key: metaData.key,
    size: fileInfo.size,
    fieldName: options?.fieldName ?? '',
  };

  const isMultiPartUploadRequired = 'upload_id' in metaData && 'part_metadata' in metaData;
  if (isMultiPartUploadRequired) {
    if (!options?.multipart) {
      return Promise.reject('block_multipart_upload');
    }

    const response = await uploadMultipart(targetFile, metaData);
    const isOk = response.reduce((acc, cur) => acc && cur.ok, true);

    if (isOk) {
      const value = {
        ...commonValue,
        multipartUploadResult: {
          uploadId: metaData.upload_id,
          partPairs: metaData.part_metadata.map(({ part_number: PartNumber }, i) => ({
            PartNumber,
            ETag: response[i].headers.get('ETag') ?? '',
          })),
        },
      };

      return value;
    }
  } else {
    const response = await uploadGeneral(targetFile, metaData);
    if (response.ok) {
      const value = { ...commonValue, objectUrl: metaData.object_url };

      return value;
    }
  }

  return Promise.reject('upload_fail');
};

export { getUploadMetaData, uploadGeneral, uploadMultipart, uploadFile };
