import { graphql, useFragment } from 'react-relay';

import {
  ScrapSourceStatusEnum,
  ScrapSourceStatusLabel_scrapSource$key,
} from '../../../relay/__generated__/ScrapSourceStatusLabel_scrapSource.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const ScrapSourceStatusLabel_scrapSource = graphql`
  fragment ScrapSourceStatusLabel_scrapSource on ScrapSource {
    status
  }
`;

type Props = {
  scrapSource: ScrapSourceStatusLabel_scrapSource$key;
} & Omit<LabelProps, 'variant'>;

const ScrapSourceStatusLabel = ({ scrapSource, ...props }: Props) => {
  const { status } = useFragment(ScrapSourceStatusLabel_scrapSource, scrapSource);

  const labelPropsByStatus: Record<ScrapSourceStatusEnum, LabelProps> = {
    'completed': { variant: 'success' },
    'ongoing': { variant: 'default' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'ScrapSourceStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default ScrapSourceStatusLabel;
