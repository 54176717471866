/**
 * @generated SignedSource<<33cd7d788ecde3444fc59ea5e5858ac8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATPremiumTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
export type MATInternalPremiumApplyHistoryUpdateInput = {
  endAt?: any | null;
  id: string;
  startAt?: any | null;
};
export type PremiumUpdateDialog_premiumUpdateMutation$variables = {
  input: MATInternalPremiumApplyHistoryUpdateInput;
};
export type PremiumUpdateDialog_premiumUpdateMutation$data = {
  readonly internalPremiumApplyHistoryUpdate: {
    readonly created: any;
    readonly endAt: any;
    readonly id: string;
    readonly item: {
      readonly id: string;
      readonly name: string;
      readonly premiumType: MATPremiumTypeEnum;
    };
    readonly startAt: any;
    readonly user: {
      readonly id: string;
    };
  };
};
export type PremiumUpdateDialog_premiumUpdateMutation = {
  response: PremiumUpdateDialog_premiumUpdateMutation$data;
  variables: PremiumUpdateDialog_premiumUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MATPremiumApplyHistory",
    "kind": "LinkedField",
    "name": "internalPremiumApplyHistoryUpdate",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MATInternalItem",
        "kind": "LinkedField",
        "name": "item",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "premiumType",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MATInternalUser",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PremiumUpdateDialog_premiumUpdateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PremiumUpdateDialog_premiumUpdateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d7cf9e44e3bac267d313be93625ff3e8",
    "id": null,
    "metadata": {},
    "name": "PremiumUpdateDialog_premiumUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation PremiumUpdateDialog_premiumUpdateMutation(\n  $input: MATInternalPremiumApplyHistoryUpdateInput!\n) {\n  internalPremiumApplyHistoryUpdate(input: $input) {\n    id\n    startAt\n    endAt\n    created\n    item {\n      id\n      name\n      premiumType\n    }\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "993a7daff326cd48b59c031ffe174d4a";

export default node;
