import { graphql, useFragment } from 'react-relay';

import {
  SchoolExamProblemStatusEnum,
  SchoolExamProblemStatusLabel_schoolExamProblem$key,
} from '../../../relay/__generated__/SchoolExamProblemStatusLabel_schoolExamProblem.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const SchoolExamProblemStatusLabel_schoolExamProblem = graphql`
  fragment SchoolExamProblemStatusLabel_schoolExamProblem on SchoolExamProblem {
    status
  }
`;

type Props = {
  schoolExamProblem: SchoolExamProblemStatusLabel_schoolExamProblem$key;
} & Omit<LabelProps, 'variant'>;

const SchoolExamProblemStatusLabel = ({ schoolExamProblem, ...props }: Props) => {
  const { status } = useFragment(SchoolExamProblemStatusLabel_schoolExamProblem, schoolExamProblem);

  const labelPropsByStatus: Record<SchoolExamProblemStatusEnum, LabelProps> = {
    'approved': { variant: 'success' },
    'rejected': { variant: 'danger' },
    'review': { variant: 'severe' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'SchoolExamProblemStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default SchoolExamProblemStatusLabel;
