import { Select as PrimerSelect, SelectProps as PrimerSelectProps } from '@primer/react';
import { FieldConfig, useField } from 'formik';
import { ComponentProps } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import ItemList from '../ItemList';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
  options: { id: NonNullable<string | number | undefined>; text: string | undefined; disabled?: boolean }[];
  onChange?: (value: string) => void;
  value?: PrimerSelectProps['value'];
} & Omit<PrimerSelectProps, 'onChange' | 'value'> &
  Pick<FieldConfig, 'validate'>;

const SelectField = ({
  label,
  labelConfig,
  caption,
  disabled,
  required,
  options,
  name = '',
  placeholder = '선택해주세요',
  onChange: propOnChange,
  value: propValue,
  validate,
  ...props
}: Props) => {
  const [{ value: baseValue = '', onChange: baseOnChange, ...restFieldProps }, { error }, { setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();

  const value = !isNullable(propValue) ? propValue : baseValue;

  const handleChange: PrimerSelectProps['onChange'] = (...args) => {
    const [event] = args;
    const value = event.target?.value ?? '';
    propOnChange ? propOnChange(value) : baseOnChange(...args);

    setError(undefined);
  };

  return (
    <FormControl disabled={disabled || isSubmitting} required={required}>
      <FormControl.Label {...labelConfig}>{label}</FormControl.Label>
      <PrimerSelect
        block
        value={value ?? ''}
        onChange={handleChange}
        validationStatus={error ? 'error' : undefined}
        {...restFieldProps}
        {...props}
      >
        <PrimerSelect.Option disabled value={''}>
          {placeholder}
        </PrimerSelect.Option>
        <ItemList
          items={options}
          renderItem={({ id, text, disabled }) => (
            <PrimerSelect.Option key={id} value={id as string} disabled={disabled}>
              {text}
            </PrimerSelect.Option>
          )}
        />
      </PrimerSelect>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default SelectField;
export type { Props as SelectFieldProps };
