import { graphql, useFragment } from 'react-relay';

import {
  SchoolExamSourceStatusEnum,
  SchoolExamSourceStatusLabel_schoolExamSource$key,
} from '../../../relay/__generated__/SchoolExamSourceStatusLabel_schoolExamSource.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const SchoolExamSourceStatusLabel_schoolExamSource = graphql`
  fragment SchoolExamSourceStatusLabel_schoolExamSource on SchoolExamSource {
    status
  }
`;

type Props = {
  schoolExamSource: SchoolExamSourceStatusLabel_schoolExamSource$key;
} & Omit<LabelProps, 'variant'>;

const SchoolExamSourceStatusLabel = ({ schoolExamSource, ...props }: Props) => {
  const { status } = useFragment(SchoolExamSourceStatusLabel_schoolExamSource, schoolExamSource);

  const labelPropsByStatus: Record<SchoolExamSourceStatusEnum, LabelProps> = {
    'scrap': {},
    'upload_required': {},
    'published': { variant: 'success' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'SchoolExamSourceStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default SchoolExamSourceStatusLabel;
