import { CopyIcon, KebabHorizontalIcon, TrashIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React from 'react';
import { graphql } from 'react-relay';

import AppDialogCreateDialog from '../../components/appDialog/AppDialogCreateDialog';
import AppDialogDescriptionList from '../../components/appDialog/AppDialogDescriptionList';
import AppDialogImageAndTextTypePreviewItem from '../../components/appDialog/AppDialogImageAndTextTypePreviewItem';
import AppDialogImageTypePreviewItem from '../../components/appDialog/AppDialogImageTypePreviewItem';
import AppDialogStatusLabel from '../../components/appDialog/AppDialogStatusLabel';
import AppDialogTextTypePreviewItem from '../../components/appDialog/AppDialogTextTypePreviewItem';
import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Card from '../../components/core/Card';
import DateText from '../../components/core/DateText';
import DialogButton from '../../components/core/DialogButton';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import UserAvatarText from '../../components/user/UserAvatarText';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { AppDialogId_appDialogDeleteMutation } from '../../relay/__generated__/AppDialogId_appDialogDeleteMutation.graphql';
import { AppDialogId_appDialogQuery } from '../../relay/__generated__/AppDialogId_appDialogQuery.graphql';
import { NextPage } from '../_app';

const appDialogForAppDialogId = graphql`
  query AppDialogId_appDialogQuery($id: ID!) {
    appDialog(id: $id) {
      id
      createdBy {
        id
        ...UserAvatarText_user
      }
      actions
      status
      title
      userAppVersion {
        min
        max
      }
      userYears
      userTypes
      userOs
      userPeriod {
        min
        max
      }
      appDialogType
      appDeepLink
      appDialogTitle
      appDialogBody
      appDialogButton
      startAt
      endAt
      created
      modified
      exposeHour
      exposeType
      exposeTag
      exposeWeekdays
      appDialogImage {
        objectUrl
        size
        key
      }
      appDialogMobileImage {
        objectUrl
        size
        key
      }
      appDialogTabletImage {
        objectUrl
        size
        key
      }
      ...AppDialogDescriptionList_appDialog
      ...AppDialogStatusLabel_appDialog
      ...AppDialogImageTypePreviewItem_appDialog
      ...AppDialogImageAndTextTypePreviewItem_appDialog
      ...AppDialogTextTypePreviewItem_appDialog
    }
  }
`;

export type Props = {};
const AppDialogId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ appDialog }] = useLazyLoadQuery<AppDialogId_appDialogQuery>(appDialogForAppDialogId, {
    id: router.query.appDialogId as string,
  });
  if (!appDialog) return null;
  const {
    id,
    title,
    actions,
    appDialogButton,
    appDialogType,
    appDialogTitle,
    createdBy,
    userAppVersion,
    userOs,
    userPeriod,
    startAt,
    appDialogBody,
    endAt,
    userTypes,
    userYears,
    created,
    exposeHour,
    exposeTag,
    exposeType,
    exposeWeekdays,
    appDeepLink,
    appDialogImage,
    appDialogMobileImage,
    appDialogTabletImage,
  } = appDialog;

  return (
    <View>
      <Head siteTitle={`만능 다이얼로그 - ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{title}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              <Stack.Item>
                <DialogButton
                  renderDialog={({ isOpen, closeDialog }) => (
                    <AppDialogCreateDialog
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      initialValues={{
                        title,
                        userAppVersion,
                        userOs,
                        userTypes,
                        userYears,
                        userPeriod,
                        appDialogType,
                        startAt,
                        endAt,
                        exposeType,
                        exposeTag,
                        exposeHour,
                        exposeWeekdays,
                        appDeepLink,
                        appDialogTitle,
                        appDialogButton,
                        appDialogBody,
                        appDialogImage,
                        appDialogMobileImage,
                        appDialogTabletImage,
                      }}
                      config={{
                        onCompleted: () => {
                          toast('만능다이얼로그가 생성되었어요!', 'success');
                          closeDialog();
                        },
                      }}
                    />
                  )}
                  leadingIcon={CopyIcon}
                >
                  복제하기
                </DialogButton>
              </Stack.Item>
              {actions.includes('app_dialog_delete') ? (
                <Stack.Item>
                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.MutationItem<AppDialogId_appDialogDeleteMutation>
                          mutation={graphql`
                            mutation AppDialogId_appDialogDeleteMutation($input: AppDialogDeleteInput!) {
                              appDialogDelete(input: $input) {
                                id @deleteRecord
                              }
                            }
                          `}
                          input={{ id }}
                          variant={'danger'}
                          config={{
                            onCompleted: () => {
                              router.replace('/appDialog').then(() => toast('만능 다이얼로그 삭제 완료!', 'success'));
                            },
                          }}
                          message={'정말 삭제할까요?'}
                        >
                          <ActionList.LeadingVisual>
                            <TrashIcon />
                          </ActionList.LeadingVisual>
                          삭제하기
                        </ActionList.MutationItem>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Stack.Item>
              ) : null}
            </Stack>
          </Grid.Unit>
        </Grid>
      </View>
      <View sx={{ marginTop: [6, 3, 0] }}>
        <HorizontalDivider />
      </View>
      <View sx={{ marginTop: 6 }}>
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <Text fontWeight={'bold'} fontSize={1}>
              타겟 사용자
            </Text>

            <Card sx={{ padding: 4, marginTop: 3 }}>
              <AppDialogDescriptionList
                titleUnitSize={1 / 5}
                descriptionUnitSize={4 / 5}
                appDialog={appDialog}
                type={'target'}
              />
            </Card>

            <View sx={{ marginTop: 6 }}>
              <Text fontWeight={'bold'} fontSize={1}>
                만능다이얼로그
              </Text>
            </View>

            <View sx={{ marginTop: 6 }}>
              {appDialogType === 'image' ? (
                <View sx={{ display: 'flex', justifyContent: 'center' }}>
                  <View width={['100%', 300]}>
                    <AppDialogImageTypePreviewItem appDialog={appDialog} />
                  </View>
                </View>
              ) : appDialogType === 'image_and_text' ? (
                <View sx={{ display: 'flex', justifyContent: 'center' }}>
                  <View width={['100%', 300]}>
                    <AppDialogImageAndTextTypePreviewItem appDialog={appDialog} />
                  </View>
                </View>
              ) : appDialogType === 'text' ? (
                <View sx={{ display: 'flex', justifyContent: 'center' }}>
                  <View width={['100%', 300]}>
                    <AppDialogTextTypePreviewItem appDialog={appDialog} />
                  </View>
                </View>
              ) : null}
            </View>

            <Card sx={{ padding: 4, marginTop: 6 }}>
              <AppDialogDescriptionList
                titleUnitSize={1 / 5}
                descriptionUnitSize={4 / 5}
                type={
                  appDialogType === 'image'
                    ? 'dialog-image'
                    : appDialogType === 'image_and_text'
                    ? 'dialog-image-and-text'
                    : 'dialog-text'
                }
                appDialog={appDialog}
              />
            </Card>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <ItemList
              items={[
                { id: 'createdBy', title: '제작자', description: <UserAvatarText user={createdBy} /> },
                { id: 'created', title: '제작일', description: <DateText>{created}</DateText> },
                { id: 'status', title: '상태', description: <AppDialogStatusLabel appDialog={appDialog} /> },
              ]}
              renderItem={({ title, description }) => (
                <Grid gapX={1} gapY={1}>
                  <Grid.Unit size={[1, 1, 'max']}>
                    <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 'min']}>
                    <Text sx={{ fontSize: 1 }}>{description}</Text>
                  </Grid.Unit>
                </Grid>
              )}
              renderItemWrapper={(children, { id }, i) => (
                <View key={id} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                  {children}
                </View>
              )}
            />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

AppDialogId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
AppDialogId.authenticated = true;
AppDialogId.routes = [
  {
    id: 'appDialogId',
    name: '만능 다이얼로그 상세',
    pathname: '/appDialog/[appDialogId]',
    permissions: ['app_dialog_read'],
  },
];

export default AppDialogId;
