/**
 * @generated SignedSource<<046b88928101db86d7fd3e2555345c42>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BookBookScrapDeleteDialog_book$data = {
  readonly id: string;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"BookBookScrapPaginator_book">;
  readonly " $fragmentType": "BookBookScrapDeleteDialog_book";
};
export type BookBookScrapDeleteDialog_book$key = {
  readonly " $data"?: BookBookScrapDeleteDialog_book$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookBookScrapDeleteDialog_book">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookBookScrapDeleteDialog_book",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "BookBookScrapPaginator_book"
    }
  ],
  "type": "Book",
  "abstractKey": null
};

(node as any).hash = "dea028ba90bcb69004f80727a65e0489";

export default node;
