const formatMobilePhoneNumber = (value: string, separator?: string) =>
  [value.slice(0, 3), value.slice(3, 3 + 4), value.slice(3 + 4)].join(separator ?? ' ').trim();

const parseFormattedPhoneNumber = (value: string, separator?: string) =>
  value
    .split(separator ?? ' ')
    .join('')
    .trim();

export { formatMobilePhoneNumber, parseFormattedPhoneNumber };
