import { CheckIcon, PlusIcon, XIcon } from '@primer/octicons-react';
import { FieldArray } from 'formik';
import { useRouter } from 'next/router';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { ShortcutUpdateReloadDialog_shortcutUpdateMutation } from '../../../relay/__generated__/ShortcutUpdateReloadDialog_shortcutUpdateMutation.graphql';
import { ShortcutUpdateReloadDialog_user$key } from '../../../relay/__generated__/ShortcutUpdateReloadDialog_user.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import ItemList from '../../core/ItemList';
import { convertEnterToNewLine, convertNewLineToEnter } from '../../core/KatexEditor/utils';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const ShortcutUpdateReloadDialog_user = graphql`
  fragment ShortcutUpdateReloadDialog_user on User {
    id
    shortcuts {
      label
      text
    }
  }
`;

type Props = { user: ShortcutUpdateReloadDialog_user$key } & DialogProps &
  Pick<MutationFormikProps<ShortcutUpdateReloadDialog_shortcutUpdateMutation>, 'onSubmit' | 'config'>;

const ShortcutUpdateReloadDialog = ({ user, onSubmit, config, ...props }: Props) => {
  const router = useRouter();
  const { shortcuts } = useFragment(ShortcutUpdateReloadDialog_user, user);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>타이핑 단축키 설정</Text>
      </Dialog.Header>
      <MutationFormik<ShortcutUpdateReloadDialog_shortcutUpdateMutation>
        mutation={graphql`
          mutation ShortcutUpdateReloadDialog_shortcutUpdateMutation($input: ShortcutsUpdateInput!) {
            shortcutsUpdate(input: $input) {
              id
              label
              text
            }
          }
        `}
        config={{
          ...config,
          onCompleted: (...arg) => {
            config?.onCompleted?.(...arg);
            router.reload();
          },
        }}
        onSubmit={onSubmit}
        initialValues={{
          shortcuts: shortcuts.map(({ text, label }) => ({ label, text: convertNewLineToEnter(text) })),
        }}
      >
        {(
          { handleSubmit: baseHandleSubmit, dirty, setFieldValue, values },
          { scrollContainerRef, nonFieldErrorRef },
        ) => {
          const handleSubmit = () => {
            setFieldValue(
              'shortcuts',
              values.shortcuts.map(({ text, label }) => ({ label, text: convertEnterToNewLine(text) })),
            );
            baseHandleSubmit();
          };
          return (
            <>
              <Dialog.Body ref={scrollContainerRef}>
                <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
                <FormLayout>
                  <FieldArray name={'shortcuts'}>
                    {({ insert, remove }) => (
                      <>
                        <ItemList
                          items={values.shortcuts}
                          renderItem={(_, index) => (
                            <Grid key={index} sx={{ alignItems: 'center' }} gapX={2}>
                              <Grid.Unit size={'min'}>
                                <TextField
                                  label={'단축키'}
                                  labelConfig={{ visuallyHidden: true }}
                                  name={`shortcuts.${index}.label`}
                                  placeholder={'단축키 입력'}
                                  autoComplete={'off'}
                                />
                              </Grid.Unit>
                              <Grid.Unit size={'max'}>
                                <TextField
                                  label={'코드'}
                                  labelConfig={{ visuallyHidden: true }}
                                  name={`shortcuts.${index}.text`}
                                  placeholder={'코드 입력'}
                                  autoComplete={'off'}
                                />
                              </Grid.Unit>
                              <Grid.Unit size={'min'}>
                                <IconButton
                                  aria-label={`remove ${index + 1} shortcut input field`}
                                  icon={XIcon}
                                  onClick={() => remove(index)}
                                  disabled={values.shortcuts.length < 2}
                                />
                              </Grid.Unit>
                            </Grid>
                          )}
                        />
                        <Button
                          sx={{ marginX: 'auto' }}
                          leadingIcon={PlusIcon}
                          variant={'outline'}
                          size={'small'}
                          onClick={() => insert(values.shortcuts.length, { text: '', label: '' })}
                        >
                          추가하기
                        </Button>
                      </>
                    )}
                  </FieldArray>
                </FormLayout>
              </Dialog.Body>
              <Dialog.Footer>
                <Button variant={'primary'} disabled={!dirty} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                  추가 및 수정하기
                </Button>
              </Dialog.Footer>
            </>
          );
        }}
      </MutationFormik>
    </Dialog>
  );
};

export default ShortcutUpdateReloadDialog;
