import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { PreviousLabelingDialog_task$key } from '../../../relay/__generated__/PreviousLabelingDialog_task.graphql';
import { isNullable } from '../../../utils/is';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import Label from '../../core/Label';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import UnitDItem from '../../unitD/UnitDItem';

const PreviousLabelingDialog_task = graphql`
  fragment PreviousLabelingDialog_task on Task {
    id
    difficulty
    unitDs(order: { order: DESC }) {
      id
      ...UnitDItem_unitD
    }
    firstLabeling {
      id
      difficulty
      unitDs(order: { order: DESC }) {
        id
        ...UnitDItem_unitD
      }
    }
    secondLabeling {
      id
      difficulty
      unitDs(order: { order: DESC }) {
        id
        ...UnitDItem_unitD
      }
    }
  }
`;

type Props = {
  task: PreviousLabelingDialog_task$key;
  onClickGetButton?: (difficulty: number, unitD: string[]) => void;
} & DialogProps;

const PreviousLabelingDialog = ({ task, onClickGetButton, ...props }: Props) => {
  const { id, difficulty, firstLabeling, secondLabeling, unitDs } = useFragment(PreviousLabelingDialog_task, task);
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>이전 라벨링 가져오기</Text>
      </Dialog.Header>
      <Dialog.Body>
        <ItemList
          items={[
            { title: '2차 라벨링', labeling: secondLabeling },
            { title: '1차 라벨링', labeling: firstLabeling },
            { title: '창작 라벨링', labeling: { id, unitDs, difficulty } },
          ].filter(({ labeling }) => !isNullable(labeling))}
          renderItem={({ title, labeling }) => (
            <>
              <Grid sx={{ alignItems: 'center', marginBottom: 2 }}>
                <Grid.Unit size={'max'}>
                  <Stack gapX={1}>
                    <Stack.Item>
                      <Text fontSize={1} fontWeight={'bold'}>
                        {title}
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text fontSize={1} fontWeight={'bold'} color={'accent.fg'}>
                        Rating {labeling?.difficulty || 0}
                      </Text>
                    </Stack.Item>
                  </Stack>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Button
                    variant={'primary'}
                    onClick={() =>
                      onClickGetButton?.(labeling?.difficulty || 0, labeling?.unitDs.map(({ id }) => id) || [])
                    }
                  >
                    가져오기
                  </Button>
                </Grid.Unit>
              </Grid>
              <ItemList
                items={labeling?.unitDs || []}
                renderItem={(unitD) => <UnitDItem unitD={unitD} />}
                renderItemWrapper={(children, { id }, index) => (
                  <View
                    key={id}
                    sx={{
                      marginTop: index === 0 ? 0 : 2,
                      borderRadius: 2,
                      backgroundColor: 'neutral.muted',
                      padding: 2,
                    }}
                  >
                    {index === 0 ? (
                      <Label sx={{ marginBottom: 1 }} variant={'default'}>
                        Last Unit D
                      </Label>
                    ) : null}
                    {children}
                  </View>
                )}
              />
            </>
          )}
          renderItemWrapper={(children, { title }, index) => (
            <View key={title} sx={{ marginTop: index === 0 ? 0 : 4 }}>
              {children}
            </View>
          )}
        />
      </Dialog.Body>
    </Dialog>
  );
};

export default PreviousLabelingDialog;
