/**
 * @generated SignedSource<<3762897d7680f47f23a26e755fbe336d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type WorkbookStatusEnum = "created" | "deactivated" | "publish_required" | "published" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkbookStatusLabel_workbook$data = {
  readonly status: WorkbookStatusEnum;
  readonly " $fragmentType": "WorkbookStatusLabel_workbook";
};
export type WorkbookStatusLabel_workbook$key = {
  readonly " $data"?: WorkbookStatusLabel_workbook$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkbookStatusLabel_workbook">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkbookStatusLabel_workbook",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Workbook",
  "abstractKey": null
};

(node as any).hash = "cdd5fcc2ca473994a73739044e675c71";

export default node;
