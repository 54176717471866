/**
 * @generated SignedSource<<7f30bfdf65c797a3834b835c1353f747>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATInvoiceDeleteInput = {
  id: string;
};
export type OrganizationId_invoiceDeleteMutation$variables = {
  input: MATInvoiceDeleteInput;
};
export type OrganizationId_invoiceDeleteMutation$data = {
  readonly invoiceDelete: {
    readonly id: string;
    readonly organization: {
      readonly currentInvoice: {
        readonly id: string;
      } | null;
      readonly id: string;
    };
  };
};
export type OrganizationId_invoiceDeleteMutation = {
  response: OrganizationId_invoiceDeleteMutation$data;
  variables: OrganizationId_invoiceDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MATOrganization",
  "kind": "LinkedField",
  "name": "organization",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "MATInvoice",
      "kind": "LinkedField",
      "name": "currentInvoice",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationId_invoiceDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATInvoice",
        "kind": "LinkedField",
        "name": "invoiceDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationId_invoiceDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATInvoice",
        "kind": "LinkedField",
        "name": "invoiceDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b1c40f836d1ef1a6f56be6f2f90763d",
    "id": null,
    "metadata": {},
    "name": "OrganizationId_invoiceDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationId_invoiceDeleteMutation(\n  $input: MATInvoiceDeleteInput!\n) {\n  invoiceDelete(input: $input) {\n    id\n    organization {\n      id\n      currentInvoice {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f05a59df1d10fecea9cea26c07e3c950";

export default node;
