import { RadioGroup, Radio } from '@primer/react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { StoreStuffUpdateDialog_storeStuff$key } from '../../../relay/__generated__/StoreStuffUpdateDialog_storeStuff.graphql';
import { StoreStuffUpdateDialog_storeStuffUpdateMutation } from '../../../relay/__generated__/StoreStuffUpdateDialog_storeStuffUpdateMutation.graphql';
import { endOfDay, formatISO } from '../../../utils/date';
import Button from '../../core/Button';
import DateField from '../../core/DateField';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormControl from '../../core/FormControl';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import View from '../../core/View';
import StuffSelectField from '../../stuff/StuffSelectField';

const StoreStuffUpdateDialog_storeStuff = graphql`
  fragment StoreStuffUpdateDialog_storeStuff on StoreStuff {
    id
    title
    type
    period
    endAt
    stuff {
      id
    }
  }
`;

type Props = { storeStuff: StoreStuffUpdateDialog_storeStuff$key } & DialogProps &
  Pick<MutationFormikProps<StoreStuffUpdateDialog_storeStuffUpdateMutation>, 'config' | 'onSubmit' | 'connections'>;

const StoreStuffUpdateDialog = ({ storeStuff, config, onSubmit, connections, ...props }: Props) => {
  const { id, title, type, stuff, endAt, period } = useFragment(StoreStuffUpdateDialog_storeStuff, storeStuff);

  const initialActiveTime = endAt ? 'endAt' : 'period';
  const activeTimeItems = [
    { id: 'period', text: '기간' },
    { id: 'endAt', text: '만료일' },
  ];

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>수정하기</Text>
      </Dialog.Header>
      <MutationFormik<StoreStuffUpdateDialog_storeStuffUpdateMutation>
        mutation={graphql`
          mutation StoreStuffUpdateDialog_storeStuffUpdateMutation($input: StoreStuffUpdateInput!) {
            storeStuffUpdate(input: $input) {
              id
              ...StoreStuffUpdateDialog_storeStuff
            }
          }
        `}
        initialValues={{
          id,
          title,
          type,
          period: period || null,
          endAt: endAt ? formatISO(new Date(endAt)) : null,
          stuff: stuff?.id,
        }}
        initialStatus={initialActiveTime}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {(
          { handleSubmit, setValues, values, dirty, setStatus, status, isSubmitting },
          { scrollContainerRef, nonFieldErrorRef },
        ) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField name={'title'} label={'상품명'} placeholder={'상품명 입력'} required />
                <EnumPairRadioGroupField
                  typename={'StoreStuffTypeEnum'}
                  name={'type'}
                  label={'타입'}
                  required
                  renderContainer={(children) => (
                    <Stack gapX={4} gapY={2} wrap>
                      {children}
                    </Stack>
                  )}
                  renderOptionWrapper={(option, { id }) => <Stack.Item key={id}>{option}</Stack.Item>}
                />
                <View>
                  <Text fontSize={1} fontWeight={'bold'}>
                    부여일 *
                  </Text>
                  <View sx={{ marginTop: 2 }}>
                    <RadioGroup
                      name={'period or endAt'}
                      onChange={(value) => {
                        if (value) {
                          setValues({
                            ...values,
                            ...activeTimeItems.reduce((pre, { id }) => {
                              if (id === value) return pre;
                              return { ...pre, [id]: null };
                            }, {}),
                          });
                          setStatus(value);
                        }
                      }}
                      required
                      disabled={isSubmitting}
                    >
                      <Grid gapX={2} wrap={false}>
                        <ItemList
                          items={activeTimeItems}
                          renderItem={({ id, text }) => (
                            <FormControl sx={{ alignItems: 'center' }}>
                              <Radio id={id} value={id} checked={status === id} />
                              <FormControl.Label sx={{ fontWeight: 'normal' }}>{text}</FormControl.Label>
                            </FormControl>
                          )}
                          renderItemWrapper={(children, { id }) => (
                            <Grid.Unit key={id} size={1 / 2}>
                              {children}
                            </Grid.Unit>
                          )}
                        />
                      </Grid>
                    </RadioGroup>
                  </View>
                  <View sx={{ marginTop: 2 }}>
                    <Grid gapX={2} wrap={false}>
                      <Grid.Unit size={1 / 2}>
                        <NumberField
                          label={'기간'}
                          labelConfig={{ visuallyHidden: true }}
                          name={'period'}
                          disabled={status !== 'period'}
                          placeholder={'기간 입력'}
                        />
                      </Grid.Unit>
                      <Grid.Unit size={1 / 2}>
                        <DateField
                          label={'만료일'}
                          labelConfig={{ visuallyHidden: true }}
                          name={'endAt'}
                          onChange={(date) => setValues({ ...values, endAt: formatISO(endOfDay(date)) })}
                          disabled={status !== 'endAt'}
                          placeholder={'YYYY-MM-DD'}
                        />
                      </Grid.Unit>
                    </Grid>
                  </View>
                </View>
                <StuffSelectField label={'물품'} name={'stuff'} placeholder={'물품 선택'} />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} size={'large'} disabled={!dirty} onClick={() => handleSubmit()}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default StoreStuffUpdateDialog;
