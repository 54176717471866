import Button, { ButtonProps } from '../Button';
import Link, { LinkProps } from '../Link';
import View from '../View';

type Props = {} & LinkProps & ButtonProps;

const LinkButton = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  locale,
  legacyBehavior,
  target,
  download,
  ...props
}: Props) => (
  <View sx={{ width: 'fit-content' }}>
    {props.disabled ? (
      <Button {...props} />
    ) : (
      <Link
        href={href}
        as={as}
        replace={replace}
        scroll={scroll}
        shallow={shallow}
        passHref={passHref}
        prefetch={prefetch}
        locale={locale}
        legacyBehavior={legacyBehavior}
        target={target}
        download={download}
      >
        <Button {...props} />
      </Link>
    )}
  </View>
);
export default LinkButton;
export type { Props as LinkButtonProps };
