import { Space } from '@teamturing/react-kit';
import { graphql, useFragment } from 'react-relay';

import { UserUpdatePasswordDialog_user$key } from '../../../relay/__generated__/UserUpdatePasswordDialog_user.graphql';
import { UserUpdatePasswordDialog_userUpdateMutation } from '../../../relay/__generated__/UserUpdatePasswordDialog_userUpdateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import PasswordField from '../../core/PasswordField';
import Text from '../../core/Text';

const UserUpdatePasswordDialog_user = graphql`
  fragment UserUpdatePasswordDialog_user on User {
    id
  }
`;

type Props = { user: UserUpdatePasswordDialog_user$key } & DialogProps &
  Pick<MutationFormikProps<UserUpdatePasswordDialog_userUpdateMutation>, 'config'>;

const UserUpdatePasswordDialog = ({ config, user, ...props }: Props) => {
  const { id } = useFragment(UserUpdatePasswordDialog_user, user);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text fontSize={2} fontWeight={'bold'}>
          비밀번호 변경하기
        </Text>
      </Dialog.Header>
      <MutationFormik<UserUpdatePasswordDialog_userUpdateMutation>
        mutation={graphql`
          mutation UserUpdatePasswordDialog_userUpdateMutation($input: UserUpdateInput!) {
            userUpdate(input: $input) {
              ...UserUpdatePasswordDialog_user
            }
          }
        `}
        initialValues={{ id, password: '' }}
        initialStatus={{ passwordCheck: '' }}
        config={config}
      >
        {({ handleSubmit, setStatus, setFieldError, values, status }) => (
          <>
            <Dialog.Body>
              <PasswordField
                label={'비밀번호'}
                labelConfig={{ visuallyHidden: true }}
                placeholder={'비밀번호 입력'}
                name={'password'}
                required
              />
              <Space mt={2}>
                <PasswordField
                  label={'비밀번호 확인'}
                  labelConfig={{ visuallyHidden: true }}
                  placeholder={'비밀번호 확인'}
                  name={'passwordCheck'}
                  onChange={(e) => setStatus({ passwordCheck: e.target.value ?? '' })}
                />
              </Space>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                variant={'primary'}
                onClick={() => {
                  if (values.password === status.passwordCheck) handleSubmit();
                  else {
                    setFieldError('passwordCheck', '비밀번호가 일치하지 않습니다.');
                  }
                }}
              >
                변경하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default UserUpdatePasswordDialog;
