import { graphql, useFragment } from 'react-relay';

import { TaskHintFeedbackDifferenceItemList_difference$key } from '../../../relay/__generated__/TaskHintFeedbackDifferenceItemList_difference.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import Text from '../../core/Text';
import View from '../../core/View';
import DiffItem from '../../difference/DiffItem';

const TaskHintFeedbackDifferenceItemList_difference = graphql`
  fragment TaskHintFeedbackDifferenceItemList_difference on Difference {
    ... on TaskHintFeedbackDifference {
      hint {
        ...DiffItem_diff
      }
      specialAnswer {
        ...DiffItem_diff
      }
      special {
        ...DiffItem_diff
      }
      general {
        ...DiffItem_diff
      }
      incorrect {
        ...DiffItem_diff
      }
      correct {
        ...DiffItem_diff
      }
    }
  }
`;

type Props = { difference: TaskHintFeedbackDifferenceItemList_difference$key };

const TaskHintFeedbackDifferenceItemList = ({ difference }: Props) => {
  const { hint, specialAnswer, special, general, incorrect, correct } = useFragment(
    TaskHintFeedbackDifferenceItemList_difference,
    difference,
  );

  const diffItems = [
    { title: '힌트', diff: hint, renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex> },
    {
      title: '특정 오답',
      diff: specialAnswer,
    },
    {
      title: '특정 오답 피드백',
      diff: special,
      renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex>,
    },
    {
      title: '일반 오답 피드백',
      diff: general,
      renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex>,
    },
    {
      title: '오답 피드백',
      diff: incorrect,
      renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex>,
    },
    { title: '정답 피드백', diff: correct, renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex> },
  ].filter(({ diff }) => !isNullable(diff));
  return (
    <ItemList
      items={diffItems}
      renderItem={({ title, diff, renderItem }) => (
        <>
          <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
          <View sx={{ marginTop: 2 }}>
            <DiffItem diff={diff!} renderItem={renderItem} />
          </View>
        </>
      )}
      renderItemWrapper={(children, { title }, i) => (
        <View key={title} sx={{ marginTop: i > 0 ? 3 : 0 }}>
          {children}
        </View>
      )}
      emptyState={
        <View sx={{ paddingY: 6 }}>
          <EmptyState
            title={'기록되지 않은 히스토리이에요'}
            description={'이 기능이 만들어지기 전에 일어난 일이거나 오류로 누락된 히스토리입니다.'}
          />
        </View>
      }
    />
  );
};

export default TaskHintFeedbackDifferenceItemList;
