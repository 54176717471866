import useConfirm, { UseConfirmOptions } from '../../../hooks/useConfirm';
import IconButton, { IconButtonProps } from '../IconButton';

type Props = {} & UseConfirmOptions & IconButtonProps;

const ConfirmIconButton = ({ onClick, message, ...props }: Props) => {
  const { confirm } = useConfirm({ message });

  const handleClick: IconButtonProps['onClick'] = (event) => {
    if (!onClick) return;
    if (confirm()) onClick(event);
  };
  return <IconButton onClick={handleClick} {...props} />;
};

export default ConfirmIconButton;
export type { Props as ConfirmIconButtonProps };
