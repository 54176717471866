/**
 * @generated SignedSource<<2f2cad929b10754c8b112684e089b01e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StuffTypeEnum = "coupon" | "delivery" | "gifticon" | "premium_non_refundable" | "premium_refundable" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StuffUpdateDialog_stuff$data = {
  readonly description: string | null;
  readonly id: string;
  readonly title: string;
  readonly type: StuffTypeEnum;
  readonly " $fragmentType": "StuffUpdateDialog_stuff";
};
export type StuffUpdateDialog_stuff$key = {
  readonly " $data"?: StuffUpdateDialog_stuff$data;
  readonly " $fragmentSpreads": FragmentRefs<"StuffUpdateDialog_stuff">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StuffUpdateDialog_stuff",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Stuff",
  "abstractKey": null
};

(node as any).hash = "91f7fc75586ef2ad665c79e3736dd41c";

export default node;
