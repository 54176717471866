import { useEffect } from 'react';
import {
  PreloadableConcreteRequest,
  PreloadedQuery,
  useQueryLoader,
  UseQueryLoaderLoadQueryOptions,
  VariablesOf,
} from 'react-relay';
import { DisposeFn, GraphQLTaggedNode, OperationType } from 'relay-runtime';

import { SearchOverlayFieldProps } from '../../components/core/SearchOverlayField';

const useSearchOverlayQueryLoader = <TQuery extends OperationType>(
  preloadableRequest: GraphQLTaggedNode | PreloadableConcreteRequest<TQuery>,
  variables?: TQuery['variables'],
  initialQueryReference?: PreloadedQuery<TQuery> | null,
) => {
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<TQuery>(preloadableRequest, initialQueryReference);
  useEffect(() => {
    loadQuery({ ...variables });
    return () => disposeQuery();
  }, [loadQuery, disposeQuery]);

  const searchOverlayFieldProps: Pick<SearchOverlayFieldProps<any>, 'onSearch' | 'onOverlayOpen' | 'onOverlayClose'> = {
    onSearch: (search) => {
      loadQuery({ ...variables, filters: { ...variables?.filters, search } });
    },
    onOverlayOpen: () => loadQuery({ ...variables }),
    onOverlayClose: () => disposeQuery(),
  };

  return [searchOverlayFieldProps, queryReference, loadQuery, disposeQuery] as [
    Pick<SearchOverlayFieldProps<any>, 'onSearch' | 'onOverlayOpen' | 'onOverlayClose'>,
    PreloadedQuery<TQuery> | null | undefined,
    (variables: VariablesOf<TQuery>, options?: UseQueryLoaderLoadQueryOptions) => void,
    DisposeFn,
  ];
};

export default useSearchOverlayQueryLoader;
