import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  CodepushVersionDescriptionList_codepushVersion$data,
  CodepushVersionDescriptionList_codepushVersion$key,
} from '../../../relay/__generated__/CodepushVersionDescriptionList_codepushVersion.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import Markdown from '../../core/Markdown';
import CodepushVersionStatusLabel from '../CodepushVersionStatusLabel';

const CodepushVersionDescriptionList_codepushVersion = graphql`
  fragment CodepushVersionDescriptionList_codepushVersion on CodepushVersion {
    id
    version
    targetBinaryVersion
    severity
    title

    created
    ...CodepushVersionStatusLabel_codepushVersion
  }
`;

type Props = {
  codepushVersion: CodepushVersionDescriptionList_codepushVersion$key;
  type?: 'default' | 'activity';
} & Omit<
  DescriptionListProps<CodepushVersionDescriptionList_codepushVersion$data>,
  'item' | 'itemDescriptions' | 'picks'
>;

const CodepushVersionDescriptionList = ({
  codepushVersion: codepushVersionReference,
  type = 'default',
  ...props
}: Props) => {
  const codepushVersion = useFragment(CodepushVersionDescriptionList_codepushVersion, codepushVersionReference);

  return (
    <DescriptionList
      item={codepushVersion}
      itemDescriptions={{
        version: {
          title: '버전',
          renderValue: ({ version }) => version,
        },
        targetBinaryVersion: {
          title: '바이너리 타겟 버전',
          renderValue: ({ targetBinaryVersion }) => targetBinaryVersion,
        },
        severity: {
          title: '티어',
          renderValue: ({ severity }) => <EnumPair typename={'CodepushSeverityEnum'}>{severity}</EnumPair>,
        },
        title: {
          title: '업데이트 항목',
          renderValue: ({ title }) => (title ? <Markdown>{title}</Markdown> : undefined),
        },
        created: {
          title: '제작일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
        },
        status: {
          title: '상태',
          renderValue: (codepushVersion) => <CodepushVersionStatusLabel codepushVersion={codepushVersion} />,
        },
      }}
      picks={
        type === 'default'
          ? ['version', 'targetBinaryVersion', 'severity', 'title']
          : type === 'activity'
          ? ['created', 'status']
          : []
      }
      {...props}
    />
  );
};

export default CodepushVersionDescriptionList;
