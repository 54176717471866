import { CheckIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import { MATOrganizationTeachersCreateDialog_matOrganization$key } from '../../../relay/__generated__/MATOrganizationTeachersCreateDialog_matOrganization.graphql';
import { MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation } from '../../../relay/__generated__/MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import Text from '../../core/Text';

const MATOrganizationTeachersCreateDialog_matOrganization = graphql`
  fragment MATOrganizationTeachersCreateDialog_matOrganization on MATOrganization {
    id
  }
`;

type Props = { matOrganization: MATOrganizationTeachersCreateDialog_matOrganization$key } & DialogProps &
  Pick<
    MutationFormikProps<MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const MATOrganizationTeachersCreateDialog = ({ matOrganization, config, onSubmit, connections, ...props }: Props) => {
  const { id } = useFragment(MATOrganizationTeachersCreateDialog_matOrganization, matOrganization);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>선생님 계정 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation>
        mutation={graphql`
          mutation MATOrganizationTeachersCreateDialog_organizationTeachersCreateMutation(
            $input: MATOrganizationTeachersCreateInput!
          ) {
            organizationTeachersCreate(input: $input)
          }
        `}
        initialValues={{ organization: id }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit }, { nonFieldErrorRef, scrollContainerRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <NumberField
                  label={'계정 개수'}
                  name={'count'}
                  required
                  trailingVisual={'개'}
                  placeholder={'개수 입력'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default MATOrganizationTeachersCreateDialog;
