import { graphql, useFragment } from 'react-relay';

import {
  TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo$data,
  TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo$key,
} from '../../../relay/__generated__/TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo.graphql';
import { isNullable } from '../../../utils/is';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';

const TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo = graphql`
  fragment TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo on TaskProblemSolveInfo {
    totalCount
    correctRate
  }
`;

type Props = {
  taskProblemSolveInfo: TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo$key;
  type?: 'default';
} & Omit<
  DescriptionListProps<TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo$data>,
  'item' | 'itemDescriptions' | 'picks'
>;

const TaskProblemSolveInfoDescriptionList = ({
  taskProblemSolveInfo: taskProblemSolveInfoReference,
  type = 'default',
  ...props
}: Props) => {
  const taskProblemSolveInfo = useFragment(
    TaskProblemSolveInfoDescriptionList_taskProblemSolveInfo,
    taskProblemSolveInfoReference,
  );

  return (
    <DescriptionList
      item={taskProblemSolveInfo}
      itemDescriptions={{
        totalCount: {
          title: '전체 풀이 수',
          renderValue: ({ totalCount }) => totalCount,
        },
        correctRate: {
          title: '정답률',
          renderValue: ({ correctRate }) => (!isNullable(correctRate) ? `${correctRate}%` : undefined),
        },
      }}
      picks={type === 'default' ? ['totalCount', 'correctRate'] : []}
      {...props}
    />
  );
};

export default TaskProblemSolveInfoDescriptionList;
