/**
 * @generated SignedSource<<c3e984d11451771825ebd303726223b2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type useEnumPairsQuery_enumPairsQuery$variables = {
  typename: string;
};
export type useEnumPairsQuery_enumPairsQuery$data = {
  readonly enumPairs: ReadonlyArray<{
    readonly key: string;
    readonly value: string;
  }>;
};
export type useEnumPairsQuery_enumPairsQuery = {
  response: useEnumPairsQuery_enumPairsQuery$data;
  variables: useEnumPairsQuery_enumPairsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "typename"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "typename",
        "variableName": "typename"
      }
    ],
    "concreteType": "Pair",
    "kind": "LinkedField",
    "name": "enumPairs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useEnumPairsQuery_enumPairsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useEnumPairsQuery_enumPairsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a2a2d3b774dbbd59418443efcf900146",
    "id": null,
    "metadata": {},
    "name": "useEnumPairsQuery_enumPairsQuery",
    "operationKind": "query",
    "text": "query useEnumPairsQuery_enumPairsQuery(\n  $typename: String!\n) {\n  enumPairs(typename: $typename) {\n    key\n    value\n  }\n}\n"
  }
};
})();

(node as any).hash = "7becdb889e5fb49ee2e4495f038a2d17";

export default node;
