import { graphql, useFragment } from 'react-relay';

import {
  AppDialogStatusEnum,
  AppDialogStatusLabel_appDialog$key,
} from '../../../relay/__generated__/AppDialogStatusLabel_appDialog.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const AppDialogStatusLabel_appDialog = graphql`
  fragment AppDialogStatusLabel_appDialog on AppDialog {
    status
  }
`;

type Props = {
  appDialog: AppDialogStatusLabel_appDialog$key;
} & Omit<LabelProps, 'variant'>;

const AppDialogStatusLabel = ({ appDialog, ...props }: Props) => {
  const { status } = useFragment(AppDialogStatusLabel_appDialog, appDialog);

  const labelPropsByStatus: Record<AppDialogStatusEnum, LabelProps> = {
    'pending': { variant: 'accent' },
    'ongoing': { variant: 'success' },
    'finished': { variant: 'default' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'AppDialogStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default AppDialogStatusLabel;
