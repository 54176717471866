import { SearchIcon, SyncIcon, WorkflowIcon, XIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { ComponentProps, Suspense, useEffect, useState } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import Checkbox from '../../components/core/Checkbox';
import CheckboxGroupField from '../../components/core/CheckboxGroupField';
import { RowWrapper } from '../../components/core/DataTable';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import NumberRangeField from '../../components/core/NumberRangeField';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import ProblemGeneratorAssignDialog from '../../components/problemGenerator/ProblemGeneratorAssignDialog';
import ProblemGeneratorConnectionDataTable from '../../components/problemGenerator/ProblemGeneratorConnectionDataTable';
import ProblemGeneratorWindowPaginator from '../../components/problemGenerator/ProblemGeneratorWindowPaginator';
import UnitAPaginatorSearchOverlayTokenField from '../../components/unitA/UnitAPaginatorSearchOverlayTokenField';
import UnitBPaginatorSearchOverlayTokenField from '../../components/unitB/UnitBPaginatorSearchOverlayTokenField';
import UnitCPaginatorSearchOverlayTokenField from '../../components/unitC/UnitCPaginatorSearchOverlayTokenField';
import UnitDPaginatorSearchOverlayTokenField from '../../components/unitD/UnitDPaginatorSearchOverlayTokenField';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { problemGenerator_meQuery } from '../../relay/__generated__/problemGenerator_meQuery.graphql';
import {
  ProblemGeneratorStatusEnum,
  problemGenerator_problemGeneratorsQuery,
} from '../../relay/__generated__/problemGenerator_problemGeneratorsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { normalizeObject } from '../../utils/object';
import { NextPage } from '../_app';

import ProblemGeneratorId from './[problemGeneratorId]';

const problemGeneratorsForProblemGenerator = graphql`
  query problemGenerator_problemGeneratorsQuery(
    $filters: ProblemGeneratorFilter
    $order: ProblemGeneratorOrder
    $page: Int
    $pageSize: Int
  ) {
    ...ProblemGeneratorWindowPaginator_query
      @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

const meForProblemGenerator = graphql`
  query problemGenerator_meQuery {
    me {
      id
      permissions {
        id
        title
      }
    }
  }
`;

type Props = {};

const ProblemGenerator: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const [{ me }] = useLazyLoadQuery<problemGenerator_meQuery>(meForProblemGenerator, {});

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },

    status_In: { type: 'string', multiple: true },
    publishedProblemsCount_Gte: { type: 'number' },
    publishedProblemsCount_Lte: { type: 'number' },

    activeProblemsCount_Gte: { type: 'number' },
    activeProblemsCount_Lte: { type: 'number' },

    unitAId_In: { type: 'string', multiple: true },
    unitBId_In: { type: 'string', multiple: true },

    unitCId_In: { type: 'string', multiple: true },
    unitDId_In: { type: 'string', multiple: true },

    assignedToId_In: { type: 'string', multiple: true },
    assignedTo_Exists_In: { type: 'boolean', multiple: true },

    order: { type: 'string' },
    page: { type: 'number' },
  });

  const {
    search,
    status_In,
    publishedProblemsCount_Gte,
    publishedProblemsCount_Lte,
    activeProblemsCount_Gte,
    activeProblemsCount_Lte,
    unitAId_In,
    unitBId_In,
    unitCId_In,
    unitDId_In,
    assignedToId_In,
    assignedTo_Exists_In,
    order,
    page,
  } = initialValues;

  const [selectedProblemGenerators, setSelectedProblemGenerators] = useState<string[]>([]);

  useEffect(() => {
    return () => setSelectedProblemGenerators([]);
  }, [JSON.stringify(initialValues)]);

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'문제 자동 생성'} />
      <ErrorBoundary>
        <QueryFormik<problemGenerator_problemGeneratorsQuery>
          query={problemGeneratorsForProblemGenerator}
          staticVariables={{ pageSize: 50 }}
          initialValues={{
            filters: {
              search,
              status_In: status_In as ProblemGeneratorStatusEnum[],
              publishedProblemsCount_Gte,
              publishedProblemsCount_Lte,
              activeProblemsCount_Gte,
              activeProblemsCount_Lte,
              unitAId_In,
              unitBId_In,
              unitCId_In,
              unitDId_In,
              assignedToId_In,
              assignedTo_Exists_In,
            },
            order: order || '-changed',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          enableReinitialize
        >
          {({ values: { filters, order }, setFieldValue, submitForm, handleSubmit, resetForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            const isSelectedPublishedProblemsCountRange =
              filters?.publishedProblemsCount_Gte || filters?.publishedProblemsCount_Lte;
            const isSelectedActiveProblemsCountRange =
              filters?.activeProblemsCount_Gte || filters?.activeProblemsCount_Lte;

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>문제 자동 생성</Text>
                  </Grid.Unit>
                  {!!me.permissions.find(({ title }) => title === 'problem_generator_assign') ? (
                    <Grid.Unit size={'min'}>
                      <DialogButton
                        size={'large'}
                        variant={'primary'}
                        disabled={selectedProblemGenerators.length === 0}
                        renderDialog={({ isOpen, closeDialog }) => (
                          <ProblemGeneratorAssignDialog
                            isOpen={isOpen}
                            onDismiss={closeDialog}
                            problemGenerators={selectedProblemGenerators}
                            config={{
                              onCompleted: () => {
                                toast('배정이 완료됐어요', 'success');
                                closeDialog();
                                handleSubmit();
                                setSelectedProblemGenerators([]);
                              },
                              onError: () => {
                                toast('배정에 실패했어요', 'error');
                              },
                            }}
                          />
                        )}
                      >
                        배정하기
                      </DialogButton>
                    </Grid.Unit>
                  ) : null}
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'ProblemGeneratorFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'ProblemGeneratorOrder'}
                            orders={['changed', 'originalTask_UnitD_Order']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'상태'}>
                      <EnumPairSearchOverlayTokenField
                        label={'상태'}
                        typename={'ProblemGeneratorStatusEnum'}
                        name={'filters.status_In'}
                        placeholder={'상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'생성된 문제'}>
                      <Grid sx={{ alignItems: 'center' }} wrap={false}>
                        <Grid.Unit size={'max'}>
                          <NumberRangeField
                            label={'activeProblemsCount'}
                            labelConfig={{ visuallyHidden: true }}
                            name={['filters.activeProblemsCount_Gte', 'filters.activeProblemsCount_Lte']}
                            placeholder={['0', '0']}
                            debounce
                          />
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <IconButton
                            icon={XIcon}
                            aria-label={'reset range'}
                            variant={'plain'}
                            disabled={!isSelectedActiveProblemsCountRange}
                            onClick={() => {
                              setFieldValue('filters.activeProblemsCount_Gte', undefined);
                              setFieldValue('filters.activeProblemsCount_Lte', undefined);
                            }}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 3]} title={'출시된 문제'}>
                      <Grid sx={{ alignItems: 'center' }} wrap={false}>
                        <Grid.Unit size={'max'}>
                          <NumberRangeField
                            label={'publishedProblemsCount'}
                            labelConfig={{ visuallyHidden: true }}
                            name={['filters.publishedProblemsCount_Gte', 'filters.publishedProblemsCount_Lte']}
                            placeholder={['0', '0']}
                            debounce
                          />
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <IconButton
                            icon={XIcon}
                            aria-label={'reset range'}
                            variant={'plain'}
                            disabled={!isSelectedPublishedProblemsCountRange}
                            onClick={() => {
                              setFieldValue('filters.publishedProblemsCount_Gte', undefined);
                              setFieldValue('filters.publishedProblemsCount_Lte', undefined);
                            }}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'작업자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'작업자'}
                        name={'filters.assignedToId_In'}
                        placeholder={'작업자 선택'}
                        variables={{ filters: { team_In: ['contents'] } }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'배정'}>
                      <CheckboxGroupField
                        name={'filters.assignedTo_Exists_In'}
                        label={'배정'}
                        options={[
                          { id: 'false', text: '배정 필요' },
                          { id: 'true', text: '배정 완료' },
                        ]}
                        renderContainer={(children) => (
                          <View sx={{ paddingX: 2 }}>
                            <Grid gapX={2}>{children}</Grid>
                          </View>
                        )}
                        renderOptionWrapper={(children, { id }) => (
                          <Grid.Unit key={id} size={'min'}>
                            {children}
                          </Grid.Unit>
                        )}
                        value={filters?.assignedTo_Exists_In?.map((value) => (value === true ? 'true' : 'false'))}
                        onChange={(selected) =>
                          setFieldValue(
                            'filters.assignedTo_Exists_In',
                            selected.map((selectedValue) => selectedValue === 'true'),
                          )
                        }
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'Unit A'}>
                      <UnitAPaginatorSearchOverlayTokenField
                        label={'Unit A'}
                        name={'filters.unitAId_In'}
                        placeholder={'Unit A 선택'}
                        variables={{
                          order: { order: 'ASC' },
                        }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'Unit B'}>
                      <UnitBPaginatorSearchOverlayTokenField
                        label={'Unit B'}
                        name={'filters.unitBId_In'}
                        placeholder={'Unit B 선택'}
                        variables={{
                          order: { order: 'ASC' },
                          filters: {
                            unitAId_In: filters?.unitAId_In,
                          },
                        }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'Unit C'}>
                      <UnitCPaginatorSearchOverlayTokenField
                        label={'Unit C'}
                        name={'filters.unitCId_In'}
                        placeholder={'Unit C 선택'}
                        variables={{
                          order: { order: 'ASC' },
                          filters: {
                            unitAId_In: filters?.unitAId_In,
                            unitBId_In: filters?.unitBId_In,
                          },
                        }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'Unit D'}>
                      <UnitDPaginatorSearchOverlayTokenField
                        label={'Unit D'}
                        name={'filters.unitDId_In'}
                        placeholder={'Unit D 선택'}
                        variables={{
                          order: { order: 'ASC' },
                          filters: {
                            unitAId_In: filters?.unitAId_In,
                            unitBId_In: filters?.unitBId_In,
                            unitCId_In: filters?.unitCId_In,
                          },
                        }}
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<problemGenerator_problemGeneratorsQuery>>
                        {(queryReference) => (
                          <ProblemGeneratorWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => {
                              setParsedUrlQuery({ ...filters, order, page });
                            }}
                          >
                            {({ problemGenerators }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    {`총 ${numberWithCommas(problemGenerators.totalCount || 0)}개${
                                      selectedProblemGenerators.length > 0
                                        ? ` / ${selectedProblemGenerators.length}개`
                                        : ''
                                    }`}
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <ProblemGeneratorConnectionDataTable
                                    problemGeneratorConnection={problemGenerators}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                    renderHead={(columns) => (
                                      <View
                                        as={'thead'}
                                        sx={{
                                          borderBottomWidth: 1,
                                          borderBottomStyle: 'solid',
                                          borderBottomColor: 'border.default',
                                          backgroundColor: 'canvas.subtle',
                                        }}
                                      >
                                        <View as={'tr'}>
                                          <View as={'th'} sx={{ padding: 2 }}>
                                            <Checkbox
                                              checked={
                                                selectedProblemGenerators.length === problemGenerators.edges.length
                                              }
                                              onChange={(e) => {
                                                setSelectedProblemGenerators(
                                                  e.target.checked
                                                    ? problemGenerators.edges.map(({ node }) => node.id)
                                                    : [],
                                                );
                                              }}
                                            />
                                          </View>
                                          {columns.map(({ field, title, width }) => (
                                            <View
                                              key={field}
                                              as={'th'}
                                              sx={{
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                borderColor: 'border.default',
                                                minWidth: width,
                                                textAlign: 'start',
                                                padding: 2,
                                                fontWeight: 'bold',
                                                color: 'fg.muted',
                                              }}
                                            >
                                              {title}
                                            </View>
                                          ))}
                                        </View>
                                      </View>
                                    )}
                                    renderRowWrapper={(children, row) => {
                                      const isSelectedProblemGenerator = !!selectedProblemGenerators.find(
                                        (id) => id === row.id,
                                      );
                                      return (
                                        <RowWrapper
                                          key={row.id}
                                          sx={{
                                            'cursor': 'pointer',
                                            ':hover': {
                                              backgroundColor: 'canvas.inset',
                                              transition: 'background-color 250ms',
                                            },
                                          }}
                                          onClick={() => {
                                            const pathname = `/problemGenerator/${row.id}`;
                                            router.push(
                                              { pathname, query: normalizeObject({ ...filters, order }) },
                                              pathname,
                                            );
                                          }}
                                        >
                                          <View
                                            as={'td'}
                                            sx={{
                                              cursor: 'default',
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              padding: 2,
                                            }}
                                            onClick={(e) => e.stopPropagation()}
                                          >
                                            <Checkbox
                                              checked={isSelectedProblemGenerator}
                                              onChange={() => {
                                                setSelectedProblemGenerators(
                                                  isSelectedProblemGenerator
                                                    ? selectedProblemGenerators.filter((id) => id !== row.id)
                                                    : [...selectedProblemGenerators, row.id],
                                                );
                                              }}
                                            />
                                          </View>
                                          {children}
                                        </RowWrapper>
                                      );
                                    }}
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </ProblemGeneratorWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

ProblemGenerator.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
ProblemGenerator.authenticated = true;
ProblemGenerator.routes = [
  {
    id: 'problemGenerator',
    pathname: '/problemGenerator',
    name: '문제 자동 생성',
    icon: WorkflowIcon,
    permissions: ['problem_generator_read'],
  },
  ...ProblemGeneratorId.routes,
];

export default ProblemGenerator;
