import { KebabHorizontalIcon, PencilIcon, TrashIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { graphql } from 'react-relay';

import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import DateText from '../../components/core/DateText';
import DialogButton from '../../components/core/DialogButton';
import EnumPair from '../../components/core/EnumPair';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import RaffleStuffPreviewItem from '../../components/raffleStuff/RaffleStuffPreviewItem';
import RaffleStuffUpdateDialog from '../../components/raffleStuff/RaffleStuffUpdateDialog';
import UserAvatarText from '../../components/user/UserAvatarText';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { RaffleStuffId_raffleStuffDeleteMutation } from '../../relay/__generated__/RaffleStuffId_raffleStuffDeleteMutation.graphql';
import { RaffleStuffId_raffleStuffQuery } from '../../relay/__generated__/RaffleStuffId_raffleStuffQuery.graphql';
import { NextPage } from '../_app';

const raffleStuffForRaffleStuffId = graphql`
  query RaffleStuffId_raffleStuffQuery($id: ID!) {
    raffleStuff(id: $id) {
      id
      title
      actions
      description
      ...RaffleStuffPreviewItem_raffleStuff
      stuff {
        id
        type
        title
      }
      createdBy {
        id
        ...UserAvatarText_user
      }
      created
      modifiedBy {
        id
        ...UserAvatarText_user
      }
      modified
      ...RaffleStuffUpdateDialog_raffleStuff
    }
  }
`;

type Props = {};

const RaffleStuffId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ raffleStuff }] = useLazyLoadQuery<RaffleStuffId_raffleStuffQuery>(raffleStuffForRaffleStuffId, {
    id: router.query.raffleStuffId as string,
  });
  if (!raffleStuff) return null;
  const { id, title, actions, description, stuff, createdBy, created, modifiedBy, modified } = raffleStuff;

  return (
    <View>
      <Head siteTitle={`뽑기왕 경품 - ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>{title}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              {actions.includes('raffle_stuff_update') ? (
                <Stack.Item>
                  <DialogButton
                    renderDialog={({ isOpen, closeDialog }) => (
                      <RaffleStuffUpdateDialog
                        raffleStuff={raffleStuff}
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        wide
                        config={{
                          onCompleted: () => {
                            toast('뽑기왕 경품 수정 완료!', 'success');
                            closeDialog();
                          },
                        }}
                      />
                    )}
                    variant={'outline'}
                    leadingIcon={PencilIcon}
                  >
                    수정하기
                  </DialogButton>
                </Stack.Item>
              ) : null}
              {actions.includes('raffle_stuff_delete') ? (
                <Stack.Item>
                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.MutationItem<RaffleStuffId_raffleStuffDeleteMutation>
                          mutation={graphql`
                            mutation RaffleStuffId_raffleStuffDeleteMutation($input: RaffleStuffDeleteInput!) {
                              raffleStuffDelete(input: $input) {
                                id @deleteRecord
                              }
                            }
                          `}
                          input={{ id }}
                          variant={'danger'}
                          config={{
                            onCompleted: () => {
                              router.replace('/raffleStuff').then(() => toast('뽑기왕 경품 삭제 완료!', 'success'));
                            },
                          }}
                          message={'정말 삭제할까요?'}
                        >
                          <ActionList.LeadingVisual>
                            <TrashIcon />
                          </ActionList.LeadingVisual>
                          삭제하기
                        </ActionList.MutationItem>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Stack.Item>
              ) : null}
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <View>
              <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>미리보기</Text>
              <View sx={{ marginTop: 2 }}>
                <Grid gapX={2}>
                  <Grid.Unit size={[1 / 2, 1 / 2, 1 / 4]}>
                    <RaffleStuffPreviewItem raffleStuff={raffleStuff} />
                  </Grid.Unit>
                </Grid>
              </View>
            </View>
            <View sx={{ marginTop: 3 }}>
              <ItemList
                items={[
                  {
                    id: 'description',
                    title: '설명',
                    description: (
                      <Text sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>{description || '-'}</Text>
                    ),
                  },
                  {
                    id: 'stuff',
                    title: '물품',
                    description: (
                      <Text>
                        {stuff.title} <EnumPair typename={'StuffTypeEnum'}>{stuff.type}</EnumPair>
                      </Text>
                    ),
                  },
                ]}
                renderItem={({ title, description }) => (
                  <View>
                    <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                    <View
                      sx={{
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderColor: 'border.default',
                        borderRadius: 2,
                        marginTop: 2,
                        padding: 3,
                      }}
                    >
                      <Text sx={{ fontSize: 1 }}>{description}</Text>
                    </View>
                  </View>
                )}
                renderItemWrapper={(children, { id }, i) => (
                  <View key={id} sx={{ marginTop: i > 0 ? 3 : 0 }}>
                    {children}
                  </View>
                )}
              />
            </View>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <ItemList
              items={[
                { id: 'createdBy', title: '제작자', description: <UserAvatarText user={createdBy} /> },
                { id: 'created', title: '제작일', description: <DateText>{created}</DateText> },
                {
                  id: 'modifiedBy',
                  title: '수정자',
                  description: modifiedBy ? <UserAvatarText user={modifiedBy} /> : '-',
                },
                { id: 'modified', title: '수정일', description: <DateText>{modified}</DateText> },
              ]}
              renderItem={({ title, description }) => (
                <Grid gapX={1} gapY={1}>
                  <Grid.Unit size={[1, 1, 'max']}>
                    <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 'min']}>
                    <Text sx={{ fontSize: 1 }}>{description}</Text>
                  </Grid.Unit>
                </Grid>
              )}
              renderItemWrapper={(children, { id }, i) => (
                <View key={id} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                  {children}
                </View>
              )}
            />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

RaffleStuffId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
RaffleStuffId.authenticated = true;
RaffleStuffId.routes = [
  {
    id: 'raffleStuffId',
    name: '뽑기왕 경품',
    pathname: '/raffleStuff/[raffleStuffId]',
    permissions: ['raffle_stuff_read'],
  },
];

export default RaffleStuffId;
