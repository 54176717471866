/**
 * @generated SignedSource<<a5993ff8e8f0e2ffacb58ed7a90d78e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CommentActionEnum = "comment_delete" | "comment_update" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CommentMutationActionItem_comment$data = {
  readonly actions: ReadonlyArray<CommentActionEnum>;
  readonly createdBy: {
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly description: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"CommentItem_comment">;
  readonly " $fragmentType": "CommentMutationActionItem_comment";
};
export type CommentMutationActionItem_comment$key = {
  readonly " $data"?: CommentMutationActionItem_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentMutationActionItem_comment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentMutationActionItem_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentItem_comment"
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "7765765ed8e9c562c5a5d06e04553462";

export default node;
