import { graphql } from 'react-relay';

import { SchoolPaginator_query$key } from '../../../relay/__generated__/SchoolPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const SchoolPaginator_query = graphql`
  fragment SchoolPaginator_query on Query
  @argumentDefinitions(
    filters: { type: SchoolFilter }
    order: { type: SchoolOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "SchoolPaginatorRefreshQuery") {
    schools(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "SchoolPaginator_query_schools") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
          ...SchoolItem_school
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<SchoolPaginator_query$key>, 'fragment'>;

const SchoolPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={SchoolPaginator_query} {...props} />;

export default SchoolPaginator;
