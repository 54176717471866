/**
 * @generated SignedSource<<78343abf9ff194f5bb4040a2f00d09a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StoreStuffTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StoreStuffUpdateDialog_storeStuff$data = {
  readonly endAt: any | null;
  readonly id: string;
  readonly period: number | null;
  readonly stuff: {
    readonly id: string;
  } | null;
  readonly title: string;
  readonly type: StoreStuffTypeEnum;
  readonly " $fragmentType": "StoreStuffUpdateDialog_storeStuff";
};
export type StoreStuffUpdateDialog_storeStuff$key = {
  readonly " $data"?: StoreStuffUpdateDialog_storeStuff$data;
  readonly " $fragmentSpreads": FragmentRefs<"StoreStuffUpdateDialog_storeStuff">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoreStuffUpdateDialog_storeStuff",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "period",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Stuff",
      "kind": "LinkedField",
      "name": "stuff",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "StoreStuff",
  "abstractKey": null
};
})();

(node as any).hash = "f083b61bf4bc3056cb487fb1de520672";

export default node;
