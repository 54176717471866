/**
 * @generated SignedSource<<39694d3c7f33bd6f045fe644099e1053>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RaffleStuffUpdateInput = {
  backgroundColor?: string | null;
  blackThumbnail?: Upload | null;
  colorThumbnail?: Upload | null;
  description?: string | null;
  id: string;
  stuff?: NodeInput | null;
  title?: string | null;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type NodeInput = {
  id: string;
};
export type RaffleStuffUpdateDialog_raffleStuffUpdateMutation$variables = {
  input: RaffleStuffUpdateInput;
};
export type RaffleStuffUpdateDialog_raffleStuffUpdateMutation$data = {
  readonly raffleStuffUpdate: {
    readonly blackThumbnail: {
      readonly key: string;
      readonly objectUrl: string;
    };
    readonly colorThumbnail: {
      readonly key: string;
      readonly objectUrl: string;
    };
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly id: string;
      readonly name: string;
      readonly picture: string | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"RaffleStuffUpdateDialog_raffleStuff">;
  };
};
export type RaffleStuffUpdateDialog_raffleStuffUpdateMutation = {
  response: RaffleStuffUpdateDialog_raffleStuffUpdateMutation$data;
  variables: RaffleStuffUpdateDialog_raffleStuffUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "UploadResult",
  "kind": "LinkedField",
  "name": "colorThumbnail",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UploadResult",
  "kind": "LinkedField",
  "name": "blackThumbnail",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "modifiedBy",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "picture",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RaffleStuffUpdateDialog_raffleStuffUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RaffleStuff",
        "kind": "LinkedField",
        "name": "raffleStuffUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RaffleStuffUpdateDialog_raffleStuff"
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RaffleStuffUpdateDialog_raffleStuffUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "RaffleStuff",
        "kind": "LinkedField",
        "name": "raffleStuffUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Stuff",
            "kind": "LinkedField",
            "name": "stuff",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "backgroundColor",
            "storageKey": null
          },
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "326e86abb6613a0dfc7bb565084400c3",
    "id": null,
    "metadata": {},
    "name": "RaffleStuffUpdateDialog_raffleStuffUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation RaffleStuffUpdateDialog_raffleStuffUpdateMutation(\n  $input: RaffleStuffUpdateInput!\n) {\n  raffleStuffUpdate(input: $input) {\n    id\n    ...RaffleStuffUpdateDialog_raffleStuff\n    colorThumbnail {\n      key\n      objectUrl\n    }\n    blackThumbnail {\n      key\n      objectUrl\n    }\n    modifiedBy {\n      id\n      name\n      picture\n    }\n    modified\n  }\n}\n\nfragment RaffleStuffUpdateDialog_raffleStuff on RaffleStuff {\n  id\n  stuff {\n    id\n  }\n  title\n  description\n  backgroundColor\n}\n"
  }
};
})();

(node as any).hash = "a86d64f097807d5874497c9779fac963";

export default node;
