import React, { Suspense } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import { AppPushCreateDialog_appPushCreateMutation } from '../../../relay/__generated__/AppPushCreateDialog_appPushCreateMutation.graphql';
import { AppPushCreateDialog_appPushPreviewQuery } from '../../../relay/__generated__/AppPushCreateDialog_appPushPreviewQuery.graphql';
import { formatISO } from '../../../utils/date';
import { isNullable } from '../../../utils/is';
import { numberWithCommas } from '../../../utils/number';
import AppDeepLinkSelectField from '../../appDeepLink/AppDeepLinkSelectField';
import Button from '../../core/Button';
import DataTable from '../../core/DataTable';
import DatetimeField from '../../core/DatetimeField';
import Dialog, { DialogProps } from '../../core/Dialog';
import DialogButton from '../../core/DialogButton';
import EmptyState from '../../core/EmptyState';
import ErrorBoundary from '../../core/ErrorBoundary';
import ErrorState from '../../core/ErrorState';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import SQLEditorField from '../../core/SQLEditorField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';
import UploadField from '../../core/UploadField';
import View from '../../core/View';
import AppPushPreview from '../AppPushPreview';

const appPushPreviewForAppPushCreateDialog = graphql`
  query AppPushCreateDialog_appPushPreviewQuery(
    $csvFile: Upload = null
    $pushBody: String!
    $pushTitle: String!
    $query: String!
  ) {
    appPushPreview(pushBody: $pushBody, pushTitle: $pushTitle, csvFile: $csvFile, query: $query) {
      targetCount
      rows {
        id
        title
        token
        body
      }
    }
  }
`;

type Props = {} & DialogProps &
  Partial<
    Pick<
      MutationFormikProps<AppPushCreateDialog_appPushCreateMutation>,
      'config' | 'onSubmit' | 'connections' | 'initialValues'
    >
  >;

const AppPushCreateDialog = ({ config, onSubmit, connections, initialValues, ...props }: Props) => {
  const defaultValues: MutationFormikProps<AppPushCreateDialog_appPushCreateMutation>['initialValues'] = {
    title: '',
    description: null,
    startAt: formatISO(Date.now()),
    pushTitle: '',
    pushBody: '',
    deepLinkParams: null,
    deepLinkUrl: '',
    query: '',
    csvFile: null,
  };

  const [previewQueryReference, loadPreviewQuery] = useQueryLoader<AppPushCreateDialog_appPushPreviewQuery>(
    appPushPreviewForAppPushCreateDialog,
  );

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>푸시 알림 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<AppPushCreateDialog_appPushCreateMutation>
        mutation={graphql`
          mutation AppPushCreateDialog_appPushCreateMutation($input: AppPushCreateInput!) {
            appPushCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{ ...defaultValues, ...initialValues }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
        enableReinitialize={false}
      >
        {({ values, submitForm }, { scrollContainerRef, nonFieldErrorRef }) => {
          return (
            <>
              <Dialog.Body ref={scrollContainerRef}>
                <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
                <FormLayout>
                  <TextField label={'제목'} required placeholder={'제목 입력'} name={'title'} autoComplete={'off'} />
                  <TextareaField label={'설명'} placeholder={'설명 입력'} name={'description'} autoComplete={'off'} />
                  <View>
                    <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>푸시 알림</Text>
                  </View>
                  <DatetimeField
                    label={'시작일'}
                    required
                    name={'startAt'}
                    datePickerProps={{ placeholder: '시작일 선택' }}
                  />
                  <TextareaField
                    label={'제목'}
                    name={'pushTitle'}
                    placeholder={'푸시 제목 입력'}
                    required
                    autoComplete={'off'}
                  />
                  <TextareaField
                    label={'본문'}
                    name={'pushBody'}
                    placeholder={'푸시 본문 입력'}
                    required
                    autoComplete={'off'}
                  />
                  <Grid gapX={4} sx={{ alignItems: 'flex-end' }}>
                    <Grid.Unit size={1 / 2}>
                      <Suspense fallback={<Spinner size={'small'} />}>
                        <AppDeepLinkSelectField label={'딥링크'} name={'deepLinkUrl'} placeholder={'쿼리 선택'} />
                      </Suspense>
                    </Grid.Unit>
                    <Grid.Unit size={1 / 2}>
                      <TextField
                        label={'딥링크 파라미터'}
                        labelConfig={{ visuallyHidden: true }}
                        name={'deepLinkParams'}
                        leadingVisual={'?'}
                        autoComplete={'off'}
                        placeholder={'파라미터 입력'}
                      />
                    </Grid.Unit>
                  </Grid>
                  <SQLEditorField label={'SQL'} name={'query'} height={200} required />
                  <UploadField
                    label={'CSV'}
                    name={'csvFile'}
                    placeholder={'CSV를 선택하세요'}
                    disabled={values.query.indexOf('{user_id_list}') === -1}
                    accept={'.csv'}
                    caption={'* {user_id_list} 입력 시, CSV 파일을 업로드해주세요'}
                  />
                </FormLayout>
              </Dialog.Body>
              <Dialog.Footer>
                <DialogButton
                  variant={'primary'}
                  size={'large'}
                  renderDialog={({ isOpen, closeDialog }) => (
                    <Dialog isOpen={isOpen} onDismiss={closeDialog} wide>
                      <Dialog.Header>
                        <Text fontSize={3} fontWeight={'bold'}>
                          생성하기
                        </Text>
                      </Dialog.Header>
                      {previewQueryReference ? (
                        <Suspense
                          fallback={
                            <View sx={{ padding: 5 }}>
                              <Spinner />
                            </View>
                          }
                        >
                          <ErrorBoundary
                            key={previewQueryReference.fetchKey}
                            fallback={({ parsedGraphQLError }) => {
                              return (
                                <View sx={{ padding: 5 }}>
                                  <ErrorState
                                    description={
                                      parsedGraphQLError?.[0].message || '작성된 SQL과 CSV 파일을 한 번 더 확인해주세요'
                                    }
                                  />
                                </View>
                              );
                            }}
                          >
                            <PreloadedQueryRenderer
                              query={appPushPreviewForAppPushCreateDialog}
                              queryReference={previewQueryReference}
                            >
                              {({ appPushPreview }) => {
                                return (
                                  <>
                                    <Dialog.Body>
                                      <Text fontSize={1} fontWeight={'bold'}>
                                        {`약 ${
                                          !isNullable(appPushPreview.targetCount)
                                            ? numberWithCommas(appPushPreview.targetCount)
                                            : '-'
                                        }명 발송 예정`}
                                      </Text>
                                      <View sx={{ marginTop: 3 }}>
                                        <AppPushPreview
                                          title={appPushPreview.rows[0]?.title || values.pushTitle}
                                          body={appPushPreview.rows[0]?.body || values.pushBody}
                                        />
                                      </View>
                                      <View sx={{ marginTop: 5 }}>
                                        <Text fontSize={1} fontWeight={'bold'}>
                                          미리보기
                                        </Text>
                                        <View sx={{ marginTop: 3 }}>
                                          <DataTable
                                            columns={[
                                              {
                                                field: 'token',
                                                title: 'token',
                                                renderValue: ({ token }) => <Text fontSize={1}>{token}</Text>,
                                                width: 245,
                                              },
                                              {
                                                field: 'title',
                                                title: 'title',
                                                renderValue: ({ title }) =>
                                                  title ? <Text fontSize={1}>{title}</Text> : '-',
                                                width: 285,
                                              },
                                              {
                                                field: 'body',
                                                title: 'body',
                                                renderValue: ({ body }) =>
                                                  body ? (
                                                    <Text fontSize={1} whiteSpace={'pre-wrap'}>
                                                      {body}
                                                    </Text>
                                                  ) : (
                                                    '-'
                                                  ),
                                                width: 285,
                                              },
                                            ]}
                                            rows={appPushPreview.rows}
                                            emptyState={<EmptyState title={'입력된 푸시 알림이 없어요'} />}
                                          />
                                        </View>
                                      </View>
                                    </Dialog.Body>
                                    <Dialog.Footer>
                                      <Button
                                        variant={'primary'}
                                        size={'large'}
                                        onClick={() => {
                                          submitForm();
                                          closeDialog();
                                        }}
                                      >
                                        생성하기
                                      </Button>
                                    </Dialog.Footer>
                                  </>
                                );
                              }}
                            </PreloadedQueryRenderer>
                          </ErrorBoundary>
                        </Suspense>
                      ) : null}
                    </Dialog>
                  )}
                  onClick={() => {
                    const { csvFile, pushBody, pushTitle, query } = values;
                    loadPreviewQuery({
                      csvFile,
                      pushBody,
                      pushTitle,
                      query,
                    });
                  }}
                  disabled={!values.query.trim()}
                >
                  생성하기
                </DialogButton>
              </Dialog.Footer>
            </>
          );
        }}
      </MutationFormik>
    </Dialog>
  );
};

export default AppPushCreateDialog;
