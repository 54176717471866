import { graphql } from 'react-relay';

import { StuffPaginator_query$key } from '../../../relay/__generated__/StuffPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const StuffPaginator_query = graphql`
  fragment StuffPaginator_query on Query
  @argumentDefinitions(
    filters: { type: StuffFilter }
    order: { type: StuffOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "StuffPaginatorRefreshQuery") {
    stuffs(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "StuffPaginator_query_stuffs") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<StuffPaginator_query$key>, 'fragment'>;

const StuffPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={StuffPaginator_query} {...props} />;

export default StuffPaginator;
