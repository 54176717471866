/**
 * @generated SignedSource<<8903883e9d2b922332b933aa8101d6d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GeneratedProblemsDeleteInput = {
  generatorId: string;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation$variables = {
  input: GeneratedProblemsDeleteInput;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation$data = {
  readonly generatedProblemsDelete: ReadonlyArray<{
    readonly id: string;
  }>;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation = {
  response: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation$data;
  variables: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GeneratedProblem",
    "kind": "LinkedField",
    "name": "generatedProblemsDelete",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8828ff9ab6b696a04b6d48537ce462c2",
    "id": null,
    "metadata": {},
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation(\n  $input: GeneratedProblemsDeleteInput!\n) {\n  generatedProblemsDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "986665ae3128f416ed46e3c0d8db5eb0";

export default node;
