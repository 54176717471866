/**
 * @generated SignedSource<<0bf27fcf5dcfc88f41b13c671ed19bc9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MATInternalPremiumApplyHistoryDeleteInput = {
  id: string;
};
export type PremiumDeleteConfirmButton_premiumDeleteMutation$variables = {
  input: MATInternalPremiumApplyHistoryDeleteInput;
};
export type PremiumDeleteConfirmButton_premiumDeleteMutation$data = {
  readonly internalPremiumApplyHistoryDelete: boolean;
};
export type PremiumDeleteConfirmButton_premiumDeleteMutation = {
  response: PremiumDeleteConfirmButton_premiumDeleteMutation$data;
  variables: PremiumDeleteConfirmButton_premiumDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "internalPremiumApplyHistoryDelete",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PremiumDeleteConfirmButton_premiumDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PremiumDeleteConfirmButton_premiumDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a57ec0ea63bf70a81ff6bb199ee86835",
    "id": null,
    "metadata": {},
    "name": "PremiumDeleteConfirmButton_premiumDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation PremiumDeleteConfirmButton_premiumDeleteMutation(\n  $input: MATInternalPremiumApplyHistoryDeleteInput!\n) {\n  internalPremiumApplyHistoryDelete(input: $input)\n}\n"
  }
};
})();

(node as any).hash = "16b065b10895eab99f1adfaf371dcd81";

export default node;
