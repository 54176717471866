import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  UnitBSelectField_unitBsQuery,
  UnitBSelectField_unitBsQuery$variables,
} from '../../../relay/__generated__/UnitBSelectField_unitBsQuery.graphql';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const unitBsForUnitBSelectField = graphql`
  query UnitBSelectField_unitBsQuery($filters: UnitBFilter, $order: UnitBOrder) {
    unitBs(filters: $filters, order: $order) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {
  variables?: UnitBSelectField_unitBsQuery$variables;
} & Omit<SelectFieldProps, 'options'>;

const UnitBSelectField = ({ variables, ...props }: Props) => {
  const [{ unitBs }] = useLazyLoadQuery<UnitBSelectField_unitBsQuery>(unitBsForUnitBSelectField, { ...variables });

  return <SelectField options={unitBs.edges.map(({ node: { id, title } }) => ({ id, text: title }))} {...props} />;
};

export default UnitBSelectField;
