/**
 * @generated SignedSource<<c2fa42a67ba66c669c28f948f1d44e25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TypingAutomationHistoryTimelineItem_typingAutomationHistory$data = {
  readonly created: any;
  readonly script: {
    readonly id: string;
    readonly title: string;
  };
  readonly " $fragmentType": "TypingAutomationHistoryTimelineItem_typingAutomationHistory";
};
export type TypingAutomationHistoryTimelineItem_typingAutomationHistory$key = {
  readonly " $data"?: TypingAutomationHistoryTimelineItem_typingAutomationHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"TypingAutomationHistoryTimelineItem_typingAutomationHistory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TypingAutomationHistoryTimelineItem_typingAutomationHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TypingAutomationScript",
      "kind": "LinkedField",
      "name": "script",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    }
  ],
  "type": "TypingAutomationHistory",
  "abstractKey": null
};

(node as any).hash = "fc041fb7dd4de253b7f92752d955e0fd";

export default node;
