import React, { Suspense, useEffect } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';

import { ProductUpdateDialog_product$key } from '../../../relay/__generated__/ProductUpdateDialog_product.graphql';
import { ProductUpdateDialog_productPriceQuery } from '../../../relay/__generated__/ProductUpdateDialog_productPriceQuery.graphql';
import { ProductUpdateDialog_productUpdateMutation } from '../../../relay/__generated__/ProductUpdateDialog_productUpdateMutation.graphql';
import { numberWithCommas, numberWithPercent } from '../../../utils/number';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairSearchOverlayTokenField from '../../core/EnumPairSearchOverlayTokenField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextInput from '../../core/TextInput';
import View from '../../core/View';
import StoreStuffPaginatorSearchOverlayField from '../../storeStuff/StoreStuffPaginatorSearchOverlayField';

const ProductUpdateDialog_product = graphql`
  fragment ProductUpdateDialog_product on Product {
    id
    title
    storeStuff {
      id
    }
    showTypes
    price
    discountPrice
    additionalDiscountAmount
  }
`;

const productPriceForProductUpdateDialog = graphql`
  query ProductUpdateDialog_productPriceQuery($discountPrice: Int!, $price: Int!, $storeStuff: ID!) {
    productPrice(discountPrice: $discountPrice, price: $price, storeStuff: $storeStuff) {
      discountPercentage
      monthlyPrice
    }
  }
`;

type Props = { product: ProductUpdateDialog_product$key } & DialogProps &
  Pick<MutationFormikProps<ProductUpdateDialog_productUpdateMutation>, 'connections' | 'config' | 'onSubmit'>;

const ProductUpdateDialog = ({ product, connections, config, onSubmit, isOpen, ...props }: Props) => {
  const { id, title, storeStuff, price, discountPrice, showTypes, additionalDiscountAmount } = useFragment(
    ProductUpdateDialog_product,
    product,
  );
  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<ProductUpdateDialog_productPriceQuery>(
    productPriceForProductUpdateDialog,
  );

  useEffect(() => {
    if (isOpen && storeStuff) loadQuery({ storeStuff: storeStuff?.id, price, discountPrice });
    else if (!isOpen) disposeQuery();
  }, [isOpen]);

  const defaultProductPriceGridUnits = (
    <>
      <Grid.Unit size={[1 / 2, 1 / 4]}>
        <Text fontSize={1} sx={{ display: 'block' }}>
          할인율
        </Text>
        <TextInput value={'-'} readOnly block size={'large'} sx={{ marginTop: 1 }} />
      </Grid.Unit>
      <Grid.Unit size={[1 / 2, 1 / 4]}>
        <Text fontSize={1} sx={{ display: 'block' }}>
          월가
        </Text>
        <TextInput value={'-'} readOnly block size={'large'} sx={{ marginTop: 1 }} />
      </Grid.Unit>
    </>
  );

  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>수정하기</Text>
      </Dialog.Header>
      <MutationFormik<ProductUpdateDialog_productUpdateMutation>
        mutation={graphql`
          mutation ProductUpdateDialog_productUpdateMutation($input: ProductUpdateInput!) {
            productUpdate(input: $input) {
              id
              productPrice {
                monthlyPrice
                discountPercentage
              }
              ...ProductUpdateDialog_product
            }
          }
        `}
        initialValues={{
          id,
          title,
          storeStuff: storeStuff?.id,
          showTypes,
          price,
          discountPrice,
          additionalDiscountAmount: additionalDiscountAmount || null,
        }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {(
          { values: { price, discountPrice, storeStuff }, setFieldValue, submitForm, dirty },
          { scrollContainerRef, nonFieldErrorRef },
        ) => {
          return (
            <>
              <Dialog.Body ref={scrollContainerRef}>
                <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 4 }} />
                <FormLayout>
                  <View>
                    <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>판매 상품 정보</Text>
                  </View>
                  <TextField
                    label={'판매 상품명'}
                    required
                    size={'large'}
                    placeholder={'상품명 + 기간 입력 (e.g. 환급형 2024 수능권)'}
                    caption={'띄어쓰기 포함 최대 10자'}
                    name={'title'}
                    autoComplete={'off'}
                  />
                  <EnumPairSearchOverlayTokenField
                    typename={'ProductShowTypeEnum'}
                    label={'타입'}
                    placeholder={'타입 선택'}
                    name={'showTypes'}
                    size={'large'}
                    required
                  />
                  <StoreStuffPaginatorSearchOverlayField
                    label={'상품'}
                    name={'storeStuff'}
                    size={'large'}
                    placeholder={'상품 선택'}
                    onChange={({ id }) => {
                      setFieldValue('storeStuff', id);
                      if (discountPrice && price) loadQuery({ discountPrice, price, storeStuff: id });
                    }}
                    variables={{
                      order: {
                        created: 'DESC',
                      },
                      filters: {
                        isValid: true,
                      },
                    }}
                    required
                  />
                  <Grid gapX={2} gapY={2} sx={{ alignItems: 'flex-start' }}>
                    <Grid.Unit size={[1 / 2, 1 / 4]}>
                      <NumberField
                        label={'정가'}
                        debounce
                        required
                        size={'large'}
                        placeholder={'정가 입력'}
                        name={'price'}
                        onChange={(e) => {
                          const value = e.target.value !== '' ? Number(e.target.value) : null;
                          setFieldValue('price', value);
                          if (storeStuff && value && discountPrice)
                            loadQuery({ discountPrice, storeStuff, price: value });
                        }}
                        min={0}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={[1 / 2, 1 / 4]}>
                      <NumberField
                        label={'판매가'}
                        debounce
                        required
                        size={'large'}
                        placeholder={'판매가 입력'}
                        name={'discountPrice'}
                        onChange={(e) => {
                          const value = e.target.value !== '' ? Number(e.target.value) : null;
                          setFieldValue('discountPrice', value);
                          if (storeStuff && price && value) loadQuery({ storeStuff, price, discountPrice: value });
                        }}
                        min={0}
                      />
                    </Grid.Unit>
                    {queryReference && discountPrice && price ? (
                      <Suspense fallback={defaultProductPriceGridUnits}>
                        <PreloadedQueryRenderer<ProductUpdateDialog_productPriceQuery>
                          query={productPriceForProductUpdateDialog}
                          queryReference={queryReference}
                        >
                          {({ productPrice: { discountPercentage, monthlyPrice } }) => (
                            <>
                              <Grid.Unit size={[1 / 2, 1 / 4]}>
                                <Text fontSize={1} sx={{ display: 'block' }}>
                                  할인율
                                </Text>
                                <TextInput
                                  value={numberWithPercent(discountPercentage)}
                                  readOnly
                                  block
                                  size={'large'}
                                  sx={{ marginTop: 1 }}
                                />
                              </Grid.Unit>
                              <Grid.Unit size={[1 / 2, 1 / 4]}>
                                <Text fontSize={1} sx={{ display: 'block' }}>
                                  월가
                                </Text>
                                <TextInput
                                  value={numberWithCommas(monthlyPrice)}
                                  readOnly
                                  block
                                  size={'large'}
                                  sx={{ marginTop: 1 }}
                                />
                              </Grid.Unit>
                            </>
                          )}
                        </PreloadedQueryRenderer>
                      </Suspense>
                    ) : (
                      defaultProductPriceGridUnits
                    )}
                  </Grid>
                  <NumberField
                    label={'콜 추가 할인'}
                    size={'large'}
                    placeholder={'콜 추가 할인 입력'}
                    caption={'개인 결제 링크 상품에만 적용돼요'}
                    name={'additionalDiscountAmount'}
                    autoComplete={'off'}
                  />
                </FormLayout>
              </Dialog.Body>
              <Dialog.Footer>
                <Button variant={'primary'} size={'large'} disabled={!dirty} onClick={() => submitForm()}>
                  수정하기
                </Button>
              </Dialog.Footer>
            </>
          );
        }}
      </MutationFormik>
    </Dialog>
  );
};

export default ProductUpdateDialog;
