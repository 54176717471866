import { FieldConfig, useField } from 'formik';
import { debounce } from 'lodash-es';
import { ChangeEventHandler, ComponentProps, useEffect, useRef } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import NumberInput, { NumberInputProps } from '../NumberInput';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
  debounce?: boolean;
} & Omit<NumberInputProps, 'type'> &
  Pick<FieldConfig, 'validate'>;

const NumberField = ({
  label,
  labelConfig,
  caption,
  disabled: propDisabled,
  required,
  name = '',
  onChange: propOnChange,
  value: propValue,
  debounce: propDebounce = false,
  validate,
  ...props
}: Props) => {
  const inputId = `number_field_for_${label}`;

  const [{ value: baseValue, ...restFieldProps }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 입력해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();

  const value = isNullable(propValue) ? baseValue : propValue;
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value !== '' ? Number(e.target.value) : null;
    setValue(value);
  };

  const handleChange: NumberInputProps['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else onChange(...arg);
  };

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value ?? '';
    }
  }, [value]);

  const disabled = propDisabled || isSubmitting;

  return (
    <FormControl disabled={disabled} required={required}>
      <FormControl.Label {...labelConfig} htmlFor={inputId}>
        {label}
      </FormControl.Label>
      <NumberInput
        {...restFieldProps}
        ref={inputRef}
        id={inputId}
        block
        onChange={propDebounce ? debounce(handleChange, 500) : handleChange}
        validationStatus={error ? 'error' : undefined}
        disabled={disabled}
        {...props}
      />
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default Object.assign(NumberField, { Action: NumberInput.Action, DialogAction: NumberInput.DialogAction });
export type { Props as NumberFieldProps };
