import html2canvas, { Options } from 'html2canvas';

import { getUploadMetaData, uploadGeneral } from '../../utils/file';
import { isNullable } from '../../utils/is';

const useCaptureHtml = (options: Partial<Options>) => {
  const capture = async (container: HTMLElement, additionalOptions?: Partial<Options>) => {
    return html2canvas(container, {
      allowTaint: true,
      useCORS: true,
      ...options,
      ...additionalOptions,
      onclone: (document, element) => {
        options?.onclone?.(document, element);
        additionalOptions?.onclone?.(document, element);
        fixLargeBracket();
        fixCancel();

        function fixLargeBracket() {
          const original = container.querySelectorAll(
            '.katex .mopen:not(:has(.msupsub)) svg, .mclose:not(:has(.msupsub)) svg',
          );
          const copiedSvgs = element.querySelectorAll(
            '.katex .mopen:not(:has(.msupsub)) svg, .mclose:not(:has(.msupsub)) svg',
          );
          copiedSvgs.forEach((svg, i) => {
            // get original attribute
            const attribute = original.item(i).getAttribute('style') ?? '';
            const svgHeight = svg.getBoundingClientRect().height;

            const wrappedSvg = wrapSvgWithSpan(svg);
            replaceElement(svg, wrappedSvg);

            // 큰 중괄호의 미세한 끊김 보정 값
            const delta = 1.3;
            const scale = (svgHeight + delta) / svgHeight;

            wrappedSvg.setAttribute('style', attribute + `transform-origin: 0 0; transform: scaleY(${scale});`);
          });
        }

        function fixCancel() {
          const clonedSvgs = element.querySelectorAll('.katex svg:has(line)');
          clonedSvgs.forEach((svg) => {
            replaceElement(svg, wrapSvgWithSpan(svg));
          });
        }

        function replaceElement(src: Element, dist: Element) {
          const parentElement = src.parentElement;
          if (!parentElement) return;

          parentElement.replaceChild(dist, src);
        }

        function wrapSvgWithSpan(_svg: Element) {
          const svg = _svg.cloneNode(true) as HTMLElement;
          const span = document.createElement('span');
          const style = svg.getAttribute('style');
          svg.removeAttribute('style');
          span.appendChild(svg);
          span.setAttribute('style', style ?? '');
          return span;
        }
      },
    });
  };

  const downloadImageFromHtml = (container: HTMLElement, fileName?: string, additionalOptions?: Partial<Options>) =>
    capture(container, additionalOptions).then((canvas) => {
      const url = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = fileName || 'image';
      link.href = url;
      link.click();
    });

  const uploadImageFromHtml = async (
    container: HTMLElement,
    fileName: string,
    additionalOptions?: Partial<Options>,
  ) => {
    const canvas = await capture(container, additionalOptions);

    const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve));

    if (isNullable(blob)) {
      return Promise.reject('blob is null');
    }
    const { type: fileType, size: fileSize } = blob;

    const [, extension] = fileType.split('/');

    const metaData = await getUploadMetaData({
      fileName: `${fileName}.${extension}`,
      fileSize,
    });
    if (!metaData) {
      return Promise.reject('metaData is null');
    }

    const isMultiPartUploadRequired = 'upload_id' in metaData && 'part_metadata' in metaData;
    if (isMultiPartUploadRequired) {
      return Promise.reject('too large image');
    }

    const response = await uploadGeneral(blob, metaData);
    if (response.ok) {
      return Promise.resolve({ blob, metaData });
    } else {
      return Promise.reject(`general upload error: ${response.status}`);
    }
  };

  return { capture, downloadImageFromHtml, uploadImageFromHtml };
};

export default useCaptureHtml;
