/**
 * @generated SignedSource<<51426f3ca67af1b867334552406ffa8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProblemGeneratorConnectionDataTable_problemGeneratorConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly activeProblemsCount: number;
      readonly assignedTo: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly changed: any | null;
      readonly id: string;
      readonly originalTask: {
        readonly id: string;
        readonly sequence: number;
        readonly unitA: {
          readonly id: string;
          readonly title: string;
        } | null;
        readonly unitD: {
          readonly description: string | null;
          readonly id: string;
        } | null;
      };
      readonly publishedProblemsCount: number;
      readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorStatusLabel_problemGenerator">;
    };
  }>;
  readonly " $fragmentType": "ProblemGeneratorConnectionDataTable_problemGeneratorConnection";
};
export type ProblemGeneratorConnectionDataTable_problemGeneratorConnection$key = {
  readonly " $data"?: ProblemGeneratorConnectionDataTable_problemGeneratorConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProblemGeneratorConnectionDataTable_problemGeneratorConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProblemGeneratorConnectionDataTable_problemGeneratorConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProblemGeneratorEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProblemGenerator",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProblemGeneratorStatusLabel_problemGenerator"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publishedProblemsCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "activeProblemsCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Task",
              "kind": "LinkedField",
              "name": "originalTask",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sequence",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UnitA",
                  "kind": "LinkedField",
                  "name": "unitA",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "UnitD",
                  "kind": "LinkedField",
                  "name": "unitD",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "description",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "changed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "assignedTo",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProblemGeneratorConnection",
  "abstractKey": null
};
})();

(node as any).hash = "6bd23b6397ec670dcd1b9a966d5505fd";

export default node;
