import { Formik as BaseFormik, FormikConfig, FormikValues, FormikHelpers, FormikProps } from 'formik';

type Props<Values = {}, ExtraProps = {}> = FormikConfig<Values> & ExtraProps;

const Formik = <Values extends FormikValues = FormikValues, ExtraProps = {}>({
  ...props
}: FormikConfig<Values> & ExtraProps) => {
  return <BaseFormik<Values> validateOnChange={false} validateOnBlur={false} validateOnMount={false} {...props} />;
};

export default Formik;
export type { Props as FormikConfig, FormikValues, FormikHelpers, FormikProps };
