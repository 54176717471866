/**
 * @generated SignedSource<<aafc96c7206e8e2eb6d79625dd633a67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserDeleteInput = {
  id: string;
};
export type permission_userDeleteMutation$variables = {
  input: UserDeleteInput;
};
export type permission_userDeleteMutation$data = {
  readonly userDelete: {
    readonly id: string;
  };
};
export type permission_userDeleteMutation = {
  response: permission_userDeleteMutation$data;
  variables: permission_userDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "userDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "permission_userDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "permission_userDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc175aa5b5744d3751b75f8969dbf042",
    "id": null,
    "metadata": {},
    "name": "permission_userDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation permission_userDeleteMutation(\n  $input: UserDeleteInput!\n) {\n  userDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "be5cb2be7a6b8b6ad1ae1103aa9daafc";

export default node;
