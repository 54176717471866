import { EyeClosedIcon, FileAddedIcon, RocketIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { WorkbookHistoryTimelineItem_workbookHistory$key } from '../../../relay/__generated__/WorkbookHistoryTimelineItem_workbookHistory.graphql';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Label from '../../core/Label';
import Timeline from '../../core/Timeline';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';

const WorkbookHistoryTimelineItem_workbookHistory = graphql`
  fragment WorkbookHistoryTimelineItem_workbookHistory on WorkbookHistory {
    id
    createdBy {
      id
      ...UserAvatarText_user
    }
    created
    type
  }
`;

type Props = { workbookHistory: WorkbookHistoryTimelineItem_workbookHistory$key };

const WorkbookHistoryTimelineItem = ({ workbookHistory }: Props) => {
  const { created, createdBy, type } = useFragment(WorkbookHistoryTimelineItem_workbookHistory, workbookHistory);
  return (
    <Timeline.Item>
      <Timeline.Badge>
        {type === 'created' ? (
          <FileAddedIcon />
        ) : type === 'deactivated' ? (
          <EyeClosedIcon />
        ) : type === 'published' ? (
          <RocketIcon />
        ) : null}
      </Timeline.Badge>
      <Timeline.Body>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Grid.Unit size={'max'}>
              <View sx={{ display: 'flex', flexDirection: 'column' }}>
                <UserAvatarText user={createdBy} sx={{ fontWeight: 'bold', color: 'fg.muted' }} />
                <View sx={{ marginTop: 2 }}>
                  <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
                </View>
              </View>
            </Grid.Unit>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Label
              variant={
                type === 'created'
                  ? 'secondary'
                  : type === 'published'
                  ? 'success'
                  : type === 'deactivated'
                  ? 'secondary'
                  : 'default'
              }
            >
              <EnumPair typename={'WorkbookHistoryTypeEnum'}>{type}</EnumPair>
            </Label>
          </Grid.Unit>
        </Grid>
      </Timeline.Body>
    </Timeline.Item>
  );
};

export default WorkbookHistoryTimelineItem;
