import { clipboard } from '@teamturing/utils';
import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import {
  MATStudentConnectionDataTable_matStudentConnection$data,
  MATStudentConnectionDataTable_matStudentConnection$key,
} from '../../../relay/__generated__/MATStudentConnectionDataTable_matStudentConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import Tooltip from '../../core/Tooltip';

const MATStudentConnectionDataTable_matStudentConnection = graphql`
  fragment MATStudentConnectionDataTable_matStudentConnection on MATStudentConnection {
    edges {
      node {
        id
        loginId
        studentType
        userId: sequence
        friendTag
        name
      }
    }
  }
`;

type Props = {
  matStudentConnection: MATStudentConnectionDataTable_matStudentConnection$key;
} & Omit<
  DataTableProps<MATStudentConnectionDataTable_matStudentConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const MATStudentConnectionDataTable = ({ matStudentConnection, ...props }: Props) => {
  const { edges } = useFragment(MATStudentConnectionDataTable_matStudentConnection, matStudentConnection);
  const { toast } = useToast();

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'loginId',
          title: 'ID',
          renderValue: ({ loginId }) => loginId,
          width: 284,
        },
        {
          field: 'studentType',
          title: '학년',
          renderValue: ({ studentType }) => (
            <Suspense fallback={'-'}>
              {studentType ? <EnumPair typename={'MATStudentTypeEnum'}>{studentType}</EnumPair> : null}
            </Suspense>
          ),
          width: 96,
        },
        {
          field: 'name',
          title: '학생 이름',
          renderValue: ({ name }) => name,
          width: 100,
        },
        {
          field: 'userId',
          title: '유저 ID',
          renderValue: ({ userId }) => (
            <Tooltip
              text={'클릭해서 복사하기'}
              onClick={async () => {
                const isSuccess = await clipboard.writeText(`${userId}`);
                if (isSuccess) {
                  toast('유저 아이디를 복사했어요.', 'success');
                }
              }}
            >
              <Text sx={{ ':hover': { textDecoration: 'underline', cursor: 'pointer' } }}>{userId}</Text>
            </Tooltip>
          ),
          width: 96,
        },
        {
          field: 'friendTag',
          title: '친구 태그',
          renderValue: ({ friendTag }) => (
            <Tooltip
              text={'클릭해서 복사하기'}
              onClick={async () => {
                const isSuccess = await clipboard.writeText(friendTag);
                if (isSuccess) {
                  toast('친구 태그를 복사했어요.', 'success');
                }
              }}
            >
              <Text sx={{ ':hover': { textDecoration: 'underline', cursor: 'pointer' } }}>{friendTag}</Text>
            </Tooltip>
          ),
          width: 96,
        },
      ]}
      {...props}
    />
  );
};

export default MATStudentConnectionDataTable;
