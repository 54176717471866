import { ChangeEventHandler, forwardRef, PropsWithChildren, Ref, useState } from 'react';

import Grid from '../../core/Grid';
import Katex from '../../core/Katex';
import TextInput, { TextInputProps } from '../../core/TextInput';
import View from '../../core/View';

type Props = { value?: string } & Omit<TextInputProps, 'value' | 'sx'>;

const KatexTextInput = ({ value: propValue, onChange, ...props }: Props, ref: Ref<HTMLInputElement>) => {
  const [value, setValue] = useState<string>(propValue || '');

  const handleChangeTitle: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(e.target.value);
    onChange?.(e);
  };

  return (
    <Grid gapX={2}>
      <Grid.Unit size={1 / 2}>
        <TextInput
          ref={ref}
          value={value}
          onChange={handleChangeTitle}
          autoComplete={'off'}
          sx={{ height: '100%' }}
          block
          {...props}
        />
      </Grid.Unit>
      <Grid.Unit size={1 / 2}>
        <KatexWrapper>
          <Katex>{value || ''}</Katex>
        </KatexWrapper>
      </Grid.Unit>
    </Grid>
  );
};

const KatexWrapper = ({ children }: PropsWithChildren<{}>) => (
  <View
    sx={{
      height: '100%',
      lineHeight: 1,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'border.default',
      borderRadius: 2,
      overflow: 'scroll',
    }}
  >
    {children}
  </View>
);
export default forwardRef(KatexTextInput);
export type { Props as KatexTextInputProps };
