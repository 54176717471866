/**
 * @generated SignedSource<<f8c2cbb79ef9e75a5992bd1042bb0780>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductEventStatusEnum = "finished" | "inactive" | "ongoing" | "pending" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductEventStatusLabel_productEvent$data = {
  readonly status: ProductEventStatusEnum;
  readonly " $fragmentType": "ProductEventStatusLabel_productEvent";
};
export type ProductEventStatusLabel_productEvent$key = {
  readonly " $data"?: ProductEventStatusLabel_productEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductEventStatusLabel_productEvent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductEventStatusLabel_productEvent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "ProductEvent",
  "abstractKey": null
};

(node as any).hash = "68ee58bee6df9cb82132e63960304c09";

export default node;
