/**
 * @generated SignedSource<<fcb77a7928a45933624f516a3fb2f8a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NoticeUpdateInput = {
  description?: string | null;
  id: string;
  title?: string | null;
};
export type NoticeUpdateDialog_noticeUpdateMutation$variables = {
  input: NoticeUpdateInput;
};
export type NoticeUpdateDialog_noticeUpdateMutation$data = {
  readonly noticeUpdate: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"NoticeItem_notice">;
  };
};
export type NoticeUpdateDialog_noticeUpdateMutation = {
  response: NoticeUpdateDialog_noticeUpdateMutation$data;
  variables: NoticeUpdateDialog_noticeUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NoticeUpdateDialog_noticeUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Notice",
        "kind": "LinkedField",
        "name": "noticeUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NoticeItem_notice"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NoticeUpdateDialog_noticeUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Notice",
        "kind": "LinkedField",
        "name": "noticeUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "modified",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "249a39ea3260c989b182cb19a6f5081e",
    "id": null,
    "metadata": {},
    "name": "NoticeUpdateDialog_noticeUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation NoticeUpdateDialog_noticeUpdateMutation(\n  $input: NoticeUpdateInput!\n) {\n  noticeUpdate(input: $input) {\n    id\n    ...NoticeItem_notice\n  }\n}\n\nfragment NoticeItem_notice on Notice {\n  id\n  created\n  createdBy {\n    id\n    picture\n    name\n    group {\n      id\n      title\n    }\n  }\n  modified\n  modifiedBy {\n    id\n  }\n  title\n  description\n}\n"
  }
};
})();

(node as any).hash = "03d72d54aa209b0f6dbef4d2b45e6966";

export default node;
