/**
 * @generated SignedSource<<e7645dd6ed53ec50099bd3f8c8366169>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type QuizStatusEnum = "error_report" | "published" | "review" | "%future added value";
export type QuizFilter = {
  AND?: QuizFilter | null;
  DISTINCT?: boolean | null;
  NOT?: QuizFilter | null;
  OR?: QuizFilter | null;
  answer_Exact?: string | null;
  answer_In?: ReadonlyArray<string> | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  sequence_Exact?: number | null;
  sequence_In?: ReadonlyArray<number> | null;
  status_Exact?: QuizStatusEnum | null;
  status_In?: ReadonlyArray<QuizStatusEnum> | null;
  unitAId_Exact?: string | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_Exact?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_Exact?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitDId_Exact?: string | null;
  unitDId_In?: ReadonlyArray<string> | null;
  unitDTitle_Contains?: string | null;
  unitId_Exact?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitTitle_Contains?: string | null;
};
export type QuizOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
  sequence?: Ordering | null;
};
export type QuizPaginatorRefreshQuery$variables = {
  after?: string | null;
  filters?: QuizFilter | null;
  first?: number | null;
  order?: QuizOrder | null;
};
export type QuizPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"QuizPaginator_query">;
};
export type QuizPaginatorRefreshQuery = {
  response: QuizPaginatorRefreshQuery$data;
  variables: QuizPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "QuizPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuizPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "QuizConnection",
        "kind": "LinkedField",
        "name": "quizzes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "QuizEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Quiz",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UnitD",
                    "kind": "LinkedField",
                    "name": "unitD",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "problem",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "solution",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tip",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filters",
          "order"
        ],
        "handle": "connection",
        "key": "QuizPaginator_query_quizzes",
        "kind": "LinkedHandle",
        "name": "quizzes"
      }
    ]
  },
  "params": {
    "cacheID": "208e7aa1f16c522eed03402e55b49ee1",
    "id": null,
    "metadata": {},
    "name": "QuizPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query QuizPaginatorRefreshQuery(\n  $after: String\n  $filters: QuizFilter\n  $first: Int\n  $order: QuizOrder\n) {\n  ...QuizPaginator_query_2rfEMg\n}\n\nfragment QuizPaginator_query_2rfEMg on Query {\n  quizzes(filters: $filters, order: $order, first: $first, after: $after) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        ...QuizQuizSetItem_quiz\n        __typename\n      }\n    }\n  }\n}\n\nfragment QuizQuizSetItem_quiz on Quiz {\n  sequence\n  unitD {\n    title\n    id\n  }\n  answer\n  problem\n  solution\n  tip\n}\n"
  }
};
})();

(node as any).hash = "7b6c81e2423da253f5d2237fef08f8a6";

export default node;
