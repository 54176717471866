import { isNullable } from '../../../../utils/is';
import { Monaco } from '../../Editor';

export type TypingShortcut = { label: string; text: string };
const defaultTypingShortcut: TypingShortcut[] = [
  {
    label: '선지',
    text: `[선지]
1. \${1}
2. \${2}
3. \${3}
4. \${4}
5. \${5}`,
  },
  {
    label: '박스',
    text: `[박스]
\${1}
[박스 끝]
`,
  },
  {
    label: '보기',
    text: `[보기]
\${1}
[보기 끝]
`,
  },
  {
    label: '들여쓰기',
    text: `[들여쓰기\${1}]
\${2}
[들여쓰기\${1}끝]
`,
  },
];

const emojiTypingShortCut: TypingShortcut[] = [
  {
    label: ':light-bulb:',
    text: '$\\char128161$',
  },
  {
    label: ':writing-hand:',
    text: '$\\char9997$',
  },
  {
    label: ':right-arrow:',
    text: '$\\char10132$',
  },
];

export function convertEnterToNewLine(text: string) {
  return text.replace(/<enter>/g, '\n');
}
export function convertNewLineToEnter(text: string) {
  return text.replace(/\n/g, '<enter>');
}

export function filterInvalidShortcuts(shortcuts: TypingShortcut[]): TypingShortcut[] {
  return shortcuts
    .map((shortcut) => {
      if (!shortcut?.label || !shortcut?.text) {
        return null;
      }
      return { label: shortcut.label, text: shortcut.text };
    })
    .filter((s) => !!s) as TypingShortcut[];
}

export function getCompletionItems(monaco: Monaco, shortcuts?: TypingShortcut[]) {
  // returning a static list of proposals, not even looking at the prefix (filtering is done by the Monaco editor),
  // here you could do a server side lookup
  const typingShortcut = !isNullable(shortcuts) ? shortcuts : [...defaultTypingShortcut, ...emojiTypingShortCut];

  const convertToCompletionItem = (label: string, text: string) => {
    return {
      label,
      kind: monaco.languages.CompletionItemKind.Text,
      insertText: text.replaceAll('\\', '\\\\'),
      insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
    };
  };
  return filterInvalidShortcuts(typingShortcut).map(({ label, text }) => convertToCompletionItem(label, text));
}
