/**
 * @generated SignedSource<<e411dcc930d06fa7ea5a316953edac9d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPushConnectionDataTable_appPushConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly destination: string | null;
      readonly id: string;
      readonly sequence: number;
      readonly startAt: any;
      readonly targetCount: number;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"AppPushStatusLabel_appPush">;
    };
  }>;
  readonly " $fragmentType": "AppPushConnectionDataTable_appPushConnection";
};
export type AppPushConnectionDataTable_appPushConnection$key = {
  readonly " $data"?: AppPushConnectionDataTable_appPushConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppPushConnectionDataTable_appPushConnection">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPushConnectionDataTable_appPushConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AppPushEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AppPush",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AppPushStatusLabel_appPush"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "destination",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "targetCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AppPushConnection",
  "abstractKey": null
};

(node as any).hash = "1d07c671314f1f6ced52d8a3b4522a43";

export default node;
