import { graphql } from 'react-relay';

import { MATTeacherWindowPaginator_query$key } from '../../../relay/__generated__/MATTeacherWindowPaginator_query.graphql';
import FragmentWindowPaginator, { FragmentWindowPaginatorProps } from '../../core/FragmentWindowPaginator';
import Pagination from '../../page/Pagination';

const MATTeacherWindowPaginator_query = graphql`
  fragment MATTeacherWindowPaginator_query on Query
  @argumentDefinitions(
    filters: { type: MATTeacherFilter }
    order: { type: MATTeacherOrder }
    page: { type: Int, defaultValue: 1 }
    pageSize: { type: Int, defaultValue: 20 }
  )
  @refetchable(queryName: "MATTeacherWindowPaginatorRefreshQuery") {
    organizationTeachers(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      totalCount
      pages {
        ...Pagination_pages
      }
      edges {
        node {
          id
        }
      }
      ...MATTeacherConnectionDataTable_matTeacherConnection
    }
  }
`;

type Props = {} & Omit<FragmentWindowPaginatorProps<MATTeacherWindowPaginator_query$key>, 'fragment'>;

const MATTeacherWindowPaginator = ({ ...props }: Props) => (
  <FragmentWindowPaginator fragment={MATTeacherWindowPaginator_query} {...props}>
    {(data, { loadPage }) =>
      props.children(data, {
        loadPage,
        renderPagination: () => <Pagination pages={data.organizationTeachers.pages} loadPage={loadPage} />,
      })
    }
  </FragmentWindowPaginator>
);

export default MATTeacherWindowPaginator;
