/**
 * @generated SignedSource<<2c438c96337242694a0d4d918f7a6cf6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ScrapSourceSubmitInput = {
  scrapSource: NodeInput;
};
export type NodeInput = {
  id: string;
};
export type ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation$variables = {
  input: ScrapSourceSubmitInput;
};
export type ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation$data = {
  readonly scrapSourceSubmit: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceActionsMutationButtonStack_ScrapSource" | "ScrapSourceScrapPaginator_scrapSource" | "ScrapSourceStatusLabel_scrapSource">;
  };
};
export type ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation = {
  response: ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation$data;
  variables: ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemBookPage",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "solutionBookPage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "problemNumber",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScrapSource",
        "kind": "LinkedField",
        "name": "scrapSourceSubmit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScrapSourceStatusLabel_scrapSource"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScrapSourceActionsMutationButtonStack_ScrapSource"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ScrapSourceScrapPaginator_scrapSource"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ScrapSource",
        "kind": "LinkedField",
        "name": "scrapSourceSubmit",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "solutionPdf",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "File",
            "kind": "LinkedField",
            "name": "problemPdf",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Scrap",
            "kind": "LinkedField",
            "name": "latestScrap",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "problemPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "solutionPage",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ScrapConnection",
            "kind": "LinkedField",
            "name": "scraps",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ScrapEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Scrap",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "connection",
            "key": "ScrapSourceScrapPaginator_scrapSource_scraps",
            "kind": "LinkedHandle",
            "name": "scraps"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d7893bf81d97e96743e480f3118f874a",
    "id": null,
    "metadata": {},
    "name": "ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation",
    "operationKind": "mutation",
    "text": "mutation ScrapSourceActionsMutationButtonStack_scrapSourceSubmitMutation(\n  $input: ScrapSourceSubmitInput!\n) {\n  scrapSourceSubmit(input: $input) {\n    id\n    ...ScrapSourceStatusLabel_scrapSource\n    ...ScrapSourceActionsMutationButtonStack_ScrapSource\n    ...ScrapSourceScrapPaginator_scrapSource\n  }\n}\n\nfragment ScrapCreateDialog_scrapSource on ScrapSource {\n  id\n  title\n  type\n  solutionPdf {\n    id\n    objectUrl\n  }\n  problemPdf {\n    id\n    objectUrl\n  }\n  latestScrap {\n    problemBookPage\n    solutionBookPage\n    problemNumber\n    problemPage\n    solutionPage\n    id\n  }\n}\n\nfragment ScrapItem_scrap on Scrap {\n  id\n  problemBookPage\n  solutionBookPage\n  problemNumber\n}\n\nfragment ScrapSourceActionsMutationButtonStack_ScrapSource on ScrapSource {\n  id\n  actions\n  ...ScrapCreateDialog_scrapSource\n}\n\nfragment ScrapSourceScrapConnectionDataTable_scrapConnection on ScrapConnection {\n  edges {\n    node {\n      id\n      actions\n      problemNumber\n      problemBookPage\n      solutionBookPage\n    }\n  }\n}\n\nfragment ScrapSourceScrapPaginator_scrapSource on ScrapSource {\n  scraps {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        actions\n        ...ScrapItem_scrap\n        __typename\n      }\n    }\n    ...ScrapSourceScrapConnectionDataTable_scrapConnection\n  }\n  id\n}\n\nfragment ScrapSourceStatusLabel_scrapSource on ScrapSource {\n  status\n}\n"
  }
};
})();

(node as any).hash = "2fe41cb269a531eb63713a8520d1583e";

export default node;
