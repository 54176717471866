/**
 * @generated SignedSource<<15a1d6e97996a552d22b57a2ed895863>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProductEventUserYearEnum = "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3" | "high_school_N" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductEventDescriptionList_productEvent$data = {
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly description: string | null;
  readonly endAt: any;
  readonly id: string;
  readonly startAt: any;
  readonly userYears: ReadonlyArray<ProductEventUserYearEnum>;
  readonly " $fragmentSpreads": FragmentRefs<"ProductEventStatusLabel_productEvent">;
  readonly " $fragmentType": "ProductEventDescriptionList_productEvent";
};
export type ProductEventDescriptionList_productEvent$key = {
  readonly " $data"?: ProductEventDescriptionList_productEvent$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductEventDescriptionList_productEvent">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductEventDescriptionList_productEvent",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userYears",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductEventStatusLabel_productEvent"
    }
  ],
  "type": "ProductEvent",
  "abstractKey": null
};
})();

(node as any).hash = "e384462bb16c49fe3c1825f834a3027b";

export default node;
