import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import {
  ScrapSourceCreateDialog_scrapSourceCreateMutation,
  ScrapSourceTypeEnum,
} from '../../../relay/__generated__/ScrapSourceCreateDialog_scrapSourceCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import ErrorBoundary from '../../core/ErrorBoundary';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import UploadField from '../../core/UploadField';
import View from '../../core/View';
import UnitSelectField from '../../unit/UnitSelectField';
import UnitASelectField from '../../unitA/UnitASelectField';
import ScrapSourceBookNamesSearchOverlayField from '../ScrapSourceBookNamesSearchOverlayField';

type Props = {} & DialogProps &
  Pick<MutationFormikProps<ScrapSourceCreateDialog_scrapSourceCreateMutation>, 'onSubmit' | 'config' | 'connections'>;

const ScrapSourceCreateDialog = ({ onSubmit, config, connections, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>PDF 업로드</Text>
      </Dialog.Header>
      <Suspense
        fallback={
          <View sx={{ padding: 5 }}>
            <Spinner />
          </View>
        }
      >
        <ErrorBoundary>
          <MutationFormik<ScrapSourceCreateDialog_scrapSourceCreateMutation>
            mutation={graphql`
              mutation ScrapSourceCreateDialog_scrapSourceCreateMutation($input: ScrapSourceCreateInput!) {
                scrapSourceCreate(input: $input) {
                  id
                }
              }
            `}
            initialValues={{
              unit: {
                id: '',
              },
              type: 'exam',
              problemPdf: {
                key: '',
                size: 0,
              },
              solutionPdf: {
                key: '',
                size: 0,
              },
            }}
            onSubmit={onSubmit}
            config={config}
            connections={connections}
          >
            {({ values, handleSubmit, dirty, setValues }, { scrollContainerRef, nonFieldErrorRef }) => (
              <>
                <Dialog.Body ref={scrollContainerRef}>
                  <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
                  <FormLayout>
                    <UnitSelectField label={'교육과정'} name={'unit.id'} required placeholder={'교육과정 선택'} />
                    <EnumPairRadioGroupField
                      typename={'ScrapSourceTypeEnum'}
                      label={'기출/사설'}
                      name={'type'}
                      required
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={'min'}>
                          {children}
                        </Grid.Unit>
                      )}
                      renderContainer={(children) => <Grid gapX={3}>{children}</Grid>}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          type: value as ScrapSourceTypeEnum,
                          [value === 'exam' ? 'nonExamInfoInput' : 'examInfoInput']: null,
                        });
                      }}
                    />
                    {values.type === 'exam' ? (
                      <>
                        <EnumPairRadioGroupField
                          typename={'ScrapSourceExamSourceEnum'}
                          label={'출제기관'}
                          name={'examInfoInput.source'}
                          required
                          renderOptionWrapper={(children, { id }) => (
                            <Grid.Unit key={id} size={'min'}>
                              {children}
                            </Grid.Unit>
                          )}
                          renderContainer={(children) => <Grid gapX={3}>{children}</Grid>}
                        />
                        <Grid gapX={4}>
                          <Grid.Unit size={1 / 4}>
                            <EnumPairSelectField
                              typename={'ScrapSourceExamYearEnum'}
                              label={'연도'}
                              required
                              name={'examInfoInput.year'}
                              placeholder={'연도 선택'}
                            />
                          </Grid.Unit>
                          <Grid.Unit size={1 / 4}>
                            <EnumPairSelectField
                              typename={'ScrapSourceExamMonthEnum'}
                              label={'월'}
                              required
                              name={'examInfoInput.month'}
                              placeholder={'월 선택'}
                            />
                          </Grid.Unit>
                          <Grid.Unit size={1 / 4}>
                            <EnumPairSelectField
                              typename={'ScrapSourceExamGradeEnum'}
                              label={'학년'}
                              required
                              name={'examInfoInput.grade'}
                              placeholder={'학년 선택'}
                            />
                          </Grid.Unit>
                          <Grid.Unit size={1 / 4}>
                            <EnumPairSelectField
                              typename={'ScrapSourceExamCategoryEnum'}
                              label={'구분'}
                              required
                              name={'examInfoInput.category'}
                              placeholder={'구분 선택'}
                            />
                          </Grid.Unit>
                        </Grid>
                      </>
                    ) : (
                      <Grid gapX={3}>
                        <Grid.Unit size={1 / 3}>
                          <ScrapSourceBookNamesSearchOverlayField
                            label={'출처'}
                            name={'nonExamInfoInput.bookName'}
                            required
                            placeholder={'출처 입력'}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={1 / 3}>
                          <UnitASelectField
                            label={'UnitA'}
                            name={'nonExamInfoInput.unitA.id'}
                            required
                            placeholder={'Unit A 선택'}
                            variables={{
                              order: { order: 'ASC' },
                            }}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={1 / 3}>
                          <TextField
                            label={'출판사'}
                            name={'nonExamInfoInput.bookPublisher'}
                            required
                            placeholder={'출판사 입력'}
                            autoComplete={'off'}
                          />
                        </Grid.Unit>
                      </Grid>
                    )}
                    <UploadField label={'문제 PDF'} name={'problemPdf'} accept={'application/pdf'} required />
                    <UploadField label={'해설 PDF'} name={'solutionPdf'} accept={'application/pdf'} required />
                  </FormLayout>
                </Dialog.Body>
                <Dialog.Footer>
                  <Button variant={'primary'} disabled={!dirty} onClick={() => handleSubmit()}>
                    업로드하기
                  </Button>
                </Dialog.Footer>
              </>
            )}
          </MutationFormik>
        </ErrorBoundary>
      </Suspense>
    </Dialog>
  );
};

export default ScrapSourceCreateDialog;
