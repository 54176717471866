import { ArchiveIcon, SearchIcon, SyncIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import StuffConnectionDataTable from '../../components/stuff/StuffConnectionDataTable';
import StuffCreateDialog from '../../components/stuff/StuffCreateDialog';
import StuffWindowPaginator from '../../components/stuff/StuffWindowPaginator';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import { StuffTypeEnum, stuff_stuffsQuery } from '../../relay/__generated__/stuff_stuffsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import StuffId from './[stuffId]';

const stuffsForStuff = graphql`
  query stuff_stuffsQuery($filters: StuffFilter, $order: StuffOrder, $page: Int, $pageSize: Int) {
    ...StuffWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const Stuff: NextPage<Props> = () => {
  const router = useRouter();

  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    createdById_In: { type: 'string', multiple: true },
    type_In: { type: 'string', multiple: true },
    page: { type: 'number' },
    order: { type: 'string' },
  });
  const { search, createdById_In, type_In, page, order } = initialValues;

  const { toast } = useToast();

  return (
    <View>
      <Head siteTitle={'물품'} />
      <ErrorBoundary>
        <QueryFormik<stuff_stuffsQuery>
          query={stuffsForStuff}
          staticVariables={{ pageSize: 20 }}
          initialValues={{
            filters: { search, createdById_In, type_In: type_In as StuffTypeEnum[] },
            order: order || '-created',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
          enableReinitialize
        >
          {({ values: { filters, order }, setFieldValue, handleSubmit, submitForm, resetForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>물품</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <DialogButton
                      size={'large'}
                      variant={'primary'}
                      renderDialog={({ isOpen, closeDialog }) => (
                        <StuffCreateDialog
                          isOpen={isOpen}
                          onDismiss={closeDialog}
                          config={{
                            onCompleted: () => {
                              toast('물품 생성에 성공했어요', 'success');
                              handleSubmit();
                              closeDialog();
                            },
                          }}
                        />
                      )}
                    >
                      생성하기
                    </DialogButton>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'StuffFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'StuffOrder'}
                            orders={['created']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={1} title={'타입'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'StuffTypeEnum'}
                        label={'타입'}
                        name={'filters.type_In'}
                        placeholder={'타입 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={1} title={'제작자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'제작자'}
                        name={'filters.createdById_In'}
                        placeholder={'제작자 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<stuff_stuffsQuery>>
                        {(queryReference) => (
                          <StuffWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ stuffs }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(stuffs.totalCount || 0)}개
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <StuffConnectionDataTable
                                    stuffConnection={stuffs}
                                    onRowClick={({ id }) => router.push({ pathname: `/stuff/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </StuffWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

Stuff.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Stuff.authenticated = true;
Stuff.routes = [
  {
    id: 'stuff',
    name: '물품',
    pathname: '/stuff',
    icon: ArchiveIcon,
    permissions: ['stuff_read'],
  },
  ...StuffId.routes,
];

export default Stuff;
