import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { TaskSolutionVideoUpdateDialog_taskSolutionVideo$key } from '../../../relay/__generated__/TaskSolutionVideoUpdateDialog_taskSolutionVideo.graphql';
import {
  TaskSolutionVideoUpdateDialog_taskSolutionVideoUpdateMutation,
  TaskSolutionVideoUpdateInput,
} from '../../../relay/__generated__/TaskSolutionVideoUpdateDialog_taskSolutionVideoUpdateMutation.graphql';
import Button from '../../core/Button';
import CheckboxField from '../../core/CheckboxField';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TaskVideoSelectField from '../TaskVideoSelectField';
import TaskVideoThumbnailRadioGroupField from '../TaskVideoThumbnailRadioGroupField';

const taskSolutionVideoUpdateForTaskVideoSolutionUpdateDialog = graphql`
  mutation TaskSolutionVideoUpdateDialog_taskSolutionVideoUpdateMutation($input: TaskSolutionVideoUpdateInput!) {
    taskSolutionVideoUpdate(input: $input) {
      id
      task {
        id
        actions
        ...TaskTaskHistoryPaginator_task
        ...TaskDescriptionList_task
        solutionVideo {
          id
          modified
          isPremium
          isRecommended
          taskVideo {
            id
          }
          ...TaskSolutionVideoUpdateDialog_taskSolutionVideo
          ...TaskSolutionVideoGrid_taskSolutionVideo
        }
      }
    }
  }
`;

const TaskSolutionVideoUpdateDialog_taskSolutionVideo = graphql`
  fragment TaskSolutionVideoUpdateDialog_taskSolutionVideo on TaskSolutionVideo {
    id
    modified
    taskVideo {
      id
    }
    isPremium
    isRecommended
    thumbnailHorizontal {
      id
      key
    }
    thumbnailVertical {
      id
      key
    }
  }
`;

type Props = { taskId: string; taskSolutionVideo: TaskSolutionVideoUpdateDialog_taskSolutionVideo$key } & DialogProps &
  Pick<MutationFormikProps<TaskSolutionVideoUpdateDialog_taskSolutionVideoUpdateMutation>, 'config' | 'onSubmit'>;

const TaskSolutionVideoUpdateDialog = ({ taskId, taskSolutionVideo, config, onSubmit, ...props }: Props) => {
  const { id, isPremium, isRecommended, taskVideo, thumbnailHorizontal, thumbnailVertical } = useFragment(
    TaskSolutionVideoUpdateDialog_taskSolutionVideo,
    taskSolutionVideo,
  );
  const { toast } = useToast();
  const handleCompletedUpdate = () => toast('대표 해설 영상 지정 수정 완료!', 'success');

  const staticValues: TaskSolutionVideoUpdateInput = {
    id,
  };
  const defaultValues: Partial<TaskSolutionVideoUpdateInput> = {
    isPremium,
    isRecommended,
    video: taskVideo,
    thumbnailHorizontalKey: thumbnailHorizontal?.key || null,
    thumbnailVerticalKey: thumbnailVertical?.key || null,
  };

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>해설 영상 수정</Text>
      </Dialog.Header>
      <MutationFormik<TaskSolutionVideoUpdateDialog_taskSolutionVideoUpdateMutation>
        mutation={taskSolutionVideoUpdateForTaskVideoSolutionUpdateDialog}
        initialValues={{
          ...staticValues,
          ...defaultValues,
        }}
        config={{
          ...config,
          onCompleted: (...arg) => {
            config?.onCompleted?.(...arg);
            handleCompletedUpdate();
          },
        }}
        onSubmit={onSubmit}
      >
        {({ values, handleSubmit }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <Suspense fallback={<Spinner />}>
                  <TaskVideoSelectField taskId={taskId} label={'비디오'} name={'video.id'} required />
                </Suspense>
                <Grid gapX={2} gapY={2}>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <CheckboxField label={'유료 콘텐츠인가요?'} name={'isPremium'} />
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <CheckboxField label={'매력이 높은 영상인가요?'} name={'isRecommended'} />
                  </Grid.Unit>
                </Grid>
                {values.video?.id ? (
                  <Suspense fallback={<Spinner />}>
                    <TaskVideoThumbnailRadioGroupField
                      label={'세로형 썸네일'}
                      name={'thumbnailVerticalKey'}
                      taskVideoId={values.video.id}
                      filters={{ type: 'vertical' }}
                      renderContainer={(children) => (
                        <Grid gapX={3} gapY={2}>
                          {children}
                        </Grid>
                      )}
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={1 / 4}>
                          {children}
                        </Grid.Unit>
                      )}
                    />
                    <TaskVideoThumbnailRadioGroupField
                      label={'가로형 썸네일'}
                      name={'thumbnailHorizontalKey'}
                      taskVideoId={values.video.id}
                      filters={{ type: 'horizontal' }}
                      renderContainer={(children) => (
                        <Grid gapX={3} gapY={2}>
                          {children}
                        </Grid>
                      )}
                      renderOptionWrapper={(children, { id }) => (
                        <Grid.Unit key={id} size={1 / 3}>
                          {children}
                        </Grid.Unit>
                      )}
                    />
                  </Suspense>
                ) : null}
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default TaskSolutionVideoUpdateDialog;
