/**
 * @generated SignedSource<<08e841e3f2c866f72150b76b747b23db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type shortcut_meQuery$variables = {};
export type shortcut_meQuery$data = {
  readonly me: {
    readonly id: string;
    readonly shortcuts: ReadonlyArray<{
      readonly id: string;
      readonly label: string;
      readonly text: string;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"ShortcutUpdateReloadDialog_user">;
  };
};
export type shortcut_meQuery = {
  response: shortcut_meQuery$data;
  variables: shortcut_meQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "Shortcut",
  "kind": "LinkedField",
  "name": "shortcuts",
  "plural": true,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "shortcut_meQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShortcutUpdateReloadDialog_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "shortcut_meQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d588f91a74bd3009bb4805a948fc0964",
    "id": null,
    "metadata": {},
    "name": "shortcut_meQuery",
    "operationKind": "query",
    "text": "query shortcut_meQuery {\n  me {\n    id\n    shortcuts {\n      id\n      label\n      text\n    }\n    ...ShortcutUpdateReloadDialog_user\n  }\n}\n\nfragment ShortcutUpdateReloadDialog_user on User {\n  id\n  shortcuts {\n    label\n    text\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "15c26de43cde2664bde5193d44cf4af6";

export default node;
