import React from 'react';

import Button, { ButtonProps } from '../Button';
import DialogHandler from '../DialogHandler';

type Props = {
  renderDialog: ({ isOpen, closeDialog }: { isOpen: boolean; closeDialog: () => void }) => React.ReactNode;
} & ButtonProps;

const DialogButton = ({ renderDialog, onClick, ...props }: Props) => (
  <DialogHandler renderDialog={renderDialog} onClick={onClick}>
    <Button {...props} />
  </DialogHandler>
);

export default DialogButton;
