import { graphql, useFragment } from 'react-relay';

import { TypingAutomationHistoryTimelineItem_typingAutomationHistory$key } from '../../../relay/__generated__/TypingAutomationHistoryTimelineItem_typingAutomationHistory.graphql';
import DateText from '../../core/DateText';
import Text from '../../core/Text';
import Timeline from '../../core/Timeline';
import View from '../../core/View';

const TypingAutomationHistoryTimelineItem_typingAutomationHistory = graphql`
  fragment TypingAutomationHistoryTimelineItem_typingAutomationHistory on TypingAutomationHistory {
    script {
      id
      title
    }
    created
  }
`;

type Props = {
  typingAutomationHistory: TypingAutomationHistoryTimelineItem_typingAutomationHistory$key;
};

const TypingAutomationHistoryTimelineItem = ({ typingAutomationHistory }: Props) => {
  const { script, created } = useFragment(
    TypingAutomationHistoryTimelineItem_typingAutomationHistory,
    typingAutomationHistory,
  );

  return (
    <Timeline.Item>
      <Timeline.Badge />
      <Timeline.Body>
        <View>
          <Text fontSize={1} fontWeight={'bold'}>
            {script.title}
          </Text>
          <View sx={{ marginTop: 2 }}>
            <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
          </View>
        </View>
      </Timeline.Body>
    </Timeline.Item>
  );
};

export default TypingAutomationHistoryTimelineItem;
