import { useField } from 'formik';
import { RefObject, Suspense } from 'react';
import { graphql } from 'react-relay';

import useSearchOverlayQueryLoader from '../../../hooks/useSearchOverlayQueryLoader';
import {
  ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery,
  ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery$data,
} from '../../../relay/__generated__/ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery.graphql';
import { isNullable } from '../../../utils/is';
import ItemList from '../../core/ItemList';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import ScrollContainer from '../../core/ScrollContainer';
import SearchOverlayField, { SearchOverlayFieldProps } from '../../core/SearchOverlayField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';

const scrapSourceBookNamesForScrapSourceBookNamesSearchOverlayField = graphql`
  query ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery($filters: ScrapSourceBookNamesFilter) {
    scrapSourceBookNames(filters: $filters)
  }
`;

type Props = {} & Omit<
  SearchOverlayFieldProps<{
    id: ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery$data['scrapSourceBookNames'][number];
  }>,
  'renderItemList'
>;

const ScrapSourceBookNamesSearchOverlayField = ({ name = '', value: propValue, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [searchOverlayFieldProps, queryReference] =
    useSearchOverlayQueryLoader<ScrapSourceBookNamesSearchOverlayField_scrapSourceBookNamesQuery>(
      scrapSourceBookNamesForScrapSourceBookNamesSearchOverlayField,
    );

  return (
    <SearchOverlayField
      value={value}
      name={name}
      renderItemList={({ controllingElementRef, containerRef, handleSelect }, itemProps) =>
        queryReference ? (
          <Suspense fallback={<Spinner sx={{ paddingY: 3 }} />}>
            <PreloadedQueryRenderer
              query={scrapSourceBookNamesForScrapSourceBookNamesSearchOverlayField}
              queryReference={queryReference}
            >
              {({ scrapSourceBookNames }) => {
                const items = scrapSourceBookNames.map((scrapSourceBookName) => ({
                  id: scrapSourceBookName,
                  title: scrapSourceBookName,
                }));
                return (
                  <ScrollContainer ref={containerRef as RefObject<HTMLDivElement>}>
                    <ItemList
                      items={
                        controllingElementRef.current?.value
                          ? [
                              {
                                id: controllingElementRef.current?.value,
                                title: `"${controllingElementRef.current?.value}"으로 입력`,
                              },
                              ...items,
                            ]
                          : items
                      }
                      renderItem={({ title }) => <Text sx={{ fontSize: 1 }}>{title}</Text>}
                      renderItemWrapper={(children, { id }, i) => (
                        <View
                          {...itemProps}
                          key={`${id}-${i}`}
                          onClick={(e) => handleSelect(e, { id })}
                          onKeyDown={(e) => handleSelect(e, { id })}
                        >
                          {children}
                        </View>
                      )}
                    />
                  </ScrollContainer>
                );
              }}
            </PreloadedQueryRenderer>
          </Suspense>
        ) : null
      }
      {...searchOverlayFieldProps}
      {...props}
    />
  );
};

export default ScrapSourceBookNamesSearchOverlayField;
