import { FieldConfig, useField } from 'formik';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import MarkdownEditor, { MarkdownEditorProps } from '../MarkdownEditor';

type Props = { caption?: string } & Omit<MarkdownEditorProps, 'value' | 'onChange'> &
  Pick<Partial<MarkdownEditorProps>, 'value' | 'onChange'> &
  Pick<FieldConfig, 'validate'>;

const MarkdownEditorField = ({
  name = '',
  value: propValue,
  onChange: propOnChange,
  label,
  required,
  disabled,
  caption,
  validate,
  ...props
}: Props) => {
  const [{ value: baseValue }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label ?? '내용', '을', '를')} 입력해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();

  const value = !isNullable(propValue) ? propValue : baseValue;
  const onChange: typeof propOnChange = (newMarkdown) => setValue(newMarkdown);
  const handleChange: MarkdownEditorProps['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else onChange(...arg);
  };

  return (
    <FormControl sx={{ display: 'block' }}>
      <FormControl.Label />
      <MarkdownEditor
        disabled={disabled || isSubmitting}
        value={value}
        onChange={handleChange}
        sx={{ ...(error ? { borderWidth: 1, borderStyle: 'solid', borderColor: 'danger.fg', borderRadius: 2 } : {}) }}
        label={label}
        required={required}
        {...props}
      />
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default MarkdownEditorField;
