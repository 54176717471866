/**
 * @generated SignedSource<<42e7765f32ec4e06130ac47e1a5ccf39>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamProblemReasonEnum = "image_not_clear" | "image_not_enough" | "image_not_exam_info" | "image_not_match" | "image_not_related" | "others" | "%future added value";
export type SchoolExamProblemRejectInput = {
  id: string;
  reason: SchoolExamProblemReasonEnum;
};
export type SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation$variables = {
  input: SchoolExamProblemRejectInput;
};
export type SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation$data = {
  readonly schoolExamProblemReject: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SchoolExamProblemActionsMutationButtonStack_schoolExamProblem" | "SchoolExamProblemStatusLabel_schoolExamProblem">;
  };
};
export type SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation = {
  response: SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation$data;
  variables: SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamProblem",
        "kind": "LinkedField",
        "name": "schoolExamProblemReject",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SchoolExamProblemStatusLabel_schoolExamProblem"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SchoolExamProblemActionsMutationButtonStack_schoolExamProblem"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamProblem",
        "kind": "LinkedField",
        "name": "schoolExamProblemReject",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actions",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "57b466cd5fa24db3d06b71c50d29daf9",
    "id": null,
    "metadata": {},
    "name": "SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation",
    "operationKind": "mutation",
    "text": "mutation SchoolExamProblemRejectDialog_schoolExamProblemRejectMutation(\n  $input: SchoolExamProblemRejectInput!\n) {\n  schoolExamProblemReject(input: $input) {\n    id\n    ...SchoolExamProblemStatusLabel_schoolExamProblem\n    ...SchoolExamProblemActionsMutationButtonStack_schoolExamProblem\n  }\n}\n\nfragment SchoolExamProblemActionsMutationButtonStack_schoolExamProblem on SchoolExamProblem {\n  id\n  actions\n  ...SchoolExamProblemRejectDialog_schoolExamProblem\n}\n\nfragment SchoolExamProblemRejectDialog_schoolExamProblem on SchoolExamProblem {\n  id\n}\n\nfragment SchoolExamProblemStatusLabel_schoolExamProblem on SchoolExamProblem {\n  status\n}\n"
  }
};
})();

(node as any).hash = "e122c75b8f39b233a83cb599f677fcbc";

export default node;
