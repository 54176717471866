import { HTMLAttributes } from 'react';

import View, { ViewProps } from '../View';

type Props = {} & Pick<ViewProps, 'sx'> & HTMLAttributes<HTMLTableRowElement>;

const RowWrapper = ({ children, ...props }: React.PropsWithChildren<Props>) => (
  <View as={'tr'} tabIndex={0} {...props}>
    {children}
  </View>
);

export default RowWrapper;
