/**
 * @generated SignedSource<<2bb682c13442cb5d3d5c2cc266191c1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamProblemApproveInput = {
  id: string;
};
export type SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation$variables = {
  input: SchoolExamProblemApproveInput;
};
export type SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation$data = {
  readonly schoolExamProblemApprove: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"SchoolExamProblemActionsMutationButtonStack_schoolExamProblem" | "SchoolExamProblemStatusLabel_schoolExamProblem">;
  };
};
export type SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation = {
  response: SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation$data;
  variables: SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamProblem",
        "kind": "LinkedField",
        "name": "schoolExamProblemApprove",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SchoolExamProblemStatusLabel_schoolExamProblem"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SchoolExamProblemActionsMutationButtonStack_schoolExamProblem"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SchoolExamProblem",
        "kind": "LinkedField",
        "name": "schoolExamProblemApprove",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "actions",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "231864b8a7562d0c1fc60931ece991ed",
    "id": null,
    "metadata": {},
    "name": "SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation",
    "operationKind": "mutation",
    "text": "mutation SchoolExamProblemActionsMutationButtonStack_schoolExamProblemApproveMutation(\n  $input: SchoolExamProblemApproveInput!\n) {\n  schoolExamProblemApprove(input: $input) {\n    id\n    ...SchoolExamProblemStatusLabel_schoolExamProblem\n    ...SchoolExamProblemActionsMutationButtonStack_schoolExamProblem\n  }\n}\n\nfragment SchoolExamProblemActionsMutationButtonStack_schoolExamProblem on SchoolExamProblem {\n  id\n  actions\n  ...SchoolExamProblemRejectDialog_schoolExamProblem\n}\n\nfragment SchoolExamProblemRejectDialog_schoolExamProblem on SchoolExamProblem {\n  id\n}\n\nfragment SchoolExamProblemStatusLabel_schoolExamProblem on SchoolExamProblem {\n  status\n}\n"
  }
};
})();

(node as any).hash = "10e1a633e449687d51f51eed764b0b95";

export default node;
