import { graphql } from 'react-relay';

import withSuspense from '../../../hocs/withSuspense';
import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  UnitSchoolYearSelectField_unitQuery,
  UnitSchoolYearSelectField_unitQuery$variables,
} from '../../../relay/__generated__/UnitSchoolYearSelectField_unitQuery.graphql';
import EnumPair from '../../core/EnumPair';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const unitForUnitSchoolYearSelectField = graphql`
  query UnitSchoolYearSelectField_unitQuery($id: ID!) {
    unit(id: $id) {
      id
      schoolYears
    }
  }
`;

type Props = { variables: UnitSchoolYearSelectField_unitQuery$variables } & Omit<SelectFieldProps, 'options'>;

const UnitSchoolYearSelectField = ({ variables, ...props }: Props) => {
  const [{ unit }] = useLazyLoadQuery<UnitSchoolYearSelectField_unitQuery>(unitForUnitSchoolYearSelectField, {
    ...variables,
  });

  return (
    <SelectField
      options={unit.schoolYears.map((schoolYear) => ({
        id: schoolYear,
        text: (<EnumPair typename={'UnitSchoolYearEnum'}>{schoolYear}</EnumPair>) as unknown as string,
      }))}
      {...props}
    />
  );
};

export default withSuspense(UnitSchoolYearSelectField);
