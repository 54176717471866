import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { WorkbookGradesDataTable_grades$key } from '../../../relay/__generated__/WorkbookGradesDataTable_grades.graphql';
import DataTable from '../../core/DataTable';
import Text from '../../core/Text';

const WorkbookGradesDataTable_grades = graphql`
  fragment WorkbookGradesDataTable_grades on Workbook {
    grades {
      grade
      percentile
      rawScore
      standardScore
    }
  }
`;

type Props = {
  grades: WorkbookGradesDataTable_grades$key;
};

const WorkbookGradesDataTable = ({ grades: fragmentRef }: Props) => {
  const { grades } = useFragment(WorkbookGradesDataTable_grades, fragmentRef);

  return (
    <DataTable
      rows={grades.map((g) => ({ ...g, id: g.grade }))}
      columns={[
        {
          field: 'grade',
          renderValue: ({ grade }) => <Text fontSize={1}>{grade}</Text>,
          title: '등급',
          width: 48,
        },
        {
          field: 'rawScore',
          renderValue: ({ rawScore }) => <Text fontSize={1}>{rawScore ?? '-'}</Text>,
          title: '원점수',
          width: 100,
        },
        {
          field: 'standardScore',
          renderValue: ({ standardScore }) => <Text fontSize={1}>{standardScore ?? '-'}</Text>,
          title: '표준 점수',
          width: 100,
        },
        {
          field: 'percentile',
          renderValue: ({ percentile }) => <Text fontSize={1}>{percentile ?? '-'}</Text>,
          title: '백분위',
          width: 100,
        },
      ]}
    />
  );
};

export default WorkbookGradesDataTable;
