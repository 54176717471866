/**
 * @generated SignedSource<<83948391f1459630c266d3e8834c1c02>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnitDItem_unitD$data = {
  readonly description: string | null;
  readonly id: string;
  readonly unitATitle: string;
  readonly unitBTitle: string;
  readonly unitCTitle: string;
  readonly " $fragmentType": "UnitDItem_unitD";
};
export type UnitDItem_unitD$key = {
  readonly " $data"?: UnitDItem_unitD$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnitDItem_unitD">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnitDItem_unitD",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitATitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitBTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitCTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "type": "UnitD",
  "abstractKey": null
};

(node as any).hash = "4e45364ee4998d09ff9838151ac87eb6";

export default node;
