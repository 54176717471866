import { graphql } from 'react-relay';

import { CommentPaginator_query$key } from '../../../relay/__generated__/CommentPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const CommentPaginator_query = graphql`
  fragment CommentPaginator_query on Query
  @argumentDefinitions(
    filters: { type: CommentFilter }
    order: { type: CommentOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "CommentPaginatorQuery") {
    comments(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "CommentsPaginator_query_comments") {
      __id
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          nodeId
          typename
          ...CommentItem_comment
          ...CommentMutationActionItem_comment
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<CommentPaginator_query$key>, 'fragment'>;

const CommentPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={CommentPaginator_query} {...props} />;

export default CommentPaginator;
export type { Props as CommentPaginatorProps };
