/**
 * @generated SignedSource<<f89e178c5b11f7676169984ae2a12d1e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InternalUserPhoneNumberUpdateDialog_internalUser$data = {
  readonly id: string;
  readonly phoneNumber: string | null;
  readonly " $fragmentType": "InternalUserPhoneNumberUpdateDialog_internalUser";
};
export type InternalUserPhoneNumberUpdateDialog_internalUser$key = {
  readonly " $data"?: InternalUserPhoneNumberUpdateDialog_internalUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"InternalUserPhoneNumberUpdateDialog_internalUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InternalUserPhoneNumberUpdateDialog_internalUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "type": "MATInternalUser",
  "abstractKey": null
};

(node as any).hash = "8be4091fe3a7440378922106836d44c8";

export default node;
