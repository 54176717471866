import { CheckIcon } from '@primer/octicons-react';
import { graphql } from 'react-relay';

import { BookCreateDialog_bookCreateMutation } from '../../../relay/__generated__/BookCreateDialog_bookCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import UploadField from '../../core/UploadField';
import UnitAPaginatorSearchOverlayTokenField from '../../unitA/UnitAPaginatorSearchOverlayTokenField';

type Props = {} & DialogProps &
  Pick<MutationFormikProps<BookCreateDialog_bookCreateMutation>, 'onSubmit' | 'config' | 'connections'>;

const BookCreateDialog = ({ onSubmit, config, connections, ...props }: Props) => {
  return (
    <Dialog wide {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>PDF 업로드</Text>
      </Dialog.Header>
      <MutationFormik<BookCreateDialog_bookCreateMutation>
        mutation={graphql`
          mutation BookCreateDialog_bookCreateMutation($input: BookCreateInput!) {
            bookCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{
          type: 'normal',
          title: '',
          unitAs: [],
          publisher: '',
        }}
        onSubmit={onSubmit}
        config={config}
        connections={connections}
      >
        {({ handleSubmit }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <EnumPairRadioGroupField
                  typename={'BookTypeEnum'}
                  name={'type'}
                  label={'시중 교재/교과서'}
                  required
                  renderContainer={(children) => <Grid gapX={3}>{children}</Grid>}
                  renderOptionWrapper={(children, { id }) => (
                    <Grid.Unit key={id} size={'min'}>
                      {children}
                    </Grid.Unit>
                  )}
                />
                <Grid gapX={4}>
                  <Grid.Unit size={1 / 4}>
                    <TextField
                      label={'교재 이름'}
                      name={'title'}
                      placeholder={'e.g. 개념 해결의 법칙 - 초등수학 3-1'}
                      required
                    />
                  </Grid.Unit>
                  <Grid.Unit size={1 / 4}>
                    <UnitAPaginatorSearchOverlayTokenField
                      label={'Unit A'}
                      name={'unitAs'}
                      placeholder={'Unit A 선택'}
                      variables={{ order: { order: 'ASC' } }}
                      visibleTokenCount={1}
                      required
                    />
                  </Grid.Unit>
                  <Grid.Unit size={1 / 4}>
                    <TextField label={'출판사'} name={'publisher'} placeholder={'출판사 입력'} required />
                  </Grid.Unit>
                  <Grid.Unit size={1 / 4}>
                    <TextField label={'발행 연도'} name={'edition'} placeholder={'e.g. 2024'} required />
                  </Grid.Unit>
                </Grid>
                <UploadField label={'문제 표지 PDF'} name={'coverPdf'} accept={'application/pdf'} required />
                <UploadField label={'문제 PDF'} name={'problemPdf'} accept={'application/pdf'} required />
                <UploadField label={'해설 PDF'} name={'solutionPdf'} accept={'application/pdf'} required />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button leadingIcon={CheckIcon} variant={'primary'} onClick={() => handleSubmit()}>
                업로드하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default BookCreateDialog;
