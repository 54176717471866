import { commaizeNumber } from '@teamturing/utils';
import { graphql, useFragment } from 'react-relay';

import {
  MATInvoiceConnectionDataTable_matInvoiceConnection$data,
  MATInvoiceConnectionDataTable_matInvoiceConnection$key,
} from '../../../relay/__generated__/MATInvoiceConnectionDataTable_matInvoiceConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import MATInvoiceStateLabel from '../MATInvoiceStateLabel';

const MATInvoiceConnectionDataTable_matInvoiceConnection = graphql`
  fragment MATInvoiceConnectionDataTable_matInvoiceConnection on MATInvoiceConnection {
    edges {
      node {
        id
        title
        premiumType
        scholarshipAmount
        startDate
        endDate
        maxStudentCount
        amount
        ...MATInvoiceStateLabel_matInvoice
      }
    }
  }
`;

type Props = {
  matInvoiceConnection: MATInvoiceConnectionDataTable_matInvoiceConnection$key;
} & Omit<
  DataTableProps<MATInvoiceConnectionDataTable_matInvoiceConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const MATInvoiceConnectionDataTable = ({ matInvoiceConnection, ...props }: Props) => {
  const { edges } = useFragment(MATInvoiceConnectionDataTable_matInvoiceConnection, matInvoiceConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'title',
          title: '제목',
          renderValue: ({ title }) => title,
          width: 77,
        },
        {
          field: 'premiumType',
          title: '타입',
          renderValue: ({ premiumType }) => (
            <EnumPair typename={'MATOrganizationPremiumTypeEnum'}>{premiumType}</EnumPair>
          ),
          width: 52,
        },
        {
          field: 'scholarshipAmount',
          title: '일 환급 금액',
          renderValue: ({ scholarshipAmount }) => (scholarshipAmount ? `${commaizeNumber(scholarshipAmount)}원` : '-'),
          width: 83,
        },
        {
          field: 'startDate',
          title: '시작일',
          renderValue: ({ startDate }) => <DateText format={'P'}>{startDate}</DateText>,
          width: 84,
        },
        {
          field: 'endDate',
          title: '종료일',
          renderValue: ({ endDate }) => <DateText format={'P'}>{endDate}</DateText>,
          width: 84,
        },
        {
          field: 'maxStudentCount',
          title: '이용 학생 수',
          renderValue: ({ maxStudentCount }) => `${commaizeNumber(maxStudentCount)}명`,
          width: 84,
        },
        {
          field: 'amount',
          title: '결제 금액',
          renderValue: ({ amount }) => `${commaizeNumber(amount)}원`,
          width: 100,
        },
        {
          field: 'state',
          title: '적용 여부',
          renderValue: (invoice) => <MATInvoiceStateLabel matInvoice={invoice} size={'small'} />,
          width: 100,
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

export default MATInvoiceConnectionDataTable;
