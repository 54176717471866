import { Textarea as PrimerTextarea, TextareaProps as PrimerTextareaProps } from '@primer/react';
import { forwardRef, Ref } from 'react';

import { useDialogContext } from '../../../contexts/DialogContext';

type Props = {} & PrimerTextareaProps;

const Textarea = ({ onKeyDown, ...props }: Props, ref: Ref<HTMLTextAreaElement>) => {
  const dialogContext = useDialogContext();
  const handleKeyDown: PrimerTextareaProps['onKeyDown'] = (event) => {
    if (!!dialogContext && event.key === 'Tab') event.stopPropagation();
    onKeyDown?.(event);
  };
  return <PrimerTextarea ref={ref} resize={'vertical'} onKeyDown={handleKeyDown} {...props} />;
};

export default forwardRef(Textarea);
export type { Props as TextareaProps };
