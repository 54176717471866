import { ActionMenu as PrimerActionMenu, ActionMenuProps as PrimerActionMenuProps } from '@primer/react';

type Props = {} & PrimerActionMenuProps;

const ActionMenu = ({ ...props }: Props) => <PrimerActionMenu {...props} />;

export default Object.assign(ActionMenu, {
  Anchor: PrimerActionMenu.Anchor,
  Overlay: PrimerActionMenu.Overlay,
  Button: PrimerActionMenu.Button,
});
export type { Props as ActionMenuProps };
