/**
 * @generated SignedSource<<e5dc9d411f1360966a450493b7dd6663>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type FormulaSetStatusEnum = "pending" | "published" | "review" | "%future added value";
export type FormulaSetFilter = {
  AND?: FormulaSetFilter | null;
  DISTINCT?: boolean | null;
  NOT?: FormulaSetFilter | null;
  OR?: FormulaSetFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  reviewedById_Exact?: string | null;
  reviewedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  sequence_Exact?: number | null;
  sequence_In?: ReadonlyArray<number> | null;
  status_Exact?: FormulaSetStatusEnum | null;
  status_In?: ReadonlyArray<FormulaSetStatusEnum> | null;
  unitAId_Exact?: string | null;
  unitAId_In?: ReadonlyArray<string> | null;
  unitATitle_Contains?: string | null;
  unitBId_Exact?: string | null;
  unitBId_In?: ReadonlyArray<string> | null;
  unitBTitle_Contains?: string | null;
  unitCId_Exact?: string | null;
  unitCId_In?: ReadonlyArray<string> | null;
  unitCTitle_Contains?: string | null;
  unitDId_Exact?: string | null;
  unitDId_In?: ReadonlyArray<string> | null;
  unitDTitle_Contains?: string | null;
  unitId_Exact?: string | null;
  unitId_In?: ReadonlyArray<string> | null;
  unitTitle_Contains?: string | null;
};
export type FormulaSetImageExportDialog_formulaSetsQuery$variables = {
  filters?: FormulaSetFilter | null;
};
export type FormulaSetImageExportDialog_formulaSetsQuery$data = {
  readonly formulaSets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly formulas: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly text: string;
              readonly title: string;
            };
          }>;
        };
        readonly id: string;
        readonly sequence: number;
        readonly unitD: {
          readonly description: string | null;
          readonly id: string;
        };
      };
    }>;
  };
};
export type FormulaSetImageExportDialog_formulaSetsQuery = {
  response: FormulaSetImageExportDialog_formulaSetsQuery$data;
  variables: FormulaSetImageExportDialog_formulaSetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      }
    ],
    "concreteType": "FormulaSetConnection",
    "kind": "LinkedField",
    "name": "formulaSets",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FormulaSetEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormulaSet",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sequence",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UnitD",
                "kind": "LinkedField",
                "name": "unitD",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FormulaConnection",
                "kind": "LinkedField",
                "name": "formulas",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormulaEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Formula",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FormulaSetImageExportDialog_formulaSetsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FormulaSetImageExportDialog_formulaSetsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "2b20bb6bfb50b60a3dab978d7733a076",
    "id": null,
    "metadata": {},
    "name": "FormulaSetImageExportDialog_formulaSetsQuery",
    "operationKind": "query",
    "text": "query FormulaSetImageExportDialog_formulaSetsQuery(\n  $filters: FormulaSetFilter\n) {\n  formulaSets(filters: $filters) {\n    edges {\n      node {\n        id\n        sequence\n        unitD {\n          id\n          description\n        }\n        formulas {\n          edges {\n            node {\n              id\n              title\n              text\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6860c541bb75ab38833d4bf58199b53";

export default node;
