/**
 * @generated SignedSource<<4402afee0cdb98d43b20c46c21029e1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FormulaSetActionEnum = "formula_set_review" | "formula_set_update" | "%future added value";
export type FormulaSetReviewInput = {
  id: string;
};
export type FormulaSetMutationActionsButtonStack_formulaSetReviewMutation$variables = {
  input: FormulaSetReviewInput;
};
export type FormulaSetMutationActionsButtonStack_formulaSetReviewMutation$data = {
  readonly formulaSetReview: {
    readonly actions: ReadonlyArray<FormulaSetActionEnum>;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"FormulaSetFormulaSetHistoryPaginator_formulaSet" | "FormulaSetStatusLabel_formulaSet">;
  };
};
export type FormulaSetMutationActionsButtonStack_formulaSetReviewMutation = {
  response: FormulaSetMutationActionsButtonStack_formulaSetReviewMutation$data;
  variables: FormulaSetMutationActionsButtonStack_formulaSetReviewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "created": "DESC"
    }
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "before",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "after",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FormulaSetMutationActionsButtonStack_formulaSetReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FormulaSet",
        "kind": "LinkedField",
        "name": "formulaSetReview",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FormulaSetStatusLabel_formulaSet"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FormulaSetFormulaSetHistoryPaginator_formulaSet"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FormulaSetMutationActionsButtonStack_formulaSetReviewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FormulaSet",
        "kind": "LinkedField",
        "name": "formulaSetReview",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "FormulaSetHistoryConnection",
            "kind": "LinkedField",
            "name": "histories",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FormulaSetHistoryEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormulaSetHistory",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "createdBy",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "picture",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isDeleted",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "created",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "difference",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isDifference"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FormulaDifference",
                                "kind": "LinkedField",
                                "name": "formulas",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Diff",
                                    "kind": "LinkedField",
                                    "name": "text",
                                    "plural": false,
                                    "selections": (v6/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Diff",
                                    "kind": "LinkedField",
                                    "name": "title",
                                    "plural": false,
                                    "selections": (v6/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Diff",
                                "kind": "LinkedField",
                                "name": "description",
                                "plural": false,
                                "selections": (v6/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "FormulaSetDifference",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "histories(first:10,order:{\"created\":\"DESC\"})"
          },
          {
            "alias": null,
            "args": (v4/*: any*/),
            "filters": [
              "order"
            ],
            "handle": "connection",
            "key": "FormulaSetFormulaSetHistoryPaginator_formulaSet_histories",
            "kind": "LinkedHandle",
            "name": "histories"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0c7606067f4f5967a68c0bb4b7bd36b6",
    "id": null,
    "metadata": {},
    "name": "FormulaSetMutationActionsButtonStack_formulaSetReviewMutation",
    "operationKind": "mutation",
    "text": "mutation FormulaSetMutationActionsButtonStack_formulaSetReviewMutation(\n  $input: FormulaSetReviewInput!\n) {\n  formulaSetReview(input: $input) {\n    id\n    actions\n    ...FormulaSetStatusLabel_formulaSet\n    ...FormulaSetFormulaSetHistoryPaginator_formulaSet\n  }\n}\n\nfragment DiffItem_diff on Diff {\n  before\n  after\n}\n\nfragment FormulaDifferenceItemList_formulaDifference on FormulaDifference {\n  text {\n    ...DiffItem_diff\n  }\n  title {\n    ...DiffItem_diff\n  }\n}\n\nfragment FormulaSetDifferenceItemList_formulaSetDifference on Difference {\n  __isDifference: __typename\n  ... on FormulaSetDifference {\n    formulas {\n      ...FormulaDifferenceItemList_formulaDifference\n    }\n    description {\n      ...DiffItem_diff\n    }\n  }\n}\n\nfragment FormulaSetFormulaSetHistoryPaginator_formulaSet on FormulaSet {\n  histories(order: {created: DESC}, first: 10) {\n    totalCount\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n    edges {\n      cursor\n      node {\n        id\n        type\n        ...FormulaSetHistoryDialog_formulaSetHistory\n        ...FormulaSetHistoryTimelineItem_formulaSetHistory\n        __typename\n      }\n    }\n  }\n  id\n}\n\nfragment FormulaSetHistoryDialog_formulaSetHistory on FormulaSetHistory {\n  createdBy {\n    name\n    picture\n    id\n  }\n  created\n  difference {\n    __typename\n    ...FormulaSetDifferenceItemList_formulaSetDifference\n  }\n}\n\nfragment FormulaSetHistoryTimelineItem_formulaSetHistory on FormulaSetHistory {\n  id\n  createdBy {\n    ...UserAvatarText_user\n    id\n  }\n  created\n  type\n}\n\nfragment FormulaSetStatusLabel_formulaSet on FormulaSet {\n  status\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "549620d1badaa970bfea36ebea5f88e8";

export default node;
