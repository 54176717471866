import { graphql, useFragment } from 'react-relay';

import {
  QuizDescriptionList_quiz$data,
  QuizDescriptionList_quiz$key,
} from '../../../relay/__generated__/QuizDescriptionList_quiz.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import Text from '../../core/Text';
import UnitDItem from '../../unitD/UnitDItem';
import UserAvatarText from '../../user/UserAvatarText';
import QuizStatusLabel from '../QuizStatusLabel';

const QuizDescriptionList_quiz = graphql`
  fragment QuizDescriptionList_quiz on Quiz {
    id

    answer
    unitD {
      id
      ...UnitDItem_unitD
    }
    description

    createdBy {
      id
      ...UserAvatarText_user
    }
    created
    ...QuizStatusLabel_quiz
  }
`;

type Props = {
  quiz: QuizDescriptionList_quiz$key;
  type?: 'default' | 'activity';
} & Omit<DescriptionListProps<QuizDescriptionList_quiz$data>, 'item' | 'itemDescriptions' | 'picks'>;
const QuizDescriptionList = ({ quiz: quizReference, type = 'default', ...props }: Props) => {
  const quiz = useFragment(QuizDescriptionList_quiz, quizReference);

  return (
    <DescriptionList
      item={quiz}
      itemDescriptions={{
        description: {
          title: '설명',
          renderValue: ({ description }) =>
            description ? (
              <Text fontSize={1} sx={{ whiteSpace: 'pre-wrap' }}>
                {description}
              </Text>
            ) : undefined,
        },
        answer: {
          title: '정답',
          renderValue: ({ answer }) => <Text fontSize={1}>{answer}</Text>,
        },
        unitD: {
          title: 'unitD',
          renderValue: ({ unitD }) => <UnitDItem unitD={unitD} />,
        },
        createdBy: {
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
        },
        created: {
          title: '제작일',
          renderValue: ({ created }) => <DateText fontSize={1}>{created}</DateText>,
        },
        status: {
          title: '상태',
          renderValue: (quiz) => <QuizStatusLabel quiz={quiz} />,
        },
      }}
      picks={
        type === 'default'
          ? ['description', 'answer', 'unitD']
          : type === 'activity'
          ? ['createdBy', 'created', 'status']
          : []
      }
      {...props}
    />
  );
};

export default QuizDescriptionList;
