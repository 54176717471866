/**
 * @generated SignedSource<<6f5e27d51ed2622909b2ac5e40777243>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScrapSourceActionEnum = "scrap_source_delete" | "scrap_source_scrap_create" | "scrap_source_scraps_delete" | "scrap_source_submit" | "scrap_source_submit_cancel" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScrapSourceActionsMutationButtonStack_ScrapSource$data = {
  readonly actions: ReadonlyArray<ScrapSourceActionEnum>;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapCreateDialog_scrapSource">;
  readonly " $fragmentType": "ScrapSourceActionsMutationButtonStack_ScrapSource";
};
export type ScrapSourceActionsMutationButtonStack_ScrapSource$key = {
  readonly " $data"?: ScrapSourceActionsMutationButtonStack_ScrapSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceActionsMutationButtonStack_ScrapSource">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScrapSourceActionsMutationButtonStack_ScrapSource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScrapCreateDialog_scrapSource"
    }
  ],
  "type": "ScrapSource",
  "abstractKey": null
};

(node as any).hash = "285921715aa9016648580413588ab1c5";

export default node;
