import React, { useEffect, useRef } from 'react';

import View, { ViewProps } from '../../core/View';

type Props = {
  onScrollReachEnd?: () => void;
} & Pick<ViewProps, 'height'>;

const ScrollContainer = (
  { height = '100%', onScrollReachEnd, children }: React.PropsWithChildren<Props>,
  ref: React.Ref<HTMLDivElement>,
) => {
  const handleIntersection: IntersectionObserverCallback = ([entry]) => {
    if (entry.intersectionRatio <= 0) return;
    onScrollReachEnd?.();
  };

  const observedRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(handleIntersection, {
      root: ref && 'current' in ref ? ref.current : null,
      rootMargin: '0px 0px 1px 0px',
    });

    if (observedRef.current) {
      intersectionObserver.observe(observedRef.current);
    }

    return () => {
      if (observedRef.current) {
        intersectionObserver.unobserve(observedRef.current);
      }
    };
  }, [onScrollReachEnd]);

  return (
    <View ref={ref} sx={{ height, overflowY: 'auto' }}>
      {children}
      <View ref={observedRef} sx={{ height: 1, width: '100%' }} />
    </View>
  );
};

export default React.forwardRef(ScrollContainer);
