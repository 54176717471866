import { CheckIcon, XIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import {
  TaskConnectionDataTable_taskConnection$data,
  TaskConnectionDataTable_taskConnection$key,
} from '../../../relay/__generated__/TaskConnectionDataTable_taskConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import StyledOcticon from '../../core/StyledOcticon';
import UserAvatarText from '../../user/UserAvatarText';
import TaskStatusLabel from '../TaskStatusLabel';

const TaskConnectionDataTable_taskConnection = graphql`
  fragment TaskConnectionDataTable_taskConnection on TaskConnection {
    edges {
      node {
        id
        sequence
        status
        ...TaskStatusLabel_task
        problem {
          id
          problemType
        }
        title
        unitA {
          id
          title
        }
        unitB {
          id
          title
        }
        unitC {
          id
          title
        }
        unitD {
          id
          description
        }
        assignedTo {
          id
          ...UserAvatarText_user
        }
        completedBy {
          id
          ...UserAvatarText_user
        }
        completed
        difficulty
        problemGenerator {
          id
        }
        solutionVideo {
          id
        }
        hint {
          id
          publishedText
        }
        submitFeedback {
          id
          publishedSpecial
          publishedGeneral
        }
        typingAutomationScript {
          id
        }
      }
    }
  }
`;

type Props = {
  taskConnection: TaskConnectionDataTable_taskConnection$key;
} & Omit<DataTableProps<TaskConnectionDataTable_taskConnection$data['edges'][number]['node']>, 'rows' | 'columns'>;

const TaskConnectionDataTable = ({ taskConnection, ...props }: Props) => {
  const { edges } = useFragment(TaskConnectionDataTable_taskConnection, taskConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        { field: 'sequence', title: 'ID', renderValue: ({ sequence }) => sequence, width: 80 },
        {
          field: 'TaskStatusLabel_task',
          title: '상태',
          renderValue: (node) => <TaskStatusLabel task={node} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'problem.problemType',
          title: '창작 방식',
          renderValue: ({ problem }) =>
            problem ? <EnumPair typename={'TaskProblemTypeEnum'}>{problem.problemType}</EnumPair> : '-',
          width: 85,
        },
        {
          field: 'title',
          title: '제목',
          renderValue: ({ title }) => title,
          width: 300,
        },
        {
          field: 'unitA',
          title: 'Unit A',
          renderValue: ({ unitA }) => unitA?.title || '-',
          width: 80,
        },
        {
          field: 'unitB',
          title: 'Unit B',
          renderValue: ({ unitB }) => unitB?.title || '-',
          width: 180,
        },
        {
          field: 'unitC',
          title: 'Unit C',
          renderValue: ({ unitC }) => unitC?.title || '-',
          width: 180,
        },
        {
          field: 'unitD',
          title: 'Unit D',
          renderValue: ({ unitD }) => unitD?.description || '-',
          width: 180,
        },
        {
          field: 'difficulty',
          title: 'Rating',
          renderValue: ({ difficulty }) => (difficulty ? difficulty : '-'),
          width: 40,
          align: 'center',
        },
        {
          field: 'problemGenerator',
          title: '자동 생성기',
          renderValue: ({ problemGenerator }) =>
            problemGenerator?.id ? (
              <StyledOcticon icon={CheckIcon} sx={{ color: 'success.fg' }} />
            ) : (
              <StyledOcticon icon={XIcon} sx={{ color: 'danger.fg' }} />
            ),
          width: 85,
          align: 'center',
        },
        {
          field: 'assignedTo.id',
          title: '배정',
          renderValue: ({ assignedTo }) =>
            assignedTo?.id ? (
              <StyledOcticon icon={CheckIcon} sx={{ color: 'success.fg' }} />
            ) : (
              <StyledOcticon icon={XIcon} sx={{ color: 'danger.fg' }} />
            ),
          width: 70,
          align: 'center',
        },
        {
          field: 'assignedTo',
          title: '작업자',
          renderValue: ({ assignedTo }) => (assignedTo ? <UserAvatarText user={assignedTo} /> : '-'),
          width: 92,
        },
        {
          field: 'completedBy',
          title: '완료자',
          renderValue: ({ completedBy }) => (completedBy ? <UserAvatarText user={completedBy} /> : '-'),
          width: 92,
        },
        {
          field: 'completed',
          title: '완료일',
          renderValue: ({ completed }) => (completed ? <DateText>{completed}</DateText> : '-'),
          width: 160,
        },
        {
          field: 'solutionVideo',
          title: '해설 영상',
          renderValue: ({ solutionVideo }) =>
            solutionVideo ? (
              <StyledOcticon icon={CheckIcon} sx={{ color: 'success.fg' }} />
            ) : (
              <StyledOcticon icon={XIcon} sx={{ color: 'danger.fg' }} />
            ),
          width: 70,
          align: 'center',
        },
        {
          field: 'hint.publishedText',
          title: '힌트',
          renderValue: ({ hint }) =>
            hint?.publishedText ? <StyledOcticon icon={CheckIcon} sx={{ color: 'success.fg' }} /> : '-',
          width: 70,
          align: 'center',
        },
        {
          field: 'submitFeedback.publishedSpecial',
          title: '특정',
          renderValue: ({ submitFeedback }) =>
            submitFeedback?.publishedSpecial ? <StyledOcticon icon={CheckIcon} sx={{ color: 'success.fg' }} /> : '-',
          width: 70,
          align: 'center',
        },
        {
          field: 'submitFeedback.publishedGeneral',
          title: '일반',
          renderValue: ({ submitFeedback }) =>
            submitFeedback?.publishedGeneral ? <StyledOcticon icon={CheckIcon} sx={{ color: 'success.fg' }} /> : '-',
          width: 70,
          align: 'center',
        },
        {
          field: 'typingAutomationScript',
          title: '자동 타이핑',
          renderValue: ({ typingAutomationScript }) =>
            typingAutomationScript ? <StyledOcticon icon={CheckIcon} sx={{ color: 'success.fg' }} /> : '-',
          width: 90,
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

export default TaskConnectionDataTable;
