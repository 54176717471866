import { themeGet } from '@primer/react';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type Props = {};

const Kbd = ({ children }: PropsWithChildren<Props>) => {
  return <BaseKbd>{children}</BaseKbd>;
};

const BaseKbd = styled.kbd`
  padding-right: ${themeGet('space.1')};
  padding-left: ${themeGet('space.1')};

  background-color: ${themeGet('colors.neutral.muted')};
  border-radius: ${themeGet('radii.1')};

  font-size: ${themeGet('fontSizes.0')};
  border: 1px solid ${themeGet('colors.border.default')};
  box-shadow: 0 1px 0 ${themeGet('colors.border.muted')};

  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export default Kbd;
