/**
 * @generated SignedSource<<5f8e1645f7c7e2762f15589a16a68dd3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceUploadDifferenceItemList_difference$data = {
  readonly innerPdf?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly " $fragmentType": "SchoolExamSourceUploadDifferenceItemList_difference";
};
export type SchoolExamSourceUploadDifferenceItemList_difference$key = {
  readonly " $data"?: SchoolExamSourceUploadDifferenceItemList_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceUploadDifferenceItemList_difference">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceUploadDifferenceItemList_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "innerPdf",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DiffItem_diff"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "SchoolExamSourceUploadDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};

(node as any).hash = "19e9a2bb4f4b92be29527f55a6e8a014";

export default node;
