import { KebabHorizontalIcon, PencilIcon, TrashIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React from 'react';
import { graphql } from 'react-relay';

import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Card from '../../components/core/Card';
import DialogButton from '../../components/core/DialogButton';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import StoreStuffDescriptionList from '../../components/storeStuff/StoreStuffDescriptionList';
import StoreStuffUpdateDialog from '../../components/storeStuff/StoreStuffUpdateDialog';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { StoreStuffId_storeStuffDeleteMutation } from '../../relay/__generated__/StoreStuffId_storeStuffDeleteMutation.graphql';
import { StoreStuffId_storeStuffQuery } from '../../relay/__generated__/StoreStuffId_storeStuffQuery.graphql';
import { NextPage } from '../_app';

const storeStuffForStoreStuffId = graphql`
  query StoreStuffId_storeStuffQuery($id: ID!) {
    storeStuff(id: $id) {
      id
      title
      actions
      stuff {
        id
        title
      }
      ...StoreStuffDescriptionList_storeStuff
      ...StoreStuffUpdateDialog_storeStuff
    }
  }
`;

type Props = {};

const StoreStuffId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const [{ storeStuff }] = useLazyLoadQuery<StoreStuffId_storeStuffQuery>(storeStuffForStoreStuffId, {
    id: router.query.storeStuffId as string,
  });
  if (!storeStuff) return null;
  const { id, title, actions, stuff } = storeStuff;

  return (
    <View>
      <Head siteTitle={`상품 - ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h2'}>{`${title}${stuff ? ` / ${stuff.title}` : ''}`}</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              {actions.includes('store_stuff_update') ? (
                <Stack.Item>
                  <DialogButton
                    renderDialog={({ closeDialog, isOpen }) => (
                      <StoreStuffUpdateDialog
                        storeStuff={storeStuff}
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        wide
                        config={{
                          onCompleted: () => {
                            toast('수정이 완료됐어요', 'success');
                            closeDialog();
                          },
                          onError: () => {
                            toast('다시 수정해 주세요', 'error');
                          },
                        }}
                      />
                    )}
                    size={'large'}
                    variant={'outline'}
                    leadingIcon={PencilIcon}
                  >
                    수정하기
                  </DialogButton>
                </Stack.Item>
              ) : null}
              {actions.includes('store_stuff_delete') ? (
                <Stack.Item>
                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.MutationItem<StoreStuffId_storeStuffDeleteMutation>
                          mutation={graphql`
                            mutation StoreStuffId_storeStuffDeleteMutation($input: StoreStuffDeleteInput!) {
                              storeStuffDelete(input: $input) {
                                id @deleteRecord
                              }
                            }
                          `}
                          input={{ id }}
                          variant={'danger'}
                          config={{
                            onCompleted: () => {
                              router.replace('/storeStuff').then(() => toast('상품 삭제 완료!', 'success'));
                            },
                          }}
                          message={'정말 삭제할까요?'}
                        >
                          <ActionList.LeadingVisual>
                            <TrashIcon />
                          </ActionList.LeadingVisual>
                          삭제하기
                        </ActionList.MutationItem>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Stack.Item>
              ) : null}
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <Text as={'h3'}>상품 정보</Text>
            <Card sx={{ padding: 4 }}>
              <StoreStuffDescriptionList titleUnitSize={1 / 4} descriptionUnitSize={3 / 4} storeStuff={storeStuff} />
            </Card>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <StoreStuffDescriptionList
              type={'activity'}
              storeStuff={storeStuff}
              titleUnitSize={'max'}
              descriptionUnitSize={'min'}
              renderTitle={(value) => <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{value}</Text>}
            />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

StoreStuffId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
StoreStuffId.authenticated = true;
StoreStuffId.routes = [
  {
    id: 'storeStuffId',
    pathname: '/storeStuff/[storeStuffId]',
    name: '상품 상세',
    permissions: ['store_stuff_read'],
  },
];

export default StoreStuffId;
