import { graphql, useFragment } from 'react-relay';

import { UserUpdateDialog_user$key } from '../../../relay/__generated__/UserUpdateDialog_user.graphql';
import { UserUpdateDialog_userUpdateMutation } from '../../../relay/__generated__/UserUpdateDialog_userUpdateMutation.graphql';
import { formatDate, formatISO } from '../../../utils/date';
import Button from '../../core/Button';
import DateField from '../../core/DateField';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import UserAvatarText from '../UserAvatarText';

const UserUpdateDialog_user = graphql`
  fragment UserUpdateDialog_user on User {
    id
    slackId
    joinedAt
    ...UserAvatarText_user
  }
`;

type Props = { user: UserUpdateDialog_user$key } & DialogProps &
  Pick<MutationFormikProps<UserUpdateDialog_userUpdateMutation>, 'config'>;

const UserUpdateDialog = ({ config, user, ...props }: Props) => {
  const _user = useFragment(UserUpdateDialog_user, user);
  const { id, slackId, joinedAt } = _user;

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Stack gapX={1}>
          <Stack.Item>
            <UserAvatarText fontSize={2} fontWeight={'bold'} user={_user} />
          </Stack.Item>
          <Stack.Item>
            <Text fontSize={2} fontWeight={'bold'}>
              정보 수정하기
            </Text>
          </Stack.Item>
        </Stack>
      </Dialog.Header>
      <MutationFormik<UserUpdateDialog_userUpdateMutation>
        mutation={graphql`
          mutation UserUpdateDialog_userUpdateMutation($input: UserUpdateInput!) {
            userUpdate(input: $input) {
              ...UserUpdateDialog_user
            }
          }
        `}
        initialValues={{ id, slackId, joinedAt }}
        config={config}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Dialog.Body>
              <FormLayout>
                <TextField label={'슬랙 아이디'} placeholder={'슬랙 아이디 입력'} name={'slackId'} size={'large'} />
                <DateField
                  label={'입사일'}
                  placeholder={'입사일 입력'}
                  name={'joinedAt'}
                  onChange={(date) => setFieldValue('joinedAt', formatDate(formatISO(date), 'yyyy-MM-dd'))}
                  size={'large'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()}>
                수정하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default UserUpdateDialog;
