import {
  ActionList as PrimerActionList,
  ActionListItemProps,
  ActionListProps as PrimerActionListProps,
} from '@primer/react';
import { KeyboardEvent as ReactKeyboardEvent, MouseEvent as ReactMouseEvent, PropsWithChildren } from 'react';
import { GraphQLTaggedNode, useMutation, UseMutationConfig } from 'react-relay';

import useConfirm, { UseConfirmOptions } from '../../../hooks/useConfirm';

type Props = {} & PrimerActionListProps;

const ActionList = ({ ...props }: PropsWithChildren<Props>) => <PrimerActionList {...props} />;

type ActionListMutationItemProps<
  TMutation extends {
    readonly response: {};
    readonly variables: { readonly input?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
> = {
  mutation: GraphQLTaggedNode;
  input: TMutation['variables']['input'];
  config?: Omit<UseMutationConfig<TMutation>, 'variables'>;
} & ActionListItemProps &
  UseConfirmOptions;

const MutationItem = <
  TMutation extends {
    readonly response: {};
    readonly variables: { readonly input?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
>({
  mutation,
  input,
  config,
  onSelect,
  message,
  ...props
}: ActionListMutationItemProps<TMutation>) => {
  const { confirm } = useConfirm({ message });
  const [mutate] = useMutation(mutation);

  const handleSelect = (e: ReactKeyboardEvent<HTMLLIElement> | ReactMouseEvent<HTMLLIElement, MouseEvent>) => {
    if (confirm()) {
      onSelect?.(e);
      mutate({ ...(config ? config : {}), variables: { input: input } });
    }
  };

  return <PrimerActionList.Item onSelect={handleSelect} {...props} />;
};

export default Object.assign(ActionList, {
  Item: PrimerActionList.Item,
  MutationItem,
  LeadingVisual: PrimerActionList.LeadingVisual,
  TrailingVisual: PrimerActionList.TrailingVisual,
  Description: PrimerActionList.Description,
  Group: PrimerActionList.Group,
});
export type { Props as ActionListProps };
