/**
 * @generated SignedSource<<a1cd95e1785530393f109f564f83af00>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ScrapSourceExamCategoryEnum = "common" | "giha" | "hwak_tong" | "mijukbun" | "none" | "type_ga" | "type_na" | "%future added value";
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamSourceEnum = "evaluation_test" | "military_academy" | "mock_test" | "police_academy" | "sat" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
export type ScrapSourceTypeEnum = "book" | "exam" | "%future added value";
export type ScrapSourceCreateInput = {
  examInfoInput?: ScrapSourceExamInfoInput | null;
  nonExamInfoInput?: ScrapSourceNonExamInfoInput | null;
  problemPdf: Upload;
  solutionPdf: Upload;
  type: ScrapSourceTypeEnum;
  unit: NodeInput;
};
export type ScrapSourceExamInfoInput = {
  category: ScrapSourceExamCategoryEnum;
  grade: ScrapSourceExamGradeEnum;
  month: ScrapSourceExamMonthEnum;
  source: ScrapSourceExamSourceEnum;
  year: ScrapSourceExamYearEnum;
};
export type ScrapSourceNonExamInfoInput = {
  bookName: string;
  bookPublisher: string;
  unitA: NodeInput;
};
export type NodeInput = {
  id: string;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type ScrapSourceCreateDialog_scrapSourceCreateMutation$variables = {
  input: ScrapSourceCreateInput;
};
export type ScrapSourceCreateDialog_scrapSourceCreateMutation$data = {
  readonly scrapSourceCreate: {
    readonly id: string;
  };
};
export type ScrapSourceCreateDialog_scrapSourceCreateMutation = {
  response: ScrapSourceCreateDialog_scrapSourceCreateMutation$data;
  variables: ScrapSourceCreateDialog_scrapSourceCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ScrapSource",
    "kind": "LinkedField",
    "name": "scrapSourceCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScrapSourceCreateDialog_scrapSourceCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScrapSourceCreateDialog_scrapSourceCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fd3c7a70a0752381ad98bbd26c2c65a8",
    "id": null,
    "metadata": {},
    "name": "ScrapSourceCreateDialog_scrapSourceCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ScrapSourceCreateDialog_scrapSourceCreateMutation(\n  $input: ScrapSourceCreateInput!\n) {\n  scrapSourceCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1845112040230e7029c4d35ceee40731";

export default node;
