import { graphql, useFragment } from 'react-relay';

import { RaffleStuffPreviewItem_raffleStuff$key } from '../../../relay/__generated__/RaffleStuffPreviewItem_raffleStuff.graphql';
import Image from '../../core/Image';
import SquareView from '../../core/SquareView';
import View from '../../core/View';

const RaffleStuffPreviewItem_raffleStuff = graphql`
  fragment RaffleStuffPreviewItem_raffleStuff on RaffleStuff {
    colorThumbnail {
      objectUrl
    }
    backgroundColor
  }
`;

type Props = {
  raffleStuff: RaffleStuffPreviewItem_raffleStuff$key;
};

const RaffleStuffPreviewItem = ({ raffleStuff }: Props) => {
  const { backgroundColor, colorThumbnail } = useFragment(RaffleStuffPreviewItem_raffleStuff, raffleStuff);

  return (
    <SquareView>
      <View
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor,
        }}
      >
        <View sx={{ position: 'relative', width: '80%', height: '80%' }}>
          <Image src={colorThumbnail.objectUrl} alt={colorThumbnail.objectUrl} fill style={{ objectFit: 'contain' }} />
        </View>
      </View>
    </SquareView>
  );
};

export default RaffleStuffPreviewItem;
