/**
 * @generated SignedSource<<cbac51f556eb21af63d1fa9a5396b6a9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type BookTypeEnum = "normal" | "school" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BookUpdateDialog_book$data = {
  readonly coverPdf: {
    readonly key: string;
    readonly objectUrl: string;
  };
  readonly edition: string;
  readonly id: string;
  readonly problemPdf: {
    readonly key: string;
    readonly objectUrl: string;
  };
  readonly publisher: string;
  readonly solutionPdf: {
    readonly key: string;
    readonly objectUrl: string;
  };
  readonly title: string;
  readonly type: BookTypeEnum;
  readonly unitAs: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentType": "BookUpdateDialog_book";
};
export type BookUpdateDialog_book$key = {
  readonly " $data"?: BookUpdateDialog_book$data;
  readonly " $fragmentSpreads": FragmentRefs<"BookUpdateDialog_book">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BookUpdateDialog_book",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UnitA",
      "kind": "LinkedField",
      "name": "unitAs",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publisher",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "edition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "coverPdf",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "problemPdf",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "solutionPdf",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Book",
  "abstractKey": null
};
})();

(node as any).hash = "f3998814cc422237c8348b2e4c89e170";

export default node;
