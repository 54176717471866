/**
 * @generated SignedSource<<fc063a5c55e9c9af7113c86897d8c907>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScrapSourceTypeEnum = "book" | "exam" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScrapSourceConnectionDataTable_scrapSourceConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly id: string;
      readonly sequence: number;
      readonly submittedBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly title: string;
      readonly type: ScrapSourceTypeEnum;
      readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceStatusLabel_scrapSource">;
    };
  }>;
  readonly " $fragmentType": "ScrapSourceConnectionDataTable_scrapSourceConnection";
};
export type ScrapSourceConnectionDataTable_scrapSourceConnection$key = {
  readonly " $data"?: ScrapSourceConnectionDataTable_scrapSourceConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceConnectionDataTable_scrapSourceConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScrapSourceConnectionDataTable_scrapSourceConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ScrapSourceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScrapSource",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ScrapSourceStatusLabel_scrapSource"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "submittedBy",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ScrapSourceConnection",
  "abstractKey": null
};
})();

(node as any).hash = "cd03cbee8418fc236af5248a2f33ebca";

export default node;
