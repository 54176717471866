import { forwardRef, Ref } from 'react';

import View, { ViewProps } from '../View';

type Props = {} & Omit<ViewProps, 'width' | 'ref'>;

const FixedMiniView = ({ sx, ...props }: Props, ref: Ref<HTMLDivElement>) => {
  return <View ref={ref} {...props} sx={{ ...sx, width: 240 }} />;
};

export default forwardRef(FixedMiniView);
export type { Props as FixedMiniViewProps };
