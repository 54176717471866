/**
 * @generated SignedSource<<be3a914c13905240a4d841fb7898c05d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskSolvedProblemUserAnswerUpdateDialog_task$data = {
  readonly problem: {
    readonly answer: string | null;
    readonly id: string;
  } | null;
  readonly sequence: number;
  readonly " $fragmentType": "TaskSolvedProblemUserAnswerUpdateDialog_task";
};
export type TaskSolvedProblemUserAnswerUpdateDialog_task$key = {
  readonly " $data"?: TaskSolvedProblemUserAnswerUpdateDialog_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskSolvedProblemUserAnswerUpdateDialog_task">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskSolvedProblemUserAnswerUpdateDialog_task",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sequence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProblem",
      "kind": "LinkedField",
      "name": "problem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "answer",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};

(node as any).hash = "d55f0e75d6ea3ad2d567936a0dd35464";

export default node;
