import { PaintbrushIcon } from '@primer/octicons-react';
import {
  Emoji,
  MarkdownEditor as PrimerMarkdownEditor,
  MarkdownEditorProps as PrimerMarkdownEditorProps,
} from '@primer/react/drafts';
import { SuggestionOptions } from '@primer/react/lib-esm/drafts/MarkdownEditor/suggestions';
import { sanitize } from 'dompurify';
import { capitalize } from 'lodash-es';
import { useState } from 'react';
import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import useMarked from '../../../hooks/useMarked';
import { MarkdownEditor_usersQuery } from '../../../relay/__generated__/MarkdownEditor_usersQuery.graphql';
import { markdownSxWithTeamturingToken, markdownStyle } from '../Markdown/markdownStyle';

const usersForMarkdownEditor = graphql`
  query MarkdownEditor_usersQuery($filters: UserFilter, $order: UserOrder, $first: Int, $after: String) {
    users(filters: $filters, order: $order, first: $first, after: $after) {
      edges {
        node {
          id
          name
          group {
            id
            title
          }
        }
      }
    }
  }
`;

type Props = {
  label?: string;
  labelConfig?: { visuallyHidden?: boolean };
  required?: boolean;
  withTogglePreviewTokenButton?: boolean;
} & Omit<
  PrimerMarkdownEditorProps,
  'children' | 'onRenderPreview' | 'mentionSuggestions' | 'emojiSuggestions' | 'referenceSuggestions'
>;

const emojis: SuggestionOptions<Emoji> = [
  { name: '+1', character: '👍' },
  { name: '-1', character: '👎' },
  { name: 'heart', character: '❤️' },
  { name: 'wave', character: '👋' },
  { name: 'raised_hands', character: '🙌' },
  { name: 'pray', character: '🙏' },
  { name: 'clap', character: '👏' },
  { name: 'ok_hand', character: '👌' },
  { name: 'fire', character: '🔥' },
];

const MarkdownEditor = ({ label = '', labelConfig, required, sx, withTogglePreviewTokenButton, ...props }: Props) => {
  const { parse } = useMarked({});
  const [withTeamturingToken, setWithTeamturingToken] = useState(false);

  const [{ users }] = useLazyLoadQuery<MarkdownEditor_usersQuery>(usersForMarkdownEditor, {});

  return (
    <PrimerMarkdownEditor
      sx={{
        ...sx,
        '& > div': withTeamturingToken
          ? { ...markdownSxWithTeamturingToken }
          : {
              ...markdownStyle,
            },
        '& > footer': { display: 'none' },
      }}
      onRenderPreview={async (markdown = '') => sanitize(await parse(markdown))}
      mentionSuggestions={users.edges.map(({ node }) => ({
        identifier: capitalize(node.name),
        description: node.group?.title || '',
      }))}
      emojiSuggestions={emojis}
      {...props}
    >
      <PrimerMarkdownEditor.Label visuallyHidden={labelConfig?.visuallyHidden}>
        {label}
        {required ? ' *' : ''}
      </PrimerMarkdownEditor.Label>
      <PrimerMarkdownEditor.Toolbar>
        {withTogglePreviewTokenButton ? (
          <PrimerMarkdownEditor.ToolbarButton
            icon={PaintbrushIcon}
            sx={withTeamturingToken ? { backgroundColor: 'neutral.muted' } : {}}
            onClick={() => setWithTeamturingToken((pre) => !pre)}
            aria-label={'toggle teamturing token to preview'}
          />
        ) : null}
        <PrimerMarkdownEditor.DefaultToolbarButtons />
      </PrimerMarkdownEditor.Toolbar>
    </PrimerMarkdownEditor>
  );
};

export default MarkdownEditor;
export type { Props as MarkdownEditorProps };
