import useGlobalEventListener from '../useGlobalEventListener';

const useShortcutKey = (predicate: (e: KeyboardEvent) => boolean, event: (e?: KeyboardEvent) => void) => {
  useGlobalEventListener('keyup', (e: KeyboardEvent) => {
    if (predicate(e)) {
      event(e);
    }
  });
};

export default useShortcutKey;
