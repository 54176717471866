import { graphql, useFragment } from 'react-relay';

import {
  RaffleStatusEnum,
  RaffleStatusLabel_raffle$key,
} from '../../../relay/__generated__/RaffleStatusLabel_raffle.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const RaffleStatusLabel_raffle = graphql`
  fragment RaffleStatusLabel_raffle on Raffle {
    status
  }
`;

type Props = {
  raffle: RaffleStatusLabel_raffle$key;
} & Omit<LabelProps, 'variant'>;

const RaffleStatusLabel = ({ raffle, ...props }: Props) => {
  const { status } = useFragment(RaffleStatusLabel_raffle, raffle);

  const labelPropsByStatus: Record<RaffleStatusEnum, LabelProps> = {
    'pending': { variant: 'accent' },
    'ongoing': { variant: 'success' },
    'finished': { variant: 'secondary' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'RaffleStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default RaffleStatusLabel;
