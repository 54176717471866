import { HomeIcon, PencilIcon, TrashIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import DialogButton from '../../components/core/DialogButton';
import DialogIconButton from '../../components/core/DialogIconButton';
import EmptyState from '../../components/core/EmptyState';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import ItemList from '../../components/core/ItemList';
import KatexEditorInput from '../../components/core/KatexEditorInput';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationConfirmIconButton from '../../components/core/MutationConfirmIconButton';
import SQLEditor from '../../components/core/SQLEditor';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import Ellipsis from '../../components/draft/Ellipsis';
import NoticeCreateDialog from '../../components/notice/NoticeCreateDialog';
import NoticeItem from '../../components/notice/NoticeItem';
import NoticeUpdateDialog from '../../components/notice/NoticeUpdateDialog';
import NoticeWindowPaginator from '../../components/notice/NoticeWindowPaginator';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { dashboard_noticeDeleteMutation } from '../../relay/__generated__/dashboard_noticeDeleteMutation.graphql';
import { dashboard_noticesQuery } from '../../relay/__generated__/dashboard_noticesQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

type Props = {};

const noticesForDashboard = graphql`
  query dashboard_noticesQuery($filters: NoticeFilter, $order: NoticeOrder, $page: Int, $pageSize: Int) {
    ...NoticeWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

const Dashboard: NextPage<Props> = () => {
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    page: { type: 'number' },
  });
  const { page } = initialValues;

  const [query, refresh] = useLazyLoadQuery<dashboard_noticesQuery>(noticesForDashboard, {
    order: { created: 'DESC' },
    page,
  });

  const { toast } = useToast();

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'대시보드'} />
      <View>
        <Grid sx={{ alignItems: 'center' }} gapX={2}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'}>홈</Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <DialogButton
              size={'large'}
              variant={'primary'}
              renderDialog={({ isOpen, closeDialog }) => (
                <NoticeCreateDialog
                  wide
                  isOpen={isOpen}
                  onDismiss={closeDialog}
                  config={{
                    onCompleted: () => {
                      toast('공지사항 작성이 완료됐어요', 'success');
                      refresh();
                      closeDialog();
                    },
                  }}
                />
              )}
            >
              공지사항 작성하기
            </DialogButton>
          </Grid.Unit>
        </Grid>
        <View>
          <KatexEditorInput type={'default'} />
          <View sx={{ marginTop: 3 }}>
            <SQLEditor />
          </View>
        </View>
        <View sx={{ marginTop: 7 }}>
          <Suspense fallback={<Spinner />}>
            <NoticeWindowPaginator
              fragmentReference={query}
              onLoadPage={(page) => {
                setParsedUrlQuery({ page });
              }}
            >
              {({ notices }, { renderPagination }) => (
                <>
                  <Stack gapX={1}>
                    <Stack.Item>
                      <Text fontWeight={'bold'} fontSize={1}>
                        공지사항
                      </Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Text
                        sx={{ fontSize: 1, fontWeight: 'bold' }}
                        color={notices?.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                      >
                        {numberWithCommas(notices?.totalCount || 0)}
                      </Text>
                    </Stack.Item>
                  </Stack>
                  <View sx={{ marginTop: 2 }}>
                    <ItemList
                      items={notices.edges.map(({ node }) => node).filter((node) => !!node)}
                      renderItem={(notice) => <NoticeItem notice={notice} />}
                      renderItemWrapper={(children, notice, index) => {
                        const { id, actions } = notice;
                        return (
                          <View key={id} sx={{ paddingY: 4 }}>
                            <Grid wrap={false}>
                              <Grid.Unit size={'max'}>
                                {index === 0 ? (
                                  children
                                ) : (
                                  <Ellipsis detail maxHeight={300}>
                                    {children}
                                  </Ellipsis>
                                )}
                              </Grid.Unit>
                              <Grid.Unit size={'min'}>
                                <Stack gapX={1}>
                                  {actions.map((action) =>
                                    action === 'notice_update' ? (
                                      <Stack.Item key={action}>
                                        <DialogIconButton
                                          renderDialog={({ isOpen, closeDialog }) => (
                                            <Suspense>
                                              <NoticeUpdateDialog
                                                isOpen={isOpen}
                                                onDismiss={closeDialog}
                                                wide
                                                notice={notice}
                                                config={{
                                                  onCompleted: () => {
                                                    closeDialog();
                                                  },
                                                }}
                                              />
                                            </Suspense>
                                          )}
                                          icon={PencilIcon}
                                          size={'small'}
                                          variant={'plain'}
                                          aria-label={'update notice'}
                                        />
                                      </Stack.Item>
                                    ) : action === 'notice_delete' ? (
                                      <Stack.Item key={action}>
                                        <MutationConfirmIconButton<dashboard_noticeDeleteMutation>
                                          icon={TrashIcon}
                                          size={'small'}
                                          variant={'plain'}
                                          aria-label={'delete notice'}
                                          mutation={graphql`
                                            mutation dashboard_noticeDeleteMutation($input: NoticeDeleteInput!) {
                                              noticeDelete(input: $input) {
                                                id @deleteRecord
                                              }
                                            }
                                          `}
                                          config={{
                                            onCompleted: () => {
                                              toast('공지사항 삭제가 완료됐어요', 'success');
                                              refresh();
                                            },
                                            onError: () => {
                                              toast('공지사항 삭제에 실패했어요', 'error');
                                            },
                                          }}
                                          message={'정말 삭제할까요?'}
                                          input={{ id }}
                                        />
                                      </Stack.Item>
                                    ) : null,
                                  )}
                                </Stack>
                              </Grid.Unit>
                            </Grid>
                          </View>
                        );
                      }}
                      emptyState={
                        <View sx={{ paddingY: 5 }}>
                          <EmptyState title={'공지사항이 없어요'} />
                        </View>
                      }
                    />
                  </View>
                  <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>{renderPagination?.()}</View>
                </>
              )}
            </NoticeWindowPaginator>
          </Suspense>
        </View>
      </View>
    </View>
  );
};

Dashboard.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
Dashboard.authenticated = true;
Dashboard.routes = [{ id: 'dashboard', pathname: '/dashboard', name: '홈', icon: HomeIcon }];

export default Dashboard;
