import { BellIcon } from '@primer/octicons-react';
import { Space } from '@teamturing/react-kit';
import { subDays } from 'date-fns';
import { camelCase } from 'lodash-es';
import React, { Suspense, useEffect, useState } from 'react';
import { graphql, useMutation, useQueryLoader } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import { NotificationHeaderItem_notificationArrivedSubscription } from '../../../relay/__generated__/NotificationHeaderItem_notificationArrivedSubscription.graphql';
import { NotificationHeaderItem_notificationCheckMutation } from '../../../relay/__generated__/NotificationHeaderItem_notificationCheckMutation.graphql';
import { NotificationHeaderItem_notificationsQuery } from '../../../relay/__generated__/NotificationHeaderItem_notificationsQuery.graphql';
import { NotificationHeaderItem_unchecked_notificationsQuery } from '../../../relay/__generated__/NotificationHeaderItem_unchecked_notificationsQuery.graphql';
import { formatISO, startOfDay } from '../../../utils/date';
import { dynamicFavicon } from '../../../utils/dynamicFavicon';
import { isNullable } from '../../../utils/is';
import { notification } from '../../../utils/notification';
import AnchoredOverlay from '../../core/AnchoredOverlay';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import ItemList from '../../core/ItemList';
import Link from '../../core/Link';
import MutationButton from '../../core/MutationButton';
import OverlayHandler from '../../core/OverlayHandler';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import ScrollContainer from '../../core/ScrollContainer';
import Spinner from '../../core/Spinner';
import SubscriptionRenderer from '../../core/SubscriptionRenderer';
import Text from '../../core/Text';
import View from '../../core/View';
import NotificationItem from '../NotificationItem';
import NotificationPaginator from '../NotificationPaginator';

const uncheckedNotificationForNotificationHeaderItem = graphql`
  query NotificationHeaderItem_unchecked_notificationsQuery($filters: NotificationFilter) {
    notifications(filters: $filters) {
      totalCount
    }
  }
`;

const notificationsForNotificationHeaderItem = graphql`
  query NotificationHeaderItem_notificationsQuery(
    $first: Int
    $order: NotificationOrder
    $filters: NotificationFilter
  ) {
    ...NotificationPaginator_query @arguments(first: $first, order: $order, filters: $filters)
  }
`;

const notificationArrivedForNotificationHeaderItem = graphql`
  subscription NotificationHeaderItem_notificationArrivedSubscription($connections: [ID!]!) {
    notificationArrived @prependNode(connections: $connections, edgeTypeName: "NotificationEdge") {
      id
      title
      description
      nodeId
      typename
      ...NotificationItem_notification
    }
  }
`;

const FallbackComponent = () => (
  <IconButton icon={BellIcon} variant={'plain'} aria-label={'open notification overlay'} />
);

const NotificationHeaderItem = () => {
  const [showRedDot, setShowRedDot] = useState(false);
  const showNewIndicators = () => {
    setShowRedDot(true);
    dynamicFavicon.apply();
  };
  const removeNewIndicators = () => {
    setShowRedDot(false);
    dynamicFavicon.remove();
  };

  const notificationCreatedFilterValue = formatISO(startOfDay(subDays(Date.now(), 30)));

  const [{ notifications: uncheckedNotifications }, refetchUncheckedNotifications] =
    useLazyLoadQuery<NotificationHeaderItem_unchecked_notificationsQuery>(
      uncheckedNotificationForNotificationHeaderItem,
      {
        filters: { checked_Isnull: true, created_Gte: notificationCreatedFilterValue },
      },
    );
  const uncheckedNotificationsTotalCount = uncheckedNotifications?.totalCount ?? 0;
  useEffect(() => {
    if (uncheckedNotificationsTotalCount > 0) {
      showNewIndicators();
    } else removeNewIndicators();
  }, [uncheckedNotificationsTotalCount]);

  const [notificationsReference, loadNotifications] = useQueryLoader<NotificationHeaderItem_notificationsQuery>(
    notificationsForNotificationHeaderItem,
  );
  useEffect(() => {
    if (!notificationsReference)
      loadNotifications({
        first: 10,
        filters: { created_Gte: notificationCreatedFilterValue },
        order: { created: 'DESC' },
      });
  }, []);
  const [isErrorSubscription, setIsErrorSubscription] = useState(false);

  const [checkNoti] = useMutation<NotificationHeaderItem_notificationCheckMutation>(graphql`
    mutation NotificationHeaderItem_notificationCheckMutation($input: NotificationCheckInput!) {
      notificationCheck(input: $input) {
        id
        checked
      }
    }
  `);

  return (
    <>
      <OverlayHandler>
        {({ isOpen, openOverlay, closeOverlay }) =>
          notificationsReference ? (
            <PreloadedQueryRenderer<NotificationHeaderItem_notificationsQuery>
              query={notificationsForNotificationHeaderItem}
              queryReference={notificationsReference}
            >
              {(fragmentReference) => (
                <NotificationPaginator fragmentReference={fragmentReference}>
                  {({ notifications }, { refetch, hasNext, loadMore, isLoadingNext }) => {
                    const openNotificationList = () => {
                      openOverlay();
                      if (isErrorSubscription) refetch({}, { fetchPolicy: 'store-and-network' });
                    };
                    const notiCount = notifications.totalCount ?? 0;
                    return (
                      <AnchoredOverlay
                        open={isOpen}
                        onOpen={openNotificationList}
                        onClose={closeOverlay}
                        width={'medium'}
                        height={'large'}
                        renderAnchor={(anchorProps) => (
                          <SubscriptionRenderer<NotificationHeaderItem_notificationArrivedSubscription>
                            subscription={notificationArrivedForNotificationHeaderItem}
                            config={{
                              variables: { connections: [notifications.__id] },
                              onError: (error) => {
                                console.error(error);
                                setIsErrorSubscription(true);
                              },
                              onNext: (data) => {
                                setIsErrorSubscription(false);
                                if (data) {
                                  showNewIndicators();

                                  const { id, title, description, nodeId, typename } = data.notificationArrived;
                                  notification.show(
                                    {
                                      body: [title, description].filter((value) => !!value).join(' · '),
                                    },
                                    {
                                      onClick: () => {
                                        window.open(typename ? `${camelCase(typename)}/${nodeId ?? ''}` : '', '_blank');
                                        checkNoti({
                                          variables: { input: { id } },
                                          onCompleted: () => {
                                            refetchUncheckedNotifications();
                                          },
                                        });
                                      },
                                    },
                                  );
                                }
                              },
                              updater: (store) => {
                                const storeNotification = store.get(notifications.__id);
                                const storeTotalCount = storeNotification?.getValue('totalCount') as number;
                                storeNotification?.setValue((storeTotalCount ?? 0) + 1, 'totalCount');
                              },
                            }}
                          >
                            {() => (
                              <View position={'relative'}>
                                <IconButton
                                  {...(anchorProps as any)}
                                  icon={BellIcon}
                                  variant={'plain'}
                                  aria-label={'open notification overlay'}
                                />
                                {showRedDot ? (
                                  <View
                                    borderRadius={999}
                                    backgroundColor={'closed.emphasis'}
                                    width={6}
                                    height={6}
                                    position={'absolute'}
                                    right={'4px'}
                                    top={'4px'}
                                  />
                                ) : null}
                              </View>
                            )}
                          </SubscriptionRenderer>
                        )}
                      >
                        <View display={'flex'} flexDirection={'column'} height={'100%'}>
                          <Space py={2} px={3}>
                            <Grid sx={{ alignItems: 'center' }}>
                              <Grid.Unit size={'max'}>
                                <Text fontSize={2} fontWeight={'bold'}>
                                  {`알림 ${notiCount > 99 ? '99+' : notiCount}`}
                                </Text>
                              </Grid.Unit>
                              <Grid.Unit size={'min'}>
                                <MutationButton
                                  mutation={graphql`
                                    mutation NotificationHeaderItem_notificationAllCheckMutation {
                                      notificationAllCheck
                                    }
                                  `}
                                  input={{}}
                                  size={'small'}
                                  config={{
                                    onCompleted: () => {
                                      refetch({}, { fetchPolicy: 'store-and-network' });
                                      removeNewIndicators();
                                    },
                                  }}
                                  variant={'plain'}
                                >
                                  모두 읽음
                                </MutationButton>
                              </Grid.Unit>
                            </Grid>
                          </Space>
                          <View flexGrow={1} overflow={'hidden'}>
                            <ScrollContainer
                              height={'100%'}
                              onScrollReachEnd={() => {
                                if (hasNext && !isLoadingNext) loadMore(20);
                              }}
                            >
                              <ItemList
                                items={notifications.edges}
                                renderItem={({ node }) => <NotificationItem notification={node} />}
                                renderItemWrapper={(children, { node: { id, typename, nodeId, checked } }) => (
                                  <View key={id}>
                                    <Link
                                      href={`/${camelCase(typename)}/${nodeId ?? ''}`}
                                      onClick={() => {
                                        closeOverlay();
                                        if (isNullable(checked))
                                          checkNoti({
                                            variables: { input: { id } },
                                            onCompleted: () => {
                                              refetchUncheckedNotifications();
                                            },
                                          });
                                      }}
                                    >
                                      {children}
                                    </Link>
                                  </View>
                                )}
                                emptyState={
                                  <Space pt={7}>
                                    <EmptyState title={'아직 도착한 알림이 없어요'} />
                                  </Space>
                                }
                              />
                              <View lineHeight={1} textAlign={'center'} pt={3} pb={2}>
                                <Text fontSize={0} color={'border.default'}>
                                  최대 30일까지의 알림을 확인할 수 있어요
                                </Text>
                              </View>
                              {isLoadingNext ? <Spinner size={'small'} sx={{ paddingY: 2 }} /> : null}
                            </ScrollContainer>
                          </View>
                        </View>
                      </AnchoredOverlay>
                    );
                  }}
                </NotificationPaginator>
              )}
            </PreloadedQueryRenderer>
          ) : (
            <FallbackComponent />
          )
        }
      </OverlayHandler>
    </>
  );
};

export default () => (
  <Suspense fallback={<FallbackComponent />}>
    <NotificationHeaderItem />
  </Suspense>
);
// export type { Props as NotificationHeaderItemProps };
