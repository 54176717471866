import createCtx from '../../utils/context';

type DialogContextValue = { isConfirm: boolean; confirmText: string };
type DialogContextFn = Record<`set${Capitalize<keyof DialogContextValue>}`, (newValue: any) => void>;

const [useContext, Provider] = createCtx<Readonly<DialogContextFn & DialogContextValue> | undefined>();

const useDialogContext = () => {
  try {
    return useContext();
  } catch (e) {
    return undefined;
  }
};

export { useDialogContext, Provider as DialogContextProvider };
export type { DialogContextValue };
