import { CheckIcon } from '@primer/octicons-react';
import { useUnmountEffect } from 'framer-motion';
import React, { Suspense } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import { WorkbookCreateDialog_tasksQuery } from '../../../relay/__generated__/WorkbookCreateDialog_tasksQuery.graphql';
import { WorkbookCreateDialog_workbookCreateMutation } from '../../../relay/__generated__/WorkbookCreateDialog_workbookCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairSearchOverlayTokenField from '../../core/EnumPairSearchOverlayTokenField';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import ErrorBoundary from '../../core/ErrorBoundary';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const tasksForWorkbookCreateDialog = graphql`
  query WorkbookCreateDialog_tasksQuery($filters: TaskFilter) {
    tasks(filters: $filters) {
      totalCount
    }
  }
`;

type Props = {} & DialogProps &
  Pick<MutationFormikProps<WorkbookCreateDialog_workbookCreateMutation>, 'config' | 'onSubmit' | 'connections'>;

const WorkbookCreateDialog = ({ config, onSubmit, connections, ...props }: Props) => {
  const [totalCountQueryReference, loadValueQuery, disposeValueQuery] =
    useQueryLoader<WorkbookCreateDialog_tasksQuery>(tasksForWorkbookCreateDialog);

  useUnmountEffect(() => {
    disposeValueQuery();
  });

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>기출 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<WorkbookCreateDialog_workbookCreateMutation>
        mutation={graphql`
          mutation WorkbookCreateDialog_workbookCreateMutation($input: WorkbookCreateInput!) {
            workbookCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{
          title: '',
          grade: 'high_1',
          month: 'month_1',
          year: 'year_2024',
          categories: [],
          sourceName: '',
        }}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <FormLayout>
                <TextField
                  name={'title'}
                  label={'기출명'}
                  placeholder={'기출명 입력'}
                  required
                  caption={'권장 20자 이내 (최대 32자)'}
                  maxLength={32}
                />
                <Grid gapX={3}>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <Suspense fallback={<Spinner />}>
                      <EnumPairSelectField
                        typename={'ScrapSourceExamGradeEnum'}
                        name={'grade'}
                        label={'학년'}
                        required
                        placeholder={'학년 선택'}
                      />
                    </Suspense>
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <Suspense fallback={<Spinner />}>
                      <EnumPairSelectField
                        typename={'ScrapSourceExamMonthEnum'}
                        name={'month'}
                        label={'월'}
                        required
                        placeholder={'월 선택'}
                      />
                    </Suspense>
                  </Grid.Unit>
                </Grid>
                <Suspense fallback={<Spinner />}>
                  <EnumPairSelectField
                    typename={'ScrapSourceExamYearEnum'}
                    name={'year'}
                    label={'연도'}
                    required
                    placeholder={'연도 선택'}
                  />
                </Suspense>
                <Suspense fallback={<Spinner />}>
                  <EnumPairSearchOverlayTokenField
                    typename={'ScrapSourceExamCategoryEnum'}
                    name={'categories'}
                    label={'구분'}
                    required
                    placeholder={'구분 선택'}
                  />
                </Suspense>
                <TextField name={'sourceName'} label={'출제기관'} required placeholder={'출제기관명 입력'} />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button
                onClick={() =>
                  loadValueQuery({
                    filters: {
                      scrapSourceMonth_Exact: values.month,
                      scrapSourceYear_Exact: values.year,
                      scrapSourceGrade_Exact: values.grade,
                      scrapSourceCategory_In: values.categories,
                    },
                  })
                }
                sx={{ mr: 3 }}
              >
                Task 개수 확인
              </Button>
              {totalCountQueryReference ? (
                <Suspense fallback={<Spinner sx={{ mr: 4 }} />}>
                  <ErrorBoundary>
                    <PreloadedQueryRenderer
                      query={tasksForWorkbookCreateDialog}
                      queryReference={totalCountQueryReference}
                    >
                      {({ tasks: { totalCount } }) => (
                        <Text fontSize={1} mt={1} mr={4}>{`${totalCount ?? '?'}개`}</Text>
                      )}
                    </PreloadedQueryRenderer>
                  </ErrorBoundary>
                </Suspense>
              ) : null}
              <Button variant={'primary'} onClick={() => handleSubmit()} leadingIcon={CheckIcon}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default WorkbookCreateDialog;
