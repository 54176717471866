/**
 * @generated SignedSource<<9609a997b7b0831bac4cdd7698aca6ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type StoreStuffTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StoreStuffDescriptionList_storeStuff$data = {
  readonly created: any;
  readonly createdBy: {
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly endAt: any | null;
  readonly id: string;
  readonly period: number | null;
  readonly stuff: {
    readonly id: string;
    readonly title: string;
  } | null;
  readonly type: StoreStuffTypeEnum;
  readonly " $fragmentType": "StoreStuffDescriptionList_storeStuff";
};
export type StoreStuffDescriptionList_storeStuff$key = {
  readonly " $data"?: StoreStuffDescriptionList_storeStuff$data;
  readonly " $fragmentSpreads": FragmentRefs<"StoreStuffDescriptionList_storeStuff">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StoreStuffDescriptionList_storeStuff",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "period",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Stuff",
      "kind": "LinkedField",
      "name": "stuff",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StoreStuff",
  "abstractKey": null
};
})();

(node as any).hash = "8d3205763bb25fb592048c7e44cb9fa7";

export default node;
