/**
 * @generated SignedSource<<6561913ac5e7b9d515cdc39b8fb0c065>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type ProductShowTypeEnum = "non_registered" | "private" | "repurchase" | "store" | "%future added value";
export type ProductStatusEnum = "active" | "inactive" | "ongoing" | "%future added value";
export type StoreStuffTypeEnum = "premium_non_refundable" | "premium_refundable" | "%future added value";
export type ProductFilter = {
  AND?: ProductFilter | null;
  DISTINCT?: boolean | null;
  NOT?: ProductFilter | null;
  OR?: ProductFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  id?: string | null;
  id_Exact?: string | null;
  id_In?: ReadonlyArray<string> | null;
  id_Not_In?: ReadonlyArray<string> | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  showTypes_Contains?: ReadonlyArray<ProductShowTypeEnum> | null;
  showTypes_Contains_Any?: ReadonlyArray<ProductShowTypeEnum> | null;
  status_In?: ReadonlyArray<ProductStatusEnum> | null;
  storeStuffId_In?: ReadonlyArray<string> | null;
  storeStuffType_In?: ReadonlyArray<StoreStuffTypeEnum> | null;
  stuffId_In?: ReadonlyArray<string> | null;
};
export type ProductOrder = {
  created?: Ordering | null;
  id?: Ordering | null;
  modified?: Ordering | null;
};
export type ProductSelectField_productsQuery$variables = {
  filters?: ProductFilter | null;
  order?: ProductOrder | null;
  showAdditionalDiscountAmount?: boolean | null;
};
export type ProductSelectField_productsQuery$data = {
  readonly products: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly additionalDiscountAmount?: number;
        readonly discountPrice: number;
        readonly id: string;
        readonly title: string;
      };
    }>;
  };
};
export type ProductSelectField_productsQuery = {
  response: ProductSelectField_productsQuery$data;
  variables: ProductSelectField_productsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": false,
    "kind": "LocalArgument",
    "name": "showAdditionalDiscountAmount"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "filters",
        "variableName": "filters"
      },
      {
        "kind": "Variable",
        "name": "order",
        "variableName": "order"
      }
    ],
    "concreteType": "ProductConnection",
    "kind": "LinkedField",
    "name": "products",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "discountPrice",
                "storageKey": null
              },
              {
                "condition": "showAdditionalDiscountAmount",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "additionalDiscountAmount",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductSelectField_productsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductSelectField_productsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "456179311dc45aa866aff2b4e1457eee",
    "id": null,
    "metadata": {},
    "name": "ProductSelectField_productsQuery",
    "operationKind": "query",
    "text": "query ProductSelectField_productsQuery(\n  $filters: ProductFilter\n  $order: ProductOrder\n  $showAdditionalDiscountAmount: Boolean = false\n) {\n  products(filters: $filters, order: $order) {\n    edges {\n      node {\n        id\n        title\n        discountPrice\n        additionalDiscountAmount @include(if: $showAdditionalDiscountAmount)\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "638bf5bd71393317d308fe1bbb8b6d1b";

export default node;
