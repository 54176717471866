/**
 * @generated SignedSource<<e23b8610ad5a3edc68244eeac1e11b86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourceUploadDifferenceCounterLabel_difference$data = {
  readonly innerPdf?: {
    readonly __typename: "Diff";
  } | null;
  readonly " $fragmentType": "SchoolExamSourceUploadDifferenceCounterLabel_difference";
};
export type SchoolExamSourceUploadDifferenceCounterLabel_difference$key = {
  readonly " $data"?: SchoolExamSourceUploadDifferenceCounterLabel_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceUploadDifferenceCounterLabel_difference">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourceUploadDifferenceCounterLabel_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "innerPdf",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "SchoolExamSourceUploadDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};

(node as any).hash = "c9ab18d6ed95f82a15f49e6133ea703e";

export default node;
