import { ChevronLeftIcon, ChevronRightIcon, KebabHorizontalIcon, TrashIcon } from '@primer/octicons-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Button from '../../components/core/Button';
import Card from '../../components/core/Card';
import Checkbox from '../../components/core/Checkbox';
import ConfirmButton from '../../components/core/ConfirmButton';
import { RowWrapper } from '../../components/core/DataTable';
import DialogHandler from '../../components/core/DialogHandler';
import EmptyState from '../../components/core/EmptyState';
import EnumPair from '../../components/core/EnumPair';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import Label from '../../components/core/Label';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationFormik from '../../components/core/MutationFormik';
import Stack from '../../components/core/Stack';
import StyledOcticon from '../../components/core/StyledOcticon';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import ScrapSourceActionsMutationButtonStack from '../../components/scrapSource/ScrapSourceActionsMutationButtonStack';
import ScrapSourceDescriptionList from '../../components/scrapSource/ScrapSourceDescriptionList';
import ScrapSourceScrapConnectionDataTable from '../../components/scrapSource/ScrapSourceScrapConnectionDataTable';
import ScrapSourceScrapDeleteDialog from '../../components/scrapSource/ScrapSourceScrapDeleteDialog';
import ScrapSourceScrapPaginator from '../../components/scrapSource/ScrapSourceScrapPaginator';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import {
  ScrapSourceFilter,
  ScrapSourceId_scrapSourceQuery,
} from '../../relay/__generated__/ScrapSourceId_scrapSourceQuery.graphql';
import { ScrapSourceId_scrapSourceScrapsDeleteMutation } from '../../relay/__generated__/ScrapSourceId_scrapSourceScrapsDeleteMutation.graphql';
import { TaskId_taskDeleteMutation } from '../../relay/__generated__/TaskId_taskDeleteMutation.graphql';
import { isNullable } from '../../utils/is';
import { numberWithCommas } from '../../utils/number';
import { parseOrdering } from '../../utils/order';
import { NextPage } from '../_app';

const scrapSourceForScrapSourceId = graphql`
  query ScrapSourceId_scrapSourceQuery($id: ID!, $filters: ScrapSourceFilter, $order: ScrapSourceOrder) {
    scrapSource(id: $id) {
      id
      sequence
      actions
      previous(order: $order, filters: $filters)
      next(order: $order, filters: $filters)
      unit {
        id
        title
      }
      type
      title
      solutionPdf {
        key
        objectUrl
      }
      problemPdf {
        key
        objectUrl
      }
      ...ScrapSourceDescriptionList_scrapSource
      ...ScrapSourceStatusLabel_scrapSource
      ...ScrapSourceActionsMutationButtonStack_ScrapSource
      ...ScrapSourceScrapDeleteDialog_ScrapSource
      ...ScrapSourceScrapPaginator_scrapSource
    }
  }
`;

const ScrapSourceId: NextPage = () => {
  const router = useRouter();
  const { toast } = useToast();

  const { initialValues } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    status_In: { type: 'string', multiple: true },
    type_In: { type: 'string', multiple: true },
    bookName_Contains: { type: 'string' },
    bookPublisher_Contains: { type: 'string' },
    category_In: { type: 'string', multiple: true },
    grade_In: { type: 'string', multiple: true },
    month_In: { type: 'string', multiple: true },
    source_In: { type: 'string', multiple: true },
    unitAId_In: { type: 'string', multiple: true },
    unitId_In: { type: 'string', multiple: true },
    year_In: { type: 'string', multiple: true },

    order: { type: 'string' },
  });

  const { order, ...filters } = initialValues;

  const [{ scrapSource }, refresh] = useLazyLoadQuery<ScrapSourceId_scrapSourceQuery>(scrapSourceForScrapSourceId, {
    id: router.query.scrapSourceId as string,
    order: order ? parseOrdering(order as string) : undefined,
    filters: filters as ScrapSourceFilter,
  });

  if (!scrapSource) return null;
  const {
    id,
    previous: previousScrapSourceId,
    next: nextScrapSourceId,
    actions,
    sequence,
    unit,
    title,
    type,
    problemPdf,
    solutionPdf,
  } = scrapSource;

  const hasPrevious = !isNullable(previousScrapSourceId);
  const handlePreviousButtonClick = () => {
    const pathname = `/scrapSource/${previousScrapSourceId}`;
    router.replace({ pathname, query: initialValues }, pathname);
  };

  const hasNext = !isNullable(nextScrapSourceId);
  const handleNextButtonClick = () => {
    const pathname = `/scrapSource/${nextScrapSourceId}`;
    router.replace({ pathname, query: initialValues }, pathname);
  };

  return (
    <View>
      <Head siteTitle={'PDF 상세'} />
      <View>
        <Grid gapX={2} sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Stack gapX={3}>
              <Stack.Item>
                <IconButton
                  icon={ChevronLeftIcon}
                  aria-label={'Previous ScrapSource Id'}
                  disabled={!hasPrevious}
                  onClick={() => handlePreviousButtonClick()}
                />
              </Stack.Item>
              <Stack.Item>
                <Text as={'h1'}>{sequence}</Text>
              </Stack.Item>
              <Stack.Item>
                <IconButton
                  icon={ChevronRightIcon}
                  aria-label={'Next ScrapSource Id'}
                  disabled={!hasNext}
                  onClick={() => handleNextButtonClick()}
                />
              </Stack.Item>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <ScrapSourceActionsMutationButtonStack scrapSource={scrapSource} />
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            {actions.includes('scrap_source_delete') ? (
              <ActionMenu>
                <ActionMenu.Anchor>
                  <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                </ActionMenu.Anchor>
                <ActionMenu.Overlay>
                  <ActionList>
                    <ActionList.MutationItem<TaskId_taskDeleteMutation>
                      mutation={graphql`
                        mutation ScrapSourceId_scrapSourceDeleteMutation($input: ScrapSourceDeleteInput!) {
                          scrapSourceDelete(input: $input) {
                            id @deleteRecord
                          }
                        }
                      `}
                      input={{ id }}
                      variant={'danger'}
                      config={{
                        onCompleted: () => {
                          router.replace('/scrapSource').then(() => toast('PDF 삭제 완료!', 'success'));
                        },
                        onError: () => {
                          toast('PDF 삭제에 실패했어요', 'error');
                        },
                      }}
                      message={'정말 삭제할까요?'}
                    >
                      <ActionList.LeadingVisual>
                        <TrashIcon />
                      </ActionList.LeadingVisual>
                      삭제하기
                    </ActionList.MutationItem>
                  </ActionList>
                </ActionMenu.Overlay>
              </ActionMenu>
            ) : null}
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>교육과정</Text>
            <Card sx={{ marginTop: 2, padding: 3 }}>
              <Text fontSize={1} fontWeight={'normal'}>
                {unit.title}
              </Text>
            </Card>

            <View sx={{ marginTop: 5 }}>
              <Stack gapX={1}>
                <Stack.Item>
                  <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                </Stack.Item>
                <Stack.Item>
                  <View>
                    <Label variant={'accent'}>
                      <EnumPair typename={'ScrapSourceTypeEnum'}>{type}</EnumPair>
                    </Label>
                  </View>
                </Stack.Item>
              </Stack>
            </View>
            <Card sx={{ marginTop: 2, padding: 3 }}>
              <ScrapSourceDescriptionList
                scrapSource={scrapSource}
                type={type === 'exam' ? 'examInfo' : 'nonExamInfo'}
              />
            </Card>

            <View sx={{ marginTop: 5 }}>
              <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>문제 PDF</Text>
            </View>
            <Card sx={{ marginTop: 2 }}>
              <Link href={problemPdf.objectUrl} target={'_blank'}>
                <Grid gapX={1} wrap={false} sx={{ padding: 3 }}>
                  <Grid.Unit size={'max'}>
                    <Text sx={{ fontSize: 1 }}>{problemPdf.key}</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Text sx={{ fontSize: 1 }} color={'fg.subtle'}>
                      PDF 열기
                    </Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <StyledOcticon icon={ChevronRightIcon} color={'fg.subtle'} />
                  </Grid.Unit>
                </Grid>
              </Link>
            </Card>
            <View sx={{ marginTop: 5 }}>
              <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>해설 PDF</Text>
            </View>
            <Card sx={{ marginTop: 2 }}>
              <Link href={solutionPdf.objectUrl} target={'_blank'}>
                <Grid gapX={1} wrap={false} sx={{ padding: 3 }}>
                  <Grid.Unit size={'max'}>
                    <Text sx={{ fontSize: 1 }}>{solutionPdf.key}</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Text sx={{ fontSize: 1 }} color={'fg.subtle'}>
                      PDF 열기
                    </Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <StyledOcticon icon={ChevronRightIcon} color={'fg.subtle'} />
                  </Grid.Unit>
                </Grid>
              </Link>
            </Card>
            <View sx={{ marginTop: 5 }}>
              <MutationFormik<ScrapSourceId_scrapSourceScrapsDeleteMutation>
                mutation={graphql`
                  mutation ScrapSourceId_scrapSourceScrapsDeleteMutation($input: ScrapSourceScrapsDeleteInput!) {
                    scrapSourceScrapsDelete(input: $input) {
                      id
                      ...ScrapSourceScrapPaginator_scrapSource
                    }
                  }
                `}
                initialValues={{ scrapSource: id, scraps: [] }}
                config={{
                  onCompleted: (response, errors, formikHelpers) => {
                    toast('스크랩이 삭제됐어요', 'success');
                    formikHelpers.resetForm();
                  },
                }}
              >
                {({ submitForm, isSubmitting, values, setFieldValue, resetForm }) => {
                  const canDelete = actions.includes('scrap_source_scraps_delete');
                  return (
                    <>
                      <Grid sx={{ alignItems: 'center' }}>
                        <Grid.Unit size={'max'}>
                          <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>스크랩한 문제</Text>
                        </Grid.Unit>
                        {canDelete ? (
                          <Grid.Unit size={'min'}>
                            <ConfirmButton
                              size={'large'}
                              variant={'danger'}
                              onClick={() => submitForm()}
                              disabled={values.scraps.length === 0}
                              message={'삭제된 스크랩은 되돌릴 수 없어요. 삭제할까요?'}
                            >
                              삭제하기
                            </ConfirmButton>
                          </Grid.Unit>
                        ) : null}
                      </Grid>
                      <View sx={{ marginTop: 3 }}>
                        <ScrapSourceScrapPaginator fragmentReference={scrapSource}>
                          {({ scraps }, { loadMore, hasNext, isLoadingNext }) => (
                            <>
                              <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                {`총 ${numberWithCommas(scraps.totalCount || 0)} 문제${
                                  values.scraps.length > 0 ? ` / ${values.scraps.length} 문제` : ''
                                }`}
                              </Text>
                              <View sx={{ marginTop: 2, overflowX: 'auto' }}>
                                <ScrapSourceScrapConnectionDataTable
                                  scrapConnection={scraps}
                                  emptyState={
                                    <View sx={{ paddingY: 3 }}>
                                      <EmptyState title={'스크랩한 문제가 없어요'} />
                                    </View>
                                  }
                                  renderHead={(columns) => (
                                    <View
                                      as={'thead'}
                                      sx={{
                                        borderBottomWidth: 1,
                                        borderBottomStyle: 'solid',
                                        borderBottomColor: 'border.default',
                                        backgroundColor: 'canvas.subtle',
                                      }}
                                      onClick={(e) => e.stopPropagation()}
                                    >
                                      <View as={'tr'}>
                                        {canDelete ? (
                                          <View
                                            as={'th'}
                                            sx={{ padding: 2, width: 40 }}
                                            onClick={(e) => {
                                              if (e.currentTarget === e.target)
                                                (
                                                  e.currentTarget.querySelector('input[type=checkbox]') as HTMLElement
                                                )?.click();
                                            }}
                                          >
                                            <Checkbox
                                              disabled={isSubmitting || scraps.edges.length === 0}
                                              checked={
                                                values.scraps.length !== 0 &&
                                                values.scraps.length === scraps.edges.length
                                              }
                                              onChange={(e) => {
                                                setFieldValue(
                                                  'scraps',
                                                  e.target.checked ? scraps.edges.map(({ node }) => node.id) : [],
                                                );
                                              }}
                                            />
                                          </View>
                                        ) : null}
                                        {columns.map(({ field, title, width }) => (
                                          <View
                                            key={field}
                                            as={'th'}
                                            sx={{
                                              borderWidth: 1,
                                              borderStyle: 'solid',
                                              borderColor: 'border.default',
                                              minWidth: width,
                                              textAlign: 'start',
                                              padding: 2,
                                              fontWeight: 'bold',
                                              color: 'fg.muted',
                                            }}
                                          >
                                            {title}
                                          </View>
                                        ))}
                                      </View>
                                    </View>
                                  )}
                                  renderRowWrapper={(children, row, index) => (
                                    <RowWrapper
                                      key={index}
                                      sx={{
                                        'cursor': 'pointer',
                                        ':hover': {
                                          backgroundColor: 'canvas.inset',
                                          transition: 'background-color 250ms',
                                        },
                                      }}
                                    >
                                      {canDelete && row.actions.includes('scrap_delete') ? (
                                        <View
                                          as={'td'}
                                          sx={{
                                            cursor: 'default',
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'border.default',
                                            padding: 2,
                                          }}
                                          onClick={(e) => {
                                            if (e.currentTarget === e.target)
                                              (
                                                e.currentTarget.querySelector('input[type=checkbox]') as HTMLElement
                                              )?.click();
                                            e.stopPropagation();
                                          }}
                                        >
                                          <Checkbox
                                            disabled={isSubmitting}
                                            checked={values.scraps.includes(row.id)}
                                            onChange={(e) => {
                                              setFieldValue(
                                                'scraps',
                                                e.target.checked
                                                  ? [...values.scraps, row.id]
                                                  : values.scraps.filter((id) => id !== row.id),
                                              );
                                            }}
                                          />
                                        </View>
                                      ) : null}
                                      <DialogHandler
                                        renderDialog={({ isOpen, closeDialog }) => {
                                          return (
                                            <ScrapSourceScrapDeleteDialog
                                              isOpen={isOpen}
                                              onDismiss={closeDialog}
                                              scrapSource={scrapSource}
                                              initialIndex={index}
                                              full
                                              config={{
                                                onCompleted: () => {
                                                  toast('스크랩한 문제가 삭제됐어요', 'success');
                                                  resetForm();
                                                  refresh();
                                                },
                                              }}
                                            />
                                          );
                                        }}
                                      >
                                        {children}
                                      </DialogHandler>
                                    </RowWrapper>
                                  )}
                                />
                                {hasNext ? (
                                  <Button
                                    sx={{ width: '100%', marginTop: 2 }}
                                    disabled={isLoadingNext}
                                    onClick={() => loadMore(100)}
                                  >
                                    더보기
                                  </Button>
                                ) : null}
                              </View>
                            </>
                          )}
                        </ScrapSourceScrapPaginator>
                      </View>
                    </>
                  );
                }}
              </MutationFormik>
            </View>
          </Grid.Unit>

          <Grid.Unit size={[1, 1, 1 / 4]}>
            <ScrapSourceDescriptionList
              scrapSource={scrapSource}
              type={'activity'}
              titleUnitSize={'max'}
              descriptionUnitSize={'min'}
              renderTitle={(title) => (
                <Text fontWeight={'bold'} fontSize={1}>
                  {title}
                </Text>
              )}
            />
            <View sx={{ marginTop: 6 }} />
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

ScrapSourceId.getLayout = (page) => (
  <HeaderSidebarNavPageLayout>
    <Suspense>{page}</Suspense>
  </HeaderSidebarNavPageLayout>
);
ScrapSourceId.authenticated = true;
ScrapSourceId.routes = [
  {
    id: 'scrapSourceId',
    pathname: '/scrapSource/[scrapSourceId]',
    name: 'PDF 스크랩 상세',
    permissions: ['scrap_source_read'],
  },
];

export default ScrapSourceId;
