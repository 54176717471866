import { graphql } from 'react-relay';

import { useEnumPairsQuery_enumPairsQuery } from '../../relay/__generated__/useEnumPairsQuery_enumPairsQuery.graphql';
import useLazyLoadQuery from '../useLazyLoadQuery';

const enumPairsForUseEnumPairsQuery = graphql`
  query useEnumPairsQuery_enumPairsQuery($typename: String!) {
    enumPairs(typename: $typename) {
      key
      value
    }
  }
`;

const useEnumPairsQuery = (typename: string) => {
  const [{ enumPairs }] = useLazyLoadQuery<useEnumPairsQuery_enumPairsQuery>(enumPairsForUseEnumPairsQuery, {
    typename,
  });
  return enumPairs;
};

export default useEnumPairsQuery;
