import { SxProp } from '@primer/react';
import { PropsWithChildren, useEffect, useRef } from 'react';

import View from '../View';

type Props = {
  text?: string | null;
  highlightSx?: SxProp['sx'];
};

/**
 * @description Do not use this component to wrap whole page. It can be affect performance of browser.
 */
const TextHighlight = ({ children, text = '', highlightSx = {} }: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const markDOMTree = (target: Element) => {
      const regex = new RegExp(text || '', 'gi');

      if (target.firstChild?.nodeType === Node.TEXT_NODE) {
        target.innerHTML = target.innerHTML.replace(regex, (match) => `<mark>${match}</mark>`);
        return;
      }
      if (target.firstElementChild) markDOMTree(target.firstElementChild);
      if (target.nextElementSibling) {
        if (ref.current?.nextElementSibling === target.nextElementSibling) return;
        markDOMTree(target.nextElementSibling);
      }
    };

    if (ref.current) {
      ref.current.innerHTML = ref.current?.innerHTML.replace(/<mark>(.*?)<\/mark>/gim, '$1');
      if (text) {
        markDOMTree(ref.current);
      }
    }
  }, [text]);

  return (
    <View ref={ref} sx={{ mark: { ...highlightSx } }}>
      {children}
    </View>
  );
};

export default TextHighlight;
export type { Props as TextHighLightProps };
