/**
 * @generated SignedSource<<5a3181cb7aef8f57472553a5edc33b01>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskFinalLabelingInput = {
  difficulty: number;
  task: string;
  unitDs: ReadonlyArray<string>;
};
export type TaskFinalLabelingDialog_taskFinalLabelingMutation$variables = {
  input: TaskFinalLabelingInput;
};
export type TaskFinalLabelingDialog_taskFinalLabelingMutation$data = {
  readonly taskFinalLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly task: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"TaskFinalLabelingDialog_task">;
    };
    readonly unitDs: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type TaskFinalLabelingDialog_taskFinalLabelingMutation = {
  response: TaskFinalLabelingDialog_taskFinalLabelingMutation$data;
  variables: TaskFinalLabelingDialog_taskFinalLabelingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "difficulty",
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": {
      "order": "DESC"
    }
  }
],
v5 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "UnitD",
  "kind": "LinkedField",
  "name": "unitDs",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
},
v6 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": (v4/*: any*/),
  "concreteType": "UnitD",
  "kind": "LinkedField",
  "name": "unitDs",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitATitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitBTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unitCTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    }
  ],
  "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
},
v8 = [
  (v2/*: any*/),
  (v3/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskFinalLabelingDialog_taskFinalLabelingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TaskLabeling",
        "kind": "LinkedField",
        "name": "taskFinalLabeling",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TaskFinalLabelingDialog_task"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskFinalLabelingDialog_taskFinalLabelingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TaskLabeling",
        "kind": "LinkedField",
        "name": "taskFinalLabeling",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sequence",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Scrap",
                "kind": "LinkedField",
                "name": "scrap",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "problems",
                    "plural": true,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "solutions",
                    "plural": true,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Unit",
                "kind": "LinkedField",
                "name": "unit",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolYears",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schoolGrades",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskProblem",
                "kind": "LinkedField",
                "name": "problem",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "problem",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "solution",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "problemType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answerChoiceCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answerType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskLabeling",
                "kind": "LinkedField",
                "name": "finalLabeling",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskLabeling",
                "kind": "LinkedField",
                "name": "firstLabeling",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TaskLabeling",
                "kind": "LinkedField",
                "name": "secondLabeling",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3afc1fdec2d774cba0201fbef05f8a65",
    "id": null,
    "metadata": {},
    "name": "TaskFinalLabelingDialog_taskFinalLabelingMutation",
    "operationKind": "mutation",
    "text": "mutation TaskFinalLabelingDialog_taskFinalLabelingMutation(\n  $input: TaskFinalLabelingInput!\n) {\n  taskFinalLabeling(input: $input) {\n    id\n    difficulty\n    unitDs(order: {order: DESC}) {\n      id\n    }\n    task {\n      id\n      ...TaskFinalLabelingDialog_task\n    }\n  }\n}\n\nfragment PreviousLabelingDialog_task on Task {\n  id\n  difficulty\n  unitDs(order: {order: DESC}) {\n    id\n    ...UnitDItem_unitD\n  }\n  firstLabeling {\n    id\n    difficulty\n    unitDs(order: {order: DESC}) {\n      id\n      ...UnitDItem_unitD\n    }\n  }\n  secondLabeling {\n    id\n    difficulty\n    unitDs(order: {order: DESC}) {\n      id\n      ...UnitDItem_unitD\n    }\n  }\n}\n\nfragment TaskFinalLabelingDialog_task on Task {\n  id\n  title\n  sequence\n  scrap {\n    id\n    problems {\n      id\n      objectUrl\n    }\n    solutions {\n      id\n      objectUrl\n    }\n  }\n  unit {\n    id\n    schoolType\n    schoolYears\n    schoolGrades\n  }\n  problem {\n    id\n    problem\n    solution\n    problemType\n    answer\n    answerChoiceCount\n    answerType\n  }\n  finalLabeling {\n    id\n    difficulty\n    unitDs(order: {order: DESC}) {\n      id\n    }\n  }\n  ...PreviousLabelingDialog_task\n}\n\nfragment UnitDItem_unitD on UnitD {\n  id\n  unitATitle\n  unitBTitle\n  unitCTitle\n  description\n}\n"
  }
};
})();

(node as any).hash = "35d8a5544254f2b1cd16f6dd99076c11";

export default node;
