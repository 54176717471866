import { graphql, useFragment } from 'react-relay';

import { FormulaDifferenceItemList_formulaDifference$key } from '../../../relay/__generated__/FormulaDifferenceItemList_formulaDifference.graphql';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import Text from '../../core/Text';
import View from '../../core/View';
import DiffItem from '../../difference/DiffItem';

const FormulaDifferenceItemList_formulaDifference = graphql`
  fragment FormulaDifferenceItemList_formulaDifference on FormulaDifference {
    text {
      ...DiffItem_diff
    }
    title {
      ...DiffItem_diff
    }
  }
`;

type Props = {
  formulaDifference: FormulaDifferenceItemList_formulaDifference$key;
};

const FormulaDifferenceItemList = ({ formulaDifference }: Props) => {
  const { text, title } = useFragment(FormulaDifferenceItemList_formulaDifference, formulaDifference);

  const diffItems = [
    { title: '제목', diff: title, renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex> },
    {
      title: '내용',
      diff: text,
      renderItem: (beforeOrAfter: string) => <Katex>{beforeOrAfter}</Katex>,
    },
  ];

  return (
    <ItemList
      items={diffItems}
      renderItem={({ title, diff, renderItem }) => (
        <>
          <Text sx={{ fontSize: 1 }}>{title}</Text>
          <View sx={{ marginTop: 1 }}>
            <DiffItem diff={diff!} renderItem={renderItem} />
          </View>
        </>
      )}
      renderItemWrapper={(children, { title }, index) => (
        <View key={title} sx={{ marginTop: index > 0 ? 2 : 0 }}>
          {children}
        </View>
      )}
    />
  );
};

export default FormulaDifferenceItemList;
