/**
 * @generated SignedSource<<3a695d97c2d3c4ed1ce32605e0626edd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPushActionEnum = "app_push_create" | "app_push_delete" | "%future added value";
export type AppPushId_appPushQuery$variables = {
  id: string;
};
export type AppPushId_appPushQuery$data = {
  readonly appPush: {
    readonly actions: ReadonlyArray<AppPushActionEnum>;
    readonly deepLinkParams: string | null;
    readonly deepLinkUrl: string | null;
    readonly description: string | null;
    readonly id: string;
    readonly pushBody: string;
    readonly pushTitle: string;
    readonly query: string;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"AppPushDescriptionList_appPush">;
  };
};
export type AppPushId_appPushQuery = {
  response: AppPushId_appPushQuery$data;
  variables: AppPushId_appPushQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pushTitle",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pushBody",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deepLinkParams",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deepLinkUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "query",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppPushId_appPushQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppPush",
        "kind": "LinkedField",
        "name": "appPush",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AppPushDescriptionList_appPush"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppPushId_appPushQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppPush",
        "kind": "LinkedField",
        "name": "appPush",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "destination",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3424b17a0a9879fb2ede2783b0b033fa",
    "id": null,
    "metadata": {},
    "name": "AppPushId_appPushQuery",
    "operationKind": "query",
    "text": "query AppPushId_appPushQuery(\n  $id: ID!\n) {\n  appPush(id: $id) {\n    id\n    title\n    actions\n    description\n    pushTitle\n    pushBody\n    deepLinkParams\n    deepLinkUrl\n    query\n    ...AppPushDescriptionList_appPush\n  }\n}\n\nfragment AppPushDescriptionList_appPush on AppPush {\n  id\n  startAt\n  pushTitle\n  pushBody\n  destination\n  created\n  ...AppPushStatusLabel_appPush\n}\n\nfragment AppPushStatusLabel_appPush on AppPush {\n  status\n}\n"
  }
};
})();

(node as any).hash = "ba907458a6836f06a77c17eca7e72f17";

export default node;
