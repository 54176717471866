import { graphql } from 'react-relay';

import { useOrderPairsQuery_orderPairsQuery } from '../../relay/__generated__/useOrderPairsQuery_orderPairsQuery.graphql';
import useLazyLoadQuery from '../useLazyLoadQuery';

const orderPairsForUseOrderPairsQuery = graphql`
  query useOrderPairsQuery_orderPairsQuery($typename: String!) {
    orderPairs(typename: $typename) {
      key
      value
    }
  }
`;

const useOrderPairsQuery = (typename: string) => {
  const [{ orderPairs }] = useLazyLoadQuery<useOrderPairsQuery_orderPairsQuery>(orderPairsForUseOrderPairsQuery, {
    typename,
  });
  return orderPairs;
};

export default useOrderPairsQuery;
