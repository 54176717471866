import { CheckIcon, PencilIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { FormulaSetMutationActionsButtonStack_formulaSet$key } from '../../../relay/__generated__/FormulaSetMutationActionsButtonStack_formulaSet.graphql';
import DialogButton from '../../core/DialogButton';
import MutationConfirmButton from '../../core/MutationConfirmButton';
import Stack from '../../core/Stack';
import FormulaSetUpdateDialog from '../FormulaSetUpdateDialog';

const FormulaSetMutationActionsButtonStack_formulaSet = graphql`
  fragment FormulaSetMutationActionsButtonStack_formulaSet on FormulaSet {
    id
    actions
    ...FormulaSetUpdateDialog_formulaSet
  }
`;

type Props = {
  formulaSet: FormulaSetMutationActionsButtonStack_formulaSet$key;
  disabled?: boolean;
};

const FormulaSetMutationActionsButtonStack = ({ formulaSet, disabled }: Props) => {
  const data = useFragment(FormulaSetMutationActionsButtonStack_formulaSet, formulaSet);
  const { id, actions } = data;

  const { toast } = useToast();
  const handleCompletedReview = () => {
    toast('개념집 출시 완료!', 'success');
  };
  const handleCompletedUpdate = () => {
    toast('개념 추가 및 수정 완료!', 'success');
  };

  return (
    <Stack gapX={2}>
      {actions.includes('formula_set_update') ? (
        <Stack.Item>
          <DialogButton
            renderDialog={({ isOpen, closeDialog }) => (
              <FormulaSetUpdateDialog
                formulaSet={data}
                isOpen={isOpen}
                onDismiss={closeDialog}
                config={{
                  onCompleted: () => {
                    handleCompletedUpdate();
                    closeDialog();
                  },
                }}
                full
              />
            )}
            disabled={disabled}
            leadingIcon={PencilIcon}
            variant={'primary'}
          >
            추가 및 수정하기
          </DialogButton>
        </Stack.Item>
      ) : null}
      {actions.includes('formula_set_review') ? (
        <Stack.Item>
          <MutationConfirmButton
            leadingIcon={CheckIcon}
            variant={'outline'}
            disabled={disabled}
            mutation={graphql`
              mutation FormulaSetMutationActionsButtonStack_formulaSetReviewMutation($input: FormulaSetReviewInput!) {
                formulaSetReview(input: $input) {
                  id
                  actions
                  ...FormulaSetStatusLabel_formulaSet
                  ...FormulaSetFormulaSetHistoryPaginator_formulaSet
                }
              }
            `}
            input={{ id }}
            message={'이 개념집을 출시할까요?'}
            config={{ onCompleted: handleCompletedReview }}
          >
            검수하기
          </MutationConfirmButton>
        </Stack.Item>
      ) : null}
    </Stack>
  );
};

export default FormulaSetMutationActionsButtonStack;
