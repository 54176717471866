import { forwardRef, Ref } from 'react';

import View, { ViewProps } from '../View';

type Props = {} & ViewProps;

const Card = ({ sx, ...props }: Props, ref: Ref<HTMLDivElement>) => {
  return (
    <View
      ref={ref}
      sx={{
        borderRadius: 2,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'border.default',
        backgroundColor: 'canvas.default',
        overflow: 'hidden',
        ...sx,
      }}
      {...props}
    />
  );
};

export default forwardRef(Card);
export type { Props as CardProps };
