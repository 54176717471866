import { CheckIcon, FileAddedIcon, RocketIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory$key } from '../../../relay/__generated__/SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory.graphql';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Label from '../../core/Label';
import Timeline from '../../core/Timeline';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';

type Props = {
  schoolExamSourceHistory: SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory$key;
};

const SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory = graphql`
  fragment SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory on SchoolExamSourceHistory {
    created
    createdBy {
      id
      ...UserAvatarText_user
    }
    type
  }
`;

const SchoolExamSourceHistoryTimelineItem = ({ schoolExamSourceHistory }: Props) => {
  const { created, createdBy, type } = useFragment(
    SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory,
    schoolExamSourceHistory,
  );

  return (
    <Timeline.Item>
      <Timeline.Badge>
        {type === 'upload' ? <FileAddedIcon /> : type === 'publish' ? <RocketIcon /> : <CheckIcon />}
      </Timeline.Badge>
      <Timeline.Body>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Grid.Unit size={'max'}>
              <View sx={{ display: 'flex', flexDirection: 'column' }}>
                <UserAvatarText user={createdBy} sx={{ fontWeight: 'bold', color: 'fg.muted' }} />
                <View sx={{ marginTop: 2 }}>
                  <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
                </View>
              </View>
            </Grid.Unit>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Label variant={type === 'publish' ? 'success' : 'default'}>
              <EnumPair typename={'SchoolExamSourceHistoryTypeEnum'}>{type}</EnumPair>
            </Label>
          </Grid.Unit>
        </Grid>
      </Timeline.Body>
    </Timeline.Item>
  );
};

export default SchoolExamSourceHistoryTimelineItem;
