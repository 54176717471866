/**
 * @generated SignedSource<<d552d544eface7450bf328c88b2631c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScrapSourceTypeEnum = "book" | "exam" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ScrapSourceScrapDeleteDialog_ScrapSource$data = {
  readonly id: string;
  readonly scraps: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
      };
    }>;
  };
  readonly title: string;
  readonly type: ScrapSourceTypeEnum;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceScrapPaginator_scrapSource">;
  readonly " $fragmentType": "ScrapSourceScrapDeleteDialog_ScrapSource";
};
export type ScrapSourceScrapDeleteDialog_ScrapSource$key = {
  readonly " $data"?: ScrapSourceScrapDeleteDialog_ScrapSource$data;
  readonly " $fragmentSpreads": FragmentRefs<"ScrapSourceScrapDeleteDialog_ScrapSource">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScrapSourceScrapDeleteDialog_ScrapSource",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ScrapConnection",
      "kind": "LinkedField",
      "name": "scraps",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ScrapEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Scrap",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ScrapSourceScrapPaginator_scrapSource"
    }
  ],
  "type": "ScrapSource",
  "abstractKey": null
};
})();

(node as any).hash = "f3d0bf80887907fc797cc075d709d521";

export default node;
