import { CheckCircleIcon, FileAddedIcon, PackageIcon, PencilIcon, XCircleIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import { DeliveryOrderHistoryTimelineItem_deliveryOrderHistory$key } from '../../../relay/__generated__/DeliveryOrderHistoryTimelineItem_deliveryOrderHistory.graphql';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Label from '../../core/Label';
import Timeline from '../../core/Timeline';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';

const DeliveryOrderHistoryTimelineItem_deliveryOrderHistory = graphql`
  fragment DeliveryOrderHistoryTimelineItem_deliveryOrderHistory on DeliveryOrderHistory {
    id
    createdBy {
      id
      ...UserAvatarText_user
    }
    created
    type
  }
`;

type Props = { deliveryOrderHistory: DeliveryOrderHistoryTimelineItem_deliveryOrderHistory$key };

const DeliveryOrderHistoryTimelineItem = ({ deliveryOrderHistory }: Props) => {
  const { created, createdBy, type } = useFragment(
    DeliveryOrderHistoryTimelineItem_deliveryOrderHistory,
    deliveryOrderHistory,
  );
  return (
    <Timeline.Item>
      <Timeline.Badge>
        {type === 'delivery_order_create' ? (
          <FileAddedIcon />
        ) : type === 'delivery_order_update' ? (
          <PencilIcon />
        ) : type === 'delivery_waybill_create' ? (
          <PackageIcon />
        ) : type === 'delivery_order_completed' ? (
          <CheckCircleIcon />
        ) : type === 'delivery_order_canceled' ? (
          <XCircleIcon />
        ) : null}
      </Timeline.Badge>
      <Timeline.Body>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Grid.Unit size={'max'}>
              <View sx={{ display: 'flex', flexDirection: 'column' }}>
                <UserAvatarText user={createdBy} sx={{ fontWeight: 'bold', color: 'fg.muted' }} />
                <View sx={{ marginTop: 2 }}>
                  <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
                </View>
              </View>
            </Grid.Unit>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Label
              variant={
                type === 'delivery_order_create'
                  ? 'default'
                  : type === 'delivery_order_update'
                  ? 'accent'
                  : type === 'delivery_waybill_create'
                  ? 'default'
                  : type === 'delivery_order_completed'
                  ? 'success'
                  : type === 'delivery_order_canceled'
                  ? 'danger'
                  : 'default'
              }
            >
              <EnumPair typename={'DeliveryOrderHistoryTypeEnum'}>{type}</EnumPair>
            </Label>
          </Grid.Unit>
        </Grid>
      </Timeline.Body>
    </Timeline.Item>
  );
};

export default DeliveryOrderHistoryTimelineItem;
