/**
 * @generated SignedSource<<4fbf1fb747c81fd5d36b1436d7e3b7e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeliveryOrderHistoryTypeEnum = "delivery_order_canceled" | "delivery_order_completed" | "delivery_order_create" | "delivery_order_update" | "delivery_waybill_create" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderHistoryTimelineItem_deliveryOrderHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
  };
  readonly id: string;
  readonly type: DeliveryOrderHistoryTypeEnum;
  readonly " $fragmentType": "DeliveryOrderHistoryTimelineItem_deliveryOrderHistory";
};
export type DeliveryOrderHistoryTimelineItem_deliveryOrderHistory$key = {
  readonly " $data"?: DeliveryOrderHistoryTimelineItem_deliveryOrderHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderHistoryTimelineItem_deliveryOrderHistory">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryOrderHistoryTimelineItem_deliveryOrderHistory",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatarText_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "DeliveryOrderHistory",
  "abstractKey": null
};
})();

(node as any).hash = "74790e78413eb2faf331295f1687d5d1";

export default node;
