/**
 * @generated SignedSource<<50c4d6186cf65d859cac79dd29ba5d1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourcePublishDifferenceItemList_difference$data = {
  readonly scrapImages?: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  }> | null;
  readonly " $fragmentType": "SchoolExamSourcePublishDifferenceItemList_difference";
};
export type SchoolExamSourcePublishDifferenceItemList_difference$key = {
  readonly " $data"?: SchoolExamSourcePublishDifferenceItemList_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourcePublishDifferenceItemList_difference">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SchoolExamSourcePublishDifferenceItemList_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "scrapImages",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "DiffItem_diff"
            }
          ],
          "storageKey": null
        }
      ],
      "type": "SchoolExamSourcePublishDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};

(node as any).hash = "547590272b1119a248d4abb2d0d06560";

export default node;
