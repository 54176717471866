import { useRef, useState } from 'react';

import Checkbox, { CheckboxProps } from '../Checkbox';
import View from '../View';

type Props = {
  onChange?: (value: boolean) => void;
  value?: boolean;
} & Omit<CheckboxProps, 'onChange' | 'value' | 'ref' | 'checked' | 'defaultChecked' | 'sx'>;

const Switch = ({ value, name, onChange, disabled, ...props }: Props) => {
  const [isOn, setIsOn] = useState(!!value);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleClick = () => {
    if (disabled) return;
    onChange?.(!isOn);
    setIsOn(!isOn);
  };

  return (
    <View
      as={'label'}
      htmlFor={name}
      sx={{
        backgroundColor: isOn ? (disabled ? 'accent.muted' : 'accent.fg') : 'border.default',
        borderRadius: 3,
        padding: '2px',
        width: '40px',
        display: 'flex',
        justifyContent: isOn ? 'end' : 'start',
        cursor: disabled ? 'not-allowed' : 'pointer',
        overflow: 'hidden',
      }}
      tabIndex={disabled ? -1 : 0}
      onKeyDown={(e) => {
        if (e.code === 'Enter' || e.code === 'Space') inputRef.current?.click();
      }}
    >
      <Checkbox
        ref={inputRef}
        id={name}
        disabled={disabled}
        checked={isOn}
        onChange={handleClick}
        tabIndex={-1}
        sx={{ display: 'none' }}
        {...props}
      />
      <View
        sx={{
          borderStyle: 'none',
          backgroundColor: 'canvas.overlay',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          boxShadow: 'shadow.small',
        }}
      />
    </View>
  );
};

export default Switch;
export type { Props as SwitchProps };
