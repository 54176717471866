import { Checkbox as PrimerCheckbox, CheckboxProps as PrimerCheckboxProps } from '@primer/react';
import React from 'react';

type Props = {} & PrimerCheckboxProps;

const Checkbox = ({ checked = false, ...props }: Props, ref: React.Ref<HTMLInputElement>) => (
  <PrimerCheckbox ref={ref} checked={checked} {...props} />
);

export default React.forwardRef(Checkbox);
export type { Props as CheckboxProps };
