import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import {
  ProductSelectField_productsQuery,
  ProductSelectField_productsQuery$variables,
} from '../../../relay/__generated__/ProductSelectField_productsQuery.graphql';
import { numberWithCommas } from '../../../utils/number';
import SelectField, { SelectFieldProps } from '../../core/SelectField';

const productsForProductSelectField = graphql`
  query ProductSelectField_productsQuery(
    $filters: ProductFilter
    $order: ProductOrder
    $showAdditionalDiscountAmount: Boolean = false
  ) {
    products(filters: $filters, order: $order) {
      edges {
        node {
          id
          title
          discountPrice
          additionalDiscountAmount @include(if: $showAdditionalDiscountAmount)
        }
      }
    }
  }
`;

type Props = {
  variables?: ProductSelectField_productsQuery$variables;
} & Omit<SelectFieldProps, 'options'>;

const ProductSelectField = ({ variables, ...props }: Props) => {
  const [{ products }] = useLazyLoadQuery<ProductSelectField_productsQuery>(productsForProductSelectField, {
    showAdditionalDiscountAmount: false,
    ...variables,
  });

  return (
    <SelectField
      options={products.edges.map(({ node }) => ({
        id: node.id,
        text:
          `${node.title} ` +
          (typeof node.additionalDiscountAmount === 'number'
            ? `(판매가 ${numberWithCommas(node.discountPrice)}원 / 콜 추가 할인 금액 ${numberWithCommas(
                node.additionalDiscountAmount,
              )}원)`
            : `(${numberWithCommas(node.discountPrice)}원)`),
      }))}
      {...props}
    />
  );
};

export default ProductSelectField;
