import { FieldConfig, useField } from 'formik';
import { ComponentProps } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { formatISO } from '../../../utils/date';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import DatePicker, { DatePickerProps } from '../DatePicker';
import FormControl from '../FormControl';
import View from '../View';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
} & Pick<
  DatePickerProps,
  'value' | 'onChange' | 'placeholder' | 'disabled' | 'name' | 'required' | 'size' | 'minDate' | 'maxDate'
> &
  Pick<FieldConfig, 'validate'>;

const DateField = ({
  label,
  labelConfig,
  caption,
  disabled: propDisabled,
  required,
  name = '',
  value: propValue,
  onChange: propOnChange,
  validate,
  ...props
}: Props) => {
  const INPUT_ID = `date_field_for_${label}`;

  const [{ value: baseValue }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();
  const value = !isNullable(propValue) ? propValue : baseValue;

  const baseOnChange: (value: Date) => void = (value) => setValue(formatISO(value));
  const handleChange: DatePickerProps['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else baseOnChange(...arg);
  };

  const disabled = propDisabled || isSubmitting;

  return (
    <FormControl disabled={disabled} required={required}>
      <FormControl.Label {...labelConfig} htmlFor={INPUT_ID}>
        {label}
      </FormControl.Label>
      <View sx={{ width: '100%' }}>
        <DatePicker
          id={INPUT_ID}
          disabled={disabled}
          validationStatus={error ? 'error' : undefined}
          onChange={handleChange}
          value={value}
          {...props}
        />
      </View>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default DateField;
export type { Props as DateFieldProps };
