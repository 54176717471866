import { forwardRef, PropsWithChildren, Ref } from 'react';

import { isArray } from '../../../utils/is';
import View, { ViewProps } from '../View';

type UnitSizeType = 'min' | 'max' | number;

type Props = {
  size: UnitSizeType | UnitSizeType[];
} & Pick<ViewProps, 'sx'>;

const Unit = ({ children, size, sx }: PropsWithChildren<Props>, ref: Ref<HTMLDivElement>) => {
  const getFlexGrowBySize = (size: UnitSizeType) => (size === 'max' ? 1 : size === 'min' ? 0 : 1);
  const getFlexBySize = (size: UnitSizeType) => (size === 'max' ? 1 : size === 'min' ? undefined : undefined);
  const getFlexBasisBySize = (size: UnitSizeType) => {
    const percentage = Math.round((size as number) * 100 * 10000) / 10000;
    return size === 'max' ? 'auto' : size === 'min' ? 'auto' : `${percentage}%`;
  };
  const getWidthBySize = (size: UnitSizeType) => (size === 'max' ? 'auto' : size === 'min' ? 'auto' : undefined);
  const getMaxWidthBySize = (size: UnitSizeType) => {
    const percentage = Math.round((size as number) * 100 * 10000) / 10000;
    return size === 'max' ? '100%' : size === 'min' ? undefined : `${percentage}%`;
  };

  return (
    <View
      ref={ref}
      sx={{
        ...sx,
        flexGrow: isArray(size) ? size.map((s) => getFlexGrowBySize(s)) : getFlexGrowBySize(size),
        flex: isArray(size) ? size.map((s) => getFlexBySize(s)) : getFlexBySize(size),
        flexBasis: isArray(size) ? size.map((s) => getFlexBasisBySize(s)) : getFlexBasisBySize(size),
        width: isArray(size) ? size.map((s) => getWidthBySize(s)) : getWidthBySize(size),
        maxWidth: isArray(size) ? size.map((s) => getMaxWidthBySize(s)) : getMaxWidthBySize(size),
      }}
    >
      {children}
    </View>
  );
};

export default forwardRef(Unit);
export type { Props as GridUnitProps };
