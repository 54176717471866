import { PropsWithChildren, useEffect, useRef, useState } from 'react';

import View from '../../core/View';

type Props = { maxHeight?: number; detail?: boolean };

const Ellipsis = ({ children, maxHeight = 50, detail = false }: PropsWithChildren<Props>) => {
  const viewRef = useRef<HTMLDivElement>(null);
  const [ellipsis, setEllipsis] = useState<boolean>(false);

  useEffect(() => {
    if (viewRef.current && viewRef.current.clientHeight > maxHeight) {
      setEllipsis(true);
    }
  }, []);

  const handleClick = () => setEllipsis(false);

  const isAvailableOpen = detail && ellipsis;

  return (
    <View
      ref={viewRef}
      sx={{
        ...(ellipsis
          ? {
              maxHeight,
              maskImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 1) 50%, rgba(0, 0, 0, 0) 100%)',
            }
          : {}),
        ...(isAvailableOpen ? { cursor: 'pointer' } : {}),
      }}
      onClick={isAvailableOpen ? handleClick : undefined}
    >
      {children}
    </View>
  );
};

export default Ellipsis;
