/**
 * @generated SignedSource<<2cb1b15aeec16218d3b18f10421002ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkbookTasksDeleteInput = {
  id: string;
  taskIds: ReadonlyArray<string>;
};
export type WorkbookId_workbookTasksDeleteMutation$variables = {
  input: WorkbookTasksDeleteInput;
};
export type WorkbookId_workbookTasksDeleteMutation$data = {
  readonly workbookTasksDelete: {
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly tasks: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
      readonly totalCount: number | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"WorkbookStatusLabel_workbook">;
  };
};
export type WorkbookId_workbookTasksDeleteMutation = {
  response: WorkbookId_workbookTasksDeleteMutation$data;
  variables: WorkbookId_workbookTasksDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TaskConnection",
  "kind": "LinkedField",
  "name": "tasks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookId_workbookTasksDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookTasksDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookStatusLabel_workbook"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookId_workbookTasksDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookTasksDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7690a451f97362c840054b9d87ea2a22",
    "id": null,
    "metadata": {},
    "name": "WorkbookId_workbookTasksDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation WorkbookId_workbookTasksDeleteMutation(\n  $input: WorkbookTasksDeleteInput!\n) {\n  workbookTasksDelete(input: $input) {\n    id\n    ...WorkbookStatusLabel_workbook\n    modifiedBy {\n      ...UserAvatarText_user\n      id\n    }\n    modified\n    tasks {\n      totalCount\n      edges {\n        node {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n\nfragment WorkbookStatusLabel_workbook on Workbook {\n  status\n}\n"
  }
};
})();

(node as any).hash = "370050762c958cfcc704adf75c62ef3d";

export default node;
