import { graphql, useFragment } from 'react-relay';

import { TaskProblemCreationDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/TaskProblemCreationDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const TaskProblemCreationDifferenceCounterLabel_difference = graphql`
  fragment TaskProblemCreationDifferenceCounterLabel_difference on Difference {
    ... on TaskProblemCreationDifference {
      answerType {
        __typename
      }
      answer {
        __typename
      }
      answerChoiceCount {
        __typename
      }
      problem {
        __typename
      }
      solution {
        __typename
      }
    }
  }
`;

type Props = { difference: TaskProblemCreationDifferenceCounterLabel_difference$key } & CounterLabelProps;

const TaskProblemCreationDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { problem, solution, answer, answerType, answerChoiceCount } = useFragment(
    TaskProblemCreationDifferenceCounterLabel_difference,
    difference,
  );

  const differenceCount = [problem, solution, answer, answerType, answerChoiceCount].filter(
    (diff) => !isNullable(diff),
  ).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default TaskProblemCreationDifferenceCounterLabel;
