import { CheckIcon, DownloadIcon, KebabHorizontalIcon, QuestionIcon, XIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import {
  TaskVideoConnectionDataTable_taskVideoConnection$data,
  TaskVideoConnectionDataTable_taskVideoConnection$key,
} from '../../../relay/__generated__/TaskVideoConnectionDataTable_taskVideoConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import IconButton from '../../core/IconButton';
import Link from '../../core/Link';
import StyledOcticon from '../../core/StyledOcticon';
import View from '../../core/View';

const TaskVideoConnectionDataTable_taskVideoConnection = graphql`
  fragment TaskVideoConnectionDataTable_taskVideoConnection on TaskVideoConnection {
    edges {
      node {
        id
        type
        title
        teacher {
          id
          name
        }
        created
        video {
          id
          transcodingStatus
        }
        originVideoUrl
      }
    }
  }
`;

type Props = {
  taskVideoConnection: TaskVideoConnectionDataTable_taskVideoConnection$key;
} & Omit<
  DataTableProps<TaskVideoConnectionDataTable_taskVideoConnection$data['edges'][number]['node']>,
  'columns' | 'rows'
>;

const TaskVideoConnectionDataTable = ({ taskVideoConnection, ...props }: Props) => {
  const { edges: taskVideos } = useFragment(TaskVideoConnectionDataTable_taskVideoConnection, taskVideoConnection);

  return (
    <DataTable
      columns={[
        {
          field: 'type',
          title: '타입',
          renderValue: ({ type }) => (type === 'horizontal' ? '가로형' : type === 'vertical' ? '세로형' : ''),
          width: 100,
        },
        {
          field: 'title',
          title: '제목',
          renderValue: ({ title }) => title,
          width: 360,
        },
        {
          field: 'teacher',
          title: '선생님',
          renderValue: ({ teacher }) => teacher.name,
          width: 120,
        },
        {
          field: 'created',
          title: '제작일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
          width: 180,
        },
        {
          field: 'videoTranscodingStatus',
          title: '인코딩',
          renderValue: ({ video }) => (
            <View sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {video.transcodingStatus === 'failed' ? (
                <StyledOcticon icon={XIcon} sx={{ color: 'danger.fg' }} />
              ) : video.transcodingStatus === 'ongoing' ? (
                <KebabHorizontalIcon />
              ) : video.transcodingStatus === 'pending' ? (
                <KebabHorizontalIcon />
              ) : video.transcodingStatus === 'success' ? (
                <StyledOcticon icon={CheckIcon} sx={{ color: 'success.fg' }} />
              ) : (
                <QuestionIcon />
              )}
            </View>
          ),
          width: 80,
        },
        {
          field: 'originVideoUrl',
          title: '다운로드',
          renderValue: ({ originVideoUrl }) => (
            <View sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {originVideoUrl ? (
                <Link href={originVideoUrl} target={'_blank'}>
                  <IconButton icon={DownloadIcon} aria-label={'download'} />
                </Link>
              ) : (
                <IconButton icon={DownloadIcon} aria-label={'download'} disabled />
              )}
            </View>
          ),
          width: 80,
        },
      ]}
      rows={taskVideos.map(({ node }) => node)}
      {...props}
    />
  );
};

export default TaskVideoConnectionDataTable;
