import { graphql, useFragment } from 'react-relay';

import { TaskStatusEnum, TaskStatusLabel_task$key } from '../../../relay/__generated__/TaskStatusLabel_task.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const TaskStatusLabel_task = graphql`
  fragment TaskStatusLabel_task on Task {
    status
  }
`;

type Props = {
  task: TaskStatusLabel_task$key;
} & Omit<LabelProps, 'variant'>;

const TaskStatusLabel = ({ task, ...props }: Props) => {
  const { status } = useFragment(TaskStatusLabel_task, task);

  const labelPropsByStatus: Record<TaskStatusEnum, LabelProps> = {
    'deactivated': { variant: 'attention' },
    'problem_typing': {},
    'problem_creation': {},
    'image_creation': {},
    'first_labeling': {},
    'second_labeling': {},
    'review': {},
    'error_report': { variant: 'danger' },
    'published': { variant: 'success' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'TaskStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default TaskStatusLabel;
