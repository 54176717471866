import { graphql, useFragment } from 'react-relay';

import { TaskVideoDifferenceItemList_difference$key } from '../../../relay/__generated__/TaskVideoDifferenceItemList_difference.graphql';
import { isNullable } from '../../../utils/is';
import EmptyState from '../../core/EmptyState';
import ItemList from '../../core/ItemList';
import Text from '../../core/Text';
import View from '../../core/View';
import DiffItem from '../../difference/DiffItem';

const TaskVideoDifferenceItemList_difference = graphql`
  fragment TaskVideoDifferenceItemList_difference on Difference {
    ... on TaskVideoDifference {
      title {
        ...DiffItem_diff
      }
      description {
        ...DiffItem_diff
      }
      teacherName {
        ...DiffItem_diff
      }
      type {
        ...DiffItem_diff
      }
    }
  }
`;

type Props = {
  difference: TaskVideoDifferenceItemList_difference$key;
};

const TaskVideoDifferenceItemList = ({ difference }: Props) => {
  const { title, description, teacherName, type } = useFragment(TaskVideoDifferenceItemList_difference, difference);

  const diffItems = [
    { title: '제목', diff: title },
    { title: '설명', diff: description },
    { title: '선생님', diff: teacherName },
    { title: '영상 타입', diff: type },
  ].filter(({ diff }) => !isNullable(diff));

  return (
    <ItemList
      items={diffItems}
      renderItem={({ title, diff }) => (
        <>
          <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
          <View sx={{ marginTop: 2 }}>
            <DiffItem diff={diff!} />
          </View>
        </>
      )}
      renderItemWrapper={(children, { title }, i) => (
        <View key={title} sx={{ marginTop: i > 0 ? 3 : 0 }}>
          {children}
        </View>
      )}
      emptyState={
        <View sx={{ paddingY: 6 }}>
          <EmptyState
            title={'기록되지 않은 히스토리이에요'}
            description={'이 기능이 만들어지기 전에 일어난 일이거나 오류로 누락된 히스토리입니다.'}
          />
        </View>
      }
    />
  );
};

export default TaskVideoDifferenceItemList;
