import { FilterIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';

import useEnumPairsQuery from '../../../hooks/useEnumPairsQuery';
import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { parseOrdering } from '../../../utils/order';
import ActionList from '../ActionList';
import ActionMenu, { ActionMenuProps } from '../ActionMenu';
import Button from '../Button';
import EnumPair from '../EnumPair';
import ItemList from '../ItemList';
import OrderPair, { OrderPairProps } from '../OrderPair';

type Props = {
  typename: OrderPairProps['typename'];
  orders: OrderPairProps['children'][];
  onChange?: (newValue: string) => void;
} & Omit<ActionMenuProps, 'children'>;

const OrderActionMenuButton = ({ typename, orders, onChange, ...props }: Props) => {
  const {
    values: { order },
    setFieldValue,
  } = useFormikContext<{ order?: string }>();
  const orderingEnums = useEnumPairsQuery('Ordering').map(({ key }) => key);
  const [field, ordering] = order ? Object.entries(parseOrdering(order))[0] : [];

  const handleSelect = (newValue: string) => {
    setFieldValue('order', newValue);
    onChange?.(newValue);
  };

  return (
    <Suspense>
      <ActionMenu {...props}>
        <ActionMenu.Anchor>
          <Button leadingIcon={FilterIcon} variant={'plain'}>
            {!isNullable(field) && !isNullable(ordering) ? (
              <>
                <OrderPair typename={typename}>{field}</OrderPair> <EnumPair typename={'Ordering'}>{ordering}</EnumPair>
              </>
            ) : (
              '정렬'
            )}
          </Button>
        </ActionMenu.Anchor>
        <ActionMenu.Overlay>
          <ActionList selectionVariant={'single'}>
            <ItemList
              items={orders}
              renderItem={(field) => (
                <ItemList
                  key={field}
                  items={orderingEnums}
                  renderItem={(orderingEnum) => {
                    const itemValue = orderingEnum === 'ASC' ? field : '-' + field;
                    return (
                      <ActionList.Item
                        key={orderingEnum}
                        selected={order === itemValue}
                        onSelect={() => handleSelect(itemValue)}
                      >
                        <OrderPair typename={typename}>{field}</OrderPair>{' '}
                        <EnumPair typename={'Ordering'}>{orderingEnum}</EnumPair>
                      </ActionList.Item>
                    );
                  }}
                />
              )}
            />
          </ActionList>
        </ActionMenu.Overlay>
      </ActionMenu>
    </Suspense>
  );
};

export default OrderActionMenuButton;
