import { graphql, useFragment } from 'react-relay';

import { TaskSequenceItem_task$key } from '../../../relay/__generated__/TaskSequenceItem_task.graphql';
import Text from '../../core/Text';

const TaskSequenceItem_task = graphql`
  fragment TaskSequenceItem_task on Task {
    sequence
  }
`;

type Props = {
  task: TaskSequenceItem_task$key;
};

const TaskSequenceItem = ({ task }: Props) => {
  const { sequence } = useFragment(TaskSequenceItem_task, task);

  return <Text sx={{ fontSize: 1 }}>{sequence}</Text>;
};

export default TaskSequenceItem;
