import { forwardRef, Ref } from 'react';

import FixedMiniView from '../FixedMiniView';
import Katex, { KatexProps } from '../Katex';

type Props = { height?: string | number } & KatexProps;

const ScrollMiniKatex = ({ height = '128px', ...props }: Props, ref: Ref<HTMLDivElement>) => (
  <FixedMiniView ref={ref} sx={{ height, overflow: 'auto' }}>
    <Katex {...props} />
  </FixedMiniView>
);

export default forwardRef(ScrollMiniKatex);
