/**
 * @generated SignedSource<<94ffaf05088ac866a0b11b16d475862f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ScrapSourceExamGradeEnum = "high_1" | "high_2" | "high_3" | "%future added value";
export type ScrapSourceExamMonthEnum = "month_1" | "month_10" | "month_11" | "month_12" | "month_2" | "month_3" | "month_4" | "month_5" | "month_6" | "month_7" | "month_8" | "month_9" | "none" | "%future added value";
export type ScrapSourceExamYearEnum = "year_2017" | "year_2018" | "year_2019" | "year_2020" | "year_2021" | "year_2022" | "year_2023" | "year_2024" | "year_2025" | "year_2026" | "year_2027" | "year_2028" | "year_2029" | "year_2030" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type WorkbookUpdateDialog_workbook$data = {
  readonly grade: ScrapSourceExamGradeEnum;
  readonly id: string;
  readonly month: ScrapSourceExamMonthEnum;
  readonly sourceName: string;
  readonly title: string;
  readonly year: ScrapSourceExamYearEnum;
  readonly " $fragmentType": "WorkbookUpdateDialog_workbook";
};
export type WorkbookUpdateDialog_workbook$key = {
  readonly " $data"?: WorkbookUpdateDialog_workbook$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkbookUpdateDialog_workbook">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkbookUpdateDialog_workbook",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grade",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "month",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "year",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceName",
      "storageKey": null
    }
  ],
  "type": "Workbook",
  "abstractKey": null
};

(node as any).hash = "a8cfaffffbc0475eb08784ef77162dcb";

export default node;
