import { isFunction } from 'lodash-es';
import { useEffect, useState } from 'react';

import createCtx from '../../utils/context';

type ColorMode = 'day' | 'night' | 'light' | 'dark';
type ColorModeWithAuto = ColorMode | 'auto';

type ColorModeContextValue = {
  colorMode: ColorModeWithAuto;
  setColorMode: (colorMode: ColorModeWithAuto) => void;
};

const [useContext, BaseProvider] = createCtx<ColorModeContextValue>();

const LOCALSTORAGE_KEY = 'colorMode';

const getColorModeFromLocalStorage = () => (window.localStorage.getItem(LOCALSTORAGE_KEY) as ColorModeWithAuto) ?? null;
const setColorModeToLocalStorage = (colorMode: ColorModeWithAuto) =>
  window.localStorage.setItem(LOCALSTORAGE_KEY, colorMode);

type ColorModeContextProviderProps = {
  children: ((values: ColorModeContextValue) => React.ReactNode) | React.ReactNode;
};
const ColorModeContextProvider = ({ children }: ColorModeContextProviderProps) => {
  const [colorMode, _setColorMode] = useState<ColorModeWithAuto>('auto');

  const setColorMode = (colorMode: ColorModeWithAuto) => {
    _setColorMode(colorMode);
    setColorModeToLocalStorage(colorMode);
  };
  useEffect(() => {
    const colorMode = getColorModeFromLocalStorage();
    if (colorMode) setColorMode(colorMode);
  }, []);

  const value = { colorMode, setColorMode };

  return <BaseProvider value={value}>{isFunction(children) ? children(value) : children}</BaseProvider>;
};

export { useContext as useColorModeContext, ColorModeContextProvider };
export type { ColorMode, ColorModeWithAuto };
