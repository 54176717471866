/**
 * @generated SignedSource<<341e389a2a332fe8b7208ddec6c3b212>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QuizSetConnectionDataTable_quizSetConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly modified: any;
      readonly modifiedBy: {
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      } | null;
      readonly unitB: {
        readonly id: string;
        readonly title: string;
      };
      readonly unitC: {
        readonly id: string;
        readonly title: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"QuizSetStatusLabel_quizSet">;
    };
  }>;
  readonly " $fragmentType": "QuizSetConnectionDataTable_quizSetConnection";
};
export type QuizSetConnectionDataTable_quizSetConnection$key = {
  readonly " $data"?: QuizSetConnectionDataTable_quizSetConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"QuizSetConnectionDataTable_quizSetConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "title",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuizSetConnectionDataTable_quizSetConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "QuizSetEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuizSet",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UnitB",
              "kind": "LinkedField",
              "name": "unitB",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "UnitC",
              "kind": "LinkedField",
              "name": "unitC",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "modifiedBy",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "modified",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuizSetStatusLabel_quizSet"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "QuizSetConnection",
  "abstractKey": null
};
})();

(node as any).hash = "83b463d6059b611fd3024f11069583c5";

export default node;
