import { DotFillIcon, EyeClosedIcon, EyeIcon, TrashIcon } from '@primer/octicons-react';
import { GraphQLError } from 'graphql/index';
import React, { ReactNode, Suspense, useEffect, useState } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';

import useToast from '../../../hooks/useToast';
import { ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation } from '../../../relay/__generated__/ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation.graphql';
import { ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemDeactivateMutation } from '../../../relay/__generated__/ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemDeactivateMutation.graphql';
import { ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery } from '../../../relay/__generated__/ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery.graphql';
import { ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation } from '../../../relay/__generated__/ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation.graphql';
import { ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation } from '../../../relay/__generated__/ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation.graphql';
import { ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery } from '../../../relay/__generated__/ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery.graphql';
import { ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator$key } from '../../../relay/__generated__/ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator.graphql';
import { parseGraphQLError } from '../../../utils/error';
import { numberWithCommas } from '../../../utils/number';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EmptyState from '../../core/EmptyState';
import ErrorBoundary from '../../core/ErrorBoundary';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import Katex from '../../core/Katex';
import MutationConfirmIconButton from '../../core/MutationConfirmIconButton';
import MutationFormik from '../../core/MutationFormik';
import MutationIconButton from '../../core/MutationIconButton';
import NumberField from '../../core/NumberField';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import ScrollMiniKatex from '../../core/ScrollMiniKatex';
import Spinner from '../../core/Spinner';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import TabHandler from '../../core/TabHandler';
import Text from '../../core/Text';
import View from '../../core/View';

const ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator = graphql`
  fragment ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator on ProblemGenerator {
    id
    actions
    originalTask {
      id
      sequence
      problem {
        id
        problem
        solution
        answer
      }
      hint {
        id
        publishedText
      }
      submitFeedback {
        id
        publishedSpecialAnswer
        publishedSpecial
        publishedGeneral
      }
    }
  }
`;

const generatedProblemsForProblemGeneratorGeneratedProblemMutationActionsDialog = graphql`
  query ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery(
    $filters: GeneratedProblemFilter
    $order: GeneratedProblemOrder
  ) {
    generatedProblems(filters: $filters, order: $order) {
      totalCount
      edges {
        node {
          id
          actions
          status
        }
      }
    }
  }
`;

const generatedProblemForProblemGeneratorGeneratedProblemMutationActionsDialog = graphql`
  query ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery($id: ID!) {
    generatedProblem(id: $id) {
      id
      problem
      solution
      answer

      hint

      submitSpecialAnswer
      submitSpecial
      submitGeneral
    }
  }
`;

type Props = {
  problemGenerator: ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator$key;
} & DialogProps;

const ProblemGeneratorGeneratedProblemMutationActionsDialog = ({ isOpen, problemGenerator, ...props }: Props) => {
  const { id, actions, originalTask } = useFragment(
    ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator,
    problemGenerator,
  );

  const tabItems = [
    {
      id: 'solution',
      label: '해설',
    },
    {
      id: 'hint',
      label: '힌트',
    },
    {
      id: 'submitFeedback',
      label: '다시 풀기 피드백',
    },
  ];

  const { toast } = useToast();
  const [problemNumber, setProblemNumber] = useState(0);

  const [generatedProblemReference, loadGeneratedProblem] =
    useQueryLoader<ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemQuery>(
      generatedProblemForProblemGeneratorGeneratedProblemMutationActionsDialog,
    );

  const [generatedProblemsReference, loadGeneratedProblemsProblem] =
    useQueryLoader<ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery>(
      generatedProblemsForProblemGeneratorGeneratedProblemMutationActionsDialog,
    );
  useEffect(() => {
    if (isOpen) loadGeneratedProblemsProblem({ filters: { problemGeneratorId_Exact: id }, order: { created: 'ASC' } });
  }, [isOpen]);

  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>생성하기</Text>
      </Dialog.Header>
      <Dialog.Body sx={{ padding: 0 }}>
        <Grid sx={{ height: '100%' }}>
          <Grid.Unit
            size={3 / 4}
            sx={{ padding: 5, height: '100%', display: 'flex', flexDirection: 'column', rowGap: 6 }}
          >
            <TabHandler initialSelectIndex={0}>
              {({ selectedIndex, handleSelect }) => {
                const tabItem = tabItems[selectedIndex];

                const renderGridUnitForTab = (children: ReactNode) => (
                  <Grid.Unit
                    size={tabItem.id === 'solution' ? 1 / 2 : 'min'}
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                  >
                    {children}
                  </Grid.Unit>
                );

                const renderScrollMiniKatexOrEmptyState = (value: string, emptyStateTitle: string) =>
                  value ? (
                    <ScrollMiniKatex height={'100%'}>{value}</ScrollMiniKatex>
                  ) : (
                    <View
                      sx={{
                        width: 240,
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <EmptyState title={emptyStateTitle} />
                    </View>
                  );

                return (
                  <>
                    <View sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                      <Grid gapX={3} sx={{ alignItems: 'center' }}>
                        <Grid.Unit size={'min'}>
                          <Text fontSize={3} fontWeight={'bold'}>
                            {originalTask.sequence}
                          </Text>
                        </Grid.Unit>
                        <Grid.Unit size={'max'}>
                          <Stack gapX={1}>
                            {tabItems.map(({ id, label }, index) => (
                              <Stack.Item key={id}>
                                <Button
                                  variant={tabItem.id === id ? 'outline' : 'invisible'}
                                  onClick={() => handleSelect(index)}
                                >
                                  {label}
                                </Button>
                              </Stack.Item>
                            ))}
                          </Stack>
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <Text fontSize={2} fontWeight={'bold'} color={'accent.fg'}>
                            {tabItem.id === 'submitFeedback'
                              ? `특정 오답 ${originalTask.submitFeedback?.publishedSpecialAnswer || '-'}`
                              : `정답 ${originalTask.problem?.answer || '-'}`}
                          </Text>
                        </Grid.Unit>
                      </Grid>
                      <View sx={{ marginTop: 4, flex: 1, overflow: 'hidden' }}>
                        <Grid gapX={2} sx={{ height: '100%', alignItems: 'start' }} wrap={false}>
                          <Grid.Unit
                            size={tabItem.id === 'solution' ? 1 / 2 : 'max'}
                            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                          >
                            <Text fontSize={1}>원본 문제</Text>
                            <Card sx={{ overflow: 'auto', flex: 1, marginTop: 1 }}>
                              <Katex>{originalTask.problem?.problem || ''}</Katex>
                            </Card>
                          </Grid.Unit>
                          {tabItem.id === 'solution' ? (
                            renderGridUnitForTab(
                              <>
                                <Text fontSize={1}>원본 해설</Text>
                                <Card
                                  sx={{
                                    overflow: 'auto',
                                    marginTop: 1,
                                    flex: 1,
                                  }}
                                >
                                  <Katex>{originalTask.problem?.solution || ''}</Katex>
                                </Card>
                              </>,
                            )
                          ) : tabItem.id === 'hint' ? (
                            renderGridUnitForTab(
                              <>
                                <Text fontSize={1}>원본 힌트</Text>
                                <Card sx={{ overflow: 'auto', marginTop: 1, flex: 1 }}>
                                  {renderScrollMiniKatexOrEmptyState(
                                    originalTask?.hint?.publishedText || '',
                                    '제작한 힌트가 없어요',
                                  )}
                                </Card>
                              </>,
                            )
                          ) : tabItem.id === 'submitFeedback' ? (
                            <>
                              {renderGridUnitForTab(
                                <>
                                  <Text fontSize={1}>원본 특정 오답 피드백</Text>
                                  <Card sx={{ overflow: 'auto', marginTop: 1, flex: 1 }}>
                                    {renderScrollMiniKatexOrEmptyState(
                                      originalTask?.submitFeedback?.publishedSpecial || '',
                                      '제작한 피드백이 없어요',
                                    )}
                                  </Card>
                                </>,
                              )}
                              {renderGridUnitForTab(
                                <>
                                  <Text fontSize={1}>원본 일반 오답 피드백</Text>
                                  <Card sx={{ overflow: 'auto', marginTop: 1, flex: 1 }}>
                                    {renderScrollMiniKatexOrEmptyState(
                                      originalTask?.submitFeedback?.publishedGeneral || '',
                                      '제작한 피드백이 없어요',
                                    )}
                                  </Card>
                                </>,
                              )}
                            </>
                          ) : null}
                        </Grid>
                      </View>
                    </View>
                    <View sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                      {generatedProblemReference ? (
                        <Suspense
                          fallback={
                            <View sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Spinner />
                            </View>
                          }
                        >
                          <ErrorBoundary>
                            <PreloadedQueryRenderer
                              query={generatedProblemForProblemGeneratorGeneratedProblemMutationActionsDialog}
                              queryReference={generatedProblemReference}
                            >
                              {({ generatedProblem }) => (
                                <>
                                  <Grid sx={{ alignItems: 'center' }}>
                                    <Grid.Unit size={'max'}>
                                      <Text fontSize={3} fontWeight={'bold'}>
                                        {`문제 ${problemNumber}`}
                                      </Text>
                                    </Grid.Unit>
                                    <Grid.Unit size={'min'}>
                                      <Text fontSize={2} fontWeight={'bold'} color={'accent.fg'}>
                                        {tabItem.id === 'submitFeedback'
                                          ? `특정 오답 ${generatedProblem.submitSpecialAnswer || '-'}`
                                          : `정답 ${generatedProblem.answer}`}
                                      </Text>
                                    </Grid.Unit>
                                  </Grid>
                                  <View sx={{ marginTop: 4, flex: 1, overflow: 'hidden' }}>
                                    <Grid gapX={2} sx={{ height: '100%', alignItems: 'start' }} wrap={false}>
                                      <Grid.Unit
                                        size={tabItem.id === 'solution' ? 1 / 2 : 'max'}
                                        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                                      >
                                        <Text fontSize={1}>생성된 문제</Text>
                                        <Card sx={{ overflow: 'auto', flex: 1, marginTop: 1 }}>
                                          <Katex>{generatedProblem.problem}</Katex>
                                        </Card>
                                      </Grid.Unit>
                                      {tabItem.id === 'solution' ? (
                                        renderGridUnitForTab(
                                          <>
                                            <Text fontSize={1}>생성된 해설</Text>
                                            <Card
                                              sx={{
                                                overflow: 'auto',
                                                marginTop: 1,
                                                flex: 1,
                                              }}
                                            >
                                              <Katex>{generatedProblem.solution || ''}</Katex>
                                            </Card>
                                          </>,
                                        )
                                      ) : tabItem.id === 'hint' ? (
                                        renderGridUnitForTab(
                                          <>
                                            <Text fontSize={1}>생성된 힌트</Text>
                                            <Card sx={{ overflow: 'auto', marginTop: 1, flex: 1 }}>
                                              {renderScrollMiniKatexOrEmptyState(
                                                generatedProblem.hint || '',
                                                '제작한 힌트가 없어요',
                                              )}
                                            </Card>
                                          </>,
                                        )
                                      ) : tabItem.id === 'submitFeedback' ? (
                                        <>
                                          {renderGridUnitForTab(
                                            <>
                                              <Text fontSize={1}>생성된 특정 오답 피드백</Text>
                                              <Card sx={{ overflow: 'auto', marginTop: 1, flex: 1 }}>
                                                {renderScrollMiniKatexOrEmptyState(
                                                  generatedProblem.submitSpecial || '',
                                                  '제작한 피드백이 없어요',
                                                )}
                                              </Card>
                                            </>,
                                          )}
                                          {renderGridUnitForTab(
                                            <>
                                              <Text fontSize={1}>생성된 일반 오답 피드백</Text>
                                              <Card sx={{ overflow: 'auto', marginTop: 1, flex: 1 }}>
                                                {renderScrollMiniKatexOrEmptyState(
                                                  generatedProblem.submitGeneral || '',
                                                  '제작한 피드백이 없어요',
                                                )}
                                              </Card>
                                            </>,
                                          )}
                                        </>
                                      ) : null}
                                    </Grid>
                                  </View>
                                </>
                              )}
                            </PreloadedQueryRenderer>
                          </ErrorBoundary>
                        </Suspense>
                      ) : null}
                    </View>
                  </>
                );
              }}
            </TabHandler>
          </Grid.Unit>
          <Grid.Unit
            size={1 / 4}
            sx={{
              padding: 5,
              height: '100%',
              backgroundColor: 'canvas.inset',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <MutationFormik<ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation>
              mutation={graphql`
                mutation ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation(
                  $input: GeneratedProblemsCreateInput!
                ) {
                  generatedProblemsCreate(input: $input) {
                    id
                  }
                }
              `}
              initialValues={{ generatorId: id, count: 0, maxIteration: 1000 }}
              config={{
                onCompleted: ({ generatedProblemsCreate }) => {
                  if (generatedProblemsCreate.length > 0) {
                    toast(`${generatedProblemsCreate.length}문제가 자동 생성 됐어요`, 'success');
                    loadGeneratedProblemsProblem(
                      { filters: { problemGeneratorId_Exact: id }, order: { created: 'ASC' } },
                      { fetchPolicy: 'store-and-network' },
                    );
                  } else {
                    toast('더 이상 자동 생성할 수 없어요', 'warning');
                  }
                },
                onError: (error) => {
                  toast(parseGraphQLError(error as GraphQLError)?.[0].message || '문제 생성에 실패했어요', 'error');
                },
              }}
              enableReinitialize={false}
            >
              {({ values, submitForm, resetForm }) => (
                <>
                  <Grid gapX={2} gapY={1}>
                    <Grid.Unit size={[1, 1, 1, 1 / 2]}>
                      <NumberField
                        label={'문제 개수'}
                        disabled={!actions.includes('generated_problems_create')}
                        name={'count'}
                        min={0}
                        size={'large'}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={[1, 1, 1, 1 / 2]}>
                      <NumberField
                        label={'이터레이션 횟수'}
                        disabled={!actions.includes('generated_problems_create')}
                        name={'maxIteration'}
                        min={1}
                        size={'large'}
                      />
                    </Grid.Unit>
                  </Grid>
                  <Button
                    size={'large'}
                    disabled={!actions.includes('generated_problems_create') || values.count <= 0}
                    onClick={() => {
                      submitForm().then(() => resetForm({ values, isSubmitting: true }));
                    }}
                    sx={{ width: '100%', marginTop: 2 }}
                  >
                    문제 생성하기
                  </Button>
                </>
              )}
            </MutationFormik>
            {generatedProblemsReference ? (
              <Suspense>
                <ErrorBoundary>
                  <PreloadedQueryRenderer<ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsQuery>
                    query={generatedProblemsForProblemGeneratorGeneratedProblemMutationActionsDialog}
                    queryReference={generatedProblemsReference}
                  >
                    {({ generatedProblems }) => (
                      <>
                        <View sx={{ marginTop: 5 }}>
                          <Grid gapX={1} sx={{ alignItems: 'center' }}>
                            <Grid.Unit size={'max'}>
                              <Stack gapX={1}>
                                <Stack.Item>
                                  <Text fontWeight={'bold'} fontSize={2}>
                                    생성된 문제
                                  </Text>
                                </Stack.Item>
                                <Stack.Item>
                                  <Text
                                    sx={{ fontSize: 2, fontWeight: 'bold' }}
                                    color={generatedProblems?.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                                  >
                                    {numberWithCommas(generatedProblems?.totalCount || 0)}
                                  </Text>
                                </Stack.Item>
                              </Stack>
                            </Grid.Unit>
                            <Grid.Unit size={'min'}>
                              <MutationConfirmIconButton<ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation>
                                mutation={graphql`
                                  mutation ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsDeleteMutation(
                                    $input: GeneratedProblemsDeleteInput!
                                  ) {
                                    generatedProblemsDelete(input: $input) {
                                      id
                                    }
                                  }
                                `}
                                input={{ generatorId: id }}
                                variant={'plain'}
                                icon={TrashIcon}
                                size={'large'}
                                disabled={(generatedProblems?.totalCount || 0) < 1}
                                aria-label={'delete all generated problems'}
                                message={'생성된 문제를 전체 삭제할까요?'}
                                config={{
                                  onCompleted: () => {
                                    toast('전체 삭제했어요', 'success');
                                    loadGeneratedProblemsProblem(
                                      { filters: { problemGeneratorId_Exact: id }, order: { created: 'ASC' } },
                                      { fetchPolicy: 'store-and-network' },
                                    );
                                  },
                                  onError: (error) => {
                                    toast(
                                      parseGraphQLError(error as GraphQLError)?.[0].message || '전체 삭제에 실패했어요',
                                      'error',
                                    );
                                  },
                                }}
                              />
                            </Grid.Unit>
                          </Grid>
                        </View>
                        <View sx={{ marginTop: 3, flex: 1, overflowY: 'auto' }}>
                          <ItemList
                            items={generatedProblems.edges.map(({ node }) => node)}
                            renderItem={(_, index) => <Text fontSize={1}>{`문제 ${index + 1}`}</Text>}
                            renderItemWrapper={(children, { id, status, actions }, index) => (
                              <View
                                key={id}
                                sx={{
                                  cursor: 'pointer',
                                  minHeight: '40px',
                                  borderRadius: 2,
                                  padding: 1,
                                  backgroundColor:
                                    generatedProblemReference?.variables.id === id ? 'accent.subtle' : undefined,
                                  color: status === 'inactive' ? 'border.default' : 'fg.default',
                                }}
                                tabIndex={0}
                                onClick={() => {
                                  setProblemNumber(index + 1);
                                  loadGeneratedProblem({ id });
                                }}
                              >
                                <Grid wrap={false} gapX={1} sx={{ alignItems: 'center', height: '100%' }}>
                                  <Grid.Unit size={'min'} sx={{ display: 'flex', alignItems: 'center', padding: 2 }}>
                                    <StyledOcticon size={12} icon={DotFillIcon} />
                                  </Grid.Unit>
                                  <Grid.Unit size={'max'}>{children}</Grid.Unit>
                                  {actions.includes('generated_problem_activate') ? (
                                    <Grid.Unit size={'min'}>
                                      <MutationIconButton<ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation>
                                        mutation={graphql`
                                          mutation ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemActivateMutation(
                                            $input: GeneratedProblemActivateInput!
                                          ) {
                                            generatedProblemActivate(input: $input) {
                                              id
                                              status
                                              actions
                                            }
                                          }
                                        `}
                                        input={{ id }}
                                        icon={EyeClosedIcon}
                                        variant={'plain'}
                                        aria-label={'activate generated problem'}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </Grid.Unit>
                                  ) : null}
                                  {actions.includes('generated_problem_deactivate') ? (
                                    <Grid.Unit size={'min'}>
                                      <MutationIconButton<ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemDeactivateMutation>
                                        mutation={graphql`
                                          mutation ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemDeactivateMutation(
                                            $input: GeneratedProblemDeactivateInput!
                                          ) {
                                            generatedProblemDeactivate(input: $input) {
                                              id
                                              status
                                              actions
                                            }
                                          }
                                        `}
                                        input={{ id }}
                                        icon={EyeIcon}
                                        variant={'plain'}
                                        aria-label={'deactivate generated problem'}
                                        onClick={(e) => e.stopPropagation()}
                                      />
                                    </Grid.Unit>
                                  ) : null}
                                </Grid>
                              </View>
                            )}
                            emptyState={
                              <Card sx={{ padding: 5 }}>
                                <EmptyState title={'생성된 문제가 없어요'} />
                              </Card>
                            }
                          />
                        </View>
                      </>
                    )}
                  </PreloadedQueryRenderer>
                </ErrorBoundary>
              </Suspense>
            ) : null}
          </Grid.Unit>
        </Grid>
      </Dialog.Body>
    </Dialog>
  );
};

export default ProblemGeneratorGeneratedProblemMutationActionsDialog;
