export enum FocusKeys {
  ArrowHorizontal = 1,
  ArrowVertical = 2,
  JK = 4,
  HL = 8,
  HomeAndEnd = 16,
  PageUpDown = 256,
  WS = 32,
  AD = 64,
  Tab = 128,
  ArrowAll = 3,
  HJKL = 12,
  WASD = 96,
  All = 511,
}
