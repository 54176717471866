import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  FormulaSetStatusEnum,
  FormulaSetStatusLabel_formulaSet$key,
} from '../../../relay/__generated__/FormulaSetStatusLabel_formulaSet.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const FormulaSetStatusLabel_formulaSet = graphql`
  fragment FormulaSetStatusLabel_formulaSet on FormulaSet {
    status
  }
`;

type Props = {
  formulaSet: FormulaSetStatusLabel_formulaSet$key;
} & Omit<LabelProps, 'variant'>;

const FormulaSetStatusLabel = ({ formulaSet, ...props }: Props) => {
  const { status } = useFragment(FormulaSetStatusLabel_formulaSet, formulaSet);

  const labelPropsByStatus: Record<FormulaSetStatusEnum, LabelProps> = {
    'published': { variant: 'success' },
    'review': { variant: 'severe' },
    'pending': { variant: 'secondary' },
    '%future added value': {},
  };

  return (
    <Suspense>
      <Label {...labelPropsByStatus[status]} {...props}>
        <EnumPair typename={'FormulaSetStatusEnum'}>{status}</EnumPair>
      </Label>
    </Suspense>
  );
};

export default FormulaSetStatusLabel;
