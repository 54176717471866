import { graphql } from 'react-relay';

import { ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator$key } from '../../../relay/__generated__/ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator = graphql`
  fragment ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator on ProblemGenerator
  @argumentDefinitions(first: { type: Int, defaultValue: 10 }, after: { type: String })
  @refetchable(queryName: "ProblemGeneratorProblemGeneratorHistoryPaginatorRefreshQuery") {
    histories(first: $first, after: $after)
      @connection(key: "ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator_histories") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          type
          ...ProblemGeneratorHistoryDialog_problemGeneratorHistory
          ...ProblemGeneratorHistoryTimelineItem_problemGeneratorHistory
        }
      }
    }
  }
`;

type Props = {} & Omit<
  FragmentPaginatorProps<ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator$key>,
  'fragment'
>;

const ProblemGeneratorProblemGeneratorHistoryPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator} {...props} />
);

export default ProblemGeneratorProblemGeneratorHistoryPaginator;
