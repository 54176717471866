import { ChevronLeftIcon, ChevronRightIcon, KebabHorizontalIcon, TrashIcon } from '@primer/octicons-react';
import { GraphQLError } from 'graphql/index';
import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { graphql } from 'react-relay';

import CommentCreateMutationFormik from '../../components/comment/CommentCreateMutationFormik';
import CommentMutationActionItem from '../../components/comment/CommentMutationActionItem';
import CommentPaginator from '../../components/comment/CommentPaginator';
import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Button from '../../components/core/Button';
import Card from '../../components/core/Card';
import Checkbox from '../../components/core/Checkbox';
import ConfirmButton from '../../components/core/ConfirmButton';
import { RowWrapper } from '../../components/core/DataTable';
import DialogButton from '../../components/core/DialogButton';
import DialogHandler from '../../components/core/DialogHandler';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import ItemList from '../../components/core/ItemList';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import MutationConfirmButton from '../../components/core/MutationConfirmButton';
import MutationFormik from '../../components/core/MutationFormik';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import Timeline from '../../components/core/Timeline';
import View from '../../components/core/View';
import WorkbookDescriptionList from '../../components/workbook/WorkbookDescriptionList';
import WorkbookGradesDataTable from '../../components/workbook/WorkbookGradesDataTable';
import WorkbookGradesUpdateDialog from '../../components/workbook/WorkbookGradesUpdateDialog';
import WorkbookHistoryTimelineItem from '../../components/workbook/WorkbookHistoryTimelineItem';
import WorkbookTaskConnectionDataTable from '../../components/workbook/WorkbookTaskConnectionDataTable';
import WorkbookTasksAddDialog from '../../components/workbook/WorkbookTasksAddDialog';
import WorkbookTasksReorderDialog from '../../components/workbook/WorkbookTasksReorderDialog';
import WorkbookUpdateDialog from '../../components/workbook/WorkbookUpdateDialog';
import WorkbookWorkbookHistoryPaginator from '../../components/workbook/WorkbookWorkbookHistoryPaginator';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { Ordering, WorkbookId_commentsQuery } from '../../relay/__generated__/WorkbookId_commentsQuery.graphql';
import { WorkbookId_workbookDeleteMutation } from '../../relay/__generated__/WorkbookId_workbookDeleteMutation.graphql';
import { WorkbookId_workbookPublishMutation } from '../../relay/__generated__/WorkbookId_workbookPublishMutation.graphql';
import { WorkbookFilter, WorkbookId_workbookQuery } from '../../relay/__generated__/WorkbookId_workbookQuery.graphql';
import { WorkbookId_workbookTasksDeleteMutation } from '../../relay/__generated__/WorkbookId_workbookTasksDeleteMutation.graphql';
import { parseGraphQLError } from '../../utils/error';
import { isNullable } from '../../utils/is';
import { numberWithCommas } from '../../utils/number';
import { parseOrdering } from '../../utils/order';
import { scrollToBottom } from '../../utils/scroll';
import { NextPage } from '../_app';

const workbookForWorkbookId = graphql`
  query WorkbookId_workbookQuery($id: ID!, $filters: WorkbookFilter, $order: WorkbookOrder) {
    workbook(id: $id) {
      id
      sequence
      previous(filters: $filters, order: $order)
      next(filters: $filters, order: $order)

      title
      actions
      ...WorkbookUpdateDialog_workbook
      ...WorkbookDescriptionList_workbook
      ...WorkbookTasksAddDialog_workbook
      ...WorkbookTasksReorderDialog_workbook
      ...WorkbookGradesDataTable_grades
      ...WorkbookGradesUpdateDialog_workbook
      grades {
        grade
        percentile
        rawScore
        standardScore
      }
      tasks {
        totalCount
        edges {
          node {
            id
          }
        }
        ...WorkbookTaskConnectionDataTable_taskConnection
      }
      ...WorkbookWorkbookHistoryPaginator_workbook
    }
  }
`;

const commentsForWorkbookId = graphql`
  query WorkbookId_commentsQuery($filters: CommentFilter, $order: CommentOrder) {
    ...CommentPaginator_query @arguments(filters: $filters, order: $order)
  }
`;

type Props = {};

const WorkbookId: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();

  const workbookId = router.query.workbookId as string;

  const [comments] = useLazyLoadQuery<WorkbookId_commentsQuery>(commentsForWorkbookId, {
    filters: { nodeId_Exact: workbookId },
    order: { created: 'ASC' as Ordering },
  });
  const commentContainerRef = useRef<HTMLDivElement>(null);

  const { initialValues } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },

    status_In: { type: 'string', multiple: true },
    schoolType_In: { type: 'string', multiple: true },
    workbookCategory_In: { type: 'string', multiple: true },

    taskCount_Gte: { type: 'number' },
    taskCount_Lte: { type: 'number' },
    modifiedById_In: { type: 'string', multiple: true },

    order: { type: 'string' },
  });
  const { order, ...filters } = initialValues;

  const [{ workbook }, refresh] = useLazyLoadQuery<WorkbookId_workbookQuery>(workbookForWorkbookId, {
    id: workbookId,
    filters: filters as WorkbookFilter,
    order: order ? parseOrdering(order as string) : undefined,
  });
  if (!workbook) return null;
  const { id, sequence, title, tasks, actions, next: nextWorkbookId, previous: previousWorkbookId, grades } = workbook;

  const hasPrevious = !isNullable(previousWorkbookId);
  const handlePreviousButtonClick = () => {
    const previousWorkbookPathname = `/workbook/${previousWorkbookId}`;
    router.replace({ pathname: previousWorkbookPathname, query: initialValues }, previousWorkbookPathname);
  };

  const hasNext = !isNullable(nextWorkbookId);
  const handleNextButtonClick = () => {
    const nextWorkbookPathname = `/workbook/${nextWorkbookId}`;
    router.replace({ pathname: nextWorkbookPathname, query: initialValues }, nextWorkbookPathname);
  };

  return (
    <View>
      <Head siteTitle={`기출 - ${title}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Stack gapX={3}>
              <Stack.Item>
                <IconButton
                  icon={ChevronLeftIcon}
                  aria-label={'Previous Workbook'}
                  disabled={!hasPrevious}
                  onClick={handlePreviousButtonClick}
                />
              </Stack.Item>
              <Stack.Item>
                <Text as={'h1'} fontSize={3}>
                  {sequence}
                </Text>
              </Stack.Item>
              <Stack.Item>
                <IconButton
                  icon={ChevronRightIcon}
                  aria-label={'Next Workbook'}
                  disabled={!hasNext}
                  onClick={handleNextButtonClick}
                />
              </Stack.Item>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Stack gapX={2}>
              {actions.includes('workbook_publish') ? (
                <Stack.Item>
                  <MutationConfirmButton<WorkbookId_workbookPublishMutation>
                    mutation={graphql`
                      mutation WorkbookId_workbookPublishMutation($input: WorkbookPublishInput!) {
                        workbookPublish(input: $input) {
                          id
                          ...WorkbookStatusLabel_workbook
                          actions
                          modified
                          modifiedBy {
                            ...UserAvatarText_user
                          }
                          ...WorkbookWorkbookHistoryPaginator_workbook
                          ...WorkbookDescriptionList_workbook
                        }
                      }
                    `}
                    config={{
                      onCompleted: () => {
                        toast('출시 완료됐어요', 'success');
                      },
                      onError: (error) => {
                        toast(parseGraphQLError(error as GraphQLError)?.[0].message || '출시에 실패했어요', 'error');
                      },
                    }}
                    input={{ id }}
                    variant={'primary'}
                    size={'large'}
                    disabled={
                      (tasks?.totalCount || 0) < 1 ||
                      grades.some(
                        ({ percentile, rawScore, standardScore }) =>
                          typeof percentile !== 'number' ||
                          typeof rawScore !== 'number' ||
                          typeof standardScore !== 'number',
                      )
                    }
                    message={'생성된 기출을 출시할까요?'}
                  >
                    출시하기
                  </MutationConfirmButton>
                </Stack.Item>
              ) : null}
              {actions.includes('workbook_delete') ? (
                <Stack.Item>
                  <ActionMenu>
                    <ActionMenu.Anchor>
                      <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay>
                      <ActionList>
                        <ActionList.MutationItem<WorkbookId_workbookDeleteMutation>
                          mutation={graphql`
                            mutation WorkbookId_workbookDeleteMutation($input: WorkbookDeleteInput!) {
                              workbookDelete(input: $input) {
                                id @deleteRecord
                              }
                            }
                          `}
                          input={{ id }}
                          variant={'danger'}
                          config={{
                            onCompleted: () => {
                              router.replace('/workbook').then(() => toast('기출 삭제가 완료됐어요', 'success'));
                            },
                            onError: (error) => {
                              toast(
                                parseGraphQLError(error as GraphQLError)?.[0].message || '기출 삭제에 실패했어요',
                                'error',
                              );
                            },
                          }}
                          message={'삭제한 기출은 되돌릴 수 없어요. 삭제할까요?'}
                        >
                          <ActionList.LeadingVisual>
                            <TrashIcon />
                          </ActionList.LeadingVisual>
                          삭제하기
                        </ActionList.MutationItem>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Stack.Item>
              ) : null}
            </Stack>
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid reverse={[true, true, false]} gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <Grid sx={{ alignItems: 'center' }}>
              <Grid.Unit size={'max'}>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>기출 정보</Text>
              </Grid.Unit>
              {actions.includes('workbook_update') ? (
                <Grid.Unit size={'min'}>
                  <DialogButton
                    renderDialog={({ closeDialog, isOpen }) => (
                      <WorkbookUpdateDialog
                        workbook={workbook}
                        isOpen={isOpen}
                        onDismiss={closeDialog}
                        wide
                        fillHeight
                        config={{
                          onCompleted: () => {
                            toast('기출 정보가 수정됐어요', 'success');
                            closeDialog();
                          },
                          onError: (error) => {
                            toast(
                              parseGraphQLError(error as GraphQLError)?.[0].message || '기출 정보 수정에 실패했어요',
                              'error',
                            );
                          },
                        }}
                      />
                    )}
                    variant={'outline'}
                    size={'large'}
                  >
                    수정하기
                  </DialogButton>
                </Grid.Unit>
              ) : null}
            </Grid>
            <View sx={{ marginTop: 3 }}>
              <Card sx={{ padding: 4 }}>
                <WorkbookDescriptionList workbook={workbook} titleUnitSize={1 / 4} descriptionUnitSize={3 / 4} />
              </Card>
            </View>
            <MutationFormik<WorkbookId_workbookTasksDeleteMutation>
              mutation={graphql`
                mutation WorkbookId_workbookTasksDeleteMutation($input: WorkbookTasksDeleteInput!) {
                  workbookTasksDelete(input: $input) {
                    id
                    ...WorkbookStatusLabel_workbook
                    modifiedBy {
                      ...UserAvatarText_user
                    }
                    modified
                    tasks {
                      totalCount
                      edges {
                        node {
                          id
                        }
                      }
                    }
                  }
                }
              `}
              initialValues={{ id, taskIds: [] }}
              config={{
                onCompleted: () => {
                  toast('문제가 삭제됐어요', 'success');
                },
              }}
            >
              {({ values, setFieldValue, isSubmitting, submitForm, resetForm }) => (
                <>
                  <View sx={{ marginTop: 5 }}>
                    <Grid sx={{ alignItems: 'center' }}>
                      <Grid.Unit size={'max'}>
                        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>문제</Text>
                      </Grid.Unit>
                      <Grid.Unit size={'min'}>
                        <Stack gapX={2}>
                          {actions.includes('workbook_tasks_delete') ? (
                            <Stack.Item>
                              <ConfirmButton
                                disabled={values.taskIds.length === 0}
                                onClick={() => {
                                  submitForm().then(() => resetForm());
                                }}
                                variant={'danger'}
                                size={'large'}
                                message={'삭제한 문제는 되돌릴 수 없어요. 삭제할까요?'}
                              >
                                삭제하기
                              </ConfirmButton>
                            </Stack.Item>
                          ) : null}
                          {actions.includes('workbook_tasks_add') ? (
                            <Stack.Item>
                              <DialogButton
                                renderDialog={({ isOpen, closeDialog }) => (
                                  <WorkbookTasksAddDialog
                                    isOpen={isOpen}
                                    full
                                    onDismiss={closeDialog}
                                    workbook={workbook}
                                    config={{
                                      onCompleted: () => {
                                        toast('문제가 추가됐어요', 'success');
                                      },
                                      onError: (error) => {
                                        toast(
                                          parseGraphQLError(error as GraphQLError)?.[0].message ||
                                            '문제 추가에 실패했어요',
                                          'error',
                                        );
                                      },
                                    }}
                                  />
                                )}
                                variant={'outline'}
                                size={'large'}
                              >
                                추가하기
                              </DialogButton>
                            </Stack.Item>
                          ) : null}
                        </Stack>
                      </Grid.Unit>
                    </Grid>
                  </View>
                  <View sx={{ marginTop: 3 }}>
                    <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                      {`총 ${numberWithCommas(tasks.totalCount || 0)} 문제${
                        values.taskIds.length > 0 ? ` / ${values.taskIds.length} 문제` : ''
                      }`}
                    </Text>
                    <View sx={{ marginTop: 2, overflowX: 'auto' }}>
                      <DialogHandler
                        renderDialog={({ isOpen, closeDialog }) => (
                          <WorkbookTasksReorderDialog
                            workbook={workbook}
                            isOpen={tasks?.totalCount !== 0 ? isOpen : false}
                            onDismiss={closeDialog}
                            full
                          />
                        )}
                      >
                        <WorkbookTaskConnectionDataTable
                          taskConnection={tasks}
                          emptyState={
                            <View sx={{ paddingY: 3 }}>
                              <EmptyState title={'추가된 문제가 없어요'} />
                            </View>
                          }
                          renderHead={(columns) => (
                            <View
                              as={'thead'}
                              sx={{
                                borderBottomWidth: 1,
                                borderBottomStyle: 'solid',
                                borderBottomColor: 'border.default',
                                backgroundColor: 'canvas.subtle',
                              }}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <View as={'tr'}>
                                <View
                                  as={'th'}
                                  sx={{ padding: 2 }}
                                  onClick={(e) => {
                                    if (e.currentTarget === e.target)
                                      (e.currentTarget.querySelector('input[type=checkbox]') as HTMLElement)?.click();
                                  }}
                                >
                                  <Checkbox
                                    disabled={
                                      isSubmitting ||
                                      tasks.totalCount === 0 ||
                                      !actions.includes('workbook_tasks_delete')
                                    }
                                    checked={values.taskIds.length !== 0 && values.taskIds.length === tasks.totalCount}
                                    onChange={(e) => {
                                      setFieldValue(
                                        'taskIds',
                                        e.target.checked ? tasks.edges.map(({ node }) => node.id) : [],
                                      );
                                    }}
                                  />
                                </View>
                                {columns.map(({ field, title, width }) => (
                                  <View
                                    key={field}
                                    as={'th'}
                                    sx={{
                                      borderWidth: 1,
                                      borderStyle: 'solid',
                                      borderColor: 'border.default',
                                      minWidth: width,
                                      textAlign: 'start',
                                      padding: 2,
                                      fontWeight: 'bold',
                                      color: 'fg.muted',
                                    }}
                                  >
                                    {title}
                                  </View>
                                ))}
                              </View>
                            </View>
                          )}
                          renderRowWrapper={(children, row) => (
                            <RowWrapper
                              key={row.id}
                              sx={{
                                'cursor': 'pointer',
                                ':hover': {
                                  backgroundColor: 'canvas.inset',
                                  transition: 'background-color 250ms',
                                },
                              }}
                            >
                              <View
                                as={'td'}
                                sx={{
                                  cursor: 'default',
                                  borderWidth: 1,
                                  borderStyle: 'solid',
                                  borderColor: 'border.default',
                                  padding: 2,
                                }}
                                onClick={(e) => {
                                  if (e.currentTarget === e.target)
                                    (e.currentTarget.querySelector('input[type=checkbox]') as HTMLElement)?.click();
                                  e.stopPropagation();
                                }}
                              >
                                <Checkbox
                                  disabled={isSubmitting || !actions.includes('workbook_tasks_delete')}
                                  checked={values.taskIds.includes(row.id)}
                                  onChange={(e) => {
                                    setFieldValue(
                                      'taskIds',
                                      e.target.checked
                                        ? [...values.taskIds, row.id]
                                        : values.taskIds.filter((id) => id !== row.id),
                                    );
                                  }}
                                />
                              </View>
                              {children}
                            </RowWrapper>
                          )}
                        />
                      </DialogHandler>
                    </View>
                  </View>
                </>
              )}
            </MutationFormik>

            <>
              <View sx={{ marginTop: 5 }}>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>등급컷</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Stack gapX={2}>
                      {actions.includes('workbook_update') ? (
                        <Stack.Item>
                          <DialogButton
                            renderDialog={({ isOpen, closeDialog }) => (
                              <WorkbookGradesUpdateDialog
                                isOpen={isOpen}
                                onDismiss={closeDialog}
                                workbook={workbook}
                                wide
                                fillHeight
                                config={{
                                  onCompleted: () => {
                                    toast('등급컷을 수정했어요', 'success');
                                    refresh();
                                    closeDialog();
                                  },
                                  onError: (error) => {
                                    toast(
                                      parseGraphQLError(error as GraphQLError)?.[0].message ||
                                        '등급컷 수정에 실패했어요',
                                      'error',
                                    );
                                  },
                                }}
                              />
                            )}
                            variant={'outline'}
                            size={'large'}
                          >
                            수정하기
                          </DialogButton>
                        </Stack.Item>
                      ) : null}
                    </Stack>
                  </Grid.Unit>
                </Grid>
                <View mt={3}>
                  <WorkbookGradesDataTable grades={workbook} />
                </View>
              </View>
            </>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <View>
              <WorkbookDescriptionList
                workbook={workbook}
                type={'activity'}
                titleUnitSize={'max'}
                descriptionUnitSize={'min'}
                renderTitle={(title) => <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>}
                renderItemWrapper={(children, _, i) => (
                  <View key={i} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                    {children}
                  </View>
                )}
              />
            </View>
            <View sx={{ marginTop: 6 }}>
              <ErrorBoundary>
                <CommentPaginator fragmentReference={comments}>
                  {({ comments }, { loadMore, hasNext, isLoadingNext, refetch }) => {
                    return (
                      <>
                        <View>
                          <Stack gapX={1}>
                            <Stack.Item>
                              <Text fontWeight={'bold'} fontSize={1}>
                                댓글
                              </Text>
                            </Stack.Item>
                            <Stack.Item>
                              <Text
                                sx={{ fontSize: 1, fontWeight: 'bold' }}
                                color={comments?.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                              >
                                {numberWithCommas(comments?.totalCount || 0)}
                              </Text>
                            </Stack.Item>
                          </Stack>
                          <Card ref={commentContainerRef} sx={{ maxHeight: 240, overflowY: 'auto', marginTop: 2 }}>
                            <View sx={{ padding: 3 }}>
                              <ItemList
                                items={comments?.edges.map(({ node }) => node).filter((node) => !!node)}
                                renderItem={(node) => <CommentMutationActionItem comment={node} />}
                                renderItemWrapper={(children, { id }, index) => (
                                  <View key={id} sx={{ marginTop: index === 0 ? 0 : 4 }}>
                                    {children}
                                  </View>
                                )}
                                emptyState={<EmptyState title={'댓글이 없어요'} />}
                              />
                              {hasNext ? (
                                <Button
                                  onClick={() => loadMore(3)}
                                  disabled={isLoadingNext}
                                  sx={{ width: '100%', marginTop: 1 }}
                                >
                                  더보기
                                </Button>
                              ) : null}
                            </View>
                          </Card>
                        </View>
                        <View sx={{ marginTop: 2 }}>
                          <CommentCreateMutationFormik
                            nodeId={workbookId}
                            config={{
                              onCompleted: () => {
                                refetch(
                                  {},
                                  {
                                    fetchPolicy: 'store-and-network',
                                    onComplete: () => {
                                      setTimeout(() => {
                                        if (commentContainerRef.current)
                                          scrollToBottom(commentContainerRef.current, {});
                                      }, 0);
                                    },
                                  },
                                );
                              },
                            }}
                          />
                        </View>
                      </>
                    );
                  }}
                </CommentPaginator>
              </ErrorBoundary>
            </View>
            <View sx={{ marginTop: 6 }}>
              <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>히스토리</Text>
              <ErrorBoundary>
                <WorkbookWorkbookHistoryPaginator fragmentReference={workbook}>
                  {({ histories }, { isLoadingNext, hasNext, loadMore }) => (
                    <Timeline>
                      <ItemList
                        items={histories.edges}
                        renderItem={({ node }) => <WorkbookHistoryTimelineItem key={node.id} workbookHistory={node} />}
                      />
                      {hasNext ? (
                        <Button sx={{ width: '100%' }} disabled={isLoadingNext} onClick={() => loadMore(10)}>
                          더보기
                        </Button>
                      ) : (
                        <Timeline.Break />
                      )}
                    </Timeline>
                  )}
                </WorkbookWorkbookHistoryPaginator>
              </ErrorBoundary>
            </View>
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

WorkbookId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
WorkbookId.authenticated = true;
WorkbookId.routes = [
  {
    id: 'workbookId',
    pathname: '/workbook/[workbookId]',
    name: '기출 상세',
    permissions: ['workbook_read'],
  },
];

export default WorkbookId;
