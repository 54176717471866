import { graphql, useFragment } from 'react-relay';

import {
  CodepushVersionConnectionDataTable_codepushVersionConnection$key,
  CodepushVersionConnectionDataTable_codepushVersionConnection$data,
} from '../../../relay/__generated__/CodepushVersionConnectionDataTable_codepushVersionConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Markdown from '../../core/Markdown';
import Text from '../../core/Text';
import CodepushVersionStatusLabel from '../CodepushVersionStatusLabel';

const CodepushVersionConnectionDataTable_codepushVersionConnection = graphql`
  fragment CodepushVersionConnectionDataTable_codepushVersionConnection on CodepushVersionConnection {
    edges {
      node {
        id
        ...CodepushVersionStatusLabel_codepushVersion
        status
        version
        targetBinaryVersion
        severity
        title
        created
      }
    }
  }
`;

type Props = {
  codepushVersionConnection: CodepushVersionConnectionDataTable_codepushVersionConnection$key;
} & Omit<
  DataTableProps<CodepushVersionConnectionDataTable_codepushVersionConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const CodepushVersionConnectionDataTable = ({ codepushVersionConnection, ...props }: Props) => {
  const { edges } = useFragment(
    CodepushVersionConnectionDataTable_codepushVersionConnection,
    codepushVersionConnection,
  );

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (codepushVersion) => <CodepushVersionStatusLabel codepushVersion={codepushVersion} />,
          width: 80,
          align: 'center',
        },
        { field: 'version', title: '버전', renderValue: ({ version }) => <Text>{version}</Text>, width: 80 },
        {
          field: 'targetBinaryVersion',
          title: '바이너리 타겟 버전',
          renderValue: ({ targetBinaryVersion }) => <Text fontSize={1}>{targetBinaryVersion}</Text>,
          width: 130,
        },
        {
          field: 'severity',
          title: '티어',
          renderValue: ({ severity }) => <EnumPair typename={'CodepushSeverityEnum'}>{severity}</EnumPair>,
          width: 80,
        },
        {
          field: 'description',
          title: '업데이트 항목',
          renderValue: ({ title }) => (title ? <Markdown>{title}</Markdown> : '-'),
          width: 546,
        },
        { field: 'created', title: '제작일', renderValue: ({ created }) => <DateText>{created}</DateText>, width: 180 },
      ]}
      {...props}
    />
  );
};

export default CodepushVersionConnectionDataTable;
