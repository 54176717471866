import { useState } from 'react';
import { fetchQuery, GraphQLTaggedNode, useRelayEnvironment } from 'react-relay';

import Button, { ButtonProps } from '../Button';

type Props<
  TQuery extends {
    readonly response: {};
    readonly variables: {};
  },
> = {
  query: GraphQLTaggedNode;
  variables?: TQuery['variables'];
  cacheConfig?: Parameters<typeof fetchQuery>[3];
  onComplete?: (value: TQuery['response'] | undefined) => void;
  onError?: (reason: any) => void;
} & ButtonProps;

const FetchQueryButton = <
  TQuery extends {
    readonly response: {};
    readonly variables: {};
  },
>({
  onClick,
  query,
  variables,
  cacheConfig,
  disabled: propDisabled,
  onComplete,
  onError,
  ...props
}: Props<TQuery>) => {
  const environment = useRelayEnvironment();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick: ButtonProps['onClick'] = (...arg) => {
    onClick?.(...arg);
    setIsLoading(true);
    fetchQuery<TQuery>(environment, query, { ...variables }, cacheConfig)
      .toPromise()
      .then(onComplete)
      .catch(onError)
      .finally(() => setIsLoading(false));
  };

  const disabled = propDisabled || isLoading;

  return <Button disabled={disabled} onClick={handleClick} {...props} />;
};

export default FetchQueryButton;
