import { FieldConfig, useField } from 'formik';
import { ComponentProps, useState } from 'react';
import { SketchPicker, ColorChangeHandler } from 'react-color';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import AnchoredOverlay from '../AnchoredOverlay';
import FormControl from '../FormControl';
import TextInput, { TextInputProps } from '../TextInput';
import View from '../View';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
  onChange?: ColorChangeHandler;
} & Pick<TextInputProps, 'value' | 'placeholder' | 'disabled' | 'name' | 'required'> &
  Pick<FieldConfig, 'validate'>;

const ColorPickField = ({
  label,
  labelConfig,
  caption,
  disabled: propDisabled,
  required,
  name = '',
  value: propValue,
  onChange: propOnChange,
  validate,
  ...props
}: Props) => {
  const inputId = `color_pick_field_for_${label}`;

  const [{ value: baseValue }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });

  const { isSubmitting } = useFormikContext();
  const value = !isNullable(propValue) ? propValue : baseValue;

  const onChange: ColorChangeHandler = (value) => {
    setValue(value.hex);
  };
  const handleChange: ColorChangeHandler = (...args) => {
    setError(undefined);

    if (propOnChange) propOnChange(...args);
    else onChange(...args);
  };

  const [isAnchoredOverlayOpen, setIsAnchoredOverlayOpen] = useState<boolean>(false);
  const handleAnchoredOverlayOpen = () => {
    setIsAnchoredOverlayOpen(true);
  };
  const handleAnchoredOverlayClose = () => {
    setIsAnchoredOverlayOpen(false);
  };

  const disabled = propDisabled || isSubmitting;

  return (
    <FormControl disabled={disabled} required={required}>
      <FormControl.Label {...labelConfig} htmlFor={inputId}>
        {label}
      </FormControl.Label>
      <AnchoredOverlay
        width={'auto'}
        height={'auto'}
        open={isAnchoredOverlayOpen}
        onOpen={handleAnchoredOverlayOpen}
        onClose={handleAnchoredOverlayClose}
        renderAnchor={(anchorProps) => (
          <View sx={{ width: '100%' }} {...anchorProps}>
            <TextInput
              {...props}
              id={inputId}
              value={value}
              onChange={() => {}}
              size={'medium'}
              block
              disabled={disabled}
              autoComplete="off"
              validationStatus={error ? 'error' : undefined}
              trailingVisual={() => (
                <View
                  sx={{
                    width: '16px',
                    height: '16px',
                    backgroundColor: value,
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: 'border.default',
                    borderRadius: 1,
                  }}
                />
              )}
            />
          </View>
        )}
      >
        <SketchPicker color={value} onChange={handleChange} disableAlpha />
      </AnchoredOverlay>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default ColorPickField;
export type { Props as ColorPickProps };
