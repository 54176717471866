import { graphql, useFragment } from 'react-relay';

import { CommentItem_comment$key } from '../../../relay/__generated__/CommentItem_comment.graphql';
import DateText from '../../core/DateText';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';

const CommentItem_comment = graphql`
  fragment CommentItem_comment on Comment {
    id
    created
    createdBy {
      id
      ...UserAvatarText_user
    }
    modified
    modifiedBy {
      id
    }
    description
  }
`;

type Props = { comment: CommentItem_comment$key };

const CommentItem = ({ comment }: Props) => {
  const { modified, modifiedBy, created, createdBy, description } = useFragment(CommentItem_comment, comment);
  return (
    <>
      <Stack gapX={1}>
        <Stack.Item>
          <UserAvatarText user={createdBy} fontSize={1} fontWeight={'bold'} />
        </Stack.Item>
        <Stack.Item>
          <View>
            <DateText ago sx={{ color: 'fg.subtle', fontSize: 0, lineHeight: 2 }}>
              {modifiedBy ? modified : created}
            </DateText>
            {modifiedBy ? <Text sx={{ color: 'fg.subtle', fontSize: 0, lineHeight: 2 }}>(수정됨)</Text> : null}
          </View>
        </Stack.Item>
      </Stack>
      <View sx={{ marginTop: 2 }}>
        <Text fontSize={1}>{description}</Text>
      </View>
    </>
  );
};

export default CommentItem;
