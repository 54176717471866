/**
 * @generated SignedSource<<48d7da886c2fc4bebc7c63bbe801d99d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type permission_groupQuery$variables = {
  id: string;
};
export type permission_groupQuery$data = {
  readonly group: {
    readonly id: string;
    readonly leaders: ReadonlyArray<{
      readonly group: {
        readonly title: string;
      } | null;
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    }>;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"GroupCategoryDialog_group" | "GroupInviteDialog_group" | "UserCreateDialog_group">;
  };
};
export type permission_groupQuery = {
  response: permission_groupQuery$data;
  variables: permission_groupQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "permission_groupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "leaders",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserCreateDialog_group"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GroupInviteDialog_group"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GroupCategoryDialog_group"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "permission_groupQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Group",
        "kind": "LinkedField",
        "name": "group",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "leaders",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "group",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Category",
            "kind": "LinkedField",
            "name": "categories",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Feature",
                "kind": "LinkedField",
                "name": "features",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Permission",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isActive",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "02c233e23e3ca079b0ea90f93cabeea9",
    "id": null,
    "metadata": {},
    "name": "permission_groupQuery",
    "operationKind": "query",
    "text": "query permission_groupQuery(\n  $id: ID!\n) {\n  group(id: $id) {\n    id\n    title\n    leaders {\n      id\n      ...UserAvatarText_user\n      group {\n        title\n        id\n      }\n    }\n    ...UserCreateDialog_group\n    ...GroupInviteDialog_group\n    ...GroupCategoryDialog_group\n  }\n}\n\nfragment CategoryTableItem_category on Category {\n  id\n  features {\n    id\n    title\n    permissions {\n      id\n      isActive\n      label\n    }\n  }\n}\n\nfragment GroupCategoryDialog_group on Group {\n  id\n  categories {\n    id\n    title\n    ...CategoryTableItem_category\n  }\n}\n\nfragment GroupInviteDialog_group on Group {\n  id\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n\nfragment UserCreateDialog_group on Group {\n  id\n}\n"
  }
};
})();

(node as any).hash = "8697a5d983c937ecd79fc582d30ceed4";

export default node;
