import { graphql } from 'react-relay';

import { SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource$key } from '../../../relay/__generated__/SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource = graphql`
  fragment SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource on SchoolExamSource
  @argumentDefinitions(
    filters: { type: SchoolExamSourceHistoryFilter }
    order: { type: SchoolExamSourceHistoryOrder, defaultValue: { created: DESC } }
    first: { type: Int, defaultValue: 10 }
    after: { type: String }
  )
  @refetchable(queryName: "SchoolExamSourceSchoolExamSourceHistoryPaginatorRefreshQuery") {
    histories(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource_histories") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          type
          ...SchoolExamSourceHistoryDialog_SchoolExamSourceHistory
          ...SchoolExamSourceHistoryTimelineItem_schoolExamSourceHistory
        }
      }
    }
  }
`;

type Props = {} & Omit<
  FragmentPaginatorProps<SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource$key>,
  'fragment'
>;

const SchoolExamSourceSchoolExamSourceHistoryPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={SchoolExamSourceSchoolExamSourceHistoryPaginator_schoolExamSource} {...props} />
);

export default SchoolExamSourceSchoolExamSourceHistoryPaginator;
