import useConfirm, { UseConfirmOptions } from '../../../hooks/useConfirm';
import Button, { ButtonProps } from '../Button';

type Props = {} & ButtonProps & UseConfirmOptions;

const ConfirmButton = ({ onClick, message, ...props }: Props) => {
  const { confirm } = useConfirm({ message });

  const handleClick: ButtonProps['onClick'] = (event) => {
    if (!onClick) return;
    if (confirm()) onClick(event);
  };
  return <Button onClick={handleClick} {...props} />;
};

export default ConfirmButton;
export type { Props as ConfirmButtonProps };
