/**
 * @generated SignedSource<<0474b1c49a633014a27d606d8aacce9b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type QuizSetLabelingInput = {
  quizSet: NodeInput;
  quizzes: ReadonlyArray<NodeInput>;
};
export type NodeInput = {
  id: string;
};
export type QuizSetId_quizSetLabelingMutation$variables = {
  input: QuizSetLabelingInput;
};
export type QuizSetId_quizSetLabelingMutation$data = {
  readonly quizSetLabeling: {
    readonly id: string;
  };
};
export type QuizSetId_quizSetLabelingMutation = {
  response: QuizSetId_quizSetLabelingMutation$data;
  variables: QuizSetId_quizSetLabelingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "QuizSet",
    "kind": "LinkedField",
    "name": "quizSetLabeling",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuizSetId_quizSetLabelingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuizSetId_quizSetLabelingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c7f9921a7a00ee951aaa1a43b936fa44",
    "id": null,
    "metadata": {},
    "name": "QuizSetId_quizSetLabelingMutation",
    "operationKind": "mutation",
    "text": "mutation QuizSetId_quizSetLabelingMutation(\n  $input: QuizSetLabelingInput!\n) {\n  quizSetLabeling(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "11379eb4be9d68cc70218f4b75576e40";

export default node;
