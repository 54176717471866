import { graphql, useFragment } from 'react-relay';

import {
  DeliveryOrderStatusEnum,
  DeliveryOrderStatusLabel_deliveryOrder$key,
} from '../../../relay/__generated__/DeliveryOrderStatusLabel_deliveryOrder.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const DeliveryOrderStatusLabel_deliveryOrder = graphql`
  fragment DeliveryOrderStatusLabel_deliveryOrder on DeliveryOrder {
    status
  }
`;

type Props = {
  deliveryOrder: DeliveryOrderStatusLabel_deliveryOrder$key;
} & Omit<LabelProps, 'variant'>;

const DeliveryOrderStatusLabel = ({ deliveryOrder, ...props }: Props) => {
  const { status } = useFragment(DeliveryOrderStatusLabel_deliveryOrder, deliveryOrder);

  const labelPropsByStatus: Record<DeliveryOrderStatusEnum, LabelProps> = {
    'waybill_required': { variant: 'secondary' },
    'completed': { variant: 'success' },
    'canceled': { variant: 'danger' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'DeliveryOrderStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default DeliveryOrderStatusLabel;
