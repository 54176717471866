/**
 * @generated SignedSource<<cf4de7aa228e4776d695eb44f245586b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeliveryAddressDescriptionList_deliveryAddress$data = {
  readonly address: string;
  readonly addressDetail: string;
  readonly recipientName: string;
  readonly recipientNumber: string;
  readonly zipCode: string;
  readonly " $fragmentType": "DeliveryAddressDescriptionList_deliveryAddress";
};
export type DeliveryAddressDescriptionList_deliveryAddress$key = {
  readonly " $data"?: DeliveryAddressDescriptionList_deliveryAddress$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryAddressDescriptionList_deliveryAddress">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryAddressDescriptionList_deliveryAddress",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "recipientName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "addressDetail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "zipCode",
      "storageKey": null
    }
  ],
  "type": "DeliveryAddress",
  "abstractKey": null
};

(node as any).hash = "eee1a3109e53a62acb873624edd18af7";

export default node;
