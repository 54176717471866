/**
 * @generated SignedSource<<0c446c5aed383035e4be33a50962aa25>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppDialogImageTypePreviewItem_appDialog$data = {
  readonly appDialogMobileImage: {
    readonly id: string;
    readonly objectUrl: string;
    readonly size: number;
  } | null;
  readonly appDialogTabletImage: {
    readonly id: string;
    readonly objectUrl: string;
    readonly size: number;
  } | null;
  readonly " $fragmentType": "AppDialogImageTypePreviewItem_appDialog";
};
export type AppDialogImageTypePreviewItem_appDialog$key = {
  readonly " $data"?: AppDialogImageTypePreviewItem_appDialog$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppDialogImageTypePreviewItem_appDialog">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppDialogImageTypePreviewItem_appDialog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "appDialogMobileImage",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "appDialogTabletImage",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "AppDialog",
  "abstractKey": null
};
})();

(node as any).hash = "021ae0c2e06f7b6f93f563774a8908c3";

export default node;
