import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeviceCameraIcon,
  IterationsIcon,
  TrashIcon,
} from '@primer/octicons-react';
import { Radio, RadioGroup } from '@primer/react';
import React, { useEffect, useRef, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import useShortcutKey from '../../../hooks/useShortcutKey';
import useToast from '../../../hooks/useToast';
import { SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation } from '../../../relay/__generated__/SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation.graphql';
import { SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource$key } from '../../../relay/__generated__/SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource.graphql';
import { formatDate } from '../../../utils/date';
import { getUploadMetaData, uploadGeneral } from '../../../utils/file';
import { isNullable } from '../../../utils/is';
import Button from '../../core/Button';
import Card from '../../core/Card';
import CheckboxField from '../../core/CheckboxField';
import DescriptionList from '../../core/DescriptionList';
import Dialog, { DialogProps } from '../../core/Dialog';
import DialogButton from '../../core/DialogButton';
import EnumPair from '../../core/EnumPair';
import ErrorBoundary from '../../core/ErrorBoundary';
import FormControl from '../../core/FormControl';
import Grid from '../../core/Grid';
import IconButton from '../../core/IconButton';
import CoreImage from '../../core/Image';
import ItemList from '../../core/ItemList';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import NumberInput from '../../core/NumberInput';
import PdfCropper, { Cropper, PdfCropperProps } from '../../core/PdfCropper';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';

const SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource = graphql`
  fragment SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource on SchoolExamSource
  @argumentDefinitions(
    filters: { type: SchoolExamProblemFilter }
    order: { type: SchoolExamProblemOrder }
    scrapOrder: { type: SchoolExamScrapOrder }
  ) {
    id
    school {
      id
      title
    }
    examYear
    schoolYear
    examType
    innerPdf {
      id
      objectUrl
    }
    scraps(order: $scrapOrder) {
      edges {
        node {
          id
          order
        }
      }
    }

    problems(filters: $filters, order: $order) {
      edges {
        node {
          id
          sequence
          created
          pdf {
            id
            objectUrl
          }
        }
      }
    }
  }
`;

type CroppedImageType = {
  width: number;
  height: number;
  objectUrl: string;
};

type Props = {
  schoolExamSource: SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource$key;
} & DialogProps &
  Pick<
    MutationFormikProps<SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation>,
    'connections' | 'config' | 'onSubmit'
  >;

const SchoolExamSourceSchoolExamScrapCreateDialog = ({
  schoolExamSource,
  config,
  connections,
  onSubmit,
  isOpen,
  ...props
}: Props) => {
  const { id, school, examYear, schoolYear, examType, innerPdf, problems, scraps } = useFragment(
    SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamSource,
    schoolExamSource,
  );
  const { toast } = useToast();

  const allScrapOrders = scraps.edges
    .map(({ node }) => node?.order)
    .filter((order) => !!order)
    .sort((a, b) => a - b);
  const jumpedOrders = allScrapOrders.reduce<number[]>((pre, order, index) => {
    if (index === 0 && order !== 1) return Array.from({ length: order - 1 }, (_, i) => i + 1);
    if (order - allScrapOrders[index - 1] === 1) return pre;

    return [
      ...pre,
      ...Array.from({ length: order - allScrapOrders[index - 1] - 1 }, (_, i) => allScrapOrders[index - 1] + 1 + i),
    ];
  }, []);

  const initialValue = {
    order: allScrapOrders[allScrapOrders.length - 1] + 1 || 1,
    schoolExamSource: id,
    scrap: {
      key: '',
      size: 0,
    },
    isEnhance: false,
  };

  const formattedInnerPdf = { id: innerPdf?.id, pdf: innerPdf, title: '내부 업로드' };
  const formattedProblemPdfs = problems.edges
    .map(({ node }) => node)
    .filter((node) => !!node)
    .map(({ created, pdf, sequence }) => ({
      id: pdf?.id,
      pdf: pdf,
      title: `사용자 업로드 ${sequence} (${formatDate(created, 'P')})`,
    }));
  const pdfList = [formattedInnerPdf, ...formattedProblemPdfs];

  const [selectedPdf, setSelectedPdf] = useState<string | undefined>(pdfList[0].pdf?.objectUrl);

  const [pdfPage, setPdfPage] = useState(1);
  useEffect(() => {
    setPdfPage(1);
  }, [selectedPdf]);

  const [pdfTotalPage, setPdfTotalPage] = useState(1);
  const pdfPageInputRef = useRef<HTMLInputElement>(null);
  const handleClickPdfPageIconButton = (offset: number) => {
    const value = pdfPage + offset;
    if (value > 0 && value <= pdfTotalPage) {
      setPdfPage(value);
      if (pdfPageInputRef.current) pdfPageInputRef.current.valueAsNumber = value;
    }
  };
  const handleLoadSuccessPdf: PdfCropperProps['onLoadSuccess'] = (pdf) => {
    setPdfTotalPage(pdf.numPages);
  };

  const pdfCropperRef = useRef<Cropper>(null);

  const mergeCroppedImages = (images: CroppedImageType[], callback: BlobCallback) => {
    const canvas = document.createElement('canvas');
    canvas.width = Math.max(...images.map(({ width }) => width));
    canvas.height = images.reduce((acc, cur) => acc + cur.height, 0);
    const ctx = canvas.getContext('2d');

    images.forEach(({ width, height, objectUrl }, i, images) => {
      const imageElement = new Image();
      imageElement.src = objectUrl;
      imageElement.width = width;
      imageElement.height = height;
      ctx?.drawImage(
        imageElement,
        0,
        images.slice(0, i).reduce((acc, cur) => acc + cur.height, 0),
      );
    });

    canvas.toBlob(async (blob) => {
      callback(blob);
      canvas.remove();
    });
  };

  const cropButtonRef = useRef<HTMLButtonElement>(null);
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const isEnhanceCheckboxRef = useRef<HTMLInputElement>(null);
  const prevButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const deletePreviewButtonRef = useRef<HTMLButtonElement>(null);

  useShortcutKey(
    (e) => e.code === 'KeyC',
    () => cropButtonRef.current?.click(),
  );
  useShortcutKey(
    (e) => e.code === 'KeyS',
    () => saveButtonRef.current?.click(),
  );
  useShortcutKey(
    (e) => e.code === 'KeyE',
    () => isEnhanceCheckboxRef.current?.click(),
  );
  useShortcutKey(
    (e) => e.code === 'KeyD',
    () => deletePreviewButtonRef.current?.click(),
  );

  useShortcutKey(
    (e) => e.code === 'KeyZ',
    () => prevButtonRef.current?.click(),
  );
  useShortcutKey(
    (e) => e.code === 'KeyV',
    () => nextButtonRef.current?.click(),
  );

  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>PDF 선택하기</Dialog.Header>
      <Dialog.Body>
        <RadioGroup
          aria-labelledby={'스크랩할 PDF 선택'}
          name={'select pdf'}
          onChange={(value) => setSelectedPdf(value as string)}
        >
          <ItemList
            items={pdfList}
            renderItem={({ id, pdf, title }) => {
              if (!pdf) return null;
              return (
                <FormControl key={id} sx={{ alignItems: 'center' }}>
                  <Radio value={pdf.objectUrl} checked={pdf.objectUrl === selectedPdf} />
                  <FormControl.Label sx={{ fontWeight: 'normal' }}>{title}</FormControl.Label>
                </FormControl>
              );
            }}
          />
        </RadioGroup>
      </Dialog.Body>
      <Dialog.Footer>
        <DialogButton
          renderDialog={({ isOpen, closeDialog }) => (
            <Dialog
              full
              isOpen={isOpen}
              onDismiss={() => {
                closeDialog();
                props.onDismiss?.();
              }}
            >
              <MutationFormik<SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation>
                mutation={graphql`
                  mutation SchoolExamSourceSchoolExamScrapCreateDialog_schoolExamScrapCreateMutation(
                    $input: SchoolExamScrapCreateInput!
                  ) {
                    schoolExamScrapCreate(input: $input) {
                      id
                      order
                    }
                  }
                `}
                initialValues={initialValue}
                config={{
                  ...config,
                  onCompleted: (response, _error, formikHelper) => {
                    config?.onCompleted?.(response, _error, formikHelper);
                    const { schoolExamScrapCreate } = response;

                    formikHelper.resetForm({
                      values: {
                        ...initialValue,
                        order: schoolExamScrapCreate.order + 1,
                        scrap: { key: '', size: 0 },
                        isEnhance: !!isEnhanceCheckboxRef.current?.checked,
                      },
                    });
                  },
                }}
                connections={connections}
                onSubmit={onSubmit}
                enableReinitialize={false}
              >
                {(
                  { values, setFieldValue, submitForm, status: mutationFormikStatus, setStatus, setSubmitting },
                  { scrollContainerRef, nonFieldErrorRef },
                ) => {
                  const status = (mutationFormikStatus || []) as CroppedImageType[];
                  const availableSubmit = status.length > 0;

                  return (
                    <>
                      <Dialog.Header>
                        <Grid wrap={false} gapX={2} sx={{ paddingRight: 4, alignItems: 'center' }}>
                          <Grid.Unit size={'max'}>
                            <Stack gapX={1}>
                              <Stack.Item>
                                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>스크랩하기</Text>
                              </Stack.Item>
                              <Stack.Item>
                                <Label variant={'accent'}>
                                  {school.title} <EnumPair typename={'ExamYearEnum'}>{examYear}</EnumPair>{' '}
                                  <EnumPair typename={'SchoolYearEnum'}>{schoolYear}</EnumPair>{' '}
                                  <EnumPair typename={'ExamTypeEnum'}>{examType}</EnumPair>
                                </Label>
                              </Stack.Item>
                            </Stack>
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            <CheckboxField
                              ref={isEnhanceCheckboxRef}
                              label={'제출 시 이미지 보정'}
                              name={'isEnhance'}
                            />
                          </Grid.Unit>
                          <Grid.Unit size={'min'}>
                            <Button
                              ref={saveButtonRef}
                              variant={'primary'}
                              leadingIcon={CheckIcon}
                              onClick={() => {
                                setSubmitting(true);
                                mergeCroppedImages(status, async (blob) => {
                                  if (isNullable(blob)) {
                                    setSubmitting(false);
                                    return;
                                  }
                                  const { type: fileType, size: fileSize } = blob;

                                  const [, extension] = fileType.split('/');

                                  const metaData = await getUploadMetaData({
                                    fileName: `${id}-${values.order}.${extension}`,
                                    fileSize,
                                  });

                                  if (!metaData) {
                                    setSubmitting(false);
                                    return;
                                  }

                                  const isMultiPartUploadRequired =
                                    'upload_id' in metaData && 'part_metadata' in metaData;

                                  if (isMultiPartUploadRequired) {
                                    toast('사진 크기가 너무 큽니다. 더 작게 캡쳐해주세요.', 'error');
                                    setSubmitting(false);
                                  } else {
                                    const response = await uploadGeneral(blob, metaData);
                                    if (response.ok) {
                                      setFieldValue('scrap', {
                                        key: metaData.key,
                                        size: blob.size,
                                        objectUrl: metaData.object_url,
                                      });
                                      setTimeout(() => submitForm());
                                    }
                                  }
                                });
                              }}
                              disabled={!availableSubmit}
                            >
                              저장 및 다음
                            </Button>
                          </Grid.Unit>
                        </Grid>
                      </Dialog.Header>
                      <Dialog.Body sx={{ padding: 0 }}>
                        <View sx={{ overflowX: 'hidden', height: '100%' }}>
                          <Grid wrap={false} sx={{ height: '100%' }}>
                            <Grid.Unit
                              ref={scrollContainerRef}
                              size={3 / 4}
                              sx={{
                                'height': '100%',
                                'padding': 3,
                                '&>*:not(:last-child)': { marginBottom: 3 },
                                'display': 'flex',
                                'flexDirection': 'column',
                              }}
                            >
                              <NonFieldError ref={nonFieldErrorRef} />
                              <Grid gapX={2} sx={{ alignItems: 'center' }}>
                                <Grid.Unit size={'max'}>
                                  <Stack gapX={3}>
                                    <Stack.Item>
                                      <Text fontSize={2} fontWeight={'bold'}>
                                        시험지
                                      </Text>
                                    </Stack.Item>
                                    <Stack.Item>
                                      <Stack gapX={1}>
                                        <Stack.Item>
                                          <IconButton
                                            ref={prevButtonRef}
                                            icon={ChevronLeftIcon}
                                            aria-label={'previous school exam pdf page'}
                                            size={'large'}
                                            disabled={pdfPage <= 1}
                                            onClick={() => handleClickPdfPageIconButton(-1)}
                                          />
                                        </Stack.Item>
                                        <Stack.Item>
                                          <NumberInput
                                            ref={pdfPageInputRef}
                                            sx={{ width: 60 }}
                                            min={1}
                                            max={pdfTotalPage}
                                            defaultValue={pdfPage}
                                            onChange={(e) =>
                                              pdfCropperRef.current?.setPageNumber(Number(e.target.value))
                                            }
                                            size={'large'}
                                          />
                                        </Stack.Item>
                                        <Stack.Item>
                                          <IconButton
                                            ref={nextButtonRef}
                                            icon={ChevronRightIcon}
                                            aria-label={'next school exam pdf page'}
                                            size={'large'}
                                            disabled={pdfPage >= pdfTotalPage}
                                            onClick={() => handleClickPdfPageIconButton(1)}
                                          />
                                        </Stack.Item>
                                        <Stack.Item>
                                          <Text fontSize={2}>{`/ ${pdfTotalPage || 1}P`}</Text>
                                        </Stack.Item>
                                      </Stack>
                                    </Stack.Item>
                                  </Stack>
                                </Grid.Unit>
                                <Grid.Unit size={'min'} sx={{ lineHeight: 1, textAlign: 'end' }}>
                                  <Text fontSize={0} color={'fg.subtle'} whiteSpace={'pre-wrap'}>
                                    {
                                      '[단축키] (C):자르기, (S):저장, (E):이미지 보정, (D):마지막 프리뷰 삭제\n(Z):이전 페이지, (V):다음 페이지'
                                    }
                                  </Text>
                                </Grid.Unit>
                                <Grid.Unit size={'min'}>
                                  <Stack gapX={2}>
                                    <Stack.Item>
                                      <IconButton
                                        icon={IterationsIcon}
                                        aria-label={'rotate school exam pdf'}
                                        size={'large'}
                                        onClick={() => {
                                          if (!isNullable(pdfCropperRef.current)) pdfCropperRef.current.rotate(270);
                                        }}
                                      />
                                    </Stack.Item>
                                    <Stack.Item>
                                      <IconButton
                                        ref={cropButtonRef}
                                        icon={DeviceCameraIcon}
                                        aria-label={'crop school exam pdf'}
                                        size={'large'}
                                        onClick={() => {
                                          if (!isNullable(pdfCropperRef.current)) {
                                            setStatus([
                                              ...status,
                                              {
                                                width: pdfCropperRef.current.getCroppedCanvas().width,
                                                height: pdfCropperRef.current.getCroppedCanvas().height,
                                                objectUrl: pdfCropperRef.current.getCroppedCanvas().toDataURL(),
                                              },
                                            ]);
                                          }
                                        }}
                                      />
                                    </Stack.Item>
                                  </Stack>
                                </Grid.Unit>
                              </Grid>
                              <View sx={{ overflowY: 'scroll' }}>
                                <ErrorBoundary>
                                  <PdfCropper
                                    ref={pdfCropperRef}
                                    src={selectedPdf || ''}
                                    pageNumber={pdfPage}
                                    onLoadSuccess={handleLoadSuccessPdf}
                                    pdfWidth={1080}
                                    cropperHeight={'auto'}
                                    scale={1.5}
                                  />
                                </ErrorBoundary>
                              </View>
                            </Grid.Unit>
                            <Grid.Unit
                              size={1 / 4}
                              sx={{
                                backgroundColor: 'canvas.inset',
                                height: '100%',
                                padding: 3,
                              }}
                            >
                              <View sx={{ maxWidth: 400, height: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Grid gapX={6} gapY={2} sx={{ alignItems: 'center' }}>
                                  <Grid.Unit size={'min'}>
                                    <Stack gapX={2}>
                                      <Stack.Item>
                                        <Stack gapX={1}>
                                          <Stack.Item>
                                            <Text fontSize={2} fontWeight={'bold'}>
                                              문제
                                            </Text>
                                          </Stack.Item>
                                          <Stack.Item>
                                            <Text
                                              fontSize={1}
                                              fontWeight={'bold'}
                                              color={status.length === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                                            >
                                              {status.length}
                                            </Text>
                                          </Stack.Item>
                                        </Stack>
                                      </Stack.Item>
                                      <Stack.Item>
                                        <Stack gapX={1}>
                                          <Stack.Item>
                                            <NumberField
                                              sx={{ width: 60 }}
                                              label={'문제 번호'}
                                              labelConfig={{ visuallyHidden: true }}
                                              name={'order'}
                                              min={1}
                                              size={'large'}
                                            />
                                          </Stack.Item>
                                          <Stack.Item>
                                            <Text fontSize={1}>번</Text>
                                          </Stack.Item>
                                        </Stack>
                                      </Stack.Item>
                                    </Stack>
                                  </Grid.Unit>
                                  <Grid.Unit size={'max'}>
                                    <DescriptionList
                                      item={{
                                        lastScrapOrder: allScrapOrders[allScrapOrders.length - 1],
                                        jumpedOrders,
                                      }}
                                      itemDescriptions={{
                                        lastScrapOrder: {
                                          title: '마지막 스크랩 번호',
                                          renderValue: ({ lastScrapOrder }) => lastScrapOrder,
                                        },
                                        jumpedOrders: {
                                          title: '스크랩이 없는 번호',
                                          renderValue: ({ jumpedOrders }) =>
                                            jumpedOrders.length !== 0 ? (
                                              <View sx={{ maxHeight: 100, overflowY: 'auto' }}>
                                                {jumpedOrders.join(', ')}
                                              </View>
                                            ) : undefined,
                                        },
                                      }}
                                      picks={['lastScrapOrder', 'jumpedOrders']}
                                      titleUnitSize={'max'}
                                      descriptionUnitSize={'min'}
                                      renderTitle={(title) => (
                                        <Text fontWeight={'bold'} fontSize={1} color={'fg.muted'}>
                                          {title}
                                        </Text>
                                      )}
                                      renderDescription={(description) => (
                                        <Text fontSize={0} color={'fg.subtle'}>
                                          {description}
                                        </Text>
                                      )}
                                    />
                                  </Grid.Unit>
                                </Grid>
                                <Card sx={{ borderRadius: 0, flexGrow: 1, marginTop: 2, overflowY: 'auto' }}>
                                  <ItemList
                                    items={status.map(({ objectUrl }) => ({ id: objectUrl, objectUrl }))}
                                    renderItem={({ objectUrl }, index) => (
                                      <View
                                        sx={{
                                          'position': 'relative',
                                          '&:hover': {
                                            '*': {
                                              display: 'block',
                                            },
                                          },
                                        }}
                                      >
                                        <View
                                          sx={{
                                            position: 'absolute',
                                            display: 'none',
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'fg.default',
                                            opacity: 0.2,
                                            zIndex: 1,
                                          }}
                                        />
                                        <IconButton
                                          ref={index === status.length - 1 ? deletePreviewButtonRef : undefined}
                                          sx={{ position: 'absolute', display: 'none', left: 2, bottom: 2, zIndex: 1 }}
                                          size={'large'}
                                          icon={TrashIcon}
                                          aria-label={`delete image${index}`}
                                          onClick={() => {
                                            setStatus(status.filter((_, i) => i !== index));
                                          }}
                                        />
                                        <CoreImage
                                          src={objectUrl || ''}
                                          alt={`image${index}`}
                                          html
                                          style={{ display: 'block' }}
                                        />
                                      </View>
                                    )}
                                    renderItemWrapper={(children, { id }) => <View key={id}>{children}</View>}
                                    emptyState={
                                      <View
                                        sx={{
                                          height: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <Text fontSize={2} color={'neutral.emphasis'}>
                                          문제를 스크랩 해주세요
                                        </Text>
                                      </View>
                                    }
                                  />
                                </Card>
                              </View>
                            </Grid.Unit>
                          </Grid>
                        </View>
                      </Dialog.Body>
                    </>
                  );
                }}
              </MutationFormik>
            </Dialog>
          )}
          variant={'primary'}
          leadingIcon={CheckIcon}
          disabled={!selectedPdf}
        >
          선택 완료
        </DialogButton>
      </Dialog.Footer>
    </Dialog>
  );
};

export default SchoolExamSourceSchoolExamScrapCreateDialog;
