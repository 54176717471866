import { graphql, useFragment } from 'react-relay';

import { DeliveryOrderHistoryDialog_deliveryOrderHistory$key } from '../../../relay/__generated__/DeliveryOrderHistoryDialog_deliveryOrderHistory.graphql';
import Avatar from '../../core/Avatar';
import DateText from '../../core/DateText';
import Dialog, { DialogProps } from '../../core/Dialog';
import Grid from '../../core/Grid';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import DeliveryOrderDifferenceCounterLabel from '../DeliveryOrderDifferenceCounterLabel';
import DeliveryOrderDifferenceItemList from '../DeliveryOrderDifferenceItemList';

const DeliveryOrderHistoryDialog_deliveryOrderHistory = graphql`
  fragment DeliveryOrderHistoryDialog_deliveryOrderHistory on DeliveryOrderHistory {
    createdBy {
      name
      picture
    }
    created
    difference {
      ...DeliveryOrderDifferenceCounterLabel_difference
      ...DeliveryOrderDifferenceItemList_difference
    }
  }
`;

type Props = { deliveryOrderHistory: DeliveryOrderHistoryDialog_deliveryOrderHistory$key } & DialogProps;

const DeliveryOrderHistoryDialog = ({ deliveryOrderHistory, ...props }: Props) => {
  const { created, createdBy, difference } = useFragment(
    DeliveryOrderHistoryDialog_deliveryOrderHistory,
    deliveryOrderHistory,
  );
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Grid gapX={3} sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'min'}>
            <Avatar src={createdBy.picture || ''} alt={createdBy.name} size={40} />
          </Grid.Unit>
          <Grid.Unit size={'max'}>
            <Stack gapX={1}>
              <Stack.Item>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{`${createdBy.name}님의 수정 내역`}</Text>
              </Stack.Item>
              <Stack.Item>
                {difference ? <DeliveryOrderDifferenceCounterLabel difference={difference} scheme={'primary'} /> : null}
              </Stack.Item>
            </Stack>
            <View>
              <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
            </View>
          </Grid.Unit>
        </Grid>
      </Dialog.Header>
      <View sx={{ padding: 3 }}>{difference ? <DeliveryOrderDifferenceItemList difference={difference} /> : null}</View>
    </Dialog>
  );
};

export default DeliveryOrderHistoryDialog;
