import { useField } from 'formik';
import { Suspense } from 'react';
import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import usePaginatorSearchOverlayQueryLoader from '../../../hooks/usePaginatorSearchOverlayQueryLoader';
import {
  UserPaginatorSearchOverlayTokenField_UserValue_userQuery,
  UserPaginatorSearchOverlayTokenField_UserValue_userQuery$variables,
} from '../../../relay/__generated__/UserPaginatorSearchOverlayTokenField_UserValue_userQuery.graphql';
import {
  UserPaginatorSearchOverlayTokenField_usersQuery,
  UserPaginatorSearchOverlayTokenField_usersQuery$variables,
} from '../../../relay/__generated__/UserPaginatorSearchOverlayTokenField_usersQuery.graphql';
import { isNullable } from '../../../utils/is';
import Checkbox from '../../core/Checkbox';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import Label from '../../core/Label';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import ScrollContainer from '../../core/ScrollContainer';
import SearchOverlayTokenField, { SearchOverlayTokenFieldProps } from '../../core/SearchOverlayTokenField';
import Spinner from '../../core/Spinner';
import View from '../../core/View';
import UserAvatarText from '../UserAvatarText';
import UserPaginator from '../UserPaginator';

const usersForUserPaginatorSearchOverlayTokenField = graphql`
  query UserPaginatorSearchOverlayTokenField_usersQuery(
    $filters: UserFilter
    $order: UserOrder
    $first: Int
    $after: String
  ) {
    users(filters: $filters, order: $order, first: $first, after: $after) {
      edges {
        node {
          id
          name
        }
      }
    }
    ...UserPaginator_query @arguments(filters: $filters, order: $order, first: $first, after: $after)
  }
`;

type Props = {
  variables?: UserPaginatorSearchOverlayTokenField_usersQuery$variables;
} & Omit<SearchOverlayTokenFieldProps, 'renderItemList'>;

const UserPaginatorSearchOverlayTokenField = ({ name = '', value: propValue, variables, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string[] | undefined>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [searchOverlayFieldProps, queryReference] =
    usePaginatorSearchOverlayQueryLoader<UserPaginatorSearchOverlayTokenField_usersQuery>(
      usersForUserPaginatorSearchOverlayTokenField,
      variables,
    );

  return (
    <SearchOverlayTokenField
      name={name}
      value={value}
      renderTokenText={(id) =>
        (
          // TODO
          <Suspense>
            <UserValue id={id} />
          </Suspense>
        ) as any
      }
      renderItemList={({ containerRef, handleSelect }, itemProps) =>
        queryReference ? (
          <Suspense fallback={<Spinner sx={{ paddingY: 3 }} />}>
            <PreloadedQueryRenderer
              query={usersForUserPaginatorSearchOverlayTokenField}
              queryReference={queryReference}
            >
              {(queryData) => (
                <UserPaginator fragmentReference={queryData}>
                  {(data, { hasNext, isLoadingNext, loadMore }) => (
                    <ScrollContainer
                      ref={containerRef as React.RefObject<HTMLDivElement>}
                      onScrollReachEnd={() => {
                        if (hasNext && !isLoadingNext) loadMore(20);
                      }}
                    >
                      <ItemList
                        items={data.users.edges}
                        renderItem={({ node }) => (
                          <>
                            <UserAvatarText user={node} sx={{ fontSize: 1 }} />
                            {node.group ? <Label sx={{ marginLeft: 1 }}>{node.group.title}</Label> : null}
                          </>
                        )}
                        renderItemWrapper={(children, { node: { id } }) => (
                          <View
                            {...itemProps}
                            key={id}
                            onClick={(e) => handleSelect(e, id)}
                            onKeyDown={(e) => handleSelect(e, id)}
                          >
                            <Grid gapX={2} wrap={false}>
                              <Grid.Unit size={'min'}>
                                <Checkbox checked={value?.some((selectedId) => selectedId === id)} />
                              </Grid.Unit>
                              <Grid.Unit size={'max'}>{children}</Grid.Unit>
                            </Grid>
                          </View>
                        )}
                        emptyState={
                          <View sx={{ paddingY: 3 }}>
                            <EmptyState title={'검색 결과가 없어요'} description={'다른 검색어로 다시 시도해주세요.'} />
                          </View>
                        }
                      />
                      <View>{isLoadingNext ? <Spinner size={'small'} /> : null}</View>
                    </ScrollContainer>
                  )}
                </UserPaginator>
              )}
            </PreloadedQueryRenderer>
          </Suspense>
        ) : null
      }
      {...searchOverlayFieldProps}
      {...props}
    />
  );
};

const UserValue = ({ ...variables }: UserPaginatorSearchOverlayTokenField_UserValue_userQuery$variables) => {
  const [{ user }] = useLazyLoadQuery<UserPaginatorSearchOverlayTokenField_UserValue_userQuery>(
    graphql`
      query UserPaginatorSearchOverlayTokenField_UserValue_userQuery($id: ID!) {
        user(id: $id) {
          id
          name
        }
      }
    `,
    { ...variables },
  );

  return user.name as unknown as JSX.Element;
};

export default UserPaginatorSearchOverlayTokenField;
