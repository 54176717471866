import React, { ReactNode, Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  CategoryTableItem_category$data,
  CategoryTableItem_category$key,
} from '../../../relay/__generated__/CategoryTableItem_category.graphql';
import EnumPair from '../../core/EnumPair';
import ItemList from '../../core/ItemList';
import Text from '../../core/Text';
import View from '../../core/View';

const CategoryTableItem_category = graphql`
  fragment CategoryTableItem_category on Category {
    id
    features {
      id
      title
      permissions {
        id
        isActive
        label
      }
    }
  }
`;

type Props = {
  category: CategoryTableItem_category$key;
  renderPermissions?: (permissions: CategoryTableItem_category$data['features'][number]['permissions']) => ReactNode;
};

const CategoryTableItem = ({ category, renderPermissions: propRenderPermissions }: Props) => {
  const { features } = useFragment(CategoryTableItem_category, category);

  const renderPermission = propRenderPermissions
    ? propRenderPermissions
    : (permissions: CategoryTableItem_category$data['features'][number]['permissions']) => (
        <Text fontSize={1}>{permissions.map(({ label }) => label).join(', ')}</Text>
      );

  return (
    <View
      as={'table'}
      sx={{ width: '100%', borderTopStyle: 'solid', borderTopColor: 'border.default', borderTopWidth: 1 }}
    >
      <View as={'tbody'}>
        <ItemList
          items={features}
          renderItem={({ title, permissions }) => (
            <>
              <View
                as={'td'}
                sx={{
                  width: 140,
                  padding: 2,
                  borderRightStyle: 'solid',
                  borderRightColor: 'border.default',
                  borderRightWidth: 1,
                }}
              >
                <Text sx={{ fontSize: 1 }}>
                  <Suspense>
                    <EnumPair typename={'FeatureEnum'}>{title}</EnumPair>
                  </Suspense>
                </Text>
              </View>
              <View as={'td'}>{renderPermission(permissions)}</View>
            </>
          )}
          renderItemWrapper={(children, { id }) => (
            <View
              key={id}
              as={'tr'}
              sx={{ borderBottomStyle: 'solid', borderBottomColor: 'border.default', borderBottomWidth: 1 }}
            >
              {children}
            </View>
          )}
        />
      </View>
    </View>
  );
};

export default CategoryTableItem;
