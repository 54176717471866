import { Button as PrimerButton, ButtonProps as PrimerButtonProps } from '@primer/react';
import React from 'react';

import useFormikContext from '../../../hooks/useFormikContext';

// type NoStringIndex<T> = { [K in keyof T as string extends K ? never : K]: T[K] };
// type NoNumberIndex<T> = { [K in keyof T as number extends K ? never : K]: T[K] };
// type NoSymbolIndex<T> = { [K in keyof T as symbol extends K ? never : K]: T[K] };
//
// type Props = {} & NoStringIndex<NoNumberIndex<NoSymbolIndex<PrimerButtonProps>>>;
type Props = {} & PrimerButtonProps;

const Button = ({ disabled, ...props }: Props, ref: React.Ref<HTMLButtonElement>) => {
  const formikContext = useFormikContext();

  return <PrimerButton ref={ref} disabled={disabled || formikContext?.isSubmitting} {...props} />;
};

export default Object.assign(React.forwardRef(Button), { Counter: PrimerButton.Counter });
export type { Props as ButtonProps };
