import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  BookDescriptionList_book$data,
  BookDescriptionList_book$key,
} from '../../../relay/__generated__/BookDescriptionList_book.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';
import UserAvatarText from '../../user/UserAvatarText';
import BookStatusLabel from '../BookStatusLabel';

const BookDescriptionList_book = graphql`
  fragment BookDescriptionList_book on Book {
    type
    title
    unitAs {
      id
      title
    }
    publisher
    edition
    ...BookStatusLabel_book
    createdBy {
      id
      ...UserAvatarText_user
    }
    submittedBy {
      id
      ...UserAvatarText_user
    }
    created
  }
`;

type Props = {
  book: BookDescriptionList_book$key;
  type?: 'default' | 'activity';
} & Omit<DescriptionListProps<BookDescriptionList_book$data>, 'item' | 'itemDescriptions' | 'picks'>;

const BookDescriptionList = ({ book: bookReference, type = 'default', ...props }: Props) => {
  const book = useFragment(BookDescriptionList_book, bookReference);

  return (
    <DescriptionList
      item={book}
      itemDescriptions={{
        type: {
          title: '교재 타입',
          renderValue: ({ type }) => (
            <Suspense fallback={'-'}>
              <EnumPair typename={'BookTypeEnum'}>{type}</EnumPair>
            </Suspense>
          ),
        },
        title: {
          title: '교재 이름',
          renderValue: ({ title }) => title,
        },
        unitAs: {
          title: 'Unit A',
          renderValue: ({ unitAs }) => unitAs.map(({ title }) => title).join(', '),
        },
        publisher: {
          title: '출판사',
          renderValue: ({ publisher }) => publisher,
        },
        edition: {
          title: '발행 연도',
          renderValue: ({ edition }) => edition || '-',
        },
        status: {
          title: '상태',
          renderValue: (book) => <BookStatusLabel book={book} size={'small'} />,
        },
        createdBy: {
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
        },
        submittedBy: {
          title: '제출자',
          renderValue: ({ submittedBy }) => (submittedBy ? <UserAvatarText user={submittedBy} /> : '-'),
        },
        created: {
          title: '제작일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
        },
      }}
      picks={
        type === 'default'
          ? ['type', 'title', 'unitAs', 'publisher', 'edition']
          : type === 'activity'
          ? ['status', 'createdBy', 'submittedBy', 'created']
          : []
      }
      {...props}
    />
  );
};

export default BookDescriptionList;
