import { useField } from 'formik';
import { useState } from 'react';

import useEnumPairsQuery from '../../../hooks/useEnumPairsQuery';
import { isNullable } from '../../../utils/is';
import Checkbox from '../Checkbox';
import EmptyState from '../EmptyState';
import Grid from '../Grid';
import ItemList from '../ItemList';
import SearchOverlayTokenField, { SearchOverlayTokenFieldProps } from '../SearchOverlayTokenField';
import Text from '../Text';
import View from '../View';

type Props = {
  typename: string;
} & Omit<SearchOverlayTokenFieldProps, 'renderItemList'>;

const EnumPairSearchOverlayTokenField = ({
  name = '',
  value: propValue,
  typename,
  onOverlayClose,
  ...props
}: Props) => {
  const [{ value: baseValue }] = useField<string[] | undefined>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const enumPairs = useEnumPairsQuery(typename);
  const [items, setItems] = useState(enumPairs);

  const handleCloseOverlay = () => {
    onOverlayClose?.();
    setItems(enumPairs);
  };

  return (
    <SearchOverlayTokenField
      name={name}
      value={value}
      renderTokenText={(key) => enumPairs.find((item) => item.key === key)?.value || ''}
      renderItemList={({ containerRef, handleSelect }, itemProps) => (
        <View ref={containerRef as React.RefObject<HTMLDivElement>}>
          <ItemList
            items={items}
            renderItem={({ value }) => <Text sx={{ fontSize: 1 }}>{value}</Text>}
            renderItemWrapper={(children, { key }) => (
              <View
                {...itemProps}
                key={key}
                onClick={(e) => handleSelect(e, key)}
                onKeyDown={(e) => handleSelect(e, key)}
              >
                <Grid gapX={2} wrap={false}>
                  <Grid.Unit size={'min'}>
                    <Checkbox checked={value?.some((selectedKey) => selectedKey === key)} />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>{children}</Grid.Unit>
                </Grid>
              </View>
            )}
            emptyState={
              <View sx={{ paddingY: 3 }}>
                <EmptyState title={'검색 결과가 없어요'} description={'다른 검색어로 다시 시도해주세요.'} />
              </View>
            }
          />
        </View>
      )}
      onSearch={(text) => {
        const filteredItems = enumPairs.filter(({ value }) => value.indexOf(text) !== -1);
        setItems(filteredItems);
      }}
      onOverlayClose={handleCloseOverlay}
      {...props}
    />
  );
};

export default EnumPairSearchOverlayTokenField;
