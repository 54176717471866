/**
 * @generated SignedSource<<5da16292aaff840576d355e12f4f0832>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TranscodingStatusEnum = "failed" | "ongoing" | "pending" | "success" | "%future added value";
export type VideoTypeEnum = "horizontal" | "vertical" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskVideoConnectionDataTable_taskVideoConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly created: any;
      readonly id: string;
      readonly originVideoUrl: string | null;
      readonly teacher: {
        readonly id: string;
        readonly name: string;
      };
      readonly title: string;
      readonly type: VideoTypeEnum;
      readonly video: {
        readonly id: string;
        readonly transcodingStatus: TranscodingStatusEnum;
      };
    };
  }>;
  readonly " $fragmentType": "TaskVideoConnectionDataTable_taskVideoConnection";
};
export type TaskVideoConnectionDataTable_taskVideoConnection$key = {
  readonly " $data"?: TaskVideoConnectionDataTable_taskVideoConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskVideoConnectionDataTable_taskVideoConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskVideoConnectionDataTable_taskVideoConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskVideoEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskVideo",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Teacher",
              "kind": "LinkedField",
              "name": "teacher",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Video",
              "kind": "LinkedField",
              "name": "video",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "transcodingStatus",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "originVideoUrl",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TaskVideoConnection",
  "abstractKey": null
};
})();

(node as any).hash = "e775bc92b4bdadf79a1a41353a803847";

export default node;
