import { BellIcon, SearchIcon, SyncIcon, XIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { ComponentProps, Suspense } from 'react';
import { graphql } from 'react-relay';

import AppPushConnectionDataTable from '../../components/appPush/AppPushConnectionDataTable';
import AppPushCreateDialog from '../../components/appPush/AppPushCreateDialog';
import AppPushWindowPaginator from '../../components/appPush/AppPushWindowPaginator';
import Button from '../../components/core/Button';
import DateRangeField from '../../components/core/DateRangeField';
import DialogButton from '../../components/core/DialogButton';
import EmptyState from '../../components/core/EmptyState';
import EnumPairSearchOverlayTokenField from '../../components/core/EnumPairSearchOverlayTokenField';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import FetchQueryButton from '../../components/core/FetchQueryButton';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import QueryFormik from '../../components/core/QueryFormik';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import UserPaginatorSearchOverlayTokenField from '../../components/user/UserPaginatorSearchOverlayTokenField';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useToast from '../../hooks/useToast';
import { appPush_appPushXlsxExportQuery } from '../../relay/__generated__/appPush_appPushXlsxExportQuery.graphql';
import { appPush_appPushsQuery, AppPushStatusEnum } from '../../relay/__generated__/appPush_appPushsQuery.graphql';
import { numberWithCommas } from '../../utils/number';
import { NextPage } from '../_app';

import AppPushId from './[appPushId]';

const appPushsForAppPush = graphql`
  query appPush_appPushsQuery($filters: AppPushFilter, $order: AppPushOrder, $page: Int, $pageSize: Int) {
    ...AppPushWindowPaginator_query @arguments(filters: $filters, order: $order, page: $page, pageSize: $pageSize)
  }
`;

type Props = {};

const AppPush: NextPage<Props> = () => {
  const router = useRouter();
  const { toast } = useToast();
  const { initialValues, setParsedUrlQuery } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    page: { type: 'number' },

    status_In: { type: 'string', multiple: true },

    createdById_In: { type: 'string', multiple: true },
    startAt_Gte: { type: 'string' },
    startAt_Lte: { type: 'string' },
  });
  const { search, order, page, status_In, createdById_In, startAt_Gte, startAt_Lte } = initialValues;

  return (
    <View>
      <Head siteTitle={'푸시 알림'} />
      <ErrorBoundary>
        <QueryFormik<appPush_appPushsQuery>
          query={appPushsForAppPush}
          staticVariables={{ pageSize: 20 }}
          initialValues={{
            filters: {
              search,
              status_In: status_In as AppPushStatusEnum[],
              createdById_In,
              startAt_Gte,
              startAt_Lte,
            },
            order: order || '-startAt',
            page,
          }}
          options={{ fetchPolicy: 'store-and-network' }}
          onSubmit={(values) => setParsedUrlQuery({ ...values.filters, order }, { scroll: false })}
        >
          {({ values: { filters, order }, handleSubmit, resetForm, setFieldValue, submitForm }, queryReference) => {
            const handleReset = () => {
              resetForm({ values: { order } });
              setParsedUrlQuery({ order }, { scroll: false });
            };

            const handleChangeOrderActionMenu: ComponentProps<typeof QueryFormik.OrderActionMenuButton>['onChange'] = (
              newValue,
            ) => {
              setParsedUrlQuery({ ...filters, order: newValue }, { scroll: false });
            };

            const isPeriodSelected = filters?.startAt_Gte || filters?.startAt_Lte;

            return (
              <View>
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={'max'}>
                    <Text as={'h1'}>푸시 알림</Text>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Stack gapX={2}>
                      <Stack.Item>
                        <FetchQueryButton<appPush_appPushXlsxExportQuery>
                          query={graphql`
                            query appPush_appPushXlsxExportQuery($filters: AppPushFilter) {
                              appPushXlsxExport(filters: $filters)
                            }
                          `}
                          variables={{ filters }}
                          variant={'outline'}
                          size={'large'}
                          onComplete={(data) => {
                            if (data) router.push(data.appPushXlsxExport);
                          }}
                          onError={() => toast('xlsx 다운로드에 실패했어요', 'error')}
                        >
                          xlsx 다운로드
                        </FetchQueryButton>
                      </Stack.Item>
                      <Stack.Item>
                        <DialogButton
                          size={'large'}
                          renderDialog={({ isOpen, closeDialog }) => (
                            <AppPushCreateDialog
                              isOpen={isOpen}
                              onDismiss={closeDialog}
                              full
                              config={{
                                onCompleted: () => {
                                  toast('푸시 알림이 생성됐어요!', 'success');
                                  handleSubmit();
                                  closeDialog();
                                },
                                onError: () => {
                                  toast('다시 생성해 주세요', 'error');
                                },
                              }}
                            />
                          )}
                          variant={'primary'}
                        >
                          생성하기
                        </DialogButton>
                      </Stack.Item>
                    </Stack>
                  </Grid.Unit>
                </Grid>
                <Grid>
                  <Grid.Unit size={'min'}>
                    <QueryFormik.FilterSearchTextField
                      typename={'AppPushFilter'}
                      label={'Search'}
                      labelConfig={{ visuallyHidden: true }}
                      name={'filters.search'}
                      size={'large'}
                      autoComplete={'off'}
                      leadingVisual={SearchIcon}
                      debounce
                      onChange={(e) => {
                        setFieldValue('filters.search', e.target.value);
                        setTimeout(() => submitForm(), 0);
                      }}
                    />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>
                    <View sx={{ display: 'flex', justifyContent: 'end' }}>
                      <Stack gapX={2}>
                        <Stack.Item>
                          <Button leadingIcon={SyncIcon} variant={'plain'} onClick={handleReset}>
                            초기화
                          </Button>
                        </Stack.Item>
                        <Stack.Item>
                          <QueryFormik.OrderActionMenuButton
                            typename={'AppPushOrder'}
                            orders={['startAt']}
                            onChange={handleChangeOrderActionMenu}
                          />
                        </Stack.Item>
                      </Stack>
                    </View>
                  </Grid.Unit>
                </Grid>
                <View sx={{ marginTop: 5 }}>
                  <QueryFormik.FilterGrid
                    onChange={(filters) => {
                      setParsedUrlQuery({ ...filters, order }, { scroll: false });
                    }}
                  >
                    <QueryFormik.FilterGridUnit size={1} title={'상태'}>
                      <EnumPairSearchOverlayTokenField
                        typename={'AppPushStatusEnum'}
                        label={'상태'}
                        name={'filters.status_In'}
                        placeholder={'상태 선택'}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'제작자'}>
                      <UserPaginatorSearchOverlayTokenField
                        label={'제작자'}
                        placeholder={'제작자 선택'}
                        name={'filters.createdById_In'}
                        variables={{
                          filters: {
                            team_In: ['growth', 'developers'],
                          },
                        }}
                      />
                    </QueryFormik.FilterGridUnit>
                    <QueryFormik.FilterGridUnit size={[1, 1, 1 / 2]} title={'시작일'}>
                      <Grid sx={{ alignItems: 'center' }} wrap={false}>
                        <Grid.Unit size={'max'}>
                          <DateRangeField
                            label={'시작일'}
                            labelConfig={{ visuallyHidden: true }}
                            name={['filters.startAt_Gte', 'filters.startAt_Lte']}
                            placeholder={['시작일 선택', '시작일 선택']}
                          />
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <IconButton
                            icon={XIcon}
                            aria-label={'reset period'}
                            variant={'plain'}
                            disabled={!isPeriodSelected}
                            onClick={() => {
                              setFieldValue('filters', {
                                ...filters,
                                startAt_Gte: undefined,
                                startAt_Lte: undefined,
                              });
                            }}
                          />
                        </Grid.Unit>
                      </Grid>
                    </QueryFormik.FilterGridUnit>
                  </QueryFormik.FilterGrid>
                </View>
                <View sx={{ marginTop: 5 }}>
                  <ErrorBoundary key={queryReference?.fetchKey}>
                    <Suspense fallback={<Spinner />}>
                      <QueryFormik.PreloadedQueryRenderer<appPush_appPushsQuery>>
                        {(queryReference) => (
                          <AppPushWindowPaginator
                            fragmentReference={queryReference}
                            onLoadPage={(page) => setParsedUrlQuery({ ...filters, order, page })}
                          >
                            {({ appPushs }, { renderPagination }) => (
                              <>
                                <View>
                                  <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                                    총 {numberWithCommas(appPushs?.totalCount || 0)}건
                                  </Text>
                                </View>
                                <View sx={{ marginTop: 3 }}>
                                  <AppPushConnectionDataTable
                                    appPushConnection={appPushs}
                                    onRowClick={({ id }) => router.push({ pathname: `/appPush/${id}` })}
                                    emptyState={
                                      <View sx={{ paddingY: 3 }}>
                                        <EmptyState
                                          title={'필터에 맞는 결과가 없어요'}
                                          description={'다른 필터로 다시 시도해보세요.'}
                                        />
                                      </View>
                                    }
                                  />
                                </View>
                                <View sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                  {renderPagination?.()}
                                </View>
                              </>
                            )}
                          </AppPushWindowPaginator>
                        )}
                      </QueryFormik.PreloadedQueryRenderer>
                    </Suspense>
                  </ErrorBoundary>
                </View>
              </View>
            );
          }}
        </QueryFormik>
      </ErrorBoundary>
    </View>
  );
};

AppPush.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
AppPush.authenticated = true;
AppPush.routes = [
  {
    id: 'appPush',
    icon: BellIcon,
    pathname: '/appPush',
    name: '푸시 알림',
    permissions: ['app_push_read'],
  },
  ...AppPushId.routes,
];

export default AppPush;
