import styled from 'styled-components';

import ItemList, { ItemListProps } from '../ItemList';
import Stack from '../Stack';
import View from '../View';

type Props<T extends { id: string | number; value?: string | number | boolean }> = {
  selectedIndex?: number;
  onSelect?: (value: T, index: number) => void;
} & ItemListProps<T>;

const TabList = <T extends { id: string | number; value?: string | number | boolean }>({
  selectedIndex,
  onSelect,
  renderItemWrapper = (children, item, i) => (
    <Stack.Item key={item.id}>
      <ItemView
        selected={i === selectedIndex}
        onClick={() => onSelect?.(item, i)}
        sx={{
          position: 'relative',
          width: 'fit-content',
          paddingY: 2,
          cursor: 'pointer',
        }}
      >
        {children}
      </ItemView>
    </Stack.Item>
  ),
  ...props
}: Props<T>) => {
  return (
    <View sx={{ paddingX: 2, overflowX: 'auto', overflowY: 'hidden' }}>
      <Stack gapX={4}>
        <ItemList<T> renderItemWrapper={renderItemWrapper} {...props} />
      </Stack>
    </View>
  );
};

const ItemView = styled(View)<{ selected: boolean }>`
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: ${({ selected, theme }) => (selected ? theme.colors.accent.emphasis : 'transparent')};
    bottom: -1px;
    left: 0;
  }
`;

export default TabList;
export type { Props as TabListProps };
