/**
 * @generated SignedSource<<3003ed8d2453d1f12919307edda2e403>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type BillCreateDialog_billFinalPriceQuery$variables = {
  additionalDiscount: boolean;
  product: string;
};
export type BillCreateDialog_billFinalPriceQuery$data = {
  readonly billFinalPrice: number;
};
export type BillCreateDialog_billFinalPriceQuery = {
  response: BillCreateDialog_billFinalPriceQuery$data;
  variables: BillCreateDialog_billFinalPriceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "additionalDiscount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "product"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "additionalDiscount",
        "variableName": "additionalDiscount"
      },
      {
        "kind": "Variable",
        "name": "product",
        "variableName": "product"
      }
    ],
    "kind": "ScalarField",
    "name": "billFinalPrice",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BillCreateDialog_billFinalPriceQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "BillCreateDialog_billFinalPriceQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a1147884ac167d80373440ab9a1958b5",
    "id": null,
    "metadata": {},
    "name": "BillCreateDialog_billFinalPriceQuery",
    "operationKind": "query",
    "text": "query BillCreateDialog_billFinalPriceQuery(\n  $product: ID!\n  $additionalDiscount: Boolean!\n) {\n  billFinalPrice(product: $product, additionalDiscount: $additionalDiscount)\n}\n"
  }
};
})();

(node as any).hash = "fbee9c3353fb2fa6386c2cfd94069734";

export default node;
