/**
 * @generated SignedSource<<89e0727b0fb7ce5f4a619e45dd2b88a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskFirstLabelingInput = {
  difficulty: number;
  task: string;
  unitDs: ReadonlyArray<string>;
};
export type TaskFirstLabelingDialog_taskFirstLabelingMutation$variables = {
  input: TaskFirstLabelingInput;
};
export type TaskFirstLabelingDialog_taskFirstLabelingMutation$data = {
  readonly taskFirstLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly unitDs: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type TaskFirstLabelingDialog_taskFirstLabelingMutation = {
  response: TaskFirstLabelingDialog_taskFirstLabelingMutation$data;
  variables: TaskFirstLabelingDialog_taskFirstLabelingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TaskLabeling",
    "kind": "LinkedField",
    "name": "taskFirstLabeling",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "difficulty",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "order": "DESC"
            }
          }
        ],
        "concreteType": "UnitD",
        "kind": "LinkedField",
        "name": "unitDs",
        "plural": true,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskFirstLabelingDialog_taskFirstLabelingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskFirstLabelingDialog_taskFirstLabelingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ad9faa6dd3981da2ca1e9caf0573936b",
    "id": null,
    "metadata": {},
    "name": "TaskFirstLabelingDialog_taskFirstLabelingMutation",
    "operationKind": "mutation",
    "text": "mutation TaskFirstLabelingDialog_taskFirstLabelingMutation(\n  $input: TaskFirstLabelingInput!\n) {\n  taskFirstLabeling(input: $input) {\n    id\n    difficulty\n    unitDs(order: {order: DESC}) {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8441a20fa3474eb698100093d17eac3b";

export default node;
