/**
 * @generated SignedSource<<dae6eccd3ff272861b0d00776751eca0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskHistoryDialog_taskHistory$data = {
  readonly created: any;
  readonly createdBy: {
    readonly name: string;
    readonly picture: string | null;
  };
  readonly difference: {
    readonly __typename: string;
    readonly " $fragmentSpreads": FragmentRefs<"TaskFirstLabelingDifferenceCounterLabel_difference" | "TaskFirstLabelingDifferenceItemList_difference" | "TaskHintFeedbackDifferenceCounterLabel_difference" | "TaskHintFeedbackDifferenceItemList_difference" | "TaskImageCreationDifferenceCounterLabel_difference" | "TaskImageCreationDifferenceItemList_difference" | "TaskProblemCreationDifferenceCounterLabel_difference" | "TaskProblemCreationDifferenceItemList_difference" | "TaskProblemTypingDifferenceCounterLabel_difference" | "TaskProblemTypingDifferenceItemList_difference" | "TaskPublishDifferenceCounterLabel_difference" | "TaskPublishDifferenceItemList_difference" | "TaskSecondLabelingDifferenceCounterLabel_difference" | "TaskSecondLabelingDifferenceItemList_difference" | "TaskSolutionVideoDifferenceCounterLabel_difference" | "TaskSolutionVideoDifferenceItemList_difference" | "TaskVideoDifferenceCounterLabel_difference" | "TaskVideoDifferenceItemList_difference">;
  } | null;
  readonly " $fragmentType": "TaskHistoryDialog_taskHistory";
};
export type TaskHistoryDialog_taskHistory$key = {
  readonly " $data"?: TaskHistoryDialog_taskHistory$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskHistoryDialog_taskHistory">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskHistoryDialog_taskHistory",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "createdBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "picture",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "difference",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskProblemTypingDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskProblemTypingDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskProblemCreationDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskProblemCreationDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskImageCreationDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskImageCreationDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskFirstLabelingDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskFirstLabelingDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskSecondLabelingDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskSecondLabelingDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskPublishDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskPublishDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskVideoDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskVideoDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskSolutionVideoDifferenceCounterLabel_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskSolutionVideoDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskHintFeedbackDifferenceItemList_difference"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TaskHintFeedbackDifferenceCounterLabel_difference"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TaskHistory",
  "abstractKey": null
};

(node as any).hash = "68a08f5a766d185b54ef8aafb6d64acb";

export default node;
