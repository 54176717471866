import { graphql, useFragment } from 'react-relay';

import { SchoolExamSourceHistoryDialog_SchoolExamSourceHistory$key } from '../../../relay/__generated__/SchoolExamSourceHistoryDialog_SchoolExamSourceHistory.graphql';
import Avatar from '../../core/Avatar';
import DateText from '../../core/DateText';
import Dialog, { DialogProps } from '../../core/Dialog';
import Grid from '../../core/Grid';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import SchoolExamSourcePublishDifferenceCounterLabel from '../SchoolExamSourcePublishDifferenceCounterLabel';
import SchoolExamSourcePublishDifferenceItemList from '../SchoolExamSourcePublishDifferenceItemList';
import SchoolExamSourceUploadDifferenceCounterLabel from '../SchoolExamSourceUploadDifferenceCounterLabel';
import SchoolExamSourceUploadDifferenceItemList from '../SchoolExamSourceUploadDifferenceItemList';
const SchoolExamSourceHistoryDialog_SchoolExamSourceHistory = graphql`
  fragment SchoolExamSourceHistoryDialog_SchoolExamSourceHistory on SchoolExamSourceHistory {
    id
    createdBy {
      name
      picture
    }
    created
    difference {
      __typename
      ...SchoolExamSourcePublishDifferenceCounterLabel_difference
      ...SchoolExamSourcePublishDifferenceItemList_difference
      ...SchoolExamSourceUploadDifferenceCounterLabel_difference
      ...SchoolExamSourceUploadDifferenceItemList_difference
    }
  }
`;

type Props = {
  schoolExamSourceHistory: SchoolExamSourceHistoryDialog_SchoolExamSourceHistory$key;
} & DialogProps;

const SchoolExamSourceHistoryDialog = ({ schoolExamSourceHistory, ...props }: Props) => {
  const { createdBy, created, difference } = useFragment(
    SchoolExamSourceHistoryDialog_SchoolExamSourceHistory,
    schoolExamSourceHistory,
  );

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Grid gapX={3} sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'min'}>
            <Avatar src={createdBy.picture || ''} alt={createdBy.name} size={40} />
          </Grid.Unit>
          <Grid.Unit size={'max'}>
            <Stack gapX={1}>
              <Stack.Item>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{`${createdBy.name}님의 수정 내역`}</Text>
              </Stack.Item>
              {difference?.__typename === 'SchoolExamSourceUploadDifference' ? (
                <Stack.Item>
                  <SchoolExamSourceUploadDifferenceCounterLabel difference={difference} scheme={'primary'} />
                </Stack.Item>
              ) : difference?.__typename === 'SchoolExamSourcePublishDifference' ? (
                <Stack.Item>
                  <SchoolExamSourcePublishDifferenceCounterLabel difference={difference} scheme={'primary'} />
                </Stack.Item>
              ) : null}
            </Stack>
            <View>
              <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
            </View>
          </Grid.Unit>
        </Grid>
      </Dialog.Header>
      <Dialog.Body>
        {difference?.__typename === 'SchoolExamSourceUploadDifference' ? (
          <SchoolExamSourceUploadDifferenceItemList difference={difference} />
        ) : difference?.__typename === 'SchoolExamSourcePublishDifference' ? (
          <SchoolExamSourcePublishDifferenceItemList difference={difference} />
        ) : null}
      </Dialog.Body>
    </Dialog>
  );
};

export default SchoolExamSourceHistoryDialog;
