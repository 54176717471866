/**
 * @generated SignedSource<<a629f0fc6b151a1aa0cd27de2740c959>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPushStatusEnum = "completed" | "scheduled" | "%future added value";
export type Ordering = "ASC" | "ASC_NULLS_FIRST" | "ASC_NULLS_LAST" | "DESC" | "DESC_NULLS_FIRST" | "DESC_NULLS_LAST" | "%future added value";
export type AppPushFilter = {
  AND?: AppPushFilter | null;
  DISTINCT?: boolean | null;
  NOT?: AppPushFilter | null;
  OR?: AppPushFilter | null;
  createdById_Exact?: string | null;
  createdById_In?: ReadonlyArray<string> | null;
  endAt_Gte?: any | null;
  endAt_Lte?: any | null;
  modifiedById_Exact?: string | null;
  modifiedById_In?: ReadonlyArray<string> | null;
  search?: string | null;
  startAt_Gte?: any | null;
  startAt_Lte?: any | null;
  status_Exact?: AppPushStatusEnum | null;
  status_In?: ReadonlyArray<AppPushStatusEnum> | null;
};
export type AppPushOrder = {
  id?: Ordering | null;
  startAt?: Ordering | null;
};
export type AppPushWindowPaginatorRefreshQuery$variables = {
  filters?: AppPushFilter | null;
  order?: AppPushOrder | null;
  page?: number | null;
  pageSize?: number | null;
};
export type AppPushWindowPaginatorRefreshQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AppPushWindowPaginator_query">;
};
export type AppPushWindowPaginatorRefreshQuery = {
  response: AppPushWindowPaginatorRefreshQuery$data;
  variables: AppPushWindowPaginatorRefreshQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filters"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  },
  {
    "defaultValue": 1,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "pageSize"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "pageSize",
    "variableName": "pageSize"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "page",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppPushWindowPaginatorRefreshQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "AppPushWindowPaginator_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppPushWindowPaginatorRefreshQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AppPushConnection",
        "kind": "LinkedField",
        "name": "appPushs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Pages",
            "kind": "LinkedField",
            "name": "pages",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "first",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "around",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCurrent",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "last",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "next",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Page",
                "kind": "LinkedField",
                "name": "previous",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AppPushEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AppPush",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "destination",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "targetCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b21f335033d9225e792a5becd284cb5c",
    "id": null,
    "metadata": {},
    "name": "AppPushWindowPaginatorRefreshQuery",
    "operationKind": "query",
    "text": "query AppPushWindowPaginatorRefreshQuery(\n  $filters: AppPushFilter\n  $order: AppPushOrder\n  $page: Int = 1\n  $pageSize: Int\n) {\n  ...AppPushWindowPaginator_query_36liK3\n}\n\nfragment AppPushConnectionDataTable_appPushConnection on AppPushConnection {\n  edges {\n    node {\n      id\n      ...AppPushStatusLabel_appPush\n      sequence\n      title\n      destination\n      targetCount\n      startAt\n    }\n  }\n}\n\nfragment AppPushStatusLabel_appPush on AppPush {\n  status\n}\n\nfragment AppPushWindowPaginator_query_36liK3 on Query {\n  appPushs(filters: $filters, order: $order, page: $page, pageSize: $pageSize) {\n    totalCount\n    pages {\n      ...Pagination_pages\n    }\n    edges {\n      node {\n        id\n      }\n    }\n    ...AppPushConnectionDataTable_appPushConnection\n  }\n}\n\nfragment Pagination_pages on Pages {\n  first {\n    page\n  }\n  around {\n    page\n    isCurrent\n  }\n  last {\n    page\n  }\n  next {\n    page\n  }\n  previous {\n    page\n  }\n}\n"
  }
};
})();

(node as any).hash = "86830a02cf81d2f4694b4f0fcdf85470";

export default node;
