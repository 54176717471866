/**
 * @generated SignedSource<<95d2e95e477b302ee2832a673ccba310>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type BookDeleteInput = {
  id: string;
};
export type BookId_bookDeleteMutation$variables = {
  input: BookDeleteInput;
};
export type BookId_bookDeleteMutation$data = {
  readonly bookDelete: {
    readonly id: string;
  };
};
export type BookId_bookDeleteMutation = {
  response: BookId_bookDeleteMutation$data;
  variables: BookId_bookDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookId_bookDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookId_bookDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Book",
        "kind": "LinkedField",
        "name": "bookDelete",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "deleteRecord",
            "key": "",
            "kind": "ScalarHandle",
            "name": "id"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "39b34423a344a4a3288899ea50aee814",
    "id": null,
    "metadata": {},
    "name": "BookId_bookDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation BookId_bookDeleteMutation(\n  $input: BookDeleteInput!\n) {\n  bookDelete(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a24fa1a08bf3f71647fdd0f7168a170";

export default node;
