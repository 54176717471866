import { ChevronLeftIcon, ChevronRightIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React, { Suspense, useRef } from 'react';
import { graphql } from 'react-relay';

import CommentCreateMutationFormik from '../../components/comment/CommentCreateMutationFormik';
import CommentMutationActionItem from '../../components/comment/CommentMutationActionItem';
import CommentPaginator from '../../components/comment/CommentPaginator';
import Button from '../../components/core/Button';
import Card from '../../components/core/Card';
import DataTable, { RowWrapper } from '../../components/core/DataTable';
import DialogButton from '../../components/core/DialogButton';
import DialogHandler from '../../components/core/DialogHandler';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import ItemList from '../../components/core/ItemList';
import Katex from '../../components/core/Katex';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Spinner from '../../components/core/Spinner';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import Timeline from '../../components/core/Timeline';
import View from '../../components/core/View';
import ProblemGeneratorActionsMutationButtonStack from '../../components/problemGenerator/ProblemGeneratorActionsMutationButtonStack';
import ProblemGeneratorDescriptionList from '../../components/problemGenerator/ProblemGeneratorDescriptionList';
import ProblemGeneratorGeneratedProblemMutationActionsDialog from '../../components/problemGenerator/ProblemGeneratorGeneratedProblemMutationActionsDialog';
import ProblemGeneratorHistoryDialog from '../../components/problemGenerator/ProblemGeneratorHistoryDialog';
import ProblemGeneratorHistoryTimelineItem from '../../components/problemGenerator/ProblemGeneratorHistoryTimelineItem';
import ProblemGeneratorProblemGeneratorHistoryPaginator from '../../components/problemGenerator/ProblemGeneratorProblemGeneratorHistoryPaginator';
import TaskDescriptionList from '../../components/task/TaskDescriptionList';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import {
  Ordering,
  ProblemGeneratorId_commentsQuery,
} from '../../relay/__generated__/ProblemGeneratorId_commentsQuery.graphql';
import {
  ProblemGeneratorFilter,
  ProblemGeneratorId_problemGeneratorQuery,
} from '../../relay/__generated__/ProblemGeneratorId_problemGeneratorQuery.graphql';
import { isNullable } from '../../utils/is';
import { numberWithCommas } from '../../utils/number';
import { parseOrdering } from '../../utils/order';
import { scrollToBottom } from '../../utils/scroll';
import { NextPage } from '../_app';

const problemGeneratorForProblemGeneratorId = graphql`
  query ProblemGeneratorId_problemGeneratorQuery(
    $id: ID!
    $filters: ProblemGeneratorFilter
    $order: ProblemGeneratorOrder
    $generatedProblemFilters: GeneratedProblemFilter
    $generatedProblemOrder: GeneratedProblemOrder
  ) {
    problemGenerator(id: $id) {
      id
      previous(filters: $filters, order: $order)
      next(filters: $filters, order: $order)

      originalTask {
        id
        sequence
        problem {
          id
          problem
          solution
        }
        ...TaskDescriptionList_task
      }
      generatedProblems(filters: $generatedProblemFilters, order: $generatedProblemOrder) {
        totalCount
        edges {
          node {
            id
            task {
              id
              sequence
            }
          }
        }
      }
      ...ProblemGeneratorActionsMutationButtonStack_problemGenerator
        @arguments(generatedProblemFilters: $generatedProblemFilters, generatedProblemOrder: $generatedProblemOrder)
      ...ProblemGeneratorDescriptionList_problemGenerator
      ...ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator
      ...ProblemGeneratorGeneratedProblemMutationActionsDialog_problemGenerator
    }
  }
`;

const commentsForProblemGeneratorId = graphql`
  query ProblemGeneratorId_commentsQuery($filters: CommentFilter, $order: CommentOrder) {
    ...CommentPaginator_query @arguments(filters: $filters, order: $order)
  }
`;

const ProblemGeneratorId: NextPage = () => {
  const router = useRouter();
  const { problemGeneratorId } = router.query;

  const [comments] = useLazyLoadQuery<ProblemGeneratorId_commentsQuery>(commentsForProblemGeneratorId, {
    filters: { nodeId_Exact: problemGeneratorId as string },
    order: { created: 'ASC' as Ordering },
  });

  const commentContainerRef = useRef<HTMLDivElement>(null);

  const { initialValues } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },

    status_In: { type: 'string', multiple: true },
    publishedProblemsCount_Gte: { type: 'number' },
    publishedProblemsCount_Lte: { type: 'number' },

    activeProblemsCount_Gte: { type: 'number' },
    activeProblemsCount_Lte: { type: 'number' },

    unitAId_In: { type: 'string', multiple: true },
    unitBId_In: { type: 'string', multiple: true },

    unitCId_In: { type: 'string', multiple: true },
    unitDId_In: { type: 'string', multiple: true },

    assignedToId_In: { type: 'string', multiple: true },
    assignedTo_Exists_In: { type: 'boolean', multiple: true },

    order: { type: 'string' },
  });

  const { order, ...filters } = initialValues;

  const [data, refresh] = useLazyLoadQuery<ProblemGeneratorId_problemGeneratorQuery>(
    problemGeneratorForProblemGeneratorId,
    {
      id: problemGeneratorId as string,
      filters: filters as ProblemGeneratorFilter,
      order: order ? parseOrdering(order) : undefined,
      generatedProblemFilters: { status_Exact: 'active' },
      generatedProblemOrder: { created: 'ASC' },
    },
  );
  const { problemGenerator } = data;
  if (!problemGenerator) return null;
  const {
    previous: previousProblemGeneratorId,
    next: nextProblemGeneratorId,
    originalTask,
    generatedProblems,
  } = problemGenerator;

  const hasPrevious = !isNullable(previousProblemGeneratorId);
  const handlePreviousButtonClick = () => {
    const previousProblemGeneratorPathname = `/problemGenerator/${previousProblemGeneratorId}`;
    router.replace(
      { pathname: previousProblemGeneratorPathname, query: initialValues },
      previousProblemGeneratorPathname,
    );
  };

  const hasNext = !isNullable(nextProblemGeneratorId);
  const handleNextButtonClick = () => {
    const nextProblemGeneratorPathname = `/problemGenerator/${nextProblemGeneratorId}`;
    router.replace({ pathname: nextProblemGeneratorPathname, query: initialValues }, nextProblemGeneratorPathname);
  };

  return (
    <View>
      <Head siteTitle={`자동 문제 생성 - ${originalTask.sequence}`} />
      <View>
        <Grid sx={{ alignItems: 'center' }} gapX={2} gapY={2}>
          <Grid.Unit size={[1, 1, 'max']}>
            <Stack gapX={3}>
              <Stack.Item>
                <IconButton
                  icon={ChevronLeftIcon}
                  aria-label={'Previous Problem Generator Id'}
                  disabled={!hasPrevious}
                  onClick={() => handlePreviousButtonClick()}
                />
              </Stack.Item>
              <Stack.Item>
                <Text as={'h1'} fontSize={3}>
                  {originalTask.sequence}
                </Text>
              </Stack.Item>
              <Stack.Item>
                <IconButton
                  icon={ChevronRightIcon}
                  aria-label={'Next Problem Generator Id'}
                  disabled={!hasNext}
                  onClick={() => handleNextButtonClick()}
                />
              </Stack.Item>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <ProblemGeneratorActionsMutationButtonStack problemGenerator={problemGenerator} />
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        <Grid gapX={5} gapY={3}>
          <Grid.Unit size={[1, 1, 3 / 4]}>
            <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>원본 문제 정보</Text>
            <View sx={{ marginTop: 3 }}>
              <Grid gapY={3} gapX={2}>
                <Grid.Unit size={[1, 1, 1 / 2]}>
                  <View sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Text fontSize={0} color={'fg.subtle'}>
                      문제
                    </Text>
                    <Card
                      sx={{
                        marginTop: 1,
                        overflow: 'auto',
                        maxHeight: 350,
                        flexGrow: 1,
                      }}
                    >
                      {originalTask.problem?.problem ? (
                        <Katex>{originalTask.problem.problem}</Katex>
                      ) : (
                        <View
                          sx={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 3,
                          }}
                        >
                          <EmptyState title={'원본 문제가 없어요'} />
                        </View>
                      )}
                    </Card>
                  </View>
                </Grid.Unit>
                <Grid.Unit size={[1, 1, 1 / 2]}>
                  <View sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Text fontSize={0} color={'fg.subtle'}>
                      해설
                    </Text>
                    <Card
                      sx={{
                        marginTop: 1,
                        flexGrow: 1,
                        maxHeight: 350,
                        overflow: 'auto',
                      }}
                    >
                      {originalTask.problem?.solution ? (
                        <Katex>{originalTask.problem.solution}</Katex>
                      ) : (
                        <View
                          sx={{
                            display: 'flex',
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: 3,
                          }}
                        >
                          <EmptyState title={'원본 해설이 없어요'} />
                        </View>
                      )}
                    </Card>
                  </View>
                </Grid.Unit>
              </Grid>
            </View>
            <Card
              sx={{
                marginTop: 2,
                padding: 4,
              }}
            >
              <TaskDescriptionList
                task={originalTask}
                type={'generatorOriginalTask'}
                titleUnitSize={1 / 4}
                descriptionUnitSize={3 / 4}
              />
            </Card>
            <View sx={{ marginTop: 5 }}>
              <Grid>
                <Grid.Unit size={'max'}>
                  <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>자동 생성 문제</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <DialogButton
                    size={'large'}
                    variant={'outline'}
                    renderDialog={({ isOpen, closeDialog }) => (
                      <ProblemGeneratorGeneratedProblemMutationActionsDialog
                        isOpen={isOpen}
                        onDismiss={() => {
                          closeDialog();
                          refresh();
                        }}
                        full
                        problemGenerator={problemGenerator}
                      />
                    )}
                  >
                    생성하기
                  </DialogButton>
                </Grid.Unit>
              </Grid>
            </View>
            <View sx={{ marginTop: 2 }}>
              <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                총 {numberWithCommas(generatedProblems?.totalCount || 0)}개
              </Text>
            </View>
            <View sx={{ marginTop: 3, overflowX: 'auto' }}>
              <DataTable
                rows={generatedProblems.edges.map(({ node }) => node)}
                columns={[
                  { field: 'index', title: '', renderValue: (_, index) => index + 1, width: 48 },
                  {
                    field: 'id',
                    title: 'ID',
                    renderValue: ({ task }) =>
                      task?.sequence ? (
                        <Text fontSize={1}>{task.sequence}</Text>
                      ) : (
                        <Text fontSize={1} color={'fg.subtle'}>
                          출시 후 생성
                        </Text>
                      ),
                    width: 680,
                  },
                ]}
                emptyState={
                  <View sx={{ padding: 5 }}>
                    <EmptyState title={'자동 생성된 문제가 없어요'} />
                  </View>
                }
                renderRowWrapper={(children, { id, task }) => (
                  <RowWrapper
                    key={id}
                    onClick={task ? () => router.push(`/task/${task.id}`) : undefined}
                    sx={{
                      ...(task
                        ? {
                            'cursor': 'pointer',
                            ':hover': { backgroundColor: 'canvas.inset', transition: 'background-color 250ms' },
                          }
                        : {}),
                    }}
                  >
                    {children}
                  </RowWrapper>
                )}
              />
            </View>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 4]}>
            <ProblemGeneratorDescriptionList
              problemGenerator={problemGenerator}
              type={'activity'}
              titleUnitSize={'max'}
              descriptionUnitSize={'min'}
              renderTitle={(title) => <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>}
              renderItemWrapper={(children, _, i) => (
                <View key={i} sx={{ marginTop: i > 0 ? 2 : 0 }}>
                  {children}
                </View>
              )}
            />
            <View sx={{ marginTop: 5 }}>
              <ErrorBoundary>
                <CommentPaginator fragmentReference={comments}>
                  {({ comments }, { loadMore, hasNext, isLoadingNext, refetch }) => {
                    return (
                      <>
                        <View>
                          <Stack gapX={1}>
                            <Stack.Item>
                              <Text fontWeight={'bold'} fontSize={1}>
                                댓글
                              </Text>
                            </Stack.Item>
                            <Stack.Item>
                              <Text
                                sx={{ fontSize: 1, fontWeight: 'bold' }}
                                color={comments.totalCount === 0 ? 'neutral.emphasis' : 'accent.emphasis'}
                              >
                                {numberWithCommas(comments.totalCount || 0)}
                              </Text>
                            </Stack.Item>
                          </Stack>
                          <Card ref={commentContainerRef} sx={{ maxHeight: 240, overflowY: 'auto', marginTop: 2 }}>
                            <View sx={{ padding: 3 }}>
                              <ItemList
                                items={comments.edges.map(({ node }) => node).filter((node) => !!node)}
                                renderItem={(node) => <CommentMutationActionItem comment={node} />}
                                renderItemWrapper={(children, { id }, index) => (
                                  <View key={id} sx={{ marginTop: index === 0 ? 0 : 4 }}>
                                    {children}
                                  </View>
                                )}
                                emptyState={<EmptyState title={'댓글이 없어요'} />}
                              />
                              {hasNext ? (
                                <Button
                                  onClick={() => loadMore(3)}
                                  disabled={isLoadingNext}
                                  sx={{ width: '100%', marginTop: 1 }}
                                >
                                  더보기
                                </Button>
                              ) : null}
                            </View>
                          </Card>
                        </View>
                        <View sx={{ marginTop: 2 }}>
                          <CommentCreateMutationFormik
                            nodeId={problemGeneratorId as string}
                            config={{
                              onCompleted: () => {
                                refetch(
                                  {},
                                  {
                                    fetchPolicy: 'store-and-network',
                                    onComplete: () => {
                                      setTimeout(() => {
                                        if (commentContainerRef.current)
                                          scrollToBottom(commentContainerRef.current, {});
                                      }, 0);
                                    },
                                  },
                                );
                              },
                            }}
                          />
                        </View>
                      </>
                    );
                  }}
                </CommentPaginator>
              </ErrorBoundary>
            </View>
            <View sx={{ marginTop: 5 }}>
              <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>히스토리</Text>
              <ErrorBoundary>
                <ProblemGeneratorProblemGeneratorHistoryPaginator fragmentReference={problemGenerator}>
                  {({ histories }, { isLoadingNext, hasNext, loadMore }) => (
                    <Timeline>
                      <ItemList
                        items={histories.edges}
                        renderItem={({ node }) => (
                          <ProblemGeneratorHistoryTimelineItem key={node.id} problemGeneratorHistory={node} />
                        )}
                        renderItemWrapper={(children, { node }) => {
                          const { id, type } = node;
                          return (
                            <React.Fragment key={id}>
                              {type === 'publish' ? (
                                <DialogHandler
                                  renderDialog={({ isOpen, closeDialog }) => (
                                    <ProblemGeneratorHistoryDialog
                                      problemGeneratorHistory={node}
                                      isOpen={isOpen}
                                      onDismiss={() => closeDialog()}
                                      wide
                                    />
                                  )}
                                >
                                  <View
                                    sx={{
                                      'borderRadius': 2,
                                      'cursor': 'pointer',
                                      'transition': 'background-color 250ms',
                                      ':hover': { backgroundColor: 'canvas.subtle' },
                                    }}
                                  >
                                    <View sx={{ paddingX: 2 }}>{children}</View>
                                  </View>
                                </DialogHandler>
                              ) : (
                                <View sx={{ paddingX: 2 }}>{children}</View>
                              )}
                            </React.Fragment>
                          );
                        }}
                        emptyState={
                          <Card sx={{ padding: 3, marginBottom: 2 }}>
                            <EmptyState title={'기록된 히스토리가 없어요'} />
                          </Card>
                        }
                      />
                      {hasNext ? (
                        <Button sx={{ width: '100%' }} disabled={isLoadingNext} onClick={() => loadMore(10)}>
                          더보기
                        </Button>
                      ) : (
                        <Timeline.Break />
                      )}
                    </Timeline>
                  )}
                </ProblemGeneratorProblemGeneratorHistoryPaginator>
              </ErrorBoundary>
            </View>
          </Grid.Unit>
        </Grid>
      </View>
    </View>
  );
};

ProblemGeneratorId.getLayout = (page) => (
  <HeaderSidebarNavPageLayout>
    <Suspense fallback={<Spinner />}>{page}</Suspense>
  </HeaderSidebarNavPageLayout>
);
ProblemGeneratorId.authenticated = true;
ProblemGeneratorId.routes = [
  {
    id: 'problemGeneratorId',
    pathname: '/problemGenerator/[problemGeneratorId]',
    name: '문제 자동 생성 상세',
    permissions: ['problem_generator_read'],
  },
];

export default ProblemGeneratorId;
