import { graphql, useFragment } from 'react-relay';

import { ProblemGeneratorHistoryDialog_problemGeneratorHistory$key } from '../../../relay/__generated__/ProblemGeneratorHistoryDialog_problemGeneratorHistory.graphql';
import Avatar from '../../core/Avatar';
import DateText from '../../core/DateText';
import Dialog, { DialogProps } from '../../core/Dialog';
import Grid from '../../core/Grid';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import ProblemGeneratorDifferenceCounterLabel from '../ProblemGeneratorDifferenceCounterLabel';
import ProblemGeneratorDifferenceItemList from '../ProblemGeneratorDifferenceItemList';

const ProblemGeneratorHistoryDialog_problemGeneratorHistory = graphql`
  fragment ProblemGeneratorHistoryDialog_problemGeneratorHistory on ProblemGeneratorHistory {
    createdBy {
      name
      picture
    }
    created
    difference {
      __typename
      ...ProblemGeneratorDifferenceCounterLabel_difference
      ...ProblemGeneratorDifferenceItemList_difference
    }
  }
`;

type Props = {
  problemGeneratorHistory: ProblemGeneratorHistoryDialog_problemGeneratorHistory$key;
} & DialogProps;

const ProblemGeneratorHistoryDialog = ({ problemGeneratorHistory, ...props }: Props) => {
  const { createdBy, created, difference } = useFragment(
    ProblemGeneratorHistoryDialog_problemGeneratorHistory,
    problemGeneratorHistory,
  );

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Grid gapX={3} sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'min'}>
            <Avatar src={createdBy.picture || ''} alt={createdBy.name} size={40} />
          </Grid.Unit>
          <Grid.Unit size={'max'}>
            <Stack gapX={1}>
              <Stack.Item>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{`${createdBy.name}님의 수정 내역`}</Text>
              </Stack.Item>
              <Stack.Item>
                {difference ? (
                  <ProblemGeneratorDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : null}
              </Stack.Item>
            </Stack>
            <View>
              <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
            </View>
          </Grid.Unit>
        </Grid>
      </Dialog.Header>
      <Dialog.Body>{difference ? <ProblemGeneratorDifferenceItemList difference={difference} /> : null}</Dialog.Body>
    </Dialog>
  );
};

export default ProblemGeneratorHistoryDialog;
