/**
 * @generated SignedSource<<77f92b79f56971e8945ea0af0531c5f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CodepushVersionActionEnum = "codepush_version_update_production" | "codepush_version_update_staging" | "%future added value";
export type CodepushVersionProductionUpdateInput = {
  id: string;
};
export type CodepushVersionId_codepushVersionProductionUpdateMutation$variables = {
  input: CodepushVersionProductionUpdateInput;
};
export type CodepushVersionId_codepushVersionProductionUpdateMutation$data = {
  readonly codepushVersionProductionUpdate: {
    readonly actions: ReadonlyArray<CodepushVersionActionEnum>;
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"CodepushVersionStatusLabel_codepushVersion">;
  };
};
export type CodepushVersionId_codepushVersionProductionUpdateMutation = {
  response: CodepushVersionId_codepushVersionProductionUpdateMutation$data;
  variables: CodepushVersionId_codepushVersionProductionUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CodepushVersionId_codepushVersionProductionUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CodepushVersion",
        "kind": "LinkedField",
        "name": "codepushVersionProductionUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CodepushVersionStatusLabel_codepushVersion"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CodepushVersionId_codepushVersionProductionUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CodepushVersion",
        "kind": "LinkedField",
        "name": "codepushVersionProductionUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0894e66dac41a4cd2480bb39e42351af",
    "id": null,
    "metadata": {},
    "name": "CodepushVersionId_codepushVersionProductionUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation CodepushVersionId_codepushVersionProductionUpdateMutation(\n  $input: CodepushVersionProductionUpdateInput!\n) {\n  codepushVersionProductionUpdate(input: $input) {\n    id\n    actions\n    ...CodepushVersionStatusLabel_codepushVersion\n  }\n}\n\nfragment CodepushVersionStatusLabel_codepushVersion on CodepushVersion {\n  status\n}\n"
  }
};
})();

(node as any).hash = "234dec4353f1d13fe50b291491750669";

export default node;
