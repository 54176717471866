/**
 * @generated SignedSource<<414f8fa81f4d604568952cfeeb535491>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductDiscountUpdateDialog_productDiscount$data = {
  readonly backgroundColor: string | null;
  readonly id: string;
  readonly mobileImage: {
    readonly key: string;
    readonly objectUrl: string;
    readonly size: number;
  } | null;
  readonly product: {
    readonly id: string;
    readonly title: string;
  };
  readonly webImage: {
    readonly key: string;
    readonly objectUrl: string;
    readonly size: number;
  } | null;
  readonly " $fragmentType": "ProductDiscountUpdateDialog_productDiscount";
};
export type ProductDiscountUpdateDialog_productDiscount$key = {
  readonly " $data"?: ProductDiscountUpdateDialog_productDiscount$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDiscountUpdateDialog_productDiscount">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDiscountUpdateDialog_productDiscount",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "webImage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "mobileImage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "backgroundColor",
      "storageKey": null
    }
  ],
  "type": "ProductDiscount",
  "abstractKey": null
};
})();

(node as any).hash = "2b6ff8069268350fab4832b79fc61e3b";

export default node;
