import { graphql } from 'react-relay';

import { NotificationPaginator_query$key } from '../../../relay/__generated__/NotificationPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const NotificationPaginator_query = graphql`
  fragment NotificationPaginator_query on Query
  @argumentDefinitions(
    filters: { type: NotificationFilter }
    order: { type: NotificationOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "NotificationPaginatorQuery") {
    notifications(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "NotificationsPaginator_query_notifications") {
      __id
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          nodeId
          typename
          checked
          ...NotificationItem_notification
        }
      }
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<NotificationPaginator_query$key>, 'fragment'>;

const NotificationPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={NotificationPaginator_query} {...props} />
);

export default NotificationPaginator;
export type { Props as NotificationPaginatorProps };
