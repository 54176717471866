/**
 * @generated SignedSource<<ec75c381d6801342b20bf1f5af2db1a4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeliveryOrderCancelDialog_deliveryOrder$data = {
  readonly id: string;
  readonly " $fragmentType": "DeliveryOrderCancelDialog_deliveryOrder";
};
export type DeliveryOrderCancelDialog_deliveryOrder$key = {
  readonly " $data"?: DeliveryOrderCancelDialog_deliveryOrder$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryOrderCancelDialog_deliveryOrder">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryOrderCancelDialog_deliveryOrder",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DeliveryOrder",
  "abstractKey": null
};

(node as any).hash = "6cf7525677f82d94ce15955f34ff9600";

export default node;
