import { graphql, useFragment } from 'react-relay';

import {
  WorkbookStatusEnum,
  WorkbookStatusLabel_workbook$key,
} from '../../../relay/__generated__/WorkbookStatusLabel_workbook.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const WorkbookStatusLabel_workbook = graphql`
  fragment WorkbookStatusLabel_workbook on Workbook {
    status
  }
`;

type Props = {
  workbook: WorkbookStatusLabel_workbook$key;
} & Omit<LabelProps, 'variant'>;

const WorkbookStatusLabel = ({ workbook, ...props }: Props) => {
  const { status } = useFragment(WorkbookStatusLabel_workbook, workbook);

  const labelPropsByStatus: Record<WorkbookStatusEnum, LabelProps> = {
    'created': { variant: 'secondary' },
    'published': { variant: 'success' },
    'publish_required': { variant: 'danger' },
    'deactivated': { variant: 'secondary' },
    '%future added value': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'WorkbookStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default WorkbookStatusLabel;
