/**
 * @generated SignedSource<<1196738c634e1e1f93696cee6cb01b8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type SchoolRegionEnum = "busan" | "chungbuk" | "chungnam" | "daegu" | "daejeon" | "gangwon" | "gwangju" | "gyeongbuk" | "gyeonggi" | "gyeongnam" | "incheon" | "jeju" | "jeongbuk" | "jeonnam" | "sejong" | "seoul" | "ulsan" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SchoolExamSourcePaginator_query$data = {
  readonly schoolExamSources: {
    readonly edges: ReadonlyArray<{
      readonly cursor: string;
      readonly node: {
        readonly id: string;
        readonly school: {
          readonly id: string;
          readonly region: SchoolRegionEnum;
          readonly title: string;
        };
        readonly scraps: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly scrap: {
                readonly id: string;
                readonly objectUrl: string;
              };
            };
          }>;
        };
        readonly sequence: number;
        readonly title: string;
        readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourceExportedPreview_schoolExamSource">;
      };
    }>;
    readonly pageInfo: {
      readonly endCursor: string | null;
      readonly hasNextPage: boolean;
    };
    readonly totalCount: number | null;
  };
  readonly " $fragmentType": "SchoolExamSourcePaginator_query";
};
export type SchoolExamSourcePaginator_query$key = {
  readonly " $data"?: SchoolExamSourcePaginator_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"SchoolExamSourcePaginator_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "schoolExamSources"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filters"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "order"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scrapCount"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "scrapOrder"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./SchoolExamSourcePaginatorRefreshQuery.graphql')
    }
  },
  "name": "SchoolExamSourcePaginator_query",
  "selections": [
    {
      "alias": "schoolExamSources",
      "args": [
        {
          "kind": "Variable",
          "name": "filters",
          "variableName": "filters"
        },
        {
          "kind": "Variable",
          "name": "order",
          "variableName": "order"
        }
      ],
      "concreteType": "SchoolExamSourceConnection",
      "kind": "LinkedField",
      "name": "__SchoolExamSourcePaginator_query_schoolExamSources_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SchoolExamSourceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SchoolExamSource",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "sequence",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "School",
                  "kind": "LinkedField",
                  "name": "school",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "region",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "first",
                      "variableName": "scrapCount"
                    },
                    {
                      "kind": "Variable",
                      "name": "order",
                      "variableName": "scrapOrder"
                    }
                  ],
                  "concreteType": "SchoolExamScrapConnection",
                  "kind": "LinkedField",
                  "name": "scraps",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SchoolExamScrapEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "SchoolExamScrap",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "File",
                              "kind": "LinkedField",
                              "name": "scrap",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "objectUrl",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "scrapCount",
                      "variableName": "scrapCount"
                    },
                    {
                      "kind": "Variable",
                      "name": "scrapOrder",
                      "variableName": "scrapOrder"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "SchoolExamSourceExportedPreview_schoolExamSource"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "9625dfa4dd84696a8e0f371718fb5eab";

export default node;
