import { CheckIcon } from '@primer/octicons-react';
import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { MATOrganizationStudentsCreateDialog_matOrganization$key } from '../../../relay/__generated__/MATOrganizationStudentsCreateDialog_matOrganization.graphql';
import { MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation } from '../../../relay/__generated__/MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairSelectField from '../../core/EnumPairSelectField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import RadioGroupField from '../../core/RadioGroupField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';

const MATOrganizationStudentsCreateDialog_matOrganization = graphql`
  fragment MATOrganizationStudentsCreateDialog_matOrganization on MATOrganization {
    id
  }
`;

type Props = { matOrganization: MATOrganizationStudentsCreateDialog_matOrganization$key } & DialogProps &
  Pick<
    MutationFormikProps<MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const MATOrganizationStudentsCreateDialog = ({ matOrganization, config, onSubmit, connections, ...props }: Props) => {
  const { id } = useFragment(MATOrganizationStudentsCreateDialog_matOrganization, matOrganization);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>학생 계정 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation>
        mutation={graphql`
          mutation MATOrganizationStudentsCreateDialog_organizationStudentsCreateMutation(
            $input: MATOrganizationStudentsCreateInput!
          ) {
            organizationStudentsCreate(input: $input)
          }
        `}
        initialValues={{ organization: id, isSample: true }}
        config={config}
        onSubmit={onSubmit}
        connections={connections}
      >
        {({ handleSubmit, values, setValues }, { nonFieldErrorRef, scrollContainerRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <NumberField
                  label={'계정 개수'}
                  name={'count'}
                  required
                  trailingVisual={'개'}
                  placeholder={'개수 입력'}
                />
                <Suspense fallback={<Spinner />}>
                  <EnumPairSelectField
                    typename={'MATSchoolYearMajorTypeEnum'}
                    label={'학년'}
                    name={'studentType'}
                    required
                    placeholder={'학년 선택'}
                  />
                </Suspense>
                <RadioGroupField
                  label={'샘플 계정 여부'}
                  name={'isSample'}
                  required
                  options={[
                    { id: 'true', text: '샘플 O' },
                    { id: 'false', text: '샘플 X' },
                  ]}
                  renderContainer={(children) => <Grid gapX={4}>{children}</Grid>}
                  renderOptionWrapper={(children, { id }) => (
                    <Grid.Unit key={id} size={'min'}>
                      {children}
                    </Grid.Unit>
                  )}
                  value={values.isSample === true ? 'true' : 'false'}
                  onChange={(selected) => {
                    const value = selected === 'true' ? true : false;
                    setValues({ ...values, isSample: value });
                  }}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default MATOrganizationStudentsCreateDialog;
