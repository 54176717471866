/**
 * @generated SignedSource<<8099b8acf7d4efe5e496a04e8e8c3d5f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskHintStatusEnum = "published" | "saved" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskHintStatusLabel_taskHint$data = {
  readonly status: TaskHintStatusEnum;
  readonly " $fragmentType": "TaskHintStatusLabel_taskHint";
};
export type TaskHintStatusLabel_taskHint$key = {
  readonly " $data"?: TaskHintStatusLabel_taskHint$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskHintStatusLabel_taskHint">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskHintStatusLabel_taskHint",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "TaskHint",
  "abstractKey": null
};

(node as any).hash = "8191098df281c948a6dfe910d6ffd24a";

export default node;
