import { graphql } from 'react-relay';

import { UserPaginator_query$key } from '../../../relay/__generated__/UserPaginator_query.graphql';
import FragmentPaginator, { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const UserPaginator_query = graphql`
  fragment UserPaginator_query on Query
  @argumentDefinitions(
    filters: { type: UserFilter }
    order: { type: UserOrder }
    first: { type: Int }
    after: { type: String }
  )
  @refetchable(queryName: "UserPaginatorRefreshQuery") {
    users(filters: $filters, order: $order, first: $first, after: $after)
      @connection(key: "UserPaginator_query_users") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          group {
            id
            title
          }
          ...UserAvatarText_user
          ...UserPermissionsUpdateDialog_user
        }
      }
      ...UserConnectionDataTable_UserConnection
    }
  }
`;

type Props = {} & Omit<FragmentPaginatorProps<UserPaginator_query$key>, 'fragment'>;

const UserPaginator = ({ ...props }: Props) => <FragmentPaginator fragment={UserPaginator_query} {...props} />;

export default UserPaginator;
