/**
 * @generated SignedSource<<19ebb5d618503edbc0161dab29b08edd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RaffleConnectionDataTable_raffleConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly endAt: any;
      readonly id: string;
      readonly order: number;
      readonly raffleStuff: {
        readonly id: string;
        readonly title: string;
        readonly " $fragmentSpreads": FragmentRefs<"RaffleStuffPreviewItem_raffleStuff">;
      };
      readonly showEndAt: any;
      readonly startAt: any;
      readonly title: string;
      readonly winnerCount: number;
      readonly " $fragmentSpreads": FragmentRefs<"RaffleStatusLabel_raffle">;
    };
  }>;
  readonly " $fragmentType": "RaffleConnectionDataTable_raffleConnection";
};
export type RaffleConnectionDataTable_raffleConnection$key = {
  readonly " $data"?: RaffleConnectionDataTable_raffleConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"RaffleConnectionDataTable_raffleConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RaffleConnectionDataTable_raffleConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "RaffleEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Raffle",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RaffleStatusLabel_raffle"
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "RaffleStuff",
              "kind": "LinkedField",
              "name": "raffleStuff",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "RaffleStuffPreviewItem_raffleStuff"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "winnerCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showEndAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "order",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RaffleConnection",
  "abstractKey": null
};
})();

(node as any).hash = "9c2ebda96379ee4230d599332c70969e";

export default node;
