import { BellIcon, LinkIcon } from '@primer/octicons-react';
import { useTheme } from '@primer/react';
import { Space } from '@teamturing/react-kit';
import { graphql, useFragment } from 'react-relay';

import { NotificationItem_notification$key } from '../../../relay/__generated__/NotificationItem_notification.graphql';
import DateText from '../../core/DateText';
import Grid from '../../core/Grid';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';
import View from '../../core/View';

const NotificationItem_notification = graphql`
  fragment NotificationItem_notification on Notification {
    id
    title
    notificationType
    description
    checked
    created
  }
`;

type Props = { notification: NotificationItem_notification$key };

const NotificationItem = ({ notification }: Props) => {
  const { theme } = useTheme();
  const { title, notificationType, description, checked, created } = useFragment(
    NotificationItem_notification,
    notification,
  );

  const titleLeadingIcon = notificationType === 'bill_completed' ? LinkIcon : BellIcon;

  return (
    <View backgroundColor={checked ? undefined : `${theme?.colors.accent.subtle}66`}>
      <Space p={'12px'}>
        <Stack gapX={2} wrap={false}>
          <Grid.Unit size={'min'}>
            <View
              borderRadius={3}
              backgroundColor={'canvas.subtle'}
              width={32}
              height={32}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              {titleLeadingIcon ? <StyledOcticon icon={titleLeadingIcon} size={16} color={'fg.subtle'} /> : null}
            </View>
          </Grid.Unit>
          <Grid.Unit size={'max'} sx={{ overflowX: 'hidden' }}>
            <View lineHeight={1}>
              <Text
                fontSize={0}
                color={'fg.subtle'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: 'calc(100%)',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                }}
              >
                <DateText ago tooltipProps={{ direction: 'w' }}>
                  {created}
                </DateText>{' '}
                · {title}
              </Text>
            </View>
            <Text fontSize={1} fontWeight={'bold'} color={'fg.default'}>
              {description}
            </Text>
          </Grid.Unit>
        </Stack>
      </Space>
    </View>
  );
};

export default NotificationItem;
export type { Props as NotificationItemProps };
