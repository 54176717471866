/**
 * @generated SignedSource<<0442cd8ddb3fd8c7300fd44362e0fb16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskProblemTypingDifferenceItemList_difference$data = {
  readonly problem?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly solution?: {
    readonly " $fragmentSpreads": FragmentRefs<"DiffItem_diff">;
  } | null;
  readonly " $fragmentType": "TaskProblemTypingDifferenceItemList_difference";
};
export type TaskProblemTypingDifferenceItemList_difference$key = {
  readonly " $data"?: TaskProblemTypingDifferenceItemList_difference$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskProblemTypingDifferenceItemList_difference">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "DiffItem_diff"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskProblemTypingDifferenceItemList_difference",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "problem",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Diff",
          "kind": "LinkedField",
          "name": "solution",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "type": "TaskProblemTypingDifference",
      "abstractKey": null
    }
  ],
  "type": "Difference",
  "abstractKey": "__isDifference"
};
})();

(node as any).hash = "f18457b9485e02fa54170bc6ab52d64a";

export default node;
