/**
 * @generated SignedSource<<9c02433008c5855f5a7caa3c8e5baea8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AppDialogExposeHourEnum = "day" | "hour" | "month" | "%future added value";
export type AppDialogExposeTypeEnum = "always" | "hour_repeat" | "manual_tag" | "once" | "weekday_repeat" | "%future added value";
export type AppDialogExposeWeekdayEnum = "fri" | "mon" | "sat" | "sun" | "thu" | "tue" | "wed" | "%future added value";
export type AppDialogTypeEnum = "image" | "image_and_text" | "text" | "%future added value";
export type AppDialogUserOsEnum = "android" | "ios" | "%future added value";
export type AppDialogUserTypeEnum = "general" | "non_refundable" | "refundable" | "%future added value";
export type AppDialogUserYearEnum = "elementary_school_3" | "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3" | "high_school_N" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
export type AppDialogCreateInput = {
  appDeepLink?: string | null;
  appDialogBody?: string | null;
  appDialogButton?: string | null;
  appDialogImage?: Upload | null;
  appDialogMobileImage?: Upload | null;
  appDialogTabletImage?: Upload | null;
  appDialogTitle?: string | null;
  appDialogType: AppDialogTypeEnum;
  description?: string | null;
  endAt: any;
  exposeHour?: AppDialogExposeHourEnum | null;
  exposeTag?: string | null;
  exposeType: AppDialogExposeTypeEnum;
  exposeWeekdays?: ReadonlyArray<AppDialogExposeWeekdayEnum> | null;
  order: number;
  startAt: any;
  title: string;
  userAppVersion: StringRangeInput;
  userOs: ReadonlyArray<AppDialogUserOsEnum>;
  userPeriod: IntRangeInput;
  userTypes: ReadonlyArray<AppDialogUserTypeEnum>;
  userYears: ReadonlyArray<AppDialogUserYearEnum>;
};
export type Upload = {
  fieldName?: string | null;
  key: string;
  multipartUploadResult?: MultiPartUploadResult | null;
  objectUrl?: string | null;
  size: number;
};
export type MultiPartUploadResult = {
  partPairs: ReadonlyArray<PartPair>;
  uploadId: string;
};
export type PartPair = {
  ETag: string;
  PartNumber: number;
};
export type StringRangeInput = {
  max?: string | null;
  min?: string | null;
};
export type IntRangeInput = {
  max?: number | null;
  min?: number | null;
};
export type AppDialogCreateDialog_appDialogCreateMutation$variables = {
  input: AppDialogCreateInput;
};
export type AppDialogCreateDialog_appDialogCreateMutation$data = {
  readonly appDialogCreate: {
    readonly id: string;
  };
};
export type AppDialogCreateDialog_appDialogCreateMutation = {
  response: AppDialogCreateDialog_appDialogCreateMutation$data;
  variables: AppDialogCreateDialog_appDialogCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AppDialog",
    "kind": "LinkedField",
    "name": "appDialogCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppDialogCreateDialog_appDialogCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppDialogCreateDialog_appDialogCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6a0ac0af2b4c1945568a228fff4c21eb",
    "id": null,
    "metadata": {},
    "name": "AppDialogCreateDialog_appDialogCreateMutation",
    "operationKind": "mutation",
    "text": "mutation AppDialogCreateDialog_appDialogCreateMutation(\n  $input: AppDialogCreateInput!\n) {\n  appDialogCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0a79e07ac02244e8ec097a8d302a62e";

export default node;
