import { ChevronLeftIcon, ChevronRightIcon, KebabHorizontalIcon } from '@primer/octicons-react';
import { themeGet } from '@primer/react';
import { graphql, useFragment } from 'react-relay';
import styled from 'styled-components';

import { Pagination_pages$key } from '../../../relay/__generated__/Pagination_pages.graphql';
import ItemList from '../../core/ItemList';
import Stack from '../../core/Stack';
import StyledOcticon from '../../core/StyledOcticon';
import Text from '../../core/Text';

const Pagination_pages = graphql`
  fragment Pagination_pages on Pages {
    first {
      page
    }
    around {
      page
      isCurrent
    }
    last {
      page
    }
    next {
      page
    }
    previous {
      page
    }
  }
`;

type Props = {
  pages: Pagination_pages$key;
  loadPage: (page: number) => void;
};

const Pagination = ({ pages, loadPage }: Props) => {
  const { first, around, last, next, previous } = useFragment(Pagination_pages, pages);

  return (
    <Stack gapX={2}>
      <Stack.Item>
        <PaginationButton disabled={!previous} onClick={() => (previous ? loadPage(previous.page) : null)}>
          <ChevronLeftIcon />
        </PaginationButton>
      </Stack.Item>
      {first ? (
        <>
          <Stack.Item>
            <PaginationButton onClick={() => loadPage(first.page)}>
              <PaginationButtonText>{first.page}</PaginationButtonText>
            </PaginationButton>
          </Stack.Item>
          <Stack.Item>
            <StyledOcticon icon={KebabHorizontalIcon} color={'neutral.muted'} />
          </Stack.Item>
        </>
      ) : null}
      <ItemList
        items={around}
        renderItem={({ page, isCurrent }) => (
          <PaginationButton onClick={() => loadPage(page)} isCurrent={isCurrent} disabled={isCurrent}>
            <PaginationButtonText>{page}</PaginationButtonText>
          </PaginationButton>
        )}
        renderItemWrapper={(children, { page }) => <Stack.Item key={page}>{children}</Stack.Item>}
      />
      {last ? (
        <>
          <Stack.Item>
            <StyledOcticon icon={KebabHorizontalIcon} color={'neutral.muted'} />
          </Stack.Item>
          <Stack.Item>
            <PaginationButton onClick={() => loadPage(last.page)}>
              <PaginationButtonText>{last.page}</PaginationButtonText>
            </PaginationButton>
          </Stack.Item>
        </>
      ) : null}
      <Stack.Item>
        <PaginationButton disabled={!next} onClick={() => (next ? loadPage(next.page) : null)}>
          <ChevronRightIcon />
        </PaginationButton>
      </Stack.Item>
    </Stack>
  );
};

const PaginationButton = styled.button<{ isCurrent?: boolean }>`
  position: relative;
  border-radius: 50%;
  border-width: 0;
  background-color: ${({ isCurrent }) => (isCurrent ? themeGet('colors.neutral.muted') : 'transparent')};
  color: ${themeGet('colors.fg.muted')};
  width: ${themeGet('space.5')};
  height: ${themeGet('space.5')};

  :hover:not(:disabled) {
    background-color: ${themeGet('colors.neutral.subtle')};
    cursor: pointer;
  }
  :active:not(:disabled) {
    background-color: ${themeGet('colors.neutral.muted')};
  }
  :disabled {
    color: ${({ isCurrent }) => (isCurrent ? 'none' : themeGet('colors.neutral.muted'))};
  }
`;

const PaginationButtonText = styled(Text)`
  position: absolute;
  transform: translate(-50%, -50%);

  font-weight: ${themeGet('fontWeights.normal')};
  font-size: ${themeGet('fontSizes.1')};
  font-family: ${themeGet('fonts.normal')};
`;

export default Pagination;
