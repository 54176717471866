import { FieldConfig, useField } from 'formik';
import React, { ComponentProps } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { formatDate, formatISO } from '../../../utils/date';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import DatePicker, { DatePickerProps } from '../DatePicker';
import FormControl from '../FormControl';
import Grid from '../Grid';
import TimePicker, { TimePickerProps } from '../TimePicker';
import View from '../View';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
  disabled?: boolean;
  required?: boolean;
  name?: string;
  value?: string;
  onChange?: (value: string) => void;
  size?: 'small' | 'medium' | 'large';
  datePickerProps?: Pick<DatePickerProps, 'placeholder' | 'minDate' | 'maxDate'>;
  timePickerProps?: Pick<TimePickerProps, 'placeholder' | 'min' | 'max'>;
} & Pick<FieldConfig, 'validate'>;

const DatetimeField = ({
  label,
  labelConfig,
  caption,
  disabled: propDisabled,
  required,
  name = '',
  value: propValue,
  onChange: propOnChange,
  size,
  datePickerProps,
  timePickerProps,
  validate,
}: Props) => {
  const INPUT_ID = `date_time_field_for_${label}`;

  const [{ value: baseValue }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });

  const { isSubmitting } = useFormikContext();
  const value = !isNullable(propValue) ? propValue : baseValue;

  const baseOnChange = (value: string) => setValue(value);
  const handleChange: Props['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else baseOnChange(...arg);
  };

  const disabled = propDisabled || isSubmitting;

  const validationStatus = error ? 'error' : undefined;

  return (
    <FormControl disabled={disabled} required={required}>
      <FormControl.Label {...labelConfig} htmlFor={INPUT_ID}>
        {label}
      </FormControl.Label>
      <View sx={{ width: '100%' }}>
        <Grid gapX={2} gapY={2}>
          <Grid.Unit size={[1, 1, 1 / 2]}>
            <DatePicker
              id={INPUT_ID}
              name={name}
              required={required}
              placeholder={'시작일 입력'}
              validationStatus={validationStatus}
              value={value}
              size={size}
              disabled={disabled}
              onChange={(date) => {
                const newDate = date;
                if (value) {
                  const prevDate = new Date(value);
                  newDate.setHours(prevDate.getHours(), prevDate.getMinutes(), prevDate.getSeconds());
                }
                handleChange(formatISO(newDate));
              }}
              {...datePickerProps}
            />
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 1 / 2]}>
            <TimePicker
              name={name}
              required={required}
              value={formatDate(value, 'HH:mm:ss')}
              size={size}
              onChange={(e) => {
                const [hours, min, sec] = e.target.value.split(':').map((value) => Number(value));
                const newDate = new Date(value).setHours(hours ?? 0, min ?? 0, sec ?? 0);
                handleChange(formatISO(newDate));
              }}
              disabled={disabled}
              validationStatus={validationStatus}
              {...timePickerProps}
            />
          </Grid.Unit>
        </Grid>
      </View>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default DatetimeField;
export type { Props as DatetimeFieldProps };
