import { ChevronLeftIcon, ChevronRightIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import React from 'react';
import { graphql } from 'react-relay';

import Button from '../../components/core/Button';
import DateText from '../../components/core/DateText';
import DialogHandler from '../../components/core/DialogHandler';
import EmptyState from '../../components/core/EmptyState';
import ErrorBoundary from '../../components/core/ErrorBoundary';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import ItemList from '../../components/core/ItemList';
import Katex from '../../components/core/Katex';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Stack from '../../components/core/Stack';
import Text from '../../components/core/Text';
import Timeline from '../../components/core/Timeline';
import View from '../../components/core/View';
import FormulaSetFormulaSetHistoryPaginator from '../../components/formulaSet/FormulaSetFormulaSetHistoryPaginator';
import FormulaSetHistoryDialog from '../../components/formulaSet/FormulaSetHistoryDialog';
import FormulaSetHistoryTimelineItem from '../../components/formulaSet/FormulaSetHistoryTimelineItem';
import FormulaSetMutationActionsButtonStack from '../../components/formulaSet/FormulaSetMutationActionsButtonStack';
import FormulaSetStatusLabel from '../../components/formulaSet/FormulaSetStatusLabel';
import UserAvatarText from '../../components/user/UserAvatarText';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import { FormulaSetId_formulaSetQuery } from '../../relay/__generated__/FormulaSetId_formulaSetQuery.graphql';
import { isNullable } from '../../utils/is';
import { NextPage } from '../_app';

const formulaSetForFormulaSetId = graphql`
  query FormulaSetId_formulaSetQuery($id: ID!) {
    formulaSet(id: $id) {
      id
      sequence
      previous
      next
      unitD {
        title
      }
      formulas {
        edges {
          node {
            id
            title
            text
          }
        }
        totalCount
      }
      description
      createdBy {
        ...UserAvatarText_user
      }
      created
      ...FormulaSetStatusLabel_formulaSet
      ...FormulaSetMutationActionsButtonStack_formulaSet
      ...FormulaSetFormulaSetHistoryPaginator_formulaSet
    }
  }
`;

const FormulaSetId: NextPage = () => {
  const router = useRouter();
  const [{ formulaSet }] = useLazyLoadQuery<FormulaSetId_formulaSetQuery>(formulaSetForFormulaSetId, {
    id: router.query.formulaSetId as string,
  });
  if (isNullable(formulaSet)) return null;
  const {
    id,
    sequence,
    previous: previousFormulaSetId,
    next: nextFormulaSetId,
    description,
    unitD,
    formulas,
    created,
    createdBy,
  } = formulaSet;

  const hasPrevious = !isNullable(previousFormulaSetId);
  const hasNext = !isNullable(nextFormulaSetId);
  const handlePreviousButtonClick = () => {
    router.replace(`/formulaSet/${previousFormulaSetId}`);
  };
  const handleNextButtonClick = () => {
    router.replace(`/formulaSet/${nextFormulaSetId}`);
  };

  return (
    <View>
      <Head siteTitle={`${unitD.title} 개념집`} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={[1, 1, 'max']}>
            <Stack gapX={3}>
              <Stack.Item>
                <IconButton
                  icon={ChevronLeftIcon}
                  aria-label={'Previous Quiz Id'}
                  disabled={!hasPrevious}
                  onClick={handlePreviousButtonClick}
                />
              </Stack.Item>
              <Stack.Item>
                <Text as={'h1'}>{sequence}</Text>
              </Stack.Item>
              <Stack.Item>
                <IconButton
                  icon={ChevronRightIcon}
                  aria-label={'Next Quiz Id'}
                  disabled={!hasNext}
                  onClick={handleNextButtonClick}
                />
              </Stack.Item>
            </Stack>
          </Grid.Unit>
          <Grid.Unit size={[1, 1, 'min']}>
            <FormulaSetMutationActionsButtonStack formulaSet={formulaSet} />
          </Grid.Unit>
        </Grid>
        <HorizontalDivider mt={[3, 3, 0]} mb={5} />
        {formulas.totalCount === 0 ? (
          <EmptyState title={'추가된 개념이 없어요'} description={'개념을 추가해 주세요'} />
        ) : (
          <Grid gapX={5} gapY={3}>
            <Grid.Unit size={[1, 1, 3 / 4]}>
              <ItemList
                items={[
                  {
                    id: id,
                    title: '설명',
                    description: (
                      <Text fontWeight={'normal'} fontSize={1}>
                        {description}
                      </Text>
                    ),
                  },
                  ...formulas.edges.map(({ node: { id, title, text } }, index) => ({
                    id: id,
                    title: `#${index + 1}`,
                    description: (
                      <>
                        <Text fontSize={1} fontWeight={'bold'}>
                          <Katex>{title}</Katex>
                        </Text>
                        <View sx={{ marginTop: 2 }}>
                          <Katex>{text}</Katex>
                        </View>
                      </>
                    ),
                  })),
                ]}
                renderItem={({ title, description }) => (
                  <>
                    <Text fontWeight={'bold'} fontSize={1}>
                      {title}
                    </Text>
                    <View
                      sx={{
                        padding: 3,
                        borderRadius: 2,
                        borderStyle: 'solid',
                        borderWidth: 1,
                        borderColor: 'border.default',
                        marginTop: 3,
                        overflowX: 'scroll',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {description}
                    </View>
                  </>
                )}
                renderItemWrapper={(children, { id }, index) => (
                  <View key={id} sx={{ marginTop: index > 0 ? 5 : 0 }}>
                    {children}
                  </View>
                )}
              />
            </Grid.Unit>
            <Grid.Unit size={[1, 1, 1 / 4]}>
              <ItemList
                items={[
                  {
                    id: 'createdBy',
                    title: '제작자',
                    description: (
                      <UserAvatarText user={createdBy} sx={{ color: 'fg.muted', fontSize: 1, fontWeight: 'bold' }} />
                    ),
                  },
                  {
                    id: 'created',
                    title: '제작일',
                    description: (
                      <DateText sx={{ color: 'fg.muted', fontSize: 1, fontWeight: 'bold' }}>{created}</DateText>
                    ),
                  },
                  {
                    id: 'status',
                    title: '상태',
                    description: <FormulaSetStatusLabel formulaSet={formulaSet} />,
                  },
                ]}
                renderItem={({ title, description }) => (
                  <Grid>
                    <Grid.Unit size={'max'}>
                      <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>{title}</Text>
                    </Grid.Unit>
                    <Grid.Unit size={'min'}>{description}</Grid.Unit>
                  </Grid>
                )}
                renderItemWrapper={(children, { id }, index) => (
                  <View key={id} sx={{ marginTop: index === 0 ? 0 : 2 }}>
                    {children}
                  </View>
                )}
              />
              <View sx={{ marginTop: 6 }}>
                <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>히스토리</Text>
                <ErrorBoundary>
                  <FormulaSetFormulaSetHistoryPaginator fragmentReference={formulaSet}>
                    {({ histories }, { isLoadingNext, hasNext, loadMore }) => (
                      <Timeline>
                        <ItemList
                          items={histories.edges}
                          renderItem={({ node }) => <FormulaSetHistoryTimelineItem formulaSetHistory={node} />}
                          renderItemWrapper={(children, { node }) => (
                            <React.Fragment key={node.id}>
                              {node.type === 'formula_set_update' ? (
                                <DialogHandler
                                  key={node.id}
                                  renderDialog={({ isOpen, closeDialog }) => (
                                    <FormulaSetHistoryDialog
                                      formulaSetHistory={node}
                                      isOpen={isOpen}
                                      onDismiss={closeDialog}
                                      wide
                                    />
                                  )}
                                >
                                  <View
                                    sx={{
                                      'paddingX': 2,
                                      'borderRadius': 2,
                                      'cursor': 'pointer',
                                      'transition': 'background-color 250ms',
                                      ':hover': { backgroundColor: 'canvas.subtle' },
                                    }}
                                  >
                                    {children}
                                  </View>
                                </DialogHandler>
                              ) : (
                                <View sx={{ paddingX: 2 }}>{children}</View>
                              )}
                            </React.Fragment>
                          )}
                        />
                        {hasNext ? (
                          <Button sx={{ width: '100%' }} disabled={isLoadingNext} onClick={() => loadMore(10)}>
                            더보기
                          </Button>
                        ) : (
                          <Timeline.Break />
                        )}
                      </Timeline>
                    )}
                  </FormulaSetFormulaSetHistoryPaginator>
                </ErrorBoundary>
              </View>
            </Grid.Unit>
          </Grid>
        )}
      </View>
    </View>
  );
};

FormulaSetId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
FormulaSetId.authenticated = true;
FormulaSetId.routes = [
  {
    id: 'formulaSetId',
    pathname: '/formulaSet/[formulaSetId]',
    name: '개념집 상세',
    permissions: ['formula_set_read'],
  },
];

export default FormulaSetId;
