import { graphql, useFragment } from 'react-relay';

import {
  MATTeacherConnectionDataTable_matTeacherConnection$data,
  MATTeacherConnectionDataTable_matTeacherConnection$key,
} from '../../../relay/__generated__/MATTeacherConnectionDataTable_matTeacherConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';

const MATTeacherConnectionDataTable_matTeacherConnection = graphql`
  fragment MATTeacherConnectionDataTable_matTeacherConnection on MATTeacherConnection {
    edges {
      node {
        id
        email
      }
    }
  }
`;

type Props = {
  matTeacherConnection: MATTeacherConnectionDataTable_matTeacherConnection$key;
} & Omit<
  DataTableProps<MATTeacherConnectionDataTable_matTeacherConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const MATTeacherConnectionDataTable = ({ matTeacherConnection: teacherConnection, ...props }: Props) => {
  const { edges } = useFragment(MATTeacherConnectionDataTable_matTeacherConnection, teacherConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'email',
          title: 'ID',
          renderValue: ({ email }) => email,
          width: 672,
        },
      ]}
      {...props}
    />
  );
};

export default MATTeacherConnectionDataTable;
