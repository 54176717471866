import { ArrowRightIcon } from '@primer/octicons-react';
import { useField } from 'formik';
import { ComponentProps, useCallback } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { endOfDay, formatISO } from '../../../utils/date';
import DatePicker, { DatePickerProps } from '../DatePicker';
import FormControl from '../FormControl';
import Grid from '../Grid';
import StyledOcticon from '../StyledOcticon';
import View from '../View';

type ValueType = { min?: string; max?: string };

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
  name?: string | [string, string];
  placeholder?: [string, string];
  onChange?: (value: ValueType) => void;
} & Omit<DatePickerProps, 'placeholder' | 'name'>;

const NumberRangeField = ({
  label,
  labelConfig,
  caption,
  disabled: propDisabled,
  required,
  name: propName = '',
  onChange: propOnChange,
  placeholder = ['', ''],
  ...props
}: Props) => {
  const inputId = `date_range_field_for_${label}`;

  const name: [string, string] = typeof propName === 'string' ? [`${propName}.min`, `${propName}.max`] : propName;
  const { isSubmitting } = useFormikContext();
  const [{ value: minValue }, { error: minError }, { setValue: setMinValue }] = useField({ name: name[0] });
  const [{ value: maxValue }, { error: maxError }, { setValue: setMaxValue }] = useField({ name: name[1] });

  const baseOnChange = (value: ValueType) => {
    setMinValue(value.min);
    setMaxValue(value.max);
  };
  const handleChange = propOnChange ? propOnChange : baseOnChange;

  const handleMinChange = useCallback<Required<DatePickerProps>['onChange']>(
    (date) => {
      const min = formatISO(date);
      handleChange({ min, max: maxValue });
    },
    [maxValue],
  );
  const handleMaxChange = useCallback<Required<DatePickerProps>['onChange']>(
    (date) => {
      const max = formatISO(endOfDay(date));
      handleChange({ min: minValue, max });
    },
    [minValue],
  );

  const disabled = propDisabled || isSubmitting;

  return (
    <FormControl disabled={disabled} required={required}>
      <FormControl.Label {...labelConfig} htmlFor={inputId}>
        {label}
      </FormControl.Label>
      <View sx={{ width: '100%' }}>
        <Grid wrap={false} sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <DatePicker
              id={inputId}
              value={minValue}
              onChange={handleMinChange}
              disabled={disabled}
              validationStatus={minError ? 'error' : undefined}
              placeholder={placeholder[0]}
              {...props}
            />
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <StyledOcticon icon={ArrowRightIcon} color={'fg.muted'} />
          </Grid.Unit>
          <Grid.Unit size={'max'}>
            <DatePicker
              value={maxValue}
              onChange={handleMaxChange}
              validationStatus={maxError ? 'error' : undefined}
              disabled={disabled}
              placeholder={placeholder[1]}
              {...props}
            />
          </Grid.Unit>
        </Grid>
      </View>
      {minError ? (
        <FormControl.Validation variant={'error'}>{minError}</FormControl.Validation>
      ) : maxError ? (
        <FormControl.Validation variant={'error'}>{maxError}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default NumberRangeField;
export type { Props as NumberRangeFieldProps };
