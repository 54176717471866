import { graphql, useFragment } from 'react-relay';

import {
  ScrapSourceScrapConnectionDataTable_scrapConnection$data,
  ScrapSourceScrapConnectionDataTable_scrapConnection$key,
} from '../../../relay/__generated__/ScrapSourceScrapConnectionDataTable_scrapConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import Text from '../../core/Text';

const ScrapSourceScrapConnectionDataTable_scrapConnection = graphql`
  fragment ScrapSourceScrapConnectionDataTable_scrapConnection on ScrapConnection {
    edges {
      node {
        id
        actions
        problemNumber
        problemBookPage
        solutionBookPage
      }
    }
  }
`;

type Props = { scrapConnection: ScrapSourceScrapConnectionDataTable_scrapConnection$key } & Omit<
  DataTableProps<ScrapSourceScrapConnectionDataTable_scrapConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const ScrapSourceScrapConnectionDataTable = ({ scrapConnection, ...props }: Props) => {
  const { edges } = useFragment(ScrapSourceScrapConnectionDataTable_scrapConnection, scrapConnection);
  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'index',
          title: '',
          renderValue: (_, index) => <Text fontSize={1}>{index + 1}</Text>,
          width: 30,
        },
        {
          field: 'problem',
          title: '문제',
          renderValue: ({ problemBookPage, problemNumber }) => (
            <Text fontSize={1}>{`${problemBookPage}P의 ${problemNumber}`}</Text>
          ),
          width: 120,
        },
        {
          field: 'solution',
          title: '해설',
          renderValue: ({ solutionBookPage }) => <Text fontSize={1}>{solutionBookPage}P</Text>,
          width: 120,
        },
      ]}
      {...props}
    />
  );
};

export default ScrapSourceScrapConnectionDataTable;
