import { CheckIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql } from 'react-relay';

import useToast from '../../../hooks/useToast';
import {
  TaskVideoCreateDialog_taskVideoCreateMutation,
  VideoTypeEnum,
} from '../../../relay/__generated__/TaskVideoCreateDialog_taskVideoCreateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import RadioGroupField from '../../core/RadioGroupField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TextField from '../../core/TextField';
import TextareaField from '../../core/TextareaField';
import UploadField from '../../core/UploadField';
import TeacherSelectField from '../../teacher/TeacherSelectField';

const taskVideoCreateForTaskVideoCreateDialog = graphql`
  mutation TaskVideoCreateDialog_taskVideoCreateMutation($connections: [ID!]!, $input: TaskVideoCreateInput!) {
    taskVideoCreate(input: $input) @appendNode(connections: $connections, edgeTypeName: "TaskVideoEdge") {
      id
      task {
        id
        actions
        ...TaskTaskHistoryPaginator_task
        ...TaskDescriptionList_task
        videos {
          totalCount
          ...TaskVideoConnectionDataTable_taskVideoConnection
        }
      }
    }
  }
`;

type Props = { taskId: string } & DialogProps &
  Pick<MutationFormikProps<TaskVideoCreateDialog_taskVideoCreateMutation>, 'config' | 'onSubmit' | 'connections'>;

const TaskVideoCreateDialog = ({ taskId, config, onSubmit, connections, ...props }: Props) => {
  const { toast } = useToast();
  const handleCompletedCreate = () => toast('영상 업로드 완료!', 'success');
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>영상 업로드</Text>
      </Dialog.Header>
      <MutationFormik<TaskVideoCreateDialog_taskVideoCreateMutation>
        mutation={taskVideoCreateForTaskVideoCreateDialog}
        initialValues={{
          task: { id: taskId },
          title: '',
          video: { key: '', size: 0 },
          type: 'vertical',
          teacher: { id: '' },
        }}
        connections={connections}
        config={{
          ...config,
          onCompleted: (...arg) => {
            config?.onCompleted?.(...arg);
            handleCompletedCreate();
          },
        }}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <UploadField
                  nodeId={taskId}
                  label={'동영상'}
                  name={'video'}
                  accept={'video/*'}
                  placeholder={'영상 업로드'}
                  required
                />
                <RadioGroupField
                  label={'영상 타입'}
                  name={'type'}
                  required
                  options={[
                    { id: 'vertical' as VideoTypeEnum, text: '세로형' },
                    { id: 'horizontal' as VideoTypeEnum, text: '가로형' },
                  ]}
                  renderContainer={(children) => <Grid gapX={3}>{children}</Grid>}
                  renderOptionWrapper={(children, { id }) => (
                    <Grid.Unit key={id} size={'min'}>
                      {children}
                    </Grid.Unit>
                  )}
                />
                <TextField label={'제목'} name={'title'} required placeholder={'제목 입력'} />
                <Suspense fallback={<Spinner />}>
                  <TeacherSelectField label={'선생님'} name={'teacher.id'} required placeholder={'선생님 선택'} />
                </Suspense>
                <TextareaField label={'설명'} name={'description'} placeholder={'설명 입력'} />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} onClick={() => handleSubmit()} leadingIcon={CheckIcon}>
                업로드하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default TaskVideoCreateDialog;
export type { Props as TaskVideoCreateDialogProps };
