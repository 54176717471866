/**
 * @generated SignedSource<<23fdcc97d5c90a5af151978498ae7acd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MATInvoiceStateEnum = "expired" | "ongoing" | "pending" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MATInvoiceStateLabel_matInvoice$data = {
  readonly id: string;
  readonly organization: {
    readonly currentInvoice: {
      readonly id: string;
    } | null;
    readonly id: string;
  };
  readonly state: MATInvoiceStateEnum;
  readonly " $fragmentType": "MATInvoiceStateLabel_matInvoice";
};
export type MATInvoiceStateLabel_matInvoice$key = {
  readonly " $data"?: MATInvoiceStateLabel_matInvoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATInvoiceStateLabel_matInvoice">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATInvoiceStateLabel_matInvoice",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MATOrganization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "MATInvoice",
          "kind": "LinkedField",
          "name": "currentInvoice",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MATInvoice",
  "abstractKey": null
};
})();

(node as any).hash = "a6ae9bd211c9162a8c15874cc29fabea";

export default node;
