/**
 * @generated SignedSource<<b4386cbef954f3c06c52996a419d168a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProductUpdateDialog_productPriceQuery$variables = {
  discountPrice: number;
  price: number;
  storeStuff: string;
};
export type ProductUpdateDialog_productPriceQuery$data = {
  readonly productPrice: {
    readonly discountPercentage: number;
    readonly monthlyPrice: number;
  };
};
export type ProductUpdateDialog_productPriceQuery = {
  response: ProductUpdateDialog_productPriceQuery$data;
  variables: ProductUpdateDialog_productPriceQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "discountPrice"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "price"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "storeStuff"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "discountPrice",
        "variableName": "discountPrice"
      },
      {
        "kind": "Variable",
        "name": "price",
        "variableName": "price"
      },
      {
        "kind": "Variable",
        "name": "storeStuff",
        "variableName": "storeStuff"
      }
    ],
    "concreteType": "ProductPrice",
    "kind": "LinkedField",
    "name": "productPrice",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "discountPercentage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "monthlyPrice",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductUpdateDialog_productPriceQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductUpdateDialog_productPriceQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d325c18da74e5304783a778904f266ed",
    "id": null,
    "metadata": {},
    "name": "ProductUpdateDialog_productPriceQuery",
    "operationKind": "query",
    "text": "query ProductUpdateDialog_productPriceQuery(\n  $discountPrice: Int!\n  $price: Int!\n  $storeStuff: ID!\n) {\n  productPrice(discountPrice: $discountPrice, price: $price, storeStuff: $storeStuff) {\n    discountPercentage\n    monthlyPrice\n  }\n}\n"
  }
};
})();

(node as any).hash = "d6a18ce545aa63a58eea6bbab94e3f47";

export default node;
