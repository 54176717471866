import { PaperAirplaneIcon } from '@primer/octicons-react';
import { graphql } from 'react-relay';

import {
  CommentCreateMutationFormik_commentCreateMutation,
  CommentCreateMutationFormik_commentCreateMutation$variables,
} from '../../../relay/__generated__/CommentCreateMutationFormik_commentCreateMutation.graphql';
import IconButton from '../../core/IconButton';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import TextField from '../../core/TextField';

type Props = {} & Pick<
  MutationFormikProps<CommentCreateMutationFormik_commentCreateMutation>,
  'onSubmit' | 'connections' | 'config'
> &
  Pick<CommentCreateMutationFormik_commentCreateMutation$variables['input'], 'nodeId'>;

const CommentCreateMutationFormik = ({ nodeId, onSubmit, config, connections }: Props) => {
  return (
    <MutationFormik<CommentCreateMutationFormik_commentCreateMutation>
      mutation={graphql`
        mutation CommentCreateMutationFormik_commentCreateMutation($input: CommentCreateInput!, $connections: [ID!]!) {
          commentCreate(input: $input) @appendNode(edgeTypeName: "CommentEdge", connections: $connections) {
            id
            actions
            ...CommentMutationActionItem_comment
          }
        }
      `}
      initialValues={{
        nodeId,
        description: '',
      }}
      onSubmit={onSubmit}
      config={{
        ...config,
        onCompleted: (response, errors, formikHelpers) => {
          config?.onCompleted?.(response, errors, formikHelpers);
          formikHelpers.resetForm();
        },
      }}
      connections={connections}
    >
      {({ submitForm, dirty, isSubmitting }) => {
        return (
          <TextField
            label={'내용'}
            labelConfig={{ visuallyHidden: true }}
            placeholder={'댓글 입력'}
            name={'description'}
            autoComplete={'off'}
            onKeyUp={(e) => {
              if (e.key === 'Enter') submitForm();
            }}
            trailingAction={
              <IconButton
                icon={PaperAirplaneIcon}
                aria-label={'create comment'}
                disabled={isSubmitting || !dirty}
                variant={'plain'}
                onClick={() => {
                  submitForm();
                }}
              />
            }
          />
        );
      }}
    </MutationFormik>
  );
};

export default CommentCreateMutationFormik;
