import { useField } from 'formik';
import { useState } from 'react';
import { graphql } from 'react-relay';

import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import { StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery } from '../../../relay/__generated__/StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery.graphql';
import { isNullable } from '../../../utils/is';
import Checkbox from '../../core/Checkbox';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import SearchOverlayTokenField, { SearchOverlayTokenFieldProps } from '../../core/SearchOverlayTokenField';
import Text from '../../core/Text';
import View from '../../core/View';

const storeStuffPeriodsForStoreStuffPeriodsSearchOverlayTokenField = graphql`
  query StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery {
    storeStuffPeriods
  }
`;

type Props = {} & Omit<SearchOverlayTokenFieldProps, 'renderItemList'>;

const StoreStuffPeriodsSearchOverlayTokenField = ({ name = '', value: propValue, onOverlayClose, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string[] | undefined>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [{ storeStuffPeriods: baseStoreStuffPeriods }] =
    useLazyLoadQuery<StoreStuffPeriodsSearchOverlayTokenField_storeStuffPeriodsQuery>(
      storeStuffPeriodsForStoreStuffPeriodsSearchOverlayTokenField,
      {},
    );
  const storeStuffPeriods = baseStoreStuffPeriods.map((period) => period.toString());
  const [items, setItems] = useState(storeStuffPeriods);

  const handleCloseOverlay = () => {
    onOverlayClose?.();
    setItems(storeStuffPeriods);
  };

  return (
    <SearchOverlayTokenField
      value={value}
      name={name}
      renderTokenText={(key) => `${key}일`}
      renderItemList={({ containerRef, handleSelect }, itemProps) => (
        <View ref={containerRef as React.RefObject<HTMLDivElement>}>
          <ItemList
            items={items}
            renderItem={(value) => <Text sx={{ fontSize: 1 }}>{value}일</Text>}
            renderItemWrapper={(children, period) => (
              <View
                {...itemProps}
                key={period}
                onClick={(e) => handleSelect(e, period)}
                onKeyDown={(e) => handleSelect(e, period)}
              >
                <Grid gapX={2} wrap={false}>
                  <Grid.Unit size={'min'}>
                    <Checkbox checked={value?.some((selectedPeriod) => selectedPeriod === period)} />
                  </Grid.Unit>
                  <Grid.Unit size={'max'}>{children}</Grid.Unit>
                </Grid>
              </View>
            )}
            emptyState={
              <View sx={{ paddingY: 3 }}>
                <EmptyState title={'검색 결과가 없어요'} description={'다른 검색어로 다시 시도해주세요.'} />
              </View>
            }
          />
        </View>
      )}
      onSearch={(text) => {
        const filteredItems = storeStuffPeriods.filter((period) => period.indexOf(text) !== -1);
        setItems(filteredItems);
      }}
      onOverlayClose={handleCloseOverlay}
      {...props}
    />
  );
};

export default StoreStuffPeriodsSearchOverlayTokenField;
