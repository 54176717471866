import { SearchIcon } from '@primer/octicons-react';
import { useTheme } from '@primer/react';
import { FieldConfig, useField } from 'formik';
import { debounce } from 'lodash-es';
import {
  ChangeEventHandler,
  ComponentProps,
  MouseEvent,
  KeyboardEvent as ReactKeyboardEvent,
  Suspense,
  useEffect,
  useRef,
  useState,
} from 'react';
import { GraphQLTaggedNode, useQueryLoader } from 'react-relay';

import { FocusKeys } from '../../../types/primer';
import { isNullable } from '../../../utils/is';
import { scrollIntoView } from '../../../utils/scroll';
import { formatKoreanByConsonant } from '../../../utils/string';
import AnchoredOverlay from '../AnchoredOverlay';
import FormControl from '../FormControl';
import ItemList, { ItemListProps } from '../ItemList';
import PreloadedQueryRenderer from '../PreloadedQueryRenderer';
import TextInput, { TextInputProps } from '../TextInput';
import View from '../View';

type Props<
  TQuery extends {
    readonly response: { [key: string]: { readonly edges: ReadonlyArray<{ readonly node: { id: string } }> } };
    readonly variables: { readonly filters?: {} | null; readonly order?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
> = {
  query: GraphQLTaggedNode;
  variables?: TQuery['variables'];
  getItems: (response: TQuery['response']) => TQuery['response'][keyof typeof response]['edges'];
  renderItem: ItemListProps<TQuery['response'][keyof TQuery['response']]['edges'][number]>['renderItem'];
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  value?: string | number | readonly string[] | undefined;
  onChange?: (id: TQuery['response'][keyof TQuery['response']]['edges'][number]['node']['id']) => void;
  caption?: string;
} & Pick<TextInputProps, 'placeholder' | 'disabled' | 'name' | 'required' | 'trailingAction'> &
  Pick<FieldConfig, 'validate'>;

const QuerySearchChoiceTextField = <
  TQuery extends {
    readonly response: { [key: string]: { readonly edges: ReadonlyArray<{ readonly node: { id: string } }> } };
    readonly variables: { readonly filters?: {} | null; readonly order?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
>({
  query,
  variables,
  placeholder,
  disabled,
  required,
  getItems,
  renderItem,
  name = '',
  label,
  labelConfig,
  value: propValue,
  trailingAction,
  onChange: propOnChange,
  caption,
  validate,
}: Props<TQuery>) => {
  const inputId = `query_search_choice_field_for_${label}`;

  const [{ value: baseValue }, { error }, { setValue, setError }] = useField({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (isNullable(value) || value === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 선택해 주세요`;
      }
    },
  });

  const value = !isNullable(propValue) ? propValue : baseValue;

  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<TQuery>(query);

  useEffect(() => {
    loadQuery({ ...variables });
    return () => disposeQuery();
  }, [loadQuery, disposeQuery]);

  const textInputRef = useRef<HTMLInputElement>(null);
  const textInputWidth = textInputRef.current?.getBoundingClientRect().width;
  const handleTextInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    loadQuery({ ...variables, filters: { ...variables?.filters, search: e.target.value } });
  };

  const [isAnchoredOverlayOpen, setIsAnchoredOverlayOpen] = useState<boolean>(false);
  const handleAnchoredOverlayOpen = () => {
    setIsAnchoredOverlayOpen(true);
  };
  const handleAnchoredOverlayClose = () => {
    setIsAnchoredOverlayOpen(false);
  };

  const handleChange = (selected: TQuery['response'][keyof TQuery['response']]['edges'][number]['node']) => {
    setError(undefined);
    propOnChange ? propOnChange(selected.id) : setValue(selected.id);
    handleAnchoredOverlayClose();
  };
  const handleClick = (
    e: MouseEvent<HTMLDivElement>,
    selected: TQuery['response'][keyof TQuery['response']]['edges'][number]['node'],
  ) => handleChange(selected);
  const handleKeyDown = (
    e: ReactKeyboardEvent<HTMLDivElement>,
    selected: TQuery['response'][keyof TQuery['response']]['edges'][number]['node'],
  ) => ([' ', 'Enter'].includes(e.code) ? handleChange(selected) : null);

  const { theme } = useTheme();
  const containerRef = useRef<HTMLElement>(null);
  const activeDescendantRef = useRef<HTMLElement>();
  const controllingElementRef = useRef<HTMLElement>(null);
  const textInputHeight = 32;

  const validationStatus = error ? 'error' : undefined;

  return (
    <FormControl required={required} disabled={disabled}>
      <FormControl.Label {...labelConfig} htmlFor={inputId}>
        {label}
      </FormControl.Label>
      <AnchoredOverlay
        width={'auto'}
        height={'auto'}
        overlayProps={{
          maxHeight: 'small',
          sx: { minWidth: textInputWidth, display: 'flex' },
        }}
        align={'center'}
        anchorRef={textInputRef}
        onOpen={handleAnchoredOverlayOpen}
        onClose={handleAnchoredOverlayClose}
        focusZoneSettings={{
          containerRef,
          activeDescendantFocus: controllingElementRef,
          focusOutBehavior: 'wrap',
          bindKeys: FocusKeys.ArrowVertical,
          onActiveDescendantChanged: (current, previous) => {
            activeDescendantRef.current = current;
            if (current && containerRef.current) {
              current.style.backgroundColor = theme?.colors.neutral.muted;
              scrollIntoView(current, containerRef.current, { behavior: 'auto' });
            }
            if (previous) {
              previous.style.backgroundColor = '';
            }
          },
          focusableElementFilter: (elem) => elem instanceof HTMLDivElement,
        }}
        renderAnchor={(anchorProps) => (
          <TextInput
            {...anchorProps}
            id={inputId}
            value={value}
            size={'medium'}
            block
            placeholder={placeholder}
            disabled={disabled}
            autoComplete="off"
            readOnly
            trailingAction={trailingAction}
            validationStatus={validationStatus}
          />
        )}
        open={isAnchoredOverlayOpen}
      >
        <View sx={{ padding: 1, width: '100%' }}>
          <TextInput
            ref={controllingElementRef as React.RefObject<HTMLInputElement>}
            leadingVisual={SearchIcon}
            size={'medium'}
            block
            placeholder={'검색'}
            disabled={disabled}
            autoComplete="off"
            onChange={debounce(handleTextInputChange, 500)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.code === 'Enter' && activeDescendantRef.current) {
                const activeDescendantEvent = new KeyboardEvent(e.type, e.nativeEvent);
                activeDescendantRef.current?.dispatchEvent(activeDescendantEvent);
              }
            }}
          />
          {queryReference ? (
            <Suspense>
              <PreloadedQueryRenderer<TQuery> query={query} queryReference={queryReference}>
                {(data) => (
                  <View
                    ref={containerRef as React.RefObject<HTMLDivElement>}
                    sx={{
                      height: `calc(100% - ${textInputHeight}px)`,
                      overflowY: 'auto',
                    }}
                  >
                    <ItemList
                      items={getItems(data)}
                      renderItem={renderItem}
                      renderItemWrapper={(children, { node }, i) => (
                        <View
                          tabIndex={-1}
                          key={i}
                          sx={{
                            cursor: 'pointer',
                            transition: 'background-color 250ms',
                          }}
                          onClick={(e) => handleClick(e, node)}
                          onKeyDown={(e) => handleKeyDown(e, node)}
                        >
                          {children}
                        </View>
                      )}
                    />
                  </View>
                )}
              </PreloadedQueryRenderer>
            </Suspense>
          ) : null}
        </View>
      </AnchoredOverlay>
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default QuerySearchChoiceTextField;
export type { Props as QuerySearchChoiceFieldProps };
