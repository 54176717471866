import { graphql, useFragment } from 'react-relay';

import {
  TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection$data,
  TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection$key,
} from '../../../relay/__generated__/TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import UserAvatarText from '../../user/UserAvatarText';

const TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection = graphql`
  fragment TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection on TypingAutomationScriptConnection {
    edges {
      node {
        id
        title
        isDefault
        createdBy {
          id
          ...UserAvatarText_user
        }
        created
      }
    }
  }
`;

type Props = {
  typingAutomationScriptConnection: TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection$key;
} & Omit<
  DataTableProps<
    TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection$data['edges'][number]['node']
  >,
  'rows' | 'columns'
>;

const TypingAutomationScriptConnectionDataTable = ({ typingAutomationScriptConnection, ...props }: Props) => {
  const { edges } = useFragment(
    TypingAutomationScriptConnectionDataTable_typingAutomationScriptConnection,
    typingAutomationScriptConnection,
  );

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'title',
          title: '제목',
          renderValue: ({ title }) => title,
          width: 717,
        },
        {
          field: 'isDefault',
          title: '기본 프롬프트 여부',
          renderValue: ({ isDefault }) => (isDefault ? 'O' : 'X'),
          width: 127,
          align: 'center',
        },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 92,
        },
        {
          field: 'created',
          title: '제작일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default TypingAutomationScriptConnectionDataTable;
