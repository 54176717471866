/**
 * @generated SignedSource<<a34147778a8e59b0173f23316543b70b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductEventActionEnum = "product_event_activate" | "product_event_delete" | "product_event_update" | "%future added value";
export type ProductEventUserYearEnum = "elementary_school_4" | "elementary_school_5" | "elementary_school_6" | "high_school_1" | "high_school_2" | "high_school_3" | "high_school_N" | "middle_school_1" | "middle_school_2" | "middle_school_3" | "%future added value";
export type ProductEventId_productEventQuery$variables = {
  id: string;
};
export type ProductEventId_productEventQuery$data = {
  readonly productEvent: {
    readonly actions: ReadonlyArray<ProductEventActionEnum>;
    readonly description: string | null;
    readonly discounts: ReadonlyArray<{
      readonly amount: number;
      readonly id: string;
      readonly product: {
        readonly id: string;
        readonly title: string;
      };
      readonly " $fragmentSpreads": FragmentRefs<"ProductDiscountUpdateDialog_productDiscount">;
    }>;
    readonly endAt: any;
    readonly id: string;
    readonly startAt: any;
    readonly title: string;
    readonly userYears: ReadonlyArray<ProductEventUserYearEnum>;
    readonly " $fragmentSpreads": FragmentRefs<"ProductEventDescriptionList_productEvent" | "ProductEventUpdateDialog_productEvent">;
  };
};
export type ProductEventId_productEventQuery = {
  response: ProductEventId_productEventQuery$data;
  variables: ProductEventId_productEventQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userYears",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Product",
  "kind": "LinkedField",
  "name": "product",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "objectUrl",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductEventId_productEventQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductEvent",
        "kind": "LinkedField",
        "name": "productEvent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductDiscount",
            "kind": "LinkedField",
            "name": "discounts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ProductDiscountUpdateDialog_productDiscount"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductEventDescriptionList_productEvent"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProductEventUpdateDialog_productEvent"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductEventId_productEventQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ProductEvent",
        "kind": "LinkedField",
        "name": "productEvent",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductDiscount",
            "kind": "LinkedField",
            "name": "discounts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "webImage",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "File",
                "kind": "LinkedField",
                "name": "mobileImage",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "backgroundColor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "created",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "52e09f84ac497c41f6e7b7e5eea3b6aa",
    "id": null,
    "metadata": {},
    "name": "ProductEventId_productEventQuery",
    "operationKind": "query",
    "text": "query ProductEventId_productEventQuery(\n  $id: ID!\n) {\n  productEvent(id: $id) {\n    id\n    title\n    actions\n    description\n    startAt\n    endAt\n    userYears\n    discounts {\n      id\n      product {\n        id\n        title\n      }\n      amount\n      ...ProductDiscountUpdateDialog_productDiscount\n    }\n    ...ProductEventDescriptionList_productEvent\n    ...ProductEventUpdateDialog_productEvent\n  }\n}\n\nfragment ProductDiscountUpdateDialog_productDiscount on ProductDiscount {\n  id\n  product {\n    id\n    title\n  }\n  webImage {\n    key\n    objectUrl\n    size\n    id\n  }\n  mobileImage {\n    key\n    objectUrl\n    size\n    id\n  }\n  backgroundColor\n}\n\nfragment ProductEventDescriptionList_productEvent on ProductEvent {\n  id\n  description\n  startAt\n  endAt\n  userYears\n  createdBy {\n    id\n    ...UserAvatarText_user\n  }\n  created\n  ...ProductEventStatusLabel_productEvent\n}\n\nfragment ProductEventStatusLabel_productEvent on ProductEvent {\n  status\n}\n\nfragment ProductEventUpdateDialog_productEvent on ProductEvent {\n  id\n  title\n  description\n  startAt\n  endAt\n  discounts {\n    id\n    amount\n    product {\n      id\n    }\n  }\n  userYears\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "68e5271418cc29b4a21787351b1c6907";

export default node;
