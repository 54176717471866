/**
 * @generated SignedSource<<b90756043393343b62fe22f076504f31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskSecondLabelingInput = {
  difficulty: number;
  task: string;
  unitDs: ReadonlyArray<string>;
};
export type TaskSecondLabelingDialog_taskSecondLabelingMutation$variables = {
  input: TaskSecondLabelingInput;
};
export type TaskSecondLabelingDialog_taskSecondLabelingMutation$data = {
  readonly taskSecondLabeling: {
    readonly difficulty: number;
    readonly id: string;
    readonly unitDs: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type TaskSecondLabelingDialog_taskSecondLabelingMutation = {
  response: TaskSecondLabelingDialog_taskSecondLabelingMutation$data;
  variables: TaskSecondLabelingDialog_taskSecondLabelingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TaskLabeling",
    "kind": "LinkedField",
    "name": "taskSecondLabeling",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "difficulty",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "order",
            "value": {
              "order": "DESC"
            }
          }
        ],
        "concreteType": "UnitD",
        "kind": "LinkedField",
        "name": "unitDs",
        "plural": true,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": "unitDs(order:{\"order\":\"DESC\"})"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskSecondLabelingDialog_taskSecondLabelingMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskSecondLabelingDialog_taskSecondLabelingMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9050b3c1080da4dd4b85ae6b3150aaab",
    "id": null,
    "metadata": {},
    "name": "TaskSecondLabelingDialog_taskSecondLabelingMutation",
    "operationKind": "mutation",
    "text": "mutation TaskSecondLabelingDialog_taskSecondLabelingMutation(\n  $input: TaskSecondLabelingInput!\n) {\n  taskSecondLabeling(input: $input) {\n    id\n    difficulty\n    unitDs(order: {order: DESC}) {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ee77e0e01f1bd8aededb91dad6813c95";

export default node;
