import { CheckIcon } from '@primer/octicons-react';
import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { MATOrganizationInvoiceCreateDialog_invoiceCreateMutation } from '../../../relay/__generated__/MATOrganizationInvoiceCreateDialog_invoiceCreateMutation.graphql';
import { MATOrganizationInvoiceCreateDialog_matOrganization$key } from '../../../relay/__generated__/MATOrganizationInvoiceCreateDialog_matOrganization.graphql';
import Button from '../../core/Button';
import DateField from '../../core/DateField';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPairRadioGroupField from '../../core/EnumPairRadioGroupField';
import FormLayout from '../../core/FormLayout';
import Grid from '../../core/Grid';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import NumberField from '../../core/NumberField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import TextField from '../../core/TextField';

const MATOrganizationInvoiceCreateDialog_matOrganization = graphql`
  fragment MATOrganizationInvoiceCreateDialog_matOrganization on MATOrganization {
    id
  }
`;

type Props = {
  matOrganization: MATOrganizationInvoiceCreateDialog_matOrganization$key;
} & DialogProps &
  Pick<
    MutationFormikProps<MATOrganizationInvoiceCreateDialog_invoiceCreateMutation>,
    'config' | 'onSubmit' | 'connections'
  >;

const MATOrganizationInvoiceCreateDialog = ({ matOrganization, config, onSubmit, connections, ...props }: Props) => {
  const { id } = useFragment(MATOrganizationInvoiceCreateDialog_matOrganization, matOrganization);
  return (
    <Dialog wide {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>이용 정보 생성하기</Text>
      </Dialog.Header>
      <MutationFormik<MATOrganizationInvoiceCreateDialog_invoiceCreateMutation>
        mutation={graphql`
          mutation MATOrganizationInvoiceCreateDialog_invoiceCreateMutation($input: MATInvoiceCreateInput!) {
            invoiceCreate(input: $input) {
              id
              organization {
                id
                currentInvoice {
                  id
                  premiumType
                }
              }
            }
          }
        `}
        config={config}
        connections={connections}
        onSubmit={onSubmit}
        initialValues={{
          organization: id,
          premiumType: 'premium_refundable',
          maxStudentCount: 0,
          amount: 0,
        }}
      >
        {({ handleSubmit }, { nonFieldErrorRef, scrollContainerRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <FormLayout>
                <NonFieldError ref={nonFieldErrorRef} />
                <TextField label={'제목'} name={'title'} required placeholder={'e.g. 조직 이름 혹은 체험용 입력'} />
                <Suspense fallback={<Spinner />}>
                  <EnumPairRadioGroupField
                    label={'타입'}
                    name={'premiumType'}
                    required
                    typename={'MATOrganizationPremiumTypeEnum'}
                    renderOptionWrapper={(children, { id }) => (
                      <Grid.Unit key={id} size={1 / 2}>
                        {children}
                      </Grid.Unit>
                    )}
                    renderContainer={(children) => <Grid gapX={3}>{children}</Grid>}
                  />
                </Suspense>
                <NumberField
                  label={'일 환급 금액'}
                  name={'scholarshipAmount'}
                  required
                  trailingVisual={'원'}
                  placeholder={'환급 금액 입력'}
                />
                <Grid gapX={3} gapY={2}>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <DateField label={'시작일'} name={'startDate'} required placeholder={'시작일 선택'} />
                  </Grid.Unit>
                  <Grid.Unit size={[1, 1, 1 / 2]}>
                    <DateField label={'종료일'} name={'endDate'} required placeholder={'종료일 선택'} />
                  </Grid.Unit>
                </Grid>
                <NumberField
                  label={'최대 인원 수'}
                  name={'maxStudentCount'}
                  required
                  trailingVisual={'명'}
                  placeholder={'최대 인원 수 입력'}
                />
                <NumberField
                  label={'결제 금액'}
                  name={'amount'}
                  required
                  trailingVisual={'원'}
                  placeholder={'결제 금액 입력'}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} onClick={() => handleSubmit()}>
                생성하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default MATOrganizationInvoiceCreateDialog;
