/**
 * @generated SignedSource<<bbdbe3323712a3e93fbe79cf20f380be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MATSolvedProblemUserAnswerInput = {
  currentAnswer: number;
  previousAnswer: number;
  sequence: number;
};
export type TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery$variables = {
  input: MATSolvedProblemUserAnswerInput;
};
export type TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery$data = {
  readonly solvedProblemUserAnswer: {
    readonly helpText: string | null;
  };
};
export type TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery = {
  response: TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery$data;
  variables: TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MATSolvedProblemUserAnswer",
    "kind": "LinkedField",
    "name": "solvedProblemUserAnswer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "helpText",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "22c39cc5ea887f2f81547b3539ebbed5",
    "id": null,
    "metadata": {},
    "name": "TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery",
    "operationKind": "query",
    "text": "query TaskAnswerChangeHistorySolvedProblemUserAnswerUpdateDialog_SolvedProblemUserAnswerHelpText_solvedProblemUserAnswerQuery(\n  $input: MATSolvedProblemUserAnswerInput!\n) {\n  solvedProblemUserAnswer(input: $input) {\n    helpText\n  }\n}\n"
  }
};
})();

(node as any).hash = "b770383d49d672f8f7cc646aea3300e2";

export default node;
