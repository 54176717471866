import { signIn, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import logo from '../../../public/logo.png';
import Button from '../../components/core/Button';
import Head from '../../components/core/Head';
import Image from '../../components/core/Image';
import LinkButton from '../../components/core/LinkButton';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import { NextPage } from '../_app';

import Credential from './credential';

type Props = {};

const Login: NextPage<Props> = () => {
  const router = useRouter();
  const { status } = useSession();

  const callbackUrl = (router.query.next || '/dashboard') as string;
  const isUnauthenticated = status === 'unauthenticated';

  return (
    <View>
      <Head title={'TCMS'} siteTitle={'로그인'} />
      <View display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'} height={'100vh'} px={[3, 0]}>
        <View display={'flex'} flexDirection={'column'} alignItems={'center'} mt={8}>
          <Image src={logo} alt={logo.src} width={48} height={48} />
          <Text fontSize={3} mt={2} textAlign={'center'}>
            TCMS - Turing Common Management System
          </Text>
        </View>
        <View
          sx={{
            width: ['100%', 330],
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button
            sx={{ width: '100%' }}
            variant={'primary'}
            onClick={() => signIn('google', { callbackUrl })}
            disabled={!isUnauthenticated}
          >
            Sign in with Google
          </Button>
          <LinkButton
            href={{ pathname: '/login/credential', query: router.query }}
            sx={{ marginTop: 2 }}
            variant={'invisible'}
            disabled={!isUnauthenticated}
          >
            Sign in with account
          </LinkButton>
        </View>
        <View mt={2} fontSize={1}>
          <Text>© TURING</Text>
        </View>
      </View>
    </View>
  );
};

Login.authenticated = false;
Login.routes = [{ id: 'login', pathname: '/login', name: 'Login' }, ...Credential.routes];

export default Login;
