/**
 * @generated SignedSource<<2c1b7b24831b3a9fd9d4164fa1cddba2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkbookGradeInput = {
  grades: ReadonlyArray<WorkbookGrade>;
  id: string;
};
export type WorkbookGrade = {
  percentile: number;
  rawScore: number;
  standardScore: number;
};
export type WorkbookGradesUpdateDialog_workbookGradeMutation$variables = {
  input: WorkbookGradeInput;
};
export type WorkbookGradesUpdateDialog_workbookGradeMutation$data = {
  readonly workbookGrade: {
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"WorkbookStatusLabel_workbook" | "WorkbookUpdateDialog_workbook">;
  };
};
export type WorkbookGradesUpdateDialog_workbookGradeMutation = {
  response: WorkbookGradesUpdateDialog_workbookGradeMutation$data;
  variables: WorkbookGradesUpdateDialog_workbookGradeMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkbookGradesUpdateDialog_workbookGradeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookGrade",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookStatusLabel_workbook"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "WorkbookUpdateDialog_workbook"
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserAvatarText_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkbookGradesUpdateDialog_workbookGradeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Workbook",
        "kind": "LinkedField",
        "name": "workbookGrade",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "grade",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "month",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sourceName",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "picture",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4bba37d6e238e3ac2fed4159d798345a",
    "id": null,
    "metadata": {},
    "name": "WorkbookGradesUpdateDialog_workbookGradeMutation",
    "operationKind": "mutation",
    "text": "mutation WorkbookGradesUpdateDialog_workbookGradeMutation(\n  $input: WorkbookGradeInput!\n) {\n  workbookGrade(input: $input) {\n    id\n    ...WorkbookStatusLabel_workbook\n    ...WorkbookUpdateDialog_workbook\n    modified\n    modifiedBy {\n      ...UserAvatarText_user\n      id\n    }\n  }\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n\nfragment WorkbookStatusLabel_workbook on Workbook {\n  status\n}\n\nfragment WorkbookUpdateDialog_workbook on Workbook {\n  id\n  title\n  grade\n  month\n  year\n  sourceName\n}\n"
  }
};
})();

(node as any).hash = "becece5b628452320138089341c76325";

export default node;
