import { DashIcon } from '@primer/octicons-react';
import { useField } from 'formik';
import { debounce } from 'lodash-es';
import { ChangeEvent, ComponentProps, useCallback, useEffect, useRef } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { isNullable } from '../../../utils/is';
import FormControl from '../FormControl';
import Grid from '../Grid';
import NumberInput, { NumberInputProps } from '../NumberInput';
import StyledOcticon from '../StyledOcticon';
import View from '../View';

type ValueType = { min?: number; max?: number };

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  caption?: string;
  debounce?: boolean;
  onChange?: (value: ValueType) => void;
  name?: string | [string, string];
  placeholder?: [string, string];
} & Omit<NumberInputProps, 'type' | 'onChange' | 'name' | 'placeholder'>;

const NumberRangeField = ({
  label,
  labelConfig,
  caption,
  disabled: propDisabled,
  required,
  name: propName = '',
  onChange: propOnChange,
  debounce: propDebounce = false,
  placeholder = ['', ''],
  ...props
}: Props) => {
  const inputId = `number_range_field_for_${label}`;

  const name: [string, string] = typeof propName === 'string' ? [`${propName}.min`, `${propName}.max`] : propName;
  const { isSubmitting } = useFormikContext();
  const [{ value: minValue }, { error: minError }, { setValue: setMinValue }] = useField({ name: name[0] });
  const [{ value: maxValue }, { error: maxError }, { setValue: setMaxValue }] = useField({ name: name[1] });

  const baseOnChange = (value: ValueType) => {
    setMinValue(value.min);
    setMaxValue(value.max);
  };
  const handleChange = propOnChange ? propOnChange : baseOnChange;

  const minInputRef = useRef<HTMLInputElement>(null);
  const maxInputRef = useRef<HTMLInputElement>(null);

  const handleMinChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const min = e.target.value !== '' ? Number(e.target.value) : undefined;
    const max = maxInputRef.current && maxInputRef.current.value !== '' ? Number(maxInputRef.current.value) : undefined;
    handleChange({ min, max });
  }, []);
  const handleMaxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const min = minInputRef.current && minInputRef.current.value !== '' ? Number(minInputRef.current.value) : undefined;
    const max = e.target.value !== '' ? Number(e.target.value) : undefined;
    handleChange({ min, max });
  }, []);

  useEffect(() => {
    if (minInputRef.current) {
      minInputRef.current.value = !isNullable(minValue) ? `${minValue}` : '';
    }
  }, [minValue]);

  useEffect(() => {
    if (maxInputRef.current) {
      maxInputRef.current.value = !isNullable(maxValue) ? `${maxValue}` : '';
    }
  }, [maxValue]);

  const disabled = propDisabled || isSubmitting;

  return (
    <FormControl disabled={disabled} required={required}>
      <FormControl.Label {...labelConfig} htmlFor={inputId}>
        {label}
      </FormControl.Label>
      <View sx={{ width: '100%' }}>
        <Grid gapX={2} wrap={false} sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <NumberInput
              id={inputId}
              ref={minInputRef}
              onChange={propDebounce ? debounce(handleMinChange, 500) : handleMinChange}
              disabled={disabled}
              block
              validationStatus={minError ? 'error' : undefined}
              placeholder={placeholder[0]}
              {...props}
            />
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <StyledOcticon icon={DashIcon} color={'neutral.emphasis'} />
          </Grid.Unit>
          <Grid.Unit size={'max'}>
            <NumberInput
              ref={maxInputRef}
              onChange={propDebounce ? debounce(handleMaxChange, 500) : handleMaxChange}
              validationStatus={maxError ? 'error' : undefined}
              disabled={disabled}
              block
              placeholder={placeholder[1]}
              {...props}
            />
          </Grid.Unit>
        </Grid>
      </View>
      {minError ? (
        <FormControl.Validation variant={'error'}>{minError}</FormControl.Validation>
      ) : maxError ? (
        <FormControl.Validation variant={'error'}>{maxError}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default NumberRangeField;
export type { Props as NumberRangeFieldProps };
