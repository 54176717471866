import { graphql, useFragment } from 'react-relay';

import { TaskHistoryDialog_taskHistory$key } from '../../../relay/__generated__/TaskHistoryDialog_taskHistory.graphql';
import Avatar from '../../core/Avatar';
import DateText from '../../core/DateText';
import Dialog, { DialogProps } from '../../core/Dialog';
import Grid from '../../core/Grid';
import Stack from '../../core/Stack';
import Text from '../../core/Text';
import View from '../../core/View';
import TaskFirstLabelingDifferenceCounterLabel from '../TaskFirstLabelingDifferenceCounterLabel';
import TaskFirstLabelingDifferenceItemList from '../TaskFirstLabelingDifferenceItemList';
import TaskHintFeedbackDifferenceCounterLabel from '../TaskHintFeedbackDifferenceCounterLabel';
import TaskHintFeedbackDifferenceItemList from '../TaskHintFeedbackDifferenceItemList';
import TaskImageCreationDifferenceCounterLabel from '../TaskImageCreationDifferenceCounterLabel';
import TaskImageCreationDifferenceItemList from '../TaskImageCreationDifferenceItemList';
import TaskProblemCreationDifferenceCounterLabel from '../TaskProblemCreationDifferenceCounterLabel';
import TaskProblemCreationDifferenceItemList from '../TaskProblemCreationDifferenceItemList';
import TaskProblemTypingDifferenceCounterLabel from '../TaskProblemTypingDifferenceCounterLabel';
import TaskProblemTypingDifferenceItemList from '../TaskProblemTypingDifferenceItemList';
import TaskPublishDifferenceCounterLabel from '../TaskPublishDifferenceCounterLabel';
import TaskPublishDifferenceItemList from '../TaskPublishDifferenceItemList';
import TaskSecondLabelingDifferenceCounterLabel from '../TaskSecondLabelingDifferenceCounterLabel';
import TaskSecondLabelingDifferenceItemList from '../TaskSecondLabelingDifferenceItemList';
import TaskSolutionVideoDifferenceCounterLabel from '../TaskSolutionVideoDifferenceCounterLabel';
import TaskSolutionVideoDifferenceItem from '../TaskSolutionVideoDifferenceItemList';
import TaskVideoDifferenceCounterLabel from '../TaskVideoDifferenceCounterLabel';
import TaskVideoDifferenceItemList from '../TaskVideoDifferenceItem';

const TaskHistoryDialog_taskHistory = graphql`
  fragment TaskHistoryDialog_taskHistory on TaskHistory {
    createdBy {
      name
      picture
    }
    created
    difference {
      __typename
      ...TaskProblemTypingDifferenceCounterLabel_difference
      ...TaskProblemTypingDifferenceItemList_difference
      ...TaskProblemCreationDifferenceCounterLabel_difference
      ...TaskProblemCreationDifferenceItemList_difference
      ...TaskImageCreationDifferenceCounterLabel_difference
      ...TaskImageCreationDifferenceItemList_difference
      ...TaskFirstLabelingDifferenceItemList_difference
      ...TaskFirstLabelingDifferenceCounterLabel_difference
      ...TaskSecondLabelingDifferenceItemList_difference
      ...TaskSecondLabelingDifferenceCounterLabel_difference
      ...TaskPublishDifferenceItemList_difference
      ...TaskPublishDifferenceCounterLabel_difference
      ...TaskVideoDifferenceCounterLabel_difference
      ...TaskVideoDifferenceItemList_difference
      ...TaskSolutionVideoDifferenceCounterLabel_difference
      ...TaskSolutionVideoDifferenceItemList_difference
      ...TaskHintFeedbackDifferenceItemList_difference
      ...TaskHintFeedbackDifferenceCounterLabel_difference
    }
  }
`;

type Props = {
  taskHistory: TaskHistoryDialog_taskHistory$key;
} & DialogProps;

const TaskHistoryDialog = ({ taskHistory, ...props }: Props) => {
  const { createdBy, created, difference } = useFragment(TaskHistoryDialog_taskHistory, taskHistory);

  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Grid gapX={3} sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'min'}>
            <Avatar src={createdBy.picture || ''} alt={createdBy.name} size={40} />
          </Grid.Unit>
          <Grid.Unit size={'max'}>
            <Stack gapX={1}>
              <Stack.Item>
                <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>{`${createdBy.name}님의 수정 내역`}</Text>
              </Stack.Item>
              <Stack.Item>
                {difference?.__typename === 'TaskProblemTypingDifference' ? (
                  <TaskProblemTypingDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : difference?.__typename === 'TaskProblemCreationDifference' ? (
                  <TaskProblemCreationDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : difference?.__typename === 'TaskImageCreationDifference' ? (
                  <TaskImageCreationDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : difference?.__typename === 'TaskVideoDifference' ? (
                  <TaskVideoDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : difference?.__typename === 'TaskSolutionVideoDifference' ? (
                  <TaskSolutionVideoDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : difference?.__typename === 'TaskFirstLabelingDifference' ? (
                  <TaskFirstLabelingDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : difference?.__typename === 'TaskSecondLabelingDifference' ? (
                  <TaskSecondLabelingDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : difference?.__typename === 'TaskPublishDifference' ? (
                  <TaskPublishDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : difference?.__typename === 'TaskHintFeedbackDifference' ? (
                  <TaskHintFeedbackDifferenceCounterLabel difference={difference} scheme={'primary'} />
                ) : null}
              </Stack.Item>
            </Stack>
            <View>
              <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
            </View>
          </Grid.Unit>
        </Grid>
      </Dialog.Header>
      <Dialog.Body>
        {difference?.__typename === 'TaskProblemTypingDifference' ? (
          <TaskProblemTypingDifferenceItemList difference={difference} />
        ) : difference?.__typename === 'TaskProblemCreationDifference' ? (
          <TaskProblemCreationDifferenceItemList difference={difference} />
        ) : difference?.__typename === 'TaskImageCreationDifference' ? (
          <TaskImageCreationDifferenceItemList difference={difference} />
        ) : difference?.__typename === 'TaskVideoDifference' ? (
          <TaskVideoDifferenceItemList difference={difference} />
        ) : difference?.__typename === 'TaskSolutionVideoDifference' ? (
          <TaskSolutionVideoDifferenceItem difference={difference} />
        ) : difference?.__typename === 'TaskFirstLabelingDifference' ? (
          <TaskFirstLabelingDifferenceItemList difference={difference} />
        ) : difference?.__typename === 'TaskSecondLabelingDifference' ? (
          <TaskSecondLabelingDifferenceItemList difference={difference} />
        ) : difference?.__typename === 'TaskPublishDifference' ? (
          <TaskPublishDifferenceItemList difference={difference} />
        ) : difference?.__typename === 'TaskHintFeedbackDifference' ? (
          <TaskHintFeedbackDifferenceItemList difference={difference} />
        ) : null}
      </Dialog.Body>
    </Dialog>
  );
};

export default TaskHistoryDialog;
