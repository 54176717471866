import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import {
  WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery,
  WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery$variables,
} from '../../../relay/__generated__/WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery.graphql';
import { isNullable } from '../../../utils/is';
import Checkbox from '../../core/Checkbox';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import SearchOverlayTokenField, { SearchOverlayTokenFieldProps } from '../../core/SearchOverlayTokenField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';

const workbookSourceNamesForWorkbookSourceNamesSearchOverlayTokenField = graphql`
  query WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery($filters: WorkbookSourceNamesFilter) {
    workbookSourceNames(filters: $filters)
  }
`;

type Props = {
  variables?: WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery$variables;
} & Omit<SearchOverlayTokenFieldProps, 'renderItemList'>;

const WorkbookSourceNamesSearchOverlayTokenField = ({ name = '', value: propValue, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string[] | undefined>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [queryReference, loadQuery, disposeQuery] =
    useQueryLoader<WorkbookSourceNamesSearchOverlayTokenField_workbookSourceNamesQuery>(
      workbookSourceNamesForWorkbookSourceNamesSearchOverlayTokenField,
    );
  useEffect(() => {
    loadQuery({});
    return () => disposeQuery();
  }, [loadQuery, disposeQuery]);

  const [searchText, setSearchText] = useState('');

  return queryReference ? (
    <PreloadedQueryRenderer
      query={workbookSourceNamesForWorkbookSourceNamesSearchOverlayTokenField}
      queryReference={queryReference}
    >
      {({ workbookSourceNames }) => (
        <SearchOverlayTokenField
          name={name}
          value={value}
          renderTokenText={(id) => id}
          renderItemList={({ containerRef, handleSelect }, itemProps) => (
            <View ref={containerRef as React.RefObject<HTMLDivElement>}>
              <ItemList
                items={
                  searchText
                    ? workbookSourceNames.filter((workbookSourceName) => workbookSourceName.includes(searchText))
                    : workbookSourceNames
                }
                renderItem={(workbookSourceName) => <Text sx={{ fontSize: 1 }}>{workbookSourceName}</Text>}
                renderItemWrapper={(children, workbookSourceName) => (
                  <View
                    {...itemProps}
                    key={workbookSourceName}
                    onClick={(e) => handleSelect(e, workbookSourceName)}
                    onKeyDown={(e) => handleSelect(e, workbookSourceName)}
                  >
                    <Grid gapX={2} wrap={false}>
                      <Grid.Unit size={'min'}>
                        <Checkbox checked={value?.some((selectedId) => selectedId === workbookSourceName)} />
                      </Grid.Unit>
                      <Grid.Unit size={'max'}>{children}</Grid.Unit>
                    </Grid>
                  </View>
                )}
                emptyState={
                  <View sx={{ paddingY: 3 }}>
                    <EmptyState title={'검색 결과가 없어요'} description={'다른 검색어로 다시 시도해주세요.'} />
                  </View>
                }
              />
            </View>
          )}
          onSearch={setSearchText}
          {...props}
        />
      )}
    </PreloadedQueryRenderer>
  ) : (
    <Spinner size={'small'} />
  );
};

export default WorkbookSourceNamesSearchOverlayTokenField;
