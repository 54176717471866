import { ChevronLeftIcon, ChevronRightIcon, KebabHorizontalIcon, TrashIcon } from '@primer/octicons-react';
import { useRouter } from 'next/router';
import { graphql } from 'react-relay';

import BookActionsMutationButtonStack from '../../components/book/BookActionsMutationButtonStack';
import BookBookScrapDeleteDialog from '../../components/book/BookBookScrapDeleteDialog';
import BookBookScrapPaginator from '../../components/book/BookBookScrapPaginator';
import BookDescriptionList from '../../components/book/BookDescriptionList';
import BookUpdateDialog from '../../components/book/BookUpdateDialog';
import BookScrapConnectionDataTable from '../../components/bookScrap/BookScrapConnectionDataTable';
import ActionList from '../../components/core/ActionList';
import ActionMenu from '../../components/core/ActionMenu';
import Button from '../../components/core/Button';
import Card from '../../components/core/Card';
import Checkbox from '../../components/core/Checkbox';
import ConfirmButton from '../../components/core/ConfirmButton';
import { RowWrapper } from '../../components/core/DataTable';
import DialogButton from '../../components/core/DialogButton';
import DialogHandler from '../../components/core/DialogHandler';
import EmptyState from '../../components/core/EmptyState';
import Grid from '../../components/core/Grid';
import Head from '../../components/core/Head';
import HorizontalDivider from '../../components/core/HorizontalDivider';
import IconButton from '../../components/core/IconButton';
import { HeaderSidebarNavPageLayout } from '../../components/core/Layout';
import Link from '../../components/core/Link';
import MutationFormik from '../../components/core/MutationFormik';
import Stack from '../../components/core/Stack';
import StyledOcticon from '../../components/core/StyledOcticon';
import Text from '../../components/core/Text';
import View from '../../components/core/View';
import useInitialValuesFromParsedUrlQuery from '../../hooks/useInitialValuesFromParsedUrlQuery';
import useLazyLoadQuery from '../../hooks/useLazyLoadQuery';
import useToast from '../../hooks/useToast';
import { BookId_bookDeleteMutation } from '../../relay/__generated__/BookId_bookDeleteMutation.graphql';
import { BookFilter, BookId_bookQuery } from '../../relay/__generated__/BookId_bookQuery.graphql';
import { BookId_bookScrapsDeleteMutation } from '../../relay/__generated__/BookId_bookScrapsDeleteMutation.graphql';
import { isNullable } from '../../utils/is';
import { numberWithCommas } from '../../utils/number';
import { parseOrdering } from '../../utils/order';
import { NextPage } from '../_app';

const bookForBookId = graphql`
  query BookId_bookQuery($id: ID!, $filters: BookFilter, $order: BookOrder) {
    book(id: $id) {
      id
      sequence
      status
      actions
      previous(order: $order, filters: $filters)
      next(order: $order, filters: $filters)

      title
      coverPdf {
        key
        objectUrl
      }
      problemPdf {
        key
        objectUrl
      }
      solutionPdf {
        key
        objectUrl
      }
      ...BookUpdateDialog_book
      ...BookBookScrapDeleteDialog_book
      ...BookDescriptionList_book
      ...BookActionsMutationButtonStack_book
      ...BookBookScrapPaginator_book
    }
  }
`;

const BookId: NextPage = () => {
  const router = useRouter();
  const { toast } = useToast();

  const { initialValues } = useInitialValuesFromParsedUrlQuery({
    search: { type: 'string' },
    order: { type: 'string' },
    status_In: { type: 'string', multiple: true },
    type_In: { type: 'string', multiple: true },
    unitAId_In: { type: 'string', multiple: true },
    publisher_In: { type: 'string', multiple: true },
  });

  const { order, ...filters } = initialValues;

  const [{ book }, refresh] = useLazyLoadQuery<BookId_bookQuery>(bookForBookId, {
    id: router.query.bookId as string,
    order: order ? parseOrdering(order as string) : undefined,
    filters: filters as BookFilter,
  });

  if (!book) return null;
  const {
    id,
    previous: previousBookId,
    next: nextBookId,
    status,
    actions,
    sequence,
    title,
    coverPdf,
    problemPdf,
    solutionPdf,
  } = book;

  const hasPrevious = !isNullable(previousBookId);
  const handlePreviousButtonClick = () => {
    const pathname = `/book/${previousBookId}`;
    router.replace({ pathname, query: initialValues }, pathname);
  };

  const hasNext = !isNullable(nextBookId);
  const handleNextButtonClick = () => {
    const pathname = `/book/${nextBookId}`;
    router.replace({ pathname, query: initialValues }, pathname);
  };

  return (
    <View>
      <Head siteTitle={`시중 교재 스크랩 상세`} />
      <Grid gapX={2} sx={{ alignItems: 'center' }}>
        <Grid.Unit size={'max'}>
          <Stack gapX={3}>
            <Stack.Item>
              <IconButton
                icon={ChevronLeftIcon}
                aria-label={'Previous Book Id'}
                disabled={!hasPrevious}
                onClick={() => handlePreviousButtonClick()}
              />
            </Stack.Item>
            <Stack.Item>
              <Text as={'h1'}>{sequence}</Text>
            </Stack.Item>
            <Stack.Item>
              <IconButton
                icon={ChevronRightIcon}
                aria-label={'Next Book Id'}
                disabled={!hasNext}
                onClick={() => handleNextButtonClick()}
              />
            </Stack.Item>
          </Stack>
        </Grid.Unit>
        <Grid.Unit size={'min'}>
          <BookActionsMutationButtonStack book={book} />
        </Grid.Unit>
        <Grid.Unit size={'min'}>
          {actions.includes('book_delete') ? (
            <ActionMenu>
              <ActionMenu.Anchor>
                <IconButton icon={KebabHorizontalIcon} variant={'plain'} aria-label="Open column options" />
              </ActionMenu.Anchor>
              <ActionMenu.Overlay>
                <ActionList>
                  <ActionList.MutationItem<BookId_bookDeleteMutation>
                    mutation={graphql`
                      mutation BookId_bookDeleteMutation($input: BookDeleteInput!) {
                        bookDelete(input: $input) {
                          id @deleteRecord
                        }
                      }
                    `}
                    input={{ id }}
                    variant={'danger'}
                    config={{
                      onCompleted: () => {
                        router.replace('/book').then(() => toast('시중 교재 스크랩 삭제 완료!', 'success'));
                      },
                      onError: () => {
                        toast('시중 교재 스크랩 삭제에 실패했어요', 'error');
                      },
                    }}
                    message={'정말 삭제할까요?'}
                  >
                    <ActionList.LeadingVisual>
                      <TrashIcon />
                    </ActionList.LeadingVisual>
                    삭제하기
                  </ActionList.MutationItem>
                </ActionList>
              </ActionMenu.Overlay>
            </ActionMenu>
          ) : null}
        </Grid.Unit>
      </Grid>
      <HorizontalDivider mt={[3, 3, 0]} mb={5} />
      <Grid gapX={5} gapY={3}>
        <Grid.Unit size={[1, 1, 3 / 4]}>
          <Grid gapX={1} sx={{ alignItems: 'center' }}>
            <Grid.Unit size={'max'}>
              <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.default' }}>{title}</Text>
            </Grid.Unit>
            {actions.includes('book_update') ? (
              <Grid.Unit size={'min'}>
                <DialogButton
                  renderDialog={({ closeDialog, isOpen }) => (
                    <BookUpdateDialog
                      book={book}
                      isOpen={isOpen}
                      onDismiss={closeDialog}
                      config={{
                        onCompleted: () => {
                          toast('PDF 정보가 수정됐어요', 'success');
                          closeDialog();
                        },
                      }}
                    />
                  )}
                  size={'large'}
                  variant={'outline'}
                >
                  수정하기
                </DialogButton>
              </Grid.Unit>
            ) : null}
          </Grid>
          <Card sx={{ marginTop: 2, padding: 3 }}>
            <BookDescriptionList
              book={book}
              titleUnitSize={[1, 1, 1 / 6]}
              descriptionUnitSize={[1, 1, 5 / 6]}
              gapX={6}
              renderTitle={(title) => (
                <Text fontWeight={'normal'} fontSize={1} color={'fg.subtle'}>
                  {title}
                </Text>
              )}
            />
          </Card>
          <View sx={{ marginTop: 5 }}>
            <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>문제 표지 PDF</Text>
          </View>
          <Card sx={{ marginTop: 2 }}>
            <Link href={coverPdf.objectUrl} target={'_blank'}>
              <Grid gapX={1} wrap={false} sx={{ padding: 3 }}>
                <Grid.Unit size={'max'}>
                  <Text sx={{ fontSize: 1 }}>{coverPdf.key}</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Text sx={{ fontSize: 1 }} color={'fg.subtle'}>
                    PDF 열기
                  </Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <StyledOcticon icon={ChevronRightIcon} color={'fg.subtle'} />
                </Grid.Unit>
              </Grid>
            </Link>
          </Card>
          <View sx={{ marginTop: 5 }}>
            <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>문제 PDF</Text>
          </View>
          <Card sx={{ marginTop: 2 }}>
            <Link href={problemPdf.objectUrl} target={'_blank'}>
              <Grid gapX={1} wrap={false} sx={{ padding: 3 }}>
                <Grid.Unit size={'max'}>
                  <Text sx={{ fontSize: 1 }}>{problemPdf.key}</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Text sx={{ fontSize: 1 }} color={'fg.subtle'}>
                    PDF 열기
                  </Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <StyledOcticon icon={ChevronRightIcon} color={'fg.subtle'} />
                </Grid.Unit>
              </Grid>
            </Link>
          </Card>
          <View sx={{ marginTop: 5 }}>
            <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>해설 PDF</Text>
          </View>
          <Card sx={{ marginTop: 2 }}>
            <Link href={solutionPdf.objectUrl} target={'_blank'}>
              <Grid gapX={1} wrap={false} sx={{ padding: 3 }}>
                <Grid.Unit size={'max'}>
                  <Text sx={{ fontSize: 1 }}>{solutionPdf.key}</Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <Text sx={{ fontSize: 1 }} color={'fg.subtle'}>
                    PDF 열기
                  </Text>
                </Grid.Unit>
                <Grid.Unit size={'min'}>
                  <StyledOcticon icon={ChevronRightIcon} color={'fg.subtle'} />
                </Grid.Unit>
              </Grid>
            </Link>
          </Card>
          <View sx={{ marginTop: 5 }}>
            <MutationFormik<BookId_bookScrapsDeleteMutation>
              mutation={graphql`
                mutation BookId_bookScrapsDeleteMutation($input: BookScrapsDeleteInput!) {
                  bookScrapsDelete(input: $input) {
                    id
                    ...BookBookScrapPaginator_book
                  }
                }
              `}
              initialValues={{
                book: id,
                bookScraps: [],
              }}
              config={{
                onCompleted: (response, errors, formikHelpers) => {
                  toast('문제가 삭제됐어요', 'success');
                  formikHelpers.resetForm();
                },
              }}
            >
              {({ submitForm, isSubmitting, values, setFieldValue, resetForm }) => {
                const isDeletable = status === 'ongoing';
                return (
                  <>
                    <Grid sx={{ alignItems: 'center' }}>
                      <Grid.Unit size={'max'}>
                        <Text sx={{ fontSize: 1, fontWeight: 'bold' }}>스크랩한 문제</Text>
                      </Grid.Unit>
                      {isDeletable ? (
                        <Grid.Unit size={'min'}>
                          <ConfirmButton
                            size={'large'}
                            variant={'danger'}
                            onClick={() => submitForm()}
                            disabled={values.bookScraps.length === 0}
                            message={'삭제된 문제는 되돌릴 수 없어요. 삭제할까요?'}
                          >
                            삭제하기
                          </ConfirmButton>
                        </Grid.Unit>
                      ) : null}
                    </Grid>
                    <View sx={{ marginTop: 3 }}>
                      <BookBookScrapPaginator fragmentReference={book}>
                        {({ bookScraps }, { loadMore, hasNext, isLoadingNext }) => (
                          <>
                            <Text sx={{ fontSize: 1, fontWeight: 'bold', color: 'fg.muted' }}>
                              {`총 ${numberWithCommas(bookScraps.totalCount || 0)} 문제${
                                values.bookScraps.length > 0 ? ` / ${values.bookScraps.length} 문제` : ''
                              }`}
                            </Text>
                            <View sx={{ marginTop: 2, overflowX: 'auto' }}>
                              <BookScrapConnectionDataTable
                                bookScrapConnection={bookScraps}
                                emptyState={
                                  <View sx={{ paddingY: 3 }}>
                                    <EmptyState title={'아직 스크랩한 문제가 없어요'} />
                                  </View>
                                }
                                renderHead={(columns) => (
                                  <View
                                    as={'thead'}
                                    sx={{
                                      borderBottomWidth: 1,
                                      borderBottomStyle: 'solid',
                                      borderBottomColor: 'border.default',
                                      backgroundColor: 'canvas.subtle',
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <View as={'tr'}>
                                      {isDeletable ? (
                                        <View
                                          as={'th'}
                                          sx={{ padding: 2, width: 40 }}
                                          onClick={(e) => {
                                            if (e.currentTarget === e.target)
                                              (
                                                e.currentTarget.querySelector('input[type=checkbox]') as HTMLElement
                                              )?.click();
                                          }}
                                        >
                                          <Checkbox
                                            disabled={isSubmitting || bookScraps.edges.length === 0}
                                            checked={
                                              values.bookScraps.length !== 0 &&
                                              values.bookScraps.length === bookScraps.edges.length
                                            }
                                            onChange={(e) => {
                                              setFieldValue(
                                                'bookScraps',
                                                e.target.checked ? bookScraps.edges.map(({ node }) => node.id) : [],
                                              );
                                            }}
                                          />
                                        </View>
                                      ) : null}
                                      {columns.map(({ field, title, width }) => (
                                        <View
                                          key={field}
                                          as={'th'}
                                          sx={{
                                            borderWidth: 1,
                                            borderStyle: 'solid',
                                            borderColor: 'border.default',
                                            minWidth: width,
                                            textAlign: 'start',
                                            padding: 2,
                                            fontWeight: 'bold',
                                            color: 'fg.muted',
                                          }}
                                        >
                                          {title}
                                        </View>
                                      ))}
                                    </View>
                                  </View>
                                )}
                                renderRowWrapper={(children, row, index) => (
                                  <RowWrapper
                                    key={index}
                                    sx={{
                                      'cursor': 'pointer',
                                      ':hover': {
                                        backgroundColor: 'canvas.inset',
                                        transition: 'background-color 250ms',
                                      },
                                    }}
                                  >
                                    {isDeletable && row.actions.includes('book_scrap_delete') ? (
                                      <View
                                        as={'td'}
                                        sx={{
                                          cursor: 'default',
                                          borderWidth: 1,
                                          borderStyle: 'solid',
                                          borderColor: 'border.default',
                                          padding: 2,
                                        }}
                                        onClick={(e) => {
                                          if (e.currentTarget === e.target)
                                            (
                                              e.currentTarget.querySelector('input[type=checkbox]') as HTMLElement
                                            )?.click();
                                          e.stopPropagation();
                                        }}
                                      >
                                        <Checkbox
                                          disabled={isSubmitting}
                                          checked={values.bookScraps.includes(row.id)}
                                          onChange={(e) => {
                                            setFieldValue(
                                              'bookScraps',
                                              e.target.checked
                                                ? [...values.bookScraps, row.id]
                                                : values.bookScraps.filter((id) => id !== row.id),
                                            );
                                          }}
                                        />
                                      </View>
                                    ) : null}
                                    <DialogHandler
                                      renderDialog={({ isOpen, closeDialog }) => {
                                        return (
                                          <BookBookScrapDeleteDialog
                                            book={book}
                                            isOpen={isOpen}
                                            onDismiss={closeDialog}
                                            initialValues={{ id: row.id }}
                                            full
                                            config={{
                                              onCompleted: () => {
                                                toast('스크랩한 문제가 삭제됐어요', 'success');
                                                resetForm();
                                                refresh();
                                              },
                                            }}
                                          />
                                        );
                                      }}
                                    >
                                      {children}
                                    </DialogHandler>
                                  </RowWrapper>
                                )}
                              />
                              {hasNext ? (
                                <Button
                                  sx={{ width: '100%', marginTop: 2 }}
                                  disabled={isLoadingNext}
                                  onClick={() => loadMore(100)}
                                >
                                  더보기
                                </Button>
                              ) : null}
                            </View>
                          </>
                        )}
                      </BookBookScrapPaginator>
                    </View>
                  </>
                );
              }}
            </MutationFormik>
          </View>
        </Grid.Unit>
        <Grid.Unit size={[1, 1, 1 / 4]}>
          <BookDescriptionList
            book={book}
            type={'activity'}
            titleUnitSize={'max'}
            descriptionUnitSize={'min'}
            renderTitle={(title) => (
              <Text fontWeight={'bold'} fontSize={1}>
                {title}
              </Text>
            )}
          />
        </Grid.Unit>
      </Grid>
    </View>
  );
};

BookId.getLayout = (page) => <HeaderSidebarNavPageLayout>{page}</HeaderSidebarNavPageLayout>;
BookId.authenticated = true;
BookId.routes = [
  {
    id: 'bookId',
    pathname: '/book/[bookId]',
    name: '시중 교재 스크랩 상세',
    permissions: ['book_read'],
  },
];

export default BookId;
