import BaseCropper from 'cropperjs';
import { debounce, isEmpty } from 'lodash-es';
import { forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Document, DocumentProps, Page, PageProps, pdfjs } from 'react-pdf';

import useGlobalEventListener from '../../../hooks/useGlobalEventListener';
import useToast from '../../../hooks/useToast';
import { UploadMetaDataSchema } from '../../../types/upload';
import { getUploadMetaData, uploadGeneral } from '../../../utils/file';
import { isNullable } from '../../../utils/is';
import ErrorState from '../ErrorState';
import Spinner from '../Spinner';
import View from '../View';

type Props = {
  src: string;
  disabled?: boolean;
  cropperHeight?: string;
  pdfWidth?: number;
  pdfHeight?: number;
  scale?: number;
  rotated?: Degree;
} & Pick<DocumentProps, 'onLoadSuccess'> &
  Pick<PageProps, 'pageNumber' | 'width'>;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Degree = 0 | 90 | 180 | 270;
type Cropper = Omit<BaseCropper, 'rotate'> & {
  rotate: (number: Degree) => void;
  setPageNumber: (page: number) => void;
  cropWithUpload: (onUpload: (blob: Blob, metaData: UploadMetaDataSchema) => void, fileName?: string) => void;
};

const arrowKeyMovement = {
  ArrowUp: { top: -1, left: 0 },
  ArrowDown: { top: 1, left: 0 },
  ArrowLeft: { top: 0, left: -1 },
  ArrowRight: { top: 0, left: 1 },
};
const arrowKeyMovementScale = 40;

const PdfCropper = (
  {
    src,
    onLoadSuccess,
    pageNumber: propPageNumber = 1,
    rotated: propRotated = 0,
    cropperHeight = '60vh',
    pdfWidth,
    pdfHeight,
    scale = 1,
  }: Props,
  ref: Ref<Cropper | null>,
) => {
  const { toast } = useToast();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const pdfContainerRef = useRef<HTMLDivElement>(null);

  const [cropper, setCropper] = useState<BaseCropper | null>(null);
  const [rotated, _setRotated] = useState<Degree>(propRotated);
  const [pageNumber, _setPageNumber] = useState<number>(propPageNumber);

  const initializeCropper = (canvas: HTMLCanvasElement) => {
    const prevCropBoxData = cropper?.getCropBoxData();
    const prevCanvasData = cropper?.getCanvasData();

    cropper?.destroy();

    let cropBoxMoveStartData: Cropper.CropBoxData;

    const cropStart = (event: Cropper.CropStartEvent<HTMLCanvasElement>) => {
      cropBoxMoveStartData = event.currentTarget.cropper.getCropBoxData();
    };
    const cropMove = (event: Cropper.CropMoveEvent<HTMLCanvasElement>) => {
      if (event.detail.originalEvent.shiftKey && event.detail.action === 'all') {
        const startLeft = cropBoxMoveStartData?.left || 0;
        const startTop = cropBoxMoveStartData?.top || 0;

        const currentCropBoxData = event.currentTarget.cropper.getCropBoxData();
        const dLeft = Math.abs(startLeft - currentCropBoxData.left);
        const dTop = Math.abs(startTop - currentCropBoxData.top);

        if (dLeft < dTop) {
          event.currentTarget.cropper.setCropBoxData({ ...currentCropBoxData, left: startLeft });
        } else {
          event.currentTarget.cropper.setCropBoxData({ ...currentCropBoxData, top: startTop });
        }
      }
    };

    setCropper(
      new BaseCropper(canvas, {
        zoomable: false,
        autoCrop: !isEmpty(prevCropBoxData),
        autoCropArea: (prevCropBoxData?.width || 1) / (prevCanvasData?.width || 1),
        initialAspectRatio: (prevCropBoxData?.width || 1) / (prevCropBoxData?.height || 1),
        cropstart: cropStart,
        cropmove: cropMove,
      }),
    );
  };

  const cropWithUpload = useCallback(
    (onUpload: (blob: Blob, metaData: UploadMetaDataSchema) => void, fileName?: string) => {
      cropper?.getCroppedCanvas().toBlob(async (blob) => {
        if (isNullable(blob)) return;

        const { type: fileType, size: fileSize } = blob;

        const [, extension] = fileType.split('/');

        const metaData = await getUploadMetaData({
          fileName: `${fileName ? fileName : fileSize}.${extension}`,
          fileSize,
        });

        if (!metaData) {
          return;
        }

        const isMultiPartUploadRequired = 'upload_id' in metaData && 'part_metadata' in metaData;

        if (isMultiPartUploadRequired) {
          toast('사진 크기가 너무 큽니다. 더 작게 캡쳐해주세요.', 'error');
        } else {
          const response = await uploadGeneral(blob, metaData);
          if (response.ok) {
            onUpload(blob, metaData);
          }
        }
      });
    },
    [cropper],
  );

  const setRotated = useCallback(
    debounce((degree: Degree) => {
      _setRotated((d) => ((d + degree) % 360) as Degree);
    }, 200),
    [_setRotated],
  );
  useEffect(() => {
    setRotated(propRotated);
  }, [propRotated]);

  const setPageNumber = useCallback(
    debounce((page: number) => {
      _setPageNumber(page);
    }, 200),
    [_setPageNumber],
  );
  useEffect(() => {
    setPageNumber(propPageNumber);
  }, [propPageNumber]);

  const [points, setPoints] = useState<{ x: number; y: number }[]>([]);
  const appendPoint = (e: MouseEvent) => setPoints((pre) => [...pre, { x: e.offsetX, y: e.offsetY }]);
  useGlobalEventListener('keydown', (e) => {
    if (e.code === 'KeyA') {
      pdfContainerRef.current?.addEventListener('click', appendPoint);
    }
  });

  const removePointEvent = () => {
    pdfContainerRef.current?.removeEventListener('click', appendPoint);
    setPoints([]);
  };
  useGlobalEventListener('focusout', removePointEvent);
  useGlobalEventListener('keyup', (e) => {
    if (e.code === 'KeyA') {
      removePointEvent();
    }
  });

  useEffect(() => {
    if (points.length >= 2) {
      const [first, second] = points;

      const left = Math.min(first.x, second.x);
      const top = Math.min(first.y, second.y);

      const width = Math.abs(first.x - second.x);
      const height = Math.abs(first.y - second.y);

      cropper?.crop();
      cropper?.setCropBoxData({ left, top, width, height });
      setPoints([]);
    }
  }, [points]);

  useImperativeHandle(
    ref,
    () =>
      cropper
        ? Object.assign(cropper, {
            rotate: setRotated,
            setPageNumber,
            cropWithUpload,
          })
        : null,
    [cropper, setRotated, setPageNumber, cropWithUpload],
  );

  return (
    <View
      sx={{ borderWidth: 1, borderStyle: 'solid', borderColor: 'border.default', overflow: 'hidden' }}
      onPointerDown={(e) => e.currentTarget.focus()}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.code in arrowKeyMovement) {
          e.preventDefault();
          const key = e.code as keyof typeof arrowKeyMovement;
          const cropBoxData = cropper?.getCropBoxData();

          let topDelta = arrowKeyMovement[key].top;
          let leftDelta = arrowKeyMovement[key].left;
          if (e.shiftKey) {
            topDelta = topDelta * arrowKeyMovementScale;
            leftDelta = leftDelta * arrowKeyMovementScale;
          }

          cropper?.setCropBoxData({
            ...cropBoxData,
            top: (cropBoxData?.top || 0) + topDelta,
            left: (cropBoxData?.left || 0) + leftDelta,
          });
        }
      }}
    >
      <Document
        loading={<Spinner />}
        file={src}
        onLoadSuccess={onLoadSuccess}
        onLoadError={() => <ErrorState title={'PDF를 불러올 수 없습니다'} />}
        onSourceError={() => <ErrorState title={'PDF를 찾을 수 없습니다'} />}
      >
        <Page
          inputRef={pdfContainerRef}
          canvasRef={canvasRef}
          devicePixelRatio={Math.max(2, window.devicePixelRatio) /* for high quality image */}
          loading={<Spinner />}
          onLoadError={() => <ErrorState title={'페이지를 불러올 수 없습니다'} />}
          onRenderSuccess={() => {
            if (canvasRef.current) initializeCropper(canvasRef.current);
          }}
          onLoadSuccess={() => {
            pdfContainerRef.current?.setAttribute('style', `position: relative; width: 100%; height: ${cropperHeight}`);
          }}
          rotate={rotated}
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderInteractiveForms={false}
          renderTextLayer={false}
          renderMode={'canvas'}
          width={pdfWidth}
          height={pdfHeight}
          scale={scale}
        />
      </Document>
    </View>
  );
};

export default forwardRef(PdfCropper);
export type { Props as PdfCropperProps, Cropper };
