import { useField } from 'formik';
import { useEffect, useState } from 'react';
import { graphql, useQueryLoader } from 'react-relay';

import { EbookSearchOverlayTokenField_ebooksQuery } from '../../../relay/__generated__/EbookSearchOverlayTokenField_ebooksQuery.graphql';
import { isNullable } from '../../../utils/is';
import Checkbox from '../../core/Checkbox';
import EmptyState from '../../core/EmptyState';
import Grid from '../../core/Grid';
import ItemList from '../../core/ItemList';
import PreloadedQueryRenderer from '../../core/PreloadedQueryRenderer';
import SearchOverlayTokenField, { SearchOverlayTokenFieldProps } from '../../core/SearchOverlayTokenField';
import Spinner from '../../core/Spinner';
import Text from '../../core/Text';
import View from '../../core/View';

const ebooksForEbookSearchOverlayTokenField = graphql`
  query EbookSearchOverlayTokenField_ebooksQuery {
    ebooks {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

type Props = {} & Omit<SearchOverlayTokenFieldProps, 'renderItemList'>;

const EbookSearchOverlayTokenField = ({ name = '', value: propValue, ...props }: Props) => {
  const [{ value: baseValue }] = useField<string[] | undefined>({ name });
  const value = !isNullable(propValue) ? propValue : baseValue;

  const [queryReference, loadQuery, disposeQuery] = useQueryLoader<EbookSearchOverlayTokenField_ebooksQuery>(
    ebooksForEbookSearchOverlayTokenField,
  );
  useEffect(() => {
    loadQuery({});
    return () => disposeQuery();
  }, [loadQuery, disposeQuery]);

  const [searchText, setSearchText] = useState('');

  return queryReference ? (
    <PreloadedQueryRenderer query={ebooksForEbookSearchOverlayTokenField} queryReference={queryReference}>
      {({ ebooks }) => (
        <SearchOverlayTokenField
          name={name}
          value={value}
          renderTokenText={(id) => ebooks?.edges.find(({ node }) => node.id === id)?.node.title || ''}
          renderItemList={({ containerRef, handleSelect }, itemProps) => (
            <View ref={containerRef as React.RefObject<HTMLDivElement>}>
              <ItemList
                items={searchText ? ebooks.edges.filter(({ node }) => node.title.includes(searchText)) : ebooks.edges}
                renderItem={({ node }) => <Text sx={{ fontSize: 1 }}>{node.title}</Text>}
                renderItemWrapper={(children, { node }) => (
                  <View
                    {...itemProps}
                    key={node.id}
                    onClick={(e) => handleSelect(e, node.id)}
                    onKeyDown={(e) => handleSelect(e, node.id)}
                  >
                    <Grid gapX={2} wrap={false}>
                      <Grid.Unit size={'min'}>
                        <Checkbox checked={value?.some((selectedId) => selectedId === node.id)} />
                      </Grid.Unit>
                      <Grid.Unit size={'max'}>{children}</Grid.Unit>
                    </Grid>
                  </View>
                )}
                emptyState={
                  <View sx={{ paddingY: 3 }}>
                    <EmptyState title={'검색 결과가 없어요'} description={'다른 검색어로 다시 시도해주세요.'} />
                  </View>
                }
              />
            </View>
          )}
          onSearch={setSearchText}
          {...props}
        />
      )}
    </PreloadedQueryRenderer>
  ) : (
    <Spinner size={'small'} />
  );
};

export default EbookSearchOverlayTokenField;
