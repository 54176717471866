import { ChevronLeftIcon, ChevronRightIcon } from '@primer/octicons-react';
import { useTheme } from '@primer/react';
import { throttle } from 'lodash-es';
import { MouseEvent as ReactMouseEvent, TableHTMLAttributes, useEffect, useRef, useState } from 'react';

import View from '../View';

import RowWrapper from './RowWrapper';

type DataTableColumnType<T> = {
  field: string;
  title: string | React.ReactNode;
  width: number;
  renderValue: (row: T, index: number) => React.ReactNode;
  align?: 'start' | 'center' | 'end';
};

type Props<T extends { readonly id: NonNullable<string | number> }> = {
  columns: readonly DataTableColumnType<T>[];
  renderHead?: (columns: readonly DataTableColumnType<T>[]) => React.ReactNode;
  rows: readonly T[];
  onRowClick?: (row: T, e: ReactMouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  renderRow?: (
    row: T,
    columns: readonly DataTableColumnType<T>[],
    index: number,
    rows: readonly T[],
  ) => React.ReactNode;
  renderRowWrapper?: (children: React.ReactNode, row: T, index: number) => React.ReactNode;
  renderFoot?: () => React.ReactNode;
  emptyState?: React.ReactNode;
} & TableHTMLAttributes<HTMLTableElement>;

const DataTable = <T extends { readonly id: NonNullable<string | number> }>({
  columns,
  rows,
  renderHead = (columns) => (
    <View
      as={'thead'}
      sx={{
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: 'border.default',
        backgroundColor: 'canvas.subtle',
      }}
    >
      <View as={'tr'}>
        {columns.map(({ field, width, title }) => {
          return (
            <View
              key={field}
              as={'th'}
              sx={{
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'border.default',
                minWidth: width,
                textAlign: 'start',
                padding: 2,
                fontWeight: 'bold',
                color: 'fg.muted',
              }}
            >
              {title}
            </View>
          );
        })}
      </View>
    </View>
  ),
  onRowClick,
  renderRow = (row, columns, index) =>
    columns.map(({ field, renderValue, width, align = 'start' }) => (
      <View
        key={field}
        as={'td'}
        sx={{
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: 'border.default',
          minWidth: width,
          padding: 2,
          textAlign: align,
        }}
      >
        {renderValue(row, index)}
      </View>
    )),
  renderRowWrapper = (children, row) => (
    <RowWrapper
      key={row.id}
      onClick={(e) => onRowClick?.(row, e)}
      sx={{
        ...(onRowClick
          ? { 'cursor': 'pointer', ':hover': { backgroundColor: 'canvas.inset', transition: 'background-color 250ms' } }
          : {}),
      }}
    >
      {children}
    </RowWrapper>
  ),
  renderFoot = () => <View as={'tfoot'} />,
  emptyState = null,
  ...props
}: Props<T>) => {
  const { theme } = useTheme();

  const rootRef = useRef<HTMLDivElement>(null);

  const [isLeftButtonVisible, setIsLeftButtonVisible] = useState(
    rootRef.current ? rootRef.current.scrollLeft > 0 : false,
  );
  const [isRightButtonVisible, setIsRightButtonVisible] = useState(
    rootRef.current ? rootRef.current.clientWidth + rootRef.current.scrollLeft < rootRef.current.scrollWidth : false,
  );

  const handleScrollButtonVisibility = () => {
    if (rootRef.current) {
      setIsLeftButtonVisible(rootRef.current ? rootRef.current.scrollLeft > 0 : false);
      setIsRightButtonVisible(
        rootRef.current
          ? rootRef.current.clientWidth + Math.ceil(rootRef.current.scrollLeft) < rootRef.current.scrollWidth
          : false,
      );
    }
  };

  const gradientWidth = 200;

  const handleLeftButtonClick = () => {
    if (rootRef.current) {
      rootRef.current.scrollTo({
        left: rootRef.current.scrollLeft - rootRef.current.clientWidth + gradientWidth,
        behavior: 'smooth',
      });
    }
  };
  const handleRightButtonClick = () => {
    if (rootRef.current) {
      rootRef.current.scrollTo({
        left: rootRef.current.scrollLeft + rootRef.current.clientWidth - gradientWidth,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    handleScrollButtonVisibility();
  }, []);

  return (
    <View sx={{ position: 'relative' }}>
      <View ref={rootRef} sx={{ overflow: 'auto' }} onScroll={throttle(handleScrollButtonVisibility, 150)}>
        <View
          as={'table'}
          sx={{
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: 'border.default',
            borderCollapse: 'collapse',
            width: '100%',
            fontSize: 1,
            color: 'fg.default',
            borderRightStyle: 'hidden',
            borderLeftStyle: 'hidden',
          }}
          {...props}
        >
          {renderHead(columns)}
          <View as={'tbody'}>
            {rows.length === 0 ? (
              <View as={'tr'}>
                <View as={'td'} colSpan={columns.length}>
                  {emptyState}
                </View>
              </View>
            ) : (
              rows.map((row, index) => renderRowWrapper(renderRow(row, columns, index, rows), row, index))
            )}
          </View>
          {renderFoot()}
        </View>
        <View display={['none', 'initial', 'initial']}>
          {isLeftButtonVisible ? (
            <>
              <View
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  width: gradientWidth,
                  height: '100%',
                  background: `linear-gradient(to right, ${theme?.colors.canvas.default}, #ffffff00)`,
                  pointerEvents: 'none',
                }}
              />
              <View
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  width: 32,
                  cursor: 'w-resize',
                }}
                onClick={handleLeftButtonClick}
              >
                <ChevronLeftIcon />
              </View>
            </>
          ) : null}
          {isRightButtonVisible ? (
            <>
              <View
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  width: gradientWidth,
                  height: '100%',
                  background: `linear-gradient(to left, ${theme?.colors.canvas.default}, #ffffff00)`,
                  pointerEvents: 'none',
                }}
              />
              <View
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  width: 32,
                  cursor: 'e-resize',
                }}
                onClick={handleRightButtonClick}
              >
                <ChevronRightIcon />
              </View>
            </>
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default DataTable;
export { RowWrapper };
export type { Props as DataTableProps, DataTableColumnType };
