const START_OF_KR_UNICODE = 44032;
const END_OF_KR_UNICODE = 55203;

export const formatKoreanByConsonant = (text: string, withLastConsonant: string, withoutLastConsonant: string) => {
  if (typeof text !== 'string' || text === '') return text;

  const lastChar = text.charCodeAt(text.length - 1);

  /* check last char is korean */
  if (lastChar < START_OF_KR_UNICODE || lastChar > END_OF_KR_UNICODE) return text;

  const lastConsonant = (lastChar - START_OF_KR_UNICODE) % 28;
  if (lastConsonant === 0) return text + withoutLastConsonant;
  return text + withLastConsonant;
};
