import { CheckCircleIcon, FileAddedIcon, PencilIcon, ReportIcon, TagIcon } from '@primer/octicons-react';
import { graphql, useFragment } from 'react-relay';

import { QuizHistoryTimelineItem_quizHistory$key } from '../../../relay/__generated__/QuizHistoryTimelineItem_quizHistory.graphql';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Label from '../../core/Label';
import Timeline from '../../core/Timeline';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';

const QuizHistoryTimelineItem_quizHistory = graphql`
  fragment QuizHistoryTimelineItem_quizHistory on QuizHistory {
    id
    createdBy {
      id
      ...UserAvatarText_user
    }
    created
    type
  }
`;

type Props = {
  quizHistory: QuizHistoryTimelineItem_quizHistory$key;
};

const QuizHistoryTimelineItem = ({ quizHistory }: Props) => {
  const { created, createdBy, type } = useFragment(QuizHistoryTimelineItem_quizHistory, quizHistory);

  return (
    <Timeline.Item>
      <Timeline.Badge>
        {type === 'quiz_create' ? (
          <FileAddedIcon />
        ) : type === 'quiz_update' ? (
          <PencilIcon />
        ) : type === 'quiz_review' ? (
          <CheckCircleIcon />
        ) : type === 'quiz_labeling' ? (
          <TagIcon />
        ) : type === 'quiz_error_report' ? (
          <ReportIcon />
        ) : null}
      </Timeline.Badge>
      <Timeline.Body>
        <Grid>
          <Grid.Unit size={'max'}>
            <View sx={{ display: 'flex', flexDirection: 'column' }}>
              <UserAvatarText user={createdBy} sx={{ fontWeight: 'bold', color: 'fg.muted' }} />
              <View sx={{ marginTop: 2 }}>
                <DateText sx={{ fontSize: 0, color: 'fg.subtle' }}>{created}</DateText>
              </View>
            </View>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <Label
              variant={
                type === 'quiz_create'
                  ? 'default'
                  : type === 'quiz_update'
                  ? 'accent'
                  : type === 'quiz_review'
                  ? 'success'
                  : type === 'quiz_labeling'
                  ? 'primary'
                  : type === 'quiz_error_report'
                  ? 'danger'
                  : 'default'
              }
            >
              <EnumPair typename={'QuizHistoryTypeEnum'}>{type}</EnumPair>
            </Label>
          </Grid.Unit>
        </Grid>
      </Timeline.Body>
    </Timeline.Item>
  );
};

export default QuizHistoryTimelineItem;
export type { Props as QuizHistoryTimelineItemProps };
