import { graphql, useFragment } from 'react-relay';
import styled from 'styled-components';

import { SchoolExamSourceExportedPreview_schoolExamSource$key } from '../../../relay/__generated__/SchoolExamSourceExportedPreview_schoolExamSource.graphql';
import Grid from '../../core/Grid';
import HorizontalDivider from '../../core/HorizontalDivider';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import Text from '../../core/Text';
import View from '../../core/View';

const SchoolExamSourceExportedPreview_schoolExamSource = graphql`
  fragment SchoolExamSourceExportedPreview_schoolExamSource on SchoolExamSource
  @argumentDefinitions(scrapCount: { type: Int }, scrapOrder: { type: SchoolExamScrapOrder }) {
    id
    school {
      id
      title
    }
    title
    scraps(order: $scrapOrder, first: $scrapCount) {
      edges {
        node {
          id
          scrap {
            id
            objectUrl
          }
        }
      }
    }
  }
`;

type Props = { schoolExamSource: SchoolExamSourceExportedPreview_schoolExamSource$key };

const colors = {
  background: 'rgba(255, 255, 255, 1)',
  fg: {
    default: 'rgba(51, 55, 59, 1)',
    subtle: 'rgba(122, 130, 141, 1)',
  },
  border: 'rgba(229, 231, 235, 1)',
};

const SchoolExamSourceExportedPreview = ({ schoolExamSource }: Props) => {
  const { school, title, scraps } = useFragment(SchoolExamSourceExportedPreview_schoolExamSource, schoolExamSource);
  return (
    <View
      sx={{
        width: 1200,
        paddingX: 12,
        paddingTop: 4,
        paddingBottom: 6,
        backgroundColor: colors.background,
      }}
    >
      <Text fontWeight={700} fontSize={'18px'} color={colors.fg.default}>
        {school.title}
      </Text>
      <View sx={{ marginBottom: 4 }}>
        <Text fontWeight={500} fontSize={1} color={colors.fg.subtle}>
          {title}
        </Text>
      </View>
      <View sx={{ position: 'relative', overflow: 'hidden' }}>
        <Grid gapX={3} gapY={5}>
          <ItemList
            items={scraps.edges}
            renderItem={({ node: { scrap } }) => (
              <Image alt={scrap.id} src={scrap.objectUrl} html style={{ display: 'block' }} />
            )}
            renderItemWrapper={(children, { node: { scrap } }, index) => {
              const isLastLine = scraps.edges.length - index < 3;
              return (
                <Grid.Unit key={scrap.id} size={1 / 2}>
                  <View sx={{ height: 109, position: 'relative', overflowY: 'hidden' }}>
                    {children}
                    <BottomEllipsis height={50} />
                  </View>
                  {!isLastLine ? <HorizontalDivider borderColor={colors.border} mt={5} /> : null}
                </Grid.Unit>
              );
            }}
          />
        </Grid>
        <BottomEllipsis height={160} />
      </View>
    </View>
  );
};

const BottomEllipsis = styled.div<{ height?: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${({ height }) => height || 150}px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, ${colors.background} 100%);
`;

export default SchoolExamSourceExportedPreview;
