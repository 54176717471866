import { GraphQLTaggedNode, PreloadedQuery } from 'react-relay';

import createCtx from '../../utils/context';

type QueryFormikContextValue = {
  query: GraphQLTaggedNode;
  queryReference: PreloadedQuery<any> | undefined | null;
};

const [useContext, Provider] = createCtx<QueryFormikContextValue>();

export { useContext as useQueryFormikContext, Provider as QueryFormikContextProvider };
