/**
 * @generated SignedSource<<b0e420db38e4c093c9d31c7298b07bec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GeneratedProblemsCreateInput = {
  count: number;
  generatorId: string;
  maxIteration?: number | null;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation$variables = {
  input: GeneratedProblemsCreateInput;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation$data = {
  readonly generatedProblemsCreate: ReadonlyArray<{
    readonly id: string;
  }>;
};
export type ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation = {
  response: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation$data;
  variables: ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "GeneratedProblem",
    "kind": "LinkedField",
    "name": "generatedProblemsCreate",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1f4d0f82a1093fc556b8ff9940c1d375",
    "id": null,
    "metadata": {},
    "name": "ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ProblemGeneratorGeneratedProblemMutationActionsDialog_generatedProblemsCreateMutation(\n  $input: GeneratedProblemsCreateInput!\n) {\n  generatedProblemsCreate(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f60f973c59fe34460310583d2a0b1a3";

export default node;
