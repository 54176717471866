import { CheckIcon } from '@primer/octicons-react';
import React, { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import { ProblemGeneratorActivateDialog_problemGenerator$key } from '../../../relay/__generated__/ProblemGeneratorActivateDialog_problemGenerator.graphql';
import { ProblemGeneratorActivateDialog_problemGeneratorActivateMutation } from '../../../relay/__generated__/ProblemGeneratorActivateDialog_problemGeneratorActivateMutation.graphql';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';
import UserAvatarRadioGroupField from '../../user/UserAvatarRadioGroupField';

const ProblemGeneratorActivateDialog_problemGenerator = graphql`
  fragment ProblemGeneratorActivateDialog_problemGenerator on ProblemGenerator {
    id
  }
`;

type Props = { problemGenerator: ProblemGeneratorActivateDialog_problemGenerator$key } & DialogProps &
  Pick<
    MutationFormikProps<ProblemGeneratorActivateDialog_problemGeneratorActivateMutation>,
    'onSubmit' | 'config' | 'connections'
  >;

const ProblemGeneratorActivateDialog = ({
  isOpen,
  onSubmit,
  config,
  connections,
  problemGenerator,
  ...props
}: Props) => {
  const { id } = useFragment(ProblemGeneratorActivateDialog_problemGenerator, problemGenerator);
  return (
    <Dialog isOpen={isOpen} {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>검수 완료하기</Text>
      </Dialog.Header>
      <MutationFormik<ProblemGeneratorActivateDialog_problemGeneratorActivateMutation>
        mutation={graphql`
          mutation ProblemGeneratorActivateDialog_problemGeneratorActivateMutation(
            $input: ProblemGeneratorActivateInput!
          ) {
            problemGeneratorActivate(input: $input) {
              id
              actions
              ...ProblemGeneratorStatusLabel_problemGenerator
              ...ProblemGeneratorProblemGeneratorHistoryPaginator_problemGenerator
              assigned
              assignedTo {
                id
                ...UserAvatarText_user
              }
            }
          }
        `}
        initialValues={{
          id,
          assignedTo: '',
        }}
        onSubmit={onSubmit}
        config={config}
        connections={connections}
      >
        {({ handleSubmit }) => (
          <>
            <Dialog.Body>
              <NonFieldError sx={{ marginBottom: 3 }} />
              <Suspense>
                <UserAvatarRadioGroupField
                  label={'작업자'}
                  name={'assignedTo'}
                  required
                  variables={{ filters: { team_In: ['contents'] } }}
                />
              </Suspense>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} leadingIcon={CheckIcon} size={'large'} onClick={() => handleSubmit()}>
                완료하기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default ProblemGeneratorActivateDialog;
