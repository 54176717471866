/**
 * @generated SignedSource<<f5d2c61158309d3a14b428c4d788926d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DeliveryWaybillBoxTypeEnum = "big" | "medium" | "small" | "%future added value";
export type DeliveryWaybillPaymentTypeEnum = "deferred" | "pre" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeliveryWaybillDescriptionList_deliveryWaybill$data = {
  readonly basicFee: number;
  readonly boxType: DeliveryWaybillBoxTypeEnum;
  readonly count: number;
  readonly extraFee: number;
  readonly innerCount: number;
  readonly paymentType: DeliveryWaybillPaymentTypeEnum;
  readonly pickUpAt: any | null;
  readonly pickUpStore: string | null;
  readonly sender: string;
  readonly senderNumber: string;
  readonly totalFee: number;
  readonly waybillNumber: string;
  readonly " $fragmentType": "DeliveryWaybillDescriptionList_deliveryWaybill";
};
export type DeliveryWaybillDescriptionList_deliveryWaybill$key = {
  readonly " $data"?: DeliveryWaybillDescriptionList_deliveryWaybill$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliveryWaybillDescriptionList_deliveryWaybill">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliveryWaybillDescriptionList_deliveryWaybill",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pickUpAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "waybillNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "senderNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pickUpStore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boxType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "innerCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "basicFee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "extraFee",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalFee",
      "storageKey": null
    }
  ],
  "type": "DeliveryWaybill",
  "abstractKey": null
};

(node as any).hash = "dd0a53ae62bd2caef6f9f2739b74917c";

export default node;
