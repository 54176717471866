/**
 * @generated SignedSource<<f3d059eabd0d181485836f9273b67708>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationTypeEnum = "academy" | "school" | "%future added value";
export type MATOrganizationUpdateInput = {
  id: string;
  title?: string | null;
  type?: MATOrganizationTypeEnum | null;
};
export type MATOrganizationUpdateDialog_organizationUpdateMutation$variables = {
  input: MATOrganizationUpdateInput;
};
export type MATOrganizationUpdateDialog_organizationUpdateMutation$data = {
  readonly organizationUpdate: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"MATOrganizationUpdateDialog_matOrganization">;
  };
};
export type MATOrganizationUpdateDialog_organizationUpdateMutation = {
  response: MATOrganizationUpdateDialog_organizationUpdateMutation$data;
  variables: MATOrganizationUpdateDialog_organizationUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MATOrganizationUpdateDialog_organizationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATOrganization",
        "kind": "LinkedField",
        "name": "organizationUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MATOrganizationUpdateDialog_matOrganization"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MATOrganizationUpdateDialog_organizationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MATOrganization",
        "kind": "LinkedField",
        "name": "organizationUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "prefix",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bfaba3532ffecdeebf5828f17c3c238f",
    "id": null,
    "metadata": {},
    "name": "MATOrganizationUpdateDialog_organizationUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation MATOrganizationUpdateDialog_organizationUpdateMutation(\n  $input: MATOrganizationUpdateInput!\n) {\n  organizationUpdate(input: $input) {\n    id\n    ...MATOrganizationUpdateDialog_matOrganization\n  }\n}\n\nfragment MATOrganizationUpdateDialog_matOrganization on MATOrganization {\n  id\n  title\n  prefix\n  type\n}\n"
  }
};
})();

(node as any).hash = "f654dd19c9bc37df98fa7babf5124a94";

export default node;
