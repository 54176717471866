/**
 * @generated SignedSource<<cf301812f677decd00e0c85f70a72a9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskActionEnum = "task_activate" | "task_assign" | "task_copy" | "task_deactivate" | "task_delete" | "task_final_labeling" | "task_first_labeling" | "task_first_labeling_complete" | "task_hint_feedback_publish" | "task_hint_feedback_save" | "task_hint_save" | "task_image_creation_complete" | "task_image_creation_update" | "task_image_request_create" | "task_image_request_delete" | "task_labeling_problem_update" | "task_problem_creation_complete" | "task_problem_typing" | "task_problem_typing_complete" | "task_problem_update" | "task_publish" | "task_result_feedback_save" | "task_second_labeling" | "task_second_labeling_complete" | "task_solution_video_create" | "task_solution_video_update" | "task_submit_feedback_save" | "task_update" | "task_video_create" | "task_video_update" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskActionsMutationButtonStack_task$data = {
  readonly actions: ReadonlyArray<TaskActionEnum>;
  readonly id: string;
  readonly problem: {
    readonly id: string;
    readonly problem: string | null;
    readonly solution: string | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"TaskHintFeedbackPublishDialog_task">;
  readonly " $fragmentType": "TaskActionsMutationButtonStack_task";
};
export type TaskActionsMutationButtonStack_task$key = {
  readonly " $data"?: TaskActionsMutationButtonStack_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskActionsMutationButtonStack_task">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskActionsMutationButtonStack_task",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "actions",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TaskHintFeedbackPublishDialog_task"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskProblem",
      "kind": "LinkedField",
      "name": "problem",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "problem",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "solution",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};
})();

(node as any).hash = "0d6baf0a0d1afeae17e33457ff2729f8";

export default node;
