import { commaizeNumber } from '@teamturing/utils';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  BillConnectionDataTable_billConnection$data,
  BillConnectionDataTable_billConnection$key,
} from '../../../relay/__generated__/BillConnectionDataTable_billConnection.graphql';
import { formatMobilePhoneNumber } from '../../../utils/phoneNumber';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import BillStatusLabel from '../BillStatusLabel';

const BillConnectionDataTable_billConnection = graphql`
  fragment BillConnectionDataTable_billConnection on BillConnection {
    edges {
      node {
        id
        title
        ...BillStatusLabel_bill
        product {
          id
          title
          additionalDiscountAmount
        }
        additionalDiscount
        finalPrice
        studentName
        studentNumber
        purchaserNumber
        created
        expired
        createdBy {
          id
          ...UserAvatarText_user
        }

        # todo
        actions
        paymentLink
        ...BillSendMessageDialog_bill
      }
    }
  }
`;

type Props = {
  billConnection: BillConnectionDataTable_billConnection$key;
} & Omit<DataTableProps<BillConnectionDataTable_billConnection$data['edges'][number]['node']>, 'rows' | 'columns'>;

const BillConnectionDataTable = ({ billConnection, ...props }: Props) => {
  const { edges } = useFragment(BillConnectionDataTable_billConnection, billConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '결제 여부',
          renderValue: (bill) => <BillStatusLabel bill={bill} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'title',
          title: '링크 제목',
          renderValue: ({ title }) => <Text fontSize={1}>{title}</Text>,
          width: 240,
        },
        {
          field: 'product.title',
          title: '판매 상품',
          renderValue: ({ product }) => <Text fontSize={1}>{product.title}</Text>,
          width: 240,
        },
        {
          field: 'additionalDiscountAmount',
          title: '콜 추가 할인',
          renderValue: ({ additionalDiscount, product }) => (
            <Text fontSize={1}>
              {additionalDiscount ? `${commaizeNumber(product.additionalDiscountAmount ?? 0)}원` : '-'}
            </Text>
          ),
          width: 100,
        },
        {
          field: 'finalPrice',
          title: '최종 판매가',
          renderValue: ({ finalPrice }) => <Text fontSize={1}>{commaizeNumber(finalPrice)}원</Text>,
          width: 120,
        },
        {
          field: 'studentName',
          title: '학생 이름',
          renderValue: ({ studentName }) => <Text fontSize={1}>{studentName}</Text>,
          width: 80,
        },
        {
          field: 'studentNumber',
          title: '학생 번호',
          renderValue: ({ studentNumber }) => <Text fontSize={1}>{formatMobilePhoneNumber(studentNumber)}</Text>,
          width: 120,
        },
        {
          field: 'purchaserNumber',
          title: '구매자 번호',
          renderValue: ({ purchaserNumber }) => <Text fontSize={1}>{formatMobilePhoneNumber(purchaserNumber)}</Text>,
          width: 120,
        },
        {
          field: 'created',
          title: '제작일',
          renderValue: ({ created }) => <DateText fontSize={1}>{created}</DateText>,
          width: 160,
        },
        {
          field: 'expired',
          title: '만료일',
          renderValue: ({ expired }) => <DateText fontSize={1}>{expired}</DateText>,
          width: 160,
        },
        {
          field: 'createdBy',
          title: '제작자',
          renderValue: ({ createdBy }) => <UserAvatarText user={createdBy} />,
          width: 92,
        },
      ]}
      {...props}
    />
  );
};

export default BillConnectionDataTable;
