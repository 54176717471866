import { KeyAsteriskIcon, PencilIcon } from '@primer/octicons-react';
import { graphql } from 'react-relay';

import DialogButton from '../../../components/core/DialogButton';
import EmptyState from '../../../components/core/EmptyState';
import Grid from '../../../components/core/Grid';
import Head from '../../../components/core/Head';
import ItemList from '../../../components/core/ItemList';
import { HeaderSidebarNavPageLayout, SettingGridNavListLayout } from '../../../components/core/Layout';
import Text from '../../../components/core/Text';
import View from '../../../components/core/View';
import ShortcutUpdateReloadDialog from '../../../components/shortcut/ShortcutUpdateReloadDialog';
import useLazyLoadQuery from '../../../hooks/useLazyLoadQuery';
import { shortcut_meQuery } from '../../../relay/__generated__/shortcut_meQuery.graphql';
import { NextPage } from '../../_app';

const meForShortcut = graphql`
  query shortcut_meQuery {
    me {
      id
      shortcuts {
        id
        label
        text
      }
      ...ShortcutUpdateReloadDialog_user
    }
  }
`;

const Shortcut: NextPage = () => {
  const [{ me }] = useLazyLoadQuery<shortcut_meQuery>(meForShortcut, {});
  return (
    <View>
      <Head siteTitle={'단축키 설정'} />
      <View>
        <Grid sx={{ alignItems: 'center' }}>
          <Grid.Unit size={'max'}>
            <Text as={'h1'} fontWeight={'bold'}>
              타이핑 단축키 설정
            </Text>
          </Grid.Unit>
          <Grid.Unit size={'min'}>
            <DialogButton
              variant={'primary'}
              leadingIcon={PencilIcon}
              renderDialog={({ isOpen, closeDialog }) => (
                <ShortcutUpdateReloadDialog wide user={me} isOpen={isOpen} onDismiss={closeDialog} />
              )}
            >
              추가 및 수정하기
            </DialogButton>
          </Grid.Unit>
        </Grid>
        <View sx={{ marginTop: 5 }}>
          <View
            sx={{ padding: 3, borderColor: 'border.default', borderStyle: 'solid', borderWidth: 1, borderRadius: 2 }}
          >
            <ItemList
              items={me.shortcuts}
              renderItem={({ label, text }) => (
                <Grid sx={{ alignItems: 'center' }}>
                  <Grid.Unit size={[1 / 3, 1 / 3, 1 / 5]}>
                    <Text fontSize={1} color={'fg.default'} fontWeight={'bold'}>
                      {label}
                    </Text>
                  </Grid.Unit>
                  <Grid.Unit size={[2 / 3, 2 / 3, 4 / 5]}>
                    <Text fontSize={1} color={'fg.default'}>
                      {text.replace(/\n/g, '<enter>')}
                    </Text>
                  </Grid.Unit>
                </Grid>
              )}
              renderItemWrapper={(children, { id }, index) => (
                <View key={id} sx={{ marginTop: index > 0 ? 2 : 0 }}>
                  {children}
                </View>
              )}
              emptyState={<EmptyState title={'지정된 단축키가 없습니다'} description={'단축키를 등록해보세요'} />}
            />
          </View>
        </View>
      </View>
    </View>
  );
};

Shortcut.getLayout = (page) => (
  <HeaderSidebarNavPageLayout>
    <SettingGridNavListLayout>{page}</SettingGridNavListLayout>
  </HeaderSidebarNavPageLayout>
);
Shortcut.authenticated = true;
Shortcut.routes = [
  {
    id: 'shortcut',
    name: '타이핑 단축키 설정',
    pathname: '/setting/shortcut',
    icon: KeyAsteriskIcon,
  },
];

export default Shortcut;
