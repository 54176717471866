/**
 * @generated SignedSource<<83e8612ed5fd6e61949e1f56e4a41bf0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RaffleUpdateInput = {
  description?: string | null;
  endAt?: any | null;
  id: string;
  isPremium?: boolean | null;
  order?: number | null;
  raffleStuff?: NodeInput | null;
  requiredPoint?: number | null;
  showEndAt?: any | null;
  startAt?: any | null;
  title?: string | null;
  winnerCount?: number | null;
};
export type NodeInput = {
  id: string;
};
export type RaffleUpdateDialog_raffleUpdateMutation$variables = {
  input: RaffleUpdateInput;
};
export type RaffleUpdateDialog_raffleUpdateMutation$data = {
  readonly raffleUpdate: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"RaffleUpdateDialog_raffle">;
  };
};
export type RaffleUpdateDialog_raffleUpdateMutation = {
  response: RaffleUpdateDialog_raffleUpdateMutation$data;
  variables: RaffleUpdateDialog_raffleUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RaffleUpdateDialog_raffleUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Raffle",
        "kind": "LinkedField",
        "name": "raffleUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RaffleUpdateDialog_raffle"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RaffleUpdateDialog_raffleUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Raffle",
        "kind": "LinkedField",
        "name": "raffleUpdate",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "RaffleStuff",
            "kind": "LinkedField",
            "name": "raffleStuff",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isPremium",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requiredPoint",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "winnerCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "endAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "showEndAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "order",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5a90afab973df7475c34f4f27952f9ef",
    "id": null,
    "metadata": {},
    "name": "RaffleUpdateDialog_raffleUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation RaffleUpdateDialog_raffleUpdateMutation(\n  $input: RaffleUpdateInput!\n) {\n  raffleUpdate(input: $input) {\n    id\n    ...RaffleUpdateDialog_raffle\n  }\n}\n\nfragment RaffleUpdateDialog_raffle on Raffle {\n  id\n  raffleStuff {\n    id\n  }\n  title\n  description\n  isPremium\n  requiredPoint\n  winnerCount\n  startAt\n  endAt\n  showEndAt\n  order\n}\n"
  }
};
})();

(node as any).hash = "7d2a0b96f4fbb79f46a3cba2a5b48960";

export default node;
