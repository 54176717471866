import { graphql, useFragment } from 'react-relay';

import { SchoolExamSourceUploadDifferenceCounterLabel_difference$key } from '../../../relay/__generated__/SchoolExamSourceUploadDifferenceCounterLabel_difference.graphql';
import { isNullable } from '../../../utils/is';
import CounterLabel, { CounterLabelProps } from '../../core/CounterLabel';

const SchoolExamSourceUploadDifferenceCounterLabel_difference = graphql`
  fragment SchoolExamSourceUploadDifferenceCounterLabel_difference on Difference {
    ... on SchoolExamSourceUploadDifference {
      innerPdf {
        __typename
      }
    }
  }
`;

type Props = { difference: SchoolExamSourceUploadDifferenceCounterLabel_difference$key } & CounterLabelProps;

const SchoolExamSourceUploadDifferenceCounterLabel = ({ difference, ...props }: Props) => {
  const { innerPdf } = useFragment(SchoolExamSourceUploadDifferenceCounterLabel_difference, difference);

  const differenceCount = [innerPdf].filter((diff) => !isNullable(diff)).length;
  return <CounterLabel {...props}>{differenceCount}</CounterLabel>;
};

export default SchoolExamSourceUploadDifferenceCounterLabel;
