import { graphql } from 'react-relay';

import { DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder$key } from '../../../relay/__generated__/DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder.graphql';
import FragmentPaginator from '../../core/FragmentPaginator';
import { FragmentPaginatorProps } from '../../core/FragmentPaginator';

const DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder = graphql`
  fragment DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder on DeliveryOrder
  @argumentDefinitions(first: { type: Int, defaultValue: 10 }, after: { type: String })
  @refetchable(queryName: "DeliveryOrderDeliveryOrderHistoryPaginatorRefreshQuery") {
    histories(first: $first, after: $after)
      @connection(key: "DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder_histories") {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          type
          ...DeliveryOrderHistoryDialog_deliveryOrderHistory
          ...DeliveryOrderHistoryTimelineItem_deliveryOrderHistory
        }
      }
    }
  }
`;

type Props = {} & Omit<
  FragmentPaginatorProps<DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder$key>,
  'fragment'
>;

const DeliveryOrderDeliveryOrderHistoryPaginator = ({ ...props }: Props) => (
  <FragmentPaginator fragment={DeliveryOrderDeliveryOrderHistoryPaginator_deliveryOrder} {...props} />
);

export default DeliveryOrderDeliveryOrderHistoryPaginator;
