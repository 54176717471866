import { PreloadedQuery } from 'react-relay';

import { useQueryFormikContext } from '../../../contexts/QueryFormikContext';
import BasePreloadedQueryRenderer, {
  PreloadedQueryRendererProps as BasePreloadedQueryRendererProps,
} from '../PreloadedQueryRenderer';

type Props<
  TQuery extends {
    readonly response: {};
    readonly variables: { readonly filters?: {} | null; readonly order?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
> = {} & Pick<BasePreloadedQueryRendererProps<TQuery>, 'children'>;

const PreloadedQueryRenderer = <
  TQuery extends {
    readonly response: {};
    readonly variables: { readonly filters?: {} | null; readonly order?: {} | null };
    readonly rawResponse?: {} | undefined;
  },
>({
  ...props
}: Props<TQuery>) => {
  const { query, queryReference } = useQueryFormikContext();

  return queryReference ? (
    <BasePreloadedQueryRenderer<TQuery>
      query={query}
      queryReference={queryReference as PreloadedQuery<TQuery>}
      {...props}
    />
  ) : null;
};

export default PreloadedQueryRenderer;
