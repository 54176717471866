/**
 * @generated SignedSource<<33018dba47d95707b013f184f44345b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type NotificationHeaderItem_notificationAllCheckMutation$variables = {};
export type NotificationHeaderItem_notificationAllCheckMutation$data = {
  readonly notificationAllCheck: any | null;
};
export type NotificationHeaderItem_notificationAllCheckMutation = {
  response: NotificationHeaderItem_notificationAllCheckMutation$data;
  variables: NotificationHeaderItem_notificationAllCheckMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "notificationAllCheck",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationHeaderItem_notificationAllCheckMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationHeaderItem_notificationAllCheckMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "621faddd95ebd1493b3460c65a8d16c5",
    "id": null,
    "metadata": {},
    "name": "NotificationHeaderItem_notificationAllCheckMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationHeaderItem_notificationAllCheckMutation {\n  notificationAllCheck\n}\n"
  }
};
})();

(node as any).hash = "b0a3f771f92d87ff6424d6cec2741be3";

export default node;
