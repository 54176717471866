/**
 * @generated SignedSource<<9730ae5392e9751b7a53d4a2e99368ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AppDeepLinkSelectField_appDeepLinksQuery$variables = {};
export type AppDeepLinkSelectField_appDeepLinksQuery$data = {
  readonly appDeepLinks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly description: string | null;
        readonly id: string;
        readonly title: string;
      };
    }>;
  };
};
export type AppDeepLinkSelectField_appDeepLinksQuery = {
  response: AppDeepLinkSelectField_appDeepLinksQuery$data;
  variables: AppDeepLinkSelectField_appDeepLinksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AppDeepLinkConnection",
    "kind": "LinkedField",
    "name": "appDeepLinks",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AppDeepLinkEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AppDeepLink",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppDeepLinkSelectField_appDeepLinksQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppDeepLinkSelectField_appDeepLinksQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "1fd52b0abeb726289ea880fe010ab4db",
    "id": null,
    "metadata": {},
    "name": "AppDeepLinkSelectField_appDeepLinksQuery",
    "operationKind": "query",
    "text": "query AppDeepLinkSelectField_appDeepLinksQuery {\n  appDeepLinks {\n    edges {\n      node {\n        id\n        title\n        description\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "60402785b97904eea8fda036fae321a3";

export default node;
