import { graphql, useFragment } from 'react-relay';

import {
  TaskSubmitFeedbackStatusEnum,
  TaskSubmitFeedbackStatusLabel_taskSubmitFeedback$key,
} from '../../../relay/__generated__/TaskSubmitFeedbackStatusLabel_taskSubmitFeedback.graphql';
import EnumPair from '../../core/EnumPair';
import Label, { LabelProps } from '../../core/Label';

const TaskSubmitFeedbackStatusLabel_taskSubmitFeedback = graphql`
  fragment TaskSubmitFeedbackStatusLabel_taskSubmitFeedback on TaskSubmitFeedback {
    status
  }
`;

type Props = {
  taskSubmitFeedback: TaskSubmitFeedbackStatusLabel_taskSubmitFeedback$key;
} & Omit<LabelProps, 'variant'>;

const TaskSubmitFeedbackStatusLabel = ({ taskSubmitFeedback, ...props }: Props) => {
  const { status } = useFragment(TaskSubmitFeedbackStatusLabel_taskSubmitFeedback, taskSubmitFeedback);

  const labelPropsByStatus: Record<TaskSubmitFeedbackStatusEnum, LabelProps> = {
    '%future added value': {},
    'published': { variant: 'success' },
    'saved': {},
  };

  return (
    <Label {...labelPropsByStatus[status]} {...props}>
      <EnumPair typename={'TaskSubmitFeedbackStatusEnum'}>{status}</EnumPair>
    </Label>
  );
};

export default TaskSubmitFeedbackStatusLabel;
