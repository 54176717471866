/**
 * @generated SignedSource<<858cd4b7fa0022195fb054989bcf6976>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BillSendMessageDialog_bill$data = {
  readonly id: string;
  readonly purchaserNumber: any;
  readonly " $fragmentType": "BillSendMessageDialog_bill";
};
export type BillSendMessageDialog_bill$key = {
  readonly " $data"?: BillSendMessageDialog_bill$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillSendMessageDialog_bill">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillSendMessageDialog_bill",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purchaserNumber",
      "storageKey": null
    }
  ],
  "type": "Bill",
  "abstractKey": null
};

(node as any).hash = "7a538b1b9ddb8db8315dde7d31095526";

export default node;
