/**
 * @generated SignedSource<<708c93f5d7efcc035d69793558cf4f8b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductEventConnectionDataTable_productEventConnection$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly createdBy: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
      };
      readonly endAt: any;
      readonly id: string;
      readonly sequence: number;
      readonly startAt: any;
      readonly title: string;
      readonly " $fragmentSpreads": FragmentRefs<"ProductEventStatusLabel_productEvent">;
    };
  }>;
  readonly " $fragmentType": "ProductEventConnectionDataTable_productEventConnection";
};
export type ProductEventConnectionDataTable_productEventConnection$key = {
  readonly " $data"?: ProductEventConnectionDataTable_productEventConnection$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductEventConnectionDataTable_productEventConnection">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductEventConnectionDataTable_productEventConnection",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductEventEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductEvent",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ProductEventStatusLabel_productEvent"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sequence",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "createdBy",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserAvatarText_user"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductEventConnection",
  "abstractKey": null
};
})();

(node as any).hash = "79b5c61f6c80c28824c792b2f48188af";

export default node;
