import { graphql, useFragment } from 'react-relay';

import {
  WorkbookConnectionDataTable_workbookConnection$data,
  WorkbookConnectionDataTable_workbookConnection$key,
} from '../../../relay/__generated__/WorkbookConnectionDataTable_workbookConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import EnumPair from '../../core/EnumPair';
import Text from '../../core/Text';
import UserAvatarText from '../../user/UserAvatarText';
import WorkbookStatusLabel from '../WorkbookStatusLabel';

const WorkbookConnectionDataTable_workbookConnection = graphql`
  fragment WorkbookConnectionDataTable_workbookConnection on WorkbookConnection {
    edges {
      node {
        id
        sequence
        ...WorkbookStatusLabel_workbook

        title

        year
        grade
        month
        sourceName

        modified
        modifiedBy {
          id
          ...UserAvatarText_user
        }
      }
    }
  }
`;

type Props = {
  workbookConnection: WorkbookConnectionDataTable_workbookConnection$key;
} & Omit<
  DataTableProps<WorkbookConnectionDataTable_workbookConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const WorkbookConnectionDataTable = ({ workbookConnection, ...props }: Props) => {
  const { edges } = useFragment(WorkbookConnectionDataTable_workbookConnection, workbookConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (workbook) => <WorkbookStatusLabel workbook={workbook} />,
          width: 80,
          align: 'center',
        },
        { field: 'sequence', title: 'ID', renderValue: ({ sequence }) => sequence, width: 80 },
        {
          field: 'title',
          title: '기출명',
          renderValue: ({ title }) => <Text fontSize={1}>{title}</Text>,
          width: 308,
        },
        {
          field: 'year',
          title: '연도',
          renderValue: ({ year }) => (
            <Text fontSize={1}>
              <EnumPair typename={'ScrapSourceExamYearEnum'}>{year}</EnumPair>
            </Text>
          ),
          width: 100,
        },
        {
          field: 'grade',
          title: '학년',
          renderValue: ({ grade }) => (
            <Text fontSize={1}>
              <EnumPair typename={'ScrapSourceExamGradeEnum'}>{grade}</EnumPair>
            </Text>
          ),
          width: 100,
        },
        {
          field: 'month',
          title: '월',
          renderValue: ({ month }) => (
            <Text fontSize={1}>
              <EnumPair typename={'ScrapSourceExamMonthEnum'}>{month}</EnumPair>
            </Text>
          ),
          width: 100,
        },
        {
          field: 'sourceName',
          title: '출제기관',
          renderValue: ({ sourceName }) => <Text fontSize={1}>{sourceName}</Text>,
          width: 100,
        },
        {
          field: 'modified',
          title: '작업일',
          renderValue: ({ modified }) => <DateText fontSize={1}>{modified}</DateText>,
          width: 160,
        },
        {
          field: 'modifiedBy',
          title: '작업자',
          renderValue: ({ modifiedBy }) => (modifiedBy ? <UserAvatarText user={modifiedBy} /> : '-'),
          width: 92,
        },
      ]}
      {...props}
    />
  );
};

export default WorkbookConnectionDataTable;
