import StyledOcticon, { StyledOcticonProps } from '../StyledOcticon';
import View, { ViewProps } from '../View';

type Props = { disabled?: boolean } & Pick<StyledOcticonProps, 'icon'> & Pick<ViewProps, 'onClick'>;

const ToolbarIconButton = ({ icon, onClick, disabled = false }: Props) => (
  <View onClick={(e) => (!disabled ? onClick?.(e) : null)}>
    <StyledOcticon
      icon={icon}
      className={`ril-zoom-in ril__toolbarItemChild ril__builtinButton ${disabled ? 'ril__builtinButtonDisabled' : ''}`}
      sx={{ height: '22px' }}
    />
  </View>
);

export default ToolbarIconButton;
export type { Props as ToolbarIconButtonProps };
