/**
 * @generated SignedSource<<7a80631e25a553da3b78c6ca28098aae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type StuffActionEnum = "stuff_delete" | "stuff_update" | "%future added value";
export type StuffTypeEnum = "coupon" | "delivery" | "gifticon" | "premium_non_refundable" | "premium_refundable" | "%future added value";
export type StuffId_stuffQuery$variables = {
  id: string;
};
export type StuffId_stuffQuery$data = {
  readonly stuff: {
    readonly actions: ReadonlyArray<StuffActionEnum>;
    readonly created: any;
    readonly createdBy: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    };
    readonly description: string | null;
    readonly id: string;
    readonly modified: any;
    readonly modifiedBy: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatarText_user">;
    } | null;
    readonly title: string;
    readonly type: StuffTypeEnum;
    readonly " $fragmentSpreads": FragmentRefs<"StuffUpdateDialog_stuff">;
  };
};
export type StuffId_stuffQuery = {
  response: StuffId_stuffQuery$data;
  variables: StuffId_stuffQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "actions",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserAvatarText_user"
  }
],
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "modified",
  "storageKey": null
},
v10 = [
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "picture",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isDeleted",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StuffId_stuffQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stuff",
        "kind": "LinkedField",
        "name": "stuff",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": (v7/*: any*/),
            "storageKey": null
          },
          (v9/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "StuffUpdateDialog_stuff"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StuffId_stuffQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Stuff",
        "kind": "LinkedField",
        "name": "stuff",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "createdBy",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "modifiedBy",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14da247147bab306d69272157525649f",
    "id": null,
    "metadata": {},
    "name": "StuffId_stuffQuery",
    "operationKind": "query",
    "text": "query StuffId_stuffQuery(\n  $id: ID!\n) {\n  stuff(id: $id) {\n    id\n    title\n    actions\n    description\n    type\n    createdBy {\n      id\n      ...UserAvatarText_user\n    }\n    created\n    modifiedBy {\n      id\n      ...UserAvatarText_user\n    }\n    modified\n    ...StuffUpdateDialog_stuff\n  }\n}\n\nfragment StuffUpdateDialog_stuff on Stuff {\n  id\n  title\n  description\n  type\n}\n\nfragment UserAvatarText_user on User {\n  name\n  picture\n  isDeleted\n}\n"
  }
};
})();

(node as any).hash = "0ba5fe4208378817f954ebb87bbf44e8";

export default node;
