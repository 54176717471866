import { graphql, useFragment } from 'react-relay';

import {
  FormulaSetConnectionDataTable_formulaSetConnection$data,
  FormulaSetConnectionDataTable_formulaSetConnection$key,
} from '../../../relay/__generated__/FormulaSetConnectionDataTable_formulaSetConnection.graphql';
import { isNullable } from '../../../utils/is';
import DataTable, { DataTableProps } from '../../core/DataTable';
import DateText from '../../core/DateText';
import View from '../../core/View';
import UserAvatarText from '../../user/UserAvatarText';
import FormulaSetStatusLabel from '../FormulaSetStatusLabel';

const FormulaSetConnectionDataTable_formulaSetConnection = graphql`
  fragment FormulaSetConnectionDataTable_formulaSetConnection on FormulaSetConnection {
    edges {
      node {
        id
        ...FormulaSetStatusLabel_formulaSet
        sequence
        unitD {
          description
        }
        unitD {
          unitATitle
          description
        }
        modifiedBy {
          id
          ...UserAvatarText_user
        }
        reviewedBy {
          id
          ...UserAvatarText_user
        }
        modified
      }
    }
  }
`;

type Props = {
  formulaSetConnection: FormulaSetConnectionDataTable_formulaSetConnection$key;
} & Omit<
  DataTableProps<FormulaSetConnectionDataTable_formulaSetConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const FormulaSetConnectionDataTable = ({ formulaSetConnection, ...props }: Props) => {
  const { edges } = useFragment(FormulaSetConnectionDataTable_formulaSetConnection, formulaSetConnection);

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        {
          field: 'status',
          title: '상태',
          renderValue: (formulaSet) => <FormulaSetStatusLabel formulaSet={formulaSet} />,
          width: 80,
          align: 'center',
        },
        {
          field: 'sequence',
          title: 'ID',
          renderValue: ({ sequence }) => (
            <View sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{sequence}</View>
          ),
          width: 80,
        },
        { field: 'unitA', title: 'Unit A', renderValue: ({ unitD: { unitATitle } }) => unitATitle, width: 120 },
        { field: 'unitD', title: 'Unit D', renderValue: ({ unitD: { description } }) => description, width: 472 },
        {
          field: 'reviewedBy',
          title: '검수자',
          renderValue: ({ reviewedBy }) => (isNullable(reviewedBy) ? ' - ' : <UserAvatarText user={reviewedBy} />),
          width: 100,
        },
        {
          field: 'modifiedBy',
          title: '마지막 작성자',
          renderValue: ({ modifiedBy }) => (isNullable(modifiedBy) ? ' - ' : <UserAvatarText user={modifiedBy} />),
          width: 100,
        },
        {
          field: 'modified',
          title: '수정일',
          renderValue: ({ modified }) => <DateText>{modified}</DateText>,
          width: 160,
        },
      ]}
      {...props}
    />
  );
};

export default FormulaSetConnectionDataTable;
