import { FieldConfig, useField } from 'formik';
import { ComponentProps } from 'react';

import useFormikContext from '../../../hooks/useFormikContext';
import { AddressInput } from '../../../types/gql';
import { AddressData } from '../../../types/postcode';
import { isNullable } from '../../../utils/is';
import { formatKoreanByConsonant } from '../../../utils/string';
import FormControl from '../FormControl';
import TextInput, { TextInputProps } from '../TextInput';
import ZipCodeInput, { ZipCodeInputProps } from '../ZipCodeInput';

type Props = {
  label: string;
  labelConfig?: ComponentProps<typeof FormControl.Label>;
  value?: AddressInput;
  caption?: string;
  zipCodeAddressReadOnly?: boolean;
  onSelectZipCode?: (data: AddressData) => void;
} & Omit<TextInputProps, 'value' | 'placeholder'> &
  Pick<FieldConfig, 'validate'>;

const AddressInputField = ({
  name = '',
  value: propValue,
  label,
  labelConfig,
  caption,
  required,
  disabled: propDisabled,
  onSelectZipCode,
  onChange: propOnChange,
  readOnly,
  validate,
  ...props
}: Props) => {
  const INPUT_ID = `address_input_field_for_${label}`;

  const [
    { value: baseValue = { zipCode: '', address: '' }, onChange: baseOnChange },
    { error },
    { setValue, setError },
  ] = useField<AddressInput>({
    name,
    validate: (value) => {
      const errorMessage = validate?.(value);
      if (errorMessage) return errorMessage;
      if (required && (value?.zipCode === '' || value?.address === '')) {
        return `${formatKoreanByConsonant(label, '을', '를')} 입력해 주세요`;
      }
    },
  });
  const { isSubmitting } = useFormikContext();

  const value = !isNullable(propValue) ? propValue : baseValue;
  const disabled = propDisabled || isSubmitting;
  const handleChange: ZipCodeInputProps['onChange'] = (...arg) => {
    setError(undefined);

    if (propOnChange) propOnChange(...arg);
    else baseOnChange(...arg);
  };
  const handleSelectZipCode: ZipCodeInputProps['onSelectZipCode'] = (data) => {
    setError(undefined);

    if (onSelectZipCode) onSelectZipCode(data);
    else setValue({ zipCode: data.zonecode, address: data.address });
  };

  return (
    <FormControl sx={{ alignItems: 'stretch' }} required={required} disabled={disabled}>
      <FormControl.Label {...labelConfig} sx={{ marginBottom: 1, ...labelConfig?.sx }} htmlFor={INPUT_ID}>
        {label}
      </FormControl.Label>
      <ZipCodeInput
        id={INPUT_ID}
        block
        name={`${name}.zipCode`}
        disabled={disabled}
        placeholder={'우편번호'}
        value={value.zipCode || ''}
        onChange={handleChange}
        onSelectZipCode={handleSelectZipCode}
        readOnly
        validationStatus={error ? 'error' : undefined}
        {...props}
      />
      <TextInput
        block
        name={`${name}.address`}
        disabled={disabled}
        placeholder={'주소'}
        value={value.address || ''}
        autoComplete={'off'}
        onChange={handleChange}
        readOnly
        validationStatus={error ? 'error' : undefined}
        {...props}
      />
      <TextInput
        block
        name={`${name}.addressDetail`}
        disabled={disabled}
        placeholder={'상세 주소'}
        value={value.addressDetail || ''}
        autoComplete={'off'}
        onChange={handleChange}
        validationStatus={error ? 'error' : undefined}
        readOnly={readOnly}
        {...props}
      />
      {error ? (
        <FormControl.Validation variant={'error'}>{error}</FormControl.Validation>
      ) : caption ? (
        <FormControl.Caption sx={{ whiteSpace: 'pre-wrap' }}>{caption}</FormControl.Caption>
      ) : null}
    </FormControl>
  );
};

export default AddressInputField;
export type { Props as AddressInputFieldProps };
