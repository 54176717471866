import { CheckIcon } from '@primer/octicons-react';
import { formatKatexToHtmlString } from '@teamturing/katex-utils';
import { editor } from 'monaco-editor';
import React, { Suspense, useState } from 'react';
import { graphql, useFragment } from 'react-relay';

import { TaskProblemTypingDialog_task$key } from '../../../relay/__generated__/TaskProblemTypingDialog_task.graphql';
import { TaskProblemTypingDialog_taskProblemTypingMutation } from '../../../relay/__generated__/TaskProblemTypingDialog_taskProblemTypingMutation.graphql';
import Button from '../../core/Button';
import Card from '../../core/Card';
import Dialog, { DialogProps } from '../../core/Dialog';
import EnumPair from '../../core/EnumPair';
import Grid from '../../core/Grid';
import Image from '../../core/Image';
import ItemList from '../../core/ItemList';
import { KatexEditorShortcutButtonsStack } from '../../core/KatexEditor';
import KatexEditorField from '../../core/KatexEditorField';
import Label from '../../core/Label';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Stack from '../../core/Stack';
import TabHandler from '../../core/TabHandler';
import Text from '../../core/Text';
import View from '../../core/View';

const TaskProblemTypingDialog_task = graphql`
  fragment TaskProblemTypingDialog_task on Task {
    id
    sequence
    title
    scrap {
      id
      problems {
        id
        objectUrl
      }
      solutions {
        id
        objectUrl
      }
    }
    problem {
      id
      problem
      solution
      problemType
    }
  }
`;

type Props = {
  task: TaskProblemTypingDialog_task$key;
} & DialogProps &
  Pick<MutationFormikProps<TaskProblemTypingDialog_taskProblemTypingMutation>, 'connections' | 'config' | 'onSubmit'>;

const TaskProblemTypingDialog = ({ task, connections, config, onSubmit, ...props }: Props) => {
  const { id, sequence, title, scrap, problem } = useFragment(TaskProblemTypingDialog_task, task);

  const editorInputTabItems = [
    { id: 'all', label: '문제 + 해설' },
    { id: 'problem', label: '문제' },
    { id: 'solution', label: '해설' },
  ];
  const [activeEditor, setActiveEditor] = useState<editor.IStandaloneCodeEditor | null>(null);

  return (
    <Dialog {...props}>
      <MutationFormik<TaskProblemTypingDialog_taskProblemTypingMutation>
        mutation={graphql`
          mutation TaskProblemTypingDialog_taskProblemTypingMutation($input: TaskProblemTypingInput!) {
            taskProblemTyping(input: $input) {
              id
              solution
              problem
            }
          }
        `}
        initialValues={{
          problem: {
            text: problem?.problem || '',
            textHtml: formatKatexToHtmlString(problem?.problem || ''),
          },
          solution: {
            text: problem?.solution || '',
            textHtml: formatKatexToHtmlString(problem?.solution || ''),
          },
          task: id,
        }}
        connections={connections}
        config={config}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, dirty }, { scrollContainerRef, nonFieldErrorRef }) => {
          return (
            <>
              <Dialog.Header>
                <Grid sx={{ paddingRight: 4 }}>
                  <Grid.Unit size={'max'}>
                    <Stack gapX={1}>
                      <Stack.Item>
                        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>타이핑하기</Text>
                      </Stack.Item>
                      {problem ? (
                        <Stack.Item>
                          <Suspense>
                            <View>
                              <Label variant={'accent'}>
                                <EnumPair typename={'TaskProblemTypeEnum'}>{problem.problemType}</EnumPair>
                                {` · ${title}`}
                              </Label>
                            </View>
                          </Suspense>
                        </Stack.Item>
                      ) : null}
                      <Stack.Item>
                        <Label variant={'primary'}>{sequence}</Label>
                      </Stack.Item>
                    </Stack>
                  </Grid.Unit>
                  <Grid.Unit size={'min'}>
                    <Button
                      variant={'primary'}
                      onClick={() => handleSubmit()}
                      leadingIcon={CheckIcon}
                      disabled={!dirty}
                    >
                      저장하기
                    </Button>
                  </Grid.Unit>
                </Grid>
              </Dialog.Header>
              <Dialog.Body ref={scrollContainerRef}>
                <TabHandler initialSelectIndex={0}>
                  {({ selectedIndex, handleSelect }) => (
                    <>
                      <NonFieldError ref={nonFieldErrorRef} />
                      <Grid gapY={2} sx={{ '&:not(:first-child)': { paddingTop: 3 } }}>
                        <Grid.Unit size={'max'}>
                          <Stack gapX={1}>
                            <ItemList
                              items={editorInputTabItems}
                              renderItem={({ label }, index) => (
                                <Button
                                  onClick={() => handleSelect(index)}
                                  variant={selectedIndex === index ? 'outline' : 'invisible'}
                                >
                                  {label}
                                </Button>
                              )}
                              renderItemWrapper={(children, { id }) => <Stack.Item key={id}>{children}</Stack.Item>}
                            />
                          </Stack>
                        </Grid.Unit>
                        <Grid.Unit size={'min'}>
                          <KatexEditorShortcutButtonsStack editor={activeEditor!} gapX={1} />
                        </Grid.Unit>
                      </Grid>
                      <View sx={{ 'marginTop': 5, '&>*:not(:first-child)': { marginTop: 3 } }}>
                        {editorInputTabItems[selectedIndex].id === 'all' ||
                        editorInputTabItems[selectedIndex].id === 'problem' ? (
                          <Grid gapX={2}>
                            <Grid.Unit size={1 / 3}>
                              <Text fontSize={1}>스크랩한 문제</Text>
                              <Card
                                sx={{
                                  marginTop: 1,
                                  overflow: 'auto',
                                  height: editorInputTabItems[selectedIndex].id === 'all' ? '33vh' : '70vh',
                                }}
                              >
                                <ItemList
                                  items={scrap?.problems || []}
                                  renderItem={({ objectUrl }) => (
                                    <Image
                                      key={objectUrl}
                                      src={objectUrl}
                                      alt={objectUrl}
                                      html
                                      style={{ display: 'block' }}
                                    />
                                  )}
                                />
                              </Card>
                            </Grid.Unit>
                            <Grid.Unit size={2 / 3}>
                              <KatexEditorField
                                reverse
                                name={'problem'}
                                label={'문제 타이핑'}
                                type={'scroll'}
                                height={editorInputTabItems[selectedIndex].id === 'all' ? '33vh' : '70vh'}
                                required
                                onFocus={setActiveEditor}
                                disabledUploadImage
                                emptyStateText={'아직 타이핑한 문제가 없어요'}
                              />
                            </Grid.Unit>
                          </Grid>
                        ) : null}
                        {editorInputTabItems[selectedIndex].id === 'all' ||
                        editorInputTabItems[selectedIndex].id === 'solution' ? (
                          <Grid gapX={2}>
                            <Grid.Unit size={1 / 3}>
                              <Text fontSize={1}>스크랩한 해설</Text>
                              <Card
                                sx={{
                                  marginTop: 1,
                                  overflow: 'auto',
                                  height: editorInputTabItems[selectedIndex].id === 'all' ? '33vh' : '70vh',
                                }}
                              >
                                <ItemList
                                  items={scrap?.solutions || []}
                                  renderItem={({ objectUrl }) => (
                                    <Image
                                      key={objectUrl}
                                      src={objectUrl}
                                      alt={objectUrl}
                                      html
                                      style={{ display: 'block' }}
                                    />
                                  )}
                                />
                              </Card>
                            </Grid.Unit>
                            <Grid.Unit size={2 / 3}>
                              <KatexEditorField
                                reverse
                                name={'solution'}
                                label={'해설 타이핑'}
                                type={'scroll'}
                                height={editorInputTabItems[selectedIndex].id === 'all' ? '33vh' : '70vh'}
                                required
                                onFocus={setActiveEditor}
                                disabledUploadImage
                                emptyStateText={'아직 타이핑한 해설이 없어요'}
                              />
                            </Grid.Unit>
                          </Grid>
                        ) : null}
                      </View>
                    </>
                  )}
                </TabHandler>
              </Dialog.Body>
            </>
          );
        }}
      </MutationFormik>
    </Dialog>
  );
};

export default TaskProblemTypingDialog;
