import { Suspense } from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  MATOrganizationDescriptionList_matOrganization$key,
  MATOrganizationDescriptionList_matOrganization$data,
} from '../../../relay/__generated__/MATOrganizationDescriptionList_matOrganization.graphql';
import DateText from '../../core/DateText';
import DescriptionList, { DescriptionListProps } from '../../core/DescriptionList';
import EnumPair from '../../core/EnumPair';

const MATOrganizationDescriptionList_matOrganization = graphql`
  fragment MATOrganizationDescriptionList_matOrganization on MATOrganization {
    createdBy
    created
    title
    prefix
    type
  }
`;

type Props = {
  matOrganization: MATOrganizationDescriptionList_matOrganization$key;
  type?: 'default' | 'activity';
} & Omit<
  DescriptionListProps<MATOrganizationDescriptionList_matOrganization$data>,
  'item' | 'itemDescriptions' | 'picks'
>;

const MATOrganizationDescriptionList = ({
  matOrganization: matOrganizationReference,
  type = 'default',
  ...props
}: Props) => {
  const organization = useFragment(MATOrganizationDescriptionList_matOrganization, matOrganizationReference);

  return (
    <DescriptionList
      item={organization}
      itemDescriptions={{
        title: {
          title: '조직 이름',
          renderValue: ({ title }) => title,
        },
        type: {
          title: '조직 타입',
          renderValue: ({ type }) => (
            <Suspense fallback={'-'}>
              <EnumPair typename={'MATOrganizationTypeEnum'}>{type}</EnumPair>
            </Suspense>
          ),
        },
        prefix: {
          title: '계정 이름',
          renderValue: ({ prefix }) => prefix,
        },
        createdBy: {
          title: '제작자',
          renderValue: ({ createdBy }) => createdBy,
        },
        created: {
          title: '제작일',
          renderValue: ({ created }) => <DateText>{created}</DateText>,
        },
      }}
      picks={type === 'default' ? ['title', 'prefix', 'type'] : type === 'activity' ? ['createdBy', 'created'] : []}
      {...props}
    />
  );
};

export default MATOrganizationDescriptionList;
