import { editor } from 'monaco-editor';
import { forwardRef, Ref } from 'react';
import { fetchQuery, graphql } from 'react-relay';

import { KatexEditor_meQuery } from '../../../relay/__generated__/KatexEditor_meQuery.graphql';
import environment from '../../../relay/environment';
import Editor, { EditorProps, Monaco } from '../Editor';

import KatexEditorShortcutButtonsStack from './KatexEditorShortcutButtonsStack';
import { getCompletionItems, getLanguageConfig, language, provideCodeActions } from './utils';

const meForKatexEditor = graphql`
  query KatexEditor_meQuery {
    me {
      shortcuts {
        text
        label
      }
    }
  }
`;

type Props = {} & Omit<EditorProps, 'language'>;

let registered = false;

const KatexEditor = ({ beforeMount, onMount, ...props }: Props, ref: Ref<editor.IStandaloneCodeEditor>) => {
  const LANGUAGE_NAME = 'html-custom';

  const handleBeforeMount = (monaco: Monaco) => {
    if (registered) {
      return;
    }
    const conf = getLanguageConfig(monaco);
    // Register a new language
    monaco.languages.register({ id: LANGUAGE_NAME });

    // Register a tokens provider for the language
    monaco.languages.setMonarchTokensProvider(LANGUAGE_NAME, language as any);

    monaco.languages.onLanguage(LANGUAGE_NAME, () => {
      monaco.languages.setLanguageConfiguration(LANGUAGE_NAME, conf);

      fetchQuery<KatexEditor_meQuery>(environment, meForKatexEditor, {})
        .toPromise()
        .then((data) => {
          monaco.languages.registerCompletionItemProvider(LANGUAGE_NAME, {
            provideCompletionItems: () => {
              return {
                suggestions: getCompletionItems(
                  monaco,
                  data?.me.shortcuts.map(({ text, label }) => ({ text, label })),
                ),
              };
            },
          } as any);
        });

      monaco.languages.registerCodeActionProvider(LANGUAGE_NAME, {
        provideCodeActions,
      });
    });

    beforeMount?.(monaco);

    registered = true;
  };

  const handleMount: EditorProps['onMount'] = (editor, monaco) => {
    const conf = getLanguageConfig(monaco);

    editor.onDidChangeModelContent(({ changes }) => {
      const text = changes[0].text;

      if (text.length !== 2) return;

      const isAutoClosing = !!conf.autoClosingPairs.find(({ open, close }) => open === text[0] && close === text[1]);
      if (isAutoClosing) return;

      const closingValue = conf.autoClosingPairs.find(({ open }) => open === text[1])?.close;
      const selection = editor.getSelection();

      if (closingValue && selection)
        editor.executeEdits(
          null,
          [
            {
              range: selection,
              text: closingValue,
            },
          ],
          [selection],
        );
    });

    onMount?.(editor, monaco);
  };

  return <Editor ref={ref} beforeMount={handleBeforeMount} onMount={handleMount} language={LANGUAGE_NAME} {...props} />;
};

export default forwardRef(KatexEditor);
export { KatexEditorShortcutButtonsStack };

export type { Props as KatexEditorProps };
