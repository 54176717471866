/**
 * @generated SignedSource<<a899a90c73d963dc70182dc07f68f392>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MATOrganizationTypeEnum = "academy" | "school" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MATOrganizationUpdateDialog_matOrganization$data = {
  readonly id: string;
  readonly prefix: string;
  readonly title: string;
  readonly type: MATOrganizationTypeEnum;
  readonly " $fragmentType": "MATOrganizationUpdateDialog_matOrganization";
};
export type MATOrganizationUpdateDialog_matOrganization$key = {
  readonly " $data"?: MATOrganizationUpdateDialog_matOrganization$data;
  readonly " $fragmentSpreads": FragmentRefs<"MATOrganizationUpdateDialog_matOrganization">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MATOrganizationUpdateDialog_matOrganization",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prefix",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "MATOrganization",
  "abstractKey": null
};

(node as any).hash = "54b3e463adaffc575762842898c75bf9";

export default node;
