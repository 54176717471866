import { graphql } from 'react-relay';

import { ScrapSourceBookCreateDialog_scrapSourceBookCreateMutation } from '../../../relay/__generated__/ScrapSourceBookCreateDialog_scrapSourceBookCreateMutation.graphql';
import BookPaginatorSearchOverlayField from '../../book/BookPaginatorSearchOverlayField';
import Button from '../../core/Button';
import Dialog, { DialogProps } from '../../core/Dialog';
import FormLayout from '../../core/FormLayout';
import MutationFormik, { MutationFormikProps } from '../../core/MutationFormik';
import NonFieldError from '../../core/NonFieldError';
import Text from '../../core/Text';

type Props = {} & DialogProps &
  Pick<
    MutationFormikProps<ScrapSourceBookCreateDialog_scrapSourceBookCreateMutation>,
    'onSubmit' | 'config' | 'connections'
  >;

const ScrapSourceBookCreateDialog = ({ onSubmit, config, connections, ...props }: Props) => {
  return (
    <Dialog {...props}>
      <Dialog.Header>
        <Text sx={{ fontSize: 3, fontWeight: 'bold' }}>시중 교재 스크랩에서 가져오기</Text>
      </Dialog.Header>
      <MutationFormik<ScrapSourceBookCreateDialog_scrapSourceBookCreateMutation>
        mutation={graphql`
          mutation ScrapSourceBookCreateDialog_scrapSourceBookCreateMutation($input: ScrapSourceBookCreateInput!) {
            scrapSourceBookCreate(input: $input) {
              id
            }
          }
        `}
        initialValues={{ bookId: '' }}
        onSubmit={onSubmit}
        config={config}
        connections={connections}
      >
        {({ handleSubmit, dirty }, { scrollContainerRef, nonFieldErrorRef }) => (
          <>
            <Dialog.Body ref={scrollContainerRef}>
              <NonFieldError ref={nonFieldErrorRef} sx={{ marginBottom: 3 }} />
              <FormLayout>
                <BookPaginatorSearchOverlayField
                  label={'시중 교재 스크랩'}
                  name={'bookId'}
                  required
                  placeholder={'시중 교재 스크랩 선택'}
                  variables={{ filters: { status_Exact: 'completed' } }}
                />
              </FormLayout>
            </Dialog.Body>
            <Dialog.Footer>
              <Button variant={'primary'} disabled={!dirty} onClick={() => handleSubmit()}>
                가져오기
              </Button>
            </Dialog.Footer>
          </>
        )}
      </MutationFormik>
    </Dialog>
  );
};

export default ScrapSourceBookCreateDialog;
