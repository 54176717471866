import useConfirm, { UseConfirmOptions } from '../../../hooks/useConfirm';

import ToolbarIconButton, { ToolbarIconButtonProps } from './ToolbarIconButton';

type Props = {} & UseConfirmOptions & ToolbarIconButtonProps;

const ConfirmToolbarIconButton = ({ onClick, message, ...props }: Props) => {
  const { confirm } = useConfirm({ message });

  const handleClick: ToolbarIconButtonProps['onClick'] = (event) => {
    if (!onClick) return;
    if (confirm()) onClick(event);
  };
  return <ToolbarIconButton onClick={handleClick} {...props} />;
};

export default ConfirmToolbarIconButton;
export type { Props as ConfirmToolbarIconButtonProps };
