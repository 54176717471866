import View, { ViewProps } from '../View';

type Props = {} & ViewProps;

const SquareView = ({ sx, children, ...props }: Props) => (
  <View
    sx={{
      ...sx,
      'position': 'relative',
      'width': '100%',
      ':after': {
        content: "''",
        display: 'block',
        paddingBottom: '100%',
      },
    }}
    {...props}
  >
    <View sx={{ position: 'absolute', width: '100%', height: '100%' }}>{children}</View>
  </View>
);

export default SquareView;
