/**
 * @generated SignedSource<<82346e26744c2a07edd648b905b04eb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type WorkbookGradesDataTable_grades$data = {
  readonly grades: ReadonlyArray<{
    readonly grade: string;
    readonly percentile: number | null;
    readonly rawScore: number | null;
    readonly standardScore: number | null;
  }>;
  readonly " $fragmentType": "WorkbookGradesDataTable_grades";
};
export type WorkbookGradesDataTable_grades$key = {
  readonly " $data"?: WorkbookGradesDataTable_grades$data;
  readonly " $fragmentSpreads": FragmentRefs<"WorkbookGradesDataTable_grades">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WorkbookGradesDataTable_grades",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkbookGradeType",
      "kind": "LinkedField",
      "name": "grades",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percentile",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rawScore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "standardScore",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Workbook",
  "abstractKey": null
};

(node as any).hash = "d5f5ec4f5e82dcb94b492c07680c762c";

export default node;
