import { CheckIcon, XIcon } from '@primer/octicons-react';
import React from 'react';
import { graphql, useFragment } from 'react-relay';

import {
  SchoolExamSourceConnectionDataTable_schoolExamSourceConnection$data,
  SchoolExamSourceConnectionDataTable_schoolExamSourceConnection$key,
} from '../../../relay/__generated__/SchoolExamSourceConnectionDataTable_schoolExamSourceConnection.graphql';
import DataTable, { DataTableProps } from '../../core/DataTable';
import EnumPair from '../../core/EnumPair';
import StyledOcticon from '../../core/StyledOcticon';
import UserAvatarText from '../../user/UserAvatarText';
import SchoolExamSourceStatusLabel from '../SchoolExamSourceStatusLabel';

const SchoolExamSourceConnectionDataTable_schoolExamSourceConnection = graphql`
  fragment SchoolExamSourceConnectionDataTable_schoolExamSourceConnection on SchoolExamSourceConnection {
    edges {
      node {
        id
        sequence
        ...SchoolExamSourceStatusLabel_schoolExamSource
        school {
          id
          title
          region
        }
        title
        innerPdf {
          id
        }
        assignedTo {
          id
          ...UserAvatarText_user
        }
        completedBy {
          id
          ...UserAvatarText_user
        }
      }
    }
  }
`;

type Props = {
  schoolExamSourceConnection: SchoolExamSourceConnectionDataTable_schoolExamSourceConnection$key;
} & Omit<
  DataTableProps<SchoolExamSourceConnectionDataTable_schoolExamSourceConnection$data['edges'][number]['node']>,
  'rows' | 'columns'
>;

const SchoolExamSourceConnectionDataTable = ({ schoolExamSourceConnection, ...props }: Props) => {
  const { edges } = useFragment(
    SchoolExamSourceConnectionDataTable_schoolExamSourceConnection,
    schoolExamSourceConnection,
  );

  return (
    <DataTable
      rows={edges.map(({ node }) => node).filter((node) => !!node)}
      columns={[
        { field: 'sequence', title: 'ID', renderValue: ({ sequence }) => sequence, width: 80 },
        {
          field: 'status',
          title: '상태',
          renderValue: (schoolExamSource) => <SchoolExamSourceStatusLabel schoolExamSource={schoolExamSource} />,
          width: 100,
          align: 'center',
        },
        {
          field: 'school.region',
          title: '지역',
          renderValue: ({ school }) => <EnumPair typename={'SchoolRegionEnum'}>{school.region}</EnumPair>,
          width: 80,
        },
        { field: 'school.title', title: '학교', renderValue: ({ school }) => school.title, width: 160 },
        { field: 'title', title: '제목', renderValue: ({ title }) => title, width: 504 },
        {
          field: 'assignedTo',
          title: '작업자',
          renderValue: ({ assignedTo }) => (assignedTo ? <UserAvatarText user={assignedTo} /> : '-'),
          width: 92,
        },
        {
          field: 'completedBy',
          title: '완료자',
          renderValue: ({ completedBy }) => (completedBy ? <UserAvatarText user={completedBy} /> : '-'),
          width: 92,
        },
        {
          field: 'innerPdf',
          title: '내부 업로드',
          renderValue: ({ innerPdf }) =>
            innerPdf ? (
              <StyledOcticon icon={CheckIcon} color={'success.fg'} />
            ) : (
              <StyledOcticon icon={XIcon} color={'closed.fg'} />
            ),
          width: 90,
          align: 'center',
        },
      ]}
      {...props}
    />
  );
};

export default SchoolExamSourceConnectionDataTable;
